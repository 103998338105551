@if(isApproval) {
    <div nxRow>
        <!--Current Extra Cover-->
        <div nxCol="12,12,12,12,6">
            <div>
                <app-external-scrollbar targetSyncScroll="4"
                    [targetBaseWidth]="tableHolder4"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar"
                    #tableHolder4 syncScroll="4">
                    <table nxTable zebra cellpadding="0" class="nx-margin-bottom-2m">
                        <thead>
                            <tr nxTableRow class="table_row_flex">
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Current Extra Cover</span>
                                </th>
                                <th nxHeaderCell class="table_extra_coverage"></th>
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Premium (RM)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(currentExtraCover of currentExtraCoverList; track currentExtraCover) {
                                <tr nxTableRow class="table_row_flex">
                                <td nxTableCell class="table_extra_coverage">
                                    <span nxCopytext="medium">{{ currentExtraCover.subCoverCodeDescription }}</span>
                                </td>
                                <td nxTableCell class="table_extra_coverage">
                                    @for(subItem of currentExtraCover.mtExtCovDtoList; track subItem) {
                                        @if(currentExtraCover.planCode) {
                                            <span nxCopytext="medium">{{ currentExtraCover.displayLabel }} <br></span> 
                                        }
                                    }
                                    @if(currentExtraCover.subCoverSumInsured != 0) {
                                        <span nxCopytext="medium">SUM INSURED (RM) : {{ currentExtraCover.subCoverSumInsured | formatDecimal }}</span> 
                                    }
                                    
                                </td><!--Temp Value-->
                                <td nxTableCell class="table_extra_coverage">
                                    <span nxCopytext="medium">{{ currentExtraCover.subCoverPrem | formatDecimal }}</span>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Previous Extra Cover-->
        <div nxCol="12,12,12,12,6">
            <div>
                <app-external-scrollbar targetSyncScroll="5"
                    [targetBaseWidth]="tableHolder5"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar"
                    #tableHolder5 syncScroll="5">
                    <table nxTable zebra cellpadding="0" class="nx-margin-bottom-2m">
                        <thead>
                            <tr nxTableRow class="table_row_flex">
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Previous Extra Cover</span>
                                </th>
                                <th nxHeaderCell class="table_extra_coverage"></th>
                                <th nxHeaderCell class="table_extra_coverage">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Premium (RM)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(prevExtraCover of prevExtraCoverList; track prevExtraCover) {
                                <tr nxTableRow class="table_row_flex">
                                    <td nxTableCell class="table_extra_coverage">
                                        <span nxCopytext="medium">{{ prevExtraCover.subCoverCodeDescription }}</span>
                                    </td>
                                    <td nxTableCell class="table_extra_coverage">
                                        @for(subItem of prevExtraCover.mtExtCovDtoList; track subItem) {
                                            @if(prevExtraCover.planCode) {
                                                <span nxCopytext="medium">{{ prevExtraCover.displayLabel }} <br></span>
                                            }
                                        }

                                        @if(prevExtraCover.subCoverSumInsured != 0) {
                                            <span nxCopytext="medium">SUM INSURED (RM) : {{ prevExtraCover.subCoverSumInsured | formatDecimal }}</span> 
                                        }

                                    </td><!--Temp Value-->
                                    <td nxTableCell class="table_extra_coverage">
                                        <span nxCopytext="medium">{{ prevExtraCover.subCoverPrem | formatDecimal }}</span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}
