import { ChangeDetectorRef, Component } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { SortDirection, SortEvent } from "@aposin/ng-aquila/table";
import { Router, RouterLink } from "@angular/router";

interface Policy {
  agent: string;
  policy_no: string;
  product_name: string;
  customer_name: string;
  Vehicle_trailer_no: string;
  effective_date: Date;
  expiry_date: Date;
  premium_due: number;
  additional_info: string;
  status: string;
}

interface Claim {
  claim_no: string;
  policy_no: string;
  Vehicle_trailer_no: string;
  customer_name: string;
  as_at: Date;
  ncd_percent: number;
}

@Component({
  selector: "app-policy-enquiry",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./policy-enquiry.component.html",
  styleUrl: "./policy-enquiry.component.scss",
})
export class PolicyEnquiryComponent {

  title : any = "Policy Enquiry";

  currentIndex = 0;
  tabChange(event: any){
    this.title = event.tab?._label;


    // 0 = policy enquiry
    // 1 = claim enquiry
    // 2 = e-invoice enquiry
    this.currentIndex = event.index
    // return
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }


  product_list = [
    "all",
    "all risks",
    "ALLIANZ BUSINESS SHIELD",
    "ALLIANZ HOSPITAL INCOME",
    "ALLIANZ LIFESTYLE PROTECT",
    "ALLIANZ MEDICURE",
    "motor",
  ];

  search_by_list = [
    "Vehicle / Trailer No.",
    "e-Policy No.",
    "e-cover note no.",
    "Customer Name",
    "NRIC",
    "Old IC / Police ID / Army ID / Passport No.",
    "Business Registration No.",
    "New Business Registration No.",
    "chassis No.",
  ];

  tableElements: Policy[] = [
    {
      agent: "AS00584 / KALA ENTERPRISE",
      policy_no: "23VAS0001685-00",
      product_name: "PRIVATE CARS EXCLUDING GOODS",
      customer_name: "WONG KAM WAI",
      Vehicle_trailer_no: "WA8012L",
      effective_date: new Date("12/13/2023"),
      expiry_date: new Date("12/12/2024"),
      premium_due: 1540.40,
      additional_info: "CHASSIS NO. 12345678900111",
      status: "active",
    },
  ];

  tableClaims: Claim[] = [
    {
      claim_no: "VKL9151394",
      policy_no: "VKL9151394",
      Vehicle_trailer_no: "LBC3333",
      customer_name: "WONG KAM WAI",
      as_at: new Date("11/22/2022"),
      ncd_percent: 0.0,
    },
    {
      claim_no: "VKL9151396",
      policy_no: "22VKL0042209-00",
      Vehicle_trailer_no: "LBC3333",
      customer_name: "WONG KAM WAI",
      as_at: new Date("11/22/2022"),
      ncd_percent: 0.0,
    },
    {
      claim_no: "VKL9151391",
      policy_no: "22VKL0042209-00",
      Vehicle_trailer_no: "LBC3333",
      customer_name: "WONG KAM WAI",
      as_at: new Date("08/29/2023 "),
      ncd_percent: 0.0,
    },
  ];

  /**
   * Sorts the table data by a certain category.
   */
  sortTable(sort: SortEvent) {
    this.tableElements = this.tableElements.sort(
      (a: { [key: string]: any }, b: { [key: string]: any }) => {
        if (sort.active in a && sort.active in b) {
          return this.compare(a[sort.active], b[sort.active], sort.direction);
        }
        return 0;
      }
    );
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }

  show_policy_filter = true;
  togglePolicyFilter() {
    this.show_policy_filter = !this.show_policy_filter;
  }

  show_claim_filter = true;
  toggleClaimFilter() {
    this.show_claim_filter = !this.show_claim_filter;
  }

  constructor(private router: Router, private cdr: ChangeDetectorRef) {}

  clickTable(type: any) {
    this.router.navigate(['/enquiry/'+type]);
  }
}
