import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { GET_CHECKLIST_LOV } from '@store/sat-motor/sat-motor.action';

interface ChecklistItem {
  id: number;
  title: string;
  description: string;
  options: string[];
  notes?: string[];
}

@Component({
  selector: 'app-checklist',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './checklist.component.html',
  styleUrl: './checklist.component.scss'
})

export class ChecklistComponent implements OnInit {
  @Input() checklistItems: any;
  @Input() checklistRemarks: any;
  @Input() parentForm: FormGroup;
  @Input() isViewOnly: boolean = false;
  @Input() endtCode: any;
  checklistForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.checklistForm = this.fb.group({
      rows: this.fb.array([])
    });

    if(this.isViewOnly) {
      this.initViewChecklist();
    }

    this.parentForm.controls["checklistForm"] = this.checklistForm;

    this.store.dispatch(new GET_CHECKLIST_LOV(this.endtCode));

    console.log("checklist form: ", this.checklistForm.getRawValue());

  }

  initViewChecklist() {
    this.checklistItems.forEach((item, index) => {
      this.rows.push(this.fb.group({
        declareAns: [ { value: item.declareAns, disabled: true } ],
        declareCode: [item.declareCode],
      }));
    });
  }

  getQuestionDesc(declareCode) {
    if(this.isViewOnly) {
      return this.checklistItems.find(e => e.declareCode === declareCode)?.description;
    }
  }

  formatData(value: string): string {
    if (!value || value === '') {
        return 'N/A';
    }
    return value.toUpperCase();
  }

  get rows() {
    return this.checklistForm.get('rows') as FormArray;
  }
}
