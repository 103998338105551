<ng-container>
    <div class="header_main">
      <div class="breadcrumb-content nx-hidden-xs nx-hidden-s">
        <span *ngFor="let item of items; let i = index; last as isLast">
          <nx-link *ngIf="i < items.length-1" class="nx-font-weight-light">
            <a (click)="back()">{{ item }}</a>
          </nx-link>
          <nx-icon *ngIf="i < items.length-1"  name="chevron-right"></nx-icon>
        </span>
        <nx-link class="nx-font-weight-semibold">
          <a (click)="(false)">{{ items[items.length-1] }}</a>
        </nx-link>
      </div>
      <div class="breadcrumb-content nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        <nx-icon name="arrow-left" style="font-size: 20px;"></nx-icon>
        <nx-link class="nx-font-weight-semibold">
          <a (click)="back()">&nbsp;BACK</a>
        </nx-link>
      </div>
  
      <div nxLayout="grid maxwidth">
        <div nxRow>
          <div
            nxCol="12"
            class="container_flex_center align_center nx-margin-top-4m"
          >
            <h1 nxHeadline="page">Motor Comprehensive Cover</h1>
          </div>
          <div
            nxCol="12"
            class="container_flex_center align_center nx-margin-top-s nx-margin-bottom-3xl"
          >
            <span nxCopytext="large"
              >Whether you have a car or a motorcycle, Allianz offers reliable
              protection and the peace of mind you need.</span
            >
          </div>
        </div>
      </div>
    </div>
  
    <div class="desc">
      <div nxLayout="grid maxwidth">
        <div nxRow>
          <div
            nxCol="12"
            class="container_flex_center align_center nx-margin-top-4m"
          >
            <h1 nxHeadline="page">Product Info</h1>
          </div>
          <div
            nxCol="12"
            class="container_flex_center align_center nx-margin-top-s"
          >
            <span nxCopytext="normal"
              >Motor Comprehensive insurance covers your car against liabilities
              to other parties for death or bodily injuries, damages to other
              parties’ properties, accidental or fire damages to your car and
              theft of your car.</span
            >
          </div>
          <div nxCol="12" class="container_flex_center nx-margin-top-s">
            <span nxCopytext="normal">Coverage:</span>
          </div>
          <div
            nxCol="12"
            class="container_flex_center nx-margin-top-s nx-margin-bottom-l"
          >
            <ul nxList>
              <li>
                <span nxCopytext="normal"
                  >Losses or damages to your car due to accident, fire and
                  theft</span
                >
              </li>
              <li>
                <span nxCopytext="normal"
                  >Third Party death and bodily injuries</span
                >
              </li>
              <li>
                <span nxCopytext="normal"
                  >Third Party property losses or damages</span
                >
              </li>
              <li><span nxCopytext="normal">Extended benefits</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
    <div nxLayout="grid maxwidth" class="nx-margin-top-m">
      <div nxRow>
        <div nxCol="12" class="container_flex_center align_center">
          <h1 nxHeadline="page">Downloads</h1>
        </div>
      </div>
  
      <div nxRow class="nx-margin-top-2m">
        <div
          nxCol="12, 12, 12, 4"
          *ngFor="let item of download_list"
          class="nx-margin-top-s"
        >
          <nx-card>
            <div nxLayout="grid nogutter nopadding ">
              <div nxRow>
                <div nxCol="2,2,1,2">
                  <img src="assets/img/Icon - PDF - Colour.svg" alt="" />
                </div>
                <div nxCol="6,8,9,6" class="padding_0">
                  <span nxCopytext="normal"> {{ item.name }}</span>
                  <br />
                  <span nxCopytext="medium" class="font-grey">
                    {{ item.date }}</span
                  >
                </div>
                <div nxCol="4,2,2,4" class="download_button">
                  <nx-icon
                    class="icon-download"
                    aria-hidden="true"
                    outline="true"
                    name="download"
                    style="font-size: 18px"
                  ></nx-icon>
                  <nx-icon
                    class="icon-download"
                    aria-hidden="true"
                    outline="true"
                    name="share"
                    style="font-size: 18px"
                    (click)="openSharePopup()"
                  ></nx-icon>
                </div>
              </div>
            </div>
          </nx-card>
        </div>
      </div>
    </div>
  </ng-container>