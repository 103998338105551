import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { UserResponse } from '@interfaces/user';
import { ACTIONS } from '@shared/enums/index.enum';
import {
  CHECK_SINGLE_AGENT,
  GET_AGENT_DETAIL_ONLY,
  GET_AGENT_LIST_AGENT,
  GET_AGENT_LIST_BRANCH,
  GET_BRANCH_LIST, GET_LOV,
  GET_CCENTRIC_OPPORTUNITY_INFO, 
  SET_ACTION,
  GET_DRAFT_DETAILS, 
  COPY_DRAFT_INFO,
  GET_PRODUCT_LIST,
  RESET_SAT_MOTOR_STATE,
  CALCULATE_PREMIUM,
  SEARCH_MAKE_MODEL,
  GET_VEHICLE_USE,
  SELECTED_MAKE_MODEL, 
  COPY_CNGE_INFO_VIEW_QUOTATION,
  SET_OPPORTUNITY_ID
} from '@store/sat-motor/sat-motor.action';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { RESET_APPROVAL_STATE } from '@store/sat-motor-approval/sat-motor-approval.action';

@Component({
  selector: "app-home",
  standalone: true,
  imports: [],
  templateUrl: "./home.component.html",
  styleUrl: "./home.component.scss",
})
export class HomeComponent implements OnInit {
  userInfo: UserResponse;
  cCentricInfo: any;

  constructor(
    public store: Store,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    let isRenewNow = this.store.selectSnapshot(SatMotorState.isRenewNow);
    const params = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    if (!isRenewNow) {
      this.resetState();
      if(params.opportunityId){
        this.store.dispatch(new SET_OPPORTUNITY_ID(params.opportunityId));
      }else{
        this.store.dispatch(new SET_OPPORTUNITY_ID(null));
      }

      this.store.dispatch(new GET_LOV()).subscribe((state) => {
        this.ngZone.run(async () => {

          this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
  
          //this.store.dispatch(new GET_PENDING_NUMBERS({ userId: this.userInfo.userId })).toPromise();
  
          if (this.userInfo.hqAdmin || this.userInfo.branchAdmin) {
            this.store.dispatch(new CHECK_SINGLE_AGENT(false));
            this.store.dispatch(new GET_BRANCH_LIST(this.userInfo.userId)).subscribe((state) => {
              if (state.SatMotorState.data.branchList) {
                const params = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
  
                if (params) {
                  if (params.agentCode) {
                    let branchCode = params.agentCode.substr(0, 2);
                    this.store.dispatch(new GET_AGENT_LIST_BRANCH(state.UserState.user.userId, branchCode)).subscribe(() => {
                      this.actionReroute();
                    });
                  } else {
                    this.actionReroute();
                  }
                } else {
                  this.store.dispatch(new GET_AGENT_LIST_BRANCH(state.UserState.user.userId, state.NEQTState.data.branchList[0].code)).subscribe(() => {
                    this.actionReroute();
                  });
                }
              } else {
                this.actionReroute();
              }
            });
          } else {
            if(params.opportunityId){
              this.store.dispatch(new GET_AGENT_LIST_AGENT({ userId: this.userInfo.userId+"&criteria=CC_USER&criteriaValue=AG"  })).subscribe(() => {
                this.actionReroute();
              });
            }else{
              this.store.dispatch(new GET_AGENT_LIST_AGENT({ userId: this.userInfo.userId })).subscribe(() => {
                this.actionReroute();
              });
            }
            
          }
        });
      });
      
    }
  }

  async actionReroute() {
    let URL = {
      [ACTIONS.ISSUE]: "/issuance/declaration-issuance-info",
      [ACTIONS.ENDT]: "/endorsement/declaration-issuance-info",
      [ACTIONS.RTP]: "/rtp/declaration-issuance-info",
      [ACTIONS.SELECT_AGENT]: "/agent-selection",
    };

    const params = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    if (params) {
      // this.store.dispatch(new RESET_QUERY_PARAMS());
      const action = params.action;
      this.store.dispatch(new SET_ACTION(action));
      if (action) {
        switch (action) {
          case ACTIONS.SELECT_AGENT:
          case ACTIONS.ISSUE:
          case ACTIONS.RTP:
          case ACTIONS.ENDT: {
            const queryParams: Params = {
              cnoteType: params.cnoteType,
              agentCode: params.agentCode,
              productType: params.productType,
              idType: params.idType,
              idValue: params.idValue,
              clientType: params.clientType,
            };
            const data = {
              operator: this.userInfo.userId,
              agentCode: params.agentCode,
              productType: "MT",
            };

            if (action === ACTIONS.ENDT) {
              if(params.opportunityId){
                this.store.dispatch(new SET_OPPORTUNITY_ID(params.opportunityId));
              }
              await this.router.navigate(["/endorsement/declaration-issuance-info"]);
              break;
            }

            if (action === ACTIONS.RTP) {
              await this.router.navigate(["/rtp/declaration-issuance-info"]);
              break;
            }

            if(params.opportunityId){
              this.store.dispatch(new SET_OPPORTUNITY_ID(params.opportunityId));
              this.ccentricAction(params);
            }

            if (params.agentCode && params.agentCode.trim() !== "") {
              let agentDetailRes = await this.store.dispatch(new GET_AGENT_DETAIL_ONLY(data)).toPromise();
              if (agentDetailRes) {
                this.store.dispatch(new GET_PRODUCT_LIST({ lob: 'MT', agentCode: params.agentCode })).subscribe(() => {
                  // if (params.renewNow === "true") {
                  //   this.router.navigate(["/issuance/summary"], { queryParams: queryParams })
                  // } else {
                     this.router.navigate([URL[action]], { queryParams: queryParams });
                  // }
                });
              }
            } else {
              const isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
              if (isSingleAgent) {
                const agentList = this.store.selectSnapshot<any>((state) => state.SatMotorState?.data?.step1?.agentList);
                const payload = {
                  operator: this.userInfo.userId,
                  agentCode: agentList[0].code,
                  productType: "MT",
                };
                let agentDetailRes = await this.store.dispatch(new GET_AGENT_DETAIL_ONLY(payload)).toPromise();
                if (agentDetailRes) {
                  this.store.dispatch(new GET_PRODUCT_LIST({ lob: 'MT', agentCode: agentList[0].code })).subscribe(() => {
                    if(params.renewNow === "true"){
                      this.router.navigate(["/issuance/summary"], { queryParams: queryParams })
                    }else{
                      this.router.navigate([URL[action]], { queryParams: queryParams });
                    }
                  });

                }
              } else {
                await this.router.navigate(["/agent-selection"], { queryParams: queryParams });
              }
            }
            break;
          }
          case ACTIONS.ENQUIRY: {
            const queryParams: Params = {
              documentNo: params.documentNo,
              action: ACTIONS.ENQUIRY,
              agentCode: params.agentCode,
              enquiryProduct: params.enquiryProduct,
              searchCriteria: params.searchCriteria,
              searchValue: params.searchValue,
            };
            await this.ngZone.run(() =>
              this.router.navigate(["/enquiry"], {
                queryParams: queryParams,
              }),
            );
            break;
          }
          case ACTIONS.VIEW: {
            const queryParams: Params = {
              contractId: params.contractId,
              action: ACTIONS.VIEW,
            }
            
            if(params.opportunityId){
              this.store.dispatch(new SET_OPPORTUNITY_ID(params.opportunityId));
            }

            await this.ngZone.run(() =>
              this.router.navigate(["/view-quotation"]),
            );
            break;
          }
          case ACTIONS.VIEWENDT: {
            this.store.dispatch(new SET_ACTION('ENDT'));
            const queryParams: Params = {
              contractId: params.contractId,
              action: ACTIONS.VIEW,
            }
            await this.ngZone.run(() =>
              this.router.navigate(["/view-endorsement"]),
            );
            break;
          }
          case ACTIONS.CLAIMPOLICY:
          case ACTIONS.VIEWPOLICY: {
            const queryParams: Params = {
              action: action === ACTIONS.VIEWPOLICY ? ACTIONS.VIEWPOLICY : ACTIONS.CLAIMPOLICY,
            }
            await this.ngZone.run(() =>
              this.router.navigate(["/view-policy"]),
            );
            break;
          }

          case ACTIONS.MOTOR_APPROVAL: {
            this.store.dispatch(new RESET_APPROVAL_STATE());
            const queryParams: Params = {
              contractId: params.contractId
            }
            await this.ngZone.run(() =>
              this.router.navigate(["/view-approval"]),
            );
            break;
          }
          case ACTIONS.UPDATE: {
            const queryParams: Params = {
              contractId: params.contractId,
              action: ACTIONS.UPDATE
            }

            this.retrieveDraftRecord(queryParams);
            
            break;
          }
          case ACTIONS.ISM_DATA_UPLOAD: {
            const queryParams: Params = {
              action: ACTIONS.ISM_DATA_UPLOAD,
            }
            await this.ngZone.run(() =>
              this.router.navigate(["/endorsement-data-upload"]),
            );
            break;
          }
          case ACTIONS.NCD_CONFIRMATION: {
            const queryParams: Params = {
              action: ACTIONS.NCD_CONFIRMATION,
            }
            await this.ngZone.run(() =>
              this.router.navigate(["/ncd-confirmation"]),
            );
            break;
          }
          case ACTIONS.NCD_ENQUIRY: {
            const queryParams: Params = {
              action: ACTIONS.NCD_ENQUIRY,
            }
            await this.ngZone.run(() =>
              this.router.navigate(["/ncd-enquiry"]),
            );
            break;
          }
        }
      }
    }
  }

  retrieveDraftRecord(queryParams) {
    let userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    
    this.store.dispatch(new GET_DRAFT_DETAILS(queryParams.contractId, "SUMMARY-DRAFT")).subscribe(state => {
      let draftDetails = state.SatMotorState.draftDetails;
      let hasAdditionalPage = this.isCallCenterUser() || draftDetails?.mkagAgent?.bancaAgent || draftDetails?.mkagAgent?.sundriesAgent;

      const data = {
        operator: this.userInfo.userId,
        agentCode: draftDetails?.mkagAgent?.agentCode,
        productType: "MT",
        customerType: (draftDetails?.customerType || draftDetails?.occCustomerType) ?? null,
        branchCode: draftDetails?.mkagAgent?.branchCode ?? null,
        branchAgentList: null
      };
  
      this.store.dispatch(new GET_AGENT_DETAIL_ONLY(data)).subscribe(() => {
        if (hasAdditionalPage) {
          this.updateCallCenterAction(draftDetails);
        } else {
          this.updateAction(draftDetails);
        }
      });
    });
  }

  isCallCenterUser() {
    return this.userInfo?.callCentreType !== undefined;
  }

  ccentricAction(params) {
    if (params.opportunityId) {
      const param = {
        "opportunityId": params.opportunityId
      };

      this.store.dispatch(new GET_CCENTRIC_OPPORTUNITY_INFO(param)).subscribe((state) => {
          this.router.navigate(["/agent-selection"]);
      });
    }
  }

  updateAction(draftDetails) {

    let userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    //only for cr14 optional package
    if (draftDetails?.riskList?.[0]?.coverList?.[0]?.subCovPackage) {
      draftDetails = {
        ...draftDetails,
        riskList: [
          {
            ...draftDetails.riskList[0],
            coverList: [
              {
                ...draftDetails.riskList[0].coverList[0],
                coverDto: { extCovPackageSelected: draftDetails.riskList[0].coverList[0].subCovPackage }
              }
            ]
          }
        ]
      };
    }
    
    //this.store.dispatch(new GET_DRAFT_DETAILS(queryParams.contractId, "SUMMARY-DRAFT")).subscribe(state => {
      //let draftDetails = state.SatMotorState.draftDetails;
      if (draftDetails) {
        this.store.dispatch(new GET_PRODUCT_LIST({ lob: draftDetails.productType, agentCode: draftDetails.mkagAgent.agentCode })).subscribe((state) => {
          this.store.dispatch(new COPY_DRAFT_INFO({ ...draftDetails, hasAdditionalPage: false })).subscribe(state => {
            switch (draftDetails.progress) {
              case "2":
                this.ngZone.run(() =>
                  this.router.navigate(["/issuance/customer-partner"]),
              );
              break;
              
              case "3":
                this.ngZone.run(() =>
                  this.store.dispatch(new GET_VEHICLE_USE({ productCode: draftDetails.productCode })).subscribe((state) => {
                    this.store
                      .dispatch(
                        new SEARCH_MAKE_MODEL({
                          type: "",
                          classCode: state.SatMotorState.data.step3.coverage.vehicleUseLov.filter((v) => v.code === draftDetails.riskList[0].vehicleUse)[0].vehicleClass,
                          yearMnftr: draftDetails.riskList[0].vehicleMakeYear,
                        }),
                      )
                      .subscribe((state) => {
                        this.store.dispatch(new SELECTED_MAKE_MODEL(state.SatMotorState.data.step3.coverage.searchMakeModelList.find((mm) => mm.vehModelCodeNvic === draftDetails.riskList[0].vehModelNvic))).subscribe((state) => {
                          if (draftDetails.progress) {
                            this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: draftDetails }));
                            this.router.navigate(["/issuance/coverage"]);
                          }
                        });
                      });
                  }),
                );
                break;
              
              case "4":
                this.ngZone.run(() =>
                  this.router.navigate(["/issuance/summary"]),
              );
              break;
            }
          });
        });
      }
    //});

  }

  resetState() {
    this.store.dispatch(new RESET_SAT_MOTOR_STATE());
  }

  updateCallCenterAction(draftDetails) {

    let userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    
    //this.store.dispatch(new GET_DRAFT_DETAILS(queryParams.contractId, "SUMMARY")).subscribe(state => {
      //let draftDetails = state.SatMotorState.draftDetails;
      if (draftDetails) {
        this.store.dispatch(new GET_PRODUCT_LIST({ lob: draftDetails.productType, agentCode: draftDetails.mkagAgent.agentCode })).subscribe((state) => {
          this.store.dispatch(new COPY_DRAFT_INFO({ ...draftDetails, hasAdditionalPage: true })).subscribe(state => {
            switch (draftDetails.progress) {
              case "3":
                this.ngZone.run(() =>
                  this.router.navigate(["/issuance/customer-partner"]),
              );
              break;
              
              case "4":
                this.ngZone.run(() =>
                  this.store.dispatch(new GET_VEHICLE_USE({ productCode: draftDetails.productCode })).subscribe((state) => {
                    this.store
                      .dispatch(
                        new SEARCH_MAKE_MODEL({
                          type: "",
                          classCode: state.SatMotorState.data.step3.coverage.vehicleUseLov.filter((v) => v.code === draftDetails.riskList[0].vehicleUse)[0].vehicleClass,
                          yearMnftr: draftDetails.riskList[0].vehicleMakeYear,
                        }),
                      )
                      .subscribe((state) => {
                        this.store.dispatch(new SELECTED_MAKE_MODEL(state.SatMotorState.data.step3.coverage.searchMakeModelList.find((mm) => mm.vehModelCodeNvic === draftDetails.riskList[0].vehModelNvic))).subscribe((state) => {
                          if (draftDetails.progress) {
                            this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: draftDetails }));
                            this.router.navigate(["/issuance/coverage"]);
                          }
                        });
                      });
                  }),
                );
                break;
              
              case "5":
                this.ngZone.run(() =>
                  this.router.navigate(["/issuance/summary"]),
              );
              break;
            }
          });
        });
      }
    //});

  }
}
