import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Store, Select } from '@ngxs/store';

interface Driver {
  name: string;
  id: string;
  disabled: boolean;
}

@Component({
  selector: 'app-e-hailing-driver',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './e-hailing-driver.component.html',
  styleUrl: './e-hailing-driver.component.scss',
})

export class EHailingDriverComponent {
  @Select(SatMotorState.motorActionType) actionType$;

  @Input() parentForm: FormGroup;
  eHailingDriverNameForm: FormGroup;
  eHailingDriverFromState: any;
  isEndorsement: boolean = false;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {}

  ngOnInit() {

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;
      }
    });

    this.eHailingDriverFromState = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step3?.coverage?.extracover?.ehailingDriverDetails?.rows);
    
    this.eHailingDriverNameForm = this.fb.group({
      rows: this.fb.array([])
    });

    this.initFormRows();

    this.parentForm.controls["ehailingDriverDetails"] = this.eHailingDriverNameForm;
  }

  initFormRows() {
    for(let i = 0; i < 2; i++) {
    
      let row = this.fb.group({
        driverName: [this.eHailingDriverFromState?.[i]?.driverName ? this.eHailingDriverFromState[i].driverName : ''],
        driverId: [this.eHailingDriverFromState?.[i]?.driverId ? this.eHailingDriverFromState[i].driverId : '']
      });

      this.rows.push(row);
    }
  }

  onChangeForm() {
    this.parentForm.updateValueAndValidity();
  }

  get rows() {
    return this.eHailingDriverNameForm.get('rows') as FormArray;
  }
}
