import {
  Component,
  ElementRef,
  HostListener,
  EventEmitter, 
  Output,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from "@angular/core";
// import { CoverageComponent } from "../../../pages/coverage/coverage.component";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../../popup/endorsement-popup/endorsement-popup.component";
import { STORE_ENDORSEMENT } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { SharedModule } from "../../../../shared/shared.module";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { ExtraCoverageComponent } from "../../coverages/extra-coverage/extra-coverage.component";
import { CoverageComponent } from "../../../../pages/coverage/coverage.component";

import { SelectionModel } from '@angular/cdk/collections';
import { DriverNameComponent } from '@shared/components/driver-name/driver-name.component';
import { EHailingDriverComponent } from '@shared/components/e-hailing-driver/e-hailing-driver.component';
import { Observable } from "rxjs";
import { CoveragePopupComponent } from '@shared/components/modal/coverage-popup/extra-coverage-popup.component';
import { CALCULATE_PREMIUM, CALCULATE_PREMIUM_PAYLOAD, STORE_COVERAGE_TYPE, STORE_COVERAGE_VEHICLE_DETAILS, STORE_EXTRA_COVER_PACKAGE } from '@store/sat-motor/sat-motor.action';
import { UserResponse } from '@interfaces/user';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal/format-decimal.pipe';
import { RemoveCommaPipe } from '@shared/pipes/remove-comma/remove-comma.pipe';

@Component({
  selector: 'app-extra-cover-form',
  standalone: true,
  imports: [
    SharedModule,
    NxSmallStageModule,
    TextFieldModule,
    ExtraCoverageComponent,
    EHailingDriverComponent, //to duplicate and configure later
    DriverNameComponent, //to duplicate and configure later
  ],
  templateUrl: './extra-cover-form.component.html',
  styleUrl: './extra-cover-form.component.scss'
})
export class ExtraCoverFormComponent {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;

  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;

  @Input() isComprehensive = false;
  @Input() isThirdParty = false;
  @Input() isThirdPartyFireTheft = false;
  @Input() defaultDriver: any;

  @Input() endorsementType: any;

  extraCoverForm: FormGroup;
  extraCoverDetailForm: FormGroup;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CoveragePopupComponent>;
  selection: any;
  allComplete: boolean = true;
  subCovList: any;
  subCovPackageList: any;
  extraCoverDetails: any;
  packageDescription: any;
  userInfo: any;
  extraCoverList: any = [];
  checkPrivateHireCar = [];
  privateHireCarIsSelected: boolean = false;
  rahmahExtraCoverList = [];
  topExtraCoverList = [];
  defaultedExtraCoverList = [];
  fixedDefaultedExtraCoverList = [];
  allSelectedWithRahmahExtraCoverList = [];
  selectExtraCoverAppDrive = [];
  unnamedDriverExtraCoverList = [];
  coverDto: any;
  updateSelectedAndAllListToOPUS: any;
  subExtraCoverDropdownList: any;
  numberFmt: any;
  decimalFmt: any;
  isWaiver: boolean = false;
  isAllDriver: boolean = false;
  subCovPackageDesc: any;
  removeSumInsured = [
    "M01", "M02", "M03", "M07", "M08", "22", "22(a)", "24(c)", "24(d)", "25",
    "72", "100", "101", "101(a)", "102", "105", "108", "109", "111",
    "A200", "A201", "A202", "A202-1", "A202-2", "A203", "A203-1", "A203-2", "A203-3"
    , "A207", "PAB-OCR",
  ]
  alimEligibleInd: boolean = false;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;
  @ViewChild("autosize") autosize!: CdkTextareaAutosize;

  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  endorsement_details = true;

  // Dialog management
  openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
    this.templateDialogRef = this.dialogService.open(template, {
      height: config.height || '500px',
      width: config.width || '780px',
    });
  }

  closeDialog(): void {
    this.templateDialogRef?.close();
  }

  // For Coverage table
  show_comprehensive = true;
  show_tp = false;
  show_tpft = false;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) { 
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.extraCoverDetailForm = this.fb.group({
      checkedAll: [false],
      extraCoverPackage: [""],
      extraCoverPackageSummary: [""],
      driverNameDetails: new FormGroup({}),
      ehailingDriverDetails: new FormGroup({}),
      subCoverSumInsured: [""], // for sub sumInsured
      //subCoverSumInsured:["", [Validators.required]],
      dropdownSubCover: [""],// for sub dropdown
      extraCoverPackageDto: [""]
    });
  }

  ngOnInit() {
    // this.loadExtraCover();
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    // this.coverageFmGroup.controls["extraCoverDetails"] = this.extraCoverDetailForm;
    this.selection = new SelectionModel<any>(true, []);
    this.extraCoverList.forEach(element => {
      this.selection.select(element)
    });
    //this.populateExtraCoverage();

    this.extraCoverList = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.extraCoverPackage);
    this.extraCoverDetailForm.patchValue({
      dropdownSubCover: this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.dropdownSubCover)
    });

    this.isAllDriver = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.driverNameDetails.vehicleAlldrvrInd);
    this.isWaiver = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step3?.coverage?.extracover?.driverNameDetails.vehicleUnNamedDrvrInd);

  }

  ngAfterViewInit() {
    // this.coveragePremium$.subscribe((state) => {
    //   //if (this.typeOfCoverage) {
    //   let stCngeNote: any, stRiskList: any, stCoverList: any, stCoverDto: any;
    //   if (state) {
    //     if ((stCngeNote = state["cngeNote"]) && (stRiskList = stCngeNote["riskList"]) && stRiskList.length > 0) {
    //       if(stCngeNote?.alimEligibleInd){
    //         this.alimEligibleInd = stCngeNote?.alimEligibleInd;
    //       }
    //       //if ((stCngeNote = this.dummyCngeNote["cngeNote"]) && (stRiskList = stCngeNote["riskList"]) && stRiskList.length > 0) {
    //       if ((stCoverList = stRiskList[0]["coverList"]) && stCoverList.length > 0) {
    //         this.subCovPackageDesc = stCoverList[0].subCovPackageDesc;
    //         this.coverageFmGroup.patchValue({
    //           basicPremium: stCoverList[0].basicPremium,
    //         });
    //         if ((stCoverList[0]["coverDto"])) {
    //           this.coverDto = stCoverList[0]["coverDto"]; // temp use
    //           stCoverDto = stCoverList[0]["coverDto"];
    //           if (stCoverDto && stCoverDto["subCovList"]) {
    //             this.subCovList = stCoverDto["subCovList"];
    //             this.subCovList = this.subCovList.map(obj => {
    //               return { ...obj, subCoverAmtday: obj.subCoverAmtPerDay, subCoverDays: obj.subCoverNumberOfDays }
    //             })
    //             this.updateSelectedAndAllListToOPUS = this.subCovList;

    //           }
    //           if (stCoverDto && stCoverDto["subCovPackageList"]) {
    //             this.subCovPackageList = stCoverDto["subCovPackageList"][0];
    //           }

    //           if (this.subCovPackageList?.packageCode) {
    //             this.packageDescription = this.subCovPackageList.packageDescription;
    //             this.rahmahExtraCoverList = this.subCovList.filter(o1 => o1.top == true && o1.subCovPackage === this.subCovPackageList.packageCode &&
    //               o1.enabled == false && o1.subCoverPrem == 0);
    //           } else {
    //             this.packageDescription = "";
    //             this.rahmahExtraCoverList = [];
    //           }
    //         }
    //       }
    //     }
    //   }

    //   if (this.subCovList) {

    //     const packageCode = this.subCovPackageList?.packageCode;
    //     const validPackageCode = packageCode !== undefined ? packageCode : null;

    //     this.topExtraCoverList = this.subCovList.filter(o1 =>
    //       o1.top === true && o1.selected === true 
    //       //cater specific for motor RW & motor ERW
    //       && ((o1.subCoverCode === "PAB-RW" && o1.planCode != '')  || (o1.subCoverCode === "PAB-ERW" && o1.planCode != '') || o1.enabled === false )
    //       && o1.subCoverPrem == 0  && (validPackageCode === null || o1.subCovPackage !== validPackageCode));

    //     this.fixedDefaultedExtraCoverList = this.defaultedExtraCoverList = this.subCovList.filter(o1 => o1.top === true && o1.selected === false && (validPackageCode === null || o1.subCovPackage !== validPackageCode));
    //     this.extraCoverList = this.subCovList.filter(o1 => o1.selected === true && (validPackageCode === null || o1.subCovPackage !== validPackageCode));
    //     //filter topExtraCoverList from this.extraCoverList
    //     this.extraCoverList = this.extraCoverList.filter(o1 => !this.topExtraCoverList.some(o2 => o2.subCoverCode === o1.subCoverCode));
    //     //for all driver checkbox
    //     this.unnamedDriverExtraCoverList = this.subCovList.filter(o1 => o1.selected === true && o1.subCoverCode === "A200");

    //     this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
    //     this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList); //to save quotation
    //     this.extraCoverDetailForm.controls['extraCoverPackageDto'].setValue(this.updateSelectedAndAllListToOPUS);//send to ODM

    //     //to display in Summary
    //     let updateSumInsuredExtraCoverList = this.extraCoverList.map((obj) => {
    //       if (this.removeSumInsured.includes(obj.subCoverCode)) {
    //         return { ...obj, subCoverSumInsured: 0 };
    //       }
    //       return obj;
    //     });

    //     let toDisplayInSummary = {
    //       subCovPackageDesc: this.subCovPackageDesc,
    //       rahmahPackageList: this.rahmahExtraCoverList,
    //       topExtraCoverList: this.topExtraCoverList,
    //       selectedExtraCoverList: updateSumInsuredExtraCoverList
    //     }

    //     this.extraCoverDetailForm.controls['extraCoverPackageSummary'].setValue(toDisplayInSummary);
    //     this.privateHireCar();
    //   }
    // });

  }

  openPopupComp(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(CoveragePopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "800px",
      maxWidth: "800px",
      data: {
        popup_type: popup_type,
        selectedExtraCoverList: this.allSelectedWithRahmahExtraCoverList,
        originalExtraCoverList: this.subCovList,
        packageCode: this.subCovPackageList?.packageCode
      },
    });

    this.componentDialogRef.afterClosed().subscribe((result) => {
      console.log('result is : ' + result)
      if (result) {
        result.selection.forEach(objDialog => {
          const newObj = { ...objDialog, selected: true };
          this.extraCoverList.push(newObj);
          this.extraCoverList.forEach(element => {
            this.selection.select(element)
          });
          this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
            if (objDialog.subCoverCode === obj.subCoverCode) {
              return { ...obj, selected: !obj.selected };
            }
            return obj;
          });
        });

        this.privateHireCar();
        this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
        this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
        this.calculatePremium();
      }
    });
  }

  emitData(item: boolean, code: any, location: any) {
    if (location === 'topHeader') {
      // this.topExtraCoverList.forEach((obj, index) => {
      //   if (code == obj.subCoverCode) {
      //     this.topExtraCoverList.splice(index, 1);
      //     const newObj = { ...obj, selected: false };
      //     this.defaultedExtraCoverList.push(newObj);

      //     this.defaultedExtraCoverList.sort((a, b) => {
      //       return a.sequenceNumber - b.sequenceNumber;
      //     });
      //   }
      // });
      console.log("defaultedExtraCoverList : " + JSON.stringify(this.topExtraCoverList));
    }
    if (location === 'defaultedExtraCover') {
      this.defaultedExtraCoverList.forEach((obj, index) => {
        if (code == obj.subCoverCode) {
          this.defaultedExtraCoverList.splice(index, 1);
          const newObj = { ...obj, selected: true };
          this.extraCoverList.push(newObj);
          this.extraCoverList.sort((a, b) => {
            if (a.top !== b.top) {
              return a.top ? -1 : 1;  // 'true' (top) comes first
            }
            return a.sequenceNumber - b.sequenceNumber;
          });
        }
      });

      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (code === obj.subCoverCode) {
          return { ...obj, selected: !obj.selected };
        }
        return obj;
      });
    } else {
      this.extraCoverList.forEach((obj, index) => {
        if (code == obj.subCoverCode) {
          this.extraCoverList.splice(index, 1);
          if (obj.top == true) {
            const newObj = { ...obj, selected: false };
            this.defaultedExtraCoverList.push(newObj);
            // Ascending order defaultedExtraCoverList
            this.defaultedExtraCoverList.sort((a, b) => {
              return a.sequenceNumber - b.sequenceNumber;
            });

            this.extraCoverList.sort((a, b) => {
              if (a.top !== b.top) {
                return a.top ? -1 : 1;  // 'true' (top) comes first
              }
              return a.sequenceNumber - b.sequenceNumber;
            });
          }
        }
      });

      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (code === obj.subCoverCode) {
          if (!obj.selected == false) {
            return { ...obj, selected: !obj.selected, subCoverPrem: 0, mtExtCovDtoList: null };

          } else {
            return { ...obj, selected: !obj.selected };
          }
        }
        return obj;
      });
    }
    //this.privateHireCar();
    this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
    this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
    this.calculatePremium();
    console.log("this.extraCoverList" + this.extraCoverList);
    console.log("this.topExtraCoverList" + this.topExtraCoverList);
  }

  checkedAllChange() {
    if (this.extraCoverDetailForm.controls['checkedAll'].value) {
      this.defaultedExtraCoverList.forEach((obj) => {
        const newObj = { ...obj, selected: true };
        this.extraCoverList.push(newObj);
      });

      this.defaultedExtraCoverList = [];

      this.extraCoverList.sort((a, b) => {
        if (a.top !== b.top) {
          return a.top ? -1 : 1;
        }
        return a.sequenceNumber - b.sequenceNumber;
      });

      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj2) => {
        const matchingObj = this.extraCoverList.find(obj1 => obj1.subCoverCode === obj2.subCoverCode);
        if (matchingObj) {
          return { ...obj2, selected: true };
        }
        return obj2;
      });

    } else {
      //this.defaultedExtraCoverList = this.fixedDefaultedExtraCoverList
      this.extraCoverList = [];
      this.updateSelectedAndAllListToOPUS = [];
    }

    this.privateHireCar();
    this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
    this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList); //to save quotation
    this.calculatePremium();
  }

  onChangeSubCoverSumInsured(subCoverCodeValue: any, subCoverSumInsuredValue: any) {
    let formatSubCoverSumInsuredValue = subCoverSumInsuredValue.value ? subCoverSumInsuredValue.value : 0.00;
    if (formatSubCoverSumInsuredValue) {
      formatSubCoverSumInsuredValue = formatSubCoverSumInsuredValue.toString().replace(/,/g, "");


      this.extraCoverList = this.extraCoverList.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, subCoverSumInsured: formatSubCoverSumInsuredValue };
        }
        return obj;
      });

      this.extraCoverList.sort((a, b) => {
        if (a.top !== b.top) {
          return a.top ? -1 : 1;
        }
        return a.sequenceNumber - b.sequenceNumber;
      });


      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, subCoverSumInsured: formatSubCoverSumInsuredValue };
        }
        return obj;
      });
      this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
      this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
      this.calculatePremium();
    }

  }

  onChangeProduct(subCoverCodeValue: any, dropdownCode: any, dropdownDesc: any) {
    // Helper function to update and sort lists
    const updateAndSortLists = (property: string, value: any) => {
      this.extraCoverList = this.extraCoverList.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, [property]: value };
        }
        return obj;
      });

      //update to OPUS list
      this.updateSelectedAndAllListToOPUS = this.updateSelectedAndAllListToOPUS.map((obj) => {
        if (subCoverCodeValue === obj.subCoverCode) {
          return { ...obj, [property]: value };
        }
        return obj;
      });

      // Sorting logic
      this.extraCoverList.sort((a, b) => {
        if (a.top !== b.top) {
          return a.top ? -1 : 1;
        }
        return a.sequenceNumber - b.sequenceNumber;
      });

      // Combining lists
      this.allSelectedWithRahmahExtraCoverList = this.rahmahExtraCoverList.concat(this.topExtraCoverList.concat(this.extraCoverList));
      this.extraCoverDetailForm.controls['extraCoverPackage'].setValue(this.allSelectedWithRahmahExtraCoverList);  //to save quotation
    };

    // Determine the property to update based on the description
    if (dropdownDesc === 'PLAN / UNIT') {
      updateAndSortLists('planCode', dropdownCode);
    } else if (dropdownDesc === 'NO OF DAY') {
      updateAndSortLists('subCoverNumberOfDays', dropdownCode);
      updateAndSortLists('subCoverDays', dropdownCode);
    } else if (dropdownDesc === 'AMOUNT PER DAY') {
      updateAndSortLists('subCoverAmtPerDay', dropdownCode);
      updateAndSortLists('subCoverAmtday', dropdownCode);
    }

    // Calculate premium once after all changes
    this.calculatePremium();
  }

  privateHireCar() {
    this.checkPrivateHireCar = this.extraCoverList.find(top => top.subCoverCode === 'A202');
    if (this.checkPrivateHireCar) {
      this.privateHireCarIsSelected = true;
    } else {
      this.privateHireCarIsSelected = false;
    }
  }

  calculatePremium() {
    // let vehicleDetails = this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue());
    // let typeOfCoverage = this.populateTypeOfCoverageData({
    //   ...this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType),
    //   ...this.coverageFmGroup.controls["typeOfCoverage"].getRawValue()
    // });

    // let driverNameList = this.populateDriverNameData()

    // this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(vehicleDetails)).subscribe((state) => {
    //   this.store.dispatch(new STORE_COVERAGE_TYPE(typeOfCoverage)).subscribe((state) => {
    //     this.store.dispatch(new CALCULATE_PREMIUM_PAYLOAD({
    //       coverage: {
    //         alimEligibleInd: this.alimEligibleInd,
    //         vehicleDetails: vehicleDetails,
    //         typeOfCoverage: typeOfCoverage,
    //         extracover: this.allSelectedWithRahmahExtraCoverList,
    //         cngeRiskDrvrDtoList: driverNameList,
    //         coverDto: {
    //           ...this.coverDto, // Spread the existing coverDto
    //           subCovList: [
    //             ...this.updateSelectedAndAllListToOPUS // Add updatedExtraCoverList to subCovList
    //           ]
    //         },
    //         vehicleUnNamedDrvrInd: this.isWaiver,
    //         vehicleAlldrvrInd: this.isAllDriver
    //       },
    //       operator: this.userInfo.userId,
    //     }),
    //     ).subscribe((state) => {
    //       this.store.dispatch(new CALCULATE_PREMIUM(state.SatMotorState.data.step3.coverage.coveragePremiumPayload));
    //     });
    //   });
    // });
  }
  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  loadExtraCover() {
    this.extraCoverForm = new FormGroup({
      vehicleNo: new FormControl('', [
        Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{1,20}$')
      ]),
      policyNo: new FormControl(''),
      productCode: new FormControl(''),
      policyEffectiveDate: new FormControl(''),
      policyExpiryDate: new FormControl(''),
      endorsementType: new FormControl(''), //Validators.required
      endorsementEffectiveDate: new FormControl(''),
      endorsementExpiryDate: new FormControl(''),
    });
    this.loadEndorsementDetailsData();
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state) {
        if (state.extraCover) {
          this.extraCoverForm.patchValue(state.extraCover);
        }
      }
    });
  }


  comprehensive(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {
      height: "500px",
      width: "780px",
      maxWidth: "780px",
    });
  }

  tp(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  tpft(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;

  openPopup(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopup3(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      data: { popup_type: popup_type },
    });
  }


  // Validation //to be replaced because now using formbuilder in constructor

  //////////////////////////////////////////////////////
  planunitFormControl = new FormControl('', [
    Validators.required,
  ]);

  amountdayFormControl = new FormControl('', [
    Validators.required,
  ]);

  amountFormControl = new FormControl('', [
    Validators.required,
  ]);


  storeEndorsementDetailsData() {
    if (this.extraCoverForm.valid) {
      let extraCover = this.extraCoverForm.value;
      console.log('Saving Extra Coverage Data:', extraCover);
      let payload = {
        extraCover: extraCover,
        isExtraCover: true
      }

      this.store.dispatch(new STORE_ENDORSEMENT(payload));
    } else {
      console.error('Extra Coverage form is invalid!');
      // this.logFormErrors(this.extraCoverForm);
      this.extraCoverForm.markAllAsTouched();
    }
  }

}
