import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NX_MODAL_DATA, NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-remarks',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './remarks.component.html',
  styleUrl: './remarks.component.scss'
})
export class RemarksComponent{
  title: any;
  primaryButtonLabel:any;

  remarksForm: FormGroup = this.fb.group({
    remarks:['']
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: NxModalRef<any>,
    private readonly dialogService: NxDialogService,
    private store: Store,
    @Inject(NX_MODAL_DATA) data: any,
  ) {
    this.title = data.title;
    this.primaryButtonLabel = data.primaryButtonLabel;
  }

  closeRemarksModal() {
    this.dialogRef.close();
  }

  onRemarks() {
    if (this.remarksForm.valid) {
      this.dialogRef.close(this.remarksForm.getRawValue());
    }
  }

}
