import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { ConsentIndicatorComponent } from '../consent-indicator/consent-indicator.component';

@Component({
  selector: 'app-pdpa',
  standalone: true,
  imports: [SharedModule, ConsentIndicatorComponent],
  templateUrl: './pdpa.component.html',
  styleUrl: './pdpa.component.scss'
})
export class PdpaComponent implements OnChanges {
  @Input() pdpaForm!: FormGroup;
  @Input() isRTP: boolean = false;
  @Input() isEndorsement: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pdpaForm']) {
      //console.log('pdpaForm has changed:', changes['pdpaForm'].currentValue);
    }
  } 

  get consentDetails(): FormGroup {
    return this.pdpaForm.get('consentDetails') as FormGroup;
  }
}
