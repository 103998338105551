<div nxLayout="grid" class="grid_remove_padding coverage">
    <div nxRow>
        <div nxCol="12">
            <h4 class="nx-margin-0">Extra Coverage</h4>
        </div>
    </div>
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12" class="nx-font-weight-semibold">
            These coverage(s) / service(s) are provided at no extra
            cost.
        </div>
    </div>
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12">
            <table class="extra_package">
                <tbody>
                    <tr nxTableRow>
                        <td>
                            <nx-checkbox [disabled]="true" [checked]="true" labelSize="small">
                                <div class="nx-font-weight-bold">
                                    RAHMAH PACKAGE
                                </div>
                            </nx-checkbox>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td class="sub_cover">
                            - COMPASSIONATE FLOOD COVER
                        </td>
                        <td class="si">SUM INSURED (RM) : 3,000.00</td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td class="sub_cover">
                            - DEATH/PERMANENT DISABLEMENT
                        </td>
                        <td class="si">SUM INSURED (RM) : 5,000.00</td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td class="sub_cover">- HOSPITAL INCOME</td>
                        <td class="si">RM 50.00 PER DAY / MAX 60 DAYS</td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td>
                            <nx-checkbox [disabled]="true" [checked]="true">
                                <div class="nx-font-weight-bold">
                                    24-HOUR EMERGENCY TOWING (UP TO 150KM)
                                </div>
                            </nx-checkbox>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <div class="nx-margin-top-2m">
                <app-external-scrollbar targetSyncScroll="3" [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3 syncScroll="3">
                    <table nxTable class="more_extra_cover">
                        <tbody>
                            <tr nxTableRow>
                                <th nxHeaderCell>
                                    <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                        <div class="nx-font-weight-bold">
                                            Extra Cover
                                        </div>
                                    </nx-checkbox>
                                </th>
                                <th nxHeaderCell></th>
                                <th nxHeaderCell>Premium (RM)</th>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        MOTOR ROAD WARRIOR
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell>
                                    <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">PLAN / UNIT :
                                        </span>
                                        <nx-formfield class="formfield_no_padding">
                                            <nx-dropdown [panelGrow]="true" value="1 UNIT">
                                                <nx-dropdown-item value="1 UNIT"
                                                    class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="2 UNIT"
                                                    class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="3 UNIT"
                                                    class="table_dropdown"></nx-dropdown-item>
                                            </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>Plan required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">20.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                                        DRIVERS
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">20.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        COVER FOR WINDSCREENS, WINDOWS AND SUNROOF
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    SUM INSURED (RM) : 500.00
                                </td>
                                <td nxTableCell class="std-ta-right">60.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        INCLUSION OF SPECIAL PERILS
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">80.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        MOTOR ENHANCED ROAD WARRIOR
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">PLAN / UNIT :
                                        </span>
                                        <nx-formfield class="formfield_no_padding">
                                            <nx-dropdown [panelGrow]="true" value="PLAN A">
                                                <nx-dropdown-item value="PLAN A"
                                                    class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="PLAN B"
                                                    class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="PLAN C"
                                                    class="table_dropdown"></nx-dropdown-item>
                                            </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>Plan required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">75.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        OPTIONAL CAR REPLACEMENT
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">75.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        WAIVER OF BETTERMENT CONTRIBUTION
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">230.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        KEY CARE
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small" class="color_disabled">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                            <input nxInput value="1,000.00" disabled class="color_disabled" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote>
                                            <strong>Amount required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">35.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        DRIVER AND PASSENGERS SHIELD
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">PLAN / UNIT :
                                        </span>
                                        <nx-formfield class="formfield_no_padding test">
                                            <nx-dropdown [panelGrow]="true" value="PLAN A">
                                                <nx-dropdown-item value="PLAN A"
                                                    class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="PLAN B"
                                                    class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="PLAN C"
                                                    class="table_dropdown"></nx-dropdown-item>
                                            </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>Plan required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">30.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td>
                                    <div class="sub_cover">
                                        <ul nxList>
                                            <li nxListIcon="minus" size="s">
                                                <p nxCopytext="medium">
                                                    DEATH/PERMANENT DISABLEMENT OCCURRING
                                                    WITHIN 180 DAYS OF THE ACCIDENT
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="std-ta-right">
                                    <p nxCopytext="medium">
                                        SUM INSURED (RM) : 25,000 PER PERSON
                                    </p>
                                </td>
                                <td class="std-ta-right"></td>
                            </tr>
                            <tr nxTableRow>
                                <td>
                                    <div class="sub_cover sub_line_cover">
                                        <ul nxList>
                                            <li nxListIcon="minus" size="s">
                                                <p nxCopytext="medium">
                                                    HOSPITAL INCOME
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="std-ta-right">
                                    RM 50.00 PER DAY / MAX 60 DAYS
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td class="sub_line">
                                    <div class="sub_cover sub_line_cover">
                                        <ul nxList>
                                            <li nxListIcon="minus" size="s">
                                                <p nxCopytext="medium">
                                                    FUNERAL EXPENSES
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="std-ta-right sub_line">
                                    RM 1,000.00
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        COMPENSATION FOR ASSESSED REPAIR TIME (CART)
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">NO. OF DAYS :</span>
                                        <nx-formfield class="formfield_no_padding test">
                                            <nx-formfield class="formfield_no_padding test">
                                                <nx-dropdown [panelGrow]="true" [panelMaxWidth]="500" value="7">
                                                    <nx-dropdown-item value="0"
                                                        class="table_dropdown"></nx-dropdown-item>
                                                    <nx-dropdown-item value="21"
                                                        class="table_dropdown"></nx-dropdown-item>
                                                    <nx-dropdown-item value="14"
                                                        class="table_dropdown"></nx-dropdown-item>
                                                    <nx-dropdown-item value="7"
                                                        class="table_dropdown"></nx-dropdown-item>
                                                </nx-dropdown>
                                            </nx-formfield>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>No. of days required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                    <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">AMOUNT PER DAY:</span>
                                        <nx-formfield class="formfield_no_padding test">
                                            <nx-dropdown [panelGrow]="true" value="50">
                                                <nx-dropdown-item value="0" class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="200" class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="100" class="table_dropdown"></nx-dropdown-item>
                                                <nx-dropdown-item value="50" class="table_dropdown"></nx-dropdown-item>
                                            </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>Amount per day required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">75.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        GAS CONVERSION KIT AND TANK
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                            <input nxInput value="100.00" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>Amount required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">7.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        SEPARATE COVER FOR ACCESSORIES FIXED TO YOUR
                                        CAR/ MOTORCYCLE
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                            <input nxInput value="100.00" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>Amount required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">30.00</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        CAR BREAK-IN/ROBBERY
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small" class="color_disabled">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                            <input nxInput value="500.00" disabled class="color_disabled" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                            <strong>Amount required.</strong>
                                        </nx-error>
                                    </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">3.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12">
            <button nxPlainButton type="button" class="nx-margin-right-m" (click)="comprehensive()">
                <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                ADD MORE
            </button>
            <ng-template #template>
                <div class="left-content">
                    <h3 nxHeadline="subsection-medium" class="nx-margin-0">
                        Exta Cover
                    </h3>
                    <div class="scroll-container nx-margin-top-2m">
                        <table nxTable zebra>
                            <thead>
                                <tr nxTableRow>
                                    <th nxHeaderCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">Extra Cover</span>
                                        </nx-checkbox>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">PEER-TO-PEER (P2P)</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">EXTENSION OF COVER TO THE KINGDOM OF
                                                THAILAND</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">PRIVATE HIRE CAR</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">LEGAL LIABILITY OF PASSENGERS FOR
                                                NEGLIGENT ACTS</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">DRIVER AND PASSENGERS SHIELD</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">CAR BREAK-IN/ROBBERY</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
                        <div nxRow>
                            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
                                <nx-natural-language-form size="small" class="pagination_form">
                                    <span nxCopytext="normal">Show</span>
                                    <nx-word>
                                        <nx-dropdown value="5" [panelGrow]="true" [panelMaxWidth]="500">
                                            <nx-dropdown-item value="5">5</nx-dropdown-item>
                                            <nx-dropdown-item value="10">10</nx-dropdown-item>
                                            <nx-dropdown-item value="20">20</nx-dropdown-item>
                                            <nx-dropdown-item value="30">30</nx-dropdown-item>
                                            <nx-dropdown-item value="40">40</nx-dropdown-item>
                                            <nx-dropdown-item value="50">50</nx-dropdown-item>
                                        </nx-dropdown>
                                    </nx-word>
                                    <span nxCopytext="normal">results per page</span>
                                </nx-natural-language-form>
                            </div>

                            <div
                                class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                            </div>
                            <div nxCol="12,12,6">
                                <nx-pagination [count]="20" [page]="2" [perPage]="2" type="advanced"
                                    ariaLabel="Advanced example pagination" class="pagination_position">
                                </nx-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="two-button-container nx-margin-top-m">
                        <button nxButton="secondary" (click)="closeTemplateDialog()"
                            class="nx-margin-right-s nx-margin-top-m">
                            Cancel
                        </button>
                        <button nxButton (click)="closeTemplateDialog()" class="nx-margin-top-m">
                            Proceed
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>