import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserResponse } from 'src/app/interfaces/user';
import { SIGN_OUT } from 'src/app/store/user/user.action';
import { PROMPT_ERROR } from 'src/app/store/exception/error.action';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

  errorCodesList2 = [400, 500, 503, '400', '500', '503'];
  constructor(private store: Store) { }

  intercept(
    request: HttpRequest<any>, next: HttpHandler
  ): Observable<any> {
    const authReq = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });

    return next.handle(authReq).pipe(
      catchError(err => {
        const user = this.store.selectSnapshot<Partial<UserResponse>>(state => state.UserState.user);
        if (err.status == '401' || err.status == '403') {
          this.store.dispatch(new SIGN_OUT());
        }
        if (this.errorCodesList2.includes(err.status)) {
          console.warn(err)
          this.store.dispatch(new PROMPT_ERROR(err.error));
        }
        return of(err);
      })
    );
  }
}
