import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EnquiryComponent } from '../enquiry.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { GET_ENQUIRY_DETAILS } from '@store/enquiry/enquiry.action';
import moment from 'moment';

@Component({
  selector: 'app-view-endorsement',
  standalone: true,
  imports: [
    EnquiryComponent,
    SharedModule
  ],
  templateUrl: './view-endorsement.component.html',
  styleUrl: './view-endorsement.component.scss'
})
export class ViewEndorsementComponent implements OnInit {
  
  jpjIsmForm: FormGroup;
  
  enquiryDetail: any;
  type: any;
  popupMessage: any;
  referralReasonList: any;
  status: any;
  coverNoteText: any;
  agentData: any;
  progressType: any;
  issuanceInfo: any;
  cpData: any;
  premiumInfo: any;
  claimList: any;
  endorsementHistoryList: any;
  checkListItems: any;
  checklistNote: any;
  checklistRemark: any;
  remarksInfo: any;

  @ViewChild("templateEnHistory") templateRef5!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  
  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {

    this.status = 'active';
    this.type = 'view-endorsement-enquiry';
    this.popupMessage = 'Your e-Cover Note has been submitted as pending approval.';
    this.referralReasonList = [
      { code: "CC005", desc: "Renew Sum Insured / Refer" },
      { code: "CD011", desc: "High Performance" },
      { code: "CD018", desc: "Status Expired" }
    ];

    this.store.dispatch(new GET_ENQUIRY_DETAILS(null));
    this.enquiryDetail = this.agentData = this.store.selectSnapshot<any>(state => state.EnquiryState.enquiryDetail);
    console.log("enquiryDetail: "+JSON.stringify(this.enquiryDetail, null, 2));

    this.coverNoteText = this.enquiryDetail.cnoteNo;

    //Agent Information
    if(this.enquiryDetail && this.enquiryDetail.mkagAgent){
      this.agentData = this.enquiryDetail.mkagAgent;
      this.agentData.issuedBy = this.enquiryDetail.createBy;
      this.agentData.issuedDate = this.getFormattedDate(this.enquiryDetail.cnoteDate);
    }

    //Product Information
    if(this.enquiryDetail) {
      this.progressType = 'cn';
      const { productName, vehicleNo, cnoteType, cnoteNo, 
        effectiveDate, expiryDate, proposalNo, createDate, edocConsentInd, einvoiceConsentInd 
      } = this.enquiryDetail;

      this.issuanceInfo = this.issuanceInfo || {};
      this.issuanceInfo.issuance = { 
        productName: productName,
        vehicleNo: vehicleNo,
        coverNoteType: cnoteType,
        prevPolicyNo: cnoteNo,
        effectiveDate: this.getFormattedDate(effectiveDate),
        expiryDate: this.getFormattedDate(expiryDate),
        equotationNo: proposalNo,
        equotationCreatedDate: this.getFormattedDate(createDate),
        eCoverNoteNo: cnoteNo
      };

      this.issuanceInfo.pdpa = { 
        edocConsent: edocConsentInd,
        einvoiceConsentInd: einvoiceConsentInd
      };
    }

    //customer Partner
    if(this.enquiryDetail && this.enquiryDetail.clientPartner){
      this.cpData = this.enquiryDetail.clientPartner;
    }


    //Premium Information
    if(this.enquiryDetail){
      this.premiumInfo = this.enquiryDetail;
    }

    //Claim Information
    this.claimList = [
      {
        claimNo: 'A0002',
        claimType: 'COVER FOR WINDSCREENS, WINDOWS AND SUNROOF',
        claimLossDate: '18/08/2024',
        claimCode: 'CLAIM 02'
      }
    ];
    
    //Endorsement History
    this.endorsementHistoryList = [
      {
        "endorsementRefNo": "PGE00069327",
        "issuedDate": "05/03/2024",
        "endorsementEffectiveDate": "05/03/2024",
        "endorsementType": "REVISION OF SUM INSURED",
        "referralReasons": "ENDT-30 REVISE SUM INSURED",
        "status": "Pending Approval",
        "issuedBy": "ALPHA.UAT.55",
        "approveDeclineBy": "ALPHA.UAT.55",
        "approveDeclineDate": "05/03/2024",
        "remarks": "History"
      },
      {
        "endorsementRefNo": "PGE00069328",
        "issuedDate": "06/03/2024",
        "endorsementEffectiveDate": "06/03/2024",
        "endorsementType": "ADDITION OF COVERAGE",
        "referralReasons": "ENDT-31 ADD COVERAGE",
        "status": "Approved",
        "issuedBy": "BETA.UAT.56",
        "approveDeclineBy": "BETA.UAT.56",
        "approveDeclineDate": "06/03/2024",
        "remarks": "Added coverage for new asset"
      },
      {
        "endorsementRefNo": "PGE00069329",
        "issuedDate": "07/03/2024",
        "endorsementEffectiveDate": "07/03/2024",
        "endorsementType": "CANCELLATION",
        "referralReasons": "ENDT-32 CANCEL POLICY",
        "status": "Declined",
        "issuedBy": "GAMMA.UAT.57",
        "approveDeclineBy": "GAMMA.UAT.57",
        "approveDeclineDate": "07/03/2024",
        "remarks": "Cancellation request declined"
      }
    ];
    
    //CheckList
    this.checkListItems = [
      "Insured or Agent Authorised letter / form / email (with contact number)",
      "Vehicle inspection done",
      "Requested by Bank / Finance owner"
    ];
    this.checklistNote = 'Warranted no claim as at todate';
    this.checklistRemark = null;

    //Remarks
    this.remarksInfo = {
      referral: '',
      admin: this.getAdminRemarks(this.enquiryDetail.referAdminRemarkList),
      approval: '',
      updatedBy: this.enquiryDetail.createBy + ' at ' + this.getFormattedDate(this.enquiryDetail.createDate)
    }

    this.jpjIsmForm = this.fb.group({
      jpjUpdated: [false],
      ismUpdated: [false]
    });

  }
  getAdminRemarks(referAdminRemarkList: any) {
    let list = [];
    referAdminRemarkList.forEach((remark, index) => {
        let combinedString = `${index + 1}. ${remark.createdBy} ${this.getFormattedDateTime(remark.createDate)}: ${remark.referAction}`;
        list.push(combinedString);
    });
    return list;
  }


  getFormattedDate(dateVal) {
    if (!dateVal) {
      return dateVal;
    }

    return moment(dateVal, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  getFormattedDateTime(dateString: string): string {
    if(!dateString){
      return dateString;
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  }

  enRefNo(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef5, {});
  }
  
  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }
}
