<div class="blue_panel nx-margin-top-xs" *ngIf="isThirdPartyFireTheft">
  <nx-expansion-panel expanded="[true]">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title>
        <div nxLayout="grid" class="coverage">
          <div nxRow>
            <div nxCol="12,12,8" class="m_padding_bottom_10">
              <p nxCopytext="small">{{ 'COVERAGE.FIELD.COVERAGE_DESCRIPTION' | translate }}</p>
              <h2 class="nx-margin-0">{{ typeOfCoverage.coverDescription | titlecase }}</h2>
            </div>
            <div nxCol="12,12,4" class="container_flex_center align_right">
              <div class="gross">
                <p nxCopytext="small">{{ 'COVERAGE.FIELD.GROSS_PREMIUM' | translate }}</p>
                <p nxCopytext="large">{{ typeOfCoverage.grossPremium | number }}</p>
              </div>
              <div class="nx-margin-left-s">
                <nx-icon name="trash-o" size="s" class="gross_trash" (click)="deleteCover(typeOfCoverage)"></nx-icon>
              </div>
            </div>
          </div>
        </div>
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <div nxLayout="grid" class="coverage">

      @if (isMotorcycle()) {
      <div nxRow>
        <div nxCol="12">
          <p nxCopytext="normal" class="nx-margin-y-m">
            <i>Note: Please select Sum Insured within recommended Sum Insured range to avoid underinsurance.</i>
          </p>
        </div>
      </div>
      } 
      @else {
      <div nxRow>
        <div nxCol="12">
          <p nxCopytext="normal" class="nx-margin-y-m"></p>
        </div>
      </div>
      }
	  
      <div [formGroup]="thirdPartyFireTheftForm">
        <div nxRow>
          <div nxCol="12,12,6" class="agreed_value">
            <div nxLayout="grid" class="grid_remove_padding">
              <div nxRow rowAlignItems="center,center">
                <div nxCol="12,12,12,12,6">
                  <nx-formfield label="{{ 'COVERAGE.FIELD.SUM_INSURED_RM' | translate }}">
                    <input nxInput formControlName="sumInsured" (blur)="onChangeForm($event)" appCusCurrencyFormat />
                    <nx-error nxFormfieldError><strong>Please note:</strong> this field is required!</nx-error>
                  </nx-formfield>
                </div>
               <div nxCol="12,12,12,12,6">
                 <nx-checkbox class="nx-margin-bottom-xs" formControlName="avInd" [checked]="avIndChecked"> {{ 'COVERAGE.FIELD.RECOMMENDED_VALUE' | translate }} </nx-checkbox>
               </div>
              </div>
            </div>
            <div class="nx-hidden-xs nx-hidden-s nx-hidden-m nx-margin-bottom-m">
              @if (!sumInsuredRange && avSi) {
              <p nxCopytext="small" class="nx-margin-bottom-m">
                <i>{{ 'COVERAGE.FIELD.RECOMMENDED_SI_RANGE' | translate: { makeModelSIMin: avSi[agentDetail.region].avSiMinDesc, makeModelSIMax: avSi[agentDetail.region].avSiMaxDesc } }}</i>
              </p>
              }
              @if (underInsurenceApplies) {
              <nx-error nxFormfieldNote>
                <strong>Underinsurance applies</strong>
              </nx-error>
              }
             <!--  
             <button nxPlainButton (click)="openMessage('betterment')">Vehicle betterment popup</button>
             <br />
             <button nxPlainButton (click)="openMessage('duplication')">Duplication of Coverage popup</button>
             <br />
             <button nxPlainButton (click)="openMessage('blocked')">Application blocked popup</button>
             <br />
             -->
            </div>
          </div>
          <div nxCol="12,12,6" class="m_padding_top_20">
            <nx-formfield label="{{ 'COVERAGE.FIELD.RISK_EXCESS_AMOUNT' | translate }}">
              <input nxInput formControlName="excessAmount" appCusCurrencyFormat />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.BASIC_PREMIUM' | translate }}">
              <input nxInput formControlName="basicPremium" appCusCurrencyFormat />
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="{{ 'COVERAGE.FIELD.ANNUAL_PREMIUM' | translate }}">
              <input nxInput formControlName="annualPremium" appCusCurrencyFormat />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD' | translate }}">
              <input nxInput formControlName="ncdPercentage" />
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_AMOUNT' | translate }}">
              <input nxInput formControlName="ncdAmount" />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_FROM' | translate }}">
              <nx-dropdown formControlName="prevNcdInsurer">
                <nx-dropdown-item *ngFor="let item of lov.insurerList" value="{{item.code}}">
                  {{ item.descp | uppercase }}
                </nx-dropdown-item>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield  label="{{ 'COVERAGE.FIELD.NCD_VEHICLE_NO' | translate }}">
              <input nxInput formControlName="prevNcdVeh" (blur)="onChangeForm($event)" appToUpperCase/>
                @if (prevNcdVeh.errors && prevNcdVeh.errors["invalid"]) {
                    <nx-error nxFormfieldError>
                        <strong>{{ 'COVERAGE.ERROR.VEHICLE_NO.INVALID' | translate }}</strong>
                    </nx-error>
                }
            </nx-formfield>
          </div>
        </div>
      </div>

      <div nxRow>
        <div nxCol="12">
          <button nxButton="secondary small" type="button" class="btn_small_full_width"[disabled]="isDisabledGetNCD" (click)="onClickGetNCD()">GET NO CLAIM DISCOUNT</button>
        </div>
      </div>

      <div nxRow>
        <div nxCol="12">
          <hr class="nx-margin-y-2m" />
        </div>
      </div>
      @if(isShowExtraCoverage){
          <app-extra-coverage [coverageFmGroup]="coverageFmGroup" [isThirdPartyFireTheft]="isThirdPartyFireTheft" [defaultDriver]="defaultDriver"></app-extra-coverage>
      }
      <app-premium-summary [isThirdParty]="false"></app-premium-summary>

    </div>
  </nx-expansion-panel>
</div>
