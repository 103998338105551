import { Component, OnInit } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { SummaryPopupComponent } from "../../../../popup/summary-popup/summary-popup.component";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CALCULATE_PREMIUM_PAYLOAD,
  CALCULATE_PREMIUM_SUMMARY,
  DOWNLOAD_PDF,
  GENERATE_QUOTATION,
  GET_DOCUMENT_LOV,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  SEND_TO_JPJ,
  SET_CURRENT_STEP,
  SET_JPJ_STATUS_SENT,
  STORE_ISSUANCE_SUMMARY
} from "@store/sat-motor/sat-motor.action";
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { SharedModule } from '@shared/shared.module';
import { OneAllianzMessageComponent } from '@shared/components/modal/one-allianz-message/one-allianz-message.component';
import { PaymentDetailsComponent } from '@shared/components/payment-details/payment-details.component';
import { UpsellProductComponent } from '@shared/components/upsell-product/upsell-product.component';
import { UploadDocumentComponent } from "@shared/components/upload-document/upload-document.component";
import { UserResponse } from "@interfaces/user";
import { UploadDocument } from "@interfaces/fileupload";
import { CoverageSummaryComponent } from '@shared/components/coverages/coverage-summary/coverage-summary.component';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import moment from 'moment';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { ScrollToError } from '@shared/classes/scroll-to-error';
import { EndorsementNarrationComponent } from "@shared/components/endorsement-narration/endorsement-narration.component";
import { PaymentComponent } from "@shared/components/payment/payment.component";

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    ProgressBarComponent,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxRadioModule,
    SharedModule,
    PaymentDetailsComponent,
    UpsellProductComponent,
    CoverageSummaryComponent,
    OkMessageComponent,
    EndorsementNarrationComponent,
    PaymentComponent,
  ],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent implements OnInit {

  @Select(SatMotorState.motorActionType) actionType$;

  isIssuance: boolean = false;
  isEndorsement: boolean = false;
  isEnquiry: boolean = false;
  isRTP: boolean = false;

  progressType: any;
  userInfo: any;
  agentData: any;
  issuanceInfo: any = {};
  clientClaimHistory: any;
  vehicleClaimHistory: any;
  rtpPolicy: any;
  premiumInfo: any;
  coverageInfo: any;
  cpData: any;
  lov: any;
  isProgressTypeCN: boolean = false;
  isProgressTypeENDST: boolean = false;
  isProgressTypeRTP: boolean = false;

  showAgentInfo: boolean = false;
  showIssuanceInfo: boolean = false;
  showCustomerPartner: boolean = false;
  showReferralDetails: boolean = false;
  showCoverage: boolean = false;
  showHistoricalClaim: boolean = false;
  showPremiumInformation: boolean = false;
  showReferCase: boolean = false;
  showPayment: boolean = false;
  showUpsellProduct: boolean = false;
  showPaymentInformation: boolean = false;
  showPaymentDetails: boolean = false;
  showChecklist: boolean = false;
  showRenewalAssistant: boolean = false;
  showEndorsementNarration: boolean = false;
  showRoadTaxPayable: boolean = false;
  showEndorsementErrMessage: boolean = false;
  showEditButton: boolean = true;

  referralForm: FormGroup;
  referCaseForm: FormGroup;
  previousPolicyDetails: any;
  requoteQuotation: any;
  cnoteType: any;

  dialogRef?: NxModalRef<any>;
  okMessageModal?: NxModalRef<OkMessageComponent>;

  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;

  alimCustomerEligible: boolean;
  hasShowedOneAllianzPopUp: boolean;
  oneAllianzModal?: NxModalRef<OneAllianzMessageComponent>;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.referralForm = this.fb.group({
      referralId: ['']
    });

    this.referCaseForm = this.fb.group({
      waiveLoadInd: [{ value: false, disabled: true }],
      waiveExcessInd: [{ value: false, disabled: true }],
      underwritingInd: [false],
      remarks: ['']
    });
  }

  renewalAssistanceForm = new FormGroup({
    renewalAssistant: new FormControl(false),
    paymentMode: new FormControl(''),
    creditCardNo: new FormControl(''),
    creditCardExpiryDateMonth: new FormControl(''),
    creditCardExpiryDateYear: new FormControl(''),
    nameOnCard: new FormControl(''),
    termofUse: new FormControl(false),
    renewalAssistanceStartDate: new FormControl('')
  });

  monthList = [
    { code: '01', name: '01' },
    { code: '02', name: '02' },
    { code: '03', name: '03' },
    { code: '04', name: '04' },
    { code: '05', name: '05' },
    { code: '06', name: '06' },
    { code: '07', name: '07' },
    { code: '08', name: '08' },
    { code: '09', name: '09' },
    { code: '10', name: '10' },
    { code: '11', name: '11' },
    { code: '12', name: '12' },
  ];

  yearList = [
    { code: '2024', name: '2024' },
    { code: '2025', name: '2025' },
    { code: '2026', name: '2026' },
    { code: '2027', name: '2027' }
  ];

  paymentModeList = [
    { code: 'MASTER', name: 'MASTER' },
    { code: 'VISA', name: 'VISA' }
  ];

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });

    this.store.dispatch(new SET_CURRENT_STEP(4));
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.previousPolicyDetails = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.previousPolicyDetails);
    this.cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1?.issuance?.issuance?.cnoteType);

    this.requoteQuotation = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.requoteQuotation);

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);

    this.showComponents();
    this.setProgressType();

    if (this.isIssuance) {
      this.retrieveIssuanceData();
    }

    if (this.isRTP) {
      this.retrieveRTPData();
    }

    if (this.isEndorsement) {
      this.retrieveEndorsementData();
    }

    if (this.previousPolicyDetails || this.requoteQuotation) {
      this.premiumInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
      this.premiumInfo = {
        ...this.premiumInfo,
        stamp: this.previousPolicyDetails.stamp,
        premiumDueRounded: this.previousPolicyDetails.premiumDueRounded
      };
    }

    this.saveSummaryStep();
  }

  retrieveIssuanceData() {
    let step4Summary = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step4?.summary);
    const cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step3?.coverage?.coveragePremium?.cngeNote);
    this.alimCustomerEligible = this.store.selectSnapshot<boolean>((state) => state.SatMotorState.motor?.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0].alimCustomerEligible);
    this.hasShowedOneAllianzPopUp = this.store.selectSnapshot<boolean>(state => state.SatMotorState.data?.hasShowedOneAllianzPopUp);
    const activeQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step1?.activeQuotation ?? null);
    const previousPolicyDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.previousPolicyDetails ?? null);
    let oldData = null;

    if (activeQuotation || previousPolicyDetails) {
      oldData = activeQuotation ? activeQuotation.cngeNote : previousPolicyDetails;
    }

    if (this.alimCustomerEligible && !this.hasShowedOneAllianzPopUp) {
      this.openPopupOneAllianz();
    }

    //Agent Information
    const agentDetail = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.agentDetail);
    this.agentData = {
      ...agentDetail,
      issuedDate: cngeNote?.cnoteDate,
      issuedBy: cngeNote?.issueBy
    };

    //Issuance Information
    if (oldData) {
      this.issuanceInfo = this.getIssuanceData(oldData);
    } else {
      this.issuanceInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.issuance);
    }

    //Customer Partner
    this.cpData = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step2);

    //Coverage
    this.coverageInfo = this.store.selectSnapshot(SatMotorState.motorStep3Coverage);

    //Historical claims
    this.clientClaimHistory = cngeNote?.claimCnt ?? '0';
    this.vehicleClaimHistory = cngeNote?.vehClaimCount ?? '0';

    //Premium Information
    this.loadPremiumSummaryData();

    //ReferralId and Remarks populate
    this.populateReferralRemarks(step4Summary);

    //Refer Case
    this.updateRemarksValidator();
    this.subscribeToCheckboxChanges();

    this.store.dispatch(new GET_DOCUMENT_LOV({
      lob: "MT",
      userId: this.userInfo.userId,
      productCode: this.issuanceInfo.issuance.productCode,
      productType: ""
    }));
  }

  getIssuanceData(cngeNote: any): any {
    let risk = cngeNote.riskList[0];

    const issuanceInfo = {
      issuance: {
        productCode: cngeNote.productCode,
        vehicleNo: cngeNote.vehicleNo ?? risk.vehicleNo,
        cnoteType: cngeNote.cnoteType,
        prevPolicyNo: cngeNote.previousPolicyNo,
        effectiveDate: cngeNote.effectiveDate,
        expiryDate: cngeNote.expiryDate,
        equotationNo: cngeNote.proposalNo,
        equotationCreatedDate: cngeNote.createDate,
        eCoverNoteNo: cngeNote.cnoteNo,
        jpjStatus: risk.jpjStatus,
        jpjMessage: cngeNote.jpjMessage,
      },
      pdpa: {
        consentDetails: {
          edocConsentInd: cngeNote.edocConsentInd,
          einvoiceConsentInd: cngeNote.einvoiceConsentInd,
        },
      },
    };

    return issuanceInfo;
  }

  populateReferralRemarks(data) {
    if (data) {
      if (data.referralId !== null && data.referralId !== undefined && data.referralId !== '') {
        this.referralForm.patchValue({
          referralId: data.referralId
        });
      }

      if (data.referCaseData) {
        const referCaseData = data.referCaseData;

        const formValue: any = {
          waiveLoadInd: referCaseData.waiveLoadInd,
          waiveExcessInd: referCaseData.waiveExcessInd,
          underwritingInd: referCaseData.underwritingInd
        };

        if (referCaseData.remarks !== null && referCaseData.remarks !== undefined && referCaseData.remarks !== '') {
          formValue.remarks = referCaseData.remarks;
        }

        this.referCaseForm.patchValue(formValue);
      }
    }
  }

  retrieveEndorsementData() {
    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    const endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);

    let step3Summary = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails);
    // const cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step3?.coverage?.coveragePremium?.cngeNote);
    // this.alimCustomerInd = this.store.selectSnapshot<string>((state) => state.SatMotorState?.motor?.step2?.alimCustomerIndicator) === "YES";
    this.hasShowedOneAllianzPopUp = this.store.selectSnapshot<boolean>(state => state.SatMotorState.data?.hasShowedOneAllianzPopUp);
    const activeQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step1?.activeQuotation ?? null);
    const previousPolicyDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.previousPolicyDetails ?? null);

    //Agent Information
    const agentDetail = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.agentDetail);
    console.log('agentDetail:' + agentDetail); //undefined
    this.agentData = {
      ...agentDetail,
      agentCode: endtVehiclePolicyData?.mkagAgent?.agentCode,
      name: endtVehiclePolicyData?.mkagAgent?.name,
      issuedDate: endtVehiclePolicyData?.cnoteDate,
      issuedBy: endtVehiclePolicyData?.issueBy
    };

    //Issuance Information
    this.issuanceInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.issuance);

    //Customer Partner

    let eInvoiceCustomerPartner = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step2.endorsementDetails.eInvoiceCustomerPartner);

    if (eInvoiceCustomerPartner) {
      this.cpData = eInvoiceCustomerPartner;

    } else if (!eInvoiceCustomerPartner && endtVehiclePolicyData) {
      let customerPartnerData = endtVehiclePolicyData?.clientPartner;
      this.cpData = {
        ...customerPartnerData,
        // fixLine: endtVehiclePolicyData?.clientPartner?.fixLine, //not found
        // faxNumber: endtVehiclePolicyData?.clientPartner?.faxNumber, //not found
        email: endtVehiclePolicyData?.clientPartner?.clientEmail,
      };
    }

    //Coverage
    let endorsementDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step2.endorsementDetails); //need to check
    console.log('summary endorsementDetails:', endorsementDetails);

    if (!this.coverageInfo) {
      this.getCoverageFromPolicy(endtVehiclePolicyData);
    }

    let coverageInfoTemp = this.coverageInfo;

    if (endorsementDetails.vehicleDetails) {
      this.coverageInfo = {
        ...coverageInfoTemp,
        vehicleDetails: endorsementDetails.vehicleDetails,
      };
    } else if (endorsementDetails.extraCover) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        extraCover: endorsementDetails.extraCover,
      };
    } else if (endorsementDetails.vehicleSumInsured) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        vehicleSumInsured: endorsementDetails.vehicleSumInsured,
      };
    } else if (endorsementDetails.ncd) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        ncd: endorsementDetails.ncd,
      };
    } else if (endorsementDetails.namedDriver) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        namedDriver: endorsementDetails.namedDriver,
      };
    }

    console.log('summary this.coverageInfo:', this.coverageInfo);

    //Historical claims
    this.clientClaimHistory = endtPreviousVehiclePolicyData?.claimCnt ?? '0';
    this.vehicleClaimHistory = endtPreviousVehiclePolicyData?.vehClaimCount ?? '0'; //no data for now

    //Premium Information
    // this.loadPremiumSummaryData();
    this.premiumInfo = endtVehiclePolicyData?.riskList[0]?.coverList[0];
    this.premiumInfo = {
      ...this.premiumInfo,
      stamp: endtVehiclePolicyData.stamp,
      premiumDueRounded: endtVehiclePolicyData.premiumDueRounded
    };

    //ReferralId and Remarks populate
    this.populateReferralRemarks(step3Summary);

    //Refer Case
    this.updateRemarksValidator();
    this.subscribeToCheckboxChanges();

    this.store.dispatch(new GET_DOCUMENT_LOV({
      lob: "MT",
      userId: this.userInfo.userId,
      productCode: this.issuanceInfo.issuance.productCode,
      productType: ""
    }));
  }

  retrieveRTPData() {
    this.rtpPolicy = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto);
    let vehicleNo = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.issuance?.issuance?.vehicleNo);

    if (this.rtpPolicy) {
      this.agentData = { ...this.rtpPolicy.mkagAgent, issuedDate: this.rtpPolicy.cnoteDate, issuedBy: this.rtpPolicy.issueBy };

      //Declaration issuance
      let issuanceData = {
        productCode: this.rtpPolicy.productCode,
        vehicleNo: this.rtpPolicy.vehicleNo ?? vehicleNo,
        coverNoteType: 'ROAD TAX PURPOSE',
        policyNo: this.rtpPolicy.policyNo,
        effectiveDate: this.rtpPolicy.effectiveDate,
        expiryDate: this.rtpPolicy.expiryDate,
        eCoverNoteNo: this.rtpPolicy.cnoteNo,
      }

      this.issuanceInfo = {
        issuance: issuanceData
      }

      //Customer Partner
      this.cpData = this.rtpPolicy.clientPartner;


      //Coverage
      if (!this.coverageInfo) {
        this.getCoverageFromPolicy(this.rtpPolicy);
      }

      //Premium Calculation
      this.premiumInfo = this.rtpPolicy?.riskList[0]?.coverList[0];
      this.premiumInfo = {
        ...this.premiumInfo,
        stamp: this.rtpPolicy.stamp,
        premiumDueRounded: this.rtpPolicy.premiumDueRounded
      };
    }

    this.clientClaimHistory = this.rtpPolicy.claimCnt ?? '0';
    this.vehicleClaimHistory = this.rtpPolicy.vehClaimCount ?? '0';
  }

  loadPremiumSummaryData() {
    let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote);
    const premiumInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null);

    if (premiumInfo && cngeNote) {
      this.premiumInfo = {
        ...premiumInfo,
        stamp: cngeNote.stamp,
        premiumDueRounded: cngeNote.premiumDueRounded
      };
      this.updateReferState();
    }
  }

  getCoverageFromPolicy(policy) {
    let risk = policy.riskList[0];
    let coverList = risk.coverList[0];

    const coverageInfo = {
      vehicleDetails: risk,
      coverageDetails: coverList,
      driverNameDetails: this.getDriverListForRTPAndEndt(risk.cngeRiskDrvrDtoList, true),
      ehailingDriverDetails: this.getDriverListForRTPAndEndt(risk.cngeRiskDrvrDtoList, false),
      extraCoverPackageSummary: this.getExtraCoverage(coverList),
    };

    this.coverageInfo = coverageInfo;
  }

  getExtraCoverage(coverList: any) {
    if (!coverList || !coverList.subCoverList) {
      return {
        subCovPackageDesc: null,
        rahmahPackageList: [],
        topExtraCoverList: [],
        selectedExtraCoverList: [],
      };
    }

    const extraCoverPackage = coverList.subCoverList.map(cv => ({
      ...cv,
      description: cv.subCoverCodeDescription,
      selected: cv.selectedInd,
      enabled: cv.enableInd ?? false,
    }));

    return {
      subCovPackageDesc: coverList.subCovPackageDesc ?? null,
      rahmahPackageList: extraCoverPackage.filter(o1 => o1.subCovPackage && !o1.enabled && !o1.subCoverPrem),
      topExtraCoverList: extraCoverPackage.filter(o1 => o1.selected && !o1.enabled && !o1.subCoverPrem && !o1.subCovPackage),
      selectedExtraCoverList: extraCoverPackage.filter(o1 => o1.selected && o1.subCoverPrem > 0),
    };
  }

  getDriverListForRTPAndEndt(cngeRiskDrvrDtoList, isNormalDriver) {
    return cngeRiskDrvrDtoList?.filter(({ cngeRiskDrvrPkDto: { driverType } }) =>
      isNormalDriver ? driverType === 'NORM' : driverType === 'EHAIL'
    ).map(({ cngeRiskDrvrPkDto: { driverName }, driverId, driverAge }) => ({
      driverName,
      driverId,
      driverAge,
    })) || null;
  }

  getDriverListData(cngeRiskDrvrDtoList: any) {
    if (cngeRiskDrvrDtoList) {
      return cngeRiskDrvrDtoList.map(item => {
        return {
          driverName: item.cngeRiskDrvrPkDto.driverName,
          driverId: item.driverId,
          driverAge: item.driverAge
        };
      });
    }
    return null;
  }

  setProgressType() {
    if (this.isIssuance) {
      this.progressType = 'cn';
    }

    if (this.isRTP) {
      this.progressType = 'rtp';
    }

    if (this.isEndorsement) {
      this.progressType = 'endorsement';
    }
  }

  saveSummaryStep() {

    let summaryData = {
      agentData: this.agentData,
      issuanceInfo: this.issuanceInfo,
      customerPartnerDetails: this.cpData,
      referralId: this.referralForm?.get('referralId')?.value ?? null,
      coverageInfo: this.coverageInfo,
      clientClaimHistory: this.clientClaimHistory,
      vehicleClaimHistory: this.vehicleClaimHistory,
      premiumInfo: this.premiumInfo,
      referCaseData: this.referCaseForm.getRawValue()
    };

    console.log('Summary Data:', summaryData);
    this.store.dispatch(new STORE_ISSUANCE_SUMMARY(summaryData));

  }

  onBlurReferralId() {
    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
    }
    if (this.referralForm.valid) {
      this.saveSummaryStep();
    }
  }

  updateReferState() {
    this.toggleReferControlState('waiveLoadInd', this.premiumInfo.loadAmount > 0);
    this.toggleReferControlState('waiveExcessInd', this.premiumInfo.excessAmount > 0);
  }

  toggleReferControlState(controlName: string, condition: boolean) {
    const control = this.referCaseForm.get(controlName);
    condition ? control.enable() : control.disable();
  }

  updateRemarksValidator() {
    const waiveLoadInd = this.referCaseForm.get('waiveLoadInd').value;
    const waiveExcessInd = this.referCaseForm.get('waiveExcessInd').value;
    const underwritingInd = this.referCaseForm.get('underwritingInd').value;

    if (waiveLoadInd || waiveExcessInd || underwritingInd) {
      this.referCaseForm.get('remarks').setValidators([Validators.required]);
    } else {
      this.referCaseForm.get('remarks').clearValidators();
    }
    this.referCaseForm.get('remarks').updateValueAndValidity();
  }

  subscribeToCheckboxChanges() {
    this.referCaseForm.get('waiveLoadInd').valueChanges.subscribe(() => this.updateRemarksValidator());
    this.referCaseForm.get('waiveExcessInd').valueChanges.subscribe(() => this.updateRemarksValidator());
    this.referCaseForm.get('underwritingInd').valueChanges.subscribe(() => this.updateRemarksValidator());
  }

  showComponents() {
    if (this.isIssuance) {
      this.showAgentInfo = this.showIssuanceInfo = this.showCustomerPartner =
        this.showReferralDetails = this.showCoverage = this.showHistoricalClaim =
        this.showPremiumInformation = this.showReferCase = true;
    }

    if (this.isRTP) {
      this.showEditButton = false;
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      // this.showIssuanceInfo = this.showCustomerPartner =
      //   this.showReferralDetails = this.showCoverage = this.showHistoricalClaim =
      //   this.showPremiumInformation = this.showReferCase = true;
    }

    if (this.isEndorsement) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.showChecklist = true; //endorsement only
      this.showEndorsementNarration = true; //endorsement only
      this.showPayment = true; //endorsement only
    }
  }

  componentDialogRef?: NxModalRef<SummaryPopupComponent>;

  openPopup(popup_type: any) {

    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopupOCC(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      height: '500px',
      data: { popup_type: popup_type },
    });
  }

  goToIssueDeclaration() {
    this.saveSummaryStep();

    this.router.navigate(['issuance/declaration-issuance-info'], {
      queryParamsHandling: 'preserve',
    });

  }

  goToCPScreen() {
    this.saveSummaryStep();

    this.router.navigate(['issuance/customer-partner'], {
      queryParamsHandling: 'preserve',
    });
  }

  goToCoverageScreen() {
    this.saveSummaryStep();

    this.router.navigate(['issuance/coverage'], {
      queryParamsHandling: 'preserve',
    });
  }

  openPopupOneAllianz() {
    this.oneAllianzModal = this.dialogService.open(OneAllianzMessageComponent, {
      showCloseIcon: false,
      width: "400px",
      maxWidth: "400px",
    });
  }

  validateReferral() {
    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }

    return this.referralForm.valid;
  }

  validateReferCase() {
    const underwritingIndControl = this.referCaseForm.get('underwritingInd');
    const remarksControl = this.referCaseForm.get('remarks');

    if (underwritingIndControl?.value) {
      remarksControl?.setValidators([Validators.required]);
    } else {
      remarksControl?.clearValidators();
    }
    remarksControl?.updateValueAndValidity();

    if (this.referCaseForm.invalid) {
      console.log("referCaseForm invalid!");
      this.referCaseForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }

    return this.referCaseForm.valid;
  }

  scrollToFirstInvalidControl(form: FormGroup) {
    const firstInvalidControl: HTMLElement = document.querySelector('form .ng-invalid');
    if (firstInvalidControl) {
      firstInvalidControl.focus();
      firstInvalidControl.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }

  validateRenewalAssistance() {
    if (this.renewalAssistanceForm.invalid) {
      console.log("renewalAssistanceForm invalid!");
      this.renewalAssistanceForm.markAllAsTouched();
    } else {
      console.log("renewalAssistanceForm valid...");
    }

    return this.renewalAssistanceForm.valid;
  }

  generate() {

    let cnoteType = "";
    
    if (this.isEndorsement) {
      cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.generateQuotation?.cnoteStatus);
    } else {
      cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.cnoteStatus);
    }

    let isFormValid = this.validateFormFields();
    const isCnoteStatusPrinted = cnoteType === "PRINTED";

    if (!isFormValid) {
      console.log("Form Validation Failed.");
      return;
    }

    let payload = this.getPayloadData();

    let resetPayload = {};

    if (this.isRTP) {
      resetPayload = {
        isRTP: this.isRTP
      };
    } else if (this.isEndorsement) {
      resetPayload = {
        isEndorsement: this.isEndorsement
      };
    }

    if (!isCnoteStatusPrinted) {
      this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    }
    this.store.dispatch(new GENERATE_QUOTATION(payload, this.cnoteType)).subscribe(() => { //need to check for endorsement
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation ?? null);
      console.log(response);

      if (response && response.data && response.data.contractId) {
        let contractId = response?.data?.contractId;

        if (response?.data?.cnoteStatus === 'PRINTED') {
          this.downloadPDF(contractId, 'quotation');
        }

        this.router.navigate(['issuance/complete'], {
          queryParamsHandling: 'preserve',
        });

      } else {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: response.message
          }
        });
      }
    });
  }

  getPayloadData() {
    let payload = {
      userId: this.userInfo.userId,
      waiveLoadInd: this.referCaseForm.get('waiveLoadInd').value,
      waiveExcessInd: this.referCaseForm.get('waiveExcessInd').value,
      underwritingInd: this.referCaseForm.get('underwritingInd').value,
      agentReferId: this.referralForm.get('referralId').value,
      referRemarkList: null,
      isEndorsement: this.isEndorsement,
    };

    const referRemarkValue = this.referCaseForm.get('remarks').value;
    if (referRemarkValue) {
      payload = {
        ...payload,
        referRemarkList: [
          {
            referRemark: referRemarkValue,
            createdBy: this.userInfo.userId
          }
        ]
      };
    } else {
      delete payload.referRemarkList;
    }

    this.saveSummaryStep();
    return payload;
  }

  validateFormFields() {
    let isValidReferral = this.validateReferral();
    let isValidRemark = this.validateReferCase();
    //isValid = this.validateRenewalAssistance();

    return this.isRTP ? true : (isValidReferral && isValidRemark);
  }

  sendToJPJ() {
    let Address1 = this.cpData.clientPartnerAddress.clientAddress1;
    console.log("address", Address1);
    if (Address1 == "") {
      this.openPopup("address");
    }
    else {

      let isFormValid = this.validateFormFields();

      if (!isFormValid) {
        console.log("Form Validation Failed.");
        return;
      }

      let resetPayload = {};

      if (this.isRTP) {
        resetPayload = {
          isRTP: this.isRTP
        };
      } else if (this.isEndorsement) {
        resetPayload = {
          isEndorsement: this.isEndorsement
        };
      }

      let payload = this.getPayloadData() as any;
      payload = {
        ...payload,
        isRTP: this.isRTP,
        isEndorsement: this.isEndorsement,
        isRenewal: this.cnoteType
      };

      this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
      this.store.dispatch(new SEND_TO_JPJ(payload)).subscribe(() => {
        let response;
        if (this.isRTP) {
          response = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step2?.sendToJPJ ?? null);
          if (response && response.data && response.data.cngeNoteDto.contractId) {
            let contractId = response?.data?.cngeNoteDto?.contractId;

            this.router.navigate(['issuance/complete'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.showResponseError(response);
          }
        } else if (this.isEndorsement) {
          response = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.sendToJPJ ?? null);
          console.log('SEND_TO_JPJ response:' + response); //null

          if (response && response.data && response.data.cngeNoteDto) {
            let contractId = response?.data?.cngeNoteDto?.contractId; //not found in response

            this.router.navigate(['endorsement/complete'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.showResponseError(response);
          }
        } else {
          response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);
          if (response && response.data && response.success) {
            let contractId = response?.data?.contractId;
            if (contractId && response?.data?.cnoteStatus === 'PRINTED') {
              this.downloadPDF(contractId, 'covernote');
            }

            this.store.dispatch(new SET_JPJ_STATUS_SENT(true));

            this.router.navigate(['issuance/complete'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.showResponseError(response);
          }
        }
      });
    }
  }

  showResponseError(response: any) {

    console.log('showResponseError response:' + response);

    let message = (response.message && response.message != '') ? response.message : (response.errorCode && response.errorCode != '') ? response.errorCode : null;

    console.log('showResponseError message:' + message);

    if (message) {
      this.okMessageModal = this.dialogService.open(OkMessageComponent, {
        width: '400px',
        data: {
          message: message
        }
      });
    }
  }

  downloadPDF(contractId, type) {
    let payload = {
      operator: this.userInfo.userId,
      contractId: contractId,
      type: type
    };

    this.store.dispatch(new DOWNLOAD_PDF(payload)).subscribe(() => {
      this.router.navigate(['issuance/complete'], {
        queryParamsHandling: 'preserve',
      });
    });

  }

  onChangeRenewalAssistanceForm(fieldName: any, value: any) {
    console.log('onChangeValue..' + value.checked);
    if (fieldName == 'renewalAssistant') {
      //this.renewalAssistanceForm.controls.paymentMode.reset();
      this.renewalAssistanceForm.controls.paymentMode.clearValidators();
      this.renewalAssistanceForm.controls.creditCardNo.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.clearValidators();
      this.renewalAssistanceForm.controls.nameOnCard.clearValidators();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.clearValidators();
      this.renewalAssistanceForm.controls.termofUse.clearValidators();
      if (value == true) {
        this.renewalAssistanceForm.controls.paymentMode.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.setValidators([Validators.required]);
      } else {
        this.renewalAssistanceForm.controls.paymentMode.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.removeValidators([Validators.required]);
      }
      this.renewalAssistanceForm.controls.paymentMode.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardNo.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.updateValueAndValidity();
      this.renewalAssistanceForm.controls.nameOnCard.updateValueAndValidity();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.updateValueAndValidity();
      this.renewalAssistanceForm.controls.termofUse.updateValueAndValidity();
    }
  }

  uploadDocument() {

    let dialogConfig = {
      disableClose: true,
      maxWidth: '100%',
      maxHeight: '100vh',
      height: '96%',
      width: '90%',
      panelClass: 'uploadDocumentDialogCss',
      data: {
        products: [],
        arePreviousDocumentsCopied: false
      }
    };

    let products: UploadDocument.Data = {
      products: []
    };

    [{ cnoteStatus: 'SAVED', code: '080100', descp: 'MOTOR' }].forEach(prod => {
      if (prod.cnoteStatus !== 'CANCELLED' && prod.cnoteStatus !== 'P.CANCEL' && prod.cnoteStatus !== 'SUBMITTED') {
        const data: UploadDocument.ProductData = {
          productCode: prod.code,
          productName: prod.descp,
          contractId: prod["contractId"]
        }
        products.products.push(data);
      }
    });
    dialogConfig.data.products = products.products;

    this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  goBack() {
    if (this.isRTP) {
      this.router.navigate(['issuance/declaration-issuance-info']);
    }

    if (this.isIssuance) {
      this.router.navigate(['issuance/coverage']);
    }

    if (this.isEndorsement) {
      this.router.navigate(['endorsement/endorsement-details']);
    }
  }

}


