import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TypeOfCoverageComponent } from "./type-of-coverage/type-of-coverage.component";
import { VehicleDetailsComponent } from "@shared/components/vehicle-details/vehicle-details.component";
import { SharedModule } from "@shared/shared.module";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { GET_VEHICLE_USE, SAVE_AS_DRAFT, STORE_COVERAGE_TYPE, STORE_COVERAGE_VEHICLE_DETAILS, INIT_STEP3, STORE_EXTRA_COVER_DETAILS, SET_CURRENT_STEP, GET_SWITCH_SITE, GET_COVER_LIST_LOV, CALCULATE_PREMIUM_PAYLOAD, CALCULATE_PREMIUM, CANCEL_DRAFT } from "@store/sat-motor/sat-motor.action";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { Select, Store } from "@ngxs/store";
import { UserResponse } from "@interfaces/user";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { ScrollToError } from '@shared/classes/scroll-to-error';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Observable } from "rxjs";
import { ConfirmationModalComponent } from "@shared/components/modal/confirmation-modal/confirmation-modal.component";

@Component({
  selector: "app-coverage",
  standalone: true,
  imports: [SharedModule, TypeOfCoverageComponent, VehicleDetailsComponent],
  templateUrl: "./coverage.component.html",
  styleUrl: "./coverage.component.scss",
})
export class CoverageComponent implements OnInit {
  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;

  coverageFmGroup: any;
  alimCustomerInd: boolean;

  userInfo: UserResponse;
  issuanceInfo: any;
  agentDetail: any;
  partnerInfo: any;
  coverList: any;
  vehicleUselov: any;
  isSaved: any;

  decimalFmt: any;
  numberFmt: any;

  subCovList: any;
  subCovPackageList: any;
  extraCoverDetails: any;
  packageDescription: any;
  extraCoverList: any = [];
  rahmahExtraCoverList = [];
  topExtraCoverList = [];
  defaultedExtraCoverList = [];
  fixedDefaultedExtraCoverList = [];
  allSelectedWithRahmahExtraCoverList = [];
  unnamedDriverExtraCoverList = [];
  coverDto: any;
  updateSelectedAndAllListToOPUS: any;
  isWaiver: boolean = false;
  isAllDriver: boolean = false;
  subCovPackageDesc: any;

  infoMessageModal?: NxModalRef<SimpleModalComponent>;
  confirmMessageModal?: NxModalRef<ConfirmationModalComponent>;

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.coverageFmGroup = this.fb.group({
      extraCoverDetails: new FormGroup({}),
      vehicleDetails: new FormGroup({}),
      typeOfCoverage: new FormGroup({}),
    });
  }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    
    this.store.dispatch(new SET_CURRENT_STEP(3));
    this.isSaved = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step4?.generateQuotation);
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    this.agentDetail = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.motor?.step1?.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.motor?.step1?.issuance?.issuance);
    this.partnerInfo = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.motor?.step2);
    this.alimCustomerInd = this.store.selectSnapshot<string>((state) => state.SatMotorState.motor?.step2?.alimCustomerIndicator) === "YES";
    
    if (this.store.selectSnapshot<any>((state) => state.SatMotorState?.actionType) === "ISSUE")
      if (!this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState?.motor?.step3))
        this.store.dispatch(new INIT_STEP3({}));
      
      let productAccessConfigList;
      if (productAccessConfigList = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov?.productAccessConfigList)) {
        let coverListLov = [];
        productAccessConfigList.forEach((product) => {
          for (let code in product) {
          if (product.hasOwnProperty(code) && code === this.issuanceInfo?.productCode) {
            coverListLov = product[code].coverList;
          }
        }
      });     
       
      this.store.dispatch(new GET_COVER_LIST_LOV({coverListLov: coverListLov})).subscribe((state) => {
        this.coverList = state.SatMotorState.data.step3.coverage.coverListLov;
        this.store.dispatch(new GET_VEHICLE_USE({ productCode: this.issuanceInfo.productCode })).subscribe((state) => {
          this.vehicleUselov = state.SatMotorState.data.step3.coverage.vehicleUseLov;
        });
      });
    }
    
  }

  goBack() {
    this.router.navigate(["issuance/customer-partner"]);
  }

  validateVehicleDetails() {
    if (!this.coverageFmGroup.controls["vehicleDetails"].valid) {
      this.coverageFmGroup.controls["vehicleDetails"].markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      return false;
    }
    return true;
  }

  validateTypeOfCoverage() {
    if (!this.coverageFmGroup.controls["typeOfCoverage"].valid) {
      this.coverageFmGroup.controls["typeOfCoverage"].markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      return false;
    }
    return true;
  }

  validateExtraCoverDetails() {
    if (!this.coverageFmGroup.controls["extraCoverDetails"].valid) {
      this.coverageFmGroup.controls["extraCoverDetails"].markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      return false;
    }
    return true;
  }

  validateDriverNameDetails() {
    const driverNameDetails = this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()?.driverNameDetails?.rows;
    if (driverNameDetails) {
      let driver = driverNameDetails.map((d) => { return d.driverId + d.driverName; });
      if (new Set(driver).size !== driver.length) {
        this.dialogService.open(SimpleModalComponent, {
          showCloseIcon: false, width: "400px", maxWidth: "400px",
          data: { type: 'WARNING', message: "Duplicate driver name" },
        });
        return false;
      }
    }
    return true;
  }

  validateEhailingDriver() {
    const extraCoverPackage = this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()?.extraCoverPackage;
    if (extraCoverPackage) {
      let privateHireCar = extraCoverPackage.find(ex => ex.subCoverCode === 'A202' && ex.selected == true)
      if (privateHireCar) {
        const ehailingDriverDetails = this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()?.ehailingDriverDetails?.rows;
        if (ehailingDriverDetails) {
          let driver = ehailingDriverDetails.map((d) => { return d.driverId + d.driverName; });
          if (!driver.filter(Boolean).length) {
            this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "400px", maxWidth: "400px",
              data: { type: 'WARNING', message: "Minimum 1 e-Hailing driver is required." },
            });
            return false;
          }
        }
      }
    }
    return true;
  }

  isNext(coveragePremium) {
    if (coveragePremium)
      return true;
    return false;
  }

  next() {

    if (!this.validateVehicleDetails()) return;
    if (!this.validateTypeOfCoverage()) return;
    if (!this.validateExtraCoverDetails()) return;
    if (!this.validateDriverNameDetails()) return;
    if (!this.validateEhailingDriver()) return;

    this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue()))).subscribe((state) => {
      this.store.dispatch(new STORE_COVERAGE_TYPE(this.populateTypeOfCoverageData(this.coverageFmGroup.controls["typeOfCoverage"].getRawValue()))).subscribe((state) => {
        this.store.dispatch(new STORE_EXTRA_COVER_DETAILS(this.populateExtraCoverageData(this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()))).subscribe((state) => {
          this.router.navigate(["issuance/summary"]);
        });
      });
    });
  }

  saveAsDraft() {
    this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue()))).subscribe((state) => {
      this.store.dispatch(new STORE_COVERAGE_TYPE(this.populateTypeOfCoverageData(this.coverageFmGroup.controls["typeOfCoverage"].getRawValue()))).subscribe((state) => {
        this.store.dispatch(new STORE_EXTRA_COVER_DETAILS(this.populateExtraCoverageData(this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()))).subscribe((state) => {
          this.store.dispatch(new SAVE_AS_DRAFT({ userId: this.userInfo.userId })).subscribe((state) => {
            if (state) {
              this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
                showCloseIcon: false, width: "630px",
                data: { 
                  type: "e-Quotation Saved", 
                  message: "Your e-Quotation has been saved as a Draft. Here is your Draft Reference No. " + state.SatMotorState.motor.step3.draftNo + ". You may return to this e-Quotation through the Draft Summary Screen.",
                  btnOkLabel: "BACK TO SUMMARY LISTING", 
                },
              });
              this.infoMessageModal.afterClosed().subscribe((result) => {
                this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                  window.location.href = state.SatMotorState.data.switchSiteUrl;
                });       	      
              });
            }
          });
        });
      });
    });
  }

  cancelDraft() {

    this.confirmMessageModal = this.dialogService.open(ConfirmationModalComponent, {
      showCloseIcon: false, width: "500px", maxWidth: "500px",
      data: { message: "Did you want to cancel the Draft?" },
    });
  
    this.confirmMessageModal.afterClosed().subscribe(result => {
      if (result) {
        const data = {
          operator: this.userInfo.userId,
          cngeNote: this.isSaved
        };
        this.store.dispatch(new CANCEL_DRAFT(data)).subscribe(state => {
          if (state) {
            this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "500px", maxWidth: "500px",
              data: { message: "Cancel Draft Success" },
            })
            .afterClosed().subscribe((result) => {
              this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                window.location.href = state.SatMotorState.data.switchSiteUrl;
              });       	      
            });
          }
        });
      }
    });

  }

  calculatePremium(data: any) {
    let vehicleDetails = this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue());
    let typeOfCoverage = this.populateTypeOfCoverageData({ ...data, ...this.coverageFmGroup.controls["typeOfCoverage"].getRawValue() });
    this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(vehicleDetails)).subscribe((state) => {
      this.store.dispatch(new STORE_COVERAGE_TYPE(typeOfCoverage)).subscribe((state) => {
        this.store.dispatch(new CALCULATE_PREMIUM_PAYLOAD({
          coverage: { vehicleDetails: vehicleDetails, typeOfCoverage: typeOfCoverage, vehicleAlldrvrInd: ["080200", "080300"].includes(this.issuanceInfo.productCode) },
          operator: this.userInfo.userId,
        })).subscribe(state => { 
          this.store.dispatch(new CALCULATE_PREMIUM(state.SatMotorState.data.step3.coverage.coveragePremiumPayload))
        });
      });
    });
  }
  
  populateVehicleDetailsData(data) {
    let selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3?.coverage?.selectedMakeModel);
    let makeModelPrintInd = data.makeModelPrintInd ? "Y" : "N";
    let vehicleDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.vehicleDetails);
    return {
      ...vehicleDetails,
      ...data, ...selectedMakeModel,
      vehicleCapacity: this.numberFmt.transform(data.vehicleCapacity),
      makeModelPrintInd: makeModelPrintInd,
      recommendDesc: data.makeModelDesc + " " + data.variantDesc,
      importStatus: selectedMakeModel?.importStatus,
      vehModelNvic: selectedMakeModel?.vehModelCodeNvic,
      vehicleModel: selectedMakeModel?.vehModelCodeNvic,
      nvicNo: selectedMakeModel?.nvicCode
    };
  }

  populateTypeOfCoverageData(data) {
    let coverageType = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    let typeOfCoverageData = { 
      ...coverageType, ...data, 
      sumInsured: this.numberFmt.transform(data.sumInsured), excessAmount: this.numberFmt.transform(data.excessAmount),
      basicPremium: this.numberFmt.transform(data.basicPremium), annualPremium: this.numberFmt.transform(data.annualPremium), 
      ncdAmount: this.numberFmt.transform(data.ncdAmount), ncdPercentage: this.numberFmt.transform(data.ncdPercentage)
    };
    if (data.grossPremium) {
      typeOfCoverageData = {
        ...typeOfCoverageData, grossPremium: this.numberFmt.transform(data.grossPremium), 
      };
    }
    return typeOfCoverageData;
  }

  populateExtraCoverageData(data) {
    let driverNameDetails = data?.driverNameDetails?.rows?.map((d, i) => { return { ...d, driverSeq: i+1 } });
    let ehailingDriverDetails = data?.ehailingDriverDetails?.rows?.map((d, i) => { return { ...d, driverSeq: i+1 } });
    return {
      ...data, 
      driverNameDetails: {
        rows: driverNameDetails,
        vehicleUnNamedDrvrInd: data.driverNameDetails?.waiverInd,
        vehicleAlldrvrInd: data.driverNameDetails?.allDriverInd
      }, 
      ehailingDriverDetails: {
        rows: ehailingDriverDetails
      }
    };
  }

  populateDriverNameData() {
    let driverNameForm = this.coverageFmGroup.controls["driverNameForm"].getRawValue();
    const namedDriverList = driverNameForm.rows
    let cngeRiskDrvrDtoList = namedDriverList.map((driver) => {
      let driverName;
      if (driver.driverName) {
        driverName = driver.driverName;
      } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
        driverName = driver.cngeRiskDrvrPkDto.driverName;
      }

      let cngeRiskDrvrPkDto = {
        cngeRiskDrvrPkDto: {
          driverName: driverName ? driverName : "",
          driverType: "NORM",
        },
        driverId: driver.driverId,
        driverAge: driver.driverAge,
        driverSeq: driver.driverSeq,
      };

      return cngeRiskDrvrPkDto;
    });
    return cngeRiskDrvrDtoList;
  }
}
