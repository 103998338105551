<div class="cross_sell_banner">
    <div nxLayout="grid maxwidth">
         <div nxRow>
            <div nxCol="12" class="nx-margin-bottom-m">
                <div class="cross-sell-header">
                    Your customer do not have following coverage,
                    <br />
                    get them covered?
                </div>
            </div>
         </div>
    </div>

    <div nxLayout="grid maxwidth" class="grid_spacing">
        <div nxRow>
            <div nxCol="12">
                <div class="carousel-container">
                    <!-- Scrollable Image Container -->
                    <div #scrollContainer class="banner_flex">
                        @for(item of crossSellList; let i = $index; track item) {
                        <nx-card [class.active]="i === currentIndex"
                            [style.flex]="'0 0 ' + (100 / imagesPerView) + '%'">
                        <div nxRow>
                            <div nxCol="12" class="card-title">
                            {{item.productName}}
                            </div>
                        </div>
                            <div class="details-container">
                                <div nxRow>
                                    <div nxCol="12">
                                        <img class="img" [src]="item.bannerImg">
                                    </div>
                                </div>
                            </div>
                            <div nxRow rowAlignItems="center" class="padding_top_20 small_view_btn">
                                <div nxCol="12,12,7">
                                    <button nxButton="primary" class="cross-sell-button" (click)="openPopUpMarketingMaterial(item)" 
                                    [disabled]="disableMartketingAndIssueNew()">
                                        Send Marketing Material
                                    </button>
                                </div>
                                <div nxCol="12,12,5" class="std-ta-right">
                                    <button nxPlainButton type="button" class="plain_btn spacing_btn_top"
                                    (click)="getCSRedirectURL(item)" [disabled]="disableMartketingAndIssueNew()">
                                        <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon
                                        >ISSUE NEW
                                    </button>
                                </div>
                            </div>
                        </nx-card>
                        }
                    </div>

                    <!-- Pagination & Arrows Below the Banner -->
                    <div class="pagination-container">
                    <!-- Left Arrow (Disabled on first slide) -->
                     @if(showPagination) {
                        <button class="arrow left" 
                            [class.disabled]="currentIndex <= 0" 
                            (click)="prevImage()" 
                            [disabled]="currentIndex <= 0">
                            <nx-icon name="arrow-left"></nx-icon>
                        </button>
                    }

                    <!-- Pagination Dots -->
                        <div class="pagination">
                            @for(item of crossSellList.slice(0, totalPages); let i = $index; track item) {
                            <span  class="dot"
                                [class.active]="i === currentIndex"
                                (click)="setCurrentIndex(i)">
                            </span>
                            }
                        </div>

                        <!-- Right Arrow (Disabled on last slide) -->
                         @if(showPagination) {
                        <button class="arrow right" 
                            [class.disabled]="currentIndex >= totalPages - 1" 
                            (click)="nextImage()" 
                            [disabled]="currentIndex >= totalPages - 1">
                            <nx-icon name="arrow-right"></nx-icon>
                        </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>