import { NgZone, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { map, catchError } from 'rxjs/operators';

import { USERROLE } from 'src/app/shared/enums/index.enum';

import { UserResponse, SascEmailConfigModel } from 'src/app/interfaces/user';

import {
	GET_USER_MOCK, 
	POPULATE_POLICY_MANAGEMENT,
	RESET_QUERY_PARAMS,
	BACK_TO_ALPHA,
	BACK_TO_SAT,
	REDIRECT_SAT,
	SIGN_OUT,
	GET_USER,
    STORE_QUERY_PARAMS,
} from './user.action';

import { UserService } from './user.service';

interface UserModel {
    user: Partial<UserResponse>,
    loading: boolean,
    role: string,
    emailConfig: SascEmailConfigModel,
    queryParams: any,
    menu: any,
}
@Injectable()
@State<UserModel>({
    name: 'UserState',
    defaults: {
        user: null,
        loading: false,
        role: USERROLE.ADMIN,
        emailConfig: null,
        queryParams: {},
        menu: null
    }
})

export class UserState {
	
	constructor(private userService: UserService, private router: Router, private ngZone: NgZone) { }

	@Selector()
	public static menu(state) {
		return state.menu;
	}

	@Selector()
	public static menuList(state: UserModel) {
		return state.user.menuList ? state.user.menuList : null;
	}

	@Selector()
	public static user(state: UserModel) {
		return state.user ? state.user : {
			name: "XXX",
			lastLoginDate: "XX/XX/XXXX XX:XX:XX"
		};
	}

	@Action(GET_USER)
    public getUser(
        { patchState, getState, dispatch }: StateContext<UserModel>,
        { payload }: GET_USER) {
        const state = getState();
        patchState({ loading: true });
        return this.userService
            .getUser(payload.userId)
            .pipe(
                map(res => {
                    if (res.error) {
                        dispatch(new SIGN_OUT());
                    } else {
                        patchState({
                            user: res,
                            loading: false,
                            role: this.getUserRole(res),
                            queryParams: payload.queryParams
                        });
                    }
                }),
                catchError(err => {
                    patchState({ loading: false });
                    dispatch(new SIGN_OUT());
                    return null;
                })
            );
    }

    @Action(GET_USER_MOCK)
    public getUserMock(
        { patchState, getState, dispatch }: StateContext<UserModel>,
        { payload }: any) {
        const state = getState();
        patchState({ loading: true });
        return this.userService
            .getUserMock(payload.userId)
            .pipe(
                map(res => {
                    if (res.error) {
                        dispatch(new SIGN_OUT());
                    } else {
                        patchState({
                            user: res,
                            loading: false,
                            role: this.getUserRole(res),
                            queryParams: payload.queryParams
                        });
                    }
                }),
                catchError(err => {
                    patchState({ loading: false });
                    dispatch(new SIGN_OUT());
                    return null;
                })
            );
    }
	
    @Action(RESET_QUERY_PARAMS)
    public clearQueryParams({patchState}): StateContext<UserModel> {
        return patchState({
            queryParams: null
        });
    }

	@Action(POPULATE_POLICY_MANAGEMENT)
	public populatePolicyManagement({patchState, getState}): StateContext<UserModel> {
		const state = getState();
		let copyList = JSON.parse(JSON.stringify(state.user.menuList));
		let menuPolicyManagement;
		if (copyList && copyList.length > 0) {
			copyList.forEach(element => {
				if (element.menuId == 'SA_MNPOLICIES' || element.menuId == 'MNPOLICIES') {
					//element.subMenu.forEach(sm => {
					//	let menuSub = sm.link.replace("alpha-sat-svc", "sat-motor-svc");
					//	sm.link = menuSub;
					//});
					menuPolicyManagement = element;
				}
			});
		}
		return patchState({
			menu: menuPolicyManagement
		});
	}
	
	@Action(BACK_TO_ALPHA)
	public backToAlpha({ patchState, getState }: StateContext<UserModel>) {
	  const state = getState();
	  return this.userService.backToAlpha(state.user.userId).pipe(
		map((res) => {
		  if (res.redirectUrl) {
			window.location.href = res.redirectUrl;
		  }
		}),
		catchError((err) => {
		  return null;
		})
	  );
	}
  
	@Action(BACK_TO_SAT)
	public backToSAT(
		{ patchState, getState }: StateContext<UserModel>) {
	  const state = getState();
	  return this.userService.backToSAT(state.user.userId).pipe(
		map((res) => {
		  console.log('BACK TO SAT', res);
		  if (res.redirectUrl) {
			window.location.href = res.redirectUrl;
		  }
		}),
		catchError((err) => {
		  return null;
		})
	  );
	}
	
	@Action(REDIRECT_SAT)
	public redirectSAT({ patchState }: StateContext<UserModel>, { payload }: REDIRECT_SAT) {
		console.log("check:",payload)
	  return this.userService.redirectSAT(payload).pipe(
		map((res) => {
		  if (res.redirectUrl) {
			window.location.href = res.redirectUrl;
		  }
		}),
		catchError((err) => {
		  return null;
		})
	  );
	}

    @Action(SIGN_OUT)
    public signOut(
        { patchState, getState }: StateContext<UserModel>) {
        const state = getState();
        return this.userService.signOut(state.user.userId)
            .pipe(
                map(res => {
                    if (res.redirectUrl) {
                        window.location.href = res.redirectUrl;
                    }
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    getUserRole(state) {
        if (state) {
            if (state.admin) {
                return USERROLE.ADMIN;
            }

            if (state.agent) {
                return USERROLE.AGENT;
            }

            if (state.branchAdmin) {
                return USERROLE.BRANCH;
            }

            if (state.callCentreOrBancaAgent) {
                return USERROLE.BANCA;
            }
        }
        return null;
    }

    @Action(STORE_QUERY_PARAMS)
    public storeQueryParamsContractId({ patchState, getState }, { payload }: STORE_QUERY_PARAMS): StateContext<UserModel> {
      const state = getState();
      return patchState({
        queryParams: payload,
      });
    }
}


