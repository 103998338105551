import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal/format-decimal.pipe';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-premium-adjustable',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './premium-adjustable.component.html',
  styleUrl: './premium-adjustable.component.scss'
})
export class PremiumAdjustableComponent implements OnInit {
  @Input() data: any;
  @Input() htgeData: any;
  @Input() parentForm: FormGroup;
  @Output() premiumCalculation = new EventEmitter<any>();
  coverageType: any; //COMP / TPFT
  isRenewal: boolean;
  calculationType: any; // DTT / DNT
  userRole: string;
  decimalFmt: any;

  constructor(
    private store: Store
  ){
    this.decimalFmt = new FormatDecimalPipe();
  }
  
  ngOnInit() {
    this.userRole = this.store.selectSnapshot<any>(state => state.UserState.role);
    this.parentForm.addControl("riskExcess", new FormControl({value: this.decimalFmt.transform(this.formatData(this.data.riskList[0].coverList[0].excessAmount)), disabled: this.disabledRiskExcess() }));
    this.parentForm.addControl("hqPremiumPct", new FormControl({value: this.formatData(this.data.riskList[0].coverList[0].discountPercentageHq), disabled: this.disabledHqPremiumPct()}));
    this.parentForm.addControl("loading", new FormControl(this.formatData(this.data.riskList[0].coverList[0].loadPercentage)));
    this.coverageType = this.data.riskList[0].coverList[0].coverCode;
    this.isRenewal = this.data.cnoteType === "RN";
    this.calculationType = this.data.riskList[0].coverList[0].calculationMethod;
  }

  disabledRiskExcess() {
    return this.coverageType === "01" || this.coverageType === "30";
  }

  disabledHqPremiumPct() {
    return this.userRole.toUpperCase() === "ADMIN" && this.data.isPremAdjustment;
  }

  onRiskExcessChange() {
    let riskExcessValue = this.parentForm.get("riskExcess").value; 
    let data = {
      fieldName: "riskExcess",
      value: riskExcessValue
    }
    this.premiumCalculation.emit(data);
  }

  onHQPremiumPctChange() {
    let hqPremiumPctValue = this.parentForm.get("hqPremiumPct").value;
    let data = {
      fieldName: "hqPremiumPct",
      value: hqPremiumPctValue
    }
    this.premiumCalculation.emit(data);
  }

  onLoadingPctChange() {
    let loadingValue = this.parentForm.get("loading").value;
    let data = {
      fieldName: "loading",
      value: loadingValue
    }
    this.premiumCalculation.emit(data);
  }

  formatData(value: string): string {
    if (!value) {
        return "0";
    }
    return value;
  }
}
