<div *ngIf="popup_type == 'warningPopup'">
    <div class="std-ta-center">
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
        <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
            {{popup_error}}
        </p>
        <button nxModalClose class="button_popup_2btn" nxButton="primary" type="button" (click)="onClick('N')">
            Cancel
        </button>
        <button nxModalClose nxButton="primary" type="button" (click)="onClick('Y')">
            OK
        </button>
    </div>
</div>

<div *ngIf="popup_type == 'warningPopupTwo'">
    <div class="std-ta-center">
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
        <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
            {{popup_error}}
        </p>
        <button nxModalClose nxButton="primary" type="button">
            OK
        </button>
    </div>
</div>