import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgIf, formatNumber } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { PremiumSummaryComponent } from "@shared/components/premium-summary/premium-summary.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ExtraCoverageComponent } from "@shared/components/coverages/extra-coverage/extra-coverage.component";
import { Select, Store } from "@ngxs/store";
import { Observable, pairwise, startWith } from "rxjs";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { CALCULATE_PREMIUM_PAYLOAD, DELETE_COVERAGE, DELETE_EXTRA_COVER_DETAILS, GET_NCD } from "@store/sat-motor/sat-motor.action";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NcdMessageComponent } from "@shared/components/modal/ncd-message/ncd-message.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";

@Component({
  selector: "app-coverage-comprehensive",
  standalone: true,
  imports: [NgIf, SharedModule, PremiumSummaryComponent, ExtraCoverageComponent],
  templateUrl: "./coverage-comprehensive.component.html",
  styleUrl: "./coverage-comprehensive.component.scss",
})
export class CoverageComprehensiveComponent implements OnInit {
  @Input() coverageFmGroup!: FormGroup;
  @Input() isComprehensive!: boolean;
  @Input() lov!: any;
  @Input() defaultDriver: any;
  @Input() issuanceInfo: any;

  @Output() onCoverDeleted = new EventEmitter<unknown>();
  @Output() onCoverFormChanged = new EventEmitter<unknown>();
  @Output() alimCustomerEligible = new EventEmitter<unknown>();

  @Select(SatMotorState.currentTypeOfCoverage) currentTypeOfCoverage$: Observable<SatMotorState>;
  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;

  comprehensiveForm: FormGroup<any>;
  agentDetail: any;
  vehicleNo: any;
  selectedMakeModel: any;
  typeOfCoverage: any;
  sumInsuredRange: boolean;
  avSi: any;
  avSiAvailable: any;
  underInsurenceApplies: boolean;
  avIndChecked: boolean;
  isShowExtraCoverage: boolean = false;

  oldValue = {};

  decimalFmt: any;
  numberFmt: any;

  isDisabledGetNCD: boolean;
  ncdMessageModal?: NxModalRef<NcdMessageComponent>;
  warningMessageModal?: NxModalRef<SimpleModalComponent>;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    public translate: TranslateService,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.comprehensiveForm = this.fb.group({
      sumInsured: ["", Validators.required],
      avInd: false,
      excessAmount: [{ value: "", disabled: true }],
      basicPremium: [{ value: "", disabled: true }],
      annualPremium: [{ value: "", disabled: true }],
      ncdPercentage: [{ value: "", disabled: true }],
      ncdAmount: [{ value: "", disabled: true }],
      prevNcdInsurer: "",
      prevNcdVeh: ["", VehicleNoValidator()],
    });
  }

  ngOnInit() {
    this.agentDetail = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.issuance.issuance);
    this.vehicleNo = this.issuanceInfo.vehicleNo;

    this.typeOfCoverage = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    if (this.typeOfCoverage && this.isComprehensive) {
      this.comprehensiveForm.patchValue({
        sumInsured: this.decimalFmt.transform(this.typeOfCoverage["sumInsured"]),
        excessAmount: this.decimalFmt.transform(this.typeOfCoverage["excessAmount"]),
        annualPremium:this.decimalFmt.transform( this.typeOfCoverage["annualPremium"]),
        basicPremium: this.decimalFmt.transform(this.typeOfCoverage["basicPremium"]),
        ncdPercentage: this.decimalFmt.transform(this.typeOfCoverage["ncdPercentage"]),
        ncdAmount: this.decimalFmt.transform(this.typeOfCoverage["ncdAmount"]),
        prevNcdVeh: this.decimalFmt.transform(this.typeOfCoverage["prevNcdVeh"]),
        prevNcdInsurer: this.typeOfCoverage["prevNcdInsurer"],
      });
    }
    
    this.setRecommendedSumInsuredRange();

    this.comprehensiveForm.valueChanges.pipe(startWith(this.comprehensiveForm.value), pairwise()).subscribe(([old, value]) => {
      this.oldValue = old;
    });

    this.currentTypeOfCoverage$.subscribe((state) => {
      if (state) {
        if (state["coverCode"] === "01") {
          this.coverageFmGroup.controls["typeOfCoverage"] = this.comprehensiveForm;
          this.typeOfCoverage = state;
          if (this.typeOfCoverage && this.isComprehensive) {
            let coverListLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data?.step3?.coverage?.coverListLov);
            for (let coverList of coverListLov) {
              if (coverList["coverCode"] === "01") {
                this.typeOfCoverage = { ...this.typeOfCoverage, coverDescription: coverList["coverDescription"] };
              }
            } 
          }
        }
      }
    });
    
    this.coveragePremium$.subscribe((state) => {
      if (state) {
        const cngeNote = state["cngeNote"];
        if (cngeNote) {
          const riskList = cngeNote["riskList"];
          if (riskList?.length) {
            for (let riskListItem of riskList) {
              this.prevNcdVeh.setValue(riskListItem.vehicleNo);
              const coverList = riskListItem["coverList"];
              if (coverList?.length) {
                for (let coverListItem of coverList) {
                  if (this.isComprehensive && coverListItem["coverDto"].subCovList?.length > 0) {
                    this.isShowExtraCoverage = true;
                  }
                  
                  if (coverListItem["coverCode"] !== "01") break;

                  const sumInsured = coverListItem["sumInsured"];
                  const grossPremium = coverListItem["grossPremium"];
                  
                  this.setRecommendedSumInsuredRange();
                  if (sumInsured) {
                    const siWarning = this.setSIWarning(sumInsured);
                    if (!siWarning.valid)
                      this.showSIWarning(siWarning.value);

                    const grossPremiumWarning = this.setGrossPremiumWarning(grossPremium);
                    if (siWarning.valid && !grossPremiumWarning.valid)
                      this.showGrossPremiumWarning(grossPremiumWarning.value);
                    
                    this.setSumInsuredRange(sumInsured)

                    if ([siWarning.valid, grossPremiumWarning.valid].indexOf(true) != -1 && !this.sumInsuredRange)
                      this.setUnderInsurenceApplies(sumInsured);
                    
                    this.setAgreedValue();

                    if ([siWarning.valid, grossPremiumWarning.valid, this.sumInsuredRange].indexOf(true) != -1 && coverListItem.alimCustomerEligible)
                      this.alimCustomerEligible.emit();
                    
                    this.typeOfCoverage = {
                      ...this.typeOfCoverage,
                      ...{ grossPremium: grossPremium },
                      ...{ basicPremium: coverListItem["basicPremium"] },
                      ...{ ncdPercentage: coverListItem["ncdPercentage"] },
                      ...{ ncdAmount: coverListItem["ncdAmount"] },
                      ...{ subCoverPremium: coverListItem["subCoverPremium"] },
                    };
                  }
                }
              }
            }
          }
        }
      }
    });
    
    this.checkDisabledGetNCD();
  }

  onChangeForm($event: any) {

    let data = {
      fieldName: $event.target.getAttribute("formControlName"),
      value: $event.target.value,
      oldValue: this.oldValue[$event.target.getAttribute("formControlName")],
    };

    const doChangeForm = {
      sumInsured: () => {
        if (data.value)
          this.onCoverFormChanged.emit(data);
      },
      prevNcdVeh: () => {
        this.checkDisabledGetNCD();
      },
    };

    if (["sumInsured"].includes(data.fieldName)) {
      data = { ...data, value: this.numberFmt.transform(data.value), oldValue: this.numberFmt.transform(data.oldValue) }
    }

    if (data.oldValue !== data.value) {
      doChangeForm[data.fieldName]();
    }
  }

  deleteCover(cover: any) {
    this.store.dispatch(new DELETE_COVERAGE(cover)).subscribe((state) => {
      this.comprehensiveForm.reset();
      this.onCoverDeleted.emit();
    });
    this.isShowExtraCoverage = false;
    this.store.dispatch(new DELETE_EXTRA_COVER_DETAILS(cover)).subscribe((state) => {});
  }

  checkDisabledGetNCD() {
    let vehicleNo = this.comprehensiveForm.get("prevNcdVeh");
    if (this.issuanceInfo.cnoteType === "NWTR" && vehicleNo.valid && vehicleNo.value) {
      this.isDisabledGetNCD = false;
    } else {
      this.isDisabledGetNCD = true;
    }
  }

  onClickGetNCD() {
    this.store.dispatch(new CALCULATE_PREMIUM_PAYLOAD({})).subscribe((state) => {
      this.store.dispatch(new GET_NCD(state.SatMotorState.data.step3.coverage.coveragePremiumPayload)).subscribe((state) => {
        let ncdInfo = state.SatMotorState.data.step3.coverage.coverageNCD.cngeNote;
        if (ncdInfo.ncdMessageList) {
          this.ncdMessageModal = this.dialogService.open(NcdMessageComponent, {
            data: {
              message: ncdInfo.ncdMessageList[0],
            },
          });
        } else {
          this.comprehensiveForm.patchValue({
            ncdPercentage: ncdInfo.ncdPercentage,
          });
          this.comprehensiveForm.get('ncdPercentage').enable();
        }
      });
    });
  }

  openPopup(popup_type: any) {}

  openMessage(popup_type: any) {}

  openPopupComp(popup_type: any) {}

  openPopupMakeModel(popup_type: any) {}

  isPrivateCars() {
    return ["080100", "080200", "080300"].includes(this.issuanceInfo.productCode);
  }

  isMotorcycle() {
    return ["082100", "082101"].includes(this.issuanceInfo.productCode);
  }

  setRecommendedSumInsuredRange() {
    this.selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3?.coverage?.selectedMakeModel);
    if (this.selectedMakeModel) {
      this.avSi = {
        E: { avSiMin: this.selectedMakeModel.avEmSiMin, avSiMinDesc: formatNumber(Number(this.selectedMakeModel.avEmSiMin), 'en-US'), 
        	 avSiMax: this.selectedMakeModel.avEmSiMax, avSiMaxDesc: formatNumber(Number(this.selectedMakeModel.avEmSiMax), 'en-US'), 
           },
        W: { avSiMin: this.selectedMakeModel.avWmSiMin, avSiMinDesc: formatNumber(Number(this.selectedMakeModel.avWmSiMin), 'en-US'), 
        	 avSiMax: this.selectedMakeModel.avWmSiMax, avSiMaxDesc: formatNumber(Number(this.selectedMakeModel.avWmSiMax), 'en-US'), 
           },
      };
      if (this.avSi) {
        this.avSiAvailable = 
          this.avSi[this.agentDetail.region].avSiMin > 0  &&
          this.avSi[this.agentDetail.region].avSiMax > 0 &&
          this.selectedMakeModel.inactiveSiInd === "N";
      }
    }
  }  

  setSumInsuredRange(sumInsured) {
    return this.sumInsuredRange = (this.avSiAvailable) && 
      (sumInsured >= this.avSi[this.agentDetail.region].avSiMin && sumInsured <= this.avSi[this.agentDetail.region].avSiMax);
  }  

  setAgreedValue() {
    
    this.avIndChecked = false;
    this.avInd.enable();
    
    this.avIndChecked = this.avSi[this.agentDetail.region] && this.selectedMakeModel.inactiveSiInd === "N" && this.sumInsuredRange;

    if (this.isMotorcycle() || !this.avSiAvailable || !this.sumInsuredRange) {
      this.avInd.disable();
    }
  }  

  setUnderInsurenceApplies(sumInsured) {
    return this.underInsurenceApplies = sumInsured < ((90 / 100) * this.avSi[this.agentDetail.region].avSiMin);
  }

  setSIWarning(sumInsured : any) {
    let value : any;
    if (this.isPrivateCars() && sumInsured < 10000) {
      value = "10000";
    }
    if (this.isMotorcycle() && sumInsured < 1000) {
      value = "1000";
    }
    
    if (value) {
      return { valid: false, value: value };
    }
    return { valid: true };
  }
  
  showSIWarning(value : any) {
    if (value) {
      this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: "Warning", message: "Sum insured must be at least RM" + formatNumber(Number(value), 'en-US') + "." },
      });
	  
      this.warningMessageModal.afterClosed().subscribe((result) => {
        this.sumInsured.setValue(this.decimalFmt.transform(value));
        this.onCoverFormChanged.emit();
      });
    }
  }
  
  setGrossPremiumWarning(grossPremium : any) {
    let value : any;
    if (this.isPrivateCars() && grossPremium < 50) {
      value = "50";
    }
    if (this.isMotorcycle() && grossPremium < 20) {
      value = "20";
    }
    
    if (value) {
      return { valid: false, value: value };
    }
    return { valid: true };
  }  

  showGrossPremiumWarning(value : any) {
    if (value) {
      this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: "Warning", message: "Minimum Gross Premium Applied." },
      });
	  
      this.warningMessageModal.afterClosed().subscribe((result) => {
        this.typeOfCoverage = { ...this.typeOfCoverage, ...{ grossPremium: this.decimalFmt.transform(value) } };
        this.onCoverFormChanged.emit(this.typeOfCoverage);
      });
    }
  }

  get avInd() {
    return this.comprehensiveForm.controls["avInd"];
  }

  get prevNcdVeh() {
    return this.comprehensiveForm.controls["prevNcdVeh"];
  }

  get sumInsured() {
    return this.comprehensiveForm.controls["sumInsured"];
  }
}
