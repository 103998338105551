<!-- <app-external-scrollbar> -->
<!-- =================================test================================ -->

<div nxLayout="grid">
  <div nxRow>
    <!-- <div nxCol="12" class="nx-margin-top-2m">
      <div class="table_dashboard table_scrollbar">
        <div
          #tableDesc
          class="table-desc nx-margin-bottom-xs"
          [ngClass]="{ hidden: shouldHideTableDesc }"
        >
          <span nxCopytext="small">Swipe to view more</span>
        </div>

        <div class="scrollbar-wrapper scrolldiv">
          <div #scrolldivs class="wmd-view-topscroll">
            <div class="scroll-div1"></div>
          </div>
        </div>
        <div class="wmd-view" #scrolldivs>
          <div class="scroll-div2">
            <table nxTable zebra #table cellpadding="0">
              <thead #tableHeader>
                <tr nxTableRow>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >No.</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Endorsement Type</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Effective Date</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Expiry Date</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Sum Insured (RM)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Premium Due (RM)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Vehicle No.</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of endorsement_table">
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.no }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium"> {{ item.type }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.effective_date }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.exp_date }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.si }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.premium_due }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.vehicle_no }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="scrollbar-wrapper scrolldiv">
          <div #scrolldivs class="wmd-view-topscroll">
            <div class="scroll-div1"></div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div nxCol="12" class="nx-margin-top-2m">
      <div class="table_enquiry table_scrollbar">
        <div
          #tableDesc
          class="table-desc nx-margin-bottom-xs"
          [ngClass]="{ hidden: shouldHideTableDesc }"
        >
          <span nxCopytext="small">Swipe to view more</span>
        </div>

        <div class="scrollbar-wrapper scrolldiv">
          <div #scrolldivs class="wmd-view-topscroll">
            <div class="scroll-div1"></div>
          </div>
        </div>
        <div class="wmd-view" #scrolldivs>
          <div class="scroll-div2">
            <table nxTable zebra #table cellpadding="0">
              <thead #tableHeader>
                <tr nxTableRow>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >No.</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Endorsement Type</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Effective Date</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Expiry Date</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Sum Insured (RM)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Premium Due (RM)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Vehicle No.</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of endorsement_table">
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.no }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium"> {{ item.type }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.effective_date }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.exp_date }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.si }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.premium_due }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.vehicle_no }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="scrollbar-wrapper scrolldiv">
          <div #scrolldivs class="wmd-view-topscroll">
            <div class="scroll-div1"></div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div nxCol="12" class="nx-margin-top-2m">
      <div class="table_summary table_scrollbar">
        <div
          #tableDesc
          class="table-desc nx-margin-bottom-xs"
          [ngClass]="{ hidden: shouldHideTableDesc }"
        >
          <span nxCopytext="small">Swipe to view more</span>
        </div>

        <div class="scrollbar-wrapper scrolldiv">
          <div #scrolldivs class="wmd-view-topscroll">
            <div class="scroll-div1"></div>
          </div>
        </div>
        <div class="wmd-view" #scrolldivs>
          <div class="scroll-div2">
            <table nxTable zebra #table cellpadding="0">
              <thead #tableHeader>
                <tr nxTableRow>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >No.</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Endorsement Type</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Effective Date</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Expiry Date</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Sum Insured (RM)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Premium Due (RM)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Vehicle No.</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of endorsement_table">
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.no }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium"> {{ item.type }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.effective_date }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.exp_date }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.si }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.premium_due }}</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">{{ item.vehicle_no }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="scrollbar-wrapper scrolldiv">
          <div #scrolldivs class="wmd-view-topscroll">
            <div class="scroll-div1"></div>
          </div>
        </div>
      </div>
    </div> -->

    <button (click)="toggleshow()">show</button>

    <!-- example using scollbar from equote -->
    <div nxCol="12">
      <div>
        <app-external-scrollbar
          targetSyncScroll="1"
          [targetBaseWidth]="tableHolder"
        ></app-external-scrollbar>
        <div
          class="table-holder table_content_scroll only-this-horizon-scrollbar"
          #tableHolder
          syncScroll="1"
        >
          <table nxTable zebra nxSort>
            <thead>
              <tr nxTableRow>
                <th nxHeaderCell nxSortHeaderCell="date">Issued<br />Date</th>
                <th nxHeaderCell nxSortHeaderCell="agentcode">
                  Agent Code / <br />Agent Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="policy">
                  Draft Ref No. / <br />Quotation No.
                </th>
                <th nxHeaderCell nxSortHeaderCell="product">Product Name</th>
                <th nxHeaderCell nxSortHeaderCell="customer">Customer Name</th>
                <th nxHeaderCell nxSortHeaderCell="issuance">
                  Issuance <br />Type
                </th>
                <th nxHeaderCell nxSortHeaderCell="vehicle">
                  Vehicle / <br />Trailer No.
                </th>
                <th
                  nxHeaderCell
                  nxSortHeaderCell="premium"
                  class="std-ta-right"
                >
                  Premium <br />Due (RM)
                </th>
                <th nxHeaderCell nxSortHeaderCell="additional">
                  Additional Info
                </th>
                <th nxHeaderCell nxSortHeaderCell="progress">Progress</th>
                <th nxHeaderCell nxSortHeaderCell="status">Status</th>
              </tr>
            </thead>
            <!-- <tbody>
              <tr nxTableRow *ngFor="let item of tableDraftElements">
                <td nxTableCell>{{ item.date }}</td>
                <td nxTableCell>{{ item.agentcode }}</td>
                <td nxTableCell class="completed_color_primary">
                  {{ item.policy }}
                </td>
                <td nxTableCell>{{ item.product }}</td>
                <td nxTableCell>{{ item.customer }}</td>
                <td nxTableCell>{{ item.issuance }}</td>
                <td nxTableCell></td>
                <td nxTableCell class="std-ta-right">{{ item.premium }}</td>
                <td nxTableCell>{{ item.additional }}</td>
                <td nxTableCell>
                  <p nxCopytext="small">{{ item.progressText }}</p>
                  <nx-progressbar [value]="item.progress"></nx-progressbar>
                </td>
                <td nxTableCell>
                  <nx-badge [type]="item.status" [class]="item.class">{{
                    item.statusText
                  }}</nx-badge>
                </td>
              </tr>
            </tbody> -->
          </table>
        </div>
      </div>
    </div>

    <div nxCol="12">
      <div>
        <app-external-scrollbar
          targetSyncScroll="2"
          [targetBaseWidth]="tableHolder2"
        ></app-external-scrollbar>
        <div
          class="table-holder table_content_scroll only-this-horizon-scrollbar"
          #tableHolder2
          syncScroll="2"
        >
        <!-- table here -->
        <table nxTable zebra nxSort>
          <thead>
            <tr nxTableRow>
              <th nxHeaderCell nxSortHeaderCell="date">Issued<br />Date</th>
              <th nxHeaderCell nxSortHeaderCell="agentcode">
                Agent Code / <br />Agent Name
              </th>
              <th nxHeaderCell nxSortHeaderCell="policy">
                Draft Ref No. / <br />Quotation No.
              </th>
              <th nxHeaderCell nxSortHeaderCell="product">Product Name</th>
              <th nxHeaderCell nxSortHeaderCell="customer">Customer Name</th>
              <th nxHeaderCell nxSortHeaderCell="issuance">
                Issuance <br />Type
              </th>
              <th nxHeaderCell nxSortHeaderCell="vehicle">
                Vehicle / <br />Trailer No.
              </th>
              <th
                nxHeaderCell
                nxSortHeaderCell="premium"
                class="std-ta-right"
              >
                Premium <br />Due (RM)
              </th>
              <th nxHeaderCell nxSortHeaderCell="additional">
                Additional Info
              </th>
              <th nxHeaderCell nxSortHeaderCell="progress">Progress</th>
              <th nxHeaderCell nxSortHeaderCell="status">Status</th>
            </tr>
          </thead>
          <!-- <tbody>
            <tr nxTableRow *ngFor="let item of tableDraftElements">
              <td nxTableCell>{{ item.date }}</td>
              <td nxTableCell>{{ item.agentcode }}</td>
              <td nxTableCell class="completed_color_primary">
                {{ item.policy }}
              </td>
              <td nxTableCell>{{ item.product }}</td>
              <td nxTableCell>{{ item.customer }}</td>
              <td nxTableCell>{{ item.issuance }}</td>
              <td nxTableCell></td>
              <td nxTableCell class="std-ta-right">{{ item.premium }}</td>
              <td nxTableCell>{{ item.additional }}</td>
              <td nxTableCell>
                <p nxCopytext="small">{{ item.progressText }}</p>
                <nx-progressbar [value]="item.progress"></nx-progressbar>
              </td>
              <td nxTableCell>
                <nx-badge [type]="item.status" [class]="item.class">{{
                  item.statusText
                }}</nx-badge>
              </td>
            </tr>
          </tbody> -->
        </table>
        </div>
      </div>
    </div>

  
  </div>
</div>
