import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { Store } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-agents-information',
  templateUrl: './agents-information.component.html',
  styleUrl: './agents-information.component.scss'
})
export class AgentsInformationComponent implements OnInit {

  @Input() agentData: any;
  @Input() isEndorsement: boolean;
  @Input() isApproval: boolean = false;
  @Input() isClaimEnquiry: boolean = false;
  @Input() viewPolicyDetails: any;

  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    console.log("agent data: ", this.agentData);
    console.log("policy",this.viewPolicyDetails)

    let newAgentData = { ...this.agentData };
    // newAgentData.issuedDate = this.agentData.issuedDate ? this.formatDateValue(this.agentData.issuedDate) : null;
    this.agentData = newAgentData;
  }

  formatDateValue(date: Date): string {
      return moment(date).format('DD/MM/YYYY');
  }

  formatData(value: string): string {
    if (!value) {
        return 'N/A';
    }
    return value.toUpperCase();
  }

}
