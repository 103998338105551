<div>
    <div nxRow>
        <div nxCol="12">
            <nx-accordion variant="light">
                <nx-expansion-panel [expanded]="showTypeOfCoverage">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title>
                            Type of Coverage
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div class="button_coverage">
                        @for (cover of coverList; track cover) {
                            <button nxButton="primary small" type="button" class="nx-margin-right-s"
                                    (click)="addCover(cover)" [disabled]="!cover.enable">
                                <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                                {{ cover.coverDescription | titlecase }}
                            </button>
                        }
                    </div>

                    <!--Comprehensive-->
                    <app-coverage-comprehensive [coverageFmGroup]="coverageFmGroup" [lov]="lov" [isComprehensive]="isComprehensive"  
                                                [defaultDriver]="coverageDefaultDriver$ | async" [issuanceInfo]="issuanceInfo"
                                                (onCoverDeleted)="onCoverDeleted()" (onCoverFormChanged)="onCoverFormChanged($event)"
                                                (alimCustomerEligible)="openPopupOneAllianz()" [partnerInfo]="partnerInfo" (bettermentEligible)="openPopupBetterment()">
					</app-coverage-comprehensive>

                    <!--Third Party-->
                    <app-coverage-third-party [coverageFmGroup]="coverageFmGroup" [lov]="lov" [isThirdParty]="isThirdParty"
                                              [defaultDriver]="coverageDefaultDriver$ | async" [issuanceInfo]="issuanceInfo"
                                              (onCoverDeleted)="onCoverDeleted()" (onCoverFormChanged)="onCoverFormChanged($event)"
                                              (alimCustomerEligible)="openPopupOneAllianz()" [partnerInfo]="partnerInfo" (bettermentEligible)="openPopupBetterment()">
					</app-coverage-third-party>

                    <!--Third Party, Fire and Theft-->
                    <app-coverage-third-party-fire-theft [coverageFmGroup]="coverageFmGroup" [lov]="lov" [isThirdPartyFireTheft]="isThirdPartyFireTheft"
                                                         [defaultDriver]="coverageDefaultDriver$ | async" [issuanceInfo]="issuanceInfo"
                                                         (onCoverDeleted)="onCoverDeleted()" (onCoverFormChanged)="onCoverFormChanged($event)"
                                                         (alimCustomerEligible)="openPopupOneAllianz()" [partnerInfo]="partnerInfo" (bettermentEligible)="openPopupBetterment()">
					</app-coverage-third-party-fire-theft>
                </nx-expansion-panel>
            </nx-accordion>
        </div>
    </div>
</div>
