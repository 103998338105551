<form [formGroup]="searchForm">
        <div nxRow class="nx-margin-top-2m">
          <div nxCol="12">
            <div class="cp_toggle">
              <nx-radio-toggle formControlName="clientType">
                <nx-radio-toggle-button
                  value="P" [disabled]="product === '080200' || product === '080300'"
                >
                  INDIVIDUAL
                </nx-radio-toggle-button>
                <nx-radio-toggle-button
                  value="I" [disabled]="product == '080100'"
                >
                  COMPANY
                </nx-radio-toggle-button>
              </nx-radio-toggle>
            </div>
          </div>
        </div>
      
        @if (!company) {
          <div nxRow class="nx-margin-top-3m">
          <div nxCol="12">
              <nx-radio-group name="nationality" formControlName="clientAddressType">
                <div class="radio-group">
                  <nx-radio value="MAL" class="nx-margin-right-s">Malaysian</nx-radio>
                  <nx-radio value="NM">Non-Malaysian</nx-radio>
                </div>
              </nx-radio-group>
          </div>
        </div>
        }
        
        <div nxRow class="nx-margin-top-2m">
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="ID Type">
              <nx-dropdown formControlName="idType" (selectionChange)="idTypeOnChange()">
                <nx-dropdown-item *ngFor="let item of idTypeOption" [value]="item.code">{{item.descp}}</nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <strong> Please enter ID Type </strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="ID No.">
              <input formControlName="idValue" nxInput type="text" [maxlength]="idValueLength" (blur)="onBlurIdValue()" appToUpperCase/>
              @if(!isPassport && searchForm.get('idValue').errors?.['required']) {
                <nx-error nxFormfieldError>
                  <strong> Please enter ID No. </strong>
                </nx-error>
              }

              @if(isPassport && searchForm.get('idValue').errors?.['required']) {
                <nx-error nxFormfieldError>
                  <strong> Passport Required. </strong>
                </nx-error>
              }
              
              @if(!searchForm.get('idValue').errors?.['required'] && searchForm.get('idValue').errors?.['wrongNricFormat']) {
              <nx-error nxFormfieldError>
                <strong> Wrong Format </strong>
              </nx-error>
              }

              @if(!searchForm.get('idValue').errors?.['required'] && searchForm.get('idValue').errors?.['wrongNewBrnoFormat']) {
              <nx-error nxFormfieldError>
                <strong> Invalid Business Registration No. </strong>
              </nx-error>
              }
            </nx-formfield>
          </div>
        </div>

        @if (company && product == "082101") {
        <div nxRow class="nx-margin-top-3xs">
          <div nxCol="12">
            <span nxCopytext="normal"><i>e-Invoice is mandatory to all Company policyholders.</i></span>
          </div>
        </div>
      }

        <div nxRow class="nx-margin-top-m nx-margin-bottom-3xl">
          <div nxCol="12" class="button-container">
            <button nxButton="secondary" type="button" (click)="goBack()">BACK</button>

            <button nxButton="primary" type="button" (click)="onSubmit()">
              SEARCH
            </button>
          </div>
        </div>
</form>
