import { EventEmitter, Injectable } from "@angular/core";
import {
  IGeocodingConversionPayload,
  IGeocodingConversionResponse,
  IGeocodingStatusPayload,
} from "./geolocation.interface";
import { HttpHelperService } from "@shared/services/http-helper/http-helper.service";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root' // or the specific module name
})
export class GeolocationService {
  locationEmitter: EventEmitter<void> = new EventEmitter<void>();
  private postcodeListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public postcodeList$: Observable<any> = this.postcodeListSubject.asObservable();
  private lovSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public lov$: Observable<any> = this.lovSubject.asObservable();
  private selectedAddressSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedAddress$: Observable<IGeocodingConversionResponse<any>> = this.selectedAddressSubject.asObservable();

  constructor(private httpHelperService: HttpHelperService) {}

  convertGeolocation(payload: IGeocodingConversionPayload) {
    return this.httpHelperService.post("/cp/geocoding/conversion", payload);
  }

  checkGeolocationEnabled(payload: IGeocodingStatusPayload) {
    return this.httpHelperService.get(
      `/geocoding/status?operator=${payload.operator}&productCode=${payload.productCode}`
    );
  }

  onSelectedLocation() {
    this.locationEmitter.emit();
  }

  setAddressPostcodeList<T>(value: T) {
    this.postcodeListSubject.next(value);
  }

  setAddressLOV<IGeocodingAddressLOV>(value: IGeocodingAddressLOV) {
    this.lovSubject.next(value);
  }

  /**
   * used by geolocation-modal with address component to emit address value.
   * din't use state because its only used by geolocation-modal for now. and state implementation was already completed before requirement for the full object is needed.
   * @param value all address fields with raw LOV object values for city, state, country.
   */
  setAddressComponentForm(value: IGeocodingConversionResponse) {
    this.selectedAddressSubject.next(value);
  }

  /**
   * this directly returns from environment variable for now. need enhancement to consider to retrieve api key from backend
   * @returns goolge api key string value eg. AIzaSyCAlvXeK2xv3VKDp27HsJs4jWzKwA
   */
  getGoogleApiKey(): string {
    // return await lastValueFrom(this.httpHelperService.get(" .... ").pipe(map(take(1)))).toString();
    return environment.googleApiKey;
  }
}
