import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
  Inject,
} from "@angular/core";
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
// import { EndorsementPopupComponent } from "../../../../popup/endorsement-popup/endorsement-popup.component";
import { SharedModule } from "../../../../shared/shared.module";
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-policy-movement-popup',
  standalone: true,
  imports: [
    SharedModule,
    NxGridModule
  ],
  templateUrl: './policy-movement-popup.component.html',
  styleUrl: './policy-movement-popup.component.scss'
})
export class PolicyMovementPopupComponent {

  safeNarration: SafeHtml | undefined;
  
  // @Input() policyMovemementPopupData: any;
  @Input() selectedPolicyMovement: any;

  popup_type: string = '';
  selectedItem: any = null;

  templateDialogRef?: NxModalRef<any>;
  // popup_type: any;

  constructor(@Inject(NX_MODAL_DATA) data: any, private sanitizer: DomSanitizer) {
    // console.log("data", data.popup_type);
    this.popup_type = data.popup_type;
    this.selectedPolicyMovement = data.selectedPolicyMovement;

    if (this.selectedPolicyMovement.narration) {
      this.safeNarration = this.sanitizeNarration(this.selectedPolicyMovement.narration);
    }

    if (this.selectedPolicyMovement.cnoteDate) {
      this.selectedPolicyMovement.cnoteDate = this.formatDate(this.selectedPolicyMovement.cnoteDate);
    }

  }

  formatDate = (date) => {
    if (date) {
      const d = new Date(date);
      // return d.toLocaleDateString('ms-MY');
      const day = ('0' + d.getDate()).slice(-2); // Pad day with leading zero
      const month = ('0' + (d.getMonth() + 1)).slice(-2); // Pad month with leading zero
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return 'Invalid Date';
  };

  sanitizeNarration(narration: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(narration);
  }

}
