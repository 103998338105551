import { Component, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { GET_VEHICLE_USE } from '@store/sat-motor/sat-motor.action';
import moment from 'moment';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';

@Component({
  selector: 'app-coverage-summary',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './coverage-summary.component.html',
  styleUrl: './coverage-summary.component.scss'
})
export class CoverageSummaryComponent {

  @Select(SatMotorState.motorActionType) actionType$;

  @Input() coverageInfo: any;
  @Input() viewQuotation: boolean = false;
  @Input() viewPolicyDetails: boolean = false;
  @Input() isRTP: boolean = false;
  @Input() isEndorsement: boolean = false;
  @Input() isIssuance: boolean = false;
  @Input() responseData : any;
  @Input() productCode: any;

  vehicleDetails: any;
  coverageDetails: any;
  namedDriverList: any;
  ehailingDriverList: any;
  rahmahPackageList: any;
  extraCoverPackageSummary: any;
  clientPartnerDetails: any;
  coverList: any;
  lov: any;
  vehicleUseLov: any;
  showEhailing: boolean = false;
  showNamedDriver: boolean = false;
  showExtraCoverage: boolean = true;
  minSumInsured;
  maxSumInsured;
  isShowMaxMin:boolean;

  vehicleDetailsLabels = [
    { key: 'region', label: 'Where is the vehicle being used?' },
    { key: 'vehicleUse', label: 'Use of Vehicle' },
    { key: 'vehicleNo', label: 'Vehicle No.' },
    { key: 'makeModel', label: 'Make / Model' },
    { key: 'vehicleMakeYear', label: 'Year of Manufacture' },
    { key: 'vehicleCapacity', label: 'Capacity / Unit of Measurement' },
    { key: 'engineType', label: 'Engine Type' },
    { key: 'vehicleSeat', label: 'No. of Seats' },
    { key: 'vehicleEngine', label: 'Engine / Motor No.' },
    { key: 'vehicleChassis', label: 'Chassis No.' },
    { key: 'vehicleLoanType', label: 'Loan Type' },
    { key: 'vehicleLoanCompany', label: 'Loan Company' },
    { key: 'vehiclePurchaseDate', label: 'Vehicle Purchase Date' },
    { key: 'makeModelPrintInd', label: 'Make / Model to be printed in Quotation / Cover Note' }
  ];
  coverageDetailsLabels = [
    { key: 'coverDescription', label: 'Coverage Type' },
    { key: 'coverDescp', label: 'Coverage Type' },
    { key: 'sumInsured', label: 'Vehicle Sum Insured (RM)' },
    { key: 'avInd', label: 'Agreed Value' },
    { key: 'ncdPercentage', label: 'No Claim Discount (%)' },
    { key: 'prevNcdInsurer', label: 'No Claim Discount From' },
    { key: 'prevNcdVeh', label: 'No Claim Discount Vehicle No.' },
    { key: 'noClaimDiscountEffectiveDate', label: 'No Claim Discount Effective Date' }
  ];

  driverHeaderLabels = [
    { key: 'driverName', label: 'Driver Name' },
    { key: 'driverId', label: 'Driver ID' },
    { key: 'driverAge', label: 'Age' }
  ];

  ehailingDriverHeaderLabels = [
    { key: 'driverName', label: 'Driver Name' },
    { key: 'driverId', label: 'Driver ID' }
  ];

  extraCoverHeaderLabels = [
    { key: 'description', label: 'Extra Cover' },
    { key: 'subCoverSumInsured', label: 'Premium (RM)' }
  ];
  // motorRoadWarriroList = [
  //   {
  //     "code": "MR1",
  //     "descp": "1 UNIT"
  //   },
  //   {
  //     "code": "MR2",
  //     "descp": "2 UNITS"
  //   },
  //   {
  //     "code": "MR3",
  //     "descp": "3 UNITS"
  //   },
  //   {
  //     "code": "MR4",
  //     "descp": "4 UNITS"
  //   },
  //   {
  //     "code": "MR5",
  //     "descp": "5 UNITS"
  //   }
  // ]

  // motorEnhancedRoadWarriroList = [
  //   {
  //     "code": "PABERWA",
  //     "descp": "PLAN A"
  //   },
  //   {
  //     "code": "PABERWB",
  //     "descp": "PLAN B"
  //   },
  //   {
  //     "code": "PABERWC",
  //     "descp": "PLAN C"
  //   }
  // ]

  // driverPassangerList = [
  //   {
  //     "code": "PAB3A",
  //     "descp": "PLAN A (25000)"
  //   },
  //   {
  //     "code": "PAB3B",
  //     "descp": "PLAN B (50000)"
  //   }
  // ]

  constructor(
    private store: Store
  ) {}


  ngOnInit() {

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isRTP = state.isRTP;
      }
    });
    
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.vehicleUseLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data?.step3?.coverage?.vehicleUseLov);
    
    console.log("viewQuotation : "+this.viewQuotation);
    console.log("coverageDetailsLabels : "+this.coverageDetailsLabels[0].key);
     
    if (this.coverageInfo && !this.viewQuotation && !this.viewPolicyDetails) {
      if ((!this.coverageInfo.coverage?.coverageType ||
        !this.coverageInfo.coverage.coverageType.coverDescp ||
        this.coverageInfo.coverage.coverageType.coverDescp === '') &&
        !this.isEndorsement) {
        this.coverageDetailsLabels = this.coverageDetailsLabels.filter(item => item.key !== 'coverDescp');
      } else {
        this.coverageDetailsLabels = this.coverageDetailsLabels.filter(item => item.key !== 'coverDescription');
      }
    
      if (this.isRTP) {
        this.vehicleDetails = this.coverageInfo.vehicleDetails;
        this.coverageDetails = this.coverageInfo.coverageDetails;
        this.namedDriverList = this.coverageInfo.driverNameDetails;
        this.ehailingDriverList = this.coverageInfo.ehailingDriverDetails;
        this.extraCoverPackageSummary = this.coverageInfo.extraCoverPackageSummary;

      } else if (this.isEndorsement) {
        this.vehicleDetails = this.coverageInfo.vehicleDetails;
        this.coverageDetails = this.coverageInfo.coverageDetails;
        this.namedDriverList = this.coverageInfo.driverNameDetails;
        this.ehailingDriverList = this.coverageInfo.ehailingDriverDetails;
        this.extraCoverPackageSummary = this.coverageInfo.extraCoverPackageSummary;
        
      } else {
        this.vehicleDetails = this.coverageInfo.coverage?.vehicleDetails;
        this.coverageDetails = this.coverageInfo.coverage?.coverageType;
        this.namedDriverList = this.coverageInfo.coverage?.extracover?.driverNameDetails?.rows;
        this.ehailingDriverList = this.coverageInfo.coverage?.extracover?.ehailingDriverDetails?.rows;
        this.extraCoverPackageSummary = this.coverageInfo.coverage?.extracover?.extraCoverPackageSummary;
      }

      if (this.coverageDetails?.coverCode === '20') {
        this.coverageDetailsLabels = this.coverageDetailsLabels.filter(label => label.key !== 'avInd');
      } else if (this.coverageDetails?.coverCode === '30') {
        this.coverageDetailsLabels = this.coverageDetailsLabels.map(label => {
          if (label.key === 'avInd') {
            return { ...label, label: 'Recommended Value' };
          }
          return label;
        });
      }

      let productCode = this.isEndorsement ? this.coverageInfo.productCode : this.coverageInfo.coverage?.coveragePremium?.cngeNote?.productCode ?? null;
      let coverCode = this.coverageDetails?.coverCode;
      if (productCode && coverCode) {
        if (['080100', '080200', '080300'].includes(productCode)) {
          if (coverCode === '01') {
            // Keep avInd as 'Agreed Value'
          } else if (coverCode === '30') {
            // Update avInd to 'Recommended Value'
            this.coverageDetailsLabels = this.coverageDetailsLabels.map(label =>
              label.key === 'avInd' ? { ...label, label: 'Recommended Value' } : label
            );
          } else {
            this.coverageDetailsLabels = this.coverageDetailsLabels.filter(label => label.key !== 'avInd');
          }
        } else if (['082100', '082101'].includes(productCode)) {
          if (coverCode === '01' || coverCode === '30') {
            // Update avInd to 'Recommended Value'
            this.coverageDetailsLabels = this.coverageDetailsLabels.map(label =>
              label.key === 'avInd' ? { ...label, label: (this.isEndorsement ? 'Allianz Recommended Value' : 'Recommended Value') } : label
            );
          } else {
            this.coverageDetailsLabels = this.coverageDetailsLabels.filter(label => label.key !== 'avInd');
          }
        }
      }

      this.showEhailing = !!this.ehailingDriverList && this.ehailingDriverList.length > 0;

    } else if ((this.coverageInfo && this.viewQuotation) || (this.coverageInfo && this.viewPolicyDetails)) {
      this.coverageDetailsLabels = this.coverageDetailsLabels.filter(item => item.key !== 'coverDescription');
      this.vehicleDetails = this.coverageInfo.vehicleDetails;
      this.coverageDetails = this.coverageInfo.coverageDetails;
      this.namedDriverList = this.coverageInfo.driverNameDetails;
      this.ehailingDriverList = this.coverageInfo.ehailingDriverDetails;
      this.extraCoverPackageSummary = this.coverageInfo.extraCoverPackageSummary;

      if (this.coverageInfo?.coverageDetails?.coverCode === '20') {
        this.coverageDetailsLabels = this.coverageDetailsLabels.filter(label => label.key !== 'avInd');
      } else if (this.coverageDetails?.coverCode === '30') {
        this.coverageDetailsLabels = this.coverageDetailsLabels.map(label => {
          if (label.key === 'avInd') {
            return { ...label, label: 'Recommended Value' };
          }
          return label;
        });
      }

      this.showEhailing = !!this.ehailingDriverList && this.ehailingDriverList.length > 0;

      if (this.isRTP || this.isIssuance || this.viewPolicyDetails || this.viewQuotation) {
        const additionalLabels = [
          { key: 'excessAmount2', label: 'Risk Excess (RM)' },
          { key: 'excessAmount1', label: 'Voluntary Excess (RM)' }
        ];

        const index = this.coverageDetailsLabels.findIndex(label => label.key === 'avInd');

        this.coverageDetailsLabels = [
          ...this.coverageDetailsLabels.slice(0, index + 1),
          ...additionalLabels,
          ...this.coverageDetailsLabels.slice(index + 1)
        ];

        this.coverageDetailsLabels.push({ key: 'dealer3A', label: '3A Dealer' });
      }

      if (this.viewQuotation) {
        if (this.coverageInfo?.coverageDetails?.coverCode === '20') {
          this.coverageDetailsLabels = [
            { key: 'coverDescp', label: 'Coverage Type' },
            { key: 'sumInsured', label: 'Vehicle Sum Insured (RM)' },
            { key: 'loadingPctVehAge', label: 'Vehicle Age Loading (%)' },
            { key: 'loadingPctDrvrAge', label: 'Driver Age Loading (%)' },
            { key: 'loadingPctClmExp', label: 'Claims Experience Loading (%)' },
            { key: 'ncdPercentage', label: 'No Claim Discount (%)' },
            { key: 'prevNcdInsurer', label: 'No Claim Discount From' },
            { key: 'prevNcdVeh', label: 'No Claim Discount Vehicle No.' },
            { key: 'noClaimDiscountEffectiveDate', label: 'No Claim Discount Effective Date' },
            { key: 'dealer3A', label: '3A Dealer' }
          ];
          this.isShowMaxMin = false;
        } else {
          this.isShowMaxMin = true;
        }

        let cngeNote = this.store.selectSnapshot<any>((state) => state.SatMotorState.viewQuotation);
        let productCode = cngeNote.productCode ?? null;
        let coverCode = this.coverageDetails?.coverCode;
        if (productCode && coverCode) {
          if (['080100', '080200', '080300'].includes(productCode)) {
            if (coverCode === '01') {
              // Keep avInd as 'Agreed Value'
            } else if (coverCode === '30') {
              this.coverageDetailsLabels = this.coverageDetailsLabels.map(label =>
                label.key === 'avInd' ? { ...label, label: 'Recommended Value' } : label
              );
            } else {
              this.coverageDetailsLabels = this.coverageDetailsLabels.filter(label => label.key !== 'avInd');
            }
          } else if (['082100', '082101'].includes(productCode)) {
            if (coverCode === '01' || coverCode === '30') {
              this.coverageDetailsLabels = this.coverageDetailsLabels.map(label =>
                label.key === 'avInd' ? { ...label, label: 'Recommended Value' } : label
              );
            } else {
              this.coverageDetailsLabels = this.coverageDetailsLabels.filter(label => label.key !== 'avInd');
            }
          }
        }
      
      }

      this.minSumInsured = this.coverageDetails?.makeModelSiMin ?? '0.00';
      this.maxSumInsured = this.coverageDetails?.makeModelSiMax ?? '0.00';
      
      if(this.viewPolicyDetails){
        if(this.minSumInsured == 0.00 && this.maxSumInsured == 0.00){
          this.isShowMaxMin = false;
        }
        else{
          this.isShowMaxMin = true;
  
        }
      }
      
    }

    this.showExtraCoverage = !(
      this.extraCoverPackageSummary === undefined ||
      this.extraCoverPackageSummary === null ||
      ((this.extraCoverPackageSummary.subCovPackageDesc === null || this.extraCoverPackageSummary.subCovPackageDesc === undefined) &&
        this.extraCoverPackageSummary.rahmahPackageList.length === 0 &&
        this.extraCoverPackageSummary.topExtraCoverList.length === 0 &&
        this.extraCoverPackageSummary.selectedExtraCoverList.length === 0)
    );

    if(this.isRTP || this.isEndorsement) {
      let cngeNote;
      if (this.isRTP) {
        cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto);
      }

      let payload = {
        productCode: this.isEndorsement ? this.productCode : cngeNote.productCode,
        isRTP: this.isRTP,
        isEndorsement: this.isEndorsement
      }
      
      this.store.dispatch(new GET_VEHICLE_USE(payload)).subscribe((state) => {
        this.vehicleUseLov = this.isEndorsement? state.SatMotorState.endorsement?.step2?.vehicleUseLov : state.SatMotorState.rtp?.step2?.vehicleUseLov;
      });
    }

    this.showNamedDriver = this.namedDriverList && this.namedDriverList.length > 0;
    
  }

  getFilteredVehicleDetailsLabels(): any[] {
    return this.vehicleDetailsLabels.filter(item => {
      if (item.key === 'makeModelPrintInd') {
        const dataSource = this['vehicleDetails'];
        return dataSource.makeModelPrintInd === 'Y';
      }
      return true;
    });
  }
    
  getValue(key: string, source: 'vehicleDetails' | 'coverageDetails'): string {
    const dataSource = this[source];
    let value: any;

    switch (key) {
      case 'sumInsured':
        value = this.formatDecimal(dataSource.sumInsured);
        break;
      case 'vehicleCapacity':
        value = this.getVehicleCapacity(dataSource);
        break;
      case 'region':
        value = this.getRegion(dataSource);
        break;
      case 'vehicleUse':
        value = this.getVehicleUse(dataSource);
        break;
      case 'engineType':
        value = this.getEngineType(dataSource);
        break;
      case 'vehicleLoanType':
        value = this.getVehicleLoanType(dataSource);
        break;
      case 'makeModel':
        value = this.getMakeModel(dataSource);
        break;
      case 'makeModelPrintInd':
        value = this.getMakeModelPrintInd(dataSource);
        break;
      case 'avInd':
        value = this.getAvInd(dataSource);
        break;
      case 'prevNcdInsurer':
        value = this.getPrevNcdInsurer(dataSource.prevNcdInsurer);
        break;
      case 'ncdPercentage':
        if(this.viewPolicyDetails==true){
        value = this.formatDecimal(dataSource.ncdPct);
        }
        else{
          value = this.formatDecimal(dataSource.ncdPercentage);
        }
        break;
      case 'vehiclePurchaseDate':
        value = this.formatDate(dataSource.vehiclePurchaseDate);
        break;
      case 'prevNcdVeh':
        value = this.getPrevNcdVeh(dataSource.prevNcdVeh);
        break;
      case 'noClaimDiscountEffectiveDate':
        value = this.getNcdEffectiveDate(dataSource.noClaimDiscountEffectiveDate);
        break;
      case 'dealer3A':
        value = this.getDealer3A(dataSource);
        break;
      case 'excessAmount1':
        value = this.getVoluntaryExcess(dataSource);
        break;
      case 'excessAmount2':
        value = this.getRiskExcess(dataSource);
        break;
      case 'loadingPctVehAge':
        value = this.getloadingPct(dataSource, 'vehage');
        break;
      case 'loadingPctDrvrAge':
        value = this.getloadingPct(dataSource, 'drvrage');
        break;
      case 'loadingPctClmExp':
        value = this.getloadingPct(dataSource, 'clmexp');
        break;
      default:
        value = dataSource[key];
        break;
    }
    return this.formatData(value);
  }

  getloadingPct(dataSource: any, code: string): any {
    switch (code) {
      case 'vehage':
        return dataSource.loadingPctVehAge || '0.00';
      case 'drvrage':
        return dataSource.loadingPctDrvrAge || '0.00';
      case 'clmexp':
        return dataSource.loadingPctClmExp || '0.00';
      default:
        return '0.00';
    }
  }

  formatData(value: any): string {

    if (value === null || value === undefined || value === '') {
        return 'N/A';
    }

    if (typeof value === 'string') {
        return value.toUpperCase();
    }

    if (typeof value === 'number') {
        return value.toString().toUpperCase();
    }

    if (value instanceof Date) {
        return moment(value).format('DD/MM/YYYY').toUpperCase();
    }

    if (typeof value === 'object') {
        return JSON.stringify(value).toUpperCase();
    }

    return value.toString().toUpperCase();
  }

  formatDecimal(value: any): string {
    if (value !== null && value != '') {
      let formattedValue = parseFloat(value).toFixed(2);
      return Number(formattedValue).toLocaleString('ms-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return '0.00';
  }

  getVehicleCapacity(dataSource: any): string {
    let vehicleCapacity = dataSource.vehicleCapacity;

    if (typeof vehicleCapacity === 'string' && /^\d+$/.test(vehicleCapacity)) {
      vehicleCapacity = parseInt(vehicleCapacity, 10);
    }

    if (vehicleCapacity !== null && Number.isInteger(vehicleCapacity)) {
      return vehicleCapacity.toLocaleString() + ' / ' + this.formatData(dataSource.vehicleUom);
    }

    return dataSource.vehicleCapacity + ' / ' + dataSource.vehicleUom;
  }

  getRegion(dataSource: any): string {
    return dataSource.region === 'W' ? 'West Malaysia' : 'East Malaysia (Sabah, Sarawak, Labuan)';
  }

  getVehicleUse(dataSource: any): string {
    if(this.viewPolicyDetails){
      this.vehicleUseLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data?.vehicleUseLov);
    } else {
      if(!this.vehicleUseLov) {
        this.vehicleUseLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.vehicleUseLov);
      }
    }
    if (this.vehicleUseLov) {
      const vehicleUseItem = this.vehicleUseLov.find(item => item.code === dataSource.vehicleUse);
      return vehicleUseItem ? vehicleUseItem.description : 'N/A';
    }
    // else if(this.viewPolicyDetails){
    //   const vehicleUseItem = this.vehicleDetails.find(item => item.code === dataSource.vehicleUse);
    //   return vehicleUseItem ? vehicleUseItem.description : 'N/A';
    // }
    return 'N/A';
  }

  getEngineType(dataSource: any): string {
    const engineTypeItem = this.lov.engineTypeList.find(item => item.code === dataSource.engineType);
    return engineTypeItem ? engineTypeItem.descp : 'N/A';
  }

  getVehicleLoanType(dataSource: any): string {
    const vehicleLoanTypeItem = this.lov.vehicleLoanTypeList.find(item => item.code === dataSource.vehicleLoanType);
    return vehicleLoanTypeItem ? vehicleLoanTypeItem.descp : 'N/A';
  }

  getMakeModel(dataSource: any): string {
    if (dataSource.vehModelNvic && dataSource.vehModelNvic.trim() !== '') { //new model
      if (dataSource.variantFullDesc) {
        return dataSource.piamMakeModelDesc + ' ' + dataSource.variantFullDesc;
      }
      return dataSource.piamMakeModelDesc;

    } else if (dataSource.vehicleModel && dataSource.vehicleModel.trim() !== '') { //old model
      return dataSource.vehicleModelDescp;
    }
    return null;
  }

  getMakeModelPrintInd(dataSource: any): string {
    if (dataSource.makeModelPrintInd === 'Y') {
      return dataSource.piamMakeModelDesc + ' ' + (dataSource.variantDesc?dataSource.variantDesc: "");
    }
    return '';
  }

  getAvInd(dataSource: any): string {
    return dataSource.avInd ? 'YES' : 'NO';
  }

  getDealer3A(dataSource: any): string {
    return dataSource.dealer3A ? 'YES' : 'NO';
  }

  getRiskExcess(dataSource: any): string {
    if ( this.viewPolicyDetails && dataSource?.htgeCoverExcessList &&
      Array.isArray(dataSource.htgeCoverExcessList) && dataSource.htgeCoverExcessList.length > 0 &&
      dataSource.htgeCoverExcessList[0]?.excessAmount !== undefined
    ){
      return dataSource.htgeCoverExcessList[0].excessAmount.toFixed(2);
    }
    else if( dataSource?.cngeCoverExcessList &&
      Array.isArray(dataSource.cngeCoverExcessList) && dataSource.cngeCoverExcessList.length > 0 &&
      dataSource.cngeCoverExcessList[0]?.excessAmount !== undefined
    ) {
      return dataSource.cngeCoverExcessList[0].excessAmount.toFixed(2);
    }
    else{
      return '0.00';
    }
  }


  getVoluntaryExcess(dataSource: any): string {
    if (this.viewPolicyDetails && dataSource?.htgeCoverExcessList &&
      Array.isArray(dataSource.htgeCoverExcessList) && dataSource.htgeCoverExcessList.length > 0 &&
      dataSource.htgeCoverExcessList[1]?.excessAmount !== undefined
    ) {
      return dataSource.htgeCoverExcessList[1].excessAmount.toFixed(2);

    }
    else if ( dataSource?.cngeCoverExcessList &&
      Array.isArray(dataSource.cngeCoverExcessList) && dataSource.cngeCoverExcessList.length > 0 &&
      dataSource.cngeCoverExcessList[1]?.excessAmount !== undefined
    ) {
      return dataSource.cngeCoverExcessList[1].excessAmount.toFixed(2);
    }
    return '0.00';
  }

  getPrevNcdInsurer(prevNcdInsurerVal) {
    let prevNcdInsurer = this.coverageInfo.coverage?.coveragePremium?.cngeNote?.riskList[0]?.prevNcdInsurer
    if(!prevNcdInsurer){
      prevNcdInsurer = prevNcdInsurerVal
    } 

    const prevNcdInsurerItem = this.lov.insurerList.find(item => item.code === prevNcdInsurer);
    return prevNcdInsurerItem ? prevNcdInsurerItem.descp : 'N/A';
  }

  getNcdEffectiveDate(noClaimDiscountEffectiveDate: any){
    let ncdEffDate =  this.coverageInfo.coverage?.coveragePremium?.cngeNote?.riskList[0]?.ncdEffDate
    if(noClaimDiscountEffectiveDate){
      return this.formatDate(noClaimDiscountEffectiveDate);
    } else if(ncdEffDate){
      return this.formatDate(ncdEffDate);
    }
    return 'N/A';
  }

  getPrevNcdVeh(prevNcdVehVal: any){
    let prevNcdVeh =  this.coverageInfo.coverage?.coveragePremium?.cngeNote?.riskList[0]?.prevNcdVeh
    if(!prevNcdVeh){
      prevNcdVeh = prevNcdVehVal;
    }
    return prevNcdVeh;
  }

  formatDate(dateVal: any): any {
    if (!dateVal) {
      return null;
    }

    const date = new Date(dateVal);

    if (isNaN(date.getTime())) {
      return null;
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formatDriverAgeAndId(value) {
    if(!value || value == 0) {
      return 'N/A';
    }
    return value;
  }

  formatSumInsured(data) {
    if(data && data != '' && data != undefined) {
      return data.toLocaleString();
    }
    return data;
  }

  // getMRWDesc(code: any){
  //   const item = this.motorRoadWarriroList.find(item => item.code === code);
  //   return item ? item.descp : code;
  // }

  // getMERWDesc(code: any){
  //   const item = this.motorEnhancedRoadWarriroList.find(item => item.code === code);
  //   return item ? item.descp : code;
  // }

  // getDriverPassenger(code: any){
  //   const item = this.driverPassangerList.find(item => item.code === code);
  //   return item ? item.descp : code;
  // }

  // getDropDownDesc(subCoverCode: any, code: any) {
  //   if (subCoverCode === 'PAB-RW') {
  //     return this.getMRWDesc(code);
  //   } else if (subCoverCode === 'PAB3') {
  //     return this.getDriverPassenger(code);
  //   } else if (subCoverCode === 'PAB-ERW') {
  //     return this.getMERWDesc(code);
  //   } else {
  //     return code;
  //   }
  // }

  removeDecimal(value: any){
    if(value){
      return value.replace(/\.00(?=\D|$)/, "");
    }
    return value;
  }

  removeNewLinesFromOpus(value: any,subCoverCode: any) {
    if (value) {
      // if (subCoverCode === 'PAB3' && value.includes('$NEWLINES')) {
      //   return value.replace(/\$NEWLINESS.*/, "");
      // } else 
      
      if (value && value.includes("$NEWLINES")) {
        return value.replace("$NEWLINES", "<br>");
      }
    }
    return value;
  }

  isSameAvSumInsured() {
    return this.minSumInsured === this.maxSumInsured;
  }

} 
