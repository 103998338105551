<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
                Coverage -Vehicle Sum Insured
            </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <div nxLayout="grid nopadding">

            <div [formGroup]="vehicleSumInsuredForm">
                <!-- ///////////////////////////////////////////////////// -->
                <ng-container>
                    <div nxLayout="grid" class="grid_remove_padding coverage nx-margin-bottom-m">
                        <div nxRow class="blue_panel">
                            <div nxCol="12" class="agreed_value">
                                <div nxLayout="grid" class="grid_remove_padding">
                                    <div nxRow rowAlignItems="start,start">
                                        <div nxCol="12,12,6">

                                            <nx-formfield label="{{ 'COVERAGE.FIELD.SUM_INSURED_RM' | translate }}">
                                                <input nxInput formControlName="sumInsured"
                                                    (blur)="onChangeForm($event)" appCusCurrencyFormat />
                                                @if ((isPrivateCar && (isComprehensive || isThirdPartyFireTheft)) || (isMotorcycle && (isComprehensive || isThirdPartyFireTheft))) {
                                                <span nxFormfieldHint nxCopytext="small" class="std-ta-left">
                                                    <i>{{ 'COVERAGE.FIELD.RECOMMENDED_SI_RANGE' | translate: {
                                                        makeModelSIMin,
                                                        makeModelSIMax } }}</i>
                                                  </span>
                                                }
                                                <!-- <nx-error nxFormfieldError><strong>Please note:</strong> this field is
                                                    required!</nx-error> -->
                                                @if (underInsurenceApplies) {
                                                <nx-error nxFormfieldNote>
                                                    <strong>Underinsurance applies</strong>
                                                </nx-error>
                                                }
                                            </nx-formfield>

                                            <p nxCopytext="medium">
                                                Previous Sum Insured (RM): {{previousSumInsured}}
                                            </p>

                                        </div>
                                        <div nxCol="12,12,6" class="m_padding_top_10">
                                            @if (isPrivateCar && isComprehensive) {
                                            <nx-checkbox class="nx-margin-bottom-xs" formControlName="avInd"
                                                [checked]="avIndChecked"> {{ 'COVERAGE.FIELD.AGREED_VALUE' | translate
                                                }} </nx-checkbox>
                                            } @else {
                                            <nx-checkbox class="nx-margin-bottom-xs" formControlName="avInd"
                                                [checked]="avIndChecked"> {{ 'COVERAGE.FIELD.RECOMMENDED_VALUE' |
                                                translate }} </nx-checkbox>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>

        </div>
    </nx-expansion-panel>
</nx-accordion>