import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CdkTextareaAutosize, TextFieldModule } from "@angular/cdk/text-field";
// import { ExternalScrollbarComponent } from "../../shared/modules/external-scrollbar/external-scrollbar.component";

// declare var $: any;

@Component({
  selector: "app-vehicle-details",
  standalone: true,
  templateUrl: "./vehicle-details.component.html",
  styleUrl: "./vehicle-details.component.scss",
  imports: [SharedModule, TextFieldModule],
})
export class VehicleDetailsComponent {
  // scroll bar
  @Input() data!: any;

  show = false;
  toggleshow() {
    this.show = !this.show;
  }

  endorsement_table = [
    {
      no: "1",
      type: "CHANGE OF ENGINE",
      effective_date: "26/12/2023",
      exp_date: "27/12/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "2",
      type: "EXTENSION PRORATE",
      effective_date: "20/12/2023",
      exp_date: "19/12/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "3",
      type: "EXTRA BENEFITS",
      effective_date: "15/12/2023",
      exp_date: "14/12/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "4",
      type: "NAMED DRIVER",
      effective_date: "29/10/2023",
      exp_date: "28/10/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "5",
      type: "NAMED DRIVER",
      effective_date: "09/05/2023",
      exp_date: "08/05/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
  ];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkTableOverflow();
  }

  @ViewChild("table-desc") tableDesc!: ElementRef;
  @ViewChildren("scrolldivs", { read: ElementRef })
  scrolldivs!: QueryList<ElementRef>;
  shouldHideTableDesc: boolean = false;

  checkTableOverflow() {
    this.scrolldivs.forEach((elementRef: ElementRef) => {
      const element = elementRef.nativeElement;
      // console.log(element)
      if (element.scrollWidth > element.clientWidth + 1) {
        // console.log(element.classList);
        this.renderer.removeClass(element, "hide-scrollbar"); // Remove the class to show the scrollbar
        this.shouldHideTableDesc = false; // Show the table description
      } else {
        this.renderer.addClass(element, "hide-scrollbar"); // Add the class to hide the scrollbar
        this.shouldHideTableDesc = true; // Hide the table description
      }
    });
  }

  // Table Draft //
  tableDraftElements = [
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
  ];
}
