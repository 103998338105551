import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { Store } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-premium-information',
  templateUrl: './premium-information.component.html',
  styleUrl: './premium-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PremiumInformationComponent implements OnInit {
  
  @Input() premiumInfo: any;
  @Input() isApproval: any;
  @Input() isEndorsement;
  @Input() isRTP;
  @Input() isIssuance;
  data: any = {};
  @Input() coverageType;
  @Input() isPackageSelected;
  @Input() viewOnly;
  @Input() viewPolicyDetails :any;

  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.premiumInfo) {
      this.data = this.premiumInfo;
    } 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['premiumInfo'] && changes['premiumInfo'].currentValue) {
      this.data = changes['premiumInfo'].currentValue;
      this.cdr.markForCheck();
    }
    
  }

  formatNumberWithCommas(number) {
      const [integerPart, decimalPart] = number.toString().split('.');
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  showLoading() {
    return this.data.coverCode === '20';
  }

  get isSelectReplacementBMW() {
    if(this.premiumInfo.subCoverList) {
      return this.premiumInfo.subCoverList.some(a => a.subCoverCode === "A203-3");
    }else {
      return false;
    }

  }

}
