<div class="std-ta-center">

    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-light">
            {{message}}
        </p>

    </div>

    <button nxModalClose nxButton="primary" type="button">OK</button>

</div>
