import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewChild, effect, runInInjectionContext, signal } from '@angular/core';
import { GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { BranchService } from './branch.service';
import { Subject } from 'rxjs/internal/Subject';
import { Branch } from './branch';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment.development';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { CONVERT_GEOLOCATION, SET_SELECTED_LOCATION_DETAILS } from '../geolocation/store/geolocation.action';
import { DefaultAddressForConversionPayload, IGeocodingConversionPayload } from '../geolocation/store/geolocation.interface';
import { CustomerPartnerDetailsComponent } from '../customer-partner/customer-partner-details/customer-partner-details.component';
import { UPDATE_GEO_INDICATOR } from '@store/sat-motor/sat-motor.action';

declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: 'app-branches-map',
  standalone: true,
  imports: [
    CommonModule,
    GoogleMapsModule,
    RouterModule,
    SharedModule,
  ],
  providers: [BranchService],
  templateUrl: './branches-map.component.html',
  styleUrl: './branches-map.component.scss'
})
export class BranchesMapComponent  implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  // @ViewChild(CustomerPartnerDetailsComponent) geoInd!: CustomerPartnerDetailsComponent;
  @ViewChild("search", { static: false })
  searchInput!: ElementRef<HTMLInputElement>;

  @Output() formPopulated = new EventEmitter<any>();
  @Input() mapVisible: boolean = false;
  @Input()
  get autoCompleteSearchOptions(): google.maps.places.AutocompleteOptions {
    if (!this._autoCompleteSearchOptions) {
      this._autoCompleteSearchOptions = {
        fields: [
          "formatted_address",
          "geometry",
          "name",
          "address_components",
          "place_id",
          "plus_code",
        ],
        strictBounds:true, 
        componentRestrictions:{country:'my'}
      };
    }
    return this._autoCompleteSearchOptions;
  }

  set autoCompleteSearchOptions(
    options: google.maps.places.AutocompleteOptions
  ) {
    this._autoCompleteSearchOptions = {
      ...this.autoCompleteSearchOptions,
      ...options,
    };
  }

  private _autoCompleteSearchOptions: google.maps.places.AutocompleteOptions;
  private searchQuerySubject: Subject<string> = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();
  autocompleteSuggestions: Array<google.maps.places.AutocompletePrediction>;
  branches = signal<Branch[]>([]);
  center: google.maps.LatLngLiteral = { lat: 3.1390, lng: 101.6869 }; // Center of Kuala Lumpur, Malaysia
  zoom = 12; // Adjust zoom level as needed
  markers: BranchMapMarker[] = [];
  selectedBranch = signal<Branch | null>(null);
  googleMapsLoaded = signal<boolean>(false);
  disabled: boolean = false;
  geocodedInd: boolean;
  
  showSuggestions: boolean = false;
  matchedSubstrings: number;
  searchAddress = '';
  searchMode: 'address' | 'latLng' = 'address';
  latitude: string = '';
  longitude: string = '';
  geoMessageErr: String;
  isGeoMessageErr: boolean = false;
  COUNTRY_MALAYSIA = 'Malaysia';
  geoPostCodeErr = 'Address is incomplete, please re-select with higher accuracy.';
  geoCountryErr = 'Countries other than Malaysia. Not allowed to proceed.';
  mapOptions: google.maps.MapOptions = {
    fullscreenControl: false
  };
  

  constructor(
    private branchService: BranchService, 
    @Inject(Injector) private injector: Injector,
    private store: Store) {
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.markers = this.getMarkers();
      }, { allowSignalWrites: true });
    });
  }

  ngOnInit() {
    this.loadGoogleMapsScript();
  }

  loadGoogleMapsScript() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  ngAfterViewInit() {
    window.initMap = this.initMap.bind(this);
    this.watchSearchQueryChange();

    if (this.searchInput) {
      this.prefillSearchAddress();
    }
  }

  onSearchModeChange(mode) {
    if (this.markers.length > 0) {
      const marker = this.markers[0];
      const lat = marker.position.lat;
      const lng = marker.position.lng;

      if (mode === 'latLng') {
        this.showSuggestions = false;
        // this.latitude = lat.toString();
        // this.longitude = lng.toString();
        // this.getAddress(lat, lng, null);
      } else if (mode === 'address') {
        this.showSuggestions = true;
        this.searchQuerySubject.next(this.searchAddress);
        this.showSuggestedAddress(this.searchAddress);
        //this.getAddress(lat, lng, null);
        //this.searchAddress
      }
    } else {
      this.searchAddress = '';
      this.latitude = '';
      this.longitude = '';
    }
    
  }

  watchSearchQueryChange() {
    this.searchQuerySubject
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchQuery) => {
        this.autocompleteSuggestions = [];
        if (searchQuery) {
          this.showSuggestions = true;
          this.showSuggestedAddress(searchQuery);
        } else {
          this.showSuggestions = false;
        }
      });
  }

  showSuggestedAddress(searchQuery){
    const autocompleteService =
    new google.maps.places.AutocompleteService();
    const request = {
      input: searchQuery,
      ...this.autoCompleteSearchOptions,
    };
    autocompleteService.getPlacePredictions(
      request,
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.autocompleteSuggestions = predictions.map((prediction) => {
            this.matchedSubstrings = prediction.matched_substrings[0] ? 
              prediction.matched_substrings[0].length : 0;
            return prediction;
          });
        }
      }
    );
  }

  prefillSearchAddress() {
    new google.maps.event.trigger(this.searchInput, "focus");
    new google.maps.event.trigger(this.searchInput, "keydown", {
      keyCode: 13,
    });
    let placesService = new google.maps.places.PlacesService(
      this.searchInput.nativeElement
    );
  
    const request = {
      fields: [
        "formatted_address",
        "geometry",
        "name",
        "place_id",
        "plus_code",
      ],
      query: this.searchAddress,
    };
    placesService.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
        this.setMarkerFromGeometry(results[0]);
        //this.internalGeocodingConversion(results[0]);
      } else {
        console.error('Error fetching place details:', status);
      }
    });
  }

  setMarkerFromGeometry(result) {
    if (result.geometry && result.geometry.location) {
      this.markers = [];
  
      const marker: BranchMapMarker = {
        label: '',
        position: { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() },
        title: result.name || 'New Marker',
        options: { animation: google.maps.Animation.DROP },
        branch: null, 
      };
      this.markers.push(marker);
      this.markers = [...this.markers];
    }
  }

  searchByLatLng() {
    const lat = parseFloat(this.latitude);
    const lng = parseFloat(this.longitude);
    if (!isNaN(lat) && !isNaN(lng)) {
      const mapMouseEvent: google.maps.MapMouseEvent = {
        latLng: new google.maps.LatLng(lat, lng),
        domEvent: undefined,
        stop: () => {}
      };
  
      this.zoom = 12;
      this.addMarker(mapMouseEvent, null);
    } else {
      console.log('Please enter valid Latitude and Longitude');
    }
  }
  
  
  getPlaceDetails(placeId: string, setMarker: boolean) {
    let placeService = new google.maps.places.PlacesService(this.searchInput.nativeElement);
    placeService.getDetails({ placeId: placeId }, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.store.dispatch(new UPDATE_GEO_INDICATOR(true));
        if (setMarker && result.geometry && result.geometry.location) {
          const location = result.geometry.location;
          const mapMouseEvent: google.maps.MapMouseEvent = {
            latLng: location,
            domEvent: undefined,
            stop: () => {}
          };
          this.addMarker(mapMouseEvent, result);
          this.zoom = 16;
          const additionalData = { newProperty: true };
        }
        //this.internalGeocodingConversion(result);
      } else {
        console.error('Error fetching place details:', status);
      }
    });
  }

  internalGeocodingConversion(address: google.maps.places.PlaceResult) {
    this.isGeoMessageErr = false;
    let payload = this.formatAddressComponentForPayload(
      address.address_components
    );
    payload = {
      ...payload,
      formatted_address: address.formatted_address,
      name: address.name,
      clientGeoPlusCode: address.plus_code ? address.plus_code.global_code.toString() : "",
      clientGeoLatitude: address.geometry ? address.geometry.location.lat().toString() : "",
      clientGeoLongitude: address.geometry ? address.geometry.location.lng().toString() : "",
      inputLatitude: payload.clientGeoLatitude,
      inputLongitude: payload.clientGeoLongitude,
    //  geocodedInd :true
    };
    console.warn('geo address :', address, "formatted address :", payload);
    if(payload.postal_code && this.COUNTRY_MALAYSIA === payload.country){
      this.store.dispatch(new CONVERT_GEOLOCATION(payload)).subscribe(() => {
        const geolocation = this.store.selectSnapshot((state)=> state.GeolocationState.selectedLocation.convertedResponse)
        const setLocationPayload = {
          ...geolocation,
          clientGeoPlaceId: address.place_id,
          clientGeoFormattedAddress: address.formatted_address,
          // geocodedInd:true
        };
        this.formPopulated.emit(setLocationPayload);
        this.store.dispatch(
          new SET_SELECTED_LOCATION_DETAILS(setLocationPayload)
        );
      });
      //this.geolocationService.onSelectedLocation();
    }else{
      //const addressComponents = results[0].address_components;
      const data = this.extractAddressData(address.address_components);
      const setLocationPayload = {
        clientUnitNo: data.unitNo,
        clientAddress1: data.address1,
        clientAddress2: data.address2,
        clientAddress3: data.address3,
        clientPostCode: data.postCode,
        clientCityDesc: data.city,
        clientStateDesc: data.state,
        clientCountryDesc: data.country
      };
      this.formPopulated.emit(setLocationPayload);
      // this.isGeoMessageErr = true;
      // if(COUNTRY_MALAYSIA !== payload.country){
      //   this.geoMessageErr = geoCountryErr;
      // } else{
      //   this.geoMessageErr = geoPostCodeErr;
      // }
      // let emptyLocation: IGeocodingConversionResponse;
      // this.store.dispatch(new SET_SELECTED_LOCATION_DETAILS(emptyLocation)).subscribe(state=>{
      //   this.geolocationService.onSelectedLocation();
      // })
    }
  }
  

  formatAddressComponentForPayload(
    address: google.maps.GeocoderAddressComponent[]
  ): IGeocodingConversionPayload {
    let formatted: IGeocodingConversionPayload =
      new DefaultAddressForConversionPayload();
    address.map((item) => {
      if (item.types.includes("administrative_area_level_1")) {
        formatted.administrative_area_level_1 = item.long_name;
      }
      if (item.types.includes("locality")) {
        formatted.locality = item.long_name;
      }
      if (item.types.includes("country")) {
        formatted.country = item.long_name;
      }
      if (item.types.includes("postal_code")) {
        formatted.postal_code = item.long_name;
      }
      return item;
    });

    return formatted;
  }
  

  onInputChange(event) {
    const searchQuery = event.target.value;
    this.searchQuerySubject.next(searchQuery);
  }

  onInputFocus() {
    this.showSuggestions = true;
  }

  onInputBlur() {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 1000);
  }

  onSuggestionClick(selectedSuggestion: google.maps.places.AutocompletePrediction) {
    this.showSuggestions = false;
    this.searchAddress = selectedSuggestion.description;
    this.getPlaceDetails(selectedSuggestion.place_id, true);
  }

  initMap() {
    this.branches.set(this.branchService.getBranches());
    this.googleMapsLoaded.set(true);
    
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.markers = this.getMarkers();
      }, { allowSignalWrites: true });
    });
  }

  getMarkers() {
    return this.branches()
      .map((branch) => {
        const marker: BranchMapMarker = {
          label: '',
          position: { lat: branch.lat, lng: branch.lng },
          title: branch.name,
          options: { animation: google.maps.Animation.DROP },
          branch: branch,
        };
        return marker;
      })
      .filter(
        (marker) => !isNaN(marker.position.lat) && !isNaN(marker.position.lng)
      );
  }

  addMarker(event: google.maps.MapMouseEvent, result: google.maps.places.PlaceResult) {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
  
    if (this.searchMode === 'latLng') {
      this.latitude = lat.toString();
      this.longitude = lng.toString();
      this.getAddress(lat, lng, result);
    } else if (this.searchMode === 'address') {
      this.getAddress(lat, lng, result);
    }
    console.log(`Latitude: ${lat}, Longitude: ${lng}`);
  
    this.markers = [];
  
    const marker: BranchMapMarker = {
      label: '',
      position: { lat, lng },
      title: 'New Marker',
      options: { animation: google.maps.Animation.DROP },
      branch: null,
    };
    this.markers.push(marker);
  
    this.markers = [...this.markers];
  
    this.center = { lat, lng };

    //const data = { /* your data here */ }; 
    //this.formPopulated.emit(data);
    this.store.dispatch(new UPDATE_GEO_INDICATOR(true));
  }
  

  getAddress(lat: number, lng: number, result: google.maps.places.PlaceResult) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: lat, lng: lng };

    if (result && result?.formatted_address) {
      this.searchAddress = result.formatted_address;
      this.internalGeocodingConversion(result as google.maps.places.PlaceResult);
    } else {
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            this.searchAddress = results[0].formatted_address;
            const addressComponents = results[0].address_components;
            //const data = this.extractAddressData(addressComponents);
            
            this.internalGeocodingConversion(results[0] as google.maps.places.PlaceResult);
          } else {
            console.error('No results found');
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    }
  }
  
  extractAddressData(addressComponents: any) {
    let data: any = {
      unitNo: '',
      address1: '',
      address2: '',
      address3: '',
      postCode: '',
      city: '',
      state: '',
      country: ''
    };
  
    addressComponents.forEach(component => {
      const types = component.types;
      if (types.includes('street_number')) {
        data.unitNo = component.long_name;
      }
      if (types.includes('route') || types.includes('plus_code')) {
        data.address1 = component.long_name;
      }
      if (types.includes('sublocality_level_1') || types.includes('locality')) {
        data.city = component.long_name;
      }
      if (types.includes('administrative_area_level_1')) {
        data.state = component.long_name;
      }
      if (types.includes('postal_code')) {
        data.postCode = component.long_name;
      }
      if (types.includes('country')) {
        data.country = component.long_name;
      }

    });
  
    return data;
  }  
  
  searchLocation() {
    if (!this.searchAddress) {
      return;
    }
  
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: this.searchAddress }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const location = results[0].geometry.location;
        this.center = { lat: location.lat(), lng: location.lng() };
        this.zoom = 15;
  
        const mapMouseEvent: google.maps.MapMouseEvent = {
          latLng: location,
          domEvent: undefined,
          stop: () => {}
        };
  
        this.addMarker(mapMouseEvent, null);
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  openInfoWindow(branch: Branch, marker: MapMarker): void {
    this.selectedBranch.set(branch);
    if (this.infoWindow) {
      this.infoWindow.open(marker);
    }
  }
  clearAddress(){
    this.searchAddress ='';
    this.center = { lat: 3.1390, lng: 101.6869 }; 
    this.zoom = 12; 
    this.markers = []; 
    this.geocodedInd = false;

  }
}

export interface BranchMapMarker {
  branch: Branch | null;
  position: {
    lat: number;
    lng: number;
  };
  title: string;
  options: {
    animation: google.maps.Animation;
  };
  label: string;
  click?: () => void;
}
