import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-endorsement-narration',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './endorsement-narration.component.html',
  styleUrl: './endorsement-narration.component.scss'
})
export class EndorsementNarrationComponent implements OnInit {
  @Input() endtNarration: string;
  safeEndtNarration: SafeHtml;

  constructor(
    private sanitizier: DomSanitizer
  ) {}

  ngOnInit() {
    this.safeEndtNarration = this.sanitizier.bypassSecurityTrustHtml(this.endtNarration);
  }
}
