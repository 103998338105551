<table nxTable cellpadding="0" class="summary_table">
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Customer Type</span>
      </td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{cpData.clientType === 'I' ? 'INDIVIDUAL' : 'COMPANY'}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">Name</span></td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{cpData.clientName}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">Nationality</span></td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{cpData.clientNationality}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{cpData.idValue1}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">Gender</span></td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{cpData.clientGender}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Marital Status</span>
      </td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{cpData.clientMaritalStatus}}</span>
      </td>
    </tr>
    <tr nxTableRow>
      <td nxTableCell><span nxCopytext="normal">Age</span></td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{cpData.clientAge}}</span>
      </td>
    </tr>
  </table>