<div nxModalContent>
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
        Do you want to print clause wording?
    </h3>
</div>

<button nxModalClose class="button_popup_2btn" nxButton="primary" type="button">
    YES
</button>
<button nxModalClose nxButton="primary" type="button">
    NO
</button>