<div nxLayout="grid nopadding">
    <div nxRow>
      <div nxCol="12" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="nx-font-weight-bold">Referral Remarks:</nx-data-display-label>
            @if(remarksInfo.referral && remarksInfo.referral.length !== 0) {
              @for (remark of remarksInfo.referral; track remark) {
                <p nxCopytext="large">{{ remark.displayReferRemark }}</p>
              }
            } @else {
              <p nxCopytext="large">N/A</p>
            }
        </nx-data-display>
      </div>
    </div>

    @if (isApprovalView) {
    <div nxRow>
      <div nxCol="12" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="nx-font-weight-bold">Admin Remarks:</nx-data-display-label>
          @if(remarksInfo.admin && remarksInfo.admin.length !== 0) {
          @for (remark of remarksInfo.admin; track remark) {
          <p nxCopytext="large">{{ remark.displayReferRemark }}</p>
          }
          }@else {
          <p nxCopytext="large">N/A</p>
          }
        </nx-data-display>
      </div>
      </div>
      }

    @if (isQuotationView) {
      <div nxRow>
        <div nxCol="12" class="nx-margin-bottom-2m">
          <nx-data-display size="small">
            <nx-data-display-label class="nx-font-weight-bold">Cancellation Remarks:</nx-data-display-label>
            <p nxCopytext="large">{{remarksInfo.cancel ? remarksInfo.cancel : 'N/A'}}</p>
          </nx-data-display>
        </div>
      </div>
    }

    
    <div nxRow>
      <div nxCol="12" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="nx-font-weight-bold">Approval Remarks:</nx-data-display-label>
          <p nxCopytext="large">{{remarksInfo.approval ? remarksInfo.approval : 'N/A'}}</p>
        </nx-data-display>
      </div>
    </div>

    <div nxRow>
      <div nxCol="12">
        <nx-data-display size="small">
          <nx-data-display-label class="nx-font-weight-bold">Updated by: {{remarksInfo.updatedBy}}
          </nx-data-display-label>
        </nx-data-display>
      </div>
    </div>
  </div>