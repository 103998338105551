import { Routes } from "@angular/router";
import { AgentsComponent } from "./pages/agents/agents.component";
import { PdpaComponent } from "./pages/pdpa/pdpa.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";

import { SummaryComponent } from "./pages/summary/summary.component";
import { CompleteComponent } from "./pages/complete/complete.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { EnquiryComponent } from "./pages/enquiry/enquiry.component";

import { VehicleDetailsComponent } from "./pages/vehicle-details/vehicle-details.component";
import { EndorsementPdpaComponent } from "./pages/endorsement-pdpa/endorsement-pdpa.component";
import { ReportComponent } from "./pages/report/report.component";
import { DownloadComponent } from "./pages/download/download.component";
import { PolicyEnquiryComponent } from "./pages/policy-enquiry/policy-enquiry.component";
import { IsmPolicyComponent } from "./pages/ism-policy/ism-policy.component";
import { ProductDetailsComponent } from "./pages/download/product-details/product-details.component";
import { UnificationComponent } from "./pages/unification/unification.component";
import { CoverageComponent } from "./pages/coverage/coverage.component";
import { CustomerPartnerComponent } from "./pages/customer-partner/customer-partner.component";


export const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  { path: "dashboard", component: DashboardComponent },
  { path: "agents", component: AgentsComponent },
  { path: "pdpa", component: PdpaComponent },
  { path: "customer-partner", component: CustomerPartnerComponent },
  { path: "summary", component: SummaryComponent },
  { path: "complete", component: CompleteComponent },
  { path: "progress-bar", component: ProgressBarComponent },
  { path: "enquiry", component: EnquiryComponent },
  { path: "enquiry/:type", component: EnquiryComponent },
  { path: "policy-enquiry", component: PolicyEnquiryComponent },
  { path: "coverage", component: CoverageComponent },
  { path: "vehicle", component: VehicleDetailsComponent },
  { path: "endorsement-pdpa", component: EndorsementPdpaComponent },
  { path: "report", component: ReportComponent },
  { path: "download", component: DownloadComponent },
  { path: "product-details", component: ProductDetailsComponent },
  { path: "ism-policy", component: IsmPolicyComponent },
  { path: "agent-additional-info", component: UnificationComponent },
];
