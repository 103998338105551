<!-- Payment Details -->
    <button (click)="addPaymentMode()" nxPlainButton type="button" class="nx-margin-right-m nx-margin-top-m">
      <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>ADD PAYMENT MODE
    </button>
    <div class="nx-margin-top-m">
      <app-external-scrollbar targetSyncScroll="3" [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
      <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3 syncScroll="3">
        <table nxTable zebra cellpadding="0">
          <thead>
            <tr nxTableRow>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Payment Mode</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Reference(s)</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold">Payment Made To</span>
              </th>
              <th nxHeaderCell class="std-ta-right">
                <span nxCopytext="medium" class="nx-font-weight-semibold">Amount Paid (RM)</span>
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold"></span>
              </th>
            </tr>
          </thead>
          <tbody>
            @for(payment of (paymentList$ | async); track payment) {
            <tr nxTableRow>
              <td nxTableCell>
                <p nxCopytext="medium">{{ getPaymentModeDescp(payment.paymentMode) }}</p>
              </td>
              <td nxTableCell>
                @if (payment.payInSlip && payment.paymentMode != 'CC') {
                  <p nxCopytext="medium">Pay in Slip/Red No. : {{ payment.payInSlip | uppercase }}</p>
                }
                @if (payment.cdmId != null && payment.cdmId != '') {
                  <p nxCopytext="medium">CDM ID/BRCD : {{payment.cdmId | uppercase}}</p>
                }
                @if (payment.seqNo != null && payment.seqNo != '') {
                  <p nxCopytext="medium">SEQ No. : {{payment.seqNo | uppercase}}</p>
                }
                @if (payment.issueBank != null && payment.issueBank != '') {
                  <p nxCopytext="medium">Issuing Bank Name : {{getIssuingBankNameDescp(payment.issueBank) | uppercase}}</p>
                }
                @if (payment.issueBankBranch != null && payment.issueBankBranch != '') {
                  <p nxCopytext="medium">Issuing Bank Branch: {{payment.issueBankBranch | uppercase}}</p>
                }
                @if (payment.chequeNo != null && payment.chequeNo != '') {
                  <p nxCopytext="medium">Cheque No. : {{payment.chequeNo | uppercase}}</p>
                }
                @if (payment.atmId != null && payment.atmId != '') {
                  <p nxCopytext="medium">ATM ID : {{payment.atmId | uppercase}}</p>
                }
                @if (payment.traceNo != null && payment.traceNo != '') {
                  <p nxCopytext="medium">Trace No. : {{payment.traceNo}}</p>
                }
                @if (payment.approvalCode != null && payment.approvalCode != '') {
                  <p nxCopytext="medium">Approval Code : {{payment.approvalCode | uppercase}}</p>
                }
                @if (payment.creditCardNo != null && payment.creditCardNo != '') {
                  <p nxCopytext="medium">Credit Card No : {{payment.creditCardNo}}</p>
                }
                @if (payment.accountHolder != null && payment.accountHolder != '') {
                  <p nxCopytext="medium">Acc Holder's Name : {{payment.accountHolder | uppercase}}</p>
                }
                @if (payment.transactionDate != null && payment.transactionDate != '') {
                  <p nxCopytext="medium">Transaction Date: {{utcDateFormart(payment.transactionDate)}}</p>
                }
              </td>

              <td nxTableCell>
                <p nxCopytext="medium">{{ getPaymentToDescp(payment.paymentMadeTo) }}</p>
              </td>

              <td nxTableCell class="std-ta-right">
                <p nxCopytext="medium">{{ payment?.amount | number : '1.2-2' }}</p>
              </td>

              <td nxTableCell>
                <div class="horizontal-checkboxes">
                  <!-- <button class="nx-margin-right-xs" nxPlainButton type="button" aria-label="add item" (click)="openPopupOCC('occ')"> -->
                  <button class="nx-margin-right-xs" nxPlainButton type="button" aria-label="add item" (click)="editPaymentMode($index)">
                    <nx-icon name="edit" aria-hidden="true"></nx-icon>
                  </button>
                  <button nxPlainButton type="button" aria-label="add item" (click)="deletePaymentModeComponent($index)">
                    <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
                  </button>
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>

    <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-m">
        <table nxTable zebra cellpadding="0">
          <thead>
            <tr nxTableRow>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold"
                  >Payment Mode</span
                >
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold"
                  >Reference(s)</span
                >
              </th>
              <th nxHeaderCell>
                <span nxCopytext="medium" class="nx-font-weight-semibold"
                  >Payment Made To</span
                >
              </th>
              <th nxHeaderCell class="std-ta-right">
                <span nxCopytext="medium" class="nx-font-weight-semibold"
                  >Amount Paid (RM)</span
                >
              </th>
              <th nxHeaderCell>
                <span
                  nxCopytext="medium"
                  class="nx-font-weight-semibold"
                ></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="medium">Cash (Over the Counter)</span>
              </td>
              <td nxTableCell>
                <span nxCopytext="medium"
                  >Pay in Slip/Red No. : 333333333</span
                >
              </td>
              <td nxTableCell>
                <span nxCopytext="medium"
                  >Call Centre Collection Account (8000818281)</span
                >
              </td>
              <td nxTableCell class="std-ta-right">
                <span nxCopytext="medium">1,843.40</span>
              </td>
              <td nxTableCell>
                <div class="horizontal-checkboxes">
                 
                  <button
                    class="nx-margin-right-xs"
                    nxPlainButton
                    type="button"
                    aria-label="add item"
                  >
                    <nx-icon name="edit" aria-hidden="true"></nx-icon>
                  </button>
                  <button nxPlainButton type="button" aria-label="add item">
                    <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </nx-swipebar> -->