import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from "@angular/core";
import { CoverageComponent } from "../../../pages/coverage/coverage.component";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../popup/endorsement-popup/endorsement-popup.component";
import {
  STORE_ENDORSEMENT,
  CALCULATE_ENDT_PREMIUM,
} from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { SharedModule } from "../../../shared/shared.module";
import { ProgressBarComponent } from "../../../components/progress-bar/progress-bar.component";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { CoveragePopupComponent } from "../../../popup/coverage-popup/coverage-popup.component";

@Component({
  selector: 'app-no-claim-discount',
  standalone: true,
  imports: [
    SharedModule,
    NxSmallStageModule,
    TextFieldModule
  ],
  templateUrl: './no-claim-discount.component.html',
  styleUrl: './no-claim-discount.component.scss'
})
export class NoClaimDiscountComponent {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.endtStep1Issuance) endtIssuanceData$;
  @Select(SatMotorState.endtVehiclePolicy) endtVehicleData$;

  @Input() endorsementType: any;

  isEndorsement: boolean = true;
  endtPolicy: any;
  // policyNo: any;


  ncdForm: FormGroup;
  ncd: any;
  issuanceInfo: any;
  vehicleNo: any;
  endorsementEffectiveDate: any;
  lov: any;

  endtVehiclePolicyData: any;
	endtPreviousVehiclePolicyData: any;

  NCDAllow = 'ENDT23'; //NCD Allowed
  NCDAmend = 'ENDT24'; //NCD Amendment
  NCDWithdraw = 'ENDT25'; //NCD Withdraw

  endorsement_details = true;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) { }

  ngOnInit() {

    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);

    this.endtVehicleData$.subscribe((state) => {
      this.endtPolicy = state || {};
      // this.policyNo = state.policyNo;

    });

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;

        console.log("NoClaimDiscountComponent isEndorsement", this.isEndorsement);
      }
    });

    this.endtIssuanceData$.subscribe((state) => {
      if (state) {
        this.endorsementType = state.issuance.endorsementType;
        this.vehicleNo = state.issuance.vehicleNo;
        this.endorsementEffectiveDate = state.issuance.endorsementEffectiveDate;

        console.log("this.endorsementType", this.endorsementType);
        console.log("this.vehicleNo", this.vehicleNo);
        console.log("this.endorsementEffectiveDate", this.endorsementEffectiveDate);
      }
    });

    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.issuance.issuance);

    this.initializeNCDForm();
    // this.loadEndorsementDetailsData();

    this.ncdForm.patchValue({
      ncdEffectiveDate: this.endorsementEffectiveDate,
      ncdVehicleNo: this.vehicleNo, //to confirm if correct
    });

    /////////////////////////////////////////////////////////////////
    this.ncd = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails?.ncd);
    console.log("ncd:", this.ncd);

    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    const endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);
    // console.log("endtVehiclePolicyData:", endtVehiclePolicyData);
    // console.log("endtPreviousVehiclePolicyData:", endtPreviousVehiclePolicyData);

    this.endtVehiclePolicyData = endtVehiclePolicyData
    this.endtPreviousVehiclePolicyData = endtPreviousVehiclePolicyData
    
    console.log("endtVehiclePolicyData:", this.endtVehiclePolicyData);
    console.log("endtPreviousVehiclePolicyData:", this.endtPreviousVehiclePolicyData);

    if (this.ncd) {

      this.ncdForm.patchValue(this.ncd);

    } else if (endtVehiclePolicyData) {

      // let customerData = endtVehiclePolicyData?.clientPartner;
      // let agentData = endtVehiclePolicyData?.mkagAgent;
      let coverageData = endtVehiclePolicyData?.riskList;
      let coverList = endtVehiclePolicyData?.riskList[0]?.coverList;

      // console.log("customerData:", customerData);
      // console.log("agentData:", agentData);
      console.log("coverageData:", coverageData);
      console.log("coverList:", coverList);

      if (coverList) {
        let data = coverList[0];

        this.ncdForm.patchValue({
          ncdPercentage: data.ncdPercentage,
        });


      } else {
        console.error("coverList cannot populate ncdForm");
      }
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }
    ////////////////////////////////////////////////////////

    // SAMPLE code for calculating premium later
    // this.coveragePremium$.subscribe((state) => {
    //   if (state) {
    //     const cngeNote = state["cngeNote"];
    //     if (cngeNote) {
    //       const riskList = cngeNote["riskList"];
    //       if (riskList?.length) {
    //         for (let riskListItem of riskList) {
    //           this.prevNcdVeh.setValue(riskListItem.vehicleNo);
    //           const coverList = riskListItem["coverList"];
    //           if (coverList?.length) {
    //             for (let coverListItem of coverList) {
    //               if (coverListItem["coverDto"].subCovList?.length > 0) {
    //                 this.isShowExtraCoverage = true;
    //               }

    //               if (coverListItem["coverCode"] !== "01") break;
    //               this.setRecommendedSumInsuredRange();
    //               if (coverListItem["sumInsured"]) {
    //                 this.setAgreedValue();
    //                 this.setSumInsuredRange(coverListItem["sumInsured"]);
    //                 this.setUnderInsurenceApplies(coverListItem["sumInsured"]);
    //                 this.setSIWarning(coverListItem["sumInsured"]);
    //                 this.typeOfCoverage = {
    //                   ...this.typeOfCoverage,
    //                   ...{ grossPremium: coverListItem["grossPremium"] },
    //                   ...{ basicPremium: coverListItem["basicPremium"] },
    //                   ...{ ncdPercentage: coverListItem["ncdPercentage"] },
    //                   ...{ ncdAmount: coverListItem["ncdAmount"] },
    //                   ...{ subCoverPremium: coverListItem["subCoverPremium"] },
    //                 };
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // });

  }

  // ngAfterViewInit() {}

  initializeNCDForm() {
    this.ncdForm = this.fb.group({
      ncdVehicleNo: ['', Validators.required],
      ncdFrom: ['', Validators.required],
      ncdPercentage: ['', Validators.required],
      ncdEffectiveDate: [{ value: '', disabled: true }]
    });
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state && state.ncd) {
        this.ncdForm.patchValue(state.ncd);
      }
    });
  }

  storeEndorsementDetailsData() {
    if (this.ncdForm.valid) {
      let ncd = this.ncdForm.value;
      let payload = {
        ncd: ncd,
        isNCD: true
      }

      console.log('Saving NCD Data:', ncd);

      this.store.dispatch(new STORE_ENDORSEMENT(payload));
    } else {
      console.error('NCD form is invalid!');
      // this.logFormErrors(this.ncdForm);
      this.ncdForm.markAllAsTouched();
    }
  }

  // Accessors for form controls
  get ncdVehicleNo() {
    return this.ncdForm.get('ncdVehicleNo');
  }

  get ncdFrom() {
    return this.ncdForm.get('ncdFrom');
  }

  get ncdPercentage() {
    return this.ncdForm.get('ncdPercentage');
  }

  get ncdEffectiveDate() {
    return this.ncdForm.get('ncdEffectiveDate');
  }

}
