import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ProgressBarComponent } from "../../../components/progress-bar/progress-bar.component";
import { SharedModule } from "../../../shared/shared.module";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../popup/endorsement-popup/endorsement-popup.component";

@Component({
  selector: 'app-thailand-trip',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './thailand-trip.component.html',
  styleUrl: './thailand-trip.component.scss'
})
export class ThailandTripComponent {
  // data: any;
  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  endorsementType: any;
  isEndorsement: boolean;

  endorsement_details = true;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;

  // For Coverage table
  show_comprehensive = true;
  show_tp = false;
  show_tpft = false;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
   
  }


  comprehensive(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {
      height: "500px",
      width: "780px",
      maxWidth: "780px",
    });
  }

  tp(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  tpft(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  closeTemplateDialog() {
    this.templateDialogRef ?.close();
  }

  // Validation //

  thailandstartdateFormControl = new FormControl('', [
    Validators.required,
  ]);

  thailandenddateFormControl = new FormControl('', [
    Validators.required,
  ]);

  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;
  
  openPopup(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopup3(popup_type: any) {
  this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      data: { popup_type: popup_type },
    });
  }

}
