import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { SortDirection, SortEvent } from "@aposin/ng-aquila/table";

interface Data {
  date: Date;
  eDocument_name: string;
  eDocument_type: string;
  link: string;
}

interface Action {
  icon: string;
  label: string;
  product: any[];
}

@Component({
  selector: "app-download",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./download.component.html",
  styleUrl: "./download.component.scss",
})
export class DownloadComponent {

  // Filter //
  toDisplay = false;

  toggleData() {
      this.toDisplay = !this.toDisplay;
  }

  desktopScreen = true;

  currentIndex = 1;

  tableElementsGuides: Data[] = [
    {
      date: new Date("01/14/2020"),
      eDocument_name: "Alpha 2.0 New Motor Endorsement Flow",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/14/2019"),
      eDocument_name: "User Guide (Agents) - Alpha 2.0 Endorsement",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "USER GUIDE - CIMB CLICK BILL PAYMENT",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "USER GUIDE - EPAYMENT PAYMENT REJECTION PROCESS",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "Special Type user guide",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "Windscreen coverage user guide",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "USER GUIDE - MISC COMBO (ALPHA) - Part 2",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "USER GUIDE - MISC COMBO (ALPHA) - Part 1",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "Smart Home Cover - Alpha Manual Guide - Part 2",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("11/29/2020"),
      eDocument_name: "Smart Home Cover - Alpha Manual Guide - Part 1",
      eDocument_type: "PDF Document",
      link: "#",
    },
  ];

  tableElementsForms: Data[] = [
    {
      date: new Date("02/02/2018"),
      eDocument_name: " Credit Card Payment Form",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("08/14/2020"),
      eDocument_name: "Hospitalisation & Surgical Claim",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("08/11/2020"),
      eDocument_name: "Medical Report (Death Claim)",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("07/29/2020"),
      eDocument_name: "Outpatient Treatment Claim",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("07/29/2020"),
      eDocument_name: "Accident Claim",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("07/29/2020"),
      eDocument_name: "Death Claim",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("07/29/2020"),
      eDocument_name:
        "CLAIMANT’S STATEMENT - GROUP TEMPORARY DISABILITY CLAIM (EB)",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("07/29/2020"),
      eDocument_name:
        "CLAIMANT’S STATEMENT - GROUP TOTAL AND PERMANENT DISABILITY CLAIM (EB)",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("07/29/2020"),
      eDocument_name: "CLAIMANT’S STATEMENT - HSBC ACCIDENT CLAIM",
      eDocument_type: "PDF Document",
      link: "#",
    },
    {
      date: new Date("07/29/2020"),
      eDocument_name: "CLAIMANT’S STATEMENT - HSBC DEATH CLAIM",
      eDocument_type: "PDF Document",
      link: "#",
    },
  ];

  /**
   * Sorts the table data by a certain category.
   */
  sortTableGuides(sort: SortEvent) {
    this.tableElementsGuides = this.tableElementsGuides.sort(
      (a: { [key: string]: any }, b: { [key: string]: any }) => {
        if (sort.active in a && sort.active in b) {
          return this.compare(a[sort.active], b[sort.active], sort.direction);
        }
        return 0;
      }
    );
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }

  /**
   * filter funtion
   */

  years = [2024, 2023, 2022];
  filterYear = ["2024"];
  show_filter = false;

  toggleFilter() {
    this.show_filter = !this.show_filter;
  }

  /**
   * Pagination
   */
  count = 210;
  page = 1;
  perPage = 10;

  prevPage() {
    this.page--;
  }

  nextPage() {
    this.page++;
  }

  goToPage(n: number) {
    this.page = n;
  }

  // product
  actions: Action[] = [
    {
      icon: "arrow-right",
      label: "Automotive and Travel",
      product: [
        {
          name: "Car and Motorcycle",
          items: [
            { name: "MOTOR COMPREHENSIVE COVER" },
            { name: "MOTOR THIRD PARTY COVER" },
            { name: "MOTOR THIRD PARTY, FIRE AND THEFT" },
            { name: "ALLIANZ MOTORCYCLE PLUS" },
            { name: "E-HAILING ADD ON" },
          ],
        },
        {
          name: "Travel and Flight",
          items: [
            { name: "ALLIANZ TRAVEL CARE" },
            { name: "ALLIANZ FLIGHT CARE" },
          ],
        },
        {
          name: "Roadside Assistance",
          items: [
            { name: "ROAD WARRIOR" },
            { name: "BIKE WARRIOR" },
            { name: "ENHANCED ROAD WARRIOR" },
          ],
        },
      ],
    },
    {
      icon: "arrow-right",
      label: "Home Insurance",
      product: [
        {
          name: "Home Insurance",
          items: [
            { name: "HOUSEOWNER AND HOUSEHOLDER" },
            { name: "SMART HOME COVER" },
            { name: "ALLIANZ KAMPUNGKU" },
            { name: "HOMEGUARD" },
          ],
        },
      ],
    },
    {
      icon: "arrow-right",
      label: "Medical Critical Illness",
      product: [],
    },
    {
      icon: "arrow-right",
      label: "Personal Accident",
      product: [],
    },
  ];

  selectedAction!: Action;

  ngOnInit(): void {
    this.selectedAction = this.actions[0];
  }

  onSelect(action: Action) {
    this.selectedAction = action;
  }


  selectedTabChanged(event: any){
    // console.log(event)

  }
  
}
