<div nxRow>
    <div nxCol="12">

        <h2 class="nx-margin-bottom-2m nx-margin-top-0">Complete</h2>

        <app-status-message [data]="data"></app-status-message>

        <nx-card class="completed_card">

            <!-- Printed Status for e-Quotation (to be move to new component) -->
             @if (isEquotation && data.cnoteStatus === "PRINTED") {
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="card_title nx-margin-0">e-Quotation</h2>
                    </div>
                    <div nxCol="12,12,2" class="completed_status">
                        <nx-badge type="active">Printed</nx-badge>
                    </div>
                </div>
            </div>

            <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName | titlecase }}</h2>

            <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.proposalNo }}</h3>
            <p nxCopytext="normal" class="nx-margin-bottom-m">has been successfully downloaded. You may save a digital
                copy of the e-Quotation by clicking on the Download
                e-Quotation button below.</p>
            }

            <!-- Printed Status for e-Cover Note (to be move to new component) -->
            @if (!isCoverQuotation && isCoverNote && data.cnoteStatus === "PRINTED" && !isRTP) {
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="card_title nx-margin-0">e-Cover Note</h2>
                    </div>
                    <div nxCol="12,12,2" class="completed_status ">
                        <nx-badge type="active">Printed</nx-badge>
                    </div>
                </div>
            </div>

            <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName }}</h2>

            <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.cnoteNo }}</h3>
            <p nxCopytext="normal" class="nx-margin-bottom-m">has been successfully downloaded. You may save a digital
                copy of the e-Cover Note by clicking on the Download
                e-Cover Note button below.</p>
            }

            <!-- Printed Status for Cover Note - RTP -->
            @if (isRTP && isCoverNote && data.cnoteStatus === "PRINTED") {
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="card_title nx-margin-0">Cover Note</h2>
                    </div>
                    <div nxCol="12,12,2" class="completed_status ">
                        <nx-badge type="active">Printed</nx-badge>
                    </div>
                </div>
            </div>
            
            <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName }}</h2>
            
            <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.cnoteNo }}</h3>
            }

            <!-- Printed Status for e-Quotation and e-Cover Note (to be move to new component) -->
             @if(isCoverQuotation && data.cnoteStatus === "PRINTED") {
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="card_title nx-margin-0">e-Quotation / e-Cover Note</h2>
                    </div>
                    <div nxCol="12,12,2" class="completed_status ">
                        <nx-badge type="active">Printed</nx-badge>
                    </div>
                </div>
            </div>

            <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName }}</h2>

            <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.proposalNo }} / {{ data.cnoteNo }}
            </h3>
            <p nxCopytext="normal" class="nx-margin-bottom-m">has been successfully downloaded. You may save a digital
                copy of the e-Cover Note by clicking on the Download
                e-Cover Note button below.</p>
            }

            <!-- Pending Approval Status (to be move to new component) -->
             @if(isEquotation && data.cnoteStatus === "PENDING") {
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="card_title nx-margin-0">e-Quotation</h2>
                    </div>
                    <div nxCol="12,12,2" class="completed_status ">
                        <nx-badge type="active" class="pending_badge">Pending Approval</nx-badge>
                    </div>
                </div>
            </div>

            <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName }}</h2>

            <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.proposalNo }}</h3>

            <!-- <p nxCopytext="normal">Your e-Quotation has been successfully generated, but conversion to Cover Note will be a referral case. </p>
            <ul nxList="small" class="completed_list nx-margin-bottom-m">
                @for(item of data.referList; track item) {
                    <li><span class="nx-font-weight-bold">{{ item.referCode }}</span> - {{ item.referDescp }}</li>
                }
            </ul> -->
            }

            @if(isCoverNote && data.cnoteStatus === "PENDING") {
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="card_title nx-margin-0">e-Cover Note</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="active" class="pending_badge">Pending Approval</nx-badge>
                        </div>
                    </div>
                </div>
    
                <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName }}</h2>
    
                <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.cnoteNo }}</h3>
    
                <!-- <p nxCopytext="normal">The e-Cover Note you have just submitted is a referral case based on the following reason(s):</p>
                <ul nxList="small" class="completed_list nx-margin-bottom-m">
                    @for(item of data.referList; track item) {
                        <li><span class="nx-font-weight-bold">{{ item.referCode }}</span> - {{ item.referDescp }}</li>
                    }
                </ul> -->
            }

            <!-- Cancelled Status (to be move to new component) -->
             @if(isEquotation && data.cnoteStatus === "CANCELLED") {
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="card_title nx-margin-0">e-Quotation</h2>
                    </div>
                    <div nxCol="12,12,2" class="completed_status ">
                        <nx-badge type="critical">Cancelled</nx-badge>
                    </div>
                </div>
            </div>

            <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName }}</h2>
            <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.proposalNo }}</h3>
            }

            @if(isCoverNote && data.cnoteStatus === "CANCELLED") {
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="card_title nx-margin-0">e-Cover Note</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="critical">Cancelled</nx-badge>
                        </div>
                    </div>
                </div>
    
                <h2 class="nx-margin-bottom-m nx-margin-top-0 product_name">{{ data.productName }}</h2>
                <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">{{ data.cnoteNo }}</h3>
                }

            <!-- Approved Status (to be move to new component) -->
            @if(isEquotation && data.cnoteStatus === "APPROVED") {
            <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                <div nxRow class="completed_status">
                    <div nxCol="12,12,10">
                        <h2 class="card_title nx-margin-0">e-Quotation</h2>
                    </div>
                    <div nxCol="12,12,2" class="completed_status ">
                        <nx-badge type="positive">Approved</nx-badge>
                    </div>
                </div>
            </div>
            }

            @if(!isRTP) {
            <div class="button-container nx-margin-y-m">
                @if(!isUpdatePayment && (data.cnoteStatus === 'PENDING' || data.cnoteStatus === 'PRINTED')) {
                <button nxButton="secondary small" type="button" (click)="prepareCancellationData()">Request
                    Cancellation</button>
                }
                    
                @if(data.cnoteStatus === 'PENDING' && userInfo.userId === data.issueBy) {
                    <button nxButton="secondary small" type="button" (click)="openPopupReferralRemarks()">Referral
                    Remarks</button>
                }
                
                @if(data.cnoteStatus === 'PRINTED' && data.proposalNo && !data.cnoteNo) {
                <button nxButton="primary small" type="button" (click)="downloadPDF(data.contractId, 'quotation', false)">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>e-Quotation PDF
                </button>
                }

                @if(data.cnoteStatus === 'PRINTED' && data.cnoteNo) {
                <button nxButton="primary small" type="button" (click)="downloadPDF(data.contractId, 'covernote', false)">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>e-Cover Note PDF
                </button>

                <button nxButton="primary small" type="button" (click)="downloadPDF(data.contractId, 'invoice', false)">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>e-Invoice PDF
                </button>
                }

                @if(data.cnoteStatus === 'PRINTED' || data.cnoteStatus === 'PENDING') {
                <button nxButton="primary small" type="button" (click)="uploadDocument()">Upload Document</button>
                }

            </div>
            }

            @if (isRTP) {
                <div class="button-container nx-margin-bottom-m"></div>
            }

            <hr class="nx-margin-bottom-m">

            <app-complete-information [data]="data"></app-complete-information>
         
        </nx-card>

        <div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">
            <button nxButton="secondary" type="button" (click)="backToSummaryListing()">Back to Summary listing</button>
            
            <button nxButton="primary" type="button" (click)="goToViewPage()">VIEW {{ isEquotation ? 'E-QUOTATION' : 'E-COVER NOTE' }}</button>

            @if(data.cnoteStatus === 'PRINTED' && !data.cnoteNo && !isCCentric) {
                <button nxButton (click)="sendToJPJ()">
                    SEND TO JPJ
                </button>
            }

            @if((data.cnoteStatus === 'PRINTED' || data.cnoteStatus === 'SUBMITTED') && !isRTP) {
            <button nxButton="primary" [disabled]="isDisableEmailButton()" type="button" (click)="openPopupEmailDoc('emaildocument')">EMAIL DOCUMENT</button>
            }

            <button nxButton="primary" type="button" (click)="onContinueIssuance()">Continue issuance</button>
        </div>
    </div>
</div>

<!-- @if(crossSellList.length !== 0) {
    <app-cross-sell-banner [crossSellList]="crossSellList" [userInfo]="userInfo" 
    [marketingConsent]="marketingConsent" [clientPartnerInfo]="customerPartnerInfo" [agentInfo]="agentInfo">
    </app-cross-sell-banner>
} -->
