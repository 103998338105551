import { Input, Directive } from '@angular/core';
import { CONSTANTS, IDTYPE, COUNTRIES, CLIENTTYPE } from 'src/app/shared/enums/index.enum';
import moment from 'moment';

@Directive()
export abstract class CustomerPartnerBase {
    @Input() cpDetails;
    @Input() lov;

    getClientType = CONSTANTS.getClientType();
    getMaritalStatus = CONSTANTS.getMaritalStatus();
    getIdLabel = CONSTANTS.getIdLabel();
    getCountry = CONSTANTS.getCountry();
    getGender = CONSTANTS.getGender();
    getResidentStatus  = CONSTANTS.getResidentStatus();

    constructor() { }

    get type() {
        return this.cpDetails.clientType === CLIENTTYPE.COMPANY ? CLIENTTYPE.I : CLIENTTYPE.P;
    }

    get name() {
        return this.cpDetails.clientName;
    }

    get alimCustomer() {
        return this.cpDetails.alimCustomerIndicator;
    }

    get age() {
        return this.cpDetails.clientAge;
    }

    get idLabel1() {
        return this.getIdLabel(this.cpDetails.idType1);
    }

    get idLabel2() {
        return this.getIdLabel(this.cpDetails.idType2);
    }

    get idValue1() {
        return this.cpDetails.idValue1;
    }

    get idValue2() {
        return this.cpDetails.idValue2;
    }

    get gender() {
        return this.getGender(this.cpDetails.clientGender);
    }

    get countryname() {
        // return this.source.idType1 === IDTYPE.PASS ? COUNTRIES.NM : COUNTRIES.MAL;
        // return this.source.clientNationality === COUNTRIES.MALAYSIAN? this.getCountry(this.source.clientNationality): COUNTRIES.NM;
        return this.cpDetails.clientNationality ? 
            (this.lov.clientNationalityList.find(country => country.code === this.cpDetails.clientNationality) ? this.lov.clientNationalityList.find(country => country.code === this.cpDetails.clientNationality).descp : COUNTRIES.MAL)
            : COUNTRIES.MAL;
    }

    get countryIncorporation() {
        return this.cpDetails.clientNationality ? 
            (this.lov.clientNationalityList.find(country => country.code === this.cpDetails.clientNationality) ? this.lov.clientNationalityList.find(country => country.code === this.cpDetails.clientNationality).descp : COUNTRIES.MAL)
            : COUNTRIES.MAL;
    }


    get marriedStatus() {
        return this.getMaritalStatus(this.cpDetails.clientMaritalStatus) ?
            this.getMaritalStatus(this.cpDetails.clientMaritalStatus)
            :
            CONSTANTS.DEFAULT_DISPLAY;
    }

    get mobilePhone() {
        return this.cpDetails.clientMobileCode1 && this.cpDetails.clientMobileNo1 ?
            `${this.cpDetails.clientMobileCode1}-${this.cpDetails.clientMobileNo1}`
            :
            CONSTANTS.DEFAULT_DISPLAY;
    }

    get fixLine() {
        return this.cpDetails.clientMobileCode2 && this.cpDetails.clientMobileNo2 ?
            `${this.cpDetails.clientMobileCode2}-${this.cpDetails.clientMobileNo2}`
            :
            CONSTANTS.DEFAULT_DISPLAY;
    }

    get faxNumber() {
        return this.cpDetails.clientPartnerAddress!.clientFax1Code && this.cpDetails.clientPartnerAddress!.clientFax1No ?
            `${this.cpDetails.clientPartnerAddress.clientFax1Code}-${this.cpDetails.clientPartnerAddress.clientFax1No}`
            :
            CONSTANTS.DEFAULT_DISPLAY;
    }

    get fullAddress() {
        const clientPartnerAddress = this.cpDetails.clientPartnerAddress;
        const clientAddress1 = clientPartnerAddress.clientAddress1 ? clientPartnerAddress.clientAddress1 : '';
        const clientAddress2 = clientPartnerAddress.clientAddress2 ? clientPartnerAddress.clientAddress2 : '';
        const clientAddress3 = clientPartnerAddress.clientAddress3 ? clientPartnerAddress.clientAddress3 : '';
        const address = `${clientAddress1} ${clientAddress2} ${clientAddress3}`;
        return `${address}, ${clientPartnerAddress.clientPostcode}, ${this.city}, ${this.state}, ${this.country}`;
    }

    get city() {
        const city = this.lov.clientCityList.find(city => city.code === this.cpDetails.clientPartnerAddress.clientCity);
        return city ? city.descp : CONSTANTS.DEFAULT_DISPLAY;
    }

    get state() {
        const state = this.lov.clientStateList.find(state => state.code === this.cpDetails.clientPartnerAddress.clientState);
        return state ? state.descp : CONSTANTS.DEFAULT_DISPLAY;
    }

    get country() {
        const country = this.lov.clientCountryList.find(country => country.code === this.cpDetails.clientPartnerAddress.clientCountry);
        return country ? country.descp : CONSTANTS.DEFAULT_DISPLAY;
    }

    get email() {
        return this.cpDetails.clientEmail ? this.cpDetails.clientEmail : CONSTANTS.DEFAULT_DISPLAY;
    }

    get website() {
        return this.cpDetails.clientWebsite ? this.cpDetails.clientWebsite : CONSTANTS.DEFAULT_DISPLAY;
    }

    get isIndividual() {
        return this.cpDetails.clientType === CLIENTTYPE.INDIVIDUAL;
    }

    get isCompany() {
        return this.cpDetails.clientType === CLIENTTYPE.COMPANY;
    }

    get smeRegisteredNumber() {
        return (this.cpDetails && this.cpDetails.smeRegisteredNumber) ?
        this.cpDetails.smeRegisteredNumber : CONSTANTS.DEFAULT_DISPLAY;
    }
    
    get smeCategory() {
        let smeCategory = this.cpDetails && this.cpDetails.smeCategory ? this.cpDetails.smeCategory: CONSTANTS.DEFAULT_DISPLAY;
        if(smeCategory != CONSTANTS.DEFAULT_DISPLAY){
        if(this.lov.smeCategoryList && this.lov.smeCategoryList.length > 0){
            let smeCodeoBj = this.lov.smeCategoryList.find(item => item.code === this.cpDetails.smeCategory);
            return smeCodeoBj.descp;
        }else{
            return smeCategory;
          }
        }
        return CONSTANTS.DEFAULT_DISPLAY;
    }

    get smeStartDate() {
        return (this.cpDetails && this.cpDetails.smeStartDate) ?
        moment(this.cpDetails.smeStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : CONSTANTS.DEFAULT_DISPLAY;
    }
    
    get smeEndDate() {
        return (this.cpDetails && this.cpDetails.smeEndDate) ?
        moment(this.cpDetails.smeEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : CONSTANTS.DEFAULT_DISPLAY;
    }

    get dateOfBirth() {
        return (this.cpDetails && this.cpDetails.clientDateofBirth) ?
        moment(this.cpDetails.clientDateofBirth, 'YYYY-MM-DD').format('DD/MM/YYYY') : CONSTANTS.DEFAULT_DISPLAY;
    }

    get clientTinNo(){
        return this.cpDetails.clientTinNo ? this.cpDetails.clientTinNo : CONSTANTS.DEFAULT_DISPLAY;
    }

    get clientSstRegNo(){
        return this.cpDetails.clientSstRegNo ? this.cpDetails.clientSstRegNo : CONSTANTS.DEFAULT_DISPLAY;
    }

    get clientSstRegDate() {
        return (this.cpDetails && this.cpDetails.clientSstRegDate) ?
        moment(this.cpDetails.clientSstRegDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : CONSTANTS.DEFAULT_DISPLAY;
    }

    get clientSstDeRegDate() {
        return (this.cpDetails && this.cpDetails.clientSstDeRegDate) ?
        moment(this.cpDetails.clientSstDeRegDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : CONSTANTS.DEFAULT_DISPLAY;
    }

    get clientSstResidentStatus(){
        return this.getResidentStatus(this.cpDetails.clientSstResidentStatus) ? 
        this.getResidentStatus(this.cpDetails.clientSstResidentStatus) :
        CONSTANTS.DEFAULT_DISPLAY;
    }
    
    get clientSstCountry(){
        const country = this.lov.clientCountryList.find(country => country.code === this.cpDetails.clientSstCountry);
        return country ? country.descp : CONSTANTS.DEFAULT_DISPLAY;
    }

    get isGeoShow() {
        return this.cpDetails.clientPartnerAddress?.clientGeoPlusCode ? true : false;
    }
  
}