import { Component, Input } from '@angular/core';
import { SharedModule } from "../../../../shared/shared.module";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PdpaPopupComponent } from "../../../../popup/pdpa-popup/pdpa-popup.component";

//src\app\shared\shared.module.ts 

@Component({
  selector: 'app-issue-endorsement-validation',
  standalone: true,
  imports: [SharedModule, ProgressBarComponent],
  templateUrl: './issue-endorsement-validation.component.html',
  styleUrl: './issue-endorsement-validation.component.scss'
})
export class IssueEndorsementValidationComponent {

  @Input() endorsement = false; // Receives endorsement data from DeclarationIssuanceInfoComponent

  selectedEndorsementType: string;
  endorsementErrorMessage: string = '';
  policyAgentType: string; // For checking agent type
  policyNCD: number; // For checking NCD
  eInvoiceConsent: boolean; // For checking e-Invoice Consent
  IRBMStatus: string; // For checking IRBM Status
  policyCoverage: string; // Third-party or comprehensive

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  consentChange(event: any) {
    // console.log(event.value)
    localStorage.setItem("eInvoice_consent", event.value);
  }

  constructor(private dialogService: NxDialogService) {}
  componentDialogRef?: NxModalRef<PdpaPopupComponent>;

  endorsementTypes = [
    "Change of Engine",
    "Extension Prorate",
    "Amendment of Period",
    "Cancellation by Insured",
    "Change of Bancassurance Details",
    "Change of Vehicle Chassis No.",
    "Change of Vehicle Registration No.",
    "Extra Benefits",
    "Hire Purchase / Employers' Loan/Leasing",
    "Named Driver",
    "NCD Allowed",
    "NCD Amendment",
    "NCD Withdraw",
    "Revision of Sum Insured",
    "Change of Make & Model",
    "Reinstatement of Extra Coverage",
    "Amendment of e-Invoice Details",
    "Change of e-Invoice Consent"
  ];

  validateEndorsementType(type: string) {
    this.endorsementErrorMessage = '';

    switch (type) {
      case 'Change of Bancassurance Details':
        if (this.policyAgentType !== 'Banca Agent') {
          this.endorsementErrorMessage = 'Unable to proceed due to selected agent is not banca agent.';
        }
        break;
      case 'NCD Withdraw':
        if (this.policyNCD === 0) {
          this.endorsementErrorMessage = 'Not allow to withdraw, NCD percentage is zero.';
        }
        break;
      case 'NCD Allowed':
        if (this.policyNCD !== 0) {
          this.endorsementErrorMessage = 'NCD Allowed not applicable because NCD percentage is not zero.';
        }
        break;
      case 'Amendment of e-Invoice Details':
        if (!this.eInvoiceConsent || this.IRBMStatus !== 'Invalid') {
          this.endorsementErrorMessage = 'Amendment of e-Invoice details are not allowed.';
        }
        break;
      case 'Change of e-Invoice Consent':
        // Add additional logic for validation
        this.endorsementErrorMessage = 'Change of e-Invoice consent are not allowed.';
        break;
      case 'Revision of Sum Insured':
        if (this.policyCoverage === 'Third Party') {
          this.endorsementErrorMessage = 'Endorsement Revision of Sum Insured not allowed.';
        }
        break;
      case 'Change of Make & Model':
        // Placeholder for condition related to old make/model validation
        this.endorsementErrorMessage = 'Please contact your branch for change of make/model for this policy.';
        break;
      default:
        this.endorsementErrorMessage = '';
    }

    if (this.endorsementErrorMessage) {
      this.clearEndorsementType();
      this.disableNextButton();
      this.showWarningMessage(this.endorsementErrorMessage);
    }
  }

  clearEndorsementType() {
    this.selectedEndorsementType = '';
  }

  disableNextButton() {
    // Logic to disable the "Next" button for later
  }

  showWarningMessage(message: string) {
    alert(message); // Or use a modal dialog for a better user experience
  }

  popupTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24] as const;
  
  popupMessages: { [key in typeof this.popupTypes[number]]: string } = {
    // Vehicle details
    1: "Vehicle No. doesn't exist.",
    2: "Vehicle found and already expired",
    3: "Vehicle found but another endorsement in progress",
    4: "Vehicle found but already cancelled",
    5: "e-Invoice Consent is Yes and IRBM status is Submitted",
  
    // Endorsement
    6: "Policy agent does not belong to Banca Agent",
    7: "Policy NCD is zero",
    8: "Policy NCD is not zero",
    9: "e-Invoice Consent is not Yes or policy/previous endorsement IRBM status is 'Invalid'",
    10: "Change of e-Invoice consent is not allowed",
    11: "Amendment of e-Cover Note details are not allowed except Cancellation.",
  
    // Endorsement effective date
    12: "Endorsement Effective Date cannot be earlier than the current date",
    13: "Endorsement Effective Date cannot be after Endorsement Expiry Date",
    14: "Endorsement Effective Date cannot be earlier than Policy Effective Date",
  
    // Endorsement expiry date
    15: "Extension Prorate: date range between Endorsement Effective Date and Endorsement Expiry Date cannot be more than 6 months",
    16: "Endorsement Expiry Date cannot be earlier than or equal to Endorsement Effective Date",
  
    // ID NO.
    17: "An active quotation (Printed) exists",
    18: "Validation (RN / NWOO): Vehicle found and existing policy is still in force and not going to expire in the next 90 days",
  
    // Effective date
    19: "Effective Date is earlier than Issued Date + YY days",
    20: "Effective Date is later than Issued Date + YY days",
    21: "Effective Date is later than Issued Date + XX days",
    22: "Effective Date is the same as Issued Date",
  
    // Expiry date
    23: "Expiry Date is earlier than Effective Date",
    24: "Expiry Date is later than Effective Date + XX months"
  };

  openPopup(popup_type: typeof this.popupTypes[number]) {
    const message = this.popupMessages[popup_type];
    if (message) {
      this.componentDialogRef = this.dialogService.open(PdpaPopupComponent, {
        ariaLabel: "A simple dialog",
        showCloseIcon: false,
        width: '400px',
        data: { popup_type, message },
      });
    }
  }
}
