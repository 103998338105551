import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CPPopupComponent } from "../../../../popup/cp-popup/cp-popup.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { STEP2 } from 'src/app/interfaces/motor';
import { CLIENTTYPE, CONSTANTS, GENDER, IDTYPE } from 'src/app/shared/enums/index.enum';
import { takeUntil, Subject } from 'rxjs';
import { NricService } from '@services/nric/nric.service';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { SmeCertFormComponent } from '../sme-cert-form/sme-cert-form.component';
import { SstFormDetailsComponent } from '../sst-form-details/sst-form-details.component';
import { CustomerPartnerFormComponent } from '../customer-partner-form/customer-partner-form.component';
import moment from 'moment';
import { Store } from '@ngxs/store';
import { GET_SWITCH_SITE, SAVE_AS_DRAFT, SAVE_CUSTOMER_PARTNER, SET_CUSTOMER_PARTNER_SEARCH_TYPE } from '@store/sat-motor/sat-motor.action';
import { SimpleModalComponent } from '@shared/components/modal/simple-modal/simple-modal.component';
import { ScrollToError } from '@shared/classes/scroll-to-error';

@Component({
  selector: 'app-customer-partner-incomplete',
  standalone: true,
  imports: [
    SharedModule,
    SmeCertFormComponent,
    SstFormDetailsComponent,
    CustomerPartnerFormComponent
  ],
  templateUrl: './customer-partner-incomplete.component.html',
  styleUrl: './customer-partner-incomplete.component.scss'
})
export class CustomerPartnerIncompleteComponent implements OnInit {
  @Input() dataSearch: STEP2.SearchDataStore;
  @Input() isCompOrCustInfoIncomplete: boolean;
  @Input() isContactInfoIncomplete: boolean;
  @Input() isCorrespondenceInfoIncomplete: boolean;
  @Input() eInvoiceConsent: boolean = false;
  @Input() autoRenewal: boolean = false;
  @Input() mkagAgent: any;
  @Input() cpDetails:any;
  @Input() lov: any;
  @Input() edocConsentInd: boolean = false;

  @Output() searchAgain: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back = new EventEmitter();

  customerPartnerIncompleteForm: FormGroup;
  individual: boolean = true;
  company: boolean = false;
  sst: boolean = true;
  sme: boolean = true;
  componentDialogRef?: NxModalRef<CPPopupComponent>;
  search_mode_address: boolean = true;
  infoMessageModal?: NxModalRef<SimpleModalComponent>;
  userInfo: any;

  getLabel = CONSTANTS.getIdLabel();

  unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialogService: NxDialogService,
    private fb: FormBuilder,
    private nricService: NricService,
    private router: Router,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);

    this.customerPartnerIncompleteForm =this.fb.group({
      name:[this.cpDetails.clientName ? this.cpDetails.clientName : '', Validators.required],
      nationality: [this.cpDetails.clientNationality ? this.cpDetails.clientNationality : ''],
      idType2:[this.cpDetails.idType2 ? this.cpDetails.idType2 : ''],
      idValue2:[this.cpDetails.idValue2 ? this.cpDetails.idValue2 : ''],
      tinNo:[this.cpDetails.clientTinNo ? this.cpDetails.clientTinNo : ''],
      gender:[this.cpDetails.clientGender ? this.cpDetails.clientGender : ''],
      maritalStatus:[this.cpDetails.clientMaritalStatus ? this.cpDetails.clientMaritalStatus : ''],
      dob:[this.cpDetails.clientDateofBirth ? this.cpDetails.clientDateofBirth : ''],
      age:[{value:this.cpDetails.clientAge ? this.cpDetails.clientAge : '', disabled : true}],
      mobileNoCode:[this.cpDetails.clientMobileCode1 ? this.cpDetails.clientMobileCode1 :''],
      mobileNo:[this.cpDetails.clientMobileNo1 ? this.cpDetails.clientMobileNo1 : '', [Validators.required, Validators.pattern("^\\d{7,8}$")]],
      fixedLineNoCode:[this.cpDetails.clientFixedLineNoCode1 ? this.cpDetails.clientFixedLineNoCode1 : ''],
      fixedLineNo:[this.cpDetails.clientFixedLinedNo1 ? this.cpDetails.clientFixedLinedNo1 : '', Validators.pattern("^\\d{7,8}$")],
      faxNoCode:[this.cpDetails.clientFaxNoCode1 ? this.cpDetails.clientFaxNoCode1 : ''],
      faxNo:[this.cpDetails.clientFaxNo1 ? this.cpDetails.clientFaxNo1 : '', Validators.pattern("^\\d{7,8}$")],
      email:[this.cpDetails.clientEmail ? this.cpDetails.clientEmail : ''],
      showMapSwitcher:[false],
      unitNo:[this.cpDetails.clientPartnerAddress.clientUnitNo ? this.cpDetails.clientPartnerAddress.clientUnitNo : ''],
      address1:[this.cpDetails.clientPartnerAddress.clientAddress1Tmp ? this.cpDetails.clientPartnerAddress.clientAddress1Tmp : ''],// Validators.required],
      address2:[this.cpDetails.clientPartnerAddress.clientAddress2 ? this.cpDetails.clientPartnerAddress.clientAddress2 : ''],
      address3:[this.cpDetails.clientPartnerAddress.clientAddress3 ? this.cpDetails.clientPartnerAddress.clientAddress3 : ''],
      postCode:[this.cpDetails.clientPartnerAddress.clientPostcode ? this.cpDetails.clientPartnerAddress.clientPostcode : '', Validators.required],
      city:[{value: this.cpDetails.clientPartnerAddress.clientCity ? this.cpDetails.clientPartnerAddress.clientCity : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      state:[{value: this.cpDetails.clientPartnerAddress.clientState ? this.cpDetails.clientPartnerAddress.clientState : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      country:[{value: this.cpDetails.clientPartnerAddress.clientCountry ? this.cpDetails.clientPartnerAddress.clientCountry : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      addressType:[this.cpDetails.clientPartnerAddress.clientAddressType ? this.cpDetails.clientPartnerAddress.clientAddressType : this.getDefaultAddressType()],
      clientGeoLatitude: [this.cpDetails.clientPartnerAddress.clientGeoLatitude ? this.cpDetails.clientPartnerAddress.clientGeoLatitude : ''],
      clientGeoLongitude: [this.cpDetails.clientPartnerAddress.clientGeoLongitude ? this.cpDetails.clientPartnerAddress.clientGeoLongitude : ''],
      clientGeoPlaceId: [this.cpDetails.clientPartnerAddress.clientGeoPlaceId ? this.cpDetails.clientPartnerAddress.clientGeoPlaceId : ''],
      clientGeoPlusCode: [this.cpDetails.clientPartnerAddress.clientGeoPlusCode ? this.cpDetails.clientPartnerAddress.clientGeoPlusCode : ''],
      clientGeoFormattedAddress: [this.cpDetails.clientPartnerAddress.clientGeoFormattedAddress ? this.cpDetails.clientPartnerAddress.clientGeoFormattedAddress : '']
    });
  }


  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  restartSearch() {
    // this.store.dispatch(new IS_STEP_3(false));
    this.searchAgain.emit(true);
  }
  initAddressValidators(){
    // this.customerPartnerIncompleteForm.controls['address1'].setValidators(Validators.required);
    this.customerPartnerIncompleteForm.controls['postCode'].setValidators(Validators.required);
    // this.customerPartnerIncompleteForm.controls['address1'].updateValueAndValidity();
    this.customerPartnerIncompleteForm.controls['postCode'].updateValueAndValidity();
  }

  nextPage() {
    this.initAddressValidators();
    let invalidField = [];
    if(this.customerPartnerIncompleteForm.valid) {
      //connect to BE to save the not found customer info
      console.log("customer partner incomplete form data: ", this.customerPartnerIncompleteForm.getRawValue());
      const payload = this.generateSaveCPPayload();
      this.store.dispatch(new SAVE_CUSTOMER_PARTNER(payload)).subscribe(state => {
        let cpDetails = state.SatMotorState.motor.step2;
        if(cpDetails) {
          this.store.dispatch(new SET_CUSTOMER_PARTNER_SEARCH_TYPE("FOUND"));
          this.router.navigate(['issuance/coverage']);
        }
      })
      this.router.navigate(['issuance/coverage']);
    }else {
      Object.keys(this.customerPartnerIncompleteForm.controls).forEach(a => {
        const control = this.customerPartnerIncompleteForm.get(a);
        if(control.invalid) {
          invalidField.push(a);
        }
      });
      this.customerPartnerIncompleteForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      console.log("Invalid Field: ", invalidField);
    }
  }

  generateSaveCPPayload() {
    const formValue = this.customerPartnerIncompleteForm.getRawValue();
    const searchCriteria = this.store.selectSnapshot(state => state.SatMotorState.data.step2.search);
    const date = new Date();
    const effectiveDate = moment(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'YYYY-M-D').format('YYYY-MM-DD');
    const user = this.store.selectSnapshot<string>((state) => state.UserState.user.userId);

    const payload = {
      clientPartner: {
        clientAge: formValue.age,
        clientDateofBirth: formValue.dob ? moment(formValue.dob, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientEmail: formValue.email,
        clientGender: formValue.gender,
        clientMobileCode1: formValue.mobileNoCode,
        clientMobileNo1: formValue.mobileNo,
        clientName: formValue.name,
        clientNationality: formValue.nationality,
        clientMaritalStatus: formValue.maritalStatus,
        clientPartnerAddress: {
          clientUnitNo: formValue.unitNo,
          clientAddress1: formValue.address1,
          clientAddress2: formValue.address2,
          clientAddress3: formValue.address3,
          clientAddressType: formValue.addressType,
          clientCity: formValue.city,
          clientCountry: formValue.country,
          clientFax1Code: formValue.faxNoCode,
          clientFax1No: formValue.faxNo,
          clientPostcode: formValue.postCode,
          clientState: formValue.state,
          clientGeoLatitude : formValue.clientGeoLatitude,
          clientGeoLongitude : formValue.clientGeoLongitude,
          clientGeoPlaceId : formValue.clientGeoPlaceId,
          clientGeoPlusCode : formValue.clientGeoPlusCode,
          clientGeoFormattedAddress : formValue.clientGeoFormattedAddress
        },
        clientType: searchCriteria.clientType,
        defaultMailReq: false,
        idType1: searchCriteria.idType,
        idType2: formValue.idType2,
        idValue1: searchCriteria.idValue,
        idValue2: formValue.idValue2,
        updateCustomerPartner: false,
        clientTinNo: formValue.tinNo,
        clientSstRegInd: formValue.sstRegisteredInd === 'Y' ? true : false,
        clientSstRegNo: formValue.sstNo,
        clientSstRegDate: formValue.sstEffDate && moment(formValue.sstEffDate).isValid() ? moment(formValue.sstEffDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstDeRegDate: formValue.sstDeRegDate && moment(formValue.sstDeRegDate).isValid() ? moment(formValue.sstDeRegDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstResidentStatus: formValue.sstResidentStatus,
        clientSstCountry: formValue.sstCountry,
        smeCategory: formValue.smeCategory,
        smeEndDate: formValue.smeEndDate ? moment(formValue.smeEndDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        smeRegisteredInd: formValue.smeRegisteredInd === 'Y' ? true : false,
        smeRegisteredNumber: formValue.smeRegNo,
        smeStartDate: formValue.smeStartDate ? moment(formValue.smeStartDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : ''
      },
      mkagAgent: this.mkagAgent,
      effectiveDate: effectiveDate,
      productType: 'MT',
      operator: user
    }

    return payload;
  }

  goBack() {
    this.back.emit();
  }

  saveAsDraft() {
    if(this.customerPartnerIncompleteForm.valid) {
      const payload = this.generateSaveCPPayload();
      this.store.dispatch(new SAVE_CUSTOMER_PARTNER(payload)).subscribe(state => {
        this.store.dispatch(new SAVE_AS_DRAFT({ userId: this.userInfo.userId })).subscribe(state => {
          let cpDetails = state.SatMotorState.motor.step2;
          if(cpDetails) {
            this.store.dispatch(new SET_CUSTOMER_PARTNER_SEARCH_TYPE("FOUND"));
            this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "630px",
              data: { 
                type: "e-Quotation Saved", 
                message: "Your e-Quotation has been saved as a Draft. Here is your Draft Reference No. " + state.SatMotorState.motor.step2.draftNo + " . You may return to this e-Quotation through the Draft Summary Screen.",
                btnOkLabel: "BACK TO SUMMARY LISTING", 
              },
            });
            this.infoMessageModal.afterClosed().subscribe((result) => {
              this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                window.location.href = state.SatMotorState.data.switchSiteUrl;
              });       	      
            });
            
          }
        })
      });
      
    }else {
      this.customerPartnerIncompleteForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }
  }

  getDefaultAddressType() {
    return this.isCompany ? 'O' : 'R';
  }

  get isCompany() {
    return this.cpDetails.clientType === CLIENTTYPE.COMPANY;
  }
  
}
