import {
  Component,
  ElementRef,
  HostListener,
  Input,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from "@angular/core";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NX_MODAL_DATA, NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../../popup/endorsement-popup/endorsement-popup.component";
import { PolicyMovementComponent } from '@shared/components/policy-movement/policy-movement.component';
// import { VehicleDetailsComponent } from '@shared/components/vehicle-details/vehicle-details.component';
import { ExtraCoverComponent } from '@shared/components/extra-cover/extra-cover.component';
import { NamedDriverComponent } from '@shared/components/named-driver/named-driver.component';
import { NoClaimDiscountComponent } from '@shared/components/no-claim-discount/no-claim-discount.component';
import { VehicleSumInsuredComponent } from '@shared/components/vehicle-sum-insured/vehicle-sum-insured.component';
// import { CustomerPartnerComponent } from '@shared/components/customer-partner/customer-partner.component';
import { VehicleDetailsComponent } from '@shared/components/vehicle-details/vehicle-details.component';
import { SharedModule } from '@shared/shared.module';
import { CoverageComponent } from '../../issuance/coverage/coverage.component';
import { STORE_ENDORSEMENT } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { EndorsementVehicleDetailsComponent } from './endorsement-vehicle-details/endorsement-vehicle-details.component';
import { EndorsementCustomerPartnerComponent } from './endorsement-customer-partner/endorsement-customer-partner.component';


@Component({
  selector: 'app-endorsement-details',
  standalone: true,
  imports: [
    SharedModule,
    ProgressBarComponent,
    PolicyMovementComponent,
    EndorsementVehicleDetailsComponent,
    ExtraCoverComponent,
    NamedDriverComponent,
    NoClaimDiscountComponent,
    VehicleSumInsuredComponent,
    EndorsementCustomerPartnerComponent,
    // CustomerPartnerIndComponent,
    // CustomerPartnerComComponent,
    VehicleDetailsComponent,
  ],
  templateUrl: './endorsement-details.component.html',
  styleUrl: './endorsement-details.component.scss'
})
export class EndorsementDetailsComponent {

  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.endtStep1Issuance) endtIssuanceData$;

  @ViewChild(EndorsementVehicleDetailsComponent) endorsementVehicleDetailsChild: EndorsementVehicleDetailsComponent;
  @ViewChild(ExtraCoverComponent) extraCoverChild: ExtraCoverComponent;
  @ViewChild(NamedDriverComponent) namedDriverChild: NamedDriverComponent;
  @ViewChild(NoClaimDiscountComponent) noClaimDiscountChild: NoClaimDiscountComponent;
  @ViewChild(VehicleSumInsuredComponent) vehicleSumInsuredChild: VehicleSumInsuredComponent;
  @ViewChild(EndorsementCustomerPartnerComponent) endorsementCustomerPartnerChild: EndorsementCustomerPartnerComponent;

  @ViewChild('warningPopup') warningPopup!: TemplateRef<any>;

  isEndorsement: boolean = true;
  nextButtonDisabled: boolean = false;
  endorsementType: any;
  endorsementTypeListOfValues: any;

  templateDialogRef?: NxModalRef<any>;
  // componentDialogRef?: NxModalRef<CoverageComponent>;

  popup_type: any;
  popup_message: any;

  cngeNoteData: any;
  customerData: any;
  agentData: any;
  coverageData: any;

  COEngine = 'COE'; //Change of Engine
  Chassis = 'ENDT10'; //Change of Vehicle Chassis No
  VehNo = 'ENDT12'; //Change of Vehicle Registration No
  Loan = 'ENDT18'; //Hire Purchase/Employers' Loan/Leasing
  Model = 'ENDT34'; // Change of Make & Model
  RevisedSI = 'ENDT30'; // Revision of Sum Insured
  ExtCov = 'ENDT17'; // Extra Benefits
  NCDAllow = 'ENDT23'; //NCD Allowed
  NCDAmend = 'ENDT24'; //NCD Amendment
  NCDWithdraw = 'ENDT25'; //NCD Withdraw
  NamedDriver = 'ENDT22'; //Named Driver
  Reinstate = 'ENDT29'; //Reinstatement of Extra Coverage
  //////////////////////////////////////////////////////////////
  EInvoiceDetails = 'EC175'; // Amendment of E-Invoice Details
  EInvoiceConsent = 'EC176'; // Change of E-Invoice Consent

  endorsementTypeVehicleDetails(): boolean {
    // console.log("Checking endorsement type for vehicle details:", this.endorsementType);
    return this.endorsementType === this.COEngine ||
      this.endorsementType === this.Chassis ||
      this.endorsementType === this.Model ||
      this.endorsementType === this.VehNo ||
      this.endorsementType === this.Loan;
  }
  // endorsementTypeVehicleDetails: boolean = true;

  endorsementTypeVehicleSumInsured(): boolean {
    return this.endorsementType === this.RevisedSI;
  }
  // endorsementTypeVehicleSumInsured: boolean = true;

  endorsementTypeNoClaimDiscount(): boolean {
    return this.endorsementType === this.NCDAllow ||
      this.endorsementType === this.NCDAmend ||
      this.endorsementType === this.NCDWithdraw;
  }
  // endorsementTypeNoClaimDiscount: boolean = true;

  endorsementTypeExtraCoverage(): boolean {
    return this.endorsementType === this.Reinstate ||
      this.endorsementType === this.ExtCov;
  }
  // endorsementTypeExtraCoverage: boolean = true;

  endorsementTypeNamedDriver(): boolean {
    return this.endorsementType === this.NamedDriver;
  }
  // endorsementTypeNamedDriver: boolean = true;

  endorsementTypeEInvoiceCustomerPartner(): boolean { //need to check fsd to fix for individual or company
    return this.endorsementType === this.EInvoiceDetails;
  }
  // endorsementTypeEInvoiceCustomerPartner: boolean = true;

  ///////////////////////////////////////////////////
  endorsement_details = true;

  constructor(
    private dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) { }

  ngOnInit() {

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;
        // console.log("EndorsementDetailsComponent isEndorsement", this.isEndorsement);
      }
    });

    this.endtIssuanceData$.subscribe((state) => {
      if (state) {
        this.endorsementType = state.issuance.endorsementType;
        console.log("this.endorsementType", this.endorsementType);
      }
    });

  }

  // ngAfterViewInit() {}

  prepareEndorsementTypeListLov(): any {
    const lov = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov);
    this.endorsementTypeListOfValues = lov?.endorsementList;
  }

  prepareEndorsementVehicelPolicyData(): any {
    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    console.log("endtVehiclePolicyData:", endtVehiclePolicyData);

    if (endtVehiclePolicyData) {
      this.customerData = endtVehiclePolicyData?.clientPartner;
      this.agentData = endtVehiclePolicyData?.mkagAgent;
      this.coverageData = endtVehiclePolicyData?.riskList;

      // console.log("customerData:", this.customerData);
      // console.log("agentData:", this.agentData);
      // console.log("coverageData:", this.coverageData);
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }
  }

  // closeTemplateDialog() {
  //   this.templateDialogRef?.close();
  // }

  openWarningPopup(popupMessage: string): void {
    this.dialogService.open(this.warningPopup, {
      data: { popup_message: popupMessage }
    });
  }

  goBack() {
    this.router.navigate(["endorsement/declaration-issuance-info"], {
      queryParamsHandling: 'preserve',
    });
  }

  goNext() {

    if (this.endorsementVehicleDetailsChild) {
      const hasChanged = this.endorsementVehicleDetailsChild.validateChangedFields();

      if (!hasChanged) {
        this.openWarningPopup('Kindly make changes to proceed for endorsement.');
        return;
      }
      
      this.endorsementVehicleDetailsChild.storeEndorsementDetailsData();
    }
    if (this.extraCoverChild) {
      this.extraCoverChild.storeEndorsementDetailsData();
    }
    if (this.namedDriverChild) {
      this.namedDriverChild.storeEndorsementDetailsData();
    }
    if (this.noClaimDiscountChild) {
      this.noClaimDiscountChild.storeEndorsementDetailsData();
    }
    if (this.vehicleSumInsuredChild) {
      this.vehicleSumInsuredChild.storeEndorsementDetailsData();
    }
    if (this.endorsementCustomerPartnerChild) {
      this.endorsementCustomerPartnerChild.storeEndorsementDetailsData();
    }

    this.router.navigate(['endorsement/summary'], {
      queryParamsHandling: 'preserve',
    });

  }

}
