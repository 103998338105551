
import {
  ChangeDetectorRef,
  Component,
  importProvidersFrom,
  ViewChild,
} from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PdpaPopupComponent } from "../../../../popup/pdpa-popup/pdpa-popup.component";
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { UserResponse } from "@interfaces/user";
import { STORE_AGENT_ADDITIONAL } from "@store/sat-motor/sat-motor.action";

@Component({
  selector: 'app-agent-additional-info',
  standalone: true,
  imports: [SharedModule, ProgressBarComponent],
  templateUrl: './agent-additional-info.component.html',
  styleUrl: './agent-additional-info.component.scss'
})
export class AgentAdditionalInfoComponent {

  
  @Select(SatMotorState.motorAgentAdditionalDetail) agentAdditionalDetail$;

  userInfo: any;

  agentData: any;
  customerType: any;
  isBancaUser: Boolean;
  isBAUser: Boolean;
  isFRUser: Boolean;
  isSNUser: Boolean;
  commonLov: any;

  bankChannels: any[] = [];
  bankSubChannels: any[] = [];
  bankBranches: any[] = [];
  salesCampaigns: any[] = [];
  bankClientTypes: any[] = [];

  selectedBankChannel: any;
  selectedBankSubChannel: any

  additionalInfoForm = new FormGroup({
    bankType: new FormControl('', Validators.required),
    bankChannel: new FormControl('', Validators.required),
    bankSubChannel: new FormControl('', Validators.required),
    bankBranch: new FormControl('', Validators.required),
    bankStaffNo: new FormControl(''),
    bankStaffName: new FormControl(''),
    referralSalesStaffId: new FormControl(''),
    referralSalesStaffName: new FormControl(''),
    bankClientType: new FormControl('', Validators.required),
    salesCampaign: new FormControl(''),
    rebateType: new FormControl('')
  });

  constructor(
    private dialogService: NxDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
  ) {
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    if(this.userInfo) {
      this.isBancaUser = this.userInfo.branchAdmin || this.userInfo.callCentreOrBancaAgent;
    }
    
    this.commonLov = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov);
    this.agentData = this.store.selectSnapshot(SatMotorState.motorAgentDetail);
    if(this.agentData) {
      this.bankChannels = this.agentData.bankChannels;
      this.bankClientTypes = this.agentData.bankClientTypes.map(client => ({
        ...client,
        descp: client.descp.toUpperCase()
      }));
    }
    
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });

    this.agentAdditionalDetail$.subscribe((state) => {
      if (state) {
        this.additionalInfoForm.patchValue(state);
      }
    });
    
    this.customerType = "BANCASSURANCE";

    //this.isBancaUser = true;
    //this.isBAUser = true;
    //this.isFRUser = true;
    //this.isSNUser = true;

   

  }

  onBankChannelChange(code: string) {
    const selectedChannel = this.bankChannels.find(channel => channel.code === code);
    if (selectedChannel) {
      this.bankSubChannels = selectedChannel.subList || [];
      this.bankBranches = [];
      this.salesCampaigns = [];
      this.additionalInfoForm.controls.bankSubChannel.setValue('');
      this.additionalInfoForm.controls.bankBranch.setValue('');
      this.additionalInfoForm.controls.salesCampaign.setValue('');
    }
  }
  

  onBankSubChannelChange(code: string) {
    const selectedSubChannel = this.bankSubChannels.find(subChannel => subChannel.code === code);
    if (selectedSubChannel) {
      this.bankBranches = selectedSubChannel.subList || [];
      this.salesCampaigns = selectedSubChannel.salesCampaignList || [];
      this.additionalInfoForm.controls.bankBranch.setValue('');
      this.additionalInfoForm.controls.salesCampaign.setValue('');
    }
  }
  

  goToSelectAgentCode() {
    console.log('goToSelectAgentCode..');
    this.router.navigate(['agent-selection'], {
      queryParamsHandling: 'preserve',
      });
  }

  goNext() {
    console.log('Next Clicked');

    let agentAdditionalData = this.additionalInfoForm.value;
    this.store.dispatch(new STORE_AGENT_ADDITIONAL(agentAdditionalData)).subscribe(() => {
      this.router.navigate(['issuance/declaration-issuance-info'], {
        queryParamsHandling: 'preserve',
      });
    });

  }

}
