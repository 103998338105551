import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgIf } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { PremiumSummaryComponent } from "@shared/components/premium-summary/premium-summary.component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ExtraCoverageComponent } from "@shared/components/coverages/extra-coverage/extra-coverage.component";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject, pairwise, startWith, takeUntil } from "rxjs";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { DELETE_COVERAGE, DELETE_EXTRA_COVER_DETAILS, GET_NCD_ENQUIRY, STORE_NCD_INFO, UPDATE_COVERAGE_ALERT } from "@store/sat-motor/sat-motor.action";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NcdMessageComponent } from "@shared/components/modal/ncd-message/ncd-message.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { OkMessageComponent } from "@shared/components/modal/ok-message/ok-message.component";

@Component({
  selector: "app-coverage-third-party",
  standalone: true,
  imports: [NgIf, SharedModule, PremiumSummaryComponent, ExtraCoverageComponent],
  templateUrl: "./coverage-third-party.component.html",
  styleUrl: "./coverage-third-party.component.scss",
})
export class CoverageThirdPartyComponent implements OnInit {
  @ViewChild(ExtraCoverageComponent) extraCoverageComponentChild: ExtraCoverageComponent;
  @Input() coverageFmGroup!: FormGroup;
  @Input() isThirdParty!: boolean;
  @Input() lov!: any;
  @Input() defaultDriver: any;
  @Input() issuanceInfo: any;
  @Input() partnerInfo: any;

  @Output() onCoverDeleted = new EventEmitter<unknown>();
  @Output() onCoverFormChanged = new EventEmitter<unknown>();
  @Output() alimCustomerEligible = new EventEmitter<unknown>();
  @Output() bettermentEligible = new EventEmitter<unknown>();

  @Select(SatMotorState.currentTypeOfCoverage) currentTypeOfCoverage$: Observable<SatMotorState>;
  @Select(SatMotorState.coverage) coverage$: Observable<SatMotorState>;
  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;
  @Select(SatMotorState.coverageShowAlert) coverageShowAlert$: Observable<SatMotorState>;
  private destroy$ = new Subject<void>();

  thirdPartyForm: FormGroup<any>;
  vehicleAgeLoadingList: any;
  driverAgeLoadingList: any;
  claimsExpLoadingList: any;
  vehicleAgeLoadingOldValue: any;
  driverAgeLoadingOldValue: any;
  claimsExpLoadingOldValue: any;
  vehicleNo: any;
  typeOfCoverage: any;
  isShowExtraCoverage: boolean = false;
  oldValue = {};
  decimalFmt: any;
  numberFmt: any;

  isDisabledGetNCD: boolean;
  hasClickGetNcd: boolean;
  ncdMessageModal?: NxModalRef<NcdMessageComponent>;
  warningMessageModal?: NxModalRef<SimpleModalComponent>;
  okMessageModal?: NxModalRef<OkMessageComponent>;

  coverageAlert: any;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.thirdPartyForm = this.fb.group({
      sumInsured: [{ value: 0, disabled: true }],
      annualPremium: [{ value: "", disabled: true }],
      grossPremium: [{ value: "", disabled: true }],
      basicPremium: [{ value: "", disabled: true }],
      allriderPrem: [{ value: "", disabled: true }],
      vehicleAgeLoading: "",
      driverAgeLoading: "",
      claimExperienceLoading: "",
      loadingAmount: [{ value: "", disabled: true }],
      ncdPercentage: [{ value: "", disabled: true }],
      ncdAmount: [{ value: "", disabled: true }],
      prevNcdInsurer: "",
      prevNcdVeh: ["", VehicleNoValidator()],
      loadingChanges: false
    });
  }

  ngOnInit() {
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.issuance.issuance);
    this.vehicleNo = this.issuanceInfo.vehicleNo;
    this.coverageAlert = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3?.coverage?.coverageShowDialog);

    this.typeOfCoverage = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    if (this.typeOfCoverage  && this.isThirdParty) { 

      this.thirdPartyForm.patchValue({
        sumInsured: this.decimalFmt.transform(this.typeOfCoverage["sumInsured"]),
        excessAmount: this.decimalFmt.transform(this.typeOfCoverage["excessAmount"]),
        annualPremium:this.decimalFmt.transform( this.typeOfCoverage["annualPremium"]),
        basicPremium: this.decimalFmt.transform(this.typeOfCoverage["basicPremium"]),
        allriderPrem: this.decimalFmt.transform(this.typeOfCoverage["allriderPrem"]),
        vehicleAgeLoading: this.typeOfCoverage["vehicleAgeLoading"],
        driverAgeLoading: this.typeOfCoverage["driverAgeLoading"],
        claimExperienceLoading: this.typeOfCoverage["claimExperienceLoading"],
        grossPremium: this.decimalFmt.transform(this.typeOfCoverage["grossPremium"]),
        ncdPercentage: this.decimalFmt.transform(this.typeOfCoverage["ncdPercentage"]),
        ncdAmount: this.decimalFmt.transform(this.typeOfCoverage["ncdAmount"]),
        prevNcdVeh: this.decimalFmt.transform(this.typeOfCoverage["prevNcdVeh"]),
        prevNcdInsurer: this.typeOfCoverage["prevNcdInsurer"],
      });
      this.vehicleAgeLoadingOldValue = this.typeOfCoverage["vehicleAgeLoading"];
      this.driverAgeLoadingOldValue = this.typeOfCoverage["driverAgeLoading"];
      this.claimsExpLoadingOldValue = this.typeOfCoverage["claimExperienceLoading"];
    }

    // this.thirdPartyForm.valueChanges.pipe(startWith(this.thirdPartyForm.value), pairwise()).subscribe(([old, value]) => {
    //   this.oldValue = old;
    // });

    this.vehicleAgeLoading.valueChanges.subscribe((change) => {
      if (this.vehicleAgeLoadingOldValue !== change) {
        this.vehicleAgeLoadingOldValue = change;
        this.thirdPartyForm.patchValue({ loadingChanges: true });
        this.onCoverFormChanged.emit();
      }
    });

    this.driverAgeLoading.valueChanges.subscribe((change) => {
      if (this.driverAgeLoadingOldValue !== change) {
        this.driverAgeLoadingOldValue = change;
        this.thirdPartyForm.patchValue({ loadingChanges: true });
        this.onCoverFormChanged.emit();
      }
    });

    this.claimExperienceLoading.valueChanges.subscribe((change) => {
      if (this.claimsExpLoadingOldValue !== change) {
        this.claimsExpLoadingOldValue = change;
        this.thirdPartyForm.patchValue({ loadingChanges: true }); 
        this.onCoverFormChanged.emit();
      }
    });

    this.coverage$.subscribe((state) => {
      if (state) {
        const forceDelete = state["forceDelete"];
        if (forceDelete) {
          const coverageType = state["coverageType"];
          if (coverageType && coverageType["coverCode"] === "20") {
            this.deleteCover(null);
          }
        }
      }
    });

    this.currentTypeOfCoverage$.subscribe((state) => {
      if (state) {
        if (state["coverCode"] === "20") {
          this.coverageFmGroup.controls["typeOfCoverage"] = this.thirdPartyForm;
          this.typeOfCoverage = state;
          if (this.typeOfCoverage && this.isThirdParty) {
            let coverListLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data?.step3?.coverage?.coverListLov);
            for (let coverList of coverListLov) {
              if (coverList["coverCode"] === "20") {
                this.typeOfCoverage = { ...this.typeOfCoverage, coverDescription: coverList["coverDescription"] };
              }
            } 
          }
        }
      }
    });

    this.coverageShowAlert$.subscribe((state) => {
      if (state) {
        if (state["coverCode"] === "20") {
          const grossPremiumWarning = state["grossPremiumWarning"];
  
          if (state["bettermentEligible"] && !state["bettermentEligibleShowed"]) {
            this.bettermentEligible.emit();
          } 
          else if (state["alimCustomerEligible"] && !state["alimCustomerEligibleShowed"]) {
            this.alimCustomerEligible.emit();
          } 
          else if (grossPremiumWarning && !grossPremiumWarning["valid"]) {
            this.showGrossPremiumWarning(grossPremiumWarning["value"]);
          }
        }
      }
    });

    this.coveragePremium$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (state) {

        if(!this.isThirdParty){
          return;
        }

        this.isShowExtraCoverage = false;

        const cngeNote = state["cngeNote"];
        if (cngeNote) {

          if (cngeNote.bettermentEligible) {
            this.coverageAlert = { ...this.coverageAlert, bettermentEligible: cngeNote.bettermentEligible };
          }

          const riskList = cngeNote["riskList"];
          if (riskList?.length) {

            for (let riskListItem of riskList) {
              this.prevNcdVeh.setValue(riskListItem.prevNcdVeh || riskListItem.vehicleNo);
              this.prevNcdInsurer.setValue(riskListItem.prevNcdInsurer);
              
              const coverList = riskListItem["coverList"];
              if (coverList?.length) {
                for (let coverListItem of coverList) {
                  if (this.isThirdParty && coverListItem["coverDto"].subCovList?.length > 0) {
                    this.isShowExtraCoverage = true;
                  }

                  this.coverageAlert = { ...this.coverageAlert, coverCode: coverListItem["coverCode"] };
                  
                  if (coverListItem["coverCode"] !== "20") return;

                  const grossPremium = coverListItem["grossPremium"];
                  const grossPremiumWarning = this.setGrossPremiumWarning(grossPremium);
                  this.coverageAlert = { ...this.coverageAlert, grossPremiumWarning: grossPremiumWarning };
                  
                  if (coverListItem.alimCustomerEligible) {
                    this.coverageAlert = { ...this.coverageAlert, alimCustomerEligible: coverListItem.alimCustomerEligible };
                  }

                  if(coverListItem["loadAmount"]){
                    this.thirdPartyForm.patchValue({
                      loadingAmount: coverListItem["loadAmount"].toFixed(2)
                    });
                  }

                  const coverDto = coverListItem["coverDto"];
                  if (coverDto) {
                    this.vehicleAgeLoadingList = coverDto["vehicleAgeLoadingList"];
                    this.driverAgeLoadingList = coverDto["driverAgeLoadingList"];
                    this.claimsExpLoadingList = coverDto["claimsExpLoadingList"];
                  }

                  const coverLoadingList = coverListItem["coverLoadingList"];
                  for (let coverLoadingItem of coverLoadingList) {
                    if (coverLoadingItem) {
                      if (coverLoadingItem["loadingCode"] === "VEHAGE" ) {
                        this.vehicleAgeLoadingOldValue = coverLoadingItem["loadingPct"];
                        this.thirdPartyForm.patchValue({
                          vehicleAgeLoading: this.vehicleAgeLoadingOldValue
                        });
                      } else if (coverLoadingItem["loadingCode"] === "DRVRAGE" ) {
                        this.driverAgeLoadingOldValue = coverLoadingItem["loadingPct"];
                        this.thirdPartyForm.patchValue({
                          driverAgeLoading: this.driverAgeLoadingOldValue
                        });
                      } else if (coverLoadingItem["loadingCode"] === "CLMEXP" ) {
                        this.claimsExpLoadingOldValue = coverLoadingItem["loadingPct"];
                        this.thirdPartyForm.patchValue({
                          claimExperienceLoading: this.claimsExpLoadingOldValue
                        });
                      }
                    }
                  }
                  
                  this.typeOfCoverage = {
                    ...this.typeOfCoverage,
                    ...{ grossPremium: grossPremium },
                    ...{ basicPremium: coverListItem["basicPremium"] },
                    ...{ ncdPercentage: coverListItem["ncdPercentage"] },
                    ...{ ncdAmount: coverListItem["ncdAmount"] },
                    ...{ subCoverPremium: coverListItem["subCoverPremium"] },
                    ...{ loadPercentage: coverListItem["loadPercentage"] },
                    ...{ loadAmount: coverListItem["loadAmount"] },
                  };
                }
              }
            }
          }
          this.checkDisabledGetNCD();

        }

        if (this.coverageAlert) {
          this.store.dispatch(new UPDATE_COVERAGE_ALERT(this.coverageAlert));
        }
      }
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeForm($event: any) {

    let data = {
      fieldName: $event.target.getAttribute("formControlName"),
      value: $event.target.value,
      oldValue: this.oldValue[$event.target.getAttribute("formControlName")],
    };

    const doChangeForm = {
      prevNcdVeh: () => {
        let vehicleNo = this.thirdPartyForm.get("prevNcdVeh");
        if (this.issuanceInfo.cnoteType === "NWTR") {
          this.checkDisabledGetNCD();
        } else if (this.issuanceInfo.cnoteType === "NWNN" || this.issuanceInfo.cnoteType === "NWOO") {
          if(vehicleNo.valid && vehicleNo.value && vehicleNo.value != 'NA') {
            this.getNCD();
          }
        }
        
      },
    };

    // if (data.oldValue !== data.value) {
    doChangeForm[data.fieldName]();
    // }
  }

  deleteCover(cover: any) {
    this.store.dispatch(new DELETE_COVERAGE(cover)).subscribe((state) => {
      this.thirdPartyForm.reset();
      this.onCoverDeleted.emit();
    });
    this.store.dispatch(new DELETE_EXTRA_COVER_DETAILS(cover)).subscribe((state) => {});

    this.typeOfCoverage = this.coverageAlert = null;
    this.isShowExtraCoverage = false;
    
    this.hasClickGetNcd = false;
    this.isDisabledGetNCD = false;
  }

  checkDisabledGetNCD() {
    if(this.hasClickGetNcd) {
      this.isDisabledGetNCD = true;
      return;
    }

    let vehicleNo = this.thirdPartyForm.get("prevNcdVeh");
    if (this.issuanceInfo.cnoteType === "NWTR" && vehicleNo.valid && vehicleNo.value && vehicleNo.value != 'NA') {
      this.isDisabledGetNCD = false;
    } else {
      this.isDisabledGetNCD = true;
    }
  }

  onClickGetNCD() {
    this.hasClickGetNcd = true;
    this.isDisabledGetNCD = true;

    let vehicleDetailsForm = this.coverageFmGroup.get("vehicleDetails");
    let typeOfCoverageForm = this.coverageFmGroup.get("typeOfCoverage");

    let payload = {
      productType: this.issuanceInfo.productCode,
      vehicleUse: vehicleDetailsForm.get("vehicleUse").value,
      vehicleNo: vehicleDetailsForm.get("vehicleNo").value,
      previousNcdVehicle: typeOfCoverageForm.get("prevNcdVeh").value,
      chassisNo: vehicleDetailsForm.get("vehicleChassis").value,
      idType: this.partnerInfo.idType1,
      idNo1: this.partnerInfo.idValue1
    };

    this.store.dispatch(new GET_NCD_ENQUIRY(payload)).subscribe((state) => {
      let ncdInfo = state.SatMotorState.ncdEnquiryDetails;
      if (ncdInfo) {
        this.store.dispatch(new STORE_NCD_INFO(ncdInfo));
        if (ncdInfo.errMsg) {
          this.okMessageModal = this.dialogService.open(OkMessageComponent, {
            width: '400px',
            data: {
              message: ncdInfo.errMsg
            }
          });
        } else {
          this.thirdPartyForm.patchValue({
            ncdPercentage: this.decimalFmt.transform(ncdInfo.currNcdPct),
          });
          this.onCoverFormChanged.emit();
        }
      }
    });
  }

  getNCD() {
    let vehicleDetailsForm = this.coverageFmGroup.get("vehicleDetails");
    let typeOfCoverageForm = this.coverageFmGroup.get("typeOfCoverage");

    if (vehicleDetailsForm && typeOfCoverageForm) {
      let payload = {
        productType: this.issuanceInfo.productCode,
        vehicleUse: vehicleDetailsForm.get("vehicleUse").value,
        vehicleNo: vehicleDetailsForm.get("vehicleNo").value,
        previousNcdVehicle: typeOfCoverageForm.get("prevNcdVeh").value,
        chassisNo: vehicleDetailsForm.get("vehicleChassis").value,
        idType: this.partnerInfo.idType1,
        idNo1: this.partnerInfo.idValue1
      };

      this.store.dispatch(new GET_NCD_ENQUIRY(payload)).subscribe((state) => {
        let ncdInfo = state.SatMotorState.ncdEnquiryDetails;
        if (ncdInfo) {
          this.store.dispatch(new STORE_NCD_INFO(ncdInfo));
          if (ncdInfo.errMsg) {
            this.okMessageModal = this.dialogService.open(OkMessageComponent, {
              width: '400px',
              data: {
                message: ncdInfo.errMsg
              }
            });
          } else {
            this.thirdPartyForm.patchValue({
              ncdPercentage: this.decimalFmt.transform(ncdInfo.currNcdPct),
            });
            this.onCoverFormChanged.emit();
          }
        }
      });
    }
  }


  isPrivateCars() {
    return ["080100", "080200", "080300"].includes(this.issuanceInfo.productCode);
  }

  isMotorcycle() {
    return ["082100", "082101"].includes(this.issuanceInfo.productCode);
  }

  setGrossPremiumWarning(grossPremium : any) {
    let value : any;
    if (this.isPrivateCars() && grossPremium < 50) {
      value = "50";
    }
    if (this.isMotorcycle() && grossPremium < 20) {
      value = "20";
    }
    
    if (value) {
      return { valid: false, value: value };
    }
    return { valid: true };
  }  

  showGrossPremiumWarning(value : any) {
    if (value) {
      this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: "Warning", message: "Minimum Gross Premium Applied." },
      });
	  
      this.warningMessageModal.afterClosed().subscribe((result) => {
        this.typeOfCoverage = { ...this.typeOfCoverage, ...{ grossPremium: this.decimalFmt.transform(value) } };
        this.onCoverFormChanged.emit(this.typeOfCoverage);
      });
    }
  }

  onNcdInsurerChange(event: any) {
    if (event.value === null) {
      this.prevNcdInsurer?.setValue('');
    }

    this.getNCD();
  }

  get prevNcdVeh() {
    return this.thirdPartyForm.controls["prevNcdVeh"];
  }

  get prevNcdInsurer() {
    return this.thirdPartyForm.controls["prevNcdInsurer"];
  }

  get vehicleAgeLoading() {
    return this.thirdPartyForm.controls["vehicleAgeLoading"];
  }

  get driverAgeLoading() {
    return this.thirdPartyForm.controls["driverAgeLoading"];
  }

  get claimExperienceLoading() {
    return this.thirdPartyForm.controls["claimExperienceLoading"];
  }

  callThirdPartyComponent(methodName){
    if(methodName === 'callExtraCoverageCalculatePremium'){
      return this.extraCoverageComponentChild.calculatePremium();
    }else{
      return this.extraCoverageComponentChild.motorRoadWarriorValidation();
    }
  }
}
