import { Directive, ElementRef, HostListener, Injector, Input, Self } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[appSumInsuredFormatter]',
  standalone: true,
})
export class SumInsuredFormatterDirective {
  constructor(
    private el: ElementRef, private injector: Injector,
    @Self() private model: NgControl = injector.get(NgControl)
  ) { }

  value: any = { old: null, new: null };

  private regex: RegExp = new RegExp(/^\d{1,8}(\.\d{0,2})?$/);

  private specialKeys: Array<string> = ["Backspace", "Delete", "Tab", "End", "Home", "ArrowLeft", "ArrowRight"];
  private nonNumericKeys: Array<string> = "!,@,#,$,%,^,&,*,(,),_,+,=,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z".split(",");


  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }    
    if (this.nonNumericKeys.indexOf(event.key) !== -1) {
      event.preventDefault();
    }
    this.value = { ...this.value, old: this.el.nativeElement.value };
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (this.nonNumericKeys.indexOf(event.key) !== -1) {
      event.preventDefault();
    }
    this.value = { ...this.value, new: this.el.nativeElement.value };

    if (this.value.new && String(this.value.new).match(this.regex)) {
      console.log("match");
    } else {
      event.preventDefault();
      this.model.control.setValue(this.removeComma(this.value.old));
    }
  }

  @HostListener('focus') onFocus() {
    let newVal = this.removeComma(this.el.nativeElement.value.toString());
    if (newVal) {
      this.value = { old: this.removeComma(this.el.nativeElement.value.toString()), 
                     new: this.removeComma(this.el.nativeElement.value.toString()) };
      this.model.control.setValue(this.removeComma(this.el.nativeElement.value.toString()));
    }
  }

  @HostListener('blur') onBlur() {
    let newVal = this.removeComma(this.el.nativeElement.value.toString());
    if (newVal) {
      this.value = { old: null, new: null };
      this.model.control.setValue(Number(newVal).toLocaleString(undefined, { minimumFractionDigits: 2 }));
    }
  }

  removeComma(value) {
    let newValue = value.toString().replace(/[a-zA-Z:,]+/g, "");
    return newValue;
  }
  
}
