import { Component, TemplateRef, ViewChild } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { Route, Router } from "@angular/router";
import { Location } from "@angular/common";
import { SharePopupComponent } from "../share-popup/share-popup.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";

@Component({
  selector: "app-product-details",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./product-details.component.html",
  styleUrl: "./product-details.component.scss",
})
export class ProductDetailsComponent {
  // page2
  items = [
    "e-Library",
    "Products",
    "Personal Insurance",
    "Automotive and Travel",
    "Motor Comprehensive Cover",
  ];

  download_list = [
    { name: "Motor Claims Guide (ENG)", date: "27 Feb 2020" },
    { name: "Motor Claims Guide (BM)", date: "27 Feb 2020" },
    { name: "Motor Claims Guide (CHI)", date: "27 Feb 2020" },
    { name: "Motorcycle Policy Jacket (BM)", date: "15 July 2020" },
    { name: "Motorcycle Policy Jacket (ENG)", date: "15 July 2020" },
    { name: "Motor Trade Policy Jacket (ENG-BM)", date: "15 July 2020" },
    { name: "Commercial Policy Jacket (ENG)", date: "19 Jan 2023" },
    { name: "Commercial Policy Jacket (BM)", date: "19 Jan 2023" },
    { name: "Private Car Policy Wording (ENG)", date: "29 Feb 2024" },
    { name: "Private Car Policy Wording (BM)", date: "29 Feb 2024" },
    { name: "Motorcycle Plus Policy Jacket (ENG)", date: "18 Oct 2023" },
    { name: "Motorcycle Plus Policy Jacket (BM)", date: "18 Oct 2023" },
    { name: "Motor Insurance Amendment Form", date: "7 Feb 2024" },
    {
      name: "Motorcycle Road Side Assistance Term & Conditions (ENG)",
      date: "06 Aug 2021",
    },
    {
      name: "Private Car Road Side Assistance Assistance Term & Conditions (BM)",
      date: "06 Aug 2021",
    },
    {
      name: "Private Car Road Side Assistance Assistance Term & Conditions (ENG)",
      date: "05 Aug 2021",
    },
    {
      name: "Motor Comprehensive Insurance Product Disclosure Sheet (ENG)",
      date: "09 Sep 2020",
    },
    { name: "Claims Guide for Motor Accidents (BM)", date: "18 Aug 2020" },
    { name: "Claims Guide for Motor Accidents (CHI)", date: "18 Aug 2020" },
    { name: "Motorcycle Policy Wording (BM)", date: "18 Aug 2020" },
    { name: "Private Car Policy Wording (BM)", date: "18 Aug 2020" },
    {
      name: "Motor Comprehensive Insurance Product Disclosure Sheet (BM)",
      date: "18 Aug 2020",
    },
    { name: "Claims Guide for Motor Accidents (ENG)", date: "10 Aug 2020" },
    { name: "Motor Trade Policy Wording", date: "10 Aug 2020" },
    { name: "Commercial Vehicle Policy Wording", date: "10 Aug 2020" },
    { name: "Motorcycle Policy Wording (ENG)", date: "10 Aug 2020" },
    { name: "Private Car Policy Wording (ENG)", date: "10 Aug 2020" },
  ];
  dynamicItems = this.items;

  constructor(
    private router: Router,
    private _location: Location,
    private dialogService: NxDialogService
  ) {}

  @ViewChild("template") templateRef!: TemplateRef<any>;

  componentDialogRef?: NxModalRef<SharePopupComponent>;

  openSharePopup(): void {
    this.componentDialogRef = this.dialogService.open(SharePopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: true,
    });
  }
  back() {
    this._location.back();
  }
}
