import {
  ChangeDetectorRef,
  Component,
  TemplateRef,
  Renderer2,
  ElementRef,
  Input,
  HostListener,
  ViewChild,
  ViewChildren,
  QueryList
} from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { SortDirection, SortEvent } from "@aposin/ng-aquila/table";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxCardModule } from "@aposin/ng-aquila/card";
import { NxBadgeModule } from "@aposin/ng-aquila/badge";
import { NxIconModule } from "@aposin/ng-aquila/icon";
import { NxDataDisplayModule } from "@aposin/ng-aquila/data-display";
import { NxCopytextModule } from "@aposin/ng-aquila/copytext";
import { NxSwipebarModule } from "@aposin/ng-aquila/swipebar";
import { NxTableModule } from "@aposin/ng-aquila/table";
import { NxAccordionModule } from "@aposin/ng-aquila/accordion";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SEARCH_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { Store } from "@ngxs/store";

@Component({
  selector: "app-enquiry",
  standalone: true,
  imports: [
    SharedModule,
    ProgressBarComponent,
    NxButtonModule,
    NxGridModule,
    NxCardModule,
    NxBadgeModule,
    NxIconModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxSwipebarModule,
    NxTableModule,
    NxAccordionModule
  ],
  templateUrl: "./enquiry.component.html",
  styleUrl: "./enquiry.component.scss",
})
export class EnquiryComponent {

  @Input() data!: any;
  currentIndex = 0;
  @ViewChild("templateOne") templateRef!: TemplateRef<any>;
  @ViewChild("templateTwo") templateRef6!: TemplateRef<any>;
  @ViewChild("templateThree") templateRef7!: TemplateRef<any>;
  @ViewChild("templateApproval") templateRef8!: TemplateRef<any>;
  @ViewChild("templateSummary") templateRef9!: TemplateRef<any>;
  @ViewChild("templateClaimEnq") templateRef10!: TemplateRef<any>;

  showRef(template: TemplateRef<any>, type: any) {
    this.templateRef! = template;
    this.title = type;
  }

  @ViewChild("templateAddRemarks") templateRef1!: TemplateRef<any>;
  @ViewChild("templateApprove") templateRef2!: TemplateRef<any>;
  @ViewChild("templateReject") templateRef3!: TemplateRef<any>;
  @ViewChild("templateMarketerManager") templateRef4!: TemplateRef<any>;
  @ViewChild("templateEnHistory") templateRef5!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<EnquiryComponent>;
  searchModelForm: FormGroup;
  searchModelResult: any;

  title = "Private Cars Excluding Goods";

  requestTypeList = [
    { code: '000001', name: 'ALL' },
    { code: '000002', name: 'CANCELLATION OF CONFIRMED NO CLAIM DISCOUNT' },
    { code: '000003', name: 'NO CLAIM DISCOUNT CONFIRMATION (NEWLY REGISTERED CAR)' },
    { code: '000004', name: 'NO CLAIM DISCOUNT CONFIRMATION (REGISTERED CAR)' },
    { code: '000005', name: 'NO CLAIM DISCOUNT ENQUIRY' }
  ];

  issuanceForm = new FormGroup({});

  constructor(
    private readonly dialogService: NxDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private store: Store
  ) {
    // this.templateRef! = true;
  }


  ngOnInit(): void {

  }



  ngAfterViewInit() {

    this.route.params.subscribe(params => {

      var type = params["type"];
      // console.log(type)

      if (type == "Policy Enquiry") {
        this.showRef(this.templateRef, "Policy Enquiry");
      } else if (type == "No Claim Discount Enquiry") {
        this.showRef(this.templateRef6, 'No Claim Discount Enquiry');
      } else if (type == "NCD Confirmation Enquiry") {
        this.showRef(this.templateRef7, 'NCD Confirmation Enquiry');
      } else if (type == "Motor Approval Enquiry") {
        this.showRef(this.templateRef8, 'Private Cars Excluding Goods');
      } else if (type == "Summary Enquiry") {
        this.showRef(this.templateRef9, 'Summary Enquiry');
      } else if (type == "Claim Enquiry") {
        this.showRef(this.templateRef10, 'Claim Enquiry');
      }
      else {
        this.title = "Private Cars Excluding Goods"
      }
      this.cdr.detectChanges(); // Manually trigger change detection
    });

  }

  onSearchClicked() {
    let value = this.searchModelForm.getRawValue()["searchBy"];
    value = ""; // TODO: to remove
    this.store.dispatch(new SEARCH_MAKE_MODEL({ type: value })).subscribe((res) => {
      this.searchModelResult = res.SatMotorState.data.step3.coverage.searchMakeModelList;
    });
  }

  addRemarks(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef1, {});
  }

  approve(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef2, {});
  }

  reject(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef3, {});
  }

  marketerManager(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef4, {
      height: "500px",
    });
  }

  enRefNo(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef5, {});
  }

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }


}
