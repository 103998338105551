import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NricService } from '@services/nric/nric.service';
import { SharedModule } from '@shared/shared.module';
import { Subject, takeUntil } from 'rxjs';
import { STEP2 } from 'src/app/interfaces/motor';
import { CLIENTTYPE, CONSTANTS, IDTYPE } from 'src/app/shared/enums/index.enum';
import { SmeCertFormComponent } from '../../customer-partner/sme-cert-form/sme-cert-form.component';
import { SstFormDetailsComponent } from '../../customer-partner/sst-form-details/sst-form-details.component';
import { Select, Store } from '@ngxs/store';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { NX_MODAL_DATA, NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { CpSameAgentInfoComponent } from '../cp-same-agent-info/cp-same-agent-info.component';
import { BrnoService } from '@shared/services/brno/brno.service';
import { GET_POSTCODE_INFO, UPDATE_GEO_INDICATOR } from '@store/sat-motor/sat-motor.action';
import { BranchesMapComponent } from '@shared/components/branches-map/branches-map.component';
import { ScrollToError } from '@shared/classes/scroll-to-error';

@Component({
  selector: 'app-customer-partner-edit-modal',
  standalone: true,
  imports: [
    SharedModule,
    SmeCertFormComponent,
    SstFormDetailsComponent,
    BranchesMapComponent
  ],
  templateUrl: './customer-partner-edit-modal.component.html',
  styleUrl: './customer-partner-edit-modal.component.scss'
})
export class CustomerPartnerEditModalComponent implements OnInit {
  @ViewChild(BranchesMapComponent) child!: BranchesMapComponent;
  isShow : boolean;

  lov: any;
  cpDetails: any;
  dataSearch2: any;
  idType2List: any;
  cpSameAgentInfoModal?: NxModalRef<CpSameAgentInfoComponent>;
  mkagAgent: any;
  
  eInvoiceConsent: boolean = false;
  edocConsentInd: boolean = false;
  search_mode_address: boolean = true;
  customerEditForm: FormGroup;

  unsubscribe$ = new Subject<void>();
  motorPC: any;
  lovproductConfig: any;
  productAccessListData: any;
  lovPC: string;
  lovGeo: any;

  constructor(
    private nricService: NricService,
    private brnoService: BrnoService,
    private fb: FormBuilder,
    private store: Store,
    @Inject(NX_MODAL_DATA) data: any,
    private dialogRef: NxModalRef<any>,
    private readonly dialogService: NxDialogService,
  ) {
    this.lov = data.lov;
    this.cpDetails = data.cpDetails;
    this.eInvoiceConsent = data.eInvoiceConsent;
    this.edocConsentInd = data.edocConsentInd;
    this.mkagAgent = data.mkagAgent;
  }

  ngOnInit() {
    this.motorPC = this.store.selectSnapshot<any>((state)=>state.SatMotorState.motor.step1.issuance.issuance.productCode);
    this.lovproductConfig = this.lov.productAccessConfigList;

    
    for (let i = 0; i < this.lovproductConfig.length; i++) {
    this.productAccessListData = this.lovproductConfig[i];
    this.lovPC = Object.keys( this.productAccessListData)[0]; 
  
      if (this.lovPC === this.motorPC) {
       this.lovGeo =this.productAccessListData[this.lovPC].geocodingEnableSat;

        if(this.lovGeo == "true")
          {
            this.isShow = true;
          }
          else{
            this.isShow = false;
          }
      }
  }
    this.initIdType2List();
    this.customerEditForm = this.fb.group({
      name:[this.cpDetails.clientName ? this.cpDetails.clientName : '', Validators.required],
      nationality: [this.cpDetails.clientNationality ? this.cpDetails.clientNationality : ''],
      idType2:[this.cpDetails.idType2 ? this.cpDetails.idType2 : ''],
      idValue2:[this.cpDetails.idValue2 ? this.cpDetails.idValue2 : ''],
      tinNo:[this.cpDetails.clientTinNo ? this.cpDetails.clientTinNo : ''],
      gender:[this.cpDetails.clientGender ? this.cpDetails.clientGender : ''],
      maritalStatus:[this.cpDetails.clientMaritalStatus ? this.cpDetails.clientMaritalStatus : ''],
      dob:[this.cpDetails.clientDateofBirth ? this.cpDetails.clientDateofBirth : ''],
      age:[{value:this.cpDetails.clientAge ? this.cpDetails.clientAge : '', disabled : true}],
      mobileNoCode:[this.cpDetails.clientMobileCode1 ? this.cpDetails.clientMobileCode1 :''],
      mobileNo:[this.cpDetails.clientMobileNo1 ? this.cpDetails.clientMobileNo1 : '', [Validators.required, Validators.pattern("^\\d{7,8}$")]],
      fixedLineNoCode:[this.cpDetails.clientFixedLineNoCode1 ? this.cpDetails.clientFixedLineNoCode1 : ''],
      fixedLineNo:[this.cpDetails.clientFixedLinedNo1 ? this.cpDetails.clientFixedLinedNo1 : '', Validators.pattern("^\\d{7,8}$")],
      faxNoCode:[this.cpDetails.clientFaxNoCode1 ? this.cpDetails.clientFaxNoCode1 : ''],
      faxNo:[this.cpDetails.clientFaxNo1 ? this.cpDetails.clientFaxNo1 : '', Validators.pattern("^\\d{7,8}$")],
      email:[this.cpDetails.clientEmail ? this.cpDetails.clientEmail : ''],
      showMapSwitcher:[false],
      unitNo:[this.cpDetails.clientPartnerAddress.clientUnitNo ? this.cpDetails.clientPartnerAddress.clientUnitNo : ''],
      address1:[this.cpDetails.clientPartnerAddress.clientAddress1Tmp ? this.cpDetails.clientPartnerAddress.clientAddress1Tmp : ''],//, Validators.required],
      address2:[this.cpDetails.clientPartnerAddress.clientAddress2 ? this.cpDetails.clientPartnerAddress.clientAddress2 : ''],
      address3:[this.cpDetails.clientPartnerAddress.clientAddress3 ? this.cpDetails.clientPartnerAddress.clientAddress3 : ''],
      postCode:[this.cpDetails.clientPartnerAddress.clientPostcode ? this.cpDetails.clientPartnerAddress.clientPostcode : '', Validators.required],
      city:[{value: this.cpDetails.clientPartnerAddress.clientCity ? this.cpDetails.clientPartnerAddress.clientCity : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      state:[{value: this.cpDetails.clientPartnerAddress.clientState ? this.cpDetails.clientPartnerAddress.clientState : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      country:[{value: this.cpDetails.clientPartnerAddress.clientCountry ? this.cpDetails.clientPartnerAddress.clientCountry : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      addressType:[this.cpDetails.clientPartnerAddress.clientAddressType ? this.cpDetails.clientPartnerAddress.clientAddressType : this.getDefaultAddressType()],
      clientGeoLatitude: [this.cpDetails.clientPartnerAddress.clientGeoLatitude ? this.cpDetails.clientPartnerAddress.clientGeoLatitude : ''],
      clientGeoLongitude: [this.cpDetails.clientPartnerAddress.clientGeoLongitude ? this.cpDetails.clientPartnerAddress.clientGeoLongitude : ''],
      clientGeoPlaceId: [this.cpDetails.clientPartnerAddress.clientGeoPlaceId ? this.cpDetails.clientPartnerAddress.clientGeoPlaceId : ''],
      clientGeoPlusCode: [this.cpDetails.clientPartnerAddress.clientGeoPlusCode ? this.cpDetails.clientPartnerAddress.clientGeoPlusCode : ''],
      clientGeoFormattedAddress: [this.cpDetails.clientPartnerAddress.clientGeoFormattedAddress ? this.cpDetails.clientPartnerAddress.clientGeoFormattedAddress : '']
    });

    this.initValidators();

    this.onChangeIdType2();
    this.onChangeDOB();
    this.onChangeMobileNo();
    this.onChangefixedLineNo();
    this.initAddressValidators();
  }

  initIdType2List() {
    if(this.cpDetails.clientType === CLIENTTYPE.INDIVIDUAL) {
      if(this.cpDetails.idType1 === IDTYPE.OLD_IC) {
         this.idType2List = [
          { code: IDTYPE.NRIC, descp: IDTYPE.NRIC_DESCP },
          { code: IDTYPE.POL, descp: IDTYPE.POL_DESCP }
        ]
      }
    }else {
      if(this.cpDetails.idType1 === IDTYPE.BR_NO) {
        this.idType2List = [
         { code: IDTYPE.NEW_BR_NO, descp: IDTYPE.NEW_BR_NO_DESCP }
       ]
     }else {
      this.idType2List = [
        { code: IDTYPE.BR_NO, descp: IDTYPE.BR_NO_DESCP }
      ]
     }
    }
  }

  initValidators() {
    this.initEmailValidators();

    if(this.isCompany) {
      this.initCompanyValidators();
    }else {
      this.initIndividualValidators();
    }
  }

  initEmailValidators() {
    if(this.edocConsentInd) {
      this.customerEditForm.controls['email'].setValidators([Validators.required, Validators.email]);
    }
  }

  initCompanyValidators() {
    this.customerEditForm.controls['fixedLineNo'].addValidators([Validators.required]);
    if (this.edocConsentInd) {
      this.customerEditForm.controls['tinNo'].setValidators(Validators.required);
    }
  }

  initIndividualValidators() {
    this.customerEditForm.controls['maritalStatus'].setValidators(Validators.required);
    if(this.cpDetails.idType1 === IDTYPE.PASS) {
      this.customerEditForm.controls['nationality'].setValidators(Validators.required);
    }

    if(this.cpDetails.idType1 !== IDTYPE.NRIC) {
      this.customerEditForm.controls['dob'].setValidators(Validators.required);
    }
  }

  initAddressValidators(){
    // this.customerEditForm.controls['address1'].setValidators(Validators.required);
    this.customerEditForm.controls['postCode'].setValidators(Validators.required);
    this.customerEditForm.controls['postCode'].updateValueAndValidity();

  }

  onSubmit() {
    console.warn("name: ", this.customerEditForm.controls['name'].value);
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  changeSearchMode(event: any) {
    // debugger;
    if (event.value == "address") {
      this.search_mode_address = true;
    } else {
      this.search_mode_address = false;
    }
  }

  onBlurIdValue2(idValue2) {
    console.log("idValue2: ", idValue2)
    let idValue2Control = this.customerEditForm.controls['idValue2'];
    let idType2Control = this.customerEditForm.controls['idType2'];
    let idValue2Val = idValue2Control.value;
    let idType2Val = idType2Control.value;

    if (idValue2Val) {
      idValue2Control.clearValidators();
      switch (idType2Val) {
        case IDTYPE.NRIC:
          if (idValue2Val.length === 12) {
            const isValidNric = this.nricService.isNRICValid(idValue2Val);
            if (!isValidNric) {
              idValue2Control.setErrors({ 'wrongNricFormat': true });
            }
          }else {
            idValue2Control.setErrors({ 'wrongNricFormat': true });
          }
          break;
        
        case IDTYPE.NEW_BR_NO:
          if(idValue2Val.length < 12) {
            idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
          }else {
            try {
              const isValidNewBrNo = this.brnoService.isBRNOValid(idValue2Val);
              if (!isValidNewBrNo) {
                idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
              }
            }catch (err) {
              idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
            }
            
          }
          break;
      }
      idType2Control.setValidators([Validators.required]);
    }else {
      idType2Control.removeValidators([Validators.required]);
    }
    idType2Control.updateValueAndValidity();
  }

  onChangeIdType2() {
    this.customerEditForm.controls['idType2'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let idValue2Control = this.customerEditForm.controls['idValue2'];
      let idValue2Val = idValue2Control.value;
      if(value && !idValue2Val) {
        idValue2Control.setErrors({'required': true});        
      }
    });
  }

  getErrorType(errors) {
    if (errors.wrongNricFormat) {
      return 'wrongNricFormat';
    }

    if(errors.wrongNewBrnoFormat) {
      return 'wrongNewBrnoFormat';
    }

    if (errors.invalidDOB) {
      return 'invalidDOB';
    }

    if (errors.required) {
      return 'required';
    }

    return false;
  }

  onChangeDOB() {
    this.customerEditForm.controls['dob'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let dobDate = new Date(value);
      const currentDate = new Date();
      
      currentDate.setHours(0,0,0,0);

      let dobYear = dobDate.getFullYear();
      let currentYear = currentDate.getFullYear();

      if(dobYear >= currentYear) {
        this.customerEditForm.controls['dob'].setErrors({'invalidDOB': true});
      }else {
        let age = currentYear - dobYear;
        this.customerEditForm.controls['age'].setValue(age);
      }
    });
  }

  onChangeAge() {
    console.log("age: ", this.customerEditForm.controls['age'].errors);
  }

  onChangeMobileNo() {
    let mobileNo = this.customerEditForm.controls['mobileNo'].value;
    if(mobileNo) {
      if(this.isCompany) {
        this.customerEditForm.controls['fixedLineNo'].removeValidators([Validators.required]);
      }
      this.customerEditForm.controls['mobileNoCode'].setValidators([Validators.required]);
      if(mobileNo === this.mkagAgent.mobileNo) {
        this.openCPSameAgentInfoModal("MOBILE");
      }
    }else {
      this.customerEditForm.controls['fixedLineNo'].addValidators([Validators.required]);
    }
    this.customerEditForm.controls['fixedLineNo'].updateValueAndValidity();
    this.customerEditForm.controls['mobileNoCode'].updateValueAndValidity();
    
  }

  onChangeEmail() {
    let email = this.customerEditForm.controls['email'].value;
    if(email) {
      if(email === this.mkagAgent.email) {
        this.openCPSameAgentInfoModal("EMAIL");
      }
    }
    this.customerEditForm.controls['mobileNoCode'].updateValueAndValidity();
    
  }

  onChangefixedLineNo() {
    let fixedLineNo = this.customerEditForm.controls['fixedLineNo'].value;
    this.customerEditForm.controls['fixedLineNoCode'].clearValidators();
    if(fixedLineNo) {
      if(this.isCompany) {
        this.customerEditForm.controls['mobileNo'].removeValidators([Validators.required]);
      }
      this.customerEditForm.controls['fixedLineNoCode'].setValidators([Validators.required]);
    }else {
      this.customerEditForm.controls['mobileNo'].addValidators([Validators.required]);
    }
    this.customerEditForm.controls['mobileNo'].updateValueAndValidity();
    this.customerEditForm.controls['fixedLineNoCode'].updateValueAndValidity();
  }

  onChangeFaxNo() {
    let faxNo = this.customerEditForm.controls['faxNo'].value;
    this.customerEditForm.controls['faxNoCode'].clearValidators();
    this.customerEditForm.controls['faxNo'].clearValidators();
    if(faxNo) {
      this.customerEditForm.controls['faxNo'].setValidators([Validators.pattern("^\\d{7,8}$")]);
      this.customerEditForm.controls['faxNoCode'].setValidators([Validators.required]);
    }
    this.customerEditForm.controls['faxNoCode'].updateValueAndValidity();
    this.customerEditForm.controls['faxNo'].updateValueAndValidity();
    
  }

  saveEdit() {
  this.initAddressValidators(); 
    if(this.customerEditForm.valid) {
      const geoCodedInd = this.store.selectSnapshot<any>((state)=>state.SatMotorState.geoCodedInd);
      console.log("edit customer partner form: ", this.customerEditForm.getRawValue());
      this.dialogRef.close(this.customerEditForm.getRawValue());
      
    }else {
      let invalidControls = [];
      const controls = this.customerEditForm.controls;

      for(const name in controls) {
        if(controls[name].invalid) {
          invalidControls.push(name);
        }
      }
      console.warn("invalid edit form: ", invalidControls);
      this.customerEditForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }
    
  }

  openCPSameAgentInfoModal(type) {
    this.cpSameAgentInfoModal = this.dialogService.open(CpSameAgentInfoComponent, {
      showCloseIcon: false,
      data: {
        type: type
      }
    });

    this.cpSameAgentInfoModal.afterClosed().subscribe(result => {
      if(result === "N") {
        switch(type) {
          case "MOBILE":
            this.customerEditForm.controls['mobileNo'].setValue('');
          break;
          case "EMAIL":
            this.customerEditForm.controls['email'].setValue('');
          break;
        }
      }
    });
  }

  showNationality() {
    return this.cpDetails.clientType === CLIENTTYPE.INDIVIDUAL && this.cpDetails.idType1 === IDTYPE.PASS;
  }

  cancelEdit() {
    this.dialogRef.close();
  }

  onChangePostcode() {
    let postcode = this.customerEditForm.controls['postCode'].value;

    this.store.dispatch(new GET_POSTCODE_INFO(postcode)).subscribe(state => {
      let postcodeInfo = state.SatMotorState.data.postCodeInfo;
      this.customerEditForm.patchValue({
        state: postcodeInfo.state,
        city: postcodeInfo.city,
        country: "MAL"
      });
    });
  }

  populateForm(data: any) {
    
    //reset first before populate
    this.customerEditForm.patchValue({
      unitNo: '',
      address1: '',
      address2: '',
      address3: '',
      postCode: '',
      city: '',
      state: '',
      country: '',
      clientGeoLatitude : '',
      clientGeoLongitude : '',
      clientGeoPlaceId : '',
      clientGeoPlusCode : '',
      clientGeoFormattedAddress : ''
    });

    this.customerEditForm.patchValue({
      unitNo: data.unitNumber,
      address1: data.clientAddress1,
      address2: data.clientAddress2,
      address3: data.clientAddress3,
      postCode: data.clientPostcode,
      clientGeoLatitude : data.clientGeoLatitude,
      clientGeoLongitude : data.clientGeoLongitude,
      clientGeoPlaceId : data.clientGeoPlaceId,
      clientGeoPlusCode : data.clientGeoPlusCode,
      clientGeoFormattedAddress : data.clientGeoFormattedAddress
    });

    if (data.clientPostcode) {
      this.onChangePostcode();
    } else {
      this.customerEditForm.patchValue({
        city: data.clientCityDesc,
        state: data.clientStateDesc,
        country: data.clientCountryDesc,
      });
    }
  }

  get isCompany() {
    return this.cpDetails.clientType === CLIENTTYPE.COMPANY;
  }

  get isOldIC() {
    return this.cpDetails.idType1 === IDTYPE.OLD_IC;
  }

  get idValue2ErrorType() {
    let errors = this.customerEditForm.controls['idValue2'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  get dobErrorType() {
    let errors = this.customerEditForm.controls['dob'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  get isNric() {
    return this.cpDetails.idType1 === IDTYPE.NRIC;
  }

   //reset Address to null
   clearAddress(){
    this.store.dispatch(new UPDATE_GEO_INDICATOR(false));
    this.customerEditForm.controls['address1'].clearValidators();
    this.customerEditForm.controls['postCode'].clearValidators();
  
  this.customerEditForm.controls['address1'].updateValueAndValidity();
  this.customerEditForm.controls['postCode'].updateValueAndValidity();

  this.cpDetails ={
    clientAddress1:'',
    clientAddress1Tmp: '',
    clientAddress2:'',
    clientAddress3:'', 
    clientCity:'',
    clientCountry:'',
    clientGeoLatitude:'',
    clientGeoLongitude:'',
    clientGeoPlaceId:'',
    clientGeoPlusCode:'',
    clientPostcode:'',
    clientState:'',
    clientUnitNo:'',
  };

  this.customerEditForm.patchValue({
    unitNo: '',
    address1: '',
    address2: '',
    address3: '',
    postCode: '',
    city: '',
    state: '',
    country: '',
    showMapSwitcher: [true],
    clientGeoLatitude : '',
    clientGeoLongitude : '',
    clientGeoPlaceId : '',
    clientGeoPlusCode : '',
    clientGeoFormattedAddress : ''
  });
  this.child.clearAddress();
  this.store.dispatch(new UPDATE_GEO_INDICATOR(false));
}

getDefaultAddressType() {
  return this.isCompany ? 'O' : 'R';
}

}
