<div nxCol="12,12,12,9" class="grid_width_page">
  <h3 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold">Agent Additional Information</h3>

  <p nxCopytext="normal" class="nx-margin-top-2m">Issuing Cover Note for Agent Code
    <span class="nx-font-weight-bold">{{agentData.agentCode}}</span>
  </p>
  <p class="nx-margin-bottom-2m">
            <span nxCopytext="small">
                <i>Agent Code may not be changed after this page.</i>
            </span>
  </p>
  <button nxPlainButton type="button" class="std-ta-left" (click)="goToSelectAgentCode()">
    <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon>
    SELECT A DIFFERENT AGENT CODE
  </button>

  @if (isBAUser || isFRUser) {
    <div nxRow class="nx-margin-top-2m nx-margin-bottom-s">
      <div nxCol="12">
        <span nxCopytext="normal"><b>Customer Type</b></span>
        <h4 nxHeadline="subsection-xsmall">
          {{customerType}}
        </h4>
      </div>
    </div>
  }


  <div nxRow class="nx-margin-top-2m nx-margin-bottom-s">
    <div nxCol="12">
      <span nxCopytext="normal"><b>Agent Code & Name</b></span>
      <h4 nxHeadline="subsection-xsmall">
        {{agentData.agentCode}} - {{agentData.name}}
      </h4>
    </div>
  </div>

  <form autocomplete="off"  [formGroup]="additionalInfoForm" class="w-1col nx-margin-top-2m">

    @if (isBancaUser || isBAUser) {
      <nx-formfield label="Bank Type">
        <nx-dropdown formControlName="bankType">
          <ng-container *ngFor="let bank of commonLov.bankList">
            <nx-dropdown-item [value]="bank.code">{{ bank.descp }}</nx-dropdown-item>
          </ng-container>
        </nx-dropdown>

        <nx-error nxFormfieldError> Please select the Bank Type. </nx-error>
      </nx-formfield>

      <nx-formfield label="Bank Channel">
        <nx-dropdown formControlName="bankChannel" (selectionChange)="onBankChannelChange($event.value)">
          <ng-container *ngFor="let channel of bankChannels">
            <nx-dropdown-item [value]="channel.code">{{ channel.descp }}</nx-dropdown-item>
          </ng-container>
        </nx-dropdown>

        <nx-error nxFormfieldError> Please select the Bank Channel. </nx-error>
      </nx-formfield>

      <nx-formfield label="Bank Sub Channel">
        <nx-dropdown formControlName="bankSubChannel" (selectionChange)="onBankSubChannelChange($event.value)">
          <ng-container *ngFor="let subChannel of bankSubChannels">
            <nx-dropdown-item [value]="subChannel.code">{{ subChannel.descp }}</nx-dropdown-item>
          </ng-container>
        </nx-dropdown>

        <nx-error nxFormfieldError> Please select the Bank sub Channel. </nx-error>
      </nx-formfield>

      <nx-formfield label="Bank Branch">
        <nx-dropdown formControlName="bankBranch">
          <ng-container *ngFor="let branch of bankBranches">
            <nx-dropdown-item [value]="branch.code">{{ branch.descp }}</nx-dropdown-item>
          </ng-container>
        </nx-dropdown>

        <nx-error nxFormfieldError> Please select the Bank Branch </nx-error>
      </nx-formfield>
    }

    @if (isBancaUser) {
      <nx-formfield label="Licensed Sales Staff ID">
        <input autocomplete="off" formControlName="bankStaffNo" nxInput type="tel" maxlength="20" nxInput />
      </nx-formfield>

      <nx-formfield label="Licensed Sales Staff Name">
        <input autocomplete="off" appToUpperCase #bankStaffName formControlName="bankStaffName" maxlength="100" nxInput class="upper-case" />
        <span nxFormfieldHint>
                        {{ (bankStaffName.value.length > 0) ? '' : 'Max.' }} {{ 100 - (bankStaffName.value.length) }}
          {{ (bankStaffName.value.length > 0) ? 'Characters Left' : 'Characters' }}
                    </span>
      </nx-formfield>

      <nx-formfield label="Referral Sales Staff ID">
        <input autocomplete="off" formControlName="referralSalesStaffId" nxInput type="tel" maxlength="20" nxInput />
      </nx-formfield>

      <nx-formfield label="Referral Sales Staff Name">
        <input autocomplete="off" appToUpperCase #bankStaffName formControlName="referralSalesStaffName" maxlength="100" nxInput class="upper-case" />
        <span nxFormfieldHint>
                        {{ (bankStaffName.value.length > 0) ? '' : 'Max.' }} {{ 100 - (bankStaffName.value.length) }}
          {{ (bankStaffName.value.length > 0) ? 'Characters Left' : 'Characters' }}
                    </span>
      </nx-formfield>

      <nx-formfield *ngIf="additionalInfoForm.get('bankChannel').value && additionalInfoForm.get('bankSubChannel').value" label="Sales Campaign">
        <nx-dropdown formControlName="salesCampaign">
          <ng-container *ngFor="let campaign of salesCampaigns">
            <nx-dropdown-item [value]="campaign.code">{{ campaign.descp }}</nx-dropdown-item>
          </ng-container>
        </nx-dropdown>
      </nx-formfield>
      

      <nx-formfield label="Bank Client Type">
        <nx-dropdown formControlName="bankClientType">
          <ng-container *ngFor="let client of bankClientTypes">
            <nx-dropdown-item [value]="client.code">{{ client.descp }}</nx-dropdown-item>
          </ng-container>
        </nx-dropdown>

        <nx-error nxFormfieldError> Please select the Bank Client Type </nx-error>
      </nx-formfield>
    }

    @if (isSNUser) {
      <nx-formfield label="Rebate Type">
        <nx-dropdown formControlName="rebateType">
          <ng-container *ngFor="let rebate of commonLov.rebateTypeList">
            <nx-dropdown-item [value]="rebate.code">{{ rebate.descp }}</nx-dropdown-item>
          </ng-container>
        </nx-dropdown>

        <nx-error nxFormfieldError> Please select the Rebate Type </nx-error>
      </nx-formfield>
    }

    <div nxRow class="nx-margin-top-l nx-margin-bottom-3xl">
      <div nxCol="12">
        <button nxButton="primary" type="button" (click)="goNext()" [disabled]="!additionalInfoForm.valid">Next</button>
      </div>
    </div>
  </form>

</div>
