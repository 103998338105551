import {
  ChangeDetectorRef,
  Component,
  importProvidersFrom,
  ViewChild,
} from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { NxInputDirective } from "@aposin/ng-aquila/input";
import { NxDropdownComponent } from "@aposin/ng-aquila/dropdown";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { GET_AGENT_DETAIL_ONLY, GET_AGENT_LIST_AGENT, GET_BRANCH_LIST, GET_PRODUCT_LIST, RESET_AGENT_LIST, STORE_AGENT_CODE } from '../../../../store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { UserResponse } from "@interfaces/user";
import { map, Observable } from 'rxjs';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";


@Component({
  selector: 'app-agent-selection',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './agent-selection.component.html',
  styleUrl: './agent-selection.component.scss'
})
export class AgentSelectionComponent {
  
  @Select(SatMotorState.motorBranchList) branchList$: Observable<any>;
  @Select(SatMotorState.motorAgentList) agentList$: Observable<any>;
  @Select(SatMotorState.motorAgentDetail) agentDetail$;
  @Select(SatMotorState.motorActionType) actionType$;

  agentList: any[];
  userInfo: any;
  hqAdminUser: boolean;
  branchAdmin: boolean;
  isIssuance: boolean = false; 
  isEndorsement: boolean = false; 
  isEnquiry: boolean = false;
  opportunityId: boolean = false;
  isRequoteQuotation: any;
  customerType = [
    { name: 'FRANCHISE & OTHERS', code: 'FR' },
    { name: 'BANCASSURANCE', code: 'BA' },
  ];

  agentForm: FormGroup;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) {}

  ngOnInit() {
    this.isRequoteQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState.requoteQuotation);
    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
      }
    });

    this.agentList$.pipe(
      map((list) => {
        this.agentList = list;
      })
    ).subscribe();
    
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.opportunityId = this.store.selectSnapshot<any>((state) => state.SatMotorState.opportunityId);

    if (this.userInfo) {
      this.hqAdminUser = this.userInfo.hqAdmin;
      this.branchAdmin = this.userInfo.branchAdmin;

      this.agentForm = new FormGroup({
        customerType: new FormControl(''),
        branchCode: new FormControl(''),
        agent: new FormControl('', Validators.required)
      });

      if (this.hqAdminUser) {
        this.agentForm.get('branchCode').setValidators(Validators.required);
      } else {
        this.agentForm.get('branchCode').clearValidators();
      }

      this.agentForm.get('branchCode').updateValueAndValidity();

      if(this.isHqOrBranchAdmin()) {
        this.onBranchCodeChanged();
      }
    }
    
    this.branchList$.subscribe((state) => {
      if(!state) {
        if(this.hqAdminUser) {
          this.resetAgentList();
        }
      }
    });

    this.agentDetail$.subscribe((state) => {
      if(state) {
        if (state.customerType) {
          //this.agentForm.controls['customerType'].setValue(state.customerType);
        }

        if (this.isHqOrBranchAdmin() && state.branchCode) {
          //this.agentForm.controls['branchCode'].setValue(state.branchCode);
          //this.agentList = state.branchAgentList;
          //this.agentForm.controls['agent'].setValue(state.agentCode);
        } else {
          //this.agentForm.controls['agent'].setValue(state.agentCode);
        }
      }
    });

    if(this.isRequoteQuotation){
      this.agentForm.controls['agent'].disable();
      this.agentForm.patchValue({
        agent : this.isRequoteQuotation?.mkagAgent?.agentCode
      });
    }
  }

  onCustomerTypeChange(event: any) {
    this.store.dispatch(new GET_AGENT_LIST_AGENT({ userId: this.userInfo.userId, criteria: 'CC_USER' }));
  }

  isHqOrBranchAdmin() {
    return this.hqAdminUser || this.branchAdmin;
  }

  isCallCenterUser() {
    if (this.userInfo.callCentreType != undefined) {
      return true;
    } else {
      return false;
    }
  } 

  isAgentCodeEnabled() {
    if (this.isCallCenterUser()) {
      if (this.agentForm.controls?.['customerType']?.value == 'FR' || this.agentForm.controls?.['customerType']?.value == 'BA') {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  resetAgentList() {
    this.store.dispatch(new RESET_AGENT_LIST());
  }

  onBranchCodeChanged() {
    this.agentForm.get('branchCode').valueChanges.subscribe(value => {
      this.resetAgentList();
      if (this.agentForm.get('agent').value) {
        this.agentForm.get('agent').setValue('');
      }

      if (value) {
        //this.retrieveAgentList(value);
      }
    });
  }

  retrieveBranchList() {
    let userId = this.userInfo.userId;

    this.store.dispatch(new GET_BRANCH_LIST(userId)).subscribe(state => {
      if (state.SatMotorState.data.branchList) {
        console.log("branchlist: "+JSON.stringify(state.SatMotorState.data.branchList));
      }
    });
  }

  retrieveAgentList(branchCode) {
    let payload = {
      branchCode: branchCode,
      productType: 'MT',
      userId: this.userInfo.userId
    }
    this.store.dispatch(new GET_AGENT_LIST_AGENT(payload));
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  async goNext() {
    let selectedAgentCode = this.agentForm.controls['agent'].value;
    let customerType = this.agentForm.controls['customerType'].value ?? null;
    let branchCode = this.agentForm.controls['branchCode'].value ?? null;

    const data = {
      operator: this.userInfo.userId,
      agentCode: selectedAgentCode,
      productType: "MT",
      customerType: customerType,
      branchCode: branchCode,
      branchAgentList: branchCode ? this.agentList : null
    };

    this.store.dispatch(new GET_PRODUCT_LIST({lob: 'MT', agentCode: selectedAgentCode})).subscribe(() => {
      this.store.dispatch(new GET_AGENT_DETAIL_ONLY(data)).subscribe(() => {
        let agentDetail = this.getAgentDetail();

        if ((this.isCallCenterUser() || agentDetail.bancaAgent || agentDetail.sundriesAgent) && !this.opportunityId) {
          this.router.navigate(['issuance/agent-additional-info'], {
            queryParamsHandling: 'preserve',
          });
        } else {
          this.router.navigate(['issuance/declaration-issuance-info'], {
            queryParamsHandling: 'preserve',
          });
        }
      });
    });
  }

  getAgentDetail() {
    return this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.agentDetail ?? null);
  }

}  
