<div class="title-header">
  <div nxLayout="grid maxwidth">
    <h1>Private Cars Excluding Goods</h1>
  </div>
</div>

<div nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12,12,12,3" class="grid_width_page">
      <!-- 
      Note:
      Progree type:
      1. cn = cover note and quotation progress bar, 5 step
      2. endorsement = endorsement progress bar, 4 step
      3. unification = unification progress bar, 6 step
      4. rtp = road tax purpose progress bar, 3 step

      progressvalue = step index
      -->
      <app-progress-bar [progressValue]="3" [progressType]="'cn'" />
    </div>
    <div nxCol="12,12,12,9" class="grid_width_page">
      <h1
        nxHeadline
        size="s"
        class="nx-font-weight-semibold nx-margin-bottom-2m"
      >
        Summary
      </h1>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Agent Information
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Agent Code</nx-data-display-label
                  >
                  <p nxCopytext="large">BT15982</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Agent Name</nx-data-display-label
                  >
                  <p nxCopytext="large">GOLDEN HIGHWAY AUTO-CITY SDN BHD</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Issued By</nx-data-display-label
                  >
                  <p nxCopytext="large">ALPHA.UAT.25</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Issued Date</nx-data-display-label
                  >
                  <p nxCopytext="large">14/05/2024</p>
                </nx-data-display>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Issuance Information<button nxPlainButton type="button">
                EDIT
              </button>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <div nxLayout="grid nopadding">
            <!-- RoadTax Issuance Information (to be move to new component) -->
            <div nxRow>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Product Name</nx-data-display-label
                  >
                  <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Vehicle No.</nx-data-display-label
                  >
                  <p nxCopytext="large">WA8012L</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Cover Note Type</nx-data-display-label
                  >
                  <p nxCopytext="large">ROAD TAX PURPOSE</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Policy No.</nx-data-display-label
                  >
                  <p nxCopytext="large">21PKL5001832-00</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Effective Date</nx-data-display-label
                  >
                  <p nxCopytext="large">13/12/2023</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Expiry Date</nx-data-display-label
                  >
                  <p nxCopytext="large">12/12/2024</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Cover Note No.</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
            </div>

            <!-- Cover Note Issuance Information (to be move to new component) -->
            <div nxRow>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Product Name</nx-data-display-label
                  >
                  <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Vehicle No.</nx-data-display-label
                  >
                  <p nxCopytext="large">NA</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Cover Note Type</nx-data-display-label
                  >
                  <p nxCopytext="large">
                    NEW BUSINESS - NEW VEHICLE, NEW REGISTRATION
                  </p>
                </nx-data-display>
              </div>

              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Previous Policy No.</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Effective Date</nx-data-display-label
                  >
                  <p nxCopytext="large">13/12/2023</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Expiry Date</nx-data-display-label
                  >
                  <p nxCopytext="large">12/12/2024</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Quotation No.</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Quotation Creation Date</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Cover Note No.</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Document Consent</nx-data-display-label
                  >
                  <p nxCopytext="large">YES</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Invoice Consent</nx-data-display-label
                  >
                  <p nxCopytext="large">YES</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >JPJ Status</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >JPJ Message</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
            </div>

            <!-- Endorsement Issuance Information (to be move to new component) -->
            <div nxRow>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Product Name</nx-data-display-label
                  >
                  <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Vehicle No.</nx-data-display-label
                  >
                  <p nxCopytext="large">WA8012L</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Endorsement Type</nx-data-display-label
                  >
                  <p nxCopytext="large">CHANGE OF VEHICLE</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Policy No.</nx-data-display-label
                  >
                  <p nxCopytext="large">19VKL0012663-00</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Policy Effective Date</nx-data-display-label
                  >
                  <p nxCopytext="large">13/12/2023</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Policy Expiry Date</nx-data-display-label
                  >
                  <p nxCopytext="large">12/12/2024</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Endorsement Effective Date</nx-data-display-label
                  >
                  <p nxCopytext="large">13/12/2023</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Endorsement Expiry Date</nx-data-display-label
                  >
                  <p nxCopytext="large">12/12/2024</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Endorsement Ref. No.</nx-data-display-label
                  >
                  <p nxCopytext="large">UWMT240016790</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Endorsement No.</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Invoice Consent</nx-data-display-label
                  >
                  <p nxCopytext="large">YES</p>
                </nx-data-display>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel>
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Customer Partner<button nxPlainButton type="button">EDIT</button>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <!-- Individual -->
          <table nxTable cellpadding="0" class="summary_table">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Customer Type</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">INDIVIDUAL</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Name</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">WONG KAM WAI</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Nationality</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">MALAYSIA</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">880914145277</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Old IC No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">A3078</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Policy ID No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">B3030</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Passport No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">A37</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Tax Identification No. (TIN)</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">OG12345678101</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Gender</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">MALE</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Marital Status</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">SINGLE</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Date of Birth</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">14/09/1988</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Age</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">35</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">ALIM Customer</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">NO</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Mobile No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">6012-2222222</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Fixed Line No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">602-33333333</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Fax No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">602-44444444</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Email</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">KAMWAIGMAIL.COM</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Address</span></td>
              <td nxTableCell class="column_cp_content">
                <button nxPlainButton type="button" aria-label="add item">
                  <nx-icon name="location" aria-hidden="true"></nx-icon>
                </button>
                <span nxCopytext="normal"
                  >28-3, ALLIANZ MALAYSIA BERHAD, LEVEL 28, MENARA ALLIANZ
                  SENTRAL, 203, JALAN TUN SAMBANTHAN, KUALA LUMPUR SENTRAL,
                  50470 KUALA LUMPUR, WILAYAH PERSEKUTUAN, MALAYSIA</span
                >
              </td>
            </tr>
          </table>

          <!-- This is just spacing, you can remove it -->
          <div class="nx-margin-y-2m"></div>

          <!-- Company -->
          <table nxTable cellpadding="0" class="summary_table">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Customer Type</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">COMPANY</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Name</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">ALPHA SDN. BHD.</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Country of Incorporation</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">MALAYSIA</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">New Business Registration No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">201333333322</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Business Registration No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">555666-X</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Tax Identification No. (TIN)</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">C2584563202</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">SST No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">W88888888888888</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">SST Effective Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">26/07/2022</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">SST De-Registration Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">25/07/2024</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">SST Resident Status</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">RESIDENT</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">SST Country</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">MALAYSIA</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">SME Reg. No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">SME908766</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">SME Category</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">MICRO</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">SME Cert. Start Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">27/12/2021</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">SME Cert. End Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">26/12/2023</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Mobile No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">6012-2264680</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Fixed Line No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">602-33333333</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Fax No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">602-44444444</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Email</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">ALPHAGMAIL.COM</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Address</span></td>
              <td nxTableCell class="column_cp_content">
                <button nxPlainButton type="button" aria-label="add item">
                  <nx-icon name="location" aria-hidden="true"></nx-icon>
                </button>
                <span nxCopytext="normal"
                  >28-3, ALLIANZ, 203, JALAN TUN SAMBANTHAN, KUALA LUMPUR
                  SENTRAL, 50470 KUALA LUMPUR, WILAYAH PERSEKUTUAN,
                  MALAYSIA</span
                >
              </td>
            </tr>
          </table>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Referral Details
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,6">
                <form>
                  <nx-formfield label="Referral ID No.">
                    <input nxInput />
                    <nx-error nxFormfieldNote>
                      <strong>Please enter Referral ID No.</strong>
                    </nx-error>
                  </nx-formfield>
                </form>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordione_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Coverage<button nxPlainButton type="button">EDIT</button>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <h4 class="nx-margin-y-2m">Vehicle Details</h4>

          <table nxTable cellpadding="0" class="summary_table">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal"
                  >Where is the vehicle being used?</span
                >
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">WEST MALAYSIA</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Use of Vehicle</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal"
                  >PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY USE)</span
                >
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Vehicle No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">WA8012L</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Year of Manufacture</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">2007</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Make / Model</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal"
                  >31*0000012 - PERODUA MYVI 1.3 (A) SE</span
                >
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Capacity / Unit of Measurement</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">1,298 / CC</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Engine Type</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">INTERNAL COMBUSTION ENGINE</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">No. of Seats</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">5</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Engine / Motor No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">2NROP28330</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Chassis No.</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">PN153SYF103098483</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Loan Type</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">HIRE PURCHASE</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Loan Company</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">MAYBANK</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Vehicle Purchase Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">26/07/2007</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal"
                  >Make / Model to be printed in Quotation / Cover Note</span
                >
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">PERODUA MYVI 1.3 (A) SE 2205</span>
              </td>
            </tr>
          </table>

          <hr class="nx-margin-y-2m" />

          <h4 class="nx-margin-bottom-2m">Coverage Details</h4>

          <table nxTable cellpadding="0" class="summary_table">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Coverage Type</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">COMPREHENSIVE</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Vehicle Sum Insured (RM)</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">40,000.00</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell><span nxCopytext="normal">Agreed Value</span></td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">YES</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Recommended Value</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">YES</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">No Claim Discount (%)</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">25.00</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">No Claim Discount From</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal"
                  >ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD</span
                >
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">No Claim Discount Vehicle No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">WA8012L</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal"
                  >No Claim Discount Effective Date</span
                >
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">12/12/2023</span>
              </td>
            </tr>
          </table>

          <hr class="nx-margin-y-2m" />

          <h4 class="nx-margin-bottom-2m">Extra Coverage</h4>
          <span nxCopytext="normal" class="nx-font-weight-semibold"
            >These coverage(s) / service(s) are provided at no extra cost.</span
          >

          <ul nxList="small" class="completed_list">
            <li>
              <span nxCopytext="normal" class="nx-font-weight-semibold"
                >RAHMAH PACKAGE</span
              >
              <ul class="nx-margin-y-0">
                <div nxLayout="grid nopadding" class="grid_remove_padding">
                  <div nxRow>
                    <div nxCol="12,12,6,5">
                      <span nxCopytext="medium"
                        >- COMPASSIONATE FLOOD COVER</span
                      >
                    </div>
                    <div nxCol="12,12,6,4" class="extra_coverage_text">
                      <span nxCopytext="medium"
                        >SUM INSURED (RM) : 3,000.00</span
                      >
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="12,12,6,5">
                      <span nxCopytext="medium"
                        >- DEATH/PERMANENT DISABLEMENT
                      </span>
                    </div>
                    <div nxCol="12,12,6,4" class="extra_coverage_text">
                      <span nxCopytext="medium"
                        >SUM INSURED (RM) : 5,000.00</span
                      >
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="12,12,6,5">
                      <span nxCopytext="medium">- HOSPITAL INCOME</span>
                    </div>
                    <div nxCol="12,12,6,4" class="extra_coverage_text">
                      <span nxCopytext="medium"
                        >RM 50.00 PER DAY / MAX 60 DAYS</span
                      >
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li>
              <span nxCopytext="normal" class="nx-font-weight-semibold"
                >24-HOUR EMERGENCY TOWING (UP TO 150KM)</span
              >
            </li>
            <li>
              <div nxLayout="grid nopadding" class="grid_remove_padding">
                <div nxRow>
                  <div nxCol="12,12,6,5">
                    <span nxCopytext="normal" class="nx-font-weight-semibold"
                      >MOTOR ROAD WARRIOR</span
                    >
                  </div>
                  <div nxCol="12,12,6,4" class="extra_coverage_text">
                    <span nxCopytext="medium"
                      >PLAN / UNIT : 1 UNIT<br />SUM INSURED (RM) :
                      3,000.00</span
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div>
            <app-external-scrollbar
              targetSyncScroll="1"
              [targetBaseWidth]="tableHolder1"
            ></app-external-scrollbar>
            <div
              class="table-holder table_content_scroll only-this-horizon-scrollbar"
              #tableHolder1
              syncScroll="1"
            >
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow class="table_row_flex">
                    <th nxHeaderCell class="table_extra_coverage">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Extra Cover</span
                      >
                    </th>
                    <th nxHeaderCell class="table_extra_coverage"></th>
                    <th nxHeaderCell class="table_extra_coverage">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Premium (RM)</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium"
                        >WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage"></td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">20.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium"
                        >COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">60.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium"
                        >INCLUSION OF SPECIAL PERILS</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage"></td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">80.0</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium"
                        >MOTOR ENHANCED ROAD WARRIOR</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium"
                        >PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                        10,000.00</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">75.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage"></td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">75.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium"
                        >WAIVER OF BETTERMENT CONTRIBUTION</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage"></td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">230.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">KEY CARE</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium"
                        >SUM INSURED (RM) : 1,000.00</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage">
                      <span nxCopytext="medium">7.00</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-2m">
            <table nxTable zebra cellpadding="0">
              <thead>
                <tr nxTableRow class="table_row_flex">
                  <th nxHeaderCell class="table_extra_coverage">
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Extra Cover</span
                    >
                  </th>
                  <th nxHeaderCell class="table_extra_coverage"></th>
                  <th nxHeaderCell class="table_extra_coverage">
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Premium (RM)</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow class="table_row_flex">
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium"
                      >WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER</span
                    >
                  </td>
                  <td nxTableCell class="table_extra_coverage"></td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">20.00</span>
                  </td>
                </tr>
                <tr nxTableRow class="table_row_flex">
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium"
                      >COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span
                    >
                  </td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                  </td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">60.00</span>
                  </td>
                </tr>
                <tr nxTableRow class="table_row_flex">
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">INCLUSION OF SPECIAL PERILS</span>
                  </td>
                  <td nxTableCell class="table_extra_coverage"></td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">80.0</span>
                  </td>
                </tr>
                <tr nxTableRow class="table_row_flex">
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">MOTOR ENHANCED ROAD WARRIOR</span>
                  </td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium"
                      >PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                      10,000.00</span
                    >
                  </td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">75.00</span>
                  </td>
                </tr>
                <tr nxTableRow class="table_row_flex">
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                  </td>
                  <td nxTableCell class="table_extra_coverage"></td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">75.00</span>
                  </td>
                </tr>
                <tr nxTableRow class="table_row_flex">
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium"
                      >WAIVER OF BETTERMENT CONTRIBUTION</span
                    >
                  </td>
                  <td nxTableCell class="table_extra_coverage"></td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">230.00</span>
                  </td>
                </tr>
                <tr nxTableRow class="table_row_flex">
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">KEY CARE</span>
                  </td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">SUM INSURED (RM) : 1,000.00</span>
                  </td>
                  <td nxTableCell class="table_extra_coverage">
                    <span nxCopytext="medium">7.00</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </nx-swipebar> -->

          <hr class="nx-margin-y-2m" />

          <h4 class="nx-margin-bottom-2m">Named Drivers</h4>

          <div class="nx-margin-top-2m">
            <app-external-scrollbar
              targetSyncScroll="2"
              [targetBaseWidth]="tableHolder2"
            ></app-external-scrollbar>
            <div
              class="table-holder table_content_scroll only-this-horizon-scrollbar"
              #tableHolder2
              syncScroll="2"
            >
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Driver Name</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Driver ID</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Age</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">THE POLICYHOLDER</span>
                    </td>
                    <td nxTableCell>
                      <span nxCopytext="medium">880914145277</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">36</span></td>
                  </tr>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">ALL RIDER / DRIVER</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  </tr>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">ANY AUTHORISED DRIVERS</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-2m">
            <table nxTable zebra cellpadding="0">
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Driver Name</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Driver ID</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Age</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="medium">THE POLICYHOLDER</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium">880914145277</span>
                  </td>
                  <td nxTableCell><span nxCopytext="medium">36</span></td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="medium">ALL RIDER / DRIVER</span>
                  </td>
                  <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="medium">ANY AUTHORISED DRIVERS</span>
                  </td>
                  <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                </tr>
              </tbody>
            </table>
          </nx-swipebar> -->
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Historical Claim
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <table nxTable cellpadding="0" class="summary_table">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Client Claim History</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">0</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Vehicle Claim History</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">0</span>
              </td>
            </tr>
          </table>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Premium Information
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <nx-card class="card_summary">
            <table nxTable cellpadding="0" class="premium_summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal" class="font-grey"
                    >Premium Summary</span
                  >
                </td>
                <td nxTableCell class="std-ta-right">
                  <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Claim History</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">40,000.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Annual Premium</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">1,181.97</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Basic Premium</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">1,181.97</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Loading (0%)</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">102.58</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount (25.00%)</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">295.49</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Total Extra Cover Premium</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">277.50</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Gross Premium</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">1,459.47</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Rebate (0%)</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">0.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Service Tax (8%)</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">70.92</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Stamp Duty</span></td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">10.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Commission (10%)</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">118.19</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell class="nx-font-weight-bold">
                  <span nxCopytext="normal" class="nx-font-weight-bold"
                    >Total Amount</span
                  >
                </td>
                <td nxTableCell class="column_cp_content_bold std-ta-right">
                  <span nxCopytext="normal" class="nx-font-weight-bold"
                    >1,540.39</span
                  >
                </td>
              </tr>
              <div class="nx-margin-y-m"></div>

              <tr nxTableRow>
                <td nxTableCell>
                  <h4
                    nxHeadline="subsection-xsmall"
                    class="nx-font-weight-bold"
                  >
                    Total Amount (Rounded)
                  </h4>
                </td>
                <td nxTableCell class="column_cp_content_bold std-ta-right">
                  <h4
                    nxHeadline="subsection-xsmall"
                    class="nx-font-weight-bold"
                  >
                    1,540.40
                  </h4>
                </td>
              </tr>
            </table>

            <hr class="nx-margin-y-m" />

            <table nxTable cellpadding="0" class="premium_summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Commission (10%)</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">113.20</span>
                </td>
              </tr>
            </table>

            <!-- <div nxLayout="grid nopadding" class="grid_remove_padding">
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary font-grey">
              <span nxCopytext="normal">Premium Summary</span>
            </div>
            <div nxCol="6,6,7,7,8" class="premium_summary font-grey std-ta-right">
              <span nxCopytext="normal">Total (RM)</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Vehicle Claim History</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">40,000.00</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Annual Premium</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">1,181.97</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Basic Premium</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">1,181.97</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Loading (0%)</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">102.58</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">No Claim Discount (25.00%)</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">295.49</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Total Extra Cover Premium</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">277.50</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Gross Premium</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">1,459.47</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Rebate (0%)</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">0.00</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Service Tax (8%)</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">70.92</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Stamp Duty</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">10.00</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal">Commission (10%)</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">118.19</span>
            </div>
          </div>
          <div nxRow>
            <div nxCol="6,6,5,5,4" class="premium_summary">
              <span nxCopytext="normal" class="nx-font-weight-bold">Total Amount</span
                  >
                </div>
                <div
                  nxCol="6,6,7,7,8"
                  class="column_cp_content_bold premium_summary std-ta-right"
                >
                  <span nxCopytext="normal" class="nx-font-weight-bold"
                    >1,540.39</span
                  >
                </div>
              </div>
              <div nxRow class="nx-margin-top-m">
                <div nxCol="6,6,5,5,4" class="premium_summary">
                  <h4
                    nxHeadline="subsection-xsmall"
                    class="nx-font-weight-bold"
                  >
                    Total Amount (Rounded)
                  </h4>
                </div>
                <div
                  nxCol="6,6,7,7,8"
                  class="column_cp_content_bold premium_summary std-ta-right"
                >
                  <h4
                    nxHeadline="subsection-xsmall"
                    class="nx-font-weight-bold"
                  >
                    1,540.40
                  </h4>
                </div>
              </div>
            </div>
            <hr class="nx-margin-y-m" />
            <div nxLayout="grid nopadding" class="grid_remove_padding">
              <div nxRow>
                <div nxCol="6,6,5,5,4" class="premium_summary">
                  <span nxCopytext="normal">Commission (10%)</span>
            </div>
            <div nxCol="6,6,7,7,8" class="column_cp_content premium_summary std-ta-right">
              <span nxCopytext="normal">113.20</span>
            </div>
          </div>
        </div> -->
          </nx-card>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Refer Case (if any)
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding" class="grid_remove_padding">
            <div nxRow>
              <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                <nx-checkbox [disabled]="true"
                  >Refer Waive Load Ind.</nx-checkbox
                >
              </div>
              <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                <nx-checkbox [disabled]="true"
                  >Refer Waive Excess Ind.</nx-checkbox
                >
              </div>
              <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                <nx-checkbox>Refer for Underwriting</nx-checkbox>
              </div>
            </div>
          </div>

          <nx-formfield label="Referral Remarks">
            <textarea
              required
              nxInput
              type="text"
              #inputToCount
              maxlength="200"
              cdkTextareaAutosize
            ></textarea>
            <span nxFormfieldHint>Max. 200 Characters</span>
          </nx-formfield>
          <nx-error nxFormfieldError class="nx-margin-bottom-2m">
            <strong>Please enter referral remarks</strong>
          </nx-error>
        </nx-expansion-panel>
      </nx-accordion>

      <!-- OCC Section (to be move to new component) -->

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Payment
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Receipt No.</nx-data-display-label
                  >
                  <p nxCopytext="large">OR22AS000002</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Transaction Date</nx-data-display-label
                  >
                  <p nxCopytext="large">13/12/2023</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >e-Cover Note No.</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Vehicle No.</nx-data-display-label
                  >
                  <p nxCopytext="large">N/A</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Account Code</nx-data-display-label
                  >
                  <p nxCopytext="large">KL12272</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Insured Name</nx-data-display-label
                  >
                  <p nxCopytext="large">WONG KAM WAI</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Total Premium Due (RM)</nx-data-display-label
                  >
                  <p nxCopytext="large">1,540.40</p>
                </nx-data-display>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Road Tax Payable
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,6">
                <nx-formfield label="Road Tax (RM)">
                  <input nxInput />
                  <nx-error nxFormfieldNote>
                    <strong>Please enter Road Tax</strong>
                  </nx-error>
                </nx-formfield>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12,12,6">
                <nx-formfield label="MyEG Fees (RM)">
                  <nx-dropdown>
                    <nx-dropdown-item value="--"></nx-dropdown-item>
                    <nx-dropdown-item value="3.00"></nx-dropdown-item>
                    <nx-dropdown-item value="11.00"></nx-dropdown-item>
                  </nx-dropdown>
                  <nx-error nxFormfieldNote>
                    <strong>Please select MyEG Fees</strong>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6" class="nx-margin-bottom-2m">
                <p nxCopytext="large" class="nx-margin-bottom-1xs">
                  Courier Fees (RM)
                </p>
                <nx-checkbox>6.00</nx-checkbox>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12,12,6" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Merchant Charges (RM)</nx-data-display-label
                  >
                  <p nxCopytext="large">0.00</p>
                </nx-data-display>
              </div>
              <div nxCol="12,12,6" class="nx-margin-bottom-2m">
                <nx-data-display size="small">
                  <nx-data-display-label class="font-grey"
                    >Road Tax Payable (RM)</nx-data-display-label
                  >
                  <p nxCopytext="large">0.00</p>
                </nx-data-display>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Upsell Product
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,6">
                <nx-formfield label="Upsell Product">
                  <nx-dropdown>
                    <nx-dropdown-item value="ROAD WARRIOR"></nx-dropdown-item>
                    <nx-dropdown-item
                      value="ENHANCED ROAD WARRIOR"
                    ></nx-dropdown-item>
                    <nx-dropdown-item value="BIKE WARRIOR"></nx-dropdown-item>
                    <nx-dropdown-item
                      value="ALLIANZ SHIELD PLUS"
                    ></nx-dropdown-item>
                    <nx-dropdown-item
                      value="SMART HOME COVER"
                    ></nx-dropdown-item>
                    <nx-dropdown-item
                      value="ALLIANZ TRAVEL CARE"
                    ></nx-dropdown-item>
                    <nx-dropdown-item value="OTHERS"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>

              <div nxCol="12,12,6">
                <nx-formfield label="Amount (RM)">
                  <input nxInput />
                </nx-formfield>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Payment Information
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <table nxTable cellpadding="0" class="payment_summary_table">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal" class="font-grey"
                  >Payment Summary</span
                >
              </td>
              <td nxTableCell class="std-ta-right">
                <span nxCopytext="normal" class="font-grey">Total (RM)</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Total Amount Payable</span>
              </td>
              <td nxTableCell class="column_cp_content std-ta-right">
                <span nxCopytext="normal">1,540.40</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Total Amount Payable (Rounded)</span>
              </td>
              <td nxTableCell class="column_cp_content std-ta-right">
                <span nxCopytext="normal">1,540.40</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Total Amount Paid by Customer</span>
              </td>
              <td nxTableCell class="column_cp_content std-ta-right">
                <span nxCopytext="normal">0.00</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Balance Payable</span>
              </td>
              <td nxTableCell class="column_cp_content std-ta-right">
                <span nxCopytext="normal">1,540.40</span>
              </td>
            </tr>
          </table>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Payment Details
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <button
            (click)="openPopupOCC('occ')"
            nxPlainButton
            type="button"
            class="nx-margin-right-m nx-margin-top-m"
          >
            <nx-icon
              name="plus"
              nxIconPositionStart
              aria-hidden="true"
            ></nx-icon
            >ADD PAYMENT MODE
          </button>
          <div class="nx-margin-top-m">
            <app-external-scrollbar
              targetSyncScroll="3"
              [targetBaseWidth]="tableHolder3"
            ></app-external-scrollbar>
            <div
              class="table-holder table_content_scroll only-this-horizon-scrollbar"
              #tableHolder3
              syncScroll="3"
            >
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Payment Mode</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Reference(s)</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Payment Made To</span
                      >
                    </th>
                    <th nxHeaderCell class="std-ta-right">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Amount Paid (RM)</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span
                        nxCopytext="medium"
                        class="nx-font-weight-semibold"
                      ></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">Cash (Over the Counter)</span>
                    </td>
                    <td nxTableCell>
                      <span nxCopytext="medium"
                        >Pay in Slip/Red No. : 333333333</span
                      >
                    </td>
                    <td nxTableCell>
                      <span nxCopytext="medium"
                        >Call Centre Collection Account (8000818281)</span
                      >
                    </td>
                    <td nxTableCell class="std-ta-right">
                      <span nxCopytext="medium">1,843.40</span>
                    </td>
                    <td nxTableCell>
                      <div class="horizontal-checkboxes">
                        <!-- <button class="nx-margin-right-xs" nxPlainButton type="button" aria-label="add item" (click)="openPopupOCC('occ')"> -->
                        <button
                          class="nx-margin-right-xs"
                          nxPlainButton
                          type="button"
                          aria-label="add item"
                        >
                          <nx-icon name="edit" aria-hidden="true"></nx-icon>
                        </button>
                        <button
                          nxPlainButton
                          type="button"
                          aria-label="add item"
                        >
                          <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-m">
            <table nxTable zebra cellpadding="0">
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Payment Mode</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Reference(s)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Payment Made To</span
                    >
                  </th>
                  <th nxHeaderCell class="std-ta-right">
                    <span nxCopytext="medium" class="nx-font-weight-semibold"
                      >Amount Paid (RM)</span
                    >
                  </th>
                  <th nxHeaderCell>
                    <span
                      nxCopytext="medium"
                      class="nx-font-weight-semibold"
                    ></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="medium">Cash (Over the Counter)</span>
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium"
                      >Pay in Slip/Red No. : 333333333</span
                    >
                  </td>
                  <td nxTableCell>
                    <span nxCopytext="medium"
                      >Call Centre Collection Account (8000818281)</span
                    >
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="medium">1,843.40</span>
                  </td>
                  <td nxTableCell>
                    <div class="horizontal-checkboxes">
                     
                      <button
                        class="nx-margin-right-xs"
                        nxPlainButton
                        type="button"
                        aria-label="add item"
                      >
                        <nx-icon name="edit" aria-hidden="true"></nx-icon>
                      </button>
                      <button nxPlainButton type="button" aria-label="add item">
                        <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </nx-swipebar> -->
        </nx-expansion-panel>
      </nx-accordion>

      <!-- Endorsement Checklist (to be move to new component) -->
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Checklist
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>

          <div nxLayout="grid nopadding" class="grid_remove_padding">
            <!-- Amendment of Period, Extra Benefits, NCD Allowed, NCD Amendment, NCD Withdraw, Reinstatement of Extra Coverage, Revision of Sum Insured -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">1</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Insured or Agent Authorised letter / form / email (with
                  contact number).</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- NCD Allowed, NCD Amendment -->
            <div nxRow>
              <div nxCol="12">
                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                  <i
                    >Note: Insured IC copy/E-payment form/copy of bank
                    book-statement required (if refund involved).</i
                  >
                </p>
              </div>
            </div>

            <!-- Amendment of Period -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">2</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Copy of VOC / Registration card (for brand new only).</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Cancellation by Insured, Hire Purchase/Employers' Loan/Leasing, Named Driver -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">1</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Insured Authorised letter/form/email (with contact
                  number).</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12">
                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                  <i>Note: Warranted no claim as at todate.</i>
                </p>
              </div>
            </div>

            <!-- Change of Bancassurance Details -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">1</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Internal email communication for changes.</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">1</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Insured or Agent Authorised letter/form/email (with contact
                  number) OR</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">2</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal">Copy of VOC/Registration card.</span>
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Extra Benefits, Named Driver, Revision of Sum Insured -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">2</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal">Vehicle inspection done.</span>
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Extra Benefits, Named Driver -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">3</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Warranted no claim as at todate.</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12">
                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                  <i
                    >Note: Inclusion of extra benefits will only be allowed
                    warranted no claim as at the date of endt.</i
                  >
                </p>
              </div>
            </div>

            <!-- Named Driver -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">4</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal">Copy of I/C / passport.</span>
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- NCD Withdraw -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">2</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal">Claim/damage to the vehicle.</span>
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12">
                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                  <i
                    >Note: Insured IC copy/Epayment form/copy of bank
                    book-statement required (if refund involved).</i
                  >
                </p>
              </div>
            </div>

            <!-- Reinstatement of Extra Coverage -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">2</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Inspection done (required only if exceeded 14 days).</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Revision of Sum Insured -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">3</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Requested by Bank/Finance owner.</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Revision of Sum Insured -->
            <div nxRow class="nx-margin-bottom-2m">
              <div nxCol="2,2,1" class="checklist">
                <span nxCopytext="normal">4</span>
              </div>
              <div nxCol="10,10,11" class="checklist">
                <span nxCopytext="normal"
                  >Purchase invoice - for new cases & vehicles from
                  Langkawi/Labuan only.</span
                >
                <div
                  nxLayout="grid nopadding"
                  class="grid_remove_padding nx-margin-top-s"
                >
                  <div nxRow>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">Yes</nx-radio>
                    </div>
                    <div nxCol="4,4,3,2" class="checklist_radio">
                      <nx-radio name="yesno">No</nx-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12">
                <p nxCopytext="medium" class="nx-margin-bottom-2m">
                  <i>Note: Warranted no claim as at todate.</i>
                </p>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12">
                <nx-formfield label="Remarks">
                  <textarea
                    nxInput
                    type="text"
                    #inputToCount
                    maxlength="200"
                    cdkTextareaAutosize
                  ></textarea>
                  <span nxFormfieldHint>Max. 200 Characters</span>
                </nx-formfield>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <!-- Renewal Assistance (to be move to new component) -->

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Renewal Assistance
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <nx-checkbox class="nx-margin-bottom-2m nx-margin-top-xs"
            >Renewal Assistance</nx-checkbox
          >

          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,6">
                <nx-formfield label="Payment Mode">
                  <nx-dropdown>
                    <nx-dropdown-item value="MASTER"></nx-dropdown-item>
                    <nx-dropdown-item value="VISA"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>

              <div nxCol="12,12,6">
                <nx-formfield label="Credit Card No.">
                  <input nxInput value="5555555555554444" />
                  <span nxFormfieldHint class="std-ta-left"
                    >eg. 5500000000000004</span
                  >
                </nx-formfield>
              </div>
            </div>
          </div>

          <p nxCopytext="normal" class="nx-margin-bottom-2m">
            Credit Card Expiry Date
          </p>

          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,6">
                <div nxLayout="grid nopadding" class="grid_remove_padding">
                  <div nxRow>
                    <div nxCol="5" class="renewal_monthyear">
                      <nx-formfield label="Month">
                        <nx-dropdown>
                          <nx-dropdown-item value="01"></nx-dropdown-item>
                          <nx-dropdown-item value="02"></nx-dropdown-item>
                          <nx-dropdown-item value="03"></nx-dropdown-item>
                          <nx-dropdown-item value="04"></nx-dropdown-item>
                          <nx-dropdown-item value="05"></nx-dropdown-item>
                          <nx-dropdown-item value="06"></nx-dropdown-item>
                          <nx-dropdown-item value="07"></nx-dropdown-item>
                          <nx-dropdown-item value="08"></nx-dropdown-item>
                          <nx-dropdown-item value="09"></nx-dropdown-item>
                          <nx-dropdown-item value="10"></nx-dropdown-item>
                          <nx-dropdown-item value="11"></nx-dropdown-item>
                          <nx-dropdown-item value="12"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                    </div>

                    <div nxCol="2" class="renewal_monthyear">
                      <p nxCopytext="large">/</p>
                    </div>

                    <div nxCol="5" class="renewal_monthyear">
                      <nx-formfield label="Years">
                        <nx-dropdown>
                          <nx-dropdown-item value="2024"></nx-dropdown-item>
                          <nx-dropdown-item value="2025"></nx-dropdown-item>
                          <nx-dropdown-item value="2026"></nx-dropdown-item>
                          <nx-dropdown-item value="2027"></nx-dropdown-item>
                          <nx-dropdown-item value="2028"></nx-dropdown-item>
                          <nx-dropdown-item value="2029"></nx-dropdown-item>
                          <nx-dropdown-item value="2030"></nx-dropdown-item>
                          <nx-dropdown-item value="2031"></nx-dropdown-item>
                          <nx-dropdown-item value="2032"></nx-dropdown-item>
                          <nx-dropdown-item value="2033"></nx-dropdown-item>
                          <nx-dropdown-item value="2034"></nx-dropdown-item>
                          <nx-dropdown-item value="2035"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                    </div>
                  </div>
                </div>
              </div>

              <div nxCol="12,12,6">
                <nx-formfield label="Name on Card">
                  <input nxInput />
                </nx-formfield>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12,12,6">
                <nx-formfield label="Renewal Assistance Start Date">
                  <input nxDatefield nxInput [datepicker]="myDatepicker2" />
                  <span nxFormfieldHint>DD/MM/YYYY</span>

                  <nx-datepicker-toggle
                    [for]="myDatepicker2"
                    nxFormfieldSuffix
                  ></nx-datepicker-toggle>
                  <nx-datepicker #myDatepicker2></nx-datepicker>
                </nx-formfield>
              </div>
            </div>
          </div>

          <nx-checkbox>Term of Use</nx-checkbox>
        </nx-expansion-panel>
      </nx-accordion>

      <!-- Endorsement Narration (to be move to new component) -->
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
              Endorsement Narration
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <!-- CHANGE OF VEHICLE ENGINE NO./CUBIC CAPACITY -->
          <u nxCopytext="normal" class="nx-font-weight-semibold"
            >CHANGE OF VEHICLE ENGINE NO./CUBIC CAPACITY</u
          >

          <p nxCopytext="normal" class="nx-margin-y-m">
            IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
            <span class="nx-font-weight-bold">13/12/2023</span> THE ENGINE
            NUMBER AND/OR CUBIC CAPACITY IS/ARE AMENDED TO READ AS FOLLOWS AND
            NOT AS OTHERWISE STATED.
          </p>

          <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-bold">
            ENGINE / MOTOR NO. : 2NROP28330<br />CUBIC CAPACITY : 1,298 CC
          </p>

          <p nxCopytext="normal" class="nx-margin-y-m">
            IN CONSIDERATION OF THE ABOVE, AN ADDITIONAL PREMIUM OF
            <span class="nx-font-weight-bold">RM0.00</span> IS HEREBY CHARGED TO
            THE INSURED.
          </p>

          <p nxCopytext="normal">
            ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
          </p>

          <!-- This is just spacing, you can remove it -->
          <div class="nx-margin-y-2m"></div>

          <!-- CHANGE OF MAKE & MODEL -->
          <u nxCopytext="normal" class="nx-font-weight-semibold"
            >CHANGE OF MAKE & MODEL</u
          >

          <p nxCopytext="normal" class="nx-margin-y-m">
            IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
            <span class="nx-font-weight-bold">13/12/2023</span> THE MAKE & MODEL
            IS AMENDED TO READ AS FOLLOWS AND NOT AS OTHERWISE STATED.
          </p>

          <p nxCopytext="normal">MAKE & MODEL:</p>
          <span nxCopytext="normal">
            <ol nxList="small" class="upload_content nx-margin-bottom-2m">
              <li>
                <span class="nx-font-weight-bold">PERODUA MYVI 1.3 (A) SE</span>
              </li>
            </ol>
          </span>

          <p nxCopytext="normal">
            ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
          </p>

          <!-- This is just spacing, you can remove it -->
          <div class="nx-margin-y-2m"></div>

          <!-- INCLUSION OF NAMED DRIVER(S) -->
          <u nxCopytext="normal" class="nx-font-weight-semibold"
            >INCLUSION OF NAMED DRIVER(S)</u
          >

          <p nxCopytext="normal" class="nx-margin-y-m">
            IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
            <span class="nx-font-weight-bold">13/12/2023</span> THE FOLLOWING
            NAMED DRIVER(S) IS/ARE INCLUDED INTO THE POLICY.
          </p>

          <p nxCopytext="normal" class="nx-font-weight-bold">
            NO NAMED DRIVER(S) - DRIVER ID
          </p>
          <span nxCopytext="normal">
            <ol
              nxList="small"
              class="upload_content_bold nx-margin-bottom-2m nx-font-weight-bold"
            >
              <li>
                <span class="nx-font-weight-bold"
                  >WONG KAM WAI - 880914145277</span
                >
              </li>
            </ol>
          </span>

          <p nxCopytext="normal">
            ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
          </p>
        </nx-expansion-panel>
      </nx-accordion>

      <p nxCopytext="medium" class="nx-margin-top-2m">
        <i
          >* You will not be able to save as quotation, please proceed to
          generate Endorsement if details has been finalised.</i
        >
      </p>

      <div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">
        <a routerLink="../coverage"
          ><button nxButton="secondary" type="button">BACK</button></a
        >
        <a
          ><button
            nxButton="primary"
            type="button"
            (click)="openPopup('sanction')"
          >
            Generate e-Quotation 1
          </button></a
        >
        <a
          ><button
            nxButton="primary"
            type="button"
            (click)="openPopup('blacklist')"
          >
            Generate e-Quotation 2
          </button></a
        >
        <a
          ><button nxButton="primary" type="button" (click)="openPopup('doc')">
            SEND TO JPJ
          </button></a
        >
        <a
          ><button nxButton="primary" type="button" (click)="openPopup('cn')">
            SEND TO JPJ
          </button></a
        >
        <a
          ><button
            nxButton="primary"
            type="button"
            (click)="openPopup('cnnull')"
          >
            SEND TO JPJ
          </button></a
        >
        <a
          ><button nxButton="primary" type="button" (click)="openPopup('cn0')">
            SEND TO JPJ
          </button></a
        >
        <a
          ><button
            nxButton="primary"
            type="button"
            (click)="openPopup('sanction')"
          >
            SEND TO JPJ
          </button></a
        >
        <a
          ><button
            nxButton="primary"
            type="button"
            (click)="openPopup('blacklist')"
          >
            SEND TO JPJ
          </button></a
        >
        <button nxButton="primary" type="button">UPLOAD DOCUMENT</button>
      </div>
    </div>
  </div>
</div>

<!-- Endorsement Premium Summary (to be move to new component) -->
