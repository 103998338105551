<form [formGroup]="customerInfoForm">
    <div nxRow>
        <div nxCol="12">
            <span nxCopytext="normal">SME Registered?</span><br />
            <nx-radio-group formControlName="smeRegisteredInd">
                <div class="radio-group nx-margin-top-xs">
                    <nx-radio name="group1" value="Y">Yes</nx-radio>
                    <nx-radio name="group1" class="nx-margin-left-2m" value="N">No</nx-radio>
                </div>
            </nx-radio-group>
        </div>
    </div>

    @if (customerInfoForm.value.smeRegisteredInd === 'Y') {
    <div nxRow class="nx-margin-top-2m">
        <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="SME Reg. No.">
                <input nxInput formControlName="smeRegNo" maxlength="12" appToUpperCase/>
                <nx-error nxFormfieldError>
                    @if (customerInfoForm.get('smeRegNo').errors?.['required']) {
                        <strong> Please enter SME Reg No. </strong>
                    }

                    @if (customerInfoForm.get('smeRegNo').errors?.['pattern']) {
                        <strong>SME Reg. No. is Invalid. </strong>
                    }
                </nx-error>
            </nx-formfield>
        </div>
        <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="SME Category">
                <nx-dropdown [valueFormatter]="toText" formControlName="smeCategory">
                    @for(smeCategory of smeCategoryList; track smeCategory) {
                        <nx-dropdown-item [value]="smeCategory.code">{{ smeCategory.descp }}</nx-dropdown-item>
                    }
                </nx-dropdown>
                
                <nx-error nxFormfieldError>
                    <strong>Plese select SME Category</strong>
                </nx-error>
            </nx-formfield>
        </div>
    </div>
    <div nxRow>
        <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="SME Cert. Start Date">
                <input nxDatefield nxInput [datepicker]="myDatepicker3" formControlName="smeStartDate" />
                <span nxFormfieldHint>MM/DD/YYYY</span>
                <nx-datepicker-toggle [for]="myDatepicker3" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker3></nx-datepicker>

                <nx-error nxFormfieldError>
                    @if (customerInfoForm.get('smeStartDate').errors?.['required']) {
                        <strong>Please enter SME Start Date.</strong>
                    }

                    @if (customerInfoForm.get('smeStartDate').errors?.['invalidSMEStartDate']) {
                        <strong>SME Start Date is Invalid.</strong>
                    }
                    
                </nx-error>
            </nx-formfield>
        </div>
        <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="SME Cert. End Date">
                <input nxDatefield nxInput [datepicker]="myDatepicker4" formControlName="smeEndDate" />
                <span nxFormfieldHint>MM/DD/YYYY</span>
                <nx-datepicker-toggle [for]="myDatepicker4" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker4></nx-datepicker>

                <nx-error nxFormfieldError>
                    @if (customerInfoForm.get('smeEndDate').errors?.['required']) {
                        <strong>Please enter SME End Date.</strong>
                    }
                    
                    @if (customerInfoForm.get('smeEndDate').errors?.['smeStartDateEmpty']) {
                        <strong>Please enter SME Start Date.</strong>
                    }

                    @if (customerInfoForm.get('smeEndDate').errors?.['smeSDLaterThanSmeED']) {
                        <strong>SME End Date is Invalid.</strong>
                    }

                    @if (customerInfoForm.get('smeEndDate').errors?.['smeEDMoreThanTwoYear']) {
                        <strong>SME End Date is more than 2 years.</strong>
                    }
                </nx-error>
            </nx-formfield>
        </div>
    </div>
    }
</form>