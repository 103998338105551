<div>
  <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-m">Make / Model</h3>
  <div nxModalContent>
    <div class="left-content">
      <div>
        <div nxLayout="grid nopadding">
          <div [formGroup]="searchModelForm">
            <div nxRow>
              <div nxCol="12">
                <nx-formfield>
                  <input #searchInput nxInput formControlName="searchBy" placeholder="{{ 'COVERAGE.FIELD.MAKE_MODEL_KEYWORD' | translate }}" appToUpperCase 
                        (keydown.enter)="onSearchClicked()"
                        [nxAutocomplete]="makeModel" nxAutocompleteDebounce="0" />
                  <span nxFormfieldPrefix>
                    <nx-icon name="search" size="s"></nx-icon>
                  </span>
                  <span nxFormfieldSuffix aria-hidden="true" (click)="clearSearch()">
                    <nx-icon name="close" size="s" style="color: #0082C2;"></nx-icon>
                  </span>
                  @if (searchModelForm.get('searchBy').errors) {
                  <nx-error nxFormfieldError>
                    <strong>{{ 'COMMON.FIELD.REQUIRED' | translate: { labelName: 'COVERAGE.FIELD.MAKE_MODEL' | translate } }}</strong>
                  </nx-error>
                  }
                </nx-formfield>
                <nx-autocomplete #makeModel="nxAutocomplete" (optionSelected)="onMakeModelSelected($event)">
                  <nx-autocomplete-option *ngFor="let item of searchMakeModelAutoList" [value]="item" >
                    {{ item.piamMakeModelDesc | uppercase }} {{ item.variantFullDesc | uppercase }}
                  </nx-autocomplete-option>
                </nx-autocomplete>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12" class="std-ta-right button_dashboard">
                <button nxButton="secondary" (click)="reset()">{{ 'GLOBAL.ACTION.RESET' | translate }}</button>
                <button nxButton (click)="onSearchClicked()">{{ 'GLOBAL.ACTION.SEARCH' | translate }}</button>
              </div>
            </div>
          </div>
          @if (searchMakeModelList.length) {
            <div nxRow>
              <div nxCol="12" class="nx-margin-top-2m">
                <div class="scroll-container table_popup">
                  <div>
                    <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder"></app-external-scrollbar>
                    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder syncScroll="1">
                      <!-- table here -->
                      <table nxTable zebra class="nx-table--scrollable">
                        <thead>
                          <tr nxTableRow>
                            <th nxHeaderCell>Make</th>
                            <th nxHeaderCell>Model</th>
                            <th nxHeaderCell class="variant">Variant</th>
                            <th nxHeaderCell>
                              Year of <br />
                              Manufacture
                            </th>
                            <th nxHeaderCell>Engine Type</th>
                            <th nxHeaderCell>Capacity</th>
                            <th nxHeaderCell>Seat</th>
                            <th nxHeaderCell class="std-ta-right">
                              <span class="text_no_wrap">Sum Insured <br />Range (RM)</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr nxTableRow *ngFor="let item of paginatedResults; let i = index" (select)="select(item)"
                            [selectable]="true">
                            <td nxTableCell>{{ item.piamMakeCodeDesc }}</td>
                            <td nxTableCell>{{ item.piamModelCodeDesc }}</td>
                            <td nxTableCell>
                              <div class="variant">{{ item.variantFullDesc }}</div>
                            </td>
                            <td nxTableCell>{{ item.yearMnftr }}</td>
                            <td nxTableCell>{{ item.engineType }}</td>
                            <td nxTableCell>{{ item.capacity | number:'1.0':'en-US' }}</td>
                            <td nxTableCell>{{ item.seat }}</td>
                            <td nxTableCell class="std-ta-right">
                              <span class="text_no_wrap">
                                @if (data.region === 'E') { {{ item.avEmSiMin | number:'1.0':'en-US' }} - {{ item.avEmSiMax | number:'1.0':'en-US' }} }
                                @if (data.region === 'W') { {{ item.avWmSiMin | number:'1.0':'en-US' }} - {{ item.avWmSiMax | number:'1.0':'en-US' }} }
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <form [formGroup]="pageFilterForm" class="navigation">
        <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
          @if (showPagination && getResultLength() > 5) {
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown formControlName="perPage" [panelGrow]="true" [panelMaxWidth]="500" [value]="perPage">
                    <nx-dropdown-item *ngFor="let item of itemsPerPageSelections" [value]="item"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"></div>
            @if (getResultLength() > 5) {
              <div nxCol="12,12,6">
                <nx-pagination [count]="getResultLength()" [page]="curpage" [perPage]="perPage" type="advanced"
                  class="pagination_position" (goPrev)="prevPage()" (goNext)="nextPage()"
                  (goPage)="goToPage($event)"></nx-pagination>
              </div>
            }
          </div>
          }
        </div>
      </form>
    </div>
  </div>
  <div class="makemodel_button nx-margin-top-m">
    <button nxButton="secondary" (click)="removeValidation()" nxModalClose type="button">Cancel</button>
  </div>
</div>