<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>Report</h1>
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12" class="grid_width_page">
      <form>
        <div nxLayout="grid nopadding">
          <div nxRow>
            <div nxCol="12">
              <nx-formfield label="Branch">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item
                    *ngFor="let option of branch"
                    [value]="option"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <nx-formfield label="Select Agent Code or Name">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item
                    *ngFor="let option of agent_code"
                    [value]="option"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <nx-formfield label="Select Product">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item
                    *ngFor="let option of product_list"
                    [value]="option"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <nx-formfield label="Select Report">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item
                    *ngFor="let option of report_list"
                    [value]="option"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,4,4">
              <nx-formfield label="Search By">
                <nx-dropdown [valueFormatter]="toText" [panelGrow]="true" [panelMaxWidth]="2000">
                  <nx-dropdown-item
                    *ngFor="let option of search_by_list"
                    [value]="option"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,4,4">
              <nx-formfield label="Enter Value">
                <input nxInput type="text" />
              </nx-formfield>
            </div>
            <div nxCol="12,12,4,4">
              <nx-formfield label="Renewal Assistance Indicator">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item [value]="'ALL'"></nx-dropdown-item>
                  <nx-dropdown-item [value]="'YES'"></nx-dropdown-item>
                  <nx-dropdown-item [value]="'NO'"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3,3,3,3">
              <nx-formfield label="Issued Date From">
                <input nxDatefield nxInput [datepicker]="myDatepicker" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3,3,3,3">
              <nx-formfield label="Issued Date To">
                <input nxDatefield nxInput [datepicker]="myDatepicker2" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker2"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker2></nx-datepicker>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <div class="button_area nx-margin-bottom-4m">
                <button nxButton="secondary" type="button">RESET</button>
                <button nxButton="primary" type="button" [disabled]="true">DOWNLOAD</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
