import { Component, ElementRef, HostListener, Renderer2 } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { Router } from "@angular/router";


interface MenuChild {
  label: string;
  path: string;
}

interface MenuItem {
  label: string;
  expanded?: boolean;
  children?: MenuChild[];
}

@Component({
  selector: "app-header",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
})
export class HeaderComponent {
  currentIndex = 0;
  closeOnDocClick = true;
  popoverManualOpenFlag = false;

  closeOnClickOutside() {
      this.closeOnDocClick = !this.closeOnDocClick;
  }

  menuData: MenuItem[] = [
    {
      label: "",
      expanded: false,
      children: [
        {
          label: "",
          path: "",
        },
      ],
    },
  ];

  onClick(item: any) {
    item.expanded = !item.expanded;
  }

  menuData1: MenuItem[] = [
    {
      label: "Commercial C Permit & A Permit",
      expanded: false,
      children: [
        {
          label: "East",
          path: "https://www.autoclaims.com.my/agv4/index2.php",
        },
        {
          label: "West",
          path: "https://www.autoclaims.com.my/agv3/index2.php",
        },
      ],
    },
    {
      label: "Motorcycle",
      expanded: false,
      children: [
        {
          label: "East",
          path: "https://www.autoclaims.com.my/agv6/index2.php",
        },
        {
          label: "West",
          path: "https://www.autoclaims.com.my/agv5/index2.php",
        },
      ],
    },
    {
      label: "Private Car",
      expanded: false,
      children: [
        {
          label: "East",
          path: "https://www.autoclaims.com.my/agv2/index2.php",
        },
        {
          label: "West",
          path: "https://www.autoclaims.com.my/agv1/index2.php",
        },
      ],
    },
  ];

  showmenu = true;
  toggleMenu() {
    this.showmenu = !this.showmenu;
  }

  screenWidth: number;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
  ) {
    this.screenWidth = window.innerWidth;
    // console.log(this.screenWidth)
    if (this.screenWidth < 993) {
      this.showmenu = false;
    } else {
      this.showmenu = true;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.screenWidth = window.innerWidth;
    // console.log(this.screenWidth)
    if (this.screenWidth < 993) {
      this.showmenu = false;
    } else {
      this.showmenu = true;
    }
  }

  nav(url: any, item: any) {
    this.router.navigate([url]);
    item.expanded = !item.expanded;
    this.checkScreen();
  }

  nav2(url: any, submenu: any, menu: any) {
    this.checkScreen();
    submenu.expanded = !submenu.expanded;
    menu.expanded = !menu.expanded;
    window.open(url);
  }

  checkScreen() {
    var screenWidth = window.innerWidth;
    if (screenWidth < 993) {
      this.showmenu = false;
    }
  }
}
