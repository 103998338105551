import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { SecurityContext, Injectable, Optional, Inject, Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { map, finalize, share, take } from 'rxjs/operators';
import * as i1$1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import { of } from 'rxjs';
import * as i1 from '@angular/common/http';
import * as i2 from '@angular/platform-browser';
class NxDefaultIcons {}
const DEFAULT_ICONS = {
  'chevron-down': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z" fill="currentColor" /> </svg>',
  'chevron-right': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z" fill="currentColor" /> </svg>',
  'chevron-left': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z" fill="currentColor" /> </svg>',
  'chevron-up': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M17.6569 16.2427L19.0711 14.8285L12.0001 7.75739L4.92896 14.8285L6.34317 16.2427L12.0001 10.5858L17.6569 16.2427Z" fill="currentColor" /> </svg>',
  'chevron-down-small': '<svg width="1em" height="1em" viewBox="-4 -4 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z" fill="currentColor" /> </svg>',
  'chevron-right-small': '<svg width="1em" height="1em" viewBox="-4 -4 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z" fill="currentColor" /> </svg>',
  'chevron-left-small': '<svg width="1em" height="1em" viewBox="-4 -4 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z" fill="currentColor" /> </svg>',
  'chevron-up-small': '<svg width="1em" height="1em" viewBox="-4 -4 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M17.6569 16.2427L19.0711 14.8285L12.0001 7.75739L4.92896 14.8285L6.34317 16.2427L12.0001 10.5858L17.6569 16.2427Z" fill="currentColor" /> </svg>',
  'exclamation-triangle': '<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Exclamation-triangle-icon" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd"><path d="M12,2 C12.3955786,2 12.7611083,2.21813636 12.9588976,2.57223923 L22.851658,20.2832823 C23.0494473,20.6373852 23.0494473,21.0736579 22.851658,21.4277608 C22.6538687,21.7818636 22.288339,22 21.8927604,22 L2.10723959,22 C1.71166099,22 1.34613128,21.7818636 1.14834198,21.4277608 C0.950552674,21.0736579 0.950552674,20.6373852 1.14834198,20.2832823 L11.0411024,2.57223923 C11.2388917,2.21813636 11.6044214,2 12,2 Z M12.01,17 C11.314102,17 10.75,17.583074 10.75,18.3023765 C10.75,19.0216791 11.314102,19.6047531 12.01,19.6047531 C12.705898,19.6047531 13.27,19.0216791 13.27,18.3023765 C13.27,17.583074 12.705898,17 12.01,17 Z M12.01,7.5 C11.314102,7.5 10.75,7.958913 10.75,8.525 L10.75,8.525 L10.75,14.675 C10.75,15.2411075 11.314102,15.7 12.01,15.7 C12.705898,15.7 13.27,15.2411075 13.27,14.675 L13.27,14.675 L13.27,8.525 C13.27,7.958913 12.705898,7.5 12.01,7.5 Z" id="Combined-Shape" fill-rule="nonzero"></path></g></svg>',
  'exclamation-circle': '<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Exclamation-circle-icon" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd"><path d="M12,2 C17.5228,2 22,6.47715 22,12 C22,17.5228 17.5228,22 12,22 C6.47715,22 2,17.5228 2,12 C2,6.47715 6.47715,2 12,2 Z M12,16 C11.33724,16 10.8,16.53724 10.8,17.2 C10.8,17.86276 11.33724,18.4 12,18.4 C12.66276,18.4 13.2,17.86276 13.2,17.2 C13.2,16.53724 12.66276,16 12,16 Z M12,6 C11.33724,6 10.8,6.44772 10.8,7 L10.8,7 L10.8,13 C10.8,13.5523 11.33724,14 12,14 C12.66276,14 13.2,13.5523 13.2,13 L13.2,13 L13.2,7 C13.2,6.44772 12.66276,6 12,6 Z" id="Combined-Shape" fill-rule="nonzero"></path></g></svg>',
  'check-circle': '<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Check-circle-icon" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd"><path d="M12,2 C17.5228182,2 22,6.47715455 22,12 C22,17.5228182 17.5228182,22 12,22 C6.47715455,22 2,17.5228182 2,12 C2,6.47715455 6.47715455,2 12,2 Z M15.545,8.20763636 L10.4023636,13.3502727 L7.8311,10.7789091 L6.54545455,12.0645455 L10.4023636,15.9215455 L16.8306364,9.49329091 L15.545,8.20763636 Z" id="Combined-Shape" fill-rule="nonzero"></path</g></svg>',
  'trash-o': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" clip-rule="evenodd" d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z" fill="currentColor" /> <path d="M9 9H11V17H9V9Z" fill="currentColor" /> <path d="M13 9H15V17H13V9Z" fill="currentColor" /> </svg>',
  'password-show-o': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="eye"><rect width="24" height="24" opacity="0"/><path d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zM12.22 17c-4.31.1-7.12-3.59-8-5 1-1.61 3.61-4.9 7.61-5 4.29-.11 7.11 3.59 8 5-1.03 1.61-3.61 4.9-7.61 5z"/><path d="M12 8.5a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 8.5zm0 5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z"/></g></g></svg>',
  'password-hide-o': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="eye-off"><rect width="24" height="24" opacity="0"/><path d="M4.71 3.29a1 1 0 0 0-1.42 1.42l5.63 5.63a3.5 3.5 0 0 0 4.74 4.74l5.63 5.63a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM12 13.5a1.5 1.5 0 0 1-1.5-1.5v-.07l1.56 1.56z"/><path d="M12.22 17c-4.3.1-7.12-3.59-8-5a13.7 13.7 0 0 1 2.24-2.72L5 7.87a15.89 15.89 0 0 0-2.87 3.63 1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.48 9.48 0 0 0 3.23-.67l-1.58-1.58a7.74 7.74 0 0 1-1.7.25z"/><path d="M21.87 11.5c-.64-1.11-4.17-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67l1.58 1.58a7.74 7.74 0 0 1 1.7-.25c4.29-.11 7.11 3.59 8 5a13.7 13.7 0 0 1-2.29 2.72L19 16.13a15.89 15.89 0 0 0 2.91-3.63 1 1 0 0 0-.04-1z"/></g></g></svg>',
  'info-circle': '<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Info-circle-icon" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd"><path d="M12,2 C17.5228,2 22,6.47715 22,12 C22,17.5228 17.5228,22 12,22 C6.47715,22 2,17.5228 2,12 C2,6.47715 6.47715,2 12,2 Z M11.9976048,9.90419162 C11.3361677,9.90419162 10.8,10.3532604 10.8,10.907212 L10.8,10.907212 L10.8,16.9251535 C10.8,17.479105 11.3361677,17.9281437 11.9976048,17.9281437 C12.6590419,17.9281437 13.1952096,17.479105 13.1952096,16.9251535 L13.1952096,16.9251535 L13.1952096,10.907212 C13.1952096,10.3532604 12.6590419,9.90419162 11.9976048,9.90419162 Z M11.9976048,6.07185629 C11.3361677,6.07185629 10.8,6.60803593 10.8,7.26946108 C10.8,7.93087425 11.3361677,8.46706587 11.9976048,8.46706587 C12.6590419,8.46706587 13.1952096,7.93087425 13.1952096,7.26946108 C13.1952096,6.60803593 12.6590419,6.07185629 11.9976048,6.07185629 Z" id="Combined-Shape"></path></g></svg>',
  'arrow-first': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.2877 11.0001V13.0001H7.80237L11.045 16.2428L9.63079 17.657L3.97394 12.0001L9.63079 6.34326L11.045 7.75748L7.80236 11.0001H22.2877Z" fill="currentColor"/><path d="M3 18V6H1V18H3Z" fill="currentColor"/></svg>',
  'arrow-left': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z" fill="currentColor"/></svg>',
  'arrow-right': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0378 6.34317L13.6269 7.76069L16.8972 11.0157L3.29211 11.0293L3.29413 13.0293L16.8619 13.0157L13.6467 16.2459L15.0643 17.6568L20.7079 11.9868L15.0378 6.34317Z" fill="currentColor"/></svg>',
  'arrow-last': '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M1 12.9999V10.9999H15.4853L12.2427 7.75724L13.6569 6.34303L19.3137 11.9999L13.6569 17.6567L12.2427 16.2425L15.4853 12.9999H1Z" fill="currentColor" /> <path d="M20.2877 6V18H22.2877V6H20.2877Z" fill="currentColor"/></svg>',
  'star-o': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26zm0-2.292l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275l-2.038 4.42-4.833.572 3.573 3.305-.949 4.773L12 15.968z"/></svg>',
  star: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"/></svg>',
  play: '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M15 12.3301L9 16.6603L9 8L15 12.3301Z" fill="currentColor" /> </svg>',
  plus: '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z" fill="currentColor" /> </svg>',
  minus: '<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z" fill="currentColor" /> </svg>',
  check: '<svg width="1em" height="1em" viewBox="4 4 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z" fill="currentColor" /> </svg>',
  'ellipsis-h': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z" fill="currentColor" /> <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="currentColor" /> <path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z" fill="currentColor" /> </svg>',
  calendar: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 4h5v4H6v-4z"/></svg>',
  close: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>'
};
DEFAULT_ICONS['exclamation-circle-warning'] = DEFAULT_ICONS['exclamation-circle'];
class NxSvgIcon {
  constructor(_httpClient, _sanitizer, _document) {
    this._httpClient = _httpClient;
    this._sanitizer = _sanitizer;
    this._document = _document;
  }
  /** Returns the content. */
  getContent() {
    return of(this.svgElement);
  }
  _svgElementFromString(str) {
    const div = this._document.createElement('DIV');
    div.innerHTML = str;
    const svg = div.querySelector('svg');
    if (!svg) {
      throw Error('<svg> tag not found');
    }
    return svg;
  }
}
class NxSvgIconLiteral extends NxSvgIcon {
  constructor(data, _httpClient, _sanitizer, _document) {
    super(_httpClient, _sanitizer, _document);
    const sanitizedLiteral = this._sanitizer.sanitize(SecurityContext.HTML, data);
    if (!sanitizedLiteral) {
      throw Error(`The literal provided to NxIconRegistry was not trusted as safe HTML by Angular's DomSanitizer. Attempted literal was "${data}".`);
    }
    this.svgElement = this._svgElementFromString(sanitizedLiteral);
  }
}
class NxSvgIconFromUrl extends NxSvgIcon {
  constructor(safeUrl, _httpClient, _sanitizer, _document) {
    super(_httpClient, _sanitizer, _document);
    this.url = this._sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl);
    if (!this.url) {
      throw Error(`The URL provided to NxIconRegistry was not trusted as a resource URL via Angular's DomSanitizer. Attempted URL was "${safeUrl}".`);
    }
    if (!_httpClient) {
      throw Error('Could not find HttpClient provider for using a SVG url in the nx-icon registry. ' + 'Please include the HttpClientModule from @angular/common/http in your app imports.');
    }
    this._httpClient = _httpClient;
  }
  /** Returns the content. If the SVG is not already loaded it fetches the SVG from icons' URL */
  getContent() {
    return this._loadSvgIcon();
  }
  _loadSvgIcon() {
    // If the SVG for this icon has already been parsed, do nothing.
    if (this.svgElement) {
      return of(this.svgElement);
    }
    return this._fetchUrl(this.url).pipe(map(svgText => {
      // It is possible that the icon was parsed and cached by an earlier request, so parsing
      // only needs to occur if the cache is yet unset.
      if (!this.svgElement) {
        this.svgElement = this._svgElementFromString(svgText);
      }
      return this.svgElement;
    }));
  }
  /**
   * Returns an Observable which produces the string contents of the given URL. Results may be
   * cached, so future calls with the same URL may not cause another HTTP request.
   */
  _fetchUrl(url) {
    // Store in-progress fetches to avoid sending a duplicate request for a URL when there is
    // already a request in progress for that URL. It's necessary to call share() on the
    // Observable returned by http.get() so that multiple subscribers don't cause multiple XHRs.
    if (this._pendingRequest) {
      return this._pendingRequest;
    }
    const req = this._httpClient.get(url, {
      responseType: 'text'
    }).pipe(finalize(() => {
      this._pendingRequest = undefined;
    }), share());
    this._pendingRequest = req;
    return req;
  }
}
class NxFontIcon {
  constructor(alias, font) {
    this.alias = alias;
    this.font = font;
  }
  getClasses() {
    return {
      alias: this.alias,
      font: this.font
    };
  }
}
function throwNxIconNotFoundError(iconName) {
  throw Error(`Could not find icon with name ${iconName}`);
}
class NxIconFontDefinition {
  constructor(hostClass, prefix = '') {
    this.hostClass = hostClass;
    this.prefix = prefix;
  }
}
class NxIconRegistry {
  constructor(_httpClient, _sanitizer, _document) {
    this._httpClient = _httpClient;
    this._sanitizer = _sanitizer;
    this._document = _document;
    this._icons = new Map();
    this._fonts = new Map();
    // register default icons
    Object.entries(DEFAULT_ICONS).forEach(([icon, literal]) => {
      this.addSvgIconLiteral(icon, _sanitizer.bypassSecurityTrustHtml(literal));
    });
  }
  /**
   * Registers an icon using an HTML string.
   */
  addSvgIconLiteral(iconName, literal) {
    this._icons.set(iconName, new NxSvgIconLiteral(literal, this._httpClient, this._sanitizer, this._document));
  }
  /**
   * Registers an icon by URL.
   */
  addSvgIcon(iconName, safeUrl) {
    if (safeUrl == null) {
      throw Error(`Cannot fetch icon from URL "${safeUrl}".`);
    }
    this._icons.set(iconName, new NxSvgIconFromUrl(safeUrl, this._httpClient, this._sanitizer, this._document));
  }
  /**
   * Only necessary to override an existing svg icon in the registry
   * with a font class. Useful to override the essential library icons.
   * To register an icon font in general use `registerFont` and
   * `setDefaultFontClass`.
   *
   * Example: you want to override the essential svg icon of the library `chevron-down`
   * with an icon from your icon font called 'arrow-down'. You would first register your
   * icon font:
   * `iconRegistry.registerFont('my-icons');`
   * `iconRegistry.setDefaultFontClass('my-icons');`
   * and then override the chevron-down icon in the registry:
   * `iconRegistry.addFontIcon('chevron-down', 'arrow-down');`
   */
  addFontIcon(iconName, alias, fontName) {
    let fontDefinition = this.getDefaultFont();
    if (fontName) {
      fontDefinition = this._fonts.get(fontName);
    }
    if (!fontDefinition) {
      throw Error(`Could not find a registered font with name ${fontName}.`);
    }
    this._icons.set(iconName, new NxFontIcon(alias, fontDefinition));
  }
  /** Returns the icon from the registry or undefined if not found. */
  getIcon(iconName) {
    const icon = this._icons.get(iconName);
    return icon;
  }
  ngOnDestroy() {
    this._icons.clear();
  }
  /**
   * Register an icon font which can be used by the font input on the icon component.
   * @param name Custom name identifier.
   * @param hostClass The hostClass is the general class like `fa` and an optional prefix can be given.
   * @param prefix The prefix is helpful if your class name for the icon would be prefixed,
   * e.g. my-icons--heart but you still want to only use the name
   * <code>&lt;nx-icon name="heart" font="my-icons"&gt;&lt;/nx-icon&gt;</code>.
   */
  registerFont(name, hostClass, prefix) {
    // register the name in a map
    this._fonts.set(name, new NxIconFontDefinition(hostClass ? hostClass : name, prefix));
  }
  /**
   * Sets the font as the default font that is used when the font input
   * is omitted.
   */
  setDefaultFont(name) {
    const font = this.getFont(name);
    this._defaultFont = font;
  }
  /**
   * Returns the registered CSS class name.
   */
  getDefaultFont() {
    return this._defaultFont;
  }
  /**
   * Returns the registered font by name.
   */
  getFont(fontName) {
    const font = this._fonts.get(fontName);
    if (!font) {
      throw Error(`Cannot find registered font with name ${fontName}`);
    }
    return font;
  }
  static {
    this.ɵfac = function NxIconRegistry_Factory(t) {
      return new (t || NxIconRegistry)(i0.ɵɵinject(i1.HttpClient, 8), i0.ɵɵinject(i2.DomSanitizer), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NxIconRegistry,
      factory: NxIconRegistry.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxIconRegistry, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i2.DomSanitizer
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
/** Clones an SVGElement while preserving type information. */
function cloneSvg(svg) {
  return svg.cloneNode(true);
}
class NxIconComponent {
  /** Sets the name for specifying the icon.*/
  set name(name) {
    this._name = name;
  }
  get name() {
    return this._name;
  }
  /** Whether the icon has an outline. */
  set outline(value) {
    this._outline = coerceBooleanProperty(value);
  }
  get outline() {
    return this._outline;
  }
  /** Whether the icon is filled. */
  set fill(value) {
    this._fill = coerceBooleanProperty(value);
  }
  get fill() {
    return this._fill;
  }
  /** Specifies the size of the icon. */
  set size(value) {
    if (this._size === value) {
      return;
    }
    this._size = value;
    this.el.nativeElement.classList.add('nx-icon--' + this.size);
  }
  get size() {
    return this._size;
  }
  constructor( /** @docs-private */el, _iconRegistry) {
    this.el = el;
    this._iconRegistry = _iconRegistry;
    this._previousFontClasses = [];
    this._name = '';
    this._outline = false;
    this._fill = false;
    this._size = 'auto';
    /** Sets the font name that should be used. */
    this.font = '';
  }
  ngOnChanges(changes) {
    if (changes.name || changes.font) {
      this.renderIcon();
    }
  }
  renderIcon() {
    const icon = this._iconRegistry.getIcon(this.name);
    if (!icon) {
      // here we fall back to the icon class so we look at the `font` input
      // or take the default font
      this._updateFontIconClasses();
      this._clearSvgElement();
    } else if (icon instanceof NxSvgIcon) {
      // add content
      icon.getContent().pipe(take(1)).subscribe(content => {
        if (!content) {
          return;
        }
        // we need to clone the svg here otherwise when you have the same icon
        // multiple times it would end up only in the last icon that got created
        this._setSvgElement(cloneSvg(content));
      });
    } else {
      // here we have to look at the alias as well that could come from the registry
      this._updateFontIconClassesFromOverride(icon.alias, icon.font);
      this._clearSvgElement();
    }
  }
  _setSvgElement(svg) {
    this._clearSvgElement();
    // Workaround for IE11 and Edge ignoring `style` tags inside dynamically-created SVGs.
    // See: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10898469/
    // Do this before inserting the element into the DOM, in order to avoid a style recalculation.
    const styleTags = svg.querySelectorAll('style');
    for (let i = 0; i < styleTags.length; i++) {
      styleTags[i].textContent += ' ';
    }
    this._removePreviousFontClasses();
    this.el.nativeElement.appendChild(svg);
  }
  _clearSvgElement() {
    const layoutElement = this.el.nativeElement;
    let childCount = layoutElement.childNodes.length;
    if (this._elementsWithExternalReferences) {
      this._elementsWithExternalReferences.clear();
    }
    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = layoutElement.childNodes[childCount];
      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        layoutElement.removeChild(child);
      }
    }
  }
  _updateFontIconClassesFromOverride(alias, font) {
    const name = alias ? alias : this.name;
    this._setFontIconClasses([font.hostClass, font.prefix + name]);
  }
  _updateFontIconClasses() {
    const font = this.font ? this._iconRegistry.getFont(this.font) : this._iconRegistry.getDefaultFont();
    const hostClass = font ? font.hostClass : '';
    const name = font ? font.prefix + this.name : this.name;
    this._setFontIconClasses([hostClass, name]);
  }
  _setFontIconClasses(classes) {
    // filters empty classes as they cannot be added via classList.add
    const filteredClasses = classes.filter(c => c !== '');
    const elem = this.el.nativeElement;
    this._removePreviousFontClasses();
    this._previousFontClasses = filteredClasses;
    filteredClasses.forEach(cl => elem.classList.add(cl));
  }
  _removePreviousFontClasses() {
    if (!this._previousFontClasses) {
      return;
    }
    this._previousFontClasses.forEach(cl => {
      // IE11 doesn't support multiple paramaters in remove or add
      // so we can't use the spread operator here
      this.el.nativeElement.classList.remove(cl);
    });
  }
  static {
    this.ɵfac = function NxIconComponent_Factory(t) {
      return new (t || NxIconComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NxIconRegistry));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxIconComponent,
      selectors: [["nx-icon"]],
      hostVars: 7,
      hostBindings: function NxIconComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-nx-icon-name", ctx.name);
          i0.ɵɵclassProp("nx-icon--outline", ctx.outline)("nx-icon--fill", ctx.fill)("nx-icon--auto", ctx.size === "auto");
        }
      },
      inputs: {
        name: "name",
        outline: "outline",
        fill: "fill",
        size: "size",
        font: "font"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function NxIconComponent_Template(rf, ctx) {},
      styles: ["[_nghost-%COMP%]{speak:none;text-transform:none;line-height:1;display:inline-flex;align-items:center;justify-content:center;letter-spacing:normal;text-align:center;width:1em;height:1em}[_nghost-%COMP%]     svg{width:1em;height:1em;fill:currentColor;flex:0 0 auto}[_nghost-%COMP%]:before{font-size:inherit;width:1em;height:1em}@media screen and (forced-colors: active){[_nghost-%COMP%]{color:inherit!important}}.nx-icon--auto[_nghost-%COMP%]{font-size:inherit}.nx-icon--s[_nghost-%COMP%]{font-size:24px}.nx-icon--m[_nghost-%COMP%]{font-size:48px}.nx-icon--l[_nghost-%COMP%]{font-size:72px}.nx-icon--xl[_nghost-%COMP%]{font-size:96px}.nx-icon--outline[_nghost-%COMP%]{border-radius:50%;border:solid;width:2em;height:2em;border-width:1px}.nx-icon--outline.nx-icon--s[_nghost-%COMP%]{border-width:1px;width:40px;height:40px}.nx-icon--outline.nx-icon--m[_nghost-%COMP%]{border-width:2px;width:80px;height:80px}.nx-icon--outline.nx-icon--l[_nghost-%COMP%]{border-width:3px;width:120px;height:120px}.nx-icon--outline.nx-icon--xl[_nghost-%COMP%]{border-width:4px;width:160px;height:160px}.nx-icon--fill[_nghost-%COMP%]{border-radius:50%;border-color:transparent;width:2em;height:2em;background:var(--icon-filled-background-color);color:var(--icon-filled-color)}.nx-icon--fill.nx-icon--s[_nghost-%COMP%]{width:40px;height:40px}.nx-icon--fill.nx-icon--m[_nghost-%COMP%]{width:80px;height:80px}.nx-icon--fill.nx-icon--l[_nghost-%COMP%]{width:120px;height:120px}.nx-icon--fill.nx-icon--xl[_nghost-%COMP%]{width:160px;height:160px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxIconComponent, [{
    type: Component,
    args: [{
      selector: 'nx-icon',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.nx-icon--outline]': 'outline',
        '[class.nx-icon--fill]': 'fill',
        '[class.nx-icon--auto]': 'size === "auto"',
        /** Provide a stable selector for getting icons by name or retrieving the name (primarily for harnesses) */
        '[attr.data-nx-icon-name]': 'name'
      },
      styles: [":host{speak:none;text-transform:none;line-height:1;display:inline-flex;align-items:center;justify-content:center;letter-spacing:normal;text-align:center;width:1em;height:1em}:host ::ng-deep svg{width:1em;height:1em;fill:currentColor;flex:0 0 auto}:host:before{font-size:inherit;width:1em;height:1em}@media screen and (forced-colors: active){:host{color:inherit!important}}:host(.nx-icon--auto){font-size:inherit}:host(.nx-icon--s){font-size:24px}:host(.nx-icon--m){font-size:48px}:host(.nx-icon--l){font-size:72px}:host(.nx-icon--xl){font-size:96px}:host(.nx-icon--outline){border-radius:50%;border:solid;width:2em;height:2em;border-width:1px}:host(.nx-icon--outline).nx-icon--s{border-width:1px;width:40px;height:40px}:host(.nx-icon--outline).nx-icon--m{border-width:2px;width:80px;height:80px}:host(.nx-icon--outline).nx-icon--l{border-width:3px;width:120px;height:120px}:host(.nx-icon--outline).nx-icon--xl{border-width:4px;width:160px;height:160px}:host(.nx-icon--fill){border-radius:50%;border-color:transparent;width:2em;height:2em;background:var(--icon-filled-background-color);color:var(--icon-filled-color)}:host(.nx-icon--fill).nx-icon--s{width:40px;height:40px}:host(.nx-icon--fill).nx-icon--m{width:80px;height:80px}:host(.nx-icon--fill).nx-icon--l{width:120px;height:120px}:host(.nx-icon--fill).nx-icon--xl{width:160px;height:160px}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: NxIconRegistry
  }], {
    name: [{
      type: Input
    }],
    outline: [{
      type: Input
    }],
    fill: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    font: [{
      type: Input
    }]
  });
})();
class NxStatusIconComponent {
  constructor() {
    this._size = 'auto';
    this.statusList = {
      error: {
        icon: 'exclamation-triangle'
      },
      warning: {
        icon: 'exclamation-circle-warning'
      },
      success: {
        icon: 'check-circle'
      },
      info: {
        icon: 'info-circle'
      }
    };
  }
  /** Specifies the size of the icon. */
  set size(value) {
    if (this._size === value) {
      return;
    }
    this._size = value;
  }
  get size() {
    return this._size;
  }
  /** @docs-private */
  get icon() {
    return this.statusList[this.type]?.icon;
  }
  /** @docs-private */
  get typeClass() {
    return `nx-status-icon--${this.type}`;
  }
  static {
    this.ɵfac = function NxStatusIconComponent_Factory(t) {
      return new (t || NxStatusIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxStatusIconComponent,
      selectors: [["nx-status-icon"]],
      inputs: {
        type: "type",
        size: "size"
      },
      decls: 1,
      vars: 3,
      consts: [["aria-hidden", "true", 3, "name", "size", "ngClass"]],
      template: function NxStatusIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "nx-icon", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("name", ctx.icon)("size", ctx.size)("ngClass", ctx.typeClass);
        }
      },
      dependencies: [i1$1.NgClass, NxIconComponent],
      styles: [".nx-status-icon--error[_ngcontent-%COMP%]{color:var(--danger)}.nx-status-icon--warning[_ngcontent-%COMP%]{color:var(--warning)}.nx-status-icon--info[_ngcontent-%COMP%]{color:var(--info)}.nx-status-icon--success[_ngcontent-%COMP%]{color:var(--success)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxStatusIconComponent, [{
    type: Component,
    args: [{
      selector: 'nx-status-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<nx-icon [name]=\"icon\" [size]=\"size\" [ngClass]=\"typeClass\" aria-hidden=\"true\"></nx-icon>\n",
      styles: [".nx-status-icon--error{color:var(--danger)}.nx-status-icon--warning{color:var(--warning)}.nx-status-icon--info{color:var(--info)}.nx-status-icon--success{color:var(--success)}\n"]
    }]
  }], null, {
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }]
  });
})();
class NxIconModule {
  static {
    this.ɵfac = function NxIconModule_Factory(t) {
      return new (t || NxIconModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxIconModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxIconModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [NxIconComponent, NxStatusIconComponent],
      exports: [NxIconComponent, NxStatusIconComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_ICONS, NxDefaultIcons, NxFontIcon, NxIconComponent, NxIconFontDefinition, NxIconModule, NxIconRegistry, NxStatusIconComponent, NxSvgIcon, NxSvgIconFromUrl, NxSvgIconLiteral, cloneSvg, throwNxIconNotFoundError };
