<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>No Claim Discount Confirmation Enquiry</h1>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #templateThree> -->
<div nxLayout="grid maxwidth" class="nx-margin-bottom-3xl">
  <form [formGroup]="searchNcdConfirmationForm">
    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Request Type">
          <nx-dropdown formControlName="requestType">
            <ng-container *ngFor="let objRequestType of requestTypeList">
              <nx-dropdown-item [value]="objRequestType.code">{{ objRequestType.name }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please choose Request Type</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Previous Vehicle No.">
          <input formControlName="previousVehicleNo" appToUpperCase appAlphaNumeric nxInput
            style="text-transform: uppercase;" maxlength="20" />
          <nx-error nxFormfieldError>
            <strong>Please note:</strong> this field is required!
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Current Vehicle No.">
          <input nxInput formControlName="currentVehicleNo" appToUpperCase appAlphaNumeric
            style="text-transform: uppercase;" maxlength="20" />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="e-Cover Note No.">
          <input nxInput formControlName="cnoteNo" appToUpperCase style="text-transform: uppercase;" />
          <nx-error nxFormfieldError>
            <strong>Please note:</strong> this field is required!
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Request Date From">
          <input nxDatefield nxInput [datepicker]="myDatepicker" formControlName="startDate" />
          <span nxFormfieldHint>DD/MM/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker></nx-datepicker>
          <nx-error nxFormfieldError>
            <strong>Please choose Request Date From.</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Request Date To">
          <input nxDatefield nxInput [datepicker]="myDatepicker2" formControlName="endDate" />
          <span nxFormfieldHint>DD/MM/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker2></nx-datepicker>
          <nx-error nxFormfieldError
            *ngIf="searchNcdConfirmationForm && searchNcdConfirmationForm.get('endDate').hasError('required')">
            <strong>Please choose Request Date To.</strong>
          </nx-error>
          <nx-error nxFormfieldError
            *ngIf="searchNcdConfirmationForm && searchNcdConfirmationForm.get('endDate').hasError('endDateBeforeStartDate')">
            <strong>Request Date To cannot be earlier than Request Date From.</strong>
          </nx-error>
          <nx-error nxFormfieldError
            *ngIf="searchNcdConfirmationForm && searchNcdConfirmationForm.get('endDate').hasError('endDateTooFar')">
            <strong>Request Date To cannot be more than 1 month later than Request Date From.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
  </form>
  <div nxRow>
    <div nxCol="12" class="nx-margin-top-s std-ta-right">
      <button (click)="resetForm()" nxButton="secondary" type="button" class="nx-margin-right-s">
        RESET
      </button>
      <button (click)="onSearchClicked()" nxButton="primary" type="button" class="m_margin_top_20"
        [disabled]="checkDisabled()">
        SEARCH
      </button>
    </div>
  </div>
  <div nxRow>
    <div nxCol="12" class="nx-margin-y-2m">
      <hr />
    </div>
  </div>
  @if(searchModelResult){
  <div nxRow class="nx-margin-top-2m">
    <div nxCol="12">
      <div class="scroll-container">
        <div>
          <app-external-scrollbar targetSyncScroll="3" [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
          <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3 syncScroll="3">
            <table zebra nxTable nxSort (sortChange)="sortTable($event)">
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="requestDate">
                    Request Date
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="responseDate">
                    Response Date
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="requestType">
                    Request Type
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="cnoteNo">
                    e-Cover Note No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="previousVehicleNo">
                    Previous <br />
                    Vehicle No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="currentVehicleNo">
                    Current <br />Vehicle No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="previousNcdLevel" class="std-ta-right std-va-top">
                    Current No Claim <br />
                    Discount (%)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="nextNcdLevel" class="std-ta-right">
                    Next No Claim <br />Discount (%)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="approvalCode">
                    Approval Code
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="ismResponseCode">
                    ISM Response
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of searchModelResult; let i = index" [selectable]="true">
                  <td nxTableCell>{{ item.requestDate ? (item.requestDate | date:'dd/MM/yyyy h:mm:ss a') : 'N/A' }}</td>
                  <td nxTableCell>{{ item.responseDate ? (item.responseDate | date:'dd/MM/yyyy h:mm:ss a') : 'N/A' }}
                  </td>
                  <td nxTableCell>{{ item.requestType }}</td>
                  <td nxTableCell>{{ item.cnoteNo === '-' ? 'N/A' : item.cnoteNo || 'N/A' }}</td>
                  <td nxTableCell>{{ item.previousVehicleNo? item.previousVehicleNo: 'N/A' }}</td>
                  <td nxTableCell>{{ item.currentVehicleNo? item.currentVehicleNo: 'N/A' }}</td>
                  <td nxTableCell class="std-ta-right">{{ item.previousNcdLevel ? (item.previousNcdLevel|
                    number:'1.2-2') : '0.00' }}</td>
                  <td nxTableCell class="std-ta-right">{{ item.nextNcdLevel ? (item.nextNcdLevel| number:'1.2-2') :
                    '0.00' }}</td>
                  <td nxTableCell>{{ item.approvalCode? item.approvalCode: 'N/A' }}</td>
                  <td nxTableCell>{{ item.ismResponseCode? item.ismResponseCode: 'N/A' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  }

  <ng-container *ngIf="!searchModelResult">
    <tr nxTableRow>
      <td>
        <span nxCopytext="normal">{{noResultFoundMessage}}</span>
      </td>
    </tr>
  </ng-container>
</div>
<!-- </ng-template> -->