<div>
    <app-external-scrollbar targetSyncScroll="7" [targetBaseWidth]="tableHolder7"></app-external-scrollbar>
    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder7 syncScroll="7">
      <table nxTable nxSort class="enquiry">
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell nxSortHeaderCell="product">
              Endorsement Ref. No.
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Issued Date
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Endorsement
              <Br />Effective Date
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Endorsement Type
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Referral Reasons
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Status
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Issued By
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Approve / Decline By
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Approve / Decline Date
            </th>
            <th nxHeaderCell nxSortHeaderCell="contractNumber">
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          @for(endtHistory of endtHistoryList; track endtHistory) {
            <tr nxTableRow>
            <td nxTableCell>
              <button nxPlainButton type="button" (click)="openEndtNarrationPopUp(endtHistory)">
                {{ endtHistory.endtReferenceNo }}
              </button>
            </td>
            <td nxTableCell>{{ endtHistory.cnoteDate | date: 'dd/MM/yyyy' }}</td>
            <td nxTableCell>{{ endtHistory.endtEffectiveDate | date: 'dd/MM/yyyy' }}</td>
            <td nxTableCell>{{ getEndtType(endtHistory.cnoteType) }}</td>
            <td nxTableCell>{{ endtHistory.endtReferCodeDescp }}</td>
            <td nxTableCell>
              <nx-badge type="active" class="pending_badge">
                {{ getStatus(endtHistory.cnoteStatus) }}
              </nx-badge>
            </td>
            <td nxTableCell>{{ endtHistory.issueBy }}</td>
            <td nxTableCell>{{ endtHistory.referBy }}</td>
            <td nxTableCell>{{ endtHistory.referDate | date: 'dd/MM/yyyy' }}</td>
            <td nxTableCell>{{ endtHistory.referAdminRemarks }}</td>
          </tr>
          }
          
        </tbody>
      </table>
    </div>
  </div>