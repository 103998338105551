import {Component, OnDestroy} from "@angular/core";
import { RouterModule, RouterOutlet } from "@angular/router";

import { Store, Select } from "@ngxs/store";

import { NxButtonModule } from "@aposin/ng-aquila/button";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";

import { environment } from "../environments/environment";

import { HttpHelperService } from "@services/http-helper/http-helper.service";
import { TranslateService } from "@ngx-translate/core";
import { MotorHeaderComponent } from "./components/motor-header/motor-header.component";
import { UserState } from "@store/user/user.state";
import { BACK_TO_ALPHA, BACK_TO_SAT, SIGN_OUT } from './store/user/user.action';
import { Subject } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { LoadingComponent } from "./shared/components/global/loading/loading.component";

@Component({
    selector: "app-root",
    standalone: true,
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
    imports: [
    RouterOutlet,
    NxButtonModule,
    RouterModule,
    FooterComponent,
    HeaderComponent,
    MotorHeaderComponent,
    AsyncPipe,
    LoadingComponent
],
})
export class AppComponent implements OnDestroy {

    @Select(UserState.user) user$;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private translate: TranslateService,
        private httpHelperService: HttpHelperService,
        private store: Store,
    ) {
        translate.setDefaultLang('en');
        this.httpHelperService.setEnv(environment.apiUrl);
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    title = "sat-motor";

    backToAlpha($event) {
        this.store.dispatch(new BACK_TO_ALPHA());
    }
    
    backToSAT($event) {
        this.store.dispatch(new BACK_TO_SAT());
    }

    signOut($event) {
        this.store.dispatch(new SIGN_OUT());
    }
}
