<div nxCol="12" class="search-map">
    <ng-container *ngIf="mapVisible && googleMapsLoaded()">
  
      <div class="pac-card" id="pac-card">
          <div>
              <div id="title">Autocomplete search</div>
              <div id="type-selector" class="pac-controls">
                  <div class="radio-group">
                      <span nxCopytext="medium">Search Mode: &nbsp;</span>
                      <div class="radio-map">
                          <nx-radio id="byAddress" name="searchMode" [(ngModel)]="searchMode" labelSize="small" [checked]="true" value="address"
                              (valueChange)="onSearchModeChange('address')"><span nxCopytext="medium">By Address</span>
                          </nx-radio>
                          <nx-radio id="byLatLng" name="searchMode" [(ngModel)]="searchMode" labelSize="small" value="latLng"
                              (valueChange)="onSearchModeChange('latLng')"><span nxCopytext="medium">By Lat/Long</span>
                          </nx-radio>
                      </div>
                  </div>
              </div>
              <br />
          </div>
          <div id="pac-container">
              <!-- <input *ngIf="searchMode === 'address'" id="pac-input" type="text" placeholder="Enter a location" /> -->
              <input *ngIf="searchMode === 'address'"
                          type="text"
                          #search
                          id="pac-input"
                          placeholder="Enter a location"
                          [ngModel]="searchAddress"
                          (input)="onInputChange($event)"
                          (focus)="onInputFocus()"
                          (blur)="onInputBlur()"
                          autocomplete="off"
                      />
                      <ul class="autocomplete-suggestions" *ngIf="searchMode === 'address' && showSuggestions">
                          <li
                          class="autocomplete-suggestion"
                          *ngFor="let suggestion of autocompleteSuggestions"
                          (click)="onSuggestionClick(suggestion)"
                          >
                          <nx-icon name="location" class="location-icon"></nx-icon>
                          <span *ngIf="matchedSubstrings > 0; else suggestionText">
                              <b>{{ suggestion.description | slice: 0:matchedSubstrings }}</b>{{ suggestion.description | slice: matchedSubstrings }}
                          </span>
                          <ng-template #suggestionText>{{ suggestion.description }}</ng-template>
                          </li>
                      </ul>
  
              <div *ngIf="searchMode === 'latLng'">
                  <div class="lat_long">
                      <input id="pac-input" type="text" placeholder="Latitude" [(ngModel)]="latitude" />
                      <input id="pac-input" type="text" placeholder="Longitude" [(ngModel)]="longitude" />
                  </div>
                  <div class="container_flex_center align_right nx-margin-top-s">
                      <button nxButton="primary small" type="button" (click)="searchByLatLng()">
                          Search
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <google-map
          [center]="center"
          [zoom]="zoom"
          [options]="mapOptions"
          height="500px"
          width="100%"
          (mapClick)="addMarker($event, null)">
          <map-marker
              *ngFor="let marker of markers"
              [position]="marker.position"
              [label]="marker.label"
              [title]="marker.title"
              [options]="marker.options">
          </map-marker>
      </google-map>
    </ng-container>
    <div *ngIf="!googleMapsLoaded()">
      Loading map...
    </div>
</div>

  