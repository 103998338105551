<div>
  <h2 nxHeadline="section" class="std-ta-center nx-margin-bottom-2m">Share</h2>

  <div class="share_container share">
    <div class="icon_container ">
      <span class="media-btn" (click)="buttonClicked('Facebook')">
        <img
          class="socialIcon"
          src="assets/img/social-icon/Facebook.svg"
          alt="Facebook"
        />
      </span>
      <span class="media-btn" (click)="buttonClicked('Twitter')">
        <img
          class="socialIcon"
          src="assets/img/social-icon/Twitter.svg"
          alt="Twitter"
        />
      </span>
      <span class="media-btn" (click)="buttonClicked('LinkedIn')">
        <img
          class="socialIcon"
          src="assets/img/social-icon/LinkedIn.svg"
          alt="LinkedIn"
        />
      </span>
      <span class="media-btn" (click)="buttonClicked('Messenger')">
        <img
          class="socialIcon"
          src="assets/img/social-icon/Messenger.svg"
          alt="Messenger"
        />
      </span>
      <span class="media-btn" (click)="buttonClicked('Whatsapp')">
        <img
          class="socialIcon"
          src="assets/img/social-icon/Whatsapp.svg"
          alt="Whatsapp"
        />
      </span>
      <span class="media-btn" (click)="buttonClicked('Email')">
        <img
          class="socialIcon"
          src="assets/img/social-icon/Email.svg"
          alt="Email"
        />
      </span>
    </div>
    <button
      class="copy-btn std-mg-bottom-m"
      nxButton
      type="button"
      nxButton="small secondary"
    >
      Copy link
    </button>
  </div>
</div>
