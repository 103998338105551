import { Injectable } from '@angular/core';
import { IBrno } from 'src/app/interfaces/brno';
import { splitBRNO, maxYearValue } from 'src/app/shared/helpers/service.helper'

@Injectable({
  providedIn: 'root'
})
export class BrnoService implements IBrno {

  isBRNOValid(value: string): boolean {
    const [input, year, type, ...rest] = splitBRNO(value);
    const thisYear = new Date();
    const compareYear = thisYear.getFullYear();
    if (maxYearValue(year, compareYear)) return year;
    return false;
  }
}
