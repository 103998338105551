import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map, catchError } from 'rxjs/operators';
import { SET_NOTIFICATION, RESET_NOTIFICATION, SET_CONNECTED, GET_NOTIFICATION_LIST, SEND_READ_NOTIFICATION, RESET_NOTIFICATION_LIST, UPDATE_UNREAD_NOTIFICATION, GET_NOTIFICATION_DETAILS } from 'src/app/store/notification/notification.action';
import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';
import { NotificationListModel, Message } from 'src/app/interfaces/notification';

interface NotificationModel {
    notification: boolean,
    isConnected: boolean,
    message: string,
    notificationList: any,
    disableLoading: boolean,
    countUnreadNotification: number,
}
@State<NotificationModel>({
    name: 'NotificationState',
    defaults: {
        notification: false,
        isConnected: false,
        message: "",
        notificationList: null,
        disableLoading: false,
        countUnreadNotification: null,
    }
})
@Injectable()
export class NotificationState {

    constructor(
        private notificationService: NotificationService,
        private router: Router,
        private store: Store
    ) { }

    @Selector()
    public static disabledLoading(state: NotificationModel) {
        return state.disableLoading;
    }

    @Selector()
    public static getNotificationList(state: NotificationModel) {
        return state.notificationList? state.notificationList: [];
    }

    @Selector()
    public static notification(state: NotificationModel) {
        return state.notification;
    }

    @Selector()
    public static getNotificationMsg(state: NotificationModel) {
        return state.message;
    }

    @Selector()
    public static getConnectionStatus(state: NotificationModel) {
        return state.isConnected;
    }

    @Selector()
    public static countUnreadNotification(state: NotificationModel) {
        return state.countUnreadNotification;
    }


    @Action(SET_NOTIFICATION)
    public setNotification(
        { patchState }: StateContext<NotificationModel>,
        { payload }: SET_NOTIFICATION) {
        patchState({
            notification: payload.notification,
            message: payload.notification ? payload.message : ""
        });
    }

    @Action(SET_CONNECTED)
    public setConnected(
        { patchState }: StateContext<NotificationModel>,
        { payload }: SET_CONNECTED) {
        patchState({ isConnected: payload });

    }

    @Action(RESET_NOTIFICATION)
    public resetNotification(
        { patchState }: StateContext<NotificationModel>) {
        patchState({ notification: false, message: "" });

    }

    @Action(GET_NOTIFICATION_LIST)
    public getNoficationList(
        { patchState, getState, dispatch }: StateContext<NotificationModel>,
        { payload, isStoreList }: GET_NOTIFICATION_LIST
    ) {
        const state = getState();
        console.log("checking01")
        patchState({ disableLoading: true })
        return this.notificationService.notificationList(payload)
            .pipe(
                map(res => {
                    let unreadCount: number = 0;
                    if(res.messages != null){
                        res.messages.map(item=>{
                            item.seenInd == false ? unreadCount++: null
                        })
                    }

                    let data;
                    if (isStoreList) {
                        let list = state.notificationList;
                        let tempList = list.messages
                        if (tempList && tempList.length > 0) {
                            data = {
                                ...data,
                                asOfDate: res.asOfDate,
                                totalPages: res.totalPages,
                                messages: tempList.concat(res.messages)
                            }
                        } else {
                            data = res;
                        }

                    } else {
                        data = []
                    }
                    patchState({
                        notificationList: data,
                        disableLoading: false,
                        countUnreadNotification: res.totalUnseen ? res.totalUnseen : 0
                    })
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    @Action(SEND_READ_NOTIFICATION)
    public sendReadNotif({ patchState }: StateContext<NotificationModel>,
        { payload }: SEND_READ_NOTIFICATION) {
        patchState({ disableLoading: true })
        return this.notificationService.sendReadNotification(payload).pipe(
            map(res => {
                if (res) {
                    this.store.dispatch(new UPDATE_UNREAD_NOTIFICATION(res));
                }
              
                patchState({
                    disableLoading: false
                });
            })
        )
    }


    @Action(RESET_NOTIFICATION_LIST)
    public resetNotificationList(
        { patchState }: StateContext<NotificationModel>,
        { }: RESET_NOTIFICATION_LIST) {
        patchState({
            notificationList: []
        });
    }

    @Action(UPDATE_UNREAD_NOTIFICATION)
    public updateUnreadNotification(
        { patchState, getState }: StateContext<NotificationModel>,
        { payload }: UPDATE_UNREAD_NOTIFICATION) {

        const state = getState();
        let messageList = state.notificationList.messages.slice();
        let docIndex = messageList.findIndex(i => i.id === payload.id);

        messageList[docIndex] = {
            ...messageList[docIndex],
            seenInd: true
        }
        patchState({
            countUnreadNotification: payload.totalUnseen ? payload.totalUnseen : 0
        });
        patchState({
            notificationList: {
                ...state.notificationList,
                messages: messageList
            }
        });
    }
}