<table nxTable cellpadding="0" class="summary_table">
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Where is the vehicle being used?</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(region) | uppercase }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Use of Vehicle</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(vehicleUse) | uppercase }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Vehicle No.</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ data.riskList[0].vehicleNo ? data.riskList[0].vehicleNo : "NA" }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Year of Manufacture</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].vehicleMakeYear) }}</span>
        </td>
    </tr>
    @if(isApproval) {
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Vehicle Age</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.vehicleAge) }}</span>
        </td>
    </tr>
    }
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Make / Model</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].makeModelDesc || data.riskList[0].vehicleModelDescp) }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Capacity / Unit of Measurement</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal"> {{ capacity }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Engine Type</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(engineType) | uppercase }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">No. of Seats</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].vehicleSeat) }}</span>
        </td>
    </tr>
    @if(!isApproval) {
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Engine / Motor No.</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].vehicleEngine) }}</span>
        </td>
    </tr>
    }
    @if(!isApproval) {
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Chassis No.</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].vehicleChassis) }}</span>
        </td>
    </tr>
    }
    <tr nxTableRow>
        <td nxTableCell><span nxCopytext="normal">Loan Type</span></td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(loanType) | uppercase }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Loan Company</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].vehicleLoanCompany) | uppercase }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Vehicle Purchase Date</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(formatDate(data.riskList[0].vehiclePurchaseDate)) }}</span>
        </td>
    </tr>
    @if(data.riskList[0].makeModelPrintInd !== 'N') {
       <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Make / Model to be printed in Quotation / Cover Note</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].recommendDesc) | uppercase }}</span>
        </td>
    </tr> 
    }
</table>