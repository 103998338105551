import { Component } from '@angular/core';
import { NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-delete-payment-mode',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './delete-payment-mode.component.html',
  styleUrl: './delete-payment-mode.component.scss'
})
export class DeletePaymentModeComponent {
  isProceed: boolean;

  constructor(
    private dialogRef: NxModalRef<any>,
  ) {}

  cancel() {
    this.isProceed = false;
    this.onClose();
  }

  proceed() {
    this.isProceed = true;
    this.onClose();
  }

  onClose() {
    this.dialogRef.close(this.isProceed);
  }
}
