import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EnquiryComponent } from '../enquiry.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { GET_ENQUIRY_DETAILS } from '@store/enquiry/enquiry.action';
import { EnquiryPopupComponent } from "../../../popup/enquiry-popup/enquiry-popup.component";
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { GET_LOV, GET_NCD_CONFIRMATION, GET_NCD_ENQUIRY, GET_PRODUCT_LIST, GET_VEHICLE_USE, SEARCH_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { SortEvent, SortDirection } from '@aposin/ng-aquila/table/public-api';
import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";

import {
  DOWNLOAD_PDF
}
  from '@store/sat-motor/sat-motor.action';

import moment from 'moment';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-ncd-enquiry',
  standalone: true,
  imports: [
    EnquiryComponent,
    SharedModule,
    CrossSellBannerComponent,
    NxMomentDateModule
  ],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }],
  templateUrl: './ncd-enquiry.component.html',
  styleUrl: './ncd-enquiry.component.scss'
})
export class NcdEnquiryComponent implements OnInit {
  @Select(SatMotorState.commonLov) commonLov$;
  componentDialogRef1?: NxModalRef<EnquiryPopupComponent>;
  jpjIsmForm: FormGroup;
  enquiryDetail: any;
  searchNcdEnquiryForm: FormGroup;
  searchModelResult: any;
  formattedCurrentDate: any;
  firstDateOfMonth: any;
  noResultFoundMessage: any;
  lov: any;
  idTypeList: any;

  productTypeList = [];
  useOfVehicleList = [];

  @ViewChild("templateEnHistory") templateRef5!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  @ViewChild("templateOne") templateRef!: TemplateRef<any>;
  showRef(template: TemplateRef<any>, type: any) {
    this.templateRef! = template;
    this.title = type;
  }

  title = "Private Cars Excluding Goods";

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    this.store.dispatch(new GET_LOV()).subscribe(() => {
      this.store.dispatch(new GET_PRODUCT_LIST({ lob: 'MT', agentCode: "KL43193" })).subscribe((state) => {
        this.productTypeList = state.SatMotorState.lov.productList;
        this.lov = state.SatMotorState.lov;
        this.getLov();
      });
    });

    this.searchNcdEnquiryForm = this.fb.group({
      productType: ['', Validators.required],
      vehicleUse: [{ value: "", disabled: true }, [Validators.required]],
      vehicleNo: ['', [Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{1,20}$')]],
      chassisNo: [''],
      idType: ['', Validators.required],
      idNo1: ['', Validators.required]
    });


  }

  ngAfterViewInit() {

  }

  onSearchClicked() {

    // Check the values

    let paramValue = {
      "productType": this.searchNcdEnquiryForm.getRawValue()["productType"],
      "vehicleUse": this.searchNcdEnquiryForm.getRawValue()["vehicleUse"],
      "vehicleNo": this.searchNcdEnquiryForm.getRawValue()["vehicleNo"],
      "previousNcdVehicle": this.searchNcdEnquiryForm.getRawValue()["vehicleNo"],
      "chassisNo": this.searchNcdEnquiryForm.controls["chassisNo"].value,
      "idType": this.searchNcdEnquiryForm.controls["idType"].value,
      "idNo1": this.searchNcdEnquiryForm.controls["idNo1"].value
      //"coverCode": "01",
    };

    console.log('Form values:', this.searchNcdEnquiryForm.getRawValue());
    if (this.searchNcdEnquiryForm.valid) {
      this.store.dispatch(new GET_NCD_ENQUIRY(paramValue)).subscribe((res) => {
        this.searchModelResult = res.SatMotorState.ncdEnquiryDetails;
        if (this.searchModelResult?.length < 0) {
          this.noResultFoundMessage = "No Result Found";
        }
        //this.searchModelResult = this.mockResult;
      });
    } else {
      this.searchNcdEnquiryForm.markAllAsTouched();
    }

  }

  resetForm() {
    //this.setDefaultDate();
    this.searchNcdEnquiryForm.reset();
    this.searchModelResult = "";
    this.noResultFoundMessage = "";
  }

  getLov() {
    this.prepareIdTypeLov(this.lov);
  }

  prepareIdTypeLov(lov: any) {
    const individualTypes = ['NRIC', 'OLD_IC', 'PASS', 'POL'];

    const filteredList = lov?.clientTypeList.reduce((acc, item) => {
      if (item.code === 'P') {
        const nricItem = item.subList.find(sub => sub.code === 'NRIC');
        if (nricItem) acc.push({ code: nricItem.code, descp: 'NRIC' });

        const combinedItem = item.subList.filter(sub => individualTypes.includes(sub.code) && sub.code !== 'NRIC');
        if (combinedItem.length > 0) {
          acc.push({ code: 'OLD_IC/POL/PASS', descp: 'OLD IC / POLICE ID / ARMY ID / PASSPORT NO.' });
        }
      } else if (item.code === 'I') {
        const newBrItem = item.subList.find(sub => sub.code === 'NEW_BR_NO');
        if (newBrItem) acc.push({ code: newBrItem.code, descp: 'NEW BUSINESS REGISTRATION NO.' });

        const brItem = item.subList.find(sub => sub.code === 'BR_NO');
        if (brItem) acc.push({ code: brItem.code, descp: 'BUSINESS REGISTRATION NO.' });
      }
      return acc;
    }, []) || [];

    this.idTypeList = filteredList;
  }

  onChangeValue(code: any) {
    this.store.dispatch(new GET_VEHICLE_USE({ productCode: code.value })).subscribe((state) => {
      this.useOfVehicleList = this.store.selectSnapshot<any>((vehicleState) => vehicleState.SatMotorState.vehicleUseLov);
      this.searchNcdEnquiryForm.controls['vehicleUse'].enable();
    });
    
  }

}
