import { Component, Input, OnInit } from '@angular/core';
import { NxDialogService } from '@aposin/ng-aquila/modal';
import { FormBuilder } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from "../../shared/shared.module";


@Component({
  selector: 'base-enquiry',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './enquiry.component.html',
  styleUrl: './enquiry.component.scss'
})
export class EnquiryComponent implements OnInit { 

  @Input() type: any;
  @Input() viewProductTitle: any;
  @Input() popupMessage: any;
  @Input() referralReasonList: any;
  @Input() remarksSuccessInd: boolean;
  
  title: any;

  enquiryTypes = [
    { code: "view-policy-enquiry", title: "Policy Enquiry" },
    { code: "view-no-claim-discount-enquiry", title: "No Claim Discount Enquiry" },
    { code: "view-ncd-confirmation-enquiry", title: "NCD Confirmation Enquiry" },
    { code: "view-motor-approval-enquiry", title: "Private Cars Excluding Goods" },
    { code: "view-summary-enquiry", title: "Summary Enquiry" },
    { code: "view-claim-enquiry", title: "Claim Enquiry" },
    { code: "view-endorsement-enquiry", title: "Private Cars Excluding Goods" }
  ];

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {

    this.title = this.getTitleByType(this.type); 
  }

  getTitleByType(type) {
    if (type === 'view-endorsement-enquiry') {
      return this.viewProductTitle;
    } else {
      const enquiry = this.enquiryTypes.find(enquiry => enquiry.code === type);
      return enquiry ? enquiry.title : 'Error Title Missing!';
    }
  }
}
