import { Component, Inject, EventEmitter, Output, ElementRef, ViewChild } from "@angular/core";
import { SelectionModel } from '@angular/cdk/collections';
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
import { SharedModule } from "@shared/shared.module";
import { Subject, debounceTime, takeUntil } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-extra-coverage-popup',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './extra-coverage-popup.component.html',
  styleUrl: './extra-coverage-popup.component.scss'
})
export class CoveragePopupComponent {

  @Output() isSelected = new EventEmitter();
  @Output() selectedItem = new EventEmitter();
  @ViewChild('tableHeader', { static: false }) tableHeader: ElementRef<HTMLTableHeaderCellElement>;

  templateDialogRef?: NxModalRef<any>;
  popup_type: any;
  selectedExtraCoverList: any;
  originalExtraCoverList: any;
  packageCode: any;
  selection: any;
  curpage: number = 1;
  perPage: number = 5;
  itemsPerPageSelections = [5, 10, 20, 50, 100];
  unsubscribe$ = new Subject();
  pageFilterForm: FormGroup;

  constructor(@Inject(NX_MODAL_DATA) data: any, private componentDialogRef: NxModalRef<any>, private fb: FormBuilder,) {
    console.log("data", data.popup_type);
    this.popup_type = data.popup_type;
    this.selectedExtraCoverList = data.selectedExtraCoverList;
    this.originalExtraCoverList = data.originalExtraCoverList;
    this.packageCode = data.packageCode;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(true, []);
    this.originalExtraCoverList = this.originalExtraCoverList.filter(o1 => !this.selectedExtraCoverList.some(o2 => o1.subCoverCode === o2.subCoverCode) &&
      (this.packageCode === undefined || this.packageCode === '' || o1.subCovPackage !== this.packageCode) && o1.top == false
    );
    console.log("this.originalExtraCoverList : " + this.originalExtraCoverList);

    this.pageFilterForm = this.fb.group({
      perPage: [this.perPage],
      selectAllExtraCover: [false]
    });

    this.setItemPerPage();
  }

  select(item: any) {
    console.log("item : " + item);
  }

  emitData(item: any, code: any) {
     this.isSelected.emit(item);
    this.selectedItem.emit(this.selection.selected);
    
  
    this.originalExtraCoverList = this.originalExtraCoverList.map(currentItem => {
      // Check if item.subCoverCode is 'PAB-RW'
      if (code === 'PAB-RW' && currentItem.subCoverCode === 'PAB-ERW') {
        if(this.selection.isSelected(item)){
          currentItem.enabled = false;
        }else{
          currentItem.enabled = true;
        }
          // Disable PAB-ERW
      } else if (code === 'PAB-ERW' && currentItem.subCoverCode === 'PAB-RW') {
        if(this.selection.isSelected(item)){
          currentItem.enabled = false;
        }else{
          currentItem.enabled = true;
        }
      }
  
      return currentItem;
    });
  }
  

  get paginatedResults() {
    const startIndex = (this.curpage - 1) * this.perPage;
    return this.originalExtraCoverList.slice(startIndex, startIndex + this.perPage);
  }

  proceedToClose() {
    if (this.selectedItem) {
      //this.componentDialogRef.close({ selection: this.selectedItem });
      this.componentDialogRef.close({ selection: this.selection.selected });
    }
  }

  selectItem(item) {
    this.selection.toggle(item.subCoverCode)
    this.emitData(item, item.subCoverCode)
  }

  prevPage() {
    this.curpage--;
    this.scrollIntoView();
  }

  nextPage() {
    this.curpage++;
    this.scrollIntoView();
  }

  goToPage(n: number) {
    this.curpage = n;
    this.scrollIntoView();
  }

  scrollIntoView() {
    this.tableHeader.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  setItemPerPage() {
    this.pageFilterForm.get('perPage').valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.unsubscribe$)
    ).subscribe((value) => {
      this.perPage = value;
      this.curpage = 1;
      this.scrollIntoView();
    });
  }

  getResultLength() {
    if (this.originalExtraCoverList) {
      return this.originalExtraCoverList.length;
    }
    return 0;
  }

  checkedAllChange() {
    const isChecked = this.pageFilterForm.controls['selectAllExtraCover'].value;
    if (isChecked) {
      this.originalExtraCoverList.forEach(item => {
        if (item.subCoverCode !== 'PAB-ERW') {
          if (!this.selection.isSelected(item) && item.enabled) {
            this.selection.select(item);
          }
        } else {
          item.enabled = false;
        }
      });
      this.perPage = 100;

    } else {
      this.selection.clear();
      this.perPage = 5;
    }
  }

}
