<!-- Revert to previous version due to new version have problem  -->

<!-- <nx-multi-stepper  [direction]="direction" linear>
    <nx-step class="steps" [attr.data-current-step]="currentStep" [attr.data-total-steps]="steps.length">
        <li
            *ngFor="let step of steps; let i = index;"
            class="step default-cursor"
            [class.active--]="isCurrentStep(i)"
            [class.bold--]="isBold(i)"
            [ngClass]="{'pointer-cursor': currentStep > i && !step.disable}"
            (click)="getNumber(i,step)"
    
        >
            <span class="step__dot">
                <nx-icon name="check"></nx-icon>
            </span>

            {{ step.label | translate }}
        </li>
    </nx-step>
</nx-multi-stepper>

 <hr class="nx-margin-y-2m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">  -->

<!-- Old Version -->
<!-- <ol
  class="steps"
  [attr.data-current-step]="currentStep"
  [attr.data-total-steps]="steps.length"
>
  <li
    *ngFor="let step of steps; let i = index"
    class="step default-cursor"
    [class.active--]="isCurrentStep(i)"
    [class.bold--]="isBold(i)"
    [ngClass]="{ 'pointer-cursor': currentStep > i && !step.disable }"
    (click)="getNumber(i, step)"
  >
    <span class="step__dot">
      <nx-icon name="check"></nx-icon>
    </span>

    {{ step.label }}
  </li>
</ol> -->

<div class="nx-margin-bottom-2m">
    <div class="progress_bar">
      <aside>
        <ol
          class="steps"
          [attr.data-current-step]="currentStep"
          [attr.data-total-steps]="steps.length"
        >
          <li
            *ngFor="let step of steps; let i = index"
            class="step default-cursor"
            [class.active--]="isCurrentStep(i)"
            [class.bold--]="isBold(i)"
            [ngClass]="{ 'pointer-cursor': currentStep > i && !step.disable }"
            
          >
            <span class="step__dot">
              <nx-icon name="check"></nx-icon>
            </span>
  
            {{ step.label }}
          </li>
        </ol>
      </aside>
    </div>
  </div>
  
  <!-- Desktop -->
  <!-- 
  <nx-multi-stepper  [direction]="direction" [linear]="true" class="nx-hidden-m nx-hidden-s nx-hidden-xs">
      <ng-container  *ngFor="let step of steps; let i = index;"
      class="step default-cursor"
      (click)="getNumber(i,step)">
          <nx-step [label]="step.label | translate"></nx-step>
          
      </ng-container>
  </nx-multi-stepper> -->
  
  <!-- Tablet / Mobile -->
  
  <!-- <div nxLayout="grid nogutters" class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
      <div nxRow class="coverage-blue-panel-title">
          <div nxCol="4,4,2,1">
              <div class="cicrle_step">
                  <p class="circle_step_content">1 of 3</p>
              </div>
          </div>
          <div nxCol="8,8,10,11">
              <h2 class="nx-margin-0 nx-font-weight-bold">Customer Partner</h2>
              <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-regular nx-margin-0">Next: Product Selection</h4>
          </div>
      </div>
  </div>
  
  <hr class="nx-margin-y-2m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"> -->
  