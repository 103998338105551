import { Component, Inject, EventEmitter, Output } from "@angular/core";
import { SelectionModel } from '@angular/cdk/collections';
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
import { SharedModule } from "../../shared/shared.module";

@Component({
  selector: 'app-coverage-popup',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './coverage-popup.component.html',
  styleUrl: './coverage-popup.component.scss'
})
export class CoveragePopupComponent {

  @Output() isSelected = new EventEmitter();
  @Output() selectedItem = new EventEmitter();

  // productList = [
  //   { code: '089337', name: 'PRIVATE CARS 1' },
  //   { code: '089338', name: 'PRIVATE CARS 2' },
  //   { code: '089339', name: 'PRIVATE CARS 3' },
  //   { code: '089340', name: 'MOTOR 1' },
  //   { code: '089341', name: 'MOTOR 2' }
  // ];

  templateDialogRef?: NxModalRef<any>;
  popup_type: any;
  selectedExtraCoverList: any;
  originalExtraCoverList: any;
  selection: any;
  constructor(@Inject(NX_MODAL_DATA) data: any, private componentDialogRef: NxModalRef<any>) {
    console.log("data", data.popup_type);
    this.popup_type = data.popup_type;
    this.selectedExtraCoverList = data.selectedExtraCoverList;
    this.originalExtraCoverList = data.originalExtraCoverList;
  }

  ngOnInit() {
    this.selection = new SelectionModel<any>(true, []);
    this.originalExtraCoverList = this.originalExtraCoverList.filter(o1 => !this.selectedExtraCoverList.some(o2 => o1.subCoverCode === o2.subCoverCode || o1.top == true));
    console.log("this.originalExtraCoverList : " + this.originalExtraCoverList);
  }

  select(item: any) {
    console.log("item : " + item);
  }

  emitData(item: any, code: any) {
    this.isSelected.emit(true);
    this.selectedItem.emit(this.selection.selected)
  }

  proceedToClose() {
    if (this.selectedItem) {
      //this.componentDialogRef.close({ selection: this.selectedItem });
      this.componentDialogRef.close({ selection: this.selection.selected });
    }
  }

  selectItem(item) {
    this.selection.toggle(item.subCoverCode)
    this.emitData(item, item.subCoverCode)
  }

}
