import {
  ChangeDetectorRef,
  Component,
  importProvidersFrom,
  Input,
  ViewChild,
} from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SharedModule } from "../../../../shared/shared.module";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PdpaPopupComponent } from "../../../../popup/pdpa-popup/pdpa-popup.component";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { STORE_DECLARATION, SEARCH_ACTIVE_QUOTATION, GET_PREVIOUS_POLICY_NO, GET_LOV, GET_PRODUCT_LIST, SEARCH_VIX, ROADTAX_SEARCH, SEARCH_ENDT_VEHICLE, COPY_CNGE_INFO, COPY_CNGE_INFO_RENEWAL, RESET_MOTOR_STATE, RESET_PREVIOS_POLICY_DETAILS, STORE_STEP2_CP_SCREEN, GET_CUSTOMER_PARTNER, STORE_STEP2_SEARCH, SHOW_SEARCH_COMPONENT_2, GET_POSTCODE_LOV, CALCULATE_PREMIUM, VALIDATE_POI_DATE, STORE_PREMIUM_CALCULATION_PAYLOAD, RESET_SUBSEQUENT_PAGE_DATA, SET_RETRIEVE_QUOTATION, ENDORSEMENT_TYPE_VALIDATION, UPDATE_ENDORSEMENT_STEP2, SET_PRODUCT_CODE_DESCRIPTION, STORE_AGENT_ADDITIONAL, CANCEL_DRAFT, GET_SWITCH_SITE, DELETE_COVERAGE, ACTIVE_SELECTION_POPUP, GET_NCD_ENQUIRY } from '@store/sat-motor/sat-motor.action';
import { IssueEndorsementValidationComponent } from "src/app/views/motor/endorsement/issue-endorsement-validation/issue-endorsement-validation.component";
import { EnquiryPopupComponent } from "../../../../popup/enquiry-popup/enquiry-popup.component";
import { PdpaComponent } from "@shared/components/declaration-issuance/pdpa/pdpa.component";
import { UserResponse } from "@interfaces/user";
import { OkMessageComponent } from "@shared/components/modal/ok-message/ok-message.component";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { PreviousPolicyErrorPopupComponent } from "@shared/components/modal/previous-policy-error-popup/previous-policy-error-popup.component";
import { ScrollToError } from "@shared/classes/scroll-to-error";
import { CLIENTTYPE, IDTYPE, COUNTRIES } from "@shared/enums/index.enum";
import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";
import { DatePipe } from "@angular/common";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import moment from "moment";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { BACK_TO_ALPHA, REDIRECT_ALPHA_CCENTRIC } from "@store/user/user.action";
import { ConfirmationModalComponent } from "@shared/components/modal/confirmation-modal/confirmation-modal.component";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { CpMandatoryFilledService } from "@shared/services/cp-mandatory-filled/cp-mandatory-filled.service";
import { NricService } from '@services/nric/nric.service';
import { SummaryPopupComponent } from "src/app/popup/summary-popup/summary-popup.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-shared-issuance',
  standalone: true,
  imports: [
    SharedModule,
    ProgressBarComponent,
    IssueEndorsementValidationComponent,
    PdpaComponent,
    NxMomentDateModule
  ],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }, DatePipe],
  templateUrl: './issuance.component.html',
  styleUrl: './issuance.component.scss'
})
export class IssuanceComponent {

  @Select(SatMotorState.motorAgentDetail) agentDetail$;
  @Select(SatMotorState.motorStep1Issuance) issuanceData$;
  @Select(SatMotorState.endtStep1Issuance) endtIssuanceData$;
  @Select(SatMotorState.endtVehiclePolicy) endtVehicleData$;
  @Select(SatMotorState.commonLov) commonLov$;
  @Select(SatMotorState.motorActionType) actionType$;

  @Input() isIssuance: boolean = false;
  @Input() isEndorsement: boolean = false;
  @Input() isEnquiry: boolean = false;
  @Input() isRTP: boolean = false;

  dialogRef?: NxModalRef<any>;
  componentDialogRef1?: NxModalRef<EnquiryPopupComponent>;
  componentDialogRef?: NxModalRef<PdpaPopupComponent>;
  componentDialogRefs?: NxModalRef<SummaryPopupComponent>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  openPreviousPolicyError?: NxModalRef<PreviousPolicyErrorPopupComponent>;
  confirmMessageModal?: NxModalRef<ConfirmationModalComponent>;

  popup_type: any;
  cn_quotation = true;
  isIndividual = true;
  agentData: any = {};
  previousPolicyNo: any;
  policyNo: any
  coverNoteTypeList: any;
  idTypeList: any;
  lov: any;
  productList: any;
  filteredProductList; any;
  endorsementList: any;
  isRTPDataExist: boolean = false;
  userInfo: any;
  endtPolicy: any;
  endtVehicleFound: boolean = false;
  previousExtension: boolean = false;
  showSpecialVehicle = false;
  isVehicleIdNoExist: boolean = false;
  previousPolicyDetails: any;
  activeQuotationDetails: any;
  issuanceInfo: any;
  updateQuotation: any;
  isRenewal: boolean = false;
  isActiveQuotation: boolean = false;
  isSingleAgent: boolean = false;
  cpDetails: any = {};
  isCompOrCustInfoIncomplete: boolean = false;
  isContactInfoIncomplete: boolean = false;
  isCorrespondenceInfoIncomplete: boolean = false;
  showDateValidateError: boolean = false;
  showLapsedError: boolean = false;
  isCommercial: boolean = false;
  dateValidateError: any;
  issuanceStateData: any;
  queryParams: any;
  operator: any;
  endtPreviousPolicy: any;
  endtIssuanceData: any;
  cCentricInfo: any;
  clientDetails: any;
  isEffectiveDateValidation: boolean = false;
  isRequote: boolean = false;
  onlyNumber: boolean = false;
  isSaved: any;
  lovproductConfig:any;
  productAccessListData: any;
  lovPC : any;
  lovGeo: any;
  emailCheck : any;
  custAge : any;

  endorsement = true; ///////for functional IssueEndorsementValidationComponent

  pdpaForm: FormGroup;
  public issuanceForm: FormGroup;

  idTypeProductMap = {
    "NRIC": ["080100", "082100", "082101"],
    "OLD_IC": ["080100", "082100", "082101"],
    "POL": ["080100", "082100", "082101"],
    "PASS": ["080100", "082100", "082101"],
    "NEW_BR_NO": ["080200", "080300", "082100", "082101"],
    "BR_NO": ["080200", "080300", "082100", "082101"]
  };

  idTypeConfig = {
    NRIC: {
      clientAddressType: COUNTRIES.MALAYSIAN,
      clientType: CLIENTTYPE.INDIVIDUAL
    },
    OLD_IC: {
      clientAddressType: COUNTRIES.MALAYSIAN,
      clientType: CLIENTTYPE.INDIVIDUAL
    },
    POL: {
      clientAddressType: COUNTRIES.MALAYSIAN,
      clientType: CLIENTTYPE.INDIVIDUAL
    },
    PASS: {
      clientAddressType: COUNTRIES.NON_MALAYSIAN,
      clientType: CLIENTTYPE.INDIVIDUAL
    },
    NEW_BR_NO: {
      clientAddressType: "",
      clientType: CLIENTTYPE.COMPANY
    },
    BR_NO: {
      clientAddressType: "",
      clientType: CLIENTTYPE.COMPANY
    }
  };

  constructor(
    private dialogService: NxDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private cpMandatoryFilledService: CpMandatoryFilledService,
    private nricService: NricService,

  ) {
    this.pdpaForm = this.fb.group({
      pdpaCheck: new FormControl(false),
      consentDetails: this.fb.group({
        edocConsentInd: '',
        einvoiceConsentInd: ''
      })
    });
  }

  ngOnInit() {

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

    this.isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
    this.queryParams = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.operator = this.store.selectSnapshot(state => state.UserState.user.userId);
    this.isCommercial = this.store.selectSnapshot<any>((state) => state.SatMotorState.opportunityId);
    this.clientDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step2);
    this.isRequote = this.store.selectSnapshot<any>((state) => state.SatMotorState.requoteQuotation);
    this.isSaved = this.store.selectSnapshot<any>((state) => state.SatMotorState?.draftDetails);

    this.commonLov$.subscribe((state) => {
      if (state) {
        this.lov = state;
        this.productList = state.productList;
        this.endorsementList = state.endorsementList;
      }
    });

    this.lovproductConfig = this.lov.productAccessConfigList;
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step1?.issuance?.issuance);
    this.updateQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState?.updateQuotation);
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.previousPolicyDetails = this.store.selectSnapshot<UserResponse>(state => state.SatMotorState.previousPolicyDetails);
    this.activeQuotationDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step1?.activeQuotation);
    if (this.activeQuotationDetails) {
      this.isActiveQuotation = true;
      if (this.activeQuotationDetails?.cngeNote?.cnoteType == 'RN') {
        this.isRenewal = true;
      }
    }

    if (this.isEndorsement) {
      this.loadEndorsement();
    }

    if (this.isIssuance) {
      this.loadIssuance();
    }

    if (this.isRTP) {
      this.loadRTP();
    }

    this.issuanceForm.get('productCode').valueChanges.subscribe(code => {
      this.updateProductDesc(code);
    });

    if (this.previousPolicyDetails?.previousPolicyNo) {
      this.loadPreviousPolicyDetails();
      this.isRenewal = true;
    }

    //Renew from alpha portal
    if(this.queryParams.renewNow && this.queryParams.policyNo){
      const param = {
        "policyNo": this.queryParams.policyNo,
        "agentCode": this.agentData.agentCode
      };
      this.searchPreviousPolicy(param);
    }

    if(this.isCommercial){
      this.loadCCentricDetails();
    }

  }

  updateProductDesc(code: any) {
    try {
      if (code === null || code === undefined) {
        console.log('Product code is null');
        this.store.dispatch(new SET_PRODUCT_CODE_DESCRIPTION({ descp: null }));
        return;
      }

      const product = this.productList.find(item => item.code === code);

      if (!product) {
        console.log('Product not found');
        this.store.dispatch(new SET_PRODUCT_CODE_DESCRIPTION({ descp: null }));
        return;
      }

      const productDescp = product.descp ? product.descp : null;
      this.store.dispatch(new SET_PRODUCT_CODE_DESCRIPTION({ descp: productDescp }));
    } catch (error) {
      console.log('Error updating product description:', error.message);
      this.store.dispatch(new SET_PRODUCT_CODE_DESCRIPTION({ descp: null }));
    }
  }

  loadPreviousPolicyDetails() {
    this.previousPolicyNo = this.previousPolicyDetails.previousPolicyNo;
    this.issuanceForm.patchValue({
      vehicleNo: this.previousPolicyDetails.vehicleNo,
      productCode: this.previousPolicyDetails.productCode,
      prevPolicyNo: this.previousPolicyDetails.previousPolicyNo,
      effectiveDate: this.previousPolicyDetails.effectiveDate,
      expiryDate: this.previousPolicyDetails.expiryDate,
      idNumberText: this.previousPolicyDetails.clientPartner.idValue1
    });
    this.isVehicleIdNoExist = true;
  }

  loadCCentricDetails() {
    this.cCentricInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.cCentricInfo);
    if(this.cCentricInfo){
      const today = new Date();
      const localDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
      this.isVehicleIdNoExist = true;
  
      this.issuanceForm.patchValue({
        vehicleNo: this.cCentricInfo.vehicleNo,
        idType: this.cCentricInfo.cngeNoteDto.clientPartner.idType1,
        idNumberText: this.cCentricInfo.cngeNoteDto.clientPartner.idValue1,
        effectiveDate: localDate,
        productCode: this.cCentricInfo.productCode? this.cCentricInfo.productCode :'080100'
      });
    }
    
  }

  loadRTP() {
    this.coverNoteTypeList = [
      {
        "code": "RTP",
        "descp": "ROAD TAX PURPOSE"
      }
    ];

    this.issuanceForm = new FormGroup({
      cnoteType: new FormControl({ value: '', disabled: true }),
      vehicleNo: new FormControl('', [
        Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d-]{1,20}$')
      ]),
      prevPolicyNo: new FormControl({ value: '', disabled: true }),
      productCode: new FormControl({ value: '', disabled: true }),
      specialVehicleInd: new FormControl({ value: false, disabled: true }),
      effectiveDate: new FormControl({ value: '', disabled: true }),
      expiryDate: new FormControl({ value: '', disabled: true })
    });

    this.issuanceForm.controls['cnoteType'].setValue('RTP');
    this.loadIssuanceData();
  }

  loadEndorsement() {
    this.issuanceForm = new FormGroup({
      vehicleNo: new FormControl('', [
        Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d-]{1,20}$')
      ]),
      policyNo: new FormControl({ value: '', disabled: true }),
      productCode: new FormControl({ value: '', disabled: true }),
      policyEffectiveDate: new FormControl({ value: '', disabled: true }),
      policyExpiryDate: new FormControl({ value: '', disabled: true }),
      endorsementType: new FormControl('', Validators.required),
      endtEffectiveDate: new FormControl(''),
      endtExpiryDate: new FormControl(''),
      previousExtension: new FormControl('')
    });

    this.updateEndtWatcher();
    this.getLov();
    this.loadAgentData();
    this.loadEndorsementData();
  }

  loadIssuance() {
    this.issuanceForm = new FormGroup({
      cnoteType: new FormControl('', Validators.required),
      vehicleNo: new FormControl('', [
        Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d-]{1,20}$')
      ]),
      idType: new FormControl('', Validators.required),
      idNumberText: new FormControl('', Validators.required),
      prevPolicyNo: new FormControl(''),
      productCode: new FormControl('', Validators.required),
      specialVehicleInd: new FormControl(false),
      effectiveDate: new FormControl('', Validators.required),
      expiryDate: new FormControl('', [
        Validators.required,
        this.expiryDateValidator()
      ])
    });

    this.updateWatcher();
    this.getLov();
    this.loadAgentData();
    this.loadIssuanceData();
    this.loadQueryParamData();

    if (!this.issuanceForm.controls['cnoteType'].value || this.issuanceForm.controls['cnoteType'].value.trim() === '') {
      this.issuanceForm.controls['cnoteType'].setValue('NWOO/RN');
      this.issuanceForm.controls['idType'].setValue('NRIC');
    }

    let isCoverageStepExist: boolean = !!this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step3);
    let isCpExtracted: boolean = !!this.store.selectSnapshot<any>(state => {
      const cpType = state.SatMotorState?.data?.step2?.cpType;
      return cpType && cpType !== 'NOT_FOUND';
    });

    if (isCoverageStepExist || isCpExtracted) {
      if (this.verifyCnoteType() === 'NWOO') {
        const fieldsToDisable = ['cnoteType', 'vehicleNo', 'idType', 'idNumberText', 'productCode'];
        fieldsToDisable.forEach(field => this.issuanceForm.controls[field].disable());

        this.isVehicleIdNoExist = true;
        const { idNumberText, productCode, effectiveDate, expiryDate } = this.issuanceStateData;
        this.issuanceForm.patchValue({ idNumberText, productCode, effectiveDate, expiryDate });
      }

      if (this.verifyCnoteType() === 'NWNN') {
        const fieldsToDisable = ['cnoteType', 'productCode'];
        fieldsToDisable.forEach(field => this.issuanceForm.controls[field].disable());

        this.isVehicleIdNoExist = true;
        const { productCode } = this.issuanceStateData;
        this.issuanceForm.patchValue({ productCode });
      }

      if (this.verifyCnoteType() === 'NWTR') {
        const fieldsToDisable = ['cnoteType', 'productCode'];
        fieldsToDisable.forEach(field => this.issuanceForm.controls[field].disable());

        this.isVehicleIdNoExist = true;
        const { productCode, effectiveDate, expiryDate } = this.issuanceStateData;
        this.issuanceForm.patchValue({ productCode, effectiveDate, expiryDate });
      }
    }
  }

  loadEndorsementData() {
    this.endtIssuanceData$.subscribe((state) => {
      if (state) {
        if (state.issuance) {
          this.issuanceForm.patchValue(state.issuance);
          this.endtIssuanceData = state.issuance;
        }
        this.issuanceForm.controls['vehicleNo'].disable();

        if (state.pdpa) {
          this.pdpaForm.patchValue(state.pdpa);
        }
      }
    });

    this.endtVehicleData$.subscribe((state) => {
      this.endtVehicleFound = !!state;
      this.endtPolicy = state || {};

      if (!state) {
        const previousExtensionControl = this.issuanceForm.get('previousExtension');
        previousExtensionControl.clearValidators();
        previousExtensionControl.updateValueAndValidity();
        this.previousExtension = false;

        this.issuanceForm.reset({
          vehicleNo: '',
          policyNo: '',
          productCode: '',
          policyEffectiveDate: '',
          policyExpiryDate: '',
          endorsementType: '',
          endtEffectiveDate: '',
          endtExpiryDate: '',
          previousExtension: ''
        });
      }

      if (state && this.endtPolicy) {
        this.populateEndtFormData(this.endtIssuanceData);
        this.endtPreviousPolicy = this.store.selectSnapshot(SatMotorState.endtPreviousVehiclePolicy);
      }

      this.pdpaForm.get('consentDetails.einvoiceConsentInd').disable();
    });

  }

  populateEndtFormData(issuanceData) {
    this.issuanceForm.setValue({
      vehicleNo: this.endtPolicy?.riskList[0]?.vehicleNo || '',
      policyNo: this.endtPolicy?.policyNo || '',
      productCode: this.endtPolicy?.productCode || '',
      policyEffectiveDate: this.endtPolicy?.effectiveDate || '',
      policyExpiryDate: this.endtPolicy?.expiryDate || '',
      endorsementType: issuanceData?.endorsementType || '',
      endtEffectiveDate: issuanceData?.endtEffectiveDate || '',
      endtExpiryDate: issuanceData?.endtExpiryDate || '',
      previousExtension: issuanceData?.previousExtension || ''
    });

    if (issuanceData?.endorsementType === 'ENDT04') {
      const previousExtensionControl = this.issuanceForm.get('previousExtension');
      this.previousExtension = true;
      previousExtensionControl.setValidators([Validators.required]);
      previousExtensionControl.updateValueAndValidity();
    }

    if (typeof this.endtPolicy?.einvoiceConsentInd === 'boolean') {
      this.pdpaForm.get('consentDetails.einvoiceConsentInd').setValue(this.endtPolicy.einvoiceConsentInd);
    }

    this.issuanceForm.controls['vehicleNo'].disable();

    Object.keys(this.issuanceForm.controls).forEach(key => {
      this.issuanceForm.controls[key].setErrors(null);
      this.issuanceForm.controls[key].markAsPristine();
      this.issuanceForm.controls[key].markAsUntouched();
      this.issuanceForm.controls[key].updateValueAndValidity();
    });
  }

  onEndorsementTypeChange(event: any): void {
    const previousExtensionControl = this.issuanceForm.get('previousExtension');
    const endtEffectiveDateControl = this.issuanceForm.get('endtEffectiveDate');
    const endtExpiryDateControl = this.issuanceForm.get('endtExpiryDate');

    if (event.value === 'ENDT04') {
      previousExtensionControl.setValidators([Validators.required]);
      this.previousExtension = true;
    } else {
      previousExtensionControl.clearValidators();
      previousExtensionControl.setValue(null);
      previousExtensionControl.setErrors(null);
      previousExtensionControl.markAsPristine();
      previousExtensionControl.markAsUntouched();
      this.previousExtension = false;
    }
    previousExtensionControl.updateValueAndValidity();

    if (event.value && event.value !== '') {
      this.updateEndtDateValidations(event.value);
    }

    [endtEffectiveDateControl, endtExpiryDateControl].forEach(control => {
      control.setValidators([Validators.required]);

      if (control.value === null || control.value === undefined) {
        this.resetFields(['endtExpiryDate']);
      } else {
        control.updateValueAndValidity();
      }
      
    });    

    this.validateEndorsementType(event.value, this.endtPolicy, this.endtPreviousPolicy);
    this.store.dispatch(new UPDATE_ENDORSEMENT_STEP2({isReset: true}));
  }

  validateEndorsementType(endtType, data, htgePolData) {
    let riskList = data.riskList[0];
    let coverList = riskList.coverList[0];

    let cnoteType = '';
    let endtCode = '';
  
    if (endtType === 'COE' || endtType === 'XP') {
      cnoteType = endtType;
      endtCode = endtType;
    } else if (endtType.startsWith('ENDT')) {
      cnoteType = endtType;
      endtCode = endtType.substring(4);
    } else if (endtType.startsWith('EC')) {
      cnoteType = endtType;
      endtCode = endtType.substring(2);
    }

    let payload = {
      vehicleNo: riskList.vehicleNo,
      cngeNoteDto: {
        cnoteType: cnoteType,
        cnoteDate: data.cnoteDate,
        endtCode: endtCode,
        policyNo: data.policyNo,
        productCode: data.productCode,
        einvoiceConsentInd: data.einvoiceConsentInd,
        mkagAgent: {
          agentCode: data.mkagAgent.agentCode
        },
        riskList: [
          {
            vehicleModel: riskList.vehicleModel,
            vehModelNvic: riskList.vehModelNvic,
            coverList: [
              {
                coverCode: coverList.coverCode
              }
            ]
          }
        ]
      },
      htgePol: {
        ncdPercentage: htgePolData?.ncdPercentage ?? 0,
        einvoiceStatus: htgePolData?.einvoiceStatus,
        ...htgePolData
      }
    };
    
    this.store.dispatch(new ENDORSEMENT_TYPE_VALIDATION(payload)).subscribe((res) => {
      let response = (res && res.SatMotorState?.endorsement?.step1?.endtTypeResponse) ?? null;

      if (response && response.success) {

      } else {
        this.resetInvalidatedFields(['endorsementType', 'endtEffectiveDate', 'endtExpiryDate']);
        if (response.message && response.message != '') {
          this.showOkMessage(response.message);
        }
      }

      if (endtType && endtType === 'EC176' && response && response.success) {
        this.pdpaForm.get('consentDetails.einvoiceConsentInd').setValue(true);

      } else if (typeof this.endtPolicy?.einvoiceConsentInd === 'boolean' && response && response.success) {
        this.pdpaForm.get('consentDetails.einvoiceConsentInd').setValue(this.endtPolicy.einvoiceConsentInd);

      } else {
        this.pdpaForm.get('consentDetails.einvoiceConsentInd').setValue(null);
      }
    });
  }
  

  resetInvalidatedFields(fieldList: string[]) {
    fieldList.forEach(field => {
      if (this.issuanceForm.controls[field]) {
        this.issuanceForm.controls[field].clearValidators();
        this.issuanceForm.controls[field].setValue(null);
        this.issuanceForm.controls[field].setErrors(null);
        this.issuanceForm.controls[field].markAsPristine();
        this.issuanceForm.controls[field].markAsUntouched();
        this.issuanceForm.controls[field].updateValueAndValidity();
      }
    });
    this.issuanceForm.updateValueAndValidity();
  }

  updateEndtDateValidations(type: string) {
    console.log("Type: " + type);
    const policyEffDate = new Date(this.issuanceForm.get('policyEffectiveDate').value);
    const policyExpDate = new Date(this.issuanceForm.get('policyExpiryDate').value);
    const currentDate = new Date();

    switch (type) {
      case 'COE':
        this.updateEndorsementDates(policyEffDate <= currentDate ? currentDate : policyEffDate, policyExpDate);
        this.setFieldState(true, false);
        break;
      case 'XP':
      case 'XS':
        const nextDay = new Date(policyExpDate);
        nextDay.setDate(policyExpDate.getDate() + 1);
        this.updateEndorsementDates(nextDay, null);
        this.setFieldState(false, true);
        break;
      case 'ENDT04':
        this.updateEndorsementDates(policyEffDate, policyExpDate);
        this.setFieldState(true, true);
        break;
      case 'EC175':
      case 'EC176':
        this.updateEndorsementDates(policyEffDate, policyExpDate);
        this.setFieldState(false, false);
        break;
      case 'ENDT05':
      case 'ENDT09':
      case 'ENDT10':
      case 'ENDT12':
      case 'ENDT17':
      case 'ENDT18':
      case 'ENDT22':
      case 'ENDT23':
      case 'ENDT24':
      case 'ENDT25':
      case 'ENDT30':
      case 'ENDT34':
      case 'ENDT29':
        this.updateEndorsementDates(policyEffDate <= currentDate ? currentDate : policyEffDate, policyExpDate);
        this.setFieldState(true, false);
        break;
      default:
        break;
    }
  }

  updateEndorsementDates(effectiveDate: Date | null, expiryDate: Date | null) {
    this.issuanceForm.get('endtEffectiveDate').setValue(effectiveDate);
    this.issuanceForm.get('endtExpiryDate').setValue(expiryDate);
  }

  setFieldState(effDtCtrl: boolean, expDtCtrl: boolean) {
    this.setControlState('endtEffectiveDate', effDtCtrl);
    this.setControlState('endtExpiryDate', expDtCtrl);
  }

  setControlState(controlName: string, enable: boolean) {
    const control = this.issuanceForm.get(controlName);
    if (control) {
      enable ? control.enable() : control.disable();
    }
  }

  loadIssuanceData() {
    if (this.isIssuance) {
      this.issuanceData$.subscribe((state) => {
        if (state) {
          if (state.issuance) {
            this.issuanceStateData = state.issuance;
            let updatedIssuance = { ...state.issuance };
            if (updatedIssuance.cnoteType === 'NWOO' || updatedIssuance.cnoteType === 'RN') {
              updatedIssuance.cnoteType = 'NWOO/RN';
            }

            this.updateProductList(updatedIssuance.idType);
            this.issuanceForm.patchValue(updatedIssuance);
            this.isVehicleIdNoExist = true;
          }
          if (state.pdpa) {
            this.pdpaForm.patchValue(state.pdpa);
            
            if(this.clientDetails){
              // if(state.pdpa.consentDetails?.einvoiceConsentInd === true || state.pdpa.consentDetails?.einvoiceConsentInd === false){
              //   this.pdpaForm.get('consentDetails.einvoiceConsentInd').disable();
              // }
              
              if(this.clientDetails?.clientType === 'I'){
                this.pdpaForm.get('consentDetails.einvoiceConsentInd').setValue(true);
                this.pdpaForm.get('consentDetails.einvoiceConsentInd').disable();
              }
            }
          }
        }
      });
    }

    if (this.isRTP) {
      const rtpPolicy = this.store.selectSnapshot<any>(state => state.SatMotorState?.rtp?.step1?.rtpPolicy);
      this.isRTPDataExist = rtpPolicy ? true : false;
      if (rtpPolicy) {
        this.filteredProductList = this.productList;
        this.populateRTPForm(rtpPolicy?.data?.cngeNoteDto);
      }
    }
  }

  loadAgentData() {
    this.agentDetail$.subscribe((state) => {
      if (state) {
        this.agentData = state;
      }
    });
  }

  loadQueryParamData() {
    if (this.queryParams) {
      if (this.queryParams.vehicleNo) {
        this.issuanceForm.controls['vehicleNo'].setValue(this.queryParams.vehicleNo);
      }

      if (this.queryParams.cnoteType) {
        this.issuanceForm.controls['cnoteType'].setValue(this.queryParams.cnoteType);
      }

      if (this.queryParams.idType) {
        this.issuanceForm.controls['idType'].setValue(this.queryParams.idType);
      }

      if (this.queryParams.idValue) {
        this.issuanceForm.controls['idNumberText'].setValue(this.queryParams.idValue);
      }

      if (this.queryParams.prevPolicyNo) {
        this.issuanceForm.controls['prevPolicyNo'].setValue(this.queryParams.prevPolicyNo);
      }

      if (this.queryParams.productCode) {
        this.issuanceForm.controls['productCode'].setValue(this.queryParams.productCode);
      }

      if (this.queryParams.idType && this.queryParams.idValue) {
        this.updateCurrentDate();
      }
    }
  }

  updateCurrentDate() {
    const effectiveDateControl = this.issuanceForm.get('effectiveDate');
    const expiryDateControl = this.issuanceForm.get('expiryDate');

    const currentDate = new Date();
    const nextYearDate = new Date(currentDate);
    nextYearDate.setFullYear(currentDate.getFullYear() + 1);
    nextYearDate.setFullYear(currentDate.getFullYear() + 1) && nextYearDate.setDate(nextYearDate.getDate() - 1);

    const formattedEffectiveDate = currentDate.toISOString().substring(0, 10);
    const formattedExpiryDate = nextYearDate.toISOString().substring(0, 10);

    effectiveDateControl.setValue(formattedEffectiveDate);
    expiryDateControl.setValue(formattedExpiryDate);
  }

  updateEndtWatcher() {
    this.issuanceForm.get('endtEffectiveDate').valueChanges.subscribe(value => {
      this.showDateValidateError = false;
      this.dateValidateError = '';

      if (value) {
        let endtExpiryDate = this.issuanceForm.controls['endtExpiryDate'].value;
        let policyEffectiveDate = this.issuanceForm.controls['policyEffectiveDate'].value;
        const endtEffectiveDate = moment(new Date(value));
        const currentDate = moment();

        if (!this.showDateValidateError && policyEffectiveDate && policyEffectiveDate != '') {
          const effectiveDate = moment(new Date(policyEffectiveDate));

          if (endtEffectiveDate.isBefore(effectiveDate, 'day')) {
            this.showDateValidateError = true;
            this.dateValidateError = `Endorsement Effective date cannot be before the effective date of the policy (${effectiveDate.format('DD/MM/YYYY')}).`;
          }
        }

        if (!this.showDateValidateError && endtEffectiveDate.isBefore(currentDate, 'day')) {
          this.showDateValidateError = true;
          this.dateValidateError = 'Endorsement Effective date cannot be earlier than issued date.';
        }

        if (!this.showDateValidateError && endtExpiryDate && endtExpiryDate != '') {
          const expiryDate = moment(new Date(endtExpiryDate));

          if (endtEffectiveDate.isAfter(expiryDate, 'day')) {
            this.showDateValidateError = true;
            this.dateValidateError = 'Endorsement Effective date cannot be after expiry date.';
          }
        }
      }
    });

    this.issuanceForm.get('endtExpiryDate').valueChanges.subscribe(value => {
      this.showDateValidateError = false;
      this.dateValidateError = '';

      if (value) {
        let endtEffectiveDate = this.issuanceForm.controls['endtEffectiveDate'].value;
        let endtExpiryDate = this.issuanceForm.controls['endtExpiryDate'].value;
        let endorsementType = this.issuanceForm.controls['endorsementType'].value;
        const currentDate = moment();

        if (endorsementType && endorsementType == 'XP') {
          const effectiveDate = moment(new Date(endtEffectiveDate));
          const expiryDate = moment(new Date(endtExpiryDate));

          if (expiryDate.diff(effectiveDate, 'months', true) > 6) {
            this.showDateValidateError = true;
            this.dateValidateError = 'Expiry date cannot be more than 6 months after effective date.';
          }
        }

        if (!this.showDateValidateError && endtExpiryDate && endtEffectiveDate) {
          const effectiveDate = moment(new Date(endtEffectiveDate));
          const expiryDate = moment(new Date(endtExpiryDate));

          if (expiryDate.isBefore(effectiveDate, 'day') || expiryDate.isSame(effectiveDate, 'day')) {
            this.showDateValidateError = true;
            this.dateValidateError = 'Expiry date cannot be earlier than effective date.';
          }
        }
      }

    });
  }

  updateWatcher() {
    this.issuanceForm.get('cnoteType').valueChanges.subscribe(value => {
      this.updateValidatorBasedCNoteType(value);
      if (value === 'NWNN') {
        this.issuanceForm.get('vehicleNo').setValue('NA');
      }
      this.isEffectiveDateValidation = false;
    });

    this.issuanceForm.get('idType').valueChanges.subscribe(value => {
      this.updateIdNumberTextValidator(value);
      this.updateProductList(value);
      this.checkInvoiceConsentInd(value);
    });

    this.issuanceForm.get('effectiveDate').valueChanges.subscribe(value => {
      if (value && value !== 'NA') {
        if(this.verifyCnoteType() !== 'NWNN' && this.previousPolicyDetails?.cnoteType !== 'RN'
        && !this.activeQuotationDetails) {
          this.isEffectiveDateValidation = true;
        }
        const effectiveDate = moment(new Date(value));
        let effectiveDateVal = this.issuanceForm.controls['effectiveDate'].value;
        if (effectiveDateVal && effectiveDateVal !== 'NA') {
          const expiryDate = effectiveDate.clone().add(1, 'year').subtract(1, 'day');
          this.issuanceForm.controls['expiryDate'].setValue(expiryDate);
        }
      }
    });

    this.issuanceForm.get('expiryDate').valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(value => {
        const formData = this.issuanceForm.getRawValue();

        if (formData.productCode && formData.productCode.trim() !== '' && value 
          && value !== 'NA' && formData.effectiveDate) {

          const expiryDate = moment(new Date(value));
          if (
            expiryDate.isValid() &&
            !this.issuanceForm.controls['effectiveDate'].errors &&
            !this.issuanceForm.controls['expiryDate'].errors
          ) {
            this.validateDate();
          }
        }
    });

    if (!this.issuanceForm.controls['cnoteType'].value || this.issuanceForm.controls['cnoteType'].value.trim() === '') {
      this.issuanceForm.controls['cnoteType'].setValue('NWOO/RN');
      this.issuanceForm.controls['idType'].setValue('NRIC');
    }

  }

  checkInvoiceConsentInd(value: any) {
    const einvoiceConsentControl = this.pdpaForm.get('consentDetails.einvoiceConsentInd');
    let cnoteType = this.issuanceForm.controls['cnoteType'].value ?? null;

    if (cnoteType === 'NWNN' || cnoteType === 'NWTR' || (cnoteType === 'NWOO/RN' && (value === 'NRIC' || value === 'OLD_IC' || value === 'PASS' || value === 'POL'))) {
      if (einvoiceConsentControl.disabled && !this.clientDetails) {
        einvoiceConsentControl.enable();
        einvoiceConsentControl.setValue(null);
      }
    } else {
      if (value === 'NEW_BR_NO' || value === 'BR_NO') {
        einvoiceConsentControl.setValue(true);
        einvoiceConsentControl.disable();
      } else {
        einvoiceConsentControl.setValue(null);
        einvoiceConsentControl.enable();
      }
    }
  }

  updateProductList(value: any) {
    if (value) {
      const productCodes = this.idTypeProductMap[value] || [];
      this.filteredProductList = this.productList.filter(product => productCodes.includes(product.code));
    }
  }

  updateValidatorBasedCNoteType(cnoteType: string) {
    const vehicleNoControl = this.issuanceForm.get('vehicleNo');
    const idTypeControl = this.issuanceForm.get('idType');
    const idNumberTextControl = this.issuanceForm.get('idNumberText');
    const effectiveDateControl = this.issuanceForm.get('effectiveDate');
    const expiryDateControl = this.issuanceForm.get('expiryDate');

    if (cnoteType === 'NWOO/RN') {
      vehicleNoControl.setValidators([
        Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d-]{1,20}$')
      ]);
      idTypeControl.setValidators(Validators.required);
      idNumberTextControl.setValidators(Validators.required);
      this.isVehicleIdNoExist = false;

      if (!idTypeControl.value) {
        idTypeControl.setValue('NRIC');
      }
    } else {
      this.filteredProductList = this.productList;
      vehicleNoControl.clearValidators();
      idTypeControl.clearValidators();
      idNumberTextControl.clearValidators();

      vehicleNoControl.setErrors(null);
      idTypeControl.setErrors(null);
      idNumberTextControl.setErrors(null);
      this.isVehicleIdNoExist = true;

      idTypeControl.setValue('');
    }


    let effectiveDateVal = this.issuanceForm.controls['effectiveDate'].value;
    let expiryDateVal = this.issuanceForm.controls['expiryDate'].value;

    if (cnoteType === 'NWNN') {
      effectiveDateControl.clearValidators();
      expiryDateControl.clearValidators();

      const currentDate = new Date();
      const nextYearDate = new Date(currentDate);
      nextYearDate.setFullYear(currentDate.getFullYear() + 1);

      const formattedEffectiveDate = currentDate.toISOString().substring(0, 10);
      const formattedExpiryDate = nextYearDate.toISOString().substring(0, 10);

      effectiveDateControl.setValue(formattedEffectiveDate);
      expiryDateControl.setValue(formattedExpiryDate);

    } else {
      if (effectiveDateVal === 'NA' || expiryDateVal === 'NA') {
        effectiveDateControl.setValue(null);
        expiryDateControl.setValue(null);
      }
      effectiveDateControl.setValidators(Validators.required);
      expiryDateControl.setValidators([
        Validators.required, this.expiryDateValidator()
      ]);
    }

    vehicleNoControl.updateValueAndValidity();
    idTypeControl.updateValueAndValidity();
    idNumberTextControl.updateValueAndValidity();
    effectiveDateControl.updateValueAndValidity();
    expiryDateControl.updateValueAndValidity();
  }

  updateIdNumberTextValidator(idType: string) {
    const idNumberTextControl = this.issuanceForm.get('idNumberText');
    this.resetFields(['productCode', 'idNumberText', 'effectiveDate', 'expiryDate']);
    let cnoteType = this.issuanceForm.controls['cnoteType'].value;

    if (cnoteType === 'NWOO/RN') {
      if (idType === 'NRIC') {
        idNumberTextControl.setValidators([
          Validators.required,
          this.nricValidator()
        ]);
        this.onlyNumber = true;
      } else if (idType === 'NEW_BR_NO') {
        idNumberTextControl.setValidators([
          Validators.required,
          this.newBrNoValidator()
        ]);
        this.onlyNumber = false;
      } else {
        idNumberTextControl.setValidators([
          Validators.required,
          // Validators.pattern('^[a-zA-Z0-9]*$'),
          Validators.maxLength(20)
        ]);
        this.onlyNumber = false;
      }
    } else {
      idNumberTextControl.clearValidators();
    }

    idNumberTextControl.updateValueAndValidity();
  }

  getLov() {
    this.prepareCoverNoteTypeLov(this.lov);
    this.prepareIdTypeLov(this.lov);
  }

  prepareIdTypeLov(lov: any) {
    const filteredList = lov?.clientTypeList.reduce((acc, item) => {
      if (item.code === 'P') {
        item.subList.forEach(sub => {
          let descp = sub.descp;
          if (sub.code === 'NRIC') {
            descp = 'NRIC';
          } else if (sub.code === 'OLD_IC') {
            descp = 'OLD IC NO.';
          } else if (sub.code === 'POL') {
            descp = 'POLICE ID / ARMY ID';
          } else if (sub.code === 'PASS') {
            descp = 'PASSPORT NO.';
          }
          acc.push({ code: sub.code, descp });
        });
      } else if (item.code === 'I') {
        const newBrItem = item.subList.find(sub => sub.code === 'NEW_BR_NO');
        if (newBrItem) acc.push({ code: newBrItem.code, descp: 'NEW BUSINESS REGISTRATION NO.' });

        const brItem = item.subList.find(sub => sub.code === 'BR_NO');
        if (brItem) acc.push({ code: brItem.code, descp: 'BUSINESS REGISTRATION NO.' });
      }
      return acc;
    }, []) || [];

    this.idTypeList = filteredList;

  }

  prepareCoverNoteTypeLov(lov: any) {

    const filteredList = lov?.cnoteTypeList.filter(item =>
      ['NWOO', 'NWNN', 'NWTR', 'RN'].includes(item.code)
    ) || [];

    const sortedList = filteredList.map(item => {
      if (item.code === 'NWOO' || item.code === 'RN') {
        return { code: 'NWOO/RN', descp: 'RENEWAL / NEW BUSINESS - OLD VEHICLE, OLD REGISTRATION' };
      }
      return { ...item, descp: item.descp.toUpperCase() };
    }).filter((item, index, self) =>
      index === self.findIndex((t) => t.code === item.code)
    ).sort((a, b) => {
      const order = ['NWOO/RN', 'NWNN', 'NWTR'];
      return order.indexOf(a.code) - order.indexOf(b.code);
    });

    this.coverNoteTypeList = sortedList;
  }



  getCoverNoteType() {
    let cnoteType = this.issuanceForm.controls['cnoteType'].value;
    return cnoteType ? cnoteType : null;
  }

  isCoverNoteNWOO() {
    return this.getCoverNoteType() === 'NWOO/RN';
  }

  isCoverNoteNWTR() {
    return this.getCoverNoteType() === 'NWTR';
  }

  isCoverNoteNWNN() {
    return this.getCoverNoteType() === 'NWNN';
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  changeType(type: any) {
    if (type == "CN") {
      this.cn_quotation = true;
      this.endorsement = false;
      this.isEndorsement = false;
    }
    if (type == "ENDT") {
      this.cn_quotation = false;
      this.endorsement = true;
      this.isEndorsement = true;
    }
  }

  goNext() {
    let idType = this.issuanceForm.get('idType')?.value;
    let coverNoteType = this.issuanceForm.get('cnoteType')?.value;
    if((coverNoteType ==='NWOO/RN') && (!this.isRTP) && (this.custAge < 16 || this.custAge > 99) 
    &&(idType !=="NEW_BR_NO" && idType !=="BR_NO")){
      this.openPopupAge("custAge");
      return;
    }

    if (this.isEndorsement) {
      //skip
    } else if (!this.isCoverNoteNWOO() && !this.isRTP) {
      this.issuanceForm.get('vehicleNo').setValue('NA');
    }

    if (this.issuanceForm.valid) {

      let issuanceFormData = this.issuanceForm.getRawValue(); 
      let pdpaFormData = this.pdpaForm.getRawValue();
      let declarationInfo = {};
      if (this.isEndorsement || this.isRTP) {
        declarationInfo = {
          issuance: { ...issuanceFormData, cnoteDate: new Date().toISOString().substring(0, 10) },
          pdpa: pdpaFormData,
          isRTP: this.isRTP,
          isEndorsement: this.isEndorsement
        };
      } 
	    if (this.updateQuotation) {
        let verifiedCNoteType = this.verifyCnoteType();
        declarationInfo = {
          issuance: { 
            ...issuanceFormData, 
            cnoteType: verifiedCNoteType, 
            cnoteDate: new Date().toISOString().substring(0, 10),
            equotationNo: this.issuanceInfo?.equotationNo, 
            equotationCreatedDate: this.issuanceInfo?.equotationCreatedDate,
            updateVersion: this.issuanceInfo?.updateVersion
          },
          pdpa: pdpaFormData,
          isRTP: this.isRTP,
          isEndorsement: this.isEndorsement
        };

      } else {
        let htgeExpDate = this.store.selectSnapshot<any>(state => state.SatMotorState?.expiredPolicyDetails?.expiryDate);
        let statePrevExpDate = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step1?.issuance?.issuance?.prevExpDate);
        let prevExpDate = htgeExpDate ? htgeExpDate : statePrevExpDate;
        declarationInfo = {
          issuance: { 
            ...issuanceFormData, 
            cnoteType: (!this.isEndorsement && !this.isRTP) ? this.verifyCnoteType() : null, 
            cnoteDate: new Date().toISOString().substring(0, 10),
            prevExpDate: prevExpDate
          },
          pdpa: pdpaFormData,
          isRTP: this.isRTP,
          isEndorsement: this.isEndorsement
        };
      }

      this.store.dispatch(new STORE_DECLARATION(declarationInfo));
      
      let activeSelectionPopup = null;
      if (!this.isEndorsement && !this.isRTP) {
        this.traceLog('verifyCnoteType', this.verifyCnoteType());
        this.traceLog('isActiveQuotation', this.isActiveQuotation);
        this.traceLog('isRenewal', this.isRenewal);
        this.traceLog('previousPolicyDetails', this.previousPolicyDetails);

        activeSelectionPopup = this.store.selectSnapshot<any>(state => state.SatMotorState.activeSelectionPopup);
        this.traceLog('activeSelectionPopup', activeSelectionPopup);
      }

      if (this.isRTP) {
        this.router.navigate(['rtp/summary'], {
          queryParamsHandling: 'preserve',
        });
      } else if (this.isEndorsement) {
        this.router.navigate(['endorsement/endorsement-details'], {
          queryParamsHandling: 'preserve',
        });
      } else if ((this.isRenewal || this.isActiveQuotation) && this.verifyCnoteType() != 'NWOO') {
        this.traceLog('isRenewalChecking', '');
        let clientDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step2);
        const einvoiceConsentValue = this.pdpaForm.get('consentDetails.einvoiceConsentInd')?.value;

        if (einvoiceConsentValue && !this.isAllMandatoryFilled()) {
          this.traceLog('clientDetails checking', '');
          this.navigateToCP(issuanceFormData);
        } else if (clientDetails?.idType1 === 'OLD_IC' && clientDetails?.idType2 !== 'NRIC') {
          this.traceLog('idType2 is not NRIC', '');
          this.navigateToCP(issuanceFormData);
        } else {
          let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step1?.activeQuotation?.cngeNote ?? null);
          if (!cngeNote) {
            cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.previousPolicyDetails);
          }

          if (!cngeNote?.riskList[0].ismRefNo) {
            this.traceLog('ismRefNo not found', '');
            let payload = {
              productType: cngeNote?.productCode,
              vehicleUse: cngeNote?.riskList[0].vehicleUse,
              vehicleNo: cngeNote?.riskList[0].vehicleNo,
              previousNcdVehicle: cngeNote?.riskList[0].prevNcdVeh,
              chassisNo: cngeNote?.riskList[0].vehicleChassis,
              idType: cngeNote?.clientPartner.idType1,
              idNo1: cngeNote?.clientPartner.idValue1
            };
        
            this.store.dispatch(new GET_NCD_ENQUIRY(payload)).subscribe((state) => {
              let ncdInfo = state.SatMotorState.ncdEnquiryDetails;
  
              if (ncdInfo) {
                if (ncdInfo.errMsg) {
                  this.traceLog('NCD Enquiry Error Message found', '');
                  this.okMessageModal = this.dialogService.open(OkMessageComponent, {
                    width: '400px',
                    data: {
                      message: ncdInfo.errMsg
                    }
                  });

                  this.okMessageModal.afterClosed().subscribe(() => {
                    this.checkMakeModelExist(cngeNote);
                  });
                } else {
                  if (ncdInfo.ismRefNo) {
                    this.traceLog('NCD Enquiry - retrieved ismRefNo', ncdInfo.ismRefNo);
                    const cngeNoteUpdated = {
                      ...cngeNote,
                      riskList: [
                        {
                          ...cngeNote.riskList[0],
                          ismRefNo: ncdInfo.ismRefNo
                        }
                      ]
                    };
                    this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: cngeNoteUpdated, operator: this.operator })).subscribe(state => {
                      this.checkMakeModelExist(cngeNote);
                    });
                  } else {
                    this.traceLog('NCD Enquiry - Return Empty Response', '');
                    this.checkMakeModelExist(cngeNote);
                  }
                }
              } else {
                this.traceLog('NCD Enquiry - No Response', '');
                this.checkMakeModelExist(cngeNote);
              }
            });
          } else {
            this.checkMakeModelExist(cngeNote);
          }
        }

      } else {
        if (this.verifyCnoteType() === 'NWOO' || this.verifyCnoteType() === 'RN') {
          this.traceLog('NWOO/RN checking', '');
          const retrieveQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.retrieveQuotation ?? null);
          let cpType = this.store.selectSnapshot<any>(state => state.SatMotorState?.data?.step2?.cpType) ?? null;
          let cpAge = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step2?.clientAge);
          let idType = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step2?.idType1);
          if (cpType && cpType === 'FOUND') {
            if ( (cpAge < 16 || cpAge > 99) && (!this.isRTP) &&(idType !=="NEW_BR_NO" && idType !=="BR_NO")){
              this.openPopupAge("custAge");
              return;
            }
          }

          if (retrieveQuotation) {
            let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step1?.activeQuotation?.cngeNote ?? null);
            this.checkMakeModelExist(cngeNote);
          } else {
            this.performCPSearch(issuanceFormData);
          }
        } else {
          this.traceLog('NWNN/NWTR checking', '');
          this.router.navigate(['issuance/customer-partner'], {
            queryParamsHandling: 'preserve',
          });
        }
      }
    } else {
      this.logFormErrors(this.issuanceForm);
      this.issuanceForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }
  }

  checkMakeModelExist(cngeNote: any) {
    if(!cngeNote?.riskList[0].vehModelNvic) {
      this.traceLog('vehicle model not found', '');
      this.router.navigate(['issuance/coverage'], {
        queryParamsHandling: 'preserve',
      });
    } else {
      this.traceLog('mandatory checking passed', '');
      this.router.navigate(['issuance/summary'], {
        queryParamsHandling: 'preserve',
      });
    }
  }

  traceLog(data: string, value?: any) {
    console.log(`[INFO TRACE] ${data}${value != '' ? ' = ' + value : ''}`);
  }

  verifyCnoteType() {
    let cnoteType = this.issuanceForm.controls['cnoteType'].value;

    if (cnoteType === 'NWOO/RN') {
      const activeQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step1?.activeQuotation);
      const previousPolicyDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.previousPolicyDetails);

      if (activeQuotation) {
        cnoteType = activeQuotation.cngeNote?.cnoteType;
      }

      if (previousPolicyDetails) {
        cnoteType = 'RN'; //For Previous Policy CnoteType always RN
      }

      if (!activeQuotation && !previousPolicyDetails) {
        cnoteType = 'NWOO'
      }

    }

    return cnoteType;
  }

  logFormErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control.errors) {
        console.log(`Field: ${field}, Errors: `, control.errors);
      }
    });
  }


  goToSelectAgentCode() {
    console.log('goToSelectAgentCode..');

    if(this.showBackButton()) {
      this.store.dispatch(new STORE_AGENT_ADDITIONAL(null)).subscribe(() => {
        this.router.navigate(['agent-selection'], {
          queryParamsHandling: 'preserve',
          });
      });
    } else {
      this.router.navigate(['agent-selection'], {
        queryParamsHandling: 'preserve',
      });
    }
  }

  consentChange(event: any) {
    // console.log(event.value)
    localStorage.setItem("eInvoice_consent", event.value);
  }

  onChangeIdNo() {
    console.log('onChangeIdNo..');

    const nricValue = this.issuanceForm?.controls['idNumberText']?.value;
    this.custAge = this.getCalculateAge(nricValue);
    this.performSearchQuotation();
  }

  onChangeVehicleNo() {
    if (this.isRTP) {
      this.performRTPVehicleSearch();
    } else if (this.isEndorsement) {
      this.performEndtVehicleSearch();
    } else {
      this.performSearchQuotation();
    }

  }

  performEndtVehicleSearch() {
    if (this.issuanceForm.controls['vehicleNo'].valid) {
      const operator = this.store.selectSnapshot(state => state.UserState.user.userId);
      let vehicleNo = this.issuanceForm.controls['vehicleNo'].value;
      if (vehicleNo != "") {
        const param = {
          "operator": operator,          
          "cngeNote": {
            "productType": "MT",
            "vehicleNo": vehicleNo 
          }
        };

        this.store.dispatch(new SEARCH_ENDT_VEHICLE(param)).subscribe((res) => {
          let endtError = (res && res.SatMotorState?.endorsement?.step1?.endtVehicleSearchError) ?? null;
          let endtPolicy = (res && res.SatMotorState?.endorsement?.step1?.endtVehicleData) ?? null;

          if (endtPolicy && endtPolicy.mkagAgent) {
            this.store.dispatch(new GET_PRODUCT_LIST({ lob: 'MT', agentCode: endtPolicy.mkagAgent.agentCode, productCode: endtPolicy.productCode }));
          }

          if (endtError && endtError.message) {
            console.log("message: " + endtError.message);
            this.showOkMessage(endtError.message);
            this.clearVehicleNo();
          }
        });
      }
    }
  }

  showOkMessage(messageData: any) {
    this.okMessageModal = this.dialogService.open(OkMessageComponent, {
      width: '400px',
      data: {
        message: messageData
      }
    });
  }

  performRTPVehicleSearch() {
    const operator = this.store.selectSnapshot(state => state.UserState.user.userId);
    let vehicleNo = this.issuanceForm.controls['vehicleNo'].value;
    if (vehicleNo != "") {

      //Reset state before search
      this.store.dispatch(new ROADTAX_SEARCH({
        isReset: true
      }));

      const param = {
        isReset: false,
        operator: operator,
        cngeNote: {
          productType: "MT",
          vehicleNo: vehicleNo
        }
      };

      this.store.dispatch(new ROADTAX_SEARCH(param)).subscribe((res) => {
        let rptError = (res && res.SatMotorState?.rtp?.step1?.rtpVehicleSearchError) ?? null;
        let rtpPolicy = (res && res.SatMotorState?.rtp?.step1?.rtpPolicy) ?? null;
        this.isRTPDataExist = rtpPolicy ? true : false;

        if (rtpPolicy && !rptError) {
          let policy = res.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto;
          if (policy && policy.mkagAgent) {
            this.store.dispatch(new GET_PRODUCT_LIST({ lob: 'MT', agentCode: policy.mkagAgent.agentCode })).subscribe(() => {
              this.filteredProductList = this.productList;
              this.populateRTPForm(policy);
            });
          }
        } else {
          if (rptError && rptError.message) {
            this.clearVehicleNo();

            console.log("message: " + rptError.message);
            this.showOkMessage(rptError.message);
          }
        }
      });
    }
  }

  clearVehicleNo() {
    const vehicleNoControl = this.issuanceForm.controls['vehicleNo'];
    vehicleNoControl.clearValidators();
    vehicleNoControl.setValue('');
    vehicleNoControl.setValidators([Validators.required]);
    vehicleNoControl.updateValueAndValidity();
    vehicleNoControl.markAsUntouched();
  }

  populateRTPForm(policy: any) {
    this.issuanceForm.patchValue({
      vehicleNo: policy.vehicleNo ?? policy.riskList[0].vehicleNo,
      prevPolicyNo: policy.previousPolicyNo ?? policy.policyNo,
      productCode: policy.productCode,
      specialVehicleInd: policy.specialVehicleInd,
      effectiveDate: policy.effectiveDate,
      expiryDate: policy.expiryDate
    });

    this.previousPolicyNo = policy.previousPolicyNo ?? policy.policyNo;
  }

  performSearchQuotation() {
    if (this.issuanceForm.controls['idNumberText'].valid &&
      (this.issuanceForm.controls['vehicleNo'].valid || this.issuanceForm.controls['vehicleNo'].disabled)
    ) { 
      this.resetStore();
      let vehicleNo = this.issuanceForm.controls['vehicleNo'].value;
      let idNo = this.issuanceForm.controls['idNumberText'].value;
      let cnoteType = this.issuanceForm.controls['cnoteType'].value;

      if (cnoteType === 'NWOO/RN' || cnoteType === 'RN' || cnoteType === 'NWOO') {
        if (vehicleNo != "" && idNo != "") {
          this.isVehicleIdNoExist = true;
          const param = {
            "vehicleNo": vehicleNo,
            "agentCode": this.agentData.agentCode,
            "idNo": idNo
          };

          const prevPolicyparam = {
            "vehicleNo": this.issuanceForm.controls['vehicleNo'].value,
            "agentCode": this.agentData.agentCode,
          };

          this.store.dispatch(new SEARCH_ACTIVE_QUOTATION(param)).subscribe((res) => {
            if (res.SatMotorState.motor?.step1?.activeQuotation) { 
              this.issuanceForm.controls['vehicleNo'].disable();
              this.activeQuotationDetails = res.SatMotorState.motor?.step1?.activeQuotation;
              this.isActiveQuotation = true;
              if(res.SatMotorState.motor?.step1?.activeQuotation.cngeNote.cnoteType == 'RN'){
                this.isRenewal = true;
              }else{
                this.isRenewal = false;
              }
              let activeQuotation = res.SatMotorState.motor?.step1?.activeQuotation;
              this.openPopupActiveQuotation('populateActiveQuotationDetails', activeQuotation, param)
            } else {
              // Previous Policy Search
              this.issuanceForm.controls['vehicleNo'].enable();
              this.searchPreviousPolicy(param);
            }
            this.isCommercial = res.SatMotorState.opportunityId;
          });
        } else {
          //this.isVehicleIdNoExist = false;
        }
      }
    }
  }

  searchPreviousPolicy(param) {
    console.log('searchPreviousPolicy..');

    this.store.dispatch(new GET_PREVIOUS_POLICY_NO(param)).subscribe((state) => {
      let previousPolicyDetails = state.SatMotorState.previousPolicyDetails;
      let previousPolicyDetailsError = state.SatMotorState.previousPolicyDetailsError;
      
      //remove previousPolicyDetails.previousPolicyNo, no policy no become NWOO
      if (previousPolicyDetails) {
        this.previousPolicyDetails = previousPolicyDetails;
        this.isCommercial = state.SatMotorState.opportunityId;
        this.issuanceForm.controls["effectiveDate"].disable();
        this.previousPolicyNo = previousPolicyDetails.previousPolicyNo || previousPolicyDetails.refPolicyNo;

        if(this.queryParams.policyNo){
          this.issuanceForm.patchValue({
            idType: previousPolicyDetails.clientPartner.idType1,
            idNumberText: previousPolicyDetails.clientPartner.idValue1
          });
        }

        this.issuanceForm.patchValue({
          vehicleNo: previousPolicyDetails.vehicleNo,
          prevPolicyNo: previousPolicyDetails.previousPolicyNo || previousPolicyDetails.refPolicyNo,
          effectiveDate: previousPolicyDetails.effectiveDate,
          expiryDate: previousPolicyDetails.expiryDate,
          productCode: previousPolicyDetails.productCode,
        });
        
        this.isVehicleIdNoExist = true;
        this.isRenewal = true;

        this.store.dispatch(new COPY_CNGE_INFO_RENEWAL(previousPolicyDetails)).subscribe(state => { });
        let vehicleDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.vehicleDetails);

        if(vehicleDetails?.makeModel && vehicleDetails?.vehModelNvic){
          let cngeNoteUpdated = previousPolicyDetails;
          let riskList = cngeNoteUpdated.riskList[0];
          if(riskList.coverList[0]){
            const { coverDto, ...coverList } = riskList.coverList[0];
  
            riskList = {
              ...riskList,
              coverList : [coverList]
            };
          }
          
          
            cngeNoteUpdated = {
              ...cngeNoteUpdated,
              riskList: [riskList]
            };
          if(riskList.coverList[0]){
            this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: cngeNoteUpdated, operator: this.operator }));
          }

          //previous policy empty
          if(!riskList.coverList[0]){
            this.store.dispatch(new DELETE_COVERAGE("")).subscribe((state) => {});
          }
        }
        
      } else {
        this.issuanceForm.controls["effectiveDate"].enable();
        this.isRenewal = false;
        this.reset();

        if (previousPolicyDetailsError &&
          previousPolicyDetailsError.message &&
          !previousPolicyDetailsError.message.includes("No result")) {
          this.clearVehicleNo();
          this.isVehicleIdNoExist = false; //when validation failed clear vehicleNo
          this.showOkMessage(previousPolicyDetailsError.message);
        }
      }
    });
  }

  previousPolicyErrorPopup(popup_type: any, error: any) {
    this.openPreviousPolicyError = this.dialogService.open(PreviousPolicyErrorPopupComponent, {
      ariaLabel: "A simple dialog",
      width: '400px',
      showCloseIcon: false,
      data: {
        popup_type: popup_type,
        popup_error: error
      },
    });
  }

  openPopupEnquiry(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EnquiryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "360px",
      maxWidth: "90vw",
      data: { popup_type: popup_type },
    });
  }

  openPopupActiveQuotation(popup_type: any, data: any, param) {
    this.openPreviousPolicyError = this.dialogService.open(PreviousPolicyErrorPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type, activeQuotationDetails: data },
    });

    this.openPreviousPolicyError.afterClosed().subscribe((result) => {
      this.store.dispatch(new ACTIVE_SELECTION_POPUP(result)).subscribe(state => { });
      if (data?.cngeNote?.effectiveDate) {
        if (result === 'Y') {
          this.issuanceForm.controls['effectiveDate'].setValue(data.cngeNote.effectiveDate);
        } else {
          const today = new Date();
          const localDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          this.issuanceForm.controls['effectiveDate'].setValue(localDate);
        }
      } 
      if (data?.cngeNote?.expiryDate) {
        if (result === 'Y') {
          this.issuanceForm.controls['expiryDate'].setValue(data.cngeNote.expiryDate);
        }
      }
      if (data?.cngeNote?.productCode) {
        this.issuanceForm.controls['productCode'].setValue(data.cngeNote.productCode);
      }

      if(data?.cngeNote?.previousPolicyNo){
        this.previousPolicyNo = data?.cngeNote?.previousPolicyNo;
      }

      if (data?.cngeNote?.cnoteType === 'NWOO' || data?.cngeNote?.cnoteType === 'RN') {
        this.store.dispatch(new COPY_CNGE_INFO_RENEWAL(data?.cngeNote)).subscribe(state => { });
        this.store.dispatch(new SET_RETRIEVE_QUOTATION(result === 'Y')).subscribe(state => { });
      }
      
      if (result == 'Y' && data?.cngeNote?.cnoteType != 'NWOO') {
        this.store.dispatch(new COPY_CNGE_INFO_RENEWAL(data?.cngeNote)).subscribe(state => { });
        
        let vehicleDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.vehicleDetails);

        if (vehicleDetails?.makeModel && vehicleDetails?.vehModelNvic) {
          let cngeNoteUpdated = data?.cngeNote;
          let riskList = cngeNoteUpdated.riskList[0];
          const { coverDto, ...coverList } = riskList.coverList[0];
          riskList = {
            ...riskList,
            coverList: [coverList]
          };

          cngeNoteUpdated = {
            ...cngeNoteUpdated,
            riskList: [riskList]
          };

          this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: cngeNoteUpdated, operator: this.operator }));
        }

      } else {
        //perform VIX search

        // this.store.dispatch(new SEARCH_VIX(param)).subscribe((res) => {
        //   if (res.SatMotorState.motor?.step1?.searchVIX) {
        //     let searchVIX = res.SatMotorState.motor?.step1?.searchVIX;
        //   }
        // });
      }
    });
  }

  reset() {
    if (this.queryParams.idType && this.queryParams.idValue) {
      this.resetFields(['productCode', 'prevPolicyNo']);
      this.updateCurrentDate();
    } else {
      this.resetFields(['productCode', 'prevPolicyNo', 'effectiveDate', 'expiryDate']);
    }
    
    this.previousPolicyNo = "";
  }

  resetFields(fieldList: string[]) {
    fieldList.forEach(field => {
      if (this.issuanceForm.controls[field]) {
        this.issuanceForm.controls[field].setValue('');
        this.issuanceForm.controls[field].markAsPristine();
        this.issuanceForm.controls[field].markAsUntouched();
        this.issuanceForm.controls[field].updateValueAndValidity();
      }
    });
  }

  openPopup(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(PdpaPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });

  }

  openPopupAge(popup_type: any) {
    
        this.componentDialogRefs = this.dialogService.open(SummaryPopupComponent, {
          ariaLabel: "A simple dialog",
          showCloseIcon: false,
          width: '400px',
          data: { popup_type: popup_type },
        });
      }


  onChangeProduct(code: any) {
    if (code === '082100') {
      this.openPopupEnquiry('popupProductMotorcycle');
    }
    this.store.dispatch(new RESET_SUBSEQUENT_PAGE_DATA());

    if (this.isIssuance) {
      if (this.verifyCnoteType() !== 'NWNN') {
        let effectiveDateControl = this.issuanceForm.controls['effectiveDate'];
        let expiryDateControl = this.issuanceForm.controls['expiryDate'];
  
        if (effectiveDateControl.value && expiryDateControl.value && this.isEffectiveDateValidation) {
          this.validateDate();
        }
      }
      
      for (let i = 0; i < this.lovproductConfig.length; i++) {
        this.productAccessListData = this.lovproductConfig[i];
        this.lovPC = Object.keys( this.productAccessListData)[0]; 
      
          if (this.lovPC === code) {
           this.lovGeo =this.productAccessListData[this.lovPC].geocodingEnableSat;
           this.emailCheck = this.productAccessListData[this.lovPC].emailCheckEnableSat;
          }
      }
    }
  }

  onChangeFormValue(code: any) {
    this.resetStore();
    this.issuanceForm.controls['vehicleNo'].reset();
    if (code == 'NWOO') {
      this.issuanceForm.controls['vehicleNo'].setValidators([Validators.required]);
    } else {
      this.issuanceForm.controls['vehicleNo'].clearValidators();
      this.issuanceForm.controls['vehicleNo'].updateValueAndValidity();
      //this.issuanceForm.controls.vehicleNo.setValidators(null);
    }
  }

  nricValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      //check if exactly 12 digits long and only numbers
      const pattern = /^\d{12}$/;
      if (!pattern.test(value)) {
        return { wrongFormat: true };
      }

      const year = +value.substring(0, 2);
      const month = +value.substring(2, 4);
      const day = +value.substring(4, 6);

      const currentYear = new Date().getFullYear();
      const fullYear = (year > currentYear % 100) ? 1900 + year : 2000 + year;

      const date = new Date(fullYear, month - 1, day);

      if (
        date.getFullYear() === fullYear &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
      ) {
        return null; // Valid NRIC
      }

      return { wrongFormat: true };
    };
  }

  newBrNoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      const pattern = /^\d{1,12}$/;
      if (!pattern.test(value) || value.length < 12) {
        return { invalidBrNo: true };
      }

      const year = +value.substring(0, 4);
      const currentYear = new Date().getFullYear();

      if (year > currentYear) {
        return { invalidBrNo: true };
      }

      return null;
    };
  }

  expiryDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.showDateValidateError = false;
      this.showLapsedError = false;
      this.dateValidateError = '';

      const formGroup = control.parent as FormGroup;
      if (!formGroup) return null;

      const effectiveDate = formGroup.get('effectiveDate')?.value;
      const expiryDate = control.value;

      if (effectiveDate && expiryDate && new Date(expiryDate) < new Date(effectiveDate)) {
        this.showDateValidateError = true;
        this.dateValidateError = 'Expiry date cannot be before effective date';
        return { dateMismatch: true };
      }
      return null;
    };
  }

  performCPSearch(issuanceFormData: any) {
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(true));

    const { idType, idNumberText: idValue } = issuanceFormData;
    const productType = "MT";
    const agentCode = this.agentData.agentCode;
    const operator = this.store.selectSnapshot(state => state.UserState.user.userId);
    const data = { idType, idValue, agentCode, operator, productType };

    this.lookupIdSearch(data, issuanceFormData);
  }

  lookupIdSearch(data: {}, issuanceFormData: any) {
    this.store.dispatch(new GET_CUSTOMER_PARTNER(data)).subscribe(state => {
      this.cpDetails = state.SatMotorState.data.step2.store;
      this.checkCPScreen(issuanceFormData, data);
    });
  }

  checkCPScreen(issuanceFormData: any, data: any) {
    const details = this.cpDetails;
    let cpType = "NOT_FOUND";

    if (details) {
      if (details.updateCustomerPartner) {
        this.isCompOrCustInfoIncomplete = this.isContactInfoIncomplete = this.isCorrespondenceInfoIncomplete = true;
        cpType = "INCOMPLETE";
      } else {
        cpType = this.isAllMandatoryFilled() ? "FOUND" : "INCOMPLETE";
      }
    }

    this.store.dispatch(new STORE_STEP2_CP_SCREEN({
      cpScreen: cpType,
      isCompOrCustInfoIncomplete: this.isCompOrCustInfoIncomplete,
      isContactInfoIncomplete: this.isContactInfoIncomplete,
      isCorrespondenceInfoIncomplete: this.isCorrespondenceInfoIncomplete
    }));

    let searchData = {
      ...data,
      clientAddressType: this.idTypeConfig[data.idType].clientAddressType,
      clientType: this.idTypeConfig[data.idType].clientType,
    };
    this.store.dispatch(new STORE_STEP2_SEARCH(searchData));
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));


    if (cpType === 'FOUND') {
      let cpType =this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step2?.idType1);
      let cpAge = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step2?.clientAge);
      if ( (cpAge < 16 || cpAge > 99) && (!this.isRTP) &&(cpType !=="NEW_BR_NO" && cpType !=="BR_NO")){
        this.openPopupAge("custAge");
        return;
      }

    }

    this.store.dispatch(new GET_POSTCODE_LOV({
      listTypes: ["clientPostcodeList"],
      agentCode: this.agentData.agentCode,
      productType: "COMM",
      productCode: issuanceFormData.productCode
    })).subscribe(() => {

      this.router.navigate(['issuance/customer-partner']);
    });
  }

  navigateToCP(issuanceFormData: any) {
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(true));

    const { idType, idNumberText: idValue } = issuanceFormData;
    const productType = "MT";
    const agentCode = this.agentData.agentCode;
    const operator = this.store.selectSnapshot(state => state.UserState.user.userId);
    const data = { idType, idValue, productType, agentCode, operator };

    this.store.dispatch(new STORE_STEP2_CP_SCREEN({
      cpScreen: 'INCOMPLETE',
      isCompOrCustInfoIncomplete: this.isCompOrCustInfoIncomplete,
      isContactInfoIncomplete: this.isContactInfoIncomplete,
      isCorrespondenceInfoIncomplete: this.isCorrespondenceInfoIncomplete
    }));

    let searchData = {
      ...data,
      clientAddressType: this.idTypeConfig[data.idType].clientAddressType,
      clientType: this.idTypeConfig[data.idType].clientType,
    };
    this.store.dispatch(new STORE_STEP2_SEARCH(searchData));
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));

    this.store.dispatch(new GET_POSTCODE_LOV({
      listTypes: ["clientPostcodeList"],
      agentCode: this.agentData.agentCode,
      productType: "COMM",
      productCode: issuanceFormData.productCode
    })).subscribe(() => {

      this.router.navigate(['issuance/customer-partner']);
    });
  }

  isAllMandatoryFilled() {
    const details = this.cpDetails;

    if (this.cpDetails || Object.keys(this.cpDetails).length === 0) {
      this.cpDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step2);
    }

    // const isIndividual = () => details.clientType === CLIENTTYPE.INDIVIDUAL;
    // const hasBasicDetails = () => details.clientName && (!isIndividual() || (details.clientGender && details.clientDateofBirth && details.clientMaritalStatus));
    // const hasIdType2 = this.cpDetails.idType1 === IDTYPE.OLD_IC ? (this.cpDetails.idType1 === IDTYPE.OLD_IC && this.cpDetails.idType2) : true;
    // const hasContactInfo = () => details.clientMobileNo1;
    // const hasAddressInfo = () => details.clientPartnerAddress.clientAddress1 && details.clientPartnerAddress.clientPostcode && details.clientPartnerAddress.clientAddressType;
    let eInvoiceConsentControl = this.pdpaForm.get('consentDetails.einvoiceConsentInd');
    let cpMandatoryFilled = this.cpMandatoryFilledService.checkCPMandatoryFilled(this.cpDetails, this.emailCheck, eInvoiceConsentControl.value);

    this.isCompOrCustInfoIncomplete = cpMandatoryFilled.isCompOrCustInfoIncomplete;
    this.isContactInfoIncomplete = cpMandatoryFilled.isContactInfoIncomplete;
    this.isCorrespondenceInfoIncomplete = cpMandatoryFilled.isCorrespondenceInfoIncomplete;

    return !(this.isCompOrCustInfoIncomplete || this.isContactInfoIncomplete || this.isCorrespondenceInfoIncomplete);
  }

  resetStore() {
    this.store.dispatch(new RESET_MOTOR_STATE()).subscribe((state) => { });
    this.store.dispatch(new RESET_PREVIOS_POLICY_DETAILS()).subscribe((state) => { });
    this.isRenewal = false;
    this.isActiveQuotation = false;
  }

  validateDate() {
    this.showDateValidateError = false;
    this.showLapsedError = false;
    this.dateValidateError = '';
    
    console.log("cNoteType: "+this.verifyCnoteType());
    if (this.verifyCnoteType() === 'NWOO' || this.verifyCnoteType() === 'NWTR' || this.verifyCnoteType() === 'RN') {
      const { productCode, effectiveDate, expiryDate } = this.issuanceForm.getRawValue();
      const payload = {
        cngeNoteDto: {
          cnoteType: this.verifyCnoteType(),
          productCode,
          effectiveDate: effectiveDate ? this.datePipe.transform(effectiveDate, 'yyyy-MM-dd') : null,
          expiryDate: expiryDate ? this.datePipe.transform(expiryDate, 'yyyy-MM-dd') : null,
          mkagAgent: { agentCode: this.agentData.agentCode }
        }
      };
    
      this.store.dispatch(new VALIDATE_POI_DATE(payload)).subscribe(state => {
        const { success, message } = state.SatMotorState.poiValidateResult || {};
        if (success || (message && message.includes('lapsed'))) {
          this.showDateValidateError = false;
          this.showLapsedError = false;
          this.dateValidateError = '';

          let payloadPremium = state.SatMotorState.data?.step3?.coverage?.coveragePremiumPayload ?? null;
          if (payloadPremium && payloadPremium.cngeNote) {
            const formattedExpiryDate = moment(expiryDate).startOf('day'); 
            const currentExpiryDate = moment(payloadPremium.cngeNote.expiryDate).startOf('day');
          
            if (!currentExpiryDate.isSame(formattedExpiryDate, 'day')) {
              let cngeNoteClone = { ...payloadPremium.cngeNote };
          
              cngeNoteClone.effectiveDate = moment(effectiveDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
              cngeNoteClone.expiryDate = formattedExpiryDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
              cngeNoteClone.operator = this.operator;
          
              payloadPremium = {
                ...payloadPremium,
                cngeNote: cngeNoteClone
              };
          
              this.updatePremiumCalculation(payloadPremium);
            }
          }

          let activeQuotation = state.SatMotorState.motor?.step1?.activeQuotation ?? null;
          if (activeQuotation && !(payloadPremium && payloadPremium.cngeNote)) {
            if (activeQuotation.cngeNote.cnoteType == 'NWOO') {
              let vehicleDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.vehicleDetails);
              let hasVehicleModel = (vehicleDetails?.makeModel && vehicleDetails?.vehModelNvic) ? true : false;

              if (hasVehicleModel) {
                let cngeNoteUpdated = activeQuotation?.cngeNote;
                let riskList = cngeNoteUpdated.riskList[0];
                const { coverDto, ...coverList } = riskList.coverList[0];
                riskList = {
                  ...riskList,
                  coverList: [coverList]
                };

                //only for cr14 optional package
                if (riskList.coverList[0].subCovPackage) {
                  riskList = {
                    ...riskList,
                    coverList: [{
                      ...riskList.coverList[0],
                      coverDto: { extCovPackageSelected: riskList.coverList[0].subCovPackage }
                    }]
                  };
                }

                cngeNoteUpdated = {
                  ...cngeNoteUpdated,
                  riskList: [riskList]
                };

                this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: cngeNoteUpdated, operator: this.operator }));
              }
            }

          }

          if ((message && message.includes('lapsed'))) {
            this.showDateValidateError = !message.includes('lapsed');
            this.showLapsedError = message.includes('lapsed');
            this.dateValidateError = message;
          }

        } else if (message) {
          this.showDateValidateError = !message.includes('lapsed');
          this.showLapsedError = message.includes('lapsed');
          this.dateValidateError = message;
        }

        this.isEffectiveDateValidation = this.showDateValidateError;
      });
    }
    
  }

  updatePremiumCalculation(payloadPremium: any) {
    this.store.dispatch(new STORE_PREMIUM_CALCULATION_PAYLOAD(payloadPremium)).subscribe({
      next: () => {
        this.store.dispatch(new CALCULATE_PREMIUM(payloadPremium));
      }
    });
  }

  checkDisabled() {
    const { pdpaCheck, consentDetails } = this.pdpaForm.getRawValue();

    if (this.isIssuance) {
      if (!pdpaCheck
        || consentDetails.edocConsentInd == null
        || consentDetails.edocConsentInd === ""
        || consentDetails.einvoiceConsentInd == null
        || consentDetails.einvoiceConsentInd === ""
        || this.showDateValidateError
        || this.isEffectiveDateValidation
        || !this.issuanceForm.valid) {
        return true;
      }
    }

    if (this.isRTP && (!this.isRTPDataExist || !pdpaCheck)) {
      return true;
    }

    if (this.isEndorsement && this.issuanceForm) {
      let endorsementType = this.issuanceForm.controls['endorsementType'].value;
      let endtEffectiveDate = this.issuanceForm.controls['endtEffectiveDate'].value;
      let endtExpiryDate = this.issuanceForm.controls['endtExpiryDate'].value;
      let invoiceConsentSelected = (consentDetails.einvoiceConsentInd == null || consentDetails.einvoiceConsentInd === "") ? false : true;

      let isEndtFieldsEmpty =
        (endorsementType === null || endorsementType === undefined || endorsementType === "") ||
        (endtEffectiveDate === null || endtEffectiveDate === undefined || endtEffectiveDate === "") ||
        (endtExpiryDate === null || endtExpiryDate === undefined || endtExpiryDate === "");

      if (!this.endtVehicleFound || !pdpaCheck || !this.issuanceForm.valid || isEndtFieldsEmpty) {
        return true;
      }
    }

    return false;
  }
  hasErrors(): boolean {
    if (this.isEndorsement && this.issuanceForm) {
      return this.issuanceForm.hasError('required', 'endorsementType') ||
        this.issuanceForm.hasError('required', 'endtEffectiveDate') ||
        this.issuanceForm.hasError('required', 'endtExpiryDate') ||
        this.showDateValidateError ||
        this.showLapsedError;
    } else {
      return this.showDateValidateError || this.showLapsedError;
    }
  }

  switchToAlpha() {
    //this.store.dispatch(new BACK_TO_ALPHA);
    const payload = {
      "userId" : this.operator,
      "opportunityId": this.isCommercial,
      "agentCode": this.agentData.agentCode
    };
    this.store.dispatch(new REDIRECT_ALPHA_CCENTRIC(payload)).subscribe({});
  }

  showBackButton() {
    let data = this.agentData;
    return (!!data.customerType) || data.bancaAgent || data.sundriesAgent;
  }

  goBack() {
    this.router.navigate(['issuance/agent-additional-info']);
  }

  cancelDraft() {

    this.confirmMessageModal = this.dialogService.open(ConfirmationModalComponent, {
      showCloseIcon: false, width: "550px", maxWidth: "550px",
      data: { message: "Do you want to cancel the Draft?" },
    });
  
    this.confirmMessageModal.afterClosed().subscribe(result => {
      if (result) {
        const data = {
          operator: this.userInfo.userId,
          cngeNote: this.isSaved
        };
        this.store.dispatch(new CANCEL_DRAFT(data)).subscribe(state => {
          if (state) {
            this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "500px", maxWidth: "500px",
              data: { message: "Cancel Draft Success" },
            })
            .afterClosed().subscribe((result) => {
              this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                window.location.href = state.SatMotorState.data.switchSiteUrl;
              });
            });
          }
        });
      }
    });

  }
  getCalculateAge(value) {
    return this.nricService.getAge(value);
  }
}
