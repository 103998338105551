import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { UserResponse } from '@interfaces/user';
import { Select, Selector, Store } from '@ngxs/store';
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { RemarksComponent } from '@shared/components/modal/remarks/remarks.component';
import { RemarksAddeddComponent } from '@shared/components/modal/remarks-addedd/remarks-addedd.component';
import { RequestCancellationComponent } from '@shared/components/modal/request-cancellation/request-cancellation.component';
import { SharedModule } from '@shared/shared.module';
import { CALCULATE_PREMIUM, CANCEL_COVER_NOTE, CANCEL_QUOTATION, CANCEL_REPLACE, CONTINUE_ISSUANCE, COPY_CNGE_INFO, DOWNLOAD_PDF, GET_AGENT_DETAIL_ONLY, GET_AGENT_LIST_AGENT, GET_CROSS_SELL_LIST, GET_DOCUMENT_LOV, GET_PRODUCT_LIST, GET_QUOTATION_DETAIL, GET_REDIRECT_PAGE, GET_SWITCH_SITE, REQUEST_CANCELLATION, SAVE_REFERRAL_REMARKS, SEND_TO_JPJ, SET_ACTION, SET_CURRENT_STEP, SET_JPJ_STATUS_SENT, STORE_COMPLETE_DATA } from '@store/sat-motor/sat-motor.action';
import { EnquiryPopupComponent } from 'src/app/popup/enquiry-popup/enquiry-popup.component';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable } from 'rxjs';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { StatusMessageComponent } from '@shared/components/status-message/status-message.component';
import { CompleteInformationComponent } from '@shared/components/complete-information/complete-information.component';
import { STORE_QUERY_PARAMS } from '@store/user/user.action';
import { SummaryPopupComponent } from 'src/app/popup/summary-popup/summary-popup.component';
import { ClauseMessageComponent } from '@shared/components/modal/clause-message/clause-message.component';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { EmailDocumentComponent } from '@shared/components/modal/email-document/email-document.component';

@Component({
  selector: 'app-endorsement-complete',
  standalone: true,
  imports: [
    SharedModule,
    CompleteInformationComponent
  ],
  templateUrl: './endorsement-complete.component.html',
  styleUrl: './endorsement-complete.component.scss'
})
export class EndorsementCompleteComponent implements OnInit{
  
  @Select(SatMotorState.motorStep4Summary) step4$: Observable<any>;
  @Select(SatMotorState.endorsementStep3) endtSummary$: Observable<any>;
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.rtpStep2Summary) rtpSummary$: Observable<any>;

  isIssuance: boolean = false; 
  isEndorsement: boolean = false; 
  isEnquiry: boolean = false;
  isRTP: boolean = false;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<EndorsementCompleteComponent>;
  componentDialogRef2?: NxModalRef<SummaryPopupComponent>;
  emailDocumentModal?: NxModalRef<EmailDocumentComponent>;

  componentDialogRef1?: NxModalRef<EnquiryPopupComponent>;

  referralRemarksModal?: NxModalRef<RemarksComponent>;
  requestCancellationModal?: NxModalRef<RequestCancellationComponent>;
  remarksAddedModal?: NxModalRef<RemarksAddeddComponent>;
  clauseMsgModal?: NxModalRef<ClauseMessageComponent>;
  dialogRef?: NxModalRef<any>;

  agentInfo: any;
  userInfo: UserResponse;
  declarationInfo: any;
  customerPartnerInfo: any;
  crossSellList: any = [];
  marketingConsent: any;
  data: any;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  queryParam: any;
  cnoteType: any;
  endtType: any;
  completeData: any;
  isQuotationGenerated: boolean = false;
  isEndorsementGenerated: boolean = false;
  isEndtNoExist: boolean = false;
  isDataLoaded: boolean = false;
  showTitleMessage: boolean = false;
  titleMessage: string;
  status: any;
  classType: any;
  referralReasonList: any;

  isShowCancellationBtn: boolean = false;
  isShowReferralRemarksBtn: boolean = false;
  isShowQuotationPDFBtn: boolean = false;
  isShowSchedulePDFBtn: boolean = false;
  isShowCertificatePDFBtn: boolean = false;
  isShowInvoicePDFBtn: boolean = false;
  isShowUploadBtn: boolean = false;

  statusMap = {
    PRINTED: "Printed",
    APPROVED: "Approved",
    PENDING: "Pending Approval",
    CANCELLED: "Cancelled",
    SUBMITTED: "Submitted",
    ACTIVE: "Active",
    EXPIRED: "Expired"
  };

  classTypeMap = {
    PRINTED: "printed_badge",
    APPROVED: "approved_badge",
    PENDING: "pending_badge",
    CANCELLED: "cancel_badge",
    SUBMITTED: "submitted_badge",
    ACTIVE: "active_badge",
    EXPIRED: "expired_badge"
  };

  constructor(
    private dialogService: NxDialogService,
    private router: Router,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.store.dispatch(new SET_CURRENT_STEP(4));
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.queryParam = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.endtType = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.issuance?.issuance?.endorsementType);
    this.populateCompleteScreen();
    this.getDocumentListLov();
  }

  getDocumentListLov() {
    this.store.dispatch(new GET_DOCUMENT_LOV({
      lob: "MT",
      userId: this.userInfo.userId,
      productCode: this.data.productCode,
      productType: "",
      type: 'SATMTCNDOCTYPE'
    }));
  }

  populateCompleteScreen() {
    this.completeData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step4?.cngeNote) ?? null;

    if (!this.completeData) {
      this.loadEndorsementData();
    } else {
      this.processEndorsementData(this.completeData);
    }
  }

  loadEndorsementData() {
    this.endtSummary$.subscribe(summary => {
      const { generateQuotation, sendToJPJ } = summary || {};
      const sendToJPJData = sendToJPJ?.data?.cngeNoteDto;
      const generateQuotationData = generateQuotation?.data?.cngeNoteDto;

      if (sendToJPJData) {
        this.isEndorsementGenerated = true;
        this.isEndtNoExist = !!sendToJPJData.endtNo;
        this.processEndorsementData(sendToJPJData);
      } else if (generateQuotationData) {
        this.isQuotationGenerated = true;
        this.processEndorsementData(generateQuotationData);
      }
    });
  }

  processEndorsementData(endorsementData: any) {
    const generateQuotationData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.generateQuotation?.data?.cngeNoteDto);
    const sendToJPJData = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.sendToJPJ?.data?.cngeNoteDto);

    if ((sendToJPJData && endorsementData.contractId !== sendToJPJData.contractId)) {
      this.data = sendToJPJData;
    } else if ((generateQuotationData && endorsementData.contractId !== generateQuotationData.contractId)) {
      this.data = generateQuotationData;
    } else {
      this.data = endorsementData;
    }

    this.getTitleMessage();
    this.status = this.statusMap[this.getStatus()] || "";
    this.classType = this.classTypeMap[this.getStatus()] || "";
    //this.storeCompleteData();
    this.showCancellationButton();
    this.showRemarksButton();
    this.showQuotationPDFBtn();
    this.showSchedulePDFBtn();
    this.showCertificatePDFBtn();
    this.showInvoicePDFBtn();
    this.showUploadBtn();
    this.isDataLoaded = true;
  }

  storeCompleteData() {
    const payload = {
      isIssuance: false,
      isRTP: false,
      isEndorsement: true,
      cngeNote: this.data
    };

    this.store.dispatch(new STORE_COMPLETE_DATA(payload)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  getStatus() {
    return this.data.cnoteStatus;
  }

  getMessage() {
    if (this.isEndorsementGenerated) {
      switch (this.getStatus()) {
        case 'PRINTED':
          return 'Your Endorsement has been successfully generated.';
        case 'PENDING':
          return 'Your Endorsement has been submitted as pending approval.';
        case 'CANCELLED':
          return 'Your Endorsement has been cancelled.';
        default:
          return null;
      }
    } else if (this.isQuotationGenerated) {
      switch (this.getStatus()) {
        case 'PRINTED':
          return 'Your e-Quotation has been generated.';
        case 'CANCELLED':
          return 'e-Quotation has been cancelled successfully.';
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  getTitleMessage() {
    this.titleMessage = this.getMessage();
    if (this.titleMessage && this.titleMessage != '') {
      this.showTitleMessage = true;
    }
  }

  isQuotationOrCoverNote() {
    //return this.isEndorsementGenerated ? 'Cover Note' : 'e-Quotation';
    return 'Endorsement';
  }

  getStatusDescription() {
    switch (this.getStatus()) {
      case 'PRINTED':
        return 'Printed';
      case 'PENDING':
        return 'Pending Approval';
      case 'CANCELLED':
        return 'Cancelled';
      default:
        return null;
    }
  }

  getProductName() {
    const productName = this.data?.productName ?? null;

    if (typeof productName !== 'string' || !productName.trim()) {
      return null;
    }

    return productName
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  getCoverNoteNumber() {
    let text = '';
    if ((this.data.proposalNo && this.data.proposalNo != '') && (this.data.cnoteNo && this.data.cnoteNo != '')) {
      text = this.data.proposalNo + ' / ' + this.data.cnoteNo;
    } else if (this.data.proposalNo && this.data.proposalNo != '') {
      text = this.data.proposalNo;
    } else if (this.data.cnoteNo && this.data.cnoteNo != '') {
      text = this.data.cnoteNo;
    }
    return text;
  }

  getQuotationMessage() {
    if (this.isQuotationGenerated && this.getStatus() === 'PRINTED') {
      return 'has been successfully downloaded. You may save a digital copy of the e-Quotation by clicking on the e-Quotation PDF button below.';
    } else if (this.isEndorsementGenerated && this.getStatus() === 'PRINTED') {
      return 'has been issued. You may save a digital copy of the Endorsement by clicking on the Download Endorsement Schedule button below.';
    } else if (this.isEndorsementGenerated && this.getStatus() === 'PENDING') {
      this.setReferralCode(this.data.referList);
      return 'The Endorsement you have just submitted is a referral case based on the following reason(s):';
    } else {
      return '';
    }
  }

  setReferralCode(referList: any) {
    if(referList && referList.length > 0) {
      this.referralReasonList = referList;
    } else {
      this.referralReasonList = [];
    }
  }

  showCancellationButton() {
    this.isShowCancellationBtn = this.getStatus() === 'PENDING' || this.getStatus() === 'PRINTED';
  }

  showRemarksButton() {
    this.isShowReferralRemarksBtn = this.isEndorsementGenerated && this.getStatus() === 'PENDING';
  }

  showQuotationPDFBtn() {
    this.isShowQuotationPDFBtn = this.isQuotationGenerated && !this.isEndorsementGenerated && this.getStatus() === 'PRINTED';
  }

  showSchedulePDFBtn() {
    this.isShowSchedulePDFBtn = this.isEndorsementGenerated && this.getStatus() === 'PRINTED';
  }

  showCertificatePDFBtn() {
    const validEndorsementTypes = ['COE', 'XP', 'ENDT04', 'ENDT12', 'ENDT34', 'ENDT23', 'ENDT24', 'ENDT25'];

    this.isShowCertificatePDFBtn = true;

    if (!this.isEndorsementGenerated) {
      console.log('Condition failed: Endorsement is not generated.');
      this.isShowCertificatePDFBtn = false;
    }

    if (this.getStatus() !== 'PRINTED') {
      console.log(`Condition failed: Status is '${this.getStatus()}', expected 'PRINTED'.`);
      this.isShowCertificatePDFBtn = false;
    }

    if (!validEndorsementTypes.includes(this.endtType)) {
      console.log(`Condition failed: endtType '${this.endtType}' is not valid.`);
      this.isShowCertificatePDFBtn = false;
    }

    console.log(`isShowCertificatePDFBtn: ${this.isShowCertificatePDFBtn}`);
  }

  showInvoicePDFBtn() {
    this.isShowInvoicePDFBtn = true;

    if (!this.data?.einvoiceConsentInd) {
      console.log('Condition failed: einvoiceConsentInd is not set or invalid.');
      this.isShowInvoicePDFBtn = false;
    }

    if (this.getStatus() !== 'PRINTED') {
      console.log(`Condition failed: Status is '${this.getStatus()}', expected 'PRINTED'.`);
      this.isShowInvoicePDFBtn = false;
    }

    if (!this.data?.premiumDue || this.data.premiumDue <= 0) {
      console.log(`Condition failed: premiumDue is '${this.data?.premiumDue}', expected a positive value.`);
      this.isShowInvoicePDFBtn = false;
    }

    console.log(`isShowInvoicePDFBtn: ${this.isShowInvoicePDFBtn}`);
  }

  showUploadBtn() {
    this.isShowUploadBtn = this.getStatus() === 'PRINTED' || this.getStatus() === 'PENDING';
  }

  openRequestCancellationPopUp() {
    let data = {
      summaryData: this.data
    }

    this.requestCancellationModal = this.dialogService.open(RequestCancellationComponent, {
      showCloseIcon: false,
      autoFocus: false,
      data: data
    });

    this.requestCancellationModal.afterClosed().subscribe(result => {
      if (result) {
        let payload = {
          cancelRemarks: result.data.cancelRemarks,
          contractId: this.data.contractId,
          operator: this.userInfo.userId
        };

        if (result.isCancelAndReplace) {
          this.store.dispatch(new CANCEL_REPLACE(payload)).subscribe((state) => {
            let cancellationRes = state.SatMotorState.data.cancellationRes;
            this.router.navigate(['endorsement/summary']);
          });
        } else {
          if (this.isQuotationGenerated) {
            this.store.dispatch(new CANCEL_QUOTATION(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if (cancellationRes) {
                this.data = cancellationRes;
                this.storeCompleteData();
              }
            });
          } else {
            this.store.dispatch(new CANCEL_COVER_NOTE(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if (cancellationRes) {
                this.data = cancellationRes;
                this.storeCompleteData();
              }
            });
          }
        }
      }
    });
  }

  openPopupReferralRemarks() {
    this.referralRemarksModal = this.dialogService.open(RemarksComponent, {
      showCloseIcon: false,
      data: {
        title: "Please add remarks",
        primaryButtonLabel: "SUBMIT"
      }
    });

    this.referralRemarksModal.afterClosed().subscribe(result => {
      if(result) {
        const data = {
          remark: result.remarks,
          operator: this.userInfo.userId,
          contractId: this.data.contractId,
          isEndorsement: true
        };
        this.store.dispatch(new SAVE_REFERRAL_REMARKS(data)).subscribe(state => {
          this.remarksAddedModal = this.dialogService.open(RemarksAddeddComponent, {
            showCloseIcon: false
          });
        });
      }
    });
  }

  uploadDocument() {
    let dialogConfig = {
      showCloseIcon: false,
      width: "80%",
      maxWidth: "80vw",
      maxHeight: "95vh",
      data: {
        contractId: this.data.contractId
      }
    };

    this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {  }
    });
    
  }

  downloadPDF(contractId, type) {
    let payload = {
      operator: this.userInfo.userId,
      contractId: contractId ? contractId : this.data.contractId,
      type: type
    };

    this.store.dispatch(new DOWNLOAD_PDF(payload));
  }

  retrieveLatestEndtNo(type) {
    this.store.dispatch(new GET_QUOTATION_DETAIL(this.data.contractId, "SUMMARY")).subscribe((state) => {
      let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation);

      if (cngeNote) {
        console.log("Current Endt No: " + (cngeNote.endtNo ? cngeNote.endtNo : 'null'));
        this.isEndtNoExist = !!cngeNote.endtNo;

        if (type == 'cancel') {
          if (this.isEndtNoExist) {
            this.data = cngeNote;
            this.showOkMessage('Endorsement generated, cannot cancel.');
          } else {
            this.openRequestCancellationPopUp();
          }
        
        } else if (type == 'email') {
          if (this.isEndtNoExist) {
            this.data = cngeNote;
            this.emailDocument();
          } else {
            this.showOkMessage('Endorsement not generated yet, please try again.');
          }
        }
      }
    });
  }

  checkLatestEndtNo(contractId, type, showClausePopup) {
    this.store.dispatch(new GET_QUOTATION_DETAIL(this.data.contractId, "SUMMARY")).subscribe((state) => {
      let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation);
      
      if (cngeNote) {
        console.log("Current Endt No: " + (cngeNote.endtNo ? cngeNote.endtNo : 'null'));
        this.isEndtNoExist = !!cngeNote.endtNo;
        
        if (this.isEndtNoExist) {
          this.data = cngeNote;
          this.checkDownloadPDF(contractId, type, showClausePopup);
        } else {
          this.showOkMessage('Endorsement not generated yet, please try again.');
        }
        
      }
    });
  }

  checkDownloadPDF(contractId, type, showClausePopup) {
    // if (!this.isEndtNoExist) {
    //   this.showOkMessage('Endorsement not generated yet, please try again.');
    
    // } else {
      if (showClausePopup) {
        const payload: any = {
          operator: this.userInfo.userId,
          contractId: contractId ? contractId : this.data.contractId,
          type: type,
        };
  
        if (this.data.cnoteType === 'ENDT17' || this.data.cnoteType === 'ENDT18') {
          this.clauseMsgModal = this.dialogService.open(ClauseMessageComponent, {
            width: '450px',
            maxWidth: '450px',
          });
          this.clauseMsgModal.afterClosed().subscribe(result => {
            payload.printClauseInd = (result === 'Y');
            this.store.dispatch(new DOWNLOAD_PDF(payload));
          });
        } else {
          this.downloadPDF(contractId, type);
        }
      
      } else {
        this.downloadPDF(contractId, type);
      }
    //}
  }

  showOkMessage(message) {
    this.okMessageModal = this.dialogService.open(OkMessageComponent, {
      width: '400px',
      data: {
        message: message
      }
    });
  }

  backToSummaryListing() {
    this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
      let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;
      console.log("SwitchSiteUrl Url : " + switchSiteUrl);
      window.location.href = switchSiteUrl;
    });
  }

  goToViewPage() {
    this.queryParam = {
      ...this.queryParam,
      contractId: this.data.contractId,
      action: 'COMPLETE'
    }
    this.store.dispatch(new STORE_QUERY_PARAMS(this.queryParam)).subscribe(() => {
      this.router.navigate(["/view-endorsement"]);
    });
  }

  onContinueIssuance() {
    this.store.dispatch(new SET_ACTION('ISSUE'));
    this.store.dispatch(new GET_AGENT_LIST_AGENT({ userId: this.userInfo.userId })).subscribe(() => {
      this.actionReroute();
    });
  }

  async actionReroute() {

    const isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
    if (isSingleAgent) {
      const agentList = this.store.selectSnapshot<any>((state) => state.SatMotorState?.data?.step1?.agentList);
      const payload = {
        operator: this.userInfo.userId,
        agentCode: this.data.mkagAgent.agentCode,
        productType: "MT",
      };
      let agentDetailRes = await this.store.dispatch(new GET_AGENT_DETAIL_ONLY(payload)).toPromise();
      if (agentDetailRes) {
        this.store.dispatch(new GET_PRODUCT_LIST({ lob: 'MT', agentCode: this.data.mkagAgent.agentCode })).subscribe(() => {
          this.router.navigate(['issuance/declaration-issuance-info']);
        });

      }
    } else {
      this.router.navigate(["/agent-selection"]);
    }
  }

  emailDocument() {
    let innerWidth = window.innerWidth;
    let popupWidth;
    console.log(innerWidth);
    if (innerWidth < 703) {
      popupWidth = "680";
    } else if (innerWidth >= 703 && innerWidth < 991) {
      popupWidth = "680";
    } else {
      popupWidth = "1100px";
    }
    // return
    this.emailDocumentModal = this.dialogService.open(EmailDocumentComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: popupWidth,
      maxWidth: popupWidth,
      data: { popup_type: 'emaildocument' },
    });
  }

}
