import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    NxMultiStepperComponent,
    NxMultiStepperDirection,
    NxProgressStepperDirective,
} from '@aposin/ng-aquila/progress-stepper';
import { Select } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';

@Component({
    selector: 'steps',
    standalone: true,
    imports: [SharedModule],
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
    host: {
        '[class.is-vertical]': 'direction === "vertical"',
    },
})
export class StepsComponent {
    direction: NxMultiStepperDirection = 'vertical';
    @Select(SatMotorState.progressStep) progressStep$;
    stepper!: NxMultiStepperComponent;

    testStepsData = ['Customer Partner', 'Product Selection', 'Summary'];


    @Input('steps') steps : any = [];
    @Input('index') index = 1;
    @Input('currentStep') currentStep:number = 1;

    constructor(
        private router: Router,
        // private store: Store
    ) { }

    isCurrentStep(index:number) : boolean {
        return (index + 1) === this.currentStep;
    }

    isBold(i:number): boolean {
        return (i + 1) === Number(this.index);
    }

    getNumber(index : any,step: any){
        // index + 1 is the one selected by user
        // this.currentStep is the progress that already fill by user
        // this.index is the current active step

        const selectedIndex = index + 1;
        // does not allow change.
        // step.disable does not allow user to select the step.
        // this.steps[this.index - 1].disable does not allow user to step out of the step.
        if(step.disable || this.steps[this.index - 1]?.disable){
            console.warn("not change")
            return null
        }
        return
        // if(selectedIndex <= this.currentStep && selectedIndex !== this.index ){
        //     if(step.route){
        //         this.router.navigate([step.route]);
        //         // this.store.dispatch(new CHANGE_CURRENT_STEP(selectedIndex));
        //     } else {
        //         // this.store.dispatch(new SET_PRODUCT_JOURNEY_STEP(selectedIndex));
        //     }
            
        // }
    }

}
