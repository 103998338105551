import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sst-form-details',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './sst-form-details.component.html',
  styleUrl: './sst-form-details.component.scss'
})
export class SstFormDetailsComponent implements OnInit {
  @Input() customerInfoForm: FormGroup;
  @Input() cpDetails: any;
  @Input() countryList: any;
  @Input() addressTypeList: any;

  unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.initForm();
    this.onChangeSSTRegInd();
  }
  
  initForm() {
    this.customerInfoForm.addControl('sstRegisteredInd', new FormControl(this.cpDetails?.clientSstRegInd ? 'Y' : 'N'));
    this.customerInfoForm.addControl('sstNo', new FormControl(this.cpDetails?.clientSstRegNo ? this.cpDetails?.clientSstRegNo : ''));
    this.customerInfoForm.addControl('sstEffDate', new FormControl(this.cpDetails?.clientSstRegDate ? this.cpDetails?.clientSstRegDate : ''));
    this.customerInfoForm.addControl('sstDeRegDate', new FormControl(this.cpDetails?.clientSstDeRegDate ? this.cpDetails?.clientSstDeRegDate : ''));
    this.customerInfoForm.addControl('sstResidentStatus', new FormControl(this.cpDetails?.clientSstResidentStatus ? this.cpDetails?.clientSstResidentStatus : ''));
    this.customerInfoForm.addControl('sstCountry', new FormControl(this.cpDetails?.clientSstCountry ? this.cpDetails?.clientSstCountry : ''));

    if(this.cpDetails) {
      if (this.customerInfoForm.controls['sstRegisteredInd'].value === 'Y') {
        this.addValidators();
      } else {
        this.clearValidators();
      }
    }
  }
  
  onChangeSSTRegInd() {
    this.customerInfoForm.controls['sstRegisteredInd'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
         if (this.customerInfoForm.controls['sstRegisteredInd'].value === 'Y') {
          this.addValidators();
          this.addDefaultValue();
        }else {
          this.clearValidators();
          this.resetForm();
        }
    });
  }

  addDefaultValue() {
    this.customerInfoForm.controls['sstResidentStatus'].setValue('R');
    this.customerInfoForm.controls['sstCountry'].setValue('MAL');
  }

  addValidators() {
    this.customerInfoForm.controls['sstNo'].setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9]{17}$')]);
    this.customerInfoForm.controls['sstEffDate'].setValidators([Validators.required]);
    // this.customerInfoForm.controls['sstEffDate'].setValidators(Validators.required);
    // this.customerInfoForm.controls['sstDeRegDate'].setValidators();
    // this.customerInfoForm.controls['sstResidentStatus'].setValidators(Validators.required);
    // this.customerInfoForm.controls['sstCountry'].setValidators(Validators.required);

    this.customerInfoForm.updateValueAndValidity();
  }

  clearValidators() {
    this.customerInfoForm.controls['sstNo'].clearValidators();
    this.customerInfoForm.controls['sstEffDate'].clearValidators();
    this.customerInfoForm.updateValueAndValidity();

  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  resetForm() {
    this.customerInfoForm.get('sstNo', ).reset();
    this.customerInfoForm.get('sstEffDate', ).reset();
    this.customerInfoForm.get('sstDeRegDate').reset();
    this.customerInfoForm.get('sstResidentStatus').reset();
    this.customerInfoForm.get('sstCountry').reset();
  }
}
