<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>Agent Code & Product Selection</h1>
      </div>
    </div>
  </div>
</div>

<div class="agents">
  <div class="wrapper">
    <div class="select-agent-code-form nx-margin-bottom-3xl">
      <form [formGroup]="agentForm">
        <nx-formfield label="Customer Type">
          <nx-dropdown
            [showFilter]="false"
            filterPlaceholder=""
            [valueFormatter]="toText"
            name="customer_type"
            required
            formControlName="customer_type"
          >
            <nx-dropdown-item [value]="'FRANCHISE & OTHERS'"></nx-dropdown-item>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please select Customer Type</strong>
          </nx-error>
        </nx-formfield>

        <nx-formfield label="Select Branch">
          <nx-dropdown
            [showFilter]="false"
            filterPlaceholder=""
            [valueFormatter]="toText"
            name="branch"
            required
            formControlName="branch"
            #branch
          >
            <nx-dropdown-item [value]="'Alor Setar'"></nx-dropdown-item>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please select Branch</strong>
          </nx-error>
        </nx-formfield>

        <nx-formfield label="Search by Agent Code or Name">
          <nx-dropdown
            [showFilter]="true"
            filterPlaceholder=""
            [valueFormatter]="toText"
            name="agent"
            required
            formControlName="agent"
            #agent_code
          >
            <nx-dropdown-item [value]="'AS00151'"
              >AS00151 - P & O AGENCY</nx-dropdown-item
            >
            <nx-dropdown-item [value]="'AS00375'"
              >AS00375 - SYARIKAT WEI CHIN</nx-dropdown-item
            >
            <nx-dropdown-item [value]="'AS00584'"
              >AS00584 - KALA ENTERPRISE</nx-dropdown-item
            >
            <nx-dropdown-item [value]="'AS00592'"
              >AS00592 - PHAN HOY MUN</nx-dropdown-item
            >
            <nx-dropdown-item [value]="'AS00626'"
              >AS00626 - ONG SENG SWEE &#64; ONG AH BAH</nx-dropdown-item
            >
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please select an agent code or name.</strong>
          </nx-error>
        </nx-formfield>
        <a routerLink="../pdpa"
          ><button class="nx-margin-top-m" nxButton="primary" type="button">
            Next
          </button></a
        >
      </form>
    </div>
  </div>
</div>
