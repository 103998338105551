<div [formGroup]="extraCoverDetailForm">

  <app-driver-name [isComprehensive]="isComprehensive" [isThirdParty]="isThirdParty"
    [isThirdPartyFireTheft]="isThirdPartyFireTheft" [defaultDriver]="defaultDriver" [parentForm]="extraCoverDetailForm"
    (selectExtraCover)="selectExtraCover($event)" (removeExtraCover)="removeExtraCover($event)"
    (selectWaiver)="selectWaiver($event)" [coverageFmGroup]="coverageFmGroup" [partnerInfo]="partnerInfo"
    (selectAllDriver)="selectAllDriver($event)" (calculatePremium)="calculatePremium()">
  </app-driver-name>

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <hr />
    </div>
  </div>

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <h4 class="nx-margin-0">Extra Coverage</h4>
    </div>
  </div>
  @if(rahmahExtraCoverList?.length > 0 || topExtraCoverList?.length > 0){
  <div nxRow class="nx-margin-top-m">
    <div nxCol="12" class="nx-font-weight-semibold">These coverage(s) / service(s) are provided at no extra cost.</div>
  </div>
  }

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <table class="extra_package">
        <tbody>
          @if(subCovPackageList?.packageDescription && !costInd){
          <tr nxTableRow>
            <td class="cr14-package-description">
              <nx-checkbox [disabled]="!subCovPackageList.enable" [checked]="subCovPackageList.selected"
                labelSize="small" (change)="emitData(false, subCovPackageList.packageCode, 'topHeader')">
                <div class="nx-font-weight-bold">
                  {{ subCovPackageList?.packageDescription | uppercase}}
                </div>
              </nx-checkbox>
            </td>
          </tr>
          <tr nxTableRow *ngFor="let item of rahmahExtraCoverList; let i = index" [selectable]="true">
            <td class="cr14-si-description">
              <div class="sub_cover">
                <ul nxList>
                  <li nxListIcon="minus" size="s">
                    <p nxCopytext="medium">
                      {{ item.description || item.subCoverCodeDescription }}
                    </p>
                  </li>
                </ul>
              </div>
            </td>
            <td class="cr14-si" *ngIf="item.sumInsuredDisplay">
              <span [innerHTML]="removeNewLinesFromOpus(item.sumInsuredDisplay, item, 'top')"></span>
            </td>
            <td class="nx-hidden-m cr14-si"></td>
          </tr>
          }
          <!-- top = true, disable = true -->
          <tr nxTableRow *ngFor="let item of topExtraCoverList; let i = index" [selectable]="true">
            <td class="cell-padding-bottom cr14-package-description">
              <nx-checkbox (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                (change)="emitData(selection.select(item), item.subCoverCode, 'topTableHeader')"
                [value]="item.subCoverCode" [checked]="item.selected" [disabled]="!item.enabled">
                <div class="nx-font-weight-bold">
                  {{ item.description || item.subCoverCodeDescription }}
                </div>
              </nx-checkbox>
            </td>
            <td class="mrw-text-align border-top-none cr14-si"
              *ngIf="item.mtExtCovDtoList && item.mtExtCovDtoList.length > 0">
              <div> <span [innerHTML]="removeNewLinesFromOpus(item.sumInsuredDisplay, item, 'top')"></span>
              </div>
            </td>
            <td class="nx-hidden-m cr14-si"></td>
          </tr>
        </tbody>
      </table>
      @if(subCovPackageList?.packageDescription && costInd){
      <div nxRow class="nx-margin-top-m">
        <div nxCol="9" class="nx-font-weight-semibold cell-padding-bottom">Extra Coverage Package</div>
      </div>
      <table class="extra_package">
        <tbody>
          <tr nxTableRow>
            <td class="cr14-package-description">
              <nx-checkbox [disabled]="!subCovPackageList.enable" [checked]="subCovPackageList.selected"
                labelSize="small" (change)="emitData(false, subCovPackageList.packageCode, 'topHeader')">
                <div class="nx-font-weight-bold">
                  {{ subCovPackageList?.packageDescription | uppercase}}
                </div>
              </nx-checkbox>
            </td>
          </tr>
          <tr nxTableRow *ngFor="let item of rahmahExtraCoverList; let i = index" [selectable]="true">
            <td class="cr14-si-description">
              <div class="sub_cover">
                <ul nxList>
                  <li nxListIcon="minus" size="s">
                    <p nxCopytext="medium">
                      {{ item.description || item.subCoverCodeDescription }}
                    </p>
                  </li>
                </ul>
              </div>
            </td>
            <td class="cr14-si" *ngIf="item.sumInsuredDisplay">
              <span nxCopytext="medium"
                [innerHTML]="removeNewLinesFromOpus(item.sumInsuredDisplay, item, 'top')"></span>
            </td>
            <td class="nx-hidden-m cr14-si"></td>
          </tr>
        </tbody>
      </table>
      }
      <div class="nx-margin-top-m">
        <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
          <table nxTable class="more_extra_cover">
            <tbody>
              <tr nxTableRow class="table_row_flex">
                <th nxHeaderCell class="table-cov">
                  <nx-checkbox class="nx-font-weight-semibold" formControlName="checkedAll"
                    (change)="checkedAllChange()" forcheckedAllChange>
                    <div class="nx-font-weight-semibold">
                      Extra Cover
                    </div>
                  </nx-checkbox>
                </th>
                <th nxHeaderCell class="table-cov"></th>
                <th nxHeaderCell class="table-cov">Premium (RM)</th>
              </tr>
              <!-- start selected = true -->
              <tr nxTableRow class="table_row_flex" *ngFor="let item of extraCoverList;" [selectable]="true">
                <td nxTableCell class="table-cov">
                  <nx-checkbox labelSize="small" class="nx-font-weight-semibold"
                    (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                    (change)="emitData(selection.select(item), item.subCoverCode, 'bottom')" [value]="item.subCoverCode"
                    [checked]="true" [disabled]="!item.enabled">
                    {{ item.description || item.subCoverCodeDescription }}
                    <!-- Error for for small screen -->
                    <div *ngIf="item.mtExtCovDtoList && item.mtExtCovDtoList.length > 0" class="error-container-small">
                      <ng-container *ngFor="let subItem of item.mtExtCovDtoList">
                        <nx-error
                          *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode != 112 && extraCoverDetailForm.controls[item.subCoverCode]?.hasError('required') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Plan Required.</strong>
                        </nx-error>
                        <nx-error
                          *ngIf="subItem.type === 'INPUT' && extraCoverDetailForm.controls[item.subCoverCode]?.hasError('required') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Amount Required.</strong>
                        </nx-error>
                        <nx-error
                          *ngIf="subItem.type === 'INPUT' && extraCoverDetailForm.controls[item.subCoverCode]?.hasError('min') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Only positive number is allowed</strong>
                        </nx-error>
                        <nx-error
                          *ngIf="subItem.type === 'INPUT' && !extraCoverDetailForm.controls[item.subCoverCode]?.hasError('min') &&
                          extraCoverDetailForm.controls[item.subCoverCode]?.hasError('pattern') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Maximum sum insured exceeded system limit.</strong>
                        </nx-error>
                        <nx-error
                          *ngIf="subItem.type === 'CHECKBOX_P2P' && extraCoverDetailForm.controls['CHECKBOX_P2P']?.hasError('required') && extraCoverDetailForm.controls['CHECKBOX_P2P']?.touched"
                          nxFormfieldNote>
                          <strong>Provider Name Required.</strong>
                        </nx-error>
                        <nx-error
                          *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'NO OF DAY' && extraCoverDetailForm.controls['112_NO_OF_DAY']?.hasError('required') && extraCoverDetailForm.controls['112_NO_OF_DAY']?.touched"
                          nxFormfieldNote>
                          <strong>No. of Days Required.</strong>
                        </nx-error>
                        <nx-error style="padding-top:5px"
                          *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'AMOUNT PER DAY' && extraCoverDetailForm.controls['112_AMOUNT_PER_DAY']?.hasError('required') && extraCoverDetailForm.controls['112_AMOUNT_PER_DAY']?.touched"
                          nxFormfieldNote>
                          <strong>Amount Per Day Required.</strong>
                        </nx-error>
                      </ng-container>
                    </div>
                  </nx-checkbox>

                  @if(item.cngeSubCoverBenefitDtoList && item.cngeSubCoverBenefitDtoList.length > 0){
                  <ng-container *ngIf="item.cngeSubCoverBenefitDtoList?.length;">
                    <div class="sub_cover">
                      <ul nxList>
                        <li nxListIcon="minus" size="s" *ngFor="let subItem of item.cngeSubCoverBenefitDtoList">
                          <div class="" nxCopytext="medium">
                            <div class="">{{ subItem.subCoverBenefitDescp }}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                  }
                </td>

                <td nxTableCell class="std-ta-right table-cov input-padding"
                  *ngIf="!item.mtExtCovDtoList || item.mtExtCovDtoList.length === 0">
                  <nx-natural-language-form size="small" class="margin-top-si">
                    <span [innerHTML]="removeNewLinesFromOpus(item.sumInsuredDisplay, item, 'middle')"></span>
                  </nx-natural-language-form>
                </td>

                <td nxTableCell class="std-ta-right table-cov"
                  *ngIf="item.mtExtCovDtoList && item.mtExtCovDtoList.length > 0">
                  <ng-container *ngFor="let subItem of item.mtExtCovDtoList">
                    <ng-container *ngIf="subItem.type === 'DISPLAY'">
                      <nx-natural-language-form size="small" class="margin-top-si">
                        <span [innerHTML]="removeNewLinesFromOpus(item.sumInsuredDisplay, item, 'middle')"></span>
                      </nx-natural-language-form>
                    </ng-container>
                    <ng-container *ngIf="subItem.type === 'INPUT'">
                      <nx-natural-language-form size="small" class="margin-top-si">{{ subItem.label }}
                        <nx-word class="padding-top-si-1">
                          <input nxInput type="text" [formControlName]="item.subCoverCode" required
                            [value]="item.subCoverSumInsured | formatDecimal"
                            (change)="onChangeSubCoverSumInsured(item.subCoverCode, $event.target, subItem.type)"
                            [disabled]="subItem.disable" maxlength="13" />
                        </nx-word>
                      </nx-natural-language-form>
                      <div class="error-container">
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls[item.subCoverCode]?.hasError('required') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Amount Required.</strong>
                        </nx-error>
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls[item.subCoverCode]?.hasError('min') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Only positive number is allowed</strong>
                        </nx-error>
                        <nx-error
                          *ngIf="!extraCoverDetailForm.controls[item.subCoverCode]?.hasError('min') &&
                          extraCoverDetailForm.controls[item.subCoverCode]?.hasError('pattern') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Maximum sum insured exceeded system limit.</strong>
                        </nx-error>
                      </div>
                    </ng-container>

                    <!-- Dropdown field (if subItem.type is 'DROPDOWN') -->
                    <ng-container *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode != 112">
                      <div class="dropdown-container">
                        <span class="display-flex-container">
                          <span class="label">{{ subItem.label }}:</span>
                          <nx-formfield class="formfield_no_padding coverage-dropdown">
                            <nx-dropdown [formControlName]="item.subCoverCode" [panelMaxWidth]="2000" [panelGrow]="true"
                              [value]="item.planCode"
                              (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)">
                                <ng-container *ngFor="let dropdownitem of subItem.data">
                                  <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown ">
                                    <span class="dropdown-item-desc">{{ dropdownitem.descp }}</span>
                                  </nx-dropdown-item>
                                </ng-container>
                            </nx-dropdown>
                          </nx-formfield>
                        </span>
                      </div>
                      @if(item.planCode && (item.subCoverCode == 'PAB-RW' || item.subCoverCode == 'PAB-ERW' ||
                      item.subCoverCode == 'PAB3')){
                      <span [innerHTML]="removeNewLinesFromOpus(item.sumInsuredDisplay, item, 'middle')"></span>
                      }
                      <div class="error-container">
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls[item.subCoverCode]?.hasError('required') && extraCoverDetailForm.controls[item.subCoverCode]?.touched"
                          nxFormfieldNote>
                          <strong>Plan Required.</strong>
                        </nx-error>
                      </div>
                    </ng-container>

                    <!-- p2p multi checkbox -->
                    <ng-container *ngIf="subItem.type === 'CHECKBOX_P2P'">
                      <div class="dropdown-container">
                        <span>
                          <span class="display-flex-container"
                            [ngClass]="{'display-block-container': this.p2pCodeList.length > 0}">
                            <span class="nx-margin-right-3xs">SERVICE PROVIDER:</span>
                            <nx-formfield class="formfield_no_padding multi_dropdown">
                              <nx-multi-select formControlName="CHECKBOX_P2P" [options]="subItem.data"
                                selectLabel="descp" selectValue="code" (selectionChange)="selectionChange($event)">
                              </nx-multi-select>
                            </nx-formfield>
                          </span>
                        </span>
                      </div>
                      <div class="error-container">
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls['CHECKBOX_P2P']?.hasError('required') && extraCoverDetailForm.controls['CHECKBOX_P2P']?.touched"
                          nxFormfieldNote>
                          <strong>Provider Name Required.</strong>
                        </nx-error>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'NO OF DAY'">
                      <div class="dropdown-container">
                        <span>
                          <span class="display-inline-flex nx-margin-right-3xs"><span class="label-cart">{{
                              subItem.label }}:</span>
                            <nx-formfield class="formfield_no_padding coverage-dropdown">
                              <nx-dropdown formControlName="112_NO_OF_DAY"
                                [value]="getSubCoverNumberOfDaysAsString(item.subCoverDays)"
                                (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                                [panelMaxWidth]="2000" [panelGrow]="true" style="padding-top: 5px">
                                <ng-container *ngFor="let dropdownitem of subItem.data">
                                  <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                                  </nx-dropdown-item>
                                </ng-container>
                              </nx-dropdown>
                            </nx-formfield>
                          </span>
                        </span>
                      </div>
                      <div class="error-container">
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls['112_NO_OF_DAY']?.hasError('required') && extraCoverDetailForm.controls['112_NO_OF_DAY']?.touched"
                          nxFormfieldNote>
                          <strong>No. of Days Required.</strong>
                        </nx-error>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'AMOUNT PER DAY'">
                      <div class="dropdown-container">
                        <span>
                          <span class="display-inline-flex nx-margin-right-3xs"><span class="label-cart">{{
                              subItem.label }}:</span>
                            <nx-formfield class="formfield_no_padding coverage-dropdown">
                              <nx-dropdown formControlName="112_AMOUNT_PER_DAY" [panelMaxWidth]="2000"
                                [panelGrow]="true" [value]="item.subCoverAmtday"
                                (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                                style="padding-top: 5px">
                                <ng-container *ngFor="let dropdownitem of subItem.data">
                                  <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                                  </nx-dropdown-item>
                                </ng-container>
                              </nx-dropdown>
                            </nx-formfield>
                          </span>
                        </span>
                      </div>
                      <div class="error-container">
                        <nx-error
                          *ngIf="extraCoverDetailForm.controls['112_AMOUNT_PER_DAY']?.hasError('required') && extraCoverDetailForm.controls['112_AMOUNT_PER_DAY']?.touched"
                          nxFormfieldNote>
                          <strong>Amount Per Day Required.</strong>
                        </nx-error>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>

                <td nxTableCell class="std-ta-right table-cov">
                  @if(item.subCoverPrem > 0){{{ item.subCoverPrem | formatDecimal }}}
                </td>
              </tr>

              <!-- start top = true selected = false -->
              <tr nxTableRow class="table_row_flex" *ngFor="let item of defaultedExtraCoverList;" [selectable]="true">
                <td nxTableCell class="table-cov">
                  <nx-checkbox labelSize="small" class="nx-font-weight-semibold"
                    (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                    (change)="emitData(selection.select(item), item.subCoverCode, 'defaultedExtraCover')"
                    [value]="item.subCoverCode" [checked]="false" [disabled]="!item.enabled">
                    {{ item.description || item.subCoverCodeDescription }}
                  </nx-checkbox>
                </td>


                <ng-container *ngIf="item.mtExtCovDtoList?.length && item.selected === true; else emptyCell">
                  <td *ngFor="let subItem of item.mtExtCovDtoList" nxTableCell class="std-ta-right table-cov">
                    <nx-natural-language-form size="small">
                      {{ subItem.label }}
                      <nx-word>
                        <input nxInput type="text" [disabled]="!item.enabled" />
                      </nx-word>
                    </nx-natural-language-form>
                  </td>
                </ng-container>

                <ng-template #emptyCell>
                  <td nxTableCell class="std-ta-right table-cov"></td>
                </ng-template>

                <td nxTableCell class="std-ta-right table-cov">
                  @if(item.subCoverPrem > 0){{{ item.subCoverPrem | formatDecimal }}}
                </td>
              </tr>
              <!-- end top = true selected = false -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div nxRow class="nx-margin-top-m">
  <div nxCol="12">
    <nx-link>
      <a (click)="openPopupComp('comprehensive')" [attr.disabled]="disableAddMore ? true : null"
        [ngStyle]="{'pointer-events': disableAddMore ? 'none' : 'auto', 'opacity': disableAddMore ? '0.5' : '1'}">
        <nx-icon name="plus" nxIconPositionStart></nx-icon>
        ADD MORE
      </a>
    </nx-link>
  </div>
</div>
@if(privateHireCarIsSelected){
<div nxRow>
  <div nxCol="12">
    <hr class="nx-margin-y-2m" />
  </div>
</div>

<div nxRow>
  <div nxCol="12">
    <app-e-hailing-driver [parentForm]="extraCoverDetailForm"></app-e-hailing-driver>
  </div>
</div>
}