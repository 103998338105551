<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>ISM Policy / Endorsement Data Upload</h1>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #templateThree> -->
<form [formGroup]="updateEndorsementDataUploadForm">
  <div nxLayout="grid maxwidth" class="nx-margin-bottom-3xl">
    <div nxRow>
      <div nxCol="12">
        <ng-container *ngIf="ismPolicyMessage && !success">
          <nx-message context="error" class="nx-margin-bottom-m">
            <span class="nx-font-weight-bold">ISM Status: NOT OK. Failed to update {{ismPolicyMessage}}</span>
          </nx-message>
        </ng-container>

        <!-- ISM Status: NOT OK. Failed to update ISM. IST054 - Duplicate data. -->
        <ng-container *ngIf="ismPolicyMessage && success">
          <nx-message context="success" class="nx-margin-bottom-m">
            <span class="nx-font-weight-bold">ISM Status: OK. ISM has been updated successfully.</span>
          </nx-message>
        </ng-container>
      </div>
    </div>

    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Insurance Code">
          <input nxInput value="216" formControlName="insCode" appToUpperCase/>
          <nx-error nxFormfieldError>
            <strong>Insurance Code Required</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Document Type">
          <nx-dropdown formControlName="docType" (selectionChange)="onChangeFormValue('docType',$event.value)">
            <ng-container *ngFor="let item of documentTypeList">
              <nx-dropdown-item [value]="item.cmgcCoderelHdrPk.code">{{ item.descp | uppercase }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Document Type Required</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Reason Code">
          <nx-dropdown formControlName="reasonCode" [disabled]="true"
            (selectionChange)="onChangeFormValue('reasonCode',$event.value)">
            <ng-container *ngFor="let item of filteredReasonCodeList">
              <nx-dropdown-item [value]="item.cmgcCoderelDetPk.code">{{ item.descp | uppercase }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Reason Code Required</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Policy No." (click)="openPopupPolicyEnquiry()">
          <input nxInput formControlName="policyNo" appToUpperCase />
          <nx-error nxFormfieldError>
            <strong>Policy No. Required</strong>
          </nx-error>
          <span nxFormfieldSuffix>
            <nx-icon aria-hidden="true" name="search"></nx-icon>
          </span>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Vehicle No.">
          <input nxInput type="text" formControlName="vehicleNo" appToUpperCase />
          <!-- <span nxFormfieldHint> eg. PUTRAJAYA1234 </span> -->
          <nx-error nxFormfieldError>
            <strong>Vehicle No. Required</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Policy Effective Date">
          <input nxDatefield nxInput [datepicker]="myDatepicker" formControlName="effectiveDate" />
          <span nxFormfieldHint>DD/MM/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker></nx-datepicker>
          <nx-error nxFormfieldError>
            <strong>Policy Effective Date Required</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Policy Expiry Date">
          <input nxDatefield nxInput [datepicker]="myDatepicker2" formControlName="expiryDate" />
          <span nxFormfieldHint>DD/MM/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker2></nx-datepicker>
          <nx-error nxFormfieldError>
            <strong>Policy Expiry Date Required</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Customer Name">
          <input nxInput formControlName="insuredName" />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="ID No. 1">
          <input nxInput formControlName="idNo1" maxlength="12" appToUpperCase/>
          <nx-error nxFormfieldError>
            <strong>ID No. 1 required.</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="ID No. 2">
          <input nxInput formControlName="idNo2" appToUpperCase/>
          <nx-error nxFormfieldError>
            <strong>ID No. 2 required.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Engine / Motor No.">
          <input nxInput formControlName="vehicleEngine" appToUpperCase/>

          <nx-error nxFormfieldError>
            <strong>Engine / Motor No. required.</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Chassis No.">
          <input nxInput formControlName="vehicleChassis" appToUpperCase/>

          <nx-error nxFormfieldError>
            <strong>Chassis No. required.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Claim Free Years (CFY)">
          <input nxInput value="0" formControlName="ncdLvl" type="number"
            (change)="onChangeFormValue('ncdLvl',$event)" />
          <nx-error nxFormfieldError>
            @if (updateEndorsementDataUploadForm.get('ncdLvl').errors?.['required']) {
            <strong>Claim Free Years (CFY) required.</strong>
            }

            @if (updateEndorsementDataUploadForm.get('ncdLvl').errors?.['invalidValue']) {
            <strong>Claim Free Year (CFY) out of range.</strong>
            }


          </nx-error>
        </nx-formfield>

        <!-- <button nxButton="primary small" type="button">
          CFY not within range 0 - 100
        </button> -->
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="No Claim Discount Effective Date">
          <input nxDatefield nxInput [datepicker]="myDatepicker3" formControlName="ncdEffectiveDate" />
          <span nxFormfieldHint>DD/MM/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker3" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker3></nx-datepicker>

          <nx-error nxFormfieldError>
            <strong>No Claim Discount Effective Date required.</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="No Claim Discount Change Date">
          <input nxDatefield nxInput [datepicker]="myDatepicker4" formControlName="ncdChgDate" />
          <span nxFormfieldHint>DD/MM/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker4" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker4></nx-datepicker>

          <nx-error nxFormfieldError>
            <strong>No Claim Discount Change Date required.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Coverage Type">
          <nx-dropdown formControlName="subcls">
            <ng-container *ngFor="let item of coverageTypeList">
              <nx-dropdown-item [value]="item.coverCode">{{ item.coverDescription | uppercase }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>

          <nx-error nxFormfieldError>
            <strong>Coverage Type required</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Vehicle Class">
          <nx-dropdown formControlName="vehCls" (selectionChange)="onChangeFormValue('vehCls',$event.value)">
            <ng-container *ngFor="let item of vehicleClassList">
              <nx-dropdown-item [value]="item.code">{{ item.descp | uppercase }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>

          <nx-error nxFormfieldError>
            <strong>Vehicle Class required</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Use of Vehicle">
          <nx-dropdown formControlName="vehicleUse">
            <ng-container *ngFor="let item of vehicleUselov">
              <nx-dropdown-item [value]="item.code">{{ item.description | uppercase }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>

          <nx-error nxFormfieldError>
            <strong>Use of Vehicle required</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Gross Premium (RM)">
          <input nxInput value="2,190.35" formControlName="grossPremium" type="number" />
          <nx-error nxFormfieldError>
            <strong>Gross Premium (RM) required</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-s std-ta-right">
        <button (click)="resetForm()" nxButton="secondary" type="button" class="nx-margin-right-s">
          RESET
        </button>
        <button (click)="onUpdateClicked()" nxButton="primary" type="button" class="m_margin_top_20">
          UPDATE
        </button>
      </div>
    </div>
  </div>
</form>
<!-- </ng-template> -->