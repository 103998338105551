<form [formGroup]="customerInfoForm">
    <div nxRow>
        <div nxCol="12" class="nx-margin-bottom-30">
            <span nxCopytext="normal">SST Registered?</span><br />
            <nx-radio-group formControlName="sstRegisteredInd">
                <div class="radio-group nx-margin-top-xs">
                    <nx-radio name="group1" value="Y">Yes</nx-radio>
                    <nx-radio name="group1" class="nx-margin-left-2m" value="N">No</nx-radio>
                </div>
            </nx-radio-group>
        </div>
    </div>

    @if (customerInfoForm.value.sstRegisteredInd === 'Y') {
    <div >
        <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="SST No.">
                    <input nxInput formControlName="sstNo" maxlength="17" appToUpperCase/>
                    @if (customerInfoForm.get('sstNo').errors?.['required']) {
                        <nx-error nxFormfieldError>
                            <strong>Please enter SST No.</strong>
                        </nx-error>
                    }
                    @if (customerInfoForm.get('sstNo').errors?.['pattern']) {
                        <nx-error nxFormfieldError>
                            <strong>The Service Tax Registration No. cannot be less or more than 17.</strong>
                        </nx-error>
                    }
                </nx-formfield>
            </div>
        </div>
        <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="SST Effective Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker1" formControlName="sstEffDate" [parseFormat]="['DD/MM/YYYY']" displayFormat="DD/MM/YYYY"/>
                    <span nxFormfieldHint>DD/MM/YYYY</span>
                    <nx-datepicker-toggle [for]="myDatepicker1" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker1></nx-datepicker>

                    <nx-error nxFormfieldError>
                        <strong> Please enter SST Effective Date</strong>
                    </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="SST De-Registration Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker2" [disabled]="true"
                        formControlName="sstDeRegDate" [parseFormat]="['DD/MM/YYYY']" displayFormat="DD/MM/YYYY"/>
                    <span nxFormfieldHint>DD/MM/YYYY</span>
                    <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker2></nx-datepicker>
                </nx-formfield>
            </div>
        </div>
        <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="SST Resident Status">
                    <nx-dropdown [valueFormatter]="toText" formControlName="sstResidentStatus">
                        @for(addressType of addressTypeList; track addressType) {
                            <nx-dropdown-item [value]="addressType.code">{{ addressType.descp }}</nx-dropdown-item>
                        }
                    </nx-dropdown>
                </nx-formfield>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="SST Country">
                    <nx-dropdown [valueFormatter]="toText" formControlName="sstCountry">
                        @for(country of countryList; track country) {
                            <nx-dropdown-item [value]="country.code">{{ country.descp }}</nx-dropdown-item>
                        }
                    </nx-dropdown>
                </nx-formfield>
            </div>
        </div>
    </div>
    }
</form>