import * as i0 from '@angular/core';
import { Optional, Inject, NgModule } from '@angular/core';
import { NxDateAdapter, NX_DATE_LOCALE, NX_DATE_LOCALE_PROVIDER, NX_DATE_FORMATS } from '@aposin/ng-aquila/datefield';
import { __decorate, __param } from 'tslib';
import * as _rollupMoment from 'moment';
import _rollupMoment__default from 'moment';
const moment = _rollupMoment__default || _rollupMoment;
/** Creates an array and fills it with values. */
function range(length, valueFunction) {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
/** @docs-private */
let NxMomentDateAdapter = class NxMomentDateAdapter extends NxDateAdapter {
  constructor(dateLocale) {
    super();
    this.setLocale(dateLocale || moment.locale());
  }
  setLocale(locale) {
    super.setLocale(locale);
    const momentLocaleData = moment.localeData(locale);
    this._localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: range(31, i => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin()
    };
  }
  toIso8601(date) {
    return this.clone(date).format();
  }
  parse(value, format, strict) {
    if (value && typeof value === 'string') {
      return moment.utc(value, format, this.locale, strict);
    }
    return value ? moment.utc(value).locale(this.locale) : null;
  }
  format(date, displayFormat) {
    date = this.clone(date);
    if (!this.isValid(date)) {
      throw Error('MomentDateAdapter: Cannot format invalid date.');
    }
    return date.format(displayFormat);
  }
  isValid(date) {
    return this.clone(date).isValid();
  }
  clone(date) {
    return date.clone().locale(this.locale);
  }
  isDateInstance(obj) {
    return moment.isMoment(obj);
  }
  getYear(date) {
    return this.clone(date).year();
  }
  getMonth(date) {
    return this.clone(date).month();
  }
  getDate(date) {
    return this.clone(date).date();
  }
  deserialize(value) {
    let date;
    if (value instanceof Date) {
      date = moment.utc({
        year: value.getFullYear(),
        month: value.getMonth(),
        date: value.getDate()
      });
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = moment.utc(value, moment.ISO_8601).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return date;
    }
    return super.deserialize(value);
  }
  invalid() {
    return moment.invalid();
  }
  getYearName(date) {
    return this.clone(date).format('YYYY');
  }
  createDate(year, month, date) {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    const result = moment.utc({
      year,
      month,
      date
    }).locale(this.locale);
    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return result;
  }
  getNumDaysInMonth(date) {
    return this.clone(date).daysInMonth();
  }
  getDateNames() {
    return this._localeData.dates;
  }
  getDayOfWeek(date) {
    return this.clone(date).day();
  }
  getFirstDayOfWeek() {
    return this._localeData.firstDayOfWeek;
  }
  getMonthNames(style) {
    // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
    return style === 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
  }
  today() {
    return moment.utc().locale(this.locale);
  }
  addCalendarMonths(date, months) {
    return this.clone(date).add({
      months
    });
  }
  addCalendarDays(date, days) {
    return this.clone(date).add({
      days
    });
  }
  getDayOfWeekNames(style) {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }
  addCalendarYears(date, years) {
    return this.clone(date).add({
      years
    });
  }
};
NxMomentDateAdapter = __decorate([__param(0, Optional()), __param(0, Inject(NX_DATE_LOCALE))], NxMomentDateAdapter);
const NX_MOMENT_DATE_FORMATS = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
class MomentDateModule {
  static {
    this.ɵfac = function MomentDateModule_Factory(t) {
      return new (t || MomentDateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MomentDateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NX_DATE_LOCALE_PROVIDER, {
        provide: NxDateAdapter,
        useClass: NxMomentDateAdapter,
        deps: [NX_DATE_LOCALE]
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MomentDateModule, [{
    type: NgModule,
    args: [{
      providers: [NX_DATE_LOCALE_PROVIDER, {
        provide: NxDateAdapter,
        useClass: NxMomentDateAdapter,
        deps: [NX_DATE_LOCALE]
      }]
    }]
  }], null, null);
})();
class NxMomentDateModule {
  static {
    this.ɵfac = function NxMomentDateModule_Factory(t) {
      return new (t || NxMomentDateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxMomentDateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: NX_DATE_FORMATS,
        useValue: NX_MOMENT_DATE_FORMATS
      }],
      imports: [MomentDateModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxMomentDateModule, [{
    type: NgModule,
    args: [{
      imports: [MomentDateModule],
      providers: [{
        provide: NX_DATE_FORMATS,
        useValue: NX_MOMENT_DATE_FORMATS
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MomentDateModule, NX_MOMENT_DATE_FORMATS, NxMomentDateAdapter, NxMomentDateModule };
