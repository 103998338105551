<div class="title-header">
    <div nxLayout="grid maxwidth">
        <h1>Private Cars Excluding Goods</h1>
    </div>
</div>

<div nxLayout="grid maxwidth">
    <div nxRow>
        <div nxCol="12,12,12,3" class="grid_width_page">
            <!-- 
      Note:
      Progress type:
      1. cn = cover note and quotation progress bar, 5 step
      2. endorsement = endorsement progress bar, 4 step
      3. unification = unification progress bar, 6 step
      4. rtp = road tax purpose progress bar, 3 step

      progressvalue = step index
      -->
            <app-progress-bar [progressValue]="4" [progressType]="'cn'" />
            <!-- <app-progress-bar /> -->
        </div>
        <div nxCol="12,12,12,9" class="grid_width_page">

            <h2 class="nx-margin-bottom-2m">Complete</h2>

            <!-- successful message for e-Quotation (to be move to new component) -->
            <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span
                    class="nx-font-weight-bold">Your e-Quotation has been successfully generated.</span></nx-message>

            <!-- successful message for e-Cover Note (to be move to new component) -->
            <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span
                    class="nx-font-weight-bold">Your e-Cover Note has been successfully generated.</span></nx-message>

            <!-- successful message for Endorsement (to be move to new component) -->
            <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span
                    class="nx-font-weight-bold">Your Endorsement has been successfully generated.</span></nx-message>

            <!-- cancel message for e-Quotation (to be move to new component) -->
            <nx-message context="info" class="green_color_notification nx-margin-bottom-2m"><span
                    class="nx-font-weight-bold">e-Quotation has been cancelled successfully.</span></nx-message>

            <!-- pending approval (to be move to new component) -->
            <nx-message context="info" class="green_color_notification nx-margin-bottom-2m">
                <span class="nx-font-weight-bold">Your e-Cover Note has been submitted as pending approval.</span>

                <div class="nx-margin-bottom-s"></div>

                <span nxCopytext="normal">Referral reason:
                    <ul nxList="small" class="completed_list">
                        <li><span class="nx-font-weight-bold">CM005</span> - Renew Sum Insured / Refer</li>
                        <li><span class="nx-font-weight-bold">CM011</span> - High Performance</li>
                    </ul>
                </span>

            </nx-message>

            <nx-card class="completed_card">

                <!-- Printed Status for e-Quotation (to be move to new component) -->
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="nx-margin-0">e-Quotation</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status">
                            <nx-badge type="active">Printed</nx-badge>
                        </div>
                    </div>
                </div>

                <h2 class="nx-margin-bottom-m nx-margin-top-0">Private Cars Excluding Goods</h2>

                <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">QAS23070000384</h3>
                <p nxCopytext="normal" class="nx-margin-bottom-m">has been successfully downloaded. You may save a
                    digital copy of the e-Quotation by clicking on the Download
                    e-Quotation button below.</p>

                <!-- Printed Status for e-Cover Note (to be move to new component) -->
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="nx-margin-0">e-Cover Note</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="active">Printed</nx-badge>
                        </div>
                    </div>
                </div>

                <h2 class="nx-margin-bottom-m nx-margin-top-0">Private Cars Excluding Goods</h2>

                <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">AEAS0003212</h3>
                <p nxCopytext="normal" class="nx-margin-bottom-m">has been successfully downloaded. You may save a
                    digital copy of the e-Cover Note by clicking on the Download
                    e-Cover Note button below.</p>

                <!-- Printed Status for e-Quotation and e-Cover Note (to be move to new component) -->
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="nx-font-weight-semibold nx-margin-0">e-Quotation / e-Cover Note</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="active">Printed</nx-badge>
                        </div>
                    </div>
                </div>

                <h2 class="nx-margin-bottom-m nx-margin-top-0">Private Cars Excluding Goods</h2>

                <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">QAS23070000384 /
                    AEAS0003212</h3>
                <p nxCopytext="normal" class="nx-margin-bottom-m">has been successfully downloaded. You may save a
                    digital copy of the e-Cover Note by clicking on the Download
                    e-Cover Note button below.</p>

                <!-- Printed Status for Endorsement (to be move to new component) -->
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="nx-margin-0">Endorsement</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="active">Printed</nx-badge>
                        </div>
                    </div>
                </div>

                <h2 class="nx-margin-bottom-m nx-margin-top-0">Private Cars Excluding Goods</h2>

                <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">AEKL0034042</h3>
                <p nxCopytext="normal" class="nx-margin-bottom-m">has been issued. You may save a digital copy of the
                    Endorsement by clicking on the Download Endorsement Schedule
                    button below.</p>

                <!-- ////////////////////////////////////////////////////////////////////////////// -->

                <div class="button-container nx-margin-y-m">
                    <button nxButton="primary small" type="button">
                        <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>Endorsement Schedule
                        PDF
                    </button>
                    <button nxButton="primary small" type="button">
                        <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>Endorsement
                        Certificate PDF
                    </button>
                    <button nxButton="primary small" type="button">
                        <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>e-Invoice PDF
                    </button>
                    <button nxButton="primary small" type="button">Upload
                        Document</button>
                </div>
                <!-- ////////////////////////////////////////////////////////////////////////////// -->

                <!-- Pending Approval Status (to be move to new component) -->
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="nx-margin-0">e-Quotation</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="active" class="pending_badge">Pending Approval</nx-badge>
                        </div>
                    </div>
                </div>

                <h2 class="nx-margin-bottom-m nx-margin-top-0">Private Cars Excluding Goods</h2>

                <h3 nxHeadline size="m" class="nx-font-weight-semibold completed_color_primary">QAS23070000384</h3>

                <p nxCopytext="normal">The e-Quotation you have just submitted is a referral case based on the following
                    reason(s) :</p>
                <ul nxList="small" class="completed_list nx-margin-bottom-m">
                    <li><span class="nx-font-weight-bold">CM005</span> - Renew Sum Insured / Refer</li>
                    <li><span class="nx-font-weight-bold">CM011</span> - High Performance</li>
                </ul>

                <!-- Cancelled Status (to be move to new component) -->
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="nx-margin-0">e-Quotation</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="critical">Cancelled</nx-badge>
                        </div>
                    </div>
                </div>

                <!-- Approved Status (to be move to new component) -->
                <div nxLayout="grid nopadding" class="completed_status nx-margin-bottom-m">
                    <div nxRow class="completed_status">
                        <div nxCol="12,12,10">
                            <h2 class="nx-margin-0">e-Quotation</h2>
                        </div>
                        <div nxCol="12,12,2" class="completed_status ">
                            <nx-badge type="positive">Approved</nx-badge>
                        </div>
                    </div>
                </div>




                <div class="button-container nx-margin-y-m">
                    <button nxButton="secondary small" type="button"
                        (click)="openPopup('popupreasoncancellation')">Request Cancellation</button>
                    <button nxButton="secondary small" type="button"
                        (click)="openPopup('popupreferralremarks')">Referral Remarks</button>
                    <button nxButton="primary small" type="button">
                        <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>e-Quotation PDF
                    </button>
                    <button nxButton="primary small" type="button" (click)="openPopup4('popupecovernote')">
                        <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>e-Cover Note PDF
                    </button>
                    <button nxButton="primary small" type="button">
                        <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>e-Invoice PDF
                    </button>
                    <button nxButton="primary small" type="button" (click)="openPopup2('uploaddocument')">Upload
                        Document</button>
                </div>

                <hr class="nx-margin-bottom-m">

                <div nxLayout="grid nopadding">
                    <div nxRow>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Agent Code</nx-data-display-label>
                                <p nxCopytext="large">AS00584</p>
                            </nx-data-display>
                        </div>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Agent Name</nx-data-display-label>
                                <p nxCopytext="large">KALA ENTERPRISE</p>
                            </nx-data-display>
                        </div>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Issued By</nx-data-display-label>
                                <p nxCopytext="large">HESTER_WONG</p>
                            </nx-data-display>
                        </div>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Customer Name</nx-data-display-label>
                                <p nxCopytext="large">WONG KAM WAI</p>
                            </nx-data-display>
                        </div>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Effective Date</nx-data-display-label>
                                <p nxCopytext="large">13/12/2023</p>
                            </nx-data-display>
                        </div>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Expiry Date</nx-data-display-label>
                                <p nxCopytext="large">12/12/2024</p>
                            </nx-data-display>
                        </div>

                        <!-- Endorsement (to be move to new component) -->
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Customer Name</nx-data-display-label>
                                <p nxCopytext="large">WONG KAM WAI</p>
                            </nx-data-display>
                        </div>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Endorsement Effective
                                    Date</nx-data-display-label>
                                <p nxCopytext="large">13/12/2023</p>
                            </nx-data-display>
                        </div>
                        <div nxCol="12,12,4" class="nx-margin-bottom-m">
                            <nx-data-display size="small">
                                <nx-data-display-label class="font-grey">Endorsement Expiry Date</nx-data-display-label>
                                <p nxCopytext="large">12/12/2024</p>
                            </nx-data-display>
                        </div>
                    </div>
                </div>
            </nx-card>

            <div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">
                <a routerLink="../dashboard"><button nxButton="secondary" type="button">Back to Summary
                        listing</button></a>
                <button nxButton="primary" type="button">view e-QUOTATION</button>
                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popuprequestcancellationmessage')">
                    Request Cancellation
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupendorsementgenerated')">
                    Request Cancellation Endorsement Generated
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupendorsementnotgenerated')">
                    Request Cancellation Endorsement not Generated
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup3('popupsendtojpjgeocode')">
                    SEND TO JPJ GEOCODE
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup3('popupsendtojpjloan')">
                    SEND TO JPJ LOAN
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjocc')">
                    SEND TO JPJ OCC
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjupload')">
                    SEND TO JPJ Upload
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjsuspension')">
                    SEND TO JPJ Suspension
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjcheckcbc')">
                    SEND TO JPJ Check CBC
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjcheckwithcbc')">
                    SEND TO JPJ Check With CBC
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjcbcindicator1')">
                    SEND TO JPJ CBC Indicator 1
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjcbcindicator0')">
                    SEND TO JPJ CBC Indicator 0
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjsanctionfailed')">
                    SEND TO JPJ Sanction Failed
                </button>

                <button nxButton class="nx-margin-right-xs" (click)="openPopup4('popupsendtojpjcheckmotorblacklist')">
                    SEND TO JPJ Check Motor Blacklist
                </button>

                <button nxButton="primary" type="button" (click)="openPopup2('emaildocument')">EMAIL DOCUMENT</button>
                <button nxButton="primary" type="button">Continue issuance</button>
            </div>
        </div>
    </div>
</div>

<div class="cross_sell_banner">
    <h1 class="title std-ta-center nx-font-weight-semibold">Your customers do not have the following coverage, get them
        covered?
    </h1>
    <div nxLayout="grid maxwidth" class="nx-margin-top-2m scroll">
        <div nxRow>
            <div nxCol="12,12,6">
                <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-s nx-font-weight-semibold">ALLIANZ MEDICURE
                </h4>
                <div nxLayout="grid nopadding">
                    <div nxRow rowAlignItems="center" class="banner_color">
                        <div nxCol="6" class="banner_img">
                            <img src="../../../assets/img/banner_1.png">

                        </div>
                        <div nxCol="6" class="content_text">
                            <p nxCopytext="normal"><span class="orange_text nx-font-weight-bold">Instant special bonus
                                    of up to 40%</span>
                                <span class="nx-font-weight-semibold"> for Individual Private Car Comprehensive
                                    Customers.</span>
                            </p>
                            <p nxCopytext="small" class="nx-margin-top-3xs">Campaign period: 1 Apr - 31 Dec 2023</p>
                            <p nxCopytext="small" class="nx-margin-top-3xs">*Terms & conditions apply.</p>
                        </div>
                    </div>
                    <div nxRow rowAlignItems="center,center,center" class="nx-margin-top-s buttons">
                        <div nxCol="12" class="buttons_container">
                            <button nxButton="primary" type="button">SEND MARKETING MATERIAL</button>
                            <button nxPlainButton type="button" class="float_right m_padding_top_10">
                                <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon>ISSUE NEW
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div nxCol="12,12,6">
                <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold nx-margin-bottom-s">ALLIANZ LIFESTYLE
                    PROTECT</h4>
                <div nxLayout="grid nopadding">
                    <div nxRow rowAlignItems="center" class="banner_color">
                        <div nxCol="6" class="banner_img">
                            <img src="../../../assets/img/banner_2.png">

                        </div>
                        <div nxCol="6" class="content_text">
                            <p nxCopytext="normal">Offer<span class="orange_text nx-font-weight-bold"> tailored
                                    coverage</span>
                                <span class="nx-font-weight-semibold"> to your SME clients that meets their unique
                                    business needs
                                    SECURE THEIR BUSINESS NOW!</span>
                            </p>
                        </div>
                    </div>
                    <div nxRow rowAlignItems="center" class="nx-margin-top-s buttons">
                        <div nxCol="12" class="buttons_container">
                            <button nxButton="primary" type="button">SEND MARKETING MATERIAL</button>
                            <button nxPlainButton type="button" class="float_right m_padding_top_10">
                                <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon>ISSUE NEW
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div nxLayout="grid" class="banner_pagination nx-margin-top-2m">
        <div nxRow>
            <div nxCol="12">
                <nx-pagination [count]="slides" [page]="activeSlide" [perPage]="1" type="slider" (goPrev)="prevPage()"
                    (goNext)="nextPage()" (goPage)="goToPage($event)">
                </nx-pagination>
            </div>
        </div>
    </div>

</div>