import { Component, OnInit } from '@angular/core';
import { SharedModule } from "../../../../shared/shared.module";
import { NxSwitcherModule } from "@aposin/ng-aquila/switcher";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { NxRadioToggleModule } from "@aposin/ng-aquila/radio-toggle";
import { STEP2 } from '../../../../interfaces/motor'
import { Select, Store } from '@ngxs/store';
import { GET_CUSTOMER_PARTNER, GET_POSTCODE_LOV, RESET_CUSTOMER_PARTNER_DATA, RESET_SUBSEQUENT_PAGE_DATA, SET_CURRENT_STEP, SET_EINVOICE_CONSENT_IND, SHOW_SEARCH_COMPONENT_2, STORE_STEP2, STORE_STEP2_CP_SCREEN, STORE_STEP2_SEARCH } from '../../../../store/sat-motor/sat-motor.action'
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { CLIENTTYPE, IDTYPE } from 'src/app/shared/enums/index.enum';
import moment from 'moment';
import { Router } from '@angular/router';
import { CustomerPartnerFoundComponent } from '@shared/components/customer-partner/customer-partner-found/customer-partner-found.component';
import { CustomerPartnerNotFoundComponent } from '@shared/components/customer-partner/customer-partner-not-found/customer-partner-not-found.component';
import { CustomerPartnerIncompleteComponent } from '@shared/components/customer-partner/customer-partner-incomplete/customer-partner-incomplete.component';
import { CustomerPartnerSearchComponent } from '@shared/components/customer-partner/customer-partner-search/customer-partner-search.component';
import { UserResponse } from '@interfaces/user';
import { CpMandatoryFilledService } from '@shared/services/cp-mandatory-filled/cp-mandatory-filled.service';

@Component({
  selector: 'app-customer-partner-form',
  standalone: true,
  imports: [
    SharedModule,
    NxRadioToggleModule,
    NxSwitcherModule,
    ProgressBarComponent,
    CustomerPartnerFoundComponent,
    CustomerPartnerNotFoundComponent,
    CustomerPartnerIncompleteComponent,
    CustomerPartnerSearchComponent
  ],
  templateUrl: './customer-partner.component.html',
  styleUrl: './customer-partner.component.scss'
})
export class CustomerPartnerComponent implements OnInit {
  @Select(SatMotorState.showSearchComponent) showSearchComponent$;
  @Select(SatMotorState.dataSearch2) dataSearch2$;
  @Select(SatMotorState.cpType) cpType$;
  @Select(SatMotorState.dataStore2) dataStore2$;
  @Select(SatMotorState.eInvoiceConsentInd) eInvoiceConsentInd$;

  userInfo: UserResponse;
  isCompOrCustInfoIncomplete: boolean;
  isContactInfoIncomplete: boolean;
  isCorrespondenceInfoIncomplete: boolean;
  eInvoiceConsent: boolean = false;
  edocConsentInd: boolean = false;
  autoRenewal: boolean = false;
  lovReturned: boolean = false;
  isRenewal: boolean = false;
  cpDetails: any;
  lov: any;
  previousPolicyDetails: any;
  isActiveQuotation: any;
  mkagAgent: any;
  issuanceInfo: any;
  searchPayload: any;
  searchCriteria: any;
  agentData: any;
  isSingleAgent: boolean = false;
  lovproductConfig:any;
  productAccessListData: any;
  lovPC : any;
  lovGeo: any;
  emailCheck : any;
  hideSaveDraftBtn : boolean = false;
  queryParams: any;
  isRetrieveActiveQuotation: any;
  isQuotationGenerated: any;
  isCoverNoteGenerated: any;
  isUpdateQuotation: any;


  constructor(
    public store: Store,
    private router: Router,
    private cpMandatoryFilledService: CpMandatoryFilledService
  ) {

  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    this.mkagAgent = this.store.selectSnapshot(state => state.SatMotorState.motor.step1.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot(state => state.SatMotorState.motor.step1.issuance);
    this.previousPolicyDetails = this.store.selectSnapshot<UserResponse>(state => state.SatMotorState.previousPolicyDetails);
    this.isActiveQuotation = this.store.selectSnapshot<UserResponse>(state => state.SatMotorState.motor.step1.activeQuotation);
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.searchCriteria = this.store.selectSnapshot<any>(state => state.SatMotorState.data?.step2?.search);
    this.agentData = this.store.selectSnapshot(SatMotorState.motorAgentDetail);
    this.isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
    this.cpDetails = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step2);
    this.isQuotationGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data?.proposalNo);
    this.isCoverNoteGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ?.data?.cnoteNo);
    this.isRetrieveActiveQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState.retrieveQuotation);
    this.isUpdateQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState.updateQuotation);
    this.hideSaveDraftBtn = this.isQuotationGenerated || this.isCoverNoteGenerated || this.isUpdateQuotation || this.isRetrieveActiveQuotation ? true : false;
    this.queryParams = this.store.selectSnapshot<any>(state => state.UserState.queryParams);

    this.setStep();

    if (!this.lov?.clientPostcodeList) {
      this.store.dispatch(new GET_POSTCODE_LOV({
        listTypes: ["clientPostcodeList"],
        agentCode: this.mkagAgent.agentCode,
        productType: "COMM",
        productCode: this.issuanceInfo.issuance.productCode,
      })).subscribe(() => {
        this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
        this.lovReturned = true;
      });
    } else {
      this.lovReturned = true;
    }

    this.eInvoiceConsent = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1.issuance.pdpa.consentDetails.einvoiceConsentInd);
    this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1.issuance.pdpa.consentDetails.edocConsentInd);

    this.lovproductConfig = this.lov.productAccessConfigList;

    for (let i = 0; i < this.lovproductConfig.length; i++) {
      this.productAccessListData = this.lovproductConfig[i];
      this.lovPC = Object.keys( this.productAccessListData)[0]; 
    
        if (this.lovPC === this.issuanceInfo.issuance.productCode) {
         this.lovGeo =this.productAccessListData[this.lovPC].geocodingEnableSat;
         this.emailCheck = this.productAccessListData[this.lovPC].emailCheckEnableSat;
        }
    }

    let showSearch = this.store.selectSnapshot<boolean>(state => state.SatMotorState.data?.step2?.showSearchComponent);
    if (showSearch === undefined || showSearch === null) {
      this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(true));
    }

    if (this.previousPolicyDetails || this.isActiveQuotation) {
      this.isRenewal = this.previousPolicyDetails? true : false;
      // this.onSearch(null);
    }

    if(this.searchCriteria && !this.cpDetails) {
      this.onSearch(this.searchCriteria);
    } else if (this.queryParams?.idType && this.queryParams?.idValue && !this.cpDetails) {
      const queryParamSearchCriteria = {
        idType: this.queryParams.idType,
        idValue: this.queryParams.idValue,
        clientType: null,
        clientAddressType: null
      };
      this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
      this.onSearch(queryParamSearchCriteria);

    } else if (this.searchCriteria && this.cpDetails) {
      this.checkCPScreen();
    }

    if(this.store.selectSnapshot<any>(state => state.SatMotorState.data.step2.cpType) === 'INCOMPLETE') {
      this.isCompOrCustInfoIncomplete = this.store.selectSnapshot<any>(state => state.SatMotorState.data.step2.isCompOrCustInfoIncomplete);
      this.isContactInfoIncomplete = this.store.selectSnapshot<any>(state => state.SatMotorState.data.step2.isContactInfoIncomplete);
      this.isCorrespondenceInfoIncomplete = this.store.selectSnapshot<any>(state => state.SatMotorState.data.step2.isCorrespondenceInfoIncomplete);
    }

  }

  setStep() {
    let step;
    if ((this.userInfo.callCentreType != undefined || (!!this.agentData?.customerType) || this.agentData?.bancaAgent || this.agentData?.sundriesAgent) && !this?.isSingleAgent) {
      step = 3;
    } else {
      step = 2;
    }
    this.store.dispatch(new SET_CURRENT_STEP(step));
  }

  onSearch($event: STEP2.SearchDataStore) {

    //store customer partner search to state

    const agentCode = this.mkagAgent.agentCode;
    const operator = this.store.selectSnapshot(state => state.UserState.user.userId);
    const searchPayload: STEP2.SearchQuery = {
      idType: $event.idType,
      idValue: $event.idValue,
      agentCode: agentCode,
      operator: operator,
      productType: "MT"

    };

    //call to BE get customer partner, also need to store the customer partner is found, not found or incomplete
    this.store.dispatch(new GET_CUSTOMER_PARTNER(searchPayload)).subscribe(state => {
      this.cpDetails = state.SatMotorState.data.step2.store;
      this.checkCPScreen();
      this.store.dispatch(new STORE_STEP2_SEARCH($event));
      this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
    });

  }

  checkCPScreen() {
    if(this.isCompany) {
      this.store.dispatch(new SET_EINVOICE_CONSENT_IND(true));
    }

    this.eInvoiceConsent = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1.issuance.pdpa.consentDetails.einvoiceConsentInd);
    this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1.issuance.pdpa.consentDetails.edocConsentInd);

    let cpType;
    if (!this.cpDetails || this.cpDetails == null) {
      cpType = "NOT_FOUND"
    } else {
      if (this.cpDetails.updateCustomerPartner) {
        this.isCompOrCustInfoIncomplete = true;
        this.isContactInfoIncomplete = true;
        this.isCorrespondenceInfoIncomplete = true;
        cpType = "INCOMPLETE";
      } else {
        let mandatoryStatus = this.isAllMandatoryFilled();
        if (mandatoryStatus) {
          cpType = "FOUND";
        } else {
          cpType = "INCOMPLETE";
        }
      }
    }

    const payload = {
      cpScreen: cpType,
      isCompOrCustInfoIncomplete: this.isCompOrCustInfoIncomplete,
      isContactInfoIncomplete: this.isContactInfoIncomplete,
      isCorrespondenceInfoIncomplete: this.isCorrespondenceInfoIncomplete
    }

    this.store.dispatch(new STORE_STEP2_CP_SCREEN(payload));
  }

  isAllMandatoryFilled() {
    let cpMandatoryFilled = this.cpMandatoryFilledService.checkCPMandatoryFilled(this.cpDetails, this.emailCheck, this.eInvoiceConsent);

    this.isCompOrCustInfoIncomplete = cpMandatoryFilled.isCompOrCustInfoIncomplete;
    this.isContactInfoIncomplete  = cpMandatoryFilled.isContactInfoIncomplete;
    this.isCorrespondenceInfoIncomplete = cpMandatoryFilled.isCorrespondenceInfoIncomplete;

    if (this.isCompOrCustInfoIncomplete || this.isContactInfoIncomplete || this.isCorrespondenceInfoIncomplete) {
      return false;
    } else {
      return true;
    }

  }

  searchAgain($event) {
    this.store.dispatch(new RESET_CUSTOMER_PARTNER_DATA());
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(true));
    this.store.dispatch(new RESET_SUBSEQUENT_PAGE_DATA());
  }

  goBack() {
    this.router.navigate(['issuance/declaration-issuance-info']);
  }

  get isCompany() {
    return this.cpDetails?.clientType === CLIENTTYPE.COMPANY;
  }

  tempCustomerPartnerData: any = [
    {
      "addressId": 51228,
      "clientType": "P",
      "idType1": "NRIC",
      "idValue1": "111111",
      "idType2": "",
      "idValue2": "",
      "clientName": "TEST",
      "insuredName": "TEST",
      "clientDateofBirth": null,
      "clientGender": "",
      "clientNationality": "MAL",
      "clientMaritalStatus": "",
      "clientMobileCode1": "6010",
      "clientMobileNo1": "1111111",
      "clientMobileCode2": "",
      "clientMobileNo2": "",
      "clientEmail": "XXX@YAHOO.COM",
      "clientWebsite": "",
      "clientGstRegDate": null,
      "clientGstInfoDate": null,
      "clientGstRegNo": "",
      "clientGstDeRegDate": null,
      "clientGstRegInd": false,
      "clientSoleProprietorInd": false,
      "gdprInd": false,
      "clientAge": 0,
      "clientHpOperator": null,
      "clientHpNumber": null,
      "clHpNoSplited": null,
      "fwCountryExtCode": null,
      "partnerId": 46496,
      "partnerVersionNo": 404,
      "updateCustomerPartner": true,
      "externalEmailInd": false,
      "clientOccupCode": null,
      "clientOccupDescription": null,
      "clientOccupCategory": null,
      "clientClass": null,
      "designation": null,
      "clientBusinessCode": null,
      "clientBusinessDescription": null,
      "language": "",
      "prefContact": "",
      "marketingConsent": "Y",
      "referenceNo": null,
      "smeRegisteredInd": false,
      "smeRegisteredNumber": "",
      "smeCategory": "",
      "relatedPolicyNo": null,
      "alimCustomerInd": false,
      "alimPolicyNo": null,
      "smeStartDate": null,
      "smeEndDate": null,
      "clientTinNo": "TIN1111111",
      "clientSstRegInd": true,
      "clientSstRegNo": "SST12345678987666",
      "clientSstRegDate": "2024-08-06",
      "clientSstDeRegDate": null,
      "clientSstResidentStatus": "R",
      "clientSstCountry": "MAL",
      "defaultMailReq": false,
      "clientPartnerAddress": {
        "partnerId": 46496,
        "clientAddressType": "O",
        "clientAddress1": "12, MENARA ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN",
        "clientAddress2": "BRICKFIELDS",
        "clientAddress3": "",
        "clientPostcode": "50470",
        "clientCity": "KL",
        "clientState": "WPN",
        "clientCountry": "MAL",
        "clientPhoneCode": "",
        "clientPhoneNo": "",
        "clientFax1Code": "602",
        "clientFax1No": "1234550",
        "clientFax2Code": "",
        "clientFax2No": "",
        "addressId": 51228,
        "addressVersionNo": 181,
        "clientUnitNo": "",
        "clientAddress1Tmp": "12, MENARA ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN",
        "clientGeoLatitude": "",
        "clientGeoLongitude": "",
        "clientGeoPlaceId": "",
        "clientGeoPlusCode": "",
        "clientGeoFormattedAddress": null,
        "inputLatitude": null,
        "inputLongitude": null
      }
    },
    {
      "addressId": 51228,
      "clientType": "P",
      "idType1": "NRIC",
      "idValue1": "222222",
      "idType2": "",
      "idValue2": "",
      "clientName": "",
      "insuredName": "TEST",
      "clientDateofBirth": null,
      "clientGender": "",
      "clientNationality": "MAL",
      "clientMaritalStatus": "SINGLE",
      "clientMobileCode1": "6010",
      "clientMobileNo1": "1111111",
      "clientMobileCode2": "",
      "clientMobileNo2": "",
      "clientEmail": "XXX@YAHOO.COM",
      "clientWebsite": "",
      "clientGstRegDate": null,
      "clientGstInfoDate": null,
      "clientGstRegNo": "",
      "clientGstDeRegDate": null,
      "clientGstRegInd": false,
      "clientSoleProprietorInd": false,
      "gdprInd": false,
      "clientAge": 0,
      "clientHpOperator": null,
      "clientHpNumber": null,
      "clHpNoSplited": null,
      "fwCountryExtCode": null,
      "partnerId": 46496,
      "partnerVersionNo": 404,
      "updateCustomerPartner": false,
      "externalEmailInd": false,
      "clientOccupCode": null,
      "clientOccupDescription": null,
      "clientOccupCategory": null,
      "clientClass": null,
      "designation": null,
      "clientBusinessCode": null,
      "clientBusinessDescription": null,
      "language": "",
      "prefContact": "",
      "marketingConsent": "Y",
      "referenceNo": null,
      "smeRegisteredInd": false,
      "smeRegisteredNumber": "",
      "smeCategory": "",
      "relatedPolicyNo": null,
      "alimCustomerInd": false,
      "alimPolicyNo": null,
      "smeStartDate": null,
      "smeEndDate": null,
      "clientTinNo": "TIN1111111",
      "clientSstRegInd": true,
      "clientSstRegNo": "SST12345678987666",
      "clientSstRegDate": "2024-08-06",
      "clientSstDeRegDate": null,
      "clientSstResidentStatus": "R",
      "clientSstCountry": "MAL",
      "defaultMailReq": false,
      "clientPartnerAddress": {
        "partnerId": 46496,
        "clientAddressType": "O",
        "clientAddress1": "12, MENARA ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN",
        "clientAddress2": "BRICKFIELDS",
        "clientAddress3": "",
        "clientPostcode": "50470",
        "clientCity": "KL",
        "clientState": "WPN",
        "clientCountry": "MAL",
        "clientPhoneCode": "",
        "clientPhoneNo": "",
        "clientFax1Code": "602",
        "clientFax1No": "1234550",
        "clientFax2Code": "",
        "clientFax2No": "",
        "addressId": 51228,
        "addressVersionNo": 181,
        "clientUnitNo": "",
        "clientAddress1Tmp": "12, MENARA ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN",
        "clientGeoLatitude": "",
        "clientGeoLongitude": "",
        "clientGeoPlaceId": "",
        "clientGeoPlusCode": "",
        "clientGeoFormattedAddress": null,
        "inputLatitude": null,
        "inputLongitude": null
      }
    },
    {
      "addressId": 51228,
      "clientType": "P",
      "idType1": "NRIC",
      "idValue1": "333333",
      "idType2": "",
      "idValue2": "",
      "clientName": "TEST",
      "insuredName": "TEST",
      "clientDateofBirth": "2024-09-02",
      "clientGender": "F",
      "clientNationality": "MAL",
      "clientMaritalStatus": "SINGLE",
      "clientMobileCode1": "6010",
      "clientMobileNo1": "1111111",
      "clientMobileCode2": "",
      "clientMobileNo2": "",
      "clientEmail": "XXX@YAHOO.COM",
      "clientWebsite": "",
      "clientGstRegDate": null,
      "clientGstInfoDate": null,
      "clientGstRegNo": "",
      "clientGstDeRegDate": null,
      "clientGstRegInd": false,
      "clientSoleProprietorInd": false,
      "gdprInd": false,
      "clientAge": 0,
      "clientHpOperator": null,
      "clientHpNumber": null,
      "clHpNoSplited": null,
      "fwCountryExtCode": null,
      "partnerId": 46496,
      "partnerVersionNo": 404,
      "updateCustomerPartner": false,
      "externalEmailInd": false,
      "clientOccupCode": null,
      "clientOccupDescription": null,
      "clientOccupCategory": null,
      "clientClass": null,
      "designation": null,
      "clientBusinessCode": null,
      "clientBusinessDescription": null,
      "language": "",
      "prefContact": "",
      "marketingConsent": "Y",
      "referenceNo": null,
      "smeRegisteredInd": false,
      "smeRegisteredNumber": "",
      "smeCategory": "",
      "relatedPolicyNo": null,
      "alimCustomerInd": false,
      "alimPolicyNo": null,
      "smeStartDate": null,
      "smeEndDate": null,
      "clientTinNo": "TIN1111111",
      "clientSstRegInd": true,
      "clientSstRegNo": "SST12345678987666",
      "clientSstRegDate": "2024-08-06",
      "clientSstDeRegDate": null,
      "clientSstResidentStatus": "R",
      "clientSstCountry": "MAL",
      "defaultMailReq": false,
      "clientPartnerAddress": {
        "partnerId": 46496,
        "clientAddressType": "O",
        "clientAddress1": "",
        "clientAddress2": "BRICKFIELDS",
        "clientAddress3": "",
        "clientPostcode": "50470",
        "clientCity": "KL",
        "clientState": "WPN",
        "clientCountry": "MAL",
        "clientPhoneCode": "",
        "clientPhoneNo": "",
        "clientFax1Code": "602",
        "clientFax1No": "1234550",
        "clientFax2Code": "",
        "clientFax2No": "",
        "addressId": 51228,
        "addressVersionNo": 181,
        "clientUnitNo": "",
        "clientAddress1Tmp": "12, MENARA ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN",
        "clientGeoLatitude": "",
        "clientGeoLongitude": "",
        "clientGeoPlaceId": "",
        "clientGeoPlusCode": "",
        "clientGeoFormattedAddress": null,
        "inputLatitude": null,
        "inputLongitude": null
      }
    },
    {
      "addressId": 51228,
      "clientType": "P",
      "idType1": "NRIC",
      "idValue1": "444444",
      "idType2": "",
      "idValue2": "",
      "clientName": "TEST",
      "insuredName": "TEST",
      "clientDateofBirth": "2024-09-02",
      "clientGender": "M",
      "clientNationality": "MAL",
      "clientMaritalStatus": "SINGLE",
      "clientMobileCode1": "6010",
      "clientMobileNo1": "1111111",
      "clientMobileCode2": "6010",
      "clientMobileNo2": "1122555",
      "clientEmail": "XXX@YAHOO.COM",
      "clientWebsite": "",
      "clientGstRegDate": null,
      "clientGstInfoDate": null,
      "clientGstRegNo": "",
      "clientGstDeRegDate": null,
      "clientGstRegInd": false,
      "clientSoleProprietorInd": false,
      "gdprInd": false,
      "clientAge": 0,
      "clientHpOperator": null,
      "clientHpNumber": null,
      "clHpNoSplited": null,
      "fwCountryExtCode": null,
      "partnerId": 46496,
      "partnerVersionNo": 404,
      "updateCustomerPartner": false,
      "externalEmailInd": false,
      "clientOccupCode": null,
      "clientOccupDescription": null,
      "clientOccupCategory": null,
      "clientClass": null,
      "designation": null,
      "clientBusinessCode": null,
      "clientBusinessDescription": null,
      "language": "",
      "prefContact": "",
      "marketingConsent": "Y",
      "referenceNo": null,
      "smeRegisteredInd": false,
      "smeRegisteredNumber": "",
      "smeCategory": "",
      "relatedPolicyNo": null,
      "alimCustomerInd": true,
      "alimPolicyNo": null,
      "smeStartDate": null,
      "smeEndDate": null,
      "clientTinNo": "TIN1111111",
      "clientSstRegInd": true,
      "clientSstRegNo": "SST12345678987666",
      "clientSstRegDate": "2024-08-06",
      "clientSstDeRegDate": null,
      "clientSstResidentStatus": "R",
      "clientSstCountry": "MAL",
      "defaultMailReq": false,
      "clientPartnerAddress": {
        "partnerId": 46496,
        "clientAddressType": "O",
        "clientAddress1": "jalan 1",
        "clientAddress2": "BRICKFIELDS",
        "clientAddress3": "",
        "clientPostcode": "50470",
        "clientCity": "KL",
        "clientState": "WPN",
        "clientCountry": "MAL",
        "clientPhoneCode": "",
        "clientPhoneNo": "",
        "clientFax1Code": "602",
        "clientFax1No": "1234550",
        "clientFax2Code": "",
        "clientFax2No": "",
        "addressId": 51228,
        "addressVersionNo": 181,
        "clientUnitNo": "",
        "clientAddress1Tmp": "12, MENARA ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN",
        "clientGeoLatitude": "",
        "clientGeoLongitude": "",
        "clientGeoPlaceId": "",
        "clientGeoPlusCode": "",
        "clientGeoFormattedAddress": null,
        "inputLatitude": null,
        "inputLongitude": null
      }
    },
    {
      "addressId": 51228,
      "clientType": "I",
      "idType1": "BR_NO",
      "idValue1": "55555",
      "idType2": "",
      "idValue2": "",
      "clientName": "",
      "insuredName": "TEST",
      "clientDateofBirth": "2024-09-02",
      "clientGender": "M",
      "clientNationality": "MAL",
      "clientMaritalStatus": "SINGLE",
      "clientMobileCode1": "6010",
      "clientMobileNo1": "",
      "clientMobileCode2": "6010",
      "clientMobileNo2": "1122555",
      "clientEmail": "XXX@YAHOO.COM",
      "clientWebsite": "",
      "clientGstRegDate": null,
      "clientGstInfoDate": null,
      "clientGstRegNo": "",
      "clientGstDeRegDate": null,
      "clientGstRegInd": false,
      "clientSoleProprietorInd": false,
      "gdprInd": false,
      "clientAge": 0,
      "clientHpOperator": null,
      "clientHpNumber": null,
      "clHpNoSplited": null,
      "fwCountryExtCode": null,
      "partnerId": 46496,
      "partnerVersionNo": 404,
      "updateCustomerPartner": false,
      "externalEmailInd": false,
      "clientOccupCode": null,
      "clientOccupDescription": null,
      "clientOccupCategory": null,
      "clientClass": null,
      "designation": null,
      "clientBusinessCode": null,
      "clientBusinessDescription": null,
      "language": "",
      "prefContact": "",
      "marketingConsent": "Y",
      "referenceNo": null,
      "smeRegisteredInd": false,
      "smeRegisteredNumber": "",
      "smeCategory": "",
      "relatedPolicyNo": null,
      "alimCustomerInd": false,
      "alimPolicyNo": null,
      "smeStartDate": null,
      "smeEndDate": null,
      "clientTinNo": "TIN1111111",
      "clientSstRegInd": true,
      "clientSstRegNo": "SST12345678987666",
      "clientSstRegDate": "2024-08-06",
      "clientSstDeRegDate": null,
      "clientSstResidentStatus": "R",
      "clientSstCountry": "MAL",
      "defaultMailReq": false,
      "clientPartnerAddress": {
        "partnerId": 46496,
        "clientAddressType": "O",
        "clientAddress1": "jalan 1",
        "clientAddress2": "BRICKFIELDS",
        "clientAddress3": "",
        "clientPostcode": "50470",
        "clientCity": "KL",
        "clientState": "WPN",
        "clientCountry": "MAL",
        "clientPhoneCode": "",
        "clientPhoneNo": "",
        "clientFax1Code": "602",
        "clientFax1No": "1234550",
        "clientFax2Code": "",
        "clientFax2No": "",
        "addressId": 51228,
        "addressVersionNo": 181,
        "clientUnitNo": "",
        "clientAddress1Tmp": "12, MENARA ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN",
        "clientGeoLatitude": "",
        "clientGeoLongitude": "",
        "clientGeoPlaceId": "",
        "clientGeoPlusCode": "",
        "clientGeoFormattedAddress": null,
        "inputLatitude": null,
        "inputLongitude": null
      }
    }
  ];

}
