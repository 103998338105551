import { Component, Inject } from "@angular/core";
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
import { SharedModule } from "../../shared/shared.module";

@Component({
  selector: "app-pdpa-popup",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./pdpa-popup.component.html",
  styleUrl: "./pdpa-popup.component.scss",
})
export class PdpaPopupComponent {
  templateDialogRef?: NxModalRef<PdpaPopupComponent>;
  popup_type: any;
  message: any;
  constructor(
    @Inject(NX_MODAL_DATA) data: any,
    private dialogService: NxDialogService
  ) {
    // console.log("data", data.popup_type);
    this.popup_type = data.popup_type;

    // Vehicle details
    if (this.popup_type == 1) {
      this.message = "Vehicle No. doesn't exist.";
    } else if (this.popup_type == 2) {
      this.message = "Policy in Expired status, please contact your Branch.";
    } else if (this.popup_type == 3) {
      this.message =
        "Endorsement currently in progress, please contact your Branch.";
    } else if (this.popup_type == 4) {
      this.message = "Policy in cancelled status, please contact your Branch.";
    } else if (this.popup_type == 5) {
      this.message = "Amendment of e-Cover Note details are not allowed.";
    }
    // Endorsment
    else if (this.popup_type == 6) {
      this.message =
        "Unable to proceed due to selected agent is not banca agent.";
    } else if (this.popup_type == 7) {
      this.message = "Not allow to withdraw, NCD percentage is zero.";
    } else if (this.popup_type == 8) {
      this.message =
        "NCD Allowed not applicable because NCD percentage is not zero.";
    } else if (this.popup_type == 9) {
      this.message = "Amendment of e-Invoice details are not allowed.";
    } else if (this.popup_type == 10) {
      this.message = "Change of e-Invoice consent are not allowed.";
    } else if (this.popup_type == 11) {
      this.message =
        "Amendment of e-Cover Note details are not allowed except Cancellation.";
    }
    // Endorsment effective date
    // else if (this.popup_type == 12) {
    //   this.message =
    //     "Endorsement Effective date cannot be earlier than issued date.";
    // } else if (this.popup_type == 13) {
    //   this.message = "Endorsement Effective date cannot be after expiry date.";
    // } else if (this.popup_type == 14) {
    //   this.message =
    //     "Endorsement Effective date cannot be before the effective date of the policy (dd/mm/yyyy).";
    // }
    // Endorsment expiry date
    else if (this.popup_type == 15) {
      this.message =
        "Expiry date cannot be more than 6 months after effective date.";
    } else if (this.popup_type == 16) {
      this.message = "Expiry date cannot be earlier than effective date.";
    }
    // ID NO.
    else if (this.popup_type == 17) {
      this.message =
        "There is an existing quotation <Quotation Number> for this vehicle, last updated on <Quotation Update Date>. Would you like to retrieve this quotation instead of creating a new one?";
    } else if (this.popup_type == 18) {
      this.message =
        "Existing Policy still inforce, not allowed to issue new Cover Note.";
    }

    // Effective date
    // else if (this.popup_type == 19) {
    //   this.message = "Backdating of effective date is not allowed.";
    // } else if (this.popup_type == 20) {
    //   this.message =
    //     "Backdated effective date cannot be earlier than YY days before issued date.";
    // } else if (this.popup_type == 21) {
    //   this.message =
    //     "Effective date cannot be later than XX days after issued date.";
    // } else if (this.popup_type == 22) {
    //   this.message = "This cover note might be lapsed.";
    // }

    // // Expiry date
    // else if (this.popup_type == 23) {
    //   this.message = "Expiry date cannot be before effective date.";
    // } else if (this.popup_type == 24) {
    //   this.message =
    //     "Expiry Date: Period of Insurance exceeds maximum allowable: XX months.";
    // }

    // from ism policy
    else if (this.popup_type == 25) {
      this.message = "Claim Free Year (CFY) out of range.";
    }
  }
}
