import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { DELETE, BACKSPACE, ENTER } from '@angular/cdk/keycodes';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, forwardRef, ElementRef, ViewChildren, NgModule } from '@angular/core';
import * as i1 from '@angular/cdk/a11y';
import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@aposin/ng-aquila/icon';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
function NxTagComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 1);
    i0.ɵɵlistener("click", function NxTagComponent_span_1_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.removeClickHandler($event));
    });
    i0.ɵɵelement(1, "nx-icon", 2);
    i0.ɵɵelementEnd();
  }
}
const _c0 = ["*"];
function NxTaglistComponent_ul_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 4)(1, "nx-tag", 5);
    i0.ɵɵlistener("clicked", function NxTaglistComponent_ul_0_li_1_Template_nx_tag_clicked_1_listener($event) {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.tagClick(i_r2, $event));
    })("removed", function NxTaglistComponent_ul_0_li_1_Template_nx_tag_removed_1_listener($event) {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.delete(i_r2, $event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const tag_r4 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("value", ctx_r2.renderTag(tag_r4))("removable", ctx_r2.allowTagDeletion)("tabindex", ctx_r2.tabindex);
  }
}
function NxTaglistComponent_ul_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ul", 2);
    i0.ɵɵtemplate(1, NxTaglistComponent_ul_0_li_1_Template, 2, 3, "li", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.tags);
  }
}
function NxTaglistComponent_ng_content_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 0, ["*ngIf", "tags.length < 1"]);
  }
}
class NxTagComponent {
  /** Whether the tag is removeable. */
  set removable(value) {
    this._removable = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  get removable() {
    return this._removable;
  }
  /**
   * Sets the tab-index of a tag. Default value: -1.
   *
   * If `allowTagDeletion` of the taglist is set to true, the default value is 0.
   */
  set tabindex(value) {
    this._tabindex = coerceNumberProperty(value);
    this._cdr.markForCheck();
  }
  get tabindex() {
    return this.removable ? 0 : this._tabindex;
  }
  /** Sets the value of the tag. */
  set value(value) {
    if (this._value !== value) {
      this._value = value;
      this._cdr.markForCheck();
    }
  }
  get value() {
    return this._value;
  }
  constructor(_cdr, _elementRef, _focusMonitor) {
    this._cdr = _cdr;
    this._elementRef = _elementRef;
    this._focusMonitor = _focusMonitor;
    this._tabindex = -1;
    /** An event is dispatched each time when the tag is clicked. */
    this.clicked = new EventEmitter();
    /** An event is dispatched each time when the tag is removed. */
    this.removed = new EventEmitter();
  }
  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef);
  }
  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  /** @docs-private */
  // Emit the removed event that the parent can remove the value
  removeClickHandler(event) {
    event.stopPropagation();
    this.removed.emit(this.value);
  }
  /** @docs-private */
  removeKeyHandler(event) {
    if (this.removable && (event.keyCode === DELETE || event.keyCode === BACKSPACE)) {
      event.preventDefault();
      event.stopPropagation();
      this.removed.emit(this.value);
    }
    if (event.keyCode === ENTER) {
      this.clicked.emit(this.value);
    }
  }
  /** @docs-private */
  tagClickHandler() {
    this.clicked.emit(this.value);
  }
  static {
    this.ɵfac = function NxTagComponent_Factory(t) {
      return new (t || NxTagComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.FocusMonitor));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxTagComponent,
      selectors: [["nx-tag"]],
      hostVars: 1,
      hostBindings: function NxTagComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function NxTagComponent_click_HostBindingHandler() {
            return ctx.tagClickHandler();
          })("keydown", function NxTagComponent_keydown_HostBindingHandler($event) {
            return ctx.removeKeyHandler($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("tabindex", ctx.tabindex);
        }
      },
      inputs: {
        removable: "removable",
        tabindex: "tabindex",
        value: "value"
      },
      outputs: {
        clicked: "clicked",
        removed: "removed"
      },
      decls: 2,
      vars: 2,
      consts: [["role", "link", "aria-label", "close", "class", "nx-tag__close", 3, "click", 4, "ngIf"], ["role", "link", "aria-label", "close", 1, "nx-tag__close", 3, "click"], ["name", "close", "aria-hidden", "true", 1, "nx-tag__close-icon"]],
      template: function NxTagComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtext(0);
          i0.ɵɵtemplate(1, NxTagComponent_span_1_Template, 2, 0, "span", 0);
        }
        if (rf & 2) {
          i0.ɵɵtextInterpolate1("", ctx.value, "\n");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.removable);
        }
      },
      dependencies: [i1$1.NgIf, i3.NxIconComponent],
      styles: ["[_nghost-%COMP%]{font-size:var(--taglist-font-size);line-height:var(--taglist-line-height);font-weight:var(--taglist-font-weight);letter-spacing:var(--taglist-letter-spacing);height:auto;max-width:100%;color:var(--taglist-color);background-color:var(--taglist-background-color);padding:6px 14px;display:flex;align-items:center;border:2px solid var(--taglist-border-color);border-radius:4px;-webkit-user-select:none;user-select:none;transition:background-color .2s ease,box-shadow .2s ease;cursor:pointer}[_nghost-%COMP%]:hover{border-color:var(--taglist-hover-border-color);color:var(--taglist-hover-color);background-color:var(--taglist-hover-background-color)}[_nghost-%COMP%]:focus{outline:2px solid transparent}.cdk-keyboard-focused[_nghost-%COMP%]{box-shadow:var(--focus-box-shadow)}@media screen and (forced-colors: active),(forced-colors: active){.cdk-keyboard-focused[_nghost-%COMP%]{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}.nx-tag__close-icon[_ngcontent-%COMP%]{outline:none;display:block;font-size:var(--taglist-close-icon-size)}.nx-tag__close[_ngcontent-%COMP%]{margin-left:8px}[dir=rtl][_nghost-%COMP%]   .nx-tag__close[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .nx-tag__close[_ngcontent-%COMP%]{margin-right:8px;margin-left:initial}.nx-taglist--keyword[_nghost-%COMP%], .nx-taglist--keyword   [_nghost-%COMP%]{padding:14px 22px}@media screen and (forced-colors: active){[_nghost-%COMP%], [_nghost-%COMP%]:hover, [_nghost-%COMP%]:focus{color:buttonText;background-color:buttonFace;border-color:buttonText;forced-colors-adjust:none}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxTagComponent, [{
    type: Component,
    args: [{
      selector: 'nx-tag',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '(click)': 'tagClickHandler()',
        '(keydown)': 'removeKeyHandler($event)',
        '[attr.tabindex]': 'tabindex'
      },
      template: "{{ value }}\n<span role=\"link\" aria-label=\"close\" class=\"nx-tag__close\" *ngIf=\"removable\" (click)=\"removeClickHandler($event)\">\n    <nx-icon class=\"nx-tag__close-icon\" name=\"close\" aria-hidden=\"true\"></nx-icon>\n</span>\n",
      styles: [":host{font-size:var(--taglist-font-size);line-height:var(--taglist-line-height);font-weight:var(--taglist-font-weight);letter-spacing:var(--taglist-letter-spacing);height:auto;max-width:100%;color:var(--taglist-color);background-color:var(--taglist-background-color);padding:6px 14px;display:flex;align-items:center;border:2px solid var(--taglist-border-color);border-radius:4px;-webkit-user-select:none;user-select:none;transition:background-color .2s ease,box-shadow .2s ease;cursor:pointer}:host:hover{border-color:var(--taglist-hover-border-color);color:var(--taglist-hover-color);background-color:var(--taglist-hover-background-color)}:host:focus{outline:2px solid transparent}:host.cdk-keyboard-focused{box-shadow:var(--focus-box-shadow)}@media screen and (forced-colors: active),(forced-colors: active){:host.cdk-keyboard-focused{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}.nx-tag__close-icon{outline:none;display:block;font-size:var(--taglist-close-icon-size)}.nx-tag__close{margin-left:8px}:host-context([dir=rtl]) .nx-tag__close{margin-right:8px;margin-left:initial}:host-context(.nx-taglist--keyword){padding:14px 22px}@media screen and (forced-colors: active){:host,:host:hover,:host:focus{color:buttonText;background-color:buttonFace;border-color:buttonText;forced-colors-adjust:none}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i1.FocusMonitor
  }], {
    removable: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    clicked: [{
      type: Output
    }],
    removed: [{
      type: Output
    }]
  });
})();
class NxTaglistComponent {
  /** Sets the list of tags. */
  set tags(value) {
    this._tags = value;
    this._cdr.markForCheck();
  }
  get tags() {
    return this._tags;
  }
  /** Sets the tabindex of the contained tags. Default value: -1. */
  set tabindex(value) {
    this._tabindex = coerceNumberProperty(value);
    this._cdr.markForCheck();
  }
  get tabindex() {
    return this.allowTagDeletion ? 0 : this._tabindex;
  }
  /** Whether the tags can be removed from the list. Default: true. */
  set allowTagDeletion(value) {
    this._allowTagDeletion = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  get allowTagDeletion() {
    return this._allowTagDeletion;
  }
  /** Whether the tags can be styled as keywords. */
  set isKeywordList(value) {
    this._isKeywordList = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  get isKeywordList() {
    return this._isKeywordList;
  }
  /** Sets the label property, in case tags represent objects. */
  set labelProperty(value) {
    if (this._labelProperty !== value) {
      this._labelProperty = value;
      this._cdr.markForCheck();
    }
  }
  get labelProperty() {
    return this._labelProperty;
  }
  /** Sets the label property to improve accessibility. */
  set labelledby(value) {
    if (this._ariaLabelledBy !== value) {
      this._ariaLabelledBy = value;
      this._cdr.markForCheck();
    }
  }
  get labelledby() {
    return this._ariaLabelledBy;
  }
  /** Sets the customization function for tag value.  */
  set valueFormatter(fn) {
    this._valueFormatterFn = fn;
    this._cdr.markForCheck();
  }
  get valueFormatter() {
    return this._valueFormatterFn;
  }
  constructor(_cdr) {
    this._cdr = _cdr;
    /** An event is dispatched each time when the list of tags changed. */
    this.tagsChange = new EventEmitter();
    /** An event is dispatched each time when a tag is clicked. */
    this.tagClickEvent = new EventEmitter();
    this._tags = [];
    this._tabindex = -1;
    this._allowTagDeletion = true;
    this._isKeywordList = false;
    this._labelProperty = 'nxTaglistLabel';
    this._valueFormatterFn = value => value;
    this._onChange = () => {};
    this._onTouched = () => {};
  }
  /** Allows to delete a tag given index. Takes index of the tag to be deleted as a parameter */
  delete(index, value) {
    if (this.allowTagDeletion) {
      this.tags = [...this.tags.slice(0, index), ...this.tags.slice(index + 1)];
      // focus next element after deletion
      if (this.tagChildren.toArray()[index + 1]) {
        this.tagChildren.toArray()[index + 1].nativeElement.focus();
      }
      this._onChange(this.tags);
      this.tagsChange.emit(this.tags);
    }
  }
  /** Allows to add a tag. Takes tag object as an input */
  addTag(tag) {
    if (!tag) {
      return;
    }
    // make sure tag is either string or has the configured label prop and is not yet in the list
    if (typeof tag === 'string' && !this.tags.includes(tag) || tag[this.labelProperty] && this.tags.filter(t => t[this.labelProperty] === tag[this.labelProperty]).length < 1) {
      this.tags = [...this.tags, tag];
      this._onChange(this.tags);
      this.tagsChange.emit(this.tags);
    }
  }
  /** Allows to clear the tag list. */
  clearTags() {
    this.tags = [];
    this._onChange(this.tags);
    this.tagsChange.emit(this.tags);
  }
  /** @docs-private */
  writeValue(tags) {
    this.tags = tags;
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  /** @docs-private */
  renderTag(tag) {
    const tagStr = typeof tag === 'string' ? tag : tag[this.labelProperty];
    return this.valueFormatter(tagStr);
  }
  /** @docs-private */
  tagClick(index, value) {
    this.tagClickEvent.emit(this.tags[index]);
  }
  static {
    this.ɵfac = function NxTaglistComponent_Factory(t) {
      return new (t || NxTaglistComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxTaglistComponent,
      selectors: [["nx-taglist"]],
      viewQuery: function NxTaglistComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NxTagComponent, 5, ElementRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tagChildren = _t);
        }
      },
      hostVars: 4,
      hostBindings: function NxTaglistComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("aria-labelledby", ctx.labelledby || null)("tabindex", -1);
          i0.ɵɵclassProp("nx-taglist--keyword", ctx.isKeywordList);
        }
      },
      inputs: {
        tags: "tags",
        tabindex: "tabindex",
        allowTagDeletion: "allowTagDeletion",
        isKeywordList: "isKeywordList",
        labelProperty: "labelProperty",
        labelledby: [i0.ɵɵInputFlags.None, "aria-labelledby", "labelledby"],
        valueFormatter: "valueFormatter"
      },
      outputs: {
        tagsChange: "tagsChange",
        tagClickEvent: "onTagClick"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NxTaglistComponent),
        multi: true
      }])],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 2,
      consts: [["class", "nx-taglist", 4, "ngIf"], [4, "ngIf"], [1, "nx-taglist"], ["class", "nx-taglist__list-item", "tabindex", "-1", 4, "ngFor", "ngForOf"], ["tabindex", "-1", 1, "nx-taglist__list-item"], [3, "clicked", "removed", "value", "removable", "tabindex"]],
      template: function NxTaglistComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, NxTaglistComponent_ul_0_Template, 2, 1, "ul", 0)(1, NxTaglistComponent_ng_content_1_Template, 1, 0, "ng-content", 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.tags.length > 0);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.tags.length < 1);
        }
      },
      dependencies: [i1$1.NgForOf, i1$1.NgIf, NxTagComponent],
      styles: ["[_nghost-%COMP%]{position:relative;min-height:32px;display:flex;justify-content:space-between}[_nghost-%COMP%]:focus{outline:none}.nx-taglist[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap}.nx-taglist__list-item[_ngcontent-%COMP%]{list-style:none;margin-right:12px;margin-bottom:12px;flex-wrap:wrap;display:flex}[dir=rtl][_nghost-%COMP%]   .nx-taglist__list-item[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .nx-taglist__list-item[_ngcontent-%COMP%]{margin-right:initial;margin-left:12px}@media (max-width: 703px){.nx-taglist--keyword[_ngcontent-%COMP%]{justify-content:center}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxTaglistComponent, [{
    type: Component,
    args: [{
      selector: 'nx-taglist',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NxTaglistComponent),
        multi: true
      }],
      host: {
        '[class.nx-taglist--keyword]': 'isKeywordList',
        '[attr.aria-labelledby]': 'labelledby || null',
        '[attr.tabindex]': '-1'
      },
      template: "<ul class=\"nx-taglist\" *ngIf=\"tags.length > 0\">\n    <li class=\"nx-taglist__list-item\" tabindex=\"-1\" *ngFor=\"let tag of tags; let i = index\">\n        <nx-tag\n            [value]=\"renderTag(tag)\"\n            [removable]=\"allowTagDeletion\"\n            [tabindex]=\"tabindex\"\n            (clicked)=\"tagClick(i, $event)\"\n            (removed)=\"delete(i, $event)\"\n        ></nx-tag>\n    </li>\n</ul>\n<ng-content *ngIf=\"tags.length < 1\"></ng-content>\n",
      styles: [":host{position:relative;min-height:32px;display:flex;justify-content:space-between}:host:focus{outline:none}.nx-taglist{display:flex;flex-wrap:wrap}.nx-taglist__list-item{list-style:none;margin-right:12px;margin-bottom:12px;flex-wrap:wrap;display:flex}:host-context([dir=rtl]) .nx-taglist__list-item{margin-right:initial;margin-left:12px}@media (max-width: 703px){.nx-taglist--keyword{justify-content:center}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    tagsChange: [{
      type: Output,
      args: ['tagsChange']
    }],
    tagClickEvent: [{
      type: Output,
      args: ['onTagClick']
    }],
    tagChildren: [{
      type: ViewChildren,
      args: [NxTagComponent, {
        read: ElementRef
      }]
    }],
    tags: [{
      type: Input,
      args: ['tags']
    }],
    tabindex: [{
      type: Input
    }],
    allowTagDeletion: [{
      type: Input
    }],
    isKeywordList: [{
      type: Input
    }],
    labelProperty: [{
      type: Input
    }],
    labelledby: [{
      type: Input,
      args: ['aria-labelledby']
    }],
    valueFormatter: [{
      type: Input
    }]
  });
})();
class NxTaglistModule {
  static {
    this.ɵfac = function NxTaglistModule_Factory(t) {
      return new (t || NxTaglistModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxTaglistModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, NxIconModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxTaglistModule, [{
    type: NgModule,
    args: [{
      declarations: [NxTaglistComponent, NxTagComponent],
      exports: [NxTaglistComponent, NxTagComponent],
      imports: [CommonModule, NxIconModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxTagComponent, NxTaglistComponent, NxTaglistModule };
