<form [formGroup]="driverNameForm">
  <!--form-->
  @if (isComprehensive) {
  @if (isPrivateCarsIncludingGoods || isPrivateCarsCompanyRegistered) {
  <div nxRow>
    <div nxCol="12">
      <hr class="nx-margin-y-2m" />
    </div>
  </div>
  <div nxRow>
    <div nxCol="12">
      <nx-checkbox formControlName="allDriverInd" (checkboxChange)="allDriverOnChange()"> All Driver </nx-checkbox>
    </div>
  </div>
  }

  @if (isPrivateCarsExcludingGoods && isComprehensive) {
  <div nxRow>
    <div nxCol="12">
      <hr class="nx-margin-y-2m" />
    </div>
  </div>
  <div nxRow>
    <div nxCol="12">
      <nx-checkbox formControlName="waiverInd" (checkboxChange)="waiverIndOnChange()"> WAIVER OF COMPULSORY EXCESS IF
        NOT NAMED DRIVER </nx-checkbox>
    </div>
  </div>
  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <p nxCopytext="medium">
        <i>Note: If you have named driver, please key in now or select WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER
          for RM 20.00.</i>
      </p>
    </div>
  </div>
  }

  @if (rows.controls.length > 0) {
    @if (product == "082100" || product == "082101" ) {
    <div nxRow>
      <div nxCol="12">
        <hr class="nx-margin-y-2m" />
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <h4 class="nx-margin-0">Named Drivers</h4>
      </div>
    </div>
  }
  <div nxRow>
    <div nxCol="12" class="driver_list">
      <div class="nx-margin-top-2m">
        <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
          <table nxTable class="named_driver_table">
            <thead>
              <tr nxTableRow>
                <th nxHeaderCell></th>
                <th nxHeaderCell>Driver Name</th>
                <th nxHeaderCell>Driver ID</th>
                <th nxHeaderCell colspan="2">Driver Age</th>
              </tr>
            </thead>
            <tbody formArrayName="rows">
              @for (row of rows.controls; track row) {
              <tr nxTableRow [formGroupName]="$index">
                <td nxTableCell>{{ $index + 1 }}</td>
                <td nxTableCell>
                  <nx-formfield appearance="outline">
                    <input formControlName="driverName" maxlength="100" nxInput appToUpperCase />
                  </nx-formfield>
                </td>
                <td nxTableCell>
                  <nx-formfield appearance="outline" class="driver_id">
                    <input formControlName="driverId" maxlength="20" (change)="onChangeDriverId($index)" nxInput
                      appToUpperCase appAlphaNumeric />
                  </nx-formfield>
                </td>
                <td nxTableCell>
                  <nx-formfield appearance="outline" class="driver_age">
                    <input formControlName="driverAge" maxlength="3" nxInput [appOnlyNumber]="true" />
                  </nx-formfield>
                </td>
                @if ($index !== 0) {
                <td nxTableCell>
                  <button nxPlainButton type="button" class="nx-margin-left-m" (click)="deleteDriver($index)">
                    <nx-icon name="trash-o" size="s"></nx-icon>
                  </button>
                </td>
                }
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  }
  @if (product !== "082100" && product !== "082101" ) {
  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <button nxPlainButton type="button" class="nx-margin-right-m" (click)="addDriver()"
        [disabled]="isDisableAddDriverBtn"><nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>ADD
        DRIVER</button>
    </div>
  </div>
  }
  }

  <!--diaplay data-->
  @if (!isComprehensive) {
  <div nxRow>
    <div nxCol="12">
      <hr class="nx-margin-y-2m" />
    </div>
  </div>
  <div nxRow>
    <div nxCol="12">
      <h4 class="nx-margin-0">Named Drivers</h4>
    </div>
  </div>

  <div nxRow>
    <div nxCol="12" class="driver_list">
      <div class="nx-margin-top-2m">
        <app-external-scrollbar targetSyncScroll="3" [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3 syncScroll="3">
          <!-- table here -->
          <table nxTable class="named_driver_table">
            <thead>
              <tr nxTableRow>
                <th nxHeaderCell>Driver Name</th>
                <th nxHeaderCell>Driver ID</th>
                <th nxHeaderCell>Age</th>
              </tr>
            </thead>
            <tbody>
              <tr nxTableRow>
                <td nxTableCell class="text_no_wrap">{{ defaultDriver?.cngeRiskDrvrPkDto?.driverName }}</td>
                <td nxTableCell>{{ formatAgeAndId(defaultDriver?.driverId) }}</td>
                <td nxTableCell>{{ formatAgeAndId(defaultDriver?.driverAge) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  }
</form>