import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-view-extra-cover',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './view-extra-cover.component.html',
  styleUrl: './view-extra-cover.component.scss'
})
export class ViewExtraCoverComponent implements OnInit {
  @Input() currentExtraCoverList;
  @Input() prevExtraCoverList;
  @Input() isApproval;

  removeSumInsured = [
    "M01", "M02", "M03", "M07", "M08", "22", "22(a)", "24(c)", "24(d)", "25",
    "72", "100", "101", "101(a)", "102", "105", "108", "109", "111",
    "A200", "A201", "A202", "A202-1", "A202-2", "A203", "A203-1", "A203-2", "A203-3"
    , "A207", "PAB-OCR",
  ];

  motorRoadWarriroList = [
    {
      "code": "MR1",
      "descp": "1 UNIT"
    },
    {
      "code": "MR2",
      "descp": "2 UNITS"
    },
    {
      "code": "MR3",
      "descp": "3 UNITS"
    },
    {
      "code": "MR4",
      "descp": "4 UNITS"
    },
    {
      "code": "MR5",
      "descp": "5 UNITS"
    }
  ]

  driverPassangerList = [
    {
      "code": "PAB3A",
      "descp": "PLAN A (25000)"
    },
    {
      "code": "PAB3B",
      "descp": "PLAN B (50000)"
    }
  ]

  ngOnInit() {
    // if(this.currentExtraCoverList && this.currentExtraCoverList.length > 0) {
    //   this.reconstructCurrExtraCoverList();
    // }
    
    // if(this.prevExtraCoverList && this.prevExtraCoverList.length > 0) {
    //   this.reconstructPrevExtraCoverList();
    // }
  }

  reconstructCurrExtraCoverList() {
    this.currentExtraCoverList = this.currentExtraCoverList.map((obj) => {
      if (this.removeSumInsured.includes(obj.subCoverCode)) {
        return { ...obj, subCoverSumInsured: 0 };
      }
      return obj;
    });
  }

  reconstructPrevExtraCoverList() {
    this.prevExtraCoverList = this.prevExtraCoverList.map((obj) => {
      if (this.removeSumInsured.includes(obj.subCoverCode)) {
        return { ...obj, subCoverSumInsured: 0 };
      }
      return obj;
    });
  }

  getMRWDesc(code: any){
    const item = this.motorRoadWarriroList.find(item => item.code === code);
    return item ? item.descp : code;
  }

  getDriverPassenger(code: any){
    const item = this.driverPassangerList.find(item => item.code === code);
    return item ? item.descp : code;
  }

  getDropDownDesc(subCoverCode: any, code: any) {
    if (subCoverCode === 'PAB-RW') {
      return this.getMRWDesc(code);
    } else if (subCoverCode === 'PAB3') {
      return this.getDriverPassenger(code);
    } else if (subCoverCode === 'PAB-ERW') {
      
    } else {
      return code;
    }
  }

  removeNewLinesFromOpus(value: any,subCoverCode: string = "") {
    if (value) {
      if (subCoverCode === 'PAB3' && value.includes('$NEWLINES')) {
        return value.replace(/\$NEWLINESS.*/, "");
      } else if (value && value.includes("$NEWLINES")) {
        return value.replace("$NEWLINES", "<br>");
      }
    }
    return value;
  }
}
