import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { combineToDate, inBetween, splitNRIC } from '../../helpers/service.helper';
import { NricService } from '@services/nric/nric.service';
import { SharedModule } from '@shared/shared.module';
import { FormatDecimalPipe } from '@shared/pipes/format-decimal/format-decimal.pipe';
import { RemoveCommaPipe } from '@shared/pipes/remove-comma/remove-comma.pipe';
import { CALCULATE_PREMIUM, CALCULATE_PREMIUM_PAYLOAD, STORE_COVERAGE_TYPE, STORE_COVERAGE_VEHICLE_DETAILS } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-driver-name',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './driver-name.component.html',
  styleUrl: './driver-name.component.scss'
})
export class DriverNameComponent implements OnInit, OnChanges {
  /*
  * pending type coverage to connect to BE to get the default driver name, driver age and driver id list
  * pending extra coverage to complete
  */
  @Input() coverageFmGroup!: FormGroup;
  @Input() isComprehensive = false;
  @Input() isThirdParty = false;
  @Input() isThirdPartyFireTheft = false;
  @Input() defaultDriver: any;
  @Input() parentForm: FormGroup;
  @Output() selectExtraCover = new EventEmitter<any>();
  @Output() removeExtraCover = new EventEmitter<any>();
  @Output() selectWaiver = new EventEmitter<any>();
  @Output() selectAllDriver = new EventEmitter<any>();

  userInfo: any;
  driverNameForm: FormGroup;
  driverNameList: any;
  isDisableAddDriverBtn: boolean;
  /*
    'PRDCTA' = 'PRIVATE CARS EXCLUDING GOODS',
    'PRDCTB' = 'PRIVATE CARS INCLUDING GOODS',
    'PRDCTC' = 'PRIVATE CARS COMPANY REGISTERED',
    'PRDCTD' = 'MOTOR CYCLE',
    'PRDCTE' = 'MOTORCYCLE PLUS'
  */
  product: any;
  driverNameDetails: any;
  vehicleUnNamedDrvrInd: boolean;
  vehicleAlldrvrInd: boolean;

  unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private nricService: NricService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.driverNameForm && changes?.["defaultDriver"]) {
      this.rows.at(0).get('driverName').setValue(this.defaultDriver.cngeRiskDrvrPkDto.driverName);
      this.rows.at(0).get('driverId').setValue(this.defaultDriver.driverId);
      this.rows.at(0).get('driverAge').setValue(this.defaultDriver.driverAge);
      console.log("changed default driver: ", this.defaultDriver);
    }
  }
  
  ngOnInit() {
    this.userInfo = this.store.selectSnapshot<any>(state => state.UserState.user);
    this.product = this.store.selectSnapshot<String>(state => state.SatMotorState.motor.step1.issuance.issuance.productCode);
    this.driverNameDetails = this.store.selectSnapshot<String>(state => state.SatMotorState.motor.step3.coverage?.extracover?.driverNameDetails?.rows);
    this.vehicleUnNamedDrvrInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step3.coverage?.extracover?.driverNameDetails?.vehicleUnNamedDrvrInd);
    this.vehicleAlldrvrInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step3.coverage?.extracover?.driverNameDetails?.vehicleAlldrvrInd);

    this.driverNameForm = this.fb.group({
      allDriverInd: [this.defaultAllDriverInd()],
      waiverInd: [this.defaultWaiverInd()],
      rows: this.fb.array([])
    });
    
    if (this.driverNameDetails) {
      this.driverNameDetails.forEach((drv, index) => {
        this.rows.push(this.fb.group({
          driverName: [ { value: drv.driverName, disabled: index === 0 } ],
          driverId: [ { value: drv.driverId, disabled: index === 0 } ],
          driverAge: [ { value: drv.driverAge, disabled: index === 0 } ]
        }));
      });
    }else {
      this.initDefaultDriverInfo();
    }

    this.parentForm.controls["driverNameDetails"] = this.driverNameForm;

    this.isDisableAddDriverBtn = this.disableAddDriver();
    this.coverageFmGroup.controls["driverNameForm"] = this.driverNameForm;

  }

  initDefaultDriverInfo() {
    this.rows.push(this.fb.group({
      driverName: [{ value: this.defaultDriver?.cngeRiskDrvrPkDto?.driverName ? this.defaultDriver.cngeRiskDrvrPkDto.driverName : '', disabled: true }],
      driverId: [{ value: this.defaultDriver?.driverId ? this.defaultDriver.driverId : '', disabled: true }],
      driverAge: [{ value: this.defaultDriver?.driverAge ? this.defaultDriver.driverAge : 0, disabled: true }]
    }));
  }

  defaultAllDriverInd() {
    if (this.vehicleAlldrvrInd === undefined || this.vehicleAlldrvrInd === null) {
      if (this.product === '080200' || this.product === '080300') {
        this.selectExtraCover.emit(true);
        return true;
      } else {
        this.selectExtraCover.emit(false);
        return false;
      }
    }else {
      return this.vehicleAlldrvrInd;
    }

  }

  defaultWaiverInd() {
    if (this.vehicleUnNamedDrvrInd === undefined || this.vehicleUnNamedDrvrInd === null) {
      if (this.userInfo.callCenter) {
        return true;
      } else {
        return false;
      }
    }else {
      return this.vehicleUnNamedDrvrInd;
    }

  }

  addDriver() {
    let driverNameRow;

    driverNameRow = this.fb.group({
      driverName: [''],
      driverId: [''],
      driverAge: ['']
    });

    this.rows.push(driverNameRow);

    this.isDisableAddDriverBtn = this.disableAddDriver();


    console.log("rows: ", this.rows);
  }

  deleteDriver(index) {
    this.rows.removeAt(index);

    this.isDisableAddDriverBtn = this.disableAddDriver();

  }

  allDriverOnChange() {
    let allDriverInd = this.driverNameForm.controls['allDriverInd'].value;

    if (allDriverInd) {
      this.deleteSecondRowOnwards();
      this.rows.at(0).get('driverName').setValue(this.defaultDriver.cngeRiskDrvrPkDto.driverName);
      this.rows.at(0).get('driverId').setValue(this.defaultDriver.driverId);
      this.rows.at(0).get('driverAge').setValue(this.defaultDriver.driverAge);
      this.selectExtraCover.emit("M02");
    } else {
      this.rows.at(0).reset();
      this.removeExtraCover.emit("M02");
    }
    this.isDisableAddDriverBtn = this.disableAddDriver();
    this.selectAllDriver.emit(allDriverInd);

  }

  waiverIndOnChange() {
    let waiverInd = this.driverNameForm.controls['waiverInd'].value;

    if (waiverInd) {
      this.deleteSecondRowOnwards();
    }
    this.isDisableAddDriverBtn = this.disableAddDriver();
    this.selectWaiver.emit(waiverInd);
  }

  deleteSecondRowOnwards() {
    for (let i = this.rows.length - 1; i >= 1; i--) {
      this.rows.removeAt(i);
    }
  }

  disableAddDriver() {
    let allDriverInd = this.driverNameForm.controls['allDriverInd'].value;
    let waiverInd = this.driverNameForm.controls['waiverInd'].value;
    let driverListLength = this.rows.length;
    let disable = false;

    if (allDriverInd || waiverInd) {
      disable = true;
    } else {
      switch (this.product) {
        case '080100':
          disable = driverListLength >= 3 ? true : false;
          break;

        case '080200':
        case '080300':
          disable = driverListLength >= 2 ? true : false;
          break;
      }
    }
    return disable;
  }

  onChangeDriverId(index) {
    let driverId = this.rows.at(index).get('driverId').value;
    let isValidNric;

    try {
      isValidNric = this.nricService.isNRICValid(driverId);
    } catch (err) {
      isValidNric = false;
    }

    if (driverId && isValidNric) {
      const [input, year, month, day, birthPlace, ...rest] = splitNRIC(driverId);
      const driverBirthDate = combineToDate(year, month, day);
      const todayDate = new Date();

      if (driverBirthDate.getFullYear() > todayDate.getFullYear()) {
        throw new Error('Invalid Driver ID');
      } else {
        this.rows.at(index).get('driverAge').disable();
        let driverAge = todayDate.getFullYear() - driverBirthDate.getFullYear();
        this.rows.at(index).get('driverAge').setValue(driverAge);
      }
    } else {
      this.rows.at(index).get('driverAge').enable();
      this.rows.at(index).get('driverAge').setValue("");
    }



  }

  formatAgeAndId(value) {
    if(value == 0) {
      return 'N/A';
    }
    return value;
  }

  get isPrivateCarsExcludingGoods() {
    return this.product === '080100';
  }

  get isPrivateCarsIncludingGoods() {
    return this.product === '080200';
  }

  get isPrivateCarsCompanyRegistered() {
    return this.product === '080300';
  }

  get rows() {
    return this.driverNameForm.get('rows') as FormArray;
  }

}
