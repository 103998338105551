<!--coverageInfo = riskList[0].coverList[0]-->
<table nxTable cellpadding="0" class="summary_table nx-margin-bottom-2m">
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Coverage Type</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <p nxCopytext="normal">
                {{ formatData(coverageType) }}
            </p>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Vehicle Sum Insured (RM)</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatNumericData(data.riskList[0].coverList[0].sumInsured) | formatDecimal }}
                @if(showRSI())  {
                    &nbsp;<span nxCopytext="small"><i>{{ data?.riskList?.[0]?.recommendDesc ? ('(' + data.riskList[0].recommendDesc + ')')  : '' }}</i></span>
                } 
            </span>
        </td>
    </tr>
    <!-- @if(showRSI()) {
    <tr nxTableRow>
        @if(makeModelSiMin === makeModelSiMax) {
            <td nxTableCell>
                <span nxCopytext="normal">Recommended Sum Insured::</span>
            </td>
            <td nxTableCell class="column_cp_content">
                {{ formatData(recommendSumInsured) }}
            </td>
        }@else {
            <td nxTableCell>
                <span nxCopytext="normal">Recommended Sum Insured Range:</span>
            </td>
            <td nxTableCell class="column_cp_content">
                {{ formatData(recommendSumInsuredRange) }}
            </td> 
        }
        
    </tr>
    } -->
    
    @if(isApproval && isProgressTypeCN && cnoteType === 'RN') {
        <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">Previous Vehicle Sum Insured (RM)</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatNumericData(htgeData?.riskList[0]?.coverList[0]?.sumInsured) | formatDecimal }}</span>
        </td>
    </tr>
    }
    @if(showAVOrRV()) {
       <tr nxTableRow>
        <td nxTableCell>
            @if(data.vehicleClass === "01") {
                <span nxCopytext="normal">Recommended Value</span> 
            }@else {
                @if(data.riskList[0].coverList[0].coverCode === "01") {
                    <span nxCopytext="normal">Agreed Value</span> 
                }@else {
                    <span nxCopytext="normal">Recommended Value</span> 
                }
            }
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].avInd ? "YES" : "NO") }}</span>
        </td>
    </tr> 
    }
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">No Claim Discount (%)</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatNumericData(data.riskList[0].coverList[0].ncdPercentage) | formatDecimal }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">No Claim Discount From</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(noClaimDiscountFrom) }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">No Claim Discount Vehicle No.</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].prevNcdVeh) }}</span>
        </td>
    </tr>
    <tr nxTableRow>
        <td nxTableCell>
            <span nxCopytext="normal">No Claim Discount Effective Date</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(getDateFormat(data.riskList[0].prevNcdEffDate)) }}</span>
        </td>
    </tr>
    @if(isApproval) {
    <tr nxTableRow>
        <td nxTableCell><span nxCopytext="normal">Corporate Type</span></td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].corporateType) }}</span>
        </td>
    </tr>
    }
</table>