import {
  Component,
  ElementRef,
  HostListener,
  EventEmitter,
  Output,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from "@angular/core";
// import { CoverageComponent } from "../../../pages/coverage/coverage.component";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../popup/endorsement-popup/endorsement-popup.component";
import { 
  STORE_ENDORSEMENT,
  UPDATE_ENDORSEMENT_STEP2
 } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { SharedModule } from "../../../shared/shared.module";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { CoveragePopupComponent } from "../../../popup/coverage-popup/coverage-popup.component";
import { ExtraCoverageComponent } from "../coverages/extra-coverage/extra-coverage.component";
import { ThailandTripComponent } from "../thailand-trip/thailand-trip.component";
import { EHailingDriverComponent } from "../e-hailing-driver/e-hailing-driver.component";
import { ComprehensiveExtraCoverComponent } from "./comprehensive-extra-cover/comprehensive-extra-cover.component";
import { ThirdPartyExtraCoverComponent } from "./third-party-extra-cover/third-party-extra-cover.component";
import { ThirdPartyFireTheftExtraCoverComponent } from "./third-party-fire-theft-extra-cover/third-party-fire-theft-extra-cover.component";
import { ExtraCoverFormComponent } from "./extra-cover-form/extra-cover-form.component";

@Component({
  selector: 'app-extra-cover',
  standalone: true,
  imports: [
    SharedModule,
    NxSmallStageModule,
    TextFieldModule,
    ExtraCoverageComponent,
    ThailandTripComponent,
    EHailingDriverComponent,
    ComprehensiveExtraCoverComponent,
    ThirdPartyExtraCoverComponent,
    ThirdPartyFireTheftExtraCoverComponent,
    ExtraCoverFormComponent,
    ExtraCoverageComponent, //////////
  ],
  templateUrl: './extra-cover.component.html',
  styleUrl: './extra-cover.component.scss'
})
export class ExtraCoverComponent {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;

  // @Input() isComprehensive = false;
  @Input() isComprehensive = true;//////for testing purposes only
  @Input() isThirdParty = false;
  @Input() isThirdPartyFireTheft = false;
  @Input() defaultDriver: any;
  @Input() endorsementType: any;
  @Output() endtCalculatePremium = new EventEmitter<unknown>();

  endtVehiclePolicyData: any;
  endtPreviousVehiclePolicyData: any;

  extraCoverForm: FormGroup;
  extraCoverDetailForm: FormGroup;

  templateDialogRef?: NxModalRef<any>;
  // componentDialogRef?: NxModalRef<CoverageComponent>;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;
  @ViewChild("autosize") autosize!: CdkTextareaAutosize;

  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  endorsement_details = true;

  // makeModel = "PERODUA MYVI 1.3";

  // temporary implementation. future to implement form
  // characterCount: number = 0;
  // maxlength = 100;
  // onKeyUp(event: any) {
  //   this.characterCount = event.target.value.length + this.makeModel.length;
  // }

  // Dialog management
  openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
    this.templateDialogRef = this.dialogService.open(template, {
      height: config.height || '500px',
      width: config.width || '780px',
    });
  }

  closeDialog(): void {
    this.templateDialogRef?.close();
  }

  // For Coverage table
  show_comprehensive = true;
  show_tp = false;
  show_tpft = false;

  individual = true;
  company = true;
  sst = true;
  sme = true;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) { }

  ngOnInit() {

    // this.loadExtraCover();

    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    const endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);
    // console.log("endtVehiclePolicyData:", endtVehiclePolicyData);
    // console.log("endtPreviousVehiclePolicyData:", endtPreviousVehiclePolicyData);

    this.endtVehiclePolicyData = endtVehiclePolicyData
    this.endtPreviousVehiclePolicyData = endtPreviousVehiclePolicyData

    console.log("endtVehiclePolicyData:", this.endtVehiclePolicyData);
    console.log("endtPreviousVehiclePolicyData:", this.endtPreviousVehiclePolicyData);
  }

  // ngAfterViewInit() {}

  loadExtraCover() {
    this.extraCoverForm = new FormGroup({
      // vehicleNo: new FormControl('', [
      //   Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{1,20}$')
      // ]),
      // policyNo: new FormControl(''),
      // productCode: new FormControl(''),
      // policyEffectiveDate: new FormControl(''),
      // policyExpiryDate: new FormControl(''),
      // endorsementType: new FormControl(''), //Validators.required
      // endorsementEffectiveDate: new FormControl(''),
      // endorsementExpiryDate: new FormControl(''),
    });

    // this.loadEndorsementDetailsData();
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state) {
        if (state.extraCover) {
          this.extraCoverForm.patchValue(state.extraCover);
        }
      }
    });
  }


  comprehensive(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {
      height: "500px",
      width: "780px",
      maxWidth: "780px",
    });
  }

  tp(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  tpft(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  // policyTable(): void {
  //   this.templateDialogRef = this.dialogService.open(this.templateRef3, {
  //     height: "500px",
  //   });
  //   ``;
  // }

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  // makemodel(): void {
  //   this.templateDialogRef = this.dialogService.open(this.templateRef2, {
  //     height: "500px",
  //     width: "920px"
  //   });
  // }

  // search_mode_address = true;
  // changeSearchMode(event: any) {
  //   // debugger;
  //   if (event.value == "address") {
  //     this.search_mode_address = true;
  //   } else {
  //     this.search_mode_address = false;
  //   }
  // }

  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;

  openPopup(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopup3(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      data: { popup_type: popup_type },
    });
  }


  // Validation //to be replaced because now using formbuilder in constructor

  //////////////////////////////////////////////////////
  planunitFormControl = new FormControl('', [
    Validators.required,
  ]);

  amountdayFormControl = new FormControl('', [
    Validators.required,
  ]);

  amountFormControl = new FormControl('', [
    Validators.required,
  ]);


  storeEndorsementDetailsData() {
    if (this.extraCoverForm.valid) {
      let extraCover = this.extraCoverForm.getRawValue();
      console.log('Saving Extra Coverage Data:', extraCover);
      let payload = {
        extraCover: extraCover,
        isExtraCover: true
      }
      this.store.dispatch(new STORE_ENDORSEMENT(payload));

      let endtUpdatedData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step2?.endtUpdatedData);
      if (!endtUpdatedData) {
        endtUpdatedData = this.endtVehiclePolicyData
      }

      //need to change the endtUpdatedData to include the new extra coverage

      // endtUpdatedData = { 
      //   ...endtUpdatedData,
      //   riskList: [{
      //     ...endtUpdatedData.riskList[0],
      //     vehicleUse: vehicleDetails.vehicleUse,
      //     vehicleNo: vehicleDetails.vehicleNo,
      //     vehicleMakeYear: vehicleDetails.vehicleMakeYear,
      //     makeModel: vehicleDetails.makeModel, //opus is null
      //     vehicleUom: vehicleDetails.vehicleUom,
      //     vehicleCapacity: vehicleDetails.vehicleCapacity,
      //     engineType: vehicleDetails.engineType, //opus is null
      //     vehicleSeat: vehicleDetails.vehicleSeat,
      //     vehicleEngine: vehicleDetails.vehicleEngine,
      //     vehicleChassis: vehicleDetails.vehicleChassis,
      //   }]
      // };

      this.store.dispatch(new UPDATE_ENDORSEMENT_STEP2({
        isReset: false,
        cngeNote: endtUpdatedData
      }));
      this.endtCalculatePremium.emit();

    } else {
      console.error('Extra Coverage form is invalid!');
      // this.logFormErrors(this.extraCoverForm);
      this.extraCoverForm.markAllAsTouched();
    }
  }

}
