<form [formGroup]="parentForm">
    <nx-card class="card_summary premium_approval nx-margin-bottom-2m">
        <table nxTable cellpadding="0" class="coverage_premium_summary_table">
            <tr nxTableRow>
                <td nxTableCell>
                    <span nxCopytext="normal" class="font-grey">Premium Summary</span>
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal" class="font-grey">Subtotal (RM)</span>
                </td>
                @if(isRenewal) {
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal" class="font-grey">Previous Subtotal (RM)</span>
                </td>
                }

            </tr>

            @if(coverageType === '01' || coverageType === '30') {
            <tr nxTableRow>
                <td nxTableCell>
                    <span nxCopytext="normal">Risk Excess</span>
                </td>
                <td nxTableCell class="column_form_content">
                    <nx-formfield class="form_row_inline">
                        <input nxInput formControlName="riskExcess" (change)="onRiskExcessChange()"
                            [appOnlyNumber]="true" />
                    </nx-formfield>
                </td>
                @if(isRenewal && (htgeData?.riskList[0].coverList[0].coverCode === '01' ||
                htgeData?.riskList[0].coverList[0].coverCode ===
                '30')) {
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal">{{ formatData(htgeData?.riskList[0].coverList[0].excessAmount) |
                        formatDecimal }}</span>
                </td>
                }
            </tr>
            }

            @if((coverageType === '01' || coverageType === '30') && (calculationType === 'DTT' || calculationType ===
            'DNT')) {
            <tr nxTableRow>
                <td nxTableCell>
                    <span nxCopytext="normal">Voluntary Excess</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].excessAmount) | formatDecimal
                        }}</span>
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if(coverageType === '01' || coverageType === '30') {
            <tr nxTableRow>
                <td nxTableCell>
                    <span nxCopytext="normal">Basic Premium</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].basicPremium) | formatDecimal
                        }}</span>
                </td>
                @if(isRenewal && (htgeData?.riskList[0].coverList[0].coverCode === '01' ||
                htgeData?.riskList[0].coverList[0].coverCode ===
                '30')) {
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal">{{ formatData(htgeData?.riskList[0].coverList[0].basicPremium) |
                        formatDecimal }}</span>
                </td>
                }
            </tr>
            }

            @if((coverageType === '01' || coverageType === '30') && (calculationType === 'DTT' || calculationType ===
            'DNT')) {
            <tr nxTableRow>
                <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Agent Premium Adjustment</span>
                            </td>
                            <td nxTableCell class="std-ta-right">
                                <span nxCopytext="normal">{{
                                    formatData(data.riskList[0].coverList[0].discountPercentageAgent) }}<span
                                        class="nx-margin-x-3xs">%</span></span>
                            </td>
                        </tr>
                    </table>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    {{ formatData(data.riskList[0].coverList[0].discountAmountAgent) | formatDecimal }}
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if((coverageType === '01' || coverageType === '30') && (calculationType === 'DTT' || calculationType ===
            'DNT')) {
            <tr nxTableRow>
                <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Branch Premium Adjustment</span>
                            </td>
                            <td nxTableCell class="std-ta-right">
                                <span nxCopytext="normal">{{
                                    formatData(data.riskList[0].coverList[0].discountPercentageBranch) }}<span
                                        class="nx-margin-x-3xs">%</span></span>
                            </td>
                        </tr>
                    </table>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].discountAmountBranch) |
                        formatDecimal }}</span>
                </td>
                <td nxTableCell class="td-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if((coverageType === '01' || coverageType === '30') && (calculationType === 'DTT' || calculationType ===
            'DNT')) {
            <tr nxTableRow>
                <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">HQ Premium Adjustment</span>
                            </td>
                            <td nxTableCell class="std-ta-right">
                                <nx-formfield class="form_row_inline">
                                    <input nxInput formControlName="hqPremiumPct" (change)="onHQPremiumPctChange()"
                                        [appOnlyNumber]="true" />

                                    <span nxCopytext="normal" nxFormfieldAppendix>%</span>
                                </nx-formfield>
                            </td>
                        </tr>
                    </table>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].discountAmountHq) |
                        formatDecimal }}</span>
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if((coverageType === '01' || coverageType === '30') && (calculationType === 'DTT' || calculationType ===
            'DNT')) {
            <tr nxTableRow>
                <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Total Premium Adjustment</span>
                            </td>
                            <td nxTableCell class="std-ta-right">
                                <span nxCopytext="normal">{{
                                    formatData(data.riskList[0].coverList[0].discountPercentage) }}<span
                                        class="nx-margin-x-3xs">%</span></span>
                            </td>
                        </tr>
                    </table>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].discountAmount) |
                        formatDecimal }}</span>
                </td>
                <td nxTableCell class="td-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if((coverageType === '01' || coverageType === '30') && (calculationType === 'DTT' || calculationType ===
            'DNT')) {
            <tr nxTableRow>
                <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Remaining Premium Adjustment</span>
                            </td>
                            <td nxTableCell class="std-ta-right">
                                <span nxCopytext="normal">{{
                                    formatData(data.riskList[0].coverList[0].remainingDiscountPct) }}<span
                                        class="nx-margin-x-3xs">%</span></span>
                            </td>
                        </tr>
                    </table>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].remainingDiscount) |
                        formatDecimal }}</span>
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if(coverageType === '01' || coverageType === '30') {
            <tr nxTableRow>
                <td nxTableCell>
                    <span nxCopytext="normal">Final Basic Premium</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].basicPremium) | formatDecimal
                        }}</span>
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if(calculationType === 'DTT' || calculationType === 'DNT') {
            <tr nxTableRow>
                <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Actual Premium / Technical Premium</span>
                            </td>
                            <td nxTableCell class="std-ta-right">
                                <span nxCopytext="normal" class="nx-margin-right-s">{{
                                    formatData(data.riskList[0].coverList[0].aptpRate) | formatDecimal }}</span>
                            </td>
                        </tr>
                    </table>
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
                <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                </td>
            </tr>
            }

            @if(coverageType === '20') {
            <tr nxTableRow>
                <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                        <tr nxTableRow>
                            <td nxTableCell>
                                <span nxCopytext="normal">Loading</span>
                            </td>
                            <td nxTableCell class="std-ta-right">
                                <nx-formfield class="form_row_inline">
                                    <input nxInput formControlName="loading" [appOnlyNumber]="true" (change)="onLoadingPctChange()"/>
                                  
                                    <span nxCopytext="normal" nxFormfieldAppendix>%</span>
                                </nx-formfield>
                            </td>
                        </tr>
                    </table>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">{{ formatData(data.riskList[0].coverList[0].loadAmount) | formatDecimal
                        }}</span>
                </td>
                @if (isRenewal) {
                <td nxTableCell class="std-ta-right">
                    <div class="loading">
                        <span nxCopytext="normal" class="percent">{{
                            formatData(htgeData?.riskList[0].coverList[0].loadPercentage) }}% :</span>
                        <span nxCopytext="normal">{{ formatData(htgeData?.riskList[0].coverList[0].loadAmount) |
                            formatDecimal }}</span>
                    </div>
                </td>
                }
            </tr>
            }
        </table>
    </nx-card>
</form>