import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { SME_DUPLICATION_CHECKING } from '@store/sat-motor/sat-motor.action';
import moment from 'moment';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sme-cert-form',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './sme-cert-form.component.html',
  styleUrl: './sme-cert-form.component.scss'
})
export class SmeCertFormComponent implements OnInit {
  @Input() customerInfoForm: FormGroup;
  @Input() cpDetails: any;
  @Input() smeCategoryList: any;
  @Input() mkagAgent: any;

  unsubscribe$ = new Subject<void>();

  constructor(
    private store:Store
  ) {}

  ngOnInit() {
    this.initForm();
    this.onChangeSmeRegisteredInd();
    this.onChangeSmeStartDate();
    this.onChangeSmeEndDate();
  }
  
  initForm() {
    this.customerInfoForm.addControl('smeRegisteredInd', new FormControl(this.cpDetails?.smeRegisteredInd ? 'Y' : 'N')); //pending integrate to OPUS for duplicate checking
    this.customerInfoForm.addControl('smeRegNo', new FormControl(this.cpDetails?.smeRegisteredNumber ? this.cpDetails?.smeRegisteredNumber : ''));
    this.customerInfoForm.addControl('smeCategory', new FormControl(this.cpDetails?.smeCategory ? this.cpDetails?.smeCategory : ''));
    this.customerInfoForm.addControl('smeStartDate', new FormControl(this.cpDetails?.smeStartDate ? this.cpDetails?.smeStartDate : ''));
    this.customerInfoForm.addControl('smeEndDate', new FormControl(this.cpDetails?.smeEndDate ? this.cpDetails?.smeEndDate : ''));

    if (this.customerInfoForm.controls['smeRegisteredInd'].value === 'Y') {
      this.addValidators();
    }else {
      this.clearValidators();
    }
  }
  
  addValidators(): void {
    this.customerInfoForm.controls['smeRegNo'].setValidators([Validators.required, Validators.pattern('^SME\\w+$')]);
    this.customerInfoForm.controls['smeCategory'].setValidators(Validators.required);
    this.customerInfoForm.controls['smeStartDate'].setValidators(Validators.required);
    this.customerInfoForm.controls['smeEndDate'].setValidators(Validators.required);

    this.customerInfoForm.updateValueAndValidity();

    this.customerInfoForm.controls['smeRegNo'].markAsUntouched();
    this.customerInfoForm.controls['smeCategory'].markAsUntouched();
    this.customerInfoForm.controls['smeStartDate'].markAsUntouched();
    this.customerInfoForm.controls['smeEndDate'].markAsUntouched();
  }

  addDefaultValue() {
    // this.customerInfoForm.controls['sstResidentStatus'].setValue('Resident');
    // this.customerInfoForm.controls['sstCountry'].setValue('Malaysia');
  }

  clearValidators() {
    this.customerInfoForm.controls['smeRegNo'].clearValidators();
    this.customerInfoForm.controls['smeCategory'].clearValidators();
    this.customerInfoForm.controls['smeStartDate'].clearValidators();
    this.customerInfoForm.controls['smeEndDate'].clearValidators();
    this.customerInfoForm.updateValueAndValidity();

  }

  onChangeSmeRegisteredInd() {
    this.customerInfoForm.controls['smeRegisteredInd'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
         if (this.customerInfoForm.controls['smeRegisteredInd'].value === 'Y') {
          this.addValidators();
          this.addDefaultValue();
        }else {
          this.clearValidators();
          this.resetForm();
        }
    });
  }

  onChangeSmeStartDate() {
    this.customerInfoForm.controls['smeStartDate'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let smeRegisteredInd = this.customerInfoForm.controls['smeRegisteredInd'].value;
      if (smeRegisteredInd === 'Y') {
        let startDate = value ? new Date(value) : null;
        startDate.setHours(0, 0, 0, 0);
        let startDateAfter2Year = startDate ? new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate() - 1) : null;
        const currentDate = new Date();

        currentDate.setHours(0, 0, 0, 0);

        if (startDate > currentDate) {
          this.customerInfoForm.controls['smeStartDate'].setErrors({ 'invalidSMEStartDate': true });
        } else {
          this.customerInfoForm.controls['smeEndDate'].setValue(startDateAfter2Year);
        }
      }
    });
  }

  onChangeSmeEndDate() {
    this.customerInfoForm.controls['smeEndDate'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let smeRegisteredInd = this.customerInfoForm.controls['smeRegisteredInd'].value;
      if(smeRegisteredInd === 'Y') {
        let startDate = this.customerInfoForm.controls['smeStartDate'].value ? new Date(this.customerInfoForm.controls['smeStartDate'].value): null;
        startDate.setHours(0, 0, 0, 0);
        let startDateAfter2Year = startDate ? new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate() - 1) : null;
        let endDate = new Date(value);
        endDate.setHours(0, 0, 0, 0);
  
        if (startDate && endDate) {
          if (endDate < startDate) {
            this.customerInfoForm.controls['smeEndDate'].setErrors({ 'smeSDLaterThanSmeED': true });
          }
  
          if (endDate > startDateAfter2Year) {
            this.customerInfoForm.controls['smeEndDate'].setErrors({ 'smeEDMoreThanTwoYear': true });
          }
        }
      }      
      
    });
  }

  resetForm() {
    this.customerInfoForm.get('smeRegNo').reset();
    this.customerInfoForm.get('smeCategory').reset();
    this.customerInfoForm.get('smeStartDate').reset();
    this.customerInfoForm.get('smeEndDate').reset();
  }

  onChangeSmeRegNo() {
    let smeRegNoControl = this.customerInfoForm.get('smeRegNo');
    let smeRegNoValue = smeRegNoControl.value;
    if(smeRegNoControl.valid && smeRegNoValue && smeRegNoValue !== this.cpDetails.smeRegisteredNumber) {
      this.checkSmeDuplicate();
    }
  }

  checkSmeDuplicate() {
    let payload = this.generateCPPayload();

    this.store.dispatch(new SME_DUPLICATION_CHECKING(payload)).subscribe(state => {
      let isSmeDuplicate = this.store.selectSnapshot<any>(state => state.SatMotorState.smeDuplicate);

      if(isSmeDuplicate) {
        this.customerInfoForm.get('smeRegNo').setErrors({ 'smeRegNoDuplicated': true });
      }
    });
  }

  generateCPPayload() {
    const formValue = this.customerInfoForm.getRawValue();
    const searchCriteria = this.store.selectSnapshot(state => state.SatMotorState.data.step2.search);
    const date = new Date();
    const effectiveDate = moment(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'YYYY-M-D').format('YYYY-MM-DD');
    const user = this.store.selectSnapshot<string>((state) => state.UserState.user.userId);

    const payload = {
      clientPartner: {
        clientAge: formValue.age,
        clientDateofBirth: formValue.dob ? moment(formValue.dob, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientEmail: formValue.email,
        clientGender: formValue.gender,
        clientMobileCode1: formValue.mobileNoCode,
        clientMobileNo1: formValue.mobileNo,
        clientName: formValue.name,
        clientNationality: formValue.nationality,
        clientMaritalStatus: formValue.maritalStatus,
        clientPartnerAddress: {
          clientUnitNo: formValue.unitNo,
          clientAddress1: formValue.address1,
          clientAddress2: formValue.address2,
          clientAddress3: formValue.address3,
          clientAddressType: formValue.addressType,
          clientCity: formValue.city,
          clientCountry: formValue.country,
          clientPhoneCode: formValue.fixedLineNoCode,
          clientPhoneNo: formValue.fixedLineNo,
          clientFax1Code: formValue.faxNoCode,
          clientFax1No: formValue.faxNo,
          clientPostcode: formValue.postCode,
          clientState: formValue.state,
          clientGeoLatitude : formValue.clientGeoLatitude,
          clientGeoLongitude : formValue.clientGeoLongitude,
          clientGeoPlaceId : formValue.clientGeoPlaceId,
          clientGeoPlusCode : formValue.clientGeoPlusCode,
          clientGeoFormattedAddress : formValue.clientGeoFormattedAddress
        },
        clientType: this.cpDetails.clientType,
        defaultMailReq: false,
        idType1: this.cpDetails.idType1,
        idType2: formValue.idType2,
        idValue1: this.cpDetails.idValue1,
        idValue2: formValue.idValue2,
        updateCustomerPartner: false,
        clientTinNo: formValue.tinNo,
        clientSstRegInd: formValue.sstRegisteredInd === 'Y' ? true : false,
        clientSstRegNo: formValue.sstNo,
        clientSstRegDate: formValue.sstEffDate && moment(formValue.sstEffDate).isValid() ? moment(formValue.sstEffDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstDeRegDate: formValue.sstDeRegDate && moment(formValue.sstDeRegDate).isValid() ? moment(formValue.sstDeRegDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        clientSstResidentStatus: formValue.sstResidentStatus,
        clientSstCountry: formValue.sstCountry,
        smeCategory: formValue.smeCategory,
        smeEndDate: formValue.smeEndDate ? moment(formValue.smeEndDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : '',
        smeRegisteredInd: formValue.smeRegisteredInd === 'Y' ? true : false,
        smeRegisteredNumber: formValue.smeRegNo,
        smeStartDate: formValue.smeStartDate ? moment(formValue.smeStartDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : ''
      },
      mkagAgent: this.mkagAgent,
      effectiveDate: effectiveDate,
      productType: 'MT',
      operator: user
    }

    return payload;
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }
}
