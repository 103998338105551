const PREFIX = 'NOTIFICATION';

export class GET_NOTIFICATION_LIST {
    static readonly type = `[${PREFIX}] GET NOTIFICATIN LIST`;
    constructor(public readonly payload, public isStoreList: boolean) { }
}

export class SET_NOTIFICATION {
    static readonly type = `[${PREFIX}] SET NOTIFICATION`;
    constructor(public readonly payload) { }
}

export class SET_CONNECTED {
    static readonly type = `[${PREFIX}] SET cONNECTED STATUS`;
    constructor(public readonly payload){}
}

export class RESET_NOTIFICATION {
    static readonly type = `[${PREFIX}] RESET NOTIFICATION`;
}

export class START_SOCKET_CONNECTION {
    static readonly type = `[${PREFIX}] RESET NOTIFICATION`;
}

export class SEND_READ_NOTIFICATION {
    static readonly type  = `[${PREFIX}] SEND READ NOTIFICATION`;
    constructor(public readonly payload: any) { }

}

export class RESET_NOTIFICATION_LIST {
    static readonly type = `[${PREFIX}] RESET  NOTIFICATION LIST`;
}

export class UPDATE_UNREAD_NOTIFICATION {
    static readonly type = `[${PREFIX}] UPDATE UNREAD NOTIFICATION`;
    constructor(public readonly payload: any) { }
}

export class GET_NOTIFICATION_DETAILS {
    static readonly type = `[${PREFIX} GET NOTIFICATION DETAILS]`;
    constructor(public readonly payload: any) { }
}