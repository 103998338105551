import { Injectable } from '@angular/core';
import { INric } from 'src/app/interfaces/nric';
import { combineToDate, inBetween, splitNRIC } from '../../helpers/service.helper';
import { isStateValid } from '../../helpers/state.helper';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class NricService implements INric {

  constructor(private store: Store) { }

  isNRICValid(value: string): boolean {
    const [input, year, month, day, birthPlace, ...rest] = splitNRIC(value);
    const birthDate = combineToDate(year, month, day);

    if (inBetween(month, 1, 12) && inBetween(day, 1, 31))
      return birthDate && isStateValid(birthPlace);
    return false;
  }

  autoPopulateGenderBasedOnNRIC(value: string) {
    let lastIcNo = Number(value.charAt(value.length - 1));

    if (lastIcNo % 2 !== 0)
      return 'M';
    else
      return 'F';
  }

  getDateOfBirth(value: string) {
    const [input, year, month, day, birthPlace, ...rest] = splitNRIC(value);
    const birthDate = combineToDate(year, month, day);
    return birthDate;
  }

  getAge(value: string) {
    const birthDate = new Date(this.getDateOfBirth(value));
    const currentDate = new Date();
    
    currentDate.setHours(0,0,0,0);

    let dobYear = birthDate.getFullYear();
    let currentYear = currentDate.getFullYear();

    let age = currentYear - dobYear;
    return age;
  }
}
