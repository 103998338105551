import { Injectable } from '@angular/core';
import { CLIENTTYPE, IDTYPE } from '@shared/enums/index.enum';

@Injectable({
  providedIn: 'root'
})
export class CpMandatoryFilledService {

  isCompOrCustInfoIncomplete: boolean;
  isContactInfoIncomplete: boolean;
  isCorrespondenceInfoIncomplete: boolean;

  checkCPMandatoryFilled(cpDetails, emailCheck, eInvoiceConsent) {
    if (cpDetails.clientType === CLIENTTYPE.INDIVIDUAL) {
      if (cpDetails.clientName && cpDetails.clientGender && cpDetails.clientDateofBirth && cpDetails.clientMaritalStatus) {
        this.isCompOrCustInfoIncomplete = false;

        if(cpDetails.idType1 === IDTYPE.OLD_IC && !cpDetails.idType2) {
          this.isCompOrCustInfoIncomplete = true;
        }
        
      } else {
        this.isCompOrCustInfoIncomplete = true;
      }

      if (cpDetails.clientMobileNo1) {
        this.isContactInfoIncomplete = false;

        if((emailCheck || eInvoiceConsent) && !cpDetails.clientEmail) {
          this.isContactInfoIncomplete = true;
        }
      } else {
        this.isContactInfoIncomplete = true;
      }

      if (cpDetails.clientPartnerAddress.clientAddress1 && cpDetails.clientPartnerAddress.clientPostcode && cpDetails.clientPartnerAddress.clientAddressType) {
        this.isCorrespondenceInfoIncomplete = false;
      } else {
        this.isCorrespondenceInfoIncomplete = true;
      }

    } else {
      if (cpDetails.clientName) {
        this.isCompOrCustInfoIncomplete = false;

        if(eInvoiceConsent && !cpDetails.clientTinNo) {
          this.isCompOrCustInfoIncomplete = true;
        }
      } else {
        this.isCompOrCustInfoIncomplete = true;
      }

      if (cpDetails.clientMobileNo1 || cpDetails?.clientPartnerAddress?.clientPhoneNo) {
        this.isContactInfoIncomplete = false;

        if((emailCheck || eInvoiceConsent) && !cpDetails.clientEmail) {
          this.isContactInfoIncomplete = true;
        }
      } else {
        this.isContactInfoIncomplete = true;
      }

      if (cpDetails?.clientPartnerAddress?.clientAddress1 && cpDetails?.clientPartnerAddress?.clientPostcode && cpDetails?.clientPartnerAddress?.clientAddressType) {
        this.isCorrespondenceInfoIncomplete = false;
      } else {
        this.isCorrespondenceInfoIncomplete = true;
      }
    }

    return {
      isCompOrCustInfoIncomplete: this.isCompOrCustInfoIncomplete,
      isContactInfoIncomplete: this.isContactInfoIncomplete,
      isCorrespondenceInfoIncomplete: this.isCorrespondenceInfoIncomplete
    };

  }

}
