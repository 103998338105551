import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDecimal'
})
export class FormatDecimalPipe implements PipeTransform {

  transform(value: any): string {
    if (value === null || value === undefined || value === '') {
        return '0.00';
    }

    let cleanedValue = value.toString().replace(/[^\d.-]/g, '');

    if (isNaN(cleanedValue)) {
        return '0.00';
    }

    let numberValue = parseFloat(cleanedValue);

    let formattedValue = numberValue.toFixed(2);

    let parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  }

}
