import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { fromEvent, asyncScheduler } from 'rxjs';
import { map, startWith, throttleTime } from 'rxjs/operators';

/**
 * Provider that defines when form controls have an error.
 */
class ErrorStateMatcher {
  isErrorState(control, form) {
    return !!(control?.invalid && (control.touched || form?.submitted));
  }
  static {
    this.ɵfac = function ErrorStateMatcher_Factory(t) {
      return new (t || ErrorStateMatcher)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ErrorStateMatcher,
      factory: ErrorStateMatcher.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ErrorStateMatcher, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Assert that a value is not `null` and redefine its type.
 */
function notNull(value) {
  return value !== null;
}
/**
 * Assert that a value is not `undefined` or `null` and redefine its type.
 */
function notNullable(value) {
  return value != null;
}
/**
 * Assert that a value is not `undefined` and redefine its type.
 */
function notUndefined(value) {
  return value !== undefined;
}
/**
 * Assert that a value is truthy using the double negation operator `!!` and redefine its type.
 */
function isTruthy(value) {
  return !!value;
}
/**
 * Assert that a value is `string` using `typeof` check and redefine its type.
 */
function isString(value) {
  return typeof value === 'string';
}
/**
 * Assert that a value is `number` using `typeof` check and redefine its type.
 */
function isNumber(value) {
  return typeof value === 'number';
}
/**
 * Assert that a value is `boolean` using `typeof` check and redefine its type.
 */
function isBoolean(value) {
  return typeof value === 'boolean';
}
/**
 * Assert that a value is `true` and redefine its type.
 */
function isTrue(value) {
  return value === true;
}
/**
 * Assert that a value is `false` and redefine its type.
 */
function isFalse(value) {
  return value === false;
}
/**
 * Assert that a value is `null` and redefine its type.
 */
function isNull(value) {
  return value === null;
}
/**
 * Assert that a value is `null` or `undefined` and redefine its type.
 */
function isNullable(value) {
  return value == null;
}
/**
 * Assert that a value is `undefined` and redefine its type.
 */
function isUndefined(value) {
  return value === undefined;
}
/**
 * Assert that a value is not `undefined` or `null` or an empty `ArrayLike` object such as `string` and redefine its type.
 */
function notEmpty(value) {
  return value != null && !!value.length;
}

// TODO comment function purpose
function pad(str, length = 2, padCharacter = '0') {
  if (typeof str !== 'string' || str.length >= length) {
    return str;
  }
  while (str.length < length) {
    str = padCharacter + str;
  }
  return str;
}
// DATE -> YYYY-MM-DD
// TODO comment function purpose
function formatDate(date) {
  return [String(date.getFullYear()), pad(String(date.getMonth() + 1)), pad(String(date.getDate()))].join('-');
}
// TODO comment function purpose
function formatDateHuman(date) {
  return [pad(String(date.getDate())), pad(String(date.getMonth() + 1)), String(date.getFullYear())].join('-');
}
/**
 * The purpose of this function is to allow a list of short keywords
 * expand to longer bem class names with will then be applied to the classname value.
 *
 * This function will map a list of keys to values in a MAPPING list.
 * Whatever value is found will replace the keyword.
 * Every keyword not found will just transfered wiithmout modifying.
 */
function mapClassNames(value, DEFAULTS = [], MAPPING = {}) {
  let sanitizedList = [...DEFAULTS];
  if (typeof value === 'string') {
    const mappedClasses = getClassNameList(value, MAPPING);
    sanitizedList = [...sanitizedList, ...mappedClasses];
  }
  return sanitizedList.join(' ').trim();
}
// TODO comment function purpose
function getClassNameList(value, MAPPING = {}) {
  let mappedClasses = [];
  if (typeof value === 'string') {
    const classNames = value.split(' ');
    const keys = Object.keys(MAPPING);
    mappedClasses = classNames.map(className => {
      if (keys.includes(className)) {
        return MAPPING[className];
      }
      return className;
    });
  }
  return mappedClasses;
}
/**
 * Add classes to the native element using the provided renderer.
 */
function appendClasses(renderer, element, classes) {
  if (renderer && element && classes) {
    classes.split(' ').forEach(item => {
      renderer.addClass(element.nativeElement, item);
    });
  }
}
/**
 * Remove classes from the native element using the provided renderer.
 */
function removeClasses(renderer, element, classes) {
  if (renderer && element && classes) {
    classes.split(' ').forEach(item => {
      renderer.removeClass(element.nativeElement, item);
    });
  }
}
/**
 * Create a new `Date` instance using the provided value.
 */
function parseDate(dateString) {
  return new Date(dateString);
}
/**
 * Manually compose a font shorthand defintion as it's not guaranteed to be given by the computed style object.
 */
function getFontShorthand(style) {
  const {
    font,
    fontStyle,
    fontVariant,
    fontWeight,
    fontSize,
    lineHeight,
    fontFamily
  } = style;
  if (font.length > 0) {
    return font;
  }
  return `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`;
}
/**
 * Return the number of decimal places of a number.
 */
function numberOfDecimals(number) {
  const parsed = Number(number);
  if (Number.isNaN(parsed) || Number.isInteger(parsed)) {
    return 0;
  }
  const match = parsed.toString().match(/(?:\.(\d+))?(?:E([+-]?\d+))?$/i);
  if (!match || !match[1]) {
    return 0;
  }
  return match[1].length;
}
/**
 * Return the provided value if in specified range, the lower or upper boundary otherwise.
 */
function clamp(value, min = 0, max = 1) {
  return Math.max(min, Math.min(max, value));
}
/**
 * Generate a random string.
 */
function randomString() {
  return Math.random().toString(36);
}

/** Available breakpoints to subscribe to. */
var NxBreakpoints;
(function (NxBreakpoints) {
  /** Min size for BREAKPOINT_MOBILE */
  NxBreakpoints[NxBreakpoints["BREAKPOINT_XSMALL"] = 0] = "BREAKPOINT_XSMALL";
  NxBreakpoints[NxBreakpoints["BREAKPOINT_SMALL"] = 320] = "BREAKPOINT_SMALL";
  /** Min size for BREAKPOINT_TABLET */
  NxBreakpoints[NxBreakpoints["BREAKPOINT_MEDIUM"] = 704] = "BREAKPOINT_MEDIUM";
  /** Min size for BREAKPOINT_DESKTOP */
  NxBreakpoints[NxBreakpoints["BREAKPOINT_LARGE"] = 992] = "BREAKPOINT_LARGE";
  NxBreakpoints[NxBreakpoints["BREAKPOINT_XLARGE"] = 1280] = "BREAKPOINT_XLARGE";
  NxBreakpoints[NxBreakpoints["BREAKPOINT_2XLARGE"] = 1472] = "BREAKPOINT_2XLARGE";
  NxBreakpoints[NxBreakpoints["BREAKPOINT_3XLARGE"] = 1760] = "BREAKPOINT_3XLARGE";
})(NxBreakpoints || (NxBreakpoints = {}));
const DEFAULT_THROTTLE_TIME = 200;
/**
 * Service subscribing to window resize events and providing breakpoint matching functions.
 */
class NxViewportService {
  constructor() {
    this.viewportChange$ = fromEvent(window, 'resize').pipe(map(() => window.innerWidth));
  }
  /** Returns whether the current viewport width is greater than or equal (>=) to minSize. */
  min(minSize, throttleTimeMs = DEFAULT_THROTTLE_TIME) {
    return this.viewportChange$.pipe(startWith(window.innerWidth), throttleTime(throttleTimeMs, asyncScheduler, {
      trailing: true
    }), map(windowInnerWidth => windowInnerWidth >= minSize));
  }
  /** Returns whether the current viewport width is lower (<) than maxSize. */
  max(maxSize, throttleTimeMs = DEFAULT_THROTTLE_TIME) {
    return this.viewportChange$.pipe(startWith(window.innerWidth), throttleTime(throttleTimeMs, asyncScheduler, {
      trailing: true
    }), map(windowInnerWidth => windowInnerWidth < maxSize));
  }
  /** Returns whether the current viewport width is greater than or equal (>=) to minSize and lower (<) than maxSize. */
  between(minSize, maxSize, throttleTimeMs = DEFAULT_THROTTLE_TIME) {
    return this.viewportChange$.pipe(startWith(window.innerWidth), throttleTime(throttleTimeMs, asyncScheduler, {
      trailing: true
    }), map(windowInnerWidth => windowInnerWidth >= minSize && windowInnerWidth < maxSize));
  }
  static {
    this.ɵfac = function NxViewportService_Factory(t) {
      return new (t || NxViewportService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NxViewportService,
      factory: NxViewportService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxViewportService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ErrorStateMatcher, NxBreakpoints, NxViewportService, appendClasses, clamp, formatDate, formatDateHuman, getClassNameList, getFontShorthand, isBoolean, isFalse, isNull, isNullable, isNumber, isString, isTrue, isTruthy, isUndefined, mapClassNames, notEmpty, notNull, notNullable, notUndefined, numberOfDecimals, pad, parseDate, randomString, removeClasses };
