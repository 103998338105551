<div *ngIf="popup_type == 'popupmakemodel'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            The vehicle is on Indemnity Value.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<div *ngIf="popup_type == 'ehailingdriver'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Minimum 1 e-Hailing driver is required.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>


<div *ngIf="popup_type == 'notallowed'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Not allowed to add more than 1 extra coverages if Thailand/Kalimantan has been selected.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<div *ngIf="popup_type == 'popupvehiclesum'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Underinsurance applies.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
     OK
    </button>
</div>

<div *ngIf="popup_type == 'popuptripdate'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Back date / Duplicate date not allowed, select future date to proceed further.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<div *ngIf="popup_type == 'popupnoclaim'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Kindly make changes to proceed for endorsement.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<div *ngIf="popup_type == 'popupmobileno'">
    <div nxModalContent>
        <!-- <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            The mobile number you have keyed in is an agent's mobile number. If you proceed with this number, it is deemed that you have
            obtained the customer's consent to use this number as the contact number for this policy. Please confirm if this
            is correct or otherwise key-in the mobile number provided by the customer.
        </p> -->
        <nx-error appearance="text">
            The mobile number you have keyed in is an agent's mobile number. If you proceed with this number, it is deemed that you have
            obtained the customer's consent to use this number as the contact number for this policy. Please confirm if this
            is correct or otherwise key-in the mobile number provided by the customer.
        </nx-error>
    </div>

    <div nxLayout="grid nopadding">
        <div nxRow>
            <div nxCol="12,12,5">
                <button nxModalClose nxButton="block secondary" type="button" class="nx-margin-top-m">NO</button>
            </div>
            <div nxCol="12,12,5">
                <button nxModalClose nxButton="block primary" type="button" class="nx-margin-top-m">YES</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="popup_type == 'popupemail'">
    <div nxModalContent>
        <!-- <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            The email you have keyed in is an agent's email. If you proceed with this email, it is deemed that you have obtained the customer's consent to use this email as the contact email for this policy. Please confirm if this is correct or otherwise key-in the email provided by the customer.
        </p> -->

        <nx-error appearance="text">
            The email you have keyed in is an agent's email. If you proceed with this email, it is deemed that you have obtained the
            customer's consent to use this email as the contact email for this policy. Please confirm if this is correct
            or otherwise key-in the email provided by the customer.
        </nx-error>
    </div>

    <div nxLayout="grid nopadding">
        <div nxRow>
            <div nxCol="12,12,5">
                <button nxModalClose nxButton="block secondary" type="button" class="nx-margin-top-m">NO</button>
            </div>
            <div nxCol="12,12,5">
                <button nxModalClose nxButton="block primary" type="button" class="nx-margin-top-m">YES</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="popup_type == 'policyTable'">
    <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s">
        Policy Movement
    </h3>
    <div nxModalContent>
        <div class="left-content">
            <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-m popup_blue_title nx-font-weight-semibold">
                Endorsement Details
            </h4>
            <div nxLayout="grid nopadding">
                <div nxRow>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Endorsement No.</nx-data-display-label>
                            <p nxCopytext="large">23VKL0012782-00*001</p>
                        </nx-data-display>
                    </div>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Endorsement Type</nx-data-display-label>
                            <p nxCopytext="large">CHANGE OF ENGINE</p>
                        </nx-data-display>
                    </div>
                </div>
                <div nxRow>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Issue Date</nx-data-display-label>
                            <p nxCopytext="large">22/01/2024</p>
                        </nx-data-display>
                    </div>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Issue By</nx-data-display-label>
                            <p nxCopytext="large">HESTER_WONG</p>
                        </nx-data-display>
                    </div>
                </div>
                <div nxRow>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small" class="nx-margin-bottom-m">
                            <nx-data-display-label class="font-grey">Gross Premium (RM)</nx-data-display-label>
                            <p nxCopytext="large">1,968.92</p>
                        </nx-data-display>
                    </div>
                </div>
                <div nxRow>
                    <hr />
                </div>
            </div>
            <hr class="nx-margin-bottom-m" />
            <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-m popup_blue_title nx-font-weight-semibold">
                Endorsement Narration
            </h4>
            <u nxCopytext="normal" class="nx-font-weight-semibold">CHANGE OF VEHICLE ENGINE NO./CUBIC CAPACITY</u>
            <p nxCopytext="normal" class="nx-margin-y-2m">
                IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
                <span class="nx-font-weight-bold">26/12/2023</span> THE ENGINE NUMBER AND/OR CUBIC CAPACITY IS/ARE AMENDED
                TO READ AS FOLLOWS AND NOT AS OTHERWISE STATED.
            </p>
            <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-bold">
                ENGINE / MOTOR NO. : SHN22891234555655<br />CUBIC CAPACITY : 1,298 CC
            </p>

            <p nxCopytext="normal" class="nx-margin-y-m">
                IN CONSIDERATION OF THE ABOVE, AN ADDITIONAL PREMIUM OF
                <span class="nx-font-weight-bold">RM0.00</span> CHARGED TO THE INSURED.
            </p>

            <p nxCopytext="normal">
                ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
            </p>

            <div nxRow class="nx-margin-top-50">
                <button nxButton="secondary" nxModalClose>
            close
          </button>
            </div>
        </div>
    </div>

    <button nxModalClose nxButton="primary" type="button" class="nx-margin-top-m">
    OK
    </button>
</div>