import { ChangeDetectorRef, Component, TemplateRef } from "@angular/core";

import { NxRadioToggleModule } from "@aposin/ng-aquila/radio-toggle";
import { SharedModule } from "../../shared/shared.module";
import { NxSwitcherModule } from "@aposin/ng-aquila/switcher";
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { CPPopupComponent } from "../../popup/cp-popup/cp-popup.component";
@Component({
  selector: "app-customer-partner",
  standalone: true,
  templateUrl: "./customer-partner.component.html",
  styleUrl: "./customer-partner.component.scss",
  imports: [
    SharedModule,
    NxRadioToggleModule,
    NxSwitcherModule,
    ProgressBarComponent,
  ],
})
export class CustomerPartnerComponent {
  dialogRef?: NxModalRef<any, undefined>;

  cp_search = true;
  cp_notfoundMissing = false;
  cp_found = false;

  individual = true;
  company = false;
  sst = true;
  sme = true;

  eInvoice_consent = true;

  search_mode_address = true;

  constructor(
    private readonly dialogService: NxDialogService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    let temp = localStorage.getItem("eInvoice_consent");
    if (temp == "Yes") {
      this.eInvoice_consent = true;
    } else {
      this.eInvoice_consent = false;
    }
  }

  search() {
    this.cp_search = !this.cp_search;

    this.cp_notfoundMissing = !this.cp_notfoundMissing;
    this.cp_found = !this.cp_found;


    // console.log(this.cp_search);
  }

  openEdit(popup: any) {
    this.componentDialogRef = this.dialogService.open(CPPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      data: { popup_type: popup },
    });
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  changeSearchMode(event: any) {
    // debugger;
    if (event.value == "address") {
      this.search_mode_address = true;
    } else {
      this.search_mode_address = false;
    }
  }


  componentDialogRef?: NxModalRef<CPPopupComponent>;

  openPopup(popup_type: any, button: any) {

    // button (one-button/two-button)

    // DOB
    // 1 = If Age (Current Year - Birth Year) = 0 

    // mobile No.
    // 2 = if Mobile No. same as Agent 

    // Email
    // 2 = if Email same as Agent 

    let data = {
      popup_type: popup_type,
      button: button
    }
    this.componentDialogRef = this.dialogService.open(CPPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: data },
    });
  }


}
