<div class="footer_container">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <nx-footer>
        <nx-footer-copyright>
          <p nxCopytext="medium" class="nx-font-weight-semibold">
            &copy; Allianz 2024. All rights reserved.
          </p>
        </nx-footer-copyright>

        <nx-footer-navigation>
          <nx-footer-link>
            <a routerLink="./" nxCopytext="medium">Legal Notes</a>
          </nx-footer-link>

          <nx-footer-link>
            <a routerLink="./" nxCopytext="medium">Privacy Principal</a>
          </nx-footer-link>

          <nx-footer-link>
            <a routerLink="./" nxCopytext="medium">Terms of Use</a>
          </nx-footer-link>
        </nx-footer-navigation>
      </nx-footer>
    </div>
  </div>
</div>
<div
  *ngIf="showScrollButton"
  class="to-top nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
  (click)="scrollToTop()"
>
  <img
    src="https://alpha-test.allianz.com.my/alpha-products/assets/images/shared/scroll-top.png"
  />
</div>
