@if (isDataLoaded) {
<h1 nxHeadline size="s" class="nx-font-weight-semibold nx-margin-bottom-2m">Summary</h1>

<!-- Agent Information -->
@if (showAgentInfo) {
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit"> Agent Information </nx-expansion-panel-title>
        </nx-expansion-panel-header>
        <div nxLayout="grid nopadding">
            <app-agents-information [agentData]="agentData"> </app-agents-information>
        </div>
    </nx-expansion-panel>
</nx-accordion>
}

<!-- Issuance Information -->
@if (showIssuanceInfo) {
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
                Issuance Information
                <button nxPlainButton type="button" (click)="goToIssueDeclaration()">EDIT</button>
            </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <div nxLayout="grid nopadding">
            <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo"
                [isTypeIssuance]=true></app-issuance-information>
        </div>
    </nx-expansion-panel>
</nx-accordion>
}

<!-- Customer Partner -->
@if (showCustomerPartner) {
<nx-accordion variant="light">
    <nx-expansion-panel [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit"> Customer Partner
            </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <app-customer-partner-details [viewOnly]="true" [cpType]="'FOUND'" [hideEdit]="true" [cpDetails]="cpData"
            [lov]="lov"> </app-customer-partner-details>
    </nx-expansion-panel>
</nx-accordion>
}

<!-- Coverage -->
@if (showCoverage) {
<nx-accordion variant="light">
    <nx-expansion-panel class="accordione_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit"> Coverage
                <button *ngIf="showCoverageEditBtn()" nxPlainButton type="button" (click)="goToCoverageScreen()">EDIT</button>
            </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <app-coverage-summary [coverageInfo]="coverageInfo" [productCode]="endtPolicy.productCode"></app-coverage-summary>
    </nx-expansion-panel>
</nx-accordion>
}

<!-- Historical Claim -->
@if (showHistoricalClaim) {
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit"> Historical Claim </nx-expansion-panel-title>
        </nx-expansion-panel-header>
        <table nxTable cellpadding="0" class="summary_table">
            <tr nxTableRow>
                <td nxTableCell>
                    <span nxCopytext="normal">Client Claim History</span>
                </td>
                <td nxTableCell class="column_cp_content">
                    <span nxCopytext="normal">{{ clientClaimHistory }}</span>
                </td>
            </tr>
            <tr nxTableRow>
                <td nxTableCell>
                    <span nxCopytext="normal">Vehicle Claim History</span>
                </td>
                <td nxTableCell class="column_cp_content">
                    <span nxCopytext="normal">{{ vehicleClaimHistory }}</span>
                </td>
            </tr>
        </table>
    </nx-expansion-panel>
</nx-accordion>
}

<!-- Premium Information -->
@if (showPremiumInformation) {
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit"> Premium Information </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <app-premium-information [premiumInfo]="premiumInfo"
            [isEndorsement]="isEndorsement"> </app-premium-information>
    </nx-expansion-panel>
</nx-accordion>
}

@if(showCheckList && checklistLov && checklistLov.length > 0) {
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit"> Checklist </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <app-checklist [parentForm]="endtSummaryForm" [checklistLov]="checklistLov"></app-checklist>
    </nx-expansion-panel>
</nx-accordion>
}

@if(endtNarration) {
<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit"> Endorsement Narration </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <app-endorsement-narration [endtNarration]="endtNarration"></app-endorsement-narration>
    </nx-expansion-panel>
</nx-accordion>
}

<!-- Payment -->
 @if (showPayment) { 
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Payment </nx-expansion-panel-title>
      </nx-expansion-panel-header>

      <app-payment [payment]="payment"></app-payment>

    </nx-expansion-panel>
  </nx-accordion>
 } 

 <!-- Road Tax Payable -->
   @if (showRoadTaxPayable) {   
    <app-road-tax-payable (onChangePaymentSection)="onChangePaymentSection()" [parentForm]="endtSummaryForm" [data]="roadTaxPayable">
    </app-road-tax-payable>
   }   

<!-- Payment Information -->
  @if (showPaymentInformation) {  
    <app-payment-information [premiumInfo]="paymentInformation" [isEndorsement]="true"></app-payment-information>
  }  

<!-- Payment Details -->
 @if (showPaymentDetails) { 
  <nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
      <nx-expansion-panel-header>
        <nx-expansion-panel-title class="accordion_edit"> Payment Details </nx-expansion-panel-title>
      </nx-expansion-panel-header>
      <app-payment-details [userInfo]="userInfo" [agentInfo]="agentData" (updatePaymentList)="updatePaymentList($event)"
      [paymentList]="paymentList" [isEndorsement]="isEndorsement">
      </app-payment-details>
    </nx-expansion-panel>
  </nx-accordion>
 } 

<!-- Buttons -->
<div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">
    <button nxButton="secondary" type="button" (click)="goBack()">BACK</button>
    <button nxButton="primary" type="button" (click)="generate()" *ngIf="showGenerateBtn()">GENERATE QUOTATION</button>
    @if(showJpjButton()) {
    <button nxButton="primary" type="button" (click)="sendToJPJ()">{{getJPJButtonName()}}</button>
    }
    <button nxButton="primary" type="button" (click)="uploadDocument()">UPLOAD DOCUMENT</button>
</div>
}