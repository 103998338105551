import { ElementRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SyncScrollService {
  scrollList: any = {};

  add(key: any, elementRef: any): number {
    // console.log("key", key)
    // console.log("elementRef", elementRef)
    // console.log(this.scrollList[key])
    if (this.scrollList[key]) {
      return this.scrollList[key].push(elementRef) - 1;
    }
    this.scrollList[key] = [elementRef];

    return 0;
  }

  remove(key: any, index: any) {
    this.scrollList[key].splice(index, 1);
  }

  sync(key: any, index: any) {
    // debugger
    let scrollElems = this.scrollList[key];
    let scrollPosition = scrollElems[index].nativeElement.scrollLeft;

    console.log("key1,", key);
    // ask if key is really undefined
    console.log("yes", index)
    for (let i = 0; i < scrollElems.length; i++) {
      if (i === index) {
        console.log(true)
        continue;
      }
      // debugger
      scrollElems[i].nativeElement.scrollLeft = scrollPosition;

      // console.log("key,", scrollElems[i].nativeElement.scrollLeft);

      // console.log("No", scrollElems[i].nativeElement.scrollLeft)
    }
    // console.log("yes", scrollPosition)
  }
}
