import { Component, Inject, InjectionToken, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { Select, Store } from "@ngxs/store";
import { SharedModule } from "@shared/shared.module";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Observable } from "rxjs";
import { NxDialogService, NxModalRef, NX_MODAL_DATA } from "@aposin/ng-aquila/modal";
import { SatMotorService } from "@store/sat-motor/sat-motor.service";
import { UserResponse } from "@interfaces/user";
import { LoadingService } from "@shared/services/loading/loading.service";
import { SAVE_AS_DRAFT } from "@store/sat-motor/sat-motor.action";
import { HttpResponse } from "@angular/common/http";

@Component({
    selector: "app-upload-document",
    templateUrl: "./upload-document.component.html",
    styleUrl: "./upload-document.component.scss",
    standalone: true,
    imports: [SharedModule, NxGridModule],
})
export class UploadDocumentComponent implements OnInit {
    documentIdCounter = 1000;
    listOfOptionalDocs: any;
    fullListOfOptionalDocs: any;
    groupedDocuments = [];
    listOfSelectedOptionalDocuments: any[] = [];
    dialogRef?: NxModalRef<any>;
    uploadCallCounter = 0;
    showMessageIfUploadSuccess: boolean;
    contractId: string;
    allowedExtensions: any = "";
    documentTypeName: string;
    isFileDeleteFailed: boolean;
    fileName: string;
    failedUploadFiles: string[] = [];
    showifUploadFailed: boolean;

    @ViewChild("deleteConfirmationDialogRef") deleteConfirmationDialogRef!: TemplateRef<any>;
    @ViewChild("notAllowedExtensionDialog") notAllowedExtensionDialog!: TemplateRef<any>;
    @ViewChild("fileTooLargeDialog") fileTooLargeDialog!: TemplateRef<any>;
    @ViewChild("mandatoryDocumetNotAllowedDeleteDialog") mandatoryDocumetNotAllowedDeleteDialog!: TemplateRef<any>;
    @ViewChild("closeWithoutSaveDialog") closeWithoutSaveDialog!: TemplateRef<any>;
    @ViewChild("showNoFileChangesDialog") showNoFileChangesDialog!: TemplateRef<any>;
    @ViewChild("failedDownloadDialog") failedDownloadDialog!: TemplateRef<any>;
    @ViewChild("fileNotReadyDialog") fileNotReadyDialog!: TemplateRef<any>;
    @Select(SatMotorState.getOptionalDocumentList) $optionalDocumentList: Observable<any>;

    constructor(
        @Inject(NX_MODAL_DATA) public data: any,
        private readonly dialogService: NxDialogService,
        private readonly satMotorService: SatMotorService,
        private readonly loadingService: LoadingService,
        private store: Store,
    ) {}

    ngOnInit(): void {

        console.log(this.data);

        this.contractId = this.data?.contractId;
        this.getDocumentTypes();
        this.getOptionaDocslList();

        if (this.contractId)
            this.getAllUploadedDocuments();
    }

    uploadDocuments() {

        const hasFileDetail = this.hasFileDetail()

        if (!hasFileDetail) {
            this.openNoFileChangeModal();
            return;
        }

        this.removeDummyDocIds();

        if (!this.contractId) {
            this.store
            .dispatch(new SAVE_AS_DRAFT({ userId: this.store.selectSnapshot<UserResponse>((state) => state.UserState.user).userId }))
            .subscribe((state) => {
                if (!state?.SatMotorState?.saveAsDraftError) {
                    this.contractId = state.SatMotorState.motor.step3.contractId;
                    this.upload();
                } else {
                    //upload failed
                    console.log('draft failed');
                }
            }) ;
            return;
        } 
        this.upload();
    }

    upload() {

        // step 1: call the initial API to get the transactionId // dummy 299369
        this.satMotorService.initiateUploadDocument({ contractId: this.contractId }).subscribe({
            next: (response: any) => {
                const transactionId = response.data.trxnId;

                // step 2: extract files and prepare requests
                const fileRequests = this.listOfSelectedOptionalDocuments
                    .flatMap((docGroup) =>
                        docGroup.documents.map((document) => {
                            if (document.file) {
                                return { formData: this.prepareFormData(transactionId, { ...document, contractId: this.contractId }) };
                            }
                            return null;
                        }),
                    )
                    .filter(Boolean);

                // step 3: execute uploads sequentially using XMLHttpRequest
                this.executeSequentialUploads(fileRequests, transactionId);
            },
            error: (err) => {
                console.error("Error initiating document upload:", err);
            },
        });

    }

    removeDummyDocIds() {
        this.listOfSelectedOptionalDocuments.forEach((docGroup) => {
            docGroup.documents.forEach((doc: any) => {
                if (doc.docId && doc.docId.startsWith("DUMMY_")) {
                    doc.docId = "";
                }
            });
        });
    }

    // Helper method to prepare FormData
    prepareFormData(transactionId: string, document: any): FormData {
        const appUserId = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user.userId);

        const formData = new FormData();

        if (document.file) {
            formData.append("file", document.file);
        }

        const requestPayload = {
            operator: appUserId,
            transactionId: transactionId,
            documents: [
                {
                    docId: document.docId,
                    contractId: document.contractId,
                    code: document.code,
                    updatePreviousRecordInd: document.updatePreviousRecordInd || false,
                    requoteInd: document.requoteInd || false,
                    productCode: document.productCode,
                },
            ],
        };
        formData.append("request", JSON.stringify(requestPayload));

        return formData;
    }

    executeSequentialUploads(requests: { formData: FormData; document: any }[], transactionId: string) {
        const executeRequest = (index: number) => {
            this.loadingService.startLoading();
            if (index >= requests.length) {
                const commitPayload = {
                    trxnId: transactionId,
                };

                this.satMotorService.commitUploadDocument(commitPayload).subscribe({
                    next: (response) => {
                        this.getAllUploadedDocuments();
                        this.loadingService.stopLoading();

                        setTimeout(() => {
                            this.showMessageIfUploadSuccess = false;
                            this.showifUploadFailed = false;
                        }, 5000);
                    },
                    error: (err) => {
                        console.error("error committing document upload:", err);
                    },
                });

                return;
            }

            const { formData } = requests[index];
            const xhr = new XMLHttpRequest();
            xhr.open("POST", this.satMotorService.uploadDocuments(), true);

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const jsonResponse = JSON.parse(xhr.responseText);

                    if (jsonResponse.success) {
                        this.uploadCallCounter += jsonResponse.data.length;
                        this.showMessageIfUploadSuccess = true;
                    }

                    executeRequest(index + 1);
                } else {
                    const failedFileName = this.getFileName(formData);
                    if (failedFileName) {
                        this.showifUploadFailed = true;
                        this.failedUploadFiles.push(failedFileName);
                    }

                    executeRequest(index + 1);
                }
            };

            xhr.onerror = () => {
                const failedFileName = this.getFileName(formData);
                if (failedFileName) {
                    this.showifUploadFailed = true;
                    this.failedUploadFiles.push(failedFileName);
                }

                console.error(`network error occurred for document ${index + 1}`);
                executeRequest(index + 1);
            };

            xhr.send(formData);
        };

        executeRequest(0);
    }

    getFileName(formData: FormData): string | null {
        let fileName: string | null = null;
        
        formData.forEach((value, key) => {
            if (value instanceof File) {
                fileName = value.name;
            }
        });
    
        return fileName;
    }
    

    getOptionaDocslList() {
        this.$optionalDocumentList.subscribe((res) => {
            this.fullListOfOptionalDocs = res["080100"];
            if (this.fullListOfOptionalDocs?.length) {
                // remove object from dropdown if docs types is already available
                const responseCodes = this.listOfSelectedOptionalDocuments.map((item) => item.code);
                this.listOfOptionalDocs = this.fullListOfOptionalDocs.filter((item) => !responseCodes.includes(item.code));

            }
        });
    }

    getAllUploadedDocuments() {
        this.satMotorService.getAllUpaloadedDocuments(this.contractId).subscribe((response) => {
            if (response?.data?.length) {
                const groupedData = Object.entries(
                    response.data.reduce((acc, value) => {
                        if (!acc[value.code]) {
                            acc[value.code] = [];
                        }
                        acc[value.code].push(value);
                        return acc;
                    }, {})
                ).map(([code, documents]) => {
                    // check mandotatory indicator from dropdown list by campare with code
                    const matchingDoc = this.fullListOfOptionalDocs.find(doc => doc.code === code);
    
                    return {
                        code,
                        descp: documents[0]?.description,
                        documents,
                        mandatoryInd: matchingDoc ? matchingDoc.mandatoryInd : false 
                    };
                });
    
                this.listOfSelectedOptionalDocuments = groupedData;
                this.getOptionaDocslList();
            }
        });
    }
    

    downloadDocument(docId: string, fileName: string) {
        const payload = {docId, fileName};
        this.satMotorService.downloadDocument(docId).subscribe({
            next: (res: HttpResponse<Blob>) => {

                if (res.body.size === 0) {
                    this.openFileNotReadyModal()
                    return;
                }

                let file = new Blob([res.body]);
                let anchor = document.createElement("a");
                let fileUrl = URL.createObjectURL(file);
                anchor.download = payload.fileName;
                anchor.href = fileUrl;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            },
            error: (err) => {
                this.openFailedDownloadModal();
                console.error("error downloading document:", err);
            },
        });
    }

    getDocumentTypes() {
        this.allowedExtensions = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov.documentList.fileTypeList).map(t => "." + t).join(", ");
    }

    handleListSelectedOptionalDocs(selectedOptinalDoc: any) {

        // find productId from dropdown list
        const dropDownOption = this.fullListOfOptionalDocs.find(dropDownOption => selectedOptinalDoc.code === dropDownOption.code);

        const newDocument = {
            // assign temporary id
            docId: `DUMMY_${this.documentIdCounter++}`,
            contractId: this.contractId,
            code: selectedOptinalDoc.code,
            updatePreviousRecordInd: false,
            requoteInd: false,
            productCode: dropDownOption.productCode,
        };

        // check if the 'code' already exists
        const existingDocIndex = this.listOfSelectedOptionalDocuments.findIndex(
            (doc) => doc.code === selectedOptinalDoc.code,
        );

        if (existingDocIndex !== -1) {
            // if the 'code' exists, add to the existing documents array
            this.listOfSelectedOptionalDocuments[existingDocIndex].documents.push(newDocument);
        } else {
            // if the 'code' does not exist, create a new object
            const newEntry = {
                descp: selectedOptinalDoc.descp,
                code: selectedOptinalDoc.code,
                documents: [newDocument],
            };
            this.listOfSelectedOptionalDocuments.push(newEntry);
        }
    }

    onCloseUploadModal() {
        // when user add file and havent upload in file server
        // locally have assign File object before send to server
        // so we simply can check if file object is not available close dialog
        // ohterwise show warning message
        const hasFileDetail = this.hasFileDetail();

        if (hasFileDetail) {
            this.openCloseWithoutSaveFileDialog();
        }

        if (!hasFileDetail) {
            this.dialogService.closeAll()
        }
    }

    handleDropdownSelection(selectedOptinalDoc: any) {
        const existingGroup = this.listOfSelectedOptionalDocuments.find(
            (docGroup) => docGroup.code === selectedOptinalDoc.code,
        );
        
        if (!existingGroup) {
            const newDocument = {
                docId: `DUMMY_${this.documentIdCounter++}`,
                contractId: this.contractId,
                code: selectedOptinalDoc.code,
                updatePreviousRecordInd: false,
                requoteInd: false,
                productCode: selectedOptinalDoc.productCode,
            };

            const newGroup = {
                descp: selectedOptinalDoc.descp,
                code: selectedOptinalDoc.code,
                mandatoryInd: selectedOptinalDoc.mandatoryInd,
                documents: [newDocument],
            };

            // remove dropdown item when file type is selected
            this.removeDropDownItemWhenFileTypeSelected(selectedOptinalDoc);
            this.listOfSelectedOptionalDocuments.push(newGroup);
        }
    }

    private removeDropDownItemWhenFileTypeSelected(selectedFileItem: any) {
        const listClone = [...this.listOfOptionalDocs];
    
        const groupIndex = listClone.findIndex((group) => group.code === selectedFileItem.code);
    
        if (groupIndex !== -1) {
            listClone.splice(groupIndex, 1);
        }
    
        this.listOfOptionalDocs = listClone;
    
    }
    

    onChangeFileInput(event: Event, docId: string) {
        const inputElement = event.target as HTMLInputElement; 
        const file = inputElement.files?.[0];

        const MAX_FILE_SIZE_MB = 6;
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

        if (file) {
            const fileExt = file.name.split('.').pop()?.toLowerCase();
            const fileSize = file.size; 

            // check if extensions supported
            if (!this.allowedExtensions.includes(fileExt || '')) {
                this.openNotAllowedExtensionModal();
                this.documentTypeName = fileExt;
                inputElement.value = '';
                return;
            }
    
            // check file size whether within 6mb
            if (fileSize > MAX_FILE_SIZE_BYTES) {
                this.openFileSizeLargeModal();
                inputElement.value = ''; 
                return;
            }
        }

        for (let item of this.listOfSelectedOptionalDocuments) {
            const document = item.documents.find((doc) => doc.docId === docId);
            if (document) {
                document.file = file;

                // set updatePreviousRecordInd to true if replace the doc
                // docsId that startwith DUMMY is not availabe in server - only in local
                if (!docId.startsWith("DUMMY")) {
                    document.updatePreviousRecordInd = true;
                }
            }
        }
    }

    handleDeleteDocs(code: string, docId: string, filename: any): void {
        const appUserId = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user.userId);
    
        this.removeDocumentFromList(code, docId);
        this.updateListOfOptionalDocs();
    
        const payloadForDeleteApi = {
            operator: appUserId,
            documents: [{ docId, contractId: this.contractId, lob: "MT" }],
            currentEquoteNo: null,
        };
    
        this.satMotorService.deleteDocumentUploaded(payloadForDeleteApi).subscribe({
            next: (response) => {
                if (!response.success) {
                    this.isFileDeleteFailed = true
                    this.fileName = filename;
                }
            },
            error: (err) => console.error("Error deleting document:", err),
        });
    }

    hasDummyDocId(): boolean {
        return this.listOfSelectedOptionalDocuments.some(section =>
            section.documents.some(doc => doc.docId.includes("DUMMY"))
        );
    }

    hasFileDetail(): boolean {
        return this.listOfSelectedOptionalDocuments.some(section =>
            section.documents.some(doc => doc.file && typeof doc.file === 'object' && 'name' in doc.file && 'size' in doc.file)
        );
    }

    // removes document from the list and updates grouped documents
    private removeDocumentFromList(code: string, docId: string): void {
        const group = this.listOfSelectedOptionalDocuments.find((doc) => doc.code === code);
        
        if (group) {
            group.documents = group.documents.filter((doc) => doc.docId !== docId);

            // Remove the group if no documents are left
            if (group.documents.length === 0) {
                this.listOfSelectedOptionalDocuments = this.listOfSelectedOptionalDocuments.filter(
                    (doc) => doc.code !== code
                );
            }
        }
    }

    // updates list of optional documents
    private updateListOfOptionalDocs(): void {
        const selectedCodes = this.listOfSelectedOptionalDocuments.map(item => item.code);
        this.listOfOptionalDocs = this.fullListOfOptionalDocs.filter(item => !selectedCodes.includes(item.code));
    }

    selectedOptionalDoc: any = null;
    // delete doc confirmation modal
    openDeleteConfirmationModal(code: string, docId: string, selectedDocument: any): void {
        this.selectedOptionalDoc = null; 

        if (selectedDocument.mandatoryInd) {
            this.openMandatoryDocsDeleteModal();
            return;
        }
    
        if (selectedDocument.documents?.some(doc => doc.docId.includes("DUMMY"))) {
            this.removeDocumentFromList(code, docId);
            this.updateListOfOptionalDocs();
            return;
        }
    
        this.dialogRef = this.dialogService.open(this.deleteConfirmationDialogRef, {
            ariaLabel: "delete file modal",
            disableClose: true,
            width: '95vw',
            maxWidth: '550px'
        });
        const filename = selectedDocument.documents[0].name + '.' + selectedDocument.documents[0].type;

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result === "proceed") {
                this.handleDeleteDocs(code, docId, filename);
            }
        });
    }

    openNotAllowedExtensionModal(): void {
        this.dialogRef = this.dialogService.open(this.notAllowedExtensionDialog, {
            ariaLabel: "file is not supported modal",
            disableClose: true,
            width: '700px',
            maxWidth: '80vw'
        });
    }

    openFileSizeLargeModal(): void {
        this.dialogRef = this.dialogService.open(this.fileTooLargeDialog, {
            ariaLabel: "file size is too large modal",
            disableClose: true,
            width: '350px',
            maxWidth: '350px'
        });
    }

    openMandatoryDocsDeleteModal(): void {
        this.dialogRef = this.dialogService.open(this.mandatoryDocumetNotAllowedDeleteDialog, {
            ariaLabel: "mandatory file not allowed to delete modal",
            showCloseIcon: true,
        });
    }

    openNoFileChangeModal(): void {
        this.dialogRef = this.dialogService.open(this.showNoFileChangesDialog, {
            ariaLabel: "no file changes modal",
            disableClose: true,
            width: '700px',
            maxWidth: '80vw'
        });
    }

    openFileNotReadyModal(): void {
        this.dialogRef = this.dialogService.open(this.fileNotReadyDialog, {
            ariaLabel: 'A simple modal',
            disableClose: true,
            width: '350px',
            maxWidth: '350px'
        });
    }

    openFailedDownloadModal(): void {
        this.dialogRef = this.dialogService.open(this.failedDownloadDialog, {
            ariaLabel: "failed download modal",
            showCloseIcon: true,
        });
    }

    openCloseWithoutSaveFileDialog(): void {
        this.dialogRef = this.dialogService.open(this.closeWithoutSaveDialog, {
            ariaLabel: "close without saving files modal",
            disableClose: true,
            width: '95vw',
            maxWidth: '550px',
            maxHeight: '95vh'
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result === "proceed") {
                this.dialogService.closeAll();
            }
        });
    }


    closeDeleteConfirmationModal(result: any): void {
        this.dialogRef?.close(result);
    }
}
