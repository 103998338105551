import { Component } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";

@Component({
  selector: "app-report",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./report.component.html",
  styleUrl: "./report.component.scss",
})
export class ReportComponent {
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  agent_code = [
    "AS00151 - P & O AGENCY",
    "AS00375 - SYARIKAT WEI CHIN",
    "AS00584 - KALA ENTERPRISE",
    "AS00592 - PHAN HOY MUN",
    "AS00626 - ONG SENG SWEE @ ONG AH BAH",
  ];

  branch = [
    "ALOR STAR",
    "BATu PAHAT",
    "BINTuLU",
    "BuKIT MERTAJAM",
    "BUTTERWORTH",
    "CORPORATE BUSINESS",
    "DESA JAYA",
    "IPOH ",
    "JOHOR BHARU",
    "KAJANG",
    "KEPONG",
    "KLANG",
    "KLUANG",
    "KOTA BAHRU",
    "KOTA KINABALU",
    "KUALA LUMPUR",
  ];

  product_list = [
    "all",
    "all risks",
    "ALLIANZ BUSINESS SHIELD",
    "ALLIANZ HOSPITAL INCOME",
    "ALLIANZ LIFESTYLE PROTECT",
    "ALLIANZ MEDICURE",
    "motor",
  ];

  report_list = [
    "Banca Daily Activity Report",
    "COVER NOTE Refer Risk Report ",
    "Agent Production Report",
    "Renewal Assistance Dealer Report",
    "Renewal Assistance Branch Summary",
    "Admin Production Report",
    "Call Center Daily Activity Report",
    "Issue Quotation Report",
    "Endorsement Report",
    "Email Status Report",
  ];

  search_by_list = [
    "Vehicle / TRAILER No.",
    "Nric",
    "old ic / Police ID / Army ID / Passport No.",
    "New Business Registration No.",
    "Business Registration No.",
  ];
}

