import { Component, Input } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-view-remarks',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './view-remarks.component.html',
  styleUrl: './view-remarks.component.scss'
})
export class ViewRemarksComponent {
  @Input() remarksInfo: any;
  @Input() isApprovalView: boolean = false;
  @Input() isQuotationView: boolean = false;
  @Input() isPolicyView : boolean = false;
}
