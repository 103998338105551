import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, InjectionToken, Directive, Optional, Inject, Input, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE } from '@angular/cdk/keycodes';
import * as i1$1 from '@angular/cdk/overlay';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil, take } from 'rxjs/operators';
import * as i2 from '@angular/cdk/scrolling';
import * as i3 from '@angular/cdk/platform';
import * as i4 from '@angular/cdk/a11y';
import { A11yModule } from '@angular/cdk/a11y';
import * as i5 from '@angular/cdk/bidi';

/**
 * Animations used by NxTooltip.
 * @docs-private
 */
const nxTooltipAnimations = {
  /** Animation that transitions a tooltip in and out. */
  tooltipState: trigger('state', [state('initial, void, hidden', style({
    opacity: 0
  })), state('visible', style({
    opacity: 1
  })), transition('* => visible', animate('70ms cubic-bezier(0, 0, 0.2, 1)', style({
    opacity: 1
  }))), transition('* => hidden', animate('70ms cubic-bezier(0, 0, 0.2, 1)', style({
    opacity: 0
  })))])
};

/**
 * Internal component that wraps the tooltip's content.
 * @docs-private
 */
class NxTooltipComponent {
  set message(value) {
    this._message = value;
    this._cdr.markForCheck();
  }
  get message() {
    return this._message;
  }
  /** Property watched by the animation framework to show or hide the tooltip */
  get visibility() {
    return this._visibility;
  }
  /** @docs-private */
  set position(value) {
    this._position = value;
    this._cdr.markForCheck();
  }
  /** @docs-private */
  get position() {
    return this._position;
  }
  /** @docs-private */
  set arrowStyle(value) {
    this._arrowStyle = value;
    this._cdr.markForCheck();
  }
  /** @docs-private */
  get arrowStyle() {
    return this._arrowStyle;
  }
  /** @docs-private */
  get arrowClass() {
    return `nx-tooltip__arrow--${this.position}`;
  }
  constructor(_cdr, elementRef) {
    this._cdr = _cdr;
    this.elementRef = elementRef;
    /** The timeout ID of any current timer set to show the tooltip */
    this._showTimeoutId = null;
    /** The timeout ID of any current timer set to hide the tooltip */
    this._hideTimeoutId = null;
    this._visibility = 'initial';
    this._position = 'bottom';
    this._arrowStyle = {};
    /** Whether interactions on the page should close the tooltip */
    this._closeOnInteraction = false;
    /** Subject for notifying that the tooltip has been hidden from the view */
    this._onHide = new Subject();
  }
  /**
   * Shows the tooltip with an aninxion originating from the provided origin
   * @param delay Amount of milliseconds to the delay showing the tooltip.
   */
  show(delay) {
    // Cancel the delayed hide if it is scheduled
    if (this._hideTimeoutId) {
      clearTimeout(this._hideTimeoutId);
      this._hideTimeoutId = null;
    }
    // Body interactions should cancel the tooltip if there is a delay in showing.
    this._closeOnInteraction = true;
    this._showTimeoutId = window.setTimeout(() => {
      this._visibility = 'visible';
      this._showTimeoutId = null;
      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._cdr.markForCheck();
    }, delay);
  }
  /**
   * Begins the animation to hide the tooltip after the provided delay in ms.
   * @param delay Amount of milliseconds to delay showing the tooltip.
   */
  hide(delay) {
    if (this._hideTimeoutId) {
      return;
    }
    // Cancel the delayed show if it is scheduled
    if (this._showTimeoutId) {
      clearTimeout(this._showTimeoutId);
      this._showTimeoutId = null;
    }
    this._hideTimeoutId = window.setTimeout(() => {
      this._visibility = 'hidden';
      this._hideTimeoutId = null;
      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._cdr.markForCheck();
    }, delay);
  }
  /** Returns an observable that notifies when the tooltip has been hidden from view. */
  afterHidden() {
    return this._onHide.asObservable();
  }
  /** Whether the tooltip is being displayed. */
  isVisible() {
    return this.visibility === 'visible';
  }
  /** Whether the tooltip started a delay to be shown/hidden */
  isDelayed() {
    return this._showTimeoutId !== null || this._hideTimeoutId !== null;
  }
  ngOnDestroy() {
    this._onHide.complete();
  }
  _animationStart() {
    this._closeOnInteraction = false;
  }
  _animationDone(event) {
    const toState = event.toState;
    if (toState === 'hidden' && !this.isVisible()) {
      this._onHide.next();
    }
    if (toState === 'visible' || toState === 'hidden') {
      this._closeOnInteraction = true;
    }
  }
  /**
   * Interactions on the HTML body should close the tooltip immediately.
   */
  _handleBodyInteraction() {
    if (this._closeOnInteraction) {
      this.hide(0);
    }
  }
  static {
    this.ɵfac = function NxTooltipComponent_Factory(t) {
      return new (t || NxTooltipComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxTooltipComponent,
      selectors: [["nx-tooltip-component"]],
      hostAttrs: ["aria-hidden", "true"],
      hostVars: 2,
      hostBindings: function NxTooltipComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function NxTooltipComponent_click_HostBindingHandler() {
            return ctx._handleBodyInteraction();
          }, false, i0.ɵɵresolveBody);
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("zoom", ctx.visibility === "visible" ? 1 : null);
        }
      },
      decls: 3,
      vars: 4,
      consts: [[1, "nx-tooltip"], [1, "nx-tooltip__arrow", 3, "ngClass", "ngStyle"]],
      template: function NxTooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵlistener("@state.start", function NxTooltipComponent_Template_div_animation_state_start_0_listener() {
            return ctx._animationStart();
          })("@state.done", function NxTooltipComponent_Template_div_animation_state_done_0_listener($event) {
            return ctx._animationDone($event);
          });
          i0.ɵɵelement(1, "div", 1);
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("@state", ctx.visibility);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", ctx.arrowClass)("ngStyle", ctx.arrowStyle);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.message, "\n");
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      styles: [".nx-tooltip-panel{pointer-events:none!important}.nx-tooltip[_ngcontent-%COMP%]{font-size:var(--tooltip-font-size);line-height:var(--tooltip-line-height);font-weight:var(--tooltip-font-weight);letter-spacing:var(--tooltip-letter-spacing);color:var(--tooltip-text-color);background-color:var(--tooltip-background-color);border-radius:var(--tooltip-border-radius);max-width:408px;padding:4px 8px;word-break:break-all}.nx-tooltip__arrow[_ngcontent-%COMP%]{position:absolute;width:8px;height:8px;background-color:inherit;z-index:-1;opacity:inherit}.nx-tooltip__arrow--top[_ngcontent-%COMP%]{transform:translate(-50%) rotate(45deg);bottom:-4px}.nx-tooltip__arrow--right[_ngcontent-%COMP%]{transform:translateY(-50%) rotate(45deg);left:-4px}[dir=rtl][_nghost-%COMP%]   .nx-tooltip__arrow--right[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .nx-tooltip__arrow--right[_ngcontent-%COMP%]{right:-4px;left:auto}.nx-tooltip__arrow--bottom[_ngcontent-%COMP%]{transform:translate(-50%) rotate(45deg);top:-4px}.nx-tooltip__arrow--left[_ngcontent-%COMP%]{transform:translateY(-50%) rotate(45deg);right:-4px}[dir=rtl][_nghost-%COMP%]   .nx-tooltip__arrow--left[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .nx-tooltip__arrow--left[_ngcontent-%COMP%]{right:auto;left:-4px}@media screen and (forced-colors: active){.nx-tooltip__arrow[_ngcontent-%COMP%]{border:1px solid CanvasText}.nx-tooltip[_ngcontent-%COMP%]{border:1px solid CanvasText;border-radius:4px}}"],
      data: {
        animation: [nxTooltipAnimations.tooltipState]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'nx-tooltip-component',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [nxTooltipAnimations.tooltipState],
      host: {
        // Forces the element to have a layout in IE and Edge. This fixes issues where the element
        // won't be rendered if the aninxions are disabled or there is no web aninxions polyfill.
        '[style.zoom]': 'visibility === "visible" ? 1 : null',
        '(body:click)': 'this._handleBodyInteraction()',
        'aria-hidden': 'true'
      },
      template: "<div class=\"nx-tooltip\" [@state]=\"visibility\" (@state.start)=\"_animationStart()\" (@state.done)=\"_animationDone($event)\">\n    <div [ngClass]=\"arrowClass\" class=\"nx-tooltip__arrow\" [ngStyle]=\"arrowStyle\"></div>\n    {{ message }}\n</div>\n",
      styles: ["::ng-deep .nx-tooltip-panel{pointer-events:none!important}.nx-tooltip{font-size:var(--tooltip-font-size);line-height:var(--tooltip-line-height);font-weight:var(--tooltip-font-weight);letter-spacing:var(--tooltip-letter-spacing);color:var(--tooltip-text-color);background-color:var(--tooltip-background-color);border-radius:var(--tooltip-border-radius);max-width:408px;padding:4px 8px;word-break:break-all}.nx-tooltip__arrow{position:absolute;width:8px;height:8px;background-color:inherit;z-index:-1;opacity:inherit}.nx-tooltip__arrow--top{transform:translate(-50%) rotate(45deg);bottom:-4px}.nx-tooltip__arrow--right{transform:translateY(-50%) rotate(45deg);left:-4px}:host-context([dir=rtl]) .nx-tooltip__arrow--right{right:-4px;left:auto}.nx-tooltip__arrow--bottom{transform:translate(-50%) rotate(45deg);top:-4px}.nx-tooltip__arrow--left{transform:translateY(-50%) rotate(45deg);right:-4px}:host-context([dir=rtl]) .nx-tooltip__arrow--left{right:auto;left:-4px}@media screen and (forced-colors: active){.nx-tooltip__arrow{border:1px solid CanvasText}.nx-tooltip{border:1px solid CanvasText;border-radius:4px}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }], null);
})();
const BASE_OFFSET = 12;
/** Injection token that determines the scroll handling while a tooltip is open. */
const NX_TOOLTIP_SCROLL_STRATEGY = new InjectionToken('nx-tooltip-scroll-strategy');
/** @docs-private */
function NX_TOOLTIP_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay) {
  return () => overlay.scrollStrategies.reposition();
}
/** @docs-private */
const NX_TOOLTIP_SCROLL_STRATEGY_PROVIDER = {
  provide: NX_TOOLTIP_SCROLL_STRATEGY,
  useFactory: NX_TOOLTIP_SCROLL_STRATEGY_PROVIDER_FACTORY,
  deps: [Overlay]
};
/**
 * CSS class that will be attached to the overlay panel.
 * @docs-private
 */
const NX_TOOLTIP_PANEL_CLASS = 'nx-tooltip-panel';
/**
 * Creates an error to be thrown if the user provided an invalid tooltip position.
 * @docs-private
 */
function getNxTooltipInvalidPositionError(position) {
  return Error(`Tooltip position "${position}" is invalid.`);
}
/** Injection token to be used to override the default options for `nxTooltip`. */
const NX_TOOLTIP_DEFAULT_OPTIONS = new InjectionToken('nx-tooltip-default-options', {
  providedIn: 'root',
  factory: NX_TOOLTIP_DEFAULT_OPTIONS_FACTORY
});
function NX_TOOLTIP_DEFAULT_OPTIONS_FACTORY() {
  return {
    showDelay: 200,
    hideDelay: 200,
    touchendHideDelay: 1500
  };
}
/**
 * Directive that attaches a tooltip to the host element.
 *
 */
class NxTooltipDirective {
  /** Allows the user to define the position of the tooltip relative to the parent element */
  set position(value) {
    if (value !== this._position) {
      this._position = value;
      if (this._overlayRef) {
        this._updatePosition();
        if (this._tooltipInstance) {
          this._tooltipInstance.position = this._position;
          this._tooltipInstance.show(0);
        }
        this._overlayRef.updatePosition();
      }
    }
  }
  get position() {
    return this._position;
  }
  /** Disables the display of the tooltip. */
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    // If tooltip is disabled, hide immediately.
    if (this._disabled) {
      this.hide(0);
    }
  }
  get disabled() {
    return this._disabled;
  }
  /**
   * Allow selection of text within tooltip trigger.
   *
   * NOTE: inputs and textareas always remain selectable, ignoring this input.
   */
  set selectable(value) {
    const oldValue = this._selectable;
    this._selectable = coerceBooleanProperty(value);
    if (this._selectable !== oldValue) {
      this._updateSelectabilityStyles();
    }
  }
  get selectable() {
    return this._selectable;
  }
  /** The message to be displayed in the tooltip */
  set message(value) {
    this._ariaDescriber.removeDescription(this._elementRef.nativeElement, this._message);
    // If the message is not a string (e.g. number), convert it to a string and trim it.
    this._message = value != null ? `${value}`.trim() : '';
    if (!this._message && this._isTooltipVisible()) {
      this.hide(0);
    } else {
      this._updateTooltipMessage();
      this._ariaDescriber.describe(this._elementRef.nativeElement, this.message);
    }
  }
  get message() {
    return this._message;
  }
  constructor(_overlay, _elementRef, _scrollDispatcher, _viewContainerRef, _ngZone, platform, _ariaDescriber, _focusMonitor, _dir, _defaultOptions, _defaultScrollStrategyFactory) {
    this._overlay = _overlay;
    this._elementRef = _elementRef;
    this._scrollDispatcher = _scrollDispatcher;
    this._viewContainerRef = _viewContainerRef;
    this._ngZone = _ngZone;
    this._ariaDescriber = _ariaDescriber;
    this._focusMonitor = _focusMonitor;
    this._dir = _dir;
    this._defaultOptions = _defaultOptions;
    this._defaultScrollStrategyFactory = _defaultScrollStrategyFactory;
    this._possibleTooltipPositions = ['bottom', 'top', 'left', 'right'];
    this._position = 'bottom';
    this._disabled = false;
    this._selectable = false;
    /** The default delay in ms before showing the tooltip after show is called */
    this.showDelay = this._defaultOptions.showDelay;
    /** The default delay in ms before hiding the tooltip after hide is called */
    this.hideDelay = this._defaultOptions.hideDelay;
    this._message = '';
    /** Strategy factory that will be used to handle scrolling while the tooltip panel is open. */
    this._scrollStrategyFactory = this._defaultScrollStrategyFactory;
    this._manualListeners = new Map();
    this._destroyed = new Subject();
    this._dir?.change.pipe(takeUntil(this._destroyed)).subscribe(this._dirChangeHandler.bind(this));
    const element = _elementRef.nativeElement;
    // The mouse events shouldn't be bound on mobile devices, because they can prevent the
    // first tap from firing its click event or can cause the tooltip to open for clicks.
    if (!platform.IOS && !platform.ANDROID) {
      this._manualListeners.set('mouseenter', () => this.show()).set('mouseleave', () => this.hide());
    } else {
      // Fall back to showing on `touchstart`, otherwise
      // there's no way for the user to trigger the tooltip on a touch device.
      this._manualListeners.set('touchstart', () => this.show());
    }
    this._manualListeners.forEach((listener, event) => element.addEventListener(event, listener));
    if (_defaultOptions?.position) {
      this.position = _defaultOptions.position;
    }
  }
  ngOnInit() {
    this._updateSelectabilityStyles();
  }
  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef).pipe(takeUntil(this._destroyed)).subscribe(origin => {
      // Note that the focus monitor runs outside the Angular zone.
      if (!origin) {
        this._ngZone.run(() => this.hide(0));
      } else if (origin === 'keyboard') {
        this._ngZone.run(() => this.show());
      }
    });
  }
  /**
   * Dispose the tooltip when destroyed.
   */
  ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._tooltipInstance = null;
    }
    // Clean up the event listeners set in the constructor
    this._manualListeners.forEach((listener, event) => {
      this._elementRef.nativeElement.removeEventListener(event, listener);
    });
    this._manualListeners.clear();
    this._destroyed.next();
    this._destroyed.complete();
    this._ariaDescriber.removeDescription(this._elementRef.nativeElement, this.message);
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  /** Shows the tooltip after the delay in ms, defaults to tooltip-delay-show or 0ms if no input */
  show(delay = this.showDelay) {
    if (this.disabled || !this.message || this._isTooltipVisible() && !this._tooltipInstance?.isDelayed()) {
      return;
    }
    const overlayRef = this._createOverlay();
    this._detach();
    this._portal = this._portal || new ComponentPortal(NxTooltipComponent, this._viewContainerRef);
    this._embeddedViewRef = overlayRef.attach(this._portal);
    this._tooltipInstance = this._embeddedViewRef.instance;
    this._tooltipInstance.afterHidden().pipe(takeUntil(this._destroyed)).subscribe(() => this._detach());
    this._updateTooltipMessage();
    this._tooltipInstance.show(delay);
  }
  /** Hides the tooltip after the delay in ms, defaults to tooltip-delay-hide or 0ms if no input */
  hide(delay = this.hideDelay) {
    if (this._tooltipInstance) {
      this._tooltipInstance.hide(delay);
    }
  }
  /** Shows/hides the tooltip */
  toggle() {
    this._isTooltipVisible() ? this.hide() : this.show();
  }
  /** Returns true if the tooltip is currently visible to the user */
  _isTooltipVisible() {
    return !!this._tooltipInstance && this._tooltipInstance.isVisible();
  }
  /** Handles the keydown events on the host element. */
  _handleKeydown(e) {
    if (this._isTooltipVisible() && e.keyCode === ESCAPE) {
      e.stopPropagation();
      this.hide(0);
    }
  }
  /** Handles the touchend events on the host element. */
  _handleTouchend() {
    this.hide(this._defaultOptions?.touchendHideDelay);
  }
  /**
   * Setup styling-specific things.
   */
  _updateSelectabilityStyles() {
    const element = this._elementRef.nativeElement;
    const elementStyle = element.style;
    const isSelectable = element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA' || this._selectable;
    const userSelect = isSelectable ? 'auto' : 'none';
    elementStyle.webkitUserSelect = elementStyle.userSelect = elementStyle.msUserSelect = userSelect;
  }
  /** Create the overlay config and position strategy */
  _createOverlay() {
    if (this._overlayRef && !!this._overlayRef.hostElement) {
      return this._overlayRef;
    }
    const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._elementRef);
    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this._overlay.position().flexibleConnectedTo(this._elementRef).withLockedPosition(true).withFlexibleDimensions(false).withPush(true);
    strategy.withScrollableContainers(scrollableAncestors);
    strategy.positionChanges.pipe(takeUntil(this._destroyed)).subscribe(change => {
      if (this._tooltipInstance) {
        const pair = change.connectionPair;
        this._ngZone.run(() => this._positionArrow(pair));
        if (change.scrollableViewProperties.isOverlayClipped && this._tooltipInstance.isVisible()) {
          // After position changes occur and the overlay is clipped by
          // a parent scrollable then close the tooltip.
          this._ngZone.run(() => this.hide(0));
        }
      }
    });
    this._overlayRef = this._overlay.create({
      direction: this._dir?.value || 'ltr',
      positionStrategy: strategy,
      panelClass: NX_TOOLTIP_PANEL_CLASS,
      scrollStrategy: this._scrollStrategyFactory(),
      disposeOnNavigation: true
    });
    this._updatePosition();
    this._overlayRef.detachments().pipe(takeUntil(this._destroyed)).subscribe(() => this._detach());
    return this._overlayRef;
  }
  /** Detaches the currently-attached tooltip. */
  _detach() {
    if (this._overlayRef?.hasAttached()) {
      this._overlayRef.detach();
    }
    this._tooltipInstance = null;
  }
  /** Updates the position of the current tooltip. */
  _updatePosition() {
    if (!this._overlayRef) {
      return;
    }
    const position = this._overlayRef.getConfig().positionStrategy;
    const origin = this._getOrigin(this.position);
    const overlay = this._getOverlayPosition(this.position);
    const offset = this._getOffset(this.position);
    const fallbacks = this._getFallbackPositions(this.position);
    position.withPositions([{
      ...origin,
      ...overlay,
      ...offset
    }, ...fallbacks]);
  }
  /**
   * Returns the origin position based on the user's position preference.
   */
  _getOrigin(position) {
    switch (position) {
      case 'top':
      case 'bottom':
        {
          return {
            originX: 'center',
            originY: position
          };
        }
      case 'left':
        {
          return {
            originX: this._isLtr ? 'start' : 'end',
            originY: 'center'
          };
        }
      case 'right':
        {
          return {
            originX: this._isLtr ? 'end' : 'start',
            originY: 'center'
          };
        }
      default:
        {
          throw getNxTooltipInvalidPositionError(position);
        }
    }
  }
  /** Returns the overlay position based on the user's preference */
  _getOverlayPosition(position) {
    switch (position) {
      case 'top':
      case 'bottom':
        {
          return {
            overlayX: 'center',
            overlayY: this._getInversePosition(position)
          };
        }
      case 'left':
        {
          return {
            overlayX: this._isLtr ? 'end' : 'start',
            overlayY: 'center'
          };
        }
      case 'right':
        {
          return {
            overlayX: this._isLtr ? 'start' : 'end',
            overlayY: 'center'
          };
        }
      default:
        {
          throw getNxTooltipInvalidPositionError(position);
        }
    }
  }
  /** Returns the overlay offset required by the user's position preference */
  _getOffset(position) {
    switch (position) {
      case 'top':
        {
          return {
            offsetY: BASE_OFFSET * -1
          };
        }
      case 'bottom':
        {
          return {
            offsetY: BASE_OFFSET
          };
        }
      case 'left':
        {
          return {
            offsetX: BASE_OFFSET * -1
          };
        }
      case 'right':
        {
          return {
            offsetX: BASE_OFFSET
          };
        }
      default:
        {
          throw getNxTooltipInvalidPositionError(position);
        }
    }
  }
  /** Returns the opposite position, using aquila tooltip position naming: top, right, bottom, left */
  _getInverseTooltipPosition(position) {
    const tooltopPositionPairs = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right'
    };
    return tooltopPositionPairs[position];
  }
  /** Returns the opposite position, using angular position naming: top, bottom, start, end, center */
  _getInversePosition(position) {
    const positionPairs = {
      top: 'bottom',
      bottom: 'top',
      start: 'end',
      end: 'start',
      center: 'center'
    };
    return positionPairs[position];
  }
  /**
   * Returns an array of fallback positions for tooltip, following the algoritm:
   * 1) Slightly alternate preferred position if applicable. I.e. for 'top' try 'top-start' and 'top-end' positioning.
   * 2) Try the opposite position, i.e. for 'top' try 'bottom'.
   * 3) Slightly alternate opposite position, i.e. 'bottom-start', 'bottom-end'.
   * 4) All remaining positions from positions list.
   */
  _getFallbackPositions(position, possiblePositions = this._possibleTooltipPositions) {
    if (!position) {
      return [];
    }
    const remainigPositions = possiblePositions.filter(possiblePosition => possiblePosition !== position);
    let fallbackPositions = [];
    switch (position) {
      case 'top':
      case 'bottom':
        {
          fallbackPositions = this._getVerticalFallbackPositionPairs(position);
          break;
        }
      case 'left':
      case 'right':
        {
          fallbackPositions = this._getHorizontalFallbackPositionPairs(position);
          break;
        }
    }
    const inversePosition = this._getInverseTooltipPosition(position);
    const nextFallbackPosition = remainigPositions.includes(inversePosition) ? inversePosition : possiblePositions[0];
    return [...fallbackPositions, ...this._getFallbackPositions(nextFallbackPosition, remainigPositions)];
  }
  /** Calculates fallbacks for vertical tooltip positioning */
  _getVerticalFallbackPositionPairs(position) {
    const isSelectedPosition = position === this.position;
    const verticalFallbackPositionPairs = [];
    const basePositionPair = {
      ...this._getOrigin(position),
      ...this._getOverlayPosition(position),
      ...this._getOffset(position)
    };
    if (!isSelectedPosition) {
      // HINT: selected position matches basePosition, so we don't need to repeat it in fallback
      verticalFallbackPositionPairs.push(basePositionPair);
    }
    verticalFallbackPositionPairs.push({
      ...basePositionPair,
      originX: 'start',
      overlayX: 'start'
    }, {
      ...basePositionPair,
      originX: 'end',
      overlayX: 'end'
    });
    return verticalFallbackPositionPairs;
  }
  /** Calculates fallbacks for horizontal tooltip positioning */
  _getHorizontalFallbackPositionPairs(position) {
    const offset = this._getOffset(position);
    return [{
      ...this._getOrigin(position),
      ...this._getOverlayPosition(position),
      ...offset
    }];
  }
  /** Updates the tooltip message and repositions the overlay according to the new message length */
  _updateTooltipMessage() {
    // Must wait for the message to be painted to the tooltip so that the overlay can properly
    // calculate the correct positioning based on the size of the text.
    if (this._tooltipInstance) {
      this._tooltipInstance.message = this.message;
      this._ngZone.onMicrotaskEmpty.asObservable().pipe(take(1), takeUntil(this._destroyed)).subscribe(() => {
        if (this._tooltipInstance && this._overlayRef) {
          this._overlayRef.updatePosition();
        }
      });
    }
  }
  _positionArrow(pair) {
    if (!this._overlayRef || !this._tooltipInstance) {
      return;
    }
    const parentElementPositionX = this._elementRef.nativeElement.getBoundingClientRect().left;
    const parentElementWidth = this._elementRef.nativeElement.getBoundingClientRect().width / 2;
    const overlayElementLeftOffset = this._overlayRef.overlayElement.getBoundingClientRect().left;
    // calculation for x position of the parent element. In this case, overlay left offset is the one thing to consider.
    const targetPosition = parentElementPositionX + parentElementWidth - overlayElementLeftOffset;
    if (pair.originX === pair.overlayX) {
      const arrowStyle = {
        left: targetPosition + 'px'
      };
      this._tooltipInstance.arrowStyle = arrowStyle;
    }
    if (pair.originX === 'end' && pair.overlayX === 'start') {
      this._tooltipInstance.arrowStyle = {
        top: '50%'
      };
      this._tooltipInstance.position = 'right';
    } else if (pair.originY === 'bottom' && pair.overlayY === 'top') {
      this._tooltipInstance.arrowStyle = {
        left: targetPosition + 'px'
      };
      this._tooltipInstance.position = 'bottom';
    } else if (pair.originX === 'start' && pair.overlayX === 'end') {
      this._tooltipInstance.arrowStyle = {
        top: '50%'
      };
      this._tooltipInstance.position = 'left';
    } else if (pair.originY === 'top' && pair.overlayY === 'bottom') {
      this._tooltipInstance.arrowStyle = {
        left: targetPosition + 'px'
      };
      this._tooltipInstance.position = 'top';
    }
  }
  _dirChangeHandler(value) {
    if (this._overlayRef) {
      this.hide(0);
      this._overlayRef.setDirection(value);
      this._updatePosition();
    }
  }
  get _isLtr() {
    return !this._dir || this._dir.value === 'ltr';
  }
  static {
    this.ɵfac = function NxTooltipDirective_Factory(t) {
      return new (t || NxTooltipDirective)(i0.ɵɵdirectiveInject(i1$1.Overlay), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.ScrollDispatcher), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i3.Platform), i0.ɵɵdirectiveInject(i4.AriaDescriber), i0.ɵɵdirectiveInject(i4.FocusMonitor), i0.ɵɵdirectiveInject(i5.Directionality, 8), i0.ɵɵdirectiveInject(NX_TOOLTIP_DEFAULT_OPTIONS, 8), i0.ɵɵdirectiveInject(NX_TOOLTIP_SCROLL_STRATEGY));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NxTooltipDirective,
      selectors: [["", "nxTooltip", ""]],
      hostBindings: function NxTooltipDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown", function NxTooltipDirective_keydown_HostBindingHandler($event) {
            return ctx._handleKeydown($event);
          })("touchend", function NxTooltipDirective_touchend_HostBindingHandler() {
            return ctx._handleTouchend();
          });
        }
      },
      inputs: {
        position: [i0.ɵɵInputFlags.None, "nxTooltipPosition", "position"],
        disabled: [i0.ɵɵInputFlags.None, "nxTooltipDisabled", "disabled"],
        selectable: [i0.ɵɵInputFlags.None, "nxTooltipSelectable", "selectable"],
        showDelay: [i0.ɵɵInputFlags.None, "nxTooltipShowDelay", "showDelay"],
        hideDelay: [i0.ɵɵInputFlags.None, "nxTooltipHideDelay", "hideDelay"],
        message: [i0.ɵɵInputFlags.None, "nxTooltip", "message"]
      },
      exportAs: ["nxTooltip"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxTooltipDirective, [{
    type: Directive,
    args: [{
      selector: '[nxTooltip]',
      exportAs: 'nxTooltip',
      host: {
        '(keydown)': '_handleKeydown($event)',
        '(touchend)': '_handleTouchend()'
      }
    }]
  }], () => [{
    type: i1$1.Overlay
  }, {
    type: i0.ElementRef
  }, {
    type: i2.ScrollDispatcher
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i0.NgZone
  }, {
    type: i3.Platform
  }, {
    type: i4.AriaDescriber
  }, {
    type: i4.FocusMonitor
  }, {
    type: i5.Directionality,
    decorators: [{
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [NX_TOOLTIP_DEFAULT_OPTIONS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NX_TOOLTIP_SCROLL_STRATEGY]
    }]
  }], {
    position: [{
      type: Input,
      args: ['nxTooltipPosition']
    }],
    disabled: [{
      type: Input,
      args: ['nxTooltipDisabled']
    }],
    selectable: [{
      type: Input,
      args: ['nxTooltipSelectable']
    }],
    showDelay: [{
      type: Input,
      args: ['nxTooltipShowDelay']
    }],
    hideDelay: [{
      type: Input,
      args: ['nxTooltipHideDelay']
    }],
    message: [{
      type: Input,
      args: ['nxTooltip']
    }]
  });
})();
class NxTooltipModule {
  static {
    this.ɵfac = function NxTooltipModule_Factory(t) {
      return new (t || NxTooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxTooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NX_TOOLTIP_SCROLL_STRATEGY_PROVIDER],
      imports: [A11yModule, CommonModule, OverlayModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxTooltipModule, [{
    type: NgModule,
    args: [{
      imports: [A11yModule, CommonModule, OverlayModule],
      exports: [NxTooltipDirective, NxTooltipComponent],
      declarations: [NxTooltipDirective, NxTooltipComponent],
      providers: [NX_TOOLTIP_SCROLL_STRATEGY_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NX_TOOLTIP_DEFAULT_OPTIONS, NX_TOOLTIP_DEFAULT_OPTIONS_FACTORY, NX_TOOLTIP_PANEL_CLASS, NX_TOOLTIP_SCROLL_STRATEGY, NX_TOOLTIP_SCROLL_STRATEGY_PROVIDER, NX_TOOLTIP_SCROLL_STRATEGY_PROVIDER_FACTORY, NxTooltipComponent, NxTooltipDirective, NxTooltipModule, getNxTooltipInvalidPositionError, nxTooltipAnimations };
