import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { Store } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'customer-partner-endorsement',
  templateUrl: './customer-partner-endorsement.component.html',
  styleUrl: './customer-partner-endorsement.component.scss'
})
export class CustomerPartnerEndorsementComponent {
  @Input() cpData: any;

  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store,
    private fb: FormBuilder,
    private router: Router
  ) {}

}
