<!-- <div class="nx-hidden-xs nx-hidden-s nx-hidden-m">
  <div class="progress_bar">
    <aside class="nx-margin-left-m"> -->

<div class="nx-hidden-xs nx-hidden-s nx-hidden-m">
  <div class="progress_bar">
    <aside>
      <!-- <nx-multi-stepper currentStepLabel="Step" [direction]="direction" linear>
          <nx-step label="Declaration & Issuance Info"></nx-step>
          <nx-step label="Customer Partner"></nx-step>
          <nx-step label="Coverage"></nx-step>
          <nx-step label="Summary"></nx-step>
          <nx-step label="Complete"></nx-step>
      </nx-multi-stepper> -->

      <nx-multi-stepper currentStepLabel="Step" [direction]="direction"  linear>
        <ng-container *ngFor="let item of activeMultiStep" >
          <nx-step [label]="item.name" [completed]="item.completed" ></nx-step>
        </ng-container>
      </nx-multi-stepper>
    </aside>
  </div>
</div>

<div class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
  <div class="steps-layout">
    <div class="steps-layout__list">
      <ol class="steps step-for-mobile">
        <div class="step-number">
          <!-- 1 of 4 -->
          {{progressValue+1}} of {{activeMultiStep?.length}}

        </div>

        <li class="step active" *ngIf="activeMultiStep">
          <!-- <div class="step-desc">Declaration & Issuance Info</div>
          <div class="next-step nx-margin-top-2xs">Next: Customer Partner</div> -->

          <div class="step-desc">{{activeMultiStep[progressValue]?.name || ''}}</div>
          <div class="next-step nx-margin-top-2xs" *ngIf="activeMultiStep[progressValue+1]">Next: {{activeMultiStep[progressValue+1]?.name || ''}}</div>
        </li>
      </ol>
    </div>
    <hr class="nx-margin-y-m" />
  </div>
</div>
