<div *ngIf="popup_type == 'comprehensive'">
  <div class="left-content">
    <h3 nxHeadline="subsection-medium" class="nx-margin-0">Extra Cover</h3>
    <div class="scroll-container nx-margin-top-2m">
      <table nxTable zebra>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>
              <nx-checkbox>
                <span nxCopytext="medium">Extra Cover</span>
              </nx-checkbox>
            </th>
          </tr>
        </thead>

        <tbody>
          <!-- <tr nxTableRow *ngFor="let item of originalExtraCoverList; let i = index"
              [selectable]="true" (select)="select(item)"
              [selected]="selection.isSelected(item)" (select)="emitData(selection.select(item))">
            <td nxTableCell><nx-checkbox [value]="item.code">{{ item.name }}</nx-checkbox></td>
          </tr> -->
          <tr nxTableRow *ngFor="let item of originalExtraCoverList; let i = index" [selectable]="true"
            (select)="emitData(selection.select(item), item.subCoverCode)">
            <td nxTableCell>
              <nx-checkbox (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                [checked]="selection.isSelected(item)" [value]="item.subCoverCode">
                {{ item.description }}
              </nx-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
      <div nxRow>
        <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
          <nx-natural-language-form size="small" class="pagination_form">
            <span nxCopytext="normal">Show</span>
            <nx-word>
              <nx-dropdown value="5" [panelGrow]="true" [panelMaxWidth]="500">
                <nx-dropdown-item value="5">5</nx-dropdown-item>
                <nx-dropdown-item value="10">10</nx-dropdown-item>
                <nx-dropdown-item value="20">20</nx-dropdown-item>
                <nx-dropdown-item value="30">30</nx-dropdown-item>
                <nx-dropdown-item value="40">40</nx-dropdown-item>
                <nx-dropdown-item value="50">50</nx-dropdown-item>
              </nx-dropdown>
            </nx-word>
            <span nxCopytext="normal">results per page</span>
          </nx-natural-language-form>
        </div>

        <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"></div>
        <div nxCol="12,12,6">
          <nx-pagination [count]="6" [page]="1" [perPage]="1" type="advanced" ariaLabel="Advanced example pagination"
            class="pagination_position">
          </nx-pagination>
        </div>
      </div>
    </div>
    <div class="button-container nx-margin-top-50">
      <button nxModalClose nxButton="secondary">Cancel</button>
      <button nxButton nxModalClose (click)="proceedToClose()">Proceed</button>
      <!-- <button nxButton nxModalClose>Proceed</button> -->
    </div>
  </div>
</div>

<div *ngIf="popup_type == 'tp'">
  <div class="left-content">
    <h3 nxHeadline="subsection-medium" class="nx-margin-0">Extra Cover</h3>
    <div class="scroll-container nx-margin-top-2m">
      <table nxTable zebra>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>
              <nx-checkbox>
                <span nxCopytext="medium">Extra Cover</span>
              </nx-checkbox>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow>
            <td nxTableCell>
              <nx-checkbox>
                <span nxCopytext="medium">TUITION PURPOSES</span>
              </nx-checkbox>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <nx-checkbox>
                <span nxCopytext="medium">EXTENSION OF COVER TO KALIMANTAN</span>
              </nx-checkbox>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <nx-checkbox>
                <span nxCopytext="medium">PRIVATELY OWNED CARS USED FOR TUTION</span>
              </nx-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="button-container nx-margin-top-50">
      <button nxModalClose nxButton="secondary">Cancel</button>
      <button nxButton nxModalClose>Proceed</button>
    </div>
  </div>
</div>

<div *ngIf="popup_type == 'tpft'">
  <div class="left-content">
    <h3 nxHeadline="subsection-medium" class="nx-margin-0">Extra Cover</h3>
    <div class="nx-margin-top-2m scroll-container">
      <table nxTable zebra>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>
              <nx-checkbox>
                <span nxCopytext="medium">Extra Cover</span>
              </nx-checkbox>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow>
            <td nxTableCell>
              <nx-checkbox>
                <span nxCopytext="medium">EXTENSION OF COVER TO KALIMANTAN</span>
              </nx-checkbox>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <nx-checkbox>
                <span nxCopytext="medium">TUITION PURPOSES</span>
              </nx-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="button-container nx-margin-top-50">
      <button nxButton="secondary" nxModalClose>Cancel</button>
      <button nxButton nxModalClose>Proceed</button>
    </div>
  </div>
</div>

<div *ngIf="popup_type == 'makemodel'">
  <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s">
    Make / Model
  </h3>
  <div nxModalContent>
    <div class="left-content">
      <div class="nx-margin-top-2m">
        <div nxLayout="grid nopadding">
          <div nxRow>
            <div nxCol="12">
              <nx-formfield>
                <input nxInput type="search" />
                <span nxFormfieldPrefix>
                  <nx-icon name="search" size="s"></nx-icon>
                </span>
                <span nxFormfieldSuffix aria-hidden="true">
                  <nx-icon name="close" size="s"></nx-icon>
                </span>
                <nx-error nxFormfieldNote><strong>Make / Model required</strong></nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12" class="std-ta-right button_dashboard">
              <button nxButton="secondary" nxModalClose>RESET</button>
              <button nxButton nxModalClose>SEARCH</button>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12" class="nx-margin-top-2m">
              <div class="scroll-container table_popup">
                <div>
                  <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder"></app-external-scrollbar>
                  <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder
                    syncScroll="1">
                    <!-- table here -->
                    <table nxTable zebra class="nx-table--scrollable">
                      <thead>
                        <tr nxTableRow>
                          <th nxHeaderCell>Make</th>
                          <th nxHeaderCell>Model</th>
                          <th nxHeaderCell>Variant</th>
                          <th nxHeaderCell>
                            Year of <br />
                            Manufacture
                          </th>
                          <th nxHeaderCell>Engine Type</th>
                          <th nxHeaderCell>Capacity</th>
                          <th nxHeaderCell>Seat</th>
                          <th nxHeaderCell class="std-ta-right">
                            <span class="text_no_wrap">Sum Insured <br />Range (RM)</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr nxTableRow>
                          <td nxTableCell>PERODUA</td>
                          <td nxTableCell>MYVI</td>
                          <td nxTableCell>1.3 (A) SE</td>
                          <td nxTableCell>2007</td>
                          <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                          <td nxTableCell>Capacity</td>
                          <td nxTableCell>5</td>
                          <td nxTableCell class="std-ta-right">
                            30,000 - 40,000
                          </td>
                        </tr>
                        <tr nxTableRow>
                          <td nxTableCell>PERODUA</td>
                          <td nxTableCell>MYVI</td>
                          <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                          <td nxTableCell>2007</td>
                          <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                          <td nxTableCell>Capacity</td>
                          <td nxTableCell>5</td>
                          <td nxTableCell class="std-ta-right">
                            30,000 - 40,000
                          </td>
                        </tr>
                        <tr nxTableRow>
                          <td nxTableCell>PERODUA</td>
                          <td nxTableCell>MYVI</td>
                          <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                          <td nxTableCell>2007</td>
                          <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                          <td nxTableCell>Capacity</td>
                          <td nxTableCell>5</td>
                          <td nxTableCell class="std-ta-right">
                            30,000 - 40,000
                          </td>
                        </tr>
                        <tr nxTableRow>
                          <td nxTableCell>PERODUA</td>
                          <td nxTableCell>MYVI</td>
                          <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                          <td nxTableCell>2007</td>
                          <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                          <td nxTableCell>Capacity</td>
                          <td nxTableCell>5</td>
                          <td nxTableCell class="std-ta-right">
                            30,000 - 40,000
                          </td>
                        </tr>
                        <tr nxTableRow>
                          <td nxTableCell>PERODUA</td>
                          <td nxTableCell>MYVI</td>
                          <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                          <td nxTableCell>2007</td>
                          <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                          <td nxTableCell>Capacity</td>
                          <td nxTableCell>5</td>
                          <td nxTableCell class="std-ta-right">
                            30,000 - 40,000
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <nx-swipebar label="Swipe to view more">
                  <table nxTable zebra class="nx-table--scrollable">
                    <thead>
                      <tr nxTableRow>
                        <th nxHeaderCell>Make</th>
                        <th nxHeaderCell>Model</th>
                        <th nxHeaderCell>Variant</th>
                        <th nxHeaderCell>Year of <br>  Manufacture</th>
                        <th nxHeaderCell>Engine Type</th>
                        <th nxHeaderCell>Capacity</th>
                        <th nxHeaderCell>Seat</th>
                        <th nxHeaderCell class="std-ta-right">Sum lnsured <br>Range (RM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr nxTableRow>
                        <td nxTableCell>PERODUA</td>
                        <td nxTableCell>MYVI</td>
                        <td nxTableCell>1.3 (A) SE</td>
                        <td nxTableCell>2007</td>
                        <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                        <td nxTableCell>Capacity</td>
                        <td nxTableCell>5</td>
                        <td nxTableCell class="std-ta-right">30,000 - 40,000</td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>PERODUA</td>
                        <td nxTableCell>MYVI</td>
                        <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                        <td nxTableCell>2007</td>
                        <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                        <td nxTableCell>Capacity</td>
                        <td nxTableCell>5</td>
                        <td nxTableCell class="std-ta-right">30,000 - 40,000</td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>PERODUA</td>
                        <td nxTableCell>MYVI</td>
                        <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                        <td nxTableCell>2007</td>
                        <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                        <td nxTableCell>Capacity</td>
                        <td nxTableCell>5</td>
                        <td nxTableCell class="std-ta-right">30,000 - 40,000</td>
                      </tr>
                      <tr nxTableRow>
                        <td nxTableCell>PERODUA</td>
                        <td nxTableCell>MYVI</td>
                        <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                        <td nxTableCell>2007</td>
                        <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                        <td nxTableCell>Capacity</td>
                        <td nxTableCell>5</td>
                        <td nxTableCell class="std-ta-right">30,000 - 40,000</td>
                      </tr>
                       <tr nxTableRow>
                        <td nxTableCell>PERODUA</td>
                        <td nxTableCell>MYVI</td>
                        <td nxTableCell>1.3 (A) SE 1.3S (A)</td>
                        <td nxTableCell>2007</td>
                        <td nxTableCell>INTERNAL COMBUSTION ENGINE</td>
                        <td nxTableCell>Capacity</td>
                        <td nxTableCell>5</td>
                        <td nxTableCell class="std-ta-right">30,000 - 40,000</td>
                      </tr>
                    </tbody>
                  </table>
                </nx-swipebar> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
        <div nxRow>
          <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
            <nx-natural-language-form size="small" class="pagination_form">
              <span nxCopytext="normal">Show</span>
              <nx-word>
                <nx-dropdown value="5" [panelGrow]="true" [panelMaxWidth]="500">
                  <nx-dropdown-item value="5">5</nx-dropdown-item>
                  <nx-dropdown-item value="10">10</nx-dropdown-item>
                  <nx-dropdown-item value="20">20</nx-dropdown-item>
                  <nx-dropdown-item value="30">30</nx-dropdown-item>
                  <nx-dropdown-item value="40">40</nx-dropdown-item>
                  <nx-dropdown-item value="50">50</nx-dropdown-item>
                </nx-dropdown>
              </nx-word>
              <span nxCopytext="normal">results per page</span>
            </nx-natural-language-form>
          </div>

          <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"></div>
          <div nxCol="12,12,6">
            <nx-pagination [count]="10" [page]="1" [perPage]="1" type="advanced" ariaLabel="Advanced example pagination"
              class="pagination_position">
            </nx-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div nxModalActions class="makemodel_button nx-margin-top-m">
    <button nxButton="secondary" nxModalClose>Cancel</button>
  </div>
</div>

<div *ngIf="popup_type == 'SI10K'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Sum insured must be at least RM10,000.00.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'SI4K'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Sum insured must be at least RM4,000.00.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'SI1K'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Sum insured must be at least RM1,000.00.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'betterment'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Your vehicle is subject to betterment, kindly consider purchasing this
      coverage.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == '1allianz'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      The customer is a One Allianz customer and is eligible for complimentary 1
      unit of Road Warrior.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'mingross'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Minimum Gross Premium Applied
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'duplication'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Duplication of Coverage with Enhanced Road Warrior/Road Warrior.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'blocked'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Your application has been blocked, kindly contact your branch.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'duplicatedriver'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Duplicate driver name
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'ehailingdriver'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      Minimum 1 e-Hailing driver is required.
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>

<div *ngIf="popup_type == 'nameddriver'">
  <div class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">WARNING</h3>
    <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
      If you have named driver, please key in now or select WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER for RM 20.00
    </p>
    <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button">
      OK
    </button>
  </div>
</div>