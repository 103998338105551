import { Component } from '@angular/core';
import { NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-clause-message',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './clause-message.component.html',
  styleUrl: './clause-message.component.scss'
})
export class ClauseMessageComponent {

  response: any;

  constructor(
    private dialogRef: NxModalRef<any>,
  ) {}

  onClickYes() {
    this.response = "Y";
    this.closeModal();
  }

  onClickNo() {
    this.response = "N";
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close(this.response);
  }

}
