import { Component, TemplateRef, ViewChild, HostListener } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxCardModule } from "@aposin/ng-aquila/card";
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { NxHeadlineModule } from "@aposin/ng-aquila/headline";
import { NxCopytextModule } from "@aposin/ng-aquila/copytext";
import { NxMessageModule } from "@aposin/ng-aquila/message";
import { NxBadgeModule } from "@aposin/ng-aquila/badge";
import { NxIconModule } from "@aposin/ng-aquila/icon";
import { NxDataDisplayModule } from "@aposin/ng-aquila/data-display";
import { NxListModule } from "@aposin/ng-aquila/list";
import { NxModalModule } from "@aposin/ng-aquila/modal";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { NxDatefieldModule } from "@aposin/ng-aquila/datefield";
// import { Moment } from "moment";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { TextFieldModule } from "@angular/cdk/text-field";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxSwipebarModule } from "@aposin/ng-aquila/swipebar";
import { NxTableModule } from "@aposin/ng-aquila/table";
import { NxRadioModule } from "@aposin/ng-aquila/radio-button";
import { NxPaginationModule } from "@aposin/ng-aquila/pagination";
import { EnquiryPopupComponent } from "../../popup/enquiry-popup/enquiry-popup.component";

@Component({
  selector: "app-complete",
  standalone: true,
  imports: [
    NxButtonModule,
    NxGridModule,
    NxCardModule,
    ProgressBarComponent,
    NxHeadlineModule,
    NxCopytextModule,
    NxMessageModule,
    NxBadgeModule,
    NxIconModule,
    NxDataDisplayModule,
    NxListModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxDropdownModule,
    NxFormfieldModule,
    TextFieldModule,
    NxInputModule,
    NxSwipebarModule,
    NxTableModule,
    NxRadioModule,
    NxPaginationModule,
    SharedModule,
  ],
  templateUrl: "./complete.component.html",
  styleUrl: "./complete.component.scss",
})
export class CompleteComponent {
  @ViewChild("emaildocument") emaildocumentRef!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CompleteComponent>;

  componentDialogRef1?: NxModalRef<EnquiryPopupComponent>;

  constructor(private readonly dialogService: NxDialogService) {}

  // openFromTemplate(): void {
  //     this.dialogService.open(this.reasoncancellationRef, {
  //         ariaLabel: 'A simple modal',
  //         disableClose: true,
  //     });
  // }

  openPopup(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EnquiryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      data: { popup_type: popup_type },
    });
  }

  openPopup2(popup_type: any) {
    let innerWidth = window.innerWidth;
    let popupWidth;
    console.log(innerWidth);
    if (innerWidth < 703) {
      popupWidth = "680";
    } else if (innerWidth >= 703 && innerWidth < 991) {
      popupWidth = "680";
    } else {
      popupWidth = "1100px";
    }
    // return
    this.componentDialogRef1 = this.dialogService.open(EnquiryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      // width: "1100px",
      // maxWidth: "1100px",
      width: popupWidth,
      maxWidth: popupWidth,
      data: { popup_type: popup_type },
    });
  }

  openPopup3(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EnquiryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "500px",
      maxWidth: "500px",
      data: { popup_type: popup_type },
    });
  }

  openPopup4(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EnquiryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "400px",
      maxWidth: "400px",
      data: { popup_type: popup_type },
    });
  }
  options = [
    "BMW",
    "Audi",
    "VW",
    "Mercedes",
    "Porsche",
    "Tesla",
    "Lada",
    "Opel",
    "Fiat",
    "Ford",
    "Kia",
    "Toyota",
    "Ferrari",
  ];

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  activeSlide = 1;
  slides = 2;

  prevPage() {
    this.activeSlide--;
  }

  nextPage() {
    this.activeSlide++;
  }

  goToPage(n: number) {
    this.activeSlide = n;
  }
}
