<form [formGroup]="customerPartnerForm">
<table nxTable cellpadding="0" class="summary_table">
  @if (showNationalityDropdown()) {
    <tr nxTableRow>
      <td nxTableCell colspan="1">
        <nx-formfield label="Nationality" class="nx-margin-bottom-2m">
          <nx-dropdown [showFilter]="true" filterPlaceholder="Type to search country" formControlName="nationality">
            @for (country of lov.clientNationalityList; track country) {
              <nx-dropdown-item [value]="country.code">{{country.descp}}</nx-dropdown-item>
            }
            
          </nx-dropdown>
        </nx-formfield>
      </td>
    </tr>
  }

  @if (showCustomerType()) {
      <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Customer Type</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">
        <div style="
                display: flex;
                justify-content: space-between;
              ">
          <span nxCopytext="normal">{{ type | uppercase }}</span>
          @if (!hideEdit) {
            <button nxPlainButton type="button" (click)="onOpenEdit()">
            EDIT
          </button>
          }
        </div>
      </span>
    </td>
  </tr>
  }


  @if(showName()) {
  <tr nxTableRow>
    <td nxTableCell><span nxCopytext="normal">Name</span></td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ name | uppercase }}</span>
    </td>
  </tr>
  }

  @if (showNationalityText()) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Nationality</span>
      </td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{ countryname | uppercase}}</span>
      </td>
    </tr>
}
  
  @if(showCOI()) {
    <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Country of Incorporation</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ countryIncorporation }}</span>
    </td>
  </tr>
  }

  @if (showIdValue()) {
    <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">{{ idLabel1 }}</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ idValue1 }}</span>
    </td>
  </tr>
  }
  

  @if (showTin()) {
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Tax Identification No. (TIN)</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ clientTinNo | uppercase}}</span>
    </td>
  </tr>
  }

  @if(showSSTAndSMESection()) {
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SST No. </span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ clientSstRegNo | uppercase}}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SST Effective Date</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ clientSstRegDate | uppercase}}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SST De-Registration Date</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ clientSstDeRegDate | uppercase}}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SST Resident Status</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ clientSstResidentStatus | uppercase}}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SST Country</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ clientSstCountry | uppercase}}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SME Reg. No.</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ smeRegisteredNumber }}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SME Category</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ smeCategory | uppercase }}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SME Cert. Start Date</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ smeStartDate }}</span>
    </td>
  </tr>
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">SME Cert. End Date</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ smeEndDate }}</span>
    </td>
  </tr>
  }

  @if(showGender()) {
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Gender</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ gender | uppercase}}</span>
    </td>
  </tr>
  }

  @if((showMartialStatus()) ||(viewPolicyDetails) ) {
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Marital Status</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ marriedStatus | uppercase}}</span>
    </td>
  </tr>
  }

  @if(showDOB()) {
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Date of Birth</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ (cpType === 'FOUND' ? dateOfBirth : (getCalculateDateOfBirth(idValue1)) | date:"dd/MM/yyyy") }}</span>
    </td>
  </tr>
  }

  @if(showAge()) {
  <tr nxTableRow>
    <td nxTableCell><span nxCopytext="normal">Age</span></td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ cpType === 'FOUND' ? age : getCalculateAge(idValue1) }}</span>
    </td>
  </tr>
  }

  @if((showALIM()) ||(viewPolicyDetails) ) {
  <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">ALIM Customer</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ alimCustomer ? alimCustomer : 'NO' | uppercase }}</span>
    </td>
  </tr>
  }

  @if(showMobileNo()) {
    <tr nxTableRow>
      <td nxTableCell>
        <span nxCopytext="normal">Mobile No.</span>
      </td>
      <td nxTableCell class="column_cp_content">
        <span nxCopytext="normal">{{ mobilePhone }}</span>
      </td>
  </tr>
  }

  @if (showFixedLineNo()) {
      <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Fixed Line No.</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ fixLine }}</span>
    </td>
  </tr>
  }

  @if(showFaxNo()) {
      <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Fax No.</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ faxNumber }}</span>
    </td>
  </tr>
  }

  @if(showEmail()) {
      <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Email</span>
    </td>
    <td nxTableCell class="column_cp_content">
      <span nxCopytext="normal">{{ email | uppercase }}</span>
    </td>
  </tr>
  }

  @if (showAddress()) {
      <tr nxTableRow>
    <td nxTableCell>
      <span nxCopytext="normal">Address</span>
    </td>
    <td nxTableCell class="column_cp_content">
      @if(isGeoShow){
      <button nxPlainButton type="button" aria-label="add item">
        <nx-icon name="location" aria-hidden="true"></nx-icon>
      </button>
    }
      <span nxCopytext="normal">{{ fullAddress | uppercase }}</span>
    </td>
  </tr>
  }
</table>
</form>