export enum USERROLE {
  AGENT = "agent",
  ADMIN = "admin",
  BRANCH = "branchAdmin",
  BANCA = "bancaAgent"
}

export enum ACTIONS {
  VIEW = 'VIEW',
  VIEWPOLICY = 'VIEWPOLICY',
  CLAIMPOLICY = 'CLAIMPOLICY',
  UPDATE = 'UPDATE',
  PROFILE = 'PROFILE',
  ISSUE = 'ISSUE',
  ENDT = 'ENDT',
  ENQUIRY = "ENQUIRY",
  RTP = 'RTP',
  MOTOR_APPROVAL = 'MT-APPROVAL',
}

export enum IDTYPE {
  NRIC = "NRIC",
  NRIC_DESCP = "NRIC",
  OLD_IC = "OLD_IC",
  OLD_IC_DESCP = "OLD IC NO.",
  PASS = "PASS",
  PASS_DESCP = "PASSPORT NO.",
  POL = "POL",
  POL_DESCP = "POLICE ID / ARMY ID",
  BR_NO = "BR_NO",
  BR_NO_DESCP = "BUSINESS REGISTRATION NO.",
  NEW_BR_NO = "NEW_BR_NO",
  NEW_BR_NO_DESCP = "NEW BUSINESS REGISTRATION NO."
}

export enum CLIENTTYPE {
  INDIVIDUAL = "P",
  COMPANY = "I",
  P = "Individual",
  I = "Company"
}

export enum GENDER {
  MALE = "M",
  FEMALE = "F",
  M = "Male",
  F = "Female"
}

export enum COUNTRIES {
  MALAYSIAN = "MAL",
  NON_MALAYSIAN = "NM",
  MAL = "Malaysia",
  NM = "Non Malaysian"
}

export namespace CONSTANTS {
  export const DEFAULT_DISPLAY = "N/A";
  
  export function getIdLabel() {
    const IDLABEL = {
      NRIC: "NRIC",
      OLD_IC: "Old IC No.",
      PASS: "Passport No.",
      POL: "Police/Army ID",
      BR_NO: "Business Registration No.",
      NEW_BR_NO: "New Business Registration No."
    }
    return key => IDLABEL[key];
  }

  export function getClientType() {
    return (key: any) => CLIENTTYPE[key];
  }

  export function getMaritalStatus() {
    const STATUSES = {
      0: "Single",
      1: "Married",
      2: "Divorce"
    }
    return (code) => STATUSES[code];
  }

  export function getCountry() {
    return (key: any) => COUNTRIES[key];
  }

  export function getGender() {
    return (key: any) => GENDER[key];
  }

  export function getResidentStatus() {
    const STATUSES = {
      R: "Residential",
      NR: "Non-residential"
    }
    return (code) => STATUSES[code];
  }
}

export enum PAYMENT_MADE_TO {
  CC = "CALL CENTRE COLLECTION ACCOUNT (8000828617)"
}

export enum PAYMENT_MODE {
  CS = "CASH (OVER THE COUNTER)",
  CDM = "CASH DEPOSIT MACHINE",
  CDMD = "CHEQUE",
  ATM = "ATM TRANSFER",
  CC = "CREDIT CARD",
  IFUND = "I-FUND TRANSFER / CIMB CLICKS",
  IBG = "INTER BANK (GIRO)"
}

export enum ISSUING_BANK_NAME {
  ALBMB = "ALLIANCE BANK MALAYSIA BERHAD",
  ALIB = "ALLIANCE ISLAMIC BANK",
  ABABB = "ABN AMRO BANK BERHAD",
  AFFB = "ASIAN FINANCE BANK BERHAD",
  AFBB = "AFFIN BANK BERHAD",
  AFIB = "AFFIN ISLAMIC BANK"
}

export enum REGION {
  W = "West Malaysia",
  E = "East Malaysia (Sabah, Sarawak, Labuan)"
}

export enum PRODUCTS {
  "080100" = "PRIVATE CARS EXCLUDING GOODS",
  "080200" = "PRIVATE CARS INCLUDING GOODS",
  "080300" = "PRIVATE CARS COMPANY REGISTERED",
  "082100" = "MOTOR CYCLE",
  "082101" = "MOTORCYCLE PLUS"
}

export enum STATUS_MAP {
  PRINTED = "Printed",
  APPROVED = "Approved",
  PENDING = "Pending \n Approval",
  CANCELLED = "Cancelled",
  SUBMITTED = "Submitted",
  ACTIVE = "Active",
  EXPIRED = "Expired"
}