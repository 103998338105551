export interface GeolocationModel {
  locations: Array<any>;
  selectedLocation: IGeocodingSelectedLocation;
  isGeolocationEnabled: boolean;
}

export interface IGeocodingSelectedLocation {
  convertedResponse: IGeocodingConversionResponse;
}

export class DefaultAddress implements IGeocodingConversionResponse {
  clientUnitNo: string = "";
  clientAddress1: string = "";
  clientAddress1Tmp: string = "";
  clientAddress2: string = "";
  clientAddress3: string = "";
  clientPostCode: string = "";
  clientCountry: string = "";
  clientCountryDesc: string = "";
  clientState: string = "";
  clientStateDesc: string = "";
  clientCity: string = "";
  clientCityDesc: string = "";
}
export class DefaultAddressDetails implements IGeocodingFullAddressDetails {
  placeId: string = "";
  plusCode: {
    global_code: string;
  };
  formattedAddress: string = "";
  longitude: number = null;
  latitude: number = null;
}
export class DefaultAddressForConversionPayload
  implements IGeocodingConversionPayload
{
  formatted_address = "";
  name = "";
  locality = "";
  administrative_area_level_1 = "";
  country = "";
  postal_code = "";
  clientGeoLongitude = "";
  clientGeoLatitude = "";
  clientGeoPlusCode = "";
  inputLatitude = "";
  inputLongitude = "";
}

export interface IGeocodingConversionPayload {
  formatted_address: string;
  name: string;
  locality: string;
  administrative_area_level_1: string;
  country: string;
  postal_code: string;
  clientGeoLongitude: string;
  clientGeoLatitude: string;
  clientGeoPlusCode: string;
  inputLatitude: string;
  inputLongitude: string;
}

/**
 * IGeocodingConversionResponse is purely all keys that is returned from API. additional values that are needed should be set into  IGeocodingAddressAdditional
 */
export interface IGeocodingConversionResponse<T = string>
  extends Partial<IGeocodingAddressAdditional> {
  clientAddress1: string;
  clientAddress1Tmp: string;
  clientAddress2: string;
  clientAddress3: string;
  clientCity: string;
  clientCityDesc: T;
  clientState: string;
  clientStateDesc: T;
  clientCountry: string;
  clientCountryDesc: T;
  clientPostCode: string;
}

interface IGeocodingAddressAdditional {
  clientUnitNo: string;
  clientGeoLongitude: string;
  clientGeoLatitude: string;
  clientGeoPlaceId: string;
  clientGeoPlusCode: string;
  clientGeoFormattedAddress: string;
}

export interface ILocationDescription {
  code: string;
  descp: string;
  intInsDescp?: string;
  type: string;
  seqNo: number;
}

export interface IGeocodingConversionSuccessResponse {
  data: IGeocodingConversionResponse;
  message: string;
  success: true;
}

export interface IGeocodingConversionFailResponse {
  message: string;
  success: false;
}

export interface IGeocodingStatusPayload {
  operator: string;
  productCode: string
}

export interface IGeocodingFullAddressDetails {
  placeId: string;
  plusCode: IGoogleMapAddressPlusCode;
  formattedAddress: string;
  latitude: number;
  longitude: number;
}

export interface IGoogleMapAddressPlusCode {
  compound_code?: string;
  global_code: string;
}

export interface IGeocodingAddressLOV {
  clientCityList: Array<Partial<LOVResponse>>;
  clientStateList: Array<Partial<LOVResponse>>;
  clientNationalityList: Array<Partial<LOVResponse>>;
}

export interface LOVResponse {
  code: string;
  descp: string;
  type: string;
  shortDescp: string;
}
