@if (isGetInitData) {
  <!-- <base-enquiry [type]="type" [popupMessage]="popupMessage" [referralReasonList]="referralReasonList" [remarksSuccessInd]="remarksSuccessInd">
</base-enquiry> -->
<div class="title-header nx-margin-0">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>{{ productName | titlecase }}</h1>
      </div>
    </div>
  </div>
</div>

<div class="button_area_bg">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        @if(cnoteStatus === "PENDING") {
        <nx-message context="info" class="green_color_notification">
          <span class="nx-font-weight-bold nx-margin-bottom-3xs">Your {{ isEndorsement ? "Endorsement" : "e-Cover Note" }} has been submitted as pending
            approval.</span><br />

          <span nxCopytext="normal">Referral reason:
            <ul nxList="small" class="completed_list">
              @for(referrer of referralReasonList; track referrer) {
              <li>
                <span class="nx-font-weight-bold">{{ referrer.code }}</span> - {{ referrer.desc }}
              </li>
              }
            </ul>
          </span>
        </nx-message>
        }@else if(cnoteStatus === "PRINTED") {
          <nx-message context="info" class="green_color_notification">
            <span class="nx-font-weight-bold nx-margin-bottom-3xs">{{ isEndorsement ? "Endorsement" : "e-Cover Note" }} has been approved.</span><br />
          </nx-message>
        }@else if(cnoteStatus === "CANCELLED") {
          <nx-message context="info" class="green_color_notification">
            <span class="nx-font-weight-bold nx-margin-bottom-3xs">{{ isEndorsement ? "Endorsement" : "e-Cover Note" }} has been rejected.</span><br />
          </nx-message>
        }

        @if(addRemarksInd) {
          <nx-message context="info" class="green_color_notification nx-margin-top-2m">
            <span class="nx-font-weight-bold">Add remarks is successful.</span>
          </nx-message>
        }
      </div>
    </div>

    <!-- @if(remarksSuccessInd) {
      <div nxRow>
        <div nxCol="12">
          <nx-message context="info" class="green_color_notification">
            <span class="nx-font-weight-bold">Add remarks is successful.</span>
          </nx-message>
        </div>
      </div>
    } -->
  </div>
</div>

<form [formGroup]="approvalForm">
<div nxLayout="grid maxwidth">
    <div nxRow>
        <div nxCol="12" class="nx-margin-top-m">
            <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
                <div nxRow class="enquiry_status">
                    <div nxCol="12,12,10">
                        <h3 nxHeadline size="s" class="nx-font-weight-regular">
                            e-Cover Note No. :
                            <span class="nx-font-weight-bold">{{ approvalDetailCngeNote.cnoteNo }}</span>
                        </h3>
                    </div>
                    <div nxCol="12,12,2" class="enquiry_status">
                        @if(cnoteStatus === 'PENDING') {
                            <nx-badge type="active" class="pending_badge">Pending <br />
                            Approval
                        </nx-badge>
                        }

                        @if(cnoteStatus === 'PRINTED') {
                          <nx-badge type="active">Printed</nx-badge>
                        }

                        @if(cnoteStatus === 'CANCELLED') {
                          <nx-badge type="critical">Cancelled</nx-badge>
                        }
                        
                    </div>
                </div>
            </div>

            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Agent Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <div nxLayout="grid nopadding">
                        <app-agents-information [agentData]="agentData" [isApproval]="true" [isEndorsement]="isEndorsement">
                        </app-agents-information>   
                    </div>
                </nx-expansion-panel>
            </nx-accordion>

            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Issuance Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <div nxLayout="grid nopadding">
                        <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo" [isApproval]="true" [inputAgentInfo]="agentData">
                        </app-issuance-information>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>

            <nx-accordion variant="light">
                <nx-expansion-panel [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Customer Partner
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <app-customer-partner-details [viewOnly]="true" [cpType]="'FOUND'" [hideEdit]="true" [cpDetails]="cpData" [lov]="lov" [isApproval]="true">
                    </app-customer-partner-details>
                </nx-expansion-panel>
            </nx-accordion>

            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Coverage
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <h4 class="nx-margin-bottom-2m">Vehicle Details</h4>

                    <app-view-vehicle-details [data]="vehicleDetails" [isApproval]="true" [vehicleUselov]="vehicleUselov" [lov]="lov">
                    </app-view-vehicle-details>

                    <hr class="nx-margin-y-2m" />

                    <h4 class="nx-margin-bottom-2m">Coverage Details</h4>

                    <app-view-coverage-details [isApproval]="true" [progressType]="progressType" [data]="approvalDetailCngeNote"
                    [product]="approvalDetailCngeNote.productCode" [cnoteType]="approvalDetailCngeNote.cnoteType" [lov]="lov">
                    </app-view-coverage-details>

                    @if(!isEndorsement) {
                      <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl nx-margin-bottom-2m" />
                      <app-premium-adjustable (premiumCalculation)="premAdjustableCalculatePremium($event)" [parentForm]="approvalForm" [data]="approvalDetailCngeNote" [htgeData]="approvalDetailHtgePol">
                      </app-premium-adjustable>
                    }

                    <h1 nxHeadline size="s" class="nx-font-weight-semibold nx-margin-bottom-2m">
                        Extra Cover
                    </h1>

                    <div nxLayout="grid nopadding">
                        <app-view-extra-cover [isApproval]="true" [currentExtraCoverList]="currentExtraCoverList" [prevExtraCoverList]="prevExtraCoverList">
                        </app-view-extra-cover>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>

            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Premium Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <app-premium-information [premiumInfo]="premiumInfo" [isApproval]="true" [isEndorsement]="isEndorsement" [isPackageSelected]="isPackageSelected">
                    </app-premium-information>
                </nx-expansion-panel>
            </nx-accordion>

            <!-- @if(approvalDetailClaimsHistory !== null && approvalDetailClaimsHistory.length !== 0) { -->
              <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Claim Details
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <app-claim-details-table [data]="approvalDetailClaimsHistory"></app-claim-details-table>
                </nx-expansion-panel>
            </nx-accordion>
            <!-- } -->

            @if(isEndorsement) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Endorsement History
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <app-endt-history-table [endtHistoryList]="approvalDetailsEndtHistoryList" [lov]="lov">
                  </app-endt-history-table>
                </nx-expansion-panel>
              </nx-accordion>
            }

            @if(isEndorsement) {
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Checklist
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <!-- <div nxLayout="grid nopadding">
                    <div nxRow>
                      <div nxCol="12" class="horizontal-checkboxes">
                        <div class="nx-margin-right-2m">1</div>
                        <div>
                          Insured or Agent Authorised letter / form / email (with
                          contact number) <br />
                          <div class="container_flex_center nx-margin-top-s">
                            <nx-radio class="nx-margin-right-2m"> Yes </nx-radio>
                            <nx-radio labelSize="small"> No </nx-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div nxRow class="nx-margin-top-m">
                      <div nxCol="12" class="horizontal-checkboxes">
                        <div class="nx-margin-right-2m">2</div>
                        <div>
                          Vehicle inspection done <br />
                          <div class="container_flex_center nx-margin-top-s">
                            <nx-radio class="nx-margin-right-2m"> Yes </nx-radio>
                            <nx-radio> No </nx-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div nxRow class="nx-margin-top-m">
                      <div nxCol="12" class="horizontal-checkboxes">
                        <div class="nx-margin-right-2m">3</div>
                        <div>
                          Requested by Bank / Finance owner <br />
                          <div class="container_flex_center nx-margin-top-s">
                            <nx-radio class="nx-margin-right-2m"> Yes </nx-radio>
                            <nx-radio> No </nx-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div nxRow class="nx-margin-top-m">
                      <div nxCol="12">
                        <p nxCopytext="medium" class="nx-margin-bottom-2m">
                          <i>Note: Warranted no claim as at todate</i>
                        </p>
                        <p nxCopytext="normal" class="nx-margin-top-m">
                          Remarks: N/A
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <app-checklist [checklistItems]="approvalDetailCngeNote.declareList" [parentForm]="approvalForm" [isViewOnly]="true"
                  [endtCode]="approvalDetailCngeNote.endtCode" [checklistRemarks]="approvalDetailCngeNote.chklistRemarks">
                  </app-checklist>
                </nx-expansion-panel>
              </nx-accordion>
            }

            @if(isEndorsement) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                      <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                          Endorsement Narration
                        </nx-expansion-panel-title>
                      </nx-expansion-panel-header>
                      <app-endorsement-narration [endtNarration]="approvalDetailCngeNote.endtNarration"></app-endorsement-narration>
                    </nx-expansion-panel>
                  </nx-accordion>
            }

            @if(!isEndorsement) {
              <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            e-Cover Note History
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <div>
                        <app-external-scrollbar targetSyncScroll="8"
                            [targetBaseWidth]="tableHolder8"></app-external-scrollbar>
                        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder8
                            syncScroll="8">
                            <table nxTable nxSort class="enquiry">
                                <thead>
                                    <tr nxTableRow>
                                        <th nxHeaderCell nxSortHeaderCell="product">
                                            e-Cover Note No.
                                        </th>
                                        <th nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Issued Date
                                        </th>
                                        <th nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Agent Code / <br />Agent Name
                                        </th>
                                        <th nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Customer Name
                                        </th>
                                        <th class="std-ta-right" nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Sum Insured (RM)
                                        </th>
                                        <th nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Approve / <br />Decline By
                                        </th>
                                        <th nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Approve / <br />Decline Date
                                        </th>
                                        <th nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Action
                                        </th>
                                        <th nxHeaderCell nxSortHeaderCell="contractNumber">
                                            Remarks
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                  @if(approvalDetailCoverNoteHistory != null && approvalDetailCoverNoteHistory.length !== 0) {
                                    @for(item of approvalDetailCoverNoteHistory; track item) {
                                      <tr nxTableRow>
                                          <td nxTableCell>{{ item.cnoteNo }}</td>
                                          <td nxTableCell>{{ item.cnoteDate }}</td>
                                          <td nxTableCell>{{ item.mkagAgent.agentCode }} / {{ item.mkagAgent.name }}</td>
                                          <td nxTableCell>{{ item.clientPartner.clientName }}</td>
                                          <td nxTableCell>{{ item.sumInsured }}</td>
                                          <td nxTableCell>{{ item.referBy }}</td>
                                          <td nxTableCell>{{ item.referDate }}</td>
                                          <td nxTableCell>{{ item.referType }}</td>
                                          <td nxTableCell>
                                              {{ item.referAdminRemarks }}
                                          </td>
                                      </tr>
                                    }
                                  }@else {
                                    <tr nxTableRow>
                                          <td nxTableCell colspan="9" class="std-ta-center">NO DATA</td>
                                    </tr>
                                  }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>
            }
            

            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Remarks
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    <app-view-remarks [remarksInfo]="remarksInfo" [isApprovalView]=true></app-view-remarks>
                </nx-expansion-panel> 
            </nx-accordion>

            @if(isEndorsement) {
              <div class="horizontal-checkboxes nx-margin-top-m">
              <nx-checkbox class="nx-margin-right-m" formControlName="jpjUpdatedInd"> JPJ Updated </nx-checkbox>
              <nx-checkbox formControlName="ismUpdatedInd"> ISM Updated </nx-checkbox>
            </div>
            }
            
        </div>
    </div>
</div>
</form>

<div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
        <div nxRow>
            <div nxCol="12">
                <div class="button-container">
                    <button nxButton="secondary" type="button" (click)="redirectToSummary()">Back</button>
                    @if(userRole.toUpperCase() === "ADMIN" && cnoteStatus === "PENDING") {
                      <button nxButton="secondary" type="button" (click)="openAddRemarksPopUp()">
                        ADD REMARKS
                    </button>
                    }
                    @if(userRole.toUpperCase() === "ADMIN" && cnoteStatus === "PENDING") {
                    <button nxButton="primary" type="button" (click)="openApprovePopUp()">
                        {{ isEndorsement ? "APPROVE ENDORSEMENT" : "APPROVE E-COVER NOTE" }}
                    </button>
                    }
                    @if(userRole.toUpperCase() === "ADMIN" && cnoteStatus === "PENDING") {
                    <button nxButton="primary" type="button" (click)="openRejectPopUp()">
                        {{ isEndorsement ? "REJECT ENDORSEMENT" : "REJECT E-COVER NOTE" }}
                    </button>
                    }
                    <!-- <button nxButton="primary" type="button">UPLOAD DOCUMENT</button> -->
                    @if(isShowEndtSchedule()) {
                      <button nxButton="primary" type="button" (click)="onClickEndtSchedule()">
                      <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>Endorsement Schedule PDF
                    </button>
                    }
                    @if(isShowEndtCertBtn()) {
                      <button nxButton="primary" type="button" (click)="onClickEndtCert()">
                        <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>Endorsement Certificate PDF
                    </button>
                    }
                    @if(isShowEInvoiceBtn()) {
                    <button nxButton="primary" type="button" (click)="onClickInvoicePDF()">
                      <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>E-invoice PDF
                    </button>
                    }
                  @if(isShowCreditTaxInvoiceBtn()) {
                  <button nxButton="primary" type="button" (click)="onClickInvoicePDF()">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>Credit Tax Invoice PDF
                  </button>
                  }
                  @if(isShowDebitTaxInvoiceBtn()) {
                  <button nxButton="primary" type="button" (click)="onClickInvoicePDF()">
                    <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>Debit Tax Invoice PDF
                  </button>
                  }
                </div>
            </div>
        </div>
    </div>
</div>

@if(crossSellList && crossSellList.length !== 0) {
  <app-cross-sell-banner [crossSellList]="crossSellList" [userInfo]="userInfo" [isApproval]="true"
  [marketingConsent]="marketingConsent" [clientPartnerInfo]="approvalDetailCngeNote.clientPartner" [agentInfo]="approvalDetailCngeNote.mkagAgent">
  </app-cross-sell-banner>
}
}