import { Component, Input } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-view-extra-cover',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './view-extra-cover.component.html',
  styleUrl: './view-extra-cover.component.scss'
})
export class ViewExtraCoverComponent {
  @Input() currentExtraCoverList;
  @Input() prevExtraCoverList;
  @Input() isApproval;
}
