<h4 class="nx-margin-y-2m">Vehicle Details</h4>
<table nxTable cellpadding="0" class="summary_table">
    <tr nxTableRow *ngFor="let item of getFilteredVehicleDetailsLabels()">
        <td nxTableCell>
            <span nxCopytext="normal">{{ item.label }}</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">
                {{ getValue(item.key, 'vehicleDetails') }}
            </span>
        </td>
    </tr>
</table>

<hr class="nx-margin-y-2m" />
<h4 class="nx-margin-y-2m">Coverage Details</h4>
<table nxTable cellpadding="0" class="summary_table">
    <tr nxTableRow *ngFor="let item of coverageDetailsLabels">
        <td nxTableCell>
            <span nxCopytext="normal">{{ item.label }}</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">
                <ng-container *ngIf="(viewQuotation || viewPolicyDetails) && item.key === 'sumInsured'">
                    {{ getValue(item.key, 'coverageDetails') }}
                    <span nxCopytext="small"><i>(Recommended Sum Insured : {{formatSumInsured(minSumInsured)}} -
                            {{formatSumInsured(maxSumInsured)}})</i></span>
                </ng-container>
                <ng-container *ngIf="!((viewQuotation || viewPolicyDetails) && item.key === 'sumInsured')">
                    {{ getValue(item.key, 'coverageDetails') }}
                </ng-container>
            </span>
        </td>
    </tr>
</table>

@if(extraCoverPackageSummary && showExtraCoverage){
<hr class="nx-margin-y-2m" />
<h4 class="nx-margin-y-2m">Extra Coverage</h4>
@if(extraCoverPackageSummary.rahmahPackageList?.length > 0){
<span nxCopytext="normal" class="nx-font-weight-semibold">These coverage(s) / service(s) are provided at no extra
    cost.</span>
}
<ul nxList="small" class="completed_list">
    <li>
        <span nxCopytext="normal" class="nx-font-weight-semibold">{{ extraCoverPackageSummary.subCovPackageDesc
            }}</span>
        <ul class="nx-margin-y-0" *ngFor="let item of extraCoverPackageSummary.rahmahPackageList; let i = index">
            <div nxLayout="grid nopadding" class="grid_remove_padding">
                <div nxRow>
                    <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- {{ item.description || item.subCoverCodeDescription }}</span>
                    </div>
                    <div *ngIf="item.displayLabel && item.subCoverCode == 'RPPA1'" nxCol="12,12,6,4"
                        class="extra_coverage_text">
                        <span nxCopytext="medium">{{ item.displayLabel}}
                        </span>
                    </div>
                    @if(item.mtExtCovDtoList){
                    <ng-container *ngFor="let subItem of item.mtExtCovDtoList">
                        @if(item.planCode){{{ subItem.displayLabel }} <br>}
                    </ng-container>
                    }
                    <div *ngIf="item.subCoverSumInsured != 0" nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">SUM INSURED (RM) : {{ item.subCoverSumInsured | formatDecimal}}
                        </span>
                    </div>
                    <div nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">
                            @if(item.subCoverAmtPerDay > 0|| item.subCoverAmtday > 0){ RM {{ item.subCoverAmtPerDay||
                            item.subCoverAmtday}} }
                            @if(item.subCoverNumberOfDays > 0 || item.subCoverDays > 0){ PER DAY / MAX {{
                            item.subCoverNumberOfDays || item.subCoverDays }} DAYS}
                        </span>
                    </div>
                </div>
            </div>
        </ul>
    </li>
    <li *ngFor="let item of extraCoverPackageSummary.topExtraCoverList; let i = index">
        <div nxLayout="grid nopadding" class="grid_remove_padding">
          <div nxRow>
            <div nxCol="12,12,6,5">
              <span nxCopytext="normal" class="nx-font-weight-semibold"
                >{{ item.description || item.subCoverCodeDescription}}</span
              >
            </div>
            @if(item.subCoverCode === 'PAB-RW'){
            <div nxCol="12,12,6,4" class="extra_coverage_text">
                <span nxCopytext="medium">
                    PLAN / UNIT : {{ getMRWDesc(item.planCode) }}<br>
                    SUM INSURED (RM) : {{ item.subCoverSumInsured | formatDecimal}}</span>
            </div>
            }
          </div>
        </div>
      </li>
</ul>
<ng-container *ngIf="extraCoverPackageSummary.selectedExtraCoverList.length > 0">
    <div class="nx-margin-top-2m">
        <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">

            <table nxTable zebra cellpadding="0">
                <thead>
                    <tr nxTableRow class="table_row_flex">
                        <th nxHeaderCell class="table_extra_coverage">
                            <span nxCopytext="medium" class="nx-font-weight-semibold">Extra Cover</span>
                        </th>
                        <th nxHeaderCell class="table_extra_coverage"></th>
                        <th nxHeaderCell class="table_extra_coverage">
                            <span nxCopytext="medium" class="nx-font-weight-semibold">Premium (RM)</span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr nxTableRow class="table_row_flex"
                        *ngFor="let item of extraCoverPackageSummary.selectedExtraCoverList">
                        <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">{{ item.description }}</span>
                        </td>
                        <td nxTableCell class="table_extra_coverage">
                            @if(item.planCode){PLAN/ UNIT : {{ getDropDownDesc(item.subCoverCode,item.planCode) }} <br>}
                            @if(item.subCoverCode == '112'){
                            <ng-container *ngIf="item.subCoverNumberOfDays > 0 || item.subCoverDays > 0">
                                NO OF DAYS : {{ item.subCoverNumberOfDays || item.subCoverDays}} <br>
                            </ng-container>
                            <ng-container *ngIf="item.subCoverAmtday > 0">
                                AMOUNT PER DAY : {{ item.subCoverAmtday }} <br>
                            </ng-container>
                            }
                            <ng-container *ngIf="item.subCoverSumInsured != 0">
                                SUM INSURED (RM) : {{ item.subCoverSumInsured| formatDecimal }}
                            </ng-container>
                        </td>
                        <td nxTableCell class="table_extra_coverage">
                            <ng-container *ngIf="item.subCoverPrem != 0">
                                <span nxCopytext="medium">{{ item.subCoverPrem| formatDecimal }}</span>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
}

@if(showNamedDriver) {
<hr class="nx-margin-y-2m" />
<h4 class="nx-marginm-2m">Named Drivers</h4>
<div class="nx-margin-top-2m-botto">
    <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
        <table nxTable zebra cellpadding="0">
            <thead>
                <tr nxTableRow>
                    <th nxHeaderCell *ngFor="let header of driverHeaderLabels">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">{{ header.label }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr nxTableRow *ngFor="let driver of namedDriverList">
                    <td nxTableCell *ngFor="let header of driverHeaderLabels">
                        @if(header.key === 'driverId' || header.key === 'driverAge') {
                        <span nxCopytext="medium">{{ formatDriverAgeAndId(driver[header.key]) }}</span>
                        }@else {
                        <span nxCopytext="medium">{{ driver[header.key] ?? 'N/A' }}</span>
                        }
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
}

@if(showEhailing) {
<h4 class="nx-margin-bottom-2m">e-Hailing Drivers</h4>
<div class="nx-margin-top-2m">
    <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
        <table nxTable zebra cellpadding="0">
            <thead>
                <tr nxTableRow>
                    <th nxHeaderCell *ngFor="let header of ehailingDriverHeaderLabels">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">{{ header.label }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr nxTableRow *ngFor="let driver of ehailingDriverList">
                    <td nxTableCell *ngFor="let header of ehailingDriverHeaderLabels">
                        <span nxCopytext="medium">{{ driver[header.key] }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
}