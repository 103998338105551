import { Component, Input, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { SharedModule } from "@shared/shared.module";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Observable } from "rxjs";

@Component({
  selector: "app-premium-summary",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./premium-summary.component.html",
  styleUrl: "./premium-summary.component.scss",
})
export class PremiumSummaryComponent implements OnInit {

  @Input() isThirdParty!: boolean;

  typeOfCoverage: any;

  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    
    this.typeOfCoverage = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);

    this.coveragePremium$.subscribe((state) => {
      if (state) {
        const cngeNote = state["cngeNote"];
        if (cngeNote) {
          const riskList = cngeNote["riskList"];
          if (riskList?.length) {
            for (let riskListItem of riskList) {
              const coverList = riskListItem["coverList"];
              if (coverList?.length) {
                for (let coverListItem of coverList) {
                  this.typeOfCoverage = {
                    ...this.typeOfCoverage,
                    ...{ grossPremium: coverListItem["grossPremium"] },
                    ...{ basicPremium: coverListItem["basicPremium"] },
                    ...{ ncdPercentage: coverListItem["ncdPercentage"] },
                    ...{ ncdAmount: coverListItem["ncdAmount"] },
                    ...{ subCoverPremium: coverListItem["subCoverPremium"] },
                    ...{ loadPercentage: coverListItem["loadPercentage"] },
                    ...{ loadAmount: coverListItem["loadAmount"] },
                  };
                }
              }
            }
          }
        }
      }
    });
  }

}
