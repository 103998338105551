<div nxModalContent class="std-ta-center">
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
        Remark Added!
    </h3>
    <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
        Add Referral Remarks is successful. You may return to this e-Cover Note through the Summary screen.
    </p>
</div>

<div nxLayout="grid" class="std-ta-center">
    <button nxButton="primary" type="button" (click)="backToSummary()">
        BACK TO SUMMARY LISTING
    </button>
</div>