<!-- Endorsement Details -->
<ng-container *ngIf="endorsement_details">
  <div class="title-header">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <h1>Private Cars Excluding Goods</h1>
        </div>
      </div>
    </div>
  </div>

  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12,12,12,3" class="grid_width_page">
        <!-- 
      Note:
      Progree type:
      1. cn = cover note and quotation progress bar, 5 step
      2. endorsement = endorsement progress bar, 4 step
      3. unification = unification progress bar, 6 step
      4. rtp = road tax purpose progress bar, 3 step

      progressvalue = step index
      -->
        <app-progress-bar [progressValue]="2" [progressType]="'endorsement'" />
        <app-progress-bar />
      </div>
      <div nxCol="12,12,12,9" class="grid_width_page">
        <div>
          <h2 class="nx-margin-0">Endorsement Details</h2>
        </div>

        <div class="nx-margin-top-2m">
          <!-- Policy movement accordion -->
           <!-- WORKING: Policy Movement -->
          <nx-accordion variant="light">
            <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
              <nx-expansion-panel-header>
                <nx-expansion-panel-title>Policy Movement
                </nx-expansion-panel-title>
              </nx-expansion-panel-header>

              <div>
                <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder1"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder1 syncScroll="1">
                  <table nxTable zebra #table cellpadding="0" class="clickable_table">
                    <thead>
                      <tr nxTableRow>
                        <th nxHeaderCell>
                          <span nxCopytext="medium" class="nx-font-weight-semibold">No.</span>
                        </th>
                        <th nxHeaderCell>
                          <span nxCopytext="medium" class="nx-font-weight-semibold">Endorsement Type</span>
                        </th>
                        <th nxHeaderCell>
                          <span nxCopytext="medium" class="nx-font-weight-semibold">Effective Date</span>
                        </th>
                        <th nxHeaderCell>
                          <span nxCopytext="medium" class="nx-font-weight-semibold">Expiry Date</span>
                        </th>
                        <th nxHeaderCell>
                          <span nxCopytext="medium" class="nx-font-weight-semibold">Sum Insured (RM)</span>
                        </th>
                        <th nxHeaderCell>
                          <span nxCopytext="medium" class="nx-font-weight-semibold">Premium Due (RM)</span>
                        </th>
                        <th nxHeaderCell>
                          <span nxCopytext="medium" class="nx-font-weight-semibold">Vehicle No.</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr nxTableRow *ngFor="let item of endorsement_table" (click)="openPopup3('policyTable')">
                        <td nxTableCell>
                          <span nxCopytext="medium">{{ item.no }}</span>
                        </td>
                        <td nxTableCell>
                          <span nxCopytext="medium"> {{ item.type }}</span>
                        </td>
                        <td nxTableCell>
                          <span nxCopytext="medium">{{
                            item.effective_date
                            }}</span>
                        </td>
                        <td nxTableCell>
                          <span nxCopytext="medium">{{ item.exp_date }}</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <span nxCopytext="medium">{{ item.si }}</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <span nxCopytext="medium">{{
                            item.premium_due
                            }}</span>
                        </td>
                        <td nxTableCell>
                          <span nxCopytext="medium">{{ item.vehicle_no }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div nxLayout="grid nopadding"
                class="nx-margin-top-2xs nx-margin-bottom-2m grid_remove_padding pagination_form">
                <div nxRow>
                  <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
                    <nx-natural-language-form size="small" class="pagination_form">
                      <span nxCopytext="normal">Show</span>
                      <nx-word>
                        <nx-dropdown value="5" [panelGrow]="true" [panelMaxWidth]="500">
                          <nx-dropdown-item value="5">5</nx-dropdown-item>
                          <nx-dropdown-item value="10">10</nx-dropdown-item>
                          <nx-dropdown-item value="20">20</nx-dropdown-item>
                          <nx-dropdown-item value="30">30</nx-dropdown-item>
                          <nx-dropdown-item value="40">40</nx-dropdown-item>
                          <nx-dropdown-item value="50">50</nx-dropdown-item>
                        </nx-dropdown>
                      </nx-word>
                      <span nxCopytext="normal">results per page</span>
                    </nx-natural-language-form>
                  </div>

                  <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                  </div>
                  <div nxCol="12,12,6" class="container_flex_center align_right">
                    <nx-pagination [count]="count" [page]="page" [perPage]="perPage" type="advanced"
                      (goPrev)="prevPage()" (goNext)="nextPage()" (goPage)="goToPage($event)"
                      ariaLabel="Advanced example pagination" class="pagination_position">
                    </nx-pagination>
                  </div>
                </div>
              </div>
            </nx-expansion-panel>
          </nx-accordion>

          <!-- Vehicle details accordion -->
           <!-- WORKING: Vehicle Details -->
            
          <nx-accordion variant="light">
            <nx-expansion-panel>
              <nx-expansion-panel-header>
                <nx-expansion-panel-title>Vehicle Details
                </nx-expansion-panel-title>
              </nx-expansion-panel-header>

              <form class="form-example">
                <div nxLayout="grid nopadding">
                  <div nxRow>
                    <div nxCol="12">
                      <span nxCopytext="normal">Where is the vehicle being used?</span><br />
                      <div nxLayout="grid nopadding" class="nx-margin-top-s">
                        <div nxRow>
                          <div nxCol="12,12,4">
                            <nx-radio name="group1" [checked]="true">West Malaysia</nx-radio>
                          </div>
                          <div nxCol="12,12,8" class="m_margin_top_20">
                            <nx-radio name="group1">
                              East Malaysia (Sabah, Sarawak, Labuan)</nx-radio>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div nxCol="12" class="nx-margin-top-2m">
                      <nx-formfield label="Use of Vehicle">
                        <nx-dropdown>
                          <nx-dropdown-item value="1">PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY
                            USE)</nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError>
                          Please select an option.
                        </nx-error>
                      </nx-formfield>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Vehicle No.">
                        <input nxInput [formControl]="vehicleFormControl" />
                        <nx-error nxFormfieldError><strong>Vehicle No. required.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError><strong>Invalid Vehicle No.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Year of Manufacture">
                        <input nxInput />
                        <nx-error nxFormfieldError><strong>Please note:</strong> this field is
                          required!</nx-error>
                      </nx-formfield>
                    </div>

                    <div nxCol="12">
                      <nx-formfield label="Make / Model">
                        <input nxInput [formControl]="makemodelFormControl" />
                        <span nxFormfieldSuffix>
                          <nx-icon aria-hidden="true" name="search" (click)="makemodel()"></nx-icon>
                        </span>
                        <nx-error nxFormfieldError><strong>Make / Model required.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError><strong>Kindly make changes to proceed for
                            endorsement.</strong>
                        </nx-error>
                      </nx-formfield>
                      <ng-template #template2>
                        <div class="left-content">
                          <h3 nxHeadline="subsection-medium" class="nx-margin-0">
                            Make / Model
                          </h3>
                          <div class="nx-margin-top-2m">
                            <div nxLayout="grid nopadding">
                              <div nxRow>
                                <div nxCol="12">
                                  <nx-formfield>
                                    <input nxInput type="search" />
                                    <span nxFormfieldPrefix>
                                      <nx-icon name="search" size="s"></nx-icon>
                                    </span>
                                    <span nxFormfieldSuffix aria-hidden="true">
                                      <nx-icon name="close" size="s"></nx-icon>
                                    </span>
                                  </nx-formfield>
                                </div>
                              </div>
                              <div nxRow>
                                <div nxCol="12" class="std-ta-right">
                                  <button nxButton="secondary" (click)="closeTemplateDialog()"
                                    class="nx-margin-right-s">
                                    RESET
                                  </button>
                                  <button nxButton (click)="closeTemplateDialog()" class="m_margin_top_20">
                                    SEARCH
                                  </button>
                                </div>
                              </div>
                              <div nxRow>
                                <div nxCol="12" class="nx-margin-top-2m coverage">
                                  <div>
                                    <app-external-scrollbar targetSyncScroll="2"
                                      [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
                                    <div class="table-holder table_content_scroll only-this-horizon-scrollbar"
                                      #tableHolder2 syncScroll="2">
                                      <table nxTable class="more_extra_cover">
                                        <tbody>
                                          <tr nxTableRow>
                                            <th nxHeaderCell>
                                              <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                                <div class="nx-font-weight-bold">
                                                  Extra Cover
                                                </div>
                                              </nx-checkbox>
                                            </th>
                                            <th nxHeaderCell></th>
                                            <th nxHeaderCell>Premium (RM)</th>
                                          </tr>
                                          <tr nxTableRow>
                                            <td nxTableCell>
                                              <nx-checkbox [checked]="true" labelSize="small"
                                                class="nx-font-weight-semibold">
                                                LEGAL LIABILITY OF PASSENGERS
                                                FOR NEGLIGENT ACTS
                                              </nx-checkbox>
                                            </td>
                                            <td nxTableCell></td>
                                            <td nxTableCell class="std-ta-right">
                                              7.50
                                            </td>
                                          </tr>
                                          <tr nxTableRow>
                                            <td nxTableCell>
                                              <nx-checkbox [checked]="true" labelSize="small"
                                                class="nx-font-weight-semibold">
                                                LEGAL LIABILITY TO PASSENGERS
                                              </nx-checkbox>
                                            </td>
                                            <td nxTableCell class="std-ta-right"></td>
                                            <td nxTableCell class="std-ta-right">
                                              33.75
                                            </td>
                                          </tr>
                                          <tr nxTableRow>
                                            <td nxTableCell>
                                              <nx-checkbox [checked]="true" labelSize="small"
                                                class="nx-font-weight-semibold">
                                                EXTENSION OF COVER TO THE
                                                KINGDOM OF THAILAND (APPLICABLE
                                                TO PRIVATE CAR THIRD PARTY
                                                POLICY ONLY)
                                              </nx-checkbox>
                                            </td>
                                            <td nxTableCell class="std-ta-right"></td>
                                            <td nxTableCell class="std-ta-right">
                                              101.25
                                            </td>
                                          </tr>
                                          <tr nxTableRow>
                                            <td nxTableCell>
                                              <nx-checkbox [checked]="true" labelSize="small"
                                                class="nx-font-weight-semibold">
                                                TUITION PURPOSES
                                              </nx-checkbox>
                                            </td>
                                            <td nxTableCell class="std-ta-right"></td>
                                            <td nxTableCell class="std-ta-right">
                                              67.50
                                            </td>
                                          </tr>
                                          <tr nxTableRow>
                                            <td nxTableCell>
                                              <nx-checkbox [checked]="true" labelSize="small"
                                                class="nx-font-weight-semibold">
                                                EXTENSION OF COVER TO KALIMANTAN
                                              </nx-checkbox>
                                            </td>
                                            <td nxTableCell class="std-ta-right"></td>
                                            <td nxTableCell class="std-ta-right">
                                              30.80
                                            </td>
                                          </tr>
                                          <tr nxTableRow>
                                            <td nxTableCell>
                                              <nx-checkbox [checked]="true" labelSize="small"
                                                class="nx-font-weight-semibold">
                                                PRIVATELY OWNED CARS USED FOR
                                                TUTION
                                              </nx-checkbox>
                                            </td>
                                            <td nxTableCell class="std-ta-right"></td>
                                            <td nxTableCell class="std-ta-right">
                                              67.50
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div nxLayout="grid nopadding" class="nx-margin-top-m pagination">
                            <div nxRow rowAlignItems="start,right">
                              <div nxCol="12,12,5" class="container_flex_center nx-hidden-xs nx-hidden-s show">
                                show
                                <nx-formfield class="formfield_no_padding dropdown_font_14 nx-margin-x-3xs">
                                  <nx-dropdown value="5" [panelGrow]="true" [panelMaxWidth]="500">
                                    <nx-dropdown-item value="5"></nx-dropdown-item>
                                    <nx-dropdown-item value="10"></nx-dropdown-item>
                                    <nx-dropdown-item value="15"></nx-dropdown-item>
                                  </nx-dropdown>
                                </nx-formfield>
                                per result
                              </div>
                              <div nxCol="12,12,7" class="numbers std-ta-right">
                                <nx-pagination [count]="200" [page]="1" [perPage]="10" type="advanced">
                                </nx-pagination>
                              </div>
                            </div>
                          </div>
                          <div class="two-button-container nx-margin-top-m">
                            <button nxButton="secondary" (click)="closeTemplateDialog()"
                              class="nx-margin-right-s nx-margin-top-m">
                              Cancel
                            </button>
                            <button nxButton (click)="closeTemplateDialog()" class="nx-margin-top-m">
                              Proceed
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Unit of Measurement">
                        <input nxInput />
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Capacity">
                        <input nxInput [formControl]="capacityFormControl" />
                        <nx-error nxFormfieldError><strong>Capacity required.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError><strong>Please change to correct Vehicle Capacity.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError><strong>CC value between 40 to 99999.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Engine Type">
                        <nx-dropdown>
                          <nx-dropdown-item value="1">INTERNAL COMBUSTION ENGINE</nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError>
                          Please select an option.
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="No. of Seats">
                        <input nxInput [formControl]="numberseatFormControl" />
                        <nx-error nxFormfieldError><strong>No. of Seats required.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError><strong>Only positive number is allowed.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Engine / Motor No.">
                        <input nxInput [formControl]="engineFormControl" />
                        <nx-error nxFormfieldError><strong>Engine/Motor No. required.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError><strong>Kindly make changes to proceed for
                            endorsement.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Chassis No.">
                        <input nxInput [formControl]="chassisFormControl" />
                        <nx-error nxFormfieldError><strong>Chassis No. required.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError><strong>Kindly make changes to proceed for
                            endorsement.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Loan Type (Optional)">
                        <nx-dropdown>
                          <nx-dropdown-item value="1">--</nx-dropdown-item>
                          <nx-dropdown-item value="2">HIRE PURCHASE</nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError>
                          Please select an option.
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Loan Company (Optional)">
                        <input nxInput [formControl]="loadcompanyFormControl" />
                        <nx-error nxFormfieldError><strong>Company Loan required.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Vehicle Purchase Date (Optional)">
                        <input nxDatefield nxInput [datepicker]="myDatepicker" />
                        <span nxFormfieldHint>MM/DD/YYYY</span>

                        <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker></nx-datepicker>
                      </nx-formfield>
                    </div>
                    <div nxCol="12">
                      <nx-checkbox class="nx-margin-bottom-s" [checked]="true">
                        I would like to amend the Make / Model Description to
                        match loan documents.
                      </nx-checkbox>

                      <div class="make_model">
                        <div class="field_label">
                          Make / Model to be printed in Quotation / Cover Note
                        </div>
                        <div class="custom">
                          <div class="make_container">
                            <span class="make_label">{{ makeModel }}</span>
                          </div>

                          <div class="input_container customField formfield_no_padding">
                            <nx-formfield>
                              <textarea nxInput cdkTextareaAutosize value="TEST" (keyup)="onKeyUp($event)"
                                [formControl]="makemodeldesFormControl"></textarea>

                              <span nxFormfieldHint>
                                <span *ngIf="characterCount === 0">
                                  Max. 90 characters
                                </span>
                                <span *ngIf="characterCount > 0">
                                  {{ 90 - (characterCount || 0) }} characters
                                  remaining
                                </span>
                              </span>
                            </nx-formfield>
                          </div>
                        </div>
                        <nx-error nxFormfieldError><strong>Make/Model Description required.</strong>
                        </nx-error>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </nx-expansion-panel>
          </nx-accordion>

          <!-- Coverage Accordion extra coverage -->
          <nx-accordion variant="light">
            <nx-expansion-panel class="accordion_remove_btm_space">
              <nx-expansion-panel-header>
                <nx-expansion-panel-title class="accordion_edit">
                  Coverage
                </nx-expansion-panel-title>
              </nx-expansion-panel-header>

              <!-- Extra Coverage -->

              <!-- Comprehensive -->
              <ng-container *ngIf="show_comprehensive">
                <div nxLayout="grid" class="grid_remove_padding coverage">
                  <div nxRow>
                    <div nxCol="12">
                      <h4 class="nx-margin-0">Extra Coverage</h4>
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12" class="nx-font-weight-semibold">
                      These coverage(s) / service(s) are provided at no extra
                      cost.
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12">
                      <table class="extra_package">
                        <tbody>
                          <tr nxTableRow>
                            <td>
                              <nx-checkbox [disabled]="true" [checked]="true" labelSize="small">
                                <div class="nx-font-weight-bold">
                                  RAHMAH PACKAGE
                                </div>
                              </nx-checkbox>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td class="sub_cover">
                              - COMPASSIONATE FLOOD COVER
                            </td>
                            <td class="si">SUM INSURED (RM) : 3,000.00</td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td class="sub_cover">
                              - DEATH/PERMANENT DISABLEMENT
                            </td>
                            <td class="si">SUM INSURED (RM) : 5,000.00</td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td class="sub_cover">- HOSPITAL INCOME</td>
                            <td class="si">RM 50.00 PER DAY / MAX 60 DAYS</td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td>
                              <nx-checkbox [disabled]="true" [checked]="true">
                                <div class="nx-font-weight-bold">
                                  24-HOUR EMERGENCY TOWING (UP TO 150KM)
                                </div>
                              </nx-checkbox>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <!-- <tr nxTableRow class="nx-margin-bottom-2m">                                       
                              <td>
                                <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                  <div class="nx-font-weight-bold">MOTOR ROAD WARRIOR</div>
                                </nx-checkbox>
                              </td>
                              <td class="si">PLAN / UNIT : 1 UNIT <br>
                                SUM INSURED (RM) : 10,000.00</td>
                              <td></td>
                            </tr>                                        -->
                        </tbody>
                      </table>

                      <div class="nx-margin-top-2m">
                        <app-external-scrollbar targetSyncScroll="3"
                          [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
                        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3
                          syncScroll="3">
                          <table nxTable class="more_extra_cover">
                            <tbody>
                              <tr nxTableRow>
                                <th nxHeaderCell>
                                  <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                    <div class="nx-font-weight-bold">
                                      Extra Cover
                                    </div>
                                  </nx-checkbox>
                                </th>
                                <th nxHeaderCell></th>
                                <th nxHeaderCell>Premium (RM)</th>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    MOTOR ROAD WARRIOR
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell>
                                  <nx-natural-language-form size="small">
                                    <span class="nx-margin-right-3xs">PLAN / UNIT :
                                    </span>
                                    <nx-formfield class="formfield_no_padding">
                                      <nx-dropdown [panelGrow]="true" value="1 UNIT">
                                        <nx-dropdown-item value="1 UNIT" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="2 UNIT" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="3 UNIT" class="table_dropdown"></nx-dropdown-item>
                                      </nx-dropdown>
                                    </nx-formfield>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>Plan required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">20.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                                    DRIVERS
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">20.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    COVER FOR WINDSCREENS, WINDOWS AND SUNROOF
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  SUM INSURED (RM) : 500.00
                                </td>
                                <td nxTableCell class="std-ta-right">60.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    INCLUSION OF SPECIAL PERILS
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">80.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    MOTOR ENHANCED ROAD WARRIOR
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  <nx-natural-language-form size="small">
                                    <span class="nx-margin-right-3xs">PLAN / UNIT :
                                    </span>
                                    <nx-formfield class="formfield_no_padding">
                                      <nx-dropdown [panelGrow]="true" value="PLAN A">
                                        <nx-dropdown-item value="PLAN A" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="PLAN B" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="PLAN C" class="table_dropdown"></nx-dropdown-item>
                                      </nx-dropdown>
                                    </nx-formfield>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>Plan required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">75.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    OPTIONAL CAR REPLACEMENT
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">75.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    WAIVER OF BETTERMENT CONTRIBUTION
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">230.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    KEY CARE
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  <nx-natural-language-form size="small" class="color_disabled">
                                    SUM INSURED (RM) :
                                    <nx-word>
                                      <input nxInput value="1,000.00" disabled class="color_disabled" />
                                    </nx-word>
                                    <nx-error nxFormfieldNote>
                                      <strong>Amount required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">35.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    DRIVER AND PASSENGERS SHIELD
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  <nx-natural-language-form size="small">
                                    <span class="nx-margin-right-3xs">PLAN / UNIT :
                                    </span>
                                    <nx-formfield class="formfield_no_padding test">
                                      <nx-dropdown [panelGrow]="true" value="PLAN A">
                                        <nx-dropdown-item value="PLAN A" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="PLAN B" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="PLAN C" class="table_dropdown"></nx-dropdown-item>
                                      </nx-dropdown>
                                    </nx-formfield>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>Plan required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">30.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          DEATH/PERMANENT DISABLEMENT OCCURRING
                                          WITHIN 180 DAYS OF THE ACCIDENT
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="std-ta-right">
                                  <p nxCopytext="medium">
                                    SUM INSURED (RM) : 25,000 PER PERSON
                                  </p>
                                </td>
                                <td class="std-ta-right"></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover sub_line_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          HOSPITAL INCOME
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="std-ta-right">
                                  RM 50.00 PER DAY / MAX 60 DAYS
                                </td>
                              </tr>
                              <tr nxTableRow>
                                <td class="sub_line">
                                  <div class="sub_cover sub_line_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          FUNERAL EXPENSES
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="std-ta-right sub_line">
                                  RM 1,000.00
                                </td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    COMPENSATION FOR ASSESSED REPAIR TIME (CART)
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  <nx-natural-language-form size="small">
                                    <span class="nx-margin-right-3xs">NO. OF DAYS :</span>
                                    <nx-formfield class="formfield_no_padding test">
                                      <nx-formfield class="formfield_no_padding test">
                                        <nx-dropdown [panelGrow]="true" [panelMaxWidth]="500" value="7">
                                          <nx-dropdown-item value="0" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="21" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="14" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="7" class="table_dropdown"></nx-dropdown-item>
                                        </nx-dropdown>
                                      </nx-formfield>
                                    </nx-formfield>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>No. of days required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                  <nx-natural-language-form size="small">
                                    <span class="nx-margin-right-3xs">AMOUNT PER DAY:</span>
                                    <nx-formfield class="formfield_no_padding test">
                                      <nx-dropdown [panelGrow]="true" value="50">
                                        <nx-dropdown-item value="0" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="200" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="100" class="table_dropdown"></nx-dropdown-item>
                                        <nx-dropdown-item value="50" class="table_dropdown"></nx-dropdown-item>
                                      </nx-dropdown>
                                    </nx-formfield>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>Amount per day required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">75.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    GAS CONVERSION KIT AND TANK
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  <nx-natural-language-form size="small">
                                    SUM INSURED (RM) :
                                    <nx-word>
                                      <input nxInput value="100.00" />
                                    </nx-word>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>Amount required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">7.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    SEPARATE COVER FOR ACCESSORIES FIXED TO YOUR
                                    CAR/ MOTORCYCLE
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  <nx-natural-language-form size="small">
                                    SUM INSURED (RM) :
                                    <nx-word>
                                      <input nxInput value="100.00" />
                                    </nx-word>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>Amount required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">30.00</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    CAR BREAK-IN/ROBBERY
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                  <nx-natural-language-form size="small" class="color_disabled">
                                    SUM INSURED (RM) :
                                    <nx-word>
                                      <input nxInput value="500.00" disabled class="color_disabled" />
                                    </nx-word>
                                    <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                      <strong>Amount required.</strong>
                                    </nx-error>
                                  </nx-natural-language-form>
                                </td>
                                <td nxTableCell class="std-ta-right">3.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- <div class="table_scrollbar nx-margin-top-2m">
                        <div #tableDesc class="nx-margin-bottom-xs">
                          <span nxCopytext="small">Swipe to view more</span>
                        </div>

                        <div class="scrollbar-wrapper">
                          <div class="wmd-view-topscroll" #scrolldiv1>
                            <div class="scroll-div1"></div>
                          </div>
                        </div>
                        <div class="wmd-view">
                          <div class="scroll-div2">
                            <table nxTable class="more_extra_cover">
                              <tbody>
                                <tr nxTableRow>
                                  <th nxHeaderCell>
                                    <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                      <div class="nx-font-weight-bold">
                                        Extra Cover
                                      </div>
                                    </nx-checkbox>
                                  </th>
                                  <th nxHeaderCell></th>
                                  <th nxHeaderCell>Premium (RM)</th>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      MOTOR ROAD WARRIOR
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell>
                                    <nx-natural-language-form size="small">
                                      <span class="nx-margin-right-3xs">PLAN / UNIT : </span>
                                      <nx-formfield class="formfield_no_padding">
                                        <nx-dropdown [panelGrow]="true" value="1 UNIT">
                                          <nx-dropdown-item value="1 UNIT" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="2 UNIT" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="3 UNIT" class="table_dropdown"></nx-dropdown-item>
                                        </nx-dropdown>
                                      </nx-formfield>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>Plan required</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    20.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVERS
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell></td>
                                  <td nxTableCell class="std-ta-right">
                                    20.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      COVER FOR WINDSCREENS, WINDOWS AND SUNROOF
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    SUM INSURED (RM) : 500.00
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    60.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      INCLUSION OF SPECIAL PERILS
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    80.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      MOTOR ENHANCED ROAD WARRIOR
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                      <span class="nx-margin-right-3xs">PLAN / UNIT : </span>
                                      <nx-formfield class="formfield_no_padding">
                                        <nx-dropdown [panelGrow]="true" value="PLAN A">
                                          <nx-dropdown-item value="PLAN A" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="PLAN B" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="PLAN C" class="table_dropdown"></nx-dropdown-item>
                                        </nx-dropdown>
                                      </nx-formfield>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>Plan required</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    75.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      OPTIONAL CAR REPLACEMENT
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    75.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      WAIVER OF BETTERMENT CONTRIBUTION
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    230.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      KEY CARE
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                      SUM INSURED (RM) :
                                      <nx-word>
                                        <input nxInput value="1,000.00" [formControl]="amountFormControl" />
                                      </nx-word>
                                      <nx-error nxFormfieldError>
                                        <strong>Amount Required.</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    35.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      DRIVER AND PASSENGERS SHIELD
                                    </nx-checkbox>

                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                      <span class="nx-margin-right-3xs">PLAN / UNIT : </span>
                                      <nx-formfield class="formfield_no_padding test">
                                        <nx-dropdown [panelGrow]="true" value="PLAN A">
                                          <nx-dropdown-item value="PLAN A" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="PLAN B" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="PLAN C" class="table_dropdown"></nx-dropdown-item>
                                        </nx-dropdown>
                                      </nx-formfield>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>Plan required.</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                  </td>
                                  <td nxTableCell class="std-ta-right">30.00</td>
                                </tr>
                                <tr nxTableRow>
                                  <td>
                                    <div class="sub_cover">
                                      <ul nxList>
                                        <li nxListIcon="minus" size="s">
                                          <p nxCopytext="medium">DEATH/PERMANENT DISABLEMENT OCCURRING WITHIN 180 DAYS OF THE ACCIDENT </p>
                                        </li>

                                      </ul>
                                    </div>
                                  </td>
                                  <td class="std-ta-right">
                                    <p nxCopytext="medium">SUM INSURED (RM) : 25,000 PER PERSON </p>
                                  </td>
                                  <td class="std-ta-right"></td>
                                </tr>
                                <tr nxTableRow>
                                  <td>
                                    <div class="sub_cover sub_line_cover">
                                      <ul nxList>
                                        <li nxListIcon="minus" size="s">
                                          <p nxCopytext="medium">HOSPITAL INCOME</p>
                                        </li>
                                      </ul>
                                    </div>

                                  </td>
                                  <td class="std-ta-right">
                                    RM 50.00 PER DAY / MAX 60 DAYS
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td class="sub_line">
                                    <div class="sub_cover sub_line_cover">
                                      <ul nxList>
                                        <li nxListIcon="minus" size="s">
                                          <p nxCopytext="medium">FUNERAL EXPENSES</p>
                                        </li>
                                      </ul>
                                    </div>

                                  </td>
                                  <td class="std-ta-right sub_line">
                                    RM 1,000.00
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      COMPENSATION FOR ASSESSED REPAIR TIME (CART)
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                      <span class="nx-margin-right-3xs">NO. OF DAYS :</span>
                                      <nx-formfield class="formfield_no_padding test">
                                        <nx-formfield class="formfield_no_padding test">
                                          <nx-dropdown [panelGrow]="true" value="7">
                                            <nx-dropdown-item value="0" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="21" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="14" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="7" class="table_dropdown"></nx-dropdown-item>
                                          </nx-dropdown>
                                        </nx-formfield>
                                      </nx-formfield>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>No. of days required</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                    <nx-natural-language-form size="small">
                                      <span class="nx-margin-right-3xs">AMOUNT PER DAY:</span>
                                      <nx-formfield class="formfield_no_padding test">
                                        <nx-dropdown [panelGrow]="true" value="50">
                                          <nx-dropdown-item value="0" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="200" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="100" class="table_dropdown"></nx-dropdown-item>
                                          <nx-dropdown-item value="50" class="table_dropdown"></nx-dropdown-item>
                                        </nx-dropdown>
                                      </nx-formfield>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>Amount per day required</strong>
                                      </nx-error>
                                    </nx-natural-language-form>


                                  </td>
                                  <td nxTableCell class="std-ta-right">75.00</td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      GAS CONVERSION KIT AND TANK
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                      SUM INSURED (RM) :
                                      <nx-word>
                                        <input nxInput value="100.00" />
                                      </nx-word>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>Amount required</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                  </td>
                                  <td nxTableCell class="std-ta-right">7.00</td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      SEPARATE COVER FOR ACCESSORIES FIXED TO YOUR CAR/ MOTORCYCLE
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small">
                                      SUM INSURED (RM) :
                                      <nx-word>
                                        <input nxInput value="100.00" />
                                      </nx-word>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>Amount required</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                  </td>
                                  <td nxTableCell class="std-ta-right">30.00</td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                      CAR BREAK-IN/ROBBERY
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right">
                                    <nx-natural-language-form size="small" class="color_disabled">
                                      SUM INSURED (RM) :
                                      <nx-word>
                                        <input nxInput value="500.00" disabled class="color_disabled" />
                                      </nx-word>
                                      <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                        <strong>Amount required</strong>
                                      </nx-error>
                                    </nx-natural-language-form>
                                  </td>
                                  <td nxTableCell class="std-ta-right">3.00</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="scrollbar-wrapper">
                          <div class="wmd-view-topscroll" #scrolldiv2>
                            <div class="scroll-div1"></div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12">
                      <button nxPlainButton type="button" class="nx-margin-right-m" (click)="comprehensive()">
                        <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                        ADD MORE
                      </button>
                      <ng-template #template>
                        <div class="left-content">
                          <h3 nxHeadline="subsection-medium" class="nx-margin-0">
                            Exta Cover
                          </h3>
                          <div class="scroll-container nx-margin-top-2m">
                            <table nxTable zebra>
                              <thead>
                                <tr nxTableRow>
                                  <th nxHeaderCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">Extra Cover</span>
                                    </nx-checkbox>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">PEER-TO-PEER (P2P)</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">EXTENSION OF COVER TO THE KINGDOM OF
                                        THAILAND</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">PRIVATE HIRE CAR</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">LEGAL LIABILITY OF PASSENGERS FOR
                                        NEGLIGENT ACTS</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">DRIVER AND PASSENGERS SHIELD</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">CAR BREAK-IN/ROBBERY</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
                            <div nxRow>
                              <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
                                <nx-natural-language-form size="small" class="pagination_form">
                                  <span nxCopytext="normal">Show</span>
                                  <nx-word>
                                    <nx-dropdown value="5" [panelGrow]="true" [panelMaxWidth]="500">
                                      <nx-dropdown-item value="5">5</nx-dropdown-item>
                                      <nx-dropdown-item value="10">10</nx-dropdown-item>
                                      <nx-dropdown-item value="20">20</nx-dropdown-item>
                                      <nx-dropdown-item value="30">30</nx-dropdown-item>
                                      <nx-dropdown-item value="40">40</nx-dropdown-item>
                                      <nx-dropdown-item value="50">50</nx-dropdown-item>
                                    </nx-dropdown>
                                  </nx-word>
                                  <span nxCopytext="normal">results per page</span>
                                </nx-natural-language-form>
                              </div>

                              <div
                                class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                              </div>
                              <div nxCol="12,12,6">
                                <nx-pagination [count]="20" [page]="2" [perPage]="2" type="advanced"
                                  ariaLabel="Advanced example pagination" class="pagination_position">
                                </nx-pagination>
                              </div>
                            </div>
                          </div>
                          <div class="two-button-container nx-margin-top-m">
                            <button nxButton="secondary" (click)="closeTemplateDialog()"
                              class="nx-margin-right-s nx-margin-top-m">
                              Cancel
                            </button>
                            <button nxButton (click)="closeTemplateDialog()" class="nx-margin-top-m">
                              Proceed
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- third party -->
              <ng-container *ngIf="show_tp">
                <div nxLayout="grid" class="grid_remove_padding coverage">
                  <div nxRow class="nx-margin-top-2m">
                    <div nxCol="12">
                      <h2 class="nx-margin-0">Extra Coverage</h2>
                    </div>
                  </div>

                  <div nxRow class="nx-margin-top-2m">
                    <div nxCol="12">
                      <div>
                        <app-external-scrollbar targetSyncScroll="4"
                          [targetBaseWidth]="tableHolder4"></app-external-scrollbar>
                        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder4
                          syncScroll="4">
                          <table nxTable class="more_extra_cover">
                            <tbody>
                              <tr nxTableRow>
                                <th nxHeaderCell>
                                  <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                    <div class="nx-font-weight-bold">
                                      Extra Cover
                                    </div>
                                  </nx-checkbox>
                                </th>
                                <th nxHeaderCell></th>
                                <th nxHeaderCell>Premium (RM)</th>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    LEGAL LIABILITY OF PASSENGERS FOR NEGLIGENT
                                    ACTS
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">7.50</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    LEGAL LIABILITY TO PASSENGERS
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">33.75</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    EXTENSION OF COVER TO THE KINGDOM OF
                                    THAILAND (APPLICABLE TO PRIVATE CAR THIRD
                                    PARTY POLICY ONLY)
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">101.25</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    TUITION PURPOSES
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">67.50</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    EXTENSION OF COVER TO KALIMANTAN
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">30.80</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    PRIVATELY OWNED CARS USED FOR TUTION
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">67.50</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- <nx-swipebar label="Swipe to see more">
                        <table nxTable class="more_extra_cover">
                          <tbody>
                            <tr nxTableRow>
                              <th nxHeaderCell>
                                <nx-checkbox
                                  [checked]="true"
                                  class="nx-font-weight-semibold"
                                >
                                  <div class="nx-font-weight-bold">
                                    Extra Cover
                                  </div>
                                </nx-checkbox>
                              </th>
                              <th nxHeaderCell></th>
                              <th nxHeaderCell>Premium (RM)</th>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  LEGAL LIABILITY OF PASSENGERS FOR NEGLIGENT
                                  ACTS
                                </nx-checkbox>
                              </td>
                              <td nxTableCell></td>
                              <td nxTableCell class="std-ta-right">7.50</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  LEGAL LIABILITY TO PASSENGERS
                                </nx-checkbox>
                              </td>
                              <td nxTableCell class="std-ta-right"></td>
                              <td nxTableCell class="std-ta-right">33.75</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  EXTENSION OF COVER TO THE KINGDOM OF THAILAND
                                  (APPLICABLE TO PRIVATE CAR THIRD PARTY POLICY
                                  ONLY)
                                </nx-checkbox>
                              </td>
                              <td nxTableCell class="std-ta-right"></td>
                              <td nxTableCell class="std-ta-right">101.25</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  TUITION PURPOSES
                                </nx-checkbox>
                              </td>
                              <td nxTableCell class="std-ta-right"></td>
                              <td nxTableCell class="std-ta-right">67.50</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  EXTENSION OF COVER TO KALIMANTAN
                                </nx-checkbox>
                              </td>
                              <td nxTableCell class="std-ta-right"></td>
                              <td nxTableCell class="std-ta-right">30.80</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  PRIVATELY OWNED CARS USED FOR TUTION
                                </nx-checkbox>
                              </td>
                              <td nxTableCell class="std-ta-right"></td>
                              <td nxTableCell class="std-ta-right">67.50</td>
                            </tr>
                          </tbody>
                        </table>
                      </nx-swipebar> -->
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-2m">
                    <div nxCol="12">
                      <button nxPlainButton type="button" class="nx-margin-right-m" (click)="tp()">
                        <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                        ADD MORE
                      </button>
                      <ng-template #template>
                        <div class="left-content">
                          <h3 nxHeadline="subsection-medium" class="nx-margin-0">
                            Exta Cover
                          </h3>
                          <div class="scroll-container nx-margin-top-2m">
                            <table nxTable zebra>
                              <thead>
                                <tr nxTableRow>
                                  <th nxHeaderCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">Extra Cover</span>
                                    </nx-checkbox>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">TUITION PURPOSES</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">EXTENSION OF COVER TO KALIMANTAN</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">PRIVATELY OWNED CARS USED FOR
                                        TUTION</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="two-button-container nx-margin-top-l">
                            <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s">
                              Cancel
                            </button>
                            <button nxButton (click)="closeTemplateDialog()" class="m_margin_top_20">
                              Proceed
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- third party, Fire and Theft -->
              <ng-container *ngIf="show_tpft">
                <div nxLayout="grid" class="grid_remove_padding coverage">
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12">
                      <h4 class="nx-margin-0">Extra Coverage</h4>
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12" class="nx-font-weight-semibold">
                      These coverage(s) / service(s) are provided at no extra
                      cost.
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12">
                      <table class="extra_package">
                        <tbody>
                          <tr nxTableRow>
                            <td>
                              <nx-checkbox [disabled]="true" [checked]="true" labelSize="small">
                                <div class="nx-font-weight-bold">
                                  RAHMAH PACKAGE
                                </div>
                              </nx-checkbox>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td class="sub_cover">
                              - COMPASSIONATE FLOOD COVER
                            </td>
                            <td class="si">SUM INSURED (RM) : 3,000.00</td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td class="sub_cover">
                              - DEATH/PERMANENT DISABLEMENT
                            </td>
                            <td class="si">SUM INSURED (RM) : 5,000.00</td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td class="sub_cover">- HOSPITAL INCOME</td>
                            <td class="si">RM 50.00 PER DAY / MAX 60 DAYS</td>
                            <td></td>
                          </tr>
                          <tr nxTableRow>
                            <td>
                              <nx-checkbox [disabled]="true" [checked]="true">
                                <div class="nx-font-weight-bold">
                                  24-HOUR EMERGENCY TOWING (UP TO 150KM)
                                </div>
                              </nx-checkbox>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="nx-margin-top-2m">
                        <app-external-scrollbar targetSyncScroll="5"
                          [targetBaseWidth]="tableHolder5"></app-external-scrollbar>
                        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder5
                          syncScroll="5">
                          <table nxTable class="more_extra_cover">
                            <tbody>
                              <tr nxTableRow>
                                <th nxHeaderCell>
                                  <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                    <div class="nx-font-weight-bold">
                                      Extra Cover
                                    </div>
                                  </nx-checkbox>
                                </th>
                                <th nxHeaderCell></th>
                                <th nxHeaderCell>Premium (RM)</th>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    LEGAL LIABILITY OF PASSENGERS FOR NEGLIGENT
                                    ACTS
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">7.50</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    LEGAL LIABILITY TO PASSENGERS
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">33.75</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    EXTENSION OF COVER TO THE KINGDOM OF
                                    THAILAND
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">197.43</td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                    TUITION PURPOSES
                                  </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">493.58</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- <nx-swipebar
                        label="Swipe to see more"
                        class="nx-margin-top-2m"
                      >
                        <table nxTable class="more_extra_cover">
                          <tbody>
                            <tr nxTableRow>
                              <th nxHeaderCell>
                                <nx-checkbox
                                  [checked]="true"
                                  class="nx-font-weight-semibold"
                                >
                                  <div class="nx-font-weight-bold">
                                    Extra Cover
                                  </div>
                                </nx-checkbox>
                              </th>
                              <th nxHeaderCell></th>
                              <th nxHeaderCell>Premium (RM)</th>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  LEGAL LIABILITY OF PASSENGERS FOR NEGLIGENT
                                  ACTS
                                </nx-checkbox>
                              </td>
                              <td nxTableCell></td>
                              <td nxTableCell class="std-ta-right">7.50</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  LEGAL LIABILITY TO PASSENGERS
                                </nx-checkbox>
                              </td>
                              <td nxTableCell></td>
                              <td nxTableCell class="std-ta-right">33.75</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  EXTENSION OF COVER TO THE KINGDOM OF THAILAND
                                </nx-checkbox>
                              </td>
                              <td nxTableCell class="std-ta-right"></td>
                              <td nxTableCell class="std-ta-right">197.43</td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>
                                <nx-checkbox
                                  [checked]="true"
                                  labelSize="small"
                                  class="nx-font-weight-semibold"
                                >
                                  TUITION PURPOSES
                                </nx-checkbox>
                              </td>
                              <td nxTableCell class="std-ta-right"></td>
                              <td nxTableCell class="std-ta-right">493.58</td>
                            </tr>
                          </tbody>
                        </table>
                      </nx-swipebar> -->
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12">
                      <button nxPlainButton type="button" class="nx-margin-right-m" (click)="tpft()">
                        <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                        ADD MORE
                      </button>
                      <ng-template #template>
                        <div class="left-content">
                          <h3 nxHeadline="subsection-medium" class="nx-margin-0">
                            Exta Cover
                          </h3>
                          <div class="nx-margin-top-2m scroll-container">
                            <table nxTable zebra>
                              <thead>
                                <tr nxTableRow>
                                  <th nxHeaderCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">Extra Cover</span>
                                    </nx-checkbox>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">EXTENSION OF COVER TO KALIMANTAN</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">EXTENSION OF COVER TO THE KINGDOM OF
                                        THAILAND</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox>
                                      <span nxCopytext="medium">TUITION PURPOSES</span>
                                    </nx-checkbox>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="two-button-container nx-margin-top-l">
                            <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s">
                              Cancel
                            </button>
                            <button nxButton (click)="closeTemplateDialog()" class="m_margin_top_20">
                              Proceed
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                '
              </ng-container>
              <!-- end Extra Coverage -->

              <!-- e-Hailing Driver Name -->
              <ng-container>
                <div nxLayout="grid" class="grid_remove_padding coverage">
                  <div nxRow>
                    <div nxCol="12">
                      <hr class="nx-margin-y-2m" />
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="12">
                      <h4 class="nx-margin-0">e-Hailing Driver Name</h4>
                    </div>
                  </div>

                  <div nxRow>
                    <div nxCol="12" class="driver_list">
                      <!-- <div class="nx-margin-top-m">
                        <app-external-scrollbar
                          targetSyncScroll="1"
                          [targetBaseWidth]="tableHolder"
                        ></app-external-scrollbar>
                        <div
                          class="table-holder table_content_scroll only-this-horizon-scrollbar"
                          #tableHolder
                          syncScroll="1"
                        >
                        <table nxTable>
                          <thead>
                            <tr nxTableRow>
                              <th nxHeaderCell></th>
                              <th nxHeaderCell>Driver Name</th>
                              <th nxHeaderCell>Driver ID</th>
                              <th nxHeaderCell></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr nxTableRow>
                              <td nxTableCell>2.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="PHILIP WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_id"
                                >
                                  <input nxInput value="891111111111" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <button
                                  nxPlainButton
                                  type="button"
                                  class="nx-margin-left-m"
                                >
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>3.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="ALICE WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_id"
                                >
                                  <input nxInput value="201111111111" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <button
                                  nxPlainButton
                                  type="button"
                                  class="nx-margin-left-m"
                                >
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div> -->

                      <nx-swipebar label="Swipe to see more" class="nx-margin-top-m">
                        <table nxTable>
                          <thead>
                            <tr nxTableRow>
                              <th nxHeaderCell></th>
                              <th nxHeaderCell>Driver Name</th>
                              <th nxHeaderCell>Driver ID</th>
                              <th nxHeaderCell></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr nxTableRow>
                              <td nxTableCell>1.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="PHILIP WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline" class="driver_id">
                                  <input nxInput value="891111111111" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <button nxPlainButton type="button" class="nx-margin-left-m">
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>2.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="ALICE WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline" class="driver_id">
                                  <input nxInput value="201111111111" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <button nxPlainButton type="button" class="nx-margin-left-m">
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <button nxPlainButton (click)="openPopup('ehailingdriver')">
                          Minimum 1 e-Hailing popup
                        </button>
                      </nx-swipebar>
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12">
                      <button nxPlainButton type="button" class="nx-margin-right-m" [disabled]="true">
                        <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>ADD DRIVER
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Thailand Trip -->
              <ng-container>
                <div nxLayout="grid" class="grid_remove_padding coverage">
                  <div nxRow>
                    <div nxCol="12">
                      <hr class="nx-margin-y-m" />
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="12">
                      <h4 class="nx-margin-0">Thailand Trip</h4>
                    </div>
                  </div>
                  <div nxRow class="nx-margin-top-m">
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="Start Date">
                        <input nxDatefield nxInput [datepicker]="myDatepicker7"
                          [formControl]="thailandstartdateFormControl" />
                        <span nxFormfieldHint>MM/DD/YYYY</span>

                        <nx-datepicker-toggle [for]="myDatepicker7" nxFormfieldSuffix></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker7></nx-datepicker>

                        <nx-error nxFormfieldError><strong>Please enter Thailand Trip Start Date.</strong>
                        </nx-error>
                      </nx-formfield>

                      <button nxPlainButton (click)="openPopup('notallowed')" class="nx-margin-bottom-m">
                        Not allowed extra coverage popup
                      </button>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="End Date">
                        <input nxDatefield nxInput [datepicker]="myDatepicker8"
                          [formControl]="thailandenddateFormControl" />
                        <span nxFormfieldHint>MM/DD/YYYY</span>

                        <nx-datepicker-toggle [for]="myDatepicker8" nxFormfieldSuffix></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker8></nx-datepicker>

                        <nx-error nxFormfieldError>
                          <strong>Please enter Thailand Trip End Date.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                  </div>
                </div>
              </ng-container>
            </nx-expansion-panel>
          </nx-accordion>

          <!-- Coverage Accordion Driver Name -->
          <nx-accordion variant="light">
            <nx-expansion-panel class="accordion_remove_btm_space">
              <nx-expansion-panel-header>
                <nx-expansion-panel-title class="accordion_edit">
                  Coverage
                </nx-expansion-panel-title>
              </nx-expansion-panel-header>

              <!--  Driver Name -->
              <ng-container>
                <div nxLayout="grid" class="grid_remove_padding coverage">
                  <div nxRow>
                    <div nxCol="12" class="driver_list">
                      <!-- <div>
                        <app-external-scrollbar
                          targetSyncScroll="1"
                          [targetBaseWidth]="tableHolder"
                        ></app-external-scrollbar>
                        <div
                          class="table-holder table_content_scroll only-this-horizon-scrollbar"
                          #tableHolder
                          syncScroll="1"
                        >
                        <table nxTable>
                          <thead>
                            <tr nxTableRow>
                              <th nxHeaderCell></th>
                              <th nxHeaderCell>Driver Name</th>
                              <th nxHeaderCell>Driver ID</th>
                              <th nxHeaderCell colspan="2">Driver Age</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr nxTableRow>
                              <td nxTableCell>1.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input
                                    nxInput
                                    disabled
                                    value="THE POLICYHOLDER"
                                  />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_id"
                                >
                                  <input
                                    nxInput
                                    disabled
                                    value="880914145277"
                                  />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_age"
                                >
                                  <input nxInput disabled value="36" />
                                </nx-formfield>
                              </td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>2.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="ALICE WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_id"
                                >
                                  <input nxInput value="200914145277" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell class="container_flex_center">
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_age"
                                >
                                  <input nxInput value="23" />
                                </nx-formfield>
                                <button
                                  nxPlainButton
                                  type="button"
                                  class="nx-margin-left-m"
                                >
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>3.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="PHILIP WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_id"
                                >
                                  <input nxInput value="200914145277" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell class="container_flex_center">
                                <nx-formfield
                                  appearance="outline"
                                  class="driver_age"
                                >
                                  <input nxInput value="23" />
                                </nx-formfield>
                                <button
                                  nxPlainButton
                                  type="button"
                                  class="nx-margin-left-m"
                                >
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div> -->
                      <nx-swipebar label="Swipe to see more">
                        <table nxTable>
                          <thead>
                            <tr nxTableRow>
                              <th nxHeaderCell></th>
                              <th nxHeaderCell>Driver Name</th>
                              <th nxHeaderCell>Driver ID</th>
                              <th nxHeaderCell colspan="2">Driver Age</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr nxTableRow>
                              <td nxTableCell>1.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput disabled value="THE POLICYHOLDER" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline" class="driver_id">
                                  <input nxInput disabled value="880914145277" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline" class="driver_age">
                                  <input nxInput disabled value="36" />
                                </nx-formfield>
                              </td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>2.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="ALICE WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline" class="driver_id">
                                  <input nxInput value="200914145277" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell class="container_flex_center">
                                <nx-formfield appearance="outline" class="driver_age">
                                  <input nxInput value="23" />
                                </nx-formfield>
                                <button nxPlainButton type="button" class="nx-margin-left-m">
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                            <tr nxTableRow>
                              <td nxTableCell>3.</td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline">
                                  <input nxInput value="PHILIP WONG" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell>
                                <nx-formfield appearance="outline" class="driver_id">
                                  <input nxInput value="200914145277" />
                                </nx-formfield>
                              </td>
                              <td nxTableCell class="container_flex_center">
                                <nx-formfield appearance="outline" class="driver_age">
                                  <input nxInput value="23" />
                                </nx-formfield>
                                <button nxPlainButton type="button" class="nx-margin-left-m">
                                  <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </nx-swipebar>
                    </div>
                  </div>

                  <div nxRow class="nx-margin-y-m">
                    <div nxCol="12">
                      <button nxPlainButton type="button" class="nx-margin-right-m" [disabled]="true">
                        <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>ADD DRIVER
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </nx-expansion-panel>
          </nx-accordion>

          <!-- Coverage Accordion NCD -->
          <nx-accordion variant="light">
            <nx-expansion-panel class="accordion_remove_btm_space">
              <nx-expansion-panel-header>
                <nx-expansion-panel-title class="accordion_edit">
                  Coverage
                </nx-expansion-panel-title>
              </nx-expansion-panel-header>

              <!-- Thailand Trip -->
              <ng-container>
                <div nxLayout="grid" class="grid_remove_padding coverage">
                  <div nxRow>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="No Claim Discount From">
                        <nx-dropdown value="AIA Berhad" [formControl]="noclaimdiscountFormControl">
                          <nx-dropdown-item value="AIA Berhad"></nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError>
                          <strong>No Claim Discount From required.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="No Claim Discount Vehicle No.">
                        <input nxInput type="text" value="WA8012L" [formControl]="noclaimdiscountvehicleFormControl" />
                        <nx-error nxFormfieldError>
                          <strong>No Claim Discount Vehicle No. required.</strong>
                        </nx-error>
                        <nx-error nxFormfieldError>
                          <strong>Invalid Vehicle No.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="No Claim Discount (%)">
                        <nx-dropdown value="25.00">
                          <nx-dropdown-item value="25.00"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="NCD Effective Date">
                        <input nxDatefield nxInput [datepicker]="myDatepicker9" />
                        <span nxFormfieldHint>MM/DD/YYYY</span>

                        <nx-datepicker-toggle [for]="myDatepicker9" nxFormfieldSuffix></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker9></nx-datepicker>
                      </nx-formfield>
                    </div>
                  </div>
                </div>
              </ng-container>
            </nx-expansion-panel>
          </nx-accordion>

          <!-- Coverage - Sum insured -->
          <nx-accordion variant="light">
            <nx-expansion-panel class="accordion_remove_btm_space">
              <nx-expansion-panel-header>
                <nx-expansion-panel-title class="accordion_edit">
                  Coverage
                </nx-expansion-panel-title>
              </nx-expansion-panel-header>

              <ng-container>
                <div nxLayout="grid" class="grid_remove_padding coverage nx-margin-bottom-m">
                  <div nxRow class="blue_panel">
                    <div nxCol="12" class="agreed_value">
                      <div nxLayout="grid" class="grid_remove_padding">
                        <div nxRow rowAlignItems="start,start">
                          <div nxCol="12,12,6">
                            <nx-formfield label="Vehicle Sum Insured (RM)">
                              <input nxInput [formControl]="vehiclesumFormControl" />
                              <span nxFormfieldHint nxCopytext="small" class="std-ta-left">
                                <i>(Recommended Sum Insured Range : 30,000 -
                                  40,000)</i>
                              </span>

                              <nx-error nxFormfieldError>
                                <strong>Vehicle Sum Insured required</strong>
                              </nx-error>

                              <nx-error nxFormfieldError>
                                <strong>Sum insured must be at least RM
                                  10,000.00.</strong>
                              </nx-error>
                              <nx-error nxFormfieldError>
                                <strong>Sum insured must be at least RM
                                  4,000.00.</strong>
                              </nx-error>
                              <nx-error nxFormfieldError>
                                <strong>Sum insured must be at least RM 1,000.00.
                                </strong>
                              </nx-error>
                              <nx-error nxFormfieldError>
                                <strong>Underinsurance applies </strong>
                              </nx-error>
                            </nx-formfield>
                            <p nxCopytext="medium">
                              Previous Sum Insured (RM): 4,000
                            </p>
                          </div>
                          <div nxCol="12,12,6" class="m_padding_top_10">
                            <nx-checkbox class="nx-margin-bottom-xs">
                              Recommended Value
                            </nx-checkbox>
                            <nx-checkbox class="nx-margin-bottom-xs">
                              Agreed Value
                            </nx-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </nx-expansion-panel>
          </nx-accordion>

          <!-- customer partner -->
          <nx-accordion variant="light">
            <nx-expansion-panel class="accordion_btm_space">
              <nx-expansion-panel-header>
                <nx-expansion-panel-title class="accordion_edit">
                  Customer Partner
                </nx-expansion-panel-title>
              </nx-expansion-panel-header>

              <ng-container>
                <div nxLayout="grid nopadding">
                  <div>
                    <div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <div nxLayout="grid nopadding" class="grid_remove_padding summary_column_space">
                            <table nxTable cellpadding="0" class="summary_table">
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <span nxCopytext="normal">Nationality</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                  <span nxCopytext="normal">MALAYSIA</span>
                                </td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <span nxCopytext="normal">NRIC</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                  <span nxCopytext="normal">880914145277</span>
                                </td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <span nxCopytext="normal">Old IC No.</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                  <span nxCopytext="normal">A307</span>
                                </td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <span nxCopytext="normal">Date of Birth</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                  <span nxCopytext="normal">14/09/1988</span>
                                </td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>
                                  <span nxCopytext="normal">Age</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                  <span nxCopytext="normal">35</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>

                      <hr class="hr" class="nx-margin-top-2m" />

                      <div nxRow>
                        <div nxCol="12">
                          <h4 *ngIf="individual">Personal Information</h4>
                          <h4 *ngIf="company">Company Information</h4>
                        </div>
                      </div>

                      <div nxRow *ngIf="company">
                        <div nxCol="12,12,6,6,6,6,6">
                          <nx-formfield label="ID Type 2">
                            <nx-dropdown value="NRIC" [formControl]="idtype2FormControl">
                              <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                            </nx-dropdown>
                            <nx-error nxFormfieldError>
                              <strong>Please enter ID Type 2.</strong>
                            </nx-error>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6,6,6,6,6">
                          <nx-formfield label="ID No. 2">
                            <input nxInput [formControl]="idno2FormControl" />
                            <nx-error nxFormfieldError>
                              <strong>Please enter ID No. 2.</strong>
                            </nx-error>
                            <nx-error nxFormfieldError>
                              <strong>Wrong Format.</strong>
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12,12,6,6,6,6,6">
                          <nx-formfield label="Tax Identification No. (TIN)">
                            <input nxInput value="OG12345678101" [formControl]="taxFormControl" />
                            <nx-error nxFormfieldError>
                              <strong>Please enter Tax Identification No.
                                (TIN).</strong>
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>

                      <div *ngIf="company">
                        <div nxRow>
                          <div nxCol="12">
                            <span nxCopytext="normal">SST Registered?</span><br />
                            <div class="radio-group nx-margin-top-xs">
                              <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                              <nx-radio name="group1" class="nx-margin-left-2m">No</nx-radio>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="sst">
                          <div nxRow class="nx-margin-top-xs">
                            <div nxCol="12,12,6,6,6,6,6">
                              <nx-formfield label="SST No.">
                                <input nxInput [formControl]="sstFormControl" />
                                <nx-error nxFormfieldError>
                                  <strong>Please enter SST No.</strong>
                                </nx-error>
                                <nx-error nxFormfieldError>
                                  <strong>The Service Tax Registration No. cannot be
                                    less or more than 17.</strong>
                                </nx-error>
                              </nx-formfield>
                            </div>
                          </div>
                          <div nxRow>
                            <div nxCol="12,12,6,6,6,6,6">
                              <nx-formfield label="SST Effective Date">
                                <input nxDatefield nxInput [datepicker]="myDatepicker1" />
                                <span nxFormfieldHint>MM/DD/YYYY</span>
                                <nx-datepicker-toggle [for]="myDatepicker1" nxFormfieldSuffix></nx-datepicker-toggle>
                                <nx-datepicker #myDatepicker1></nx-datepicker>
                              </nx-formfield>
                            </div>
                            <div nxCol="12,12,6,6,6,6,6">
                              <nx-formfield label="SST De-Registration Date">
                                <input nxDatefield nxInput [datepicker]="myDatepicker2" />
                                <span nxFormfieldHint>MM/DD/YYYY</span>
                                <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
                                <nx-datepicker #myDatepicker2></nx-datepicker>
                              </nx-formfield>
                            </div>
                          </div>
                          <div nxRow>
                            <div nxCol="12,12,6,6,6,6,6">
                              <nx-formfield label="SST Resident Status">
                                <nx-dropdown>
                                  <nx-dropdown-item value="Resident"></nx-dropdown-item>
                                </nx-dropdown>
                              </nx-formfield>
                            </div>
                            <div nxCol="12,12,6,6,6,6,6">
                              <nx-formfield label="SST Country">
                                <nx-dropdown>
                                  <nx-dropdown-item value="Malaysia"></nx-dropdown-item>
                                </nx-dropdown>
                              </nx-formfield>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12">
                          <hr class="hr" />
                          <h4>Contact Details</h4>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12,12,6,6,6,6,6">
                          <div nxLayout="grid nopadding">
                            <div class="cp__customer-detail-phone">
                              <nx-formfield label="Code" class="code">
                                <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                                  <nx-dropdown-item value="6010"></nx-dropdown-item>
                                  <nx-dropdown-item value="6011"></nx-dropdown-item>
                                  <nx-dropdown-item value="6012"></nx-dropdown-item>
                                </nx-dropdown>
                              </nx-formfield>
                              <nx-formfield label="Mobile No." class="info nx-margin-left-s">
                                <input nxInput inputmode="numeric" required numberOnly minlength="7" maxlength="8"
                                  autocomplete="off" [formControl]="mobilenoFormControl" />
                                <nx-error nxFormfieldError>
                                  <strong>Please enter Mobile No. or Phone
                                    No.</strong>
                                </nx-error>
                                <nx-error nxFormfieldError>
                                  <strong>Please enter the Mobile No.</strong>
                                </nx-error>
                                <nx-error nxFormfieldError>
                                  <strong>Please enter the number between 7 and 8
                                    characters long.</strong>
                                </nx-error>
                              </nx-formfield>
                            </div>
                          </div>
                        </div>

                        <div nxCol="12,12,6,6,6,6,6">
                          <div nxLayout="grid nopadding">
                            <div class="cp__customer-detail-phone">
                              <nx-formfield label="Code" class="code">
                                <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                                  <nx-dropdown-item value="601"></nx-dropdown-item>
                                  <nx-dropdown-item value="603"></nx-dropdown-item>
                                  <nx-dropdown-item value="603"></nx-dropdown-item>
                                </nx-dropdown>
                              </nx-formfield>
                              <nx-formfield label="Fixed Line No." class="info nx-margin-left-s">
                                <input nxInput inputmode="numeric" required numberOnly minlength="7" maxlength="8"
                                  autocomplete="off" [formControl]="fixedlinenoFormControl" />
                                <nx-error nxFormfieldError>
                                  <strong>Please enter Mobile No. or Phone
                                    No.</strong>
                                </nx-error>
                                <nx-error nxFormfieldError>
                                  <strong>Please enter the number between 7 and 8
                                    characters long.</strong>
                                </nx-error>
                              </nx-formfield>
                            </div>
                          </div>
                        </div>
                        <div nxCol="12,12,6,6,6,6,6">
                          <div nxLayout="grid nopadding">
                            <div class="cp__customer-detail-phone">
                              <nx-formfield label="Code" class="code">
                                <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                                  <nx-dropdown-item value="601"></nx-dropdown-item>
                                  <nx-dropdown-item value="603"></nx-dropdown-item>
                                  <nx-dropdown-item value="603"></nx-dropdown-item>
                                </nx-dropdown>
                              </nx-formfield>
                              <nx-formfield label="Fax No. (Optional)" class="info nx-margin-left-s">
                                <input nxInput inputmode="numeric" required numberOnly minlength="7" maxlength="8"
                                  autocomplete="off" [formControl]="faxnoFormControl" />
                                <nx-error nxFormfieldError>
                                  <strong>Please enter the number between 7 and 8
                                    characters long.</strong>
                                </nx-error>
                              </nx-formfield>
                            </div>
                          </div>
                        </div>

                        <div nxCol="12,12,6,6,6,6,6">
                          <div nxLayout="grid nopadding">
                            <nx-formfield label="Email">
                              <input nxInput type="text" [formControl]="emailFormControl" />
                              <nx-error nxFormfieldError>
                                <strong>Please enter the Email.</strong>
                              </nx-error>
                              <nx-error nxFormfieldError>
                                <strong>Please enter a valid email address.</strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12">
                          <hr class="hr" />
                          <h4>Correspondence Details</h4>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12">
                          <nx-switcher [checked]="true">Show Map</nx-switcher>
                        </div>
                        <div nxCol="12" class="search-map nx-margin-top-m">
                          <div class="pac-card" id="pac-card">
                            <div>
                              <div id="title">Autocomplete search</div>
                              <div id="type-selector" class="pac-controls">
                                <div class="horizontal-checkboxes nx-margin-top-2xs">
                                  <span nxCopytext="normal" class="search_map_font">Search Mode :</span>
                                  <div class="radio-map">
                                    <nx-radio name="group1" labelSize="small" [checked]="true" value="address"
                                      (valueChange)="changeSearchMode($event)"><span nxCopytext="medium">By
                                        Address</span>
                                    </nx-radio>
                                    <nx-radio name="group1" labelSize="small" value="lat/long"
                                      (valueChange)="changeSearchMode($event)"><span nxCopytext="medium">By
                                        Lat/Long</span>
                                    </nx-radio>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                            <div id="pac-container">
                              <input *ngIf="search_mode_address" id="pac-input" type="text"
                                placeholder="Enter a location" />

                              <div *ngIf="!search_mode_address">
                                <div class="lat_long">
                                  <input id="pac-input" type="text" placeholder="LATITUDE" />
                                  <input id="pac-input" type="text" placeholder="LONGITUDE" />
                                </div>
                                <div class="container_flex_center align_right nx-margin-top-s">
                                  <button nxButton="primary small" type="button">
                                    Search
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.857620894033!2d101.6841407748091!3d3.132302996843152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b50e075c1d%3A0xc37d80fcda677529!2sMenara%20Allianz%20Sentral!5e0!3m2!1sen!2smy!4v1716797684318!5m2!1sen!2smy"
                            class="map" style="border: 0" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        <div nxCol="12" class="nx-margin-top-m">
                          <nx-formfield label="Unit No">
                            <input nxInput />
                          </nx-formfield>
                        </div>

                        <div nxCol="12">
                          <nx-formfield label="Address 1">
                            <input nxInput [formControl]="address1FormControl" />
                            <nx-error nxFormfieldError>
                              <strong>Please enter Address 1.</strong>
                            </nx-error>
                          </nx-formfield>
                        </div>

                        <div nxCol="12">
                          <nx-formfield label="Address 2">
                            <input nxInput />
                          </nx-formfield>
                        </div>

                        <div nxCol="12">
                          <nx-formfield label="Address 3">
                            <input nxInput />
                          </nx-formfield>
                        </div>

                        <div nxCol="12,12,6,6,6,6,6">
                          <nx-formfield label="Postcode">
                            <input nxInput [formControl]="postcodeFormControl" />
                            <nx-error nxFormfieldError>
                              <strong>Please enter a valid Postcode.</strong>
                            </nx-error>
                          </nx-formfield>
                        </div>

                        <div nxCol="12,12,6,6,6,6,6">
                          <nx-formfield label="City">
                            <nx-dropdown>
                              <nx-dropdown-item value="City"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>

                        <div nxCol="12,12,6,6,6,6,6">
                          <nx-formfield label="State">
                            <nx-dropdown>
                              <nx-dropdown-item value="State"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6,6,6,6,6">
                          <nx-formfield label="Country">
                            <nx-dropdown>
                              <nx-dropdown-item value="Country"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12">
                          <p nxCopytext="large" class="nx-font-weight-semibold">
                            Please select Address Type
                          </p>
                        </div>

                        <div nxCol="12">
                          <div nxLayout="grid nopadding" class="nx-margin-top-xs">
                            <div nxRow>
                              <div nxCol="12,12,3,3,2">
                                <nx-radio name="group3" [checked]="true">Residential</nx-radio>
                              </div>
                              <div nxCol="12,12,5,5,4" class="m_padding_top_10">
                                <nx-radio name="group3">Non-residential</nx-radio>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </nx-expansion-panel>
          </nx-accordion>
        </div>
        <p nxCopytext="medium" class="nx-margin-top-2m">
          <i>
            By clicking NEXT, you confirm that the address submitted is complete
            and accurate to the best of your knowledge.
          </i>
        </p>

        <div class="button-container nx-margin-top-50 nx-margin-bottom-3xl">
          <button nxButton="secondary" type="button">BACK</button>
          <button nxButton="primary" type="button">NEXT</button>
          <button nxButton class="nx-margin-right-xs" (click)="openPopup('popupmakemodel')">
            Make / Model
          </button>

          <button nxButton class="nx-margin-right-xs" (click)="openPopup('popuptripdate')">
            Validation Trip date
          </button>

          <button nxButton class="nx-margin-right-xs" (click)="openPopup('popupnoclaim')">
            No Claim Discount
          </button>

          <button nxButton class="nx-margin-right-xs" (click)="openPopup3('popupmobileno')">
            Mobile no. validation
          </button>

          <button nxButton class="nx-margin-right-xs" (click)="openPopup3('popupemail')">
            Email validation
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>