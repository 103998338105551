import { Injectable } from '@angular/core';
import { HttpHelperService } from '@shared/services/http-helper/http-helper.service';
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SatMotorApprovalService {

  constructor(private httpHelperService: HttpHelperService) { }

  getApprovalDetails(payload) {
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/approval-detail-mock.json`);
    return this.httpHelperService.post('/cn/approval/get', payload);
  }

  saveAdminRemarks(payload) {
    return this.httpHelperService.post('/cn/update/adminRemarks', payload);
  }

  approveCoverNote(payload) {
    return this.httpHelperService.post('/cn/approval', payload);
  }

  rejectCoverNote(payload) {
    return this.httpHelperService.post('/cn/reject/approval', payload);
  }

  calculatePremium(payload) {
    return this.httpHelperService.post('/cn/calculate/premium', payload);
    // return this.httpHelperService.getDirectUrl(`/assets/data-mock/premium-mock.json`);
  }

  // calculateEndtPremium(payload): Observable<any> {
  //   return this.httpHelperService.post('/endt/calculate/premium', payload);
  // }

  downloadPDF(payload) {
    //return this.httpHelperService.getDirectUrl("/assets/data-mock/download-pdf-mock.json");
    let path = `/printing?operator=${payload.operator}&contractId=${payload.contractId}&type=${payload.type}`;

    return window.open(this.httpHelperService.getURLString(path));
  }

  getVehicleUseLov(payload) {
    return this.httpHelperService.get("/common/vehUse", payload);
  }

  getCrossSellList(payload) {
    return this.httpHelperService.post('/customer/crossSellList', payload);
  }

  generateBlob(res) {
    const base64Data = res.file.data;
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: res.file.mime });

    return blob;
  }

  getProductPDF(payload) {
    // return this.httpHelperService.getDirectUrl("/assets/data-mock/marketing-material-doc-list-mock.json");
    return this.httpHelperService.post('/cms/folder/structuredcontent/folders-contents', payload);
  }

  saveMessageLog(payload): Observable<any> {
    return this.httpHelperService.post('/customer/crossSell/msgSent', payload);
  }

  saveEmailLog(payload): Observable<any> {
    return this.httpHelperService.post('/customer/crossSell/emailSent', payload);
  }
}
