export class ScrollToError {
    public static scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({ behavior: 'instant', block: 'center' });
        }
    }

    public static scrollToError(selector): void {
        const errorMessage = document.querySelector(selector);

        if (errorMessage) {
            this.scrollTo(errorMessage);
        }
    }
}