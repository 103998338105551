<div nxRow class="nx-margin-top-30">
  <div nxCol="12">
    <button nxPlainButton type="button" class="p-0" (click)="restartSearch()">
      <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon>SEARCH AGAIN
    </button>
  </div>
</div>

<div nxRow class="nx-margin-top-m">
  <div nxCol="12">
    <nx-error nxFormfieldError>
      <strong>{{ isCompany ? 'Company' : 'Customer' }} Not Found</strong>
    </nx-error>
  </div>
</div>

  <div nxLayout="grid nopadding" class="nx-margin-top-m grid_remove_padding summary_column_space">
    <app-customer-partner-details [cpType]="'NOT_FOUND'" [cpDetails]="dataDetails" [lov]="lov" 
    [customerPartnerForm]="customerPartnerNotFoundForm" [hideEdit]="true">
    </app-customer-partner-details>
  </div>

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <hr class="hr" class="nx-margin-bottom-m" />
      <h4 class="nx-margin-0">
        Fill out the details below to create a new customer partner
        profile.
      </h4>
    </div>
  </div>

  <app-customer-partner-form [cpType]="'NOT_FOUND'" [customerPartnerForm]="customerPartnerNotFoundForm" [lov]="lov" [dataDetails]="dataDetails"
  [eInvoiceConsent]="eInvoiceConsent" [mkagAgent]="mkagAgent" [edocConsentInd]="edocConsentInd">
  </app-customer-partner-form>

  <!-- <div nxRow>
    <div nxCol="12" class="nx-margin-top-m">
      <p nxCopytext="medium">
        <i>
          By clicking NEXT, you confirm that the address submitted is
          complete and accurate to the best of your knowledge.
        </i>
      </p>
    </div>
  </div> -->

  <div nxRow class="nx-margin-top-50 nx-margin-bottom-3xl">
    <div nxCol="12" class="button-container">
      <button nxButton="secondary" type="button" (click)="goBack()">Back</button>
      <button *ngIf="!hideSaveDraftBtn" nxButton="secondary" type="button" (click)="saveAsDraft()">SAVE AS DRAFT</button>
      <a><button nxButton="primary" type="button" (click)="nextPage()">NEXT</button></a>
    </div>
    <div nxCol="12"></div>
  </div>