<div nxLayout="grid" class="grid_remove_padding coverage">
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12">
            <h4 class="nx-margin-0">Extra Coverage</h4>
        </div>
    </div>
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12" class="nx-font-weight-semibold">
            These coverage(s) / service(s) are provided at no extra
            cost.
        </div>
    </div>
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12">
            <table class="extra_package">
                <tbody>
                    <tr nxTableRow>
                        <td>
                            <nx-checkbox [disabled]="true" [checked]="true" labelSize="small">
                                <div class="nx-font-weight-bold">
                                    RAHMAH PACKAGE
                                </div>
                            </nx-checkbox>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td class="sub_cover">
                            - COMPASSIONATE FLOOD COVER
                        </td>
                        <td class="si">SUM INSURED (RM) : 3,000.00</td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td class="sub_cover">
                            - DEATH/PERMANENT DISABLEMENT
                        </td>
                        <td class="si">SUM INSURED (RM) : 5,000.00</td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td class="sub_cover">- HOSPITAL INCOME</td>
                        <td class="si">RM 50.00 PER DAY / MAX 60 DAYS</td>
                        <td></td>
                    </tr>
                    <tr nxTableRow>
                        <td>
                            <nx-checkbox [disabled]="true" [checked]="true">
                                <div class="nx-font-weight-bold">
                                    24-HOUR EMERGENCY TOWING (UP TO 150KM)
                                </div>
                            </nx-checkbox>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <div class="nx-margin-top-2m">
                <app-external-scrollbar targetSyncScroll="5" [targetBaseWidth]="tableHolder5"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder5 syncScroll="5">
                    <table nxTable class="more_extra_cover">
                        <tbody>
                            <tr nxTableRow>
                                <th nxHeaderCell>
                                    <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                        <div class="nx-font-weight-bold">
                                            Extra Cover
                                        </div>
                                    </nx-checkbox>
                                </th>
                                <th nxHeaderCell></th>
                                <th nxHeaderCell>Premium (RM)</th>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY OF PASSENGERS FOR NEGLIGENT
                                        ACTS
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">7.50</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY TO PASSENGERS
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">33.75</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        EXTENSION OF COVER TO THE KINGDOM OF
                                        THAILAND
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">197.43</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        TUITION PURPOSES
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">493.58</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12">
            <button nxPlainButton type="button" class="nx-margin-right-m" (click)="tpft()">
                <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                ADD MORE
            </button>
            <ng-template #template>
                <div class="left-content">
                    <h3 nxHeadline="subsection-medium" class="nx-margin-0">
                        Exta Cover
                    </h3>
                    <div class="nx-margin-top-2m scroll-container">
                        <table nxTable zebra>
                            <thead>
                                <tr nxTableRow>
                                    <th nxHeaderCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">Extra Cover</span>
                                        </nx-checkbox>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">EXTENSION OF COVER TO KALIMANTAN</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">EXTENSION OF COVER TO THE KINGDOM OF
                                                THAILAND</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">TUITION PURPOSES</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="two-button-container nx-margin-top-l">
                        <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s">
                            Cancel
                        </button>
                        <button nxButton (click)="closeTemplateDialog()" class="m_margin_top_20">
                            Proceed
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>