import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TypeOfCoverageComponent } from "./type-of-coverage/type-of-coverage.component";
import { VehicleDetailsComponent } from "@shared/components/vehicle-details/vehicle-details.component";
import { SharedModule } from "@shared/shared.module";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { GET_VEHICLE_USE, SAVE_AS_DRAFT, STORE_COVERAGE_TYPE, STORE_COVERAGE_VEHICLE_DETAILS, INIT_STEP3, STORE_EXTRA_COVER_DETAILS, SET_CURRENT_STEP, GET_SWITCH_SITE, GET_COVER_LIST_LOV, CALCULATE_PREMIUM_PAYLOAD, CALCULATE_PREMIUM, CANCEL_DRAFT, STORE_PREMIUM_CALCULATION_PAYLOAD, VALIDATE_BLACKLIST, GET_NCD_ENQUIRY, STORE_NCD_INFO } from "@store/sat-motor/sat-motor.action";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { Select, Store } from "@ngxs/store";
import { UserResponse } from "@interfaces/user";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { ScrollToError } from '@shared/classes/scroll-to-error';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Observable } from "rxjs";
import { ConfirmationModalComponent } from "@shared/components/modal/confirmation-modal/confirmation-modal.component";
import { PreviousPolicyErrorPopupComponent } from "@shared/components/modal/previous-policy-error-popup/previous-policy-error-popup.component";
import { OkMessageComponent } from "@shared/components/modal/ok-message/ok-message.component";
import { SaveDraftModalComponent } from "@shared/components/modal/save-draft-modal/save-draft-modal.component";

@Component({
  selector: "app-coverage",
  standalone: true,
  imports: [SharedModule, TypeOfCoverageComponent, VehicleDetailsComponent],
  templateUrl: "./coverage.component.html",
  styleUrl: "./coverage.component.scss",
})
export class CoverageComponent implements OnInit {
  @ViewChild(TypeOfCoverageComponent) typeOfCoverageComponentChild: TypeOfCoverageComponent;
  openPreviousPolicyError?: NxModalRef<PreviousPolicyErrorPopupComponent>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;

  coverageFmGroup: any;
  alimCustomerInd: boolean;

  userInfo: UserResponse;
  issuanceInfo: any;
  agentDetail: any;
  partnerInfo: any;
  coverList: any;
  vehicleUselov: any;
  isSaved: any;

  decimalFmt: any;
  numberFmt: any;

  subCovList: any;
  subCovPackageList: any;
  extraCoverDetails: any;
  packageDescription: any;
  extraCoverList: any = [];
  rahmahExtraCoverList = [];
  topExtraCoverList = [];
  defaultedExtraCoverList = [];
  fixedDefaultedExtraCoverList = [];
  allSelectedWithRahmahExtraCoverList = [];
  unnamedDriverExtraCoverList = [];
  coverDto: any;
  updateSelectedAndAllListToOPUS: any;
  isWaiver: boolean = false;
  isAllDriver: boolean = false;
  subCovPackageDesc: any;
  agentData: any;
  isSingleAgent: boolean = false;
  hideSaveDraftBtn: boolean = false;
  isQuotationGenerated: any;
  isCoverNoteGenerated: any;
  isUpdateQuotation: any;
  isRetrieveActiveQuotation: any;
  isValidateWaiver: boolean = false;

  infoMessageModal?: NxModalRef<SimpleModalComponent>;
  confirmMessageModal?: NxModalRef<ConfirmationModalComponent>;

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.coverageFmGroup = this.fb.group({
      extraCoverDetails: new FormGroup({}),
      vehicleDetails: new FormGroup({}),
      typeOfCoverage: new FormGroup({}),
    });
  }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    
    this.isSaved = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step4?.generateQuotation);
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    this.agentDetail = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.motor?.step1?.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.motor?.step1?.issuance?.issuance);
    this.partnerInfo = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.motor?.step2);
    this.alimCustomerInd = this.store.selectSnapshot<string>((state) => state.SatMotorState.motor?.step2?.alimCustomerIndicator) === "YES";
    this.agentData = this.store.selectSnapshot(SatMotorState.motorAgentDetail);
    this.isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
    this.isQuotationGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data?.proposalNo);
    this.isCoverNoteGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ?.data?.cnoteNo);
    this.isUpdateQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState.updateQuotation);
    this.isRetrieveActiveQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState.retrieveQuotation);
    this.hideSaveDraftBtn = this.isQuotationGenerated || this.isCoverNoteGenerated || this.isUpdateQuotation || this.isRetrieveActiveQuotation ? true : false;
    this.setStep();
    this.checkSundriesAgent();
    
    if (this.store.selectSnapshot<any>((state) => state.SatMotorState?.actionType) === "ISSUE")
      if (!this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState?.motor?.step3))
        this.store.dispatch(new INIT_STEP3({}));
      
      let productAccessConfigList;
      if (productAccessConfigList = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov?.productAccessConfigList)) {
        let coverListLov = [];
        productAccessConfigList.forEach((product) => {
          for (let code in product) {
          if (product.hasOwnProperty(code) && code === this.issuanceInfo?.productCode) {
            coverListLov = product[code].coverList;
          }
        }
      });     
       
      this.store.dispatch(new GET_COVER_LIST_LOV({coverListLov: coverListLov})).subscribe((state) => {
        this.coverList = state.SatMotorState.data.step3.coverage.coverListLov;
        this.store.dispatch(new GET_VEHICLE_USE({ productCode: this.issuanceInfo.productCode })).subscribe((state) => {
          this.vehicleUselov = state.SatMotorState.data.step3.coverage.vehicleUseLov;
        });
      });
    }
    
  }
  
  checkSundriesAgent() {
    if (this.agentData && this.agentData?.sundriesAgent) {
      let coveragePremium = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote ?? null);
      let rebateType = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1?.agentAdditional?.rebateType ?? null);
      if (rebateType && coveragePremium) {
        if (!coveragePremium.rebateType) {
          this.reCalculatePremium(rebateType);
        } else if (rebateType !== coveragePremium.rebateType) {
          this.reCalculatePremium(rebateType);
        }

      }
    }
  }

  reCalculatePremium(rebateType) {
    let payloadPremium = this.store.selectSnapshot<any>((state) => state.SatMotorState.data?.step3?.coverage?.coveragePremiumPayload ?? null);

    if (!payloadPremium) {
      payloadPremium = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step3?.coverage?.coveragePremium ?? null);
    }
    
    let updatedPayload = {
      ...payloadPremium,
      cngeNote: {
        ...payloadPremium.cngeNote,
        rebateType: rebateType,
        riskList: [
          {
            ...payloadPremium.cngeNote.riskList[0],
            coverList: [
              {
                ...payloadPremium.cngeNote.riskList[0].coverList[0],
                rebateType: rebateType
              }
            ]
          }
        ]
      }
    };

    this.store.dispatch(new STORE_PREMIUM_CALCULATION_PAYLOAD(updatedPayload)).subscribe({
      next: () => {
        this.store.dispatch(new CALCULATE_PREMIUM(updatedPayload));
      }
    });
  }

  goBack() {
    this.router.navigate(["issuance/customer-partner"]);
  }

  setStep() {
    let step;
    if ((this.userInfo.callCentreType != undefined || (!!this.agentData?.customerType) || this.agentData?.bancaAgent || this.agentData?.sundriesAgent) && !this?.isSingleAgent) {
      step = 4;
    } else {
      step = 3;
    }
    this.store.dispatch(new SET_CURRENT_STEP(step));
  }

  validateVehicleDetails() {
    if (!this.coverageFmGroup.controls["vehicleDetails"].valid) {
      this.coverageFmGroup.controls["vehicleDetails"].markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      return false;
    }

    if (!this.validateVariantDesc()){
      ScrollToError.scrollToError('input[formControlName="variantDesc"]');
      return false;
    }

    return true;
  }

  validateVariantDesc() {
    let selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3.coverage.selectedMakeModel);
    let variantDesc = this.coverageFmGroup.controls["vehicleDetails"].controls["variantDesc"];
    if (selectedMakeModel?.variantFullDesc === variantDesc.value) {
      variantDesc.setErrors({"isSame": true});       
      variantDesc.markAllAsTouched(); 
      return false;
    } else {
      variantDesc.setErrors(null);
      variantDesc.updateValueAndValidity();
    }
      
    return true;
  }

  validateTypeOfCoverage() {
    if (!this.coverageFmGroup.controls["typeOfCoverage"].valid) {
      this.coverageFmGroup.controls["typeOfCoverage"].markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      return false;
    }

    if (!this.validateSumInsured()){
      ScrollToError.scrollToError('input[formControlName="sumInsured"]');
      return false;
    }

    return true;
  }

  validateSumInsured() {
    let coverage = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step3?.coverage?.coverageType);
    if (coverage) {
      const coverCode = coverage?.coverCode;
      if (coverCode && ["01" ,"30"].includes(coverCode)) {
        const sumInsured = this.coverageFmGroup.controls["typeOfCoverage"].controls["sumInsured"];
        if (sumInsured.value <= 0) {
          sumInsured.setErrors({"isZero": true});       
          sumInsured.markAllAsTouched(); 
          return false;
        } else {
          sumInsured.setErrors(null);
          sumInsured.updateValueAndValidity();
        }
      }
    }
    return true;
  }

  validateExtraCoverDetails() {
    if(this.typeOfCoverageComponentChild){
      if(!this.typeOfCoverageComponentChild.callTypeOfCoverageComponent("")){
        return false;
      }
    }

    if (!this.coverageFmGroup.controls["extraCoverDetails"].valid) {
      this.coverageFmGroup.controls["extraCoverDetails"].markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
      return false;
    }

    return true;
  }

  validateDriverNameDetails() {
    const driverNameDetails = this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()?.driverNameDetails?.rows;

    if (driverNameDetails) {
      let driver = driverNameDetails.map((d) => { return d.driverId + d.driverName; });
      if (new Set(driver).size !== driver.length) {
        this.dialogService.open(SimpleModalComponent, {
          showCloseIcon: false, width: "400px", maxWidth: "400px",
          data: { type: 'WARNING', message: "Duplicate driver name" },
        });
        return false;
      }
    }
    
    return true;
  }

  validateWaiver() {
    const driverNameDetails = this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()?.driverNameDetails?.rows;
    let coveragePremium = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote ?? null);
    if (!coveragePremium?.riskList[0]?.coverList[0].vehicleUnNamedDrvrInd
      && coveragePremium?.productCode === '080100'
      && coveragePremium?.riskList[0]?.coverList[0].coverCode === '01'
      && !this.isValidateWaiver) {  
      this.isValidateWaiver = true;
      this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: 'WARNING', message: "If you have named driver, please key in now or select Unnamed Driver for RM 20.00" },
      }).afterClosed().subscribe((result) => {
        this.router.navigate(["issuance/summary"]);
      });
    }else{
      this.router.navigate(["issuance/summary"]);
    }
  }

  validateEhailingDriver() {
    const extraCoverPackage = this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()?.extraCoverPackage;
    if (extraCoverPackage) {
      let privateHireCar = extraCoverPackage.find(ex => ex.subCoverCode === 'A202' && ex.selected == true)
      if (privateHireCar) {
        const ehailingDriverDetails = this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()?.ehailingDriverDetails?.rows;
        if (ehailingDriverDetails) {
          let driver = ehailingDriverDetails.map((d) => { return d.driverId + d.driverName; });
          if (!driver.filter(Boolean).length) {
            this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "400px", maxWidth: "400px",
              data: { type: 'WARNING', message: "Minimum 1 e-Hailing driver is required." },
            });
            return false;
          }
        }
      }
    }
    return true;
  }

  isNext(coveragePremium) {
    if (coveragePremium)
      return true;
    return false;
  }

  next() {

    if (!this.validateVehicleDetails()) return;
    if (!this.validateTypeOfCoverage()) return;
    if (!this.validateExtraCoverDetails()) return;
    if (!this.validateDriverNameDetails()) return;
    if (!this.validateEhailingDriver()) return;
    this.store.dispatch(new VALIDATE_BLACKLIST({ userId: this.userInfo.userId })).subscribe((stateBlacklist) => {
      if (stateBlacklist.SatMotorState.blacklistResult.success) {
        this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue()))).subscribe((state) => {
          this.store.dispatch(new STORE_COVERAGE_TYPE(this.populateTypeOfCoverageData(this.coverageFmGroup.controls["typeOfCoverage"].getRawValue()))).subscribe((state) => {
            this.store.dispatch(new STORE_EXTRA_COVER_DETAILS(this.populateExtraCoverageData(this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()))).subscribe((state) => {
              this.validateWaiver();
            });
          });
        });
      } else {
        if (!stateBlacklist.SatMotorState.blacklistResult.success) {
          this.validateBlacklistErrorPopup('validateBlacklistPopup', stateBlacklist.SatMotorState.blacklistResult.message)
        }
      }
    });
  }

  saveAsDraft() {

    if (!this.validateVehicleDetails()) return;
    if (!this.validateTypeOfCoverage()) return;
    if (!this.validateExtraCoverDetails()) return;
    if (!this.validateDriverNameDetails()) return;
    if (!this.validateEhailingDriver()) return;

    this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue()))).subscribe((state) => {
      this.store.dispatch(new STORE_COVERAGE_TYPE(this.populateTypeOfCoverageData(this.coverageFmGroup.controls["typeOfCoverage"].getRawValue()))).subscribe((state) => {
        this.store.dispatch(new STORE_EXTRA_COVER_DETAILS(this.populateExtraCoverageData(this.coverageFmGroup.controls["extraCoverDetails"].getRawValue()))).subscribe((state) => {
            this.store.dispatch(new SAVE_AS_DRAFT({ userId: this.userInfo.userId })).subscribe((state) => {
              if (!state?.SatMotorState?.saveAsDraftError) {
                this.dialogService.open(SaveDraftModalComponent, {
                  ariaLabel: "A simple dialog",
                  showCloseIcon: false,
                  autoFocus: false,
                  width: '90vw',
                  maxWidth: '550px',
                  data: {
                    draftNo: state.SatMotorState.motor.step3.draftNo,
                  },
                })
                .afterClosed().subscribe((result) => {
                  this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                    window.location.href = state.SatMotorState.data.switchSiteUrl;
                  });
                });
              }else{
                this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
                  showCloseIcon: false, width: "630px",
                  data: {
                    type: "e-Quotation Saved Failed",
                    message: state?.SatMotorState?.saveAsDraftError,
                    btnOkLabel: "OK",
                  },
                });
              }
            });
        });
      });
    });
  }

  cancelDraft() {

    this.confirmMessageModal = this.dialogService.open(ConfirmationModalComponent, {
      showCloseIcon: false, width: "550px", maxWidth: "550px",
      data: { message: "Do you want to cancel the Draft?" },
    });
  
    this.confirmMessageModal.afterClosed().subscribe(result => {
      if (result) {
        const data = {
          operator: this.userInfo.userId,
          cngeNote: this.isSaved
        };
        this.store.dispatch(new CANCEL_DRAFT(data)).subscribe(state => {
          if (state) {
            this.dialogService.open(SimpleModalComponent, {
              showCloseIcon: false, width: "500px", maxWidth: "500px",
              data: { message: "Cancel Draft Success" },
            })
            .afterClosed().subscribe((result) => {
              this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
                window.location.href = state.SatMotorState.data.switchSiteUrl;
              });       	      
            });
          }
        });
      }
    });

  }
  
  onChangedVehicle(data: any) {
    if (data) {
      if (["region", "vehicleUse", "vehicleCapacity", "makeModel"].includes) {
        if (this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3.coverage?.coverageType)) {
          ["sumInsured", "grossPremium", "excessAmount", "annualPremium", "basicPremium", "ncdPercentage", "ncdAmount"]
          .forEach((f) => {
            this.coverageFmGroup.controls["typeOfCoverage"].patchValue({
              [f]: null
            });
          });
          this.coverageFmGroup.controls["typeOfCoverage"].patchValue({ avInd: false });
          let coverageType = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
          this.calculatePremium({ ...coverageType, grossPremium: null });
        }
      }
    }
  }

  calculatePremium(data: any) {
    let vehicleDetails = this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue());
    let typeOfCoverage = this.populateTypeOfCoverageData({ ...data, ...this.coverageFmGroup.controls["typeOfCoverage"].getRawValue() });
    let coverDto = this.populateCoverDto();
    let mtRoadPlacementTblInd = this.store.selectSnapshot<any>((state) =>  state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote?.mtRoadPlacementTblInd);
    let mtRoadEligibleInd = this.store.selectSnapshot<any>((state) =>  state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote?.mtRoadEligibleInd);
    
    this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(vehicleDetails)).subscribe((state) => {
      this.store.dispatch(new STORE_COVERAGE_TYPE(typeOfCoverage)).subscribe((state) => {
        this.store.dispatch(new CALCULATE_PREMIUM_PAYLOAD({
          coverage: {
            mtRoadPlacementTblInd: mtRoadPlacementTblInd,
            mtRoadEligibleInd: mtRoadEligibleInd,
            vehicleDetails: vehicleDetails,
            typeOfCoverage: typeOfCoverage,
            vehicleAlldrvrInd: ["080200", "080300"].includes(this.issuanceInfo.productCode),
            coverDto: coverDto
          },
          operator: this.userInfo.userId,
        })).subscribe(state => {
          this.store.dispatch(new CALCULATE_PREMIUM(state.SatMotorState.data.step3.coverage.coveragePremiumPayload))
        });
      });
    });
  }
  
  populateVehicleDetailsData(data) {
    let selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3?.coverage?.selectedMakeModel);
    let makeModelPrintInd = data.makeModelPrintInd ? "Y" : "N";
    let vehicleDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.vehicleDetails);
    return {
      ...vehicleDetails, ...selectedMakeModel, ...data, 
      vehicleCapacity: this.numberFmt.transform(data.vehicleCapacity),
      makeModelPrintInd: makeModelPrintInd,
      makeModelDesc: data.makeModelPrintInd? data.piamMakeModelDesc : '',
      importStatus: selectedMakeModel?.importStatus,
      vehModelNvic: selectedMakeModel?.vehModelCodeNvic,
      vehicleModel: selectedMakeModel?.vehModelCodeNvic,
      nvicNo: selectedMakeModel?.nvicCode
    };
  }

  populateTypeOfCoverageData(data) {
    let coverageType = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    let typeOfCoverageData = { 
      ...coverageType, ...data, 
      sumInsured: this.numberFmt.transform(data.sumInsured), excessAmount: this.numberFmt.transform(data.excessAmount),
      basicPremium: this.numberFmt.transform(data.basicPremium), annualPremium: this.numberFmt.transform(data.annualPremium), 
      ncdAmount: this.numberFmt.transform(data.ncdAmount), ncdPercentage: this.numberFmt.transform(data.ncdPercentage)
    };
    if (data.grossPremium) {
      typeOfCoverageData = {
        ...typeOfCoverageData, grossPremium: this.numberFmt.transform(data.grossPremium), 
      };
    }
    return typeOfCoverageData;
  }

  populateCoverDto() {
    let coverageType = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    let coverDto = null;

    //if (coverageType?.coverCode === '20') { //third party //Shahkir : remove because impact 1 Allianz MRW
      coverDto = this.store.selectSnapshot<any>((state) => {
        let riskList = state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList;
        let coverList = riskList?.[0]?.coverList;
        return coverList?.[0]?.coverDto ?? null;
      });
  //  }
    return coverDto;
  }

  populateExtraCoverageData(data) {
    let driverNameDetails = data?.driverNameDetails?.rows?.map((d, i) => { return { ...d, driverAge: d.driverAge !== "N/A" ? d.driverAge : 0 , driverSeq: i+1 } });
    let ehailingDriverDetails = data?.ehailingDriverDetails?.rows?.filter((a) => a.driverId || a.driverName)?.map((d, i) => { return { ...d, driverSeq: i+1 } });
    return {
      ...data, 
      driverNameDetails: {
        rows: driverNameDetails,
        vehicleUnNamedDrvrInd: data.driverNameDetails?.waiverInd,
        vehicleAlldrvrInd: data.driverNameDetails?.allDriverInd
      }, 
      ehailingDriverDetails: {
        rows: ehailingDriverDetails
      }
    };
  }

  populateDriverNameData() {
    let driverNameForm = this.coverageFmGroup.controls["driverNameForm"].getRawValue();
    const namedDriverList = driverNameForm.rows
    let cngeRiskDrvrDtoList = namedDriverList.map((driver) => {
      let driverName;
      if (driver.driverName) {
        driverName = driver.driverName;
      } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
        driverName = driver.cngeRiskDrvrPkDto.driverName;
      }

      let cngeRiskDrvrPkDto = {
        cngeRiskDrvrPkDto: {
          driverName: driverName ? driverName : "",
          driverType: "NORM",
        },
        driverId: driver.driverId,
        driverAge: driver.driverAge,
        driverSeq: driver.driverSeq,
      };

      return cngeRiskDrvrPkDto;
    });
    return cngeRiskDrvrDtoList;
  }

  validateBlacklistErrorPopup(popup_type: any, error: any) {
    this.openPreviousPolicyError = this.dialogService.open(PreviousPolicyErrorPopupComponent, {
      ariaLabel: "A simple dialog",
      width: '400px',
      showCloseIcon: false,
      data: {
        popup_type: popup_type,
        popup_error: error
      },
    });
  }

  getNCDAndCalculatePremium(data: any) {
    let vehicleDetailsForm = this.coverageFmGroup.get("vehicleDetails");
    let typeOfCoverageForm = this.coverageFmGroup.get("typeOfCoverage");
    let prevNcdVeh = typeOfCoverageForm?.get("prevNcdVeh")?.value;
    let vehicleNo = vehicleDetailsForm?.get("vehicleNo")?.value;

    if (vehicleDetailsForm && typeOfCoverageForm) {
      if (this.issuanceInfo.cnoteType === "NWTR") {
        this.calculatePremium(data);
        return;
      }

      if (this.issuanceInfo.cnoteType === "NWNN" && (prevNcdVeh === 'NA' || !prevNcdVeh) && vehicleNo === 'NA') {
        this.calculatePremium(data);
        return;
      }

      if (this.issuanceInfo.cnoteType === "NWOO" && !prevNcdVeh) {
        prevNcdVeh = vehicleNo;
      }

      let payload = {
        productType: this.issuanceInfo.productCode,
        vehicleUse: vehicleDetailsForm.get("vehicleUse")?.value,
        vehicleNo: vehicleDetailsForm.get("vehicleNo")?.value,
        previousNcdVehicle: prevNcdVeh,
        chassisNo: vehicleDetailsForm.get("vehicleChassis")?.value,
        idType: this.partnerInfo.idType1,
        idNo1: this.partnerInfo.idValue1
      };

      this.store.dispatch(new GET_NCD_ENQUIRY(payload)).subscribe((state) => {
        let ncdInfo = state.SatMotorState.ncdEnquiryDetails;
        if (ncdInfo) {
          this.store.dispatch(new STORE_NCD_INFO(ncdInfo)).subscribe((state) => {
            if (!ncdInfo.errMsg) {
              this.calculatePremium(data);
            }
          });
          if (ncdInfo.errMsg) {
            this.okMessageModal = this.dialogService.open(OkMessageComponent, {
              width: '400px',
              data: {
                message: ncdInfo.errMsg
              }
            });
          }
        }
      });
    }
 
  }
}
