<!-- PopUp for Request Cancellation (to be move to new component) -->
<div *ngIf="popup_type == 'previousPolicyErrorPopupType'">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-light">
            {{popup_error}}
        </p>
    </div>

    <button nxModalClose class="button_popup_2btn" nxButton="primary" type="button"
        (click)="onClickRequestCancellation('close')">
        OK
    </button>
</div>

<div *ngIf="popup_type == 'populateActiveQuotationDetails'">
    <div class="std-ta-center">
        <div nxModalContent>
            <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
                Warning
            </h3>
            <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-light">
                There is an existing quotation {{activeQuotationDetails?.cngeNote?.proposalNo}} for this vehicle, last
                updated
                on {{activeQuotationDetails?.cngeNote?.expiryDate | date:'dd/MM/yyyy'}}. Would you like to retrieve this
                quotation instead of creating a
                new one?
            </p>
        </div>

        <button nxModalClose class="button_popup_2btn" nxButton="primary" type="button" (click)="onClickYes()">
            YES
        </button>
        <button nxModalClose nxButton="primary" type="button">
            NO
        </button>
    </div>
</div>