import { Component, Input,EventEmitter, Output, ViewChild} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { STEP2 } from '@interfaces/motor';
import { Store } from '@ngxs/store';
import { CpSameAgentInfoComponent } from '@shared/components/modal/cp-same-agent-info/cp-same-agent-info.component';
import { CLIENTTYPE, CONSTANTS, IDTYPE, REGEX } from '@shared/enums/index.enum';
import { BrnoService } from '@shared/services/brno/brno.service';
import { NricService } from '@shared/services/nric/nric.service';
import { SharedModule } from '@shared/shared.module';
import { Subject, takeUntil } from 'rxjs';
import { SmeCertFormComponent } from '../sme-cert-form/sme-cert-form.component';
import { SstFormDetailsComponent } from '../sst-form-details/sst-form-details.component';
import { GET_POSTCODE_INFO, UPDATE_GEO_INDICATOR } from '@store/sat-motor/sat-motor.action';
import { GET_PRODUCT_CONFIG_LOV } from '@store/sat-motor/sat-motor.action';
import { BranchesMapComponent } from '@shared/components/branches-map/branches-map.component';
import { ConfirmationModalComponent } from '@shared/components/modal/confirmation-modal/confirmation-modal.component';
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import { LayoutBreakPointsService } from '@shared/services/layout-break-points/layout-break-points.service';
import { emailValidator } from '@shared/classes/validators/email.validator';

@Component({
  selector: 'app-customer-partner-form',
  standalone: true,
  imports: [
    SharedModule,
    SmeCertFormComponent,
    SstFormDetailsComponent,
    CpSameAgentInfoComponent,
    BranchesMapComponent,
    NxMomentDateModule
  ],
  templateUrl: './customer-partner-form.component.html',
  styleUrl: './customer-partner-form.component.scss'
})
export class CustomerPartnerFormComponent {
  @Input() customerPartnerForm: FormGroup;
  @Input() lov: any;
  @Input() dataDetails: any;
  @Input() eInvoiceConsent: boolean = false;
  @Input() edocConsentInd: boolean = false;
  @Input() mkagAgent: any;
  @Input() isCompOrCustInfoIncomplete: boolean;
  @Input() isContactInfoIncomplete: boolean;
  @Input() isCorrespondenceInfoIncomplete: boolean;
  @Input() cpType: any;

  @ViewChild(BranchesMapComponent) child!: BranchesMapComponent;
  search_mode_address: boolean = true;

  isShow : boolean;
  motorPC : any;
  lovproductConfig:any;
  productAccessListData: any;
  lovPC : any;
  lovGeo: any;
  emailCheck : any;

  idType2List: any;
  cpSameAgentInfoModal?: NxModalRef<CpSameAgentInfoComponent>;

  getCountry = CONSTANTS.getCountry();

  unsubscribe$ = new Subject<void>();

  mobileView: boolean = false;

  idValue2Length: number;
  onlyNumber: boolean = false;
  contactNoRegex: RegExp = new RegExp(REGEX.CONTACT_NO);
  fixedLineNoRegex: RegExp = new RegExp(REGEX.FIXED_LINE_NO);

  constructor(
    private readonly dialogService: NxDialogService,
    private nricService: NricService,
    private brnoService: BrnoService,
    private store: Store,
    private layoutService: LayoutBreakPointsService,
  ) {}

  ngOnInit() {
    this.layoutService.subscribeLayoutChanges().subscribe(() => {
      this.mobileView = !this.layoutService.renderDesktop();
    });

    this.initIdType2List();

    this.onChangeIdType2();
    this.initAddressValidators();


    if(this.cpType === "NOT_FOUND" && this.dataDetails.clientType === CLIENTTYPE.INDIVIDUAL && this.dataDetails.idType1 === IDTYPE.NRIC) {
      this.dafaultGender();
    }

    this.onChangeDOB();

    this.motorPC = this.store.selectSnapshot<any>((state)=>state.SatMotorState.motor.step1?.issuance?.issuance?.productCode);
    this.lovproductConfig = this.lov.productAccessConfigList;

    
    for (let i = 0; i < this.lovproductConfig.length; i++) {
    this.productAccessListData = this.lovproductConfig[i];
    this.lovPC = Object.keys( this.productAccessListData)[0]; 
  
      if (this.lovPC === this.motorPC) {
       this.lovGeo =this.productAccessListData[this.lovPC].geocodingEnableSat;
       this.emailCheck = this.productAccessListData[this.lovPC].emailCheckEnableSat;

        if(this.lovGeo == "true")
          {
            this.isShow = true;
          }
          else{
            this.isShow = false;
          }
      }
  }
  this.initValidators();

    if(this.cpType === "INCOMPLETE") {
      this.onChangeMobileNo(true);
      this.onChangefixedLineNo();
      if(this.showIdType2AndIdValue2()) {
        this.setIdValue2Length();
        this.checkIdValue2IsOnlyNumber();
      }
    }
  }

  initIdType2List() {
    if(this.dataDetails.clientType === CLIENTTYPE.INDIVIDUAL) {
      if(this.dataDetails.idType1 === IDTYPE.OLD_IC) {
         this.idType2List = [
          { code: IDTYPE.NRIC, descp: IDTYPE.NRIC_DESCP },
          { code: IDTYPE.POL, descp: IDTYPE.POL_DESCP }
        ]
      }
    }else {
      if(this.dataDetails.idType1 === IDTYPE.BR_NO) {
        this.idType2List = [
         { code: IDTYPE.NEW_BR_NO, descp: IDTYPE.NEW_BR_NO_DESCP }
       ]
     }else {
      this.idType2List = [
        { code: IDTYPE.BR_NO, descp: IDTYPE.BR_NO_DESCP }
      ]
     }
    }
  }

  initValidators() {
    this.initEmailValidators();

    if(this.isCompany) {
      this.initCompanyValidators();
    }else {
      this.initIndividualValidators();
    }
  }

  initEmailValidators() {
    if((this.emailCheck =="true") ||(this.eInvoiceConsent)) {
      this.customerPartnerForm.controls['email'].setValidators([Validators.required, emailValidator]);
    }
  }

  initCompanyValidators() {
    this.customerPartnerForm.controls['fixedLineNo'].addValidators([Validators.required]);

    if (this.eInvoiceConsent) {
      this.customerPartnerForm.controls['tinNo'].addValidators(Validators.required);
    }
    this.customerPartnerForm.controls['nationality'].setValidators(Validators.required);
  }

  initIndividualValidators() {
    this.customerPartnerForm.controls['maritalStatus'].setValidators(Validators.required);
    this.customerPartnerForm.controls['gender'].addValidators([Validators.required]);
    if(this.dataDetails.idType1 === IDTYPE.PASS) {
      this.customerPartnerForm.controls['nationality'].setValidators(Validators.required);
    }

    if(this.dataDetails.idType1 !== IDTYPE.NRIC) {
      this.customerPartnerForm.controls['dob'].setValidators(Validators.required);
    }
  }

  initAddressValidators(){
    // this.customerPartnerForm.controls['address1'].setValidators(Validators.required);
    this.customerPartnerForm.controls['postCode'].setValidators(Validators.required);
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  changeSearchMode(event: any) {
    // debugger;
    if (event.value == "address") {
      this.search_mode_address = true;
    } else {
      this.search_mode_address = false;
    }
  }

  onBlurIdValue2(idValue2) {
    console.log("idValue2: ", idValue2)
    let idValue2Control = this.customerPartnerForm.controls['idValue2'];
    let idType2Control = this.customerPartnerForm.controls['idType2'];
    let idValue2Val = idValue2Control.value;
    let idType2Val = idType2Control.value;

    if (idValue2Val) {
      idValue2Control.clearValidators();
      switch (idType2Val) {
        case IDTYPE.NRIC:
          if (idValue2Val.length === 12) {
            const isValidNric = this.nricService.isNRICValid(idValue2Val);
            if (!isValidNric) {
              idValue2Control.setErrors({ 'wrongNricFormat': true });
            }else {
              this.customerPartnerForm.get('dob').setValue(this.getDateOfBirth(idValue2Val));
              this.customerPartnerForm.get('gender').setValue(this.nricService.autoPopulateGenderBasedOnNRIC(idValue2Val));
            }
          }else {
            idValue2Control.setErrors({ 'wrongNricFormat': true });
          }
          break;
        
        case IDTYPE.NEW_BR_NO:
          if(idValue2Val.length < 12) {
            idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
          }else {
            try {
              const isValidNewBrNo = this.brnoService.isBRNOValid(idValue2Val);
              if (!isValidNewBrNo) {
                idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
              }
            }catch (err) {
              idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
            }
            
          }
          break;
      }
      idType2Control.setValidators([Validators.required]);
    }else {
      idType2Control.removeValidators([Validators.required]);
    }
    idType2Control.updateValueAndValidity();
  }

  onChangeIdType2() {
    this.customerPartnerForm.controls['idType2'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let idValue2Control = this.customerPartnerForm.controls['idValue2'];
      let idValue2Val = idValue2Control.value;
      if(value && !idValue2Val) {
        idValue2Control.setErrors({'required': true});        
      }
    });
  }

  dafaultGender() {
    let idValue = this.dataDetails.idValue1;
    let gender = this.nricService.autoPopulateGenderBasedOnNRIC(idValue);
    this.customerPartnerForm.controls['gender'].setValue(gender);
  }

  getErrorType(errors) {
    if (errors.wrongNricFormat) {
      return 'wrongNricFormat';
    }

    if(errors.wrongNewBrnoFormat) {
      return 'wrongNewBrnoFormat';
    }

    if (errors.invalidDOB) {
      return 'invalidDOB';
    }

    if (errors.required) {
      return 'required';
    }

    return false;
  }

  onChangeDOB() {
    this.customerPartnerForm.controls['dob'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let dobDate = new Date(value);
      const currentDate = new Date();
      
      currentDate.setHours(0,0,0,0);

      let dobYear = dobDate.getFullYear();
      let currentYear = currentDate.getFullYear();

      if(dobYear >= currentYear) {
        this.customerPartnerForm.controls['dob'].setErrors({'invalidDOB': true});
      }else {
        let age = currentYear - dobYear;
        this.customerPartnerForm.controls['age'].setValue(age);
      }
    });
  }

  onChangeAge() {
    console.log("age: ", this.customerPartnerForm.controls['age'].errors);
  }

  onChangeMobileNo(fromInit) {
    let mobileNo = this.customerPartnerForm.controls['mobileNo'].value;
    let mobileNoCode = this.customerPartnerForm.controls['mobileNoCode'].value;
    if(mobileNo) {
      if(this.isCompany) {
        this.customerPartnerForm.controls['fixedLineNo'].removeValidators([Validators.required]);
      }
      this.customerPartnerForm.controls['mobileNoCode'].setValidators([Validators.required]);

      if(!this.contactNoRegex.test(mobileNo)) {
        this.customerPartnerForm.controls['mobileNo'].setErrors({ pattern: true });
        return;
      }

      if(!fromInit && mobileNo && mobileNoCode) {
        this.checkIsSameAgentMobile();
      }
    }else {
      if(this.isCompany) {
        this.customerPartnerForm.controls['fixedLineNo'].addValidators([Validators.required]);
      }
    }
    this.customerPartnerForm.controls['fixedLineNo'].updateValueAndValidity();
    this.customerPartnerForm.controls['mobileNoCode'].updateValueAndValidity();
    
  }

  onChangeEmail() {
    let email = this.customerPartnerForm.controls['email'].value;
    if(email) {
      this.checkIsSameAgentEmail();
    }
    this.customerPartnerForm.controls['email'].updateValueAndValidity();
    
  }

  onChangefixedLineNo() {
    let fixedLineNo = this.customerPartnerForm.controls['fixedLineNo'].value;
    this.customerPartnerForm.controls['fixedLineNoCode'].clearValidators();
    if(fixedLineNo) {
      if(this.isCompany) {
        this.customerPartnerForm.controls['mobileNo'].removeValidators([Validators.required]);
      }
      this.customerPartnerForm.controls['fixedLineNoCode'].setValidators([Validators.required]);

      if(!this.fixedLineNoRegex.test(fixedLineNo)) {
        this.customerPartnerForm.controls['fixedLineNo'].setErrors({ pattern: true });
        return;
      }

    }else {
      this.customerPartnerForm.controls['mobileNo'].addValidators([Validators.required]);
    }
    this.customerPartnerForm.controls['mobileNo'].updateValueAndValidity();
    this.customerPartnerForm.controls['fixedLineNoCode'].updateValueAndValidity();
  }

  onChangeFaxNo() {
    let faxNo = this.customerPartnerForm.controls['faxNo'].value;
    this.customerPartnerForm.controls['faxNoCode'].clearValidators();
    if(faxNo) {
      this.customerPartnerForm.controls['faxNoCode'].setValidators([Validators.required]);

      if(!this.contactNoRegex.test(faxNo)) {
        this.customerPartnerForm.controls['faxNo'].setErrors({ pattern: true });
        return;
      }

    }
    this.customerPartnerForm.controls['faxNoCode'].updateValueAndValidity();  
  }

  getDateOfBirth(value) {
    return this.nricService.getDateOfBirth(value);
  }

  getAge(value) {
    return this.nricService.getAge(value);
  }

  openCPSameAgentInfoModal(type) {
    let message = "";
    if(type === "MOBILE") {
      message = "The mobile number you have keyed in is an agent's mobile number. If you proceed with this number, it is deemed that you have obtained the customer's consent to use this number as the contact number for this policy. Please confirm if this is correct or otherwise key-in the mobile number provided by the customer.";
    }else {
      message = "The email you have keyed in is an agent's email. If you proceed with this email, it is deemed that you have obtained the customer's consent to use this email as the contact email for this policy. Please confirm if this is correct or otherwise key-in the email provided by the customer.";
    }

    this.cpSameAgentInfoModal = this.dialogService.open(CpSameAgentInfoComponent, {
      showCloseIcon: false,
      data: {
        message: message
      },
      width: '400px',
    });

    this.cpSameAgentInfoModal.afterClosed().subscribe(result => {
      if(result != "OK") {
        switch(type) {
          case "MOBILE":
            this.customerPartnerForm.controls['mobileNo'].setValue('');
          break;
          case "EMAIL":
            this.customerPartnerForm.controls['email'].setValue('');
          break;
        }
      }
    });
  }

  showCompOrCustInfoSection() {
    if(this.cpType === "FOUND" || this.cpType === "NOT_FOUND") {
      return true;
    }else {
      return this.dataDetails.updateCustomerPartner || this.isCompOrCustInfoIncomplete;
    }
  }

  showContactSection() {
    if(this.cpType === "FOUND" || this.cpType === "NOT_FOUND") {
      return true;
    }else {
      return this.dataDetails.updateCustomerPartner || this.isCompOrCustInfoIncomplete || this.isContactInfoIncomplete || this.isCorrespondenceInfoIncomplete;
    }
  }

  showCorrespondenceSection () {
    if(this.cpType === "FOUND" || this.cpType === "NOT_FOUND") {
      return true;
    }else {
      return this.dataDetails.updateCustomerPartner || this.isCompOrCustInfoIncomplete || this.isContactInfoIncomplete || this.isCorrespondenceInfoIncomplete;
    }
  }

  onChangePostcode() {
    let postcode = this.customerPartnerForm.controls['postCode'].value;

    this.store.dispatch(new GET_POSTCODE_INFO(postcode)).subscribe(state => {
      let postcodeInfo = state.SatMotorState.data.postCodeInfo;
      this.customerPartnerForm.patchValue({
        state: postcodeInfo.state,
        city: postcodeInfo.city,
        country: "MAL"
      });
    });
  }

  checkIsSameAgentMobile() {
    let mobileNo = this.customerPartnerForm.controls['mobileNo'].value;
    let mobileNoCode = this.customerPartnerForm.controls['mobileNoCode'].value;

    if(!this.isCompany && mobileNoCode == this.mkagAgent.agentMobileCode1 && mobileNo == this.mkagAgent.agentMobileNo1) {
      this.openCPSameAgentInfoModal("MOBILE");
    }
  }

  checkIsSameAgentEmail() {
    let email = this.customerPartnerForm.controls['email'].value;
    if(!this.isCompany && email.toUpperCase() == this.mkagAgent.agentEmail.toUpperCase()) {
      this.openCPSameAgentInfoModal("EMAIL");
    }
  }

  onChangeMobileNoCode() {
    let mobileNo = this.customerPartnerForm.controls['mobileNo'].value;
    let mobileNoCode = this.customerPartnerForm.controls['mobileNoCode'].value;
    if(mobileNo && mobileNoCode) {
      this.checkIsSameAgentMobile();
    }
  }

  get isCompany() {
    return this.dataDetails.clientType === CLIENTTYPE.COMPANY;
  }

  get isOldIC() {
    return this.dataDetails.idType1 === IDTYPE.OLD_IC;
  }

  get isNric() {
    return this.dataDetails.idType1 === IDTYPE.NRIC;
  }

  get idValue2ErrorType() {
    let errors = this.customerPartnerForm.controls['idValue2'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  get dobErrorType() {
    let errors = this.customerPartnerForm.controls['dob'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  populateForm(data: any) {
    
    //reset first before populate
    this.customerPartnerForm.patchValue({
      unitNo: '',
      address1: '',
      address2: '',
      address3: '',
      postCode: '',
      city: '',
      state: '',
      country: '',
      clientGeoLatitude : '',
      clientGeoLongitude : '',
      clientGeoPlaceId : '',
      clientGeoPlusCode : '',
      clientGeoFormattedAddress : ''
    });

    this.customerPartnerForm.patchValue({
      unitNo: data.unitNumber,
      address1: data.clientAddress1,
      address2: data.clientAddress2,
      address3: data.clientAddress3,
      postCode: data.clientPostcode,
      clientGeoLatitude : data.clientGeoLatitude,
      clientGeoLongitude : data.clientGeoLongitude,
      clientGeoPlaceId : data.clientGeoPlaceId,
      clientGeoPlusCode : data.clientGeoPlusCode,
      clientGeoFormattedAddress : data.clientGeoFormattedAddress
    });

    if (data.clientPostcode) {
      this.onChangePostcode();
    } else {
      this.customerPartnerForm.patchValue({
        city: data.clientCityDesc,
        state: data.clientStateDesc,
        country: data.clientCountryDesc,
      });
    }
  }

  //reset Address to null
  clearAddress(){
      
    this.customerPartnerForm.controls['address1'].clearValidators();
    this.customerPartnerForm.controls['postCode'].clearValidators();
    
    this.customerPartnerForm.controls['address1'].updateValueAndValidity();
    this.customerPartnerForm.controls['postCode'].updateValueAndValidity();

    this.customerPartnerForm.patchValue({
      unitNo: '',
      address1: '',
      address2: '',
      address3: '',
      postCode: '',
      city: '',
      state: '',
      country: '',
      showMapSwitcher:[true],
      clientGeoLatitude : '',
      clientGeoLongitude : '',
      clientGeoPlaceId : '',
      clientGeoPlusCode : '',
      clientGeoFormattedAddress : ''
    });

    this.dataDetails ={
      ...this.dataDetails,
      clientAddress1:'',
      clientAddress1Tmp: '',
      clientAddress2:'',
      clientAddress3:'', 
      clientCity:'',
      clientCountry:'',
      clientGeoLatitude:'',
      clientGeoLongitude:'',
      clientGeoPlaceId:'',
      clientGeoPlusCode:'',
      clientPostcode:'',
      clientState:'',
      clientUnitNo:'',
    };
    this.child.clearAddress();
    this.store.dispatch(new UPDATE_GEO_INDICATOR(false));

  }

  idType2OnChange() {
    this.customerPartnerForm.controls['idValue2'].reset();

    this.setIdValue2Length();

    this.checkIdValue2IsOnlyNumber();
  }

  setIdValue2Length() {
    let value = this.customerPartnerForm.controls['idType2'].value;
    if (value === IDTYPE.NRIC || value === IDTYPE.NEW_BR_NO) {
      this.idValue2Length = 12;
    } else {
      this.idValue2Length = 20;
    }
  }

  checkIdValue2IsOnlyNumber() {
    let value = this.customerPartnerForm.controls['idType2'].value;
    if(value === IDTYPE.NRIC) {
      this.onlyNumber = true;
    }else {
      this.onlyNumber = false;
    }
  }

  showIdType2AndIdValue2() {
    return (!this.isCompany && this.isOldIC) || this.isCompany;
  }
}
