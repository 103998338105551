import { Component, HostListener, Input, Output, EventEmitter } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { TranslateService } from "@ngx-translate/core";
import { UserResponse } from "@interfaces/user";
import { map } from "rxjs/operators";
import { UserState } from "@store/user/user.state";
import { Select, Store } from "@ngxs/store";
import { Params, Router } from "@angular/router";
import { GET_REDIRECT_PAGE, GET_SWITCH_SITE } from "@store/sat-motor/sat-motor.action";
import { BACK_TO_ALPHA, BACK_TO_SAT, SIGN_OUT } from 'src/app/store/user/user.action';

interface MenuChild {
  description: string;
  link: string;
}

interface MenuItem {
  description: string;
  hidden?: boolean;
  subMenu?: MenuChild[];
}

@Component({
  selector: "app-motor-header",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./motor-header.component.html",
  styleUrl: "./motor-header.component.scss"
})
export class MotorHeaderComponent {

  @Select(UserState.menu) menuPolicyMgmt$;
  @Select(UserState.menuList) menuList$;
  @Output() isShowMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() user: UserResponse;

  menuPolicyMgmt: any[] = [];
  menuList: any[] = [];

  userInfo: any;

  showmenu = true;
  screenWidth: number;
  subMenuLink = [
    'SAT_MT_COVER_NOTE',
    'SAT_MT_ENDT',
    'SAT_MT_RDTAXPURPOSE',
    'SAT_MT_ISMDATAUPLOAD',
    'SAT_MT_NCDCONFIRM',
    'SAT_MT_NCDENQ',
  ];

  modularLink = [
    'SUMMARY',
    'REPORTS',
    'ENQUIRY'
  ];

  parentMenuLink = [
    '/home',
    '/e-library',
    '/sales-management',
    '/customer-management'
  ]

  nonMotorMenuId = [
    'SA_POLISSUANCE',
    'SA_POLNONMOTOR',
    'SA_POLNONMOTOR2',
    'SA_POLALPHAPORTAL'
  ]

  switchUrls = {
    'SA_POLISSUANCE': "ISSUE_SYS_URL",
    'SA_POLNONMOTOR': "ECN_SYS_URL",
    'SA_POLNONMOTOR2': "ECN_SYS_URL_PREPROD"
  };

  
  coverNoteParams: Params = {
    token: this.store.selectSnapshot<UserResponse>(state => state?.UserState?.user?.userId),
    action: 'ISSUE',
  };

  endorsementParams: Params = {
    token: this.store.selectSnapshot<UserResponse>(state => state?.UserState?.user?.userId),
    action: 'ENDT',
  };

  rtpParams: Params = {
    token: this.store.selectSnapshot<UserResponse>(state => state?.UserState?.user?.userId),
    action: 'RTP',
  };

  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router
  ) {
    this.screenWidth = window.innerWidth;
    this.showmenu = this.screenWidth >= 993;
  }

  ngOnInit() {
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);

    this.menuPolicyMgmt$.subscribe((m: any) => {
      if (m)
        this.menuPolicyMgmt = JSON.parse(JSON.stringify(m.subMenu));
    });

    this.menuList$.subscribe((m: any) => {
      if (m)
        this.menuList = JSON.parse(JSON.stringify(m));
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.screenWidth = window.innerWidth;
    this.showmenu = this.screenWidth >= 993;
  }

  onClick(item: any) {
    item.hidden = !item.hidden;
  }

  toggleMenu() {
    this.showmenu = !this.showmenu;
  }

  nav(url: any, item: any, menu: any) {
    console.log(item);
    if (item.link) {
      if (item.menuId === "SAT_MT_ROADTAXRN") {
        window.open(item.link);
        menu.hidden = !menu.hidden;
      } else if (this.nonMotorMenuId.includes(item.menuId)) {
        if (this.switchUrls[item.menuId]) {
          this.store.dispatch(new GET_SWITCH_SITE(this.switchUrls[item.menuId])).subscribe((state) => {
            let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;
            console.log("SwitchSiteUrl Url : " + switchSiteUrl);
            window.location.href = switchSiteUrl;
          });
        } else if (item.menuId === "SA_POLALPHAPORTAL") {
          this.backToAlpha();
        }
      } else if (this.subMenuLink.includes(item.link)) {
        menu.hidden = !menu.hidden;
        if (item.link === 'SAT_MT_COVER_NOTE') {
          this.router.navigate(['/home'], { queryParams: this.coverNoteParams });

        } else if (item.link === 'SAT_MT_ENDT') {
          this.router.navigate(['/home'], { queryParams: this.endorsementParams });

        } else if (item.link === 'SAT_MT_RDTAXPURPOSE') {
          this.router.navigate(["/home"], { queryParams: this.rtpParams });

        } else if (item.link === 'SAT_MT_ISMDATAUPLOAD') {
          this.router.navigate(["/endorsement-data-upload"], { queryParamsHandling: 'preserve' });

        } else if (item.link === 'SAT_MT_NCDCONFIRM') {
          this.router.navigate(["/ncd-confirmation"], { queryParamsHandling: 'preserve' });

        } else if (item.link === 'SAT_MT_NCDENQ') {
          this.router.navigate(["/ncd-enquiry"], { queryParamsHandling: 'preserve' });

        }
      } else if (this.modularLink.includes(item.link)) {
        this.store.dispatch(new GET_SWITCH_SITE(item.link + "_SYS_URL")).subscribe((state) => {
          let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;
          console.log("SwitchSiteUrl Url : " + switchSiteUrl);
          window.location.href = switchSiteUrl;
        });

      } else if (this.parentMenuLink.includes(item.link)) {

        const menuUrlMap = {
          '/e-library': 'SAT_ELIBRARY_URL',
          '/sales-management': 'SAT_ELIBRARY_URL',
          '/customer-management': 'SAT_CUSTOMER_MANAGEMENT_URL',
          '/home': 'SAT_URL'
        };

        const urlKey = menuUrlMap[item.link];
        if (urlKey) {
          this.store.dispatch(new GET_SWITCH_SITE(urlKey)).subscribe((state) => {
            const switchSiteUrl = state.SatMotorState.data.switchSiteUrl;
            console.log("SwitchSiteUrl Url : " + switchSiteUrl);

            if (switchSiteUrl) {
              window.location.href = switchSiteUrl;
            } else {
              console.error('SwitchSiteUrl is undefined for:', item.link);
            }
          });
        }

      } else {
        if (item.type == "L") {
          this.store.dispatch(new GET_REDIRECT_PAGE(url));
        } else if (item.type == "M") {
          window.location.href = url;
        }
      }
      item.hidden = !item.hidden;
      this.checkScreen();
    }
  }

  open(url: any, submenu: any, menu: any) {
    this.checkScreen();
    submenu.hidden = !submenu.hidden;
    menu.hidden = !menu.hidden;
    window.open(url);
  }

  checkScreen() {
    var screenWidth = window.innerWidth;
    if (screenWidth < 993) {
      this.showmenu = false;
    }
  }

  get userName$() {
    return this.translate.get("HEADER.WELCOME").pipe(map((text: string) => `${text}, ${this.user.preferName}`));
  }

  get lastLogin$() {
    return this.translate.get("HEADER.LAST_LOGIN_TIME").pipe(map((text: string) => `${text}: ${this.user.lastLoginDate}`));
  }

  backToAlpha() {
    this.store.dispatch(new BACK_TO_ALPHA());
  }

  backToSAT() {
    this.store.dispatch(new BACK_TO_SAT());
  }

  signOut() {
    this.store.dispatch(new SIGN_OUT());
  }

  backToAlphaSystem() {
    this.store.dispatch(new GET_SWITCH_SITE("SAT_URL")).subscribe((state) => {
      let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;
      console.log("SwitchSiteUrl Url : " + switchSiteUrl);
      window.location.href = switchSiteUrl;
    });
  }

  backToSATSystem() {
    this.backToSAT();
  }

  toAllianzMalaysia() {
    window.open("https://www.allianz.com.my", "_blank");
  }
}
