import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { Moment } from 'moment';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { SummaryPopupComponent } from "../../../popup/summary-popup/summary-popup.component";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable, delay, of } from 'rxjs';
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { ViewRemarksComponent } from '@shared/components/view-remarks/view-remarks.component';
import { VehicleViewAmendmentComponent } from '@shared/components/vehicle-view-amendment/vehicle-view-amendment.component';
import {
  CALCULATE_PREMIUM,
  SELECTED_MAKE_MODEL,
  CALCULATE_PREMIUM_SUMMARY,
  GET_CUSTOMER_PARTNER_MOCK,
  GENERATE_QUOTATION,
  SEND_TO_JPJ,
  DOWNLOAD_PDF,
  SET_JPJ_STATUS_SENT,
  CALCULATE_PREMIUM_PAYLOAD,
  GET_DOCUMENT_LOV,
  STORE_ISSUANCE_SUMMARY,
  GET_QUOTATION_DETAIL,
  CANCEL_REPLACE,
  REQUEST_CANCELLATION,
  STORE_AGENT_FROM_RESPONSE,
  STORE_ISSUANCE_FROM_RESPONSE,
  COPY_CNGE_INFO_RENEWAL,
  COPY_CNGE_INFO_VIEW_QUOTATION,
  GET_PRODUCT_LIST,
  STORE_EXTRA_COVER_PACKAGE,
  GET_SWITCH_SITE,
  GET_CROSS_SELL_LIST,
  GET_QUOTATION_DETAIL_REQUOTE,
  COPY_DRAFT_INFO,
  COPY_REQUOTE_INFO,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  COPY_CNGE_INFO,
  CANCEL_QUOTATION,
  CANCEL_COVER_NOTE,
  SET_ACTION,
  GET_AGENT_LIST_AGENT,
  GET_CCENTRIC_OPPORTUNITY_INFO,
  GET_VEHICLE_USE,
  SET_UPDATE_PAYMENT_IND,
  SET_OPPORTUNITY_ID,
  SAVE_REFERRAL_REMARKS,
  AMEND_OPENDATE,
  IS_CANCEL_REPLACE_COVER_NOTE,
  RESEND_JPJ
}
  from "@store/sat-motor/sat-motor.action";
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { SharedModule } from '@shared/shared.module';
import { PaymentModeComponent } from '@shared/components/modal/payment-mode/payment-mode.component';
import { OneAllianzMessageComponent } from '@shared/components/modal/one-allianz-message/one-allianz-message.component';
import { PaymentDetailsComponent } from '@shared/components/payment-details/payment-details.component';
import { UpsellProductComponent } from '@shared/components/upsell-product/upsell-product.component';
import { MakeModelComponent } from "@shared/components/modal/make-model/make-model.component";
import { NumberRangeValidator } from "@shared/classes/validators/number-range.validator";
import { UserResponse } from "@interfaces/user";
import { CoverageSummaryComponent } from '@shared/components/coverages/coverage-summary/coverage-summary.component';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { EnquiryPopupComponent } from 'src/app/popup/enquiry-popup/enquiry-popup.component';
import { RequestCancellationComponent } from '@shared/components/modal/request-cancellation/request-cancellation.component';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { ACTIONS } from '@shared/enums/index.enum';
import { ClauseMessageComponent } from '@shared/components/modal/clause-message/clause-message.component';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { EmailDocumentComponent } from '@shared/components/modal/email-document/email-document.component';
import { PaymentComponent } from '@shared/components/payment/payment.component';
import { RoadTaxPayableComponent } from '@shared/components/road-tax-payable/road-tax-payable.component';
import { PaymentInformationComponent } from '@shared/components/payment-information/payment-information.component';
import { ViewRoadTaxPayableComponent } from '@shared/components/view-road-tax-payable/view-road-tax-payable.component';
import { ViewUpsellProductComponent } from '@shared/components/view-upsell-product/view-upsell-product.component';
import { ConfirmationModalComponent } from '@shared/components/modal/confirmation-modal/confirmation-modal.component';
import { RemarksComponent } from '@shared/components/modal/remarks/remarks.component';
import {StatusMessageComponent} from "@shared/components/status-message/status-message.component";

@Component({
  selector: 'app-view-quotation',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    ProgressBarComponent,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxRadioModule,
    SharedModule,
    PaymentDetailsComponent,
    UpsellProductComponent,
    CoverageSummaryComponent,
    OkMessageComponent,
    CrossSellBannerComponent,
    ViewRemarksComponent,
    VehicleViewAmendmentComponent,
    PaymentComponent,
    RoadTaxPayableComponent,
    PaymentInformationComponent,
    ViewRoadTaxPayableComponent,
    ViewUpsellProductComponent,
    StatusMessageComponent
  ],
  templateUrl: './view-quotation.component.html',
  styleUrl: './view-quotation.component.scss'
})
export class ViewQuotationComponent implements OnInit {
  @ViewChild(VehicleViewAmendmentComponent) vehicleAmendData!: VehicleViewAmendmentComponent;
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.commonLov) commonLov$;

  isIssuance: boolean = false;
  isEndorsement: boolean = false;
  isEnquiry: boolean = false;
  isRTP: boolean = false;
  isCCentric: boolean = false;

  requestCancellationModal?: NxModalRef<RequestCancellationComponent>;
  clauseMsgModal?: NxModalRef<ClauseMessageComponent>;
  emailDocumentModal?: NxModalRef<EmailDocumentComponent>;
  confirmMessageModal?: NxModalRef<ConfirmationModalComponent>;
  remarksModal?: NxModalRef<RemarksComponent>;
  cnoteStatus: any;
  quotationDetail: any;
  progressType: any;
  userInfo: any;
  agentData: any;
  issuanceInfo: any = {};
  clientClaimHistory: any;
  vehicleClaimHistory: any;
  premiumInfo: any;
  coverageInfo: any;
  cpData: any;
  lov: any;
  isProgressTypeCN: boolean = false;
  isProgressTypeENDST: boolean = false;
  isProgressTypeRTP: boolean = false;

  showAgentInfo: boolean = false;
  showIssuanceInfo: boolean = false;
  showCustomerPartner: boolean = false;
  showReferralDetails: boolean = false;
  showCoverage: boolean = false;
  showHistoricalClaim: boolean = false;
  showPremiumInformation: boolean = false;
  showReferCase: boolean = false;
  showPayment: boolean = false;
  showUpsellProduct: boolean = false;
  showPaymentInformation: boolean = false;
  showPaymentDetails: boolean = false;
  showChecklist: boolean = false;
  showRenewalAssistant: boolean = false;
  showEndorsementNarration: boolean = false;
  showRoadTaxPayable: boolean = false;
  showEndorsementErrMessage: boolean = false;
  sundriesAgent: boolean = false;
  showRemarks: boolean = false;
  contractId: any;
  mock: any;
  token: any;
  referralForm: FormGroup;
  referCaseForm: FormGroup;
  callIssuanceInformation: boolean = false;
  viewQuotation: boolean = true;
  popupMessage: any;
  referralReasonList: any;
  status: any;
  classType: any;
  type: any;
  proposalNo: any;
  productName: any;
  dialogRef?: NxModalRef<any>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  productList: any;
  remarksInfo: any;
  agentReferId: string;
  responseData: any;
  cngeNote: any;
  isDataLoaded: boolean = false;
  queryParams: any = {};
  crossSellList: any = [];
  marketingConsent: any;
  isUpdateJPJScreen: boolean = false;
  isAmendDateScren: boolean = false;

  isShowBackBtn: boolean = false;
  isShowRequestCancellationBtn: boolean = false;
  isShowUpdateQuotationBtn: boolean = false;
  isShowSendToJPJBtn: boolean = false;
  isShowUploadDocumentBtn: boolean = false;
  isShowAddRemarksBtn: boolean = false;
  isUpdatePaymentBtn: boolean = false;
  isShowDownloadQuotationBtn: boolean = false;
  isShowDownloadCoverNoteBtn: boolean = false;
  isShowDownloadInvoiceBtn: boolean = false;
  isShowUpdateJPJBtn: boolean = false;
  isShowAmendCoverNoteBtn: boolean = false;
  isShowEmailDocumentBtn: boolean = false;
  isShowRequoteBtn: boolean = false;
  isShowMarketingBtn: boolean = false;
  isShowIssueNewBtn: boolean = false;

  motorPC: any;
  lovproductConfig: any;
  productAccessListData: any;
  lovPC: string;
  lovGeo: any;
  emailCheck : any;
  edocConsentInd: boolean = false;
  eInvoiceConsentInd : boolean;
  payment: any;
  paymentList: any;
  roadTaxPayable: any;
  paymentInformation: any;
  upsellProduct: any;
  agentInfo: any;
  isNotAllowCancel: boolean = false;

  yearList = [
    { code: '2024', name: '2024' },
    { code: '2025', name: '2025' },
    { code: '2026', name: '2026' },
    { code: '2027', name: '2027' }
  ];

  statusMap = {
    PRINTED: "Printed",
    APPROVED: "Approved",
    PENDING: "Pending Approval",
    CANCELLED: "Cancelled",
    SUBMITTED: "Submitted",
    ACTIVE: "Active",
    EXPIRED: "Expired"
  };

  classTypeMap = {
    PRINTED: "printed_badge",
    APPROVED: "approved_badge",
    PENDING: "pending_badge",
    CANCELLED: "cancel_badge",
    SUBMITTED: "submitted_badge",
    ACTIVE: "active_badge",
    EXPIRED: "expired_badge"
  };


  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;

  alimCustomerInd: boolean;
  hasShowedOneAllianzPopUp: boolean;
  oneAllianzModal?: NxModalRef<OneAllianzMessageComponent>;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.referralForm = this.fb.group({
      referralId: ['', Validators.required]
    });

    this.referCaseForm = this.fb.group({
      waiveLoadInd: [{ value: false, disabled: true }],
      waiveExcessInd: [{ value: false, disabled: true }],
      underwritingInd: [true],
      remarks: ['', Validators.required]
    });
  }

  renewalAssistanceForm = new FormGroup({
    renewalAssistant: new FormControl(false),
    paymentMode: new FormControl(''),
    creditCardNo: new FormControl(''),
    creditCardExpiryDateMonth: new FormControl(''),
    creditCardExpiryDateYear: new FormControl(''),
    nameOnCard: new FormControl(''),
    termofUse: new FormControl(false),
    renewalAssistanceStartDate: new FormControl('')
  });

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.queryParams = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1?.issuance?.pdpa?.consentDetails?.edocConsentInd);
    this.eInvoiceConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1?.issuance?.pdpa?.consentDetails?.einvoiceConsentInd);
    this.isCCentric = this.queryParams.opportunityId;
    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

    this.commonLov$.subscribe((state) => {
      if (state) {
        this.lov = state;
      }
    });

    if (this.isRTP) {
      this.store.dispatch(new GET_QUOTATION_DETAIL(this.queryParams.contractId, "SUMMARY")).subscribe((state) => {
        const rtpPolicy = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto);
        const sendJPJResponse = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step2?.sendToJPJ?.data?.cngeNoteDto);
        
        this.populateCommonData(state, sendJPJResponse);
        
        if (!this.issuanceInfo.issuance.eCoverNoteNo) {
          this.issuanceInfo = {
            ...this.issuanceInfo,
            issuance: {
              ...this.issuanceInfo.issuance,
              eCoverNoteNo: sendJPJResponse.cnoteNo
            }
          };
        }
        
        this.isProgressTypeRTP = true;
        this.progressType = 'rtp';
        this.showComponents();
        this.isDataLoaded = true;
      });
    } else if(this.isCCentric) {
      this.type = 'view-quotation-enquiry';
      this.popupMessage = 'Your e-Cover Note has been submitted as pending approval.';

      this.searchCoverNoteByOpportunityId();
      
    } else {
      this.type = 'view-quotation-enquiry';
      this.popupMessage = 'Your e-Cover Note has been submitted as pending approval.';

      this.searchCoverNoteByContractId();
      
    }

  }

  searchCoverNoteByOpportunityId() {
    const param = {
      "opportunityId": this.queryParams.opportunityId
    };

    this.store.dispatch(new GET_CCENTRIC_OPPORTUNITY_INFO(param)).subscribe((state) => {
      this.agentInfo = state.SatMotorState.cCentricInfo.mkagAgent;
      if(state.SatMotorState.viewQuotationData){
        this.getVehicleLov(state.SatMotorState.viewQuotationData?.cngeNote?.productCode, true);
      }
      this.populateCommonData(state, null);

      this.showRemarks = !!this.remarksInfo;
      this.showReferralDetails = this.userInfo.userType === 'AG' || this.responseData.mkagAgent.sundriesAgent;

      const getCrossSellListPayload = {
        "agentCode": this.agentData.agentCode,
        "partnerId": this.cpData.partnerId,
        "idType": this.cpData.idType1,
        "idValue": this.cpData.idValue1
      };
      this.store.dispatch(new GET_CROSS_SELL_LIST(getCrossSellListPayload, this.cngeNote.productCode)).subscribe(state => {
        this.crossSellList = state.SatMotorState.data.step5.crossSellList;
        this.marketingConsent = state.SatMotorState.data.step5.marketingConsent;
        console.log("cross sell list: ", this.crossSellList);
      });

      this.store.dispatch(new CALCULATE_PREMIUM_SUMMARY({ cngeNote: state.SatMotorState.viewQuotation })).subscribe(state => {
        let premiumResponse = state.SatMotorState.summaryPremium;
        this.getPaymentData(premiumResponse);
      });

      this.isProgressTypeCN = true;
      this.progressType = 'cn';
      this.showComponents();
      this.updateButtons(this.cngeNote, this.issuanceInfo.issuance);
      this.isIssuance = true;
      this.isDataLoaded = true;
      window.scroll({ top: 0, left: 0, behavior: 'instant' });
    });
  }

  searchCoverNoteByContractId() {
    this.store.dispatch(new GET_QUOTATION_DETAIL(this.queryParams.contractId, "SUMMARY")).subscribe((state) => {
      this.getVehicleLov(state.SatMotorState.viewQuotationData?.cngeNote?.productCode, true);
      this.populateCommonData(state, null);

      this.showRemarks = !!this.remarksInfo;
      this.showReferralDetails = this.userInfo.userType === 'AG' || this.responseData.mkagAgent.sundriesAgent;

      const getCrossSellListPayload = {
        "agentCode": this.agentData.agentCode,
        "partnerId": this.cpData.partnerId,
        "idType": this.cpData.idType1,
        "idValue": this.cpData.idValue1
      };
      this.store.dispatch(new GET_CROSS_SELL_LIST(getCrossSellListPayload, this.cngeNote.productCode)).subscribe(state => {
        this.crossSellList = state.SatMotorState.data.step5.crossSellList;
        this.marketingConsent = state.SatMotorState.data.step5.marketingConsent;
        console.log("cross sell list: ", this.crossSellList);
      });
      this.isProgressTypeCN = true;
      this.progressType = 'cn';
      this.showComponents();
      this.updateButtons(this.cngeNote, this.issuanceInfo.issuance);
      this.isIssuance = true;
      this.isDataLoaded = true;
      window.scroll({ top: 0, left: 0, behavior: 'instant' });

      this.store.dispatch(new GET_DOCUMENT_LOV({ 
        lob: "MT", 
        userId: this.userInfo.userId, 
        productCode: this.issuanceInfo.issuance.productCode,
        type: "SATMTCNDOCTYPE" }));
    });
  }

  getVehicleLov(productCode, isViewQuotation) {
    let payload = {
      productCode: productCode,
      isViewQuotation: isViewQuotation
    }
    this.store.dispatch(new GET_VEHICLE_USE(payload));
  }

  populateCommonData(state: any, sendJPJResponse: any) {
    this.responseData = state.SatMotorState.viewQuotationData;
    this.cngeNote = this.responseData.cngeNote;
    this.status = this.statusMap[this.cngeNote.cnoteStatus] || "";
    this.classType = this.classTypeMap[this.cngeNote.cnoteStatus] || "";
    this.proposalNo = sendJPJResponse ? sendJPJResponse.cnoteNo : this.cngeNote.cnoteNo || this.cngeNote.proposalNo;
  
    this.agentData = this.responseData.agentData;
    this.issuanceInfo = {
      ...this.responseData.issuanceInfo,
      issuance: {
        ...this.responseData.issuanceInfo.issuance,
        vehicleNo: this.responseData.coverageInfo.vehicleDetails.vehicleNo
      }
    };
    this.cpData = this.responseData.cpData;
    this.coverageInfo = this.responseData.coverageInfo;
    this.premiumInfo = this.responseData.premiumInfo;
    this.remarksInfo = this.responseData.remarksInfo;
  
    this.clientClaimHistory = this.cngeNote.claimCnt ?? '0';
    this.vehicleClaimHistory = this.cngeNote.vehClaimCount ?? '0';

    this.agentReferId = this.cngeNote.agentReferId ?? null;
  
    this.updateProductList(this.responseData.mkagAgent.agentCode, this.cngeNote.productCode);
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);

    if(this.cngeNote?.autoRenewalInd){
      this.showRenewalAssistant = true;
    }
  }

  updateButtons(cngeNote: any, issuance: any) {
    const status = cngeNote.cnoteStatus;
    const isPendingApproval = status === 'PENDING';
    const isPrintQuotation = !!(status === 'PRINTED' && cngeNote.proposalNo && !cngeNote.cnoteNo);
    const isPrintCNote = !!(status === 'PRINTED' && cngeNote.cnoteNo);
    const isPrintRTP = cngeNote.cnoteType === 'RP';
    const isCancelled = status === 'CANCELLED';
    const isExpired = status === 'EXPIRED';
    const isSubmitted = status === 'SUBMITTED';

    cngeNote = { ...cngeNote, isPendingApproval, isPrintQuotation, isPrintCNote };
    this.cngeNote = { ...cngeNote };

    this.isShowBackBtn = true;

    if (isPendingApproval) {
      Object.assign(this, {
        isShowRequestCancellationBtn: true,
        isShowAddRemarksBtn: !!this.userInfo.agent && this.userInfo.agent === true,
        isUpdatePaymentBtn: this.showUpdatePayment(cngeNote),
        isShowUploadDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true
      });
    } else if (isPrintQuotation) {
      Object.assign(this, {
        isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote),
        isShowUpdateQuotationBtn: true,
        isShowDownloadQuotationBtn: true,
        //isShowSendToJPJBtn: !!this.userInfo.agent && this.userInfo.agent === true,
        isUpdatePaymentBtn: this.showUpdatePayment(cngeNote),
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true
      });
    } else if (isPrintCNote) {
      Object.assign(this, {
        isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote),
        isUpdatePaymentBtn: this.showUpdatePayment(cngeNote),
        isShowDownloadCoverNoteBtn: true,
        isShowDownloadInvoiceBtn: true,
        isShowUpdateJPJBtn: this.showUpdateUpdateJPJ(cngeNote),
        isShowAmendCoverNoteBtn: this.showAmendCoverNote(cngeNote),
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true,
        isShowUpdateQuotationBtn: this.isCCentric ? true : false,
      });
    } else if (isPrintRTP) {
      this.isShowBackBtn = true;
    } else if (isCancelled || isExpired) {
      if(isCancelled) {
        Object.assign(this, {
          isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote),
          isShowDownloadCoverNoteBtn: false,
          isShowDownloadInvoiceBtn: false,
          isShowUpdateJPJBtn: this.showUpdateUpdateJPJ(cngeNote),
          isShowAmendCoverNoteBtn: this.showAmendCoverNote(cngeNote),
          isShowUploadDocumentBtn: false,
          isShowEmailDocumentBtn: false,
          isShowMarketingBtn: false,
          isShowIssueNewBtn: false,
          isShowRequoteBtn: !!this.userInfo.agent && this.userInfo.agent === true,
          isShowUpdateQuotationBtn: false,
          isShowDownloadQuotationBtn: false,
          //isShowSendToJPJBtn: false,
        });
      }else {
        this.isShowRequoteBtn = !!this.userInfo.agent && this.userInfo.agent === true;
      }
    } else if (isSubmitted) {
      Object.assign(this, {
        isUpdatePaymentBtn: this.showUpdatePayment(cngeNote),
        isShowDownloadCoverNoteBtn: true,
        isShowDownloadInvoiceBtn: true,
        isShowAmendCoverNoteBtn: true,
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true
      });
    }
  }

  showRequestCancellation(cngeNote: any): any {
    const {
      cnoteStatus, jpjStatus, cnoteType, endtCode,
      policyNo, endtNo, createBy, updateBy
    } = cngeNote;
    const { userId, issueOnBehalf, issueOnEndorsement } = this.userInfo;

    let hideButton = false;

    if (cnoteStatus !== "CANCELLED") {
      if (cngeNote.isPrintCNote) {
        hideButton =
          (cnoteStatus === 'CANCELLED') ||
          (cnoteStatus === 'PRINTED' && jpjStatus === 'SENT' && cnoteType !== 'OTTEMP') ||
          (cnoteStatus === 'PRINTED' && jpjStatus === 'SENT' && !endtCode && policyNo) ||
          (cnoteStatus === 'PRINTED' && jpjStatus === 'SENT' && endtCode && cnoteType === 'ENDT' && endtNo) ||
          ((issueOnBehalf === true || issueOnEndorsement === true) && (createBy !== userId && updateBy !== userId));
      }

      if (cngeNote.isPrintQuotation) {
        hideButton = hideButton || ((issueOnBehalf === true || issueOnEndorsement === true) && (createBy !== userId && updateBy !== userId))
          || cnoteStatus === 'CANCELLED';
      }
    }else {
      hideButton = true;
    }

    return !hideButton;
  }  

  showUpdatePayment(cngeNote: any): any {
    const isCallCenterOrBanca = this.userInfo.callCentreOrBancaAgent;
    const { userId, issueOnBehalf, issueOnEndorsement } = this.userInfo;
    const {
      cnoteStatus, createBy
    } = cngeNote;

    let hideButton = false;

    if (!isCallCenterOrBanca ||
      cnoteStatus === "CANCELLED" ||
      (cnoteStatus === "PENDING" && (issueOnBehalf === true || issueOnEndorsement === true) && createBy !== userId)) {
      hideButton = true;
    }

    return !hideButton;
  }

  showUpdateUpdateJPJ(cngeNote: any): any {
    const {
      cnoteStatus, jpjStatus, createBy, updateBy
    } = cngeNote;
    const { userId, issueOnBehalf, issueOnEndorsement } = this.userInfo;

    let hideButton = false;

    if ((cnoteStatus !== "PRINTED" || jpjStatus !== "NOT OK" ||
      issueOnBehalf === true || issueOnEndorsement === true) ||
      (createBy !== userId && updateBy !== userId)
    ) {
      hideButton = true;
    }

    return !hideButton;
  }

  showAmendCoverNote(cngeNote: any): any {
    const {
      cnoteStatus, jpjStatus, createBy, updateBy
    } = cngeNote;

    const { userId, issueOnBehalf, issueOnEndorsement } = this.userInfo;
    let hideButton = false;

    if (
      !((cnoteStatus === "PRINTED") && (jpjStatus === "OK" || jpjStatus === "USED")) ||
      issueOnBehalf === true ||
      issueOnEndorsement === true ||
      (createBy !== userId &&
      updateBy !== userId)
    ) {
      hideButton = true;
    }

    return !hideButton;
  }

  async updateProductList(agentCode, productCode) {
    this.productList = this.lov?.productList;
    if (!this.productList) {
      let productListPayload = {
        lob: 'MT',
        agentCode: agentCode
      };

      await firstValueFrom(this.store.dispatch(new GET_PRODUCT_LIST(productListPayload)));
      this.productList = this.lov?.productList;
    }

    let product = this.productList.find(e => e.code === productCode);
    if (product) {
      let descp = product.descp
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
      console.log(this.productList);
      this.productName = {
        descp: descp
      }
    }

    this.getCoverDescription(productCode);
  }

  getCoverDescription(productCode) {
    const productAccessConfigList = this.lov?.productAccessConfigList;
    if (!productAccessConfigList) return;

    const coverListLov = productAccessConfigList.reduce((list, product) => {
      return list.concat(product[productCode]?.coverList || []);
    }, []);

    const coverCodeVal = this.coverageInfo?.coverageDetails?.coverCode;

    if (coverCodeVal) {
      const matchingCover = coverListLov.find(cover => cover.coverCode === coverCodeVal);
      if (matchingCover) {
        const updatedCoverageDetails = {
          ...this.coverageInfo.coverageDetails,
          coverDescription: matchingCover.coverDescription
        };

        this.coverageInfo = {
          ...this.coverageInfo,
          coverageDetails: updatedCoverageDetails
        };
      }
    }
  }

  getCoverageFromPolicy(policy) {
    let riskList = policy.riskList[0];
    let coverageData: any;

    coverageData = {
      coverage: {
        coveragePremium: {
          cngeNote: policy,
        },
        coverageType: {
          ...riskList.coverList[0],
        },
        extracover: {
          driverNameDetails: {
            rows: riskList.cngeRiskDrvrDtoList,
          },
          ehailingDriverDetails: {},
          extraCoverPackage: {},
        },
        vehicleDetails: {
          ...riskList,
        }
      }
    };

    if (coverageData) {
      this.coverageInfo = coverageData;
    }
  }

  showComponents() {
    if (this.isProgressTypeCN) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.showReferCase = false;

      if (this.isCCentric && this.userInfo.callCenter) {
        this.showPaymentDetails = true;
        this.showPaymentInformation = true;
        this.showPayment = true;
        this.showRoadTaxPayable = true;
        this.showUpsellProduct = true;
      }
    }

    if (this.isRTP) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.viewQuotation = true;
      this.progressType = 'rtp';
    }
  }

  componentDialogRef?: NxModalRef<SummaryPopupComponent>;

  openPopup(popup_type: any) {

    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }
  componentDialogRef1?: NxModalRef<EnquiryPopupComponent>;

  openPopupQuot(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EnquiryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "400px",
      maxWidth: "400px",
      data: { popup_type: popup_type },
    });
  }


  openPopupOCC(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      height: '500px',
      data: { popup_type: popup_type },
    });
  }

  goToIssueDeclaration() {

    this.router.navigate(['issuance/declaration-issuance-info'], {
      queryParamsHandling: 'preserve',
    });

  }

  validateReferral() {

    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
    }

  }

  validateReferCase() {
    if (this.referCaseForm.invalid) {
      console.log("referCaseForm invalid!");
      this.referCaseForm.markAllAsTouched();
    } else {
      console.log("referCaseForm valid...");
    }
  }

  validateRenewalAssistance() {
    if (this.renewalAssistanceForm.invalid) {
      console.log("renewalAssistanceForm invalid!");
      this.renewalAssistanceForm.markAllAsTouched();
    } else {
      console.log("renewalAssistanceForm valid...");
    }
  }

  redirectBack() {
      this.redirectToSummary();
  }

  redirectToSummary() {
    this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe(state => {
      window.location.href = state.SatMotorState.data.switchSiteUrl;
    });
  }

  sendToJPJ() {

    let cngeNotePayload;
    let address1 = this.cpData.clientPartnerAddress.clientAddress1Tmp;
    let emailValue = this.cpData.clientEmail;
    this.motorPC = this.store.selectSnapshot<any>((state)=>state.SatMotorState.motor.step1?.issuance?.issuance?.productCode);
    this.lovproductConfig = this.lov.productAccessConfigList;

    for (let i = 0; i < this.lovproductConfig.length; i++) {
      this.productAccessListData = this.lovproductConfig[i];
      this.lovPC = Object.keys( this.productAccessListData)[0]; 
    
        if (this.lovPC === this.motorPC) {
         this.emailCheck = this.productAccessListData[this.lovPC].emailCheckEnableSat;
        }
    }
  
    if(((address1 =="") && (emailValue =="")) && ((this.edocConsentInd) || (this.emailCheck =="true") || (this.eInvoiceConsentInd))){
      this.openPopupQuot("addrEmailQuo");
    }
    else if(((address1!="") && (emailValue =="")) && ((this.edocConsentInd) || (this.emailCheck =="true") || (this.eInvoiceConsentInd))){
      this.openPopupQuot("emailQuo");
    }
    else if ((address1 == "") && ((emailValue != "")||(emailValue==""))) {
      this.openPopupQuot("address1Quo");
    }
    else {
      cngeNotePayload = {
        data: this.generateSendToJPJPayload(),
        isCngeNoteExist: true
      }

      let resetPayload = {
        isRTP: false
      };

      this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
      this.store.dispatch(new SEND_TO_JPJ(cngeNotePayload)).subscribe(() => {
        let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);
        if (response && response.data && response.success && response.data?.contractId) {
          let updatedContractId = response?.data?.contractId;
          if (updatedContractId && response?.data?.cnoteStatus === 'PRINTED') {
            this.cngeNote = {
              ...this.cngeNote,
              contractId: updatedContractId
            }
            this.downloadPDF('covernote', true);
          }
          this.searchCoverNoteByContractId();
        }

        let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
        if (message) {
          this.okMessageModal = this.dialogService.open(OkMessageComponent, {
            width: '400px',
            data: {
              message: message
            }
          });
        } else if (response.messageConfirm && response.messageHeader) {
          this.showConfirmationMessage(response, cngeNotePayload, 'sendToJPJ');
        }

      });
    }
  }

  showConfirmationMessage(response, payload, apiName) {
    this.confirmMessageModal = this.dialogService.open(ConfirmationModalComponent, {
      showCloseIcon: false, width: "550px", maxWidth: "550px",
      data: { message: response.messageConfirm, type: response.messageHeader },
    });
  
    this.confirmMessageModal.afterClosed().subscribe(result => {
      if (result) {
        let resendPayload = {
          data: {
            cngeNote: {
              ...payload.data.cngeNote,
              skipErrorAndProceed: true
            },
            operator: this.userInfo.userId
          },
          isCngeNoteExist: true
        };
        
        if (apiName === 'amendOpenDate') {
          this.store.dispatch(new AMEND_OPENDATE(resendPayload)).subscribe(() => {
            let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.amendOpenDate ?? null);
            this.handleResponse(response, payload, apiName);
          });
        } else if (apiName === 'resendJPJ') {
          let resendJPJPayload = {
            ...payload,
            skipErrorAndProceed: true
          };

          this.store.dispatch(new RESEND_JPJ(resendJPJPayload)).subscribe(() => {
            let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.resendJPJ ?? null);
            this.handleResponse(response, payload, apiName);
          });
        } else {
          this.store.dispatch(new SEND_TO_JPJ(resendPayload)).subscribe(() => {
            let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);
            this.handleResponse(response, payload, apiName);
          });
        }
      }
    });
  }
  
  handleResponse(response, payload, apiName) {
    console.log(response);
  
    if (response && response.data && response.success && response.data.contractId) {
      let updatedContractId = response?.data?.contractId;
      if (updatedContractId && response?.data?.cnoteStatus === 'PRINTED') {
        this.cngeNote = {
          ...this.cngeNote,
          contractId: updatedContractId
        };
        this.downloadPDF('covernote', true);
      }
      this.searchCoverNoteByContractId();
      this.exitUpdateScreen();
    }
  
    let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
    if (message) {
      this.okMessageModal = this.dialogService.open(OkMessageComponent, {
        width: '400px',
        data: {
          message: message
        }
      });
    } else if (response.messageConfirm && response.messageHeader) {
      this.showConfirmationMessage(response, payload, apiName);
    }
  }
  

  generateSendToJPJPayload() {
    let cngeNoteView = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation);
    let data = {
      cngeNote: {
        ...cngeNoteView,
        cnoteDate: new Date().toISOString().substring(0, 10),
        issueBy: this.userInfo.userId,
        issuedDate: new Date().toISOString().substring(0, 10)
      },
      operator: this.userInfo.userId,
    };

    return data;
  }

  downloadPDF(type: string, autoDownload: boolean) {
    const payload: any = {
      operator: this.userInfo.userId,
      contractId: this.cngeNote.contractId,
      type: type,
    };
  
    let vehicleNo = this.responseData?.riskList[0]?.vehicleNo ?? null;
    let cnoteType = this.cngeNote.cnoteType;

    if (type === 'covernote' && !autoDownload && !(cnoteType === 'NWNN' && vehicleNo === 'NA')) {
      this.clauseMsgModal = this.dialogService.open(ClauseMessageComponent, {
        width: '450px',
        maxWidth: '450px',
      });
      this.clauseMsgModal.afterClosed().subscribe(result => {
        payload.printClauseInd = (result === 'Y');
        this.store.dispatch(new DOWNLOAD_PDF(payload));
      });
    } else {
      this.store.dispatch(new DOWNLOAD_PDF(payload)).subscribe();
    }
  }

  onChangeRenewalAssistanceForm(fieldName: any, value: any) {
    console.log('onChangeValue..' + value.checked);
    if (fieldName == 'renewalAssistant') {
      //this.renewalAssistanceForm.controls.paymentMode.reset();
      this.renewalAssistanceForm.controls.paymentMode.clearValidators();
      this.renewalAssistanceForm.controls.creditCardNo.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.clearValidators();
      this.renewalAssistanceForm.controls.nameOnCard.clearValidators();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.clearValidators();
      this.renewalAssistanceForm.controls.termofUse.clearValidators();
      if (value == true) {
        this.renewalAssistanceForm.controls.paymentMode.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.setValidators([Validators.required]);
      } else {
        this.renewalAssistanceForm.controls.paymentMode.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.removeValidators([Validators.required]);
      }
      this.renewalAssistanceForm.controls.paymentMode.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardNo.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.updateValueAndValidity();
      this.renewalAssistanceForm.controls.nameOnCard.updateValueAndValidity();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.updateValueAndValidity();
      this.renewalAssistanceForm.controls.termofUse.updateValueAndValidity();
    }
  }

  uploadDocument() {

    let dialogConfig = {
      showCloseIcon: false,
      width: "80%",
      maxWidth: "80vw",
      maxHeight: "95vh",
      data: {
        contractId: this.cngeNote.contractId,
        productCode: this.cngeNote.productCode
      }
    };

    this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {  }
    });

  }

  prepareCancellationData() {
    this.store.dispatch(new GET_QUOTATION_DETAIL(this.cngeNote.contractId, "SUMMARY")).subscribe((state) => {
      let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.viewQuotation);
      
      if (cngeNote) {
        console.log("Current JPJ status: "+cngeNote.jpjStatus);
        this.cngeNote = cngeNote;
        this.openRequestCancellationPopUp();
      }
    });
  }

  openRequestCancellationPopUp() {

    if(this.cngeNote.cnoteNo && this.cngeNote.cnoteStatus === "PRINTED" && this.cngeNote.jpjStatus === "SENT") {
      this.okMessageModal = this.dialogService.open(OkMessageComponent, {
        width: "410px",
        maxWidth: "410px",
        showCloseIcon: false,
        data: {
          message: "JPJ Status Sent, please try again."
        }
      });
      return;
    }

    let data = {
      summaryData: this.cngeNote
    }

    this.requestCancellationModal = this.dialogService.open(RequestCancellationComponent, {
      showCloseIcon: false,
      autoFocus: false,
      data: data
    });

    this.requestCancellationModal.afterClosed().subscribe(result => {
      if(result) {
        let payload = {
          cancelRemarks: result.data.cancelRemarks,
          cancelReasonCode: result.data.cancellationReason,
          contractId: this.cngeNote.contractId,
          operator: this.userInfo.userId
        };

        if (result.isCancelAndReplace) {

          if(this.hasCoverNoteGenerated() && this.cngeNote.jpjStatus === "OK" && this.cngeNote.policyNo) {
            this.isNotAllowCancel = true;
            of(window).pipe(delay(300)).subscribe(() => {
              window.scroll(0, 0);
            });
            
          } else {
            this.store.dispatch(new CANCEL_REPLACE(payload)).subscribe((state) => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              // let populateData = {
              //   ...cancellationRes,
              //   ...state.SatMotorState.viewQuotation
              // };
              this.store.dispatch(new GET_PRODUCT_LIST({ lob: cancellationRes.productType, agentCode: cancellationRes.mkagAgent.agentCode })).subscribe((state) => {
                this.store.dispatch(new COPY_CNGE_INFO_VIEW_QUOTATION(cancellationRes)).subscribe(state => {
                  this.store.dispatch(new SET_ACTION("ISSUE"));
                  this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ({
                    isIssuance: true
                  }));
                  this.store.dispatch(new IS_CANCEL_REPLACE_COVER_NOTE(state.SatMotorState.viewQuotation));
                  this.router.navigate(['issuance/summary']);
                });
              });
            });
          }
          
        } else {
          if(this.cngeNote.isPrintQuotation) {
            this.store.dispatch(new CANCEL_QUOTATION(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.searchCoverNoteByContractId();
              }
            });
          } else {
            this.store.dispatch(new CANCEL_COVER_NOTE(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.searchCoverNoteByContractId();
              }
            });
          }
        }
      }
    });
  }

  updateQuotation() {
    if (this.isCCentric) {
      const param = {
        "opportunityId": this.queryParams.opportunityId
      };
      this.store.dispatch(new GET_CCENTRIC_OPPORTUNITY_INFO(param)).subscribe((state) => {
        let updateQuotationDetails = state.SatMotorState.viewQuotation;
        if (updateQuotationDetails) {
          if (updateQuotationDetails.cnoteType === 'ENDT') {
            this.store.dispatch(new SET_ACTION(ACTIONS.ENDT));
          } else if (updateQuotationDetails.cnoteType === 'RP') {
            this.store.dispatch(new SET_ACTION(ACTIONS.RTP));
          } else {
            this.store.dispatch(new SET_ACTION(ACTIONS.ISSUE));
          }
          this.store.dispatch(new GET_PRODUCT_LIST({ lob: updateQuotationDetails.productType, agentCode: updateQuotationDetails.mkagAgent.agentCode })).subscribe((stateLevel1) => {
            this.store.dispatch(new COPY_CNGE_INFO_VIEW_QUOTATION(updateQuotationDetails)).subscribe(stateLevel2 => {
              const vehicleDetails = stateLevel2.SatMotorState.motor.step3.coverage.vehicleDetails;
              if (vehicleDetails?.makeModel && vehicleDetails?.vehModelNvic) {
                this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: updateQuotationDetails })).subscribe(stateLevel3 => {
                  let premiumResponse = stateLevel3.SatMotorState.motor.step3.coverage.coveragePremium.cngeNote;
                  this.store.dispatch(new COPY_CNGE_INFO_VIEW_QUOTATION(premiumResponse));
                  this.router.navigate(["/issuance/summary"]);
                });
              } else {
                this.router.navigate(["/issuance/coverage"]);
              }

            });
          });
        }
      });
    } else {
      this.store.dispatch(new GET_QUOTATION_DETAIL_REQUOTE(this.queryParams.contractId, "SUMMARY-UPDATE")).subscribe((state) => {
        let updateQuotationDetails = state.SatMotorState.updateQuotation;
        if (updateQuotationDetails) {
          if (updateQuotationDetails.cnoteType === 'ENDT') {
            this.store.dispatch(new SET_ACTION(ACTIONS.ENDT));
          } else if (updateQuotationDetails.cnoteType === 'RP') {
            this.store.dispatch(new SET_ACTION(ACTIONS.RTP));
          } else {
            this.store.dispatch(new SET_ACTION(ACTIONS.ISSUE));
          }
          this.store.dispatch(new GET_PRODUCT_LIST({ lob: updateQuotationDetails.productType, agentCode: updateQuotationDetails.mkagAgent.agentCode })).subscribe((state) => {
            this.store.dispatch(new COPY_CNGE_INFO_VIEW_QUOTATION(updateQuotationDetails)).subscribe(state => {
              if (updateQuotationDetails) { // remove .progress impact dashboard summary(renewal) >  update quotation
                //cr14
                if (updateQuotationDetails?.riskList[0]?.coverList[0]?.subCovPackage) {
                  updateQuotationDetails = {
                    ...updateQuotationDetails,
                    riskList: [
                      {
                        ...updateQuotationDetails.riskList[0],
                        coverList: [
                          {
                            ...updateQuotationDetails.riskList[0].coverList[0],
                            coverDto: {
                              ...updateQuotationDetails.riskList[0].coverList[0].coverDto,
                              extCovPackageSelected: updateQuotationDetails.riskList[0].coverList[0].subCovPackage
                            }
                          }
                        ]
                      }
                    ]
                  };
                }

                this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: updateQuotationDetails }));
              }
              this.router.navigate(["/issuance/summary"]);

            });
          });
        }
      });
    }
  }

  showRemarksModal() {
    this.remarksModal = this.dialogService.open(RemarksComponent, {
      showCloseIcon: false,
      autoFocus : false,
      data: {
        title: "Enter Remarks",
        primaryButtonLabel: "SUBMIT"
      }
    });

    this.remarksModal.afterClosed().subscribe(result => {
      if(result) {
        const data = {
          contractId : this.cngeNote.contractId,
          remark: result.remarks,
          operator: this.userInfo.userId,
          isViewQuotation: true
        };

        this.store.dispatch(new SAVE_REFERRAL_REMARKS(data)).subscribe(state => {
          let referRemarkList = state.SatMotorState.viewQuotation?.cngeNote?.referRemarkList ?? null;
          if (referRemarkList) {
            this.remarksInfo = {
              ...this.remarksInfo,
              referral: referRemarkList
            }
          }
        });
      }
    });
  }

  updatePayment() {
    const param = {
      "opportunityId": this.queryParams.opportunityId
    };
    this.store.dispatch(new GET_CCENTRIC_OPPORTUNITY_INFO(param)).subscribe((state) => {
      let updatePaymentDetails = state.SatMotorState.requoteQuotation;
      if (updatePaymentDetails) {
        this.store.dispatch(new SET_OPPORTUNITY_ID(updatePaymentDetails.oppurtunityId));
        this.store.dispatch(new SET_UPDATE_PAYMENT_IND(true));
        this.store.dispatch(new SET_ACTION(ACTIONS.ISSUE));
        this.store.dispatch(new GET_PRODUCT_LIST({ lob: updatePaymentDetails.productType, agentCode: updatePaymentDetails.mkagAgent.agentCode })).subscribe((stateLevel1) => {
          this.store.dispatch(new CALCULATE_PREMIUM_SUMMARY({ cngeNote: updatePaymentDetails })).subscribe(stateLevel2 => {
            let premiumResponse = stateLevel2.SatMotorState.summaryPremium;
            this.store.dispatch(new COPY_CNGE_INFO_VIEW_QUOTATION(premiumResponse)).subscribe(stateLevel3 => {
              this.router.navigate(["/issuance/summary"]);
          });
          });
        });
      }
    });
  }

  performCancel() {
    this.redirectToSummary();
  }

  updateJPJDetails() {
    this.isAmendDateScren = false;
    this.isUpdateJPJScreen = true;
  }

  amendCoverNote() {
    this.isUpdateJPJScreen = false;
    this.isAmendDateScren = true;
  }

  emailDocument() {
    let innerWidth = window.innerWidth;
    let popupWidth;
    console.log(innerWidth);
    if (innerWidth < 703) {
      popupWidth = "680";
    } else if (innerWidth >= 703 && innerWidth < 991) {
      popupWidth = "680";
    } else {
      popupWidth = "1100px";
    }
    // return
    this.emailDocumentModal = this.dialogService.open(EmailDocumentComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: popupWidth,
      maxWidth: popupWidth,
      data: { popup_type: 'emaildocument' },
    });
  }

  requote() {
    this.store.dispatch(new GET_QUOTATION_DETAIL_REQUOTE(this.queryParams.contractId, "REQUOTE")).subscribe((state) => {
      let requoteQuotationDetails = state.SatMotorState.requoteQuotation;
      if (requoteQuotationDetails) {

        this.store.dispatch(new GET_PRODUCT_LIST({ lob: requoteQuotationDetails.productType, agentCode: requoteQuotationDetails.mkagAgent.agentCode })).subscribe((state) => {
          this.store.dispatch(new COPY_REQUOTE_INFO(requoteQuotationDetails)).subscribe(state => {
            if (requoteQuotationDetails) { // remove .progress impact dashboard summary(renewal) >  update quotation
              
              //cr14
              if (requoteQuotationDetails?.riskList[0]?.coverList[0]?.subCovPackage) {
                requoteQuotationDetails = {
                  ...requoteQuotationDetails,
                  riskList: [
                    {
                      ...requoteQuotationDetails.riskList[0],
                      coverList: [
                        {
                          ...requoteQuotationDetails.riskList[0].coverList[0],
                          coverDto: { 
                            ...requoteQuotationDetails.riskList[0].coverList[0].coverDto,
                            extCovPackageSelected: requoteQuotationDetails.riskList[0].coverList[0].subCovPackage 
                          }
                        }
                      ]
                    }
                  ]
                };
              }

              this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: requoteQuotationDetails }));
            }
            this.store.dispatch(new SET_ACTION(ACTIONS.ISSUE));
            
            //this.router.navigate(["/issuance/declaration-issuance-info"], {});
            const isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
            if (!isSingleAgent) {
              this.store.dispatch(new GET_AGENT_LIST_AGENT({ userId: this.userInfo.userId })).subscribe(() => {
                this.router.navigate(["/agent-selection"], {});
              });
            } else {
              this.router.navigate(["/issuance/declaration-issuance-info"], {}); // for single agent
            }
          });
        });
      }
    });
  }

  resendJPJAction() {
    if (this.vehicleAmendData && this.vehicleAmendData.vehicleDetailForm) {
      const form = this.vehicleAmendData.vehicleDetailForm;
  
      if (form.valid) {
        this.resendJPJ(form.getRawValue());

      } else {
        form.markAllAsTouched();
      }
    }
  }

  saveCNDate() {
    if (this.vehicleAmendData && this.vehicleAmendData.vehicleDetailForm) {
      const form = this.vehicleAmendData.vehicleDetailForm;
      const vehicleNo = form.get('vehicleNo')?.value;
      const jpjEffectiveDate = form.get('jpjEffectiveDate')?.value;

      if (vehicleNo && jpjEffectiveDate) {
        this.amendOpenDate(form.getRawValue());
      } else {
        form.markAllAsTouched();
      }
    }
  }

  amendOpenDate(formValue) {
    const data = this.generateSendToJPJPayload();

    const updatedRisk = {
      ...data.cngeNote.riskList[0],
      vehicleNo: formValue.vehicleNo,
      jpjEffectiveDate: formValue.jpjEffectiveDate,
      jpjExpiryDate: formValue.jpjExpiryDate,
    };

    const updatedCngeNote = {
      ...data.cngeNote,
      riskList: [updatedRisk],
    };

    const updatedData = {
      cngeNoteDto: updatedCngeNote,
      operator: data.operator,
    };

    const updatedPayload = { data: updatedData };
    console.log(updatedPayload);
    
    let resetPayload = {
      isRTP: false
    };

    this.store.dispatch(new AMEND_OPENDATE(updatedPayload)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.amendOpenDate ?? null);
      if (response && response.data && response.success && response.data?.cngeNoteDto?.contractId) {
        let updatedContractId = response?.data?.cngeNoteDto?.contractId;
        if (updatedContractId && response?.data?.cngeNoteDto?.cnoteStatus === 'PRINTED') {
          this.cngeNote = {
            ...this.cngeNote,
            contractId: updatedContractId
          }
          this.downloadPDF('covernote', true);
        }
        this.searchCoverNoteByContractId();
        this.exitUpdateScreen();
      }

      let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
      if (message) {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: message
          }
        });
      } else if (response.messageConfirm && response.messageHeader) {
        this.showConfirmationMessage(response, updatedPayload, 'amendOpenDate');
      }

    });
  }

  resendJPJ(formValue) {
    const data = this.generateSendToJPJPayload();

    const updatedRisk = {
      ...data.cngeNote.riskList[0],
      vehicleNo: formValue.vehicleNo,
      vehicleEngine: formValue.vehicleEngine,
      vehicleChassis: formValue.vehicleChassis,
    };

    const updatedCngeNote = {
      ...data.cngeNote,
      riskList: [updatedRisk],
      operator: data.operator,
    };

    console.log(updatedCngeNote);
 

    this.store.dispatch(new RESEND_JPJ(updatedCngeNote)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.resendJPJ ?? null);
      if (response && response.data && response.success && response.data?.cngeNoteDto?.contractId) {
        let updatedContractId = response?.data?.cngeNoteDto?.contractId;
        if (updatedContractId && response?.data?.cngeNoteDto?.cnoteStatus === 'PRINTED') {
          this.cngeNote = {
            ...this.cngeNote,
            contractId: updatedContractId
          }
          this.downloadPDF('covernote', true);
        }
        this.searchCoverNoteByContractId();
        this.exitUpdateScreen();
      }

      let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
      if (message) {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: message
          }
        });
      } else if (response.messageConfirm && response.messageHeader) {
        this.showConfirmationMessage(response, updatedCngeNote, 'resendJPJ');
      }

    });
  }

  exitUpdateScreen() {
    this.isUpdateJPJScreen = false;
    this.isAmendDateScren = false;
  }

  getPaymentData(data) {
    this.payment = {
      receiptNo: data.receiptNo,
      transactionDate: data.cnoteDate,
      eCoverNoteNo: data.cnoteNo,
      vehicleNo: data?.riskList[0]?.vehicleNo,
      accountCode: data.mkagAgent.agentCode,
      insuredName: data.clientPartner.clientName,
      totalPremiumDue: data?.riskList[0]?.coverList[0]?.premiumDue,
    };

    this.paymentList = data.paymentList ? data.paymentList : [];

    this.roadTaxPayable = {
      roadTax: data.roadTax,
      myegFees: data.myegFees,
      courierFees: data.courierFees,
      merchantCharges: data.merchantCharges,
      gstRoadTaxAmt: data.gstRoadTaxAmt
    };

    this.paymentInformation = {
      totalPayable: data.totalPayable,
      totalPayableRounded: data.totalPayableRounded,
      totalPaid: data.totalPaid,
      balancePayable: data.balancePayable
    };

    this.upsellProduct = {
      upSellType: data.upSellType,
      upSellAmt: data.upSellAmt
    };
  }

  isDisableEmailButton(): boolean {
    //return this.cngeNote.edocConsentInd ? false : true;
    return false; //AGIC-46575: should be enable regardless e-Document is Yes/No
  }

  hasCoverNoteGenerated() {
    return this.cngeNote.cnoteNo && this.cngeNote.cnoteNo != '';
  }

  get isShowTopMessage() {
    return this.isNotAllowCancel;
  }

  isDisableResendJPJBtn(): boolean {
    if (this.vehicleAmendData && this.vehicleAmendData.vehicleDetailForm) {
      const form = this.vehicleAmendData.vehicleDetailForm;
      return (form.valid) ? false : true;
    } else {
      return true;
    }
  }

  isDisableSaveAmendCNBtn(): boolean {
    if (this.vehicleAmendData && this.vehicleAmendData.vehicleDetailForm) {
      const form = this.vehicleAmendData.vehicleDetailForm;

      const vehicleNo = form.get('vehicleNo')?.value;
      const jpjEffectiveDate = form.get('jpjEffectiveDate')?.value;

      const isDisabled = !(vehicleNo && jpjEffectiveDate);
      return isDisabled;
    }

    return true;
  }

  get isShowCrossSell() {
    if (this.cngeNote?.cnoteStatus === "CANCELLED") {
      return false;
    }

    if (this.isUpdateJPJScreen || this.isAmendDateScren) {
      return false;
    }

    return true;
  }
}