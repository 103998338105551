<base-enquiry [type]="type" [popupMessage]="popupMessage" [referralReasonList]="referralReasonList">
</base-enquiry>

<div nxLayout="grid maxwidth">
    <div nxRow>
        <div nxCol="12" class="nx-margin-top-m">
            <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
                <div nxRow class="enquiry_status">
                    <div nxCol="12,12,10">
                        <h3 nxHeadline size="s" class="nx-font-weight-regular">
                            e-Cover Note No. :
                            <span class="nx-font-weight-bold">{{coverNoteText}}</span>
                        </h3>
                    </div>
                    <div nxCol="12,12,2" class="enquiry_status" 
                        *ngIf="status === 'printed'">
                        <nx-badge type="active">Printed</nx-badge>
                    </div>

                    <div nxCol="12,12,2" class="enquiry_status" 
                        *ngIf="status === 'pending_approval'">
                        <nx-badge type="active" class="pending_badge">Pending <br /> Approval</nx-badge>
                    </div>

                    <div nxCol="12,12,2" class="enquiry_status" 
                        *ngIf="status === 'active'">
                        <nx-badge type="active" class="active_badge">Active</nx-badge>
                    </div>
                </div>
            </div>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Agent Information -->
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Agent Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>
                    
                    <div nxLayout="grid nopadding">
                        <app-agents-information [agentData]="agentData">
                        </app-agents-information>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Issuance Information -->
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space">
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Issuance Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div nxLayout="grid nopadding">
                        <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo">
                        </app-issuance-information>
                    </div>
                </nx-expansion-panel>
            </nx-accordion>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Customer Partner -->
            <nx-accordion variant="light">
                <nx-expansion-panel>
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Customer Partner
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                        <customer-partner-endorsement 
                            [cpData]="cpData">
                        </customer-partner-endorsement>
                </nx-expansion-panel>
            </nx-accordion>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Premium Information -->
            <nx-accordion variant="light">
                <nx-expansion-panel>
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Premium Information
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <app-premium-information 
                        [premiumInfo]="premiumInfo">
                    </app-premium-information>
                </nx-expansion-panel>
            </nx-accordion>



            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Claim Details -->
            <nx-accordion variant="light">
                <nx-expansion-panel>
                    <nx-expansion-panel-header>
                        <nx-expansion-panel-title class="accordion_edit">
                            Claim Details
                        </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <table nxTable nxSort class="enquiry">
                        <thead>
                          <tr nxTableRow>
                            <th nxHeaderCell nxSortHeaderCell="product">Claim No.</th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">Claim Type</th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">Claim Loss Date</th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">Claim Code</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr nxTableRow *ngFor="let claim of claimList">
                                <td nxTableCell>{{ claim.claimNo }}</td>
                                <td nxTableCell>{{ claim.claimType }}</td>
                                <td nxTableCell>{{ claim.claimLossDate }}</td>
                                <td nxTableCell>{{ claim.claimCode }}</td>
                            </tr>
                        </tbody>
                    </table>

                </nx-expansion-panel>
            </nx-accordion>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Endorsement History -->
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Endorsement History
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <div>
                    <app-external-scrollbar
                      targetSyncScroll="7"
                      [targetBaseWidth]="tableHolder7"
                    ></app-external-scrollbar>
                    <div
                      class="table-holder table_content_scroll only-this-horizon-scrollbar"
                      #tableHolder7
                      syncScroll="7"
                    >
                      <table nxTable nxSort class="enquiry">
                        <thead>
                          <tr nxTableRow>
                            <th nxHeaderCell nxSortHeaderCell="product">
                              Endorsement Ref. No.
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Issued Date
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Endorsement
                              <Br />Effective Date
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Endorsement Type
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Referral Reasons
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Status
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Issued By
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Approve / Decline By
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Approve / Decline Date
                            </th>
                            <th nxHeaderCell nxSortHeaderCell="contractNumber">
                              Remarks
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr nxTableRow *ngFor="let endorsement of endorsementHistoryList">
                              <td nxTableCell class="center-align">
                                <button nxPlainButton type="button" (click)="enRefNo()">
                                  {{ endorsement.endorsementRefNo }}
                                </button>
                              </td>
                              <td nxTableCell class="center-align">{{ endorsement.issuedDate }}</td>
                              <td nxTableCell class="center-align">{{ endorsement.endorsementEffectiveDate }}</td>
                              <td nxTableCell class="center-align">{{ endorsement.endorsementType }}</td>
                              <td nxTableCell class="center-align">{{ endorsement.referralReasons }}</td>
                              <td nxTableCell class="center-align">
                                <nx-badge type="active" class="pending_badge">
                                  {{ endorsement.status }}
                                </nx-badge>
                              </td>
                              <td nxTableCell class="center-align">{{ endorsement.issuedBy }}</td>
                              <td nxTableCell class="center-align">{{ endorsement.approveDeclineBy }}</td>
                              <td nxTableCell class="center-align">{{ endorsement.approveDeclineDate }}</td>
                              <td nxTableCell class="center-align">{{ endorsement.remarks }}</td>
                            </tr>
                          </tbody>
                          
                          
                      </table>
                    </div>
                  </div>
      
                </nx-expansion-panel>
            </nx-accordion>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Checklist -->
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Checklist
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <div nxLayout="grid nopadding">
                    <div *ngFor="let item of checkListItems; let i = index" nxRow [class.nx-margin-top-m]="i !== 0">
                        <div nxCol="12" class="horizontal-checkboxes">
                          <div class="nx-margin-right-2m">{{ i + 1 }}</div>
                          <div>
                            {{ item }} <br />
                            <div class="container_flex_center nx-margin-top-s">
                              <nx-radio class="nx-margin-right-2m" [attr.name]="'group' + i"> Yes </nx-radio>
                              <nx-radio [attr.name]="'group' + i"> No </nx-radio>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-top-m">
                      <div nxCol="12">
                        <p nxCopytext="medium" class="nx-margin-bottom-2m">
                          <i>Note: {{checklistNote}}</i>
                        </p>
                        <p nxCopytext="normal" class="nx-margin-top-m">
                          Remarks: {{ checklistRemark ? checklistRemark : 'N/A' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </nx-expansion-panel>
            </nx-accordion>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Endorsement Narration -->
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Endorsement Narration
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <b><u>AMENDMENT ON PERIOD OF INSURANCE</u> </b><br /><br />
                  IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
                  <b>01/02/2024</b> THE PERIOD OF INSURANCE UNDER THE POLICY IS
                  AMENDED TO READ AS FROM <b>01/02/2024</b> TO <b>31/01/2025</b> AND
                  NOT AS OTHERWISE STATED. <br /><br />
                  ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED
                </nx-expansion-panel>
            </nx-accordion>
            

            <!-- //////////////////////////////////////////////////////////////////// -->
            <!-- Remarks -->
            <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Remarks
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
      
                  <div nxLayout="grid nopadding">
                    <div nxRow>
                      <div nxCol="12" class="nx-margin-bottom-2m">
                        <nx-data-display size="small">
                          <nx-data-display-label class="nx-font-weight-bold"
                            >Referral Remarks:</nx-data-display-label
                          >
                          <p nxCopytext="large">{{remarksInfo.referral ? remarksInfo.referral : 'N/A'}}</p>
                        </nx-data-display>
                      </div>
                    </div>
                    
                    <div nxRow>
                        <div nxCol="12" class="nx-margin-bottom-2m">
                          <nx-data-display size="small">
                            <nx-data-display-label class="nx-font-weight-bold">Admin Remarks:</nx-data-display-label>
                            <p *ngFor="let remark of remarksInfo.admin"><strong>{{ remark }}</strong></p>
                          </nx-data-display>
                        </div>
                    </div>

                    <div nxRow>
                      <div nxCol="12" class="nx-margin-bottom-2m">
                        <nx-data-display size="small">
                          <nx-data-display-label class="nx-font-weight-bold"
                            >Approval Remarks:</nx-data-display-label
                          >
                          <p nxCopytext="large">{{remarksInfo.approval ? remarksInfo.approval : 'N/A'}}</p>
                        </nx-data-display>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12">
                        <nx-data-display size="small">
                          <nx-data-display-label class="nx-font-weight-bold"
                            >Updated by: {{remarksInfo.updatedBy}}
                          </nx-data-display-label>
                        </nx-data-display>
                      </div>
                    </div>
                  </div>
                </nx-expansion-panel>
            </nx-accordion>


            <!-- //////////////////////////////////////////////////////////////////// -->
            <form [formGroup]="jpjIsmForm">
                <div class="horizontal-checkboxes nx-margin-top-m">
                  <nx-checkbox class="nx-margin-right-m" formControlName="jpjUpdated"> JPJ Updated </nx-checkbox>
                  <nx-checkbox formControlName="ismUpdated"> ISM Updated </nx-checkbox>
                </div>
            </form>



        </div>
    </div>
</div>

<div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <div class="button-container">
            <button nxButton="secondary" type="button">Back</button>
            <!-- <button nxButton="secondary" type="button" (click)="addRemarks()">
              ADD REMARKS
            </button>
            <button nxButton="primary" type="button">
              <nx-icon
                name="download"
                nxIconPositionStart
                aria-hidden="true"
              ></nx-icon
              >Endorsement Schedule PDF
            </button>
            <button nxButton="primary" type="button" (click)="approve()">
              APPROVE E-COVER NOTE
            </button>
            <button nxButton="primary" type="button" (click)="reject()">
              REJECT E-COVER NOTE
            </button>
            <button nxButton="primary" type="button">UPLOAD DOCUMENT</button> -->
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #templateEnHistory>
    <div class="left-content">
      <h3 nxHeadline="subsection-medium">Endorsement Narration</h3>
      <p nxCopytext="normal" class="nx-margin-top-2m">
        <u><b>REVISION OF SUM INSURED - DECREASE</b></u> <br /><br />
        IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE <b>13/12/2023</b> THE SUM
        INSURED IS DECREASED FROM <b>RM40,000.00</b> TO <b>RM28,000.00</b
        ><br /><br />
  
        IN CONSIDERATION OF THE ABOVE, A REFUND PREMIUM OF <b>RM171.89</b> IS
        HEREBY ALLOWED TO THE INSURED.<br /><br />
        ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
      </p>
  
      <button
        nxButton="secondary"
        (click)="closeTemplateDialog()"
        class="nx-margin-right-s nx-margin-top-50"
      >
        Cancel
      </button>
    </div>
  </ng-template>