<div nxLayout="grid nopadding">
  <div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Receipt No.</nx-data-display-label>
        <p nxCopytext="large">{{receiptNo}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Transaction Date</nx-data-display-label>
        <p nxCopytext="large">{{transactionDate}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">e-Cover Note No.</nx-data-display-label>
        <p nxCopytext="large">{{eCoverNoteNo}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
        <p nxCopytext="large">{{vehicleNo}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Account Code</nx-data-display-label>
        <p nxCopytext="large">{{accountCode}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Insured Name</nx-data-display-label>
        <p nxCopytext="large">{{insuredName}}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">Total Premium Due (RM)</nx-data-display-label>
        <p nxCopytext="large">{{totalPremiumDue}}</p>
      </nx-data-display>
    </div>
  </div>
</div>