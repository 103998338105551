<div nxLayout="grid" class="grid_remove_padding coverage">
    <div nxRow class="nx-margin-top-2m">
        <div nxCol="12">
            <h2 class="nx-margin-0">Extra Coverage</h2>
        </div>
    </div>

    <div nxRow class="nx-margin-top-2m">
        <div nxCol="12">
            <div>
                <app-external-scrollbar targetSyncScroll="4" [targetBaseWidth]="tableHolder4"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder4 syncScroll="4">
                    <table nxTable class="more_extra_cover">
                        <tbody>
                            <tr nxTableRow>
                                <th nxHeaderCell>
                                    <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                        <div class="nx-font-weight-bold">
                                            Extra Cover
                                        </div>
                                    </nx-checkbox>
                                </th>
                                <th nxHeaderCell></th>
                                <th nxHeaderCell>Premium (RM)</th>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY OF PASSENGERS FOR NEGLIGENT
                                        ACTS
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell></td>
                                <td nxTableCell class="std-ta-right">7.50</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY TO PASSENGERS
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">33.75</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        EXTENSION OF COVER TO THE KINGDOM OF
                                        THAILAND (APPLICABLE TO PRIVATE CAR THIRD
                                        PARTY POLICY ONLY)
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">101.25</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        TUITION PURPOSES
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">67.50</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        EXTENSION OF COVER TO KALIMANTAN
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">30.80</td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        PRIVATELY OWNED CARS USED FOR TUTION
                                    </nx-checkbox>
                                </td>
                                <td nxTableCell class="std-ta-right"></td>
                                <td nxTableCell class="std-ta-right">67.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
    <div nxRow class="nx-margin-top-2m">
        <div nxCol="12">
            <button nxPlainButton type="button" class="nx-margin-right-m" (click)="tp()">
                <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                ADD MORE
            </button>
            <ng-template #template>
                <div class="left-content">
                    <h3 nxHeadline="subsection-medium" class="nx-margin-0">
                        Exta Cover
                    </h3>
                    <div class="scroll-container nx-margin-top-2m">
                        <table nxTable zebra>
                            <thead>
                                <tr nxTableRow>
                                    <th nxHeaderCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">Extra Cover</span>
                                        </nx-checkbox>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">TUITION PURPOSES</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">EXTENSION OF COVER TO KALIMANTAN</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                                <tr nxTableRow>
                                    <td nxTableCell>
                                        <nx-checkbox>
                                            <span nxCopytext="medium">PRIVATELY OWNED CARS USED FOR
                                                TUTION</span>
                                        </nx-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="two-button-container nx-margin-top-l">
                        <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s">
                            Cancel
                        </button>
                        <button nxButton (click)="closeTemplateDialog()" class="m_margin_top_20">
                            Proceed
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>