<nx-accordion variant="light">
    <nx-expansion-panel class="accordion_btm_space">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title class="accordion_edit">
                Customer Partner -EInvoice
            </nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <div nxLayout="grid nopadding" [formGroup]="eInvoiceCustomerPartnerForm">
            <!-- <div nxLayout="grid nopadding"> -->

            <ng-container>
                <div nxLayout="grid nopadding">
                    <div>
                        <div>
                            <div nxRow class="nx-margin-top-m">
                                <div nxCol="12">
                                    <div nxLayout="grid nopadding" class="grid_remove_padding summary_column_space">

                                        @if (isIndividual) {
                                        <table nxTable cellpadding="0" class="summary_table">
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Nationality</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">
                                                        {{ getNationalityDescription(clientNationality) }}
                                                    </span>
                                                </td>
                                            </tr>
                                            @if (isNewIC) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">NRIC</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{idValue1}}</span>
                                                </td>
                                            </tr>
                                            }
                                            @if (isOldIC) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Old IC No.</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{idValue1}}</span>
                                                </td>
                                            </tr>
                                            }
                                            @if (isPoliceArmyID) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Police/Army ID</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{idValue1}}</span>
                                                </td>
                                            </tr>
                                            }
                                            @if (isPassport) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Passport No.</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{idValue1}}</span>
                                                </td>
                                            </tr>
                                            }
                                            @if (isNewIC) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Date of Birth</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">14/09/1988</span>
                                                </td>
                                            </tr>
                                            }
                                            @if (isNewIC) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Age</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">35</span>
                                                </td>
                                            </tr>
                                            }
                                        </table>
                                        }

                                        @if (isCompany) {
                                        <table nxTable cellpadding="0" class="summary_table">
                                            @if (isNewBR) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">New Business Registration No.</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{idValue1}}</span>
                                                </td>
                                            </tr>
                                            }
                                            @if (isOldBR) {
                                            <tr nxTableRow>
                                                <td nxTableCell>
                                                    <span nxCopytext="normal">Business Registration No.</span>
                                                </td>
                                                <td nxTableCell class="column_cp_content">
                                                    <span nxCopytext="normal">{{idValue1}}</span>
                                                </td>
                                            </tr>
                                            }
                                        </table>
                                        }

                                    </div>
                                </div>
                            </div>

                            <hr class="hr" class="nx-margin-top-2m" />

                            <div nxRow>
                                <div nxCol="12">
                                    @if (isIndividual) {
                                    <h4>Personal Information</h4>
                                    }
                                    @if (isCompany) {
                                    <h4>Company Information</h4>
                                    }
                                </div>
                            </div>

                            <div nxRow>
                                <div nxCol="12">
                                    <nx-formfield label="Name">
                                        <input nxInput value="James Bond" formControlName="customerName"
                                            [disabled]="true" />
                                    </nx-formfield>
                                </div>
                            </div>

                            @if (isCompany) {
                            <div nxRow>
                                <div nxCol="12">
                                    <nx-formfield label="Country of Incorporation">
                                        <nx-dropdown [valueFormatter]="toText" formControlName="nationality"
                                            [disabled]="true">
                                            @for (country of lov.clientNationalityList; track country) {
                                            <nx-dropdown-item [value]="country.code">{{ country.descp
                                                }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>
                            </div>
                            }

                            @if ((isIndividual && isOldIC) || isCompany) {
                            <div nxRow *ngIf="company">

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="ID Type 2">

                                        <!-- <nx-dropdown value="NRIC" formControlName="idType2">
                                            <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                                        </nx-dropdown> -->
                                        <nx-dropdown [valueFormatter]="toText" formControlName="idType2">
                                            @for(idType2 of idType2List; track idType2) {
                                            <nx-dropdown-item [value]="idType2.code">{{ idType2.descp
                                                }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>

                                        <nx-error nxFormfieldError>
                                            <strong>Please enter ID Type 2.</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="ID No. 2">

                                        <!-- <input nxInput formControlName="idNo2" /> -->
                                        <input nxInput formControlName="idValue2" maxlength="100" #idValue2
                                            (blur)="onBlurIdValue2(idValue2)" appToUpperCase />

                                        @if (idValue2ErrorType) {
                                        <nx-error nxFormfieldError>
                                            @if(idValue2ErrorType === 'wrongNricFormat') {
                                            <strong>Wrong Format</strong>
                                            } @else if(idValue2ErrorType === 'required') {
                                            <strong>Please enter ID No. 2</strong>
                                            } @else if(idValue2ErrorType === 'wrongNewBrnoFormat') {
                                            <strong>Invalid Business Registration No.</strong>
                                            }
                                        </nx-error>
                                        }

                                    </nx-formfield>
                                </div>
                            </div>
                            }

                            <div nxRow>
                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Tax Identification No. (TIN)">
                                        <input nxInput formControlName="taxNo" appToUpperCase />
                                        <nx-error nxFormfieldError>
                                            <strong>Please enter Tax Identification No.
                                                (TIN).</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>
                            </div>

                            @if (isCompany) {
                            <div>

                                <div nxRow>
                                    <div nxCol="12">
                                        <span nxCopytext="normal">SST Registered?</span><br />
                                        <div class="radio-group nx-margin-top-xs">
                                            <!-- <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                                            <nx-radio name="group1" class="nx-margin-left-2m">No</nx-radio> -->
                                            <nx-radio name="group1" value="true"
                                                formControlName="sstRegisteredInd">Yes</nx-radio>
                                            <nx-radio name="group1" value="false" class="nx-margin-left-2m"
                                                formControlName="sstRegisteredInd">No</nx-radio>
                                        </div>
                                    </div>
                                </div>

                                @if (eInvoiceCustomerPartnerForm.value.sstRegisteredInd) {
                                <div>
                                    <div nxRow class="nx-margin-top-xs">
                                        <div nxCol="12,12,6,6,6,6,6">
                                            <nx-formfield label="SST No.">

                                                <input nxInput formControlName="sstNo" />
                                                @if (eInvoiceCustomerPartnerForm.get('sstNo').errors?.['required']) {
                                                <nx-error nxFormfieldError>
                                                    <strong>Please enter SST No.</strong>
                                                </nx-error>
                                                }
                                                @if (eInvoiceCustomerPartnerForm.get('sstNo').errors?.['pattern']) {
                                                <nx-error nxFormfieldError>
                                                    <strong>The Service Tax Registration No. cannot be less or more than
                                                        17.</strong>
                                                </nx-error>
                                                }

                                            </nx-formfield>
                                        </div>
                                    </div>

                                    <div nxRow>

                                        <div nxCol="12,12,6,6,6,6,6">
                                            <nx-formfield label="SST Effective Date">
                                                <!-- <input nxDatefield nxInput [datepicker]="myDatepicker1" /> -->
                                                <input nxDatefield nxInput [datepicker]="myDatepicker1"
                                                    formControlName="sstEffectiveDate" />
                                                <span nxFormfieldHint>DD/MM/YYYY</span>

                                                <nx-datepicker-toggle [for]="myDatepicker1"
                                                    nxFormfieldSuffix></nx-datepicker-toggle>
                                                <nx-datepicker #myDatepicker1></nx-datepicker>

                                                <nx-error nxFormfieldError>
                                                    <strong> Please enter SST Effective Date</strong>
                                                </nx-error>

                                            </nx-formfield>
                                        </div>

                                        <div nxCol="12,12,6,6,6,6,6">
                                            <nx-formfield label="SST De-Registration Date">
                                                <!-- <input nxDatefield nxInput [datepicker]="myDatepicker2" /> -->
                                                <input nxDatefield nxInput [datepicker]="myDatepicker2"
                                                    formControlName="sstDeregistrationDate" />
                                                <span nxFormfieldHint>DD/MM/YYYY</span>

                                                <nx-datepicker-toggle [for]="myDatepicker2"
                                                    nxFormfieldSuffix></nx-datepicker-toggle>
                                                <nx-datepicker #myDatepicker2></nx-datepicker>

                                            </nx-formfield>
                                        </div>

                                    </div>

                                    <div nxRow>
                                        <div nxCol="12,12,6,6,6,6,6">
                                            <nx-formfield label="SST Resident Status">
                                                <nx-dropdown formControlName="sstResidentStatus">

                                                    <!-- <nx-dropdown-item value="Resident"></nx-dropdown-item> -->

                                                    @for (addressType of lov.addressTypeList; track addressType) {
                                                    <nx-dropdown-item [value]="addressType.code">{{ addressType.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>
                                        </div>

                                        <div nxCol="12,12,6,6,6,6,6">
                                            <nx-formfield label="SST Country">
                                                <nx-dropdown formControlName="sstCountry" [valueFormatter]="toText">

                                                    <!-- <nx-dropdown-item value="Malaysia"></nx-dropdown-item> -->

                                                    @for (country of lov.countryList; track country) {
                                                    <nx-dropdown-item [value]="country.code">{{ country.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>
                                        </div>
                                    </div>
                                </div>
                                }
                                <!-- //////////////////////////////////////////////////////////////////////// -->

                                <div nxRow>
                                    <div nxCol="12">
                                        <span nxCopytext="normal">SME Registered?</span><br />
                                        <div class="radio-group nx-margin-top-xs">
                                            <!-- <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                                            <nx-radio name="group1" class="nx-margin-left-2m">No</nx-radio> -->
                                            <nx-radio name="group1" value="true"
                                                formControlName="smeRegisteredInd">Yes</nx-radio>
                                            <nx-radio name="group1" value="false" class="nx-margin-left-2m"
                                                formControlName="smeRegisteredInd">No</nx-radio>
                                        </div>
                                    </div>
                                </div>

                                @if (eInvoiceCustomerPartnerForm.value.smeRegisteredInd) {
                                <div nxRow class="nx-margin-top-2m">
                                    <div nxCol="12,12,6,6,6,6,6">
                                        <nx-formfield label="SME Reg. No.">
                                            <input nxInput formControlName="smeRegNo" maxlength="12" appToUpperCase />
                                            <nx-error nxFormfieldError>
                                                @if (eInvoiceCustomerPartnerForm.get('smeRegNo').errors?.['required']) {
                                                <strong> Please enter SME Reg No. </strong>
                                                }

                                                @if (eInvoiceCustomerPartnerForm.get('smeRegNo').errors?.['pattern']) {
                                                <strong>SME Reg. No. is Invalid. </strong>
                                                }
                                            </nx-error>
                                        </nx-formfield>
                                    </div>

                                    <div nxCol="12,12,6,6,6,6,6">
                                        <nx-formfield label="SME Category">
                                            <nx-dropdown [valueFormatter]="toText" formControlName="smeCategory">
                                                @for(smeCategory of lov.smeCategoryList; track smeCategory) {
                                                <nx-dropdown-item [value]="smeCategory.code">{{ smeCategory.descp
                                                    }}</nx-dropdown-item>
                                                }
                                            </nx-dropdown>

                                            <nx-error nxFormfieldError>
                                                <strong>Plese select SME Category</strong>
                                            </nx-error>

                                        </nx-formfield>
                                    </div>

                                </div>

                                <div nxRow>
                                    <div nxCol="12,12,6,6,6,6,6">
                                        <nx-formfield label="SME Cert. Start Date">
                                            <input nxDatefield nxInput [datepicker]="myDatepicker3"
                                                formControlName="smeStartDate" />
                                            <span nxFormfieldHint>DD/MM/YYYY</span>
                                            <nx-datepicker-toggle [for]="myDatepicker3"
                                                nxFormfieldSuffix></nx-datepicker-toggle>
                                            <nx-datepicker #myDatepicker3></nx-datepicker>

                                            <nx-error nxFormfieldError>
                                                @if
                                                (eInvoiceCustomerPartnerForm.get('smeStartDate').errors?.['required']) {
                                                <strong>Please enter SME Start Date</strong>
                                                }

                                                @if
                                                (eInvoiceCustomerPartnerForm.get('smeStartDate').errors?.['invalidSMEStartDate'])
                                                {
                                                <strong>SME Start Date is Invalid.</strong>
                                                }

                                            </nx-error>
                                        </nx-formfield>
                                    </div>
                                    <div nxCol="12,12,6,6,6,6,6">
                                        <nx-formfield label="SME Cert. End Date">
                                            <input nxDatefield nxInput [datepicker]="myDatepicker4"
                                                formControlName="smeEndDate" />
                                            <span nxFormfieldHint>MM/DD/YYYY</span>
                                            <nx-datepicker-toggle [for]="myDatepicker4"
                                                nxFormfieldSuffix></nx-datepicker-toggle>
                                            <nx-datepicker #myDatepicker4></nx-datepicker>

                                            <nx-error nxFormfieldError>
                                                @if (eInvoiceCustomerPartnerForm.get('smeEndDate').errors?.['required'])
                                                {
                                                <strong>Please enter SME End Date</strong>
                                                }

                                                @if
                                                (eInvoiceCustomerPartnerForm.get('smeEndDate').errors?.['smeStartDateEmpty'])
                                                {
                                                <strong>Please enter SME Start Date.</strong>
                                                }

                                                @if
                                                (eInvoiceCustomerPartnerForm.get('smeEndDate').errors?.['smeSDLaterThanSmeED'])
                                                {
                                                <strong>SME End Date is Invalid.</strong>
                                                }

                                                @if
                                                (eInvoiceCustomerPartnerForm.get('smeEndDate').errors?.['smeEDMoreThanTwoYear'])
                                                {
                                                <strong>SME End Date is more than 2 years.</strong>
                                                }
                                            </nx-error>
                                        </nx-formfield>
                                    </div>
                                </div>
                                }

                            </div>
                            }

                            @if (isIndividual) {
                            <div nxRow>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <span nxCopytext="normal">Gender</span><br />
                                    <nx-radio-group formControlName="gender" name="gender">
                                        <div class="radio-group nx-margin-top-xs">
                                            <nx-radio value="M">Male</nx-radio>
                                            <nx-radio class="nx-margin-left-2m" value="F">Female</nx-radio>
                                        </div>
                                    </nx-radio-group>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <div class="customField_marital">
                                        <nx-formfield label="Marital Status">
                                            <nx-dropdown [valueFormatter]="toText" formControlName="maritalStatus">
                                                @for(clientMartial of lov.clientMaritalList; track clientMartial) {
                                                <nx-dropdown-item [value]="clientMartial.code">{{ clientMartial.descp
                                                    }}</nx-dropdown-item>
                                                }
                                            </nx-dropdown>
                                            <nx-error nxFormfieldError>
                                                <strong>Please enter the Marital Status</strong>
                                            </nx-error>
                                        </nx-formfield>
                                    </div>
                                </div>

                            </div>
                            }

                            <!--Individual-->
                            @if (isIndividual && !isNewIC) {
                            <div nxRow>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Date of Birth">
                                        <input nxDatefield nxInput [datepicker]="myDatepicker5" formControlName="dob" />
                                        <span nxFormfieldHint>DD/MM/YYYY</span>
                                        <nx-datepicker-toggle [for]="myDatepicker5"
                                            nxFormfieldSuffix></nx-datepicker-toggle>
                                        <nx-datepicker #myDatepicker5></nx-datepicker>

                                        @if(dobErrorType) {
                                        <nx-error nxFormfieldError>
                                            <!-- @if (dobErrorType === 'invalidDOB') { -->
                                            <strong>Please enter a valid DOB</strong>
                                            <!-- } -->
                                        </nx-error>
                                        }

                                    </nx-formfield>

                                    <!-- <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(1, 'one-button')">
                                    (Current Year - Birth Year) = 0
                                  </button> -->
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Age">
                                        <input nxInput value="" formControlName="age" (change)="onChangeAge()" />
                                    </nx-formfield>
                                </div>

                            </div>
                            }

                            <div nxRow>
                                <div nxCol="12">
                                    <hr class="hr" />
                                    <h4>Contact Details</h4>
                                </div>
                            </div>

                            <div nxRow>
                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">
                                        <div class="cp__customer-detail-phone">

                                            <nx-formfield label="Code" class="code">
                                                <!-- <nx-dropdown formControlName="mobileCode" [panelGrow]="true"
                                                    [panelMaxWidth]="150">
                                                    <nx-dropdown-item value="6010"></nx-dropdown-item>
                                                    <nx-dropdown-item value="6011"></nx-dropdown-item>
                                                    <nx-dropdown-item value="6012"></nx-dropdown-item>
                                                </nx-dropdown> -->
                                                <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150"
                                                    formControlName="mobileCode">
                                                    @for(mobileNoCode of lov.clientMobileCode1List; track mobileNoCode)
                                                    {
                                                    <nx-dropdown-item [value]="mobileNoCode.code">{{ mobileNoCode.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>

                                            <nx-formfield label="Mobile No." class="info nx-margin-left-s">

                                                <input nxInput inputmode="numeric" required numberOnly minlength="7"
                                                    maxlength="8" autocomplete="off" formControlName="mobileNo"
                                                    (blur)="onChangeMobileNo()" />

                                                <nx-error nxFormfieldError>
                                                    @if(eInvoiceCustomerPartnerForm.get('mobileNo').errors?.['required'])
                                                    {
                                                    @if (isCompany) {
                                                    <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                                                    } @else if (isIndividual) {
                                                    <strong>Please enter the Mobile No.</strong>
                                                    }

                                                    }

                                                    @if
                                                    (eInvoiceCustomerPartnerForm.get('mobileNo').errors?.['pattern']) {
                                                    <strong>Please enter the number between 7 and 8 characters
                                                        long.</strong>
                                                    }

                                                </nx-error>

                                            </nx-formfield>

                                        </div>
                                    </div>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">
                                        <div class="cp__customer-detail-phone">

                                            <nx-formfield label="Code" class="code">
                                                <nx-dropdown formControlName="fixedLineCode" [panelGrow]="true"
                                                    [panelMaxWidth]="150">

                                                    <!-- <nx-dropdown-item value="601"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item> -->
                                                    @for (fixLineNoCode of lov.clientFax1CodeList; track fixLineNoCode)
                                                    {
                                                    <nx-dropdown-item [value]="fixLineNoCode.code">{{
                                                        fixLineNoCode.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>

                                            <nx-formfield label="Fixed Line No." class="info nx-margin-left-s">

                                                <input nxInput inputmode="numeric" required numberOnly minlength="7"
                                                    maxlength="8" autocomplete="off" formControlName="fixedLineNo"
                                                    (blur)="onChangefixedLineNo()" />

                                                <nx-error nxFormfieldError>
                                                    @if(eInvoiceCustomerPartnerForm.get('fixedLineNo').errors?.['required'])
                                                    {
                                                    @if(isCompany) {
                                                    <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                                                    }

                                                    }

                                                    @if(eInvoiceCustomerPartnerForm.get('fixedLineNo').errors?.['pattern'])
                                                    {
                                                    <strong> Please enter the number between 7 and 8 characters
                                                        long.</strong>
                                                    }
                                                </nx-error>

                                            </nx-formfield>

                                        </div>
                                    </div>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">
                                        <div class="cp__customer-detail-phone">

                                            <nx-formfield label="Code" class="code">
                                                <nx-dropdown formControlName="faxCode" [panelGrow]="true"
                                                    [panelMaxWidth]="150">

                                                    <!-- <nx-dropdown-item value="601"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item>
                                                    <nx-dropdown-item value="603"></nx-dropdown-item> -->
                                                    @for(faxNoCode of lov.clientFax1CodeList; track faxNoCode) {
                                                    <nx-dropdown-item [value]="faxNoCode.code">{{ faxNoCode.descp
                                                        }}</nx-dropdown-item>
                                                    }

                                                </nx-dropdown>
                                            </nx-formfield>

                                            <nx-formfield label="Fax No. (Optional)" class="info nx-margin-left-s">
                                                <input nxInput inputmode="numeric" required numberOnly minlength="7"
                                                    maxlength="8" autocomplete="off" formControlName="faxNo"
                                                    (change)="onChangeFaxNo()" />
                                                <nx-error nxFormfieldError>
                                                    <strong>Please enter the number between 7 and 8
                                                        characters long.</strong>
                                                </nx-error>
                                            </nx-formfield>

                                        </div>
                                    </div>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <div nxLayout="grid nopadding">

                                        <nx-formfield label="Email">
                                            <input nxInput type="text" formControlName="email" appToUpperCase />

                                            <nx-error nxFormfieldError>
                                                @if(eInvoiceCustomerPartnerForm.get('email').errors?.['required']) {
                                                <strong> Please enter the Email </strong>
                                                }
                                                @if(eInvoiceCustomerPartnerForm.get('email').errors?.['email']) {
                                                <strong> Please enter a valid email address. </strong>
                                                }
                                            </nx-error>

                                        </nx-formfield>

                                    </div>
                                </div>
                            </div>

                            <div nxRow>
                                <div nxCol="12">
                                    <hr class="hr" />
                                    <h4>Correspondence Details</h4>
                                </div>
                            </div>

                            <div nxRow>

                                <div nxCol="12">
                                    <nx-switcher [checked]="true" formControlName="showMapSwitcher">Show
                                        Map</nx-switcher>
                                </div>

                                @if (eInvoiceCustomerPartnerForm.get('showMapSwitcher').value) {
                                <div nxCol="12" class="search-map nx-margin-top-m">

                                    <app-branches-map
                                        [mapVisible]="eInvoiceCustomerPartnerForm.get('showMapSwitcher').value"
                                        (formPopulated)="populateMapForm($event)">
                                    </app-branches-map>

                                    <!-- <div class="pac-card" id="pac-card">
                                        <div>
                                            <div id="title">Autocomplete search</div>
                                            <div id="type-selector" class="pac-controls">
                                                <div class="horizontal-checkboxes nx-margin-top-2xs">
                                                    <span nxCopytext="normal" class="search_map_font">Search Mode
                                                        :</span>
                                                    <div class="radio-map">
                                                        <nx-radio name="group1" labelSize="small" [checked]="true"
                                                            value="address"
                                                            (valueChange)="changeSearchMode($event)"><span
                                                                nxCopytext="medium">By
                                                                Address</span>
                                                        </nx-radio>
                                                        <nx-radio name="group1" labelSize="small" value="lat/long"
                                                            (valueChange)="changeSearchMode($event)"><span
                                                                nxCopytext="medium">By
                                                                Lat/Long</span>
                                                        </nx-radio>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        <div id="pac-container">
                                            <input *ngIf="search_mode_address" id="pac-input" type="text"
                                                placeholder="Enter a location" />

                                            <div *ngIf="!search_mode_address">
                                                <div class="lat_long">
                                                    <input id="pac-input" type="text" placeholder="LATITUDE" />
                                                    <input id="pac-input" type="text" placeholder="LONGITUDE" />
                                                </div>
                                                <div class="container_flex_center align_right nx-margin-top-s">
                                                    <button nxButton="primary small" type="button">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.857620894033!2d101.6841407748091!3d3.132302996843152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b50e075c1d%3A0xc37d80fcda677529!2sMenara%20Allianz%20Sentral!5e0!3m2!1sen!2smy!4v1716797684318!5m2!1sen!2smy"
                                        class="map" style="border: 0" allowfullscreen="" loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"></iframe> -->

                                </div>
                                }

                                <div nxCol="12" class="nx-margin-top-m">
                                    <nx-formfield label="Unit No">
                                        <input nxInput formControlName="unitNo" appToUpperCase />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12">
                                    <nx-formfield label="Address 1">
                                        <input nxInput formControlName="address1" appToUpperCase />

                                        <nx-error nxFormfieldError>
                                            <strong>Please enter Address 1.</strong>
                                        </nx-error>

                                    </nx-formfield>
                                </div>

                                <div nxCol="12">
                                    <nx-formfield label="Address 2">
                                        <input nxInput formControlName="address2" appToUpperCase />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12">
                                    <nx-formfield label="Address 3">
                                        <input nxInput formControlName="address3" appToUpperCase />
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Postcode">
                                        <!-- <input nxInput formControlName="postcode" /> -->
                                        <nx-dropdown [valueFormatter]="toText" formControlName="postcode"
                                            [showFilter]="true" (selectionChange)="onChangePostcode()">
                                            @for(postCode of lov.clientPostcodeList; track postCode) {
                                            <nx-dropdown-item [value]="postCode.code">{{ postCode.code
                                                }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>

                                        <nx-error nxFormfieldError>
                                            <strong>Please enter a valid Postcode.</strong>
                                        </nx-error>

                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="City">
                                        <nx-dropdown formControlName="city" [valueFormatter]="toText">
                                            @for(City of lov.clientCityList; track City) {
                                            <nx-dropdown-item [value]="City.code">{{ City.descp }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="State">
                                        <nx-dropdown formControlName="state" [valueFormatter]="toText">
                                            @for(State of lov.clientStateList; track State) {
                                            <nx-dropdown-item [value]="State.code">{{ State.descp }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>

                                <div nxCol="12,12,6,6,6,6,6">
                                    <nx-formfield label="Country">
                                        <nx-dropdown formControlName="country" [valueFormatter]="toText">
                                            @for(Country of lov.clientCountryList; track Country) {
                                            <nx-dropdown-item [value]="Country.code">{{ Country.descp
                                                }}</nx-dropdown-item>
                                            }
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>

                            </div>

                            <div nxRow>
                                <div nxCol="12">
                                    <p nxCopytext="large" class="nx-font-weight-semibold">
                                        Please select Address Type
                                    </p>
                                </div>

                                <div nxCol="12">
                                    <div nxLayout="grid nopadding" class="nx-margin-top-xs">
                                        <div nxRow>

                                            <div nxCol="12,12,3,3,2">
                                                <nx-radio name="group3" value="R"
                                                    formControlName="addressType">Residential</nx-radio>
                                            </div>

                                            <div nxCol="12,12,5,5,4" class="m_padding_top_10">
                                                <nx-radio name="group3" value="O"
                                                    formControlName="addressType">Non-residential</nx-radio>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </nx-expansion-panel>
</nx-accordion>