import { Component, Inject } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NX_MODAL_DATA, NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';

@Component({
  selector: 'app-cp-popup',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './cp-popup.component.html',
  styleUrl: './cp-popup.component.scss'
})
export class CPPopupComponent {

  templateDialogRef?: NxModalRef<CPPopupComponent>;
  popup: any;
  message : any;

  showEdit = false

  constructor(
    @Inject(NX_MODAL_DATA) data: any,
    private dialogService: NxDialogService
  ) {
    // console.log("data", data);

    if (data.popup_type == 'editCP'){
      this.showEdit = true;
    } else{
      this.showEdit = false;

      this.popup = data.popup_type;

      if(this.popup.popup_type == 1){
        this.message = "Age cannot be zero."
      } else if (this.popup.popup_type == 2){
        this.message = "The mobile number you have keyed in is an agent's mobile number. If you proceed with this number, it is deemed that you have obtained the customer's consent to use this number as the contact number for this policy. Please confirm if this is correct or otherwise key-in the mobile number provided by the customer."
      } else if (this.popup.popup_type == 3){
        this.message = "The email you have keyed in is an agent's email. If you proceed with this email, it is deemed that you have obtained the customer's consent to use this email as the contact email for this policy. Please confirm if this is correct or otherwise key-in the email provided by the customer."
      }   
    }
  }


  // edit CP 
  individual = true;
  company = true;
  sst = true;
  sme = true;

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  search_mode_address = true;
  changeSearchMode(event: any) {
    if (event.value == "address") {
      this.search_mode_address = true;
    } else {
      this.search_mode_address = false;
    }
  }

}
