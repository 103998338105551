<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../agents'"
  routerLinkActive="router-link-active"
  >agents</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../pdpa'"
  routerLinkActive="router-link-active"
  >PDPA</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../customer-partner'"
  routerLinkActive="router-link-active"
  >customer partner</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../summary'"
  routerLinkActive="router-link-active"
  >summary</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../complete'"
  routerLinkActive="router-link-active"
  >complete</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../progress-bar'"
  routerLinkActive="router-link-active"
  >Progress Bar</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../enquiry'"
  routerLinkActive="router-link-active"
  >Enquiry</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../policy-enquiry'"
  routerLinkActive="router-link-active"
  >policy-enquiry</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../coverage'"
  routerLinkActive="router-link-active"
  >coverage</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../vehicle'"
  routerLinkActive="router-link-active"
  >vehicle</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../endorsement-pdpa'"
  routerLinkActive="router-link-active"
  >endorsement details</a
>
<br />

<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../report'"
  routerLinkActive="router-link-active"
  >Report</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../download'"
  routerLinkActive="router-link-active"
  >Download</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../ism-policy'"
  routerLinkActive="router-link-active"
  >ism-policy</a
>
<br />
<a
  nxButton="primary small"
  type="button"
  [routerLink]="'../agent-additional-info'"
  routerLinkActive="router-link-active"
  >Agent Additional Info</a
>
<br />

<div class="title-header">
  <div nxLayout="grid maxwidth">
    <h1 class="title">Summary</h1>
  </div>
</div>

<div nxLayout="grid maxwidth" class="nx-margin-top-m">
  <div nxRow>
    <div nxCol="12">
      <nx-tab-group
        mobileAccordion="false"
        (selectedTabChange)="changeTab($event)"
      >
        <nx-tab>
          <ng-template nxTabLabel>
            <span class="tab-header-content">
              Summary (20)
              <nx-indicator position="over-text"></nx-indicator>
            </span>
          </ng-template>
        </nx-tab>
        <nx-tab>
          <ng-template nxTabLabel>
            <span class="tab-header-content">
              Draft (20)
              <nx-indicator position="over-text"></nx-indicator>
            </span>
          </ng-template>
        </nx-tab>
        <nx-tab>
          <ng-template nxTabLabel>
            <span class="tab-header-content">
              Expiring Soon (20)
              <nx-indicator position="over-text"></nx-indicator>
            </span>
          </ng-template>
        </nx-tab>
        <nx-tab>
          <ng-template nxTabLabel>
            <span class="tab-header-content">
              Pending Approval (20)
              <nx-indicator position="over-text"></nx-indicator>
            </span>
          </ng-template>
        </nx-tab>
        <nx-tab>
          <ng-template nxTabLabel>
            <span class="tab-header-content">
              Motor Approval (20)
              <nx-indicator position="over-text"></nx-indicator>
            </span>
          </ng-template>
        </nx-tab>
      </nx-tab-group>
    </div>
  </div>
</div>

<!-- PopUp for Receive Email (to be move to new component) -->
<!-- <ng-template #receiveemail>
    <div nxModalContent>
        <h3 size="s">
            Confirm to NOT receive email notifications but will still be accountable for quotations within your mandate?
        </h3>

    <div class="nx-margin-top-2m">
        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            NO
        </button>
        <button nxModalClose nxButton="primary" type="button">
            YES
        </button>
    </div>
</ng-template> -->

<!-- Summary -->
<div nxLayout="grid maxwidth" #draftTab *ngIf="currentIndex == 0">
  <div nxRow>
    <div nxCol="12">
      <div class="tab__content">
        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-m grid_remove_padding nx-margin-bottom-2m"
        >
          <div nxRow>
            <div nxCol="12" class="container_flex_center align_right">
              <button
                nxPlainButton
                type="button"
                aria-label="add item"
                class="button_black"
                (click)="toggleData()"
              >
                <nx-icon
                  name="filter"
                  aria-hidden="true"
                  nxIconPositionStart
                  size="s"
                ></nx-icon
                >Filter
              </button>
            </div>
          </div>
        </div>
        <div
          nxLayout="grid nopadding"
          class="grid_remove_padding"
          *ngIf="toDisplay"
        >
          <div nxRow>
            <div nxCol="12,12,12,6">
              <nx-formfield label="Product Name">
                <nx-dropdown [showFilter]="true" value="ALL">
                  <nx-dropdown-item value="ALL"></nx-dropdown-item>
                  <nx-dropdown-item value="ALL RISKS"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ BUSINESS SHIELD"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ HOSPITAL INCOME"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ LIFESTYLE PROTECTY"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="ALLIANZ MEDICURE"></nx-dropdown-item>
                  <nx-dropdown-item value="MOTOR"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Search By">
                <nx-dropdown
                  [panelGrow]="true"
                  [panelMaxWidth]="2000"
                  [showFilter]="true"
                  value="Select Search By"
                >
                  <nx-dropdown-item value="Select Search By"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Vehicle / Trailer No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Proposal / E-Quotation No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Cover Note / E-Policy / E-Schedule No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsement No."></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Endorsement Ref. No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Customer Name"></nx-dropdown-item>
                  <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Old IC / Police ID / Army ID / Passport No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Code"></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Name"></nx-dropdown-item>
                  <nx-dropdown-item value="Voucher No."></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Enter Value">
                <input nxInput />
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Status">
                <nx-dropdown [showFilter]="true" value="Status">
                  <nx-dropdown-item value="Status"></nx-dropdown-item>
                  <nx-dropdown-item value="Saved"></nx-dropdown-item>
                  <nx-dropdown-item value="Printed"></nx-dropdown-item>
                  <nx-dropdown-item value="Submitted"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending Approval"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending ABS"></nx-dropdown-item>
                  <nx-dropdown-item value="Approved"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancelled"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancellation"></nx-dropdown-item>
                  <nx-dropdown-item value="Expired"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issuance Type">
                <nx-dropdown [showFilter]="true" value="Issuance Type">
                  <nx-dropdown-item value="Issuance Type"></nx-dropdown-item>
                  <nx-dropdown-item value="New"></nx-dropdown-item>
                  <nx-dropdown-item value="Renewal"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - New Vehicle, New Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Old Vehicle, Old Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Transfer of Ownership"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Road Tax Purpose"></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsements"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date From">
                <input nxDatefield nxInput [datepicker]="startDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="startDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #startDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date To">
                <input nxDatefield nxInput [datepicker]="endDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="endDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #endDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12" class="button_dashboard">
              <button nxButton="secondary" type="button">RESET</button>
              <button
                nxButton="primary"
                type="button"
                class="nx-margin-right-0"
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>

        <div class="nx-margin-top-2m">
          <app-external-scrollbar
            targetSyncScroll="1"
            [targetBaseWidth]="tableHolder1"
          ></app-external-scrollbar>
          <div
            class="table-holder table_content_scroll only-this-horizon-scrollbar"
            #tableHolder1
            syncScroll="1"
          >
            <table nxTable zebra nxSort>
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="date">Issued<br />Date</th>
                  <th nxHeaderCell nxSortHeaderCell="agentcode">
                    Agent Code / <br />Agent Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy">
                    e-Proposal / e-Schedule / <br />Quotation / e-Cover Note No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="product">Product Name</th>
                  <th nxHeaderCell nxSortHeaderCell="customer">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="issuance">
                    Issuance <br />Type
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="vehicle">
                    Vehicle / <br />Trailer No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="jpjstatus">JPJ Status</th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="premium"
                    class="std-ta-right"
                  >
                    Premium <br />Due (RM)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="additional">
                    Additional Info
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of tableElements">
                  <td nxTableCell>{{ item.date }}</td>
                  <td nxTableCell>{{ item.agentcode }}</td>
                  <td nxTableCell class="completed_color_primary">
                    <a (click)="navEnquiry('Summary Enquiry')">{{
                      item.policy
                    }}</a>
                  </td>
                  <td nxTableCell>{{ item.product }}</td>
                  <td nxTableCell>{{ item.customer }}</td>
                  <td nxTableCell>
                    <span class="table_column_issuance">{{
                      item.issuance
                    }}</span>
                  </td>
                  <td nxTableCell>{{ item.vehicle }}</td>
                  <td nxTableCell>{{ item.jpjstatus }}</td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.premium }}
                  </td>
                  <td nxTableCell>{{ item.additional }}</td>
                  <td nxTableCell>
                    <nx-badge [type]="item.status" [class]="item.class"
                      >{{ item.statusText }}
                    </nx-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <nx-swipebar label="Swipe to view more">
          <table nxTable zebra nxSort>
            <thead>
              <tr nxTableRow>
                <th nxHeaderCell nxSortHeaderCell="date">
                  Issued<br />Date
                </th>
                <th nxHeaderCell nxSortHeaderCell="agentcode">
                  Agent Code / <br />Agent Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="policy">
                  e-Proposal / e-Schedule / <br />Quotation / e-Cover Note
                  No.
                </th>
                <th nxHeaderCell nxSortHeaderCell="product">
                  Product Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="customer">
                  Customer Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="issuance">
                  Issuance <br />Type
                </th>
                <th nxHeaderCell nxSortHeaderCell="vehicle">
                  Vehicle / <br />Trailer No.
                </th>
                <th
                  nxHeaderCell
                  nxSortHeaderCell="premium"
                  class="std-ta-right"
                >
                  Premium <br />Due (RM)
                </th>
                <th nxHeaderCell nxSortHeaderCell="additional">
                  Additional Info
                </th>
                <th nxHeaderCell nxSortHeaderCell="status">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr nxTableRow *ngFor="let item of tableElements">
                <td nxTableCell>{{ item.date }}</td>
                <td nxTableCell>{{ item.agentcode }}</td>
                <td nxTableCell class="completed_color_primary">
                  <a (click)="navEnquiry('Summary Enquiry')">{{ item.policy }}</a> 
                </td>
                <td nxTableCell>{{ item.product }}</td>
                <td nxTableCell>{{ item.customer }}</td>
                <td nxTableCell><span class="table_column_issuance">{{ item.issuance }}</span></td>
                <td nxTableCell></td>
                <td nxTableCell class="std-ta-right">{{ item.premium }}</td>
                <td nxTableCell>{{ item.additional }}</td>
                <td nxTableCell>
                  <nx-badge [type]="item.status" [class]="item.class">{{
                    item.statusText
                  }}</nx-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </nx-swipebar> -->

        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-2xs nx-margin-bottom-3xl grid_remove_padding pagination_form"
        >
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown value="20" [panelGrow]="true">
                    <nx-dropdown-item value="5">5</nx-dropdown-item>
                    <nx-dropdown-item value="10">10</nx-dropdown-item>
                    <nx-dropdown-item value="20">20</nx-dropdown-item>
                    <nx-dropdown-item value="30">30</nx-dropdown-item>
                    <nx-dropdown-item value="40">40</nx-dropdown-item>
                    <nx-dropdown-item value="50">50</nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div
              class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
            ></div>
            <div nxCol="12,12,6">
              <nx-pagination
                [count]="count"
                [page]="page"
                [perPage]="perPage"
                type="advanced"
                (goPrev)="prevPage()"
                (goNext)="nextPage()"
                (goPage)="goToPage($event)"
                ariaLabel="Advanced example pagination"
                class="pagination_position"
              >
              </nx-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- draft -->
<div nxLayout="grid maxwidth" #draftTab *ngIf="currentIndex == 1">
  <div nxRow>
    <div nxCol="12">
      <div class="tab__content">
        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-m grid_remove_padding nx-margin-bottom-2m"
        >
          <div nxRow>
            <div nxCol="12" class="container_flex_center align_right">
              <button
                nxPlainButton
                type="button"
                aria-label="add item"
                class="button_black"
                (click)="toggleData()"
              >
                <nx-icon
                  name="filter"
                  aria-hidden="true"
                  nxIconPositionStart
                  size="s"
                ></nx-icon
                >Filter
              </button>
            </div>
          </div>
        </div>
        <div
          nxLayout="grid nopadding"
          class="grid_remove_padding"
          *ngIf="toDisplay"
        >
          <div nxRow>
            <div nxCol="12,12,12,6">
              <nx-formfield label="Product Name">
                <nx-dropdown [showFilter]="true" value="ALL">
                  <nx-dropdown-item value="ALL"></nx-dropdown-item>
                  <nx-dropdown-item value="ALL RISKS"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ BUSINESS SHIELD"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ HOSPITAL INCOME"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ LIFESTYLE PROTECTY"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="ALLIANZ MEDICURE"></nx-dropdown-item>
                  <nx-dropdown-item value="MOTOR"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Search By">
                <nx-dropdown [showFilter]="true" value="Select Search By">
                  <nx-dropdown-item value="Select Search By"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Vehicle / Trailer No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Proposal / E-Quotation No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Cover Note / E-Policy / E-Schedule No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsement No."></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Endorsement Ref. No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Customer Name"></nx-dropdown-item>
                  <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Old IC / Police ID / Army ID / Passport No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Code"></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Name"></nx-dropdown-item>
                  <nx-dropdown-item value="Voucher No."></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Enter Value">
                <input nxInput />
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Status">
                <nx-dropdown [showFilter]="true" value="Status">
                  <nx-dropdown-item value="Status"></nx-dropdown-item>
                  <nx-dropdown-item value="Saved"></nx-dropdown-item>
                  <nx-dropdown-item value="Printed"></nx-dropdown-item>
                  <nx-dropdown-item value="Submitted"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending Approval"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending ABS"></nx-dropdown-item>
                  <nx-dropdown-item value="Approved"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancelled"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancellation"></nx-dropdown-item>
                  <nx-dropdown-item value="Expired"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issuance Type">
                <nx-dropdown [showFilter]="true" value="Issuance Type">
                  <nx-dropdown-item value="New"></nx-dropdown-item>
                  <nx-dropdown-item value="Renewal"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - New Vehicle, New Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Old Vehicle, Old Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Transfer of Ownership"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Road Tax Purpose"></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsements"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date From">
                <input nxDatefield nxInput [datepicker]="startDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="startDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #startDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date To">
                <input nxDatefield nxInput [datepicker]="endDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="endDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #endDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12" class="button_dashboard">
              <button nxButton="secondary" type="button">RESET</button>
              <button
                nxButton="primary"
                type="button"
                class="nx-margin-right-0"
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>
        <div>
          <app-external-scrollbar
            targetSyncScroll="2"
            [targetBaseWidth]="tableHolder2"
          ></app-external-scrollbar>
          <div
            class="table-holder table_content_scroll only-this-horizon-scrollbar"
            #tableHolder2
            syncScroll="2"
          >
            <table nxTable zebra nxSort>
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="date">Issued<br />Date</th>
                  <th nxHeaderCell nxSortHeaderCell="agentcode">
                    Agent Code / <br />Agent Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy">
                    Draft Ref No. / <br />Quotation No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="product">Product Name</th>
                  <th nxHeaderCell nxSortHeaderCell="customer">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="issuance">
                    Issuance <br />Type
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="vehicle">
                    Vehicle / <br />Trailer No.
                  </th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="premium"
                    class="std-ta-right"
                  >
                    Premium <br />Due (RM)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="additional">
                    Additional Info
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="progress">Progress</th>
                  <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of tableDraftElements">
                  <td nxTableCell>{{ item.date }}</td>
                  <td nxTableCell>{{ item.agentcode }}</td>
                  <td nxTableCell class="completed_color_primary">
                    {{ item.policy }}
                  </td>
                  <td nxTableCell>{{ item.product }}</td>
                  <td nxTableCell>{{ item.customer }}</td>
                  <td nxTableCell>{{ item.issuance }}</td>
                  <td nxTableCell></td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.premium }}
                  </td>
                  <td nxTableCell>{{ item.additional }}</td>
                  <td nxTableCell>
                    <p nxCopytext="small">{{ item.progressText }}</p>
                    <nx-progressbar [value]="item.progress"></nx-progressbar>
                  </td>
                  <td nxTableCell>
                    <nx-badge [type]="item.status" [class]="item.class"
                      >{{ item.statusText }}
                    </nx-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <nx-swipebar label="Swipe to view more">
          <table nxTable zebra nxSort>
            <thead>
              <tr nxTableRow>
                <th nxHeaderCell nxSortHeaderCell="date">
                  Issued<br />Date
                </th>
                <th nxHeaderCell nxSortHeaderCell="agentcode">
                  Agent Code / <br />Agent Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="policy">
                  Draft Ref No. / <br />Quotation No.
                </th>
                <th nxHeaderCell nxSortHeaderCell="product">
                  Product Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="customer">
                  Customer Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="issuance">
                  Issuance <br />Type
                </th>
                <th nxHeaderCell nxSortHeaderCell="vehicle">
                  Vehicle / <br />Trailer No.
                </th>
                <th
                  nxHeaderCell
                  nxSortHeaderCell="premium"
                  class="std-ta-right"
                >
                  Premium <br />Due (RM)
                </th>
                <th nxHeaderCell nxSortHeaderCell="additional">
                  Additional Info
                </th>
                <th nxHeaderCell nxSortHeaderCell="progress">Progress</th>
                <th nxHeaderCell nxSortHeaderCell="status">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr nxTableRow *ngFor="let item of tableDraftElements">
                <td nxTableCell>{{ item.date }}</td>
                <td nxTableCell>{{ item.agentcode }}</td>
                <td nxTableCell class="completed_color_primary">
                  {{ item.policy }}
                </td>
                <td nxTableCell>{{ item.product }}</td>
                <td nxTableCell>{{ item.customer }}</td>
                <td nxTableCell><span class="table_column_issuance">{{ item.issuance }}</span></td>
                <td nxTableCell></td>
                <td nxTableCell class="std-ta-right">{{ item.premium }}</td>
                <td nxTableCell>{{ item.additional }}</td>
                <td nxTableCell>
                  <p nxCopytext="small">{{ item.progressText }}</p>
                  <nx-progressbar [value]="item.progress"></nx-progressbar>
                </td>
                <td nxTableCell>
                  <nx-badge [type]="item.status" [class]="item.class">{{
                    item.statusText
                  }}</nx-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </nx-swipebar> -->

        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-2xs nx-margin-bottom-3xl grid_remove_padding pagination_form"
        >
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown value="20" [panelGrow]="true">
                    <nx-dropdown-item value="5">5</nx-dropdown-item>
                    <nx-dropdown-item value="10">10</nx-dropdown-item>
                    <nx-dropdown-item value="20">20</nx-dropdown-item>
                    <nx-dropdown-item value="30">30</nx-dropdown-item>
                    <nx-dropdown-item value="40">40</nx-dropdown-item>
                    <nx-dropdown-item value="50">50</nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div
              class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
            ></div>
            <div nxCol="12,12,6">
              <nx-pagination
                [count]="count"
                [page]="page"
                [perPage]="perPage"
                type="advanced"
                (goPrev)="prevPage()"
                (goNext)="nextPage()"
                (goPage)="goToPage($event)"
                ariaLabel="Advanced example pagination"
                class="pagination_position"
              >
              </nx-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- expiring -->
<div nxLayout="grid maxwidth" #draftTab *ngIf="currentIndex == 2">
  <div nxRow>
    <div nxCol="12">
      <div class="tab__content">
        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-m grid_remove_padding nx-margin-bottom-2m"
        >
          <div nxRow>
            <div nxCol="12" class="container_flex_center align_right">
              <button
                nxPlainButton
                type="button"
                aria-label="add item"
                class="button_black"
                (click)="toggleData()"
              >
                <nx-icon
                  name="filter"
                  aria-hidden="true"
                  nxIconPositionStart
                  size="s"
                ></nx-icon
                >Filter
              </button>
            </div>
          </div>
        </div>
        <div
          nxLayout="grid nopadding"
          class="grid_remove_padding"
          *ngIf="toDisplay"
        >
          <div nxRow>
            <div nxCol="12,12,12,6">
              <nx-formfield label="Product Name">
                <nx-dropdown [showFilter]="true" value="ALL">
                  <nx-dropdown-item value="ALL"></nx-dropdown-item>
                  <nx-dropdown-item value="ALL RISKS"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ BUSINESS SHIELD"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ HOSPITAL INCOME"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ LIFESTYLE PROTECTY"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="ALLIANZ MEDICURE"></nx-dropdown-item>
                  <nx-dropdown-item value="MOTOR"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Search By">
                <nx-dropdown [showFilter]="true" value="Select Search By">
                  <nx-dropdown-item value="Select Search By"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Vehicle / Trailer No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Proposal / E-Quotation No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Cover Note / E-Policy / E-Schedule No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsement No."></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Endorsement Ref. No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Customer Name"></nx-dropdown-item>
                  <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Old IC / Police ID / Army ID / Passport No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Code"></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Name"></nx-dropdown-item>
                  <nx-dropdown-item value="Voucher No."></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Enter Value">
                <input nxInput />
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Status">
                <nx-dropdown [showFilter]="true" value="Status">
                  <nx-dropdown-item value="Status"></nx-dropdown-item>
                  <nx-dropdown-item value="Saved"></nx-dropdown-item>
                  <nx-dropdown-item value="Printed"></nx-dropdown-item>
                  <nx-dropdown-item value="Submitted"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending Approval"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending ABS"></nx-dropdown-item>
                  <nx-dropdown-item value="Approved"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancelled"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancellation"></nx-dropdown-item>
                  <nx-dropdown-item value="Expired"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issuance Type">
                <nx-dropdown [showFilter]="true" value="Issuance Type">
                  <nx-dropdown-item value="New"></nx-dropdown-item>
                  <nx-dropdown-item value="Renewal"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - New Vehicle, New Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Old Vehicle, Old Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Transfer of Ownership"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Road Tax Purpose"></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsements"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date From">
                <input nxDatefield nxInput [datepicker]="startDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="startDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #startDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date To">
                <input nxDatefield nxInput [datepicker]="endDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="endDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #endDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12" class="button_dashboard">
              <button nxButton="secondary" type="button">RESET</button>
              <button
                nxButton="primary"
                type="button"
                class="nx-margin-right-0"
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>

        <div class="nx-margin-top-2m">
          <app-external-scrollbar
            targetSyncScroll="3"
            [targetBaseWidth]="tableHolder3"
          ></app-external-scrollbar>
          <div
            class="table-holder table_content_scroll only-this-horizon-scrollbar"
            #tableHolder3
            syncScroll="3"
          >
            <table nxTable zebra nxSort>
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="date">Issued<br />Date</th>
                  <th nxHeaderCell nxSortHeaderCell="agentcode">
                    Agent Code / <br />Agent Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy">Quotation No.</th>
                  <th nxHeaderCell nxSortHeaderCell="product">Product Name</th>
                  <th nxHeaderCell nxSortHeaderCell="customer">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="issuance">
                    Issuance <br />Type
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="vehicle">
                    Vehicle / <br />Trailer No.
                  </th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="premium"
                    class="std-ta-right"
                  >
                    Premium <br />Due (RM)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="quotationexpirydate">
                    Quotation <br />Expiry Date
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="additional">
                    Additional Info
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of tableExpiryElements">
                  <td nxTableCell>{{ item.date }}</td>
                  <td nxTableCell>{{ item.agentcode }}</td>
                  <td nxTableCell class="completed_color_primary">
                    {{ item.policy }}
                  </td>
                  <td nxTableCell>{{ item.product }}</td>
                  <td nxTableCell>{{ item.customer }}</td>
                  <td nxTableCell>{{ item.issuance }}</td>
                  <td nxTableCell></td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.premium }}
                  </td>
                  <td nxTableCell>{{ item.quotationexpirydate }}</td>
                  <td nxTableCell>{{ item.additional }}</td>
                  <!-- <td nxTableCell>
                    <p nxCopytext="small">{{ item.progressText }}</p>
                    <nx-progressbar [value]="item.progress"></nx-progressbar>
                  </td> -->
                  <td nxTableCell>
                    <nx-badge [type]="item.status" [class]="item.class"
                      >{{ item.statusText }}
                    </nx-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <nx-swipebar label="Swipe to view more">
                <table nxTable zebra nxSort>
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell nxSortHeaderCell="date">
                        Issued<br />Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="agentcode">
                        Agent Code / <br />Agent Name
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="policy">
                        Quotation No.
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="product">
                        Product Name
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="customer">
                        Customer Name
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="issuance">
                        Issuance <br />Type
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="vehicle">
                        Vehicle / <br />Trailer No.
                      </th>
                      <th
                        nxHeaderCell
                        nxSortHeaderCell="premium"
                        class="std-ta-right"
                      >
                        Premium <br />Due (RM)
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="quotationexpirydate">
                        Quotation <br />Expiry Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="additional">
                        Additional Info
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow *ngFor="let item of tableExpiryElements">
                      <td nxTableCell>{{ item.date }}</td>
                      <td nxTableCell>{{ item.agentcode }}</td>
                      <td nxTableCell class="completed_color_primary">
                        {{ item.policy }}
                      </td>
                      <td nxTableCell>{{ item.product }}</td>
                      <td nxTableCell>{{ item.customer }}</td>
                      <td nxTableCell><span class="table_column_issuance">{{ item.issuance }}</span></td>
                      <td nxTableCell></td>
                      <td nxTableCell class="std-ta-right">{{ item.premium }}</td>
                      <td nxTableCell>{{ item.quotationexpirydate }}</td>
                      <td nxTableCell>{{ item.additional }}</td>
                      <td nxTableCell>
                        <nx-badge [type]="item.status" [class]="item.class">{{
                          item.statusText
                        }}</nx-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </nx-swipebar> -->

        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-2xs nx-margin-bottom-3xl grid_remove_padding pagination_form"
        >
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown value="20" [panelGrow]="true">
                    <nx-dropdown-item value="5">5</nx-dropdown-item>
                    <nx-dropdown-item value="10">10</nx-dropdown-item>
                    <nx-dropdown-item value="20">20</nx-dropdown-item>
                    <nx-dropdown-item value="30">30</nx-dropdown-item>
                    <nx-dropdown-item value="40">40</nx-dropdown-item>
                    <nx-dropdown-item value="50">50</nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div
              class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
            ></div>
            <div nxCol="12,12,6">
              <nx-pagination
                [count]="count"
                [page]="page"
                [perPage]="perPage"
                type="advanced"
                (goPrev)="prevPage()"
                (goNext)="nextPage()"
                (goPage)="goToPage($event)"
                ariaLabel="Advanced example pagination"
                class="pagination_position"
              >
              </nx-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- pending -->
<div nxLayout="grid maxwidth" #draftTab *ngIf="currentIndex == 3">
  <div nxRow>
    <div nxCol="12">
      <div class="tab__content">
        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-m grid_remove_padding nx-margin-bottom-2m"
        >
          <div nxRow>
            <div nxCol="12" class="nx-margin-bottom-m">
              <nx-radio-group name="Receiveemail">
                <nx-label
                  [size]="'small'"
                  class="nx-font-weight-bold nx-margin-bottom-0"
                  >Receive email notification for eQuote approval?</nx-label
                >
                <div nxLayout="grid nopadding" class="grid_remove_padding">
                  <div nxRow>
                    <div nxCol="3,3,2,1">
                      <nx-radio name="approval" [labelSize]="'small'"
                        >Yes</nx-radio
                      >
                    </div>
                    <div nxCol="3,3,2,1">
                      <nx-radio name="approval" [labelSize]="'small'"
                        >No</nx-radio
                      >
                    </div>
                  </div>
                </div>
              </nx-radio-group>
            </div>

            <div nxCol="12" class="nx-margin-bottom-m">
              <nx-radio-group name="approval">
                <nx-label
                  [size]="'small'"
                  class="nx-font-weight-bold nx-margin-bottom-0"
                  >Looped/Copied in for eQuote approval notification?
                </nx-label>
                <div nxLayout="grid nopadding" class="grid_remove_padding">
                  <div nxRow>
                    <div nxCol="3,3,2,1">
                      <nx-radio name="Looped" [labelSize]="'small'"
                        >Yes</nx-radio
                      >
                    </div>
                    <div nxCol="3,3,2,1">
                      <nx-radio name="Looped" [labelSize]="'small'"
                        >No</nx-radio
                      >
                    </div>
                  </div>
                </div>
              </nx-radio-group>
            </div>

            <div nxCol="12" class="container_flex_center align_right">
              <button
                nxPlainButton
                type="button"
                aria-label="add item"
                class="button_black"
                (click)="toggleData()"
              >
                <nx-icon
                  name="filter"
                  aria-hidden="true"
                  nxIconPositionStart
                  size="s"
                ></nx-icon
                >Filter
              </button>
            </div>
          </div>
        </div>
        <div
          nxLayout="grid nopadding"
          class="grid_remove_padding"
          *ngIf="toDisplay"
        >
          <div nxRow>
            <div nxCol="12,12,12,6">
              <nx-formfield label="Product Name">
                <nx-dropdown [showFilter]="true" value="ALL">
                  <nx-dropdown-item value="ALL"></nx-dropdown-item>
                  <nx-dropdown-item value="ALL RISKS"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ BUSINESS SHIELD"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ HOSPITAL INCOME"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ALLIANZ LIFESTYLE PROTECTY"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="ALLIANZ MEDICURE"></nx-dropdown-item>
                  <nx-dropdown-item value="MOTOR"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Search By">
                <nx-dropdown [showFilter]="true" value="Select Search By">
                  <nx-dropdown-item value="Select Search By"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Vehicle / Trailer No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Proposal / E-Quotation No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="E-Cover Note / E-Policy / E-Schedule No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsement No."></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Endorsement Ref. No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Customer Name"></nx-dropdown-item>
                  <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Old IC / Police ID / Army ID / Passport No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Code"></nx-dropdown-item>
                  <nx-dropdown-item value="Agent Name"></nx-dropdown-item>
                  <nx-dropdown-item value="Voucher No."></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Enter Value">
                <input nxInput />
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Status">
                <nx-dropdown [showFilter]="true" value="Status">
                  <nx-dropdown-item value="Status"></nx-dropdown-item>
                  <nx-dropdown-item value="Saved"></nx-dropdown-item>
                  <nx-dropdown-item value="Printed"></nx-dropdown-item>
                  <nx-dropdown-item value="Submitted"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending Approval"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending ABS"></nx-dropdown-item>
                  <nx-dropdown-item value="Approved"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancelled"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancellation"></nx-dropdown-item>
                  <nx-dropdown-item value="Expired"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issuance Type">
                <nx-dropdown [showFilter]="true" value="Issuance Type">
                  <nx-dropdown-item value="New"></nx-dropdown-item>
                  <nx-dropdown-item value="Renewal"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - New Vehicle, New Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Old Vehicle, Old Registration"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="New Business - Transfer of Ownership"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Road Tax Purpose"></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsements"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date From">
                <input nxDatefield nxInput [datepicker]="startDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="startDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #startDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issued Date To">
                <input nxDatefield nxInput [datepicker]="endDatepicker" />
                <span nxFormfieldHint>DD/MM/YYYY</span>

                <nx-datepicker-toggle
                  [for]="endDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #endDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12" class="button_dashboard">
              <button nxButton="secondary" type="button">RESET</button>
              <button
                nxButton="primary"
                type="button"
                class="nx-margin-right-0"
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>

        <div>
          <app-external-scrollbar
            targetSyncScroll="4"
            [targetBaseWidth]="tableHolder4"
          ></app-external-scrollbar>
          <div
            class="table-holder table_content_scroll only-this-horizon-scrollbar"
            #tableHolder4
            syncScroll="4"
          >
            <table nxTable zebra nxSort>
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="date">Issued<br />Date</th>
                  <th nxHeaderCell nxSortHeaderCell="agentcode">
                    Agent Code / <br />Agent Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy">
                    e-Proposal / e-Schedule / <br />e-Quotation / e-Cover Note
                    No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="product">Product Name</th>
                  <th nxHeaderCell nxSortHeaderCell="customer">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="issuance">
                    Issuance <br />Type
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="vehicle">
                    Vehicle / <br />Trailer No.
                  </th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="premium"
                    class="std-ta-right"
                  >
                    Premium <br />Due (RM)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="additional">
                    Additional Info
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="referred">
                    Referred Items
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                  <th nxHeaderCell nxSortHeaderCell="Approveby">
                    Approve / Decline by
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="Approvedate">
                    Approve / Decline Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  nxTableRow
                  *ngFor="let item of tablependingapprovalElements"
                >
                  <td nxTableCell>{{ item.date }}</td>
                  <td nxTableCell>{{ item.agentcode }}</td>
                  <td nxTableCell class="completed_color_primary">
                    {{ item.policy }}
                  </td>
                  <td nxTableCell>{{ item.product }}</td>
                  <td nxTableCell>{{ item.customer }}</td>
                  <td nxTableCell>{{ item.issuance }}</td>
                  <td nxTableCell>{{ item.vehicle }}</td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.premium }}
                  </td>
                  <td nxTableCell>{{ item.additional }}</td>
                  <td nxTableCell>{{ item.referred }}</td>
                  <td nxTableCell>
                    <nx-badge [type]="item.status" [class]="item.class"
                      >{{ item.statusText }}
                    </nx-badge>
                  </td>
                  <td nxTableCell></td>
                  <td nxTableCell></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <nx-swipebar label="Swipe to view more">
          <table nxTable zebra nxSort>
            <thead>
              <tr nxTableRow>
                <th nxHeaderCell nxSortHeaderCell="date">
                  Issued<br />Date
                </th>
                <th nxHeaderCell nxSortHeaderCell="agentcode">
                  Agent Code / <br />Agent Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="policy">
                  e-Proposal / e-Schedule / <br />Quotation / Cover Note No.
                </th>
                <th nxHeaderCell nxSortHeaderCell="product">
                  Product Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="customer">
                  Customer Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="issuance">
                  Issuance <br />Type
                </th>
                <th nxHeaderCell nxSortHeaderCell="vehicle">
                  Vehicle / <br />Trailer No.
                </th>
                <th
                  nxHeaderCell
                  nxSortHeaderCell="premium"
                  class="std-ta-right"
                >
                  Premium <br />Due (RM)
                </th>
                <th nxHeaderCell nxSortHeaderCell="additional">
                  Additional Info
                </th>
                <th nxHeaderCell nxSortHeaderCell="referred">
                  Referred Items
                </th>
                <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                <th nxHeaderCell nxSortHeaderCell="Approveby">
                  Approve / Decline by
                </th>
                <th nxHeaderCell nxSortHeaderCell="Approvedate">
                  Approve / Decline Date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                nxTableRow
                *ngFor="let item of tablependingapprovalElements"
              >
                <td nxTableCell>{{ item.date }}</td>
                <td nxTableCell>{{ item.agentcode }}</td>
                <td nxTableCell class="completed_color_primary">
                  {{ item.policy }}
                </td>
                <td nxTableCell>{{ item.product }}</td>
                <td nxTableCell>{{ item.customer }}</td>
                <td nxTableCell><span class="table_column_issuance">{{ item.issuance }}</span></td>
                <td nxTableCell>{{ item.vehicle }}</td>
                <td nxTableCell class="std-ta-right">{{ item.premium }}</td>
                <td nxTableCell>{{ item.additional }}</td>
                <td nxTableCell>{{ item.referred }}</td>
                <td nxTableCell>
                  <nx-badge [type]="item.status" [class]="item.class">{{
                    item.statusText
                  }}</nx-badge>
                </td>
                <td nxTableCell></td>
                <td nxTableCell></td>
              </tr>
            </tbody>
          </table>
        </nx-swipebar> -->

        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-2xs nx-margin-bottom-3xl grid_remove_padding pagination_form"
        >
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown value="20" [panelGrow]="true">
                    <nx-dropdown-item value="5">5</nx-dropdown-item>
                    <nx-dropdown-item value="10">10</nx-dropdown-item>
                    <nx-dropdown-item value="20">20</nx-dropdown-item>
                    <nx-dropdown-item value="30">30</nx-dropdown-item>
                    <nx-dropdown-item value="40">40</nx-dropdown-item>
                    <nx-dropdown-item value="50">50</nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div
              class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
            ></div>
            <div nxCol="12,12,6">
              <nx-pagination
                [count]="count"
                [page]="page"
                [perPage]="perPage"
                type="advanced"
                (goPrev)="prevPage()"
                (goNext)="nextPage()"
                (goPage)="goToPage($event)"
                ariaLabel="Advanced example pagination"
                class="pagination_position"
              >
              </nx-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- motor -->
<div nxLayout="grid maxwidth" #draftTab *ngIf="currentIndex == 4">
  <div nxRow>
    <div nxCol="12">
      <div class="tab__content">
        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-m grid_remove_padding nx-margin-bottom-2m"
        >
          <div nxRow>
            <div nxCol="12" class="container_flex_center align_right">
              <button
                nxPlainButton
                type="button"
                aria-label="add item"
                class="button_black"
                (click)="toggleData()"
              >
                <nx-icon
                  name="filter"
                  aria-hidden="true"
                  nxIconPositionStart
                  size="s"
                ></nx-icon
                >Filter
              </button>
            </div>
          </div>
        </div>
        <div
          nxLayout="grid nopadding"
          class="grid_remove_padding"
          *ngIf="toDisplay"
        >
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield label="Product Name">
                <nx-dropdown value="MOTOR" disabled>
                  <nx-dropdown-item value="MOTOR"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield label="Branch">
                <nx-dropdown>
                  <nx-dropdown-item value="ALL"></nx-dropdown-item>
                  <nx-dropdown-item value="ALOR STAR"></nx-dropdown-item>
                  <nx-dropdown-item value="BATU PAHAT"></nx-dropdown-item>
                  <nx-dropdown-item value="BINTULU"></nx-dropdown-item>
                  <nx-dropdown-item value="BUKIT MERTAJAM"></nx-dropdown-item>
                  <nx-dropdown-item value="BUTTERWORTH"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield
                label="Marketer Manager"
                (click)="marketerManager()"
              >
                <input nxInput />
                <span nxFormfieldSuffix>
                  <nx-icon aria-hidden="true" name="search"></nx-icon>
                </span>
              </nx-formfield>

              <ng-template #templateMarketerManager>
                <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s">
                  Marketer Manager
                </h3>
                <div nxModalContent class="left-content">
                  <div class="nx-margin-top-2m">
                    <div nxLayout="grid nopadding">
                      <div nxRow>
                        <div nxCol="12">
                          <nx-formfield>
                            <input nxInput type="search" />
                            <span nxFormfieldPrefix>
                              <nx-icon name="search" size="s"></nx-icon>
                            </span>
                            <span nxFormfieldSuffix aria-hidden="true">
                              <nx-icon name="close" size="s"></nx-icon>
                            </span>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12" class="std-ta-right">
                          <button
                            nxButton="secondary"
                            (click)="closeTemplateDialog()"
                            class="nx-margin-right-s"
                          >
                            RESET
                          </button>
                          <button
                            nxButton
                            (click)="closeTemplateDialog()"
                            class="m_margin_top_20"
                          >
                            SEARCH
                          </button>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12" class="nx-margin-top-2m">
                          <div class="scroll-container table_popup">
                            <div>
                              <app-external-scrollbar
                                targetSyncScroll="5"
                                [targetBaseWidth]="tableHolder5"
                              ></app-external-scrollbar>
                              <div
                                class="table-holder table_content_scroll only-this-horizon-scrollbar"
                                #tableHolder5
                                syncScroll="5"
                              >
                                <table nxTable class="nx-table--scrollable">
                                  <thead>
                                    <tr nxTableRow>
                                      <th nxHeaderCell>Marketer Manager</th>
                                      <th nxHeaderCell>Agent Code</th>
                                      <th nxHeaderCell>Agent Name</th>
                                      <th nxHeaderCell>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr nxTableRow>
                                      <td nxTableCell>FREDA_CHAN</td>
                                      <td nxTableCell>MT22823</td>
                                      <td nxTableCell>
                                        SUNDRIES ALLIANZ ONLINE
                                      </td>
                                      <td nxTableCell>Active</td>
                                    </tr>
                                    <tr nxTableRow>
                                      <td nxTableCell>FREDA_CHAN</td>
                                      <td nxTableCell>MT5635</td>
                                      <td nxTableCell>
                                        GENTING RISK SOLUTIONS SDN BHD
                                      </td>
                                      <td nxTableCell>Active</td>
                                    </tr>
                                    <tr nxTableRow>
                                      <td nxTableCell>ONG_YANFANG</td>
                                      <td nxTableCell>MT06205</td>
                                      <td nxTableCell>
                                        STANDARD CHARTERED BANK MALAYSIA BERHARD
                                      </td>
                                      <td nxTableCell>Active</td>
                                    </tr>
                                    <tr nxTableRow>
                                      <td nxTableCell>ONG_YANFANG</td>
                                      <td nxTableCell>MT06205</td>
                                      <td nxTableCell>
                                        STANDARD CHARTERED BANK MALAYSIA BERHARD
                                      </td>
                                      <td nxTableCell>TST CENTRE-MOD</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- <nx-swipebar label="Swipe to view more">
                              <table nxTable class="nx-table--scrollable">
                                <thead>
                                  <tr nxTableRow>
                                    <th nxHeaderCell>Marketer Manager</th>
                                    <th nxHeaderCell>Agent Code</th>
                                    <th nxHeaderCell>Agent Name</th>
                                    <th nxHeaderCell>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr nxTableRow>
                                    <td nxTableCell>FREDA_CHAN</td>
                                    <td nxTableCell>MT22823</td>
                                    <td nxTableCell>
                                      SUNDRIES ALLIANZ ONLINE
                                    </td>
                                    <td nxTableCell>Active</td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>FREDA_CHAN</td>
                                    <td nxTableCell>MT5635</td>
                                    <td nxTableCell>
                                      GENTING RISK SOLUTIONS SDN BHD
                                    </td>
                                    <td nxTableCell>Active</td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>ONG_YANFANG</td>
                                    <td nxTableCell>MT06205</td>
                                    <td nxTableCell>
                                      STANDARD CHARTERED BANK MALAYSIA
                                      BERHARD
                                    </td>
                                    <td nxTableCell>Active</td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>ONG_YANFANG</td>
                                    <td nxTableCell>MT06205</td>
                                    <td nxTableCell>
                                      STANDARD CHARTERED BANK MALAYSIA
                                      BERHARD
                                    </td>
                                    <td nxTableCell>TST CENTRE-MOD</td>
                                  </tr>
                                </tbody>
                              </table>
                            </nx-swipebar> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    nxLayout="grid nopadding"
                    class="nx-margin-top-2xs grid_remove_padding pagination_form"
                  >
                    <div nxRow>
                      <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
                        <nx-natural-language-form
                          size="small"
                          class="pagination_form"
                        >
                          <span nxCopytext="normal">Show</span>
                          <nx-word>
                            <nx-dropdown value="20" [panelGrow]="true">
                              <nx-dropdown-item value="5">5</nx-dropdown-item>
                              <nx-dropdown-item value="10">10</nx-dropdown-item>
                              <nx-dropdown-item value="20">20</nx-dropdown-item>
                              <nx-dropdown-item value="30">30</nx-dropdown-item>
                              <nx-dropdown-item value="40">40</nx-dropdown-item>
                              <nx-dropdown-item value="50">50</nx-dropdown-item>
                            </nx-dropdown>
                          </nx-word>
                          <span nxCopytext="normal">results per page</span>
                        </nx-natural-language-form>
                      </div>

                      <div
                        class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                      ></div>
                      <div nxCol="12,12,6">
                        <nx-pagination
                          [count]="count"
                          [page]="page"
                          [perPage]="perPage"
                          type="advanced"
                          (goPrev)="prevPage()"
                          (goNext)="nextPage()"
                          (goPage)="goToPage($event)"
                          ariaLabel="Advanced example pagination"
                          class="pagination_position"
                        >
                        </nx-pagination>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two-button-container nx-margin-top-3m">
                  <button
                    nxButton="secondary"
                    (click)="closeTemplateDialog()"
                    class="nx-margin-right-s nx-margin-top-m"
                  >
                    Cancel
                  </button>
                </div>
              </ng-template>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield label="Select Agent Code or Name">
                <nx-dropdown [showFilter]="true">
                  <nx-dropdown-item
                    value="AS00151 - P & O AGENCY"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="AS00375 - SYARIKAT WEI CHIN"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="AS00584 - KALA ENTERPRISE"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="AS00592 - PHAN HOY MUN"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="AS00626 - ONG SENG SWEE @ ONG AH BA"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <nx-formfield label="Refered Items">
                <nx-dropdown [showFilter]="true">
                  <nx-dropdown-item value="ALL"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="COVERAGE DOWNGRADE"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="CURRENT SUM INSURED GREATER THAN PREVIOUS SUM INSURED"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="DRIVER AGE"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="ERROR IN VEHICLE CLASS ISM"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield label="e-Cover Note No.">
                <input nxInput />
                <nx-error nxFormfieldError>
                  <strong>Please note:</strong> this field is required!
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield label="Vehicle / Trailer No.">
                <input nxInput />
                <nx-error nxFormfieldError>
                  <strong>Please note:</strong> this field is required!
                </nx-error>
              </nx-formfield>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Status">
                <nx-dropdown value="All">
                  <nx-dropdown-item value="All"></nx-dropdown-item>
                  <nx-dropdown-item value="Printed"></nx-dropdown-item>
                  <nx-dropdown-item value="Submitted"></nx-dropdown-item>
                  <nx-dropdown-item value="Pending Approval"></nx-dropdown-item>
                  <nx-dropdown-item value="Approved"></nx-dropdown-item>
                  <nx-dropdown-item value="Cancelled"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Issuance Type">
                <nx-dropdown value="Issuance Type">
                  <nx-dropdown-item value="Issuance Type"></nx-dropdown-item>
                  <nx-dropdown-item value="Renewal"></nx-dropdown-item>
                  <nx-dropdown-item value="New Business - New Vehicle, New Registration"></nx-dropdown-item>
                  <nx-dropdown-item value="New Business - Old Vehicle, Old Registration"></nx-dropdown-item>
                  <nx-dropdown-item value="New Business - Transfer of Ownership"></nx-dropdown-item>
                  <nx-dropdown-item value="Endorsements"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Date Referred From">
                <input nxDatefield nxInput [datepicker]="myDatepicker2" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker2"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker2></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,3">
              <nx-formfield label="Date Referred To">
                <input nxDatefield nxInput [datepicker]="myDatepicker3" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker3"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker3></nx-datepicker>
              </nx-formfield>
            </div>
          
          </div>

          <!-- <div nxRow>
            <div nxCol="12,12,6">
              <nx-formfield label="Request Date From">
                <input nxDatefield nxInput [datepicker]="myDatepicker" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6">
              <nx-formfield label="Request Date To">
                <input nxDatefield nxInput [datepicker]="myDatepicker2" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker2"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker2></nx-datepicker>
              </nx-formfield>
            </div>
          </div> -->
          <div nxRow>
            <div nxCol="12" class="button_dashboard">
              <button nxButton="secondary" type="button">RESET</button>
              <button
                nxButton="primary"
                type="button"
                class="nx-margin-right-0"
              >
                SEARCH
              </button>
            </div>
          </div>
          <div nxRow class="nx-margin-bottom-2m blue_primary">
            <div nxCol="12" class="std-ta-right">
              <button nxPlainButton type="button">
                <nx-icon
                  name="pdf"
                  nxIconPositionStart
                  aria-hidden="true"
                ></nx-icon
                >DOWNLOAD
              </button>
            </div>
          </div>
        </div>

        <div>
          <app-external-scrollbar
            targetSyncScroll="6"
            [targetBaseWidth]="tableHolder6"
          ></app-external-scrollbar>
          <div
            class="table-holder table_content_scroll only-this-horizon-scrollbar"
            #tableHolder6
            syncScroll="6"
          >
            <table nxTable zebra nxSort>
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="date">Issued<br />Date</th>
                  <th nxHeaderCell nxSortHeaderCell="agentcode">
                    Agent Code / <br />Agent Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy">
                    e-Quotation / <br />e-Cover Note No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="product">Product Name</th>
                  <th nxHeaderCell nxSortHeaderCell="customer">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="issuance">
                    Issuance <br />Type
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="vehicle">
                    Vehicle / <br />Trailer No.
                  </th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="suminsured"
                    class="std-ta-right"
                  >
                    Sum Insured <br />(RM)
                  </th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="premium"
                    class="std-ta-right"
                  >
                    Premium <br />Due (RM)
                  </th>
                  <!-- <th nxHeaderCell nxSortHeaderCell="additional">
                          Additional Info
                        </th> -->
                  <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                  <th nxHeaderCell nxSortHeaderCell="Approveby">
                    Approve / Decline by
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="Approvedate">
                    Approve / Decline Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of tablependingmotorElements">
                  <td nxTableCell>{{ item.date }}</td>
                  <td nxTableCell>{{ item.agentcode }}</td>
                  <td nxTableCell class="completed_color_primary">
                    <a (click)="navEnquiry('Motor Approval Enquiry')">{{
                      item.policy
                    }}</a>
                  </td>
                  <td nxTableCell>{{ item.product }}</td>
                  <td nxTableCell>{{ item.customer }}</td>
                  <td nxTableCell>
                    <span class="table_column_issuance">{{
                      item.issuance
                    }}</span>
                  </td>
                  <td nxTableCell>{{ item.vehicle }}</td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.suminsured }}
                  </td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.premium }}
                  </td>
                  <!-- <td nxTableCell>{{ item.additional }}</td> -->
                  <td nxTableCell>
                    <nx-badge [type]="item.status" [class]="item.class"
                      >{{ item.statusText }}
                    </nx-badge>
                  </td>
                  <td nxTableCell></td>
                  <td nxTableCell></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <nx-swipebar label="Swipe to view more">
          <table nxTable zebra nxSort>
            <thead>
              <tr nxTableRow>
                <th nxHeaderCell nxSortHeaderCell="date">
                  Issued<br />Date
                </th>
                <th nxHeaderCell nxSortHeaderCell="agentcode">
                  Agent Code / <br />Agent Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="policy">
                  Quotation / <br />Cover Note No.
                </th>
                <th nxHeaderCell nxSortHeaderCell="product">
                  Product Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="customer">
                  Customer Name
                </th>
                <th nxHeaderCell nxSortHeaderCell="issuance">
                  Issuance <br />Type
                </th>
                <th nxHeaderCell nxSortHeaderCell="vehicle">
                  Vehicle / <br />Trailer No.
                </th>
                <th
                  nxHeaderCell
                  nxSortHeaderCell="suminsured"
                  class="std-ta-right"
                >
                  Sum Insured <br />(RM)
                </th>
                <th
                  nxHeaderCell
                  nxSortHeaderCell="premium"
                  class="std-ta-right"
                >
                  Premium <br />Due (RM)
                </th>
                <th nxHeaderCell nxSortHeaderCell="additional">
                  Additional Info
                </th>
                <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                <th nxHeaderCell nxSortHeaderCell="Approveby">
                  Approve / Decline by
                </th>
                <th nxHeaderCell nxSortHeaderCell="Approvedate">
                  Approve / Decline Date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr nxTableRow *ngFor="let item of tablependingmotorElements">
                <td nxTableCell>{{ item.date }}</td>
                <td nxTableCell>{{ item.agentcode }}</td>
                <td nxTableCell class="completed_color_primary">
                  <a (click)="navEnquiry('Motor Approval Enquiry')">{{
                    item.policy
                  }}</a>
                </td>
                <td nxTableCell>{{ item.product }}</td>
                <td nxTableCell>{{ item.customer }}</td>
                <td nxTableCell><span class="table_column_issuance">{{ item.issuance }}</span></td>
                <td nxTableCell>{{ item.vehicle }}</td>
                <td nxTableCell class="std-ta-right">
                  {{ item.suminsured }}
                </td>
                <td nxTableCell class="std-ta-right">{{ item.premium }}</td>
                <td nxTableCell>{{ item.additional }}</td>
                <td nxTableCell>
                  <nx-badge [type]="item.status" [class]="item.class">{{
                    item.statusText
                  }}</nx-badge>
                </td>
                <td nxTableCell></td>
                <td nxTableCell></td>
              </tr>
            </tbody>
          </table>
        </nx-swipebar> -->

        <div
          nxLayout="grid nopadding"
          class="nx-margin-top-2xs nx-margin-bottom-3xl grid_remove_padding pagination_form"
        >
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown value="20" [panelGrow]="true">
                    <nx-dropdown-item value="5">5</nx-dropdown-item>
                    <nx-dropdown-item value="10">10</nx-dropdown-item>
                    <nx-dropdown-item value="20">20</nx-dropdown-item>
                    <nx-dropdown-item value="30">30</nx-dropdown-item>
                    <nx-dropdown-item value="40">40</nx-dropdown-item>
                    <nx-dropdown-item value="50">50</nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div
              class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
            ></div>
            <div nxCol="12,12,6">
              <nx-pagination
                [count]="count"
                [page]="page"
                [perPage]="perPage"
                type="advanced"
                (goPrev)="prevPage()"
                (goNext)="nextPage()"
                (goPage)="goToPage($event)"
                ariaLabel="Advanced example pagination"
                class="pagination_position"
              >
              </nx-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
