<form [formGroup]="eHailingDriverNameForm">

    <div nxRow>
        <div nxCol="12">
            <h4 class="nx-margin-0">e-Hailing Drivers Name</h4>
        </div>
    </div>

    @if(rows.controls.length > 0) {
    <div nxRow>
        <div nxCol="12" class="driver_list">
            <nx-swipebar label="Swipe to see more" class="nx-margin-top-m">
                <table nxTable>
                    <thead>
                        <tr nxTableRow>
                            <th nxHeaderCell></th>
                            <th nxHeaderCell>Driver Name</th>
                            <th nxHeaderCell>Driver ID</th>
                            @if(isEndorsement) {
                                <th nxHeaderCell></th>
                            }
                        </tr>
                    </thead>
                    <tbody formArrayName="rows">
                        @for (row of rows.controls; track row) {
                        <tr nxTableRow [formGroupName]="$index">
                            <td nxTableCell>{{ $index + 1 }}</td>
                            <td nxTableCell>
                                <nx-formfield appearance="outline">
                                    <!-- <input formControlName="driverName" maxlength="100" nxInput (blur)="onChangeForm()" appToUpperCase appAlphaNumeric/> -->
                                    <input formControlName="driverName" maxlength="100" nxInput (blur)="onChangeForm()"  appToUpperCase oninput= "this.value = this.value.replace(/[^a-zA-Z0-9 ]/g, '')"/>
                                </nx-formfield>
                            </td>
                            <td nxTableCell>
                                <nx-formfield appearance="outline" class="driver_id">
                                    <input formControlName="driverId" maxlength="25" nxInput (blur)="onChangeForm()"  appToUpperCase appAlphaNumeric/>
                                </nx-formfield>
                            </td>
                            @if(isEndorsement){
                            <td nxTableCell>
                                <button nxPlainButton type="button" class="nx-margin-left-m">
                                    <nx-icon name="trash-o" size="s"></nx-icon>
                                </button>
                            </td>
                            }
                        </tr>
                        }

                    </tbody>
                </table>
            </nx-swipebar>
        </div>
    </div>
    }

    @if(isEndorsement){
    <div nxRow class="nx-margin-top-m">
        <div nxCol="12">
            <button nxPlainButton type="button" class="nx-margin-right-m" [disabled]="true">
                <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>ADD DRIVER
            </button>
        </div>
    </div>
    }

</form>