import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { formatNumber } from "@angular/common";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { GET_PREVIOUS_POLICY_NO, SEARCH_VIX, SELECTED_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { UserResponse } from "@interfaces/user";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { SharedModule } from "@shared/shared.module";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NumberRangeValidator } from "@shared/classes/validators/number-range.validator";
import { MakeModelComponent } from "@shared/components/modal/make-model/make-model.component";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-vehicle-view-amendment',
  standalone: true,
  imports: [SharedModule, NxMomentDateModule],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }],
  templateUrl: './vehicle-view-amendment.component.html',
  styleUrl: './vehicle-view-amendment.component.scss'
})
export class VehicleViewAmendmentComponent implements OnInit {

  @Input() isUpdateJPJScreen: boolean = false;
  @Input() isAmendDateScren: boolean = false;

  @Input() cngeNote: any;
  @Input() coverageInfo: any;

  vehicleDetailForm: FormGroup;
  decimalFmt: any;
  numberFmt: any;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });

    if (this.isUpdateJPJScreen) {
      let data = this.coverageInfo.vehicleDetails;
      this.vehicleDetailForm = this.fb.group({
        vehicleNo: ["", [Validators.required, VehicleNoValidator()]],
        vehicleEngine: ["", [Validators.required]],
        vehicleChassis: ["", [Validators.required]],
        jpjEffectiveDate: [{ value: "", disabled: true }],
        jpjExpiryDate: [{ value: "", disabled: true }],
      });

      if (data) {
        this.vehicleDetailForm.patchValue({
          vehicleNo: data.vehicleNo,
          vehicleEngine: data.vehicleEngine,
          vehicleChassis: data.vehicleChassis,
          jpjEffectiveDate: data.jpjEffectiveDate,
          jpjExpiryDate: data.jpjExpiryDate
        });
      }
      
    }

    if (this.isAmendDateScren) {
      this.vehicleDetailForm = this.fb.group({
        vehicleNo: ["", [Validators.required, VehicleNoValidator()]],
        vehicleMakeYear: [{ value: "", disabled: true }],
        vehModelNvicDesc: [{ value: "", disabled: true }],
        vehicleEngine: [{ value: "", disabled: true }],
        vehicleChassis: [{ value: "", disabled: true }],
        jpjEffectiveDate: ["", [Validators.required]],
        jpjExpiryDate: [{ value: "", disabled: true }],
        jpjStatus: [{ value: "", disabled: true }],
      });
    }

  }
  
}
