<div [formGroup]="extraCoverDetailForm">

  <app-driver-name [isComprehensive]="isComprehensive" [isThirdParty]="isThirdParty"
    [isThirdPartyFireTheft]="isThirdPartyFireTheft" [defaultDriver]="defaultDriver" [parentForm]="extraCoverDetailForm"
    (selectExtraCover)="selectExtraCover($event)" (removeExtraCover)="removeExtraCover($event)"
    (selectWaiver)="selectWaiver($event)" [coverageFmGroup]="coverageFmGroup"
    (selectAllDriver)="selectAllDriver($event)">
  </app-driver-name>

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <hr />
    </div>
  </div>

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <h4 class="nx-margin-0">Extra Coverage</h4>
    </div>
  </div>
  @if(rahmahExtraCoverList?.length > 0 || topExtraCoverList?.length > 0){
  <div nxRow class="nx-margin-top-m">
    <div nxCol="12" class="nx-font-weight-semibold">These coverage(s) / service(s) are provided at no extra cost.</div>
  </div>
  }

  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <table class="extra_package">
        <tbody>
          <tr nxTableRow>
            <td *ngIf="packageDescription">
              <nx-checkbox [disabled]="true" [checked]="true" labelSize="small">
                <div class="nx-font-weight-bold">
                  {{ packageDescription | uppercase}}
                </div>
              </nx-checkbox>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr nxTableRow *ngFor="let item of rahmahExtraCoverList; let i = index" [selectable]="true">
            <td>
              <div class="sub_cover">
                <ul nxList>
                  <li nxListIcon="minus" size="s">
                    <p nxCopytext="medium">
                      {{ item.description || item.subCoverCodeDescription }}
                    </p>
                  </li>
                </ul>
              </div>
            </td>
            <td *ngIf="item.subCoverSumInsured != 0" class="si">
              SUM INSURED (RM) : {{ item.subCoverSumInsured | formatDecimal}}
            </td>
            <td *ngIf="item.subCoverAmtPerDay != 0 && item.subCoverNumberOfDays != 0" class="si">
              RM {{ item.subCoverAmtPerDay }} PER DAY / MAX {{ item.subCoverNumberOfDays }} DAYS
            </td>
            <td></td>
          </tr>
          <!-- top = true, disable = true -->
          <tr nxTableRow *ngFor="let item of topExtraCoverList; let i = index" [selectable]="true">
            <td class="cell-padding-bottom">
              <nx-checkbox (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                (change)="emitData(selection.select(item), item.subCoverCode, 'topHeader')" [value]="item.subCoverCode"
                [checked]="item.selected" [disabled]="!item.enabled">
                <div class="nx-font-weight-bold">
                  {{ item.description || item.subCoverCodeDescription }}
                </div>
              </nx-checkbox>
            </td>
            <td class="std-ta-right border-top-none" *ngIf="item.mtExtCovDtoList && item.mtExtCovDtoList.length > 0">
              <ng-container *ngFor="let subItem of item.mtExtCovDtoList">
                <!-- Dropdown field (if subItem.type is 'DROPDOWN') -->
                <ng-container *ngIf="subItem.type === 'DROPDOWN'">
                  <div class="dropdown-container">
                    <span class="label">{{ subItem.label }}:</span>
                    <nx-formfield class="formfield_no_padding">
                      <nx-dropdown [panelGrow]="true" [value]="item.planCode" [disabled]="true"
                        (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                        class="margin-dropdown">
                        <ng-container *ngFor="let dropdownitem of subItem.data">
                          <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                            <span class="dropdown-item-desc">{{ dropdownitem.descp }}</span>
                          </nx-dropdown-item>
                        </ng-container>
                      </nx-dropdown>
                    </nx-formfield>
                  </div>
                </ng-container>
              </ng-container>
              @if(item.subCoverSumInsured != 0){ SUM INSURED (RM) : {{ item.subCoverSumInsured |  formatDecimal}}}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="nx-margin-top-m">
        <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
          <table nxTable class="more_extra_cover">
            <tbody>
              <tr nxTableRow>
                <th nxHeaderCell>
                  <nx-checkbox class="nx-font-weight-semibold" formControlName="checkedAll"
                    (change)="checkedAllChange()" forcheckedAllChange>
                    <div class="nx-font-weight-bold">
                      Extra Cover
                    </div>
                  </nx-checkbox>
                </th>
                <th nxHeaderCell></th>
                <th nxHeaderCell>Premium (RM)</th>
              </tr>
              <!-- start selected = true -->
              <tr nxTableRow *ngFor="let item of extraCoverList;" [selectable]="true">
                <td nxTableCell>
                  <nx-checkbox labelSize="small" class="nx-font-weight-semibold"
                    (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                    (change)="emitData(selection.select(item), item.subCoverCode, 'bottom')" [value]="item.subCoverCode"
                    [checked]="true" [disabled]="!item.enabled">
                    {{ item.description || item.subCoverCodeDescription }}
                  </nx-checkbox>
                </td>

                <td nxTableCell class="std-ta-right" *ngIf="item.mtExtCovDtoList && item.mtExtCovDtoList.length > 0">
                  <ng-container *ngFor="let subItem of item.mtExtCovDtoList">

                    <!-- Label for the field -->
                    <ng-container *ngIf="subItem.type === 'INPUT'">
                      <nx-natural-language-form size="small">{{ subItem.label }}
                        <nx-word>
                          <input nxInput type="text" [value]="item.subCoverSumInsured | formatDecimal"
                            (change)="onChangeSubCoverSumInsured(item.subCoverCode, $event.target)"
                            [disabled]="subItem.disable" />
                        </nx-word>
                      </nx-natural-language-form>
                    </ng-container>


                    <!-- Dropdown field (if subItem.type is 'DROPDOWN') -->
                    <ng-container *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode != 112">
                      <div class="dropdown-container">
                        <span class="label">{{ subItem.label }}:</span>
                        <nx-formfield class="formfield_no_padding">
                          <nx-dropdown [panelGrow]="true" [value]="item.planCode"
                            (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                            class="margin-dropdown">
                            <ng-container *ngFor="let dropdownitem of subItem.data">
                              <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                                <span class="dropdown-item-desc">{{ dropdownitem.descp }}</span>
                              </nx-dropdown-item>
                            </ng-container>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'NO OF DAY'">
                      <div class="dropdown-container">
                        <span class="label">{{ subItem.label }}:</span>
                        <nx-formfield class="formfield_no_padding">
                          <nx-dropdown [panelGrow]="true" [value]="getSubCoverNumberOfDaysAsString(item.subCoverDays)"
                            (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                            class="margin-dropdown">
                            <ng-container *ngFor="let dropdownitem of subItem.data">
                              <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                              </nx-dropdown-item>
                            </ng-container>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="subItem.type === 'DROPDOWN' &&  item.subCoverCode == 112 && subItem.label == 'AMOUNT PER DAY'">
                      <div class="dropdown-container">
                        <span class="label">{{ subItem.label }}:</span>
                        <nx-formfield class="formfield_no_padding">
                          <nx-dropdown [panelGrow]="true" [value]="item.subCoverAmtday"
                            (selectionChange)="onChangeProduct(item.subCoverCode, $event.value, subItem.label)"
                            class="margin-dropdown">
                            <ng-container *ngFor="let dropdownitem of subItem.data">
                              <nx-dropdown-item [value]="dropdownitem.code" class="table_dropdown">
                              </nx-dropdown-item>
                            </ng-container>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>

                <td nxTableCell class="std-ta-right" *ngIf="!item.mtExtCovDtoList || item.mtExtCovDtoList.length === 0">
                </td>

                <td nxTableCell class="std-ta-right">
                  @if(item.subCoverPrem > 0){{{ item.subCoverPrem | formatDecimal }}}
                </td>
              </tr>

              <!-- unnamed driver selected = true -->
              <!-- <tr nxTableRow *ngFor="let item of unnamedDriverExtraCoverList;">
                <td nxTableCell>
                  <nx-checkbox labelSize="small" class="nx-font-weight-semibold" aria-label="Toggle row selection"
                    [value]="item.subCoverCode" [checked]="item.selected" [disabled]="!item.enabled">
                    {{ item.description || item.subCoverCodeDescription }}
                  </nx-checkbox>
                </td>

                <td nxTableCell class="std-ta-right"></td>

                <td nxTableCell class="std-ta-right">
                  {{ item.subCoverPrem | formatDecimal }}
                </td>
              </tr> -->

              <!-- start top = true selected = false -->
              <tr nxTableRow *ngFor="let item of defaultedExtraCoverList;" [selectable]="true">
                <td nxTableCell>
                  <nx-checkbox labelSize="small" class="nx-font-weight-semibold"
                    (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                    (change)="emitData(selection.select(item), item.subCoverCode, 'defaultedExtraCover')"
                    [value]="item.subCoverCode" [checked]="false" [disabled]="!item.enabled">
                    {{ item.description || item.subCoverCodeDescription }}
                  </nx-checkbox>
                </td>


                <ng-container *ngIf="item.mtExtCovDtoList?.length && item.selected === true; else emptyCell">
                  <td *ngFor="let subItem of item.mtExtCovDtoList" nxTableCell class="std-ta-right">
                    <nx-natural-language-form size="small">
                      {{ subItem.label }}
                      <nx-word>
                        <input nxInput type="text" [disabled]="!item.enabled" />
                      </nx-word>
                    </nx-natural-language-form>
                  </td>
                </ng-container>

                <ng-template #emptyCell>
                  <td nxTableCell class="std-ta-right"></td>
                </ng-template>

                <td nxTableCell class="std-ta-right">
                  @if(item.subCoverPrem > 0){{{ item.subCoverPrem | formatDecimal }}}
                </td>
              </tr>
              <!-- end top = true selected = false -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div nxRow class="nx-margin-top-m">
  <div nxCol="12">
    <button nxPlainButton type="button" class="nx-margin-right-m" (click)="openPopupComp('comprehensive')">
      <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
      ADD MORE
    </button>
  </div>
</div>
@if(privateHireCarIsSelected){
<div nxRow>
  <div nxCol="12">
    <hr class="nx-margin-y-2m" />
  </div>
</div>

<div nxRow class="nx-margin-top-m">
  <div nxCol="12">
    <app-e-hailing-driver [parentForm]="extraCoverDetailForm"></app-e-hailing-driver>
  </div>
</div>
}