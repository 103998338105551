<div>
    <div class="upload_title nx-margin-top-0">Upload Document</div>
    <div class="upload_des">You may upload multiple files by clicking on the "+ ADD MORE".</div>
    <div nxModalContent class="upload_doc_row top-scroller">
        <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder1"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder1 syncScroll="1">
            <table nxTable zebra cellpadding="0" class="upload_table">
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell>
                            <span nxCopytext="medium" class="nx-font-weight-semibold">Document Needed</span>
                        </th>
                        <th nxHeaderCell>
                            <span nxCopytext="medium" class="nx-font-weight-semibold">Mandatory/<br />Optional</span>
                        </th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Filename</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Action</span></th>
                    </tr>
                </thead>
                <tbody>
                    @for (selectedOptinalDoc of listOfSelectedOptionalDocuments; track $index) {
                    @for (document of selectedOptinalDoc.documents; let docIndex = $index; track docIndex) {
                    <tr nxTableRow>
                        <td nxTableCell>
                        <ol [start]="$index + 1" [style.visibility]="docIndex === 0 ? 'visible' : 'hidden'" nxCopytext="medium" class="upload_content">
                            <li>
                                <ng-container *ngIf="docIndex === 0">
                                    {{ selectedOptinalDoc.descp }}
                                </ng-container>
                            </li>
                        </ol>
                        </td>
                        <td nxTableCell>
                            <span nxCopytext="medium" [style.visibility]="docIndex === 0 ? 'visible' : 'hidden'">
                                Optional
                            </span>
                        </td>
                        <td nxTableCell>
                            <span nxCopytext="medium">
                                @if (document?.file?.name) {
                                {{ document?.file?.name }}
                                } @else if (document.name) {
                                {{ document.name }}.{{ document.type }}
                                } @else {}
    
                                <!-- {{ document?.file?.name ?? document.name + "." + document.type }} -->
                            </span>
                        </td>
                        <td nxTableCell>
                            <div class="upload_list_button">
                                <div class="nx-margin-right-xs upload_btn_width">
                                    <input type="file" style="display: none" #fileUploadInput
                                        (change)="onChangeFileInput($event, document.docId)"
                                        [accept]="allowedExtensions" />
                                    <button class="btnwidth" type="button" nxButton="secondary small"
                                        (click)="fileUploadInput.click()">
                                        CHOOSE FILE
                                    </button>
                                </div>
                                <div class="tablet_display">
                                    <button [style.visibility]="docIndex === 0 ? 'visible' : 'hidden'"
                                        class="nx-margin-right-xs upload_btn_width" nxButton="secondary small" type="button"
                                        (click)="handleListSelectedOptionalDocs(selectedOptinalDoc)">
                                        <nx-icon name="plus" nxIconPositionsStart aria-hidden="true"></nx-icon>
                                        ADD MORE
                                    </button>
                                    <div class="horizontal-checkbox">
                                        <button class="upload_view_btn_space upload_view" nxPlainButton type="button" aria-label="add item"
                                            (click)="openDeleteConfirmationModal(selectedOptinalDoc.code, document.docId, selectedOptinalDoc)">
                                            <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
                                        </button>
                                        <button nxPlainButton type="button" class="upload_view"
                                            (click)="downloadDocument(document.docId, document.name+'.'+document.type)"
                                            [disabled]="!document.docId || document.docId.startsWith('DUMMY')">
                                            VIEW
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    }
                    }
    
                    <tr nxTableRow>
                        <td nxTableCell>
                            <nx-formfield label="Upload Optional Document" class="upload_dropdown">
                                <nx-dropdown [(value)]="selectedOptionalDoc" ariaLabelledBy="status" [showFilter]="true" [panelGrow]="true" [panelMaxWidth]="2000">
                                    @for (optionalDoc of listOfOptionalDocs; track $index) {
                                    <nx-dropdown-item (click)="handleDropdownSelection(optionalDoc)"
                                        [value]="optionalDoc.descp"></nx-dropdown-item>
                                    }
                                </nx-dropdown>
                            </nx-formfield>
                        </td>
                        <td nxTableCell><span nxCopytext="medium">Optional</span></td>
                        <td nxTableCell></td>
                        <td nxTableCell></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    @if (showMessageIfUploadSuccess) {
    <nx-message context="info" class="green_color_notification nx-margin-top-xs"><span class="nx-font-weight-bold"> {{
            uploadCallCounter }} file(s) have been successfully uploaded.</span>
    </nx-message>
    }
    
    @if (isFileDeleteFailed) {
        <nx-message context="error" class="nx-margin-top-xs">
            <span class="nx-font-weight-bold">Failed to delete for document {{fileName}}</span>
        </nx-message>
    }
    
    
    @if (showifUploadFailed) {
        @for (docName of failedUploadFiles; track $index) {
            <nx-message context="error" class="nx-margin-top-xs">
                <span class="nx-font-weight-bold">Failed to upload for document - {{docName}}</span>
            </nx-message>
        }
    }
    
    <div class="nx-margin-top-s">
        <button (click)="onCloseUploadModal()" class="button_upload_2btn" nxButton="secondary" type="button">Close</button>
        <button 
            (click)="uploadDocuments()" 
            [disabled]="(hasDummyDocId() && !hasFileDetail()) || listOfSelectedOptionalDocuments.length === 0" 
            nxButton="primary" 
            type="button">
            Upload
        </button>
    </div>
    
    <ng-template #deleteConfirmationDialogRef>
        <div nxModalContent>
            <div class="delete_title">
                Warning
            </div>
            <p nxCopytext="normal" class="nx-margin-bottom-2m">
                Deleted file cannot be restored. Are you sure you want to proceed?
            </p>
        </div>
        <button (click)="closeDeleteConfirmationModal('cancel')" class="button_delete_2btn" nxButton="primary" type="button">
            NO
        </button>
        <button (click)="closeDeleteConfirmationModal('proceed')" nxButton="primary" type="button">
            YES
        </button>
    </ng-template>
    
    <ng-template #notAllowedExtensionDialog>
        <div nxModalContent class="std-ta-center">
            <h2 nxHeadline="subsection-medium" class="nx-margin-bottom-m">
                {{documentTypeName}} document type selected is not supported. Please do upload only jpeg, png, gif, doc, docx, odf, rtf, txt, pdf or msg file type & not more than 6 MB.
            </h2>
        </div>
        <div class="upload_error_center">
            <button (click)="closeDeleteConfirmationModal('cancel')" nxButton="secondary" type="button" class="button_upload_error">
                OK
            </button>
        </div>
    </ng-template>
    
    <ng-template #fileTooLargeDialog>
        <div nxModalContent class="std-ta-center">
            <div class="delete_title nx-font-weight-regular">
                Warning
            </div>
            <p nxCopytext="normal" class="nx-margin-bottom-2m nx-font-weight-light">
                File size limit is exceeded, it must be less than 6 MB
            </p>
        </div>
        <div class="upload_error_center">
            <button (click)="closeDeleteConfirmationModal('cancel')" nxButton="primary" type="button">
                OK
            </button>
        </div>
    </ng-template>
    
    <ng-template #mandatoryDocumetNotAllowedDeleteDialog>
        <div nxModalContent>
            <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
            <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
                Not allowed to delete mandatory document.
            </p>
        </div>
        <div nxModalActions>
            <button (click)="closeDeleteConfirmationModal('cancel')" class="nx-margin-bottom-0" nxButton="primary"
                type="button">
                OK
            </button>
        </div>
    </ng-template>
    
    <ng-template #showNoFileChangesDialog>
        <div nxModalContent class="std-ta-center">
            <h2 nxHeadline="subsection-medium" class="nx-margin-bottom-m">
                No file changes detected.
            </h2>
        </div>
    
        <div class="upload_error_center">
            <button (click)="closeDeleteConfirmationModal('cancel')" nxButton="secondary" type="button" class="button_upload_error">
                OK
            </button>
        </div>
    </ng-template>

    <!-- PopUp for Has Upload Document (to be move to new component) -->
    <ng-template #closeWithoutSaveDialog>
        <div nxModalContent>
            <div class="hasupload">
            <div class="delete_title">
                Warning
            </div>
            <p nxCopytext="normal" class="hasupload_btn_gap">
                Attached document(s) has not been uploaded. Are you sure you want to exit?
            </p>

        <button (click)="closeDeleteConfirmationModal('cancel')" class="button_delete_2btn" nxButton="primary" type="button">
            NO
        </button>
        <button (click)="closeDeleteConfirmationModal('proceed')" nxButton="primary" type="button">
            YES
        </button>
        </div>
        </div>
    </ng-template>
    
    <ng-template #closeWithoutSaveDialog>
        <div nxModalContent>
            <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
            <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
                Attached document(s) has not been uploaded. Are you sure you want to exit?
            </p>
        </div>
        <div nxModalActions>
            <button (click)="closeDeleteConfirmationModal('cancel')" class="button_popup_2btn" nxButton="secondary"
                type="button">
                NO
            </button>
            <button (click)="closeDeleteConfirmationModal('proceed')" class="nx-margin-bottom-0" nxButton="primary"
                type="button">
                YES
            </button>
        </div>
    </ng-template>
    
    <ng-template #failedDownloadDialog>
        <div nxModalContent>
            <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
            <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
                Failed to download document.
            </p>
        </div>
        <div nxModalActions>
            <button (click)="closeDeleteConfirmationModal('cancel')" class="nx-margin-bottom-0" nxButton="primary"
                type="button">
                OK
            </button>
        </div>
    </ng-template>

    <ng-template #fileNotReadyDialog>
        <div  class="std-ta-center">
            <div nxModalContent>
                <div class="nofiles_title nx-margin-top-0">
                    Warning
                </div>
                <p nxCopytext="normal" class="nx-font-weight-regular nx-margin-bottom-m">
                    The file is not ready yet. Please try again later.
                </p>
            </div>
    
            <button (click)="closeDeleteConfirmationModal('cancel')" nxButton="primary" type="button" class="nofiles_button">
                OK
            </button>
        </div>
    </ng-template>

</div>