<div nxModalContent>
    <div class="modal__headline text-align-left">Warning</div>

    <p class="modal__subheadline c__delete-warning-modal-subheadline text-align-left">
        The information that you've entered will be deleted. Would you like to proceed?
    </p>
</div>

    <button nxModalClose nxButton="secondary" type="button" (click)="cancel()">Cancel</button>
    <button nxButton="primary" type="button" (click)="proceed()">Proceed</button>
