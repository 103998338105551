import { Component } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PdpaPopupComponent } from "../../popup/pdpa-popup/pdpa-popup.component";
@Component({
  selector: "app-pdpa",
  standalone: true,
  templateUrl: "./pdpa.component.html",
  styleUrl: "./pdpa.component.scss",
  imports: [SharedModule, ProgressBarComponent],
})
export class PdpaComponent {
  cn_quotation = true;
  // endorsement = false;
  endorsement = true; /////////////// for building endorsement component

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  changeType(type: any) {
    if (type == "CN") {
      this.cn_quotation = true;
      this.endorsement = false;
    }
    if (type == "ENDT") {
      this.cn_quotation = false;
      this.endorsement = true;
    }
  }

  consentChange(event: any) {
    // console.log(event.value)
    localStorage.setItem("eInvoice_consent", event.value);
  }

  constructor(private dialogService: NxDialogService) {}
  componentDialogRef?: NxModalRef<PdpaPopupComponent>;

  openPopup(popup_type: any) {

    // NOTE POPUP MESSAGE
    // Vehicle details
    // 1 = Vehicle No. doesn't exist.
    // 2 = vehicle found and already expired
    // 3 = vehicle found but another endorsement in progress
    // 4 = vehicle found but already cancelled
    // 5 = e-Invoice Consent is Yes and IRBM status is Submitted

    // Endorsment
    // 6 = When select Change of Bancassurance Details, if the policy agent not belong to Banca Agent
    // 7 = When select NCD Withdrawal, if the policy NCD is zero
    // 8 = When select NCD Allowed, if the policy NCD is not zero
    // 9 = When select Amendment of e-Invoice Details, e-Invoice Consent is not Yes or policy/previous endt IRBM status is not "Invalid"
    // 10 = Change of e-Invoice consent are not allowed
    // 11 = Amendment of e-Cover Note details are not allowed except Cancellation.

    // Endorsment effective date 
    // 12 = If date input earlier than current date
    // 13 = If date input after Endt Exp Date
    // 14 = If date input earlier than Policy Effective Date

    // Endorsment expiry date 
    // 15 = When select Extension Prorate and date range between Endt Eff Date & Endt Exp Date more than 6 months
    // 16 = If date input earlier than or equal Endt Eff Date

    // ID NO.
    // 17 = If active quotation (Printed) exist
    // 18 = Validation (RN / NWOO) - If vehicle found and existing policy still inforce and not going to expire in next 90 days

    // Effective date 
    // 19 = If Effective Date < Issued Date + YY days
    // 20 = If Effective Date > Issued Date + YY days
    // 21 = If Effective Date > Issued Date + XX days
    // 22 = If Effective Date = Issued Date

    // Expiry date
    // 23 = If Expiry Date < Eff Date
    // 24 = If Expiry Date > Eff Date + XX months
    this.componentDialogRef = this.dialogService.open(PdpaPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }
}
