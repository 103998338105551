import { Component, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from "../../shared/shared.module";
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import {TextFieldModule} from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
// import { Moment } from 'moment';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { SummaryPopupComponent } from "../../popup/summary-popup/summary-popup.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    ProgressBarComponent,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxRadioModule,
    SharedModule
  ],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent {

    constructor(private readonly dialogService: NxDialogService) {
        //       this.formGroup = new FormBuilder().group({
        //     firstName: ['', Validators.required],
        // });
    }

    
    // referralFormControl = new FormControl('', [
    //     Validators.required,
    // ]);


    componentDialogRef?: NxModalRef<SummaryPopupComponent>;

    openPopup(popup_type: any) {
      this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
        ariaLabel: "A simple dialog",
        showCloseIcon: false,
        width: '400px',
        data: { popup_type: popup_type },
      });
    }

    openPopupOCC(popup_type: any) {
      this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
        ariaLabel: "A simple dialog",
        showCloseIcon: false,
        height: '500px',
        data: { popup_type: popup_type },
      });
    }


}