<div>
    <app-external-scrollbar targetSyncScroll="6"
        [targetBaseWidth]="tableHolder6"></app-external-scrollbar>
    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder6
        syncScroll="6">
        <table nxTable nxSort class="enquiry">
            <thead>
                <tr nxTableRow>
                    <th nxHeaderCell nxSortHeaderCell="product">Claim No.</th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Claim Type
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Claim Loss Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Claim Code
                    </th>
                </tr>
            </thead>
            <tbody>
                @if (data !== null && data.length !== 0) {
                    @for(item of data; track item) {
                    <tr nxTableRow>
                        <td nxTableCell>{{ item.claimNo }}</td>
                        <td nxTableCell>{{ item.claimDescription }}</td>
                        <td nxTableCell>{{ item.lossDate | date : "dd/MM/yyyy" }}</td>
                        <td nxTableCell>{{ item.claimCode }}</td>
                    </tr>
                    }
                }@else {
                    <tr nxTableRow>
                        <td nxTableCell colspan="4" class="std-ta-center">NO DATA</td>
                    </tr>
                }
                
            </tbody>
        </table>
    </div>
</div>