import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-endt-narration',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './endt-narration.component.html',
  styleUrl: './endt-narration.component.scss'
})
export class EndtNarrationComponent implements OnInit {
  endtNarration: string;
  safeEndtNarration: SafeHtml;

  constructor(
    private sanitizier: DomSanitizer,
    private dialogRef: NxModalRef<any>,
    @Inject(NX_MODAL_DATA) readonly parentData: any,
  ) {
    this.endtNarration = parentData.endtNarration;
  }

  ngOnInit() {
    this.safeEndtNarration = this.sanitizier.bypassSecurityTrustHtml(this.endtNarration);
  }

  close() {
    this.dialogRef.close();
  }
}
