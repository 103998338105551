import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { CANCEL_REPLACE, REQUEST_CANCELLATION } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-request-cancellation',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './request-cancellation.component.html',
  styleUrl: './request-cancellation.component.scss'
})
export class RequestCancellationComponent implements OnInit {

  requestCancellationForm: FormGroup;

  
  summaryData: any;
  reasonLov: any;

  constructor(
    private fb: FormBuilder,
    private dialogRef: NxModalRef<any>,
    private store: Store,
    @Inject(NX_MODAL_DATA) readonly data: any,
  ) {
    this.summaryData = data.summaryData;
  }

  ngOnInit() {
    this.reasonLov = this.store.selectSnapshot<any>(state => state.SatMotorState.lov.cancelReasonList);
    
    this.requestCancellationForm = this.fb.group({
      cancellationReason: [''],
      cancelRemarks: [''],
    });

    this.initValidators();
  }

  initValidators() {
    if(this.isCoverNote) {
      this.requestCancellationForm.controls['cancellationReason'].setValidators(Validators.required);
      this.requestCancellationForm.controls['cancelRemarks'].setValidators(Validators.required);
    }
  }

  submitCancellation() {
    if (this.requestCancellationForm.valid) {
      //store response to summary
      this.close({ data: this.requestCancellationForm.getRawValue(), isCancelAndReplace: false });
    } else {
      this.requestCancellationForm.markAllAsTouched();
    }
  }

  cancelReplace() {
    if (this.requestCancellationForm.valid) {
      this.close({ data: this.requestCancellationForm.getRawValue(), isCancelAndReplace: true });
    } else {
      this.requestCancellationForm.markAllAsTouched();
    }
  }

  close(param) {
    this.dialogRef.close(param);
  }

  get isEquotation() {
    return (!this.summaryData.cnoteNo) && this.summaryData.proposalNo && !this.summaryData.draftInd;
  }

  get isCoverNote() {
    if(this.summaryData.cnoteNo || this.data.isViewQuotation) {
      return true;
    }else {
      return false;
    }
    
  }

  get activateCancelAndReplaceBtn() {
    if(this.isEquotation || (this.isCoverNote && this.summaryData.jpjStatus !== "OK" && !this.summaryData.policyNo)) {
        return true;
      }else {
        return false;
      }
  }
}
