import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { SEARCH_ENDORSEMENT_MAKE_MODEL, SEARCH_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { Subject, debounceTime, of, takeUntil } from "rxjs";
import { UploadDocument } from "@interfaces/fileupload";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";

@Component({
  selector: 'app-make-model',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './make-model.component.html',
  styleUrl: './make-model.component.scss',
})
export class MakeModelComponent implements OnInit {
  @ViewChild('tableHeader', { static: false }) tableHeader: ElementRef<HTMLTableHeaderCellElement>;
  @Select(SatMotorState.motorActionType) actionType$;

  isEndorsement: boolean = false;

  searchModelForm: FormGroup;

  searchMakeModelList: any = [];
  isSelectable: boolean = false;
  selection: any;
  selected: any;

  curpage: number = 1;
  perPage: number = 5;
  itemsPerPageSelections = [5, 10, 20, 50, 100];

  count: number = 0;
  showPagination: boolean = false;
  pageFilterForm: FormGroup;
  unsubscribe$ = new Subject();

  constructor(
    @Inject(NX_MODAL_DATA) public data: any,
    private dialogRef: NxModalRef<any>,
    private fb: FormBuilder,
    private store: Store,
  ) {
  }

  ngOnInit(): void {

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isEndorsement = state.isEndorsement;
        // console.log("MakeModelComponent isEndorsement", this.isEndorsement);
      }
    });
    
    this.searchModelForm = this.fb.group({
      searchBy: ['', Validators.required],
    });
    
    this.pageFilterForm = this.fb.group({
      perPage: [this.perPage]
    });
  }

  onSearchClicked() {
    this.searchMakeModelList = [];
    this.showPagination = false;

    let value = this.searchModelForm.getRawValue()["searchBy"];

    if (!this.searchModelForm.controls["searchBy"].valid) {
      this.searchModelForm.controls["searchBy"].markAllAsTouched();
      return;
    }


    if (this.isEndorsement) {
      this.store.dispatch(new SEARCH_ENDORSEMENT_MAKE_MODEL({ type: value, classCode: this.data.vehicleClass, yearMnftr: this.data.yearMnftr })).subscribe((state) => {
        this.searchMakeModelList = state.SatMotorState.data.step2.endorsementDetails.searchMakeModelList;
        if (this.searchMakeModelList && this.searchMakeModelList.length > 5) {
          this.showPagination = true;
        }
        this.setItemPerPage();
      });
    } else {
      this.store.dispatch(new SEARCH_MAKE_MODEL({ type: value, classCode: this.data.vehicleClass, yearMnftr: this.data.yearMnftr })).subscribe((state) => {
        this.searchMakeModelList = state.SatMotorState.data.step3.coverage.searchMakeModelList;
        if (this.searchMakeModelList && this.searchMakeModelList.length > 5) {
          this.showPagination = true;
        }
        this.setItemPerPage();
      });    
    }    
  }

  select(item: any) {
    this.dialogRef.close({ item });
  }

  prevPage() {
    this.curpage--;
    this.scrollIntoView();
  }

  nextPage() {
    this.curpage++;
    this.scrollIntoView();
  }

  goToPage(n: number) {
    this.curpage = n;
    this.scrollIntoView();
  }

  scrollIntoView() {
    this.tableHeader.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  setItemPerPage() {
    this.pageFilterForm.get('perPage').valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.unsubscribe$)
    ).subscribe((value) => {
      this.perPage = value;
      this.curpage = 1;
      this.scrollIntoView();
    });
  }

  get paginatedResults() {
    if (this.searchMakeModelList) {
      const startIndex = (this.curpage - 1) * this.perPage;
      return this.searchMakeModelList.slice(startIndex, startIndex + this.perPage);
    }
    return [];
  }

  getResultLength() {
    if (this.searchMakeModelList) {
      return this.searchMakeModelList.length;
    }
    return 0;
  }
}
