import { Component, Inject, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: "app-previous-policy-error-popup",
  standalone: true,
  imports: [
    SharedModule,
    NxGridModule
  ],
  templateUrl: "./previous-policy-error-popup.component.html",
  styleUrl: "./previous-policy-error-popup.component.scss",
})
export class PreviousPolicyErrorPopupComponent {
  templateDialogRef?: NxModalRef<any>;
  popup_type: any;
  popup_error: any;
  activeQuotationDetails: any;
  message: any;
  constructor(@Inject(NX_MODAL_DATA) data: any,
    private readonly dialogService: NxDialogService,
    private openPreviousPolicyError: NxModalRef<any>) {
    console.log("data", data.popup_type);
    this.popup_type = data.popup_type;
    this.popup_error = data.popup_error;
    this.activeQuotationDetails = data.activeQuotationDetails;
  }

  @ViewChild('deleteDoc') deleteDocRef!: TemplateRef<any>;
  @ViewChild('remarksAdd') remarksAddRef!: TemplateRef<any>;


  openFromTemplate4(): void {
    this.dialogService.open(this.deleteDocRef, {
      ariaLabel: 'A simple modal',
      disableClose: true,
      width: '500px',
      maxWidth: '500px'
    });
  }

  openFromTemplate1(): void {
    this.dialogService.open(this.remarksAddRef, {
      ariaLabel: 'A simple modal',
      disableClose: true,
      width: '550px',
      maxWidth: '550px'
    });
  }

  onClickYes() {
    this.openPreviousPolicyError.close('Y');
  }

  onClickRequestCancellation(action: any) {
    this.openPreviousPolicyError.close(action);
  }

}
