import { Component, Inject } from "@angular/core";
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
import { SharedModule } from "../../shared/shared.module";
import { NxGridModule } from '@aposin/ng-aquila/grid';


@Component({
  selector: "app-pdpa-popup",
  standalone: true,
  imports: [
    SharedModule,
    NxGridModule
  ],
  templateUrl: "./endorsement-popup.component.html",
  styleUrl: "./endorsement-popup.component.scss",
})
export class EndorsementPopupComponent {
  templateDialogRef?: NxModalRef<any>;
  popup_type: any;
  constructor(@Inject(NX_MODAL_DATA) data: any) {
    // console.log("data", data.popup_type);
    this.popup_type = data.popup_type;
  }

}
