import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../../../popup/endorsement-popup/endorsement-popup.component";
import { SharedModule } from '@shared/shared.module';
import { CoverageComponent } from '../../../issuance/coverage/coverage.component';
import { STORE_ENDORSEMENT } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { CLIENTTYPE, CONSTANTS, IDTYPE } from '@shared/enums/index.enum';
import { Subject, takeUntil } from 'rxjs';
import { STEP2 } from '@interfaces/motor';
// import { CpSameAgentInfoComponent } from '@shared/components/modal/cp-same-agent-info/cp-same-agent-info.component';
import { BrnoService } from '@shared/services/brno/brno.service';
import { NricService } from '@shared/services/nric/nric.service';
// import { SmeCertFormComponent } from '../sme-cert-form/sme-cert-form.component';
// import { SstFormDetailsComponent } from '../sst-form-details/sst-form-details.component';
import { GET_POSTCODE_INFO, UPDATE_GEO_INDICATOR } from '@store/sat-motor/sat-motor.action';
import { GET_PRODUCT_CONFIG_LOV } from '@store/sat-motor/sat-motor.action';
import { BranchesMapComponent } from '@shared/components/branches-map/branches-map.component';
import { CustomerPartnerBase } from '@shared/classes/customer-partner.base';
import { CpSameAgentInfoComponent } from '@shared/components/modal/cp-same-agent-info/cp-same-agent-info.component';

@Component({
  selector: 'app-endorsement-customer-partner',
  standalone: true,
  imports: [
    SharedModule,
    CpSameAgentInfoComponent,
    BranchesMapComponent,
  ],
  templateUrl: './endorsement-customer-partner.component.html',
  styleUrl: './endorsement-customer-partner.component.scss'
})
export class EndorsementCustomerPartnerComponent {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;

  isEndorsement: boolean = false;

  @Input() endorsementType: any;

  eInvoiceCustomerPartner: any;
  eInvoiceCustomerPartnerForm: FormGroup;

  isIndividual: boolean = false;
  isCompany: boolean = false;

  isNewIC: boolean = false; //NRIC
  isOldIC: boolean = false;
  isPoliceArmyID: boolean = false;
  isPassport: boolean = false;
  isOldBR: boolean = false;
  isNewBR: boolean = false;

  isMotor: boolean = false;

  lov: any;
  isShow: boolean;
  motorPC: any;
  lovproductConfig: any;
  productAccessListData: any;
  lovPC: any;
  lovGeo: any;

  customerType: any;
  idType2List: any;
  idType1: any;
  idValue1: any;
  clientDateofBirth: any;
  clientAge: any;
  clientNationality: any;

  unsubscribe$ = new Subject<void>();

  getCountry = CONSTANTS.getCountry();

  ///////////////////////////////////////////////////
  // cpSameAgentInfoModal?: NxModalRef<CpSameAgentInfoComponent>;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CoverageComponent>;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;
  @ViewChild("autosize") autosize!: CdkTextareaAutosize;

  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  endorsement_details = true;

  individual = true;
  company = true;
  sst = true;
  sme = true;

  // openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
  //   this.templateDialogRef = this.dialogService.open(template, {
  //     height: config.height || '500px',
  //     width: config.width || '780px',
  //   });
  // }

  // closeDialog(): void {
  //   this.templateDialogRef?.close();
  // }

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
    private nricService: NricService,
    private brnoService: BrnoService,
  ) { }

  ngOnInit() {

    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);

    this.loadEInvoiceCustomerPartnerForm();
    // this.loadEndorsementDetailsData();

    /////////////////////////////////////////////////////////////////
    this.eInvoiceCustomerPartner = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails?.eInvoiceCustomerPartner);
    console.log("eInvoiceCustomerPartner:", this.eInvoiceCustomerPartner);

    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    console.log("endtVehiclePolicyData:", endtVehiclePolicyData);

    if (this.eInvoiceCustomerPartner) {

      this.eInvoiceCustomerPartnerForm.patchValue(this.eInvoiceCustomerPartner);

    } else if (endtVehiclePolicyData) {

      let customerData = endtVehiclePolicyData?.clientPartner;
      // let agentData = endtVehiclePolicyData?.mkagAgent;
      // let coverageData = endtVehiclePolicyData?.riskList;

      console.log("customerData:", customerData);

      this.customerType = endtVehiclePolicyData?.clientPartner?.clientType;
      console.log("customerType:", this.customerType);

      if (this.customerType === "P") { //Personal
        this.isIndividual = true;
        this.isCompany = false;
      } else if (this.customerType === "I") { //Incorporated
        this.isIndividual = false;
        this.isCompany = true;
      }

      this.idType1 = endtVehiclePolicyData?.clientPartner?.idType1;
      console.log("idType1:", this.idType1);

      this.idValue1 = endtVehiclePolicyData?.clientPartner?.idValue1;
      console.log("idValue1:", this.idValue1);

      this.clientNationality = endtVehiclePolicyData?.clientPartner?.clientNationality;
      console.log("clientNationality:", this.clientNationality);

      if (this.idType1 === "NRIC") {
        this.clientDateofBirth = endtVehiclePolicyData?.clientPartner?.clientDateofBirth;
        console.log("clientDateofBirth:", this.clientDateofBirth);

        this.clientAge = endtVehiclePolicyData?.clientPartner?.clientAge;
        console.log("clientAge:", this.clientAge);
      }

      this.setIDType1();

      if (customerData) {
        let data = customerData;

        this.eInvoiceCustomerPartnerForm.patchValue({
          // customerName: data.clientName,

          nationality: data.clientNationality,
          maritalStatus: data.clientMaritalStatus,
          dob: data.clientDateofBirth,
          idValue2: data.idValue2,
          gender: data.clientGender,
          age: data.clientAge,

          // showMapSwitcher:[true],
          //     clientAddress1:'',
          // clientAddress1Tmp: '',
          // clientAddress2:'',
          // clientAddress3:'', 
          // clientCity:'',
          // clientCountry:'',
          // clientGeoLatitude:'',
          // clientGeoLongitude:'',
          // clientGeoPlaceId:'',
          // clientGeoPlusCode:'',
          // clientPostcode:'',
          // clientState:'',
          // clientUnitNo:'',

          unitNo: data.clientUnitNo,
          customerName: data.clientName,
          tinNo: data.clientTinNo,

          idType2: data.idType2,
          // idNo2: data.idNo2,
          taxNo: data.clientTinNo,
          sstRegisteredInd: data.clientSstRegInd,
          // sstNo: data.sstNo,
          // sstEffectiveDate: data.sstEffectiveDate,
          // sstDeregistrationDate: data.sstDeregistrationDate,
          sstResidentStatus: data.clientSstResidentStatus, //['Resident'],
          sstCountry: data.clientSstCountry, //['Malaysia'],
          mobileCode: data.clientMobileCode1, //['6010'],
          mobileNo: data.clientMobileNo1,
          fixedLineCode: data.clientMobileCode2, //['601'],
          fixedLineNo: data.clientMobileNo2,
          // faxCode: data.faxCode, //['601'],
          // faxNo: data.faxNo,
          email: data.clientEmail,
          address1: data.clientPartnerAddress.clientAddress1,
          address2: data.clientPartnerAddress.clientAddress2,
          address3: data.clientPartnerAddress.clientAddress3,
          postcode: data.clientPartnerAddress.clientPostcode,
          city: data.clientPartnerAddress.clientCity,
          state: data.clientPartnerAddress.clientState,
          country: data.clientPartnerAddress.clientCountry,
          addressType: data.clientPartnerAddress.clientAddressType, //['Residential'],
        });
        console.log("eInvoiceCustomerPartnerForm:", this.eInvoiceCustomerPartnerForm);

      } else {
        console.error("customerData cannot populate eInvoiceCustomerPartnerForm");
      }
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }
    ////////////////////////////////////////////////////////
    this.initIdType2List();
    this.onChangeIdType2();

  }

  getNationalityDescription(nationalityCode: string): string {
    // Search in the lov.clientNationalityList for the country with the given code
    const country = this.lov.clientNationalityList.find(
      country => country.code === nationalityCode
    );
  
    // Return the description (descp) if found, otherwise fallback to the code itself or a default value
    return country ? country.descp : nationalityCode;
  }

  setIDType1() {
    switch (this.idType1) {
      case 'NRIC': //"NRIC"
        this.allotherIDTypesFalse();
        this.isNewIC = true;
        break
      case 'OLD_IC': //"OLD IC NO."
        this.allotherIDTypesFalse();
        this.isOldIC = true;
        break
      case 'POL': // "POLICE ID / ARMY ID"
        this.allotherIDTypesFalse();
        this.isPoliceArmyID = true;
        break
      case 'PASS': // "PASSPORT NO."
        this.allotherIDTypesFalse();
        this.isPassport = true;
        break
      case 'BR_NO': // "BUSINESS REGISTRATION NO."
        this.allotherIDTypesFalse();
        this.isOldBR = true;
        break
      case 'NEW_BR_NO': // "NEW BUSINESS REGISTRATION NO."
        this.allotherIDTypesFalse();
        this.isNewBR = true;
        break
    }
  }

  allotherIDTypesFalse() {
    this.isNewIC = false; //NRIC
    this.isOldIC = false;
    this.isPoliceArmyID = false;
    this.isPassport = false;
    this.isOldBR = false;
    this.isNewBR = false;
  }

  onBlurIdValue2(idValue2) {
    console.log("idValue2: ", idValue2)
    let idValue2Control = this.eInvoiceCustomerPartnerForm.controls['idValue2'];
    let idType2Control = this.eInvoiceCustomerPartnerForm.controls['idType2'];
    let idValue2Val = idValue2Control.value;
    let idType2Val = idType2Control.value;

    if (idValue2Val) {
      idValue2Control.clearValidators();
      switch (idType2Val) {
        case IDTYPE.NRIC:
          if (idValue2Val.length === 12) {
            const isValidNric = this.nricService.isNRICValid(idValue2Val);
            if (!isValidNric) {
              idValue2Control.setErrors({ 'wrongNricFormat': true });
            }
          } else {
            idValue2Control.setErrors({ 'wrongNricFormat': true });
          }
          break;

        case IDTYPE.NEW_BR_NO:
          if (idValue2Val.length < 12) {
            idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
          } else {
            try {
              const isValidNewBrNo = this.brnoService.isBRNOValid(idValue2Val);
              if (!isValidNewBrNo) {
                idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
              }
            } catch (err) {
              idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
            }

          }
          break;
      }
      idType2Control.setValidators([Validators.required]);
    } else {
      idType2Control.removeValidators([Validators.required]);
    }
    idType2Control.updateValueAndValidity();
  }

  initIdType2List() {
    if (this.isIndividual) {
      if (this.idType1 === IDTYPE.OLD_IC) {
        this.idType2List = [
          { code: IDTYPE.NRIC, descp: IDTYPE.NRIC_DESCP },
          { code: IDTYPE.POL, descp: IDTYPE.POL_DESCP }
        ]
      }
    } else {
      if (this.idType1 === IDTYPE.BR_NO) {
        this.idType2List = [
          { code: IDTYPE.NEW_BR_NO, descp: IDTYPE.NEW_BR_NO_DESCP }
        ]
      } else {
        this.idType2List = [
          { code: IDTYPE.BR_NO, descp: IDTYPE.BR_NO_DESCP }
        ]
      }
    }
  }

  onChangeIdType2() {
    this.eInvoiceCustomerPartnerForm.controls['idType2'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        let idValue2Control = this.eInvoiceCustomerPartnerForm.controls['idValue2'];
        let idValue2Val = idValue2Control.value;
        if (value && !idValue2Val) {
          idValue2Control.setErrors({ 'required': true });
        }
      });
  }

  onChangeSmeStartDate() {
    this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        let smeRegisteredInd = this.eInvoiceCustomerPartnerForm.controls['smeRegisteredInd'].value;
        if (smeRegisteredInd === 'Y') {
          let startDate = value ? new Date(value) : null;
          let startDateAfter2Year = startDate ? new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate() - 1) : null;
          const currentDate = new Date();

          currentDate.setHours(0, 0, 0, 0);

          if (startDate > currentDate) {
            this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].setErrors({ 'invalidSMEStartDate': true });
          } else {
            this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setValue(startDateAfter2Year);
          }
        }
      });
  }

  onChangeSmeEndDate() {
    this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        let smeRegisteredInd = this.eInvoiceCustomerPartnerForm.controls['smeRegisteredInd'].value;
        if (smeRegisteredInd === 'Y') {
          let startDate = this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].value ? new Date(this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].value) : null;
          let startDateAfter2Year = startDate ? new Date(startDate.getFullYear() + 2, startDate.getMonth() - 1, startDate.getDate() - 1) : null;
          let endDate = new Date(value);

          if (startDate && endDate) {
            if (endDate < startDate) {
              this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setErrors({ 'smeSDLaterThanSmeED': true });
            }

            if (endDate > startDateAfter2Year) {
              this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setErrors({ 'smeEDMoreThanTwoYear': true });
            }
          } else {
            if (!startDate && endDate) {
              this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setErrors({ 'smeStartDateEmpty': true });
            }
          }
        }

      });
  }

  onChangeAge() {
    console.log("age: ", this.eInvoiceCustomerPartnerForm.controls['age'].errors);
  }

  onChangeMobileNo() {
    let mobileNo = this.eInvoiceCustomerPartnerForm.controls['mobileNo'].value;
    if (mobileNo) {
      if (this.isCompany) {
        this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].removeValidators([Validators.required]);
      }
      this.eInvoiceCustomerPartnerForm.controls['mobileCode'].setValidators([Validators.required]);
      // if(mobileNo === this.mkagAgent.mobileNo) {
      //   this.openCPSameAgentInfoModal("MOBILE");
      // }
    } else {
      if (this.isCompany) {
        this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].addValidators([Validators.required]);
      }
    }
    this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].updateValueAndValidity();
    this.eInvoiceCustomerPartnerForm.controls['mobileCode'].updateValueAndValidity();
  }

  onChangefixedLineNo() {
    let fixedLineNo = this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].value;
    this.eInvoiceCustomerPartnerForm.controls['fixedLineNoCode'].clearValidators();
    if (fixedLineNo) {
      if (this.isCompany) {
        this.eInvoiceCustomerPartnerForm.controls['mobileNo'].removeValidators([Validators.required]);
      }
      this.eInvoiceCustomerPartnerForm.controls['fixedLineNoCode'].setValidators([Validators.required]);
    } else {
      this.eInvoiceCustomerPartnerForm.controls['mobileNo'].addValidators([Validators.required]);
    }
    this.eInvoiceCustomerPartnerForm.controls['mobileNo'].updateValueAndValidity();
    this.eInvoiceCustomerPartnerForm.controls['fixedLineNoCode'].updateValueAndValidity();
  }

  onChangeFaxNo() {
    let faxNo = this.eInvoiceCustomerPartnerForm.controls['faxNo'].value;
    this.eInvoiceCustomerPartnerForm.controls['faxNoCode'].clearValidators();
    if(faxNo) {
      this.eInvoiceCustomerPartnerForm.controls['faxNoCode'].setValidators([Validators.required]);
    }
    this.eInvoiceCustomerPartnerForm.controls['faxNoCode'].updateValueAndValidity();  
  }

  onChangePostcode() {
    let postcode = this.eInvoiceCustomerPartnerForm.controls['postCode'].value;

    this.store.dispatch(new GET_POSTCODE_INFO(postcode)).subscribe(state => {
      let postcodeInfo = state.SatMotorState.data.postCodeInfo;
      this.eInvoiceCustomerPartnerForm.patchValue({
        state: postcodeInfo.state,
        city: postcodeInfo.city,
        country: "MAL"
      });
    });
  }

  populateMapForm(data: any) {
    
    //reset first before populate
    this.eInvoiceCustomerPartnerForm.patchValue({
      unitNo: '',
      address1: '',
      address2: '',
      address3: '',
      postCode: '',
      city: '',
      state: '',
      country: '',
      clientGeoLatitude : '',
      clientGeoLongitude : '',
      clientGeoPlaceId : '',
      clientGeoPlusCode : '',
      clientGeoFormattedAddress : ''
    });

    this.eInvoiceCustomerPartnerForm.patchValue({
      unitNo: data.clientUnitNo,
      address1: data.clientAddress1,
      address2: data.clientAddress2,
      address3: data.clientAddress3,
      postCode: data.clientPostCode,
      clientGeoLatitude : data.clientGeoLatitude,
      clientGeoLongitude : data.clientGeoLongitude,
      clientGeoPlaceId : data.clientGeoPlaceId,
      clientGeoPlusCode : data.clientGeoPlusCode,
      clientGeoFormattedAddress : data.clientGeoFormattedAddress
    });

    if (data.clientPostCode) {
      this.onChangePostcode();
    } else {
      this.eInvoiceCustomerPartnerForm.patchValue({
        city: data.clientCityDesc,
        state: data.clientStateDesc,
        country: data.clientCountryDesc,
      });
    }
  }

  /////////////////////////////////////////////////////////////

  loadEInvoiceCustomerPartnerForm() {
    this.eInvoiceCustomerPartnerForm = this.fb.group({
      customerName: [''],
      nationality: [''],
      maritalStatus: [''],
      dob: [''],
      idValue2: [''],
      gender: [''],
      age: [''],
      idType2: ['NRIC', Validators.required],
      idNo2: ['', Validators.required],
      taxNo: ['OG12345678101', Validators.required],
      sstRegisteredInd: [false],
      sstNo: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{17}$')]],
      sstEffectiveDate: ['', [Validators.required]],
      sstDeregistrationDate: [''],
      sstResidentStatus: ['Resident'],
      sstCountry: ['Malaysia'],
      smeRegisteredInd: [false],
      smeRegNo: ['', [Validators.required, Validators.pattern('^SEM\\w+$')]],
      smeCategory: ['', [Validators.required]],
      smeStartDate: ['', [Validators.required]],
      smeEndDate: ['', [Validators.required]],
      mobileCode: ['6010'],
      mobileNo: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(8)]],
      fixedLineCode: ['601'],
      fixedLineNo: ['', Validators.minLength(7)],
      faxCode: ['601'],
      faxNo: [''],
      email: ['', [Validators.required, Validators.email]],
      address1: [''],
      address2: [''],
      address3: [''],
      postcode: ['', Validators.required],
      city: [''],
      state: [''],
      country: [''],
      addressType: ['Residential'],
      ///////////////////////////////////////////
      showMapSwitcher:[true],
    });
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state) {
        if (state.eInvoiceCustomerPartner) {
          this.eInvoiceCustomerPartnerForm.patchValue(state.eInvoiceCustomerPartner);
        }
      }
    });
  }

  search_mode_address = true;
  changeSearchMode(event: any) {
    // debugger;
    if (event.value == "address") {
      this.search_mode_address = true;
    } else {
      this.search_mode_address = false;
    }
  }

  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;

  // openPopup(popup_type: any) {
  //   this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
  //     ariaLabel: "A simple dialog",
  //     showCloseIcon: false,
  //     width: '400px',
  //     data: { popup_type: popup_type },
  //   });
  // }


  // openPopup3(popup_type: any) {
  //   this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
  //     ariaLabel: "A simple dialog",
  //     showCloseIcon: false,
  //     data: { popup_type: popup_type },
  //   });
  // }

  getErrorType(errors) {
    if (errors.wrongNricFormat) {
      return 'wrongNricFormat';
    }

    if (errors.wrongNewBrnoFormat) {
      return 'wrongNewBrnoFormat';
    }

    if (errors.invalidDOB) {
      return 'invalidDOB';
    }

    if (errors.required) {
      return 'required';
    }

    return false;
  }

  //////////////////////////////////////////////////////
  // eInvoiceCustomerPartnerForm controls

  get idValue2ErrorType() {
    let errors = this.eInvoiceCustomerPartnerForm.controls['idValue2'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  get dobErrorType() {
    let errors = this.eInvoiceCustomerPartnerForm.controls['dob'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  get nationality() {
    return this.eInvoiceCustomerPartnerForm.controls["nationality"];
  }

  get idType2() {
    return this.eInvoiceCustomerPartnerForm.controls["idType2"];
  }

  get idNo2() {
    return this.eInvoiceCustomerPartnerForm.controls["idNo2"];
  }

  get taxNo() {
    return this.eInvoiceCustomerPartnerForm.controls["taxNo"];
  }

  get sstRegisteredInd() {
    return this.eInvoiceCustomerPartnerForm.controls["sstRegisteredInd"];
  }

  get sstNo() {
    return this.eInvoiceCustomerPartnerForm.controls["sstNo"];
  }

  get sstEffectiveDate() {
    return this.eInvoiceCustomerPartnerForm.controls["sstEffectiveDate"];
  }

  get sstDeregistrationDate() {
    return this.eInvoiceCustomerPartnerForm.controls["sstDeregistrationDate"];
  }

  get sstResidentStatus() {
    return this.eInvoiceCustomerPartnerForm.controls["sstResidentStatus"];
  }

  get sstCountry() {
    return this.eInvoiceCustomerPartnerForm.controls["sstCountry"];
  }

  get mobileCode() {
    return this.eInvoiceCustomerPartnerForm.controls["mobileCode"];
  }

  get mobileNo() {
    return this.eInvoiceCustomerPartnerForm.controls["mobileNo"];
  }

  get fixedLineCode() {
    return this.eInvoiceCustomerPartnerForm.controls["fixedLineCode"];
  }

  get fixedLineNo() {
    return this.eInvoiceCustomerPartnerForm.controls["fixedLineNo"];
  }

  get faxCode() {
    return this.eInvoiceCustomerPartnerForm.controls["faxCode"];
  }

  get faxNo() {
    return this.eInvoiceCustomerPartnerForm.controls["faxNo"];
  }

  get email() {
    return this.eInvoiceCustomerPartnerForm.controls["email"];
  }

  get address1() {
    return this.eInvoiceCustomerPartnerForm.controls["address1"];
  }

  get address2() {
    return this.eInvoiceCustomerPartnerForm.controls["address2"];
  }

  get address3() {
    return this.eInvoiceCustomerPartnerForm.controls["address3"];
  }

  get postcode() {
    return this.eInvoiceCustomerPartnerForm.controls["postcode"];
  }

  get city() {
    return this.eInvoiceCustomerPartnerForm.controls["city"];
  }

  get state() {
    return this.eInvoiceCustomerPartnerForm.controls["state"];
  }

  get country() {
    return this.eInvoiceCustomerPartnerForm.controls["country"];
  }

  get addressType() {
    return this.eInvoiceCustomerPartnerForm.controls["addressType"];
  }

  ///////////////////////////////////////////////////////////////////////
  storeEndorsementDetailsData() {
    if (this.eInvoiceCustomerPartnerForm.valid) {
      let eInvoiceCustomerPartner = this.eInvoiceCustomerPartnerForm.value;
      console.log('Saving eInvoice Customer Partner Data:', eInvoiceCustomerPartner);

      let payload = {
        eInvoiceCustomerPartner: eInvoiceCustomerPartner,
        isEInvoiceCustomerPartner: true
      }

      this.store.dispatch(new STORE_ENDORSEMENT(payload));
    } else {
      console.error('eInvoice Customer Partner form is invalid!');
      // this.logFormErrors(this.eInvoiceCustomerPartnerForm);
      this.eInvoiceCustomerPartnerForm.markAllAsTouched();
    }
  }

  ////////////////////////////////////////////

}
