import { Component } from '@angular/core';
import { NxModalRef } from '@aposin/ng-aquila/modal';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { GET_REDIRECT_PAGE, GET_SWITCH_SITE } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-remarks-addedd',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './remarks-addedd.component.html',
  styleUrl: './remarks-addedd.component.scss'
})
export class RemarksAddeddComponent {

  constructor(
    private store: Store,
    private dialogRef: NxModalRef<any>,
  ) {}

  backToSummary() {
    this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe((state) => {
      let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;
      console.log("SwitchSiteUrl Url : " + switchSiteUrl);
      window.location.href = switchSiteUrl;
    });
    this.dialogRef.close();
  }
}
