import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, Input, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@aposin/ng-aquila/copytext';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
const _c0 = ["element"];
const _c1 = ["scrollable"];
const _c2 = ["indicator"];
const _c3 = ["*"];
function NxSwipebarComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
class NxSwipebarComponent {
  constructor(zone) {
    this.zone = zone;
    this._barLength = 0;
    this._position = 0;
    this._startX = 0;
    this._mousedown = false;
    this._showBar = false;
    /**
     * The label for this swipebar.
     *
     * Default: `''`.
     */
    this.label = '';
    this._onMousedown = this._onMousedown.bind(this);
    this._onMousemove = this._onMousemove.bind(this);
    this._onMouseup = this._onMouseup.bind(this);
    this._onScroll = this._onScroll.bind(this);
  }
  ngAfterViewInit() {
    this._onResize();
    // Run outside zone to not trigger change detection when scrolling.
    this.zone.runOutsideAngular(() => {
      this._scrollable.nativeElement.addEventListener('scroll', this._onScroll);
      this._indicator.nativeElement.addEventListener('mousedown', this._onMousedown);
    });
  }
  _onResize() {
    if (!this._scrollable) {
      return;
    }
    const {
      scrollWidth,
      offsetWidth
    } = this._scrollable.nativeElement;
    this._showBar = scrollWidth > offsetWidth;
    this._barLength = Math.max(80, offsetWidth / scrollWidth * offsetWidth);
    this._updateIndicator();
  }
  _onScroll($event) {
    if (this._mousedown) {
      return;
    }
    const target = $event.target;
    const {
      scrollWidth,
      clientWidth
    } = this._scrollable.nativeElement;
    this._position = Math.floor(target.scrollLeft / (scrollWidth - clientWidth) * (target.offsetWidth - this._barLength));
    this._updateIndicator();
  }
  _onMousedown($event) {
    this._startX = $event.clientX;
    this._mousedown = true;
    document.addEventListener('mousemove', this._onMousemove);
    document.addEventListener('mouseup', this._onMouseup);
  }
  _onMousemove($event) {
    const {
      offsetWidth
    } = this._scrollable.nativeElement;
    this._position = Math.max(0, Math.min(offsetWidth - this._barLength, this._position + $event.clientX - this._startX));
    this._startX = $event.clientX;
    this._updateScrollablePosition();
    this._updateIndicator();
  }
  _onMouseup() {
    document.removeEventListener('mousemove', this._onMousemove);
    document.removeEventListener('mouseup', this._onMouseup);
    this._mousedown = false;
  }
  _updateScrollablePosition() {
    requestAnimationFrame(() => {
      const {
        scrollWidth,
        clientWidth,
        offsetWidth
      } = this._scrollable.nativeElement;
      this._scrollable.nativeElement.scrollLeft = this._position / (offsetWidth - this._barLength) * (scrollWidth - clientWidth);
    });
  }
  _updateIndicator() {
    requestAnimationFrame(() => {
      this._element.nativeElement.style.display = this._showBar ? 'block' : 'none';
      this._indicator.nativeElement.style.transform = `translateX(${this._position}px)`;
      this._indicator.nativeElement.style.width = `${this._barLength}px`;
    });
  }
  static {
    this.ɵfac = function NxSwipebarComponent_Factory(t) {
      return new (t || NxSwipebarComponent)(i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxSwipebarComponent,
      selectors: [["nx-swipebar"]],
      viewQuery: function NxSwipebarComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
          i0.ɵɵviewQuery(_c2, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._element = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._scrollable = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._indicator = _t.first);
        }
      },
      hostBindings: function NxSwipebarComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("resize", function NxSwipebarComponent_resize_HostBindingHandler() {
            return ctx._onResize();
          }, false, i0.ɵɵresolveWindow);
        }
      },
      inputs: {
        label: "label"
      },
      ngContentSelectors: _c3,
      decls: 9,
      vars: 1,
      consts: [["element", ""], ["indicator", ""], ["scrollable", ""], ["aria-hidden", "true"], ["class", "label", "nxCopytext", "", 4, "ngIf"], [1, "bar"], [1, "indicator"], [1, "scrollable"], ["nxCopytext", "", 1, "label"]],
      template: function NxSwipebarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 3, 0);
          i0.ɵɵtemplate(2, NxSwipebarComponent_div_2_Template, 2, 1, "div", 4);
          i0.ɵɵelementStart(3, "div", 5);
          i0.ɵɵelement(4, "div", 6, 1);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(6, "div", 7, 2);
          i0.ɵɵprojection(8);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.label);
        }
      },
      dependencies: [i1.NgIf, i2.NxCopytextComponent],
      styles: ["[_nghost-%COMP%]{display:block}.label[_ngcontent-%COMP%]{margin-bottom:12px}.scrollable[_ngcontent-%COMP%]{overflow:auto;padding:var(--swipebar-padding);scrollbar-width:none}.scrollable[_ngcontent-%COMP%]::-webkit-scrollbar{display:none}.bar[_ngcontent-%COMP%]{background:var(--swipebar-background-color)}.indicator[_ngcontent-%COMP%]{position:relative;height:8px;background:var(--swipebar-color);cursor:grab;transform:translate(0)}.indicator[_ngcontent-%COMP%]:active{cursor:grabbing}.indicator[_ngcontent-%COMP%]:before{content:\"\";position:absolute;display:block;inset:-8px 0}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxSwipebarComponent, [{
    type: Component,
    args: [{
      selector: 'nx-swipebar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div aria-hidden=\"true\" #element>\n    <div class=\"label\" nxCopytext *ngIf=\"label\">{{ label }}</div>\n    <div class=\"bar\">\n        <div class=\"indicator\" #indicator></div>\n    </div>\n</div>\n<div class=\"scrollable\" #scrollable>\n    <ng-content></ng-content>\n</div>\n",
      styles: [":host{display:block}.label{margin-bottom:12px}.scrollable{overflow:auto;padding:var(--swipebar-padding);scrollbar-width:none}.scrollable::-webkit-scrollbar{display:none}.bar{background:var(--swipebar-background-color)}.indicator{position:relative;height:8px;background:var(--swipebar-color);cursor:grab;transform:translate(0)}.indicator:active{cursor:grabbing}.indicator:before{content:\"\";position:absolute;display:block;inset:-8px 0}\n"]
    }]
  }], () => [{
    type: i0.NgZone
  }], {
    _element: [{
      type: ViewChild,
      args: ['element']
    }],
    _scrollable: [{
      type: ViewChild,
      args: ['scrollable']
    }],
    _indicator: [{
      type: ViewChild,
      args: ['indicator']
    }],
    label: [{
      type: Input
    }],
    _onResize: [{
      type: HostListener,
      args: ['window:resize']
    }]
  });
})();
class NxSwipebarModule {
  static {
    this.ɵfac = function NxSwipebarModule_Factory(t) {
      return new (t || NxSwipebarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxSwipebarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, NxCopytextModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxSwipebarModule, [{
    type: NgModule,
    args: [{
      declarations: [NxSwipebarComponent],
      exports: [NxSwipebarComponent],
      imports: [CommonModule, NxCopytextModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxSwipebarComponent, NxSwipebarModule };
