<!-- PopUp for Request Cancellation Validation (to be move to new component) -->
<div *ngIf="popup_type == 'popuprequestcancellationmessage'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            JPJ Status Sent, please try again.
        </p>
    </div>

    <div nxLayout="grid">
        <button nxModalClose nxButton="primary" type="button">
        OK
        </button>
    </div>
</div>


<!-- PopUp for Request Cancellation (to be move to new component) -->
<div *ngIf="popup_type == 'popupreasoncancellation'">
    <div nxModalContent>
        <!-- <h3 size="s" class="nx-margin-bottom-2m nx-margin-top-0">
            Reason for cancellation of e-Cover Note
        </h3> -->

        <h3 size="s" class="nx-margin-bottom-2m nx-margin-top-0">
            Reason for cancellation of e-Quotation
        </h3>

        <!-- <nx-formfield label="Select Reason">
            <nx-dropdown>
                <nx-dropdown-item value="CANCELLED BY OWNER"></nx-dropdown-item>
                <nx-dropdown-item value="CANCELLED DUE TO MISTAKE"></nx-dropdown-item>
            </nx-dropdown>
        </nx-formfield> -->

        <nx-formfield label="Enter Remarks">
            <textarea nxInput type="text" #inputToCount maxlength="200" cdkTextareaAutosize></textarea>
            <span nxFormfieldHint>Max. 200 Characters</span>
        </nx-formfield>
    
    <!-- 2 buttons -->
    <!-- <div>
        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            Cancel
        </button>
        <button nxModalClose nxButton="primary" type="button">
            Submit
        </button>
    </div> -->

    <!-- 3 buttons -->
    <div>
        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button" (click)="onClickRequestCancellation('close')">
            Close
        </button>
        <button nxModalClose class="button_popup_2btn" nxButton="primary" type="button" (click)="onClickRequestCancellation('cancel')">
            CANCEL
        </button>
        <button nxModalClose nxButton="primary" type="button" (click)="onClickRequestCancellation('cancelAndReplace')">
            cancel and replace
        </button>
    </div>
    </div>
</div>

<!-- PopUp for Request Cancellation Endorsement Generated (to be move to new component) -->
<div *ngIf="popup_type == 'popupendorsementgenerated'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Endorsement generated, cannot cancel.
        </p>
    </div>

    <div nxLayout="grid">
        <button nxModalClose nxButton="primary" type="button">
        OK
        </button>
    </div>
</div>

<!-- PopUp for Request Cancellation Endorsement Not Generated (to be move to new component) -->
<div *ngIf="popup_type == 'popupendorsementnotgenerated'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Endorsement not generated yet, please try again.
        </p>
    </div>

    <div nxLayout="grid">
        <button nxModalClose nxButton="primary" type="button">
        OK
        </button>
    </div>
</div>


<!-- PopUp for Referral Remarks (to be move to new component) -->
<div *ngIf="popup_type == 'popupreferralremarks'">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Please add remarks
        </h3>
        <nx-formfield label="Enter Remarks">
            <textarea nxInput type="text" #inputToCount maxlength="200" cdkTextareaAutosize></textarea>
            <span nxFormfieldHint>Max. 200 Characters</span>
        </nx-formfield>

        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            Cancel
        </button>
        <button nxModalClose nxButton="primary" type="button" (click)="openFromTemplate1()">
            Submit
        </button>
    </div>
</div>

<!-- PopUp for Remarks Add (to be move to new component) -->
<ng-template #remarksAdd>
    <div nxModalContent class="std-ta-center">
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Remark Added!
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Add Referral Remarks is successful. You may return to this e-Cover Note through the Summary screen.
        </p>
    </div>

    <div nxLayout="grid" class="std-ta-center">
        <button nxModalClose nxButton="primary" type="button">
        BACK TO SUMMARY LISTING
        </button>
    </div>

</ng-template>


<!-- PopUp for Upload Document (to be move to new component) -->
<div *ngIf="popup_type == 'uploaddocument'">
    <div nxModalContent>
        <h3 size="s" class="nx-margin-bottom-xs nx-margin-top-0">
            Upload Document
        </h3>

        <p nxCopytext="normal" class="nx-margin-bottom-m">You may upload multiple files by clicking on the "+ ADD MORE".</p>

        <nx-swipebar label="Swipe to see more">
            <table nxTable zebra cellpadding=0 class="upload_table">
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Document Needed</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Mandatory/<br>Optional</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Filename</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Action</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr nxTableRow>
                        <td nxTableCell>
                            <span nxCopytext="medium">
                                            <ol nxList="xsmall" class="upload_content">
                                                <li>New vehicle - supporting document</li>
                                            </ol>
                                        </span>
                        </td>
                        <td nxTableCell><span nxCopytext="medium">Optional</span></td>
                        <td nxTableCell><span nxCopytext="medium">motor.pdf</span></td>
                        <td nxTableCell>
                            <div class="horizontal-checkboxes">
                                <button class="nx-margin-right-xs upload_btn_width" nxButton="secondary small" type="button">
                                                CHOOSE FILE
                                            </button>
                                <button class="nx-margin-right-xs upload_btn_width" nxButton="secondary small" type="button">
                                                <nx-icon name="plus" nxIconPositionsStart aria-hidden="true"></nx-icon>
                                                    ADD MORE
                                            </button>
                                <button class="nx-margin-right-xs" nxPlainButton type="button" aria-label="add item" (click)="openFromTemplate4()">
                                                <nx-icon name="trash-o" aria-hidden="true"></nx-icon>
                                            </button>
                                <button nxPlainButton type="button">
                                                VIEW
                                            </button>
                            </div>
                        </td>
                    </tr>
                    <tr nxTableRow>
                        <td nxTableCell>
                            <nx-formfield label="Upload Optional Document" class="upload_dropdown">
                                <nx-dropdown formControlName="status" ariaLabelledBy="status" [showFilter]="true" [panelGrow]="true" [panelMaxWidth]="2000">
                                    <nx-dropdown-item value="SUPPORTING DOCUMENTS-FROM CLIENT"></nx-dropdown-item>
                                    <nx-dropdown-item value="VEHICLE REGISTRATION CARD (VOC)"></nx-dropdown-item>
                                    <nx-dropdown-item value="PURCHASE INVOICE"></nx-dropdown-item>
                                    <nx-dropdown-item value="FORM B5 (PUSPAKOM)"></nx-dropdown-item>
                                    <nx-dropdown-item value="SPAD PERMIT"></nx-dropdown-item>
                                    <nx-dropdown-item value="INSPECTION PHOTOGRAPHS"></nx-dropdown-item>
                                    <nx-dropdown-item value="SNK VALUATION"></nx-dropdown-item>
                                    <nx-dropdown-item value="INSURED OR AGENT AUTHORISED LETTER"></nx-dropdown-item>
                                    <nx-dropdown-item value="BANK/FINANCE LETTER"></nx-dropdown-item>
                                    <nx-dropdown-item value="OTHERS"></nx-dropdown-item>
                                </nx-dropdown>
                            </nx-formfield>
                        </td>
                        <td nxTableCell><span nxCopytext="medium">Optional</span></td>
                        <td nxTableCell></td>
                        <td nxTableCell></td>
                    </tr>
                </tbody>
            </table>
        </nx-swipebar>

        <nx-message context="info" class="green_color_notification nx-margin-top-xs"><span class="nx-font-weight-bold">1 file(s) have been successfully uploaded.</span></nx-message>
    </div>

    <div class="nx-margin-top-2m">
        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            Close
        </button>
        <button nxModalClose nxButton="primary" type="button">
            Upload
        </button>
    </div>

</div>

<!-- PopUp for Delete Document (to be move to new component) -->
<ng-template #deleteDoc>
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Deleted file cannot be restored. Are you sure want to proceed?
        </p>
    </div>

        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            NO
        </button>
        <button nxModalClose nxButton="primary" type="button">
            YES
        </button>

</ng-template>

<!-- PopUp for 'Motorcycle' Product Selected -->
<div *ngIf="popup_type == 'popupProductMotorcycle'" class="std-ta-center">
    <div nxModalContent>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Please select Motorcycle Plus product for added extra benefits (Premium waived).
        </p>
    </div>

    <div nxLayout="grid">
        <button nxModalClose nxButton="primary" type="button">
        OK
        </button>
    </div>
</div>

<!-- PopUp for Email Document (to be move to new component) -->
<div *ngIf="popup_type == 'emaildocument'">
    <div nxModalContent>
        <nx-message context="success" class="nx-margin-bottom-m">
            <span class="nx-font-weight-bold">Successful - Email Sent Completed. Please refer to email status history.</span>
        </nx-message>
        <h3 size="s" class="nx-margin-bottom-xs nx-margin-top-0">
            Email Document
        </h3>

        <div nxLayout="grid nopadding" class="grid_remove_padding">
            <div nxRow>
                <div nxCol="12,12,4">
                    <p nxCopytext="large" class="nx-font-weight-semibold nx-margin-bottom-xs">e-Document Consent</p>
                </div>
                <div nxCol="12,12,8">
                    <div nxLayout="grid nopadding" class="grid_remove_padding">
                        <div nxRow>
                            <div nxCol="12,6,4,3">
                                <nx-radio name="yesno" class="nx-margin-bottom-xs">
                                    Yes
                                </nx-radio>
                            </div>
                            <div nxCol="12,6,4,3">
                                <nx-radio name="yesno" class="nx-margin-bottom-xs">
                                    No
                                </nx-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="nx-margin-bottom-2m nx-margin-top-s">

        <p nxCopytext="large" class="nx-font-weight-bold nx-margin-bottom-2m">Customer email address will not be updated to the Policy and Customer Partner Information.</p>

        <nx-data-display size="small" class="nx-margin-bottom-2m">
            <nx-data-display-label class="font-grey">Policyholder Name</nx-data-display-label>
            <p nxCopytext="large">WONG KAM WAI</p>
        </nx-data-display>

        <div nxLayout="grid nopadding" class="grid_remove_padding">
            <div nxRow>
                <div nxCol="12,12,6">
                    <nx-formfield label="Customer’s Email">
                        <input nxInput value="kamwai@gmail.com" />
                    </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                    <nx-formfield label="Customer’s Additional Email">
                        <input nxInput value="kamwai@gmail.com" />
                    </nx-formfield>
                </div>
                <div nxCol="12,12,6">
                    <nx-formfield label="Agent’s Email">
                        <input nxInput value="agentkl@yahoo.com" />
                    </nx-formfield>
                </div>
            </div>
        </div>

        <hr class="nx-margin-bottom-2m">

        <p nxCopytext="large" class="nx-font-weight-bold nx-margin-bottom-2m">Email Listing History</p>

        <nx-swipebar label="Swipe to see more">
            <table nxTable zebra cellpadding=0>
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">No.</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Recipients</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Document</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Sent By</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Sent Date</span></th>
                        <th nxHeaderCell><span nxCopytext="medium" class="nx-font-weight-semibold">Email Status</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr nxTableRow>
                        <td nxTableCell><span nxCopytext="medium">1</span></td>
                        <td nxTableCell><span nxCopytext="medium">kamwaigmail.com;<br />agentklyahoo.com</span></td>
                        <td nxTableCell><span nxCopytext="medium">E-SCHEDULE [23PAS5000433-00]</span></td>
                        <td nxTableCell><span nxCopytext="medium">ALPHA.UAT.01</span></td>
                        <td nxTableCell><span nxCopytext="medium">02/02/2023 14:53:22</span></td>
                        <td nxTableCell><span nxCopytext="medium">SUCCESS</span></td>
                    </tr>
                    <tr nxTableRow>
                        <td nxTableCell><span nxCopytext="medium">2</span></td>
                        <td nxTableCell><span nxCopytext="medium">kamwaigmail.com;<br />agentklyahoo.com</span></td>
                        <td nxTableCell><span nxCopytext="medium">E-SCHEDULE [23PAS5000433-00]</span></td>
                        <td nxTableCell><span nxCopytext="medium">ALPHA.UAT.01</span></td>
                        <td nxTableCell><span nxCopytext="medium">02/02/2023 14:53:22</span></td>
                        <td nxTableCell><span nxCopytext="medium">SUCCESS</span></td>
                    </tr>
                </tbody>
            </table>
        </nx-swipebar>
    </div>


    <div class="nx-margin-top-2m">
        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            Close
        </button>
        <button nxModalClose nxButton="primary" type="button">
            SEND EMAIL
        </button>
    </div>

</div>


<!-- PopUp for e-Cover Note Clause (to be move to new component) -->
<div *ngIf="popup_type == 'popupecovernote'">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Do you want to print clause wording?
        </h3>
    </div>

        <button nxModalClose class="button_popup_2btn" nxButton="primary" type="button">
            YES
        </button>
        <button nxModalClose nxButton="primary" type="button">
            NO
        </button>
</div>

<div *ngIf="popup_type == 'populatePreviousPolicyDetails'">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Do you want to populate previous policy details?
        </h3>
    </div>

        <button nxModalClose class="button_popup_2btn" nxButton="primary" type="button" (click)="onClick()">
            YES
        </button>
        <button nxModalClose nxButton="primary" type="button">
            NO
        </button>
</div>

<div *ngIf="popup_type == 'populateActiveQuotationDetails'">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Do you want to populate active quotation details?
        </h3>
    </div>

        <button nxModalClose class="button_popup_2btn" nxButton="primary" type="button" (click)="onClick()">
            YES
        </button>
        <button nxModalClose nxButton="primary" type="button">
            NO
        </button>
</div>

<!-- PopUp for send to JPJ Geocode (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjgeocode'">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
           Geocoding not updated for Policyholder address.<br /> Please Update.
        </p>
    </div>

        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            CANCEL
        </button>
        <button nxModalClose nxButton="primary" type="button">
            PROCEED
        </button>
</div>

<!-- PopUp for send to JPJ loan (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjloan'">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
           Loan Company not updated.<br /> Please Update.
        </p>
    </div>

        <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
            CANCEL
        </button>
        <button nxModalClose nxButton="primary" type="button">
            PROCEED
        </button>
</div>

<!-- PopUp for send to JPJ OCC (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjocc'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Please at least add one payment.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<!-- PopUp for send to JPJ Upload (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjupload'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Please provide the mandatory supporting documents via Upload Document.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<!-- PopUp for send to JPJ Suspension (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjsuspension'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Not allowed to proceed since your User ID is currently suspended.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<!-- PopUp for send to JPJ Check CBC (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjcheckcbc'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Cover Note Stock is not available.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<!-- PopUp for send to JPJ Check with CBC (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjcheckwithcbc'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Cover Note Stock Limit is null.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<!-- PopUp for send to JPJ CBC Indicator 1 (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjcbcindicator1'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Your cover note stock limit is zero. Kindly contact your branch.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<!-- PopUp for send to JPJ CBC Indicator 0 (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjcbcindicator0'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
            Please settle outstanding payment to comply with CBC Regulations and to avoid suspension of account.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>


<!-- PopUp for send to JPJ Sanction Failed (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjsanctionfailed'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
           Unable to Process this Request Due to Sanction/Compliance Requirement.<br />
           Policy Holder Name: JOHN DOE<br />
           Insured Name: ALBERT LIM<br />
           Insured ID: ABC1234556
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>

<!-- PopUp for send to JPJ Check Motor Blacklist (to be move to new component) -->
<div *ngIf="popup_type == 'popupsendtojpjcheckmotorblacklist'" class="std-ta-center">
    <div nxModalContent>
        <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
            Warning
        </h3>
        <p nxCopytext="normal" class="nx-modal-margin-bottom nx-font-weight-light">
           Your application has been blocked, kindly contact your branch.
        </p>
    </div>

    <button nxModalClose nxButton="primary" type="button">
    OK
    </button>
</div>