<!-- WORKING:  Thailand Trip -->

<ng-container>
    <div nxLayout="grid" class="grid_remove_padding coverage">
        <div nxRow>
            <div nxCol="12">
                <hr class="nx-margin-y-m" />
            </div>
        </div>
        <div nxRow>
            <div nxCol="12">
                <h4 class="nx-margin-0">Thailand Trip</h4>
            </div>
        </div>
        <div nxRow class="nx-margin-top-m">
            <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Start Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker7"
                        [formControl]="thailandstartdateFormControl" />
                    <span nxFormfieldHint>MM/DD/YYYY</span>

                    <nx-datepicker-toggle [for]="myDatepicker7" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker7></nx-datepicker>

                    <nx-error nxFormfieldError><strong>Please enter Thailand Trip Start Date.</strong>
                    </nx-error>
                </nx-formfield>

                <button nxPlainButton (click)="openPopup('notallowed')" class="nx-margin-bottom-m">
                    Not allowed extra coverage popup
                </button>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="End Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker8"
                        [formControl]="thailandenddateFormControl" />
                    <span nxFormfieldHint>MM/DD/YYYY</span>

                    <nx-datepicker-toggle [for]="myDatepicker8" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker8></nx-datepicker>

                    <nx-error nxFormfieldError>
                        <strong>Please enter Thailand Trip End Date.</strong>
                    </nx-error>
                </nx-formfield>
            </div>
        </div>
    </div>
</ng-container>