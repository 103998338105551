import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, ContentChildren, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, filter, takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/cdk/a11y';
import * as i2 from '@aposin/ng-aquila/icon';
import { NxIconModule } from '@aposin/ng-aquila/icon';
const _c0 = ["nxBreadcrumbItem", ""];
const _c1 = ["*"];
const _c2 = ["nxBreadcrumb", ""];
class NxBreadcrumbItemComponent {
  constructor(_renderer, _elemRef, _focusMonitor) {
    this._renderer = _renderer;
    this._elemRef = _elemRef;
    this._focusMonitor = _focusMonitor;
    this._focusMonitor.monitor(this._elemRef);
  }
  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elemRef);
  }
  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elemRef);
  }
  /** @docs-private */
  setAsLast() {
    this._renderer.setAttribute(this._elemRef.nativeElement, 'aria-current', 'page');
  }
  /** @docs-private */
  resetAriaLabel() {
    this._renderer.removeAttribute(this._elemRef.nativeElement, 'aria-current');
  }
  // prevent location change when the user clicks on the chevron arrow
  _onIconClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  static {
    this.ɵfac = function NxBreadcrumbItemComponent_Factory(t) {
      return new (t || NxBreadcrumbItemComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.FocusMonitor));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxBreadcrumbItemComponent,
      selectors: [["a", "nxBreadcrumbItem", ""]],
      hostAttrs: [1, "nx-breadcrumb-item"],
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 3,
      vars: 0,
      consts: [[1, "nx-breadcrumb-item__text"], ["aria-hidden", "true", "name", "chevron-right", 1, "nx-breadcrumb-item__chevron", 3, "click"]],
      template: function NxBreadcrumbItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "span", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "nx-icon", 1);
          i0.ɵɵlistener("click", function NxBreadcrumbItemComponent_Template_nx_icon_click_2_listener($event) {
            return ctx._onIconClick($event);
          });
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [i2.NxIconComponent],
      styles: ["[_nghost-%COMP%]{text-decoration:none;display:flex;align-items:center;cursor:auto;color:var(--breadcrumb-color);font-size:var(--breadcrumb-font-size);line-height:var(--breadcrumb-line-height);font-weight:var(--breadcrumb-font-weight);letter-spacing:var(--breadcrumb-letter-spacing)}.nx-breadcrumb-item__chevron[_ngcontent-%COMP%]{margin-left:4px}[dir=rtl][_nghost-%COMP%]   .nx-breadcrumb-item__chevron[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .nx-breadcrumb-item__chevron[_ngcontent-%COMP%]{margin-left:initial;margin-right:4px;transform:rotate(180deg)}[_nghost-%COMP%]:not([href])   .nx-breadcrumb-item__text[_ngcontent-%COMP%]{cursor:auto}.nx-breadcrumb-item__text[_ngcontent-%COMP%]{cursor:pointer}.cdk-keyboard-focused[_nghost-%COMP%]   .nx-breadcrumb-item__text[_ngcontent-%COMP%]{box-shadow:var(--focus-box-shadow);border-radius:4px}@media screen and (forced-colors: active),(forced-colors: active){.cdk-keyboard-focused[_nghost-%COMP%]   .nx-breadcrumb-item__text[_ngcontent-%COMP%]{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxBreadcrumbItemComponent, [{
    type: Component,
    args: [{
      selector: 'a[nxBreadcrumbItem]',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'nx-breadcrumb-item'
      },
      template: "<span class=\"nx-breadcrumb-item__text\"><ng-content></ng-content></span>\n<nx-icon (click)=\"_onIconClick($event)\" class=\"nx-breadcrumb-item__chevron\" aria-hidden=\"true\" name=\"chevron-right\"> </nx-icon>\n",
      styles: [":host{text-decoration:none;display:flex;align-items:center;cursor:auto;color:var(--breadcrumb-color);font-size:var(--breadcrumb-font-size);line-height:var(--breadcrumb-line-height);font-weight:var(--breadcrumb-font-weight);letter-spacing:var(--breadcrumb-letter-spacing)}.nx-breadcrumb-item__chevron{margin-left:4px}:host-context([dir=rtl]) .nx-breadcrumb-item__chevron{margin-left:initial;margin-right:4px;transform:rotate(180deg)}:host:not([href]) .nx-breadcrumb-item__text{cursor:auto}.nx-breadcrumb-item__text{cursor:pointer}:host.cdk-keyboard-focused .nx-breadcrumb-item__text{box-shadow:var(--focus-box-shadow);border-radius:4px}@media screen and (forced-colors: active),(forced-colors: active){:host.cdk-keyboard-focused .nx-breadcrumb-item__text{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}\n"]
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i1.FocusMonitor
  }], null);
})();
class NxBreadcrumbComponent {
  /**
   * Sets the appearance of the breadcrumb.
   *
   * Default: `'default'`.
   */
  set appearance(value) {
    this._appeareance = value;
    this._cdr.markForCheck();
  }
  get appearance() {
    return this._appeareance;
  }
  /** Whether the component uses the negative styling. */
  set negative(value) {
    this._negative = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  get negative() {
    return this._negative;
  }
  constructor(_cdr) {
    this._cdr = _cdr;
    this._appeareance = 'default';
    this._negative = false;
    this._destroyed = new Subject();
  }
  ngAfterContentInit() {
    if (this.breadcrumbItems.length === 0) {
      console.warn('A breadcrumb needs NxBreadcrumbItemComponent children wrapped in <li>!');
    }
    this.breadcrumbItems.changes.pipe(startWith(this.breadcrumbItems), filter(items => items.length !== 0), takeUntil(this._destroyed)).subscribe(items => {
      this.breadcrumbItems.forEach(item => item.resetAriaLabel());
      this.breadcrumbItems.last.setAsLast();
    });
  }
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
  static {
    this.ɵfac = function NxBreadcrumbComponent_Factory(t) {
      return new (t || NxBreadcrumbComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxBreadcrumbComponent,
      selectors: [["ol", "nxBreadcrumb", ""]],
      contentQueries: function NxBreadcrumbComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NxBreadcrumbItemComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.breadcrumbItems = _t);
        }
      },
      hostVars: 4,
      hostBindings: function NxBreadcrumbComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("is-negative", ctx.negative)("is-link", ctx.appearance === "link");
        }
      },
      inputs: {
        appearance: "appearance",
        negative: "negative"
      },
      attrs: _c2,
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function NxBreadcrumbComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{display:flex;list-style:none;height:24px;padding:0}.is-link[_nghost-%COMP%]     .nx-breadcrumb-item{color:var(--breadcrumb-link-color)}.is-link[_nghost-%COMP%]     .nx-breadcrumb-item__text:active{color:var(--breadcrumb-link-active-color)}  li:not(:last-child) .nx-breadcrumb-item__text:hover{color:var(--breadcrumb-link-hover-color)}  li:last-child .nx-breadcrumb-item{font-weight:600;cursor:auto}  li:last-child .nx-breadcrumb-item__chevron{display:none}  li+li .nx-breadcrumb-item{margin-left:4px}[dir=rtl][_nghost-%COMP%]     li+li .nx-breadcrumb-item, [dir=rtl]   [_nghost-%COMP%]     li+li .nx-breadcrumb-item{margin-left:initial;margin-right:4px}.is-negative[_nghost-%COMP%]     .nx-breadcrumb-item{color:var(--negative)}@media screen and (forced-colors: active){  .nx-breadcrumb-item__chevron{color:CanvasText}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxBreadcrumbComponent, [{
    type: Component,
    args: [{
      selector: 'ol[nxBreadcrumb]',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.is-negative]': 'negative',
        '[class.is-link]': 'appearance === "link"'
      },
      template: "<ng-content></ng-content>\n",
      styles: [":host{display:flex;list-style:none;height:24px;padding:0}:host(.is-link) ::ng-deep .nx-breadcrumb-item{color:var(--breadcrumb-link-color)}:host(.is-link) ::ng-deep .nx-breadcrumb-item__text:active{color:var(--breadcrumb-link-active-color)}::ng-deep li:not(:last-child) .nx-breadcrumb-item__text:hover{color:var(--breadcrumb-link-hover-color)}::ng-deep li:last-child .nx-breadcrumb-item{font-weight:600;cursor:auto}::ng-deep li:last-child .nx-breadcrumb-item__chevron{display:none}::ng-deep li+li .nx-breadcrumb-item{margin-left:4px}:host-context([dir=rtl]) ::ng-deep li+li .nx-breadcrumb-item{margin-left:initial;margin-right:4px}:host(.is-negative) ::ng-deep .nx-breadcrumb-item{color:var(--negative)}@media screen and (forced-colors: active){::ng-deep .nx-breadcrumb-item__chevron{color:CanvasText}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    appearance: [{
      type: Input
    }],
    negative: [{
      type: Input
    }],
    breadcrumbItems: [{
      type: ContentChildren,
      args: [NxBreadcrumbItemComponent, {
        descendants: true
      }]
    }]
  });
})();
class NxBreadcrumbModule {
  static {
    this.ɵfac = function NxBreadcrumbModule_Factory(t) {
      return new (t || NxBreadcrumbModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxBreadcrumbModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [NxIconModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxBreadcrumbModule, [{
    type: NgModule,
    args: [{
      declarations: [NxBreadcrumbItemComponent, NxBreadcrumbComponent],
      exports: [NxBreadcrumbItemComponent, NxBreadcrumbComponent],
      imports: [NxIconModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxBreadcrumbComponent, NxBreadcrumbItemComponent, NxBreadcrumbModule };
