import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i1 from '@angular/cdk/platform';
import { getSupportedInputTypes } from '@angular/cdk/platform';
import * as i0 from '@angular/core';
import { InjectionToken, Directive, Optional, Self, Inject, Input, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { Validators } from '@angular/forms';
import { NxFormfieldControl, NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxAbstractControl } from '@aposin/ng-aquila/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i3 from '@aposin/ng-aquila/utils';
import * as i4 from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import * as i2$1 from '@aposin/ng-aquila/icon';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import * as i1$1 from '@angular/cdk/a11y';
const NX_INPUT_VALUE_ACCESSOR = new InjectionToken('NX_INPUT_VALUE_ACCESSOR');
const INVALID_TYPES = ['button', 'checkbox', 'file', 'hidden', 'image', 'radio', 'range', 'reset', 'submit'];
const NEVER_EMPTY = ['date', 'datetime', 'datetime-local', 'month', 'time', 'week'].filter(t => getSupportedInputTypes().has(t));
let nextUniqueId = 0;
class NxInputDirective {
  /** The id of the input. */
  set id(value) {
    this._id = value || this._uid;
  }
  get id() {
    return this._id;
  }
  /** The input element's value. */
  set value(value) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
    }
  }
  get value() {
    return this._inputValueAccessor.value;
  }
  /** Whether the element is readonly. */
  set readonly(value) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  get readonly() {
    return this._readonly;
  }
  /** set readonly state */
  setReadonly(value) {
    this.readonly = value;
  }
  /** Whether the input is disabled. */
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  get disabled() {
    if (this.ngControl?.disabled != null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  /** Whether the element is required. */
  set required(value) {
    this._required = coerceBooleanProperty(value);
  }
  get required() {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }
  /** Sets the type of the input element (e.g. password, text etc). */
  set type(value) {
    this._type = value || 'text';
    this._validateType();
    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this._isTextarea() && getSupportedInputTypes().has(this._type)) {
      this._elementRef.nativeElement.type = this._type;
    }
  }
  get type() {
    return this._type;
  }
  /**
   * Sets the text for the input placeholder.
   */
  set placeholder(value) {
    this._placeholder = value;
  }
  get placeholder() {
    return this.empty ? this._placeholder : '';
  }
  /**
   *
   * Sets the event that triggers change detection in the input.
   */
  set updateOn(value) {
    if (this._updateOn !== value) {
      this._updateOn = value;
    }
  }
  get updateOn() {
    return this._updateOn;
  }
  constructor(_elementRef, _platform, /** @docs-private */ngControl, _parentForm, _parentFormGroup, _errorStateMatcher, inputValueAccessor, _autofillMonitor) {
    this._elementRef = _elementRef;
    this._platform = _platform;
    this.ngControl = ngControl;
    this._parentForm = _parentForm;
    this._parentFormGroup = _parentFormGroup;
    this._errorStateMatcher = _errorStateMatcher;
    this._autofillMonitor = _autofillMonitor;
    this._uid = `nx-input-${nextUniqueId++}`;
    /** @docs-private */
    this.errorState = false;
    /**
     * Name of this control that is used inside the formfield component
     * @docs-private
     */
    this.controlType = 'nx-input';
    /** @docs-private */
    this.autofilled = false;
    /** @docs-private */
    this.stateChanges = new Subject();
    /** @docs-private */
    this.focused = false;
    this._readonly = false;
    this._disabled = false;
    this._type = 'text';
    this._updateOn = 'change';
    this._destroyed = new Subject();
    const id = this.id;
    this.id = id; // invoke setter
    // This will enable other directives to plugin itself as the value accessor
    // by using the NX_INPUT_VALUE_ACCESSOR Token. Default is the given input field.
    // TODO eliminate injected dateValueAccessor once we have intra-package support in ng-packagr
    // See the datefield for details.
    this._inputValueAccessor = inputValueAccessor || this._elementRef.nativeElement;
    this._previousNativeValue = this.value;
    if (this._elementRef.nativeElement.nodeName.toLowerCase() === 'textarea') {
      this.controlType = 'textarea';
    }
  }
  ngOnInit() {
    if (this._platform.isBrowser) {
      this._autofillMonitor.monitor(this._elementRef.nativeElement).pipe(takeUntil(this._destroyed)).subscribe(event => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    }
  }
  /** @docs-private */
  get elementRef() {
    return this._elementRef;
  }
  _focusChanged(isFocused) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
      if (this._updateOn === 'blur') {
        this._detectInputChanges();
      }
    }
  }
  _detectInputChanges() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
    // updating the value using `emitEvent: false`).
    this._dirtyCheckNativeValue();
  }
  ngOnChanges() {
    this.stateChanges.next();
  }
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    this.stateChanges.complete();
    if (this._platform.isBrowser) {
      this._autofillMonitor.stopMonitoring(this._elementRef.nativeElement);
    }
  }
  ngDoCheck() {
    if (this._updateOn === 'change') {
      this._detectInputChanges();
    }
  }
  /** @docs-private */
  updateErrorState() {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const control = this.ngControl ? this.ngControl.control : null;
    const newState = this._errorStateMatcher.isErrorState(control, parent);
    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }
  /**
   * Set a list of ids that is currently describing this input
   * (if you have hints and errors for example).
   */
  setDescribedByIds(ids) {
    this._ariaDescribedby = ids.join(' ');
  }
  // allow to set a arial label value in case there
  // is not possibility to display a proper label
  /**
   * Method to set the aria label.
   * This is required if you use the input outside of a formfield
   * where you don't have a label connected.
   */
  setAriaLabel(value) {
    this._ariaLabel = value;
  }
  _isBadInput() {
    // The `validity` property won't be present on platform-server.
    const validity = this._elementRef.nativeElement.validity;
    return validity?.badInput;
  }
  /** @docs-private */
  get empty() {
    return !this._isNeverEmpty() && !this._elementRef.nativeElement.value && !this._isBadInput() && !this.autofilled;
  }
  _isNeverEmpty() {
    return NEVER_EMPTY.includes(this._type);
  }
  _isTextarea() {
    const nativeElement = this._elementRef.nativeElement;
    return nativeElement.nodeName ? nativeElement.nodeName.toLowerCase() === 'textarea' : false;
  }
  _validateType() {
    if (INVALID_TYPES.includes(this._type)) {
      throw new Error(`Input of type '${this._type}' is not supported`);
    }
  }
  /** @docs-private */
  get shouldLabelFloat() {
    return !!(this.focused || !this.empty || this.placeholder && this.placeholder.length > 0);
  }
  _dirtyCheckNativeValue() {
    const newValue = this.value;
    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }
  static {
    this.ɵfac = function NxInputDirective_Factory(t) {
      return new (t || NxInputDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.Platform), i0.ɵɵdirectiveInject(i2.NgControl, 10), i0.ɵɵdirectiveInject(i2.NgForm, 8), i0.ɵɵdirectiveInject(i2.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i3.ErrorStateMatcher), i0.ɵɵdirectiveInject(NX_INPUT_VALUE_ACCESSOR, 10), i0.ɵɵdirectiveInject(i4.AutofillMonitor));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NxInputDirective,
      selectors: [["input", "nxInput", ""], ["textarea", "nxInput", ""], ["select", "nxInput", ""]],
      hostVars: 21,
      hostBindings: function NxInputDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focusout", function NxInputDirective_focusout_HostBindingHandler() {
            return ctx._focusChanged(false);
          })("focus", function NxInputDirective_focus_HostBindingHandler() {
            return ctx._focusChanged(true);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("id", ctx.id)("disabled", ctx.disabled || null)("readonly", ctx.readonly || null)("required", ctx.required || null)("aria-label", ctx._ariaLabel || null)("aria-describedby", ctx._ariaDescribedby || null)("aria-invalid", ctx.errorState)("aria-required", ctx.required.toString())("placeholder", ctx.placeholder || null);
          i0.ɵɵclassProp("c-input", true)("nx-input", true)("is-filled", ctx.empty === false)("is-disabled", ctx.disabled)("has-error", ctx.errorState)("is-focused", ctx.focused);
        }
      },
      inputs: {
        _ariaLabel: [i0.ɵɵInputFlags.None, "nxAriaLabel", "_ariaLabel"],
        id: "id",
        value: "value",
        readonly: "readonly",
        disabled: "disabled",
        required: "required",
        type: "type",
        placeholder: "placeholder",
        updateOn: "updateOn"
      },
      exportAs: ["nxInput"],
      features: [i0.ɵɵProvidersFeature([{
        provide: NxFormfieldControl,
        useExisting: NxInputDirective
      }, {
        provide: NxAbstractControl,
        useExisting: NxInputDirective
      }]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxInputDirective, [{
    type: Directive,
    args: [{
      selector: 'input[nxInput], textarea[nxInput], select[nxInput]',
      exportAs: 'nxInput',
      host: {
        '[class.c-input]': 'true',
        '[class.nx-input]': 'true',
        '[attr.id]': 'id',
        '[class.is-filled]': 'empty === false',
        '[class.is-disabled]': 'disabled',
        '[class.has-error]': 'errorState',
        '[class.is-focused]': 'focused',
        '[attr.disabled]': 'disabled || null',
        '[attr.readonly]': 'readonly || null',
        '[attr.required]': 'required || null',
        '[attr.aria-label]': '_ariaLabel || null',
        '[attr.aria-describedby]': '_ariaDescribedby || null',
        '[attr.aria-invalid]': 'errorState',
        '[attr.aria-required]': 'required.toString()',
        '[attr.placeholder]': 'placeholder || null',
        '(focusout)': '_focusChanged(false)',
        '(focus)': '_focusChanged(true)'
      },
      providers: [{
        provide: NxFormfieldControl,
        useExisting: NxInputDirective
      }, {
        provide: NxAbstractControl,
        useExisting: NxInputDirective
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1.Platform
  }, {
    type: i2.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }]
  }, {
    type: i2.NgForm,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i2.FormGroupDirective,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i3.ErrorStateMatcher
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }, {
      type: Inject,
      args: [NX_INPUT_VALUE_ACCESSOR]
    }]
  }, {
    type: i4.AutofillMonitor
  }], {
    _ariaLabel: [{
      type: Input,
      args: ['nxAriaLabel']
    }],
    id: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    updateOn: [{
      type: Input
    }]
  });
})();
const visibilityIcons = {
  show: 'password-show-o',
  hide: 'password-hide-o'
};
class NxPasswordToggleComponent {
  /**
   * Sets the aria-label needed for accessibility.
   * Notice that this `aria-label` should describe the initial action according to the status of the visibility.
   * E.g if you have an `input[type=password]` at the beginning then the
   * password will be hidden and the correct aria-label would be 'Show password.'
   */
  set ariaLabel(value) {
    if (value !== this._ariaLabel) {
      this._ariaLabel = value;
      this._cdr.markForCheck();
    }
  }
  get ariaLabel() {
    return this._ariaLabel;
  }
  constructor(_cdr, _elementRef, _focusMonitor) {
    this._cdr = _cdr;
    this._elementRef = _elementRef;
    this._focusMonitor = _focusMonitor;
    /** @docs-private */
    this._currentIcon = visibilityIcons.show;
    /** @docs-private */
    this._pressed = false;
    this._ariaLabel = 'Show password';
  }
  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef);
    if (!this.control) {
      console.warn('You need to pass an input as a control to the password toggle.');
      return;
    }
    // show the right icon according to the initial type of the input
    this._currentIcon = this.control.type === 'password' ? visibilityIcons.show : visibilityIcons.hide;
  }
  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  /** Toggles the type of the input. */
  toggleInputType() {
    if (this.control) {
      this.control.type = this.control.type === 'password' ? 'text' : 'password';
      this._pressed = !this._pressed;
      this.toggleIcon();
      this._cdr.markForCheck();
    }
  }
  /** @docs-private */
  toggleIcon() {
    this._currentIcon = this._currentIcon === visibilityIcons.show ? visibilityIcons.hide : visibilityIcons.show;
  }
  /** @docs-private */
  _onKeydown($event) {
    if ($event && ($event.keyCode === ENTER || $event.keyCode === SPACE)) {
      this.toggleInputType();
    }
  }
  /** @docs-private */
  get tabindex() {
    if (this.control) {
      return this.control.disabled ? -1 : 0;
    }
    return null;
  }
  static {
    this.ɵfac = function NxPasswordToggleComponent_Factory(t) {
      return new (t || NxPasswordToggleComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.FocusMonitor));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxPasswordToggleComponent,
      selectors: [["nx-password-toggle"]],
      hostAttrs: ["role", "button"],
      hostVars: 3,
      hostBindings: function NxPasswordToggleComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function NxPasswordToggleComponent_click_HostBindingHandler() {
            return ctx.toggleInputType();
          })("keydown", function NxPasswordToggleComponent_keydown_HostBindingHandler($event) {
            return ctx._onKeydown($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-label", ctx.ariaLabel)("tabindex", ctx.tabindex)("aria-pressed", ctx._pressed);
        }
      },
      inputs: {
        control: "control",
        ariaLabel: "ariaLabel"
      },
      decls: 1,
      vars: 1,
      consts: [["aria-hidden", "true", 3, "name"]],
      template: function NxPasswordToggleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "nx-icon", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("name", ctx._currentIcon);
        }
      },
      dependencies: [i2$1.NxIconComponent],
      styles: ["[_nghost-%COMP%]{position:relative;display:inline-block;height:24px;width:24px;cursor:pointer}@media screen and (forced-colors: active){[_nghost-%COMP%]{color:buttonText!important}}[_nghost-%COMP%]:focus{outline:none}[_nghost-%COMP%]:hover{color:var(--hover-primary)}.cdk-keyboard-focused[_nghost-%COMP%]     nx-icon{box-shadow:var(--focus-box-shadow);border-radius:4px}@media screen and (forced-colors: active),(forced-colors: active){.cdk-keyboard-focused[_nghost-%COMP%]     nx-icon{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}.is-disabled[_nghost-%COMP%], .is-disabled   [_nghost-%COMP%]{pointer-events:none}@media screen and (forced-colors: active){.is-disabled[_nghost-%COMP%], .is-disabled   [_nghost-%COMP%]{color:GrayText!important}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxPasswordToggleComponent, [{
    type: Component,
    args: [{
      selector: 'nx-password-toggle',
      template: `<nx-icon aria-hidden="true" [name]="_currentIcon"> </nx-icon>`,
      host: {
        '[attr.aria-label]': 'ariaLabel',
        '[attr.tabindex]': 'tabindex',
        '[attr.aria-pressed]': '_pressed',
        role: 'button',
        '(click)': 'toggleInputType()',
        '(keydown)': '_onKeydown($event)'
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{position:relative;display:inline-block;height:24px;width:24px;cursor:pointer}@media screen and (forced-colors: active){:host{color:buttonText!important}}:host:focus{outline:none}:host:hover{color:var(--hover-primary)}:host.cdk-keyboard-focused ::ng-deep nx-icon{box-shadow:var(--focus-box-shadow);border-radius:4px}@media screen and (forced-colors: active),(forced-colors: active){:host.cdk-keyboard-focused ::ng-deep nx-icon{box-shadow:0 0 0 2px background,0 0 0 6px CanvasText;outline:4px solid CanvasText;outline-offset:2px}}:host-context(.is-disabled){pointer-events:none}@media screen and (forced-colors: active){:host-context(.is-disabled){color:GrayText!important}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i1$1.FocusMonitor
  }], {
    control: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }]
  });
})();
class NxInputModule {
  static {
    this.ɵfac = function NxInputModule_Factory(t) {
      return new (t || NxInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, NxFormfieldModule, NxIconModule, NxFormfieldModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxInputModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NxFormfieldModule, NxIconModule],
      declarations: [NxInputDirective, NxPasswordToggleComponent],
      exports: [NxFormfieldModule, NxInputDirective, NxPasswordToggleComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NX_INPUT_VALUE_ACCESSOR, NxInputDirective, NxInputModule, NxPasswordToggleComponent };
