import {
  Component,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxCardModule } from "@aposin/ng-aquila/card";
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { NxHeadlineModule } from "@aposin/ng-aquila/headline";
import { NxCopytextModule } from "@aposin/ng-aquila/copytext";
import { NxMessageModule } from "@aposin/ng-aquila/message";
import { NxBadgeModule } from "@aposin/ng-aquila/badge";
import { NxIconModule } from "@aposin/ng-aquila/icon";
import { NxDataDisplayModule } from "@aposin/ng-aquila/data-display";
import { NxListModule } from "@aposin/ng-aquila/list";
import { NxModalModule, NxModalRef } from "@aposin/ng-aquila/modal";
import { NxDialogService } from "@aposin/ng-aquila/modal";
import { NxDatefieldModule } from "@aposin/ng-aquila/datefield";
// import { Moment } from "moment";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { TextFieldModule } from "@angular/cdk/text-field";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxSwipebarModule } from "@aposin/ng-aquila/swipebar";
import { NxTableModule } from "@aposin/ng-aquila/table";
import { NxRadioModule } from "@aposin/ng-aquila/radio-button";
import { NxPaginationModule } from "@aposin/ng-aquila/pagination";
import { NxNaturalLanguageFormModule } from "@aposin/ng-aquila/natural-language-form";
import { SharedModule } from "../../shared/shared.module";
import { PdpaPopupComponent } from "../../popup/pdpa-popup/pdpa-popup.component";

@Component({
  selector: "app-ism-policy",
  standalone: true,
  imports: [ProgressBarComponent, NxMomentDateModule, SharedModule],
  templateUrl: "./ism-policy.component.html",
  styleUrl: "./ism-policy.component.scss",
})
export class IsmPolicyComponent {
  @ViewChild("policysearch") policysearchRef!: TemplateRef<any>;

  constructor(private readonly dialogService: NxDialogService) {}

  openFromTemplate(): void {
    this.dialogService.open(this.policysearchRef, {
      ariaLabel: "A simple modal",
      width: "920px",
      maxWidth: "920px",
      disableClose: true,
    });
  }

  // Pagination //
  count = 210;
  page = 1;
  perPage = 10;

  prevPage() {
    this.page--;
  }

  nextPage() {
    this.page++;
  }

  goToPage(n: number) {
    this.page = n;
  }


  componentDialogRef?: NxModalRef<PdpaPopupComponent>;
  openPopup(popup_type: any) {

    // Claim Free Years (CFY)
    // 25 = If CFY not within range 0 - 100
    this.componentDialogRef = this.dialogService.open(PdpaPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '500px',
      data: { popup_type: popup_type },
    });
  }
}
