import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpHelperService } from 'src/app/shared/services/http-helper/http-helper.service';
import { ACTIONS } from "../../shared/enums/index.enum";
import { map } from 'rxjs/operators';




@Injectable({
	providedIn: 'root'
})

export class EnquiryService {

    constructor(private httpHelperService: HttpHelperService) { }

    getEnquiryDetails(body) {
		
		return this.httpHelperService.getDirectUrl(`/assets/data-mock/cn-detail-v2-mock.json`);
	}
}