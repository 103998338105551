<nx-accordion variant="light">
    <nx-expansion-panel expanded="[true]">
        <nx-expansion-panel-header>
            <nx-expansion-panel-title style="font-weight: 600;">Vehicles Details</nx-expansion-panel-title>
        </nx-expansion-panel-header>

        <div [formGroup]="vehicleDetailForm">
            <div nxLayout="grid nopadding">
                <div nxRow>

                    <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="{{ 'COVERAGE.FIELD.VEHICLE_NO' | translate }}">
                            <input nxInput formControlName="vehicleNo" appToUpperCase />
                            <nx-error nxFormfieldError *ngIf="vehicleDetailForm.get('vehicleNo').hasError('required')">
                                <strong>Please enter the Vehicle No.</strong>
                            </nx-error>
                            <nx-error nxFormfieldError *ngIf="vehicleDetailForm.get('vehicleNo').hasError('invalid')">
                                <strong>Vehicle No. is Invalid.</strong>
                            </nx-error>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield *ngIf="isAmendDateScren" label="{{ 'COVERAGE.FIELD.YEAR_OF_MANUFACTURE' | translate }}">
                            <input nxInput formControlName="vehicleMakeYear" appToUpperCase />
                        </nx-formfield>
                    </div>

                    <div nxCol="12" *ngIf="isAmendDateScren">
                        <nx-formfield label="{{ 'COVERAGE.FIELD.MAKE_MODEL' | translate }}">
                            <input nxInput formControlName="vehModelNvicDesc" appToUpperCase />
                            <span nxFormfieldSuffix>
                                <nx-icon aria-hidden="true" name="search"></nx-icon>
                            </span>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="{{ 'COVERAGE.FIELD.ENGINE_MOTOR_NO' | translate }}">
                            <input nxInput formControlName="vehicleEngine" appToUpperCase />
                            <nx-error nxFormfieldError
                                *ngIf="vehicleDetailForm.get('vehicleEngine').hasError('required')">
                                <strong>Please enter the Engine/Motor No.</strong>
                            </nx-error>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="{{ 'COVERAGE.FIELD.CHASSIS_NO' | translate }}">
                            <input nxInput formControlName="vehicleChassis" appToUpperCase />
                            <nx-error nxFormfieldError
                                *ngIf="vehicleDetailForm.get('vehicleChassis').hasError('required')">
                                <strong>Please enter the Chassis No.</strong>
                            </nx-error>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="JPJ Effective Date">
                            <input nxDatefield nxInput [datepicker]="myDatepicker" formControlName="jpjEffectiveDate"
                                [parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY" />
                            <span nxFormfieldHint>{{ 'GLOBAL.DATE.DD/MM/YYYY' | translate }}</span>
                            <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                            <nx-datepicker #myDatepicker></nx-datepicker>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="JPJ Expiry Date">
                            <input nxDatefield nxInput [datepicker]="myDatepicker2" formControlName="jpjExpiryDate"
                                [parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY" />
                            <span nxFormfieldHint>{{ 'GLOBAL.DATE.DD/MM/YYYY' | translate }}</span>
                            <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
                            <nx-datepicker #myDatepicker2></nx-datepicker>
                        </nx-formfield>
                    </div>

                    <div nxCol="12,12,6,6,6,6,6" *ngIf="isAmendDateScren">
                        <nx-formfield label="JPJ Status">
                            <input nxInput formControlName="jpjStatus" appToUpperCase />
                        </nx-formfield>
                    </div>
                </div>
            </div>
        </div>
    </nx-expansion-panel>
</nx-accordion>