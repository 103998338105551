import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
const _c0 = ["nxCopytext", ""];
const _c1 = ["*"];
const DEFAULT_TYPE = 'normal';
class NxCopytextComponent {
  constructor() {
    this._classNames = '';
    /** @docs-private */
    this.type = DEFAULT_TYPE;
    /** @docs-private */
    this.negative = false;
  }
  /**
   * Sets the type and whether the negative set of styling should be used.
   *
   * You can combine the values of type NxCopytextType and 'negative'.
   *
   * Default: `'normal'`.
   */
  set classNames(value) {
    if (this._classNames === value) {
      return;
    }
    this._classNames = value;
    // TODO kick null safe-guards after setter value or any calling input values are properly coerced as string
    const [type = null] = this._classNames?.match(/small|medium|normal|large/) ?? [DEFAULT_TYPE];
    this.type = type;
    this.negative = !!this._classNames?.match(/negative/);
  }
  get classNames() {
    return this._classNames;
  }
  static {
    this.ɵfac = function NxCopytextComponent_Factory(t) {
      return new (t || NxCopytextComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxCopytextComponent,
      selectors: [["", "nxCopytext", ""]],
      hostVars: 12,
      hostBindings: function NxCopytextComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("nx-copy", true)("nx-copy--small", ctx.type === "small")("nx-copy--medium", ctx.type === "medium")("nx-copy--normal", ctx.type === "normal")("nx-copy--large", ctx.type === "large")("nx-copy--negative", ctx.negative);
        }
      },
      inputs: {
        classNames: [i0.ɵɵInputFlags.None, "nxCopytext", "classNames"]
      },
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function NxCopytextComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{margin:0;font-size:var(--paragraph-03-font-size);line-height:var(--paragraph-03-line-height);font-weight:var(--paragraph-03-font-weight);letter-spacing:var(--paragraph-03-letter-spacing)}.nx-copy.nx-copy[_nghost-%COMP%]{font-weight:400}.nx-copy--negative[_nghost-%COMP%]{color:var(--negative)}.nx-copy--small[_nghost-%COMP%]{font-size:var(--paragraph-05-font-size);line-height:var(--paragraph-05-line-height);font-weight:var(--paragraph-05-font-weight);letter-spacing:var(--paragraph-05-letter-spacing)}.nx-copy--medium[_nghost-%COMP%]{font-size:var(--paragraph-04-font-size);line-height:var(--paragraph-04-line-height);font-weight:var(--paragraph-04-font-weight);letter-spacing:var(--paragraph-04-letter-spacing)}.nx-copy--large[_nghost-%COMP%]{font-size:var(--paragraph-02-font-size);line-height:var(--paragraph-02-line-height);font-weight:var(--paragraph-02-font-weight);letter-spacing:var(--paragraph-02-letter-spacing)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxCopytextComponent, [{
    type: Component,
    args: [{
      selector: '[nxCopytext]',
      template: `<ng-content></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.nx-copy]': 'true',
        '[class.nx-copy--small]': 'type === "small"',
        '[class.nx-copy--medium]': 'type === "medium"',
        '[class.nx-copy--normal]': 'type === "normal"',
        '[class.nx-copy--large]': 'type === "large"',
        '[class.nx-copy--negative]': 'negative'
      },
      styles: [":host{margin:0;font-size:var(--paragraph-03-font-size);line-height:var(--paragraph-03-line-height);font-weight:var(--paragraph-03-font-weight);letter-spacing:var(--paragraph-03-letter-spacing)}:host.nx-copy.nx-copy{font-weight:400}:host(.nx-copy--negative){color:var(--negative)}:host(.nx-copy--small){font-size:var(--paragraph-05-font-size);line-height:var(--paragraph-05-line-height);font-weight:var(--paragraph-05-font-weight);letter-spacing:var(--paragraph-05-letter-spacing)}:host(.nx-copy--medium){font-size:var(--paragraph-04-font-size);line-height:var(--paragraph-04-line-height);font-weight:var(--paragraph-04-font-weight);letter-spacing:var(--paragraph-04-letter-spacing)}:host(.nx-copy--large){font-size:var(--paragraph-02-font-size);line-height:var(--paragraph-02-line-height);font-weight:var(--paragraph-02-font-weight);letter-spacing:var(--paragraph-02-letter-spacing)}\n"]
    }]
  }], null, {
    classNames: [{
      type: Input,
      args: ['nxCopytext']
    }]
  });
})();
class NxCopytextModule {
  static {
    this.ɵfac = function NxCopytextModule_Factory(t) {
      return new (t || NxCopytextModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxCopytextModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxCopytextModule, [{
    type: NgModule,
    args: [{
      declarations: [NxCopytextComponent],
      exports: [NxCopytextComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxCopytextComponent, NxCopytextModule };
