import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";

@Component({
  selector: 'app-unification',
  standalone: true,
  imports: [SharedModule, ProgressBarComponent],
  templateUrl: './unification.component.html',
  styleUrl: './unification.component.scss'
})
export class UnificationComponent {


  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }
}
