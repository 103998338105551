import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { PdpaComponent } from '../pdpa/pdpa.component';

@Component({
  selector: 'app-consent-indicator',
  standalone: true,
  imports: [SharedModule, PdpaComponent],
  templateUrl: './consent-indicator.component.html',
  styleUrl: './consent-indicator.component.scss'
})
export class ConsentIndicatorComponent implements OnInit, AfterViewInit, OnChanges  {
  @Input() consentDetails!: FormGroup;
  @Input() isEndorsement: boolean = false;
  myForm: FormGroup;

  questions: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) { } 

  ngOnChanges(changes: SimpleChanges) {
    if (changes['consentDetails']) {
      //console.log('consentDetails has changed:', changes['consentDetails'].currentValue);
    }
  } 

  ngOnInit() {
    if (this.isEndorsement) {
      this.questions = [
        { label: 'Would you like to receive your e-Invoice as provided for under the relevant tax laws?', controlName: 'einvoiceConsentInd' },
      ];
    } else {
      this.questions = [
        { label: 'Would you like to receive all your documents in softcopy via e-mail?', controlName: 'edocConsentInd' },
        { label: 'Would you like to receive your e-Invoice as provided for under the relevant tax laws?', controlName: 'einvoiceConsentInd' },
      ];
    }
    
  }

  onSubmit() {
    //console.log(this.myForm.value);
  }

  ngAfterViewInit() {
    // console.log('Updated Form Values:', this.pdpaForm.value);
  }
}
