<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>Motor</h1>
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth">
  <!-- Temporary navigation between CN and endorsement, to delete later -->
  <div nxRow class="nx-margin-y-2m">
    <div nxCol="12">
      <button
        nxPlainButton
        class="nx-margin-right-2xs"
        type="button"
        (click)="changeType('CN')"
      >
        CN/Quotation/Roadtax purpose
      </button>
      |
      <button
        nxPlainButton
        class="nx-margin-x-2xs"
        type="button"
        (click)="changeType('ENDT')"
      >
        Endorsement
      </button>
    </div>
  </div>
  <!-- End  temporary -->

  <div nxRow rowJustify="center">
    <div nxCol="12,12,12,3" class="grid_width_page">
      <!-- 
      Note:
      Progree type:
      1. cn = cover note and quotation progress bar, 5 step
      2. endorsement = endorsement progress bar, 4 step
      3. unification = unification progress bar, 6 step
      4. rtp = road tax purpose progress bar, 3 step

      progressvalue = step index
      -->
      <app-progress-bar [progressValue]="0" [progressType]="'cn'" />
    </div>
    <div nxCol="12,12,12,9" class="grid_width_page">
      <div>
        <div nxLayout="grid nopadding">
          <div nxRow>
            <div nxCol="12">
              <h2
                class="nx-margin-0"
              >
                Declaration & Issuance Information
              </h2>
              <span nxCopytext="small"><i>All fields are mandatory.</i> </span>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <div class="nx-margin-top-2m">
                <p nxCopytext="normal">
                  Issuing Cover Note for Agent Code <b>AS00584</b>
                </p>

                <button
                  nxPlainButton
                  type="button"
                  class="std-ta-left nx-margin-y-m"
                >
                  <nx-icon
                    name="arrow-right"
                    nxIconPositionStart
                    aria-hidden="true"
                  ></nx-icon
                  >SELECT A DIFFERENT AGENT CODE
                </button>
              </div>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <h4 class="nx-margin-0 nx-formfield__flexfield">
                PDPA Declaration
              </h4>
              <hr class="hr nx-margin-top-xs" />
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <div class="nx-margin-top-m">
                <nx-checkbox class="nx-margin-bottom-s">
                  <span style="text-align: justify">
                    I hereby confirm that I have read and informed the customer
                    concerned of the Data Privacy Notice placed in the General
                    tab section under the heading PDPA Notice. I further confirm
                    that the said customer has given explicit consent, in
                    accordance with the provisions of the Personal Data
                    Protection Act 2010 (“PDPA”), on behalf of the said customer
                    and the customer’s family members, dependants,
                    beneficiaries, trustees, personal representatives, nominees,
                    assignees or other persons (collectively referred to as
                    “other persons”), to Allianz General Insurance Company
                    (Malaysia) Berhad (“Company”) and/or its Group to collect,
                    use, disclose, transfer, share or otherwise process the said
                    customer’s Personal Data and the Personal Data of the other
                    persons including Sensitive Personal Data for the purposes
                    mentioned in the Data Privacy Notice and that all the
                    necessary consents for the Company and/or Group to deal with
                    their Personal Data of the other persons have been obtained.
                    <br /><br />
                    I understand that my failure to inform or explain to the
                    customer on the Data Privacy Notice could lead to serious
                    consequences resulting in a potential breach of the PDPA and
                    hereby undertake to fully indemnify the Company and/or the
                    Group for any and all losses, damages, costs and expenses
                    that it might suffer arising therefrom or as a consequence
                    thereof.
                  </span>
                </nx-checkbox>
              </div>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <div class="nx-margin-top-2m">
                <hr *ngIf="cn_quotation" class="hr nx-margin-left-2m" />

                <div nxLayout="grid nopadding" class="nx-margin-left-2m">
                  <div
                    nxRow
                    class="nx-margin-top-2m"
                    rowJustify="around,around,around,around,around,around,around"
                    *ngIf="cn_quotation"
                  >
                    <div nxCol="12,12,12,8,9,9,9">
                      <h4
                        class="nx-margin-0 nx-margin-bottom-xs nx-font-weight-semibold"
                      >
                        Would you like to receive all your documents in softcopy
                        via e-mail?
                      </h4>
                    </div>
                    <div nxCol="12,12,12,4,3,3,3">
                      <div class="radio-group3">
                        <nx-radio name="group1" value="Yes">Yes</nx-radio>
                        <nx-radio
                          name="group1"
                          value="No"
                          class="nx-margin-left-2m"
                          >No</nx-radio
                        >
                      </div>
                    </div>
                  </div>
                  <hr class="hr nx-margin-top-2m" />
                  <div
                    nxRow
                    class="nx-margin-top-2m"
                    *ngIf="cn_quotation || endorsement"
                  >
                    <div nxCol="12,12,12,8,9,9,9">
                      <h4
                        class="nx-margin-0 nx-margin-bottom-xs nx-font-weight-semibold"
                      >
                        Would you like to receive your e-Invoice as provided for
                        under the relevant tax laws?
                      </h4>
                    </div>
                    <div nxCol="12,12,12,4,3,3,3">
                      <div>
                        <nx-radio-group
                          name="singleRadioOutputTest"
                          (groupValueChange)="consentChange($event)"
                          class="radio-group3"
                        >
                          <nx-radio name="group2" value="Yes">Yes</nx-radio>
                          <nx-radio
                            name="group2"
                            value="No"
                            class="nx-margin-left-2m"
                            >No</nx-radio
                          >
                        </nx-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="hr nx-margin-top-2m" />
              </div>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <div class="nx-margin-top-2m">
                <h4
                  class="nx-margin-0"
                >
                  Issuance Information
                </h4>
              </div>
            </div>
          </div>

          <div nxRow class="nx-margin-top-2m" *ngIf="cn_quotation">
            <div nxCol="12">
              <nx-formfield label="Cover Note Type">
                <nx-dropdown
                  [valueFormatter]="toText"
                  value="RENEWAL / NEW BUSINESS - OLD VEHICLE, OLD REGISTRATION"
                >
                  <nx-dropdown-item
                    value="RENEWAL / NEW BUSINESS - OLD VEHICLE, OLD REGISTRATION"
                  >
                  </nx-dropdown-item>
                  <nx-dropdown-item
                    value="NEW BUSINESS - NEW VEHICLE, NEW REGISTRATION"
                  >
                  </nx-dropdown-item>
                  <nx-dropdown-item
                    value="NEW BUSINESS - TRANSFER OF OWNERSHIP"
                  >
                  </nx-dropdown-item>
                  <nx-dropdown-item value="ROAD TAX PURPOSE">
                  </nx-dropdown-item>
                </nx-dropdown>

                <nx-error nxFormfieldError>
                  <strong> Please select cover note type </strong>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow *ngIf="cn_quotation || endorsement">
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Vehicle No.">
                <input nxInput type="text" />
                <span nxFormfieldHint> eg. PUTRAJAYA1234 </span>

                <!-- Please not to change "nxFormfieldNote"  to "nxFormfieldError" when applying form control-->
                <nx-error nxFormfieldNote>
                  <strong> Please enter Vehicle No. </strong>
                </nx-error>
                <nx-error nxFormfieldNote>
                  <strong> Invalid Vehicle No. </strong>
                </nx-error>
                <nx-error nxFormfieldNote>
                  <strong> Vehicle No. doesn't exist. </strong>
                </nx-error>
              </nx-formfield>

              <!-- =============to be delete ========= -->
              <div>
                <!-- <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(1)"
                >
                  not exist
                </button>
                | -->
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(2)"
                >
                  found expired
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(3)"
                >
                  found but another endorsement in progress</button
                >|
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(4)"
                >
                  found but cancelled
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(5)"
                >
                  e-Invoice Consent is Yes
                </button>
              </div>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Policy No.">
                <input nxInput type="text" />
                <nx-error nxFormfieldError>
                  <strong> Please enter Policy No. </strong>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow *ngIf="cn_quotation">
            <div nxCol="12,12,6,6,6,6,6">
              <div class="grid">
                <div nxRow>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="ID Type">
                      <nx-dropdown
                        [valueFormatter]="toText"
                        [panelGrow]="true"
                        [panelMaxWidth]="423"
                        value="NRIC"
                      >
                        <nx-dropdown-item [value]="'NRIC'"> </nx-dropdown-item>
                        <nx-dropdown-item
                          [value]="
                            'Old IC / Police ID / Army ID / Passport No.'
                          "
                        >
                        </nx-dropdown-item>
                        <nx-dropdown-item [value]="'Business Registration No.'">
                        </nx-dropdown-item>
                        <nx-dropdown-item
                          [value]="'New Business Registration No.'"
                        ></nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="ID No.">
                      <input nxInput type="text" />

                      <nx-error nxFormfieldNote>
                        <strong> Please enter ID No. </strong>
                      </nx-error>
                      <nx-error nxFormfieldNote>
                        <strong> Wrong Format</strong>
                      </nx-error>
                      <nx-error nxFormfieldNote>
                        <strong>Invalid Business Registration No.</strong>
                      </nx-error>
                    </nx-formfield>

                    <div>
                      <button
                        nxPlainButton
                        class="nx-margin-x-2xs"
                        type="button"
                        (click)="openPopup(17)"
                      >
                        active quotation (Printed) exist
                      </button>
                      |
                      <button
                        nxPlainButton
                        class="nx-margin-x-2xs"
                        type="button"
                        (click)="openPopup(18)"
                      >
                        Validation (RN / NWOO)
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Previous Policy No.">
                <input nxInput type="text" [disabled]="true" />
              </nx-formfield>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Product Name">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item [value]="'PRIVATE CARS EXCLUDING GOODS'">
                  </nx-dropdown-item>
                  <nx-dropdown-item [value]="'PRIVATE CARS INCLUDING GOODS'">
                  </nx-dropdown-item>
                  <nx-dropdown-item [value]="'PRIVATE CARS COMPANY REGISTERED'">
                  </nx-dropdown-item>
                  <nx-dropdown-item [value]="'MOTOR CYCLE'"> </nx-dropdown-item>
                  <nx-dropdown-item [value]="'MOTORCYCLE PLUS'">
                  </nx-dropdown-item>
                </nx-dropdown>
                <nx-error nxFormfieldNote>
                  <strong>Please select a product</strong>
                </nx-error>
              </nx-formfield>
            </div>

            <!-- <div
              nxCol="12,12,6,6,6,6,6"
              class="nx-margin-top-s special_vehicle"
            >
              <nx-checkbox disabled> Special Vehicle </nx-checkbox>
            </div> -->
          </div>

          <!-- Note that "Policy Effective date" and  "Policy Expiry Date" is same with "Effective date" and "Expiry Date" -->
          <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield>
                <nx-formfield-label>
                  <span *ngIf="endorsement">Policy</span> Effective Date
                </nx-formfield-label>
                <input nxDatefield nxInput [datepicker]="myDatepicker" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker></nx-datepicker>

                <nx-error nxFormfieldNote>
                  <strong>Please enter an Effective Date</strong>
                </nx-error>
              </nx-formfield>

              <!-- to be delete -->
              <!-- <div>
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(19)"
                >
                  Effective Date less than Issued Date + YY days
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(20)"
                >
                  Effective Date > Issued Date + YY days
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(21)"
                >
                  Effective Date > Issued Date + XX days
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(21)"
                >
                  Effective Date = Issued Date
                </button>
              </div> -->
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield>
                <nx-formfield-label>
                  <span *ngIf="endorsement">Policy</span> Expiry Date
                </nx-formfield-label>
                <input nxDatefield nxInput [datepicker]="myDatepicker2" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle
                  [for]="myDatepicker2"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker2></nx-datepicker>

                <nx-error nxFormfieldNote>
                  <strong>Please enter an Expiry Date</strong>
                </nx-error>
              </nx-formfield>

              <!-- to be delete -->
              <!-- <div>
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(23)"
                >
                  Expiry Date less than Eff Date
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(24)"
                >
                  Expiry Date > Eff Date + XX months
                </button>
              </div> -->
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,8,8,8,8,8">
              <nx-message context="info">
                <span class="nx-font-weight-bold">
                  Backdating of effective date is not allowed.
                </span>
              </nx-message>
              <nx-message context="info">
                <span class="nx-font-weight-bold">
                  Backdated effective date cannot be earlier than YY days before
                  issued date.
                </span>
              </nx-message>
              <nx-message context="info">
                <span class="nx-font-weight-bold">
                  Effective date cannot be later than XX days after issued date.
                </span>
              </nx-message>
              <nx-message context="info">
                <span class="nx-font-weight-bold">
                  This cover note might be lapsed.
                </span>
              </nx-message>
              <nx-message context="info">
                <span class="nx-font-weight-bold">
                  Expiry date cannot be before effective date.
                </span>
              </nx-message>
              <nx-message context="info">
                <span class="nx-font-weight-bold">
                  Expiry Date: Period of Insurance exceeds maximum allowable: XX
                  months.
                </span>
              </nx-message>
            </div>
          </div>

          <div nxRow *ngIf="endorsement">
            <div nxCol="12">
              <nx-formfield label="Endorsement Type">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item value="Change of Engine"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Extension Prorate"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Amendment of Period"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Cancellation by Insured"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Change of Bancassurance Details"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Change of Vehicle Chassis No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Change of Vehicle Registration No."
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Extra Benefits"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Hire Purchase / Employers' Loan/Leasing"
                  ></nx-dropdown-item>
                  <nx-dropdown-item value="Named Driver"></nx-dropdown-item>
                  <nx-dropdown-item value="NCD Allowed"></nx-dropdown-item>
                  <nx-dropdown-item value="NCD Amendment"></nx-dropdown-item>
                  <nx-dropdown-item value="NCD Withdraw"></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Revision of Sum Insured"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Change of Make & Model"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Reinstatement of Extra Coverage"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Amendment of e-Invoice Details"
                  ></nx-dropdown-item>
                  <nx-dropdown-item
                    value="Change of e-Invoice Consent"
                  ></nx-dropdown-item>
                </nx-dropdown>

                <nx-error nxFormfieldNote>
                  <strong>Please select Endorsement Type</strong>
                </nx-error>
              </nx-formfield>
              <!-- =============to be delete ========= -->
              <div>
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(6)"
                >
                  agent not Banca Agent
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(7)"
                >
                  policy NCD is zero
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(8)"
                >
                  policy NCD is not zero</button
                >|
                <!-- WORKING: e-Invoice (Individual) -->
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(9)"
                >
                  Amendment of e-Invoice Details
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(10)"
                >
                  Change of e-Invoice consent</button
                >|
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(11)"
                >
                  Change of e-Invoice consent
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="endorsement" class="nx-margin-bottom-s">
            <div nxRow>
              <div nxCol="12">
                <span nxCopytext="large"
                  >Are you cancelling a previous "Extension of Policy"
                  endorsement?</span
                >
                <div class="radio-group nx-margin-top-s">
                  <nx-radio name="group1">Yes</nx-radio>
                  <nx-radio name="group1" class="nx-margin-left-2m"
                    >No</nx-radio
                  >
                </div>
              </div>
            </div>

            <div nxRow class="nx-margin-top-s">
              <div nxCol="12,12,6,6,6,6,6">
                <nx-error nxFormfieldNote>
                  <strong>Please select option</strong>
                </nx-error>
              </div>
            </div>
          </div>

          <div *ngIf="endorsement">
            <div nxRow>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Endorsement Effective Date">
                  <input nxDatefield nxInput [datepicker]="myDatepicker5" />
                  <span nxFormfieldHint>MM/DD/YYYY</span>

                  <nx-datepicker-toggle
                    [for]="myDatepicker5"
                    nxFormfieldSuffix
                  ></nx-datepicker-toggle>
                  <nx-datepicker #myDatepicker5></nx-datepicker>

                  <nx-error nxFormfieldNote>
                    <strong>Please enter Endorsement Effective Date</strong>
                  </nx-error>
                </nx-formfield>

                <!-- to be delete -->
                <!-- <div>
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(12)"
                >
                  date input earlier than current date
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(13)"
                >
                  date input after Endt Exp Date
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(14)"
                >
                  date input earlier than Policy Effective Date
                </button>
              </div> -->
              </div>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Endorsement Expiry Date">
                  <input nxDatefield nxInput [datepicker]="myDatepicker6" />
                  <span nxFormfieldHint>MM/DD/YYYY</span>

                  <nx-datepicker-toggle
                    [for]="myDatepicker6"
                    nxFormfieldSuffix
                  ></nx-datepicker-toggle>
                  <nx-datepicker #myDatepicker6></nx-datepicker>

                  <nx-error nxFormfieldNote>
                    <strong
                      >Please enter a valid Endorsement Expiry Date</strong
                    >
                  </nx-error>
                </nx-formfield>

                <!-- <div>
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(15)"
                >
                  date range more than 6 months
                </button>
                |
                <button
                  nxPlainButton
                  class="nx-margin-x-2xs"
                  type="button"
                  (click)="openPopup(16)"
                >
                  date input earlier than or equal Endt Eff Date
                </button>
              </div> -->
              </div>
            </div>
            <div nxRow>
              <div nxCol="12,12,8,8,8,8,8">
                <nx-message context="info">
                  <span class="nx-font-weight-bold">
                    Endorsement Effective date cannot be earlier than issued
                    date.
                  </span>
                </nx-message>
                <nx-message context="info">
                  <span class="nx-font-weight-bold">
                    Endorsement Effective date cannot be after expiry date.
                  </span>
                </nx-message>
                <nx-message context="info">
                  <span class="nx-font-weight-bold">
                    Endorsement Effective date cannot be before the effective
                    date of the policy (dd/mm/yyyy).
                  </span>
                </nx-message>
                <nx-message context="info">
                  <span class="nx-font-weight-bold">
                    Expiry date cannot be more than 6 months after effective
                    date.
                  </span>
                </nx-message>
                <nx-message context="info">
                  <span class="nx-font-weight-bold">
                    Expiry date cannot be earlier than effective date.
                  </span>
                </nx-message>
              </div>
            </div>
          </div>

          <div nxRow class="nx-margin-top-50 nx-margin-bottom-3xl">
            <div nxCol="12" class="button_area">
              <button nxButton="secondary" type="button">back</button>
              <button nxButton="secondary" type="button">CANCEL DRAFT</button>
              <button
                nxButton="primary"
                type="button"
                [routerLink]="'../customer-partner'"
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
