<div nxRow rowJustify="center">
  <div nxCol="12" class="grid_width_page">
      <h2 class="nx-margin-0">Declaration & Issuance Information</h2>
      <span nxCopytext="small"><i>All fields are mandatory.</i> </span>

      @if (isIssuance) {
      <p nxCopytext="normal" class="nx-margin-top-2m nx-margin-bottom-s">Issuing Cover Note for Agent Code
        <span class="nx-font-weight-bold">{{agentData.agentCode}}</span>
      </p>
      }

      @if (isRTP) {
      <p nxCopytext="normal" class="nx-margin-top-2m nx-margin-bottom-s">Issuing Road Tax Purpose
      </p>
      }

      @if (isEndorsement) {
      <p nxCopytext="normal" class="nx-margin-top-2m">Issuing Endorsement
      </p>
      }

      @if (isIssuance && !isSingleAgent && !this.agentData?.bancaAgent && !this.agentData?.sundriesAgent && !isRequote) {
      <button nxPlainButton type="button" class="std-ta-left" (click)="goToSelectAgentCode()">
        <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon>
        SELECT A DIFFERENT AGENT CODE
      </button>
      }

      <h4 class="nx-margin-top-2m nx-margin-bottom-s nx-formfield__flexfield">
        PDPA Declaration
      </h4>

      <hr class="nx-margin-bottom-2m">

      @if (isIssuance || isEndorsement || isRTP) {
      <app-pdpa [pdpaForm]="pdpaForm" [isRTP]="isRTP" [isEndorsement]="isEndorsement"></app-pdpa>
      }

      <form [formGroup]="issuanceForm">

        <hr class="nx-margin-top-2m"/>
        
        <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold nx-margin-top-2m nx-margin-bottom-2m">
          Issuance Information
        </h4>

        @if (isEndorsement) {
        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Vehicle No.">
              <input nxInput type="text" appToUpperCase formControlName="vehicleNo" style="text-transform: uppercase;"
                (change)="onChangeVehicleNo()" maxlength="20" />
              <span nxFormfieldHint> eg. PUTRAJAYA1234 </span>
              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('vehicleNo').hasError('required')">
                <strong>Please enter Vehicle No.</strong>
              </nx-error>
              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('vehicleNo').hasError('pattern')">
                <strong>Invalid Vehicle No.</strong>
              </nx-error>

            </nx-formfield>
          </div>

          @if (endtVehicleFound) {
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Policy No.">
              <input nxInput type="text" formControlName="policyNo" />
            </nx-formfield>
          </div>
          }
        </div>

        @if (endtVehicleFound) {
        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Product Name">
              <nx-dropdown formControlName="productCode">
                <ng-container *ngFor="let product of productList">
                  <nx-dropdown-item [value]="product.code">{{ product.descp }}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield>
              <nx-formfield-label>
                Policy Effective Date
              </nx-formfield-label>
              <input nxDatefield nxInput [datepicker]="myDatepicker1" formControlName="policyEffectiveDate"
                [parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY" />
              <span nxFormfieldHint>DD/MM/YYYY</span>

              <nx-datepicker-toggle [for]="myDatepicker1" nxFormfieldSuffix></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker1></nx-datepicker>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield>
              <nx-formfield-label>
                Policy Expiry Date
              </nx-formfield-label>
              <input nxDatefield nxInput [datepicker]="myDatepicker2" formControlName="policyExpiryDate"
                [parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY" />
              <span nxFormfieldHint>DD/MM/YYYY</span>

              <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker2></nx-datepicker>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Endorsement Type">
              <nx-dropdown formControlName="endorsementType" (selectionChange)="onEndorsementTypeChange($event)">
                <ng-container *ngFor="let endt of endorsementList">
                  <nx-dropdown-item [value]="endt.code">{{ endt.descp | uppercase }}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('endorsementType').hasError('required')">
                <strong>Please select Endorsement Type.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        @if (previousExtension) {
        <div nxRow>
          <div nxCol="12">
            <!-- <label style="font-size: 16px; margin-bottom: 10px;">Are you cancelling a previous "Extension of Policy"
              endorsement?</label> -->
            <span nxCopytext="large">Are you cancelling a previous "Extension of Policy"
              endorsement?</span>
            <nx-radio-group formControlName="previousExtension" class="float-right">
              <div class="horizontal-buttons radio-group3 nx-margin-top-s">
                <nx-radio value="yes" class="nx-margin-bottom-m" [labelSize]="'small'">YES</nx-radio>
                <nx-radio value="no" class="nx-margin-left-2m nx-margin-bottom-m" [labelSize]="'small'">NO</nx-radio>
              </div>
            </nx-radio-group>
          </div>
        </div>
        }

        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield>
              <nx-formfield-label>
                Endorsement Effective Date
              </nx-formfield-label>
              <!-- <input nxDatefield nxInput [datepicker]="myDatepicker3" formControlName="endtEffectiveDate" />
                <span nxFormfieldHint>MM/DD/YYYY</span> -->
              <input nxDatefield nxInput [datepicker]="myDatepicker3" formControlName="endtEffectiveDate"
                [parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY" />
              <span nxFormfieldHint>DD/MM/YYYY</span>

              <nx-datepicker-toggle [for]="myDatepicker3" nxFormfieldSuffix></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker3></nx-datepicker>
            </nx-formfield>
            <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('endtEffectiveDate').hasError('required')">
              <strong>Please enter Endorsement Effective Date.</strong>
            </nx-error>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield>
              <nx-formfield-label>
                Endorsement Expiry Date
              </nx-formfield-label>
              <!-- <input nxDatefield nxInput [datepicker]="myDatepicker4" formControlName="endtExpiryDate" />
                <span nxFormfieldHint>MM/DD/YYYY</span> -->
              <input nxDatefield nxInput [datepicker]="myDatepicker4" formControlName="endtExpiryDate"
                [parseFormat]="['MM/DD/YYYY', 'DD/MM/YYYY']" displayFormat="DD/MM/YYYY" />
              <span nxFormfieldHint>DD/MM/YYYY</span>

              <nx-datepicker-toggle [for]="myDatepicker4" nxFormfieldSuffix></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker4></nx-datepicker>
            </nx-formfield>
            <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('endtExpiryDate').hasError('required')">
              <strong>Please enter Endorsement Expiry Date.</strong>
            </nx-error>
          </div>
        </div>
        }


        }

        @if (isIssuance || isRTP) {
        <nx-formfield label="Cover Note Type">
          <nx-dropdown formControlName="cnoteType" (selectionChange)="onChangeFormValue($event.value)">
            <ng-container *ngFor="let coverNote of coverNoteTypeList">
              <nx-dropdown-item [value]="coverNote.code">{{ coverNote.descp }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please select a cover note type.</strong>
          </nx-error>
        </nx-formfield>



        @if (isCoverNoteNWOO() || isCoverNoteNWTR() || isCoverNoteNWNN() || isRTP) {

        @if ((!isCoverNoteNWTR() && !isCoverNoteNWNN()) || isRTP) {
        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Vehicle No.">
              <input nxInput type="text" appToUpperCase formControlName="vehicleNo" style="text-transform: uppercase;"
                (change)="onChangeVehicleNo()" maxlength="20" />
              <span nxFormfieldHint> eg. PUTRAJAYA1234 </span>
              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('vehicleNo').hasError('required')">
                <strong>Please enter Vehicle No.</strong>
              </nx-error>
              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('vehicleNo').hasError('pattern')">
                <strong>Invalid Vehicle No.</strong>
              </nx-error>

            </nx-formfield>
          </div>

          @if (isRTP && isRTPDataExist) {
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Policy No.">
              <input nxInput type="text" appToUpperCase value="{{previousPolicyNo}}" disabled />
            </nx-formfield>
          </div>
          }
        </div>

        @if (!isRTP) {
        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <div nxRow>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="ID Type">
                  <nx-dropdown formControlName="idType" [panelGrow]="true" [panelMaxWidth]="423">
                    <ng-container *ngFor="let type of idTypeList">
                      <nx-dropdown-item [value]="type.code">{{ type.descp }}</nx-dropdown-item>
                    </ng-container>
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <strong>Please select an ID Type.</strong>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="ID No.">
                  <input nxInput type="text" appToUpperCase formControlName="idNumberText"
                    style="text-transform: uppercase;"
                    [attr.maxlength]="issuanceForm && issuanceForm.get('idType').value === 'NRIC' || issuanceForm.get('idType').value === 'NEW_BR_NO' ? 12 : 20"
                    (blur)="onChangeIdNo()" [appOnlyNumber]="onlyNumber"/>

                  <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('idNumberText').hasError('required')">
                    <strong>Please enter ID No.</strong>
                  </nx-error>
                  <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('idNumberText').hasError('maxlength')">
                    <strong>ID Number cannot be more than the allowed length.</strong>
                  </nx-error>
                  <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('idNumberText').hasError('pattern')">
                    <strong>ID Number must be valid.</strong>
                  </nx-error>
                  <nx-error nxFormfieldError
                    *ngIf="issuanceForm && issuanceForm.get('idNumberText').hasError('wrongFormat') && !issuanceForm.get('idNumberText').hasError('required')">
                    <strong>Wrong Format</strong>
                  </nx-error>
                  <nx-error nxFormfieldError
                    *ngIf="issuanceForm && issuanceForm.get('idNumberText').hasError('invalidBrNo') && !issuanceForm.get('idNumberText').hasError('required')">
                    <strong>Invalid Business Registration No.</strong>
                  </nx-error>
                </nx-formfield>
              </div>
            </div>
          </div>
          @if (isVehicleIdNoExist && previousPolicyNo) {
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Previous Policy No.">
              <input nxInput type="text" appToUpperCase value="{{previousPolicyNo}}" disabled />
            </nx-formfield>
          </div>
          }
        </div>
        }

        }

        @if ((isIssuance && isVehicleIdNoExist) || (isRTP && isRTPDataExist)) {
        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Product Name">
              <nx-dropdown formControlName="productCode" (selectionChange)="onChangeProduct($event.value)"
                [panelGrow]="true">
                <ng-container *ngFor="let product of filteredProductList">
                  <nx-dropdown-item [value]="product.code">{{ product.descp }}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('productCode').hasError('required')">
                <strong>Please select a product.</strong>
              </nx-error>
            </nx-formfield>
          </div>
          @if(isCommercial){
          <div nxCol="12,12,6,6,6,6,6">
            <div nxRow>
              <div class="button_area">
                <button nxButton="primary" type="button" (click)="switchToAlpha()">Commercial</button>
              </div>
            </div>
          </div>
          }
          @if (showSpecialVehicle) {
          <div nxCol="12,12,6,6,6,6,6">
            <nx-checkbox formControlName='specialVehicleInd' class="nx-margin-top-s">
              <span>
                Special Vehicle
              </span>
            </nx-checkbox>
          </div>
          }
        </div>
        }

        @if (((isIssuance && isVehicleIdNoExist) && !isCoverNoteNWNN()) || (isRTP && isRTPDataExist)) {
        <div nxRow>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield>
              <nx-formfield-label>Effective Date</nx-formfield-label>
              <input nxDatefield nxInput [datepicker]="myDatepicker1" formControlName="effectiveDate" />
              <span nxFormfieldHint>DD/MM/YYYY</span>

              <nx-datepicker-toggle [for]="myDatepicker1" nxFormfieldSuffix></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker1></nx-datepicker>

              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('effectiveDate').hasError('required')">
                <strong>Please enter an Effective Date.</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield>
              <nx-formfield-label>
                Expiry Date
              </nx-formfield-label>
              <input nxDatefield nxInput [datepicker]="myDatepicker2" formControlName="expiryDate" />
              <span nxFormfieldHint>DD/MM/YYYY</span>

              <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker2></nx-datepicker>

              <nx-error nxFormfieldError *ngIf="issuanceForm && issuanceForm.get('expiryDate').hasError('required')">
                <strong>Please enter an Expiry Date.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        }
        }
        }

        <!-- WORKING: Endorsement Type Validation -->
        <!-- @if (isEndorsement) {
            <app-issue-endorsement-validation [endorsement]="endorsement"></app-issue-endorsement-validation>
          } -->

        @if(showDateValidateError || showLapsedError) {
        <div nxRow>
          <div nxCol="12,12,8,8,8,8,8">
            <nx-message context="info">
              <span class="nx-font-weight-bold">
                {{ dateValidateError }}
              </span>
            </nx-message>
          </div>
        </div>
        }

        <div nxRow [ngClass]="hasErrors() ? 'button-container nx-margin-top-2m nx-margin-bottom-3xl' : 'button-container nx-margin-bottom-3xl'">
          <div nxCol="12" class="button_area">
            <button *ngIf="showBackButton()" nxButton="secondary" type="button" (click)="goBack()">BACK</button>
            @if (isSaved && isSaved.cnoteStatus && isSaved.cnoteStatus=== 'SAVED') {
              <button type="button" nxButton="secondary" (click)="cancelDraft()">
                {{ 'GLOBAL.ACTION.CANCEL_DRAFT' | translate }}
              </button>
            }
            <button nxButton="primary" type="button" (click)="goNext()" [disabled]="checkDisabled()">Next</button>
          </div>
        </div>

      </form>
  </div>
</div>