import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { formatNumber } from "@angular/common";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { GET_PREVIOUS_POLICY_NO, SEARCH_VIX, SELECTED_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { UserResponse } from "@interfaces/user";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { SharedModule } from "@shared/shared.module";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NumberRangeValidator } from "@shared/classes/validators/number-range.validator";
import { MakeModelComponent } from "@shared/components/modal/make-model/make-model.component";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-vehicle-details",
  standalone: true,
  imports: [SharedModule, NxMomentDateModule],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }],
  templateUrl: "./vehicle-details.component.html",
  styleUrl: "./vehicle-details.component.scss",
})
export class VehicleDetailsComponent implements OnInit {

  @Select(SatMotorState.motorActionType) actionType$;

  @Select(SatMotorState.endtStep2VehicleDetails) endtVehicleDetailsData$;

  @Input() coverageFmGroup!: FormGroup;
  @Input() vehicleUselov!: any;

  @Output() calculatePremium = new EventEmitter<any>();

  vehicleDetailForm: FormGroup;

  dialogRef?: NxModalRef<any>;

  lov: any;
  userInfo: any;
  vehicleUseLov: any;
  agentDetail: any;
  issuanceInfo: any;
  coverageType: any;
  selectedMakeModel: any;

  isRenewal: boolean = false;
  previousVehicleModelDescp: any = "";
  
  vehicleDetails: any;

  decimalFmt: any;
  numberFmt: any;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.vehicleDetailForm = this.fb.group({
      region: ["", [Validators.required]],
      vehicleUse: ["", [Validators.required]],
      vehicleNo: ["", [Validators.required, VehicleNoValidator()]],
      vehicleMakeYear: ["", { validators: [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.min(1924), Validators.max(new Date().getFullYear())], updateOn: "blur" }],
      makeModelDesc: ["", [Validators.required]],
      vehicleUom: [{ value: "", disabled: true }],
      vehicleCapacity: ["", [Validators.required, Validators.min(1)]],
      engineType: [{ value: "", disabled: true }, [Validators.required]],
      vehicleSeat: ["", [Validators.required, Validators.min(1)]],
      vehicleEngine: ["", [Validators.required]],
      vehicleChassis: ["", [Validators.required]],
      vehicleLoanType: [""],
      vehicleLoanCompany: [""],
      vehiclePurchaseDate: [""],
      makeModelPrintInd: [false],
      variantDesc: [""],
    });
  }

  ngOnInit() {
    this.coverageFmGroup.controls["vehicleDetails"] = this.vehicleDetailForm;
    
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    this.vehicleUseLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3.coverage.vehicleUseLov);
    this.agentDetail = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.issuance.issuance);
    this.coverageType = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    
    if (this.issuanceInfo.cnoteType !== 'NWTR') {
      this.vehicleDetailForm.controls["vehicleNo"].disable();
    }

    if (this.issuanceInfo.cnoteType == 'RN') {
      this.isRenewal = true;
    }

    this.vehicleDetails = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3.coverage?.vehicleDetails);
    if (this.vehicleDetails) {
      this.vehicleDetailForm.patchValue({ 
        ...this.vehicleDetails, vehicleCapacity: formatNumber(this.vehicleDetails.vehicleCapacity, 'en-US'),
        makeModelPrintInd: this.vehicleDetails.makeModelPrintInd === 'Y'
      });
    } else {
      this.vehicleDetailForm.patchValue({ region: this.agentDetail.region });
      if (this.issuanceInfo.cnoteType == 'NWNN') {
        this.vehicleDetailForm.patchValue({ vehicleNo: "NA" });
      }
      else if (this.issuanceInfo.cnoteType == 'NWTR') {
        this.vehicleDetailForm.patchValue({ vehicleNo: "" });
      }
      else {
        this.vehicleDetailForm.patchValue({ vehicleNo: this.issuanceInfo.vehicleNo });
      }
    }

    this.selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step3.coverage.selectedMakeModel);
    if (this.selectedMakeModel) {
      this.patchMakeModelForm(this.selectedMakeModel);
    }

    this.vehicleLoanType.valueChanges.subscribe((change) => {
      this.vehicleDetailForm.patchValue({ vehicleLoanCompany: "" });
      this.appendValidators(change, this.vehicleLoanCompany);
    });

    this.makeModelPrintInd.valueChanges.subscribe((change) => {
      this.vehicleDetailForm.patchValue({ variantDesc: "" });
      this.appendValidators(change, this.variantDesc);
    });
  }
  
  openPopupMakeModel() {
    if (!this.vehicleMakeYear.valid) {
      this.vehicleMakeYear.markAllAsTouched();
      return;
    }

    this.dialogRef = this.dialogService.open(MakeModelComponent, {
      showCloseIcon: false,
      width: "920px",
      maxWidth: "920px",
      data: { 
        region: this.region.value, 
        vehicleClass: this.vehicleUseLov.filter((v) => v.code === this.vehicleUse.value)[0].vehicleClass,
        yearMnftr: this.vehicleMakeYear.value
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new SELECTED_MAKE_MODEL(result.item)).subscribe((state) => {
          this.patchMakeModelForm(result);
        });

        if (result.item.uom === "CC") {
          this.vehicleCapacity.addValidators([NumberRangeValidator(40, 99999)]);
          this.vehicleCapacity.updateValueAndValidity();
        }

        this.calculatePremiumIfRequired();
      }
    });
  }

  patchMakeModelForm(dt) {
    let data = dt.item ?? dt;
    this.vehicleDetailForm.patchValue({
      makeModelDesc: [data.piamMakeModelDesc, data.transmission].join(" ").trim(),
      vehicleUom: data.uom,
      vehicleCapacity: formatNumber(data.capacity, 'en-US'),
      engineType: data.engineType,
      vehicleSeat: data.seat,
    });
  }

  private appendValidators(change: any, element: AbstractControl<any>) {
    if (change) {
      element.setValidators([Validators.required]);
    } else {
      element.clearValidators();
    }
    element.updateValueAndValidity();
  }

  characterCount: number = 0;
  maxlength = 100;

  onKeyUp(event: any) {
    this.characterCount = event.target.value.length;
  }

  onChangeUseOfVehicle($event) {
    if (this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3.coverage?.coverageType)) {
      this.calculatePremium.emit({});
    }
  }
  
  onChangeVehicleNo($event) {
    if ($event.target.value) {
      this.store.dispatch(new GET_PREVIOUS_POLICY_NO({ vehicleNo: $event.target.value, agentCode: this.agentDetail.agentCode })).subscribe((state) => {
        let previousPolicy = state.SatMotorState?.previousPolicyDetails;
        if (previousPolicy) {
          if (previousPolicy.riskList[0].prevNcdInsurer === "216" && this.calculateDiff(previousPolicy.expiryDate) < 90 ) {
            this.vehicleDetailForm.patchValue({ 
              ...previousPolicy.riskList[0], vehicleCapacity: formatNumber(previousPolicy.riskList[0].vehicleCapacity, 'en-US') ,
              makeModelDesc: previousPolicy.riskList[0].vehicleModelDescp
            });
            //this.store.dispatch(new SEARCH_MAKE_MODEL({ type: this.makeModelDesc.value, classCode: this.vehicleUseLov.filter((v) => v.code === this.vehicleUse.value)[0].vehicleClass, yearMnftr: previousPolicy.riskList[0].vehicleMakeYear })).subscribe((state) => {
              //this.store.dispatch(new SELECTED_MAKE_MODEL(previousPolicy.riskList[0])).subscribe((state) => {
              //  this.store.dispatch(new STORE_COVERAGE_VEHICLE_DETAILS(this.populateVehicleDetailsData(this.coverageFmGroup.controls["vehicleDetails"].getRawValue())));
              //});
            //});
          }
        } else {
          this.store.dispatch(new SEARCH_VIX({ cngeNote: { vehicleNo: $event.target.value, productCode: this.issuanceInfo.productCode  } })).subscribe((state) => {
            let ism = state.SatMotorState?.motor?.step1?.searchVIX?.cngeNote?.riskList[0];
            if (ism) {
              this.vehicleDetailForm.patchValue({ 
                ...ism, vehicleCapacity: formatNumber(ism.vehicleCapacity, 'en-US') ,
                makeModelDesc: ism.vehicleModelDescp
              });
            }
          })
        }
      });
    }
  }

  calculateDiff(sentDate) {
    var date1:any = new Date(sentDate);
    var date2:any = new Date();
    var diffDays:any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

    return diffDays;
}

  onChangeCapacity($event, comma: boolean, calculate: boolean) {
    if (!comma) {
      this.vehicleCapacity.patchValue(this.numberFmt.transform($event.target.value));
    } else {
      if (calculate)
        this.calculatePremiumIfRequired();
      this.vehicleCapacity.patchValue(formatNumber($event.target.value, 'en-US'));
    }
  }

  calculatePremiumIfRequired() {
    if (this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3.coverage?.coverageType)) {
      if (this.numberFmt.transform(this.coverageFmGroup.controls["typeOfCoverage"].getRawValue().sumInsured) > 0) {
        this.calculatePremium.emit({});
      }
    }
  }

  get region() {
    return this.vehicleDetailForm.controls["region"];
  }
  get vehicleUse() {
    return this.vehicleDetailForm.controls["vehicleUse"];
  }

  get vehicleNo() {
    return this.vehicleDetailForm.controls["vehicleNo"];
  }

  get vehicleMakeYear() {
    return this.vehicleDetailForm.controls["vehicleMakeYear"];
  }

  get makeModelDesc() {
    return this.vehicleDetailForm.controls["makeModelDesc"];
  }

  get vehicleCapacity() {
    return this.vehicleDetailForm.controls["vehicleCapacity"];
  }

  get vehicleSeat() {
    return this.vehicleDetailForm.controls["vehicleSeat"];
  }

  get vehicleEngine() {
    return this.vehicleDetailForm.controls["vehicleEngine"];
  }

  get vehicleChassis() {
    return this.vehicleDetailForm.controls["vehicleChassis"];
  }

  get vehicleLoanType() {
    return this.vehicleDetailForm.controls["vehicleLoanType"];
  }

  get vehicleLoanCompany() {
    return this.vehicleDetailForm.controls["vehicleLoanCompany"];
  }

  get makeModelPrintInd() {
    return this.vehicleDetailForm.controls["makeModelPrintInd"];
  }

  get variantDesc() {
    return this.vehicleDetailForm.controls["variantDesc"];
  }
}
