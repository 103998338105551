import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { MOTOR_COMMON, STEP1, STEP2 } from "src/app/interfaces/motor";

import {
  CHECK_SINGLE_AGENT,
  EDIT_CUSTOMER_PARTNER,
  GET_PRODUCT_CONFIG_LOV,
  POLICY_MOVEMENT_DATA,
  GET_PRODUCT_LIST,
  GET_AGENT_DETAIL,
  GET_AGENT_DETAIL_ONLY,
  GET_AGENT_LIST_AGENT,
  GET_AGENT_LIST_BRANCH,
  GET_BRANCH_LIST,
  GET_CUSTOMER_PARTNER,
  GET_DOCUMENT_LOV,
  GET_LOV,
  GET_NCD,
  GET_REDIRECT_PAGE,
  GET_SUBMENU_REDIRECTURL,
  INIT_STEP3,
  STORE_STEP3,
  SEARCH_MAKE_MODEL,
  SELECTED_MAKE_MODEL,
  SELECTED_ENDORSEMENT_MAKE_MODEL,
  SHOW_SEARCH_COMPONENT_2,
  STORE_AGENT_CODE,
  STORE_DECLARATION,
  STORE_AGENT_ADDITIONAL,
  STORE_ISSUANCE_SUMMARY,
  STORE_ENDORSEMENT,
  STORE_STEP2,
  STORE_STEP2_CP_SCREEN,
  STORE_STEP2_SEARCH,
  VALIDATE_CP,
  STORE_COVERAGE_TYPE,
  STORE_COVERAGE_VEHICLE_DETAILS,
  STORE_EXTRA_COVER_DETAILS,
  CALCULATE_PREMIUM,
  CALCULATE_ENDT_PREMIUM,
  CALCULATE_PREMIUM_SUMMARY,
  ADD_NEW_COVERAGE,
  GET_CUSTOMER_PARTNER_MOCK,
  GENERATE_QUOTATION,
  SEND_TO_JPJ,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  SEARCH_ACTIVE_QUOTATION,
  SEARCH_ENDT_VEHICLE,
  ROADTAX_SEARCH,
  SEARCH_VIX,
  STORE_SHOWED_ONE_ALLIANZ_POP_UP,
  SET_CUSTOMER_PARTNER_SEARCH_TYPE,
  UPDATE_PAYMENT_LIST,
  SAVE_REFERRAL_REMARKS,
  DOWNLOAD_PDF,
  GET_PREVIOUS_POLICY_NO,
  GET_VEHICLE_USE,
  REQUEST_CANCELLATION,
  CANCEL_REPLACE,
  DELETE_COVERAGE,
  SET_JPJ_STATUS_SENT,
  SET_ACTION,
  CONTINUE_ISSUANCE,
  ISSUE_NEW,
  CALCULATE_PREMIUM_PAYLOAD,
  SAVE_AS_DRAFT,
  DOWNLOAD_DOCUMENT,
  REQUOTE_UPLOAD_COPIED_DOCUMENTS,
  INITIATE_UPLOAD_DOCUMENT,
  COMMIT_UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT,
  RESET_UPLOAD_DOCUMENTS,
  STORE_CART_NAVIGATION,
  DELETE_UPLOAD_DOCUMENT,
  SAVE_CUSTOMER_PARTNER,
  GET_UPSELL_PRODUCT_LOV,
  STORE_UPSELL_PRODUCT,
  GET_PAYMENT_MODE_LOV,
  GET_PAYMENT_TO_LOV,
  GET_DOCUMENTS,
  GET_POSTCODE_LOV,
  GET_POSTCODE_INFO,
  RESET_AGENT_LIST,
  GET_CROSS_SELL_LIST,
  COPY_CNGE_INFO,
  STORE_AGENT,
  GET_ISS_PRODUCT_PDF,
  SAVE_ISS_MESSAGE_SENT_LOG,
  SAVE_ISS_EMAIL_SENT_LOG,
  GET_CCENTRIC_OPPORTUNITY_INFO,
  GET_SWITCH_SITE,
  SET_CURRENT_STEP,
  GET_DRAFT_DETAILS,
  GET_QUOTATION_DETAIL,
  GET_ISS_CONTENT_ID,
  DELETE_EXTRA_COVER_DETAILS,
  GET_NCD_CONFIRMATION,
  COPY_CNGE_INFO_RENEWAL,
  STORE_STEP2_RENEWAL,
  STORE_STEP3_RENEWAL,
  RESET_MOTOR_STATE,
  RESET_PREVIOS_POLICY_DETAILS,
  COPY_CNGE_INFO_VIEW_QUOTATION,
  PRODUCT_VALIDATION,
  COPY_DRAFT_INFO,
  COPY_REQUOTE_INFO,
  SEARCH_ENDORSEMENT_MAKE_MODEL,
  GET_COVER_LIST_LOV,
  STORE_EXTRA_COVER_PACKAGE,
  STORE_STEP3_DRAFT_NO,
  STORE_STEP2_DRAFT_NO,
  UPDATE_COVERAGE_TYPE,
  RESET_SAT_MOTOR_STATE,
  CANCEL_QUOTATION,
  CANCEL_COVER_NOTE,
  CANCEL_DRAFT,
  STORE_COMPLETE_DATA,
  GET_VIEW_POLICY,
  GET_QUOTATION_DETAIL_REQUOTE,
  VALIDATE_POI_DATE,
  REDIRECT_TO_MIGBASE_MOTOR,
  GET_VIEW_CLAIM,
  UPDATE_ISM_POLICY,
  GET_NCD_ENQUIRY,
  RESET_SUBSEQUENT_PAGE_DATA,
  SEARCH_POLICY_ENQUIRY,
  UPDATE_GEO_INDICATOR,
  STORE_PREMIUM_CALCULATION_PAYLOAD,
  GET_DOCUMENT_TYPE_LOV,
  GET_REASON_CODE_LOV,
  GET_VEHICLE_CLASS_USE_LOV,
  SET_RETRIEVE_QUOTATION,
  GET_CHECKLIST_LOV

} from "./sat-motor.action";
import { SatMotorService } from "./sat-motor.service";
import { HttpResponse } from "@angular/common/http";
import { UserResponse } from "@interfaces/user";
import { HttpHelperService } from "@shared/services/http-helper/http-helper.service";
import { defaultSatMotorState } from "./sat-motor-util";
import { SatMotorModel } from "./sat-motor.model";
import moment from "moment";

@Injectable()
@State<SatMotorModel>({
  name: "SatMotorState",
  defaults: defaultSatMotorState
})
export class SatMotorState {
  constructor(
    private satMotorService: SatMotorService,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private httpHelperService: HttpHelperService
  ) { }
  geoCodedInd: boolean;

  @Selector()
  public static motorActionType(state: SatMotorModel) {
    return {
      isIssuance: state.actionType === 'ISSUE',
      isEndorsement: state.actionType === 'ENDT',
      isEnquiry: state.actionType === 'ENQUIRY',
      isRTP: state.actionType === 'RTP'
    };
  }

  @Selector()
  public static progressStep(state: SatMotorModel) {
    return state.currentStep ?? 1;
  }

  @Selector()
  public static commonLov(state: SatMotorModel) {
    return state.lov ?? null;
  }

  @Selector()
  public static motorAgentList(state: SatMotorModel) {
    return state.data?.step1?.agentList ?? null;
  }

  @Selector()
  public static motorBranchList(state: SatMotorModel) {
    return state.data?.branchList ?? null;
  }

  @Selector()
  public static motorStep1Issuance(state: SatMotorModel) {
    return state.motor?.step1?.issuance ?? null;
  }

  @Selector()
  public static productName(state: SatMotorModel) {
    return state.motor.step1?.issuance?.issuance?.productCode;
  }

  @Selector()
  public static endtVehiclePolicy(state: SatMotorModel) {
    return state.endorsement?.step1?.endtVehicleData ?? null;
  }

  @Selector()
  public static endtPreviousVehiclePolicy(state: SatMotorModel) {
    return state.endorsement?.step1?.endtPreviousVehicleData ?? null;
  }

  @Selector()
  public static endtStep1Issuance(state: SatMotorModel) {
    return state.endorsement?.step1?.issuance ?? null;
  }
  ///////////////////////////////////////////////////for endorsement-details
  @Selector()
  public static endtStep2EndorsementDetails(state: SatMotorModel) {
    return state.endorsement?.step2?.endorsementDetails ?? null;
  }

  @Selector()
  public static endtStep2VehicleDetails(state: SatMotorModel) {
    return state.endorsement?.step2?.endorsementDetails?.vehicleDetails ?? null;
  }
  //////////////////////////////////////////////////////////////
  @Selector()
  public static motorAgentAdditionalDetail(state: SatMotorModel) {
    return state.motor?.step1?.agentAdditional ?? null;
  }

  @Selector()
  public static motorAgentDetail(state: SatMotorModel) {
    return state.motor?.step1?.agentDetail ?? null;
  }

  @Selector()
  public static motorStep3Coverage(state: SatMotorModel) {
    return state.motor?.step3 ?? null;
  }

  @Selector()
  public static motorStep3Endorsement(state: SatMotorModel) {
    return state.motor?.step3 ?? null;
  }

  @Selector()
  public static motorStep4Summary(state: SatMotorModel) {
    return state.motor?.step4 ?? null;
  }

  @Selector()
  public static rtpStep2Summary(state: SatMotorModel) {
    return state.rtp?.step2 ?? null;
  }

  @Selector()
  public static isRenewNow(state: SatMotorModel) {
    let renewNowObj = state.data.isRenewNow;
    return renewNowObj.isRenewNow ? renewNowObj.isRenewNow : false;
  }

  @Selector()
  public static dataSearch2(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.search : null;
  }

  @Selector()
  public static dataStore2(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.store : null;
  }

  @Selector()
  public static showSearchComponent(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.showSearchComponent : false;
  }

  @Selector()
  public static cpType(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.cpType : null;
  }

  @Selector()
  public static currentTypeOfCoverage(state: SatMotorModel) {
    return state.motor.step3.coverage.coverageType ? state.motor.step3.coverage.coverageType : null;
  }

  @Selector()
  public static coveragePremium(state: SatMotorModel) {
    return state.motor.step3.coverage.coveragePremium ? state.motor.step3.coverage.coveragePremium : null;
  }

  @Selector()
  public static premiumCalculationSummary(state: SatMotorModel) {
    return state.premiumCalculation ? state.premiumCalculation : null;
  }

  @Selector()
  public static customerPartner(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2 : null;
  }

  @Selector()
  public static paymentList(state: SatMotorModel) {
    return state.data.step4 ? state.data.step4.paymentList : null;
  }

  @Selector()
  public static jpjStatusSent(state: SatMotorModel) {
    return state.jpjStatusSent;
  }

  @Selector()
  public static getEndorsementStep1(state: SatMotorModel) {
    return state.endorsement?.step1 ?? null;
  }

  @Selector()
  public static mandatoryDocumentList(state: SatMotorModel) {
    return state.lov.documentList.mandatoryDocumentList ? state.lov.documentList.mandatoryDocumentList : [];
  }

  @Selector()
  public static optionalDocumentList(state: SatMotorModel) {
    return state.lov.documentList.optionalDocumentList ? state.lov.documentList.optionalDocumentList : [];
  }

  @Selector()
  public static cartNavigation(state: SatMotorModel) {
    return state.cartNavigation;
  }

  @Selector()
  public static getUploadDocumentInfo(state: SatMotorModel) {
    return state.uploadDocument;
  }

  @Selector()
  public static coverageDefaultDriver(state: SatMotorModel) {
    return state.motor.step3.coverage.coveragePremium?.cngeNote?.riskList[0]?.cngeRiskDrvrDtoList[0];
  }

  @Selector()
  public static viewQuotationSummary(state: SatMotorModel) {
    return state.viewQuotation ? state.viewQuotation : null;
  }

  @Action(RESET_SAT_MOTOR_STATE)
  public resetState(state: StateContext<SatMotorModel>) {
    state.patchState({ ...defaultSatMotorState });
  }

  @Action(SET_CURRENT_STEP)
  public setCurrentStep({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      currentStep: payload,
    });
  }

  @Action(STORE_AGENT_CODE)
  public setAgentInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      agentInfo: payload,
    });
  }

  @Action(SET_ACTION)
  public setAction({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      actionType: payload,
    });
  }

  @Action(STORE_DECLARATION)
  public setDeclarationInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();


    if (payload.isRTP) {
      const step1 = state.rtp?.step1 || {};
      patchState({
        rtp: {
          ...state.rtp,
          step1: {
            ...step1,
            issuance: payload,
          },
        },
      });
    } else if (payload.isEndorsement) {
      const step1 = state.endorsement?.step1 || {};
      patchState({
        endorsement: {
          ...state.endorsement,
          step1: {
            ...step1,
            issuance: payload,
          },
        },
      });
    } else {
      const step1 = state.motor?.step1 || {};
      patchState({
        motor: {
          ...state.motor,
          step1: {
            ...step1,
            issuance: payload,
          },
        },
      });
    }

  }

  @Action(STORE_AGENT_ADDITIONAL)
  public setAgentAdditionalInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();
    const step1 = state.motor?.step1 || {};

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...step1,
          agentAdditional: payload,
        },
      },
    });
  }

  @Action(STORE_ISSUANCE_SUMMARY)
  public setIssuanceSummary({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step4: {
          ...(state.motor?.step4 || {}),
          summary: payload,
        },
      },
    });
  }
  ///////////////////////////////////////////////////////
  @Action(STORE_ENDORSEMENT)
  public setEndorsementDetails({ patchState, getState }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();
    const step2 = state.endorsement?.step2 || {};
    const endorsementDetails = { ...step2.endorsementDetails };

    if (payload.isVehicleDetails) {
      endorsementDetails.vehicleDetails = payload.vehicleDetails;
    };

    if (payload.isExtraCover) {
      endorsementDetails.extraCover = payload.extraCover;
    };

    if (payload.isVehicleSumInsured) {
      endorsementDetails.vehicleSumInsured = payload.vehicleSumInsured;
    };

    if (payload.isNCD) {
      endorsementDetails.ncd = payload.ncd;
    };

    if (payload.isNamedDriver) {
      endorsementDetails.namedDriver = payload.namedDriver;
    };

    if (payload.isEInvoiceCustomerPartner) {
      endorsementDetails.eInvoiceCustomerPartner = payload.eInvoiceCustomerPartner;
    };

    patchState({
      endorsement: {
        ...state.endorsement,
        step2: {
          ...step2,
          endorsementDetails
        }
      }
    });
  }
  ///////////////////////////////////////////////

  @Action(GET_DOCUMENT_LOV)
  public getDocumentLov({ getState, patchState }: StateContext<SatMotorModel>, { payload }: GET_DOCUMENT_LOV) {
    const state = getState();
    return this.satMotorService.getDocumentLov(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            lov: {
              ...state.lov,
              documentList: {
                mandatoryDocumentList: res.data && res.data.mandatoryDocumentList ? res.data.mandatoryDocumentList : [],
                optionalDocumentList: res.data && res.data.optionalDocumentList ? res.data.optionalDocumentList : [],
                fileTypeList: res.data && res.data.fileTypeList ? res.data.fileTypeList : [],
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_AGENT_LIST_AGENT)
  public getAgentListAgent({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_AGENT_LIST_AGENT) {
    const state = getState();
    const checkSingleAgent = (list) => {
      if (list && list.length === 1) {
        dispatch(new CHECK_SINGLE_AGENT(true));
      } else {
        dispatch(new CHECK_SINGLE_AGENT(false));
      }
    };

    return this.satMotorService.fetchAgentListAgent(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              step1: {
                ...(state.data?.step1 || {}),
                ...res.data,
              },
            },
          });
          checkSingleAgent(res.data.agentList);
        }
        // else {
        //   this.httpHelperService.getDirectUrl(`/assets/data-mock/agent-list-mock.json`).subscribe((mockRes) => {
        //     patchState({
        //         data: {
        //             ...state.data,
        //             step1: mockRes.data,
        //         },
        //     });
        //     checkSingleAgent(mockRes.data.agentList);
        //   });
        // }
      }),
    );
  }

  @Action(CHECK_SINGLE_AGENT)
  public checkSingleAgent({ patchState, getState }: StateContext<SatMotorModel>, { payload }: CHECK_SINGLE_AGENT) {
    patchState({
      isSingleAgent: payload,
    });
  }

  @Action(GET_AGENT_DETAIL)
  public getAgentDetail({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_AGENT_DETAIL) {
    const state = getState();
    return this.satMotorService.getAgentDetail(payload).pipe(
      map((res) => {
        if (res && res.data) {
          if (res.data.bancaAgent) {
            const state = getState();
            patchState({
              data: {
                ...state.data,
                agentDetail: res.data,
              },
            });
          }

          if (!res.data.bancaAgent) {
            const data = {
              name: res.data.name,
              branchCode: res.data.branchCode,
              agentCode: res.data.code,
              code: res.data.code,
              channel: res.data.channel,
              subChannel: res.data.subChannel,
              email: res.data.agentEmail,
              mobCode: res.data.agentMobileCode1,
              mobNo: res.data.agentMobileNo1,
            };
            /*
            dispatch(new STORE_STEP1(data));
            if (state.data.headerEntryPoint === 'NW') {
                dispatch(new CHANGE_CURRENT_STEP(1))
                this.ngZone.run(() => this.router.navigate(["/new-equote/customer-partner"]));
            } else if (state.data.headerEntryPoint === 'RN') {
                dispatch(new CHANGE_CURRENT_STEP(1))
                this.ngZone.run(() => this.router.navigate(["/new-equote/search-policy"]));
            } else {
                dispatch(new CHANGE_CURRENT_STEP(1))
                this.ngZone.run(() => this.router.navigate(["/new-equote/customer-partner"]));
            }
            */
          }
        }
      }),
    );
  }

  @Action(STORE_STEP2_SEARCH)
  public storeStep2Search({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_SEARCH) {
    const state = getState();
    console.warn("state: ", state.data);
    return of(payload).pipe(
      map((step) => {
        patchState({
          data: {
            ...state.data,
            step2: {
              ...state.data.step2,
              search: payload,
            },
          },
        });
      }),
    );
  }

  @Action(SHOW_SEARCH_COMPONENT_2)
  public setStatusSearch({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SHOW_SEARCH_COMPONENT_2) {
    const state = getState();
    console.warn(payload);
    patchState({
      data: {
        ...state.data,
        step2: {
          ...(state.data?.step2 ? state.data.step2 : null),
          showSearchComponent: payload,
        },
      },
    });
  }

  @Action(EDIT_CUSTOMER_PARTNER)
  public editCustomerPartner({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: EDIT_CUSTOMER_PARTNER) {
    const state = getState();
    return this.satMotorService.saveCustomerPartner(payload).pipe(
      map((res) => {
        if (res.data) {
          const state = getState();
          dispatch(new STORE_STEP2(res.data));
          patchState({
            isCPUpdated: true,
          });
        } else {
          patchState({
            isCPUpdated: false,
          });
        }
      }),
    );
  }

  @Action(GET_AGENT_DETAIL_ONLY)
  public getAgentDetailOnly({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_AGENT_DETAIL) {
    const state = getState();
    return this.satMotorService.getAgentDetail(payload).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          const step1 = state.motor?.step1 || {};

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                agentDetail: res.data,
              },
            },
          });
        }
        // else {
        //   this.httpHelperService.getDirectUrl(`/assets/data-mock/agent-detail-mock.json`).subscribe((mockRes) => {
        //     const state = getState()
        //     const step1 = state.motor?.step1 || {};
        //
        //     patchState({
        //       motor: {
        //         ...state.motor,
        //         step1: {
        //           ...step1,
        //           agentDetail: mockRes.data,
        //         },
        //       },
        //     });
        //   });
        // }
      }),
    );
  }

  @Action(RESET_AGENT_LIST)
  public resetAgentList({ patchState, getState }: StateContext<SatMotorModel>): void {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step1: {
          ...state.data?.step1,
          agentList: [],
        },
      },
    });
  }

  @Action(GET_BRANCH_LIST)
  public getBranchList({ patchState, getState }: StateContext<SatMotorModel>, { userId }: GET_BRANCH_LIST) {
    return this.satMotorService.getBranchList(userId).pipe(
      map((res) => {
        const state = getState();
        patchState({
          data: {
            ...state.data,
            branchList: res.data.userBranchList,
            step1: {
              agentList: [],
            },
          },
        });
      }),
    );
  }

  @Action(GET_AGENT_LIST_BRANCH)
  public getAgentListByBranch({ patchState, getState }: StateContext<SatMotorModel>, { operator, branchCode }: GET_AGENT_LIST_BRANCH) {
    return this.satMotorService.getAgentListByBranch(operator, branchCode).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          patchState({
            data: {
              ...state.data,
              step1: res.data,
            },
          });
        }
      }),
    );
  }

  @Action(GET_REDIRECT_PAGE)
  public getRedirectPage({ patchState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_REDIRECT_PAGE) {
    return this.satMotorService.getRedirectPage(payload).pipe(
      map((res) => {
        if (res) {
          window.open(res.url, "_self");
        }
      }),
    );
  }

  @Action(GET_SUBMENU_REDIRECTURL)
  public getSubMenuRedirectUrl({ patchState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_SUBMENU_REDIRECTURL) {
    const action = ["ISSUE", "REPORTS", "ENQUIRY", "SUMMARY", "CI", "SAT_MT_COVER_NOTE"];
    if (action.includes(payload)) {
      const redirectPayload = {
        operator: this.store.selectSnapshot<string>((state) => state.UserState.user.userId),
        action: payload,
      };
      this.store.dispatch(new GET_REDIRECT_PAGE(redirectPayload));
      return true;
    } else {
      return this.satMotorService.getDirectUrlFromSubMenu(payload).pipe(
        map((res) => {
          if (res.redirectUrl) {
            window.location.href = res.redirectUrl;
          }
        }),
      );
    }
  }

  @Action(GET_NCD)
  public getNCD({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_NCD) {
    const state = getState();

    return this.satMotorService.getNCD(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            data: {
              ...state.data,
              step3: {
                ...state.data.step3,
                coverage: {
                  ...state.data.step3.coverage,
                  coveragePremium: res.cngeNote,
                  coverageNCD: res,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_CUSTOMER_PARTNER)
  public getCustomerDetail({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_CUSTOMER_PARTNER) {
    const state = getState();

    return this.satMotorService.getCustomerDetail(payload).pipe(
      map((res) => {
        if (res.data) {
          dispatch(new STORE_STEP2(res.data));
        } else {
          dispatch(new STORE_STEP2(null));
        }
      }),
    );
  }

  @Action(STORE_STEP2)
  public storeStep2({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2) {
    console.warn("store step 2 payload", payload);
    const state = getState();

    if (payload) {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...(state.data.step2 ? state.data.step2 : null),
            store: {
              ...payload,
              clientPartnerAddress: {
                ...(state.data.step2.store?.clientPartnerAddress ? state.data.step2.store.clientPartnerAddress : {}),
                ...payload.clientPartnerAddress,
              },
            },
          },
        },
        motor: {
          ...state.motor,
          step2: {
            ...(state?.motor?.step2 ? state.motor.step2 : null),
            ...payload,
            clientPartnerAddress: {
              ...(state?.motor?.step2 ? state.motor.step2.clientPartnerAddress : {}),
              ...payload.clientPartnerAddress,
            },
          },
        },
      });
    } else {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...state.data.step2,
            store: null,
          },
        },
        motor: {
          ...state.motor,
          step2: null,
        },
      });
    }
  }

  @Action(STORE_STEP2_RENEWAL)
  public storeStep2Renewal({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_RENEWAL) {
    console.warn("store step 2 payload", payload);
    const state = getState();

    if (payload) {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...state.data.step2,
            store: {
              ...state.data.step2?.store,
              ...payload,
              clientPartnerAddress: {
                ...state.data.step2?.store?.clientPartnerAddress,
                ...payload.clientPartnerAddress,
              },
            },
          },
        },
        motor: {
          ...state.motor,
          step2: {
            ...state.motor.step2,
            ...payload,
            clientPartnerAddress: {
              ...state.motor.step2?.clientPartnerAddress,
              ...payload.clientPartnerAddress,
            },
          },
        },
      });

    } else {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...state.data.step2,
            store: null,
          },
        },
        motor: {
          ...state.motor,
          step2: null,
        },
      });
    }
  }

  @Action(STORE_STEP2_CP_SCREEN)
  public storeStep2CPScreen({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_CP_SCREEN) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step2: {
          ...state.data.step2,
          cpType: payload.cpScreen,
          isCompOrCustInfoIncomplete: payload.isCompOrCustInfoIncomplete,
          isContactInfoIncomplete: payload.isContactInfoIncomplete,
          isCorrespondenceInfoIncomplete: payload.isContactInfoIncomplete,
        },
      },
    });
  }

  @Action(VALIDATE_CP)
  public validateCP({ patchState, getState }: StateContext<SatMotorModel>, { payload }: VALIDATE_CP) {
    const state = getState();

    return this.satMotorService.validateCP(payload).pipe(
      map((res) => {
        if (res && !res.error) {
          if (res.data.idType1 && res.data.idValue1) {
            patchState({
              data: {
                ...state.data,
                step2: {
                  ...(state.data.step2 ? state.data.step2 : null),
                  isValidIdValue1: true,
                },
              },
            });
          } else if (res.data.idType2 && res.data.idValue2) {
            patchState({
              data: {
                ...state.data,
                step2: {
                  ...(state.data.step2 ? state.data.step2 : null),
                  isValidIdValue2: true,
                },
              },
            });
          }
        } else {
          patchState({
            data: {
              ...state.data,
              step2: {
                ...(state.data.step2 ? state.data.step2 : null),
                isValidIdValue1: false,
                isValidIdValue2: false,
              },
            },
          });
        }
      }),
    );
  }

  @Action(SEARCH_MAKE_MODEL)
  public searchMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_MAKE_MODEL) {
    const state = getState();
    return this.satMotorService.getMakeOrModel(payload).pipe(
      map((res) => {
        let searchMakeModelList;
        if (res && res.data) {
          searchMakeModelList = res.data;
        } else {
          searchMakeModelList = [];
        }
        const state = getState();
        patchState({
          data: {
            ...state.data,
            step3: {
              ...state.data.step3,
              coverage: {
                ...state.data.step3.coverage,
                searchMakeModelList: searchMakeModelList,
              },
            },
          },
        });

      }),
    );
  }

  @Action(SEARCH_ENDORSEMENT_MAKE_MODEL)
  public searchEndtMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_ENDORSEMENT_MAKE_MODEL) {
    const state = getState();
    // console.log("SEARCH_ENDORSEMENT_MAKE_MODEL payload:", payload);

    return this.satMotorService.getMakeOrModel(payload).pipe(
      // 
      map((res) => {
        // console.log("SEARCH_ENDORSEMENT_MAKE_MODEL res:", res);

        let searchMakeModelList;
        if (res && res.data) {
          searchMakeModelList = res.data;
        } else {
          searchMakeModelList = [];
        }

        // console.log("SEARCH_ENDORSEMENT_MAKE_MODEL searchMakeModelList:", searchMakeModelList);

        const state = getState();
        patchState({
          data: {
            ...state.data,
            step2: {
              ...state.data.step2,
              endorsementDetails: {
                ...state.data.step2.endorsementDetails,
                searchMakeModelList: searchMakeModelList,
              },
            },
          },
        });
      }),
    );
  }

  @Action(SELECTED_MAKE_MODEL)
  public selectedMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SELECTED_MAKE_MODEL) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3.coverage,
            selectedMakeModel: payload,
          },
        },
      },
    });
  }

  @Action(SELECTED_ENDORSEMENT_MAKE_MODEL)
  public selectedEndorsementMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SELECTED_ENDORSEMENT_MAKE_MODEL) {
    const state = getState();
    console.log("SELECTED_ENDORSEMENT_MAKE_MODEL payload:", payload);

    patchState({
      data: {
        ...state.data,
        step2: {
          ...state.data.step2,
          endorsementDetails: {
            ...state.data.step2.endorsementDetails,
            selectedMakeModel: payload,
          },
        },
      },
    });
  }

  @Action(POLICY_MOVEMENT_DATA)
  public policyMovemementData({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: POLICY_MOVEMENT_DATA) {

    // console.log("state policyMovemementData");

    const state = getState();
    return this.satMotorService.postPolicyMovemementTableData(payload).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          patchState({
            data: {
              ...state.data,
              step2: {
                ...state.data.step2,
                endorsementDetails: {
                  ...state.data.step2.endorsementDetails,
                  policyMovemementData: res.data ? res.data.endorsementDetList : null,
                },
              },
            },
          });
          // console.log("res.data.endorsementDetList =", res.data.endorsementDetList);
        }
      }),
    );
  }

  @Action(GET_LOV)
  public getLOV({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();
    return this.satMotorService.fetchLOV().pipe(
      map((res) => {
        patchState({
          lov: {
            ...state.lov,
            ...res.data
          },
        });
      }),
    );
  }

  @Action(GET_PRODUCT_LIST)
  public getProductListLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PRODUCT_LIST) {
    const state = getState();
    return this.satMotorService.getProductListLov(payload).pipe(
      map((res) => {
        if (res && res.productAccessConfigList) {
          const state = getState();
          patchState({
            lov: {
              ...state.lov,
              ...res,
              productList: this.filterProductList(res.productAccessConfigList).productList,
              endorsementList: this.filterProductList(res.productAccessConfigList).endorsementList
            },
          });
        }
      }),
    );
  }

  filterProductList(productAccessConfigList: any): any {
    let productList = [];
    let endorsementList = [];
    let endorsementListExtracted = false;

    productAccessConfigList.forEach((product) => {
      for (let code in product) {
        if (product.hasOwnProperty(code)) {
          productList.push({
            code: code,
            descp: product[code].productDescp,
          });

          if (!endorsementListExtracted && product[code].cnoteSatEndtTypeList) {
            product[code].cnoteSatEndtTypeList.forEach((endorsement) => {
              endorsementList.push({
                code: endorsement.value,
                descp: endorsement.attributeDescp
              });
            });
            endorsementListExtracted = true;
          }
        }
      }
    });

    productList.sort((a, b) => a.code.localeCompare(b.code));

    return { productList, endorsementList };
  }

  @Action(GET_COVER_LIST_LOV)
  public getCoverListLov({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_COVER_LIST_LOV) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3?.coverage,
            ...payload,
          },
        },
      },
    });
  }

  @Action(GET_PRODUCT_CONFIG_LOV)
  public getProductConfigLov({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_PRODUCT_CONFIG_LOV) {
    const state = getState();
    return this.satMotorService.getProductConfigLov({ ...payload, partnerId: 3881892, partnerVersionNo: 1 }).pipe(
      map((res) => {
        if (res) {
          let coverListLov = res.productAccessConfigList.find((c: { productCode: any }) => (c.productCode = payload.productCode));
          patchState({
            productConfigLov: coverListLov,
            data: {
              ...state.data,
              step3: {
                ...state.data.step3,
                ...res.productAccessConfigList,
                coverage: {
                  ...state.data.step3?.coverage,
                  coverListLov: coverListLov[payload.productCode].coverList,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_VEHICLE_USE)
  public getVehicleUseLov({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_VEHICLE_USE) {
    const state = getState();
    return this.satMotorService.getVehicleUseLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          if (payload.isRTP) {
            const step2 = state.rtp?.step2 || {};
            patchState({
              rtp: {
                ...state.rtp,
                step2: {
                  ...step2,
                  vehicleUseLov: res.data
                },
              },
            });

          } else if (payload.isEndorsement) {
            patchState({
              data: {
                ...state.data,
                step2: {
                  ...state.data.step2,
                  endorsementDetails: {
                    ...state.data.step2.endorsementDetails,
                    vehicleUseLov: res.data,
                  },
                },
              },
            });
          } else if (payload.isPolicyEnquiry) {
            patchState({
              data: {
                ...state.viewPolicyData,
                vehicleUseLov: res.data,
              },
            });
          } else {
            patchState({
              vehicleUseLov: res.data,
              data: {
                ...state.data,
                step3: {
                  ...state.data.step3,
                  coverage: {
                    ...state.data.step3.coverage,
                    vehicleUseLov: res.data,
                  },
                },
              },
            });
          }
        }
      }),
    );
  }

  @Action(INIT_STEP3)
  public initStep3({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: INIT_STEP3) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
        },
      },
    });
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
        },
      },
    });
  }

  @Action(STORE_STEP3)
  public storeStep3({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP3) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
        },
      },
    });

    let riskList = payload.riskList[0];

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coveragePremium: {
              ...state.motor.step3.coverage.coveragePremium,
              cngeNote: payload,
            },
            coverageType: {
              ...state.motor.step3.coverage.coverageType,
              ...payload.riskList[0].coverList,
            },
            extracover: {
              ...state.motor.step3.coverage.extracover,
              driverNameDetails: {
                ...state.motor.step3.coverage.extracover.driverNameDetails,
                rows: payload.riskList[0].cngeRiskDrvrDtoList,
              },
              ehailingDriverDetails: {
                ...state.motor.step3.coverage.extracover.ehailingDriverDetails,
              },
              extraCoverPackage: {
                ...state.motor.step3.coverage.extracover.extraCoverPackage,
              },
              extraCoverPackageSummary: {
                ...state.motor.step3.coverage.extracover.extraCoverPackageSummary,
              },
            },
            vehicleDetails: {
              ...state.motor.step3.coverage.vehicleDetails,
              ...riskList[0],
              region: state.motor.step1.agentDetail.region
            },
          },
        },
      },
    });
  }

  @Action(STORE_STEP3_RENEWAL)
  public storeStep3Renewal({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP3) {
    const state = getState();

    let extraCoverPackage = [];
    let subCoverList = payload.riskList[0].coverList[0].subCoverList;
    for (let cv of subCoverList) {
      extraCoverPackage.push({
        ...cv,
        description: cv.subCoverCodeDescription,
        selected: cv.selectedInd,
        enabled: cv.enableInd !== undefined ? cv.enableInd : false,
        contractId: null,
        riskId: null,
        subcoverId: null,
        coverId: null,
      });
    }

    let removeSumInsured = [
      "M01", "M02", "M03", "M07", "M08", "22", "22(a)", "24(c)", "24(d)", "25",
      "72", "100", "101", "101(a)", "102", "105", "108", "109", "111",
      "A200", "A201", "A202", "A202-1", "A202-2", "A203", "A203-1", "A203-2", "A203-3"
      , "A207", "PAB-OCR",
    ]

    let selectedExtraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd && o1.subCoverPrem > 0);
    let updateSumInsuredExtraCoverList = selectedExtraCoverList.map((obj) => {
      if (removeSumInsured.includes(obj.subCoverCode)) {
        return { ...obj, subCoverSumInsured: 0 };
      }
      return obj;
    });

    let rahmahExtraCoverList = extraCoverPackage.filter(o1 => o1.subCovPackage && o1.enableInd == false && o1.subCoverPrem == 0);
    let topExtraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.enableInd === false && o1.subCoverPrem == 0 && !o1.subCovPackage);
    //let extraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.subCoverPrem > 0);

    let extraCoverInSummary = {
      subCovPackageDesc: payload.riskList[0].coverList[0].subCovPackageDesc,
      rahmahPackageList: rahmahExtraCoverList,
      topExtraCoverList: topExtraCoverList,
      selectedExtraCoverList: updateSumInsuredExtraCoverList
    }

    let riskList = payload.riskList[0];
    let selectedMakeModel = "";
    if (riskList) {
      selectedMakeModel = {
        ...payload.riskList[0],
        vehModelCodeNvic: payload.riskList[0].vehModelNvic,
        nvicCode: riskList.nvicNo,
        //avCode: avCode,
        //classCode: "02",
        //piamMakeCode: "105",
        //piamModelCode: "06",
        piamMakeModelDesc: riskList.makeModelDesc,
        makeModelDesc: riskList.makeModelDesc,
        piamMakeCodeDesc: riskList.makeModelDesc,
        //piamModelCodeDesc: "ERA KOMUTER",
        variant: null,
        series: null,
        //transmission: "5 SP MANUAL",
        //variantFullDesc: "3D WINDOW VAN 5 SP MANUAL 2498CC",
        yearMnftr: riskList.vehicleMakeYear,
        capacity: riskList.vehicleCapacity,
        uom: riskList.vehicleUom,
        //importStatus: "LOCALLY BUILT",
        seat: riskList.vehicleSeat,
        // body: "62",
        avEmSiMin: riskList.avEmSiMin ? riskList.avEmSiMin : 0,
        avEmSiMax: riskList.avEmSiMax ? riskList.avEmSiMax : 0,
        avWmSiMin: riskList.avWmSiMin ? riskList.avWmSiMin : 0,
        avWmSiMax: riskList.avWmSiMax ? riskList.avWmSiMax : 0,
        inactiveSiInd: riskList.makeModelInactiveSiInd,
        inactiveInd: riskList.makeModelInactiveInd,
      }
    }

    let makeModelDesc = payload.riskList[0].makeModelDesc ? payload.riskList[0].makeModelDesc : (payload.riskList[0].vehicleModelDescp ? payload.riskList[0].vehicleModelDescp : "");

    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3?.coverage,
            selectedMakeModel,
          },
        },
      },
    });

    //let riskList = payload.riskList[0];



    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            coveragePremium: {
              cngeNote:
              {
                ...payload,
                coverId: null,
                contractId: null,
                riskId: null,
              }
            },
            coverageType: {
              ...payload.riskList[0].coverList[0],
              premiumDue: payload.premiumDue,
              subCoverList: [
                ...extraCoverPackage
              ],
              coverId: null,
              contractId: null,
              riskId: null,
            },
            extracover: {
              driverNameDetails: {
                rows: payload.riskList[0].cngeRiskDrvrDtoList,
              },
              ehailingDriverDetails: {
              },
              extraCoverPackageSummary: extraCoverInSummary
            },
            vehicleDetails: {
              ...payload.riskList[0],
              region: state.motor.step1.agentDetail.region,
              makeModel: payload.riskList[0].vehicleModel,
              engineType: payload.riskList[0].vehicleEngine,
              vehicleUom: payload.riskList[0].vehicleUom,
              makeModelDesc: makeModelDesc,
              contractId: null,
              riskId: null,
              coverList: [
                {
                  ...payload.riskList[0].coverList[0],
                  premiumDue: payload.premiumDue,
                  subCoverList: [
                    ...extraCoverPackage
                  ],
                  coverId: null,
                  contractId: null,
                  riskId: null,
                }
              ],


            },
          },
        },
      },
    });
  }

  @Action(STORE_EXTRA_COVER_DETAILS)
  public storeExtraCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_EXTRA_COVER_DETAILS) {
    const state = getState();

    if (payload.cnoteType === 'ENDT') {
      // Ensure that riskList and coverList are valid before accessing them
      const riskList = payload.riskList || [];
      const coverList = riskList.length > 0 ? riskList[0].coverList || [] : [];

      // Only proceed if coverList has at least one item
      if (coverList.length > 0) {
        let extraCoverPackageSummary = this.getExtraCoverage(coverList[0]);

        patchState({
          motor: {
            ...state.motor,
            step3: {
              coverage: {
                extracover: extraCoverPackageSummary,
              },
            },
          },
        });
      }
    } else {
      patchState({
        motor: {
          ...state.motor,
          step3: {
            ...state.motor.step3,
            coverage: {
              ...state.motor.step3.coverage,
              extracover: payload,
            },
          },
        },
      });
    }
  }   

  @Action(DELETE_EXTRA_COVER_DETAILS)
  public deleteExtraCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_EXTRA_COVER_DETAILS) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            extracover: null,
          },
        },
      },
    });
  }

  @Action(ADD_NEW_COVERAGE)
  public addNewCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: ADD_NEW_COVERAGE) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coverageType: payload,
          },
        },
      },
    });
  }

  @Action(DELETE_COVERAGE)
  public deleteCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: DELETE_COVERAGE) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coveragePremium: null,
            coverageType: null,
          },
        },
      },
    });
  }

  @Action(STORE_COVERAGE_VEHICLE_DETAILS)
  public storeVehicleDetails({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_COVERAGE_VEHICLE_DETAILS) {
    const state = getState();

    let vehicleDetails;
    if (state.motor.step3.coverage?.vehicleDetails) {
      vehicleDetails = {
        vehicleDetails: { ...state.motor.step3.coverage?.vehicleDetails, ...payload }
      }
    }
    else {
      vehicleDetails = { vehicleDetails: payload };
    }

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            ...vehicleDetails
          },
        },
      },
    });
  }

  @Action(STORE_COVERAGE_TYPE)
  public storeTypeOfCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_COVERAGE_TYPE) {
    const state = getState();

    let coverageType;
    if (state.motor.step3.coverage?.coverageType) {
      coverageType = {
        coverageType: { ...state.motor.step3.coverage?.coverageType, ...payload }
      }
    }
    else {
      coverageType = { coverageType: payload };
    }

    let patch = {
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            ...coverageType,
          },
        },
      },
    }

    patchState(patch);
  }

  @Action(UPDATE_COVERAGE_TYPE)
  public updateTypeOfCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: UPDATE_COVERAGE_TYPE) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coverageType: {
              ...state.motor.step3.coverage.coverageType,
              ...payload
            }
          },
        },
      },
    });
  }

  @Action(CALCULATE_PREMIUM_PAYLOAD)
  public calculatePremiumPayload({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_PREMIUM_PAYLOAD) {
    const state = getState();
    return this.satMotorService.calculatePremiumPayload(payload).pipe(
      map((res) => {
        if (res) {
          let calculatePremiumPayload = {
            cngeNote: {
              alimEligibleInd: payload.coverage.alimEligibleInd,
              mtRoadPlacementTblInd: payload.coverage.mtRoadPlacementTblInd,
              mtRoadEligibleInd: payload.coverage.mtRoadEligibleInd,
              lineOfBusiness: "MT",
              ...state.motor.step1.issuance.issuance,
              ...state.motor.step1.issuance.pdpa.pdpaCheck,
              ...state.motor.step1.issuance.pdpa.consentDetails,
              clientPartner: {
                ...state.motor.step2,
              },
              riskList: [
                {
                  riskType: "VEH",
                  ...payload.coverage.vehicleDetails,
                  cngeRiskDrvrDtoList: payload.coverage.cngeRiskDrvrDtoList,
                  coverList: [
                    {
                      ...payload.coverage.typeOfCoverage,
                      subCoverList: payload.coverage.extracover,
                      coverDto: payload.coverage.coverDto,
                      vehicleUnNamedDrvrInd: payload.coverage.vehicleUnNamedDrvrInd,
                      vehicleAlldrvrInd: payload.coverage.vehicleAlldrvrInd,
                    },
                  ],
                },
              ],
              mkagAgent: {
                ...state.motor.step1.agentDetail,
              },
            },
            operator: payload.operator,
          };

          calculatePremiumPayload.cngeNote.effectiveDate = calculatePremiumPayload.cngeNote.effectiveDate === "NA" ? null : calculatePremiumPayload.cngeNote.effectiveDate;
          calculatePremiumPayload.cngeNote.expiryDate = calculatePremiumPayload.cngeNote.expiryDate === "NA" ? null : calculatePremiumPayload.cngeNote.expiryDate;
          calculatePremiumPayload.cngeNote.region = calculatePremiumPayload.cngeNote.riskList[0].region;
          calculatePremiumPayload.cngeNote.alimCustomerIndi = calculatePremiumPayload.cngeNote.clientPartner.alimCustomerIndicator === "YES";


          patchState({
            data: {
              ...state.data,
              step3: {
                ...state.data.step3,
                coverage: {
                  ...state.data.step3.coverage,
                  coveragePremiumPayload: calculatePremiumPayload,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(CALCULATE_PREMIUM)
  public calculatePremium({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_PREMIUM) {
    const state = getState();
    return this.satMotorService.calculatePremium(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            motor: {
              ...state.motor,
              step3: {
                ...state.motor.step3,
                coverage: {
                  ...state.motor.step3.coverage,
                  coveragePremium: res,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(CALCULATE_ENDT_PREMIUM)
  public calculateEndtPremium({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_ENDT_PREMIUM) {
    const state = getState();
    return this.satMotorService.calculateEndtPremium(payload).pipe(
      map((res) => {
        if (res /*&& res.data*/) {//res.cngeNote.riskList[0].coverList[0].sumInsured = 100001;
          patchState({
            endorsement: {
              ...state.endorsement,
              step2: {
                ...state.endorsement.step2,
                endorsementDetails: {
                  ...state.endorsement.step2.endorsementDetails,
                  coveragePremium: res,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(CALCULATE_PREMIUM_SUMMARY)
  public calculatePremiumSummary({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_PREMIUM_SUMMARY) {
    const state = getState();
    return this.satMotorService.calculatePremium(payload).pipe(
      map((res) => {
        if (res && res.cngeNote) {
          let stx: any, sty: any, stz: any;
          let premiumInfo: any;
          if ((stx = res['cngeNote']) && (sty = stx['riskList']) && sty.length > 0 && (stz = sty[0]['coverList']) && stz.length > 0) {
            premiumInfo = stz[0];
          }
          patchState({ premiumCalculation: premiumInfo });
        }
      }),
    );
  }

  @Action(GET_CUSTOMER_PARTNER_MOCK)
  public getCustomerPartnerInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_CUSTOMER_PARTNER_MOCK) {
    const state = getState();
    return this.satMotorService.getCPData(payload).pipe(
      map((res) => {
        if (res && res.data) {
          if (res.data) {
            dispatch(new STORE_STEP2(res.data));
          }
        }
      }),
    );
  }

  @Action(GENERATE_QUOTATION)
  public generateQuotation({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload, cnoteType }: GENERATE_QUOTATION) {
    const state = getState();
    let data = "";
    const geoIndValue = state.geoCodedInd;

    if (cnoteType && cnoteType == 'RN') {
      data = this.prepareCoverNoteRequestPayloadRenewal(state, payload, false, false, geoIndValue); //need to check if endorsement needed
    } else {
      data = this.prepareCoverNoteRequestPayload(state, payload, false, false, geoIndValue);
    }

    return this.satMotorService.generateQuotation(data).pipe(
      map((res) => {
        if (res && res.data) {
          
          if (payload.isEndorsement) {
            patchState({
              endorsement: {
                ...state.endorsement,
                step3: {
                  ...(state.endorsement?.step3 || {}),
                  generateQuotation: res,
                },
              },
            });
          } else {
            patchState({
              motor: {
                ...state.motor,
                step4: {
                  ...(state.motor?.step4 || {}),
                  generateQuotation: res,
                },
              },
            });
          }

        }
      }),
    );
  }

  @Action(SET_JPJ_STATUS_SENT)
  public setJPJStatusSent(ctx: StateContext<SatMotorModel>, action: SET_JPJ_STATUS_SENT) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jpjStatusSent: action.payload,
    });
  }

  @Action(SET_RETRIEVE_QUOTATION)
  public setRetrieveQuotation({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SET_RETRIEVE_QUOTATION) {
    patchState({
      retrieveQuotation: payload
    });
  }

  @Action(SEND_TO_JPJ)
  public sendToJPJ({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SEND_TO_JPJ) {
    const state = getState();
    let data = "";
    const geoIndValue = state.geoCodedInd;
    if (payload.isRenewal && payload.isRenewal === 'RN') {
      data = this.prepareCoverNoteRequestPayloadRenewal(state, payload, false, payload.isRTP, geoIndValue);
    } else {
      data = this.prepareCoverNoteRequestPayload(state, payload, false, payload.isRTP, geoIndValue);
    }

    return this.satMotorService.sendToJPJ(data, payload.isRTP ? true : false, payload.isEndorsement ? true : false).pipe(
      map((res) => {
        if (res && res.data) {
          if (payload.isRTP) {
            patchState({
              rtp: {
                ...state.rtp,
                step2: {
                  ...(state.rtp?.step2 || {}),
                  sendToJPJ: res,
                },
              },
            });
          } else if (payload.isEndorsement) {
            patchState({
              endorsement: {
                ...state.endorsement,
                step3: {
                  ...(state.endorsement?.step3 || {}),
                  sendToJPJ: res,
                },
              },
            });
          } else {
            patchState({
              motor: {
                ...state.motor,
                step4: {
                  ...(state.motor?.step4 || {}),
                  sendToJPJ: res,
                },
              },
            });
          }
        }
      }),
    );
  }

  @Action(RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ)
  resetGenerateQuotationAndSendToJPJ({ patchState, getState }: StateContext<SatMotorModel>, { payload }: { payload: any }) {
    const state = getState();

    if (payload.isRTP) {
      patchState({
        rtp: {
          ...state.rtp,
          step2: {
            ...(state.rtp?.step2 || {}),
            sendToJPJ: null,
          },
        },
      });
    } else if (payload.isEndorsement) {
      patchState({
        endorsement: {
          ...state.endorsement,
          step3: {
            ...(state.endorsement?.step3 || {}),
            generateQuotation: null,
            sendToJPJ: null,
          },
        },
      });
    } else {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...(state.motor?.step4 || {}),
            generateQuotation: null,
            sendToJPJ: null,
          },
        },
      });
    }
  }

  @Action(SEARCH_VIX)
  public searchVIX({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_VIX) {
    const state = getState();
    return this.satMotorService.searchVIX(payload).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          const step1 = state.motor?.step1 || {};

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                searchVIX: res.data,
              },
            },
          });
        }
      }),
    );
  }

  @Action(ROADTAX_SEARCH)
  public searchRoadTax({ patchState, getState }: StateContext<SatMotorModel>, { payload }: ROADTAX_SEARCH) {
    const state = getState();
    return this.satMotorService.searchRoadTax(payload).pipe(
      map((res) => {
        if (res) {
          const state = getState();
          const step1 = state.rtp?.step1 || {};

          patchState({
            rtp: {
              ...state.rtp,
              step1: {
                ...step1,
                rtpPolicy: res
              },
            },
          });
        }
      }),
    );
  }

  @Action(SEARCH_ACTIVE_QUOTATION)
  public searchActiveQuotation({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_ACTIVE_QUOTATION) {
    const state = getState();
    return this.satMotorService.searchActiveQuotation(payload).pipe(
      map((res) => {
        const state = getState();
        const step1 = state.motor?.step1 || {};
        if (res && res.data) {

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                activeQuotation: res.data,
              },
            },
          });
        } else {

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                activeQuotation: null,
              },
            },
          });
        }
      }),
    );
  }

  @Action(SEARCH_ENDT_VEHICLE)
  public searchEndtVehicle({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_ENDT_VEHICLE) {
    return this.satMotorService.searchEndorsementVehicle(payload).pipe(
      map((res) => {
        const state = getState();
        const step1 = state.endorsement?.step1 || {};

        const searchPolicyData = res ? res.data : null;
        const endtVehicleData = res && res.data ? res.data.cngeNoteDto : null;
        const endtPreviousVehicleData = res && res.data ? res.data.htgePol : null;
        
        this.store.dispatch(new STORE_EXTRA_COVER_DETAILS(endtVehicleData));

        patchState({
          endorsement: {
            ...state.endorsement,
            step1: {
              ...step1,
              endtVehicleData,
              endtPreviousVehicleData,
              endtVehicleSearchError: res && res.data ? null : res,
            },
          },
        });
        // console.log("SEARCH_ENDT_VEHICLE res:", res);
        // console.log("SEARCH_ENDT_VEHICLE endtVehicleData:", endtVehicleData);
        // console.log("SEARCH_ENDT_VEHICLE endtPreviousVehicleData:", endtPreviousVehicleData);

        return res;
      })
    );
  }

  @Action(STORE_SHOWED_ONE_ALLIANZ_POP_UP)
  public storeShowedOneAllianzPopUp({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_SHOWED_ONE_ALLIANZ_POP_UP) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        hasShowedOneAllianzPopUp: payload,
      },
    });
  }

  @Action(SET_CUSTOMER_PARTNER_SEARCH_TYPE)
  public setCustomerPartnerSearchType({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SET_CUSTOMER_PARTNER_SEARCH_TYPE) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step2: {
          ...state.data.step2,
          cpType: payload,
        },
      },
    });
  }

  @Action(UPDATE_PAYMENT_LIST)
  updatePaymentList({ patchState, getState }: StateContext<SatMotorModel>, { payload }: UPDATE_PAYMENT_LIST) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step4: {
          ...state.data.step4,
          paymentList: payload,
        },
      },
    });
  }

  @Action(SAVE_REFERRAL_REMARKS)
  public saveReferralRemarks({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SAVE_REFERRAL_REMARKS) {
    const state = getState();
    //need to remove after BE complete
    // patchState({
    //   data: {
    //     ...state.data,
    //     step5: {
    //       ...state.data.step5,
    //       referRemarkList: payload.remark, //temporary use payload first
    //     },
    //   },
    // });

    // after BE complete change above code to below code
    return this.satMotorService.saveReferralRemarks(payload).pipe(
      map((res) => {
        patchState({
          data: {
            ...state.data,
            step5: {
              ...state.data.step5,
              referRemarkList: res['data'].cngeNote.referRemarkList
            }
          }
        });
      })
    );
  }

  @Action(DOWNLOAD_PDF)
  public downloadPDF({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: DOWNLOAD_PDF) {
    return this.satMotorService.downloadPDF(payload);
    // return this.satMotorService.downloadPDF(payload).pipe(
    //   map((res) => {
    //     const blob = this.satMotorService.generateBlob(res);
    //     console.log("Blob:", blob);

    //     if (blob) {
    //       this.satMotorService.downloadPDFUsingBlob(blob);
    //     }
    //   }),
    // );
  }

  @Action(GET_PREVIOUS_POLICY_NO)
  public getPreviousPolicyNo({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_PREVIOUS_POLICY_NO) {
    const state = getState();
    return this.satMotorService.getPreviousPolicyNo(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            previousPolicyDetails: res.data.cngeNoteDto,
          });
        } else {
          patchState({
            previousPolicyDetails: null,
            previousPolicyDetailsError: res
          });
        }
      }),
    );
  }

  @Action(GET_VIEW_POLICY)
  public getViewPolicy({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_VIEW_POLICY) {
    const state = getState();
    return this.satMotorService.getViewPolicy(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            viewPolicyDetails: res.data,
            viewPolicyData: this.readDataFromCngeNote(res.data)
          });
        } else {
          patchState({
            viewPolicyDetails: null,
            viewPolicyDetailsError: res
          });
        }
      }),
    );
  }
  @Action(GET_VIEW_CLAIM)
  public getViewClaim({ patchState, getState }: StateContext<SatMotorModel>, { claimNo, policyNo }: GET_VIEW_CLAIM) {
    const state = getState();
    return this.satMotorService.getViewClaim(claimNo, policyNo).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            viewClaimDetails: res.data,
            viewClaimData: this.readDataFromClaim(res.data)
          });
        } else {
          patchState({
            viewClaimDetails: null,
            viewClaimDetailsError: res
          });
        }
      }),
    );
  }

  readDataFromClaim(data: any): any {
    const { mkagAgent, clientPartner: cpData, claimNo, htclClaimDet, clientPartner,
      asAt, claimCode, claimDescription, lossDate, retainNcdInd,
      clientBusinessRegNo, clientNewNric, clientOtherId } = data;

    // Agent Data
    const agentData = {
      agentCode: mkagAgent.agentCode,
      name: mkagAgent.name,
    };

    // Declaration issuance
    const issuanceInfo = {
      vehicleNo: data.vehicleNo,
      policyNo: data.policyNo
    };

    return {
      mkagAgent,
      agentData,
      issuanceInfo,
      claimNo,
      htclClaimDet,
      clientPartner,
      asAt,
      claimCode,
      claimDescription,
      lossDate,
      retainNcdInd,
      clientBusinessRegNo,
      clientNewNric,
      clientOtherId
    };
  }

  @Action(REQUEST_CANCELLATION)
  public requestCancellation({ patchState, getState }: StateContext<SatMotorModel>, { payload }: REQUEST_CANCELLATION) {
    const state = getState();

    return this.satMotorService.requestCancellation(payload).pipe(
      map((res) => {

      }),
    );
  }

  @Action(REDIRECT_TO_MIGBASE_MOTOR)
  public searchClaimEnquiry({ patchState, getState }: StateContext<SatMotorModel>, { payload }: REDIRECT_TO_MIGBASE_MOTOR) {
    let newURL = "";
    // let  newURL = `http://localhost:63650/allianz-modular-products/enquiry`; //for local testing
    console.log("MB URL: " + newURL);
    window.open(newURL, '_self');
  }

  @Action(CANCEL_REPLACE)
  public cancelReplace({ patchState, getState }: StateContext<SatMotorModel>, { payload }: CANCEL_REPLACE) {
    const state = getState();

    return this.satMotorService.cancelReplace(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              cancellationRes: res.data
            },
          });
        }
      }),
    );
  }

  @Action(CONTINUE_ISSUANCE)
  public continueIssuance({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...state.motor.step1,
          issuance: null,
        },
        step2: null,
        step3: null,
        step4: null,
        step5: null,
      },
      data: {
        ...state.data,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
        isRenewNow: null,
        agentDetail: null,
        branchList: null,
        hasShowedOneAllianzPopUp: null,
      },
      declarationInfo: null,
    });

    //change back the step2.showSearchComponent to true to let user search the customer partner
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(true));
  }

  @Action(ISSUE_NEW)
  public issueNew({ patchState, getState }: StateContext<SatMotorModel>, { payload }: ISSUE_NEW) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step3: null,
        step4: null,
        step5: null,
      },
      data: {
        ...state.data,
        step3: null,
        step4: null,
        step5: null,
        isRenewNow: null,
        agentDetail: null,
        branchList: null,
        hasShowedOneAllianzPopUp: null,
      },
      declarationInfo: {
        ...state.declarationInfo,
        productName: payload.productCode,
      },
    });
  }

  @Action(SAVE_AS_DRAFT)
  public saveAsDraft({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SAVE_AS_DRAFT) {
    const state = getState();
    const geoIndValue = state.geoCodedInd;

    let data = this.prepareCoverNoteRequestPayload(state, payload, true, false, geoIndValue); console.log(data);

    return this.satMotorService.saveAsDraft(data).pipe(
      map((res) => {
        if (res.cngeNote) {
          this.store.dispatch(new STORE_STEP2_DRAFT_NO(res.cngeNote));
          this.store.dispatch(new STORE_STEP3_DRAFT_NO(res.cngeNote));

        }
      }),
    );
  }
  @Action(CANCEL_DRAFT)
  public cancelDraft({ patchState, getState }: StateContext<SatMotorModel>, { payload }: CANCEL_DRAFT) {
    const state = getState()

    return this.satMotorService.cancelDraft(payload).pipe(
      map((res) => {
        if (res.cngeNote) {
          this.store.dispatch(new STORE_STEP3_DRAFT_NO(res.cngeNote));
        }
      }),
    );
  }

  prepareCoverNoteRequestPayload(state: SatMotorModel, payload: any, isSaveDraft: boolean, isRTP: boolean, geoIndValue: boolean) {
    let extraCoverPackage = [];
    let rtpData = {};
    let endorsementData = {};
    let data;

    if (isRTP) {
      let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto);

      if (cngeNote && cngeNote.riskList) {
        let risk = cngeNote.riskList[0];
        let cover = risk.coverList[0];

        rtpData = {
          cngeNoteDto: {
            cnoteType: cngeNote.cnoteType,
            productCode: cngeNote.productCode,
            productType: cngeNote.productType,
            effectiveDate: cngeNote.effectiveDate,
            expiryDate: cngeNote.expiryDate,
            cnoteDate: cngeNote.cnoteDate,
            endtEffectiveDate: cngeNote.endtEffectiveDate,
            endtExpiryDate: cngeNote.endtExpiryDate,
            proposalDate: cngeNote.cnoteDate,
            sumInsured: cngeNote.sumInsured,
            grossPremium: cover?.grossPremium,
            subCoverPremium: cover?.subCoverPremium,
            annualPremium: cover?.annualPremium,
            premiumDue: cover?.premiumDue,
            rebateType: "NR",
            totalHeadCount: cngeNote.totalHeadCount,
            cnoteBranch: cngeNote.mkagAgent.branchCode,
            lineOfBusiness: cngeNote.productType,
            region: cngeNote.mkagAgent.region,
            referProductCode: "",
            takeOverInd: cngeNote.takeOverInd,
            riskList: cngeNote.riskList,
            mkagAgent: cngeNote.mkagAgent,
            clientPartner: cngeNote.clientPartner,
            trailerRiskType: "XPM",
            premiumDueRounded: cngeNote.premiumDueRounded,
            stamp: cngeNote.stamp
          },
          operator: payload.userId,
          productType: "MT"
        };
      }

    } else if (payload.isEndorsement) {
      let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);

      if (cngeNote && cngeNote.riskList) {
        let risk = cngeNote.riskList[0];
        let cover = risk.coverList[0];

        endorsementData = {
          cngeNoteDto: {
            cnoteType: cngeNote.cnoteType,
            productCode: cngeNote.productCode,
            productType: cngeNote.productType,
            effectiveDate: cngeNote.effectiveDate,
            expiryDate: cngeNote.expiryDate,
            cnoteDate: cngeNote.cnoteDate,
            endtEffectiveDate: cngeNote.endtEffectiveDate,
            endtExpiryDate: cngeNote.endtExpiryDate,
            proposalDate: cngeNote.cnoteDate,
            sumInsured: cngeNote.sumInsured,
            grossPremium: cover?.grossPremium,
            subCoverPremium: cover?.subCoverPremium,
            annualPremium: cover?.annualPremium,
            premiumDue: cover?.premiumDue,
            rebateType: "NR",
            totalHeadCount: cngeNote.totalHeadCount,
            cnoteBranch: cngeNote.mkagAgent.branchCode,
            lineOfBusiness: cngeNote.productType,
            region: cngeNote.mkagAgent.region,
            referProductCode: "",
            takeOverInd: cngeNote.takeOverInd,
            riskList: cngeNote.riskList,
            mkagAgent: cngeNote.mkagAgent,
            clientPartner: cngeNote.clientPartner,
            trailerRiskType: "XPM",
            premiumDueRounded: cngeNote.premiumDueRounded,
            stamp: cngeNote.stamp
          },
          operator: payload.userId,
          productType: "MT"
        };
      }

    } else {
      let isCnoteStatusPrinted = state.motor.step4?.generateQuotation?.cnoteStatus === "PRINTED";
      let updateVersion = state.motor.step4?.generateQuotation?.updateVersion;
      let createBy = state.motor.step4?.generateQuotation?.createBy;
      let createDate = state.motor.step4?.generateQuotation?.cnoteDate;
      data = {
        cngeNote: {
          draftInd: isSaveDraft,
          geocodedInd: state.geoCodedInd,
          productType: "MT",
          currentStep: state.currentStep,
          progress: state.currentStep,
          ...state.currentStep,
          ...state.motor.step1.issuance.issuance,
          ...state.motor.step1.issuance.pdpa.pdpaCheck,
          ...state.motor.step1.issuance.pdpa.consentDetails,
          mkagAgent: {
            ...state.motor.step1.agentDetail,
          },
        },
        operator: payload.userId,
      };

      if (isCnoteStatusPrinted) {
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            updateVersion: isCnoteStatusPrinted || updateVersion ? updateVersion : null,
            createBy: isCnoteStatusPrinted || createBy ? createBy : null,
            createDate: isCnoteStatusPrinted || createDate ? createDate : null,
          },
        };
      }

      if (state.motor.step2) {
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            clientPartner: state.motor.step2,
          },
        };
      }

      if (state.motor.step3) {
        if (state.motor.step3.coverage) {
          if (state.motor.step3.coverage.vehicleDetails) {
            data = {
              ...data,
              cngeNote: {
                ...data.cngeNote,
                riskList: [{ ...state.motor.step3.coverage.vehicleDetails, riskType: "VEH" }],
                region: state.motor.step3.coverage.vehicleDetails.region,
              },
            };
          }
          if (state.motor.step3.coverage.coverageType) {
            data = {
              ...data,
              cngeNote: {
                ...data.cngeNote,
                riskList: [
                  {
                    ...data.cngeNote.riskList[0],
                    coverList: [{ ...state.motor.step3.coverage.coverageType }],
                  },
                ],
              },
            };
          }
          if (state.motor.step3.coverage.extracover) {
            let cngeRiskDrvrDtoListNamedDriver = [];
            let cngeRiskDrvrDtoListEhailing = [];
            let vehicleUnNamedDrvrInd = null;
            let vehicleAlldrvrInd = null;

            if (state.motor.step3.coverage.extracover.driverNameDetails?.rows) {
              const namedDriverList = state.motor.step3.coverage.extracover.driverNameDetails.rows;
              vehicleUnNamedDrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleUnNamedDrvrInd;
              vehicleAlldrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleAlldrvrInd;
              cngeRiskDrvrDtoListNamedDriver = namedDriverList.map((driver) => {
                let driverName;
                if (driver.driverName) {
                  driverName = driver.driverName;
                } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
                  driverName = driver.cngeRiskDrvrPkDto.driverName;
                }

                let cngeRiskDrvrPkDto = {
                  cngeRiskDrvrPkDto: {
                    driverName: driverName ? driverName : "",
                    driverType: "NORM",
                  },
                  driverId: driver.driverId,
                  driverAge: driver.driverAge,
                  driverSeq: isCnoteStatusPrinted ? state.motor.step3.coverage.vehicleDetails?.cngeRiskDrvrDtoList[0]?.driverSeq : driver.driverSeq,
                };

                return cngeRiskDrvrPkDto;
              });
            }

            if (state.motor.step3.coverage.extracover.ehailingDriverDetails?.rows) {
              const ehailingDriverList = state.motor.step3.coverage.extracover.ehailingDriverDetails.rows;
              cngeRiskDrvrDtoListEhailing = ehailingDriverList.map((driver) => {
                let driverName;
                if (driver.driverName) {
                  driverName = driver.driverName;
                } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
                  driverName = driver.cngeRiskDrvrPkDto.driverName;
                }

                let cngeRiskDrvrPkDto = {
                  cngeRiskDrvrPkDto: {
                    driverName: driverName ? driverName : "",
                    driverType: "EHAIL",
                  },
                  driverId: driver.driverId,
                  driverAge: 0, // Assuming `0` is the default driver age for ehailing drivers
                  driverSeq: driver.driverSeq,
                };

                return cngeRiskDrvrPkDto;
              });
            }

            // Merge the lists and update `data`
            data = {
              ...data,
              cngeNote: {
                ...data.cngeNote,
                riskList: [
                  {
                    ...data.cngeNote.riskList[0],
                    cngeRiskDrvrDtoList: [
                      ...cngeRiskDrvrDtoListNamedDriver,
                      ...cngeRiskDrvrDtoListEhailing
                    ],
                    coverList: [{
                      ...data.cngeNote.riskList[0].coverList[0],
                      vehicleUnNamedDrvrInd: vehicleUnNamedDrvrInd,
                      vehicleAlldrvrInd: vehicleAlldrvrInd
                    }],
                  },
                ],
              },
            };

            if (state.motor.step3.coverage.extracover.extraCoverPackage) {
              for (let cv of state.motor.step3.coverage.extracover.extraCoverPackage) {
                extraCoverPackage.push({
                  ...cv,
                  subCoverCodeDescription: cv.description,
                  selectedInd: cv.selected,
                  enableInd: cv.enabled,
                  subCoverSumInsured: cv.subCoverSumInsured,
                  subCoverDays: cv.subCoverNumberOfDays,
                  subCoverAmtday: cv.subCoverAmtPerDay > 0 ? cv.subCoverAmtPerDay : (cv.subCoverAmtday > 0 ? cv.subCoverAmtday : 0)
                });
              }
              let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
              data = {
                ...data,
                cngeNote: {
                  ...data.cngeNote,
                  premiumDueRounded: cngeNotePremium.premiumDueRounded,
                  stamp: cngeNotePremium.stamp,
                  riskList: [
                    {
                      ...data.cngeNote.riskList[0],
                      coverList: [{
                        ...data.cngeNote.riskList[0].coverList[0],
                        subCovPackage: cngeNotePremium.riskList[0].coverList[0].subCovPackage,
                        subCovPackageDesc: cngeNotePremium.riskList[0].coverList[0].subCovPackageDesc,
                        subCoverList: [
                          ...extraCoverPackage
                        ]
                      }],
                    },
                  ],
                },
              };
            }
          }
        }
      }

      if (!isSaveDraft) {
        let premiumInfo = state.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null;
        let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
        if (premiumInfo) {
          const { coverDto, cngeCoverNcdInfoList, ...remainingData } = premiumInfo;

          if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0) {
            if (data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
              if (
                !data.cngeNote.riskList[0].coverList[0].hasOwnProperty('grossPremium') ||
                data.cngeNote.riskList[0].coverList[0].grossPremium === undefined ||
                data.cngeNote.riskList[0].coverList[0].grossPremium === null ||
                data.cngeNote.riskList[0].coverList[0].grossPremium === ''
              ) {
                data.cngeNote.riskList[0].coverList[0].grossPremium = remainingData.grossPremium;
              }

              if (
                !data.cngeNote.riskList[0].coverList[0].hasOwnProperty('subCoverPremium') ||
                data.cngeNote.riskList[0].coverList[0].subCoverPremium === undefined ||
                data.cngeNote.riskList[0].coverList[0].subCoverPremium === null ||
                data.cngeNote.riskList[0].coverList[0].subCoverPremium === ''
              ) {
                data.cngeNote.riskList[0].coverList[0].subCoverPremium = remainingData.subCoverPremium;
              }

              const { cngeSubCoverUpSellList, ...coverListWithoutUpSellList } = remainingData;
              data.cngeNote.riskList[0].coverList[0] = {
                ...data.cngeNote.riskList[0].coverList[0],
                ...coverListWithoutUpSellList,
                subCoverList: [
                  ...extraCoverPackage
                ]
              };
            }
          }

          if (data.cngeNote.clientPartner && data.cngeNote.clientPartner.clientPartnerAddress) {
            let clientPartner = JSON.parse(JSON.stringify(data.cngeNote.clientPartner));

            if (!clientPartner.clientPartnerAddress.clientAddress1 || clientPartner.clientPartnerAddress.clientAddress1.trim() === '') {
              clientPartner.clientPartnerAddress.clientAddress1 = 'LOT123';
            }

            if (!clientPartner.clientPartnerAddress.clientPostcode || clientPartner.clientPartnerAddress.clientPostcode.trim() === '') {
              clientPartner.clientPartnerAddress.clientPostcode = '43950';
            }

            data.cngeNote.clientPartner = clientPartner;
          } //TO BE DELETED

          data.cngeNote = {
            ...data.cngeNote,
            ...remainingData,
            cbcAmount: 0.00,
            cbcAdvanceAmt: 0.00,
            referRemarkList: payload.referRemarkList,
            waiveLoadInd: payload.waiveLoadInd,
            waiveExcessInd: payload.waiveExcessInd,
            underwritingInd: payload.underwritingInd,
            agentReferId: payload.agentReferId,
            ...(payload.contractId && {
              proposalNo: payload.proposalNo,
              contractId: payload.contractId,
              cnoteDate: payload.cnoteDate,
              issueBy: payload.issueBy,
              issuedDate: payload.issuedDate
            }),
            premiumDueRounded: cngeNotePremium.premiumDueRounded,
            stamp: cngeNotePremium.stamp
          };

          let hasActiveQuotation = !!state.motor?.step1?.activeQuotation;

          if (hasActiveQuotation) {
            if (data.cngeNote.cnoteType === 'NWOO') {
              const contractId = state.retrieveQuotation
                ? state.motor?.step1?.activeQuotation?.cngeNote?.contractId
                : null;

              if (state.retrieveQuotation) {
                const updateVersion = state.motor?.step1?.activeQuotation?.cngeNote?.updateVersion;
                data.cngeNote = {
                  ...data.cngeNote,
                  contractId,
                  updateVersion
                };
              } else {
                setNullForTags(data.cngeNote);
                data.cngeNote.contractId = contractId;
              }
            }
          }

          function setNullForTags(obj: any) {
            if (!obj || typeof obj !== 'object') return;

            const tagsToNullify = ['contractId', 'riskId', 'coverId', 'subcoverId', 'updateVersion'];

            for (const key in obj) {
              if (tagsToNullify.includes(key)) {
                const descriptor = Object.getOwnPropertyDescriptor(obj, key);
                if (descriptor && descriptor.writable) {
                  obj[key] = null;
                }
              } else if (typeof obj[key] === 'object') {
                setNullForTags(obj[key]);
              }
            }
          }

          //Check and tally the sumInsured values
          if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0 &&
            data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
            if (data.cngeNote.riskList[0].coverList[0].sumInsured !== data.cngeNote.sumInsured) {
              data.cngeNote.riskList[0].coverList[0].sumInsured = data.cngeNote.sumInsured;
            }
          }
        }
      }
    }

    return isRTP ? rtpData : data;
  }

  @Action(DELETE_UPLOAD_DOCUMENT)
  public deleteUploadDocument({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload, equoteNo }: DELETE_UPLOAD_DOCUMENT) {
    const state = getState();
    console.warn(payload);
    const operator = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user).userId;

    const data = {
      operator: operator,
      documents: [
        {
          docId: payload.docId,
          contractId: payload.contractId,
          lob: "MT",
        },
      ],
      currentEquoteNo: equoteNo,
    };
    console.warn(data);

    return this.satMotorService.deleteDocumentUploaded(data).pipe(
      map((res) => {
        console.warn(res);
        patchState({
          uploadDocument: {
            ...state.uploadDocument,
            result: res,
          },
        });
      }),
    );
  }

  @Action(DOWNLOAD_DOCUMENT)
  public downloadDocument({ getState, patchState }: StateContext<SatMotorModel>, { payload }: DOWNLOAD_DOCUMENT) {
    const state = getState();
    return this.satMotorService.downloadDocument(payload.docId).pipe(
      map((res: HttpResponse<Blob>) => {
        if (res) {
          let file = new Blob([res.body]);
          let a = document.createElement("a");
          let fileUrl = URL.createObjectURL(file);
          a.download = payload.fileName;
          a.href = fileUrl;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          //TODO: this.notificationService.openNotificationDialog("Failed to download document.");
        }
      }),
    );
  }

  @Action(REQUOTE_UPLOAD_COPIED_DOCUMENTS)
  public requoteUploadCopiedDocuments({ patchState, getState }: StateContext<SatMotorModel>, { payload }: REQUOTE_UPLOAD_COPIED_DOCUMENTS) {
    const state = getState();
    patchState({
      uploadDocument: {
        ...state.uploadDocument,
        isRequotedDocCopied: payload.isRequotedDocCopied,
        failedDocCode: [],
      },
    });
  }
  @Action(UPDATE_GEO_INDICATOR)
  public updateGeocodeInd({ patchState, getState }: StateContext<SatMotorModel>, { payload }: UPDATE_GEO_INDICATOR) {
    const state = getState();
    patchState({
      geoCodedInd: payload,

    });
  }

  @Action(INITIATE_UPLOAD_DOCUMENT)
  public initiateUploadDocument({ patchState, getState }: StateContext<SatMotorModel>, { payload }: INITIATE_UPLOAD_DOCUMENT) {
    const state = getState();
    return this.satMotorService.initiateUploadDocument(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            uploadDocument: {
              ...state.uploadDocument,
              trxnId: res.data.trxnId,
            },
          });
        } else {
          patchState({
            uploadDocument: {
              ...state.uploadDocument,
              trxnId: null,
            },
          });
        }
      }),
    );
  }

  @Action(COMMIT_UPLOAD_DOCUMENT)
  public commitUploadDocument({ patchState, getState }: StateContext<SatMotorModel>, { payload }: COMMIT_UPLOAD_DOCUMENT) {
    const state = getState();
    return this.satMotorService.commitUploadDocument(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            uploadDocument: {
              ...state.uploadDocument,
              result: res.data,
            },
          });
        }
      }),
    );
  }

  @Action(UPLOAD_DOCUMENT)
  public uploadDocument({ getState, patchState }: StateContext<SatMotorModel>, { payload }: UPLOAD_DOCUMENT) {
    const state = getState();
    return Observable.create((observable) => {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("request", JSON.stringify(payload.request));
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status === 200) {
          const res = JSON.parse(xhr.response);
          observable.next(null);

          if (res && res.success && res.message) {
            patchState({
              uploadDocument: {
                ...state.uploadDocument,
                isUploadFailed: false,
                failedDocCode: [],
                isRequotedDocCopied: false,
              },
            });
          } else if (res && !res.success) {
            let currentFailedCodes = state.uploadDocument.failedDocCode.slice();
            currentFailedCodes.push(payload.request.documents[0].code);
            patchState({
              uploadDocument: {
                ...state.uploadDocument,
                isUploadFailed: true,
                failedDocCode: [...new Set(currentFailedCodes)],
                isRequotedDocCopied: false,
              },
            });
          }
        } else {
          //TODO: this.notificationService.openNotificationDialog('Upload documents failed. ');
          let currentFailedCodes = state.uploadDocument.failedDocCode.slice();
          currentFailedCodes.push(payload.request.documents[0].code);
          patchState({
            uploadDocument: {
              ...state.uploadDocument,
              isUploadFailed: true,
              failedDocCode: [...new Set(currentFailedCodes)],
              isRequotedDocCopied: false,
            },
          });
        }
        observable.complete();
      };
      xhr.open("POST", this.satMotorService.uploadDocuments(), true);
      xhr.send(formData);
    });
  }

  @Action(RESET_UPLOAD_DOCUMENTS)
  public resetUploadDocuments({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();
    patchState({
      uploadDocument: {
        isUploadFailed: null,
        failedDocCode: [],
        trxnId: null,
        result: null,
        isRequotedDocCopied: false,
      },
    });
  }

  @Action(STORE_CART_NAVIGATION)
  public storeCartNavigation({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_CART_NAVIGATION) {
    let selectedProduct = payload.data;
    let user = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    let state = getState();

    patchState({
      cartNavigation: {
        view: payload.action == "view" ? true : state.cartNavigation.view,
        edit: payload.action == "edit" ? true : state.cartNavigation.edit,
        editSelectedProducts: payload.action == "edit" && selectedProduct.length > 0 ? selectedProduct : [],
        editPopulatedData: state.cartNavigation.editPopulatedData,
        addMoreProducts: payload.action == "addMoreProducts" ? true : state.cartNavigation.addMoreProducts,
        addMoreProductsSelectedProducts: payload.action == "addMoreProducts" && selectedProduct.length > 0 ? selectedProduct : [],
        addMoreProductsPopulatedData: state.cartNavigation.addMoreProductsSelectedProducts,
        requote: payload.action == "requote" ? true : state.cartNavigation.requote,
        requoteSelectedProducts: payload.action == "requote" && selectedProduct.length > 0 ? selectedProduct : [],
        requotePopulatedData: state.cartNavigation.requotePopulatedData,
        requoteCopyPreviousDocInd: payload.action == "requoteCopyDoc" ? payload.copyInd : state.cartNavigation.requoteCopyPreviousDocInd,
        continue: payload.action == "continue" ? true : state.cartNavigation.continue,
        continueSelectedProducts: payload.action == "continue" && selectedProduct.length > 0 ? selectedProduct : [],
        continuePopulatedData: state.cartNavigation.continuePopulatedData,
      },
    });
  }

  @Action(SAVE_CUSTOMER_PARTNER)
  public saveCustomerPartner({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SAVE_CUSTOMER_PARTNER) {
    const state = getState();
    const geoIndValue = state.geoCodedInd;
    let data = this.prepareCoverNoteRequestPayload(state, payload, true, false, geoIndValue); console.log(data);
    return this.satMotorService.saveCustomerPartner(payload).pipe(
      map((res) => {
        if (res.data) {
          dispatch(new STORE_STEP2(res.data));
        }
      }),
    );
  }

  @Action(GET_UPSELL_PRODUCT_LOV)
  public getUpsellProductLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_UPSELL_PRODUCT_LOV) {
    const state = getState();

    return this.satMotorService.getUpsellProductLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            vehicleClassLov: res.data,
            data: {
              ...state.data,
              step4: {
                ...state.data.step4,
                upsellProductLov: res.data,
              },
            },
          });
        }
      }),
    );
  }

  @Action(STORE_UPSELL_PRODUCT)
  public storeUpsellProduct({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_UPSELL_PRODUCT) {
    const state = getState();

    patchState({
      data: {
        ...state.data,
        step4: {
          ...state.data.step4,
          upsellProduct: payload,
        },
      },
    });
  }

  @Action(GET_PAYMENT_MODE_LOV)
  public getPaymentModeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PAYMENT_MODE_LOV) {
    const state = getState();

    return this.satMotorService.getPaymentLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              step4: {
                ...state.data.step4,
                paymentModeLov: res.data,
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_PAYMENT_TO_LOV)
  public getPaymentToLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PAYMENT_TO_LOV) {
    const state = getState();

    return this.satMotorService.getPaymentLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              step4: {
                ...state.data.step4,
                paymentToLov: res.data,
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_DOCUMENTS)
  public getDocuments({ getState, patchState }: StateContext<SatMotorModel>, { payload }: GET_DOCUMENTS) {
    const state = getState();
    return this.satMotorService.getDocuments(payload).pipe(
      map((res) => {
        if (res != "{}" && res.data) {
          let mandatoryDocumentList = state.lov.documentList.mandatoryDocumentList;
          let combinedDocLovList;
          for (let mandatoryDocument of Object.keys(mandatoryDocumentList)) {
            const tempDocList = mandatoryDocumentList[mandatoryDocument].concat(state.lov.documentList.optionalDocumentList[mandatoryDocument]);
            combinedDocLovList = {
              ...combinedDocLovList,
              [mandatoryDocument]: tempDocList,
            };
          }

          Object.keys(res.data).forEach((resData) => {
            let temp = [];
            res.data[resData].forEach((element, i) => {
              const docData = combinedDocLovList[resData].find((doc) => doc.code == element.code);
              element = {
                ...element,
                fileName: res.data[resData][i] ? res.data[resData][i].name + "." + res.data[resData][i].type : "",
                descp: docData.descp,
                mandatoryInd: docData.mandatoryInd,
              };
              temp.push(element);
            });
            res.data[resData] = temp;
          });

          patchState({
            cartDocumentDetails: res.data,
          });
        } else {
          console.warn("Document list is empty.");
          patchState({
            cartDocumentDetails: [],
          });
        }
      }),
    );
  }

  @Action(GET_POSTCODE_LOV)
  public getPostcodeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PAYMENT_TO_LOV) {
    const state = getState();

    return this.satMotorService.getPostcodeLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            lov: {
              ...state.lov,
              clientPostcodeList: res.data.clientPostcodeList,
            },
          });
        }
      }),
    );
  }

  @Action(GET_POSTCODE_INFO)
  public getPostcodeInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_POSTCODE_INFO) {
    const state = getState();

    return this.satMotorService.getPostcodeInfo(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              postCodeInfo: res.data,
            },
          });
        }
      }),
    );
  }

  @Action(GET_CROSS_SELL_LIST)
  public getCrossSellList({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_CROSS_SELL_LIST) {
    const state = getState();

    return this.satMotorService.getCrossSellList(payload).pipe(
      map((res) => {
        if (res) {
          let crossSellList = res.crossSellList.map((crossSell) => {
            let responseBannerImg = {
              file: {
                data: crossSell.bannerImg,
              },
            };
            let bannerImgBlob = this.satMotorService.generateBlob(responseBannerImg);
            console.log("Banner Img Blob:", bannerImgBlob);
            let bannerImgURL = URL.createObjectURL(bannerImgBlob);

            return {
              ...crossSell,
              bannerImg: bannerImgURL,
            };
          });

          patchState({
            data: {
              ...state.data,
              step5: {
                ...(state.data.step5 ? state.data.step5 : null),
                crossSellList: crossSellList,
                marketingConsent: res.marketingConsent,
              },
            },
          });
        }
      }),
    );
  }
  @Action(STORE_AGENT)
  public storeAgent(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: STORE_AGENT) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...state.motor.step1,
          agentDetail: payload,
        },
      },
    });
  }

  @Action(COPY_CNGE_INFO)
  public copyCngeInfo(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_CNGE_INFO) {
    const state = getState();

    let step1 = {
      step1: {
        agentDetail: payload.mkagAgent,
        issuance: {
          issuance: {
            cnoteType: payload.cnoteType,
            vehicleNumber: payload.vehicleNo,
            prevPolicyNo: payload.previousPolicyNo,
            productCode: payload.productCode,
            effectiveDate: payload.effectiveDate,
            expiryDate: payload.expiryDate,
            cnoteDate: payload.cnoteDate
          },
          pdpa: {
            pdpaCheck: true,
            consentDetails: {
              edocConsentInd: payload.edocConsentInd,
              einvoiceConsentInd: payload.einvoiceConsentInd
            }
          }
        },
      }
    };

    let data2;
    let step2;

    data2 = {
      step2: {
        store: {
          ...payload.clientPartner
        }
      }
    };

    step2 = {
      step2: {
        showSearchComponent: false,
        ...payload.clientPartner
      }
    };

    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
    this.store.dispatch(new STORE_STEP2_CP_SCREEN({
      cpScreen: "FOUND",
      isCompOrCustInfoIncomplete: false,
      isContactInfoIncomplete: false,
      isCorrespondenceInfoIncomplete: false
    }));


    let data3;
    let step3;
    data3 = {
      step3: {
        coverage: {
          selectedMakeModel: payload.riskList[0]
        }
      }
    };

    step3 = {
      step3: {
        coverage: {
          vehicleDetails: {
            ...payload.riskList[0],
            region: payload.region
          },
          coverageType: {
            ...payload.riskList[0]?.coverList[0],
          },
          extracover: {
            driverNameDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "NORM")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                  driverAge: a.driverAge
                }
              }),
              vehicleUnNamedDrvrInd: payload.riskList[0].coverList[0].vehicleUnNamedDrvrInd,
              vehicleAlldrvrInd: payload.riskList[0].coverList[0].vehicleAlldrvrInd
            },
            ehailingDriverDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "EHAIL")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                }
              }),
            },
            extraCoverPackage: payload.riskList[0]?.coverList[0]?.subCoverList,
          },
          coveragePremium: {
            cngeNote: { ...payload }
          }
        },
      },
    }

    this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ({
      isIssuance: true
    }));

    patchState({
      // data: {
      //   ...data2,
      //   ...data3
      // },
      motor: {
        ...step1,
        ...step2,
        ...step3,
        step4: null,
        step5: null
      }
    });
  }

  @Action(COPY_CNGE_INFO_RENEWAL)
  public copyCngeInfoRenewal(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_CNGE_INFO_RENEWAL) {
    const state = getState();

    //agent
    this.store.dispatch(new STORE_AGENT(payload.mkagAgent));

    //declaration
    let declarationInfo = {
      issuance: {
        cnoteType: payload.cnoteType,
        vehicleNumber: payload.vehicleNo,
        prevPolicyNo: payload.previousPolicyNo,
        productCode: payload.productCode,
        effectiveDate: payload.effectiveDate,
        expiryDate: payload.expiryDate
      },
      pdpa: {
        pdpaCheck: true,
        consentDetails: {
          edocConsentInd: payload.edocConsentInd,
          einvoiceConsentInd: payload.einvoiceConsentInd
        }
      }
    };

    //customer partner
    this.store.dispatch(new STORE_STEP2_RENEWAL(payload.clientPartner));

    //coverage
    if (payload.riskList[0]) {
      this.store.dispatch(new STORE_STEP3_RENEWAL(payload));
    }

    // patchState({

    // });
  }

  @Action(COPY_CNGE_INFO_VIEW_QUOTATION)
  public copyCngeInfoViewQuotation(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_CNGE_INFO_VIEW_QUOTATION) {
    const state = getState();

    //agent
    this.store.dispatch(new STORE_AGENT(payload.mkagAgent));

    //declaration
    let declarationInfo = {
      issuance: {
        cnoteType: payload.cnoteType,
        vehicleNo: payload.vehicleNo,
        prevPolicyNo: payload.previousPolicyNo,
        productCode: payload.productCode,
        effectiveDate: payload.effectiveDate,
        expiryDate: payload.expiryDate
      },
      pdpa: {
        pdpaCheck: true,
        consentDetails: {
          edocConsentInd: payload.edocConsentInd,
          einvoiceConsentInd: payload.einvoiceConsentInd
        }
      }
    };

    this.store.dispatch(new STORE_DECLARATION(declarationInfo));

    //customer partner
    this.store.dispatch(new STORE_STEP2_RENEWAL(payload.clientPartner));

    //coverage
    if (payload.riskList[0]) {
      this.store.dispatch(new STORE_STEP3_RENEWAL(payload));
    }

    // patchState({

    // });
  }

  @Action(GET_ISS_PRODUCT_PDF)
  public getIssMarketingMaterialDocList(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: GET_ISS_PRODUCT_PDF) {
    const state = getState();

    return this.satMotorService.getProductPDF(payload).pipe(
      map((res) => {
        if (res) {
          const pdfArray = [];

          for (const folder of res.folders) {
            for (const folder2 of folder.folders) {
              for (const folder3 of folder2.folders) {
                for (const folder4 of folder3.folders) {
                  for (const folder5 of folder4.folders) {
                    if (!folder5.contents) continue
                    for (const content of folder5.contents) {
                      if (content.contentFields[0].contentFieldValue.document.fileExtension.toLowerCase() == 'pdf') {
                        pdfArray.push(content);
                      }
                    }
                  }
                }
              }
            }
          }

          console.log('pdfArr: ', pdfArray);

          patchState({
            data: {
              ...state.data,
              step5: {
                ...state.data.step5,
                folderContents: pdfArray
              }
            }
          });
        }
      }),
    );
  }

  @Action(SAVE_ISS_MESSAGE_SENT_LOG)
  saveMessageSentLog(
    { patchState }: StateContext<SatMotorModel>,
    { payload }: SAVE_ISS_MESSAGE_SENT_LOG
  ) {
    return this.satMotorService.saveMessageLog(payload).subscribe();
  }

  @Action(SAVE_ISS_EMAIL_SENT_LOG)
  public saveEmailSentLog(
    { patchState }: StateContext<SatMotorModel>,
    { payload }: SAVE_ISS_EMAIL_SENT_LOG
  ) {
    return this.satMotorService.saveEmailLog(payload).subscribe();
  }

  @Action(GET_CCENTRIC_OPPORTUNITY_INFO)
  public getCCentricOpportunityInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PRODUCT_LIST) {
    const state = getState();
    return this.satMotorService.getCCentricOpportunityInfo(payload).pipe(
      map((res) => {
        // if (res && res.productAccessConfigList) {
        //   const state = getState();
        //   patchState({
        //     lov: {
        //       ...state.lov,
        //       productList: this.filterProductList(res.productAccessConfigList),
        //     },
        //   });
        // }
      }),
    );
  }

  @Action(GET_SWITCH_SITE)
  public getSwitchSite({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_SWITCH_SITE) {
    const state = getState();
    return this.satMotorService.getSwitchSite(payload).pipe(
      map((res) => {
        if (res && res.redirectUrl) {
          patchState({
            data: {
              ...state.data,
              switchSiteUrl: res.redirectUrl
            }
          });
        } else {
          console.error('Redirect URL is missing or malformed:', res);
          return null; // Or return a fallback URL
        }
      }),
    );
  }

  @Action(GET_DRAFT_DETAILS)
  public getDraftDetails({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_DRAFT_DETAILS) {
    const state = getState();
    return this.satMotorService.getQuotationDetail(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            ...state,
            draftDetails: res.data
          });
        }
      }),
    );
  }

  @Action(GET_QUOTATION_DETAIL)
  public getQuotationDetail({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_QUOTATION_DETAIL) {
    const state = getState();
    return this.satMotorService.getQuotationDetail(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            viewQuotation: res.data,
            viewQuotationData: this.readDataFromCngeNote(res.data)
          });
        }
      }),
    );
  }

  @Action(GET_QUOTATION_DETAIL_REQUOTE)
  public getQuotationDetailToRequote({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload, action }: GET_QUOTATION_DETAIL_REQUOTE) {
    const state = getState();
    if (action) {
      return this.satMotorService.getQuotationDetailToRequote(payload, action).pipe(
        map((res) => {
          if (res && res.data) {
            patchState({
              requoteQuotation: res.data
            });
          }
        }),
      );
    } else {
      return this.satMotorService.getQuotationDetailToRequoteNoAction(payload).pipe(
        map((res) => {
          if (res && res.data) {
            patchState({
              requoteQuotation: res.data
            });
          }
        }),
      );
    }
  }

  readDataFromCngeNote(data: any): any {
    const { mkagAgent, riskList = [{}], clientPartner: cpData, referRemarkList = [], ...cngeNote } = data;
    const [risk] = riskList;
    const [coverList] = riskList[0]?.coverList || [];
    const [remarks] = referRemarkList;

    // Agent Data
    const agentData = {
      agentCode: mkagAgent.agentCode,
      name: mkagAgent.name,
      issuedBy: cngeNote.issueBy,
      issuedDate: cngeNote.cnoteDate,
    };

    // Declaration issuance
    const issuanceInfo = {
      issuance: {
        productCode: cngeNote.productCode,
        vehicleNo: cngeNote.vehicleNo ?? risk.vehicleNo,
        cnoteType: cngeNote.cnoteType,
        prevPolicyNo: cngeNote.previousPolicyNo,
        effectiveDate: cngeNote.effectiveDate,
        expiryDate: cngeNote.expiryDate,
        equotationNo: cngeNote.proposalNo,
        equotationCreatedDate: cngeNote.createDate,
        eCoverNoteNo: cngeNote.cnoteNo,
        jpjStatus: risk.jpjStatus,
        jpjMessage: cngeNote.jpjMessage,
      },
      pdpa: {
        consentDetails: {
          edocConsentInd: !!cngeNote.edocConsentInd,
          einvoiceConsentInd: !!cngeNote.einvoiceConsentInd,
        },
      },
    };

    // Coverage Info
    const coverageInfo = {
      vehicleDetails: risk,
      coverageDetails: {
        ...coverList,
        makeModelSiMin: cngeNote.region === 'W' ? risk.avWmSiMin : cngeNote.region === 'E' ? risk.avEmSiMin : undefined,
        makeModelSiMax: cngeNote.region === 'W' ? risk.avWmSiMax : cngeNote.region === 'E' ? risk.avEmSiMax : undefined
      },
      driverNameDetails: this.getDriverListData(risk.cngeRiskDrvrDtoList, true),
      ehailingDriverDetails: this.getDriverListData(risk.cngeRiskDrvrDtoList, false),
      extraCoverPackageSummary: this.getExtraCoverage(coverList),
    };

    // Premium Info
    const premiumInfo = {
      ...coverList,
      stamp: cngeNote.stamp,
      premiumDue: cngeNote.premiumDue ?? coverList.premiumDue,
      premiumDueRounded: cngeNote.premiumDueRounded,
      commissionPercentage: cngeNote.commissionPercentage ?? coverList.commissionPercentage,
      commissionAmount: cngeNote.commissionAmount ?? coverList.commissionAmount
    };

    // Remarks Info
    const remarksInfo = {
      referral: cngeNote.referRemarks,
      cancel: cngeNote.cancelRemarks,
      approval: cngeNote.referAdminRemarks,
      updatedBy: `${cngeNote.updateBy} at ${this.getFormattedDate(cngeNote.updateDate)}`,
    };

    return {
      cngeNote,
      mkagAgent,
      riskList,
      coverList,
      agentData,
      issuanceInfo,
      cpData,
      coverageInfo,
      premiumInfo,
      remarksInfo,
    };
  }

  getDriverListData(cngeRiskDrvrDtoList, isNormalDriver) {
    return cngeRiskDrvrDtoList?.filter(({ cngeRiskDrvrPkDto: { driverType } }) =>
      isNormalDriver ? driverType === 'NORM' : driverType === 'EHAIL'
    ).map(({ cngeRiskDrvrPkDto: { driverName }, driverId, driverAge }) => ({
      driverName,
      driverId,
      driverAge,
    })) || null;
  }

  getFormattedDate(dateVal: string) {
    return dateVal ? moment(dateVal, 'YYYY-MM-DD').format('DD/MM/YYYY') : dateVal;
  }

  getExtraCoverage(coverList: any) {

    if (!coverList || !coverList.subCoverList) {
      return {
        subCovPackageDesc: null,
        rahmahPackageList: [],
        topExtraCoverList: [],
        selectedExtraCoverList: [],
      };
    }

    const extraCoverPackage = coverList.subCoverList.map(cv => ({
      ...cv,
      description: cv.subCoverCodeDescription,
      selected: cv.selectedInd,
      enabled: cv.enableInd ?? false,
    }));

    let removeSumInsured = [
      "M01", "M02", "M03", "M07", "M08", "22", "22(a)", "24(c)", "24(d)", "25",
      "72", "100", "101", "101(a)", "102", "105", "108", "109", "111",
      "A200", "A201", "A202", "A202-1", "A202-2", "A203", "A203-1", "A203-2", "A203-3"
      , "A207", "PAB-OCR",
    ]

    let selectedExtraCoverList = extraCoverPackage.filter(o1 => o1.selected && o1.subCoverPrem > 0);
    let updateSumInsuredExtraCoverList = selectedExtraCoverList.map((obj) => {
      if (removeSumInsured.includes(obj.subCoverCode)) {
        return { ...obj, subCoverSumInsured: 0 };
      }
      return obj;
    });

    return {
      subCovPackageDesc: coverList.subCovPackageDesc ?? null,
      rahmahPackageList: extraCoverPackage.filter(o1 => o1.subCovPackage && !o1.enableInd && !o1.subCoverPrem),
      topExtraCoverList: extraCoverPackage.filter(o1 => o1.selectedInd && !o1.enableInd && !o1.subCoverPrem && !o1.subCovPackage),
      selectedExtraCoverList: updateSumInsuredExtraCoverList
    };
  }

  @Action(GET_ISS_CONTENT_ID)
  getContentId(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: GET_ISS_CONTENT_ID
  ) {
    const state = getState();
    return this.satMotorService.getContentId(payload).pipe(
      map(res => {
        if (res) {
          patchState({
            data: {
              ...state.data,
              step5: {
                ...state.data.step5,
                contentId: res[0].code
              }
            }
          });
        }
      })
    );
  }

  @Action(GET_NCD_CONFIRMATION)
  getNcdConfirmation(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: GET_NCD_CONFIRMATION
  ) {
    const state = getState();
    return this.satMotorService.getNcdConfirmation(payload).pipe(
      map(res => {
        if (res) {
          patchState({
            ncdDetails: res
          });
        } else {
          patchState({
            ncdDetails: null
          });
        }
      })
    );
  }

  @Action(RESET_MOTOR_STATE)
  public resetMotorState({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...state.motor.step1,
          issuance: null,
        },
        step2: null,
        step3: null,
        step4: null,
        step5: null,
      },
      data: {
        ...state.data,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
        isRenewNow: null,
        agentDetail: null,
        branchList: null,
        hasShowedOneAllianzPopUp: null,
      },
      declarationInfo: null,
    });
  }

  @Action(RESET_PREVIOS_POLICY_DETAILS)
  public resetPreviousPolicyDetails({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      previousPolicyDetails: null,
      previousPolicyDetailsError: null
    });
  }

  @Action(PRODUCT_VALIDATION)
  public productValidation({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      previousPolicyDetails: null,
      previousPolicyDetailsError: null
    });
  }

  @Action(COPY_DRAFT_INFO)
  public copyDraftInfo(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_DRAFT_INFO) {
    const state = getState();

    let step1 = {
      step1: {
        agentDetail: payload.mkagAgent,
        issuance: {
          issuance: {
            cnoteType: payload.cnoteType,
            vehicleNumber: payload.vehicleNo,
            prevPolicyNo: payload.previousPolicyNo,
            productCode: payload.productCode,
            effectiveDate: payload.effectiveDate,
            expiryDate: payload.expiryDate,
            cnoteDate: payload.cnoteDate,
            contractId: payload.contractId,
            proposalNo: payload.proposalNo,
          },
          pdpa: {
            pdpaCheck: true,
            consentDetails: {
              edocConsentInd: payload.edocConsentInd,
              einvoiceConsentInd: payload.einvoiceConsentInd
            }
          }
        },
      }
    };

    let data2;
    let step2;
    if (payload.progress > 1) {
      data2 = {
        step2: {
          store: {
            ...payload.clientPartner
          }
        }
      };

      step2 = {
        step2: {
          showSearchComponent: false,
          ...payload.clientPartner
        }
      };
      const step2CPScreen = {
        cpScreen: "FOUND",
        isCompOrCustInfoIncomplete: false,
        isContactInfoIncomplete: false,
        isCorrespondenceInfoIncomplete: false
      };
      const step2Search: STEP2.SearchQuery = {
        idType: payload.clientPartner.idType1,
        idValue: payload.clientPartner.idValue1,
        agentCode: payload.mkagAgent.agentCode,
        operator: null,
        productType: "MT"
      };
      this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
      this.store.dispatch(new STORE_STEP2_SEARCH(step2Search));
      this.store.dispatch(new STORE_STEP2_CP_SCREEN(step2CPScreen));
    }

    let data3;
    let step3;
    if (payload.progress > 2) {
      let selectedMakeModel = { ...payload.riskList[0] };
      delete selectedMakeModel["cngeRiskDrvrDtoList"];
      delete selectedMakeModel["coverList"];
      data3 = {
        step3: {
          coverage: {
            selectedMakeModel: selectedMakeModel
          }
        }
      };

      step3 = {
        step3: {
          coverage: {
            vehicleDetails: {
              ...payload.riskList[0],
              region: payload.region
            },
            coverageType: {
              ...payload.riskList[0]?.coverList[0],
            },
            extracover: {
              driverNameDetails: {
                rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "NORM")?.map(a => {
                  return {
                    driverName: a.cngeRiskDrvrPkDto.driverName,
                    driverId: a.driverId,
                    driverAge: a.driverAge
                  }
                }),
                vehicleUnNamedDrvrInd: payload.riskList[0].coverList[0].vehicleUnNamedDrvrInd,
                vehicleAlldrvrInd: payload.riskList[0].coverList[0].vehicleAlldrvrInd
              },
              ehailingDriverDetails: {
                rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "EHAIL")?.map(a => {
                  return {
                    driverName: a.cngeRiskDrvrPkDto.driverName,
                    driverId: a.driverId,
                  }
                }),
              },
              extraCoverPackage: payload.riskList[0]?.coverList[0]?.subCoverList,
            },
          },
        },
      }
    }


    patchState({
      currentStep: payload.progress,
      data: {
        ...data2,
        ...data3
      },
      motor: {
        ...step1,
        ...step2,
        ...step3,
        step4: {
          generateQuotation: payload,
        }
      }
    });

  }

  @Action(COPY_REQUOTE_INFO)
  public copyRequoteInfo(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_REQUOTE_INFO) {
    const state = getState();

    let step1 = {
      step1: {
        agentDetail: payload.mkagAgent,
        issuance: {
          issuance: {
            cnoteType: payload.cnoteType,
            vehicleNo: payload.vehicleNo,
            prevPolicyNo: payload.previousPolicyNo,
            productCode: payload.productCode,
            effectiveDate: payload.effectiveDate,
            expiryDate: payload.expiryDate,
            cnoteDate: payload.cnoteDate,
            contractId: payload.contractId,
            proposalNo: payload.proposalNo,
            idType: payload.clientPartner.idType1,
            idNumberText: payload.clientPartner.idValue1
          },
          pdpa: {
            pdpaCheck: false,
            consentDetails: {
              edocConsentInd: undefined,
              einvoiceConsentInd: undefined
            }
          }
        },
      }
    };

    let data2;
    let step2;
    // if (payload.progress > 1) {
    data2 = {
      step2: {
        store: {
          ...payload.clientPartner
        }
      }
    };

    step2 = {
      step2: {
        showSearchComponent: false,
        ...payload.clientPartner
      }
    };
    const step2CPScreen = {
      cpScreen: "FOUND",
      isCompOrCustInfoIncomplete: false,
      isContactInfoIncomplete: false,
      isCorrespondenceInfoIncomplete: false
    };
    const step2Search: STEP2.SearchQuery = {
      idType: payload.clientPartner.idType1,
      idValue: payload.clientPartner.idValue1,
      agentCode: payload.mkagAgent.agentCode,
      operator: null,
      productType: "MT"
    };
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
    this.store.dispatch(new STORE_STEP2_SEARCH(step2Search));
    this.store.dispatch(new STORE_STEP2_CP_SCREEN(step2CPScreen));
    // }

    let data3;
    let step3;
    // if (payload.progress > 2) {
    let selectedMakeModel = { ...payload.riskList[0] };
    delete selectedMakeModel["cngeRiskDrvrDtoList"];
    delete selectedMakeModel["coverList"];
    data3 = {
      step3: {
        coverage: {
          selectedMakeModel: selectedMakeModel
        }
      }
    };

    step3 = {
      step3: {
        coverage: {
          vehicleDetails: {
            ...payload.riskList[0],
            region: payload.region
          },
          coverageType: {
            ...payload.riskList[0]?.coverList[0],
          },
          extracover: {
            driverNameDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "NORM")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                  driverAge: a.driverAge
                }
              }),
              vehicleUnNamedDrvrInd: payload.riskList[0].coverList[0].vehicleUnNamedDrvrInd,
              vehicleAlldrvrInd: payload.riskList[0].coverList[0].vehicleAlldrvrInd
            },
            ehailingDriverDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "EHAIL")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                }
              }),
            },
            extraCoverPackage: payload.riskList[0]?.coverList[0]?.subCoverList,
          },
        },
      },
    }
    // }

    patchState({
      currentStep: payload.progress,
      data: {
        ...data2,
        ...data3
      },
      motor: {
        ...step1,
        ...step2,
        ...step3,
        step4: {
          generateQuotation: payload,
        }
      }
    });

  }


  @Action(STORE_STEP2_DRAFT_NO)
  public storeStep2DraftNo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_DRAFT_NO) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step2: {
          ...state.motor.step2,
          draftNo: payload.proposalNo
        }
      }
    });
  }

  @Action(STORE_STEP3_DRAFT_NO)
  public storeStep3DraftNo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP3_DRAFT_NO) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          draftNo: payload.proposalNo
        }
      }
    });
  }

  @Action(STORE_EXTRA_COVER_PACKAGE)
  public storeExtraCoveragePackage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_EXTRA_COVER_PACKAGE) {
    const state = getState();
    let extraCoverPackage = [];
    for (let cv of payload.subCoverList) {
      extraCoverPackage.push({
        ...cv,
        description: cv.subCoverCodeDescription,
        selected: cv.selectedInd,
        enabled: cv.enableInd !== undefined ? cv.enableInd : false
      });
    }

    // let rahmahExtraCoverList = extraCoverPackage.filter(o1 => o1.subCovPackage && o1.enabled == false && o1.subCoverPrem == 0);
    // let topExtraCoverList = extraCoverPackage.filter(o1 => o1.selected === true && o1.enabled === false && o1.subCoverPrem == 0 && !o1.subCovPackage);
    // let extraCoverList = extraCoverPackage.filter(o1 => o1.selected === true && o1.subCoverPrem > 0);
    let rahmahExtraCoverList = extraCoverPackage.filter(o1 => o1.subCovPackage && o1.enableInd == false && o1.subCoverPrem == 0);
    let topExtraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.enableInd === false && o1.subCoverPrem == 0 && !o1.subCovPackage);
    let extraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.subCoverPrem > 0);

    let extraCoverInSummary = {
      subCovPackageDesc: payload.subCovPackageDesc,
      rahmahPackageList: rahmahExtraCoverList,
      topExtraCoverList: topExtraCoverList,
      selectedExtraCoverList: extraCoverList
    }

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            extracover: {
              ...state.motor.step3.coverage.extraCover,
              extraCoverPackageSummary: extraCoverInSummary
            },
          },
        },
      },
    });
  }

  @Action(CANCEL_QUOTATION)
  public cancelQuotation(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: CANCEL_QUOTATION
  ) {
    const state = getState();

    return this.satMotorService.cancelQuotation(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              cancellationRes: res.data
            },
          });
        }
      }),
    );
  }

  @Action(CANCEL_COVER_NOTE)
  public cancelCoverNote(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: CANCEL_COVER_NOTE
  ) {
    const state = getState();

    return this.satMotorService.cancelCoverNote(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              cancellationRes: res.data
            },
          });
        }
      }),
    );
  }

  @Action(STORE_COMPLETE_DATA)
  public initCompleteData(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: STORE_COMPLETE_DATA
  ) {
    const state = getState();

    if (payload.isIssuance) {
      patchState({
        motor: {
          ...state.motor,
          step5: {
            ...state.motor.step5,
            cngeNote: payload.cngeNote
          }
        }
      });
    } else if (payload.isRTP) {
      patchState({
        rtp: {
          ...state.rtp,
          step3: {
            ...state.rtp.step3,
            cngeNote: payload.cngeNote
          }
        }
      });

    }
  }

  @Action(VALIDATE_POI_DATE)
  public validatePoiDate({ patchState, getState }: StateContext<SatMotorModel>,
    { payload }: VALIDATE_POI_DATE) {
    return this.satMotorService.validatePoiDate(payload).pipe(
      map((res) => {
        if (res && res.success !== undefined) {
          patchState({
            poiValidateResult: res
          });
        } else {
          patchState({
            poiValidateResult: null
          });
        }
      })
    );
  }

  prepareCoverNoteRequestPayloadRenewal(state: SatMotorModel, payload: any, isSaveDraft: boolean, isRTP: boolean, geoIndValue) {
    let rtpData = {};
    let data;

    data = {
      cngeNote: {
        draftInd: isSaveDraft,
        geoCodedInd: false,
        productType: "MT",
        currentStep: state.currentStep,
        progress: state.currentStep,
        ...state.currentStep,
        ...state.motor.step1.issuance.issuance,
        ...state.motor.step1.issuance.pdpa.pdpaCheck,
        ...state.motor.step1.issuance.pdpa.consentDetails,
        mkagAgent: {
          ...state.motor.step1.agentDetail,

        },
      },
      operator: payload.userId,
    };

    let cngeNote = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
    if(cngeNote){
      let isCnoteStatusPrinted = cngeNote.cnoteStatus === "PRINTED";
      let updateVersion = cngeNote.updateVersion;
      let createBy = cngeNote.createBy;
      let createDate = cngeNote.cnoteDate;
      let cnoteNo = cngeNote.cnoteNo;
  
      if (isCnoteStatusPrinted && !cnoteNo) {
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            updateVersion: isCnoteStatusPrinted || updateVersion ? updateVersion : null,
            createBy: isCnoteStatusPrinted || createBy ? createBy : null,
            createDate: isCnoteStatusPrinted || createDate ? createDate : null,
          },
        };
      }
    }
    

    if (state.motor.step2) {
      data = {
        ...data,
        cngeNote: {
          ...data.cngeNote,
          clientPartner: state.motor.step2,
          coverId: null,
          contractId: null,
          riskId: null,
        },
      };
    }

    if (state.motor.step3) {
      if (state.motor.step3.coverage) {
        if (state.motor.step3.coverage.vehicleDetails) {
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [{ ...state.motor.step3.coverage.vehicleDetails, riskType: "VEH" }],
              region: state.motor.step3.coverage.vehicleDetails.region,
              grossPremium: state.motor.step3.coverage.coverageType.grossPremium,
              annualPremium: state.motor.step3.coverage.coverageType.annualPremium
            },
          };
        }
        if (state.motor.step3.coverage.coverageType) {
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [
                {
                  ...data.cngeNote.riskList[0],
                  coverList: [{ ...state.motor.step3.coverage.coverageType }],
                  contractId: null,
                  riskId: null,
                  grossPremium: state.motor.step3.coverage.coverageType.grossPremium,
                  annualPremium: state.motor.step3.coverage.coverageType.annualPremium
                },
              ],
            },
          };
        }
        if (state.motor.step3.coverage.extracover) {
          let cngeRiskDrvrDtoListNamedDriver = [];
          let cngeRiskDrvrDtoListEhailing = [];
          let vehicleUnNamedDrvrInd = null;
          let vehicleAlldrvrInd = null;

          if (state.motor.step3.coverage.extracover.driverNameDetails?.rows) {
            const namedDriverList = state.motor.step3.coverage.extracover.driverNameDetails.rows;
            vehicleUnNamedDrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleUnNamedDrvrInd;
            vehicleAlldrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleAlldrvrInd;
            cngeRiskDrvrDtoListNamedDriver = namedDriverList.map((driver) => {
              let driverName;
              if (driver.driverName) {
                driverName = driver.driverName;
              } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
                driverName = driver.cngeRiskDrvrPkDto.driverName;
              }

              let cngeRiskDrvrPkDto = {
                cngeRiskDrvrPkDto: {
                  driverName: driverName ? driverName : "",
                  driverType: "NORM",
                },
                driverId: driver.driverId,
                driverAge: driver.driverAge,
                driverSeq: driver.driverSeq,
              };

              return cngeRiskDrvrPkDto;
            });
          }

          if (state.motor.step3.coverage.extracover.ehailingDriverDetails?.rows) {
            const ehailingDriverList = state.motor.step3.coverage.extracover.ehailingDriverDetails.rows;
            cngeRiskDrvrDtoListEhailing = ehailingDriverList.map((driver) => {
              let driverName;
              if (driver.driverName) {
                driverName = driver.driverName;
              } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
                driverName = driver.cngeRiskDrvrPkDto.driverName;
              }

              let cngeRiskDrvrPkDto = {
                cngeRiskDrvrPkDto: {
                  driverName: driverName ? driverName : "",
                  driverType: "EHAIL",
                },
                driverId: driver.driverId,
                driverAge: 0, // Assuming `0` is the default driver age for ehailing drivers
                driverSeq: driver.driverSeq,
              };

              return cngeRiskDrvrPkDto;
            });
          }

          // Merge the lists and update `data`
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [
                {
                  ...data.cngeNote.riskList[0],
                  cngeRiskDrvrDtoList: [
                    ...cngeRiskDrvrDtoListNamedDriver,
                    ...cngeRiskDrvrDtoListEhailing
                  ],
                  coverList: [{
                    ...data.cngeNote.riskList[0].coverList[0],
                    vehicleUnNamedDrvrInd: vehicleUnNamedDrvrInd,
                    vehicleAlldrvrInd: vehicleAlldrvrInd,
                    coverId: null,
                    contractId: null,
                    riskId: null,
                    coverDto: null
                  }],
                },
              ],
            },
          };

          if (state.motor.step3.coverage.extracover.extraCoverPackage) {
            let extraCoverPackage = [];
            for (let cv of state.motor.step3.coverage.extracover.extraCoverPackage) {
              extraCoverPackage.push({
                ...cv,
                subCoverCodeDescription: cv.description,
                selectedInd: cv.selected,
                enableInd: cv.enabled,
                subCoverSumInsured: cv.subCoverSumInsured,
                contractId: "",
                riskId: "",
                subcoverId: "",
                coverId: "",

              });
            }
            let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
            data = {
              ...data,
              cngeNote: {
                ...data.cngeNote,
                premiumDueRounded: cngeNotePremium.premiumDueRounded,
                stamp: cngeNotePremium.stamp,
                grossPremium: state.motor.step3.coverage.coverageType.grossPremium,
                annualPremium: state.motor.step3.coverage.coverageType.annualPremium,
                riskList: [
                  {
                    ...data.cngeNote.riskList[0],
                    coverList: [{
                      ...data.cngeNote.riskList[0].coverList[0],
                      subCovPackage: cngeNotePremium.riskList[0].coverList[0].subCovPackage,
                      subCovPackageDesc: cngeNotePremium.riskList[0].coverList[0].subCovPackageDesc,
                      subCoverList: [
                        ...extraCoverPackage,
                      ]
                    }],
                  },
                ],
              },
            };
          }
        }
      }
    }

    if (!isSaveDraft) {
      let premiumInfo = state.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null;
      let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
      if (premiumInfo) {
        const { coverDto, cngeCoverNcdInfoList, ...remainingData } = premiumInfo;

        if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0) {
          if (data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
            if (
              !data.cngeNote.riskList[0].coverList[0].hasOwnProperty('grossPremium') ||
              data.cngeNote.riskList[0].coverList[0].grossPremium === undefined ||
              data.cngeNote.riskList[0].coverList[0].grossPremium === null ||
              data.cngeNote.riskList[0].coverList[0].grossPremium === ''
            ) {
              data.cngeNote.riskList[0].coverList[0].grossPremium = remainingData.grossPremium;
            }

            if (
              !data.cngeNote.riskList[0].coverList[0].hasOwnProperty('subCoverPremium') ||
              data.cngeNote.riskList[0].coverList[0].subCoverPremium === undefined ||
              data.cngeNote.riskList[0].coverList[0].subCoverPremium === null ||
              data.cngeNote.riskList[0].coverList[0].subCoverPremium === ''
            ) {
              data.cngeNote.riskList[0].coverList[0].subCoverPremium = remainingData.subCoverPremium;
            }
          }
        }

        if (data.cngeNote.clientPartner && data.cngeNote.clientPartner.clientPartnerAddress) {
          let clientPartner = JSON.parse(JSON.stringify(data.cngeNote.clientPartner));

          if (!clientPartner.clientPartnerAddress.clientAddress1 || clientPartner.clientPartnerAddress.clientAddress1.trim() === '') {
            clientPartner.clientPartnerAddress.clientAddress1 = 'LOT123';
          }

          if (!clientPartner.clientPartnerAddress.clientPostcode || clientPartner.clientPartnerAddress.clientPostcode.trim() === '') {
            clientPartner.clientPartnerAddress.clientPostcode = '43950';
          }

          data.cngeNote.clientPartner = clientPartner;
        } //TO BE DELETED

        data.cngeNote = {
          ...data.cngeNote,
          ...remainingData,
          cbcAmount: 0.00,
          cbcAdvanceAmt: 0.00,
          referRemarkList: payload.referRemarkList,
          waiveLoadInd: payload.waiveLoadInd,
          waiveExcessInd: payload.waiveExcessInd,
          underwritingInd: payload.underwritingInd,
          agentReferId: payload.agentReferId,
          grossPremium: state.motor.step3.coverage.coverageType.grossPremium,
          annualPremium: state.motor.step3.coverage.coverageType.annualPremium,
          ...(payload.proposalNo && {
            proposalNo: payload.proposalNo,
            contractId: payload.contractId,
            cnoteDate: payload.cnoteDate,
            issueBy: payload.issueBy,
            issuedDate: payload.issuedDate
          }),
          premiumDueRounded: cngeNotePremium.premiumDueRounded,
          stamp: cngeNotePremium.stamp,
          coverId: null,
          contractId: null,
          riskId: null
        };

        //Check and tally the sumInsured values
        if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0 &&
          data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
          if (data.cngeNote.riskList[0].coverList[0].sumInsured !== data.cngeNote.sumInsured) {
            data.cngeNote.riskList[0].coverList[0].sumInsured = data.cngeNote.sumInsured;
          }
        }
      }
    }

    return isRTP ? rtpData : data;
  }

  @Action(UPDATE_ISM_POLICY)
  public updateIsmPolicy({ patchState, getState }: StateContext<SatMotorModel>,
    { payload }: UPDATE_ISM_POLICY) {
    return this.satMotorService.updateIsmPolicy(payload).pipe(
      map((res) => {
        if (res && res.success !== undefined) {
          patchState({
            updateIsmPolicyMessage: res
          });
        } else {
          patchState({
            updateIsmPolicyMessage: res
          });
        }
      })
    );
  }

  @Action(GET_NCD_ENQUIRY)
  getNcdEnquiry(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: GET_NCD_ENQUIRY
  ) {
    const state = getState();
    return this.satMotorService.getNcdEnquiry(payload).pipe(
      map(res => {
        if (res) {
          patchState({
            ncdEnquiryDetails: res
          });
        } else {
          patchState({
            ncdEnquiryDetails: null
          });
        }
      })
    );
  }

  @Action(RESET_SUBSEQUENT_PAGE_DATA)
  public resetSubsequentPageData(
    { patchState, getState }: StateContext<SatMotorModel>
  ) {
    const state = getState();

    const currentStep = state.currentStep;
    const actionType = state.actionType;

    if (currentStep === 1) {
      if (actionType === "ISSUE") {
        patchState({
          motor: {
            ...state.motor,
            step2: defaultSatMotorState.motor.step2,
            step3: defaultSatMotorState.motor.step3,
            step4: defaultSatMotorState.motor.step4,
            step5: defaultSatMotorState.motor.step5,
          },
          data: {
            ...state.data,
            hasShowedOneAllianzPopUp: defaultSatMotorState.data.hasShowedOneAllianzPopUp,
            step2: defaultSatMotorState.data.step2,
            step3: defaultSatMotorState.data.step3,
            step4: defaultSatMotorState.data.step4,
            step5: defaultSatMotorState.data.step5,
          }
        });
      }
    }

    if (currentStep === 2) {
      if (actionType === "ISSUE") {
        patchState({
          motor: {
            ...state.motor,
            step3: defaultSatMotorState.motor.step3,
            step4: defaultSatMotorState.motor.step4,
            step5: defaultSatMotorState.motor.step5,
          },
          data: {
            ...state.data,
            hasShowedOneAllianzPopUp: defaultSatMotorState.data.hasShowedOneAllianzPopUp,
            step3: defaultSatMotorState.data.step3,
            step4: defaultSatMotorState.data.step4,
            step5: defaultSatMotorState.data.step5,
          }
        });
      }
    }
  }

  @Action(SEARCH_POLICY_ENQUIRY)
  public searchPolicyEnquiry({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_POLICY_ENQUIRY) {
    const state = getState();
    return this.satMotorService.searchPolicyEnquiry(payload).pipe(
      map((res) => {
        let searchEnquiryList;
        if (res && res.data) {
          searchEnquiryList = res.data;
        } else {
          searchEnquiryList = [];
        }
        const state = getState();
        patchState({
          policyEnquiryDetails: searchEnquiryList
        });

      }),
    );
  }

  @Action(STORE_PREMIUM_CALCULATION_PAYLOAD)
  public storePremiumCalculationPayload({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_PREMIUM_CALCULATION_PAYLOAD) {
    const state = getState();

    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3.coverage,
            coveragePremiumPayload: payload,
          },
        },
      },
    });
  }

  @Action(GET_DOCUMENT_TYPE_LOV)
  public getDocumentTypeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { }: GET_DOCUMENT_TYPE_LOV) {
    const state = getState();

    return this.satMotorService.getDocumentTypeLov().pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            documentTypeLov: res.data
          });
        }
      }),
    );
  }

  @Action(GET_REASON_CODE_LOV)
  public getReasonCodeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_REASON_CODE_LOV) {
    const state = getState();

    return this.satMotorService.getReasonCodeLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            reasonCodeLov: res.data
          });
        }
      }),
    );
  }

  @Action(GET_VEHICLE_CLASS_USE_LOV)
  public getVehicleClassUseLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_VEHICLE_CLASS_USE_LOV) {
    const state = getState();

    return this.satMotorService.getVehicleClassUseLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            vehicleClassUseLov: res.data
          });
        }
      }),
    );
  }

  @Action(GET_CHECKLIST_LOV)
  public getChecklistLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_CHECKLIST_LOV) {
    const state = getState();

    return this.satMotorService.getChecklistLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            checklistLov: res.data
          });
        }
      }),
    );
  }

}
