import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../../../popup/endorsement-popup/endorsement-popup.component";
import { SharedModule } from '@shared/shared.module';
import { CoverageComponent } from '../../../issuance/coverage/coverage.component';
import { GET_POSTCODE_LOV, SAVE_ENDT_CUSTOMER_PARTNER, SET_ENDT_CUSTOMER_PARTNER_SEARCH_TYPE, STORE_ENDORSEMENT } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { CLIENTTYPE, CONSTANTS, COUNTRIES, IDTYPE, REGEX } from '@shared/enums/index.enum';
import { Subject, takeUntil } from 'rxjs';
import { STEP2 } from '@interfaces/motor';
// import { CpSameAgentInfoComponent } from '@shared/components/modal/cp-same-agent-info/cp-same-agent-info.component';
import { BrnoService } from '@shared/services/brno/brno.service';
import { NricService } from '@shared/services/nric/nric.service';
// import { SmeCertFormComponent } from '../sme-cert-form/sme-cert-form.component';
// import { SstFormDetailsComponent } from '../sst-form-details/sst-form-details.component';
import { GET_POSTCODE_INFO, UPDATE_GEO_INDICATOR } from '@store/sat-motor/sat-motor.action';
import { GET_PRODUCT_CONFIG_LOV } from '@store/sat-motor/sat-motor.action';
import { BranchesMapComponent } from '@shared/components/branches-map/branches-map.component';
import { CustomerPartnerBase } from '@shared/classes/customer-partner.base';
import { CpSameAgentInfoComponent } from '@shared/components/modal/cp-same-agent-info/cp-same-agent-info.component';
import moment from "moment";
import { SstFormDetailsComponent } from "@shared/components/customer-partner/sst-form-details/sst-form-details.component";
import { isEqual } from "lodash";
import { ConfirmationModalComponent } from "@shared/components/modal/confirmation-modal/confirmation-modal.component";
import { emailValidator } from "@shared/classes/validators/email.validator";

@Component({
  selector: 'app-endorsement-customer-partner',
  standalone: true,
  imports: [
    SharedModule,
    CpSameAgentInfoComponent,
    BranchesMapComponent,
    SstFormDetailsComponent
  ],
  templateUrl: './endorsement-customer-partner.component.html',
  styleUrl: './endorsement-customer-partner.component.scss'
})
export class EndorsementCustomerPartnerComponent extends CustomerPartnerBase {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;
  @Select(SatMotorState.endtCpType) endtCpType$;

  isEndorsement: boolean = false;

  @Input() endorsementType: any;

  eInvoiceCustomerPartner: any;
  eInvoiceCustomerPartnerForm: FormGroup;

  isMotor: boolean = false;

  isShow: boolean;
  motorPC: any;
  lovproductConfig: any;
  productAccessListData: any;
  lovPC: any;
  lovGeo: any;

  idType2List: any;
  mkagAgent: any;
  issuanceInfo: any;
  lovReturned: boolean;
  cpSameAgentInfoModal?: NxModalRef<CpSameAgentInfoComponent>;
  contactNoRegex: RegExp = new RegExp(REGEX.CONTACT_NO);
  fixedLineNoRegex: RegExp = new RegExp(REGEX.FIXED_LINE_NO);


  unsubscribe$ = new Subject<void>();

  ///////////////////////////////////////////////////
  // cpSameAgentInfoModal?: NxModalRef<CpSameAgentInfoComponent>;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CoverageComponent>;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;
  @ViewChild("autosize") autosize!: CdkTextareaAutosize;

  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  endorsement_details = true;

  individual = true;
  company = true;
  sst = true;
  sme = true;

  // openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
  //   this.templateDialogRef = this.dialogService.open(template, {
  //     height: config.height || '500px',
  //     width: config.width || '780px',
  //   });
  // }

  // closeDialog(): void {
  //   this.templateDialogRef?.close();
  // }

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
    private nricService: NricService,
    private brnoService: BrnoService,
  ) { 
    super();
  }

  ngOnInit() {

    this.eInvoiceCustomerPartner = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails?.eInvoiceCustomerPartner);
    console.log("eInvoiceCustomerPartner:", this.eInvoiceCustomerPartner);

    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    this.mkagAgent = endtVehiclePolicyData.mkagAgent;
    console.log("endtVehiclePolicyData:", endtVehiclePolicyData);

    this.issuanceInfo = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.issuance);

    if (!this.lov?.clientPostcodeList) {
      this.store.dispatch(new GET_POSTCODE_LOV({
        listTypes: ["clientPostcodeList"],
        agentCode: this.mkagAgent.agentCode,
        productType: "COMM",
        productCode: this.issuanceInfo.issuance.productCode,
      })).subscribe(() => {
        this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
        this.lovReturned = true;
      });
    } else {
      this.lovReturned = true;
    }

    if (this.eInvoiceCustomerPartner) {
      this.cpDetails = this.eInvoiceCustomerPartner;

      this.loadEInvoiceCustomerPartnerForm();

      if(this.isCompany) {
        this.initCompanyValidators();
      }

      this.onChangeMobileNo(true);
      this.onChangefixedLineNo();

    } else if (endtVehiclePolicyData) {

      let customerData = endtVehiclePolicyData?.clientPartner;
      console.log("customerData:", customerData);

      if (customerData) {
        this.cpDetails = customerData;

        this.loadEInvoiceCustomerPartnerForm();
        
        console.log("eInvoiceCustomerPartnerForm:", this.eInvoiceCustomerPartnerForm);

        if(this.isCompany) {
          this.initCompanyValidators();
        }

        this.onChangeMobileNo(true);
        this.onChangefixedLineNo();

      } else {
        console.error("customerData cannot populate eInvoiceCustomerPartnerForm");
      }
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }
    this.initIdType2List();
    this.onChangeIdType2();

  }

  onBlurIdValue2(idValue2) {
    console.log("idValue2: ", idValue2)
    let idValue2Control = this.eInvoiceCustomerPartnerForm.controls['idValue2'];
    let idType2Control = this.eInvoiceCustomerPartnerForm.controls['idType2'];
    let idValue2Val = idValue2Control.value;
    let idType2Val = idType2Control.value;

    if (idValue2Val) {
      idValue2Control.clearValidators();
      switch (idType2Val) {
        case IDTYPE.NRIC:
          if (idValue2Val.length === 12) {
            const isValidNric = this.nricService.isNRICValid(idValue2Val);
            if (!isValidNric) {
              idValue2Control.setErrors({ 'wrongNricFormat': true });
            }
          } else {
            idValue2Control.setErrors({ 'wrongNricFormat': true });
          }
          break;

        case IDTYPE.NEW_BR_NO:
          if (idValue2Val.length < 12) {
            idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
          } else {
            try {
              const isValidNewBrNo = this.brnoService.isBRNOValid(idValue2Val);
              if (!isValidNewBrNo) {
                idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
              }
            } catch (err) {
              idValue2Control.setErrors({ 'wrongNewBrnoFormat': true });
            }

          }
          break;
      }
      idType2Control.setValidators([Validators.required]);
    } else {
      idType2Control.removeValidators([Validators.required]);
    }
    idType2Control.updateValueAndValidity();
  }

  initIdType2List() {
    if (this.isIndividual) {
      if (this.cpDetails.idType1 === IDTYPE.OLD_IC) {
        this.idType2List = [
          { code: IDTYPE.NRIC, descp: IDTYPE.NRIC_DESCP },
          { code: IDTYPE.POL, descp: IDTYPE.POL_DESCP }
        ]
      }
    } else {
      if (this.cpDetails.idType1 === IDTYPE.BR_NO) {
        this.idType2List = [
          { code: IDTYPE.NEW_BR_NO, descp: IDTYPE.NEW_BR_NO_DESCP }
        ]
      } else {
        this.idType2List = [
          { code: IDTYPE.BR_NO, descp: IDTYPE.BR_NO_DESCP }
        ]
      }
    }
  }

  onChangeIdType2() {
    this.eInvoiceCustomerPartnerForm.controls['idType2'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        let idValue2Control = this.eInvoiceCustomerPartnerForm.controls['idValue2'];
        let idValue2Val = idValue2Control.value;
        if (value && !idValue2Val) {
          idValue2Control.setErrors({ 'required': true });
        }
      });
  }

  onChangeSmeStartDate() {
    this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        let smeRegisteredInd = this.eInvoiceCustomerPartnerForm.controls['smeRegisteredInd'].value;
        if (smeRegisteredInd === 'Y') {
          let startDate = value ? new Date(value) : null;
          let startDateAfter2Year = startDate ? new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate() - 1) : null;
          const currentDate = new Date();

          currentDate.setHours(0, 0, 0, 0);

          if (startDate > currentDate) {
            this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].setErrors({ 'invalidSMEStartDate': true });
          } else {
            this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setValue(startDateAfter2Year);
          }
        }
      });
  }

  onChangeSmeEndDate() {
    this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        let smeRegisteredInd = this.eInvoiceCustomerPartnerForm.controls['smeRegisteredInd'].value;
        if (smeRegisteredInd === 'Y') {
          let startDate = this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].value ? new Date(this.eInvoiceCustomerPartnerForm.controls['smeStartDate'].value) : null;
          let startDateAfter2Year = startDate ? new Date(startDate.getFullYear() + 2, startDate.getMonth() - 1, startDate.getDate() - 1) : null;
          let endDate = new Date(value);

          if (startDate && endDate) {
            if (endDate < startDate) {
              this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setErrors({ 'smeSDLaterThanSmeED': true });
            }

            if (endDate > startDateAfter2Year) {
              this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setErrors({ 'smeEDMoreThanTwoYear': true });
            }
          } else {
            if (!startDate && endDate) {
              this.eInvoiceCustomerPartnerForm.controls['smeEndDate'].setErrors({ 'smeStartDateEmpty': true });
            }
          }
        }

      });
  }

  onChangeAge() {
    console.log("age: ", this.eInvoiceCustomerPartnerForm.controls['age'].errors);
  }

  onChangeMobileNo(fromInit) {
    let mobileNo = this.eInvoiceCustomerPartnerForm.controls['mobileNo'].value;
    let mobileNoCode = this.eInvoiceCustomerPartnerForm.controls['mobileNoCode'].value;
    if(mobileNo) {
      if(this.isCompany) {
        this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].removeValidators([Validators.required]);
      }
      this.eInvoiceCustomerPartnerForm.controls['mobileNoCode'].setValidators([Validators.required]);

      if(!this.contactNoRegex.test(mobileNo)) {
        this.eInvoiceCustomerPartnerForm.controls['mobileNo'].setErrors({ pattern: true });
        return;
      }

      if(!fromInit && mobileNo && mobileNoCode) {
        this.checkIsSameAgentMobile();
      }
    }else {
      if(this.isCompany) {
        this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].addValidators([Validators.required]);
      }
    }
    this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].updateValueAndValidity();
    this.eInvoiceCustomerPartnerForm.controls['mobileNoCode'].updateValueAndValidity();
    
  }

  onChangefixedLineNo() {
    let fixedLineNo = this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].value;
    this.eInvoiceCustomerPartnerForm.controls['fixedLineNoCode'].clearValidators();
    if(fixedLineNo) {
      if(this.isCompany) {
        this.eInvoiceCustomerPartnerForm.controls['mobileNo'].removeValidators([Validators.required]);
      }
      this.eInvoiceCustomerPartnerForm.controls['fixedLineNoCode'].setValidators([Validators.required]);

      if(!this.fixedLineNoRegex.test(fixedLineNo)) {
        this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].setErrors({ pattern: true });
        return;
      }

    }else {
      this.eInvoiceCustomerPartnerForm.controls['mobileNo'].addValidators([Validators.required]);
    }
    this.eInvoiceCustomerPartnerForm.controls['mobileNo'].updateValueAndValidity();
    this.eInvoiceCustomerPartnerForm.controls['fixedLineNoCode'].updateValueAndValidity();
  }

  onChangeFaxNo() {
    let faxNo = this.eInvoiceCustomerPartnerForm.controls['faxNo'].value;
    this.eInvoiceCustomerPartnerForm.controls['faxNoCode'].clearValidators();
    if(faxNo) {
      this.eInvoiceCustomerPartnerForm.controls['faxNoCode'].setValidators([Validators.required]);

      if(!this.contactNoRegex.test(faxNo)) {
        this.eInvoiceCustomerPartnerForm.controls['faxNo'].setErrors({ pattern: true });
        return;
      }

    }
    this.eInvoiceCustomerPartnerForm.controls['faxNoCode'].updateValueAndValidity();  
  }

  onChangePostcode() {
    let postcode = this.eInvoiceCustomerPartnerForm.controls['postCode'].value;

    this.store.dispatch(new GET_POSTCODE_INFO(postcode)).subscribe(state => {
      let postcodeInfo = state.SatMotorState.data.postCodeInfo;
      this.eInvoiceCustomerPartnerForm.patchValue({
        state: postcodeInfo.state,
        city: postcodeInfo.city,
        country: "MAL"
      });
    });
  }

  populateMapForm(data: any) {
    
    //reset first before populate
    this.eInvoiceCustomerPartnerForm.patchValue({
      unitNo: '',
      address1: '',
      address2: '',
      address3: '',
      postCode: '',
      city: '',
      state: '',
      country: '',
      clientGeoLatitude : '',
      clientGeoLongitude : '',
      clientGeoPlaceId : '',
      clientGeoPlusCode : '',
      clientGeoFormattedAddress : ''
    });

    this.eInvoiceCustomerPartnerForm.patchValue({
      unitNo: data.unitNumber,
      address1: data.clientAddress1,
      address2: data.clientAddress2,
      address3: data.clientAddress3,
      postCode: data.clientPostcode,
      clientGeoLatitude : data.clientGeoLatitude,
      clientGeoLongitude : data.clientGeoLongitude,
      clientGeoPlaceId : data.clientGeoPlaceId,
      clientGeoPlusCode : data.clientGeoPlusCode,
      clientGeoFormattedAddress : data.clientGeoFormattedAddress
    });

    if (data.clientPostcode) {
      this.onChangePostcode();
    } else {
      this.eInvoiceCustomerPartnerForm.patchValue({
        city: data.clientCityDesc,
        state: data.clientStateDesc,
        country: data.clientCountryDesc,
      });
    }
  }

  /////////////////////////////////////////////////////////////

  loadEInvoiceCustomerPartnerForm() {
    this.eInvoiceCustomerPartnerForm =this.fb.group({
      // name:[this.cpDetails.clientName ? this.cpDetails.clientName : '', Validators.required],
      // nationality: [this.cpDetails.clientNationality ? this.cpDetails.clientNationality : ''],
      idType2:[this.cpDetails.idType2 ? this.cpDetails.idType2 : ''],
      idValue2:[this.cpDetails.idValue2 ? this.cpDetails.idValue2 : ''],
      tinNo:[this.cpDetails.clientTinNo ? this.cpDetails.clientTinNo : '', [Validators.pattern("^[a-zA-Z0-9]*$")]],
      // gender:[this.cpDetails.clientGender ? this.cpDetails.clientGender : ''],
      // maritalStatus:[this.cpDetails.clientMaritalStatus ? this.cpDetails.clientMaritalStatus : ''],
      // dob:[this.cpDetails.clientDateofBirth ? this.cpDetails.clientDateofBirth : ''],
      // age:[{value:this.cpDetails.clientAge ? this.cpDetails.clientAge : '', disabled : true}],
      mobileNoCode:[this.cpDetails.clientMobileCode1 ? this.cpDetails.clientMobileCode1 :''],
      mobileNo:[this.cpDetails.clientMobileNo1 ? this.cpDetails.clientMobileNo1 : '', [Validators.required]],
      fixedLineNoCode:[this.cpDetails.clientFixedLineNoCode1 ? this.cpDetails.clientFixedLineNoCode1 : ''],
      fixedLineNo:[this.cpDetails.clientFixedLinedNo1 ? this.cpDetails.clientFixedLinedNo1 : ''],
      faxNoCode:[this.cpDetails.clientFaxNoCode1 ? this.cpDetails.clientFaxNoCode1 : ''],
      faxNo:[this.cpDetails.clientFaxNo1 ? this.cpDetails.clientFaxNo1 : ''],
      email:[this.cpDetails.clientEmail ? this.cpDetails.clientEmail : '', [Validators.required, emailValidator]],
      showMapSwitcher:[true],
      unitNo:[this.cpDetails.clientPartnerAddress.clientUnitNo ? this.cpDetails.clientPartnerAddress.clientUnitNo : ''],
      address1:[this.cpDetails.clientPartnerAddress.clientAddress1Tmp ? this.cpDetails.clientPartnerAddress.clientAddress1Tmp : '', Validators.required],//, Validators.required],
      address2:[this.cpDetails.clientPartnerAddress.clientAddress2 ? this.cpDetails.clientPartnerAddress.clientAddress2 : ''],
      address3:[this.cpDetails.clientPartnerAddress.clientAddress3 ? this.cpDetails.clientPartnerAddress.clientAddress3 : ''],
      postCode:[this.cpDetails.clientPartnerAddress.clientPostcode ? this.cpDetails.clientPartnerAddress.clientPostcode : '', Validators.required],
      city:[{value: this.cpDetails.clientPartnerAddress.clientCity ? this.cpDetails.clientPartnerAddress.clientCity : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      state:[{value: this.cpDetails.clientPartnerAddress.clientState ? this.cpDetails.clientPartnerAddress.clientState : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      country:[{value: this.cpDetails.clientPartnerAddress.clientCountry ? this.cpDetails.clientPartnerAddress.clientCountry : '', disabled: true}], // need to change disabled = false after connect to BE to get the postcode details
      addressType:[this.cpDetails.clientPartnerAddress.clientAddressType ? this.cpDetails.clientPartnerAddress.clientAddressType : this.getDefaultAddressType()],
      clientGeoLatitude: [this.cpDetails.clientPartnerAddress.clientGeoLatitude ? this.cpDetails.clientPartnerAddress.clientGeoLatitude : ''],
      clientGeoLongitude: [this.cpDetails.clientPartnerAddress.clientGeoLongitude ? this.cpDetails.clientPartnerAddress.clientGeoLongitude : ''],
      clientGeoPlaceId: [this.cpDetails.clientPartnerAddress.clientGeoPlaceId ? this.cpDetails.clientPartnerAddress.clientGeoPlaceId : ''],
      clientGeoPlusCode: [this.cpDetails.clientPartnerAddress.clientGeoPlusCode ? this.cpDetails.clientPartnerAddress.clientGeoPlusCode : ''],
      clientGeoFormattedAddress: [this.cpDetails.clientPartnerAddress.clientGeoFormattedAddress ? this.cpDetails.clientPartnerAddress.clientGeoFormattedAddress : '']
    });
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state) {
        if (state.eInvoiceCustomerPartner) {
          this.eInvoiceCustomerPartnerForm.patchValue(state.eInvoiceCustomerPartner);
        }
      }
    });
  }

  search_mode_address = true;
  changeSearchMode(event: any) {
    // debugger;
    if (event.value == "address") {
      this.search_mode_address = true;
    } else {
      this.search_mode_address = false;
    }
  }

  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;

  // openPopup(popup_type: any) {
  //   this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
  //     ariaLabel: "A simple dialog",
  //     showCloseIcon: false,
  //     width: '400px',
  //     data: { popup_type: popup_type },
  //   });
  // }


  // openPopup3(popup_type: any) {
  //   this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
  //     ariaLabel: "A simple dialog",
  //     showCloseIcon: false,
  //     data: { popup_type: popup_type },
  //   });
  // }

  getErrorType(errors) {
    if (errors.wrongNricFormat) {
      return 'wrongNricFormat';
    }

    if (errors.wrongNewBrnoFormat) {
      return 'wrongNewBrnoFormat';
    }

    if (errors.invalidDOB) {
      return 'invalidDOB';
    }

    if (errors.required) {
      return 'required';
    }

    return false;
  }

  showDOB() {
    return this.cpDetails.idType1 === IDTYPE.NRIC;
  }

  showAge() {
    return this.cpDetails.idType1 === IDTYPE.NRIC;
  }

  showId2() {
    return this.cpDetails.idType2 && this.cpDetails.idValue2;
  }

  getDefaultAddressType() {
    return this.isCompany ? 'O' : 'R';
  }

  openCPSameAgentInfoModal(type) {
    let message = "";
    if(type === "MOBILE") {
      message = "The mobile number you have keyed in is an agent's mobile number. If you proceed with this number, it is deemed that you have obtained the customer's consent to use this number as the contact number for this policy. Please confirm if this is correct or otherwise key-in the mobile number provided by the customer.";
    }else {
      message = "The email you have keyed in is an agent's email. If you proceed with this email, it is deemed that you have obtained the customer's consent to use this email as the contact email for this policy. Please confirm if this is correct or otherwise key-in the email provided by the customer.";
    }

    this.cpSameAgentInfoModal = this.dialogService.open(CpSameAgentInfoComponent, {
      showCloseIcon: false,
      data: {
        message: message
      },
      width: '400px',
    });

    this.cpSameAgentInfoModal.afterClosed().subscribe(result => {
      if(result != "OK") {
        switch(type) {
          case "MOBILE":
            this.eInvoiceCustomerPartnerForm.controls['mobileNo'].setValue('');
          break;
          case "EMAIL":
            this.eInvoiceCustomerPartnerForm.controls['email'].setValue('');
          break;
        }
      }
    });
  }

  initCompanyValidators() {
    this.eInvoiceCustomerPartnerForm.controls['fixedLineNo'].addValidators([Validators.required]);
    this.eInvoiceCustomerPartnerForm.controls['tinNo'].addValidators(Validators.required);
  }

  checkIsSameAgentMobile() {
    let mobileNo = this.eInvoiceCustomerPartnerForm.controls['mobileNo'].value;
    let mobileNoCode = this.eInvoiceCustomerPartnerForm.controls['mobileNoCode'].value;

    if(!this.isCompany && mobileNoCode == this.mkagAgent.agentMobileCode1 && mobileNo == this.mkagAgent.agentMobileNo1) {
      this.openCPSameAgentInfoModal("MOBILE");
    }
  }

  checkIsSameAgentEmail() {
    let email = this.eInvoiceCustomerPartnerForm.controls['email'].value;
    if(!this.isCompany && email.toUpperCase() == this.mkagAgent.agentEmail.toUpperCase()) {
      this.openCPSameAgentInfoModal("EMAIL");
    }
  }

  onChangeMobileNoCode() {
    let mobileNo = this.eInvoiceCustomerPartnerForm.controls['mobileNo'].value;
    let mobileNoCode = this.eInvoiceCustomerPartnerForm.controls['mobileNoCode'].value;
    if(mobileNo && mobileNoCode) {
      this.checkIsSameAgentMobile();
    }
  }

  onChangeEmail() {
    let email = this.eInvoiceCustomerPartnerForm.controls['email'].value;
    if(email) {
      this.checkIsSameAgentEmail();
    }
    this.eInvoiceCustomerPartnerForm.controls['email'].updateValueAndValidity();
    
  }

  get idValue2ErrorType() {
    let errors = this.eInvoiceCustomerPartnerForm.controls['idValue2'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  get dobErrorType() {
    let errors = this.eInvoiceCustomerPartnerForm.controls['dob'].errors;
    if (!errors) {
      return false;
    }
    return this.getErrorType(errors);
  }

  get isOldIC() {
    return this.cpDetails.idType1 === IDTYPE.OLD_IC;
  }

  get isNric() {
    return this.cpDetails.idType1 === IDTYPE.NRIC;
  }

  storeEndorsementDetailsData() {
    let endtCpType = this.store.selectSnapshot(SatMotorState.endtCpType);
    if (this.eInvoiceCustomerPartnerForm.valid) {
      if (endtCpType !== 'FOUND') {
        let eInvoiceCustomerPartner = this.prepareCPPayload();
        console.log('Saving eInvoice Customer Partner Data:', eInvoiceCustomerPartner);
        return new Promise((resolve) => {
          this.store.dispatch(new SAVE_ENDT_CUSTOMER_PARTNER(eInvoiceCustomerPartner)).subscribe((state) => {
            let saveEndtCPRes = state.SatMotorState.endorsement.step2.endorsementDetails.eInvoiceCustomerPartner;
            if (saveEndtCPRes) {
              this.store.dispatch(new SET_ENDT_CUSTOMER_PARTNER_SEARCH_TYPE("FOUND"));
            }
            resolve(saveEndtCPRes);
          });
        });
      } else {
        return true;
      }
    } else {
      console.error('eInvoice Customer Partner form is invalid!');
      // this.logFormErrors(this.eInvoiceCustomerPartnerForm);
      this.eInvoiceCustomerPartnerForm.markAllAsTouched();
      return false;
    }
  }

  hasChangedFields() {
    const formValue = this.eInvoiceCustomerPartnerForm.getRawValue();

    const newValueObj = {
      ...this.cpDetails,
      clientTinNo: formValue.tinNo,
      idType2: formValue.idType2,
      idValue2: formValue.idValue2,
      clientSstRegInd: formValue.sstRegisteredInd === 'Y' ? true : false,
      clientSstRegNo: formValue.sstNo,
      clientMobileCode1: formValue.mobileNoCode,
      clientMobileNo1: formValue.mobileNo,
      clientEmail: formValue.email,
      clientPartnerAddress: {
        ...this.cpDetails.clientPartnerAddress,
        clientUnitNo: formValue.unitNo,
        clientAddress1: formValue.address1,
        clientAddress2: formValue.address2,
        clientAddress3: formValue.address3,
        clientAddressType: formValue.addressType,
        clientCity: formValue.city,
        clientCountry: formValue.country,
        clientFax1Code: formValue.faxNoCode,
        clientFax1No: formValue.faxNo,
        clientPostcode: formValue.postCode,
        clientState: formValue.state,
        clientGeoLatitude : formValue.clientGeoLatitude,
        clientGeoLongitude : formValue.clientGeoLongitude,
        clientGeoPlaceId : formValue.clientGeoPlaceId,
        clientGeoPlusCode : formValue.clientGeoPlusCode,
        clientGeoFormattedAddress : formValue.clientGeoFormattedAddress
      }
    };

    console.log("is Equal: ", isEqual(this.cpDetails, newValueObj));

    return !isEqual(this.cpDetails, newValueObj);
  }

  prepareCPPayload() {
    const date = new Date();
    const effectiveDate = moment(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'YYYY-M-D').format('YYYY-MM-DD');
    const formValue = this.eInvoiceCustomerPartnerForm.getRawValue();
    const user = this.store.selectSnapshot<string>((state) => state.UserState.user.userId);
    const payload = {
      clientPartner: {
        ...this.cpDetails,
        clientTinNo: formValue.tinNo,
        idType2: formValue.idType2,
        idValue2: formValue.idValue2,
        clientSstRegInd: formValue.sstRegisteredInd === 'Y' ? true : false,
        clientSstRegNo: formValue.sstNo,
        clientMobileCode1: formValue.mobileNoCode,
        clientMobileNo1: formValue.mobileNo,
        clientEmail: formValue.email,
        clientPartnerAddress: {
          ...this.cpDetails.clientPartnerAddress,
          clientUnitNo: formValue.unitNo,
          clientAddress1: formValue.address1,
          clientAddress2: formValue.address2,
          clientAddress3: formValue.address3,
          clientAddressType: formValue.addressType,
          clientCity: formValue.city,
          clientCountry: formValue.country,
          clientPhoneCode: formValue.fixedLineNoCode,
          clientPhoneNo: formValue.fixedLineNo,
          clientFax1Code: formValue.faxNoCode,
          clientFax1No: formValue.faxNo,
          clientPostcode: formValue.postCode,
          clientState: formValue.state,
          clientGeoLatitude: formValue.clientGeoLatitude,
          clientGeoLongitude: formValue.clientGeoLongitude,
          clientGeoPlaceId: formValue.clientGeoPlaceId,
          clientGeoPlusCode: formValue.clientGeoPlusCode,
          clientGeoFormattedAddress: formValue.clientGeoFormattedAddress
        }
      },
      mkagAgent: this.mkagAgent,
      effectiveDate: effectiveDate,
      productType: 'MT',
      operator: user
    };

    return payload;
  }

}
