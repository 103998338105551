import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { SharedModule } from "../../shared/shared.module";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { CoverageComponent } from "../coverage/coverage.component";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../popup/endorsement-popup/endorsement-popup.component";


@Component({
  selector: "app-endorsement-pdpa",
  standalone: true,
  imports: [SharedModule, ProgressBarComponent],
  templateUrl: "./endorsement-pdpa.component.html",
  styleUrl: "./endorsement-pdpa.component.scss",
})
export class EndorsementPdpaComponent {
  // data: any;
  tableHolder: any;
  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  endorsement_details = true;

  @ViewChild("autosize") autosize!: CdkTextareaAutosize;
  makeModel = "PERODUA MYVI 1.3";

  // temporary implementation. future to implement form
  characterCount: number = 0;
  maxlength = 100;
  onKeyUp(event: any) {
    this.characterCount = event.target.value.length + this.makeModel.length;
  }

  endorsement_table = [
    {
      no: "1",
      type: "CHANGE OF ENGINE",
      effective_date: "26/12/2023",
      exp_date: "27/12/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "2",
      type: "EXTENSION PRORATE",
      effective_date: "20/12/2023",
      exp_date: "19/12/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "3",
      type: "EXTRA BENEFITS",
      effective_date: "15/12/2023",
      exp_date: "14/12/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "4",
      type: "NAMED DRIVER",
      effective_date: "29/10/2023",
      exp_date: "28/10/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
    {
      no: "5",
      type: "NAMED DRIVER",
      effective_date: "09/05/2023",
      exp_date: "08/05/2024",
      si: "60,000.00",
      premium_due: "1,968.92",
      vehicle_no: "WA8012L",
    },
  ];

  count = 210;
  page = 1;
  perPage = 10;

  prevPage() {
    this.page--;
  }

  nextPage() {
    this.page++;
  }

  goToPage(n: number) {
    this.page = n;
  }

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CoverageComponent>;

  // For Coverage table
  show_comprehensive = true;
  show_tp = false;
  show_tpft = false;

  individual = true;
  company = true;
  sst = true;
  sme = true;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  comprehensive(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {
      height: "500px",
      width: "780px",
      maxWidth: "780px",
    });
  }

  tp(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  tpft(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {});
    ``;
  }

  policyTable(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef3, {
      height: "500px",
    });
    ``;
  }

  closeTemplateDialog() {
    this.templateDialogRef ?.close();
  }

  makemodel(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef2, {
      height: "500px",
      width: "920px"
    });
  }

  search_mode_address = true;
  changeSearchMode(event: any) {
    // debugger;
    if (event.value == "address") {
      this.search_mode_address = true;
    } else {
      this.search_mode_address = false;
    }
  }


  // Validation //

  vehicleFormControl = new FormControl('', [
    Validators.required,
  ]);

  makemodelFormControl = new FormControl('', [
    Validators.required,
  ]);

  capacityFormControl = new FormControl('', [
    Validators.required,
  ]);

  numberseatFormControl = new FormControl('', [
    Validators.required,
  ]);

  chassisFormControl = new FormControl('', [
    Validators.required,
  ]);

  engineFormControl = new FormControl('', [
    Validators.required,
  ]);

  loadcompanyFormControl = new FormControl('', [
    Validators.required,
  ]);

  makemodeldesFormControl = new FormControl('', [
    Validators.required,
  ]);

  vehiclesumFormControl = new FormControl('', [
    Validators.required,
  ]);

  thailandstartdateFormControl = new FormControl('', [
    Validators.required,
  ]);

  thailandenddateFormControl = new FormControl('', [
    Validators.required,
  ]);

  planunitFormControl = new FormControl('', [
    Validators.required,
  ]);

  amountdayFormControl = new FormControl('', [
    Validators.required,
  ]);

  amountFormControl = new FormControl('', [
    Validators.required,
  ]);

  noclaimdiscountFormControl = new FormControl('', [
    Validators.required,
  ]);

  noclaimdiscountvehicleFormControl = new FormControl('', [
    Validators.required,
  ]);

  idtype2FormControl = new FormControl('', [
    Validators.required,
  ]);

  idno2FormControl = new FormControl('', [
    Validators.required,
  ]);

  mobilenoFormControl = new FormControl('', [
    Validators.required,
  ]);

  fixedlinenoFormControl = new FormControl('', [
    Validators.required,
  ]);

  faxnoFormControl = new FormControl('', [
    Validators.required,
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
  ]);

  address1FormControl = new FormControl('', [
    Validators.required,
  ]);

  postcodeFormControl = new FormControl('', [
    Validators.required,
  ]);

  taxFormControl = new FormControl('', [
    Validators.required,
  ]);

  sstFormControl = new FormControl('', [
    Validators.required,
  ]);


  componentDialogRef1?: NxModalRef<EndorsementPopupComponent>;
  
  openPopup(popup_type: any) {
    this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopup3(popup_type: any) {
  this.componentDialogRef1 = this.dialogService.open(EndorsementPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      data: { popup_type: popup_type },
    });
  }


}
