import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { Select, Store } from '@ngxs/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { GET_PRODUCT_LIST } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-issuance-information',
  templateUrl: './issuance-information.component.html',
  styleUrl: './issuance-information.component.scss'
})
export class IssuanceInformationComponent implements OnInit, OnChanges {

  @Select(SatMotorState.motorAgentDetail) agentDetail$;

  @Input() progressType: any;
  @Input() issuanceInfo: any;
  @Input() isApproval: any;
  @Input() viewQuotation: any;
  @Input() viewPolicyDetails:any;
  @Input() isTypeIssuance: any;
  @Input() inputAgentInfo: any;
  @Input() isUpdateJPJScreen: any;
  @Input() productList: any;
  @Input() cngeNote : any;
  

  isProgressTypeCN: boolean = false;
  isProgressTypeENDST: boolean = false;
  isProgressTypeRTP: boolean = false;
  issuanceData: any = {};
  pdpaData: any = {};
  coverNoteTypeList: any;
  idTypeList: any;
  commonLov: any;
  agentData: any = {};

  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.commonLov = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov);
    this.coverNoteTypeList = this.commonLov?.cnoteTypeList;
    console.log("idd",this.cngeNote);

    if (this.progressType) {
      this.isProgressTypeCN = this.progressType === 'cn';
      this.isProgressTypeENDST = this.progressType === 'endorsement';
      this.isProgressTypeRTP = this.progressType === 'rtp';
    }

    if(!this.isApproval) {
      this.agentDetail$.subscribe((state) => {
        if (state) {
          this.agentData = state;
        }
      });
    }else {
      this.agentData = this.inputAgentInfo;
    }


    if (!this.store.selectSnapshot<any>(state => state.SatMotorState?.lov?.productList)) {
      let productListPayload = {
        lob: 'MT',
        agentCode: this.agentData?.agentCode
      };
      this.store.dispatch(new GET_PRODUCT_LIST(productListPayload)).subscribe(() => {
        this.prepareProductListLov();
      });
    } else {
      this.prepareProductListLov();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.["issuanceInfo"]?.firstChange) {
      this.issuanceData = this.issuanceInfo.issuance;
      console.log(this.issuanceData);
      this.populateIssuance();
    }
  }

  prepareProductListLov(): any {
    if (!this.productList) {
      const lov = this.store.selectSnapshot<any>(state => state.SatMotorState?.lov);
      this.productList = lov?.productList;
    }

    if (this.issuanceInfo.issuance) {
      this.issuanceData = this.issuanceInfo.issuance;
      console.log(this.issuanceData);
      this.populateIssuance();
    }

    if (this.issuanceInfo.pdpa) {
      this.pdpaData = this.issuanceInfo.pdpa;
      console.log(this.pdpaData);
      this.populatePdpaData();
    }
  }


  populateIssuance() {

    let newIssuanceData = { ...this.issuanceData };
    newIssuanceData.effectiveDate = this.getFormattedDate(this.issuanceData.effectiveDate) || this.getFormattedDate(this.issuanceData.policyEffectiveDate);
    newIssuanceData.expiryDate = this.getFormattedDate(this.issuanceData.expiryDate) || this.getFormattedDate(this.issuanceData.policyExpiryDate);
    if(this.issuanceData.equotationCreatedDate) {
      newIssuanceData.equotationCreatedDate = this.getFormattedDate(this.issuanceData.equotationCreatedDate);
    }
    this.issuanceData = newIssuanceData;

    const productName = this.productList.find(e => e.code === this.issuanceData.productCode);
    if (productName) {
      this.issuanceData.productName = productName.descp;
    } else {
      console.log("Product code not found in the productList.");
    }

    const getCoverNoteType = this.coverNoteTypeList.find(e => e.code === this.issuanceData.cnoteType);
    if (getCoverNoteType) {
      this.issuanceData.coverNoteType = getCoverNoteType.descp;
    } else {
      if (this.issuanceData.cnoteType === 'NWOO/RN') {
        this.issuanceData.coverNoteType = 'Renewal / New Business - Old Vehicle, Old Registration';
      }
      console.log("CoverNote code not found in the coverNoteTypeList.");
    }

    if(this.issuanceData.endtMethod) {
      this.issuanceData.endtMethodDesc = this.commonLov.endtCalcMethodList.find(e => e.code === this.issuanceData.endtMethod)?.descp;
    }
  }

  populatePdpaData() {
    let newPdpaData = { ...this.pdpaData };
    newPdpaData.edocConsent = this.getConsentValue(this.pdpaData.consentDetails.edocConsentInd);
    newPdpaData.einvoiceConsentInd = this.getConsentValue(this.pdpaData.consentDetails.einvoiceConsentInd);
    this.pdpaData = newPdpaData;

  }

  getFormattedDate(date) {
    if (date) {
      const originalDate = new Date(date);
      const formattedDate = originalDate.toLocaleDateString('en-MY');
      return formattedDate;
    }
    return date;
  }

  getConsentValue(value) {
    return (value) ? 'YES' : 'NO';
  }

  formatData(value: string): string {
    if (!value || value === '') {
        return 'N/A';
    }
    return value.toUpperCase();
  }
}
