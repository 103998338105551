import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnquiryService } from './enquiry.service';

import { GET_ENQUIRY_DETAILS } from './enquiry.action';

interface EnquiryModel {
    enquiryDetail: any;
  }

@Injectable()
@State<EnquiryModel>({
  name: 'EnquiryState',
})
export class EnquiryState {
  constructor(
    private enquiryService: EnquiryService,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
  ) {}


  @Action(GET_ENQUIRY_DETAILS)
  public getLOV({ patchState, getState }: StateContext<EnquiryModel>) {
    const state = getState();
    return this.enquiryService.getEnquiryDetails(null).pipe(
      map((res) => {
        patchState({
            enquiryDetail: res
        });
      }),
    );
  }

}