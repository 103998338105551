<div *ngIf="!showEdit">
  <div *ngIf="popup.button == 'one-button'" class="std-ta-center">
    <div nxModalContent>
      <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
      <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-light">
        {{ message }}
      </p>
    </div>

    <div class="button_area align_center">
      <!-- <button nxModalClose nxButton="secondary" type="button">NO</button> -->
      <button nxModalClose nxButton="primary" type="button">OK</button>
    </div>
  </div>

  <div *ngIf="popup.button == 'two-button'">
    <div nxModalContent>
      <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
      <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-light">
        {{ message }}
      </p>
    </div>

    <div class="button_area2">
      <button nxModalClose nxButton="secondary" type="button">Close</button>
      <button nxModalClose nxButton="primary" type="button">Proceed</button>
    </div>
  </div>
</div>

<!-- edit modal -->
<ng-container #editCP *ngIf="showEdit">
  <!-- <h3 nxModalTitle>Edit Customer Partner Information</h3> -->
  <h3 class="cp_popup-title nx-margin-bottom-s">
    Edit Customer Partner Information
  </h3>
  <div nxModalContent>
    

    <!-- ======================= Large and medium view ====================== -->
    <div class="nx-hidden-xs nx-hidden-s">
      <div nxLayout="grid nopadding">
        <div>
          <div nxRow class="nx-margin-top-m">
            <div nxCol="12">
              <nx-formfield label="Nationality">
                <nx-dropdown [valueFormatter]="toText" value="Thailand">
                  <nx-dropdown-item [value]="'Thailand'"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12">
              <nx-formfield label="Name">
                <input nxInput #inputToCount maxlength="200" />
                <span nxFormfieldHint>
                  <span *ngIf="inputToCount.value?.length === 0">
                    max. 200 characters
                  </span>
                  <span *ngIf="inputToCount.value.length > 0">
                    {{ 200 - (inputToCount.value.length || 0) }}
                    characters remaining
                  </span>
                </span>
              </nx-formfield>
            </div>
          </div>
          <div nxRow *ngIf="company">
            <div nxCol="12">
              <nx-formfield label="Country of Incorporation">
                <nx-dropdown value="Malaysia" [valueFormatter]="toText">
                  <nx-dropdown-item value="Malaysia"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>

          <div nxRow *ngIf="company">
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="ID Type 2">
                <nx-dropdown value="NRIC" [valueFormatter]="toText">
                  <nx-dropdown-item [value]="'NRIC'"> </nx-dropdown-item>
                  <nx-dropdown-item
                    [value]="'Old IC / Police ID / Army ID / Passport No.'"
                  >
                  </nx-dropdown-item>
                  <nx-dropdown-item [value]="'Business Registration No.'">
                  </nx-dropdown-item>
                  <nx-dropdown-item
                    [value]="'New Business Registration No.'"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="ID No. 2">
                <input nxInput />
              </nx-formfield>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Tax Identification No. (TIN)">
                <input nxInput />
              </nx-formfield>
            </div>
          </div>

          <div *ngIf="company">
            <div nxRow>
              <div nxCol="12">
                <span nxCopytext="normal">SST Registered?</span><br />
                <div class="radio-group nx-margin-top-xs">
                  <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                  <nx-radio name="group1" class="nx-margin-left-2m"
                    >No</nx-radio
                  >
                </div>
              </div>
            </div>
            <div *ngIf="sst" class="nx-margin-top-m">
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SST No.">
                    <input nxInput />
                  </nx-formfield>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SST Effective Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker1" />
                    <span nxFormfieldHint>MM/DD/YYYY</span>
                    <nx-datepicker-toggle
                      [for]="myDatepicker1"
                      nxFormfieldSuffix
                    ></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker1></nx-datepicker>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SST De-Registration Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker2" />
                    <span nxFormfieldHint>MM/DD/YYYY</span>
                    <nx-datepicker-toggle
                      [for]="myDatepicker2"
                      nxFormfieldSuffix
                    ></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker2></nx-datepicker>
                  </nx-formfield>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SST Resident Status">
                    <nx-dropdown [valueFormatter]="toText">
                      <nx-dropdown-item value="Resident"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SST Country">
                    <nx-dropdown [valueFormatter]="toText">
                      <nx-dropdown-item value="Malaysia"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="company">
            <div nxRow>
              <div nxCol="12">
                <span nxCopytext="normal">SME Registered?</span><br />
                <div class="radio-group nx-margin-top-xs">
                  <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                  <nx-radio name="group1" class="nx-margin-left-2m"
                    >No</nx-radio
                  >
                </div>
              </div>
            </div>
            <div *ngIf="sme">
              <div nxRow class="nx-margin-top-m">
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SME Reg. No.">
                    <input nxInput />
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SME Category">
                    <nx-dropdown [valueFormatter]="toText">
                      <nx-dropdown-item value="MICRO"></nx-dropdown-item>
                      <nx-dropdown-item value="SMALL"></nx-dropdown-item>
                      <nx-dropdown-item value="MEDIUM"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SME Cert. Start Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker3" />
                    <span nxFormfieldHint>MM/DD/YYYY</span>
                    <nx-datepicker-toggle
                      [for]="myDatepicker3"
                      nxFormfieldSuffix
                    ></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker3></nx-datepicker>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="SME Cert. End Date">
                    <input nxDatefield nxInput [datepicker]="myDatepicker4" />
                    <span nxFormfieldHint>MM/DD/YYYY</span>
                    <nx-datepicker-toggle
                      [for]="myDatepicker4"
                      nxFormfieldSuffix
                    ></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker4></nx-datepicker>
                  </nx-formfield>
                </div>
              </div>
            </div>
          </div>
          <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
              <span nxCopytext="normal">Gender</span><br />
              <div class="radio-group nx-margin-top-xs">
                <nx-radio name="group1" [checked]="true">Male</nx-radio>
                <nx-radio name="group1" class="nx-margin-left-2m"
                  >Female</nx-radio
                >
              </div>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
              <div class="customField_marital">
                <nx-formfield label="Marital Status">
                  <nx-dropdown [valueFormatter]="toText">
                    <nx-dropdown-item value="SINGLE"></nx-dropdown-item>
                    <nx-dropdown-item value="MARIED"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Date of Birth">
                <input nxDatefield nxInput [datepicker]="myDatepicker5" />
                <span nxFormfieldHint>MM/DD/YYYY</span>
                <nx-datepicker-toggle
                  [for]="myDatepicker5"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker5></nx-datepicker>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Age">
                <input nxInput [disabled]="true" value="35" />
              </nx-formfield>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <hr class="hr" />

              <p
                nxCopytext="large"
                class="nx-font-weight-semibold nx-margin-y-m"
              >
                Contact Details
              </p>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12,12,6,6,6,6,6">
              <div nxLayout="grid nopadding">
                <div class="cp__customer-detail-phone">
                  <nx-formfield label="Code" class="code">
                    <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                      <nx-dropdown-item value="6010"></nx-dropdown-item>
                      <nx-dropdown-item value="6011"></nx-dropdown-item>
                      <nx-dropdown-item value="6012"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                  <nx-formfield
                    label="Mobile No."
                    class="info nx-margin-left-s"
                  >
                    <input
                      nxInput
                      inputmode="numeric"
                      required
                      numberOnly
                      minlength="7"
                      maxlength="8"
                      autocomplete="off"
                    />
                    <nx-error nxFormfieldError>
                      Please enter your Mobile No.
                    </nx-error>
                  </nx-formfield>
                </div>
              </div>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <div nxLayout="grid nopadding">
                <div class="cp__customer-detail-phone">
                  <nx-formfield label="Code" class="code">
                    <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                      <nx-dropdown-item value="6010"></nx-dropdown-item>
                      <nx-dropdown-item value="6011"></nx-dropdown-item>
                      <nx-dropdown-item value="6012"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                  <nx-formfield
                    label="Fixed Line No."
                    class="info nx-margin-left-s"
                  >
                    <input
                      nxInput
                      inputmode="numeric"
                      required
                      numberOnly
                      minlength="7"
                      maxlength="8"
                      autocomplete="off"
                    />
                    <nx-error nxFormfieldError>
                      Please enter your Fixed Line No.
                    </nx-error>
                  </nx-formfield>
                </div>
              </div>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
              <div nxLayout="grid nopadding">
                <div class="cp__customer-detail-phone">
                  <nx-formfield label="Code" class="code">
                    <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                      <nx-dropdown-item value="6010"></nx-dropdown-item>
                      <nx-dropdown-item value="6011"></nx-dropdown-item>
                      <nx-dropdown-item value="6012"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                  <nx-formfield
                    label="Fax No. (Optional)"
                    class="info nx-margin-left-s"
                  >
                    <input
                      nxInput
                      inputmode="numeric"
                      required
                      numberOnly
                      minlength="7"
                      maxlength="8"
                      autocomplete="off"
                    />
                  </nx-formfield>
                </div>
              </div>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <div nxLayout="grid nopadding">
                <nx-formfield label="Email">
                  <input nxInput type="text" />
                </nx-formfield>
              </div>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <hr class="hr" />
              <p
                nxCopytext="large"
                class="nx-font-weight-semibold nx-margin-top-m"
              >
                Correspondence Details
              </p>
            </div>
          </div>

          <div nxRow class="nx-margin-top-m">
            <div nxCol="12">
              <nx-switcher [checked]="true">Show Map</nx-switcher>
            </div>
            <div nxCol="12" class="search-map nx-margin-top-m">
              <div class="pac-card" id="pac-card">
                <div>
                  <div id="title">Autocomplete search</div>
                  <div id="type-selector" class="pac-controls">
                    <div class="radio-group">
                      <span nxCopytext="medium">Search Mode: &nbsp;</span>
                      <div class="radio-map">
                        <nx-radio
                          name="group1"
                          labelSize="small"
                          [checked]="true"
                          value="address"
                          (valueChange)="changeSearchMode($event)"
                          ><span nxCopytext="medium">By Address</span>
                        </nx-radio>
                        <nx-radio
                          name="group1"
                          labelSize="small"
                          value="lat/long"
                          (valueChange)="changeSearchMode($event)"
                          ><span nxCopytext="medium">By Lat/Long</span>
                        </nx-radio>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <div id="pac-container">
                  <input
                    *ngIf="search_mode_address"
                    id="pac-input"
                    type="text"
                    placeholder="Enter a location"
                  />

                  <div *ngIf="!search_mode_address">
                    <div class="lat_long">
                      <input
                        id="pac-input"
                        type="text"
                        placeholder="LATITUDE"
                      />
                      <input
                        id="pac-input"
                        type="text"
                        placeholder="LONGITUDE"
                      />
                    </div>
                    <div
                      class="container_flex_center align_right nx-margin-top-s"
                    >
                      <button nxButton="primary small" type="button">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.857620894033!2d101.6841407748091!3d3.132302996843152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b50e075c1d%3A0xc37d80fcda677529!2sMenara%20Allianz%20Sentral!5e0!3m2!1sen!2smy!4v1716797684318!5m2!1sen!2smy"
                class="map"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div nxCol="12" class="nx-margin-top-m">
              <nx-formfield label="Unit No">
                <input nxInput />
              </nx-formfield>
            </div>

            <div nxCol="12">
              <nx-formfield label="Address 1">
                <input nxInput />
              </nx-formfield>
            </div>

            <div nxCol="12">
              <nx-formfield label="Address 2">
                <input nxInput />
              </nx-formfield>
            </div>

            <div nxCol="12">
              <nx-formfield label="Address 3">
                <input nxInput />
              </nx-formfield>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Postcode">
                <input nxInput />
              </nx-formfield>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="City">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item value="City"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>

            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="State">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item value="State"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="Country">
                <nx-dropdown [valueFormatter]="toText">
                  <nx-dropdown-item value="Country"></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12">
              <p nxCopytext="normal" class="nx-font-weight-semibold">
                Please select Address Type
              </p>
            </div>
            <div nxCol="12">
              <div class="radio-group nx-margin-top-xs">
                <nx-radio name="group3" [checked]="true">Residential</nx-radio>
                <nx-radio name="group3">Non-residential</nx-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ======================= Small view ====================== -->
    <div
      class="nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
    >
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              <span nxCopytext="large" class="nx-font-weight-bold">
                Personal Information
              </span>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow class="nx-margin-top-m">
              <div nxCol="12">
                <nx-formfield label="Nationality">
                  <nx-dropdown [valueFormatter]="toText" value="Thailand">
                    <nx-dropdown-item [value]="'Thailand'"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12">
                <nx-formfield label="Name">
                  <input nxInput #inputToCount maxlength="200" />
                  <span nxFormfieldHint>
                    <span *ngIf="inputToCount.value?.length === 0">
                      max. 200 characters
                    </span>
                    <span *ngIf="inputToCount.value.length > 0">
                      {{ 200 - (inputToCount.value.length || 0) }}
                      characters remaining
                    </span>
                  </span>
                </nx-formfield>
              </div>
            </div>
            <div nxRow *ngIf="company">
              <div nxCol="12">
                <nx-formfield label="Country of Incorporation">
                  <nx-dropdown value="Malaysia" [valueFormatter]="toText">
                    <nx-dropdown-item value="Malaysia"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>
            </div>
            <div nxRow *ngIf="company">
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="ID Type 2">
                  <nx-dropdown value="NRIC" [valueFormatter]="toText">
                    <nx-dropdown-item [value]="'NRIC'"> </nx-dropdown-item>
                    <nx-dropdown-item
                      [value]="'Old IC / Police ID / Army ID / Passport No.'"
                    >
                    </nx-dropdown-item>
                    <nx-dropdown-item [value]="'Business Registration No.'">
                    </nx-dropdown-item>
                    <nx-dropdown-item
                      [value]="'New Business Registration No.'"
                    ></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="ID No. 2">
                  <input nxInput />
                </nx-formfield>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Tax Identification No. (TIN)">
                  <input nxInput />
                </nx-formfield>
              </div>
            </div>
            <div *ngIf="company">
              <div nxRow>
                <div nxCol="12">
                  <span nxCopytext="normal">SST Registered?</span><br />
                  <div class="radio-group nx-margin-top-xs">
                    <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                    <nx-radio name="group1" class="nx-margin-left-2m"
                      >No</nx-radio
                    >
                  </div>
                </div>
              </div>
              <div *ngIf="sst" class="nx-margin-top-m">
                <div nxRow>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SST No.">
                      <input nxInput />
                    </nx-formfield>
                  </div>
                </div>
                <div nxRow>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SST Effective Date">
                      <input
                        nxDatefield
                        nxInput
                        [datepicker]="myDatepicker15"
                      />
                      <span nxFormfieldHint>MM/DD/YYYY</span>
                      <nx-datepicker-toggle
                        [for]="myDatepicker15"
                        nxFormfieldSuffix
                      ></nx-datepicker-toggle>
                      <nx-datepicker #myDatepicker15></nx-datepicker>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SST De-Registration Date">
                      <input
                        nxDatefield
                        nxInput
                        [datepicker]="myDatepicker16"
                      />
                      <span nxFormfieldHint>MM/DD/YYYY</span>
                      <nx-datepicker-toggle
                        [for]="myDatepicker16"
                        nxFormfieldSuffix
                      ></nx-datepicker-toggle>
                      <nx-datepicker #myDatepicker16></nx-datepicker>
                    </nx-formfield>
                  </div>
                </div>
                <div nxRow>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SST Resident Status">
                      <nx-dropdown [valueFormatter]="toText">
                        <nx-dropdown-item value="Resident"></nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SST Country">
                      <nx-dropdown [valueFormatter]="toText">
                        <nx-dropdown-item value="Malaysia"></nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="company">
              <div nxRow>
                <div nxCol="12">
                  <span nxCopytext="normal">SME Registered?</span><br />
                  <div class="radio-group nx-margin-top-xs">
                    <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                    <nx-radio name="group1" class="nx-margin-left-2m"
                      >No</nx-radio
                    >
                  </div>
                </div>
              </div>
              <div *ngIf="sme">
                <div nxRow class="nx-margin-top-2m">
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SME Reg. No.">
                      <input nxInput />
                    </nx-formfield>
                  </div>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SME Category">
                      <nx-dropdown [valueFormatter]="toText">
                        <nx-dropdown-item value="MICRO"></nx-dropdown-item>
                        <nx-dropdown-item value="SMALL"></nx-dropdown-item>
                        <nx-dropdown-item value="MEDIUM"></nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                  </div>
                </div>
                <div nxRow>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SME Cert. Start Date">
                      <input
                        nxDatefield
                        nxInput
                        [datepicker]="myDatepicker17"
                      />
                      <span nxFormfieldHint>MM/DD/YYYY</span>
                      <nx-datepicker-toggle
                        [for]="myDatepicker17"
                        nxFormfieldSuffix
                      ></nx-datepicker-toggle>
                      <nx-datepicker #myDatepicker17></nx-datepicker>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,12,6,6,6,6,6">
                    <nx-formfield label="SME Cert. End Date">
                      <input
                        nxDatefield
                        nxInput
                        [datepicker]="myDatepicker18"
                      />
                      <span nxFormfieldHint>MM/DD/YYYY</span>
                      <nx-datepicker-toggle
                        [for]="myDatepicker18"
                        nxFormfieldSuffix
                      ></nx-datepicker-toggle>
                      <nx-datepicker #myDatepicker18></nx-datepicker>
                    </nx-formfield>
                  </div>
                </div>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12,12,6,6,6,6,6">
                <span nxCopytext="normal">Gender</span><br />
                <div class="radio-group nx-margin-top-xs">
                  <nx-radio name="group1" [checked]="true">Male</nx-radio>
                  <nx-radio name="group1" class="nx-margin-left-2m"
                    >Female</nx-radio
                  >
                </div>
              </div>
              <div nxCol="12,12,6,6,6,6,6">
                <div class="customField_marital">
                  <nx-formfield label="Marital Status">
                    <nx-dropdown [valueFormatter]="toText">
                      <nx-dropdown-item value="SINGLE"></nx-dropdown-item>
                      <nx-dropdown-item value="MARIED"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Date of Birth">
                  <input nxDatefield nxInput [datepicker]="myDatepicker19" />
                  <span nxFormfieldHint>MM/DD/YYYY</span>
                  <nx-datepicker-toggle
                    [for]="myDatepicker19"
                    nxFormfieldSuffix
                  ></nx-datepicker-toggle>
                  <nx-datepicker #myDatepicker19></nx-datepicker>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Age">
                  <input nxInput [disabled]="true" value="35" />
                </nx-formfield>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              <span nxCopytext="large" class="nx-font-weight-bold">
                Contact Details
              </span>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <div class="cp__customer-detail-phone">
                    <nx-formfield label="Code" class="code">
                      <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                        <nx-dropdown-item value="6010"></nx-dropdown-item>
                        <nx-dropdown-item value="6011"></nx-dropdown-item>
                        <nx-dropdown-item value="6012"></nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                    <nx-formfield
                      label="Mobile No."
                      class="info nx-margin-left-s"
                    >
                      <input
                        nxInput
                        inputmode="numeric"
                        required
                        numberOnly
                        minlength="7"
                        maxlength="8"
                        autocomplete="off"
                      />
                      <nx-error nxFormfieldError>
                        Please enter your Mobile No.
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
              </div>

              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <div class="cp__customer-detail-phone">
                    <nx-formfield label="Code" class="code">
                      <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                        <nx-dropdown-item value="6010"></nx-dropdown-item>
                        <nx-dropdown-item value="6011"></nx-dropdown-item>
                        <nx-dropdown-item value="6012"></nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                    <nx-formfield
                      label="Fixed Line No."
                      class="info nx-margin-left-s"
                    >
                      <input
                        nxInput
                        inputmode="numeric"
                        required
                        numberOnly
                        minlength="7"
                        maxlength="8"
                        autocomplete="off"
                      />
                      <nx-error nxFormfieldError>
                        Please enter your Fixed Line No.
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
              </div>
              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <div class="cp__customer-detail-phone">
                    <nx-formfield label="Code" class="code">
                      <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                        <nx-dropdown-item value="6010"></nx-dropdown-item>
                        <nx-dropdown-item value="6011"></nx-dropdown-item>
                        <nx-dropdown-item value="6012"></nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                    <nx-formfield
                      label="Fax No. (Optional)"
                      class="info nx-margin-left-s"
                    >
                      <input
                        nxInput
                        inputmode="numeric"
                        required
                        numberOnly
                        minlength="7"
                        maxlength="8"
                        autocomplete="off"
                      />
                    </nx-formfield>
                  </div>
                </div>
              </div>

              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <nx-formfield label="Email">
                    <input nxInput type="text" />
                  </nx-formfield>
                </div>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              <span nxCopytext="large" class="nx-font-weight-bold">
                Correspondence Details
              </span>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow class="nx-margin-top-m">
              <div nxCol="12">
                <nx-switcher [checked]="true">Show Map</nx-switcher>
              </div>
              <div nxCol="12" class="search-map nx-margin-top-m">
                <div class="pac-card" id="pac-card">
                  <div>
                    <div id="title">Autocomplete search</div>
                    <div id="type-selector" class="pac-controls">
                      <div class="radio-group">
                        <span nxCopytext="medium">Search Mode: &nbsp;</span>
                        <div class="radio-map">
                          <nx-radio
                            name="group1"
                            labelSize="small"
                            [checked]="true"
                            value="address"
                            (valueChange)="changeSearchMode($event)"
                            ><span nxCopytext="medium">By Address</span>
                          </nx-radio>
                          <nx-radio
                            name="group1"
                            labelSize="small"
                            value="lat/long"
                            (valueChange)="changeSearchMode($event)"
                            ><span nxCopytext="medium">By Lat/Long</span>
                          </nx-radio>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                  <div id="pac-container">
                    <input
                      *ngIf="search_mode_address"
                      id="pac-input"
                      type="text"
                      placeholder="Enter a location"
                    />

                    <div *ngIf="!search_mode_address">
                      <div class="lat_long">
                        <input
                          id="pac-input"
                          type="text"
                          placeholder="Enter a location"
                        />
                        <input
                          id="pac-input"
                          type="text"
                          placeholder="Enter a location"
                        />
                      </div>
                      <div
                        class="container_flex_center align_right nx-margin-top-s"
                      >
                        <button nxButton="primary small" type="button">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.857620894033!2d101.6841407748091!3d3.132302996843152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b50e075c1d%3A0xc37d80fcda677529!2sMenara%20Allianz%20Sentral!5e0!3m2!1sen!2smy!4v1716797684318!5m2!1sen!2smy"
                  class="map"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

              <div nxCol="12" class="nx-margin-top-m">
                <nx-formfield label="Unit No">
                  <input nxInput />
                </nx-formfield>
              </div>

              <div nxCol="12">
                <nx-formfield label="Address 1">
                  <input nxInput />
                </nx-formfield>
              </div>

              <div nxCol="12">
                <nx-formfield label="Address 2">
                  <input nxInput />
                </nx-formfield>
              </div>

              <div nxCol="12">
                <nx-formfield label="Address 3">
                  <input nxInput />
                </nx-formfield>
              </div>

              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Postcode">
                  <input nxInput />
                </nx-formfield>
              </div>

              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="City">
                  <nx-dropdown [valueFormatter]="toText">
                    <nx-dropdown-item value="City"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>

              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="State">
                  <nx-dropdown [valueFormatter]="toText">
                    <nx-dropdown-item value="State"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Country">
                  <nx-dropdown [valueFormatter]="toText">
                    <nx-dropdown-item value="Country"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12">
                <p nxCopytext="normal" class="nx-font-weight-semibold">
                  Please select Address Type
                </p>
              </div>
              <div nxCol="12">
                <div class="radio-group nx-margin-top-xs">
                  <nx-radio name="group3" [checked]="true"
                    >Residential</nx-radio
                  >
                  <nx-radio name="group3">Non-residential</nx-radio>
                </div>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>
    </div>

    <div nxRow class="nx-margin-bottom-4m">
      <div nxCol="12" class="nx-margin-top-m">
        <p nxCopytext="medium">
          <i>
            By clicking SAVE, you confirm that the address submitted is complete
            and accurate to the best of your knowledge.
          </i>
        </p>
      </div>
    </div>
  </div>
  <div class="nx-margin-top-2m">
    <div class="button_area2">
      <button
        nxModalClose="disagree"
        nxButton="secondary"
        type="button"
      >
        CANCEL
      </button>
      <button nxModalClose="agree" nxButton="" type="button">SAVE</button>
    </div>
  </div>
</ng-container>
