<form [formGroup]="customerEditForm">

  <h3 class="cp_popup-title nx-margin-bottom-s">
    Edit Customer Partner Information
  </h3>
  <div nxModalContent style="overflow-x:hidden;" >
    <!--===== Large and medium view =====-->
    @if(!mobileView) {
    <div class="nx-hidden-xs nx-hidden-s">
      <div nxLayout="grid nopadding">
        <div>
      @if(showNationality()) {
      <div nxRow>
        <div nxCol="12">
          <nx-formfield label="Nationality">
            <nx-dropdown [showFilter]="true" filterPlaceholder="Type to search country" formControlName="nationality">
              @for (country of lov.clientNationalityList; track country) {
              <nx-dropdown-item [value]="country.code">{{country.descp}}</nx-dropdown-item>
              }
            </nx-dropdown>
          </nx-formfield>
        </div>
      </div>
      }

      @if (isCompany) {
        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Country of Incorporation">
              <nx-dropdown [valueFormatter]="toText" formControlName="nationality">
                @for (country of lov.clientNationalityList; track country) {
                <nx-dropdown-item [value]="country.code">{{ country.descp }}</nx-dropdown-item>
                }
              </nx-dropdown>
            </nx-formfield>
          </div>
        </div>
        }

      <div nxRow>
        <div nxCol="12">
          <nx-formfield label="Name">
            <input nxInput #inputToCount [appMaxLength]="200" formControlName="name" appToUpperCase/>
            <span nxFormfieldHint>
              <span *ngIf="inputToCount.value?.length === 0">
                Max. 200 characters
              </span>
              <span *ngIf="inputToCount.value.length > 0">
                {{ 200 - (inputToCount.value.length || 0) }}
                {{ 'COMMON.CHAR_REMAINING' | translate }}
              </span>
            </span>

            <nx-error nxFormfieldError>
              <strong> Please enter the Name.</strong>
            </nx-error>
          </nx-formfield>
        </div>
      </div>

      @if ((!isCompany && isOldIC) || isCompany) {
      <div nxRow>
        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="ID Type 2">
            <nx-dropdown [valueFormatter]="toText" formControlName="idType2" (selectionChange)="idType2OnChange()">
              @for(idType2 of idType2List; track idType2) {
              <nx-dropdown-item [value]="idType2.code">{{ idType2.descp }}</nx-dropdown-item>
              }
            </nx-dropdown>

            <nx-error nxFormfieldError>
              <strong> Please enter ID Type 2.</strong>
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="ID No. 2">
            <input nxInput formControlName="idValue2" [appMaxLength]="idValue2Length" #idValue2 (blur)="onBlurIdValue2(idValue2)" appToUpperCase [appOnlyNumber]="onlyNumber"/>
          </nx-formfield>
          @if (idValue2ErrorType) {
          <nx-error nxFormfieldError>
            @if(idValue2ErrorType === 'wrongNricFormat') {
            <strong>Wrong Format</strong>
            } @else if(idValue2ErrorType === 'required') {
            <strong>Please enter ID No. 2</strong>
            } @else if(idValue2ErrorType === 'wrongNewBrnoFormat') {
            <strong>Invalid Business Registration No.</strong>
            }
          </nx-error>
          }
          <!-- <nx-error nxFormfieldNote>
                              <strong>Wrong Format</strong>
                            </nx-error> -->
        </div>
      </div>
      }

      <div nxRow>
        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="Tax Identification No. (TIN)">
            <input nxInput formControlName="tinNo" appToUpperCase [appMaxLength]="13"/>
            <nx-error nxFormfieldError>
              @if(customerEditForm.get('tinNo').errors?.['required']) {
              <strong>Please enter Tax Identification No. (TIN).</strong>
              }

              @if(customerEditForm.get('tinNo').errors?.['pattern']) {
              <strong>Tax Identification No. (TIN) is invalid.</strong>
              }
            </nx-error>
          </nx-formfield>
        </div>
      </div>

      <!--Company-->
      @if (isCompany) {
      <app-sst-form-details [customerInfoForm]="customerEditForm" [countryList]="lov.clientSstCountryList"
        [addressTypeList]="lov.clientSstResidentStatusList" [cpDetails]="cpDetails">
      </app-sst-form-details>

      <app-sme-cert-form [customerInfoForm]="customerEditForm" [smeCategoryList]="lov.smeCategoryList"
        [cpDetails]="cpDetails" [mkagAgent]="mkagAgent">
      </app-sme-cert-form>
      }

      <!--Individual-->
      @if (!isCompany) {
      <div nxRow>
        <div nxCol="12,12,6,6,6,6,6">
          <span nxCopytext="normal">Gender</span><br />
          <nx-radio-group formControlName="gender" name="gender">
            <div class="radio-group nx-margin-top-xs">
              <nx-radio value="M">Male</nx-radio>
              <nx-radio class="nx-margin-left-2m" value="F">Female</nx-radio>
            </div>
          </nx-radio-group>
        </div>
        <div nxCol="12,12,6,6,6,6,6">
          <div class="customField_marital">
            <nx-formfield label="Marital Status">
              <nx-dropdown [valueFormatter]="toText" formControlName="maritalStatus">
                @for(clientMartial of lov.clientMaritalList; track clientMartial) {
                <nx-dropdown-item [value]="clientMartial.code">{{ clientMartial.descp }}</nx-dropdown-item>
                }
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <strong>Please enter the Marital Status.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
      </div>
      }

      <!--Individual-->
      @if (!isCompany && !isNric) {
      <div nxRow>
        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="Date of Birth">
            <input nxDatefield nxInput [datepicker]="myDatepicker5" formControlName="dob" [parseFormat]="['DD/MM/YYYY']" displayFormat="DD/MM/YYYY"/>
            <span nxFormfieldHint>DD/MM/YYYY</span>
            <nx-datepicker-toggle [for]="myDatepicker5" nxFormfieldSuffix></nx-datepicker-toggle>
            <nx-datepicker #myDatepicker5></nx-datepicker>

            @if(dobErrorType) {
            <nx-error nxFormfieldError>
              <!-- @if (dobErrorType === 'invalidDOB') { -->
              <strong>Please enter a valid DOB.</strong>
              <!-- } -->
            </nx-error>
            }

          </nx-formfield>

          <!-- <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(1, 'one-button')">
            (Current Year - Birth Year) = 0
          </button> -->
        </div>
        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="Age">
            <input nxInput value="35" formControlName="age" (change)="onChangeAge()" />
          </nx-formfield>
        </div>
      </div>
      }


      <div nxRow>
        <div nxCol="12">
          <hr class="hr" />

          <h4>Contact Details</h4>
          <!-- <p
              nxCopytext="large"
              class="nx-font-weight-semibold nx-margin-y-m"
            >
              Contact Details
            </p> -->
        </div>
      </div>

      <div nxRow>
        <div nxCol="12,12,6,6,6,6,6">
          <div nxLayout="grid nopadding">
            <div class="cp__customer-detail-phone">
              <nx-formfield label="Code" class="code">
                <nx-dropdown [panelGrow]="true" formControlName="mobileNoCode" (selectionChange)="onChangeMobileNoCode()">
                  <nx-dropdown-item [value]="null">--</nx-dropdown-item>
                  @for(mobileCode of lov.clientMobileCode1List; track mobileCode) {
                  <nx-dropdown-item [value]="mobileCode.code">{{ mobileCode.descp }}</nx-dropdown-item>
                  }

                </nx-dropdown>
              </nx-formfield>
              <nx-formfield label="Mobile No." class="info nx-margin-left-s">
                <input nxInput inputmode="numeric" numberOnly [appMaxLength]="8" autocomplete="off"
                  formControlName="mobileNo" (blur)="onChangeMobileNo(false)" [appOnlyNumber]="true"/>

                <nx-error nxFormfieldError>
                  @if(customerEditForm.get('mobileNo').errors?.['required']) {
                  @if(isCompany) {
                  <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                  }@else {
                  <strong>Please enter the Mobile No.</strong>
                  }

                  }

                  @if(customerEditForm.get('mobileNo').errors?.['pattern']) {
                  <strong>Please enter the number between 7 and 8 characters long.</strong>
                  }

                </nx-error>

              </nx-formfield>
            </div>
          </div>

          <!-- <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(2, 'two-button')">
            Mobile No. same as Agent
          </button> -->
        </div>

        <div nxCol="12,12,6,6,6,6,6">
          <div nxLayout="grid nopadding">
            <div class="cp__customer-detail-phone">
              <nx-formfield label="Code" class="code">
                <nx-dropdown [panelGrow]="true" formControlName="fixedLineNoCode">
                  <nx-dropdown-item [value]="null">--</nx-dropdown-item>
                  @for(fixLinedCode of lov.clientFax1CodeList; track fixLinedCode) {
                  <nx-dropdown-item [value]="fixLinedCode.code">{{ fixLinedCode.descp }}</nx-dropdown-item>
                  }
                </nx-dropdown>
              </nx-formfield>
              <nx-formfield label="Fixed Line No." class="info nx-margin-left-s">
                <input nxInput inputmode="numeric" numberOnly [appMaxLength]="8" 
                autocomplete="off" formControlName="fixedLineNo" (blur)="onChangefixedLineNo()" [appOnlyNumber]="true"/>
                <nx-error nxFormfieldError>
                  @if(customerEditForm.get('fixedLineNo').errors?.['required']) {
                  @if(isCompany) {
                  <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                  }

                  }

                  @if(customerEditForm.get('fixedLineNo').errors?.['pattern']) {
                  <strong> Please enter the number between 6 and 8 characters long.</strong>
                  }
                </nx-error>
              </nx-formfield>
            </div>
          </div>
        </div>

        <div nxCol="12,12,6,6,6,6,6">
          <div nxLayout="grid nopadding">
            <div class="cp__customer-detail-phone">
              <nx-formfield label="Code" class="code">
                <nx-dropdown [panelGrow]="true" formControlName="faxNoCode">
                  <nx-dropdown-item [value]="null">--</nx-dropdown-item>
                  @for(faxCode of lov.clientFax1CodeList; track faxCode) {
                  <nx-dropdown-item [value]="faxCode.code">{{ faxCode.descp }}</nx-dropdown-item>
                  }
                </nx-dropdown>
              </nx-formfield>
              <nx-formfield label="Fax No. (Optional)" class="info nx-margin-left-s">
                <input nxInput inputmode="numeric" numberOnly [appMaxLength]="8"
                 autocomplete="off" formControlName="faxNo" (blur)="onChangeFaxNo()" [appOnlyNumber]="true"/>
                <nx-error nxFormfieldError>
                  <strong>
                    Please enter the number between 7 and 8 characters long.
                  </strong>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
        </div>


        <div nxCol="12,12,6,6,6,6,6">
          <div nxLayout="grid nopadding">
            <!-- if the e-invoice content is no, Email (Optional) else Email -->
            <nx-formfield>
              <nx-formfield-label>
                Email

                @if((emailCheck =="false") && (!eInvoiceConsent)) {
                <span>(Optional)</span>
                }
              </nx-formfield-label>
              <input nxInput type="text" formControlName="email" (change)="onChangeEmail()"/>

              <nx-error nxFormfieldError>
                @if(customerEditForm.get('email').errors?.['required']) {
                <strong> Please enter the Email. </strong>
                }
                @if(customerEditForm.get('email').errors?.['email']) {
                <strong> Please enter a valid email address. </strong>
                }
              </nx-error>
            </nx-formfield>
          </div>

          <!-- <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(3, 'two-button')">
            Email same as Agent
          </button> -->
        </div>
      </div>


      <div nxRow>
        <div nxCol="12">
          <hr class="hr" />
          <!-- <div class = "nx-margin-top-m" style="display: flex; justify-content: space-between; align-items: center;"> -->
            <div style= "display: flex;" class="container_flex_center space_between nx-margin-top-m ">
              <p
                nxCopytext="large"
                class="nx-font-weight-semibold"
              >
                Correspondence Details
              </p>

              <button nxPlainButton type="button" class="clear-button" (click)="clearAddress()">
                <nx-icon
                  name="trash-o"
                  nxIconPositionStart
                  aria-hidden="true"
                ></nx-icon
                >CLEAR ADDRESS
              </button>
            </div>
        </div>
      </div>

      <div nxRow class="nx-margin-top-m">

        <ng-container *ngIf="isShow">
          <div nxCol="12" class="nx-margin-bottom-m">
            <nx-switcher formControlName="showMapSwitcher">Show Map</nx-switcher>
          </div>

          @if (customerEditForm.get('showMapSwitcher').value) {
          <!-- <div nxCol="12" class="search-map-edit nx-margin-top-m"> -->
            <!-- <div class="pac-card-edit" id="pac-card-edit"> -->
            <app-branches-map [mapVisible]="customerEditForm.get('showMapSwitcher').value"
              (formPopulated)="populateForm($event)" style="width: 100%;">
            </app-branches-map>
            <!-- </div> -->
          <!-- </div> -->
          }
        </ng-container>




        <div nxCol="12" class="nx-margin-top-m">
          <nx-formfield label="Unit No">
            <input nxInput formControlName="unitNo" appToUpperCase/>
          </nx-formfield>
        </div>

        <div nxCol="12">
          <nx-formfield label="Address 1">
            <input nxInput formControlName="address1" appToUpperCase/>

            <nx-error nxFormfieldError>
              <strong> Please enter Address 1. </strong>
            </nx-error>
          </nx-formfield>
        </div>

        <div nxCol="12">
          <nx-formfield label="Address 2">
            <input nxInput formControlName="address2" appToUpperCase/>
          </nx-formfield>
        </div>

        <div nxCol="12">
          <nx-formfield label="Address 3">
            <input nxInput formControlName="address3" appToUpperCase/>
          </nx-formfield>
        </div>

        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="Postcode">
            <nx-dropdown [valueFormatter]="toText" formControlName="postCode" [showFilter]="true" (selectionChange)="onChangePostcode()">
              @for(postCode of lov.clientPostcodeList; track postCode) {
              <nx-dropdown-item [value]="postCode.code">{{ postCode.code }}</nx-dropdown-item>
              }
            </nx-dropdown>
            <nx-error nxFormfieldError>
              <strong> Please enter a valid Postcode. </strong>
            </nx-error>
          </nx-formfield>
        </div>

        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="City">
            <nx-dropdown [valueFormatter]="toText" formControlName="city">
              @for(city of lov.clientCityList; track city) {
              <nx-dropdown-item [value]="city.code">{{ city.descp }}</nx-dropdown-item>
              }
            </nx-dropdown>
          </nx-formfield>
        </div>

        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="State">
            <nx-dropdown [valueFormatter]="toText" formControlName="state">
              @for(state of lov.clientStateList; track state) {
              <nx-dropdown-item [value]="state.code">{{ state.descp }}</nx-dropdown-item>
              }
            </nx-dropdown>
          </nx-formfield>
        </div>

        <div nxCol="12,12,6,6,6,6,6">
          <nx-formfield label="Country">
            <nx-dropdown [valueFormatter]="toText" formControlName="country">
              @for(country of lov.clientCountryList; track country) {
              <nx-dropdown-item [value]="country.code">{{ country.descp }}</nx-dropdown-item>
              }
            </nx-dropdown>
          </nx-formfield>
        </div>
      </div>



      <div nxRow>
        <div nxCol="12">
          <h4 class="nx-margin-0">Please select Address Type</h4>
        </div>

        <div nxCol="12">
          <nx-radio-group formControlName="addressType" name="addressType">
            <div class="radio-group2 nx-margin-top-m">
              <nx-radio value="R">Residential</nx-radio>
              <nx-radio value="O">Non-residential</nx-radio>
            </div>
          </nx-radio-group>
        </div>
      </div>

      <div nxRow>
        <div nxCol="12" class="nx-margin-top-m">
          <p nxCopytext="medium">
            <i>
              By clicking SAVE, you confirm that the address submitted is
              complete and accurate to the best of your knowledge.
            </i>
          </p>
        </div>
      </div>

      </div>
      </div>
    </div>
    }

    <!-- ======================= Small view ====================== -->
     @if(mobileView) {
    <div class="nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              @if(!isCompany) {
              <span nxCopytext="large" class="nx-font-weight-bold">
                Personal Information
              </span>
              }@else {
              <span nxCopytext="large" class="nx-font-weight-bold">
                Company Information
              </span>
              }
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            @if(showNationality()) {
              <div nxRow>
                <div nxCol="12">
                  <nx-formfield label="Nationality">
                    <nx-dropdown [showFilter]="true" filterPlaceholder="Type to search country" formControlName="nationality">
                      @for (country of lov.clientNationalityList; track country) {
                      <nx-dropdown-item [value]="country.code">{{country.descp}}</nx-dropdown-item>
                      }
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>
              }

              @if (isCompany) {
                <div nxRow>
                  <div nxCol="12">
                    <nx-formfield label="Country of Incorporation">
                      <nx-dropdown [valueFormatter]="toText" formControlName="nationality">
                        @for (country of lov.clientNationalityList; track country) {
                        <nx-dropdown-item [value]="country.code">{{ country.descp }}</nx-dropdown-item>
                        }
                      </nx-dropdown>
                    </nx-formfield>
                  </div>
                </div>
                }
        
        
              <div nxRow>
                <div nxCol="12">
                  <nx-formfield label="Name">
                    <input nxInput #inputToCount [appMaxLength]="200" formControlName="name" appToUpperCase/>
                    <span nxFormfieldHint>
                      <span *ngIf="inputToCount.value?.length === 0">
                        Max. 200 characters
                      </span>
                      <span *ngIf="inputToCount.value.length > 0">
                        {{ 200 - (inputToCount.value.length || 0) }}
                        {{ 'COMMON.CHAR_REMAINING' | translate }}
                      </span>
                    </span>
        
                    <nx-error nxFormfieldError>
                      <strong> Please enter the Name.</strong>
                    </nx-error>
                  </nx-formfield>
                </div>
              </div>
        
              @if ((!isCompany && isOldIC) || isCompany) {
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="ID Type 2">
                    <nx-dropdown [valueFormatter]="toText" formControlName="idType2" (selectionChange)="idType2OnChange()">
                      @for(idType2 of idType2List; track idType2) {
                      <nx-dropdown-item [value]="idType2.code">{{ idType2.descp }}</nx-dropdown-item>
                      }
                    </nx-dropdown>
        
                    <nx-error nxFormfieldError>
                      <strong> Please enter ID Type 2.</strong>
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="ID No. 2">
                    <input nxInput formControlName="idValue2" [appMaxLength]="idValue2Length" #idValue2 (blur)="onBlurIdValue2(idValue2)" appToUpperCase [appOnlyNumber]="onlyNumber"/>
                  </nx-formfield>
                  @if (idValue2ErrorType) {
                  <nx-error nxFormfieldError>
                    @if(idValue2ErrorType === 'wrongNricFormat') {
                    <strong>Wrong Format</strong>
                    } @else if(idValue2ErrorType === 'required') {
                    <strong>Please enter ID No. 2</strong>
                    } @else if(idValue2ErrorType === 'wrongNewBrnoFormat') {
                    <strong>Invalid Business Registration No.</strong>
                    }
                  </nx-error>
                  }
                  <!-- <nx-error nxFormfieldNote>
                                      <strong>Wrong Format</strong>
                                    </nx-error> -->
                </div>
              </div>
              }
        
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Tax Identification No. (TIN)">
                    <input nxInput formControlName="tinNo" appToUpperCase [appMaxLength]="13"/>
                    <nx-error nxFormfieldError>
                      @if(customerEditForm.get('tinNo').errors?.['required']) {
                      <strong>Please enter Tax Identification No. (TIN).</strong>
                      }
        
                      @if(customerEditForm.get('tinNo').errors?.['pattern']) {
                      <strong>Tax Identification No. (TIN) is invalid.</strong>
                      }
                    </nx-error>
                  </nx-formfield>
                </div>
              </div>
        
              <!--Company-->
              @if (isCompany) {
              <app-sst-form-details [customerInfoForm]="customerEditForm" [countryList]="lov.clientSstCountryList"
                [addressTypeList]="lov.clientSstResidentStatusList" [cpDetails]="cpDetails">
              </app-sst-form-details>
        
              <app-sme-cert-form [customerInfoForm]="customerEditForm" [smeCategoryList]="lov.smeCategoryList"
                [cpDetails]="cpDetails" [mkagAgent]="mkagAgent">
              </app-sme-cert-form>
              }
        
              <!--Individual-->
              @if (!isCompany) {
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <span nxCopytext="normal">Gender</span><br />
                  <nx-radio-group formControlName="gender" name="gender">
                    <div class="radio-group nx-margin-top-xs">
                      <nx-radio value="M">Male</nx-radio>
                      <nx-radio class="nx-margin-left-2m" value="F">Female</nx-radio>
                    </div>
                  </nx-radio-group>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <div class="customField_marital">
                    <nx-formfield label="Marital Status">
                      <nx-dropdown [valueFormatter]="toText" formControlName="maritalStatus">
                        @for(clientMartial of lov.clientMaritalList; track clientMartial) {
                        <nx-dropdown-item [value]="clientMartial.code">{{ clientMartial.descp }}</nx-dropdown-item>
                        }
                      </nx-dropdown>
                      <nx-error nxFormfieldError>
                        <strong>Please enter the Marital Status.</strong>
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
              </div>
              }
        
              <!--Individual-->
              @if (!isCompany && !isNric) {
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Date of Birth">
                    <input nxDatefield nxInput [datepicker]="myDatepicker5" formControlName="dob" [parseFormat]="['DD/MM/YYYY']" displayFormat="DD/MM/YYYY"/>
                    <span nxFormfieldHint>DD/MM/YYYY</span>
                    <nx-datepicker-toggle [for]="myDatepicker5" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker5></nx-datepicker>
        
                    @if(dobErrorType) {
                    <nx-error nxFormfieldError>
                      <!-- @if (dobErrorType === 'invalidDOB') { -->
                      <strong>Please enter a valid DOB.</strong>
                      <!-- } -->
                    </nx-error>
                    }
        
                  </nx-formfield>
        
                  <!-- <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(1, 'one-button')">
                    (Current Year - Birth Year) = 0
                  </button> -->
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Age">
                    <input nxInput value="35" formControlName="age" (change)="onChangeAge()" />
                  </nx-formfield>
                </div>
              </div>
              }
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              <span nxCopytext="large" class="nx-font-weight-bold">
                Contact Details
              </span>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <div class="cp__customer-detail-phone">
                    <nx-formfield label="Code" class="code">
                      <nx-dropdown [panelGrow]="true" formControlName="mobileNoCode" (selectionChange)="onChangeMobileNoCode()">
                        <nx-dropdown-item [value]="null">--</nx-dropdown-item>
                        @for(mobileCode of lov.clientMobileCode1List; track mobileCode) {
                        <nx-dropdown-item [value]="mobileCode.code">{{ mobileCode.descp }}</nx-dropdown-item>
                        }
      
                      </nx-dropdown>
                    </nx-formfield>
                    <nx-formfield label="Mobile No." class="info nx-margin-left-s">
                      <input nxInput inputmode="numeric" numberOnly [appMaxLength]="8" autocomplete="off"
                        formControlName="mobileNo" (blur)="onChangeMobileNo(false)" [appOnlyNumber]="true"/>
      
                      <nx-error nxFormfieldError>
                        @if(customerEditForm.get('mobileNo').errors?.['required']) {
                        @if(isCompany) {
                        <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                        }@else {
                        <strong>Please enter the Mobile No.</strong>
                        }
      
                        }
      
                        @if(customerEditForm.get('mobileNo').errors?.['pattern']) {
                        <strong>Please enter the number between 7 and 8 characters long.</strong>
                        }
      
                      </nx-error>
      
                    </nx-formfield>
                  </div>
                </div>
      
                <!-- <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(2, 'two-button')">
                  Mobile No. same as Agent
                </button> -->
              </div>
      
              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <div class="cp__customer-detail-phone">
                    <nx-formfield label="Code" class="code">
                      <nx-dropdown [panelGrow]="true" formControlName="fixedLineNoCode">
                        <nx-dropdown-item [value]="null">--</nx-dropdown-item>
                        @for(fixLinedCode of lov.clientFax1CodeList; track fixLinedCode) {
                        <nx-dropdown-item [value]="fixLinedCode.code">{{ fixLinedCode.descp }}</nx-dropdown-item>
                        }
                      </nx-dropdown>
                    </nx-formfield>
                    <nx-formfield label="Fixed Line No." class="info nx-margin-left-s">
                      <input nxInput inputmode="numeric" numberOnly [appMaxLength]="8" 
                      autocomplete="off" formControlName="fixedLineNo" (blur)="onChangefixedLineNo()" [appOnlyNumber]="true"/>
                      <nx-error nxFormfieldError>
                        @if(customerEditForm.get('fixedLineNo').errors?.['required']) {
                        @if(isCompany) {
                        <strong>Please enter the Mobile No. or Fixed Line No.</strong>
                        }
      
                        }
      
                        @if(customerEditForm.get('fixedLineNo').errors?.['pattern']) {
                        <strong> Please enter the number between 6 and 8 characters long.</strong>
                        }
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
              </div>
      
              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <div class="cp__customer-detail-phone">
                    <nx-formfield label="Code" class="code">
                      <nx-dropdown [panelGrow]="true" formControlName="faxNoCode">
                        <nx-dropdown-item [value]="null">--</nx-dropdown-item>
                        @for(faxCode of lov.clientFax1CodeList; track faxCode) {
                        <nx-dropdown-item [value]="faxCode.code">{{ faxCode.descp }}</nx-dropdown-item>
                        }
                      </nx-dropdown>
                    </nx-formfield>
                    <nx-formfield label="Fax No. (Optional)" class="info nx-margin-left-s">
                      <input nxInput inputmode="numeric" numberOnly [appMaxLength]="8"
                       autocomplete="off" formControlName="faxNo" (blur)="onChangeFaxNo()" [appOnlyNumber]="true"/>
                      <nx-error nxFormfieldError>
                        <strong>
                          Please enter the number between 7 and 8 characters long.
                        </strong>
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
              </div>
      
      
              <div nxCol="12,12,6,6,6,6,6">
                <div nxLayout="grid nopadding">
                  <!-- if the e-invoice content is no, Email (Optional) else Email -->
                  <nx-formfield>
                    <nx-formfield-label>
                      Email
      
                      @if((emailCheck =="false") && (!eInvoiceConsent)) {
                      <span>(Optional)</span>
                      }
                    </nx-formfield-label>
                    <input nxInput type="text" formControlName="email" (change)="onChangeEmail()"/>
      
                    <nx-error nxFormfieldError>
                      @if(customerEditForm.get('email').errors?.['required']) {
                      <strong> Please enter the Email. </strong>
                      }
                      @if(customerEditForm.get('email').errors?.['email']) {
                      <strong> Please enter a valid email address. </strong>
                      }
                    </nx-error>
                  </nx-formfield>
                </div>
      
                <!-- <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(3, 'two-button')">
                  Email same as Agent
                </button> -->
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

      <nx-accordion variant="light">
        <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              <span nxCopytext="large" class="nx-font-weight-bold">
                Correspondence Details
              </span>
            </nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxLayout="grid nopadding">
            <div nxRow class="nx-margin-top-s">
              <div nxCol="12">
                <button nxPlainButton type="button" (click)="clearAddress()">
                  <nx-icon name="trash-o" nxIconPositionStart aria-hidden="true"></nx-icon>CLEAR ADDRESS
                </button>
              </div>
            </div>
      
            <div nxRow class="nx-margin-top-m">
      
              <ng-container *ngIf="isShow">
                <div nxCol="12" class="nx-margin-bottom-m">
                  <nx-switcher formControlName="showMapSwitcher">Show Map</nx-switcher>
                </div>
      
                @if (customerEditForm.get('showMapSwitcher').value) {
                <!-- <div nxCol="12" class="search-map-edit nx-margin-top-m"> -->
                  <!-- <div class="pac-card-edit" id="pac-card-edit"> -->
                  <app-branches-map [mapVisible]="customerEditForm.get('showMapSwitcher').value"
                    (formPopulated)="populateForm($event)" style="width: 100%;">
                  </app-branches-map>
                  <!-- </div> -->
                <!-- </div> -->
                }
              </ng-container>
      
              <div nxCol="12" class="nx-margin-top-m">
                <nx-formfield label="Unit No">
                  <input nxInput formControlName="unitNo" appToUpperCase/>
                </nx-formfield>
              </div>
      
              <div nxCol="12">
                <nx-formfield label="Address 1">
                  <input nxInput formControlName="address1" appToUpperCase/>
      
                  <nx-error nxFormfieldError>
                    <strong> Please enter Address 1. </strong>
                  </nx-error>
                </nx-formfield>
              </div>
      
              <div nxCol="12">
                <nx-formfield label="Address 2">
                  <input nxInput formControlName="address2" appToUpperCase/>
                </nx-formfield>
              </div>
      
              <div nxCol="12">
                <nx-formfield label="Address 3">
                  <input nxInput formControlName="address3" appToUpperCase/>
                </nx-formfield>
              </div>
      
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Postcode">
                  <nx-dropdown [valueFormatter]="toText" formControlName="postCode" [showFilter]="true" (selectionChange)="onChangePostcode()">
                    @for(postCode of lov.clientPostcodeList; track postCode) {
                    <nx-dropdown-item [value]="postCode.code">{{ postCode.code }}</nx-dropdown-item>
                    }
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <strong> Please enter a valid Postcode. </strong>
                  </nx-error>
                </nx-formfield>
              </div>
      
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="City">
                  <nx-dropdown [valueFormatter]="toText" formControlName="city">
                    @for(city of lov.clientCityList; track city) {
                    <nx-dropdown-item [value]="city.code">{{ city.descp }}</nx-dropdown-item>
                    }
                  </nx-dropdown>
                </nx-formfield>
              </div>
      
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="State">
                  <nx-dropdown [valueFormatter]="toText" formControlName="state">
                    @for(state of lov.clientStateList; track state) {
                    <nx-dropdown-item [value]="state.code">{{ state.descp }}</nx-dropdown-item>
                    }
                  </nx-dropdown>
                </nx-formfield>
              </div>
      
              <div nxCol="12,12,6,6,6,6,6">
                <nx-formfield label="Country">
                  <nx-dropdown [valueFormatter]="toText" formControlName="country">
                    @for(country of lov.clientCountryList; track country) {
                    <nx-dropdown-item [value]="country.code">{{ country.descp }}</nx-dropdown-item>
                    }
                  </nx-dropdown>
                </nx-formfield>
              </div>
            </div>
      
            <div nxRow>
              <div nxCol="12">
                <h4 class="nx-margin-0">Please select Address Type</h4>
              </div>
      
              <div nxCol="12">
                <nx-radio-group formControlName="addressType" name="addressType">
                  <div class="radio-group2 nx-margin-top-m">
                    <nx-radio value="R">Residential</nx-radio>
                    <nx-radio value="O">Non-residential</nx-radio>
                  </div>
                </nx-radio-group>
              </div>
            </div>
      
            <div nxRow>
              <div nxCol="12" class="nx-margin-top-m">
                <p nxCopytext="medium">
                  <i>
                    By clicking SAVE, you confirm that the address submitted is
                    complete and accurate to the best of your knowledge.
                  </i>
                </p>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>

    </div>
    }
  </div>

  <div class="nx-margin-top-2m">
    <div class="button_area2">
      <button nxButton="secondary" type="button" (click)="cancelEdit()">Cancel</button>
      <button nxButton="primary" type="button" (click)="saveEdit()">SAVE</button>
    </div>
  </div>
</form>
