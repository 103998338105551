import {
  IGeocodingConversionPayload,
  IGeocodingConversionResponse,
  IGeocodingStatusPayload,
} from "../store/geolocation.interface";

const PREFIX = "GEOLOCATION";
/**
 * upon google map input selected location then send to backend to convert to correctly mapped address object
 */
export class CONVERT_GEOLOCATION {
  static readonly type = `[${PREFIX}] CONVERT LOCATION`;
  constructor(public readonly payload: IGeocodingConversionPayload) {}
}

export class SET_SELECTED_LOCATION_DETAILS {
  static readonly type = `[${PREFIX}] SET SELECTED LOCATION DETAILS`;
  constructor(public readonly payload: IGeocodingConversionResponse) {}
}

export class RESET_SELECTED_LOCATION {
  static readonly type = `[${PREFIX}] RESET SELECTED LOCATION`;
  constructor() {}
}

export class SET_GEOLOCATION_ENABLED_STATUS {
  static readonly type = `[${PREFIX}] SET GEOLOCATION ENABLED STATUS`;
  constructor(public readonly payload: IGeocodingStatusPayload) {}
}
