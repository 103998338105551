@if(isApproval) {
    <div nxRow>
        <!--Current Extra Cover-->
        <div nxCol="12,12,12,12,6" class="nx-margin-bottom-2m">
            <div>
                <app-external-scrollbar targetSyncScroll="4"
                    [targetBaseWidth]="tableHolder4"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar"
                    #tableHolder4 syncScroll="4">
                    <table nxTable zebra cellpadding="0" >
                        <thead>
                            <tr nxTableRow class="table_row_flex">
                                <th nxHeaderCell class="table_extra_cov">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Current Extra Cover</span>
                                </th>
                                <th nxHeaderCell class="table_extra_cov"></th>
                                <th nxHeaderCell class="table_extra_cov">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Premium (RM)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        @if(currentExtraCoverList != null && currentExtraCoverList.length !== 0 ) {
                            @for(currentExtraCover of currentExtraCoverList; track currentExtraCover) {
                                <tr nxTableRow class="table_row_flex">
                                <td nxTableCell class="table_extra_cov">
                                    @if(currentExtraCover.subCoverCode === 'A207'){
                                        <span nxCopytext="medium">{{ currentExtraCover.subCoverDescp }}</span>
                                    }

                                    @if(currentExtraCover.subCoverCode !== 'A207'){
                                    <span nxCopytext="medium">{{ currentExtraCover.subCoverCodeDescription }}</span>
                                    }

                                    @if(currentExtraCover.subCoverBenefitList?.length) {
                                    <div class="extra-coverage-padding-top-cov">
                                        <ul nxList>
                                            @for(subItem of currentExtraCover.subCoverBenefitList; track subItem) {
                                            <li style="margin-bottom: 0px !important;" nxListIcon="" size="s">
                                                <div nxCopytext="medium">
                                                    <div nxRow col="12">
                                                        <div>- </div>
                                                        <div class="extra-coverage-description-cov" [innerHTML]="removeNewLinesFromOpus(subItem.subCoverBenefitDescp)"></div>
                                                        <div class="extra-coverage-medium-width-cov" [innerHTML]="removeNewLinesFromOpus(subItem.subCoverBenefitDisplay)"></div>
                                                    </div>
                                                </div>
                                            </li>
                                            }
                                        </ul>
                                    </div>
                                }
                                    
                                </td>
                                <td nxTableCell class="table_extra_cov">

                                    <span nxCopytext="medium" [innerHTML]="removeNewLinesFromOpus(currentExtraCover.subcoverNarrDesc, currentExtraCover.subCoverCode)"></span> 
                                    
                                </td>
                                <td nxTableCell class="table_extra_cov">
                                    @if(currentExtraCover.subCoverPrem != 0) {
                                    <span nxCopytext="medium">{{ currentExtraCover.subCoverPrem | formatDecimal }}</span>
                                    }
                                </td>
                            </tr>
                        }
                          }@else {
                        <tr nxTableRow>
                           <td nxTableCell colspan="3" class="std-ta-center">NO DATA</td>
                        </tr>
                         }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Previous Extra Cover-->
        <div nxCol="12,12,12,12,6" class="nx-margin-bottom-2m">
            <div>
                <app-external-scrollbar targetSyncScroll="5"
                    [targetBaseWidth]="tableHolder5"></app-external-scrollbar>
                <div class="table-holder table_content_scroll only-this-horizon-scrollbar"
                    #tableHolder5 syncScroll="5">
                    <table nxTable zebra cellpadding="0">
                        <thead>
                            <tr nxTableRow class="table_row_flex">
                                <th nxHeaderCell class="table_extra_cov">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Previous Extra Cover</span>
                                </th>
                                <th nxHeaderCell class="table_extra_cov"></th>
                                <th nxHeaderCell class="table_extra_cov">
                                    <span nxCopytext="medium"
                                        class="nx-font-weight-semibold">Premium (RM)</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @if(prevExtraCoverList != null && prevExtraCoverList.length !== 0 ) {
                                @for(prevExtraCover of prevExtraCoverList; track prevExtraCover) {
                                <tr nxTableRow class="table_row_flex">
                                    <td nxTableCell class="table_extra_cov">
                                        @if(prevExtraCover.subCoverCode === 'A207'){
                                            <span nxCopytext="medium">{{ prevExtraCover.subCoverDescp }}</span>
                                        }
    
                                        @if(prevExtraCover.subCoverCode !== 'A207'){
                                        <span nxCopytext="medium">{{ prevExtraCover.subCoverCodeDescription }}</span>
                                        }
    
                                        @if(prevExtraCover.subCoverBenefitList?.length) {
                                        <div class="extra-coverage-padding-top-cov">
                                            <ul nxList>
                                                @for(subItem of prevExtraCover.subCoverBenefitList; track subItem) {
                                                <li style="margin-bottom: 0px !important;" nxListIcon="" size="s">
                                                    <div nxCopytext="medium">
                                                        <div nxRow col="12">
                                                            <div>- </div>
                                                            <div class="extra-coverage-description-cov" [innerHTML]="removeNewLinesFromOpus(subItem.subCoverBenefitDescp)"></div>
                                                            <div class="extra-coverage-medium-width-cov" [innerHTML]="removeNewLinesFromOpus(subItem.subCoverBenefitDisplay)"></div>
                                                        </div>
                                                    </div>
                                                </li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                        
                                    </td>
                                    <td nxTableCell class="table_extra_cov">
                                        
                                        <span nxCopytext="medium" [innerHTML]="removeNewLinesFromOpus(prevExtraCover.subcoverNarrDesc, prevExtraCover.subCoverCode)"></span> 

                                    </td>
                                    <td nxTableCell class="table_extra_cov">
                                        @if(prevExtraCover.subCoverPrem != 0) {
                                        <span nxCopytext="medium">{{ prevExtraCover.subCoverPrem | formatDecimal }}</span>
                                        }
                                    </td>
                                </tr>
                                }
                                }@else {
                                 <tr nxTableRow>
                                <td nxTableCell colspan="3" class="std-ta-center">NO DATA</td>
                                 </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}
