<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>Private Car Excluding Goods</h1>
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12,12,12,3" class="grid_width_page">
      <!-- 
      Note:
      Progree type:
      1. cn = cover note and quotation progress bar, 5 step
      2. endorsement = endorsement progress bar, 4 step
      3. unification = unification progress bar, 6 step
      4. rtp = road tax purpose progress bar, 3 step

      progressvalue = step index
      -->
      <app-progress-bar [progressValue]="2" [progressType]="'cn'" />
    </div>
    <div nxCol="12,12,12,9" class="grid_width_page">
      <div nxLayout="grid" class="grid_remove_padding">
        <div nxRow>
          <div nxCol="12">
            <h2 class="nx-margin-0 nx-margin-bottom-3xs">Coverage</h2>
            <p nxCopytext="small"><i>All fields are mandatory.</i></p>
          </div>
        </div>

        <div nxRow class="nx-margin-top-2m">
          <div nxCol="12">
            <nx-accordion variant="light">
              <nx-expansion-panel expanded="[true]">
                <nx-expansion-panel-header>
                  <nx-expansion-panel-title>Vehicle Details
                  </nx-expansion-panel-title>
                </nx-expansion-panel-header>

                <form class="form-example">
                  <div nxLayout="grid nopadding">
                    <div nxRow>
                      <div nxCol="12">
                        <span nxCopytext="normal">Where is the vehicle being used?</span><br />
                        <div nxLayout="grid nopadding" class="nx-margin-top-s">
                          <div nxRow>
                            <div nxCol="12,12,4">
                              <nx-radio name="group1" [checked]="true">West Malaysia</nx-radio>
                            </div>
                            <div nxCol="12,12,8" class="m_margin_top_20">
                              <nx-radio name="group1">
                                East Malaysia (Sabah, Sarawak, Labuan)</nx-radio>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div nxCol="12" class="nx-margin-top-2m">
                        <nx-formfield label="Use of Vehicle">
                          <nx-dropdown>
                            <nx-dropdown-item value="1">PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY
                              USE)</nx-dropdown-item>
                          </nx-dropdown>
                          <nx-error nxFormfieldNote><strong>Use of Vehicle required</strong></nx-error>
                        </nx-formfield>
                      </div>

                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Vehicle No.">
                          <input nxInput />
                          <nx-error nxFormfieldNote><strong>Vehicle No. required</strong> <br />
                          </nx-error>
                          <nx-error nxFormfieldNote>
                            <strong>Invalid Vehicle No.</strong>
                          </nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Year of Manufacture">
                          <input nxInput />
                          <nx-error nxFormfieldNote><strong>Year of Manufacture required </strong>
                          </nx-error>
                          <nx-error nxFormfieldNote>
                            <strong>Invalid year</strong>
                          </nx-error>
                          <nx-error nxFormfieldNote>
                            <strong>
                              Invalid vehicle make year</strong></nx-error>
                        </nx-formfield>
                      </div>

                      <div nxCol="12">
                        <nx-formfield label="Make / Model" (click)="openPopupMakeModel('makemodel')">
                          <input nxInput />
                          <span nxFormfieldSuffix>
                            <nx-icon aria-hidden="true" name="search"></nx-icon>
                          </span>
                          <nx-error nxFormfieldNote><strong>Make / Model required</strong></nx-error>
                        </nx-formfield>
                      </div>

                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Unit of Measurement">
                          <input nxInput disabled />
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Capacity">
                          <input nxInput />
                          <nx-error nxFormfieldNote><strong>Capacity required</strong>
                          </nx-error>
                          <nx-error nxFormfieldNote><strong>Please change to correct Vehicle Capacity.
                            </strong>
                          </nx-error>
                          <nx-error nxFormfieldNote>
                            <strong>CC value between 40 to 99999</strong>
                          </nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Engine Type">
                          <nx-dropdown>
                            <nx-dropdown-item value="1">INTERNAL COMBUSTION ENGINE</nx-dropdown-item>
                          </nx-dropdown>
                          <nx-error nxFormfieldNote>
                            <strong>Please select Engine Type</strong></nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="No. of Seats">
                          <input nxInput />
                          <nx-error nxFormfieldNote><strong>No. of Seats required</strong>
                          </nx-error>
                          <nx-error nxFormfieldNote>
                            <strong>Only positive number is allowed</strong>
                          </nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Engine / Motor No.">
                          <input nxInput />
                          <nx-error nxFormfieldNote><strong>Engine/Motor No. required
                            </strong></nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Chassis No.">
                          <input nxInput />
                          <nx-error nxFormfieldNote><strong>Chassis No. required</strong></nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Loan Type (Optional)">
                          <nx-dropdown>
                            <nx-dropdown-item value="1">HIRE PURCHASE</nx-dropdown-item>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Loan Company (Optional)">
                          <input nxInput />
                          <nx-error nxFormfieldNote><strong>Loan Company required</strong></nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Vehicle Purchase Date (Optional)">
                          <input nxDatefield nxInput [datepicker]="myDatepicker" />
                          <span nxFormfieldHint>MM/DD/YYYY</span>

                          <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                          <nx-datepicker #myDatepicker></nx-datepicker>
                        </nx-formfield>
                      </div>
                      <div nxCol="12">
                        <nx-checkbox class="nx-margin-bottom-s" [checked]="true">
                          I would like to amend the Make / Model Description to
                          match loan documents.
                        </nx-checkbox>

                        <div class="make_model">
                          <div class="field_label">
                            Make / Model to be printed in Quotation / Cover Note
                          </div>
                          <div class="custom">
                            <div class="make_container">
                              <span class="make_label">{{ makeModel }}</span>
                            </div>

                            <div class="input_container customField formfield_no_padding">
                              <nx-formfield>
                                <textarea nxInput cdkTextareaAutosize value="TEST" (keyup)="onKeyUp($event)"></textarea>

                                <span nxFormfieldHint>
                                  <span *ngIf="characterCount === 0">
                                    Max. 90 characters
                                  </span>
                                  <span *ngIf="characterCount > 0">
                                    {{ 90 - (characterCount || 0) }} characters
                                    remaining
                                  </span>
                                </span>
                              </nx-formfield>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </nx-expansion-panel>
            </nx-accordion>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-accordion variant="light">
              <nx-expansion-panel>
                <nx-expansion-panel-header>
                  <nx-expansion-panel-title class="nx-font-weight-semibold">Type of Coverage</nx-expansion-panel-title>
                </nx-expansion-panel-header>

                <div class="button_coverage">
                  <button nxButton="primary small" type="button" class="nx-margin-right-s" (click)="toggleDataComp()">
                    <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>Comprehensive
                  </button>
                  <button nxButton="primary small" type="button" class="nx-margin-right-s" (click)="toggleDataTP()">
                    <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>Third Party
                  </button>
                  <button nxButton="primary small" type="button" class="nx-margin-bottom-m" (click)="toggleDataTPFT()">
                    <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>Third Party, Fire and Theft
                  </button>
                </div>
                <!--Comprehensive-->
                <div class="blue_panel nx-margin-top-xs" *ngIf="toDisplayComp">
                  <nx-expansion-panel expanded="[true]">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title>
                        <div nxLayout="grid" class="coverage">
                          <div nxRow>
                            <div nxCol="12,12,8" class="m_padding_bottom_10">
                              <p nxCopytext="small">Coverage Description</p>
                              <h2 class="nx-margin-0">Comprehensive</h2>
                            </div>
                            <div nxCol="12,12,4" class="container_flex_center align_right">
                              <div class="gross">
                                <p nxCopytext="small">Gross Premium (RM)</p>
                                <p nxCopytext="large">1,181.91</p>
                              </div>
                              <div class="nx-margin-left-s">
                                <nx-icon name="trash-o" size="s" class="gross_trash"></nx-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div nxLayout="grid" class="coverage">
                      <div nxRow>
                        <div nxCol="12">
                          <p nxCopytext="normal" class="nx-margin-y-m">
                            <i>Note: If the selected Sum Insured is not Allianz
                              Agreed Value, Indemnity Value shall apply.</i>
                          </p>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6" class="agreed_value">
                          <div nxLayout="grid" class="grid_remove_padding">
                            <div nxRow rowAlignItems="center,center">
                              <div nxCol="12,12,12,12,6">
                                <nx-formfield label="Vehicle Sum Insured (RM)">
                                  <input nxInput />
                                </nx-formfield>
                                <p nxCopytext="small"
                                  class="nx-margin-bottom-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                                  <i>(Recommended Sum Insured Range : 30,000 -
                                    40,000)</i>
                                </p>
                              </div>
                              <div nxCol="12,12,12,12,6">
                                <nx-checkbox class="nx-margin-bottom-xs">
                                  Agreed Value
                                </nx-checkbox>

                                <div
                                  class="nx-margin-y-m nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                                  <nx-error nxFormfieldNote>
                                    <strong>Underinsurance applies</strong>
                                  </nx-error>
                                  <button nxPlainButton (click)="openMessage('SI10K')">
                                    Sum Insured 10,000 popup</button><br />
                                  <button nxPlainButton (click)="openMessage('SI1K')">
                                    Sum Insured 1,000 popup</button><br />
                                  <button nxPlainButton (click)="openMessage('SI4K')">
                                    Sum Insured 4,000 popup</button><br />
                                  <button nxPlainButton (click)="openMessage('betterment')">
                                    Vehicle betterment popup</button><br />
                                  <button nxPlainButton (click)="openMessage('1allianz')">
                                    One Allianz customer popup</button><br />
                                  <button nxPlainButton (click)="openMessage('mingross')">
                                    Minimum Gross popup</button><br />
                                  <button nxPlainButton (click)="openMessage('duplication')">
                                    Duplication of Coverage popup</button><br />
                                  <button nxPlainButton (click)="openMessage('blocked')">
                                    Application blocked popup</button><br />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="nx-hidden-xs nx-hidden-s nx-hidden-m nx-margin-bottom-m">
                            <p nxCopytext="small" class="nx-margin-bottom-m">
                              <i>(Recommended Sum Insured Range : 30,000 -
                                40,000)</i>
                            </p>
                            <nx-error nxFormfieldNote>
                              <strong>Underinsurance applies</strong>
                            </nx-error>
                            <button nxPlainButton (click)="openMessage('SI10K')">
                              Sum Insured 10,000 popup</button><br />
                            <button nxPlainButton (click)="openMessage('SI1K')">
                              Sum Insured 1,000 popup</button><br />
                            <button nxPlainButton (click)="openMessage('SI4K')">
                              Sum Insured 4,000 popup</button><br />
                            <button nxPlainButton (click)="openMessage('betterment')">
                              Vehicle betterment popup</button><br />
                            <button nxPlainButton (click)="openMessage('1allianz')">
                              One Allianz customer popup</button><br />
                            <button nxPlainButton (click)="openMessage('mingross')">
                              Minimum Gross popup</button><br />
                            <button nxPlainButton (click)="openMessage('duplication')">
                              Duplication of Coverage popup</button><br />
                            <button nxPlainButton (click)="openMessage('blocked')">
                              Application blocked popup</button><br />
                          </div>
                        </div>
                        <div nxCol="12,12,6" class="m_padding_top_20">
                          <nx-formfield label="Risk Excess Amount">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Basic Premium (RM)">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Annual Premium (RM)">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount (%)">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount Amount (RM)">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount From">
                            <nx-dropdown>
                              <nx-dropdown-item value="AIA"></nx-dropdown-item>
                              <nx-dropdown-item value="ALLIANZ MALAYSIA"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount Vehicle No.">
                            <input nxInput />
                            <nx-error nxFormfieldNote>
                              <strong>Invalid Vehicle No.</strong>
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <button nxButton="secondary small" type="button">
                            GET NO CLAIM DISCOUNT
                          </button>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <hr class="nx-margin-y-2m" />
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <nx-checkbox>
                            WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER
                          </nx-checkbox>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <p nxCopytext="medium">
                            <i>Note: If you have named driver, please key in now
                              or select WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                              DRIVER for RM 20.00.</i>
                          </p>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12" class="driver_list">
                          <div class="nx-margin-top-2m">
                            <app-external-scrollbar targetSyncScroll="1"
                              [targetBaseWidth]="tableHolder1"></app-external-scrollbar>
                            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder1
                              syncScroll="1">
                              <!-- table here -->
                              <table nxTable>
                                <thead>
                                  <tr nxTableRow>
                                    <th nxHeaderCell></th>
                                    <th nxHeaderCell>Driver Name</th>
                                    <th nxHeaderCell>Driver ID</th>
                                    <th nxHeaderCell colspan="2">Driver Age</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr nxTableRow>
                                    <td nxTableCell>1</td>
                                    <td nxTableCell>
                                      <nx-formfield appearance="outline">
                                        <input nxInput disabled value="THE POLICYHOLDER" />
                                      </nx-formfield>
                                    </td>
                                    <td nxTableCell>
                                      <nx-formfield appearance="outline" class="driver_id">
                                        <input nxInput disabled value="880914145277" />
                                      </nx-formfield>
                                    </td>
                                    <td nxTableCell>
                                      <nx-formfield appearance="outline" class="driver_age">
                                        <input nxInput disabled value="36" />
                                      </nx-formfield>
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>2</td>
                                    <td nxTableCell>
                                      <nx-formfield appearance="outline">
                                        <input nxInput value="ALICE WONG" />
                                      </nx-formfield>
                                    </td>
                                    <td nxTableCell>
                                      <nx-formfield appearance="outline" class="driver_id">
                                        <input nxInput value="200914145277" />
                                      </nx-formfield>
                                    </td>
                                    <td nxTableCell class="container_flex_center">
                                      <nx-formfield appearance="outline" class="driver_age">
                                        <input nxInput value="23" />
                                      </nx-formfield>
                                      <button nxPlainButton type="button" class="nx-margin-left-m">
                                        <nx-icon name="trash-o" size="s"></nx-icon>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>3</td>
                                    <td nxTableCell>
                                      <nx-formfield appearance="outline">
                                        <input nxInput value="PHILIP WONG" />
                                      </nx-formfield>
                                    </td>
                                    <td nxTableCell>
                                      <nx-formfield appearance="outline" class="driver_id formfield_no_padding">
                                        <input nxInput value="200914145277" />
                                      </nx-formfield>
                                    </td>
                                    <td nxTableCell class="container_flex_center">
                                      <nx-formfield appearance="outline" class="driver_age formfield_no_padding">
                                        <input nxInput value="23" />
                                      </nx-formfield>
                                      <button nxPlainButton type="button" class="nx-margin-left-m">
                                        <nx-icon name="trash-o" size="s"></nx-icon>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </div>
                          </div>
                          <button nxPlainButton (click)="openMessage('duplicatedriver')">
                            Duplicate driver name popup.
                          </button>
                          <!-- <nx-swipebar
                            label="Swipe to see more"
                            class="nx-margin-top-2m"
                          >
                            <table nxTable>
                              <thead>
                                <tr nxTableRow>
                                  <th nxHeaderCell></th>
                                  <th nxHeaderCell>Driver Name</th>
                                  <th nxHeaderCell>Driver ID</th>
                                  <th nxHeaderCell colspan="2">Driver Age</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr nxTableRow>
                                  <td nxTableCell>1</td>
                                  <td nxTableCell>
                                    <nx-formfield appearance="outline">
                                      <input
                                        nxInput
                                        disabled
                                        value="THE POLICYHOLDER"
                                      />
                                    </nx-formfield>
                                  </td>
                                  <td nxTableCell>
                                    <nx-formfield
                                      appearance="outline"
                                      class="driver_id"
                                    >
                                      <input
                                        nxInput
                                        disabled
                                        value="880914145277"
                                      />
                                    </nx-formfield>
                                  </td>
                                  <td nxTableCell>
                                    <nx-formfield
                                      appearance="outline"
                                      class="driver_age"
                                    >
                                      <input nxInput disabled value="36" />
                                    </nx-formfield>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>2</td>
                                  <td nxTableCell>
                                    <nx-formfield appearance="outline">
                                      <input nxInput value="ALICE WONG" />
                                    </nx-formfield>
                                  </td>
                                  <td nxTableCell>
                                    <nx-formfield
                                      appearance="outline"
                                      class="driver_id"
                                    >
                                      <input nxInput value="200914145277" />
                                    </nx-formfield>
                                  </td>
                                  <td nxTableCell class="container_flex_center">
                                    <nx-formfield
                                      appearance="outline"
                                      class="driver_age"
                                    >
                                      <input nxInput value="23" />
                                    </nx-formfield>
                                    <button
                                      nxPlainButton
                                      type="button"
                                      class="nx-margin-left-m"
                                    >
                                      <nx-icon
                                        name="trash-o"
                                        size="s"
                                      ></nx-icon>
                                    </button>
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>3</td>
                                  <td nxTableCell>
                                    <nx-formfield appearance="outline">
                                      <input nxInput value="PHILIP WONG" />
                                    </nx-formfield>
                                  </td>
                                  <td nxTableCell>
                                    <nx-formfield
                                      appearance="outline"
                                      class="driver_id formfield_no_padding"
                                    >
                                      <input nxInput value="200914145277" />
                                    </nx-formfield>
                                  </td>
                                  <td nxTableCell class="container_flex_center">
                                    <nx-formfield
                                      appearance="outline"
                                      class="driver_age formfield_no_padding"
                                    >
                                      <input nxInput value="23" />
                                    </nx-formfield>
                                    <button
                                      nxPlainButton
                                      type="button"
                                      class="nx-margin-left-m"
                                    >
                                      <nx-icon
                                        name="trash-o"
                                        size="s"
                                      ></nx-icon>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <button
                              nxPlainButton
                              (click)="openMessage('duplicatedriver')"
                            >
                              Duplicate driver name popup.
                            </button>
                          </nx-swipebar> -->
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <hr class="nx-margin-y-2m" />
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <h4 class="nx-margin-0">e-Hailing Driver Name</h4>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12" class="driver_list" class="nx-margin-top-2m">
                          <table nxTable>
                            <thead>
                              <tr nxTableRow>
                                <th nxHeaderCell></th>
                                <th nxHeaderCell>Driver Name</th>
                                <th nxHeaderCell>Driver ID</th>
                                <th nxHeaderCell></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr nxTableRow>
                                <td nxTableCell>1</td>
                                <td nxTableCell>
                                  <nx-formfield appearance="outline" class="formfield_no_padding">
                                    <input nxInput disabled value="THE POLICYHOLDER" />
                                  </nx-formfield>
                                </td>
                                <td nxTableCell>
                                  <nx-formfield appearance="outline" class="driver_id formfield_no_padding">
                                    <input nxInput disabled value="880914145277" />
                                  </nx-formfield>
                                </td>
                                <td nxTableCell>
                                  <button nxPlainButton type="button" class="nx-margin-left-m">
                                    <nx-icon name="trash-o" size="s"></nx-icon>
                                  </button>
                                </td>
                              </tr>
                              <tr nxTableRow>
                                <td nxTableCell>2</td>
                                <td nxTableCell>
                                  <nx-formfield appearance="outline" class="formfield_no_padding">
                                    <input nxInput value="ALICE WONG" />
                                  </nx-formfield>
                                </td>
                                <td nxTableCell>
                                  <nx-formfield appearance="outline" class="driver_id">
                                    <input nxInput value="200914145277" />
                                  </nx-formfield>
                                </td>
                                <td nxTableCell>
                                  <button nxPlainButton type="button" class="nx-margin-left-m">
                                    <nx-icon name="trash-o" size="s"></nx-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button nxPlainButton (click)="openMessage('ehailingdriver')">
                            Minimum 1 e-Hailing popup
                          </button>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <button nxPlainButton type="button" class="nx-margin-right-m">
                            <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>ADD DRIVER
                          </button>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <hr />
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <h4 class="nx-margin-0">Extra Coverage</h4>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12" class="nx-font-weight-semibold">
                          These coverage(s) / service(s) are provided at no
                          extra cost.
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <table class="extra_package">
                            <tbody>
                              <tr nxTableRow>
                                <td>
                                  <nx-checkbox [disabled]="true" [checked]="true" labelSize="small">
                                    <div class="nx-font-weight-bold">
                                      RAHMAH PACKAGE
                                    </div>
                                  </nx-checkbox>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          COMPASSIONATE FLOOD COVER
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="si">SUM INSURED (RM) : 3,000.00</td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          DEATH/PERMANENT DISABLEMENT
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="si">SUM INSURED (RM) : 5,000.00</td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          HOSPITAL INCOME
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="si">
                                  RM 50.00 PER DAY / MAX 60 DAYS
                                </td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <nx-checkbox [disabled]="true" [checked]="true" class="nx-margin-top-3xs">
                                    <div class="nx-font-weight-bold">
                                      24-HOUR EMERGENCY TOWING (UP TO 150KM)
                                    </div>
                                  </nx-checkbox>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr nxTableRow class="nx-margin-bottom-2m">
                                <td>
                                  <nx-checkbox [checked]="true" class="nx-font-weight-semibold"
                                    class="nx-margin-top-3xs">
                                    <div class="nx-font-weight-bold">
                                      MOTOR ROAD WARRIOR
                                    </div>
                                  </nx-checkbox>
                                </td>
                                <td class="si nx-margin-top-3xs">
                                  PLAN / UNIT : 1 UNIT <br />
                                  SUM INSURED (RM) : 10,000.00
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>

                          <div class="nx-margin-top-m">
                            <app-external-scrollbar targetSyncScroll="2"
                              [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
                            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2
                              syncScroll="2">
                              <table nxTable class="more_extra_cover">
                                <tbody>
                                  <tr nxTableRow>
                                    <th nxHeaderCell>
                                      <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                        <div class="nx-font-weight-bold">
                                          Extra Cover
                                        </div>
                                      </nx-checkbox>
                                    </th>
                                    <th nxHeaderCell></th>
                                    <th nxHeaderCell>Premium (RM)</th>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        MOTOR ROAD WARRIOR
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell>
                                      <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">PLAN / UNIT :
                                        </span>
                                        <nx-formfield class="formfield_no_padding">
                                          <nx-dropdown [panelGrow]="true" value="1 UNIT">
                                            <nx-dropdown-item value="1 UNIT" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="2 UNIT" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="3 UNIT" class="table_dropdown"></nx-dropdown-item>
                                          </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Plan required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      20.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                                        DRIVERS
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell></td>
                                    <td nxTableCell class="std-ta-right">
                                      20.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        COVER FOR WINDSCREENS, WINDOWS AND
                                        SUNROOF
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                          <input nxInput value="500.00" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Amount required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      60.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        INCLUSION OF SPECIAL PERILS
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      80.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        MOTOR ENHANCED ROAD WARRIOR
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">PLAN / UNIT :
                                        </span>
                                        <nx-formfield class="formfield_no_padding">
                                          <nx-dropdown [panelGrow]="true" value="PLAN A">
                                            <nx-dropdown-item value="PLAN A" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="PLAN B" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="PLAN C" class="table_dropdown"></nx-dropdown-item>
                                          </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Plan required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      75.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        OPTIONAL CAR REPLACEMENT
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      75.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        WAIVER OF BETTERMENT CONTRIBUTION
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      230.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        KEY CARE
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small" class="color_disabled">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                          <input nxInput value="1,000.00" disabled class="color_disabled" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote>
                                          <strong>Amount required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      35.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        PEER-TO-PEER (P2P)
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">SERVICE PROVIDER :
                                        </span>
                                        <nx-formfield class="formfield_no_padding">
                                          <nx-dropdown [panelGrow]="true" value="FLUX">
                                            <nx-dropdown-item value="FLUX">
                                              <nx-checkbox class="table_dropdown">FLUX</nx-checkbox>
                                            </nx-dropdown-item>
                                            <nx-dropdown-item value="OTHERS">
                                              <nx-checkbox class="table_dropdown">OTHERS</nx-checkbox>
                                            </nx-dropdown-item>
                                            <nx-dropdown-item value="trevo">
                                              <nx-checkbox class="table_dropdown">TREVO</nx-checkbox>
                                            </nx-dropdown-item>
                                          </nx-dropdown>
                                        </nx-formfield>

                                        <nx-error nxFormfieldNote>
                                          <strong>Please select P2P Service
                                            Provider</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      35.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        DRIVER AND PASSENGERS SHIELD
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">PLAN / UNIT :
                                        </span>
                                        <nx-formfield class="formfield_no_padding">
                                          <nx-dropdown [panelGrow]="true" value="PLAN A">
                                            <nx-dropdown-item value="PLAN A" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="PLAN B" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="PLAN C" class="table_dropdown"></nx-dropdown-item>
                                          </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Plan required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      30.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td>
                                      <div class="sub_cover">
                                        <ul nxList>
                                          <li nxListIcon="minus" size="s">
                                            <p nxCopytext="medium">
                                              DEATH/PERMANENT DISABLEMENT
                                              OCCURRING WITHIN 180 DAYS OF THE
                                              ACCIDENT
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td class="std-ta-right">
                                      <p nxCopytext="medium">
                                        SUM INSURED (RM) : 25,000 PER PERSON
                                      </p>
                                    </td>
                                    <td class="std-ta-right"></td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td>
                                      <div class="sub_cover sub_line_cover">
                                        <ul nxList>
                                          <li nxListIcon="minus" size="s">
                                            <p nxCopytext="medium">
                                              HOSPITAL INCOME
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td class="std-ta-right">
                                      RM 50.00 PER DAY / MAX 60 DAYS
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td class="sub_line">
                                      <div class="sub_cover sub_line_cover">
                                        <ul nxList>
                                          <li nxListIcon="minus" size="s">
                                            <p nxCopytext="medium">
                                              FUNERAL EXPENSES
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td class="std-ta-right sub_line">
                                      RM 1,000.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        COMPENSATION FOR ASSESSED REPAIR TIME
                                        (CART)
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">NO. OF DAYS :</span>
                                        <nx-formfield class="formfield_no_padding">
                                          <nx-dropdown [panelGrow]="true" [panelMaxWidth]="2000" value="7">
                                            <nx-dropdown-item value="0" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="21" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="14" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="7" class="table_dropdown"></nx-dropdown-item>
                                          </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>No. of days required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                      <nx-natural-language-form size="small">
                                        <span class="nx-margin-right-3xs">AMOUNT PER DAY:</span>
                                        <nx-formfield class="formfield_no_padding">
                                          <nx-dropdown [panelGrow]="true" [panelMaxWidth]="2000" value="50">
                                            <nx-dropdown-item value="0" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="200" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="100" class="table_dropdown"></nx-dropdown-item>
                                            <nx-dropdown-item value="50" class="table_dropdown"></nx-dropdown-item>
                                          </nx-dropdown>
                                        </nx-formfield>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Amount per day required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      75.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        GAS CONVERSION KIT AND TANK
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                          <input nxInput value="100.00" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Amount required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      7.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        SEPARATE COVER FOR ACCESSORIES FIXED TO
                                        YOUR CAR/ MOTORCYCLE
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                          <input nxInput value="100.00" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Amount required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      30.00
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        CAR BREAK-IN/ROBBERY
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      <nx-natural-language-form size="small" class="color_disabled">
                                        SUM INSURED (RM) :
                                        <nx-word>
                                          <input nxInput value="500.00" disabled class="color_disabled" />
                                        </nx-word>
                                        <nx-error nxFormfieldNote class="nx-margin-top-3xs">
                                          <strong>Amount required</strong>
                                        </nx-error>
                                      </nx-natural-language-form>
                                    </td>
                                    <td nxTableCell class="std-ta-right">
                                      3.00
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <button nxPlainButton type="button" class="nx-margin-right-m"
                            (click)="openPopupComp('comprehensive')">
                            <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                            ADD MORE
                          </button>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <nx-card>
                            <div nxLayout="grid nopadding" class="summary">
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5" class="title">
                                  Premium Summary
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center"></div>
                                <div nxCol="5,4,5" class="std-ta-right title">
                                  Subtotal (RM)
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">Basic Premium</div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  1,181.97
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">
                                  No Claim Discount (25.00%)
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  259.45
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">
                                  Total Extra Cover Premium
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  575.00
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,4">Gross Premium</div>
                                <div nxCol="1,1,4" class="std-ta-center">:</div>
                                <div nxCol="5,4,4" class="std-ta-right">
                                  2,016.42
                                </div>
                              </div>
                            </div>
                          </nx-card>
                        </div>
                      </div>
                    </div>
                  </nx-expansion-panel>
                </div>
                <!--Third Party-->
                <div class="blue_panel nx-margin-top-xs" *ngIf="toDisplayTP">
                  <nx-expansion-panel expanded="[true]">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title>
                        <div nxLayout="grid" class="coverage">
                          <div nxRow>
                            <div nxCol="12,12,8" class="m_padding_bottom_10">
                              <p nxCopytext="small">Coverage Description</p>
                              <h2 class="nx-margin-0">Third Party</h2>
                            </div>
                            <div nxCol="12,12,4" class="container_flex_center align_right">
                              <div class="gross">
                                <p nxCopytext="small">Gross Premium (RM)</p>
                                <p nxCopytext="large">273.43</p>
                              </div>
                              <div class="nx-margin-left-s">
                                <nx-icon name="trash-o" size="s" class="gross_trash"></nx-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div nxLayout="grid" class="coverage">
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12,12,6">
                          <nx-formfield label="Vehicle Sum Insured (RM)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Annual Premium (RM)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Gross Premium (RM)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Basic Premium (RM)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Vehicle Age Loading (%)">
                            <nx-dropdown value="50.00">
                              <nx-dropdown-item value="50.00"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Driver Age Loading (%)">
                            <nx-dropdown value="0.00">
                              <nx-dropdown-item value="0.00"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Claims Experience Loading (%)">
                            <nx-dropdown value="50.00">
                              <nx-dropdown-item value="50.00"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Loading Amount (RM)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount (%)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount Amount (RM)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount From">
                            <nx-dropdown>
                              <nx-dropdown-item value="AIA"></nx-dropdown-item>
                              <nx-dropdown-item value="Allianz Malaysia"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount Vehicle No.">
                            <input nxInput />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <button nxButton="secondary small" type="button">
                            GET NO CLAIM DISCOUNT
                          </button>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <hr class="nx-margin-y-2m" />
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <h4 class="nx-margin-0">Named Driver</h4>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12" class="driver_list">
                          <div class="nx-margin-top-2m">
                            <app-external-scrollbar targetSyncScroll="3"
                              [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
                            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3
                              syncScroll="3">
                              <!-- table here -->
                              <table nxTable>
                                <thead>
                                  <tr nxTableRow>
                                    <th nxHeaderCell>Driver Name</th>
                                    <th nxHeaderCell>Driver ID</th>
                                    <th nxHeaderCell>Age</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr nxTableRow>
                                    <td nxTableCell>ANY AUTHORISED DRIVERS</td>
                                    <td nxTableCell>N/A</td>
                                    <td nxTableCell>N/A</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <!-- <nx-swipebar
                            label="Swipe to see more"
                            class="nx-margin-top-2m"
                          >
                            <table nxTable>
                              <thead>
                                <tr nxTableRow>
                                  <th nxHeaderCell>Driver Name</th>
                                  <th nxHeaderCell>Driver ID</th>
                                  <th nxHeaderCell>Age</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr nxTableRow>
                                  <td nxTableCell>ANY AUTHORISED DRIVERS</td>
                                  <td nxTableCell>N/A</td>
                                  <td nxTableCell>N/A</td>
                                </tr>
                              </tbody>
                            </table>
                          </nx-swipebar> -->
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-2m">
                        <div nxCol="12">
                          <h4 class="nx-margin-0">Extra Coverage</h4>
                        </div>
                      </div>

                      <div nxRow class="nx-margin-top-2m">
                        <div nxCol="12">
                          <div>
                            <app-external-scrollbar targetSyncScroll="4"
                              [targetBaseWidth]="tableHolder4"></app-external-scrollbar>
                            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder4
                              syncScroll="4">
                              <!-- table here -->
                              <table nxTable class="more_extra_cover">
                                <tbody>
                                  <tr nxTableRow>
                                    <th nxHeaderCell>
                                      <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                        <div class="nx-font-weight-bold">
                                          Extra Cover
                                        </div>
                                      </nx-checkbox>
                                    </th>
                                    <th nxHeaderCell></th>
                                    <th nxHeaderCell>Premium (RM)</th>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY OF PASSENGERS FOR
                                        NEGLIGENT ACTS
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell></td>
                                    <td nxTableCell class="std-ta-right">7.50</td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY TO PASSENGERS
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      33.75
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        EXTENSION OF COVER TO THE KINGDOM OF
                                        THAILAND (APPLICABLE TO PRIVATE CAR THIRD
                                        PARTY POLICY ONLY)
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      101.25
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        TUITION PURPOSES
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      67.50
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        EXTENSION OF COVER TO KALIMANTAN
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      30.80
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        PRIVATELY OWNED CARS USED FOR TUTION
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      67.50
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <!-- <nx-swipebar label="Swipe to see more">
                            <table nxTable class="more_extra_cover">
                              <tbody>
                                <tr nxTableRow>
                                  <th nxHeaderCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      class="nx-font-weight-semibold"
                                    >
                                      <div class="nx-font-weight-bold">
                                        Extra Cover
                                      </div>
                                    </nx-checkbox>
                                  </th>
                                  <th nxHeaderCell></th>
                                  <th nxHeaderCell>Premium (RM)</th>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      LEGAL LIABILITY OF PASSENGERS FOR
                                      NEGLIGENT ACTS
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell></td>
                                  <td nxTableCell class="std-ta-right">7.50</td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      LEGAL LIABILITY TO PASSENGERS
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    33.75
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      EXTENSION OF COVER TO THE KINGDOM OF
                                      THAILAND (APPLICABLE TO PRIVATE CAR THIRD
                                      PARTY POLICY ONLY)
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    101.25
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      TUITION PURPOSES
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    67.50
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      EXTENSION OF COVER TO KALIMANTAN
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    30.80
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      PRIVATELY OWNED CARS USED FOR TUTION
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    67.50
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </nx-swipebar> -->
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-2m">
                        <div nxCol="12">
                          <button nxPlainButton type="button" class="nx-margin-right-m" (click)="openPopup('tp')">
                            <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                            ADD MORE
                          </button>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-2m">
                        <div nxCol="12">
                          <nx-card>
                            <div nxLayout="grid nopadding" class="summary">
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5" class="title">
                                  Premium Summary
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center"></div>
                                <div nxCol="5,4,5" class="std-ta-right title">
                                  Subtotal (RM)
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">Basic Premium</div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  568.92
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">Loading (0%)</div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  0.00
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">
                                  No Claim Discount (25.00%)
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  259.45
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">
                                  Total Extra Cover Premium
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  307.80
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">Gross Premium</div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  580.31
                                </div>
                              </div>
                            </div>
                          </nx-card>
                        </div>
                      </div>
                    </div>
                  </nx-expansion-panel>
                </div>
                <!--Third Party, Fire and Theft-->
                <div class="blue_panel nx-margin-top-xs" *ngIf="toDisplayTPFT">
                  <nx-expansion-panel expanded="[true]">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title>
                        <div nxLayout="grid" class="coverage">
                          <div nxRow>
                            <div nxCol="12,12,8" class="m_padding_bottom_10">
                              <p nxCopytext="small">Coverage Description</p>
                              <h2 class="nx-margin-0">
                                Third Party, Fire and Theft
                              </h2>
                            </div>
                            <div nxCol="12,12,4" class="container_flex_center align_right">
                              <div class="gross">
                                <p nxCopytext="small">Gross Premium (RM)</p>
                                <p nxCopytext="large">1,577.49</p>
                              </div>
                              <div class="nx-margin-left-s">
                                <nx-icon name="trash-o" size="s" class="gross_trash"></nx-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div nxLayout="grid" class="coverage">
                      <div nxRow>
                        <div nxCol="12">
                          <p nxCopytext="normal" class="nx-margin-y-m">
                            <i>Note: Please select Sum Insured within
                              recommended Sum Insured range to avoid
                              underinsurance.</i>
                          </p>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6" class="agreed_value">
                          <div nxLayout="grid" class="grid_remove_padding">
                            <div nxRow rowAlignItems="center,center">
                              <div nxCol="12,12,6">
                                <nx-formfield label="Vehicle Sum Insured (RM)">
                                  <input nxInput />
                                  <nx-error nxFormfieldError>
                                    <strong>Please note:</strong> this field is
                                    required!
                                  </nx-error>
                                </nx-formfield>
                                <p nxCopytext="small"
                                  class="nx-margin-bottom-s nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                                  <i>(Recommended Sum Insured Range : 30,000 -
                                    40,000)</i>
                                </p>
                              </div>
                              <div nxCol="12,12,6">
                                <nx-checkbox class="nx-margin-bottom-xs">
                                  Recommended Value
                                </nx-checkbox>
                                <nx-message context="error"
                                  class="nx-margin-y-m nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
                                  <span class="nx-font-weight-bold">Underinsurance applies</span>
                                </nx-message>
                              </div>
                            </div>
                          </div>
                          <div class="nx-hidden-xs nx-hidden-s nx-margin-bottom-m">
                            <p nxCopytext="small" class="nx-margin-bottom-m">
                              <i>(Recommended Sum Insured Range : 30,000 -
                                40,000)</i>
                            </p>
                            <nx-message context="error">
                              <span class="nx-font-weight-bold">Underinsurance applies</span>
                            </nx-message>
                          </div>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Risk Excess Amount">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Basic Premium (RM)">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="Annual Premium (RM)">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount (%)">
                            <input nxInput disabled />
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount Amount (RM)">
                            <input nxInput disabled />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount From">
                            <nx-dropdown>
                              <nx-dropdown-item value="AIA"></nx-dropdown-item>
                              <nx-dropdown-item value="Allianz Malaysia"></nx-dropdown-item>
                            </nx-dropdown>
                          </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                          <nx-formfield label="No Claim Discount Vehicle No.">
                            <input nxInput />
                            <nx-error nxFormfieldError>
                              <strong>Please note:</strong> this field is
                              required!
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <button nxButton="secondary small" type="button">
                            GET NO CLAIM DISCOUNT
                          </button>
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <hr class="nx-margin-y-m" />
                        </div>
                      </div>
                      <div nxRow>
                        <div nxCol="12">
                          <h4 class="nx-margin-0">Named Driver</h4>
                        </div>
                      </div>

                      <div nxRow>
                        <div nxCol="12" class="driver_list">
                          <div class="nx-margin-top-m">
                            <app-external-scrollbar targetSyncScroll="5"
                              [targetBaseWidth]="tableHolder5"></app-external-scrollbar>
                            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder5
                              syncScroll="5">
                              <!-- table here -->
                              <table nxTable>
                                <thead>
                                  <tr nxTableRow>
                                    <th nxHeaderCell>Driver Name</th>
                                    <th nxHeaderCell>Driver ID</th>
                                    <th nxHeaderCell>Age</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr nxTableRow>
                                    <td nxTableCell>ANY AUTHORISED DRIVERS</td>
                                    <td nxTableCell>N/A</td>
                                    <td nxTableCell>N/A</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!-- <nx-swipebar
                            label="Swipe to see more"
                            class="nx-margin-top-m"
                          >
                            <table nxTable>
                              <thead>
                                <tr nxTableRow>
                                  <th nxHeaderCell>Driver Name</th>
                                  <th nxHeaderCell>Driver ID</th>
                                  <th nxHeaderCell>Age</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr nxTableRow>
                                  <td nxTableCell>ANY AUTHORISED DRIVERS</td>
                                  <td nxTableCell>N/A</td>
                                  <td nxTableCell>N/A</td>
                                </tr>
                              </tbody>
                            </table>
                          </nx-swipebar> -->
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <hr />
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <h4 class="nx-margin-0">Extra Coverage</h4>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12" class="nx-font-weight-semibold">
                          These coverage(s) / service(s) are provided at no
                          extra cost.
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <table class="extra_package">
                            <tbody>
                              <tr nxTableRow>
                                <td>
                                  <nx-checkbox [disabled]="true" [checked]="true" labelSize="small">
                                    <div class="nx-font-weight-bold">
                                      RAHMAH PACKAGE
                                    </div>
                                  </nx-checkbox>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          COMPASSIONATE FLOOD COVER
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="si">SUM INSURED (RM) : 3,000.00</td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          DEATH/PERMANENT DISABLEMENT
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="si">SUM INSURED (RM) : 5,000.00</td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <div class="sub_cover">
                                    <ul nxList>
                                      <li nxListIcon="minus" size="s">
                                        <p nxCopytext="medium">
                                          HOSPITAL INCOME
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td class="si">
                                  RM 50.00 PER DAY / MAX 60 DAYS
                                </td>
                                <td></td>
                              </tr>
                              <tr nxTableRow>
                                <td>
                                  <nx-checkbox [disabled]="true" [checked]="true" class="nx-margin-top-3xs">
                                    <div class="nx-font-weight-bold">
                                      24-HOUR EMERGENCY TOWING (UP TO 150KM)
                                    </div>
                                  </nx-checkbox>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>


                          <div class="nx-margin-top-2m">
                            <app-external-scrollbar targetSyncScroll="6"
                              [targetBaseWidth]="tableHolder6"></app-external-scrollbar>
                            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder6
                              syncScroll="6">
                              <!-- table here -->
                              <table nxTable class="more_extra_cover">
                                <tbody>
                                  <tr nxTableRow>
                                    <th nxHeaderCell>
                                      <nx-checkbox [checked]="true" class="nx-font-weight-semibold">
                                        <div class="nx-font-weight-bold">
                                          Extra Cover
                                        </div>
                                      </nx-checkbox>
                                    </th>
                                    <th nxHeaderCell></th>
                                    <th nxHeaderCell>Premium (RM)</th>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY OF PASSENGERS FOR
                                        NEGLIGENT ACTS
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell></td>
                                    <td nxTableCell class="std-ta-right">7.50</td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        LEGAL LIABILITY TO PASSENGERS
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell></td>
                                    <td nxTableCell class="std-ta-right">
                                      33.75
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        EXTENSION OF COVER TO THE KINGDOM OF
                                        THAILAND
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      197.43
                                    </td>
                                  </tr>
                                  <tr nxTableRow>
                                    <td nxTableCell>
                                      <nx-checkbox [checked]="true" labelSize="small" class="nx-font-weight-semibold">
                                        TUITION PURPOSES
                                      </nx-checkbox>
                                    </td>
                                    <td nxTableCell class="std-ta-right"></td>
                                    <td nxTableCell class="std-ta-right">
                                      493.58
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!-- <nx-swipebar
                            label="Swipe to see more"
                            class="nx-margin-top-2m"
                          >
                            <table nxTable class="more_extra_cover">
                              <tbody>
                                <tr nxTableRow>
                                  <th nxHeaderCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      class="nx-font-weight-semibold"
                                    >
                                      <div class="nx-font-weight-bold">
                                        Extra Cover
                                      </div>
                                    </nx-checkbox>
                                  </th>
                                  <th nxHeaderCell></th>
                                  <th nxHeaderCell>Premium (RM)</th>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      LEGAL LIABILITY OF PASSENGERS FOR
                                      NEGLIGENT ACTS
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell></td>
                                  <td nxTableCell class="std-ta-right">7.50</td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      LEGAL LIABILITY TO PASSENGERS
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell></td>
                                  <td nxTableCell class="std-ta-right">
                                    33.75
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      EXTENSION OF COVER TO THE KINGDOM OF
                                      THAILAND
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    197.43
                                  </td>
                                </tr>
                                <tr nxTableRow>
                                  <td nxTableCell>
                                    <nx-checkbox
                                      [checked]="true"
                                      labelSize="small"
                                      class="nx-font-weight-semibold"
                                    >
                                      TUITION PURPOSES
                                    </nx-checkbox>
                                  </td>
                                  <td nxTableCell class="std-ta-right"></td>
                                  <td nxTableCell class="std-ta-right">
                                    493.58
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </nx-swipebar> -->
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <button nxPlainButton type="button" class="nx-margin-right-m" (click)="openPopup('tpft')">
                            <nx-icon name="plus" nxIconPositionStart aria-hidden="true"></nx-icon>
                            ADD MORE
                          </button>
                        </div>
                      </div>
                      <div nxRow class="nx-margin-top-m">
                        <div nxCol="12">
                          <nx-card>
                            <div nxLayout="grid nopadding" class="summary">
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5" class="title">
                                  Premium Summary
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center"></div>
                                <div nxCol="5,4,5" class="std-ta-right title">
                                  Subtotal (RM)
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">Basic Premium</div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  1,181.97
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">
                                  No Claim Discount (25.00%)
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  295.45
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,5">
                                  Total Extra Cover Premium
                                </div>
                                <div nxCol="1,1,2" class="std-ta-center">:</div>
                                <div nxCol="5,4,5" class="std-ta-right">
                                  691.01
                                </div>
                              </div>
                              <div nxRow class="nx-margin-bottom-3xs">
                                <div nxCol="6,7,4">Gross Premium</div>
                                <div nxCol="1,1,4" class="std-ta-center">:</div>
                                <div nxCol="5,4,4" class="std-ta-right">
                                  1,577.49
                                </div>
                              </div>
                            </div>
                          </nx-card>
                        </div>
                      </div>
                    </div>
                  </nx-expansion-panel>
                </div>
              </nx-expansion-panel>
            </nx-accordion>
          </div>
        </div>

        <div nxRow class="nx-margin-top-50 nx-margin-bottom-3xl">
          <div nxCol="12" class="button-container">
            <a routerLink="../customer-partner"><button nxButton="secondary" type="button">BACK</button></a>
            <button nxButton="secondary" type="button">SAVE AS DRAFT</button>
            <a routerLink="../summary"><button nxButton="primary" type="button">NEXT</button></a>
            <button nxButton="primary" type="button" (click)="openMessage('nameddriver')">NEXT</button>
            <button nxButton="primary" type="button" (click)="openMessage('1allianz')">NEXT </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>