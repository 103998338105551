import { Component } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-share-popup',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './share-popup.component.html',
  styleUrl: './share-popup.component.scss'
})
export class SharePopupComponent {

}
