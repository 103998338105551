import { Directive, ElementRef, HostListener, Injector, Input, Self } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
  standalone: true,
  selector: '[appCusCurrencyFormat]'
})
export class CusCurrencyFormatDirective {
  constructor(
    private el: ElementRef, private injector: Injector,
    @Self() private model: NgControl = injector.get(NgControl)
  ) { }

  @HostListener('focus') onFocus() {
    let newVal = this.removeComma(this.el.nativeElement.value.toString());
    if (newVal) {
      this.model.control.setValue(this.removeComma(this.el.nativeElement.value.toString()));
    }
  }

  @HostListener('blur') onBlur() {
    let newVal = this.removeComma(this.el.nativeElement.value.toString());
    if (newVal) {
      this.model.control.setValue(Number(newVal).toLocaleString(undefined, { minimumFractionDigits: 2 }));
    }
  }

  removeComma(value) {
    let newValue = value.toString().replace(/[a-zA-Z:,]+/g, "");
    return newValue;
  }
  
}
