<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>Motor</h1>
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth" class="nx-margin-top-2m">
  <div nxRow >
    <div nxCol="12,12,12,3" class="grid_width_page">
       <!-- 
      Note:
      Progree type:
      1. cn = cover note and quotation progress bar, 5 step
      2. endorsement = endorsement progress bar, 4 step
      3. unification = unification progress bar, 6 step
      4. rtp = road tax purpose progress bar, 3 step

      progressvalue = step index
      -->
      <app-progress-bar [progressValue]="0" [progressType]="'unification'" />

    </div>
    <div nxCol="12,12,12,6" class="grid_width_page">
      <div nxLayout="grid nopadding">
        <div nxRow>
          <div nxCol="12">
            <h2 class="nx-margin-0 nx-margin-bottom-xs nx-font-weight-bold" style="font-size: 22px;">
              Agent Additional Information
            </h2>
            <!-- <span nxCopytext="normal">
                <i>All fields are mandatory.</i>
              </span> -->
          </div>
        </div>
        <div nxRow>
          <div nxCol="12" class="nx-margin-top-2m">
            <span nxCopytext="normal">
              Issuing Cover Note for Agent Code <b>KG38547</b>
            </span>
          </div>
          <div nxCol="12" class="nx-margin-top-2xs">
            <span nxCopytext="small">
              <i>Agent Code may not be changed after this page.</i>
            </span>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <button
              nxPlainButton
              type="button"
              class="std-ta-left nx-margin-y-2m"
            >
              <nx-icon
                name="arrow-right"
                nxIconPositionStart
                aria-hidden="true"
              ></nx-icon
              >SELECT A DIFFERENT AGENT CODE
            </button>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <span nxCopytext="normal"><b>Customer Type</b></span> 
            <!-- <span nxCopytext="large" class="nx-font-weight-light">KG38547 - MALAYAN BANKING BERHAD</span> -->
            <h4 nxHeadline="subsection-xsmall">
              BANCASSURANCE
          </h4>
          </div>
        </div>

        <div nxRow class="nx-margin-top-m">
          <div nxCol="12">
            <span nxCopytext="normal"><b>Agent Code</b></span> 
            <!-- <span nxCopytext="large" class="nx-font-weight-light">KG38547 - MALAYAN BANKING BERHAD</span> -->
            <h4 nxHeadline="subsection-xsmall">
              KG38547 - MALAYAN BANKING BERHAD
          </h4>
          </div>
        </div>


        <div nxRow class="nx-margin-top-m">
          <div nxCol="12">
            <nx-formfield label="Bank Type">
              <nx-dropdown
                [valueFormatter]="toText">
                <nx-dropdown-item
                  value="Bank Type"
                >
                </nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldError>
                <strong> Please select Bank Type </strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Bank Channel">
              <nx-dropdown
                [valueFormatter]="toText">
                <nx-dropdown-item
                  value="Bank Channel"
                >
                </nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldError>
                <strong> Please select Bank Channel </strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Bank Sub-Channel">
              <nx-dropdown
                [valueFormatter]="toText">
                <nx-dropdown-item
                  value="Bank Sub-Channel"
                >
                </nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldError>
                <strong> Please select Bank Sub-Channel </strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Bank Branch">
              <nx-dropdown
                [valueFormatter]="toText">
                <nx-dropdown-item
                  value="Bank Branch"
                >
                </nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldError>
                <strong> Please select Bank Branch </strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Licensed Sales Staff ID">
              <input nxInput type="text" />
              <nx-error nxFormfieldError>
                <strong> Please enter Licensed Sales Staff ID. </strong> 
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Licensed Sales Staff Name">
              <input nxInput #inputToCount maxlength="200" />
              <span nxFormfieldHint>
                <span>
                  Max. 100 Characters
                </span>
              </span>

              <nx-error nxFormfieldNote>
                <strong> Please enter the Licensed Sales Staff Name.</strong> 
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Referral Sales Staff ID">
              <input nxInput #inputToCount maxlength="200" />
              <span nxFormfieldHint>
                <span>
                  Max. 100 Characters
                </span>
              </span>

              <nx-error nxFormfieldNote>
                <strong> Please enter the Referral Sales Staff ID.</strong> 
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Referral Sales Staff ID">
              <input nxInput #inputToCount maxlength="200" />

              <nx-error nxFormfieldNote>
                <strong> Please enter the Referral Sales Staff ID.</strong> 
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Referral Sales Staff Name">
              <input nxInput #inputToCount maxlength="200" />
              <span nxFormfieldHint>
                <span>
                  Max. 100 Characters
                </span>
              </span>

              <nx-error nxFormfieldNote>
                <strong> Please enter the Referral Sales Staff Name.</strong> 
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Bank Client Type">
              <nx-dropdown
                [valueFormatter]="toText">
                <nx-dropdown-item
                  value="Bank Client Type"
                >
                </nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldError>
                <strong> Please select Bank Client Type </strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Rebate Type">
              <nx-dropdown
                [valueFormatter]="toText">
                <nx-dropdown-item
                  value="Rebate Type"
                >
                </nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldError>
                <strong> Please select Rebate Type </strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow class="nx-margin-top-l nx-margin-bottom-3xl">
          <div nxCol="12">
            <button nxButton="primary" type="button">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
