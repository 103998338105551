import { Routes } from '@angular/router';

import { MotorComponent } from './motor.component';
import { AgentAdditionalInfoComponent } from './issuance/agent-additional-info/agent-additional-info.component';
import { LoginResolverService } from 'src/app/shared/services/login-resolver/login-resolver.service';
import { DeclarationIssuanceInfoComponent } from './issuance/declaration-issuance-info/declaration-issuance-info.component';
import { EndorsementDetailsComponent } from './endorsement/endorsement-details/endorsement-details.component';
import { CustomerPartnerComponent } from './issuance/customer-partner/customer-partner.component';
import { SummaryComponent } from './issuance/summary/summary.component';
import { CompleteComponent } from './issuance/complete/complete.component';
import { ViewEndorsementComponent } from '../enquiry/view-endorsement/view-endorsement.component';
import { HomeComponent } from '../home/home.component';
import { CoverageComponent } from './issuance/coverage/coverage.component';
import { ViewQuotationComponent } from "../enquiry/view-quotation/view-quotation.component";
import { ViewPolicyComponent} from "../enquiry/view-policy/view-policy.component"
import { AgentSelectionComponent } from "./issuance/agent-selection/agent-selection.component";
import { ViewApprovalComponent } from '../enquiry/view-approval/view-approval.component';
import { EndorsementCompleteComponent } from './endorsement/endorsement-complete/endorsement-complete.component';
import { NcdConfirmationComponent } from "../enquiry/ncd-confirmation/ncd-confirmation.component";
import { EndorsementDataUploadComponent } from '../enquiry/endorsement-data-upload/endorsement-data-upload.component';
import { NcdEnquiryComponent } from '../enquiry/ncd-enquiry/ncd-enquiry.component';
import { NotFoundComponent } from '../not-found/not-found.component';


export const MotorRoutes: Routes = [
  { path: 'home', component: HomeComponent, resolve: { user: LoginResolverService } },
  { path: "agent-selection", component: AgentSelectionComponent },
  {
    path: 'issuance',
    component: MotorComponent,
    children: [
      { path: 'agent-additional-info', component: AgentAdditionalInfoComponent, data: { action: 'issue' } },
      { path: "declaration-issuance-info", component: DeclarationIssuanceInfoComponent, data: { action: 'issue' } },
      { path: 'coverage', component: CoverageComponent, data: { action: 'issue' } },
      { path: 'customer-partner', component: CustomerPartnerComponent, data: { action: 'issue' } },
      { path: 'summary', component: SummaryComponent, data: { action: 'issue' } },
      { path: 'complete', component: CompleteComponent, data: { action: 'issue' } },
    ],
  },
  {
    path: 'endorsement',
    component: MotorComponent,
    children: [
      { path: 'declaration-issuance-info', component: DeclarationIssuanceInfoComponent, data: { action: 'endt' } },
      { path: 'endorsement-details', component: EndorsementDetailsComponent, data: { action: 'endt' } },
      { path: 'summary', component: SummaryComponent, data: { action: 'endt' } },
      // { path: 'complete', component: EndorsementCompleteComponent, data: { action: 'endt' } },
      { path: 'complete', component: CompleteComponent, data: { action: 'endt' } },
    ],
  },
  {
    path: 'rtp',
    component: MotorComponent,
    children: [
      { path: "declaration-issuance-info", component: DeclarationIssuanceInfoComponent, data: { action: 'rtp' } },
      { path: 'summary', component: SummaryComponent, data: { action: 'rtp' } },
      // { path: 'coverage', component: CoverageComponent, data: { action: 'issue' } },
      // { path: 'customer-partner', component: CustomerPartnerComponent, data: { action: 'issue' } },
      // { path: 'summary', component: SummaryComponent, data: { action: 'issue' } },
      // { path: 'complete', component: CompleteComponent, data: { action: 'issue' } },
    ],
  },
  { path: 'view-endorsement', component: ViewEndorsementComponent },
  { path: "view-quotation", component: ViewQuotationComponent },
  {path: "view-policy", component: ViewPolicyComponent},
  { path: "view-approval", component: ViewApprovalComponent },
  { path: "ncd-confirmation", component: NcdConfirmationComponent },
  { path: "ncd-enquiry", component: NcdEnquiryComponent },
  { path: "endorsement-data-upload", component: EndorsementDataUploadComponent},
  { path: "404", component: NotFoundComponent}

];
