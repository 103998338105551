import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from 'src/app/shared/services/http-helper/http-helper.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

  constructor(private httpHelperService: HttpHelperService) { }

  notificationList(body) {
    // return this.httpHelperService.post(`/notification/list`, body);
    return this.httpHelperService.getDirectUrl(`/assets/data-mock/notification-list-mock.json`);
  }

  sendReadNotification(payload:any): Observable<any>{
    return this.httpHelperService.post (`/notification/save`, payload );
  }
}
