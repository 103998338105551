import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { appConfig } from "./app/app.config";
import { AppComponent } from "./app/app.component";
import { importProvidersFrom } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { AppModule } from './app/app.module';

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
