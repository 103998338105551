<div class="title-header nx-margin-0">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>{{ title }}</h1>
      </div>
    </div>
  </div>
</div>

<div class="button_area_bg">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <!-- pending approval (to be move to new component) -->
        <nx-message context="info" class="green_color_notification">
          <span class="nx-font-weight-bold nx-margin-bottom-3xs">Your e-Cover Note has been submitted as pending
            approval.</span><br />

          <span nxCopytext="normal">Referral reason:
            <ul nxList="small" class="completed_list">
              <li>
                <span class="nx-font-weight-bold">CM005</span> - Renew Sum
                Insured / Refer
              </li>
              <li>
                <span class="nx-font-weight-bold">CM011</span> - High
                Performance
              </li>
            </ul>
          </span>
        </nx-message>
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth" class="nx-margin-bottom-m">
  <div nxRow>
    <div nxCol="12">
      <button nxPlainButton class="nx-margin-right-2xs" type="button" (mouseup)="showRef(templateOne, 'Policy Enquiry')"
        ng-click="show = 1">
        Policy Enquiry
      </button>
      |

      <button nxPlainButton class="nx-margin-x-2xs" type="button"
        (mouseup)="showRef(templateTwo, 'No Claim Discount Enquiry')">
        NCD Enquiry
      </button>
      |
      <button nxPlainButton class="nx-margin-x-2xs" type="button"
        (mouseup)="showRef(templateThree, 'NCD Confirmaton Enquiry')">
        NCD Confirmaton Enquiry
      </button>
      |
      <button nxPlainButton class="nx-margin-x-2xs" type="button"
        (mouseup)="showRef(templateApproval, 'Private Cars Excluding Goods')">
        Motor Approval Enquiry
      </button>
      |
      <button nxPlainButton class="nx-margin-x-2xs" type="button"
        (mouseup)="showRef(templateSummary, 'Summary Enquiry')">
        Summary Enquiry
      </button>
      |
      <button nxPlainButton class="nx-margin-x-2xs" type="button"
        (mouseup)="showRef(templateClaimEnq, 'Claim Enquiry')">
        Claim Enquiry
      </button>
    </div>
  </div>
</div>

<ng-template #templateOne>
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-m">
        <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
          <div nxRow class="enquiry_status">
            <div nxCol="12,12,10">
              <h3 nxHeadline size="s" class="nx-font-weight-regular">
                e-Policy No. :
                <span class="nx-font-weight-bold">23VAS0001685-00</span>
              </h3>
            </div>
            <div nxCol="12,12,2" class="enquiry_status">
              <nx-badge type="active" class="active_badge">Active</nx-badge>
            </div>
          </div>
        </div>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Agent Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Code</nx-data-display-label>
                    <p nxCopytext="large">BT15982</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Name</nx-data-display-label>
                    <p nxCopytext="large">GOLDEN HIGHWAY AUTO-CITY SDN BHD</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Issuance Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Product Name</nx-data-display-label>
                    <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                    <p nxCopytext="large">WA8012L</p>
                  </nx-data-display>
                </div>

                <!-- Cover Note Issuance Information (to be move to new component) -->
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Effective Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/2024</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Policy No.</nx-data-display-label>
                    <p nxCopytext="large">23VAS0001685-00</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Cover Note No.</nx-data-display-label>
                    <p nxCopytext="large">AEAS0003225</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Document Consent</nx-data-display-label>
                    <p nxCopytext="large">YES</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Invoice Consent</nx-data-display-label>
                    <p nxCopytext="large">YES</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel>
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Customer Partner
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <!-- Individual -->
            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Customer Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">INDIVIDUAL</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Name</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WONG KAM WAI</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Nationality</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">880914145277</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Old IC No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">A3078</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Policy ID No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">B3030</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Passport No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">A37</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Tax Identification No. (TIN)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">OG12345678101</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Gender</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Marital Status</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">SINGLE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Date of Birth</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">14/09/1988</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Age</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">35</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">ALIM Customer</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">NO</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Mobile No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">6012-2222222</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Fixed Line No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-33333333</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Fax No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-44444444</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Email</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">KAMWAIGMAIL.COM</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Address</span></td>
                <td nxTableCell class="column_cp_content">
                  <button nxPlainButton type="button" aria-label="add item">
                    <nx-icon name="location" aria-hidden="true"></nx-icon>
                  </button>
                  <span nxCopytext="normal">28-3, ALLIANZ MALAYSIA BERHAD, LEVEL 28, MENARA ALLIANZ
                    SENTRAL, 203, JALAN TUN SAMBANTHAN, KUALA LUMPUR SENTRAL,
                    50470 KUALA LUMPUR, WILAYAH PERSEKUTUAN, MALAYSIA</span>
                </td>
              </tr>
            </table>

            <!-- This is just spacing, you can remove it -->
            <div class="nx-margin-y-2m"></div>

            <!-- Company -->
            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Customer Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">COMPANY</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Name</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">ALPHA SDN. BHD.</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Country of Incorporation</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">New Business Registration No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">201333333322</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Business Registration No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">555666-X</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Tax Identification No. (TIN)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">C2584563202</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">SST No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">W88888888888888</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST Effective Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/07/2022</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST De-Registration Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">25/07/2024</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST Resident Status</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">RESIDENT</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST Country</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Reg. No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">SME908766</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Category</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MICRO</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Cert. Start Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">27/12/2021</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Cert. End Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/12/2023</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Mobile No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">6012-2264680</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Fixed Line No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-33333333</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Fax No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-44444444</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Email</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">ALPHAGMAIL.COM</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Address</span></td>
                <td nxTableCell class="column_cp_content">
                  <button nxPlainButton type="button" aria-label="add item">
                    <nx-icon name="location" aria-hidden="true"></nx-icon>
                  </button>
                  <span nxCopytext="normal">28-3, ALLIANZ, 203, JALAN TUN SAMBANTHAN, KUALA LUMPUR
                    SENTRAL, 50470 KUALA LUMPUR, WILAYAH PERSEKUTUAN,
                    MALAYSIA</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Referral Details
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Referral ID No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MOTOR-23023QWT</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordione_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Coverage
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <h4 class="nx-margin-bottom-2m">Vehicle Details</h4>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Where is the vehicle being used?</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WEST MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Use of Vehicle</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY USE)</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WA8012L</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Year of Manufacture</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">2007</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Make / Model</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">31*0000012 - PERODUA MYVI 1.3 (A) SE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Capacity / Unit of Measurement</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">1,298 / CC</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Engine Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">INTERNAL COMBUSTION ENGINE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No. of Seats</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">5</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Engine / Motor No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">2NROP28330</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Chassis No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PN153SYF103098483</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Loan Type</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">HIRE PURCHASE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Loan Company</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MAYBANK</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Purchase Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/07/2007</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Make / Model to be printed in Quotation / Cover Note</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PERODUA MYVI 1.3 (A) SE 2205</span>
                </td>
              </tr>
            </table>

            <hr class="nx-margin-y-2m" />

            <h4 class="nx-margin-bottom-2m">Coverage Details</h4>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Coverage Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <p nxCopytext="normal">
                    COMPREHENSIVE &nbsp;<span nxCopytext="small"><i>(Recommended Sum Insured : 30,000 -
                        40,000)</i></span>
                  </p>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Sum Insured (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">40,000.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Agreed Value</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Recommended Value</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Risk Excess (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">4,000.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Voluntary Excess (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">0.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount (%)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">25.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount From</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount Vehicle No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WA8012L</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount Effective Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">12/12/2023</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">3A Dealer</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
            </table>

            <hr class="nx-margin-y-2m" />

            <h4 class="nx-margin-bottom-2m">Extra Coverage</h4>

            <span nxCopytext="normal" class="nx-font-weight-semibold">These coverage(s) / service(s) are provided at no
              extra
              cost.</span>

            <ul nxList="small" class="completed_list">
              <li>
                <span nxCopytext="normal" class="nx-font-weight-semibold">RAHMAH PACKAGE</span>
                <ul class="nx-margin-y-0">
                  <div nxLayout="grid nopadding" class="grid_remove_padding">
                    <div nxRow>
                      <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- COMPASSIONATE FLOOD COVER</span>
                      </div>
                      <div nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">SUM INSURED (RM) : 3,000.00</span>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- DEATH/PERMANENT DISABLEMENT
                        </span>
                      </div>
                      <div nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">SUM INSURED (RM) : 5,000.00</span>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- HOSPITAL INCOME</span>
                      </div>
                      <div nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">RM 50.00 PER DAY / MAX 60 DAYS</span>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li>
                <span nxCopytext="normal" class="nx-font-weight-semibold">24-HOUR EMERGENCY TOWING (UP TO 150KM)</span>
              </li>
              <li>
                <div nxLayout="grid nopadding" class="grid_remove_padding">
                  <div nxRow>
                    <div nxCol="12,12,6,5">
                      <span nxCopytext="normal" class="nx-font-weight-semibold">MOTOR ROAD WARRIOR</span>
                    </div>
                    <div nxCol="12,12,6,4" class="extra_coverage_text">
                      <span nxCopytext="medium">PLAN / UNIT : 1 UNIT<br />SUM INSURED (RM) :
                        3,000.00</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div class="nx-margin-top-2m">
              <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder1"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder1 syncScroll="1">
                <table nxTable zebra cellpadding="0">
                  <thead>
                    <tr nxTableRow class="table_row_flex">
                      <th nxHeaderCell class="table_extra_coverage_display">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Extra Cover</span>
                      </th>
                      <th nxHeaderCell class="table_extra_coverage_display"></th>
                      <th nxHeaderCell class="table_extra_coverage_display">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Premium (RM)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">20.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">60.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">INCLUSION OF SPECIAL PERILS</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">80.0</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">MOTOR ENHANCED ROAD WARRIOR</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                          10,000.00</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">75.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">75.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">WAIVER OF BETTERMENT CONTRIBUTION</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">230.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">KEY CARE</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">SUM INSURED (RM) : 1,000.00</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">7.00</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-2m">
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow class="table_row_flex">
                    <th nxHeaderCell class="table_extra_coverage_display">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Extra Cover</span
                      >
                    </th>
                    <th nxHeaderCell class="table_extra_coverage_display"></th>
                    <th nxHeaderCell class="table_extra_coverage_display">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Premium (RM)</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">20.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">60.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >INCLUSION OF SPECIAL PERILS</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">80.0</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >MOTOR ENHANCED ROAD WARRIOR</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                        10,000.00</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">75.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">75.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >WAIVER OF BETTERMENT CONTRIBUTION</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">230.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">KEY CARE</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >SUM INSURED (RM) : 1,000.00</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">7.00</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->

            <hr class="nx-margin-y-2m" />

            <h4 class="nx-margin-bottom-2m">Named Drivers</h4>

            <div class="nx-margin-top-2m">
              <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
                <table nxTable zebra cellpadding="0">
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Driver Name</span>
                      </th>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Driver ID</span>
                      </th>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Age</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="medium">THE POLICYHOLDER</span>
                      </td>
                      <td nxTableCell>
                        <span nxCopytext="medium">880914145277</span>
                      </td>
                      <td nxTableCell><span nxCopytext="medium">36</span></td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="medium">ALL RIDER / DRIVER</span>
                      </td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="medium">ANY AUTHORISED DRIVERS</span>
                      </td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-2m">
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Driver Name</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Driver ID</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Age</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">THE POLICYHOLDER</span>
                    </td>
                    <td nxTableCell>
                      <span nxCopytext="medium">880914145277</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">36</span></td>
                  </tr>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">ALL RIDER / DRIVER</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  </tr>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">ANY AUTHORISED DRIVERS</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Historical Claim
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Client Claim History</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">0</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Claim History</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">0</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Premium Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <nx-card class="card_summary">
              <table nxTable cellpadding="0" class="premium_summary_table">
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal" class="font-grey">Premium Summary</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Vehicle Claim History</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">40,000.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Annual Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,181.97</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Basic Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,181.97</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Loading (0%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">102.58</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">No Claim Discount (25.00%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">295.49</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Total Extra Cover Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">277.50</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Gross Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,459.47</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Rebate (0%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">0.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Service Tax (8%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">70.92</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Stamp Duty</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">10.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Commission (10%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">118.19</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell class="nx-font-weight-bold">
                    <span nxCopytext="normal" class="nx-font-weight-bold">Total Amount</span>
                  </td>
                  <td nxTableCell class="column_cp_content_bold std-ta-right">
                    <span nxCopytext="normal" class="nx-font-weight-bold">1,540.39</span>
                  </td>
                </tr>
                <div class="nx-margin-y-m"></div>

                <tr nxTableRow>
                  <td nxTableCell>
                    <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
                      Total Amount (Rounded)
                    </h4>
                  </td>
                  <td nxTableCell class="column_cp_content_bold std-ta-right">
                    <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
                      1,540.40
                    </h4>
                  </td>
                </tr>
              </table>

              <hr class="nx-margin-y-m" />

              <table nxTable cellpadding="0" class="premium_summary_table">
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Commission (10%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">113.20</span>
                  </td>
                </tr>
              </table>
            </nx-card>
          </nx-expansion-panel>
        </nx-accordion>
      </div>
    </div>
  </div>
  <div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <button nxButton="secondary" type="button">Back</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #templateTwo>
  <div nxLayout="grid maxwidth" class="nx-margin-bottom-3xl">
    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Product Type">
          <nx-dropdown>
            <nx-dropdown-item value="PRIVATE CARS EXCLUDING GOODS"></nx-dropdown-item>
            <nx-dropdown-item value="PRIVATE CARS INCLUDING GOODS"></nx-dropdown-item>
            <nx-dropdown-item value="PRIVATE CARS COMPANY REGISTERED"></nx-dropdown-item>
            <nx-dropdown-item value="GOODS VEHICLE SCHEDULE 1"></nx-dropdown-item>
            <nx-dropdown-item value="GOODS VEHICLE SCHEDULE 2"></nx-dropdown-item>
            <nx-dropdown-item value="MOTOR TRADE PLATE"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Use of Vehicle">
          <nx-dropdown>
            <nx-dropdown-item value="PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY USE)"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Vehicle No.">
          <input nxInput />
          <nx-error nxFormfieldError>
            <strong>Please note:</strong> this field is required!
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Chassis No. (Optional)">
          <input nxInput />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6">
        <nx-formfield label="ID Type">
          <nx-dropdown>
            <nx-dropdown-item value="NRIC"></nx-dropdown-item>
            <nx-dropdown-item value="OLD IC / POLICE ID / ARMY ID / PASSPORT NO."></nx-dropdown-item>
            <nx-dropdown-item value="BUSINESS REGISTRATION NO."></nx-dropdown-item>
            <nx-dropdown-item value="NEW BUSINESS REGISTRATION NO"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield label="ID No.">
          <input nxInput />
          <nx-error nxFormfieldNote>
            <strong>Wrong Format</strong>
          </nx-error>
          <nx-error nxFormfieldNote>
            <strong>Invalid Business Registration No.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-s std-ta-right">
        <button nxButton="secondary" type="button" class="nx-margin-right-s">
          RESET
        </button>
        <button nxButton="primary" type="button" class="m_margin_top_20">
          SEARCH
        </button>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12" class="nx-margin-y-2m">
        <hr />
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <table nxTable cellpadding="0" class="summary_table">
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">ISM Response Code</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">ENQ089</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">ISM Reference No.</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">13240131181240020192</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">Previous Vehicle No.</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">WA8012L</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">Current No Claim Discount (%)</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">0.00</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">Current No Claim Discount Effective Date</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">26/07/2018</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">Current No Claim Discount Expiry Date</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">25/07/2019</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">Next No Claim Discount (%)</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">0.00</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">Next No Claim Discount Effective Date</span>
            </td>
            <td nxTableCell class="column_cp_content">
              <span nxCopytext="normal">26/07/2023</span>
            </td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>
              <span nxCopytext="normal">No Result Found</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateThree>
  <div nxLayout="grid maxwidth" class="nx-margin-bottom-3xl">
    <form [formGroup]="issuanceForm">
      <div nxRow>
        <div nxCol="12">
          <nx-formfield label="Request Type">
            <nx-dropdown formControlName="requestType">
              <nx-dropdown-item value="ALL"></nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
      </div>
    </form>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Previous Vehicle No.">
          <input nxInput />
          <nx-error nxFormfieldError>
            <strong>Please note:</strong> this field is required!
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Current Vehicle No.">
          <input nxInput />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="e-Cover Note No.">
          <input nxInput />
          <nx-error nxFormfieldError>
            <strong>Please note:</strong> this field is required!
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Request Date From">
          <input nxDatefield nxInput [datepicker]="myDatepicker" />
          <span nxFormfieldHint>MM/DD/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker></nx-datepicker>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Request Date To">
          <input nxDatefield nxInput [datepicker]="myDatepicker2" />
          <span nxFormfieldHint>MM/DD/YYYY</span>

          <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
          <nx-datepicker #myDatepicker2></nx-datepicker>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-s std-ta-right">
        <button nxButton="secondary" type="button" class="nx-margin-right-s">
          RESET
        </button>
        <button (click)="onSearchClicked()" nxButton="primary" type="button" class="m_margin_top_20">
          SEARCH
        </button>
      </div>
    </div>

    <div nxRow class="nx-margin-top-2m">
      <div nxCol="12">
        <div class="scroll-container">
          <div>
            <app-external-scrollbar targetSyncScroll="3" [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3 syncScroll="3">
              <table nxTable zebra nxSort>
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell nxSortHeaderCell="product">
                      Request Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Response Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Response Type
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      e-Cover Note No.
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Previous <br />
                      Vehicle No.
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="endingAt">
                      Current <br />Vehicle No.
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="statusText" class="std-ta-right std-va-top">
                      Current No Claim <br />
                      Discount (%)
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="statusText" class="std-ta-right">
                      Next No Claim <br />Discount (%)
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="statusText">
                      Approval Code
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="statusText">
                      ISM Response
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr nxTableRow *ngFor="let item of searchModelResult; let i = index"
                    [selectable]="true">
                    <td nxTableCell>{{ item.piamMakeCodeDesc }}</td>
                    <td nxTableCell>{{ item.piamModelCodeDesc }}</td>
                    <td nxTableCell>{{ item.variant }}</td>
                    <td nxTableCell>{{ item.yearMnftr }}</td>
                    <td nxTableCell>{{ item.engineType }}</td>
                    <td nxTableCell>{{ item.capacity }}</td>
                    <td nxTableCell>{{ item.seat }}</td>
                    <td nxTableCell>{{ item.avEmSiMin }} - {{ item.avEmSiMax }}</td>
                  </tr> -->
                  <tr nxTableRow>
                    <td nxTableCell>01/02/2024 9:03:01 AM</td>
                    <td nxTableCell>01/02/2024 9:03:01 AM</td>
                    <td nxTableCell>ENQ</td>
                    <td nxTableCell>ABKK0000060</td>
                    <td nxTableCell>WWW123</td>
                    <td nxTableCell>WTT3227</td>
                    <td nxTableCell class="std-ta-right">0.00</td>
                    <td nxTableCell class="std-ta-right">0.00</td>
                    <td nxTableCell></td>
                    <td nxTableCell>ENQ099 Error in vehicle class</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #templateApproval>
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-m">
        <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
          <div nxRow class="enquiry_status">
            <div nxCol="12,12,10">
              <h3 nxHeadline size="s" class="nx-font-weight-regular">
                e-Policy No. :
                <span class="nx-font-weight-bold">23VAS0001685-00</span>
              </h3>
            </div>
            <div nxCol="12,12,2" class="enquiry_status">
              <nx-badge type="active" class="pending_badge">Pending <br />
                Approval
              </nx-badge>
            </div>
          </div>
        </div>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Agent Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Code</nx-data-display-label>
                    <p nxCopytext="large">AS00584</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Name</nx-data-display-label>
                    <p nxCopytext="large">KALA ENTERPRISE</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Issued By</nx-data-display-label>
                    <p nxCopytext="large">HESTER_WONG</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Issued Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Score</nx-data-display-label>
                    <p nxCopytext="large">0.00</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Issuance Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Product Name</nx-data-display-label>
                    <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                    <p nxCopytext="large">WA8012L</p>
                  </nx-data-display>
                </div>

                <!-- Cover Note Issuance Information (to be move to new component) -->
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Type</nx-data-display-label>
                    <p nxCopytext="large">Amendment of Period</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Policy No.</nx-data-display-label>
                    <p nxCopytext="large">19VKL0012663-0</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Policy Effective Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Policy Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/2024</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Effective Date</nx-data-display-label>
                    <p nxCopytext="large">01/02/2024</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/202</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Previous Policy - Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Method</nx-data-display-label>
                    <p nxCopytext="large">PRORATA</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel>
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Customer Partner
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Customer Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">INDIVIDUAL</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Name</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WONG KAM WAI</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Nationality</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">880914145277</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Gender</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Marital Status</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">SINGLE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">NAge</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">35</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Coverage
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <h4 class="nx-margin-bottom-2m">Vehicle Details</h4>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Where is the vehicle being used?</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WEST MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Use of Vehicle</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY USE)</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WA8012L</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Year of Manufacture</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">2007</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Make / Model</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">31*0000012 - PERODUA MYVI 1.3 (A) SE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Capacity / Unit of Measurement</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">1,298 / CC</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Engine Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">INTERNAL COMBUSTION ENGINE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No. of Seats</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">5</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Engine / Motor No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">2NROP28330</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Chassis No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PN153SYF103098483</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Loan Type</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">HIRE PURCHASE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Loan Company</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MAYBANK</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Purchase Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/07/2007</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Make / Model to be printed in Quotation / Cover Note</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PERODUA MYVI 1.3 (A) SE 2205</span>
                </td>
              </tr>
            </table>

            <hr class="nx-margin-y-2m" />

            <h4 class="nx-margin-bottom-2m">Coverage Details</h4>

            <table nxTable cellpadding="0" class="summary_table nx-margin-bottom-2m">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Coverage Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <p nxCopytext="normal">
                    COMPREHENSIVE &nbsp;<span nxCopytext="small"><i>(Recommended Sum Insured : 30,000 -
                        40,000)</i></span>
                  </p>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Sum Insured (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">40,000.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Agreed Value</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Recommended Value</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Risk Excess (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">4,000.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Voluntary Excess (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">0.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount (%)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">25.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount From</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount Vehicle No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WA8012L</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount Effective Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">12/12/2023</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">3A Dealer</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
            </table>

            <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl nx-margin-bottom-2m" />

            <nx-card class="card_summary premium_approval nx-margin-bottom-2m">
              <table nxTable cellpadding="0" class="coverage_premium_summary_table">
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal" class="font-grey">Premium Summary</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal" class="font-grey">Subtotal (RM)</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal" class="font-grey">Previous Subtotal (RM)</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Risk Excess</span>
                  </td>
                  <td nxTableCell class="column_form_content">
                    <nx-formfield class="form_row_inline">
                      <input nxInput value="1,250.00" />
                    </nx-formfield>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal">1,500.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Voluntary Excess</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">0.00</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Basic Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">2,668.07</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal">2,980.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                      <tr nxTableRow>
                        <td nxTableCell>
                          <span nxCopytext="normal">Agent Premium Adjustment</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <span nxCopytext="normal">0<span class="nx-margin-x-3xs">%</span></span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    0.00
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                      <tr nxTableRow>
                        <td nxTableCell>
                          <span nxCopytext="normal">Branch Premium Adjustment</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <span nxCopytext="normal">0<span class="nx-margin-x-3xs">%</span></span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">0.00</span>
                  </td>
                  <td nxTableCell class="td-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                      <tr nxTableRow>
                        <td nxTableCell>
                          <span nxCopytext="normal">HQ Premium Adjustment</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <nx-formfield class="form_row_inline">
                            <input nxInput value="0" />

                            <span nxCopytext="normal" nxFormfieldAppendix>%</span>
                          </nx-formfield>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">0.00</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                      <tr nxTableRow>
                        <td nxTableCell>
                          <span nxCopytext="normal">Total Premium Adjustment</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <span nxCopytext="normal">0<span class="nx-margin-x-3xs">%</span></span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">0.00</span>
                  </td>
                  <td nxTableCell class="td-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                      <tr nxTableRow>
                        <td nxTableCell>
                          <span nxCopytext="normal">Remaining Premium Adjustment</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <span nxCopytext="normal">10.53<span class="nx-margin-x-3xs">%</span></span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">280.85</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Final Basic Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">5,929.05</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <table nxTable cellpadding="0" class="premium_summary_table">
                      <tr nxTableRow>
                        <td nxTableCell>
                          <span nxCopytext="normal">Remaining Premium Adjustment</span>
                        </td>
                        <td nxTableCell class="std-ta-right">
                          <span nxCopytext="normal" class="nx-margin-right-s">1.21</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">280.85</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal"></span>
                  </td>
                </tr>
              </table>

              <!-- <div nxLayout="grid nogutters" class="grid_remove_padding">
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary font-grey">
                    <span nxCopytext="normal">Premium Summary</span>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs"></div>
                  <div nxCol="3,3,3,3,2" class="premium_summary font-grey std-ta-right">
                    <span nxCopytext="normal">Subtotal (RM)</span>
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary font-grey std-ta-right">
                    <span nxCopytext="normal">Previous Subtotal (RM)</span>
                  </div>
                </div>
                <div nxRow>
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Risk Excess</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs"></div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content horizontal-checkboxes">
                    <!-- <span class="nx-margin-right-2xs">:</span> -->
              <!-- <nx-formfield class="formfield_no_padding">
                      <input nxInput value="1,250.00" />
                    </nx-formfield>
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right">
                    <span nxCopytext="normal">1,500.00</span>
                  </div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="4,4,3,3,3" class="premium_summary">
                    <div>Voluntary Excess</div>
                  </div>
                  <div nxCol="2,2,2,2,1" class="std-ta-right nx-margin-right-2xs"></div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    0.00
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Basic Premium</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs"></div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    2,668.07
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right">
                    2,980.00
                  </div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Agent Premium Adjustment</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs">
                    <span class="std-ta-right" nxCopytext="normal">0<span class="nx-margin-x-3xs">%</span></span>
                  </div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    0.00
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Branch Premium Adjustment</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs">
                    <span nxCopytext="normal">0<span class="nx-margin-x-3xs">%</span></span>
                  </div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    0.00
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>HQ Premium Adjustment</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs horizontal-checkboxes">
                    <nx-formfield class="formfield_no_padding">
                      <input nxInput value="0" />
                    </nx-formfield>
                    <span class="nx-margin-x-3xs">%</span>
                  </div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    0.00
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Total Premium Adjustment</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs">
                    <span nxCopytext="normal">0<span class="nx-margin-x-3xs">%</span></span>
                  </div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    0.00
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Remaining Premium Adjustment</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs">
                    <span nxCopytext="normal">10.53<span class="nx-margin-right-3xs"> %</span></span>
                  </div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    280.85
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Final Basic Premium</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs"></div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    5,929.05
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
                <div nxRow class="nx-margin-bottom-3xs">
                  <div nxCol="3,3,3,3,3" class="premium_summary">
                    <div>Actual Premium / Technical Premium</div>
                  </div>
                  <div nxCol="3,3,2,2,1" class="std-ta-right nx-margin-right-2xs">
                    <span nxCopytext="normal">1.21<span class="nx-margin-right-s"> </span
                    ></span>
                  </div>
                  <div nxCol="3,3,3,3,2" class="premium_summary std-ta-right column_cp_content">
                    280.85
                  </div>
                  <div nxCol="2,2,3,3,3" class="premium_summary std-ta-right"></div>
                </div>
              </div> -->
            </nx-card>

            <h1 nxHeadline size="s" class="nx-font-weight-semibold nx-margin-bottom-2m">
              Extra Cover
            </h1>

            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,12,12,6">
                  <div>
                    <app-external-scrollbar targetSyncScroll="4"
                      [targetBaseWidth]="tableHolder4"></app-external-scrollbar>
                    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder4
                      syncScroll="4">
                      <table nxTable zebra cellpadding="0" class="nx-margin-bottom-2m">
                        <thead>
                          <tr nxTableRow class="table_row_flex">
                            <th nxHeaderCell class="table_extra_coverage">
                              <span nxCopytext="medium" class="nx-font-weight-semibold">Current Extra Cover</span>
                            </th>
                            <th nxHeaderCell class="table_extra_coverage"></th>
                            <th nxHeaderCell class="table_extra_coverage">
                              <span nxCopytext="medium" class="nx-font-weight-semibold">Premium (RM)</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                                DRIVER</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">20.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">COVER FOR WINDSCREENS, WINDOWS AND
                                SUNROOF</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">60.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">INCLUSION OF SPECIAL PERILS</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">80.0</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">MOTOR ENHANCED ROAD WARRIOR</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                                10,000.00</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">75.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">75.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">WAIVER OF BETTERMENT CONTRIBUTION</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">230.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">KEY CARE</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">SUM INSURED (RM) : 1,000.00</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">7.00</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- <nx-swipebar label="Swipe to view more">
                    <table
                      nxTable
                      zebra
                      cellpadding="0"
                      class="nx-margin-bottom-2m"
                    >
                      <thead>
                        <tr nxTableRow class="table_row_flex">
                          <th nxHeaderCell class="table_extra_coverage">
                            <span
                              nxCopytext="medium"
                              class="nx-font-weight-semibold"
                              >Current Extra Cover</span
                            >
                          </th>
                          <th nxHeaderCell class="table_extra_coverage"></th>
                          <th nxHeaderCell class="table_extra_coverage">
                            <span
                              nxCopytext="medium"
                              class="nx-font-weight-semibold"
                              >Premium (RM)</span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                              DRIVER</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">20.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >SUM INSURED (RM) : 500.00</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">60.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >INCLUSION OF SPECIAL PERILS</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">80.0</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >MOTOR ENHANCED ROAD WARRIOR</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                              10,000.00</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">75.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >OPTIONAL CAR REPLACEMENT</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">75.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >WAIVER OF BETTERMENT CONTRIBUTION</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">230.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">KEY CARE</span>
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >SUM INSURED (RM) : 1,000.00</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">7.00</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </nx-swipebar> -->
                </div>
                <div nxCol="12,12,12,12,6">
                  <div>
                    <app-external-scrollbar targetSyncScroll="5"
                      [targetBaseWidth]="tableHolder5"></app-external-scrollbar>
                    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder5
                      syncScroll="5">
                      <table nxTable zebra cellpadding="0" class="nx-margin-bottom-2m">
                        <thead>
                          <tr nxTableRow class="table_row_flex">
                            <th nxHeaderCell class="table_extra_coverage">
                              <span nxCopytext="medium" class="nx-font-weight-semibold">Previous Extra Cover</span>
                            </th>
                            <th nxHeaderCell class="table_extra_coverage"></th>
                            <th nxHeaderCell class="table_extra_coverage">
                              <span nxCopytext="medium" class="nx-font-weight-semibold">Premium (RM)</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                                DRIVER</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">20.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">COVER FOR WINDSCREENS, WINDOWS AND
                                SUNROOF</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">60.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">INCLUSION OF SPECIAL PERILS</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">80.0</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">MOTOR ENHANCED ROAD WARRIOR</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                                10,000.00</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">75.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">75.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">WAIVER OF BETTERMENT CONTRIBUTION</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage"></td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">230.00</span>
                            </td>
                          </tr>
                          <tr nxTableRow class="table_row_flex">
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">KEY CARE</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">SUM INSURED (RM) : 1,000.00</span>
                            </td>
                            <td nxTableCell class="table_extra_coverage">
                              <span nxCopytext="medium">7.00</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- <nx-swipebar label="Swipe to view more">
                    <table
                      nxTable
                      zebra
                      cellpadding="0"
                      class="nx-margin-bottom-2m"
                    >
                      <thead>
                        <tr nxTableRow class="table_row_flex">
                          <th nxHeaderCell class="table_extra_coverage">
                            <span
                              nxCopytext="medium"
                              class="nx-font-weight-semibold"
                              >Previous Extra Cover</span
                            >
                          </th>
                          <th nxHeaderCell class="table_extra_coverage"></th>
                          <th nxHeaderCell class="table_extra_coverage">
                            <span
                              nxCopytext="medium"
                              class="nx-font-weight-semibold"
                              >Premium (RM)</span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >WAIVER OF COMPULSORY EXCESS IF NOT NAMED
                              DRIVER</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">20.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >SUM INSURED (RM) : 500.00</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">60.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >INCLUSION OF SPECIAL PERILS</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">80.0</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >MOTOR ENHANCED ROAD WARRIOR</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                              10,000.00</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">75.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >OPTIONAL CAR REPLACEMENT</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">75.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >WAIVER OF BETTERMENT CONTRIBUTION</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage"></td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">230.00</span>
                          </td>
                        </tr>
                        <tr nxTableRow class="table_row_flex">
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">KEY CARE</span>
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium"
                              >SUM INSURED (RM) : 1,000.00</span
                            >
                          </td>
                          <td nxTableCell class="table_extra_coverage">
                            <span nxCopytext="medium">7.00</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </nx-swipebar> -->
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Premium Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <nx-card class="card_summary">
              <table nxTable cellpadding="0" class="premium_summary_table">
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal" class="font-grey">Premium Summary</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Vehicle Claim History</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">40,000.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Annual Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,181.97</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Basic Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,181.97</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Loading (0%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">102.58</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">No Claim Discount (25.00%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">295.49</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Total Extra Cover Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">277.50</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Gross Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,459.47</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Rebate (0%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">0.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Service Tax (8%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">70.92</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Stamp Duty</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">10.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Commission (10%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">118.19</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell class="nx-font-weight-bold">
                    <span nxCopytext="normal" class="nx-font-weight-bold">Total Amount</span>
                  </td>
                  <td nxTableCell class="column_cp_content_bold std-ta-right">
                    <span nxCopytext="normal" class="nx-font-weight-bold">1,540.39</span>
                  </td>
                </tr>
                <div class="nx-margin-y-m"></div>

                <tr nxTableRow>
                  <td nxTableCell>
                    <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
                      Total Amount (Rounded)
                    </h4>
                  </td>
                  <td nxTableCell class="column_cp_content_bold std-ta-right">
                    <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
                      1,540.40
                    </h4>
                  </td>
                </tr>
              </table>

              <hr class="nx-margin-y-m" />

              <table nxTable cellpadding="0" class="premium_summary_table">
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Commission (10%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">113.20</span>
                  </td>
                </tr>
              </table>
            </nx-card>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Claim Details
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div>
              <app-external-scrollbar targetSyncScroll="6" [targetBaseWidth]="tableHolder6"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder6 syncScroll="6">
                <table nxTable nxSort class="enquiry">
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell nxSortHeaderCell="product">Claim No.</th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Claim Type
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Claim Loss Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Claim Code
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow>
                      <td nxTableCell>A0000</td>
                      <td nxTableCell>
                        COVER FOR WINDSCREENS, WINDOWS AND SUNROOF
                      </td>
                      <td nxTableCell>12/04/2024</td>
                      <td nxTableCell>CLAIM 01</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <nx-swipebar label="Swipe to view more">
              <table nxTable nxSort class="enquiry">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell nxSortHeaderCell="product">Claim No.</th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Claim Type
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Claim Loss Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Claim Code
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>A0000</td>
                    <td nxTableCell>
                      COVER FOR WINDSCREENS, WINDOWS AND SUNROOF
                    </td>
                    <td nxTableCell>12/04/2024</td>
                    <td nxTableCell>CLAIM 01</td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->
          </nx-expansion-panel>
        </nx-accordion>
        <!-- Endorsement -->
        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Endorsement History
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div>
              <app-external-scrollbar targetSyncScroll="7" [targetBaseWidth]="tableHolder7"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder7 syncScroll="7">
                <table nxTable nxSort class="enquiry">
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell nxSortHeaderCell="product">
                        Endorsement Ref. No.
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Issued Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Endorsement
                        <Br />Effective Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Endorsement Type
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Referral Reasons
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Status
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Issued By
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Approve / Decline By
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Approve / Decline Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <button nxPlainButton type="button" (click)="enRefNo()">
                          PGE00069327
                        </button>
                      </td>
                      <td nxTableCell>05/03/2024</td>
                      <td nxTableCell>05/03/2024</td>
                      <td nxTableCell>REVISION OF SUM INSURED</td>
                      <td nxTableCell>ENDT-30 REVISE SUM INSURED</td>
                      <td nxTableCell>
                        <nx-badge type="active" class="pending_badge">Pending <br />
                          Approval
                        </nx-badge>
                      </td>
                      <td nxTableCell>ALPHA.UAT.55</td>
                      <td nxTableCell></td>
                      <td nxTableCell></td>
                      <td nxTableCell></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <nx-swipebar label="Swipe to view more">
              <table nxTable nxSort class="enquiry">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell nxSortHeaderCell="product">
                      Endorsement Ref. No.
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Issued Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Endorsement <Br />Effective Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Endorsement Type
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Referral Reasons
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Status
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Issued By
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Approve / Decline By
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Approve / Decline Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <button nxPlainButton type="button" (click)="enRefNo()">
                        PGE00069327
                      </button>
                    </td>
                    <td nxTableCell>05/03/2024</td>
                    <td nxTableCell>05/03/2024</td>
                    <td nxTableCell>REVISION OF SUM INSURED</td>
                    <td nxTableCell>ENDT-30 REVISE SUM INSURED</td>
                    <td nxTableCell>
                      <nx-badge type="active" class="pending_badge"
                        >Pending <br />
                        Approval</nx-badge
                      >
                    </td>
                    <td nxTableCell>ALPHA.UAT.55</td>
                    <td nxTableCell></td>
                    <td nxTableCell></td>
                    <td nxTableCell></td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                e-Cover Note History
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div>
              <app-external-scrollbar targetSyncScroll="8" [targetBaseWidth]="tableHolder8"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder8 syncScroll="8">
                <table nxTable nxSort class="enquiry">
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell nxSortHeaderCell="product">
                        e-Cover Note No.
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Issued Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Agent Code / <br />Agent Name
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Customer Name
                      </th>
                      <th class="std-ta-right" nxHeaderCell nxSortHeaderCell="contractNumber">
                        Sum Insured (RM)
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Approve / <br />Decline By
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Approve / <br />Decline Date
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Action
                      </th>
                      <th nxHeaderCell nxSortHeaderCell="contractNumber">
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow>
                      <td nxTableCell>AEKL1746502</td>
                      <td nxTableCell>28/02/2024</td>
                      <td nxTableCell>KL23327 / KALA ENTERPRISE</td>
                      <td nxTableCell>KEW CHONG SEONG</td>
                      <td nxTableCell>130,000.00</td>
                      <td nxTableCell>CHEK.RAZIDA</td>
                      <td nxTableCell>26/02/2024</td>
                      <td nxTableCell>DECLINE</td>
                      <td nxTableCell>
                        ADVISE AGENT TO REISSUE & EXCESS TO MAINTAIN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <nx-swipebar label="Swipe to view more">
              <table nxTable nxSort class="enquiry">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell nxSortHeaderCell="product">
                      e-Cover Note No.
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Issued Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Agent Code / <br />Agent Name
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Customer Name
                    </th>
                    <th
                      class="std-ta-right"
                      nxHeaderCell
                      nxSortHeaderCell="contractNumber"
                    >
                      Sum Insured (RM)
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Approve / <br />Decline By
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Approve / <br />Decline Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Action
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="contractNumber">
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>AEKL1746502</td>
                    <td nxTableCell>28/02/2024</td>
                    <td nxTableCell>KL23327 / KALA ENTERPRISE</td>
                    <td nxTableCell>KEW CHONG SEONG</td>
                    <td nxTableCell>130,000.00</td>
                    <td nxTableCell>CHEK.RAZIDA</td>
                    <td nxTableCell>26/02/2024</td>
                    <td nxTableCell>DECLINE</td>
                    <td nxTableCell>
                      ADVISE AGENT TO REISSUE & EXCESS TO MAINTAIN
                    </td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Checklist
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12" class="horizontal-checkboxes">
                  <div class="nx-margin-right-2m">1</div>
                  <div>
                    Insured or Agent Authorised letter / form / email (with
                    contact number) <br />
                    <div class="container_flex_center nx-margin-top-s">
                      <nx-radio class="nx-margin-right-2m"> Yes </nx-radio>
                      <nx-radio labelSize="small"> No </nx-radio>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow class="nx-margin-top-m">
                <div nxCol="12" class="horizontal-checkboxes">
                  <div class="nx-margin-right-2m">2</div>
                  <div>
                    Vehicle inspection done <br />
                    <div class="container_flex_center nx-margin-top-s">
                      <nx-radio class="nx-margin-right-2m"> Yes </nx-radio>
                      <nx-radio> No </nx-radio>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow class="nx-margin-top-m">
                <div nxCol="12" class="horizontal-checkboxes">
                  <div class="nx-margin-right-2m">3</div>
                  <div>
                    Requested by Bank / Finance owner <br />
                    <div class="container_flex_center nx-margin-top-s">
                      <nx-radio class="nx-margin-right-2m"> Yes </nx-radio>
                      <nx-radio> No </nx-radio>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow class="nx-margin-top-m">
                <div nxCol="12">
                  <p nxCopytext="medium" class="nx-margin-bottom-2m">
                    <i>Note: Warranted no claim as at todate</i>
                  </p>
                  <p nxCopytext="normal" class="nx-margin-top-m">
                    Remarks: N/A
                  </p>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Endorsement Narration
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <b><u>AMENDMENT ON PERIOD OF INSURANCE</u> </b><br /><br />
            IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
            <b>01/02/2024</b> THE PERIOD OF INSURANCE UNDER THE POLICY IS
            AMENDED TO READ AS FROM <b>01/02/2024</b> TO <b>31/01/2025</b> AND
            NOT AS OTHERWISE STATED. <br /><br />
            ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Remarks
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="nx-font-weight-bold">Referral Remarks:</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="nx-font-weight-bold">Admin Remarks:</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="nx-font-weight-bold">Approval Remarks:</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12">
                  <nx-data-display size="small">
                    <nx-data-display-label class="nx-font-weight-bold">Updated by: hester_wong at 20/12/2023
                    </nx-data-display-label>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <div class="horizontal-checkboxes nx-margin-top-m">
          <nx-checkbox class="nx-margin-right-m"> JPJ Updated </nx-checkbox>
          <nx-checkbox> ISM Updated </nx-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <div class="button-container">
            <button nxButton="secondary" type="button">Back</button>
            <button nxButton="secondary" type="button" (click)="addRemarks()">
              ADD REMARKS
            </button>
            <button nxButton="primary" type="button">
              <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>Endorsement Schedule PDF
            </button>
            <button nxButton="primary" type="button" (click)="approve()">
              APPROVE E-COVER NOTE
            </button>
            <button nxButton="primary" type="button" (click)="reject()">
              REJECT E-COVER NOTE
            </button>
            <button nxButton="primary" type="button">UPLOAD DOCUMENT</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #templateSummary>
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-m">
        <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
          <div nxRow class="enquiry_status">
            <div nxCol="12,12,10">
              <h3 nxHeadline size="s" class="nx-font-weight-regular">
                e-Cover Note No. :
                <span class="nx-font-weight-bold">AEAS0003212</span>
              </h3>
            </div>
            <div nxCol="12,12,2" class="enquiry_status">
              <nx-badge type="active">Printed</nx-badge>
            </div>
          </div>
        </div>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Agent Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Code</nx-data-display-label>
                    <p nxCopytext="large">BT15982</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Name</nx-data-display-label>
                    <p nxCopytext="large">GOLDEN HIGHWAY AUTO-CITY SDN BHD</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Issued By</nx-data-display-label>
                    <p nxCopytext="large">ALPHA.UAT.25</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Issued Date</nx-data-display-label>
                    <p nxCopytext="large">14/05/2024</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Issuance Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div nxLayout="grid nopadding">
              <!-- RoadTax Issuance Information (to be move to new component) -->
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Product Name</nx-data-display-label>
                    <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                    <p nxCopytext="large">WA8012L</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Cover Note Type</nx-data-display-label>
                    <p nxCopytext="large">ROAD TAX PURPOSE</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Policy No.</nx-data-display-label>
                    <p nxCopytext="large">21PKL5001832-00</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Effective Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/2024</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Cover Note No.</nx-data-display-label>
                    <p nxCopytext="large">AEAS0003212</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">JPJ Status</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">JPJ Message</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
              </div>

              <!-- Cover Note Issuance Information (to be move to new component) -->
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Product Name</nx-data-display-label>
                    <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                    <p nxCopytext="large">NA</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Cover Note Type</nx-data-display-label>
                    <p nxCopytext="large">
                      NEW BUSINESS - NEW VEHICLE, NEW REGISTRATION
                    </p>
                  </nx-data-display>
                </div>

                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Previous Policy No.</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Effective Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/2024</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Quotation No.</nx-data-display-label>
                    <p nxCopytext="large">QAS23070000384</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Quotation Creation Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Cover Note No.</nx-data-display-label>
                    <p nxCopytext="large">AEAS0003212</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Document Consent</nx-data-display-label>
                    <p nxCopytext="large">YES</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Invoice Consent</nx-data-display-label>
                    <p nxCopytext="large">YES</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">JPJ Status</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">JPJ Message</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
              </div>

              <!-- Endorsement Issuance Information (to be move to new component) -->
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Product Name</nx-data-display-label>
                    <p nxCopytext="large">PRIVATE CARS EXCLUDING GOODS</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                    <p nxCopytext="large">WA8012L</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Type</nx-data-display-label>
                    <p nxCopytext="large">CHANGE OF VEHICLE</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Policy No.</nx-data-display-label>
                    <p nxCopytext="large">19VKL0012663-00</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Policy Effective Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Policy Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/2024</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Effective Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Expiry Date</nx-data-display-label>
                    <p nxCopytext="large">12/12/2024</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement Ref. No.</nx-data-display-label>
                    <p nxCopytext="large">UWMT240016790</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Endorsement No.</nx-data-display-label>
                    <p nxCopytext="large">19VKL0012663-00*002</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">JPJ Status</nx-data-display-label>
                    <p nxCopytext="large">OK</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">JPJ Message</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Invoice Consent</nx-data-display-label>
                    <p nxCopytext="large">YES</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel>
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Customer Partner
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <!-- Individual -->
            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Customer Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">INDIVIDUAL</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Name</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WONG KAM WAI</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Nationality</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">880914145277</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Old IC No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">A3078</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Policy ID No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">B3030</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Passport No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">A37</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Tax Identification No. (TIN)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">OG12345678101</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Gender</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Marital Status</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">SINGLE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Date of Birth</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">14/09/1988</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Age</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">35</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">ALIM Customer</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">NO</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Mobile No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">6012-2222222</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Fixed Line No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-33333333</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Fax No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-44444444</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Email</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">KAMWAIGMAIL.COM</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Address</span></td>
                <td nxTableCell class="column_cp_content">
                  <button nxPlainButton type="button" aria-label="add item">
                    <nx-icon name="location" aria-hidden="true"></nx-icon>
                  </button>
                  <span nxCopytext="normal">28-3, ALLIANZ MALAYSIA BERHAD, LEVEL 28, MENARA ALLIANZ
                    SENTRAL, 203, JALAN TUN SAMBANTHAN, KUALA LUMPUR SENTRAL,
                    50470 KUALA LUMPUR, WILAYAH PERSEKUTUAN, MALAYSIA</span>
                </td>
              </tr>
            </table>

            <!-- This is just spacing, you can remove it -->
            <div class="nx-margin-y-2m"></div>

            <!-- Company -->
            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Customer Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">COMPANY</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Name</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">ALPHA SDN. BHD.</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Country of Incorporation</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">New Business Registration No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">201333333322</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Business Registration No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">555666-X</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Tax Identification No. (TIN)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">C2584563202</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">SST No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">W88888888888888</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST Effective Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/07/2022</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST De-Registration Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">25/07/2024</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST Resident Status</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">RESIDENT</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SST Country</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Reg. No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">SME908766</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Category</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MICRO</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Cert. Start Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">27/12/2021</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">SME Cert. End Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/12/2023</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Mobile No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">6012-2264680</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Fixed Line No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-33333333</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Fax No.</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">602-44444444</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Email</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">ALPHAGMAIL.COM</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Address</span></td>
                <td nxTableCell class="column_cp_content">
                  <button nxPlainButton type="button" aria-label="add item">
                    <nx-icon name="location" aria-hidden="true"></nx-icon>
                  </button>
                  <span nxCopytext="normal">28-3, ALLIANZ, 203, JALAN TUN SAMBANTHAN, KUALA LUMPUR
                    SENTRAL, 50470 KUALA LUMPUR, WILAYAH PERSEKUTUAN,
                    MALAYSIA</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Referral Details
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Referral ID No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MOTOR-23023QWT</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordione_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Coverage
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <h4 class="nx-margin-bottom-2m">Vehicle Details</h4>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Where is the vehicle being used?</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WEST MALAYSIA</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Use of Vehicle</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY USE)</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WA8012L</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Year of Manufacture</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">2007</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Make / Model</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">31*0000012 - PERODUA MYVI 1.3 (A) SE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Capacity / Unit of Measurement</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">1,298 / CC</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Engine Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">INTERNAL COMBUSTION ENGINE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No. of Seats</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">5</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Engine / Motor No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">2NROP28330</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Chassis No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PN153SYF103098483</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Loan Type</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">HIRE PURCHASE</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Loan Company</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MAYBANK</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Purchase Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/07/2007</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Make / Model to be printed in Quotation / Cover Note</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">PERODUA MYVI 1.3 (A) SE 2205</span>
                </td>
              </tr>
            </table>

            <hr class="nx-margin-y-2m" />

            <h4 class="nx-margin-bottom-2m">Coverage Details</h4>

            <table nxTable cellpadding="0" class="summary_table nx-margin-bottom-2m">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Coverage Type</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <p nxCopytext="normal">
                    COMPREHENSIVE &nbsp;<span nxCopytext="small"><i>(Recommended Sum Insured : 30,000 -
                        40,000)</i></span>
                  </p>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Sum Insured (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">40,000.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Agreed Value</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Recommended Value</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Risk Excess (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">4,000.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Voluntary Excess (RM)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">0.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount (%)</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">25.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount From</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount Vehicle No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WA8012L</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">No Claim Discount Effective Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">12/12/2023</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">3A Dealer</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
            </table>

            <hr class="nx-margin-y-2m" />

            <h4 class="nx-margin-bottom-2m">Extra Coverage</h4>

            <span nxCopytext="normal" class="nx-font-weight-semibold">These coverage(s) / service(s) are provided at no
              extra
              cost.</span>

            <ul nxList="small" class="completed_list">
              <li>
                <span nxCopytext="normal" class="nx-font-weight-semibold">RAHMAH PACKAGE</span>
                <ul class="nx-margin-y-0">
                  <div nxLayout="grid nopadding" class="grid_remove_padding">
                    <div nxRow>
                      <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- COMPASSIONATE FLOOD COVER</span>
                      </div>
                      <div nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">SUM INSURED (RM) : 3,000.00</span>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- DEATH/PERMANENT DISABLEMENT
                        </span>
                      </div>
                      <div nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">SUM INSURED (RM) : 5,000.00</span>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- HOSPITAL INCOME</span>
                      </div>
                      <div nxCol="12,12,6,4" class="extra_coverage_text">
                        <span nxCopytext="medium">RM 50.00 PER DAY / MAX 60 DAYS</span>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li>
                <span nxCopytext="normal" class="nx-font-weight-semibold">24-HOUR EMERGENCY TOWING (UP TO 150KM)</span>
              </li>
              <li>
                <div nxLayout="grid nopadding" class="grid_remove_padding">
                  <div nxRow>
                    <div nxCol="12,12,6,5">
                      <span nxCopytext="normal" class="nx-font-weight-semibold">MOTOR ROAD WARRIOR</span>
                    </div>
                    <div nxCol="12,12,6,4" class="extra_coverage_text">
                      <span nxCopytext="medium">PLAN / UNIT : 1 UNIT<br />SUM INSURED (RM) :
                        3,000.00</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div class="nx-margin-top-2m">
              <app-external-scrollbar targetSyncScroll="9" [targetBaseWidth]="tableHolder9"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder9 syncScroll="9">
                <table nxTable zebra cellpadding="0">
                  <thead>
                    <tr nxTableRow class="table_row_flex">
                      <th nxHeaderCell class="table_extra_coverage_display">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Extra Cover</span>
                      </th>
                      <th nxHeaderCell class="table_extra_coverage_display"></th>
                      <th nxHeaderCell class="table_extra_coverage_display">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Premium (RM)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">20.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">60.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">INCLUSION OF SPECIAL PERILS</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">80.0</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">MOTOR ENHANCED ROAD WARRIOR</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                          10,000.00</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">75.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">75.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">WAIVER OF BETTERMENT CONTRIBUTION</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display"></td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">230.00</span>
                      </td>
                    </tr>
                    <tr nxTableRow class="table_row_flex">
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">KEY CARE</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">SUM INSURED (RM) : 1,000.00</span>
                      </td>
                      <td nxTableCell class="table_extra_coverage_display">
                        <span nxCopytext="medium">7.00</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-2m">
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow class="table_row_flex">
                    <th nxHeaderCell class="table_extra_coverage_display">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Extra Cover</span
                      >
                    </th>
                    <th nxHeaderCell class="table_extra_coverage_display"></th>
                    <th nxHeaderCell class="table_extra_coverage_display">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Premium (RM)</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >WAIVER OF COMPULSORY EXCESS IF NOT NAMED DRIVER</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">20.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >COVER FOR WINDSCREENS, WINDOWS AND SUNROOF</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">SUM INSURED (RM) : 500.00</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">60.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >INCLUSION OF SPECIAL PERILS</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">80.0</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >MOTOR ENHANCED ROAD WARRIOR</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >PLAN / UNIT : PLAN A<br />SUM INSURED (RM) :
                        10,000.00</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">75.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">OPTIONAL CAR REPLACEMENT</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">75.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >WAIVER OF BETTERMENT CONTRIBUTION</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display"></td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">230.00</span>
                    </td>
                  </tr>
                  <tr nxTableRow class="table_row_flex">
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">KEY CARE</span>
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium"
                        >SUM INSURED (RM) : 1,000.00</span
                      >
                    </td>
                    <td nxTableCell class="table_extra_coverage_display">
                      <span nxCopytext="medium">7.00</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->

            <hr class="nx-margin-y-2m" />

            <h4 class="nx-margin-bottom-2m">Named Drivers</h4>

            <div>
              <app-external-scrollbar targetSyncScroll="10" [targetBaseWidth]="tableHolder10"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder10 syncScroll="10">
                <table nxTable zebra cellpadding="0">
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Driver Name</span>
                      </th>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Driver ID</span>
                      </th>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Age</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="medium">THE POLICYHOLDER</span>
                      </td>
                      <td nxTableCell>
                        <span nxCopytext="medium">880914145277</span>
                      </td>
                      <td nxTableCell><span nxCopytext="medium">36</span></td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="medium">ALL RIDER / DRIVER</span>
                      </td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="medium">ANY AUTHORISED DRIVERS</span>
                      </td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                      <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-2m">
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Driver Name</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Driver ID</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Age</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">THE POLICYHOLDER</span>
                    </td>
                    <td nxTableCell>
                      <span nxCopytext="medium">880914145277</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">36</span></td>
                  </tr>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">ALL RIDER / DRIVER</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  </tr>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">ANY AUTHORISED DRIVERS</span>
                    </td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                    <td nxTableCell><span nxCopytext="medium">N/A</span></td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->
          </nx-expansion-panel>
        </nx-accordion>

        <!-- Renewal Assistance (to be move to new component) -->

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Renewal Assistance
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Renewal Assistance</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Payment Mode</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">MASTER</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Credit Card No.</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">479462******5566</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Credit Card Expiry Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">10/2027</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Name on Card</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WONG KAM WAI</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Renewal Assistance Start Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">26/07/2024</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Term of Use</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">YES</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Historical Claim
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Client Claim History</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">0</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Vehicle Claim History</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">0</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Premium Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <nx-card class="card_summary">
              <table nxTable cellpadding="0" class="premium_summary_table">
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal" class="font-grey">Premium Summary</span>
                  </td>
                  <td nxTableCell class="std-ta-right">
                    <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Vehicle Claim History</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">40,000.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Annual Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,181.97</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Basic Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,181.97</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Loading (0%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">102.58</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">No Claim Discount (25.00%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">295.49</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Total Extra Cover Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">277.50</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Gross Premium</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">1,459.47</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Rebate (0%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">0.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Service Tax (8%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">70.92</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Stamp Duty</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">10.00</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Commission (10%)</span>
                  </td>
                  <td nxTableCell class="column_cp_content std-ta-right">
                    <span nxCopytext="normal">118.19</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell class="nx-font-weight-bold">
                    <span nxCopytext="normal" class="nx-font-weight-bold">Total Amount</span>
                  </td>
                  <td nxTableCell class="column_cp_content_bold std-ta-right">
                    <span nxCopytext="normal" class="nx-font-weight-bold">1,540.39</span>
                  </td>
                </tr>
                <div class="nx-margin-y-m"></div>

                <tr nxTableRow>
                  <td nxTableCell>
                    <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
                      Total Amount (Rounded)
                    </h4>
                  </td>
                  <td nxTableCell class="column_cp_content_bold std-ta-right">
                    <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-bold">
                      1,540.40
                    </h4>
                  </td>
                </tr>
              </table>
            </nx-card>
          </nx-expansion-panel>
        </nx-accordion>

        <!-- OCC Section (to be move to new component) -->

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Payment
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Receipt No.</nx-data-display-label>
                    <p nxCopytext="large">OR22AS000002</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Transaction Date</nx-data-display-label>
                    <p nxCopytext="large">13/12/2023</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Cover Note No.</nx-data-display-label>
                    <p nxCopytext="large">AEAS0003212</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                    <p nxCopytext="large">N/A</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Account Code</nx-data-display-label>
                    <p nxCopytext="large">KL12272</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Insured Name</nx-data-display-label>
                    <p nxCopytext="large">WONG KAM WAI</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Total Premium Due (RM)</nx-data-display-label>
                    <p nxCopytext="large">1,540.40</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Road Tax Payable
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <table nxTable cellpadding="0" class="payment_summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal" class="font-grey">Road Tax Summary</span>
                </td>
                <td nxTableCell class="std-ta-right">
                  <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Road Tax</span></td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">300.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Runner Fees</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">0.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">MyEG Fees</span></td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">11.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Courier Fees</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">0.40</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Merchant Charges</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">0.00</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Road Tax Payable</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">311.00</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Upsell Product
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <table nxTable cellpadding="0" class="payment_summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal" class="font-grey">Upsell Product Summary</span>
                </td>
                <td nxTableCell class="std-ta-right">
                  <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Road Warrior</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">146.10</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Payment Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <table nxTable cellpadding="0" class="payment_summary_table">
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal" class="font-grey">Payment Summary</span>
                </td>
                <td nxTableCell class="std-ta-right">
                  <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Total Amount Payable</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">1,851.40</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Total Amount Payable (Rounded)</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">1,851.40</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Total Amount Paid by Customer</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">1,851.40</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Balance Payable</span>
                </td>
                <td nxTableCell class="column_cp_content std-ta-right">
                  <span nxCopytext="normal">0.00</span>
                </td>
              </tr>
            </table>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Payment Details
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div class="nx-margin-top-m">
              <app-external-scrollbar targetSyncScroll="11" [targetBaseWidth]="tableHolder11"></app-external-scrollbar>
              <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder11 syncScroll="11">
                <table nxTable zebra cellpadding="0">
                  <thead>
                    <tr nxTableRow>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Payment Mode</span>
                      </th>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Reference(s)</span>
                      </th>
                      <th nxHeaderCell>
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Payment Made To</span>
                      </th>
                      <th nxHeaderCell class="std-ta-right">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">Amount Paid (RM)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="medium">Cash (Over the Counter)</span>
                      </td>
                      <td nxTableCell>
                        <span nxCopytext="medium">Pay in Slip/Red No. : 333333333</span>
                      </td>
                      <td nxTableCell>
                        <span nxCopytext="medium">Call Centre Collection Account (8000818281)</span>
                      </td>
                      <td nxTableCell class="std-ta-right">
                        <span nxCopytext="medium">1,843.40</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <nx-swipebar label="Swipe to view more" class="nx-margin-top-m">
              <table nxTable zebra cellpadding="0">
                <thead>
                  <tr nxTableRow>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Payment Mode</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Reference(s)</span
                      >
                    </th>
                    <th nxHeaderCell>
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Payment Made To</span
                      >
                    </th>
                    <th nxHeaderCell class="std-ta-right">
                      <span nxCopytext="medium" class="nx-font-weight-semibold"
                        >Amount Paid (RM)</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr nxTableRow>
                    <td nxTableCell>
                      <span nxCopytext="medium">Cash (Over the Counter)</span>
                    </td>
                    <td nxTableCell>
                      <span nxCopytext="medium"
                        >Pay in Slip/Red No. : 333333333</span
                      >
                    </td>
                    <td nxTableCell>
                      <span nxCopytext="medium"
                        >Call Centre Collection Account (8000818281)</span
                      >
                    </td>
                    <td nxTableCell class="std-ta-right">
                      <span nxCopytext="medium">1,843.40</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </nx-swipebar> -->
          </nx-expansion-panel>
        </nx-accordion>

        <!-- Endorsement Checklist (to be move to new component) -->
        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Checklist
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div nxLayout="grid nopadding" class="grid_remove_padding">
              <!-- Amendment of Period, Extra Benefits, NCD Allowed, NCD Amendment, NCD Withdraw, Reinstatement of Extra Coverage, Revision of Sum Insured -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">1</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Insured or Agent Authorised letter / form / email (with
                    contact number).</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- NCD Allowed, NCD Amendment -->
              <div nxRow>
                <div nxCol="12">
                  <p nxCopytext="medium" class="nx-margin-bottom-2m">
                    <i>Note: Insured IC copy/E-payment form/copy of bank
                      book-statement required (if refund involved).</i>
                  </p>
                </div>
              </div>

              <!-- Amendment of Period -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">2</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Copy of VOC / Registration card (for brand new only).</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Cancellation by Insured, Hire Purchase/Employers' Loan/Leasing, Named Driver -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">1</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Insured Authorised letter/form/email (with contact
                    number).</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12">
                  <p nxCopytext="medium" class="nx-margin-bottom-2m">
                    <i>Note: Warranted no claim as at todate.</i>
                  </p>
                </div>
              </div>

              <!-- Change of Bancassurance Details -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">1</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Internal email communication for changes.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">1</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Insured or Agent Authorised letter/form/email (with contact
                    number) OR</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Change of Vehicle Chassis No, Change Of Vehicle Registration Number, Change of Make & Model -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">2</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Copy of VOC/Registration card.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Extra Benefits, Named Driver, Revision of Sum Insured -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">2</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Vehicle inspection done.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Extra Benefits, Named Driver -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">3</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Warranted no claim as at todate.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12">
                  <p nxCopytext="medium" class="nx-margin-bottom-2m">
                    <i>Note: Inclusion of extra benefits will only be allowed
                      warranted no claim as at the date of endt.</i>
                  </p>
                </div>
              </div>

              <!-- Named Driver -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">4</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">copy of I/C / passport.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- NCD Withdraw -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">2</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Claim/damage to the vehicle.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12">
                  <p nxCopytext="medium" class="nx-margin-bottom-2m">
                    <i>Note: Insured IC copy/Epayment form/copy of bank
                      book-statement required (if refund involved).</i>
                  </p>
                </div>
              </div>

              <!-- Reinstatement of Extra Coverage -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">2</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Inspection done (required only if exceeded 14 days).</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Revision of Sum Insured -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">3</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Requested by Bank/Finance owner.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Revision of Sum Insured -->
              <div nxRow class="nx-margin-bottom-2m">
                <div nxCol="2,2,1" class="checklist">
                  <span nxCopytext="normal">4</span>
                </div>
                <div nxCol="10,10,11" class="checklist">
                  <span nxCopytext="normal">Purchase invoice - for new cases & vehicles from
                    Langkawi/Labuan only.</span>
                  <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-s">
                    <div nxRow>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">Yes</nx-radio>
                      </div>
                      <div nxCol="4,4,3,2" class="checklist_radio">
                        <nx-radio name="yesno" [disabled]="true">No</nx-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12">
                  <p nxCopytext="medium" class="nx-margin-bottom-2m">
                    <i>Note: Warranted no claim as at todate.</i>
                  </p>

                  <p nxCopytext="normal" class="nx-margin-bottom-2m">
                    Remarks: N/A
                  </p>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <!-- Endorsement Narration (to be move to new component) -->
        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Endorsement Narration
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <!-- CHANGE OF VEHICLE ENGINE NO./CUBIC CAPACITY -->
            <u nxCopytext="normal" class="nx-font-weight-semibold">CHANGE OF VEHICLE ENGINE NO./CUBIC CAPACITY</u>

            <p nxCopytext="normal" class="nx-margin-y-m">
              IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
              <span class="nx-font-weight-bold">13/12/2023</span> THE ENGINE
              NUMBER AND/OR CUBIC CAPACITY IS/ARE AMENDED TO READ AS FOLLOWS AND
              NOT AS OTHERWISE STATED.
            </p>

            <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-bold">
              ENGINE / MOTOR NO. : 2NROP28330<br />CUBIC CAPACITY : 1,298 CC
            </p>

            <p nxCopytext="normal" class="nx-margin-y-m">
              IN CONSIDERATION OF THE ABOVE, AN ADDITIONAL PREMIUM OF
              <span class="nx-font-weight-bold">RM0.00</span> IS HEREBY CHARGED
              TO THE INSURED.
            </p>

            <p nxCopytext="normal">
              ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
            </p>

            <!-- This is just spacing, you can remove it -->
            <div class="nx-margin-y-2m"></div>

            <!-- CHANGE OF MAKE & MODEL -->
            <u nxCopytext="normal" class="nx-font-weight-semibold">CHANGE OF MAKE & MODEL</u>

            <p nxCopytext="normal" class="nx-margin-y-m">
              IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
              <span class="nx-font-weight-bold">13/12/2023</span> THE MAKE &
              MODEL IS AMENDED TO READ AS FOLLOWS AND NOT AS OTHERWISE STATED.
            </p>

            <p nxCopytext="normal">MAKE & MODEL:</p>
            <span nxCopytext="normal">
              <ol nxList="small" class="upload_content nx-margin-bottom-2m">
                <li>
                  <span class="nx-font-weight-bold">PERODUA MYVI 1.3 (A) SE</span>
                </li>
              </ol>
            </span>

            <p nxCopytext="normal">
              ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
            </p>

            <!-- This is just spacing, you can remove it -->
            <div class="nx-margin-y-2m"></div>

            <!-- INCLUSION OF NAMED DRIVER(S) -->
            <u nxCopytext="normal" class="nx-font-weight-semibold">INCLUSION OF NAMED DRIVER(S)</u>

            <p nxCopytext="normal" class="nx-margin-y-m">
              IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
              <span class="nx-font-weight-bold">13/12/2023</span> THE FOLLOWING
              NAMED DRIVER(S) IS/ARE INCLUDED INTO THE POLICY.
            </p>

            <p nxCopytext="normal" class="nx-font-weight-bold">
              NO NAMED DRIVER(S) - DRIVER ID
            </p>
            <span nxCopytext="normal">
              <ol nxList="small" class="upload_content_bold nx-margin-bottom-2m nx-font-weight-bold">
                <li>
                  <span class="nx-font-weight-bold">WONG KAM WAI - 880914145277</span>
                </li>
              </ol>
            </span>

            <p nxCopytext="normal">
              ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
            </p>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Remarks
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <nx-data-display size="small" class="nx-margin-bottom-2m">
              <nx-data-display-label class="nx-font-weight-bold">Referral Remarks:</nx-data-display-label>
              <p nxCopytext="large">N/A</p>
            </nx-data-display>

            <nx-data-display size="small" class="nx-margin-bottom-2m">
              <nx-data-display-label class="nx-font-weight-bold">Cancellation Remarks:</nx-data-display-label>
              <p nxCopytext="large">N/A</p>
            </nx-data-display>

            <nx-data-display size="small" class="nx-margin-bottom-2m">
              <nx-data-display-label class="nx-font-weight-bold">Approval Remarks:</nx-data-display-label>
              <p nxCopytext="large">N/A</p>
            </nx-data-display>

            <p nxCopytext="medium" class="nx-font-weight-bold">
              Updated by: hester_wong at 20/12/2023
            </p>
          </nx-expansion-panel>
        </nx-accordion>
      </div>
    </div>
  </div>
  <div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <div class="button-container">
            <button nxButton="secondary" type="button">Back</button>
            <button nxButton="secondary" type="button">
              REQUEST CANCELLATION
            </button>
            <button nxButton="primary" type="button">
              <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>E-Cover Note PDF
            </button>
            <button nxButton="primary" type="button">
              <nx-icon name="download" nxIconPositionStart aria-hidden="true"></nx-icon>E-invoice PDF
            </button>
            <button nxButton="primary" type="button">Update JPJ Details</button>
            <button nxButton="primary" type="button">UPLOAD DOCUMENT</button>
            <button nxButton="primary" type="button">EMAIL DOCUMENT</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #templateClaimEnq>
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-m">
        <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
          <div nxRow class="enquiry_status">
            <div nxCol="12">
              <h3 nxHeadline size="s" class="nx-font-weight-regular">
                Claim No. :
                <span class="nx-font-weight-bold">VKL9151394</span>
              </h3>
            </div>
          </div>
        </div>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Agent Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div nxLayout="grid nopadding">
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Code</nx-data-display-label>
                    <p nxCopytext="large">AS00584</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Agent Name</nx-data-display-label>
                    <p nxCopytext="large">KALA ENTERPRISE</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel class="accordion_remove_btm_space">
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Issuance Information
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <div nxLayout="grid nopadding">
              <!-- RoadTax Issuance Information (to be move to new component) -->
              <div nxRow>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                    <p nxCopytext="large">LBC3333</p>
                  </nx-data-display>
                </div>
                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                  <nx-data-display size="small">
                    <nx-data-display-label class="font-grey">e-Policy No.</nx-data-display-label>
                    <p nxCopytext="large">22VKL0042209-00</p>
                  </nx-data-display>
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>

        <nx-accordion variant="light">
          <nx-expansion-panel>
            <nx-expansion-panel-header>
              <nx-expansion-panel-title class="accordion_edit">
                Claim Details
              </nx-expansion-panel-title>
            </nx-expansion-panel-header>

            <!-- Individual -->
            <table nxTable cellpadding="0" class="summary_table">
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">Name</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">WONG KAM WAI</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">880914145277</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell><span nxCopytext="normal">As At</span></td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">22/11/2022</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Claim Code and Description</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">A01 THEFT BY FORCIBLE ENTRY</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Claim Lost Date</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">22/11/2022</span>
                </td>
              </tr>
              <tr nxTableRow>
                <td nxTableCell>
                  <span nxCopytext="normal">Retain No Claim Discount</span>
                </td>
                <td nxTableCell class="column_cp_content">
                  <span nxCopytext="normal">NO</span>
                </td>
              </tr>
            </table>

            <!-- Individual -->
            <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-2m">
              <div nRow>
                <div nxCol="12" class="padding_0">
                  <div>
                    <app-external-scrollbar targetSyncScroll="12"
                      [targetBaseWidth]="tableHolder12"></app-external-scrollbar>
                    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder12
                      syncScroll="12">
                      <table nxTable nxSort zebra class="enquiry">
                        <thead>
                          <tr nxTableRow>
                            <th nxHeaderCell nxHeaderCell>
                              Claim Reserve Code
                            </th>
                            <th nxHeaderCell nxHeaderCell>
                              Claim Reserve Type
                            </th>
                            <th nxHeaderCell nxHeaderCell>
                              Claim Reserve Description
                            </th>
                            <th nxHeaderCell nxHeaderCell>
                              Retain No Claim Discount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr nxTableRow>
                            <td nxTableCell>10</td>
                            <td nxTableCell>OD</td>
                            <td nxTableCell>OWN DAMAGE REPAIRS</td>
                            <td nxTableCell>{{ "No" | uppercase }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- <nx-swipebar label="Swipe to view more">
                    <table nxTable nxSort zebra class="enquiry">
                      <thead>
                        <tr nxTableRow>
                          <th nxHeaderCell nxHeaderCell>Claim Reserve Code</th>
                          <th nxHeaderCell nxHeaderCell>Claim Reserve Type</th>
                          <th nxHeaderCell nxHeaderCell>
                            Claim Reserve Description
                          </th>
                          <th nxHeaderCell nxHeaderCell>
                            Retain No Claim Discount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr nxTableRow>
                          <td nxTableCell>10</td>
                          <td nxTableCell>OD</td>
                          <td nxTableCell>OWN DAMAGE REPAIRS</td>
                          <td nxTableCell>No</td>
                        </tr>
                      </tbody>
                    </table>
                  </nx-swipebar> -->
                </div>
              </div>
            </div>
          </nx-expansion-panel>
        </nx-accordion>
      </div>
    </div>
  </div>
  <div class="button_area_bg nx-margin-top-50">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <button nxButton="secondary" type="button">Back</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-container *ngTemplateOutlet="templateRef"></ng-container>

<ng-template #templateAddRemarks>
  <div class="left-content">
    <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-2m">
      Enter Remarks
    </h3>
    <nx-formfield label="Enter Remarks">
      <input nxInput #inputToCount maxlength="15" />
      <span nxFormfieldHint>
        <span *ngIf="inputToCount.value?.length === 0">
          max. 200 characters
        </span>
        <span *ngIf="inputToCount.value.length > 0">
          {{ 200 - (inputToCount.value.length || 0) }} characters remaining
        </span>
      </span>
    </nx-formfield>
    <div class="two-button-container">
      <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s">
        Cancel
      </button>
      <button nxButton (click)="closeTemplateDialog()">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #templateApprove>
  <div class="left-content">
    <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-2m">
      Approve Remarks
    </h3>
    <nx-formfield label="Enter Remarks">
      <input nxInput #inputToCount maxlength="15" />
      <span nxFormfieldHint>
        <span *ngIf="inputToCount.value?.length === 0">
          max. 200 characters
        </span>
        <span *ngIf="inputToCount.value.length > 0">
          {{ 200 - (inputToCount.value.length || 0) }} characters remaining
        </span>
      </span>
    </nx-formfield>
    <div class="two-button-container">
      <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s">
        Cancel
      </button>
      <button nxButton (click)="closeTemplateDialog()">
        APPROVE E-COVER NOTE
      </button>
    </div>
  </div>
</ng-template>

<ng-template #templateReject>
  <div class="left-content">
    <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-2m">
      Reject Remarks
    </h3>
    <nx-formfield label="Enter Remarks">
      <input nxInput #inputToCount maxlength="15" />
      <span nxFormfieldHint>
        <span *ngIf="inputToCount.value?.length === 0">
          max. 200 characters
        </span>
        <span *ngIf="inputToCount.value.length > 0">
          {{ 200 - (inputToCount.value.length || 0) }} characters remaining
        </span>
      </span>
    </nx-formfield>
    <div class="two-button-container">
      <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s">
        Cancel
      </button>
      <button nxButton (click)="closeTemplateDialog()">
        REJECT E-COVER NOTE
      </button>
    </div>
  </div>
</ng-template>

<ng-template #templateEnHistory>
  <div class="left-content">
    <h3 nxHeadline="subsection-medium">Endorsement Narration</h3>
    <p nxCopytext="normal" class="nx-margin-top-2m">
      <u><b>REVISION OF SUM INSURED - DECREASE</b></u> <br /><br />
      IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE <b>13/12/2023</b> THE SUM
      INSURED IS DECREASED FROM <b>RM40,000.00</b> TO <b>RM28,000.00</b><br /><br />

      IN CONSIDERATION OF THE ABOVE, A REFUND PREMIUM OF <b>RM171.89</b> IS
      HEREBY ALLOWED TO THE INSURED.<br /><br />
      ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.
    </p>

    <button nxButton="secondary" (click)="closeTemplateDialog()" class="nx-margin-right-s nx-margin-top-50">
      Cancel
    </button>
  </div>
</ng-template>