import { Component, Inject } from '@angular/core';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent {

  type: any;
  message: any;
  btnOkLabel: any;
  
  constructor(
    @Inject(NX_MODAL_DATA) data: any,
    private dialogRef: NxModalRef<any>,
  ) {
    this.type = data.type;
    this.message = data.message;
  }
  
  onclickOK() {
    this.dialogRef.close("OK");
  }
  
}
