import { AbstractControl, ValidatorFn } from "@angular/forms";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";

export function NumberRangeValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) return null;
    let value = new RemoveCommaPipe().transform(control.value);
    if (Number(value) < 1) {
      return null;
    } else if (!(Number(value) > min && Number(value) < max)) {
      return { range: true };
    }
    return null;
  };
}
