<div>
  <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s">Extra Cover</h3>
  <div nxModalContent>
    <div class="left-content">
      <form [formGroup]="pageFilterForm" class="navigation">

        <div class="nx-margin-top-2m">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12" class="nx-margin-top-2m">
                <div class="scroll-container table_popup">
                  <div>
                    <app-external-scrollbar targetSyncScroll="1"
                      [targetBaseWidth]="tableHolder"></app-external-scrollbar>
                    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder
                      syncScroll="1">
                      <!-- table here -->
                      <table nxTable zebra class="nx-table--scrollable">
                        <thead #tableHeader>
                          <tr nxTableRow>
                            <th nxHeaderCell>
                              <nx-checkbox formControlName="selectAllExtraCover" (change)="checkedAllChange()"
                                forcheckedAllChange>
                                <span nxCopytext="medium">Extra Cover</span>
                              </nx-checkbox>
                            </th>
                          </tr>
                        </thead>
                        <app-external-scrollbar targetSyncScroll="1"
                          [targetBaseWidth]="tableHolder"></app-external-scrollbar>
                        <tbody>
                          <tr nxTableRow *ngFor="let item of paginatedResults; let i = index" [selectable]="true">
                            <td nxTableCell>
                              <nx-checkbox (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection"
                                (change)="emitData(item, item.subCoverCode)" [checked]="selection.isSelected(item)"
                                [value]="item.subCoverCode" [disabled]="!item.enabled">
                                {{ item.description }}
                              </nx-checkbox>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown formControlName="perPage" [panelGrow]="true" [panelMaxWidth]="500" [value]="perPage">
                    <nx-dropdown-item *ngFor="let item of itemsPerPageSelections" [value]="item"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"></div>
            @if (getResultLength() > 5) {
            <div nxCol="12,12,6">
              <nx-pagination [count]="getResultLength()" [page]="curpage" [perPage]="perPage" type="advanced"
                class="pagination_position" (goPrev)="prevPage()" (goNext)="nextPage()"
                (goPage)="goToPage($event)"></nx-pagination>
            </div>
            }
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="button-container nx-margin-top-50">
    <button nxButton="secondary" nxModalClose>Cancel</button>
    <button nxButton nxModalClose (click)="proceedToClose()">Proceed</button>
  </div>
</div>