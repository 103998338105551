import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@aposin/ng-aquila/button';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import * as i3 from '@aposin/ng-aquila/grid';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxAutocompleteModule } from '@aposin/ng-aquila/autocomplete';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
const _c0 = ["*"];
function NxPageSearchComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5)(1, "button", 6);
    i0.ɵɵlistener("click", function NxPageSearchComponent_div_5_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onButtonClick());
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nxCol", ctx_r1.buttonLayout);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.buttonLabel);
  }
}
class NxPageSearchComponent {
  /** Sets the text label of the button. */
  set buttonLabel(value) {
    if (this._buttonLabel !== value) {
      this._buttonLabel = value;
      this._cdr.markForCheck();
    }
  }
  get buttonLabel() {
    return this._buttonLabel;
  }
  /** Whether the search button should be hidden. */
  set hideSearchButton(value) {
    this._hideSearchButton = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  get hideSearchButton() {
    return this._hideSearchButton;
  }
  /** Layout of the search button inside of a NxGrid. Default: 12,12,12,2. */
  set buttonLayout(value) {
    if (this._buttonLayout !== value) {
      this._buttonLayout = value;
      this._cdr.markForCheck();
    }
  }
  get buttonLayout() {
    return this._buttonLayout;
  }
  /** Layout of the content area inside of a NxGrid. Default: 12,12,12,10 | 12 (if search button is hidden). */
  set contentLayout(value) {
    if (this._contentLayout !== value) {
      this._contentLayout = value;
      this._cdr.markForCheck();
    }
  }
  get contentLayout() {
    if (this._contentLayout) {
      return this._contentLayout;
    }
    return this.hideSearchButton ? '12' : '12,12,12,10';
  }
  constructor(_cdr) {
    this._cdr = _cdr;
    this._buttonLabel = null;
    this._hideSearchButton = false;
    this._buttonLayout = '12,12,12,2';
    this._contentLayout = null;
    /** An event emitted when the user clicks the search button. */
    this.buttonClick = new EventEmitter();
  }
  /** @docs-private */
  onButtonClick() {
    this.buttonClick.emit();
  }
  static {
    this.ɵfac = function NxPageSearchComponent_Factory(t) {
      return new (t || NxPageSearchComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxPageSearchComponent,
      selectors: [["nx-page-search"]],
      hostVars: 1,
      hostBindings: function NxPageSearchComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", "search");
        }
      },
      inputs: {
        buttonLabel: "buttonLabel",
        hideSearchButton: "hideSearchButton",
        buttonLayout: "buttonLayout",
        contentLayout: "contentLayout"
      },
      outputs: {
        buttonClick: "buttonClick"
      },
      ngContentSelectors: _c0,
      decls: 6,
      vars: 2,
      consts: [[1, "nx-pagesearch"], ["nxLayout", "grid"], ["nxRow", ""], [1, "nx-pagesearch--content", 3, "nxCol"], ["class", "nx-pagesearch__actions", 3, "nxCol", 4, "ngIf"], [1, "nx-pagesearch__actions", 3, "nxCol"], ["nxButton", "primary medium", "type", "button", 3, "click"]],
      template: function NxPageSearchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3);
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, NxPageSearchComponent_div_5_Template, 3, 2, "div", 4);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("nxCol", ctx.contentLayout);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx.hideSearchButton);
        }
      },
      dependencies: [i1.NgIf, i2.NxButtonComponent, i3.NxLayoutComponent, i3.NxRowComponent, i3.NxColComponent],
      styles: [".nx-pagesearch[_ngcontent-%COMP%]{padding:32px 0;background:var(--page-search-background-color);box-shadow:var(--page-search-box-shadow)}.nx-pagesearch[_ngcontent-%COMP%]     .nx-formfield__wrapper{padding-bottom:0}.nx-pagesearch[_ngcontent-%COMP%]     .has-outline .nx-formfield__wrapper{padding-top:4px}.nx-pagesearch[_ngcontent-%COMP%]     .c-input{height:auto;font-size:var(--page-search-font-size);line-height:var(--page-search-line-height);font-weight:var(--page-search-font-weight);letter-spacing:var(--page-search-letter-spacing)}.nx-pagesearch[_ngcontent-%COMP%]     .c-input.is-filled{font-weight:600}.nx-pagesearch[_ngcontent-%COMP%]     .c-input::placeholder{font-weight:300}.nx-pagesearch[_ngcontent-%COMP%]     .nx-formfield__input-container{align-items:center}.nx-pagesearch[_ngcontent-%COMP%]     .nx-formfield__prefix, .nx-pagesearch[_ngcontent-%COMP%]     .nx-formfield__suffix{color:var(--page-search-icon-color)}.nx-pagesearch[_ngcontent-%COMP%]     .nx-formfield__suffix{cursor:pointer}.nx-pagesearch[_ngcontent-%COMP%]     button{margin:0}.nx-pagesearch[_ngcontent-%COMP%]     .nx-icon--auto{font-size:24px}.nx-pagesearch[_ngcontent-%COMP%]   .nx-pagesearch__actions[_ngcontent-%COMP%]{display:flex;align-items:center;margin-top:0}@media (max-width: 991px){.nx-pagesearch[_ngcontent-%COMP%]   .nx-pagesearch__actions[_ngcontent-%COMP%]{margin-top:16px}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxPageSearchComponent, [{
    type: Component,
    args: [{
      selector: 'nx-page-search',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[attr.role]': '"search"'
      },
      template: "<div class=\"nx-pagesearch\">\n    <div nxLayout=\"grid\">\n        <div nxRow>\n            <div [nxCol]=\"contentLayout\" class=\"nx-pagesearch--content\">\n                <ng-content></ng-content>\n            </div>\n            <div [nxCol]=\"buttonLayout\" *ngIf=\"!hideSearchButton\" class=\"nx-pagesearch__actions\">\n                <button nxButton=\"primary medium\" (click)=\"onButtonClick()\" type=\"button\">{{ buttonLabel }}</button>\n            </div>\n        </div>\n    </div>\n</div>\n",
      styles: [".nx-pagesearch{padding:32px 0;background:var(--page-search-background-color);box-shadow:var(--page-search-box-shadow)}.nx-pagesearch ::ng-deep .nx-formfield__wrapper{padding-bottom:0}.nx-pagesearch ::ng-deep .has-outline .nx-formfield__wrapper{padding-top:4px}.nx-pagesearch ::ng-deep .c-input{height:auto;font-size:var(--page-search-font-size);line-height:var(--page-search-line-height);font-weight:var(--page-search-font-weight);letter-spacing:var(--page-search-letter-spacing)}.nx-pagesearch ::ng-deep .c-input.is-filled{font-weight:600}.nx-pagesearch ::ng-deep .c-input::placeholder{font-weight:300}.nx-pagesearch ::ng-deep .nx-formfield__input-container{align-items:center}.nx-pagesearch ::ng-deep .nx-formfield__prefix,.nx-pagesearch ::ng-deep .nx-formfield__suffix{color:var(--page-search-icon-color)}.nx-pagesearch ::ng-deep .nx-formfield__suffix{cursor:pointer}.nx-pagesearch ::ng-deep button{margin:0}.nx-pagesearch ::ng-deep .nx-icon--auto{font-size:24px}.nx-pagesearch .nx-pagesearch__actions{display:flex;align-items:center;margin-top:0}@media (max-width: 991px){.nx-pagesearch .nx-pagesearch__actions{margin-top:16px}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    buttonLabel: [{
      type: Input
    }],
    hideSearchButton: [{
      type: Input
    }],
    buttonLayout: [{
      type: Input
    }],
    contentLayout: [{
      type: Input
    }],
    buttonClick: [{
      type: Output
    }]
  });
})();
class NxPageSearchModule {
  static {
    this.ɵfac = function NxPageSearchModule_Factory(t) {
      return new (t || NxPageSearchModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxPageSearchModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, NxAutocompleteModule, NxFormfieldModule, NxButtonModule, NxGridModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxPageSearchModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NxAutocompleteModule, NxFormfieldModule, NxButtonModule, NxGridModule],
      declarations: [NxPageSearchComponent],
      exports: [NxPageSearchComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxPageSearchComponent, NxPageSearchModule };
