<h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s std-ta-center">
  Marketing Material
</h3>
<div nxModalContent>
  <div nxLayout="grid nopadding">
    <div nxRow rowAlignItems="start,start,center">
      <div nxCol="12,12.6">
        <form [formGroup]="searchForm">
        <nx-page-search [hideSearchButton]="true">
          <nx-formfield>
            <input nxInput type="search" formControlName="searchBy"/>

            <span nxFormfieldPrefix>
              <nx-icon name="search" size="s"></nx-icon>
            </span>

            @if (searchForm.get('searchBy').value) {
            <span nxFormfieldSuffix aria-hidden="true" (click)="clearSearchBar()">
              <nx-icon name="close" size="s"></nx-icon>
            </span>
            }
          </nx-formfield>
        </nx-page-search>
        </form>
      </div>
    </div>
  </div>

  <table nxTable nxSort (sortChange)="sortTable($event)" zebra class="nx-margin-top-2m">
    <thead>
      <tr nxTableRow>
        <th nxHeaderCell>
          @if(eDocument.length > 0) {
          <nx-checkbox (checkedChange)="toggleAll($event)" aria-label="Toggle selection of all rows"
            [checked]="checkboxCondition()" [indeterminate]="checkIndeterminate()">
          </nx-checkbox>
          }
        </th>
        <th nxHeaderCell nxSortHeaderCell="docTitle">e-Document Name</th>
        <th nxHeaderCell>Action</th>
      </tr>
    </thead>
    <tbody>
        @for(item of eDocument.slice(start, end); track item) {
        <tr nxTableRow [selected]="selection.isSelected(item)" (select)="selection.toggle(item)">
          <td nxTableCell>
            <nx-checkbox (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection" 
            [checked]="selection.isSelected(item)">
            </nx-checkbox>
          </td>
          <td nxTableCell class="horizontal-checkboxes">
            <img src="../../../assets/img/Icon - PDF - Colour.svg" />
            <span class="nx-margin-left-2xs">{{ item.docTitle }}</span>
          </td>
          <td nxTableCell>
            <nx-link><a [href]="item.docUrl" target="_blank">VIEW</a></nx-link>
          </td>
        </tr>
        }
      
    </tbody>
  </table>

  @if(eDocument.length > 10) {
  <form [formGroup]="pageFilterForm">
    <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
      <div nxRow>
        <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
          <nx-natural-language-form size="small" class="pagination_form">
            <span nxCopytext="normal">Show</span>
            <nx-word>
              <nx-dropdown [(value)]="perPage" formControlName="perPage" [panelGrow]="true" [panelMaxWidth]="500"
                (selectionChange)="onChangePerPage()">
                @for(item of itemsPerPageSelections; track item) {
                <nx-dropdown-item [value]="item"></nx-dropdown-item>
                }
              </nx-dropdown>
            </nx-word>
            <span nxCopytext="normal">results per page</span>
          </nx-natural-language-form>
        </div>
  
        <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"></div>
        <div nxCol="12,12,6">
          <nx-pagination [count]="eDocument.length" [page]="curPage" [perPage]="perPage" (goPrev)="prevPage()"
            (goNext)="nextPage()" (goPage)="goToPage($event)" type="advanced" ariaLabel="Advanced example pagination"
            class="pagination_position">
          </nx-pagination>
        </div>
      </div>
    </div>
  </form>
  }
  
</div>

<!-- <div nxModalActions class="">      
      <button nxButton="primary" nxModalClose>SEND EMAIL</button>
      <button nxButton="primary" nxModalClose>SEND WHATSAPP</button>     
  </div> -->

<div nxModalActions class="justify_center">
  <button nxModalClose class="nx-margin-bottom-0 nx-margin-right-xs" nxButton="primary" type="button"
  [disabled]="selection.selected.length <= 0 || selection.selected.length > 5" (click)="sendEmail()">
    SEND EMAIL
  </button>
  <button class="nx-margin-bottom-0" nxButton="primary" type="button"
  [disabled]="selection.selected.length <= 0 || selection.selected.length > 5 || !contactNo" (click)="sendWhatsapp()">
    SEND WHATSAPP
  </button>
</div>