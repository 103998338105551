<ng-container>
  <div class="title-header my-0">
    <div nxLayout="grid maxwidth">
      <div nxRow>
        <div nxCol="12">
          <h1>Download</h1>
        </div>
      </div>
    </div>
  </div>

  <div nxLayout="grid maxwidth" class="nx-margin-top-xl download">
    <div nxRow>
      <div nxCol="12">
        <nx-tab-group [(selectedIndex)]="currentIndex" (selectedTabChange)="selectedTabChanged($event)" mobileAccordion="false">
           <!-- Company profile -->
          <nx-tab label="Company Profile">
            <p class="tab__content"></p>
          </nx-tab>
          <!-- Product -->
          <nx-tab label="Product">          
            <div class="tab__content">
            <nx-accordion variant="light">
              <nx-expansion-panel
                class="accordion_remove_btm_space"
                [expanded]="true"
              >
                <nx-expansion-panel-header>
                  <nx-expansion-panel-title class="accordion_edit">
                    Personal Insurance
                  </nx-expansion-panel-title>
                </nx-expansion-panel-header>

                <!-- Large and Medium View -->
                <div
                  nxLayout="grid nopadding"
                  class="nx-hidden-s nx-hidden-xs"
                >
                  <div nxRow>
                    <div nxCol="12,12,4,3" class="action-container padding_0">
                      <button
                        nxAction
                        *ngFor="let action of actions"
                        (click)="onSelect(action)"
                        [selected]="action === selectedAction"
                      >
                        <div class="action-btn-label">
                          {{ action.label }}
                        </div>

                        <div class="action-btn-icon nx-margin-right-s">
                          <nx-icon
                            class=""
                            [name]="action.icon"
                            style="font-size: 17pt"
                          ></nx-icon>
                        </div>
                      </button>
                    </div>
                    <div nxCol="12,12,8,9" class="padding_0">
                      <div class="sub_menu">
                        <ng-container
                          *ngIf="selectedAction == this.actions[0]"
                        >
                          <div nxLayout="grid nogutter">
                            <div
                              nxRow
                              class="std-pd-m bottom-line"
                              *ngFor="
                                let product of selectedAction.product;
                                let i = index
                              "
                            >
                              <div
                                nxCol="12"
                                class="std-ta-center nx-margin-bottom-m"
                              >
                                <h4>
                                  {{ product.name }}
                                </h4>
                              </div>
                              <div nxRow>
                                <div
                                  nxCol="12,12,6,6"
                                  class="nx-margin-bottom-s"
                                  *ngFor="let item of product.items"
                                >
                                  <nx-link>
                                    <a
                                      class="container_flex_center std-tt-uppercase"
                                      [routerLink]="'../product-details'"
                                    >
                                      <nx-icon name="arrow-right"></nx-icon>
                                      <span class="product-folder-name">{{
                                        item.name
                                      }}</span>
                                    </a>
                                  </nx-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container
                          *ngIf="selectedAction == this.actions[1]"
                        >
                          <div nxLayout="grid nogutter">
                            <div
                              nxRow
                              class="std-pd-m bottom-line"
                              *ngFor="let product of selectedAction.product"
                            >
                              <div
                                nxCol="12"
                                class="std-ta-center nx-margin-bottom-m"
                              >
                                <h4>
                                  {{ product.name }}
                                </h4>
                              </div>
                              <div nxRow>
                                <div
                                  nxCol="12,12,6,6"
                                  class="nx-margin-bottom-s"
                                  *ngFor="let item of product.items"
                                >
                                  <nx-link>
                                    <a
                                      class="container_flex_center std-tt-uppercase"
                                    >
                                      <nx-icon name="arrow-right"></nx-icon>
                                      <span class="product-folder-name">{{
                                        item.name
                                      }}</span>
                                    </a>
                                  </nx-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- small view -->
                <div
                  nxCol="12,12,5,3"
                  class="action-container padding_0 nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                >
                  <button
                    nxAction
                    *ngFor="let action of actions"
                    (click)="onSelect(action)"
                    [selected]="action === selectedAction"
                  >
                    <div class="action-btn-label">
                      {{ action.label }}
                    </div>

                    <!-- content -->
                    <div class="sub_menu">
                      <ng-container
                        *ngIf="action.label == selectedAction.label"
                      >
                        <div nxLayout="grid nogutter">
                          <div
                            nxRow
                            class="std-pd-m bottom-line"
                            *ngFor="
                              let product of selectedAction.product;
                              let i = index
                            "
                          >
                            <div
                              nxCol="12"
                              class="std-ta-center nx-margin-bottom-m"
                            >
                              <h4 style="color: #414141">
                                {{ product.name }}
                              </h4>
                            </div>
                            <div nxRow>
                              <div
                                nxCol="12,12,6,6"
                                class="nx-margin-bottom-s"
                                *ngFor="let item of product.items"
                              >
                                <nx-link>
                                  <a
                                    class="container_flex_center std-tt-uppercase"
                                  >
                                    <nx-icon name="arrow-right"></nx-icon>
                                    <span class="product-folder-name">{{
                                      item.name
                                    }}</span>
                                  </a>
                                </nx-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </button>
                </div>
              </nx-expansion-panel>

              <nx-expansion-panel class="accordion_remove_btm_space">
                <nx-expansion-panel-header>
                  <nx-expansion-panel-title class="accordion_edit">
                    Business Insurance
                  </nx-expansion-panel-title>
                </nx-expansion-panel-header>

                <div>Business Insurance Content</div>
              </nx-expansion-panel>

              <nx-expansion-panel class="accordion_remove_btm_space">
                <nx-expansion-panel-header>
                  <nx-expansion-panel-title class="accordion_edit">
                    Test Insurance
                  </nx-expansion-panel-title>
                </nx-expansion-panel-header>

                <div>Test Insurance Content</div>
              </nx-expansion-panel>
            </nx-accordion>
          </div>
          </nx-tab>
          <!-- Forms -->
          <nx-tab label="Forms">
            <div class="tab__content">
              <div class="tab__content">
                <nx-accordion variant="light">
                  <nx-expansion-panel [expanded]="true" class="accordion_remove_btm_space">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title class="accordion_edit">
                        Claim Forms
                      </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <!-- filter -->
                    <ng-container>
                      <div class="std-ta-right" id="scrollHere">
                        <button
                          nxIconButton="tertiary small"
                          [nxContextMenuTriggerFor]="sort"
                          aria-label="Open menu"
                          type="button"
                          class="icon-btn-filter nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                        >
                          <nx-icon aria-hidden="true" name="popout"></nx-icon>
                        </button>
                        <nx-context-menu #sort="nxContextMenu">
                          <button nxContextMenuItem type="button">
                            Date (Most recent)
                          </button>
                          <button nxContextMenuItem type="button">
                            Date received (Oldest)
                          </button>
                        </nx-context-menu>

                        <button
                          nxIconButton="tertiary small"
                          [nxContextMenuTriggerFor]="filter"
                          aria-label="Open menu"
                          type="button"
                          class="icon-btn-filter"
                        >
                          <nx-icon aria-hidden="true" name="filter"></nx-icon>
                          <div
                            *ngIf="desktopScreen"
                            class="filter-text nx-hidden-s nx-hidden-xs"
                          >
                            &nbsp;Filter
                          </div>
                        </button>

                        <nx-context-menu #filter="nxContextMenu">
                          <div class="std-ta-center">
                            <label
                              nxCopytext="normal"
                              class="nx-font-weight-bold"
                              >Year</label
                            >
                          </div>

                          <button
                            nxContextMenuItem
                            type="button"
                            (click)="toggleFilter()"
                          >
                            2024
                          </button>
                        </nx-context-menu>
                      </div>

                      <div nxLayout="grid nopadding" *ngIf="show_filter">
                        <div nxRow>
                          <div nxCol="6">
                            <nx-taglist
                              [tags]="filterYear"
                              [allowTagDeletion]="true"
                            >
                            </nx-taglist>
                          </div>
                          <div nxCol="6" class="std-ta-right">
                            <button
                              nxButton="secondary small"
                              type="button"
                              (click)="toggleFilter()"
                            >
                              Clear Filters
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <!-- large and medium view -->
                    <ng-container *ngIf="desktopScreen">
                      <div
                        class="scroll-container nx-margin-bottom-2m nx-hidden-xs nx-hidden-s"
                      >
                      <div>
                        <app-external-scrollbar
                          targetSyncScroll="1"
                          [targetBaseWidth]="tableHolder1"
                        ></app-external-scrollbar>
                        <div
                          class="table-holder table_content_scroll only-this-horizon-scrollbar"
                          #tableHolder1
                          syncScroll="1"
                        >
                        <table
                        nxTable
                        nxSort
                        zebra
                        (sortChange)="sortTableGuides($event)"
                      >
                        <thead>
                          <tr nxTableRow>
                            <th nxHeaderCell nxSortHeaderCell="date">
                              Date
                            </th>
                            <th
                              nxHeaderCell
                              nxSortHeaderCell="eDocument_name"
                            >
                              e-Document name
                            </th>
                            <th
                              nxHeaderCell
                              nxSortHeaderCell="eDocument_type"
                            >
                              e-Dokument type
                            </th>
                            <th nxHeaderCell>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            nxTableRow
                            *ngFor="let item of tableElementsForms"
                          >
                            <td nxTableCell>
                              {{ item.date | date : "d MMM y" }}
                            </td>
                            <td nxTableCell>
                              <div class="container_flex_center">
                                <img
                                  src="assets/img/Icon - PDF - Colour.svg"
                                  alt=""
                                />
                                &nbsp; &nbsp;
                                <span> {{ item.eDocument_name }}</span>
                              </div>
                            </td>
                            <td nxTableCell>{{ item.eDocument_type }}</td>
                            <td nxTableCell>
                              
                              <div class="container_flex_center">
                                <button
                                  nxPlainButton
                                  type="button"
                                  class="nx-margin-right-m"
                                >
                                  DOWNLOAD
                                </button>
                                <button
                                  nxPlainButton
                                  type="button"
                                  class="nx-margin-right-m"
                                >
                                  SHARE
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                      </div>
                        <!-- <nx-swipebar label="Swipe to see more">
                          <table
                            nxTable
                            nxSort
                            zebra
                            (sortChange)="sortTableGuides($event)"
                          >
                            <thead>
                              <tr nxTableRow>
                                <th nxHeaderCell nxSortHeaderCell="date">
                                  Date
                                </th>
                                <th
                                  nxHeaderCell
                                  nxSortHeaderCell="eDocument_name"
                                >
                                  e-Document name
                                </th>
                                <th
                                  nxHeaderCell
                                  nxSortHeaderCell="eDocument_type"
                                >
                                  e-Dokument type
                                </th>
                                <th nxHeaderCell>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                nxTableRow
                                *ngFor="let item of tableElementsForms"
                              >
                                <td nxTableCell>
                                  {{ item.date | date : "d MMM y" }}
                                </td>
                                <td nxTableCell>
                                  <div class="container_flex_center">
                                    <img
                                      src="assets/img/Icon - PDF - Colour.svg"
                                      alt=""
                                    />
                                    &nbsp; &nbsp;
                                    <span> {{ item.eDocument_name }}</span>
                                  </div>
                                </td>
                                <td nxTableCell>{{ item.eDocument_type }}</td>
                                <td nxTableCell>
                                  
                                  <div class="container_flex_center">
                                    <button
                                      nxPlainButton
                                      type="button"
                                      class="nx-margin-right-m"
                                    >
                                      DOWNLOAD
                                    </button>
                                    <button
                                      nxPlainButton
                                      type="button"
                                      class="nx-margin-right-m"
                                    >
                                      SHARE
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </nx-swipebar> -->
                        <!-- pagination -->
                        <div
                          nxLayout="grid nopadding"
                          class="nx-margin-top-2xs grid_remove_padding pagination_form"
                        >
                          <div nxRow>
                            <div
                              nxCol="12,12,6"
                              class="nx-hidden-xs nx-hidden-s"
                            >
                              <nx-natural-language-form
                                size="small"
                                class="pagination_form"
                              >
                                <span nxCopytext="normal">Show</span>
                                <nx-word>
                                  <nx-dropdown value="20">
                                    <nx-dropdown-item value="5"
                                      >5</nx-dropdown-item
                                    >
                                    <nx-dropdown-item value="10"
                                      >10</nx-dropdown-item
                                    >
                                    <nx-dropdown-item value="20"
                                      >20</nx-dropdown-item
                                    >
                                    <nx-dropdown-item value="30"
                                      >30</nx-dropdown-item
                                    >
                                    <nx-dropdown-item value="40"
                                      >40</nx-dropdown-item
                                    >
                                    <nx-dropdown-item value="50"
                                      >50</nx-dropdown-item
                                    >
                                  </nx-dropdown>
                                </nx-word>
                                <span nxCopytext="normal"
                                  >results per page</span
                                >
                              </nx-natural-language-form>
                            </div>

                            <div
                              class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                            ></div>
                            <div
                              nxCol="12,12,6"
                              class="container_flex_center align_right"
                            >
                              <nx-pagination
                                [count]="count"
                                [page]="page"
                                [perPage]="perPage"
                                type="advanced"
                                (goPrev)="prevPage()"
                                (goNext)="nextPage()"
                                (goPage)="goToPage($event)"
                                ariaLabel="Advanced example pagination"
                                class="pagination_position"
                              >
                              </nx-pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <!-- small view -->
                    <ng-container *ngIf="desktopScreen">
                      <div
                        *ngFor="let item of tableElementsForms"
                        class="nx-margin-bottom-s nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                      >
                        <nx-card>
                          <div nxLayout="grid nopadding">
                            <div nxRow>
                              <div nxCol="2">
                                <img
                                  src="assets/img/Icon - PDF - Colour.svg"
                                  alt=""
                                />
                              </div>
                              <div nxCol="8">
                                <span nxCopytext="small">
                                  {{ item.eDocument_name }}</span
                                >
                              </div>
                              <div nxCol="2" style="display: grid; gap: 20px">
                                <nx-icon
                                  class="icon-mobile-color"
                                  aria-hidden="true"
                                  size="auto"
                                  outline="true"
                                  name="download"
                                ></nx-icon>
                                <nx-icon
                                  class="icon-mobile-color"
                                  aria-hidden="true"
                                  size="auto"
                                  outline="true"
                                  name="share"
                                ></nx-icon>
                              </div>
                            </div>
                            <div nxRow>
                              <div nxCol="5">
                                <span nxCopytext="small">Date :</span>
                              </div>
                              <div nxCol="7">
                                <span nxCopytext="small">{{
                                  item.date | date : "d MMM y"
                                }}</span>
                              </div>
                            </div>
                            <div nxRow>
                              <div nxCol="5">
                                <span nxCopytext="small">e-Document :</span>
                              </div>
                              <div nxCol="7">
                                <span nxCopytext="small">{{
                                  item.eDocument_type
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </nx-card>
                      </div>
                    </ng-container>
                  </nx-expansion-panel>
                  <nx-expansion-panel class="accordion_remove_btm_space">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title class="accordion_edit">
                        Testing Form
                      </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div>Testing Form Content</div>
                  </nx-expansion-panel>
                  <nx-expansion-panel class="accordion_remove_btm_space">
                    <nx-expansion-panel-header>
                      <nx-expansion-panel-title class="accordion_edit">
                        Other Forms
                      </nx-expansion-panel-title>
                    </nx-expansion-panel-header>

                    <div>Other Forms Content</div>
                  </nx-expansion-panel>
                </nx-accordion>
              </div>
            </div>
          </nx-tab>
          <!-- Guide -->
          <nx-tab label="Guides">            
            <div class="tab__content">
              <nx-accordion variant="light">
                <nx-expansion-panel [expanded]="true" class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Claim Form
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>

                  <!-- filter -->
                  <ng-container>
                    <div class="std-ta-right" id="scrollHere">
                      <button
                        nxIconButton="tertiary small"
                        [nxContextMenuTriggerFor]="sort"
                        aria-label="Open menu"
                        type="button"
                        class="icon-btn-filter nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                      >
                        <nx-icon aria-hidden="true" name="popout"></nx-icon>
                      </button>
                      <nx-context-menu #sort="nxContextMenu">
                        <button nxContextMenuItem type="button">
                          Date (Most recent)
                        </button>
                        <button nxContextMenuItem type="button">
                          Date received (Oldest)
                        </button>
                      </nx-context-menu>

                      <button
                        nxIconButton="tertiary small"
                        [nxContextMenuTriggerFor]="filter"
                        aria-label="Open menu"
                        type="button"
                        class="icon-btn-filter"
                      >
                        <nx-icon aria-hidden="true" name="filter"></nx-icon>
                        <div
                          *ngIf="desktopScreen"
                          class="filter-text nx-hidden-s nx-hidden-xs"
                        >
                          &nbsp;Filter
                        </div>
                      </button>

                      <nx-context-menu #filter="nxContextMenu">
                        <div class="std-ta-center">
                          <label nxCopytext="normal" class="nx-font-weight-bold"
                            >Year</label
                          >
                        </div>

                        <button
                          nxContextMenuItem
                          type="button"
                          (click)="toggleFilter()"
                        >
                          2024
                        </button>
                      </nx-context-menu>
                    </div>

                    <div nxLayout="grid nopadding" *ngIf="show_filter">
                      <div nxRow>
                        <div nxCol="6">
                          <nx-taglist
                            [tags]="filterYear"
                            [allowTagDeletion]="true"
                          >
                          </nx-taglist>
                        </div>
                        <div nxCol="6" class="std-ta-right">
                          <button
                            nxButton="secondary small"
                            type="button"
                            (click)="toggleFilter()"
                          >
                            Clear Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- large and medium view -->
                  <ng-container *ngIf="desktopScreen">
                    <div
                      class="scroll-container nx-margin-bottom-2m nx-hidden-xs nx-hidden-s"
                    >
                    <div>
                      <app-external-scrollbar
                        targetSyncScroll="2"
                        [targetBaseWidth]="tableHolder2"
                      ></app-external-scrollbar>
                      <div
                        class="table-holder table_content_scroll only-this-horizon-scrollbar"
                        #tableHolder2
                        syncScroll="2"
                      >
                      <table
                      nxTable
                      nxSort
                      zebra
                      (sortChange)="sortTableGuides($event)"
                    >
                      <thead>
                        <tr nxTableRow>
                          <th nxHeaderCell nxSortHeaderCell="date">
                            Date
                          </th>
                          <th
                            nxHeaderCell
                            nxSortHeaderCell="eDocument_name"
                          >
                            e-Document name
                          </th>
                          <th
                            nxHeaderCell
                            nxSortHeaderCell="eDocument_type"
                          >
                            e-Dokument type
                          </th>
                          <th nxHeaderCell>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          nxTableRow
                          *ngFor="let item of tableElementsForms"
                        >
                          <td nxTableCell>
                            {{ item.date | date : "d MMM y" }}
                          </td>
                          <td nxTableCell>
                            <div class="container_flex_center">
                              <img
                                src="assets/img/Icon - PDF - Colour.svg"
                                alt=""
                              />
                              &nbsp; &nbsp;
                              <span> {{ item.eDocument_name }}</span>
                            </div>
                          </td>
                          <td nxTableCell>{{ item.eDocument_type }}</td>
                          <td nxTableCell>
                           
                            <div class="container_flex_center">
                              <button
                                nxPlainButton
                                type="button"
                                class="nx-margin-right-m"
                              >
                                DOWNLOAD
                              </button>
                              <button
                                nxPlainButton
                                type="button"
                                class="nx-margin-right-m"
                              >
                                SHARE
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                      </div>
                    </div>
                      <!-- <nx-swipebar label="Swipe to see more">
                        <table
                          nxTable
                          nxSort
                          zebra
                          (sortChange)="sortTableGuides($event)"
                        >
                          <thead>
                            <tr nxTableRow>
                              <th nxHeaderCell nxSortHeaderCell="date">
                                Date
                              </th>
                              <th
                                nxHeaderCell
                                nxSortHeaderCell="eDocument_name"
                              >
                                e-Document name
                              </th>
                              <th
                                nxHeaderCell
                                nxSortHeaderCell="eDocument_type"
                              >
                                e-Dokument type
                              </th>
                              <th nxHeaderCell>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              nxTableRow
                              *ngFor="let item of tableElementsForms"
                            >
                              <td nxTableCell>
                                {{ item.date | date : "d MMM y" }}
                              </td>
                              <td nxTableCell>
                                <div class="container_flex_center">
                                  <img
                                    src="assets/img/Icon - PDF - Colour.svg"
                                    alt=""
                                  />
                                  &nbsp; &nbsp;
                                  <span> {{ item.eDocument_name }}</span>
                                </div>
                              </td>
                              <td nxTableCell>{{ item.eDocument_type }}</td>
                              <td nxTableCell>
                               
                                <div class="container_flex_center">
                                  <button
                                    nxPlainButton
                                    type="button"
                                    class="nx-margin-right-m"
                                  >
                                    DOWNLOAD
                                  </button>
                                  <button
                                    nxPlainButton
                                    type="button"
                                    class="nx-margin-right-m"
                                  >
                                    SHARE
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </nx-swipebar> -->
                      <!-- pagination -->
                      <div
                        nxLayout="grid nopadding"
                        class="nx-margin-top-2xs grid_remove_padding pagination_form"
                      >
                        <div nxRow>
                          <div
                            nxCol="12,12,6"
                            class="nx-hidden-xs nx-hidden-s"
                          >
                            <nx-natural-language-form
                              size="small"
                              class="pagination_form"
                            >
                              <span nxCopytext="normal">Show</span>
                              <nx-word>
                                <nx-dropdown value="20">
                                  <nx-dropdown-item value="5"
                                    >5</nx-dropdown-item
                                  >
                                  <nx-dropdown-item value="10"
                                    >10</nx-dropdown-item
                                  >
                                  <nx-dropdown-item value="20"
                                    >20</nx-dropdown-item
                                  >
                                  <nx-dropdown-item value="30"
                                    >30</nx-dropdown-item
                                  >
                                  <nx-dropdown-item value="40"
                                    >40</nx-dropdown-item
                                  >
                                  <nx-dropdown-item value="50"
                                    >50</nx-dropdown-item
                                  >
                                </nx-dropdown>
                              </nx-word>
                              <span nxCopytext="normal"
                                >results per page</span
                              >
                            </nx-natural-language-form>
                          </div>

                          <div
                            class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                          ></div>
                          <div
                            nxCol="12,12,6"
                            class="container_flex_center align_right"
                          >
                            <nx-pagination
                              [count]="count"
                              [page]="page"
                              [perPage]="perPage"
                              type="advanced"
                              (goPrev)="prevPage()"
                              (goNext)="nextPage()"
                              (goPage)="goToPage($event)"
                              ariaLabel="Advanced example pagination"
                              class="pagination_position"
                            >
                            </nx-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- small view -->
                  <ng-container *ngIf="desktopScreen">
                    <div
                      *ngFor="let item of tableElementsGuides"
                      class="nx-margin-bottom-s nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
                    >
                      <nx-card>
                        <div nxLayout="grid nopadding">
                          <div nxRow>
                            <div nxCol="2">
                              <img
                                src="assets/img/Icon - PDF - Colour.svg"
                                alt=""
                              />
                            </div>
                            <div nxCol="8">
                              <span nxCopytext="small">
                                {{ item.eDocument_name }}</span
                              >
                            </div>
                            <div nxCol="2" style="display: grid; gap: 20px">
                              <nx-icon
                                class="icon-mobile-color"
                                aria-hidden="true"
                                size="auto"
                                outline="true"
                                name="download"
                              ></nx-icon>
                              <nx-icon
                                class="icon-mobile-color"
                                aria-hidden="true"
                                size="auto"
                                outline="true"
                                name="share"
                              ></nx-icon>
                            </div>
                          </div>
                          <div nxRow>
                            <div nxCol="5">
                              <span nxCopytext="small">Date :</span>
                            </div>
                            <div nxCol="7">
                              <span nxCopytext="small">{{
                                item.date | date : "d MMM y"
                              }}</span>
                            </div>
                          </div>
                          <div nxRow>
                            <div nxCol="5">
                              <span nxCopytext="small">e-Document :</span>
                            </div>
                            <div nxCol="7">
                              <span nxCopytext="small">{{
                                item.eDocument_type
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </nx-card>
                    </div>
                  </ng-container>
                </nx-expansion-panel>
                <nx-expansion-panel class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Claim Guides
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>

                  <div>Claim Guides Content</div>
                </nx-expansion-panel>
                <nx-expansion-panel class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Allianz SAT User Guides
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>

                  <div>Allianz SAT User Guides Content</div>
                </nx-expansion-panel>
                <nx-expansion-panel class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Frequently Asked Questions
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>

                  <div>Frequently Asked Questions Content</div>
                </nx-expansion-panel>
                <nx-expansion-panel class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="accordion_edit">
                      Testing Guide
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>

                  <div>testing guide Content</div>
                </nx-expansion-panel>
              </nx-accordion>
            </div>
          </nx-tab>

          <!-- Newsletters -->
          <nx-tab label="Newsletters">
            <p class="tab__content"></p>
          </nx-tab>

          <!-- Annoucement -->
          <nx-tab label="Announcements">
            <p class="tab__content"></p>
          </nx-tab>

          <!-- Videos -->
          <nx-tab label="Videos">
            <p class="tab__content"></p>
          </nx-tab>
        </nx-tab-group>
      </div>
      <div class="search" (click)="toggleData()">
        <button nxPlainButton type="button">
          <nx-icon name="search" nxIconPositionStart aria-hidden="true"></nx-icon
          ><span class="nx-hidden-xs nx-hidden-s">Search</span>
        </button>   
      </div>
    </div>
  </div>
</ng-container>




