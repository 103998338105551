import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-status-message',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './status-message.component.html',
  styleUrl: './status-message.component.scss'
})
export class StatusMessageComponent implements OnInit {
  @Input() data: any;

  ngOnInit() {
    
  }

  get isCoverNote() {
    if(this.data.cnoteNo) {
      return true;
    }else {
      return false;
    }
  }

  get isEquotation() {
    if(!this.data.cnoteNo && this.data.proposalNo && !this.data.draftInd) {
      return true;
    }else {
      return false;
    }
  }

  get isEndorsement() {
    return this.data.endtNo ? true: false;
  }
}
