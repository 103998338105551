@if (isDataLoaded) {
    <div class="title-header">
        <div nxLayout="grid maxwidth">
            <div nxRow>
                <div nxCol="12" *ngIf="!isClaimEnquiry">
                    <h1>Policy Enquiry</h1>
                </div>
                <div nxCol="12" *ngIf="isClaimEnquiry">
                    <h1>Claim Enquiry</h1>
                </div>
            </div>
        </div>
    </div>
    <div nxLayout="grid maxwidth">
        <div nxRow>
    
            <div nxCol="12,12,12,12" class="grid_width_page">
                <div nxLayout="grid nopadding" class="enquiry_status nx-margin-bottom-2m">
                    <div nxRow class="enquiry_status">
                        <div nxCol="12,12,10" *ngIf="!isClaimEnquiry">
                            <h3 nxHeadline size="s" class="nx-font-weight-regular">
                                e-Policy No. :
                                <span class="nx-font-weight-bold">{{proposalNo}}</span>
                            </h3>
                        </div>
                        <div nxCol="12,12,10" *ngIf="isClaimEnquiry">
                            <h3 nxHeadline size="s" class="nx-font-weight-regular">
                                Claim No. :
                                <span class="nx-font-weight-bold">{{claimNo}}</span>
                            </h3>
                        </div>
                        <div nxCol="12,12,2" class="enquiry_status" *ngIf="!isClaimEnquiry">
                            <nx-badge type="active" [class]="classType">{{status}}</nx-badge>
                        </div>
                    </div>
                </div>
    
    
                <!-- //////////////////////////////////////////////////////////////////// -->
                <!-- Agent Information -->
                @if (showAgentInfo && this.agentData) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Agent Information
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <div nxLayout="grid nopadding">
                             <app-agents-information [viewPolicyDetails]=true [agentData]="agentData" [isClaimEnquiry]="isClaimEnquiry">
                            </app-agents-information> 
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                }
    
    
                <!-- //////////////////////////////////////////////////////////////////// -->
                <!-- Issuance Information -->
                @if (showIssuanceInfo && !isClaimEnquiry) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Issuance Information
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
    
                        <div nxLayout="grid nopadding">
                            <app-issuance-information [progressType]="progressType" [issuanceInfo]="issuanceInfo"
                                [viewPolicyDetails]="viewPolicyDetails" [cngeNote]="cngeNote" [productList]="productList">
                            </app-issuance-information>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                }

                 @if (showIssuanceInfo && isClaimEnquiry) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Issuance Information
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>

                        <div nxLayout="grid nopadding">
                            <div nxRow>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                                        <p nxCopytext="large">{{ formatData(issuanceInfo?.vehicleNo) }}</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">e-Policy No.</nx-data-display-label>
                                        <p nxCopytext="large">{{ formatData(issuanceInfo?.policyNo) }}</p>
                                    </nx-data-display>
                                </div>
                            </div>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                }
    
                <!-- //////////////////////////////////////////////////////////////////// -->
                <!-- Customer Partner -->
                @if (showCustomerPartner) {
                <nx-accordion variant="light">
                    <nx-expansion-panel [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Customer Partner
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
    
                        <app-customer-partner-details [viewPolicyDetails]=true [viewOnly]="true" [hideEdit]="true" [cpDetails]="cpData" [lov]="lov">
                        </app-customer-partner-details>
    
                    </nx-expansion-panel>
                </nx-accordion>
                }
    
                <!-- ///////////////////////////////////////////////////////////////////// -->
                <!-- Referral Details -->
                @if (showReferralDetails) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Referral Details
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <table nxTable cellpadding="0" class="summary_table">
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Referral ID No.</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{agentReferId}}</span>
                                </td>
                            </tr>
                        </table>
                    </nx-expansion-panel>
                </nx-accordion>
                }
    
    
                <!-- ////////////////////////////////////////////// -->
                <!-- Coverage -->
                @if (showCoverage) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Coverage
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
    
                        <app-coverage-summary [coverageInfo]="coverageInfo" [responseData]="responseData"
                            [viewPolicyDetails]="viewPolicyDetails" [isRTP]="isRTP">
                        </app-coverage-summary>
    
    
                    </nx-expansion-panel>
                </nx-accordion>
                }
    
    
                <!-- ////////////////////////////////////////////// -->
                <!-- Historical Claim -->
                @if (showHistoricalClaim) { 
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Historical Claim
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <table nxTable cellpadding="0" class="summary_table">
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Client Claim History</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{clientClaimHistory}}</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Vehicle Claim History</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{vehicleClaimHistory}}</span>
                                </td>
                            </tr>
                        </table>
                    </nx-expansion-panel>
                </nx-accordion>
                }
    
                <!-- ////////////////////////////////////////////// -->
                <!-- Premium Information -->
                @if (showPremiumInformation) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Premium Information
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
    
                        <app-premium-information [premiumInfo]="premiumInfo" [isIssuance]="isIssuance" [viewPolicyDetails]=true>
    
                        </app-premium-information>
    
                    </nx-expansion-panel>
                </nx-accordion>
                }

                <!-- ////////////////////////////////////////////// -->
                <!-- Claim Details -->
                @if (isClaimEnquiry) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Claim Details
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <table nxTable cellpadding="0" class="summary_table">
                            <tr nxTableRow  *ngIf="clientPartner?.clientName && responseData?.clientNewNric">
                                <td nxTableCell>
                                    <span nxCopytext="normal">Name</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{ formatData(clientPartner?.clientName)}}</span>
                                </td>
                            </tr>
                            <tr nxTableRow *ngIf="responseData?.clientNewNric">
                                <td nxTableCell>
                                    <span nxCopytext="normal">NRIC</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{ formatData(responseData?.clientNewNric)}}</span>
                                </td>
                            </tr>
                            <tr nxTableRow *ngIf="responseData?.clientOtherId">
                                <td nxTableCell>
                                    <span nxCopytext="normal">Old IC No.</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                     <span nxCopytext="normal">{{ formatData(responseData?.clientOtherId)}}</span> 
                                </td>
                            </tr>
                            <tr nxTableRow *ngIf="responseData?.clientBusinessRegNo">
                                <td nxTableCell>
                                    <span nxCopytext="normal">Business Registration No.</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{ formatData(responseData?.clientBusinessRegNo)}}</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">As At</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{ formatData(getDateFormat(responseData?.asAt)) }}</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Claim Code and Description</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{ formatData(responseData?.claimCode)}} {{ formatData(responseData?.claimDescription)}}</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Claim Lost Date</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{ formatData(getDateFormat(responseData?.lossDate)) }}</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Retain No Claim Discount</span>
                                </td>
                                <td nxTableCell class="column_cp_content">
                                    <span nxCopytext="normal">{{ formatData(retainNcdInd)}}</span>
                                </td>
                            </tr>
                        </table>

                        <div nxLayout="grid nopadding" class="grid_remove_padding nx-margin-top-2m">
                            <div nRow>
                                <div nxCol="12" class="padding_0">
                                    <div>
                                        <app-external-scrollbar targetSyncScroll="12" [targetBaseWidth]="tableHolder12"></app-external-scrollbar>
                                        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder12 syncScroll="12">
                                            <table nxTable nxSort zebra class="enquiry">
                                                <thead>
                                                    <tr nxTableRow>
                                                        <th nxHeaderCell nxHeaderCell>
                                                            Claim Reserve Code
                                                        </th>
                                                        <th nxHeaderCell nxHeaderCell>
                                                            Claim Reserve Type
                                                        </th>
                                                        <th nxHeaderCell nxHeaderCell>
                                                            Claim Reserve Description
                                                        </th>
                                                        <th nxHeaderCell nxHeaderCell>
                                                            Retain No Claim Discount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr nxTableRow *ngFor="let item of htclClaimDet">
                                                        <td nxTableCell>{{ item?.htclClaimDetPk.claimReservCode }}</td>
                                                        <td nxTableCell>{{ item?.htclClaimDetPk.claimReservType }}</td>
                                                        <td nxTableCell>{{ item?.claimReservDescription }}</td>
                                                        <td nxTableCell>{{ formatData(retainNcdInd)}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                }
    
                <!-- ////////////////////////////////////////////// -->
                <!-- Remarks -->
                <!-- @if (showRemarks) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Remarks
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <app-view-remarks [remarksInfo]="remarksInfo" [isPolicyView]=true></app-view-remarks>
                    </nx-expansion-panel> 
                </nx-accordion>
                } -->
    
                <!-- ////////////////////////////////////////////// -->
                <!-- Refer Case (if any) -->
                <!-- @if (showReferCase) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Refer Case (if any)
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <form [formGroup]="referCaseForm">
                            <div nxLayout="grid nopadding" class="grid_remove_padding">
                                <div nxRow>
                                    <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                                        <nx-checkbox formControlName="waiveLoadInd" (change)="saveSummaryStep()">Refer Waive
                                            Load Ind.</nx-checkbox>
                                    </div>
                                    <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                                        <nx-checkbox formControlName="waiveExcessInd" (change)="saveSummaryStep()">Refer
                                            Waive Excess Ind.</nx-checkbox>
                                    </div>
                                    <div nxCol="12,12,4" class="nx-margin-bottom-2m">
                                        <nx-checkbox formControlName="underwritingInd" (change)="saveSummaryStep()">Refer
                                            for Underwriting</nx-checkbox>
                                    </div>
                                </div>
                            </div>
    
                            <nx-formfield label="Referral Remarks">
                                <textarea required nxInput type="text" formControlName="remarks" #inputToCount
                                    maxlength="200" cdkTextareaAutosize (blur)="saveSummaryStep()"></textarea>
                                <span nxFormfieldHint>Max. 200 Characters</span>
                                <nx-error nxFormfieldError>
                                    <strong>Please enter referral remarks</strong>
                                </nx-error>
                            </nx-formfield>
    
                            <nx-error nxFormfieldError class="nx-margin-bottom-2m">
                              <strong>Please enter referral remarks</strong>
                          </nx-error>
                        </form>
                    </nx-expansion-panel>
                </nx-accordion>
                } -->
    
                <!-- OCC Section (to be move to new component) -->
    
                <!-- ////////////////////////////////////////////// -->
                <!-- Payment -->
                <!-- @if (showPayment) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Payment
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <div nxLayout="grid nopadding">
                            <div nxRow>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Receipt No.</nx-data-display-label>
                                        <p nxCopytext="large">OR22AS000002</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Transaction Date</nx-data-display-label>
                                        <p nxCopytext="large">13/12/2023</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">e-Cover Note No.</nx-data-display-label>
                                        <p nxCopytext="large">N/A</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Vehicle No.</nx-data-display-label>
                                        <p nxCopytext="large">N/A</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Account Code</nx-data-display-label>
                                        <p nxCopytext="large">KL12272</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Insured Name</nx-data-display-label>
                                        <p nxCopytext="large">WONG KAM WAI</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Total Premium Due
                                            (RM)</nx-data-display-label>
                                        <p nxCopytext="large">1,540.40</p>
                                    </nx-data-display>
                                </div>
                            </div>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                } -->
                <!-- ////////////////////////////////////////////// -->
                <!-- Road Tax Payable -->
                <!-- @if (showRoadTaxPayable) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Road Tax Payable
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <div nxLayout="grid nopadding">
                            <div nxRow>
                                <div nxCol="12,12,6">
                                    <nx-formfield label="Road Tax (RM)">
                                        <input nxInput />
                                        <nx-error nxFormfieldNote>
                                            <strong>Please enter Road Tax</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,12,6">
                                    <nx-formfield label="MyEG Fees (RM)">
                                        <nx-dropdown>
                                            <nx-dropdown-item value="--"></nx-dropdown-item>
                                            <nx-dropdown-item value="3.00"></nx-dropdown-item>
                                            <nx-dropdown-item value="11.00"></nx-dropdown-item>
                                        </nx-dropdown>
                                        <nx-error nxFormfieldNote>
                                            <strong>Please select MyEG Fees</strong>
                                        </nx-error>
                                    </nx-formfield>
                                </div>
                                <div nxCol="12,12,6" class="nx-margin-bottom-2m">
                                    <p nxCopytext="large" class="nx-margin-bottom-1xs">
                                        Courier Fees (RM)
                                    </p>
                                    <nx-checkbox>6.00</nx-checkbox>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,12,6" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Merchant Charges
                                            (RM)</nx-data-display-label>
                                        <p nxCopytext="large">0.00</p>
                                    </nx-data-display>
                                </div>
                                <div nxCol="12,12,6" class="nx-margin-bottom-2m">
                                    <nx-data-display size="small">
                                        <nx-data-display-label class="font-grey">Road Tax Payable
                                            (RM)</nx-data-display-label>
                                        <p nxCopytext="large">0.00</p>
                                    </nx-data-display>
                                </div>
                            </div>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
                } -->
                <!-- ////////////////////////////////////////////// -->
                <!-- Upsell Product -->
                <!-- @if (showUpsellProduct) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_remove_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Upsell Product
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <app-upsell-product></app-upsell-product>
                    </nx-expansion-panel>
    
                </nx-accordion>
    
                } -->
                <!-- ////////////////////////////////////////////// -->
                <!-- Payment Information -->
                @if (showPaymentInformation) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Payment Information
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
    
                        <table nxTable cellpadding="0" class="payment_summary_table">
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal" class="font-grey">Payment Summary</span>
                                </td>
                                <td nxTableCell class="std-ta-right">
                                    <span nxCopytext="normal" class="font-grey">Total (RM)</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Total Amount Payable</span>
                                </td>
                                <td nxTableCell class="column_cp_content std-ta-right">
                                    <span nxCopytext="normal">1,540.40</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Total Amount Payable (Rounded)</span>
                                </td>
                                <td nxTableCell class="column_cp_content std-ta-right">
                                    <span nxCopytext="normal">1,540.40</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Total Amount Paid by Customer</span>
                                </td>
                                <td nxTableCell class="column_cp_content std-ta-right">
                                    <span nxCopytext="normal">0.00</span>
                                </td>
                            </tr>
                            <tr nxTableRow>
                                <td nxTableCell>
                                    <span nxCopytext="normal">Balance Payable</span>
                                </td>
                                <td nxTableCell class="column_cp_content std-ta-right">
                                    <span nxCopytext="normal">1,540.40</span>
                                </td>
                            </tr>
                        </table>
                    </nx-expansion-panel>
                </nx-accordion>
                }
                <!-- ////////////////////////////////////////// -->
                <!-- Payment Details -->
                @if (showPaymentDetails) {
                <nx-accordion variant="light">
                    <nx-expansion-panel class="accordion_btm_space" [expanded]="true">
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title class="accordion_edit">
                                Payment Details
                            </nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <!-- <app-payment-details></app-payment-details>  -->
                    </nx-expansion-panel>
                </nx-accordion>
    
                }
            </div>
        </div>
    </div>
    <div class="button_area_bg nx-margin-top-50">
        <div nxLayout="grid maxwidth">
            <div nxRow>
                <div nxCol="12">
                    <button nxButton="secondary" type="button" (click)="redirectBack()">Back</button>
                </div>
            </div>
        </div>
    </div>
    }