import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  CONVERT_GEOLOCATION,
  RESET_SELECTED_LOCATION,
  SET_GEOLOCATION_ENABLED_STATUS,
  SET_SELECTED_LOCATION_DETAILS,
} from "./geolocation.action";
import {
  DefaultAddress,
  GeolocationModel,
  IGeocodingConversionFailResponse,
  IGeocodingConversionSuccessResponse,
} from "./geolocation.interface";
import { GeolocationService } from "./geolocation.service";
import { catchError, map } from "rxjs";

const DEFAULT_STATE: GeolocationModel = {
  locations: null,
  selectedLocation: {
    convertedResponse: new DefaultAddress(),
  },
  isGeolocationEnabled: null,
};
@State<GeolocationModel>({
  name: "GeolocationState",
  defaults: DEFAULT_STATE,
})
@Injectable()
export class GeolocationState {
  constructor(private geolocationService: GeolocationService) {}

  @Selector()
  public static selectedLocation(state: GeolocationModel) {
    return state.selectedLocation.convertedResponse;
  }

  @Selector()
  public static isGeolocationEnabled(state: GeolocationModel) {
    return state.isGeolocationEnabled;
  }

  @Action(CONVERT_GEOLOCATION)
  public convertGeolocation(
    { dispatch }: StateContext<GeolocationModel>,
    { payload }: CONVERT_GEOLOCATION
  ) {
    return this.geolocationService.convertGeolocation(payload).pipe(
      map(
        (
          res: IGeocodingConversionSuccessResponse | IGeocodingConversionFailResponse
        ) => {
          if (!res.success) {
            return res;
          }
          dispatch(new SET_SELECTED_LOCATION_DETAILS(res.data));
          return res;
        }
      ),
      catchError((err) => {
        console.log("conversion error", err);
        return null;
      })
    );
  }


  @Action(RESET_SELECTED_LOCATION)
  public resetSelectedLocation({ patchState }: StateContext<GeolocationModel>) {
    patchState({
      selectedLocation: {
        convertedResponse: new DefaultAddress(),
      },
    });
  }

  @Action(SET_GEOLOCATION_ENABLED_STATUS)
  public checkGeolocationEnabled(
    { patchState }: StateContext<GeolocationModel>,
    { payload }: SET_GEOLOCATION_ENABLED_STATUS
  ) {
    return this.geolocationService.checkGeolocationEnabled(payload).pipe(
      map((res) => {
        patchState({ isGeolocationEnabled: res });
      })
    );
  }

  @Action(SET_SELECTED_LOCATION_DETAILS)
  public setSelectedLocationDetails(
    { patchState }: StateContext<GeolocationModel>,
    { payload }: SET_SELECTED_LOCATION_DETAILS
  ) {
    patchState({
      selectedLocation: {
        convertedResponse: {
          ...payload
        }
      },
    });
  }
}
