import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const CustomBreakpointNames = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop'
};

const PREFIX = '--';

@Injectable({
  providedIn: 'root'
})
export class LayoutBreakPointsService {
  activeBreakpoints: string[] = [];
  breakpoints: object = {
    '(max-width: 703px)': CustomBreakpointNames.mobile,
    '(min-width: 704px) and (max-width: 991px)': CustomBreakpointNames.tablet,
    '(min-width: 992px)': CustomBreakpointNames.desktop
  };

  constructor(
    private breakpointObserver: BreakpointObserver) {
    // this.breakpoints['(min-width: 1px) and (max-width: ' + (this.getCSSPropertyValue('tablet') - 1) + 'px)'] = CustomBreakpointNames.mobile;
    // this.breakpoints['(min-width: ' + this.getCSSPropertyValue('tablet') + 'px) and (max-width: ' + (this.getCSSPropertyValue('desktop') - 1) + 'px)'] = CustomBreakpointNames.tablet;
    // this.breakpoints['(min-width: ' + this.getCSSPropertyValue('desktop') + 'px)'] = CustomBreakpointNames.desktop;
  }

  getCSSPropertyValue(propertyName) {
    const bodyStyles = window.getComputedStyle(document.body);
    let breakpoint = bodyStyles.getPropertyValue(`${PREFIX}${propertyName}`);
    return Number(breakpoint.replace('px', ''));
  }

  subscribeLayoutChanges(): Observable<string[]> {
    return this.breakpointObserver
      .observe(Object.keys(this.breakpoints))
      .pipe(map((observeResponse) => this.parseBreakpoints(observeResponse.breakpoints)));
  }

  parseBreakpoints(breakpointsObj): string[] {
    this.activeBreakpoints = [];
    Object.keys(breakpointsObj).map((key) => {
      if (breakpointsObj[key]) {
        this.activeBreakpoints.push(this.breakpoints[key]);
      }
    });
    return this.activeBreakpoints;
  }

  isBreakpointActive(breakpointName) {
    return this.activeBreakpoints.find(breakpoint => breakpoint === breakpointName);
  }

  renderDesktop(specialDisplay?: boolean): boolean {
    if (!specialDisplay) {
      if (this.isBreakpointActive(CustomBreakpointNames.mobile)) {
        return false;
      }
      else {
        return true;
      }

    }
    else {
      if (this.isBreakpointActive(CustomBreakpointNames.mobile) ||
        this.isBreakpointActive(CustomBreakpointNames.tablet)) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  getOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) || userAgent.match(/Macintosh/i)) {
      return 'iOS';
    }
    else if (userAgent.match(/Android/i)) {

      return 'Android';
    }
    else {
      return 'unknown';
    }
  }

  isIE() {
    return /MSIE|Trident/.test(window.navigator.userAgent);
  }
}
