<div class="std-ta-center">
    <div nxModalContent>
        <div class="delete_title">
            e-Quotation Saved
        </div>
        <p nxCopytext="normal" class="nx-margin-bottom-m">
            Your e-Quotation has been saved as a Draft. Here is your Draft Reference No. <strong>{{ draftNo }}</strong>. You may return to this e-Quotation through the Draft Summary Screen.
        </p>
    </div>
    <button nxModalClose nxButton="primary" type="button" class="btn_summary_listing" (click)="onclickOK()">
        BACK TO SUMMARY LISTING
    </button>
</div>