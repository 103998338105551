import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  STORE_ENDORSEMENT,
  UPDATE_ENDORSEMENT_STEP2
} from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { SharedModule } from "../../../shared/shared.module";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { TextFieldModule } from '@angular/cdk/text-field';
import { formatNumber } from "@angular/common";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { Observable } from "rxjs";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { SumInsuredFormatterDirective } from "@shared/directives/sum-insured/sum-insured-formatter.directive";

@Component({
  selector: 'app-vehicle-sum-insured',
  standalone: true,
  imports: [
    SharedModule,
    SumInsuredFormatterDirective,
    NxSmallStageModule,
    TextFieldModule
  ],
  templateUrl: './vehicle-sum-insured.component.html',
  styleUrl: './vehicle-sum-insured.component.scss'
})
export class VehicleSumInsuredComponent {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;
  @Select(SatMotorState.endtCoveragePremium) endtCoveragePremium$: Observable<SatMotorState>;

  @Input() endorsementType: any;
  // @Output() endtCalculatePremium = new EventEmitter<unknown>();

  isPrivateCar: boolean;
  isMotorcycle: boolean;
  isComprehensive: boolean;
  isThirdPartyFireTheft: boolean;
  isThirdParty: boolean;

  issuanceInfo: any;
  vehicleNo: any;
  vehicleSumInsured: any;
  previousSumInsured: any;
  coverCode: any;
  coverDescp: any;
  vehicleClass: any;

  agentDetail: any;

  region: any;
  sumInsuredRange: boolean;
  avSi: any;
  avSiAvailable: boolean;
  underInsuranceApplies: boolean;
  avIndChecked: boolean;
  sumInsuredOriginal: any;

  oldValue = {};

  decimalFmt: any;
  numberFmt: any;

  endtVehiclePolicyData: any;
  endtPreviousVehiclePolicyData: any;

  vehicleSumInsuredForm: FormGroup;

  warningMessageModal?: NxModalRef<SimpleModalComponent>;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();

    this.vehicleSumInsuredForm = this.fb.group({
      sumInsured: ["", [Validators.required]],
      avInd: [false],
    });
  }

  ngOnInit() {

    this.agentDetail = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.issuance.issuance);
    this.vehicleNo = this.issuanceInfo.vehicleNo;

    this.loadVehicleSumInsured();
    // this.loadEndorsementDetailsData();

    this.vehicleSumInsured = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails?.vehicleSumInsured);

    this.endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    this.endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);

    const endtVehiclePolicyData = this.endtVehiclePolicyData
    const endtPreviousVehiclePolicyData = this.endtPreviousVehiclePolicyData

    let riskList = endtVehiclePolicyData?.riskList;
    let coverList = endtVehiclePolicyData?.riskList[0]?.coverList;

    let data = coverList[0];

    this.vehicleClass = riskList[0].vehicleClass; //type of vehicle class, ex: "01" for motorcycle, "02" for private car 
    this.coverCode = data.coverCode; //type of coverage code, ex: "01"
    this.coverDescp = data.coverDescp; //type of coverage description, ex: "COMPREHENSIVE"
    this.previousSumInsured = formatNumber(data.previousSumInsured, 'en-US');
    this.sumInsuredOriginal = this.decimalFmt.transform(data.sumInsured);
    this.region = endtVehiclePolicyData.region;

    if (this.vehicleClass = "01") {
      this.allVehicleClassFalse();
      this.isMotorcycle = true;
    } else if (this.vehicleClass = "02") {
      this.allVehicleClassFalse();
      this.isPrivateCar = true;
    }

    switch (this.coverCode) {
      case '01':
        this.allCoverageTypeFalse();
        this.isComprehensive = true;
        break;
      case '20':
        this.allCoverageTypeFalse();
        this.isThirdParty = true;
        break;
      case '30':
        this.allCoverageTypeFalse();
        this.isThirdPartyFireTheft = true;
        break;
    }

    if (endtVehiclePolicyData && !this.vehicleSumInsured) {
      if (coverList) {

        // console.log("coverList:", coverList);
        // console.log("data:", data);

        this.vehicleSumInsuredForm.patchValue({
          sumInsured: this.decimalFmt.transform(data.sumInsured),
          avInd: data.avInd,
        });
        if (riskList && riskList.length) {
          console.log("riskList:", riskList);
          this.setRecommendedSumInsuredRange(riskList[0]);
        }
      } else {
        console.error("coverList cannot populate vehicleSumInsuredForm");
      }
    } else if (endtVehiclePolicyData && this.vehicleSumInsured) {

      // console.log("this.vehicleSumInsured:", this.vehicleSumInsured);

      this.region = endtVehiclePolicyData.region;
      this.vehicleSumInsuredForm.patchValue({
        sumInsured: this.decimalFmt.transform(this.vehicleSumInsured.sumInsured),
        avInd: this.vehicleSumInsured.avInd,
      });

      if (riskList && riskList.length) {
        console.log("riskList:", riskList);
        this.setRecommendedSumInsuredRange(riskList[0]);
      }
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }

    this.loadPremiumCalculation();

    // if (endtPreviousVehiclePolicyData) {
    //   let previousCoverageData = endtPreviousVehiclePolicyData?.riskList;
    //   let previousCoverList = endtPreviousVehiclePolicyData?.riskList[0]?.coverList;
    //   if (previousCoverList) {
    //     let data = previousCoverList[0];
    //     //this.makeModelSIMin = data.makeModelSiMin;
    //     //this.makeModelSIMax = data.makeModelSiMax;
    //   } else {
    //     console.error("previousCoverList is undefined");
    //   }
    // } else {
    //   console.error("endtPreviousVehiclePolicyData is undefined");
    // }
    ////////////////////////////////////////////////////////
  }

  allVehicleClassFalse() {
    this.isPrivateCar = false;
    this.isMotorcycle = false;
  }

  allCoverageTypeFalse() {
    this.isComprehensive = false;
    this.isThirdPartyFireTheft = false;
    this.isThirdParty = false;
  }

  onChangeForm($event: any) {

    const data = {
      fieldName: $event.target.getAttribute("formControlName"),
      value: $event.target.value,
      oldValue: this.oldValue[$event.target.getAttribute("formControlName")],
    };

    if (data.oldValue !== data.value) {
      this.storeEndorsementDetailsData();
    }
  }

  loadVehicleSumInsured() {
    this.vehicleSumInsuredForm = this.fb.group({
      sumInsured: ["", Validators.required],
      avInd: false,
    });
  }

  loadPremiumCalculation() {
    this.endtCoveragePremium$.subscribe((state) => {
      if (state) {
        const data = state["data"];
        if (data) {
          const cngeNoteDto = data["cngeNoteDto"];

          console.log("loadPremiumCalculation data:", data);

          this.avSi = null;
          this.sumInsuredRange = this.avSiAvailable = this.underInsuranceApplies = this.avIndChecked = false;

          if (cngeNoteDto) {
            this.region = cngeNoteDto.region;

            const riskList = cngeNoteDto["riskList"];

            if (riskList?.length) {
              for (let riskListItem of riskList) {

                const coverList = riskListItem["coverList"];
                if (coverList?.length) {
                  for (let coverListItem of coverList) {

                    const sumInsured = coverListItem["sumInsured"];

                    this.setRecommendedSumInsuredRange(riskListItem);
                    console.log("riskListItem:", riskListItem);

                    if (sumInsured) {
                      const siWarning = this.setSIWarning(sumInsured);
                      console.log("sumInsured:", sumInsured);

                      if (!siWarning.valid)
                        this.showSIWarning(siWarning.value);

                      this.setSumInsuredRange(sumInsured);

                      if ([siWarning.valid].indexOf(true) != -1 && !this.sumInsuredRange)
                        this.setUnderInsuranceApplies(sumInsured);

                      this.setAVIndicator();

                    }
                  }
                }

              }
            }
          }
        }
      } else if (!state) {
        const cngeNoteDto = this.endtVehiclePolicyData

        console.log("loadPremiumCalculation this.endtVehiclePolicyData:", this.endtVehiclePolicyData);

        this.avSi = null;
        this.sumInsuredRange = this.avSiAvailable = this.underInsuranceApplies = this.avIndChecked = false;

        if (cngeNoteDto) {
          this.region = cngeNoteDto.region;

          const riskList = cngeNoteDto["riskList"];

          if (riskList?.length) {
            for (let riskListItem of riskList) {

              const coverList = riskListItem["coverList"];
              if (coverList?.length) {
                for (let coverListItem of coverList) {

                  const sumInsured = coverListItem["sumInsured"];

                  this.setRecommendedSumInsuredRange(riskListItem);
                  console.log("riskListItem:", riskListItem);

                  if (sumInsured) {
                    const siWarning = this.setSIWarning(sumInsured);
                    console.log("sumInsured:", sumInsured);

                    if (!siWarning.valid)
                      this.showSIWarning(siWarning.value);

                    this.setSumInsuredRange(sumInsured);

                    if ([siWarning.valid].indexOf(true) != -1 && !this.sumInsuredRange)
                      this.setUnderInsuranceApplies(sumInsured);

                    this.setAVIndicator();

                  }
                }
              }

            }
          }
        }
      }

    });
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state) {
        if (state.vehicleSumInsured) {
          this.vehicleSumInsuredForm.patchValue(this.decimalFmt.transform(state.vehicleSumInsured));
        }
      }
    });
  }

  setRecommendedSumInsuredRange(riskListItem) {
    this.avSi = {
      E: {
        avSiMin: riskListItem.avEmSiMin, avSiMinDesc: formatNumber(Number(riskListItem.avEmSiMin), 'en-US'),
        avSiMax: riskListItem.avEmSiMax, avSiMaxDesc: formatNumber(Number(riskListItem.avEmSiMax), 'en-US'),
      },
      W: {
        avSiMin: riskListItem.avWmSiMin, avSiMinDesc: formatNumber(Number(riskListItem.avWmSiMin), 'en-US'),
        avSiMax: riskListItem.avWmSiMax, avSiMaxDesc: formatNumber(Number(riskListItem.avWmSiMax), 'en-US'),
      },
    };
    console.log("setRecommendedSumInsuredRange this.avSi:", this.avSi);

    if (this.avSi) {
      this.avSiAvailable = this.avSi[this.region].avSiMin > 0 && this.avSi[this.region].avSiMax > 0;
      console.log("setRecommendedSumInsuredRange this.avSiAvailable:", this.avSiAvailable);
    }
  }

  setSIWarning(sumInsured: any) {
    let value: any;
    if (this.isPrivateCar && sumInsured < 10000) {
      value = "10000";
    }
    if (this.isMotorcycle && sumInsured < 1000) {
      value = "1000";
    }

    if (value) {
      return { valid: false, value: value };
    }
    return { valid: true };
  }

  showSIWarning(value: any) {
    if (value) {
      this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: "Warning", message: "Sum insured must be at least RM" + formatNumber(Number(value), 'en-US') + "." },
      });

      this.warningMessageModal.afterClosed().subscribe((result) => {
        this.sumInsured.setValue(this.decimalFmt.transform(value));
        this.storeEndorsementDetailsData();
      });
    }
  }

  setSumInsuredRange(sumInsured) {
    return this.sumInsuredRange = (this.avSiAvailable) &&
      ((sumInsured >= this.avSi[this.region].avSiMin) && (sumInsured <= this.avSi[this.region].avSiMax));
  }

  setUnderInsuranceApplies(sumInsured) {
    return this.underInsuranceApplies = sumInsured < ((90 / 100) * this.avSi[this.region].avSiMin)
  }

  setAVIndicator() {

    this.avIndChecked = false;
    this.avInd.enable();

    this.avIndChecked = this.avSi[this.region] && this.sumInsuredRange;
    this.avInd.patchValue(this.avIndChecked);

    if (this.isMotorcycle || !this.avSiAvailable || !this.sumInsuredRange) {
      this.avInd.disable();
    }
  }

  validateChangedFields() {
    let sumInsuredOriginal = this.sumInsuredOriginal;
    let sumInsured = this.normalizeValue(this.vehicleSumInsuredForm.get('sumInsured')?.value);
    let hasChanged = false;

    if (sumInsuredOriginal === undefined) {
      console.error(`Original value for sumInsuredOriginal is undefined`);
    }

    sumInsuredOriginal = this.convertToComparableType(sumInsuredOriginal, sumInsured);
    sumInsured = this.convertToComparableType(sumInsured, sumInsuredOriginal);

    if (sumInsuredOriginal !== sumInsured) {
      hasChanged = true;
    } else {
      hasChanged = false;
    }
    console.log("Final hasChanged result:", hasChanged);
    return hasChanged;
  }

  normalizeValue(value: any) {
    if (typeof value === 'string') {
      return value.trim().toLowerCase();
    }
    return value;
  }

  convertToComparableType(value: any, referenceValue: any) {
    if (typeof referenceValue === 'number' && !isNaN(Number(value))) {
      return Number(value);
    }
    if (typeof referenceValue === 'string') {
      return String(value);
    }
    return value;
  }

  /////////////////////////////////////////////////////
  get sumInsured() {
    return this.vehicleSumInsuredForm.controls["sumInsured"];
  }

  get avInd() {
    return this.vehicleSumInsuredForm.controls["avInd"];
  }
  ///////////////////////////////////////////////////

  storeEndorsementDetailsData() {
    if (this.vehicleSumInsuredForm.valid) {
      let vehicleSumInsured = this.vehicleSumInsuredForm.getRawValue();
      console.log('Saving Vehicle Sum Insured Data:', vehicleSumInsured);

      let payload = {
        vehicleSumInsured: { ...vehicleSumInsured, sumInsured: this.numberFmt.transform(vehicleSumInsured.sumInsured) },
        isVehicleSumInsured: true
      }
      console.log('Saving Vehicle Sum Insured Data:', payload);
      this.store.dispatch(new STORE_ENDORSEMENT(payload));

      let endtUpdatedData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step2?.endtUpdatedData);
      if (!endtUpdatedData) {
        endtUpdatedData = this.endtVehiclePolicyData
      }

      //need to test the endtUpdatedData to include the vehicle sum insured
      endtUpdatedData = {
        ...endtUpdatedData,
        riskList: [{
          ...endtUpdatedData.riskList[0],
          coverList: [{
            ...endtUpdatedData.riskList[0].coverList[0],
            sumInsured: payload.vehicleSumInsured.sumInsured,
            avInd: payload.vehicleSumInsured.avInd,
          }]
        }]
      };
      this.store.dispatch(new UPDATE_ENDORSEMENT_STEP2({
        isReset: false,
        cngeNote: endtUpdatedData
      }));
      // this.endtCalculatePremium.emit();

    } else {
      console.error('Vehicle Sum Insured form is invalid!');
      // this.logFormErrors(this.vehicleSumInsuredForm);
      this.vehicleSumInsuredForm.markAllAsTouched();
    }
  }

}
