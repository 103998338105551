import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { NxMultiStepperDirection } from "@aposin/ng-aquila/progress-stepper";
import { SharedModule } from "@shared/shared.module";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";

@Component({
  selector: "app-progress-bar",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./progress-bar.component.html",
  styleUrl: "./progress-bar.component.scss",
})
export class ProgressBarComponent {
  direction: NxMultiStepperDirection = "vertical";
  @Select(SatMotorState.progressStep) progressStep$;
  progressValue: number;
  @Input() progressType!: string;


  unification = [
    {
      name: "Agent Additional Info",
      completed: false,
    },
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Customer Partner",
      completed: false,
    },
    {
      name: "Coverage",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  endorsement = [
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Endorsement Details",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  cn_quotation = [
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Customer Partner",
      completed: false,
    },
    {
      name: "Coverage",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  rtp = [
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  activeMultiStep: any;

  constructor(
    private cdr: ChangeDetectorRef,
    public store: Store,
  ) { }

  ngAfterViewInit() {
    if (this.progressType == "ISSUE") {
      this.activeMultiStep = this.cn_quotation;
    } else if (this.progressType == "ENDT") {
      this.activeMultiStep = this.endorsement;
    } else if (this.progressType == "RTP") {
      this.activeMultiStep = this.rtp;
    } else if (this.progressType == "unification") {
      this.activeMultiStep = this.unification;
    }


    this.progressStep$.subscribe((state) => {
      if (state) {
        this.progressValue = state;
      }

      const progressValueindex = this.progressValue - 1;

      for (let i = 0; i < this.activeMultiStep.length; i++) {
        if (i <= progressValueindex) {
          this.activeMultiStep[i].completed = true;
        } else {
          this.activeMultiStep[i].completed = false;
        }
      }
    });

    this.cdr.detectChanges();
  }
}
