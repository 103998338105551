import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { of } from "rxjs";
import { map } from "rxjs/operators";
import { STEP2 } from "src/app/interfaces/motor";

import {
  CHECK_SINGLE_AGENT,
  EDIT_CUSTOMER_PARTNER,
  GET_PRODUCT_CONFIG_LOV,
  POLICY_MOVEMENT_DATA,
  GET_PRODUCT_LIST,
  GET_AGENT_DETAIL,
  GET_AGENT_DETAIL_ONLY,
  GET_AGENT_LIST_AGENT,
  GET_AGENT_LIST_BRANCH,
  GET_BRANCH_LIST,
  GET_CUSTOMER_PARTNER,
  GET_DOCUMENT_LOV,
  GET_LOV,
  GET_NCD,
  GET_REDIRECT_PAGE,
  INIT_STEP3,
  STORE_STEP3,
  SEARCH_MAKE_MODEL,
  SELECTED_MAKE_MODEL,
  SELECTED_ENDORSEMENT_MAKE_MODEL,
  SHOW_SEARCH_COMPONENT_2,
  STORE_AGENT_CODE,
  STORE_DECLARATION,
  STORE_AGENT_ADDITIONAL,
  STORE_ISSUANCE_SUMMARY,
  STORE_ENDORSEMENT,
  UPDATE_ENDORSEMENT_STEP2,
  STORE_STEP2,
  STORE_STEP2_CP_SCREEN,
  STORE_STEP2_SEARCH,
  VALIDATE_CP,
  STORE_COVERAGE_TYPE,
  STORE_COVERAGE_VEHICLE_DETAILS,
  STORE_EXTRA_COVER_DETAILS,
  CALCULATE_PREMIUM,
  CALCULATE_ENDT_PREMIUM,
  CALCULATE_PREMIUM_SUMMARY,
  ADD_NEW_COVERAGE,
  GET_CUSTOMER_PARTNER_MOCK,
  GENERATE_QUOTATION,
  SEND_TO_JPJ,
  AMEND_OPENDATE,
  GENERATE_ENDORSEMENT,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  SEARCH_ACTIVE_QUOTATION,
  ENQUIRE_ENDT_NARRATION,
  SEARCH_ENDT_VEHICLE,
  ROADTAX_SEARCH,
  SEARCH_VIX,
  STORE_SHOWED_ONE_ALLIANZ_POP_UP,
  SET_CUSTOMER_PARTNER_SEARCH_TYPE,
  UPDATE_PAYMENT_LIST,
  SAVE_REFERRAL_REMARKS,
  DOWNLOAD_PDF,
  GET_PREVIOUS_POLICY_NO,
  GET_VEHICLE_USE,
  REQUEST_CANCELLATION,
  CANCEL_REPLACE,
  DELETE_COVERAGE_FLAG,
  DELETE_COVERAGE,
  SET_JPJ_STATUS_SENT,
  SET_ACTION,
  CONTINUE_ISSUANCE,
  ISSUE_NEW,
  CALCULATE_PREMIUM_PAYLOAD,
  SAVE_AS_DRAFT,
  SAVE_CUSTOMER_PARTNER,
  GET_UPSELL_PRODUCT_LOV,
  STORE_UPSELL_PRODUCT,
  GET_PAYMENT_MODE_LOV,
  GET_PAYMENT_TO_LOV,
  GET_POSTCODE_LOV,
  GET_POSTCODE_INFO,
  RESET_AGENT_LIST,
  GET_CROSS_SELL_LIST,
  COPY_CNGE_INFO,
  STORE_AGENT,
  GET_ISS_PRODUCT_PDF,
  SAVE_ISS_MESSAGE_SENT_LOG,
  SAVE_ISS_EMAIL_SENT_LOG,
  GET_CCENTRIC_OPPORTUNITY_INFO,
  GET_SWITCH_SITE,
  SET_CURRENT_STEP,
  GET_DRAFT_DETAILS,
  GET_QUOTATION_DETAIL,
  GET_ISS_CONTENT_ID,
  DELETE_EXTRA_COVER_DETAILS,
  GET_NCD_CONFIRMATION,
  COPY_CNGE_INFO_RENEWAL,
  STORE_STEP2_RENEWAL,
  STORE_STEP3_RENEWAL,
  RESET_MOTOR_STATE,
  RESET_PREVIOS_POLICY_DETAILS,
  COPY_CNGE_INFO_VIEW_QUOTATION,
  PRODUCT_VALIDATION,
  COPY_DRAFT_INFO,
  COPY_REQUOTE_INFO,
  SEARCH_ENDORSEMENT_MAKE_MODEL,
  GET_COVER_LIST_LOV,
  STORE_EXTRA_COVER_PACKAGE,
  STORE_STEP3_DRAFT_NO,
  STORE_STEP2_DRAFT_NO,
  UPDATE_COVERAGE_TYPE,
  RESET_SAT_MOTOR_STATE,
  CANCEL_QUOTATION,
  CANCEL_COVER_NOTE,
  CANCEL_DRAFT,
  STORE_COMPLETE_DATA,
  GET_VIEW_POLICY,
  GET_QUOTATION_DETAIL_REQUOTE,
  VALIDATE_POI_DATE,
  REDIRECT_TO_MIGBASE_MOTOR,
  GET_VIEW_CLAIM,
  UPDATE_ISM_POLICY,
  GET_NCD_ENQUIRY,
  RESET_SUBSEQUENT_PAGE_DATA,
  SEARCH_POLICY_ENQUIRY,
  EMAIL_DOCUMENT,
  GET_EMAIL_LISTING, 
  STORE_PREMIUM_CALCULATION_PAYLOAD,
  GET_DOCUMENT_TYPE_LOV,
  GET_REASON_CODE_LOV,
  GET_VEHICLE_CLASS_USE_LOV,
  GET_CHECKLIST_LOV,
  ENDORSEMENT_TYPE_VALIDATION,
  SET_RETRIEVE_QUOTATION,
  GET_QUOTATION_DETAIL_PAYLOAD,
  GENERATE_ENDORSEMENT_QUOTATION,
  GET_ACTION_URL,
  SAVE_ENDT_CUSTOMER_PARTNER,
  SET_OPPORTUNITY_ID,
  SET_ENDT_CUSTOMER_PARTNER_SEARCH_TYPE,
  STORE_ROAD_TAX_PAYABLE,
  SET_UPDATE_PAYMENT_IND,
  SAVE_PAYMENT,
  STORE_PAYMENT_INFO,
  ENDT_CALCULATE_PREMIUM_SUMMARY,
  COPY_CNGE_TO_ENDORSEMENT_STATE,
  IS_CANCEL_AT_COMPLETE,
  STORE_NCD_INFO,
  UPDATE_COVERAGE_ALERT,
  RESET_CUSTOMER_PARTNER_DATA,
  SET_PRODUCT_CODE_DESCRIPTION,
  VALIDATE_BLACKLIST,
  SME_DUPLICATION_CHECKING,
  SET_EINVOICE_CONSENT_IND,
  IS_CANCEL_REPLACE_COVER_NOTE,
  RESEND_JPJ,
  ACTIVE_SELECTION_POPUP,
  STORE_RENEWAL_ASSISTANCE
} from "./sat-motor.action";
import { SatMotorService } from "./sat-motor.service";
import { HttpHelperService } from "@shared/services/http-helper/http-helper.service";
import { defaultSatMotorState } from "./sat-motor-util";
import { SatMotorModel } from "./sat-motor.model";
import moment from "moment";

@Injectable()
@State<SatMotorModel>({
  name: "SatMotorState",
  defaults: defaultSatMotorState
})
export class SatMotorState {
  constructor(
    private satMotorService: SatMotorService,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private httpHelperService: HttpHelperService
  ) { }
  geoCodedInd: boolean;

  @Selector()
  public static motorActionType(state: SatMotorModel) {
    return {
      isIssuance: state.actionType === 'ISSUE',
      isEndorsement: state.actionType === 'ENDT',
      isEnquiry: state.actionType === 'ENQUIRY',
      isRTP: state.actionType === 'RTP'
    };
  }

  @Selector()
  public static progressStep(state: SatMotorModel) {
    return state.currentStep ?? 1;
  }

  @Selector()
  public static commonLov(state: SatMotorModel) {
    return state.lov ?? null;
  }

  @Selector()
  public static motorAgentList(state: SatMotorModel) {
    return state.data?.step1?.agentList ?? null;
  }

  @Selector()
  public static motorBranchList(state: SatMotorModel) {
    return state.data?.branchList ?? null;
  }

  @Selector()
  public static motorStep1Issuance(state: SatMotorModel) {
    return state.motor?.step1?.issuance ?? null;
  }

  @Selector()
  public static productName(state: SatMotorModel) {
    return state.motor.step1?.issuance?.issuance?.productCode;
  }

  @Selector()
  public static endtVehiclePolicy(state: SatMotorModel) {
    return state.endorsement?.step1?.endtVehicleData ?? null;
  }

  @Selector()
  public static endtPreviousVehiclePolicy(state: SatMotorModel) {
    return state.endorsement?.step1?.endtPreviousVehicleData ?? null;
  }

  @Selector()
  public static endtStep1Issuance(state: SatMotorModel) {
    return state.endorsement?.step1?.issuance ?? null;
  }
  ///////////////////////////////////////////////////for endorsement-details
  @Selector()
  public static endtStep2EndorsementDetails(state: SatMotorModel) {
    return state.endorsement?.step2?.endorsementDetails ?? null;
  }

  @Selector()
  public static endorsementStep3(state: SatMotorModel) {
    return state.endorsement?.step3 ?? null;
  }

  @Selector()
  public static endtStep2VehicleDetails(state: SatMotorModel) {
    return state.endorsement?.step2?.endorsementDetails?.vehicleDetails ?? null;
  }
  //////////////////////////////////////////////////////////////
  @Selector()
  public static motorAgentAdditionalDetail(state: SatMotorModel) {
    return state.motor?.step1?.agentAdditional ?? null;
  }

  @Selector()
  public static motorAgentDetail(state: SatMotorModel) {
    return state.motor?.step1?.agentDetail ?? null;
  }

  @Selector()
  public static motorStep3Coverage(state: SatMotorModel) {
    return state.motor?.step3 ?? null;
  }

  @Selector()
  public static motorStep3Endorsement(state: SatMotorModel) {
    return state.motor?.step3 ?? null;
  }

  @Selector()
  public static motorStep4Summary(state: SatMotorModel) {
    return state.motor?.step4 ?? null;
  }

  @Selector()
  public static rtpStep2Summary(state: SatMotorModel) {
    return state.rtp?.step2 ?? null;
  }

  @Selector()
  public static isRenewNow(state: SatMotorModel) {
    let renewNowObj = state.data.isRenewNow;
    return renewNowObj.isRenewNow ? renewNowObj.isRenewNow : false;
  }

  @Selector()
  public static dataSearch2(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.search : null;
  }

  @Selector()
  public static dataStore2(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.store : null;
  }

  @Selector()
  public static showSearchComponent(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.showSearchComponent : false;
  }

  @Selector()
  public static cpType(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2.cpType : null;
  }

  @Selector()
  public static coverageShowAlert(state: SatMotorModel) {
    return state.data.step3.coverage.coverageShowDialog ? state.data.step3.coverage.coverageShowDialog : null;
  }

  @Selector()
  public static endtCpType(state: SatMotorModel) {
    return state.endorsement.step2.cpType ? state.endorsement.step2.cpType : null;
  }
  
  @Selector()
  public static coverage(state: SatMotorModel) {
    return state.motor.step3.coverage ? state.motor.step3.coverage : null;
  }

  @Selector()
  public static currentTypeOfCoverage(state: SatMotorModel) {
    return state.motor.step3.coverage.coverageType ? state.motor.step3.coverage.coverageType : null;
  }

  @Selector()
  public static coveragePremium(state: SatMotorModel) {
    return state.motor.step3.coverage.coveragePremium ? state.motor.step3.coverage.coveragePremium : null;
  }
  
  @Selector()
  public static premiumCalculationSummary(state: SatMotorModel) {
    return state.premiumCalculation ? state.premiumCalculation : null;
  }

  @Selector()
  public static endtCoveragePremium(state: SatMotorModel) {
    return state.endorsement.step2.endorsementDetails.coveragePremium ? state.endorsement.step2.endorsementDetails.coveragePremium : null;
  }

  @Selector()
  public static summaryPremium(state: SatMotorModel) {
    return state.summaryPremium? state.summaryPremium : null;
  }

  @Selector()
  public static endtSummaryPremium(state: SatMotorModel) {
    return state.endtSummaryPremium? state.endtSummaryPremium : null;
  }

  @Selector()
  public static customerPartner(state: SatMotorModel) {
    return state.data.step2 ? state.data.step2 : null;
  }

  @Selector()
  public static paymentList(state: SatMotorModel) {
    return state.data.step4 ? state.data.step4.paymentList : null;
  }

  @Selector()
  public static jpjStatusSent(state: SatMotorModel) {
    return state.jpjStatusSent;
  }

  @Selector()
  public static getEndorsementStep1(state: SatMotorModel) {
    return state.endorsement?.step1 ?? null;
  }

  @Selector()
  public static endorsementUpdatedData(state: SatMotorModel) {
    return state.endorsement?.step2?.endtUpdatedData ?? null;
  }

  @Selector()
  public static coverageDefaultDriver(state: SatMotorModel) {
    return state.motor.step3.coverage.coveragePremium?.cngeNote?.riskList[0]?.cngeRiskDrvrDtoList[0];
  }

  @Selector()
  public static viewQuotationSummary(state: SatMotorModel) {
    return state.viewQuotation ? state.viewQuotation : null;
  }

  @Selector()
  public static isCancelAtComplete(state: SatMotorModel) {
    return state.isCancelAtComplete;
  }

  @Selector()
  public static eInvoiceConsentInd(state: SatMotorModel) {
    return state.motor.step1?.issuance?.pdpa?.consentDetails?.einvoiceConsentInd;
  }

  @Selector()
  public static selectedProductCodeDesc(state: SatMotorModel) {
    if (state.productCodeDesc && state.productCodeDesc !== '') {
      return state.productCodeDesc;
    }

    if (state.lov && state.lov.productList) {
      const productCode = this.getProductCode(state);

      if (productCode) {
        const product = state.lov.productList.find(item => item.code === productCode);
        if (product && product.descp) {
          return product.descp;
        }
      }
      return 'Motor';
    }
    return 'Motor';
  }

  private static getProductCode(state: SatMotorModel): string | null {
    if (state.motor?.step1?.issuance?.issuance?.productCode) {
      return state.motor.step1.issuance.issuance.productCode;
    }

    if (state.endorsement?.step1?.endtVehicleData?.productCode) {
      return state.endorsement.step1.endtVehicleData.productCode;
    }

    if (state.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto?.productCode) {
      return state.rtp.step1.rtpPolicy.data.cngeNoteDto.productCode;
    }

    if (state.motor?.step1?.activeQuotation?.cngeNote?.productCode) {
      return state.motor.step1.activeQuotation.cngeNote.productCode;
    }

    if (state.previousPolicyDetails?.productCode) {
      return state.previousPolicyDetails.productCode;
    }

    return null;
  }

  @Action(RESET_SAT_MOTOR_STATE)
  public resetState(state: StateContext<SatMotorModel>) {
    state.patchState({ ...defaultSatMotorState });
  }

  @Action(SET_CURRENT_STEP)
  public setCurrentStep({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      currentStep: payload,
    });
  }

  @Action(STORE_AGENT_CODE)
  public setAgentInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      agentInfo: payload,
    });
  }

  @Action(SET_ACTION)
  public setAction({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      actionType: payload,
    });
  }

  @Action(SET_OPPORTUNITY_ID)
  public setOpportunityId({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      opportunityId: payload,
    });
  }

  @Action(SET_PRODUCT_CODE_DESCRIPTION)
  public setProductCodeDescription({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    patchState({
      productCodeDesc: payload.descp,
    });
  }

  @Action(STORE_DECLARATION)
  public setDeclarationInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();


    if (payload.isRTP) {
      const step1 = state.rtp?.step1 || {};
      patchState({
        rtp: {
          ...state.rtp,
          step1: {
            ...step1,
            issuance: payload,
          },
        },
      });
    } else if (payload.isEndorsement) {
      const step1 = state.endorsement?.step1 || {};
      patchState({
        endorsement: {
          ...state.endorsement,
          step1: {
            ...step1,
            issuance: payload,
          },
        },
      });
    } else {
      const step1 = state.motor?.step1 || {};
      patchState({
        motor: {
          ...state.motor,
          step1: {
            ...step1,
            issuance: payload,
          },
        },
      });
    }

  }

  @Action(STORE_AGENT_ADDITIONAL)
  public setAgentAdditionalInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();
    const step1 = state.motor?.step1 || {};

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...step1,
          agentAdditional: payload,
        },
      },
    });
  }

  @Action(STORE_ISSUANCE_SUMMARY)
  public setIssuanceSummary({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step4: {
          ...(state.motor?.step4 || {}),
          summary: payload,
        },
      },
    });
  }

  @Action(UPDATE_ENDORSEMENT_STEP2)
  public updateEndorsementData({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();
    const step2 = state.endorsement?.step2 || {};
    const endtUpdatedData = payload.isReset
      ? state.endorsement?.step1?.endtVehicleData
      : payload.cngeNote;

    patchState({
      endorsement: {
        ...state.endorsement,
        step2: {
          ...step2,
          endtUpdatedData: endtUpdatedData
        },
      },
    });
    console.log("UPDATE_ENDORSEMENT_STEP2 endtUpdatedData:", endtUpdatedData);
  }

  ///////////////////////////////////////////////////////
  @Action(STORE_ENDORSEMENT)
  public setEndorsementDetails({ patchState, getState }: StateContext<SatMotorModel>, { payload }: any) {
    const state = getState();
    const step2 = state.endorsement?.step2 || {};
    const endorsementDetails = { ...step2.endorsementDetails };

    if (payload.isVehicleDetails) {
      endorsementDetails.vehicleDetails = payload.vehicleDetails;
    };

    if (payload.isExtraCover) {
      endorsementDetails.extraCover = payload.extraCover;
    };

    if (payload.isVehicleSumInsured) {
      endorsementDetails.vehicleSumInsured = payload.vehicleSumInsured;
    };

    if (payload.isNCD) {
      endorsementDetails.ncd = payload.ncd;
    };

    if (payload.isNamedDriver) {
      endorsementDetails.namedDriver = payload.namedDriver;
    };

    if (payload.isEInvoiceCustomerPartner) {
      endorsementDetails.eInvoiceCustomerPartner = payload.eInvoiceCustomerPartner;
    };

    patchState({
      endorsement: {
        ...state.endorsement,
        step2: {
          ...step2,
          endorsementDetails
        }
      }
    });
  }
  ///////////////////////////////////////////////

  @Selector()
  public static getOptionalDocumentList(state: SatMotorModel) {
    return state.lov?.documentList.optionalDocumentList ?? null;
  }

  @Action(GET_DOCUMENT_LOV)
  public getDocumentLov({ getState, patchState }: StateContext<SatMotorModel>, { payload }: GET_DOCUMENT_LOV) {
    const state = getState();
    return this.satMotorService.getDocumentLov(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            lov: {
              ...state.lov,
              documentList: {
                mandatoryDocumentList: res.data && res.data.mandatoryDocumentList ? res.data.mandatoryDocumentList : [],
                // optionalDocumentList: res.data && res.data.optionalDocumentList ? res.data.optionalDocumentList : [],
                optionalDocumentList: res.data && res.data.optionalViewDocumentHashMap ? res.data.optionalViewDocumentHashMap : [],
                fileTypeList: res.data && res.data.fileTypeList ? res.data.fileTypeList : [],
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_AGENT_LIST_AGENT)
  public getAgentListAgent({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_AGENT_LIST_AGENT) {
    const state = getState();
    const checkSingleAgent = (list) => {
      if (list && list.length === 1) {
        dispatch(new CHECK_SINGLE_AGENT(true));
      } else {
        dispatch(new CHECK_SINGLE_AGENT(false));
      }
    };

    return this.satMotorService.fetchAgentListAgent(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              step1: {
                ...(state.data?.step1 || {}),
                ...res.data,
              },
            },
          });
          checkSingleAgent(res.data.agentList);
        }
        // else {
        //   this.httpHelperService.getDirectUrl(`/assets/data-mock/agent-list-mock.json`).subscribe((mockRes) => {
        //     patchState({
        //         data: {
        //             ...state.data,
        //             step1: mockRes.data,
        //         },
        //     });
        //     checkSingleAgent(mockRes.data.agentList);
        //   });
        // }
      }),
    );
  }

  @Action(CHECK_SINGLE_AGENT)
  public checkSingleAgent({ patchState, getState }: StateContext<SatMotorModel>, { payload }: CHECK_SINGLE_AGENT) {
    patchState({
      isSingleAgent: payload,
    });
  }

  @Action(GET_AGENT_DETAIL)
  public getAgentDetail({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_AGENT_DETAIL) {
    const state = getState();
    return this.satMotorService.getAgentDetail(payload).pipe(
      map((res) => {
        if (res && res.data) {
          if (res.data.bancaAgent) {
            const state = getState();
            patchState({
              data: {
                ...state.data,
                agentDetail: res.data,
              },
            });
          }

          if (!res.data.bancaAgent) {
            const data = {
              name: res.data.name,
              branchCode: res.data.branchCode,
              agentCode: res.data.code,
              code: res.data.code,
              channel: res.data.channel,
              subChannel: res.data.subChannel,
              email: res.data.agentEmail,
              mobCode: res.data.agentMobileCode1,
              mobNo: res.data.agentMobileNo1,
            };
            /*
            dispatch(new STORE_STEP1(data));
            if (state.data.headerEntryPoint === 'NW') {
                dispatch(new CHANGE_CURRENT_STEP(1))
                this.ngZone.run(() => this.router.navigate(["/new-equote/customer-partner"]));
            } else if (state.data.headerEntryPoint === 'RN') {
                dispatch(new CHANGE_CURRENT_STEP(1))
                this.ngZone.run(() => this.router.navigate(["/new-equote/search-policy"]));
            } else {
                dispatch(new CHANGE_CURRENT_STEP(1))
                this.ngZone.run(() => this.router.navigate(["/new-equote/customer-partner"]));
            }
            */
          }
        }
      }),
    );
  }

  @Action(STORE_STEP2_SEARCH)
  public storeStep2Search({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_SEARCH) {
    const state = getState();
    console.warn("state: ", state.data);
    return of(payload).pipe(
      map((step) => {
        patchState({
          data: {
            ...state.data,
            step2: {
              ...state.data.step2,
              search: payload,
            },
          },
        });
      }),
    );
  }

  @Action(SHOW_SEARCH_COMPONENT_2)
  public setStatusSearch({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SHOW_SEARCH_COMPONENT_2) {
    const state = getState();
    console.warn(payload);
    patchState({
      data: {
        ...state.data,
        step2: {
          ...(state.data?.step2 ? state.data.step2 : null),
          showSearchComponent: payload,
        },
      },
    });
  }

  @Action(EDIT_CUSTOMER_PARTNER)
  public editCustomerPartner({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: EDIT_CUSTOMER_PARTNER) {
    const state = getState();
    return this.satMotorService.saveCustomerPartner(payload).pipe(
      map((res) => {
        if (res.data) {
          const state = getState();
          dispatch(new STORE_STEP2(res.data));
          patchState({
            isCPUpdated: true,
          });
        } else {
          patchState({
            isCPUpdated: false,
          });
        }
      }),
    );
  }

  @Action(GET_AGENT_DETAIL_ONLY)
  public getAgentDetailOnly({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_AGENT_DETAIL) {
    const state = getState();
    return this.satMotorService.getAgentDetail(payload).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          const step1 = state.motor?.step1 || {};
          const customerType = payload.customerType ?? null;
          //const branchCode = payload.branchCode ?? null; //remove it because OCC user branchCode become empty
          const branchCode = res.data.branchCode ?? null; 
          const branchAgentList = payload.branchAgentList ?? null;

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                agentDetail: (customerType || branchCode) ? { 
                  ...res.data, 
                  customerType, 
                  branchCode, 
                  branchAgentList 
                } : res.data,
              },
            },
          });
        }
        // else {
        //   this.httpHelperService.getDirectUrl(`/assets/data-mock/agent-detail-mock.json`).subscribe((mockRes) => {
        //     const state = getState()
        //     const step1 = state.motor?.step1 || {};
        //
        //     patchState({
        //       motor: {
        //         ...state.motor,
        //         step1: {
        //           ...step1,
        //           agentDetail: mockRes.data,
        //         },
        //       },
        //     });
        //   });
        // }
      }),
    );
  }

  @Action(RESET_AGENT_LIST)
  public resetAgentList({ patchState, getState }: StateContext<SatMotorModel>): void {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step1: {
          ...state.data?.step1,
          agentList: [],
        },
      },
    });
  }

  @Action(GET_BRANCH_LIST)
  public getBranchList({ patchState, getState }: StateContext<SatMotorModel>, { userId }: GET_BRANCH_LIST) {
    return this.satMotorService.getBranchList(userId).pipe(
      map((res) => {
        const state = getState();
        patchState({
          data: {
            ...state.data,
            branchList: res.data.userBranchList,
            step1: {
              agentList: [],
            },
          },
        });
      }),
    );
  }

  @Action(GET_AGENT_LIST_BRANCH)
  public getAgentListByBranch({ patchState, getState }: StateContext<SatMotorModel>, { operator, branchCode }: GET_AGENT_LIST_BRANCH) {
    return this.satMotorService.getAgentListByBranch(operator, branchCode).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          patchState({
            data: {
              ...state.data,
              step1: res.data,
            },
          });
        }
      }),
    );
  }

  @Action(GET_REDIRECT_PAGE)
  public getRedirectPage({ patchState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_REDIRECT_PAGE) {
    return this.satMotorService.getRedirectPage(payload).pipe(
      map((res) => {
        if (res) {
          console.log("redirect page res -->", res);
          window.open(res.url, "_self");
        }
      }),
    );
  }

  @Action(GET_ACTION_URL)
  public getaCTIONUrl({ patchState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_ACTION_URL) {
    return this.satMotorService.getDirectUrlFromSubMenu(payload).pipe(
      map((res) => {
        if (res.redirectUrl) {
          window.location.href = res.redirectUrl;
        }
      }),
    );
  }

  @Action(GET_NCD)
  public getNCD({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_NCD) {
    const state = getState();

    return this.satMotorService.getNCD(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            data: {
              ...state.data,
              step3: {
                ...state.data.step3,
                coverage: {
                  ...state.data.step3.coverage,
                  coveragePremium: res.cngeNote,
                  coverageNCD: res,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_CUSTOMER_PARTNER)
  public getCustomerDetail({ patchState, dispatch, getState }: StateContext<SatMotorModel>, { payload }: GET_CUSTOMER_PARTNER) {
    const state = getState();

    return this.satMotorService.getCustomerDetail(payload).pipe(
      map((res) => {
        if (res.data) {
          dispatch(new STORE_STEP2(res.data));
        } else {
          dispatch(new STORE_STEP2(null));
        }
      }),
    );
  }

  @Action(STORE_STEP2)
  public storeStep2({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2) {
    console.warn("store step 2 payload", payload);
    const state = getState();

    if (payload) {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...(state.data.step2 ? state.data.step2 : null),
            store: {
              ...payload,
              clientPartnerAddress: {
                ...(state.data.step2.store?.clientPartnerAddress ? state.data.step2.store.clientPartnerAddress : {}),
                ...payload.clientPartnerAddress,
              },
            },
          },
        },
        motor: {
          ...state.motor,
          step2: {
            ...(state?.motor?.step2 ? state.motor.step2 : null),
            ...payload,
            clientPartnerAddress: {
              ...(state?.motor?.step2 ? state.motor.step2.clientPartnerAddress : {}),
              ...payload.clientPartnerAddress,
            },
          },
        },
      });
    } else {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...state.data.step2,
            store: null,
          },
        },
        motor: {
          ...state.motor,
          step2: null,
        },
      });
    }
  }

  @Action(STORE_STEP2_RENEWAL)
  public storeStep2Renewal({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_RENEWAL) {
    console.warn("store step 2 payload", payload);
    const state = getState();

    if (payload) {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...state.data.step2,
            store: {
              ...state.data.step2?.store,
              ...payload,
              clientPartnerAddress: {
                ...state.data.step2?.store?.clientPartnerAddress,
                ...payload.clientPartnerAddress,
              },
            },
          },
        },
        motor: {
          ...state.motor,
          step2: {
            ...state.motor.step2,
            ...payload,
            clientPartnerAddress: {
              ...state.motor.step2?.clientPartnerAddress,
              ...payload.clientPartnerAddress,
            },
          },
        },
      });

    } else {
      patchState({
        data: {
          ...state.data,
          step2: {
            ...state.data.step2,
            store: null,
          },
        },
        motor: {
          ...state.motor,
          step2: null,
        },
      });
    }
  }

  @Action(STORE_STEP2_CP_SCREEN)
  public storeStep2CPScreen({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_CP_SCREEN) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step2: {
          ...state.data.step2,
          cpType: payload.cpScreen,
          isCompOrCustInfoIncomplete: payload.isCompOrCustInfoIncomplete,
          isContactInfoIncomplete: payload.isContactInfoIncomplete,
          isCorrespondenceInfoIncomplete: payload.isCorrespondenceInfoIncomplete,
        },
      },
    });
  }

  @Action(VALIDATE_CP)
  public validateCP({ patchState, getState }: StateContext<SatMotorModel>, { payload }: VALIDATE_CP) {
    const state = getState();

    return this.satMotorService.validateCP(payload).pipe(
      map((res) => {
        if (res && !res.error) {
          if (res.data.idType1 && res.data.idValue1) {
            patchState({
              data: {
                ...state.data,
                step2: {
                  ...(state.data.step2 ? state.data.step2 : null),
                  isValidIdValue1: true,
                },
              },
            });
          } else if (res.data.idType2 && res.data.idValue2) {
            patchState({
              data: {
                ...state.data,
                step2: {
                  ...(state.data.step2 ? state.data.step2 : null),
                  isValidIdValue2: true,
                },
              },
            });
          }
        } else {
          patchState({
            data: {
              ...state.data,
              step2: {
                ...(state.data.step2 ? state.data.step2 : null),
                isValidIdValue1: false,
                isValidIdValue2: false,
              },
            },
          });
        }
      }),
    );
  }

  @Action(SEARCH_MAKE_MODEL)
  public searchMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_MAKE_MODEL) {
    const state = getState();
    return this.satMotorService.getMakeOrModel(payload).pipe(
      map((res) => {
        let searchMakeModelList;
        if (res && res.data) {
          searchMakeModelList = res.data;
        } else {
          searchMakeModelList = [];
        }
        const state = getState();
        patchState({
          data: {
            ...state.data,
            step3: {
              ...state.data.step3,
              coverage: {
                ...state.data.step3.coverage,
                searchMakeModelList: searchMakeModelList,
              },
            },
          },
        });

      }),
    );
  }

  @Action(SEARCH_ENDORSEMENT_MAKE_MODEL)
  public searchEndtMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_ENDORSEMENT_MAKE_MODEL) {
    const state = getState();
    // console.log("SEARCH_ENDORSEMENT_MAKE_MODEL payload:", payload);

    return this.satMotorService.getMakeOrModel(payload).pipe(
      // 
      map((res) => {
        // console.log("SEARCH_ENDORSEMENT_MAKE_MODEL res:", res);

        let searchMakeModelList;
        if (res && res.data) {
          searchMakeModelList = res.data;
        } else {
          searchMakeModelList = [];
        }

        // console.log("SEARCH_ENDORSEMENT_MAKE_MODEL searchMakeModelList:", searchMakeModelList);

        const state = getState();
        patchState({
          data: {
            ...state.data,
            step2: {
              ...state.data.step2,
              endorsementDetails: {
                ...state.data.step2.endorsementDetails,
                searchMakeModelList: searchMakeModelList,
              },
            },
          },
        });
      }),
    );
  }

  @Action(SELECTED_MAKE_MODEL)
  public selectedMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SELECTED_MAKE_MODEL) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3.coverage,
            selectedMakeModel: payload,
          },
        },
      },
    });
  }

  @Action(SELECTED_ENDORSEMENT_MAKE_MODEL)
  public selectedEndorsementMakeOrModel({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SELECTED_ENDORSEMENT_MAKE_MODEL) {
    const state = getState();
    console.log("SELECTED_ENDORSEMENT_MAKE_MODEL payload:", payload);

    patchState({
      data: {
        ...state.data,
        step2: {
          ...state.data.step2,
          endorsementDetails: {
            ...state.data.step2.endorsementDetails,
            selectedMakeModel: payload,
          },
        },
      },
    });
  }

  @Action(POLICY_MOVEMENT_DATA)
  public policyMovemementData({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: POLICY_MOVEMENT_DATA) {

    // console.log("state policyMovemementData");

    const state = getState();
    return this.satMotorService.postPolicyMovemementTableData(payload).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          patchState({
            data: {
              ...state.data,
              step2: {
                ...state.data.step2,
                endorsementDetails: {
                  ...state.data.step2.endorsementDetails,
                  policyMovemementData: res.data ? res.data.endorsementDetList : null,
                },
              },
            },
          });
          // console.log("res.data.endorsementDetList =", res.data.endorsementDetList);
        }
      }),
    );
  }

  @Action(GET_LOV)
  public getLOV({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();
    return this.satMotorService.fetchLOV().pipe(
      map((res) => {
        patchState({
          lov: {
            ...state.lov,
            ...res.data
          },
        });
      }),
    );
  }

  @Action(GET_PRODUCT_LIST)
  public getProductListLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PRODUCT_LIST) {
    const state = getState();
    return this.satMotorService.getProductListLov(payload).pipe(
      map((res) => {
        if (res && res.productAccessConfigList) {
          const state = getState();
          patchState({
            lov: {
              ...state.lov,
              ...res,
              productList: this.filterProductList(res.productAccessConfigList).productList,
              endorsementList: this.filterProductList(res.productAccessConfigList).endorsementList
            },
          });
        }
      }),
    );
  }

  filterProductList(productAccessConfigList: any): any {
    let productList = [];
    let endorsementList = [];
    let endorsementListExtracted = false;

    productAccessConfigList.forEach((product) => {
      for (let code in product) {
        if (product.hasOwnProperty(code)) {
          productList.push({
            code: code,
            descp: product[code].productDescp,
          });

          if (!endorsementListExtracted && product[code].cnoteSatEndtTypeList) {
            product[code].cnoteSatEndtTypeList.forEach((endorsement) => {
              if (!endorsementList.some(item => item.code === endorsement.value)) {
                endorsementList.push({
                  code: endorsement.value,
                  descp: endorsement.attributeDescp
                });
              }
            });
            endorsementListExtracted = true;
          }
        }
      }
    });

    productList.sort((a, b) => a.code.localeCompare(b.code));

    return { productList, endorsementList };
  }

  @Action(GET_COVER_LIST_LOV)
  public getCoverListLov({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_COVER_LIST_LOV) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3?.coverage,
            ...payload,
          },
        },
      },
    });
  }

  @Action(GET_PRODUCT_CONFIG_LOV)
  public getProductConfigLov({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_PRODUCT_CONFIG_LOV) {
    const state = getState();
    return this.satMotorService.getProductConfigLov({ ...payload, partnerId: 3881892, partnerVersionNo: 1 }).pipe(
      map((res) => {
        if (res) {
          let coverListLov = res.productAccessConfigList.find((c: { productCode: any }) => (c.productCode = payload.productCode));
          patchState({
            productConfigLov: coverListLov,
            data: {
              ...state.data,
              step3: {
                ...state.data.step3,
                ...res.productAccessConfigList,
                coverage: {
                  ...state.data.step3?.coverage,
                  coverListLov: coverListLov[payload.productCode].coverList,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(GET_VEHICLE_USE)
  public getVehicleUseLov({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_VEHICLE_USE) {
    const state = getState();
    return this.satMotorService.getVehicleUseLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          if (payload.isRTP) {
            const step2 = state.rtp?.step2 || {};
            patchState({
              rtp: {
                ...state.rtp,
                step2: {
                  ...step2,
                  vehicleUseLov: res.data
                },
              },
            });
          } else if (payload.isEndorsement) {
            const step2 = state.endorsement?.step2 || {};
            patchState({
              endorsement: {
                ...state.endorsement,
                step2: {
                  ...step2,
                  vehicleUseLov: res.data,
                },
              },
            });
          } else if (payload.isPolicyEnquiry) {
            patchState({
              data: {
                ...state.viewPolicyData,
                vehicleUseLov: res.data,
              },
            });
          } else if (payload.isViewQuotation || payload.isNcdEnquiry) {
            patchState({
              vehicleUseLov: res.data,
            });
          } else {
            patchState({
              vehicleUseLov: res.data,
              data: {
                ...state.data,
                step3: {
                  ...state.data.step3,
                  coverage: {
                    ...state.data.step3.coverage,
                    vehicleUseLov: res.data,
                  },
                },
              },
            });
          }
        }
      }),
    );
  }

  @Action(INIT_STEP3)
  public initStep3({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: INIT_STEP3) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
        },
      },
    });
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
        },
      },
    });
  }

  @Action(STORE_STEP3)
  public storeStep3({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP3) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
        },
      },
    });

    let riskList = payload.riskList[0];

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coveragePremium: {
              ...state.motor.step3.coverage.coveragePremium,
              cngeNote: payload,
            },
            coverageType: {
              ...state.motor.step3.coverage.coverageType,
              ...payload.riskList[0].coverList,
            },
            extracover: {
              ...state.motor.step3.coverage.extracover,
              driverNameDetails: {
                ...state.motor.step3.coverage.extracover.driverNameDetails,
                rows: payload.riskList[0].cngeRiskDrvrDtoList,
              },
              ehailingDriverDetails: {
                ...state.motor.step3.coverage.extracover.ehailingDriverDetails,
              },
              extraCoverPackage: {
                ...state.motor.step3.coverage.extracover.extraCoverPackage,
              },
              extraCoverPackageSummary: {
                ...state.motor.step3.coverage.extracover.extraCoverPackageSummary,
              },
            },
            vehicleDetails: {
              ...state.motor.step3.coverage.vehicleDetails,
              ...riskList[0],
              region: state.motor.step1.agentDetail.region
            },
          },
        },
      },
    });
  }

  @Action(STORE_STEP3_RENEWAL)
  public storeStep3Renewal({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP3) {
    const state = getState();

    let riskList = payload.riskList[0];
    if (riskList) {
      if (!this.store.selectSnapshot<any>((state) => state.SatMotorState?.motor?.step3))
        this.store.dispatch(new INIT_STEP3({}));
      this.store.dispatch(new GET_VEHICLE_USE({ productCode: payload.productCode })).subscribe((state) => {
        this.store
          .dispatch(
            new SEARCH_MAKE_MODEL({
              type: "",
              classCode: state.SatMotorState.data.step3.coverage.vehicleUseLov.filter((v) => v.code === payload.riskList[0].vehicleUse)[0].vehicleClass,
              yearMnftr: payload.riskList[0].vehicleMakeYear,
            }),
          )
          .subscribe((state) => {
            this.store.dispatch(new SELECTED_MAKE_MODEL(state.SatMotorState.data.step3.coverage.searchMakeModelList.find((mm) => mm.vehModelCodeNvic === payload.riskList[0].vehModelNvic)));
          });
      })
    }

    let extraCoverPackage = [];
    let subCoverList = payload.riskList[0]?.coverList[0]?.subCoverList;
    if(subCoverList){
      for (let cv of subCoverList) {
        extraCoverPackage.push({
          ...cv,
          description: cv.subCoverCodeDescription,
          selected: cv.selectedInd,
          enabled: cv.enableInd !== undefined ? cv.enableInd : false,
          contractId: null,
          riskId: null,
          subcoverId: null,
          coverId: null,
        });
      }
    } 

    let selectedExtraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd && o1.subCoverPrem > 0);

    //removed !o1.enableInd  because when new issuance generate quotation > view quotation > update quotation > summary page missing package
    let rahmahExtraCoverList = extraCoverPackage.filter(o1 => o1.subCovPackage && o1.subCoverPrem == 0);
    let topExtraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.subCoverPrem == 0 && !o1.subCovPackage);
    //let extraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.subCoverPrem > 0);

    let extraCoverInSummary = {
      subCovPackageDesc: payload.riskList[0]?.coverList[0]?.subCovPackageDesc,
      rahmahPackageList: rahmahExtraCoverList,
      topExtraCoverList: topExtraCoverList,
      selectedExtraCoverList: selectedExtraCoverList
    }

    // let riskList = payload.riskList[0];
    // let selectedMakeModel = "";
    // if (riskList) {
    //   selectedMakeModel = {
    //     ...payload.riskList[0],
    //     vehModelCodeNvic: payload.riskList[0]?.vehModelNvic,
    //     nvicCode: riskList.nvicNo,
    //     piamMakeModelDesc: riskList.piamMakeModelDesc,
    //     makeModel: riskList.makeModelDesc,
    //     piamMakeCodeDesc: riskList.piamMakeCodeDesc,
    //     variant: null,
    //     series: null,
    //     yearMnftr: riskList.vehicleMakeYear,
    //     capacity: riskList.vehicleCapacity,
    //     uom: riskList.vehicleUom,
    //     seat: riskList.vehicleSeat,
    //     avEmSiMin: riskList.avEmSiMin ? riskList.avEmSiMin : 0,
    //     avEmSiMax: riskList.avEmSiMax ? riskList.avEmSiMax : 0,
    //     avWmSiMin: riskList.avWmSiMin ? riskList.avWmSiMin : 0,
    //     avWmSiMax: riskList.avWmSiMax ? riskList.avWmSiMax : 0,
    //     inactiveSiInd: riskList.makeModelInactiveSiInd,
    //     inactiveInd: riskList.makeModelInactiveInd,
    //   }
    // }

   // let makeModel = payload.riskList[0].makeModelDesc ? payload.riskList[0].makeModelDesc : (payload.riskList[0].vehicleModelDescp ? payload.riskList[0].vehicleModelDescp : "");
  //  let makeModel = payload.riskList[0].vehicleModelDescp;

    let coverList = payload.riskList[0].coverList[0];
    const premiumInfo = {
      sumInsured: payload.sumInsured,
      annualPremium: payload.annualPremium,
      basicPremium: payload.basicPremium,
      loadPercentage: coverList?.coverLoadingList?.loadingPct,
      loadAmount: coverList?.coverLoadingList?.loadingAmt,
      ncdPercentage: coverList?.ncdPercentage,
      ncdAmount: coverList?.ncdAmount,
      subCoverPremium: coverList?.subCoverPremium,
      subCovPackagePrem: coverList?.subCovPackagePrem,
      grossPremium: payload.grossPremium,
      rebatePct: payload.rebatePercentage,
      rebateAmt: payload.rebateAmount,
      servicetaxPercentage: payload.servicetaxPercentage,
      servicetaxAmount: payload.servicetaxAmount,
      stamp: payload.stamp,
      commissionPercentage: payload.commissionPercentage,
      commissionAmount: payload.commissionAmount ? payload.commissionAmount : payload.commisionAmount,
      premiumDue: payload.premiumDue,
      premiumDueRounded: payload.premiumDueRounded,
    };

    // patchState({
    //   data: {
    //     ...state.data,
    //     step3: {
    //       ...state.data.step3,
    //       coverage: {
    //         ...state.data.step3?.coverage,
    //         selectedMakeModel,
    //       },
    //     },
    //   },
    // });

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            coveragePremium: {
              cngeNote:
              {
                ...payload,
                ...premiumInfo,
                coverId: null,
                contractId: null,
                riskId: null,
              }
            },
            coverageType: {
              ...payload.riskList[0].coverList[0],
              premiumDue: payload.premiumDue,
              subCoverList: [
                ...extraCoverPackage
              ],
              coverId: null,
              contractId: null,
              riskId: null,
            },
            extracover: {
              driverNameDetails: {
                rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "NORM")?.map(a => {
                  return {
                    driverName: a.cngeRiskDrvrPkDto.driverName,
                    driverId: a.driverId,
                    driverAge: a.driverAge,
                    driverSeq: a.driverSeq
                  }
                }),
              },
              ehailingDriverDetails: {
                rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "EHAIL")?.map(a => {
                  return {
                    driverName: a.cngeRiskDrvrPkDto.driverName,
                    driverId: a.driverId,
                    driverSeq: a.driverSeq
                  }
                }),
              },
              extraCoverPackageSummary: {
                ...extraCoverInSummary,
                costInd : payload.riskList[0]?.coverList[0]?.costInd
              },
              extraCoverPackage: extraCoverPackage
            },
            vehicleDetails: {
              ...payload.riskList[0],
              region: state.motor.step1.agentDetail.region,
              makeModelDesc: payload.riskList[0].piamMakeModelDesc,
              engineType: payload.riskList[0].engineType || payload.riskList[0].vehicleEngine,
              vehicleUom: payload.riskList[0].vehicleUom,
              makeModel: payload.riskList[0].piamMakeModelDesc +' '+payload.riskList[0].variantFullDesc,
              contractId: null,
              riskId: null,
              coverList: [
                {
                  ...payload.riskList[0].coverList[0],
                  premiumDue: payload.premiumDue,
                  subCoverList: [
                    ...extraCoverPackage
                  ],
                  coverId: null,
                  contractId: null,
                  riskId: null,
                }
              ],


            },
          },
        },
      },
    });
  }

  @Action(STORE_EXTRA_COVER_DETAILS)
  public storeExtraCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_EXTRA_COVER_DETAILS) {
    const state = getState();

    if (payload.cnoteType === 'ENDT17') {
      // Ensure that riskList and coverList are valid before accessing them
      const riskList = payload.riskList || [];
      const coverList = riskList.length > 0 ? riskList[0].coverList || [] : [];

      // Only proceed if coverList has at least one item
      if (coverList.length > 0) {
        let extraCoverPackageSummary = this.getExtraCoverage(coverList[0]);
        let driverNameDetails =  this.getDriverListData(riskList[0].cngeRiskDrvrDtoList, true)
        let ehailingDriverDetails =  this.getDriverListData(riskList[0].cngeRiskDrvrDtoList, false)

        patchState({
          endorsement: {
            ...state.endorsement,
            step3: {
              coverage: {
                extracover: {
                  extraCoverPackageSummary,
                  driverNameDetails:{
                    driverNameDetails,
                    vehicleUnNamedDrvrInd: riskList[0].coverList[0].vehicleUnNamedDrvrInd,
                    vehicleAlldrvrInd: riskList[0].coverList[0].vehicleAlldrvrInd
                  },
                  ehailingDriverDetails: ehailingDriverDetails
                }
              },
            },
          },
        });
      }
    } else {
      patchState({
        motor: {
          ...state.motor,
          step3: {
            ...state.motor.step3,
            coverage: {
              ...state.motor.step3.coverage,
              extracover: payload,
            },
          },
        },
      });
    }
  }   

  @Action(DELETE_EXTRA_COVER_DETAILS)
  public deleteExtraCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_EXTRA_COVER_DETAILS) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            extracover: null,
          },
        },
      },
    });
  }

  @Action(ADD_NEW_COVERAGE)
  public addNewCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: ADD_NEW_COVERAGE) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coverageType: payload,
          },
        },
      },
    });
  }

  @Action(DELETE_COVERAGE_FLAG)
  public deleteCoverageFlag({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: DELETE_COVERAGE_FLAG) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            forceDelete: payload.forceDelete
          },
        },
      },
    });
  }
  
  @Action(DELETE_COVERAGE)
  public deleteCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: DELETE_COVERAGE) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coveragePremium: null,
            coverageType: null,
            forceDelete: false
          },
        },
      },
    });
  }

  @Action(STORE_COVERAGE_VEHICLE_DETAILS)
  public storeVehicleDetails({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_COVERAGE_VEHICLE_DETAILS) {
    const state = getState();

    let vehicleDetails;
    if (state.motor.step3.coverage?.vehicleDetails) {
      vehicleDetails = {
        vehicleDetails: { ...state.motor.step3.coverage?.vehicleDetails, ...payload }
      }
    }
    else {
      vehicleDetails = { vehicleDetails: payload };
    }

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            ...vehicleDetails
          },
        },
      },
    });
  }

  @Action(STORE_COVERAGE_TYPE)
  public storeTypeOfCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_COVERAGE_TYPE) {
    const state = getState();

    let coverageType;
    if (state.motor.step3.coverage?.coverageType) {
      coverageType = {
        coverageType: { ...state.motor.step3.coverage?.coverageType, ...payload }
      }
    }
    else {
      coverageType = { coverageType: payload };
    }

    let patch = {
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            ...coverageType,
          },
        },
      },
    }

    patchState(patch);
  }

  @Action(UPDATE_COVERAGE_TYPE)
  public updateTypeOfCoverage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: UPDATE_COVERAGE_TYPE) {
    const state = getState();
    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            coverageType: {
              ...state.motor.step3.coverage.coverageType,
              ...payload
            }
          },
        },
      },
    });
  }

  @Action(CALCULATE_PREMIUM_PAYLOAD)
  public calculatePremiumPayload({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_PREMIUM_PAYLOAD) {
    const state = getState();
    const agentData = state.motor?.step1?.agentDetail ?? null;

    return this.satMotorService.calculatePremiumPayload(payload).pipe(
      map((res) => {
        if (res) {
          let cnoteType = state.motor.step1.issuance.issuance.cnoteType ?? null;
          let vehicleNo;

          if (cnoteType && cnoteType == 'NWTR') {
            vehicleNo = payload.coverage.vehicleDetails.vehicleNo;
          } else {
            vehicleNo = state?.motor?.step1?.issuance?.issuance?.vehicleNo;
          }

          let calculatePremiumPayload = {
            cngeNote: {
              cnoteStatus: payload.coverage.cnoteStatus,
              alimEligibleInd: payload.coverage.alimEligibleInd,
              mtRoadPlacementTblInd: payload.coverage.mtRoadPlacementTblInd,
              mtRoadEligibleInd: payload.coverage.mtRoadEligibleInd,
              lineOfBusiness: "MT",
              ...state.motor.step1.issuance.issuance,
              ...state.motor.step1.issuance.pdpa.pdpaCheck,
              ...state.motor.step1.issuance.pdpa.consentDetails,
              vehicleNo: vehicleNo,
              clientPartner: {
                ...state.motor.step2,
              },
              riskList: [
                {
                  ismRefNo: state.motor?.step3?.coverage?.ncdInfo?.ismRefNo,
                  prevNcdVeh :state?.motor?.step3?.coverage?.coverageType?.prevNcdVeh,
                  prevNcdInsurer : state.motor?.step3?.coverage?.coverageType?.prevNcdInsurer,
                  riskType: "VEH",
                  ...payload.coverage.vehicleDetails,
                  cngeRiskDrvrDtoList: payload.coverage.cngeRiskDrvrDtoList,
                  coverList: [
                    {
                      ...payload.coverage.typeOfCoverage,
                      subCoverList: payload.coverage.extracover,
                      coverDto: payload.coverage.coverDto,
                      vehicleUnNamedDrvrInd: payload.coverage.vehicleUnNamedDrvrInd,
                      vehicleAlldrvrInd: payload.coverage.vehicleAlldrvrInd,
                      previousMinSumInsured: payload.coverage.previousMinSumInsured,
                      previousMaxSumInsured:  payload.coverage.previousMaxSumInsured
                    },
                  ],
                },
              ],
              mkagAgent: {
                ...state.motor.step1.agentDetail,
              },
            },
            operator: payload.operator,
          };

          calculatePremiumPayload.cngeNote.effectiveDate = calculatePremiumPayload.cngeNote.effectiveDate === "NA" ? null : calculatePremiumPayload.cngeNote.effectiveDate;
          calculatePremiumPayload.cngeNote.expiryDate = calculatePremiumPayload.cngeNote.expiryDate === "NA" ? null : calculatePremiumPayload.cngeNote.expiryDate;
          calculatePremiumPayload.cngeNote.region = calculatePremiumPayload.cngeNote.riskList[0].region;
          calculatePremiumPayload.cngeNote.alimCustomerIndi = calculatePremiumPayload.cngeNote.clientPartner.alimCustomerIndicator === "YES";

          if (agentData && agentData.sundriesAgent) {
            const agentAdditionalInfo = state.motor?.step1?.agentAdditional ?? null;
            if (agentAdditionalInfo && agentAdditionalInfo.rebateType) {
              calculatePremiumPayload.cngeNote.rebateType = agentAdditionalInfo.rebateType;
              calculatePremiumPayload.cngeNote.riskList[0].coverList[0].rebateType = agentAdditionalInfo.rebateType;
            }
          }

          patchState({
            data: {
              ...state.data,
              step3: {
                ...state.data.step3,
                coverage: {
                  ...state.data.step3.coverage,
                  coveragePremiumPayload: calculatePremiumPayload,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(CALCULATE_PREMIUM)
  public calculatePremium({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_PREMIUM) {
    const state = getState();
    return this.satMotorService.calculatePremium(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            motor: {
              ...state.motor,
              step3: {
                ...(state.motor?.step3 || {}),
                coverage: {
                  ...(state.motor?.step3?.coverage || {}),
                  coveragePremium: res,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(CALCULATE_ENDT_PREMIUM)
  public calculateEndtPremium({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_ENDT_PREMIUM) {
    const state = getState();
    return this.satMotorService.calculateEndtPremium(payload).pipe(
      map((res) => {
        if (res /*&& res.data*/) {//res.cngeNote.riskList[0].coverList[0].sumInsured = 100001;
          patchState({
            endorsement: {
              ...state.endorsement,
              step2: {
                ...state.endorsement.step2,
                endorsementDetails: {
                  ...state.endorsement.step2.endorsementDetails,
                  coveragePremium: res,
                },
              },
            },
          });
        }
      }),
    );
  }

  @Action(CALCULATE_PREMIUM_SUMMARY)
  public calculatePremiumSummary({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: CALCULATE_PREMIUM_SUMMARY) {
    const state = getState();
    return this.satMotorService.calculatePremium(payload).pipe(
      map((res) => {
        if (res && res.cngeNote) {
          let stx: any, sty: any, stz: any;
          let premiumInfo: any;
          if ((stx = res['cngeNote']) && (sty = stx['riskList']) && sty.length > 0 && (stz = sty[0]['coverList']) && stz.length > 0) {
            premiumInfo = stz[0];
          }
          patchState({ premiumCalculation: premiumInfo, summaryPremium: stx });
        }
      }),
    );
  }

  @Action(GET_CUSTOMER_PARTNER_MOCK)
  public getCustomerPartnerInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_CUSTOMER_PARTNER_MOCK) {
    const state = getState();
    return this.satMotorService.getCPData(payload).pipe(
      map((res) => {
        if (res && res.data) {
          if (res.data) {
            dispatch(new STORE_STEP2(res.data));
          }
        }
      }),
    );
  }

  @Action(GENERATE_QUOTATION)
  public generateQuotation({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload, cnoteType }: GENERATE_QUOTATION) {
    const state = getState();
    let data = "";
    const geoIndValue = state.geoCodedInd;

    if (cnoteType && (cnoteType == 'RN' || cnoteType == 'XP')) {
      data = this.prepareCoverNoteRequestPayloadRenewal(state, payload, false, false, geoIndValue); //need to check if endorsement needed
    } else {
      data = this.prepareCoverNoteRequestPayload(state, payload, false, false, geoIndValue);
    }

    return this.satMotorService.generateQuotation(data).pipe(
      map((res) => {
        if (res && res.data) {
          
          if (payload.isEndorsement) {
            patchState({
              endorsement: {
                ...state.endorsement,
                step3: {
                  ...(state.endorsement?.step3 || {}),
                  generateQuotation: res,
                },
              },
            });
          } else {
            patchState({
              motor: {
                ...state.motor,
                step4: {
                  ...(state.motor?.step4 || {}),
                  generateQuotation: res,
                },
              },
            });
          }

        }
      }),
    );
  }

  @Action(SET_JPJ_STATUS_SENT)
  public setJPJStatusSent(ctx: StateContext<SatMotorModel>, action: SET_JPJ_STATUS_SENT) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      jpjStatusSent: action.payload,
    });
  }

  @Action(SET_RETRIEVE_QUOTATION)
  public setRetrieveQuotation({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SET_RETRIEVE_QUOTATION) {
    patchState({
      retrieveQuotation: payload
    });
  }

  @Action(GENERATE_ENDORSEMENT)
  public generateEndorsement({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GENERATE_ENDORSEMENT) {
    const state = getState();
    let payloadRequest = this.prepareEndorsementPayload(state, payload);

    return this.satMotorService.generateEndorsement(payloadRequest).pipe(
      map((res) => {
        if (res) {
          patchState({
            endorsement: {
              ...state.endorsement,
              step3: {
                ...(state.endorsement?.step3 || {}),
                sendToJPJ: res,
              },
            },
          });
        }
      })
    );
  }

  @Action(GENERATE_ENDORSEMENT_QUOTATION)
  public generateEndtQuotation({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GENERATE_ENDORSEMENT_QUOTATION) {
    const state = getState();
    let payloadRequest = this.prepareEndorsementPayload(state, payload);

    return this.satMotorService.generateEndorsementQuotation(payloadRequest).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            endorsement: {
              ...state.endorsement,
              step3: {
                ...(state.endorsement?.step3 || {}),
                generateQuotation: res,
              },
            },
          });
        }
      })
    );
  }

  prepareEndorsementPayload(state: SatMotorModel, payload: any): any {
    const { mkagAgent, riskList = [{}], clientPartner, referRemarkList = [], ...cngeNote } = payload.policy;
    const [risk] = riskList;
    const [coverList] = riskList[0]?.coverList || [];
    let [remarks] = referRemarkList;
    const endtType = payload.endtType;
  
    let cnoteType = '';
    let endtCode = '';
  
    if (endtType === 'COE' || endtType === 'XP') {
      cnoteType = endtType;
      endtCode = null;
    } else if (endtType.startsWith('ENDT')) {
      cnoteType = endtType;
      endtCode = endtType.substring(4);
    } else if (endtType.startsWith('EC')) {
      cnoteType = endtType;
      endtCode = endtType.substring(2);
    }

    if(!remarks) {
      remarks = [
        {
          referRemark : "Test Remark: "+cnoteType,
    		  createdBy : payload.userId
        }
      ]
    }

    if(cngeNote.declareList) {
      let seq = 1;
      cngeNote.declareList = cngeNote.declareList.map(a => {
        return {
          declareCode: a.declareCode,
          declareAns: a.declareAns,
          declareSeq: seq++
        };
      });
    }
  
    let request = {
      operator: payload.userId,
      productType: 'MT',
      cngeNoteDto: {
        ...cngeNote,
        riskList: [
          {
            ...riskList[0],
            vehicleCapacity: typeof riskList[0]?.vehicleCapacity === 'string' &&
            riskList[0]?.vehicleCapacity.includes(',')
              ? riskList[0].vehicleCapacity.replace(/,/g, '')
              : riskList[0]?.vehicleCapacity
          }
        ],
        mkagAgent,
        clientPartner,
        cnoteType,
        endtCode,
        referRemarkList: remarks,
        proposalDate: cngeNote.cnoteDate,
        grossPremium: coverList.grossPremium,
        subCoverPremium: coverList.subCoverPremium,
        annualPremium: coverList.annualPremium,
        endtCalculationMethod: "F",
        endtNarration: cngeNote.endtNarration
      }
    }

    if(state?.endorsement?.step3?.paymentList) {
      request.cngeNoteDto.paymentList = state.endorsement.step3.paymentList;
    }

    if(state?.endorsement?.step3?.roadTaxPayable) {
      request.cngeNoteDto = {
        ...request.cngeNoteDto,
        ...state.endorsement.step3.roadTaxPayable
      };
    }

    if(state?.endorsement?.step3?.paymentInfo) {
      request.cngeNoteDto = {
        ...request.cngeNoteDto,
        ...state.endorsement.step3.paymentInfo
      };
    }
  
    return request;
  }

  @Action(RESEND_JPJ)
  public resendJPJ({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: RESEND_JPJ) {
    const state = getState();

    return this.satMotorService.resendJPJ(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            motor: {
              ...state.motor,
              step4: {
                ...(state.motor?.step4 || {}),
                resendJPJ: res,
              },
            },
          });
        }
      }),
    );
  }

  @Action(AMEND_OPENDATE)
  public amendOpenDate({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: AMEND_OPENDATE) {
    const state = getState();
    let data = payload.data;

    return this.satMotorService.amendOpenDate(data).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            motor: {
              ...state.motor,
              step4: {
                ...(state.motor?.step4 || {}),
                amendOpenDate: res,
              },
            },
          });
        }
      }),
    );
  }

  @Action(SEND_TO_JPJ)
  public sendToJPJ({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SEND_TO_JPJ) {
    const state = getState();
    let data = "";
    const geoIndValue = state.geoCodedInd;
    let proposalNo = state?.motor?.step4?.generateQuotation?.data?.proposalNo;

    if (!proposalNo && ((payload.cnoteType && payload.cnoteType === 'RN') || payload.cnoteType == 'XP')) {
      data = this.prepareCoverNoteRequestPayloadRenewal(state, payload, false, payload.isRTP, geoIndValue);
    } else {
      if (payload.isCngeNoteExist) {
        data = payload.data;
      } else {
        data = this.prepareCoverNoteRequestPayload(state, payload, false, payload.isRTP, geoIndValue);
      }
      
    }

    return this.satMotorService.sendToJPJ(data, payload.isRTP ? true : false, payload.isEndorsement ? true : false).pipe(
      map((res) => {
        if (res && res.data) {
          if (payload.isRTP) {
            patchState({
              rtp: {
                ...state.rtp,
                step2: {
                  ...(state.rtp?.step2 || {}),
                  sendToJPJ: res,
                },
              },
            });
          } else if (payload.isEndorsement) {
            patchState({
              endorsement: {
                ...state.endorsement,
                step3: {
                  ...(state.endorsement?.step3 || {}),
                  sendToJPJ: res,
                },
              },
            });
          } else {
            patchState({
              motor: {
                ...state.motor,
                step4: {
                  ...(state.motor?.step4 || {}),
                  sendToJPJ: res,
                },
              },
            });
          }
        }
      }),
    );
  }

  @Action(RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ)
  resetGenerateQuotationAndSendToJPJ({ patchState, getState }: StateContext<SatMotorModel>, { payload }: { payload: any }) {
    const state = getState();

    if (payload.isRTP) {
      patchState({
        rtp: {
          ...state.rtp,
          step2: {
            ...(state.rtp?.step2 || {}),
            sendToJPJ: null,
          },
        },
      });
    } else if (payload.isEndorsement) {
      patchState({
        endorsement: {
          ...state.endorsement,
          step3: {
            ...(state.endorsement?.step3 || {}),
            generateQuotation: null,
            sendToJPJ: null,
          },
        },
      });
    }else if (payload.updateQuotation) {
       //no need to reset anything
    } else {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...(state.motor?.step4 || {}),
            generateQuotation: null,
            sendToJPJ: null,
          },
        },
      });
    }
  }

  @Action(SEARCH_VIX)
  public searchVIX({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_VIX) {
    const state = getState();
    return this.satMotorService.searchVIX(payload).pipe(
      map((res) => {
        if (res && res.data) {
          const state = getState();
          const step1 = state.motor?.step1 || {};

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                searchVIX: res.data,
              },
            },
          });
        }
      }),
    );
  }

  @Action(ROADTAX_SEARCH)
  public searchRoadTax({ patchState, getState }: StateContext<SatMotorModel>, { payload }: ROADTAX_SEARCH) {
    const state = getState();
    const step1 = state.rtp?.step1 || {};

    if (payload.isReset) {
      patchState({
        rtp: {
          ...state.rtp,
          step1: {
            ...step1,
            rtpPolicy: null,
            rtpVehicleSearchError: null,
          },
        },
      });
      return null;
    } else {
      return this.satMotorService.searchRoadTax(payload).pipe(
        map((res) => {
          if (res) {
            patchState({
              rtp: {
                ...state.rtp,
                step1: {
                  ...step1,
                  rtpPolicy: (res && res.data) ? res : null,
                  rtpVehicleSearchError: (res && res.data) ? null : res,
                },
              },
            });
          }
        }),
      );
    }
  }

  @Action(SEARCH_ACTIVE_QUOTATION)
  public searchActiveQuotation({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_ACTIVE_QUOTATION) {
    const state = getState();
    return this.satMotorService.searchActiveQuotation(payload).pipe(
      map((res) => {
        const state = getState();
        const step1 = state.motor?.step1 || {};
        if (res && res.data) {

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                activeQuotation: res.data,
              },
            },
          });
        } else {

          patchState({
            motor: {
              ...state.motor,
              step1: {
                ...step1,
                activeQuotation: null,
              },
            },
          });
        }
      }),
    );
  }

  @Action(ENQUIRE_ENDT_NARRATION)
  public enquireEndorsementNarration({ patchState, getState }: StateContext<SatMotorModel>, { payload }: ENQUIRE_ENDT_NARRATION) {
    const state = getState();
    return this.satMotorService.enquireEndorsementNarration(payload).pipe(
      map((res) => {
        const state = getState();
        const step3 = state.endorsement?.step3 || {};
        if (res && res.data) {

          patchState({
            endorsement: {
              ...state.endorsement,
              step3: {
                ...step3,
                endtNarration: res.data.narration,
              },
            },
          });
        }
      }),
    );
  }

  @Action(SEARCH_ENDT_VEHICLE)
  public searchEndtVehicle({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_ENDT_VEHICLE) {
    return this.satMotorService.searchEndorsementVehicle(payload).pipe(
      map((res) => {
        const state = getState();
        const step1 = state.endorsement?.step1 || {};
        const step2 = state.endorsement?.step2 || {};

        const searchPolicyData = res ? res.data : null;
        const endtVehicleData = res && res.data ? res.data.cngeNoteDto : null;
        const endtPreviousVehicleData = res && res.data ? res.data.htgePol : null;

        patchState({
          endorsement: {
            ...state.endorsement,
            step1: {
              ...step1,
              policyData: searchPolicyData,
              endtVehicleData,
              endtPreviousVehicleData,
              endtVehicleSearchError: (res && res.data) ? null : res,
            },
            step2: {
              ...step2,
              endtUpdatedData: endtVehicleData
            },
          },
        });

        //this.store.dispatch(new STORE_EXTRA_COVER_DETAILS(endtVehicleData));
        return res;
      })
    );
  }


  @Action(STORE_SHOWED_ONE_ALLIANZ_POP_UP)
  public storeShowedOneAllianzPopUp({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_SHOWED_ONE_ALLIANZ_POP_UP) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        hasShowedOneAllianzPopUp: payload,
      },
    });
  }

  @Action(SET_CUSTOMER_PARTNER_SEARCH_TYPE)
  public setCustomerPartnerSearchType({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SET_CUSTOMER_PARTNER_SEARCH_TYPE) {
    const state = getState();
    patchState({
      data: {
        ...state.data,
        step2: {
          ...state.data.step2,
          cpType: payload,
        },
      },
    });
  }

  @Action(UPDATE_PAYMENT_LIST)
  updatePaymentList({ patchState, getState }: StateContext<SatMotorModel>, { payload }: UPDATE_PAYMENT_LIST) {
    const state = getState();
    if (!payload.isEndorsement) {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...state.motor.step4,
            paymentList: payload.paymentList,
          },
        },
      });
    }else {
      patchState({
        endorsement: {
          ...state.endorsement,
          step3: {
            ...state.endorsement.step3,
            paymentList: payload.paymentList,
          },
        },
      });
    }

  }

  @Action(SAVE_REFERRAL_REMARKS)
  public saveReferralRemarks({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SAVE_REFERRAL_REMARKS) {
    const state = getState();

    return this.satMotorService.saveReferralRemarks(payload).pipe(
      map((res) => {

        if (payload.isEndorsement) {
          patchState({
            endorsement: {
              ...state.endorsement,
              step4: {
                ...state.endorsement.step4,
                referRemarkList: res['data'].cngeNote.referRemarkList
              }
            }
          });
        } else if (payload.isViewQuotation) {
          if (res && res.data) {
            patchState({
              viewQuotation: res.data
            });
          }
        } else {
          patchState({
            data: {
              ...state.data,
              step5: {
                ...state.data.step5,
                referRemarkList: res['data'].cngeNote.referRemarkList
              }
            }
          });
        }
      })
    );
  }

  @Action(DOWNLOAD_PDF)
  public downloadPDF({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: DOWNLOAD_PDF) {
    return this.satMotorService.downloadPDF(payload);
    // return this.satMotorService.downloadPDF(payload).pipe(
    //   map((res) => {
    //     const blob = this.satMotorService.generateBlob(res);
    //     console.log("Blob:", blob);

    //     if (blob) {
    //       this.satMotorService.downloadPDFUsingBlob(blob);
    //     }
    //   }),
    // );
  }

  @Action(GET_PREVIOUS_POLICY_NO)
  public getPreviousPolicyNo({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_PREVIOUS_POLICY_NO) {
    const state = getState();
    return this.satMotorService.getPreviousPolicyNo(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            expiredPolicyDetails: res.data.htgePol,
            previousPolicyDetails: res.data.cngeNoteDto,
          });
        } else {
          patchState({
            previousPolicyDetails: null,
            previousPolicyDetailsError: res
          });
        }
      }),
    );
  }

  @Action(GET_VIEW_POLICY)
  public getViewPolicy({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_VIEW_POLICY) {
    const state = getState();
    return this.satMotorService.getViewPolicy(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            viewPolicyDetails: res.data,
            viewPolicyData: this.readDataFromCngeNote(res.data, true, false)
          });
        } else {
          patchState({
            viewPolicyDetails: null,
            viewPolicyDetailsError: res
          });
        }
      }),
    );
  }
  @Action(GET_VIEW_CLAIM)
  public getViewClaim({ patchState, getState }: StateContext<SatMotorModel>, { claimNo, policyNo }: GET_VIEW_CLAIM) {
    const state = getState();
    return this.satMotorService.getViewClaim(claimNo, policyNo).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            viewClaimDetails: res.data,
            viewClaimData: this.readDataFromClaim(res.data)
          });
        } else {
          patchState({
            viewClaimDetails: null,
            viewClaimDetailsError: res
          });
        }
      }),
    );
  }

  readDataFromClaim(data: any): any {
    const { mkagAgent, clientPartner: cpData, claimNo, htclClaimDet, clientPartner,
      asAt, claimCode, claimDescription, lossDate, retainNcdInd,
      clientBusinessRegNo, clientNewNric, clientOtherId } = data;

    // Agent Data
    const agentData = {
      agentCode: mkagAgent.agentCode,
      name: mkagAgent.name,
    };

    // Declaration issuance
    const issuanceInfo = {
      vehicleNo: data.vehicleNo,
      policyNo: data.policyNo
    };

    return {
      mkagAgent,
      agentData,
      issuanceInfo,
      claimNo,
      htclClaimDet,
      clientPartner,
      asAt,
      claimCode,
      claimDescription,
      lossDate,
      retainNcdInd,
      clientBusinessRegNo,
      clientNewNric,
      clientOtherId
    };
  }

  @Action(REQUEST_CANCELLATION)
  public requestCancellation({ patchState, getState }: StateContext<SatMotorModel>, { payload }: REQUEST_CANCELLATION) {
    const state = getState();

    return this.satMotorService.requestCancellation(payload).pipe(
      map((res) => {

      }),
    );
  }

  @Action(REDIRECT_TO_MIGBASE_MOTOR)
  public searchClaimEnquiry({ patchState, getState }: StateContext<SatMotorModel>, { payload }: REDIRECT_TO_MIGBASE_MOTOR) {
    let newURL = "";
    // let  newURL = `http://localhost:63650/allianz-modular-products/enquiry`; //for local testing
    console.log("MB URL: " + newURL);
    window.open(newURL, '_self');
  }

  @Action(CANCEL_REPLACE)
  public cancelReplace({ patchState, getState }: StateContext<SatMotorModel>, { payload }: CANCEL_REPLACE) {
    const state = getState();

    return this.satMotorService.cancelReplace(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              cancellationRes: res.data,
              cancelRemarks: payload.cancelRemarks
            },
          });
        }
      }),
    );
  }

  @Action(CONTINUE_ISSUANCE)
  public continueIssuance({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...state.motor.step1,
          issuance: null,
        },
        step2: null,
        step3: null,
        step4: null,
        step5: null,
      },
      data: {
        ...state.data,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
        isRenewNow: null,
        agentDetail: null,
        branchList: null,
        hasShowedOneAllianzPopUp: null,
        cancellationRes: null
      },
      declarationInfo: null,
      isCancelAtComplete: false,
      isCancelReplaceCoverNote: false,
      viewQuotation: null,
      viewQuotationData: null,
      updateQuotation: null,
      expiredPolicyDetails: null
    });

    //change back the step2.showSearchComponent to true to let user search the customer partner
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(true));
  }

  @Action(ISSUE_NEW)
  public issueNew({ patchState, getState }: StateContext<SatMotorModel>, { payload }: ISSUE_NEW) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step3: null,
        step4: null,
        step5: null,
      },
      data: {
        ...state.data,
        step3: null,
        step4: null,
        step5: null,
        isRenewNow: null,
        agentDetail: null,
        branchList: null,
        hasShowedOneAllianzPopUp: null,
      },
      declarationInfo: {
        ...state.declarationInfo,
        productName: payload.productCode,
      },
    });
  }

  @Action(SAVE_AS_DRAFT)
  public saveAsDraft({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SAVE_AS_DRAFT) {
    const state = getState();
    const geoIndValue = state.geoCodedInd;

    let data = this.prepareCoverNoteRequestPayload(state, payload, true, false, geoIndValue); console.log(data);

    return this.satMotorService.saveAsDraft(data).pipe(
      map((res) => {
        if (res.error) {
          patchState({
            saveAsDraftError: res.error
          });
        }
        else if (res.cngeNote) {
          patchState({
            saveAsDraftError: null,
            draftDetails: res.cngeNote
          });
          this.store.dispatch(new STORE_STEP2_DRAFT_NO(res.cngeNote));
          this.store.dispatch(new STORE_STEP3_DRAFT_NO(res.cngeNote));
        }
      }),
    );
  }

  @Action(CANCEL_DRAFT)
  public cancelDraft({ patchState, getState }: StateContext<SatMotorModel>, { payload }: CANCEL_DRAFT) {
    const state = getState()

    return this.satMotorService.cancelDraft(payload).pipe(
      map((res) => {
        if (res.cngeNote) {
          this.store.dispatch(new STORE_STEP3_DRAFT_NO(res.cngeNote));
        }
      }),
    );
  }

  prepareCoverNoteRequestPayload(state: SatMotorModel, payload: any, isSaveDraft: boolean, isRTP: boolean, geoIndValue: boolean) {
    let extraCoverPackage = [];
    let rtpData = {};
    let endorsementData = {};
    let data;

    if (isRTP) {
      rtpData = this.prepareRTPPayload(payload);

    } else if (payload.isEndorsement) {
      endorsementData = this.prepareEndtPayload(payload);

    } else {
      let isCnoteStatusPrinted = (
        state.motor.step4?.generateQuotation?.cnoteStatus === "PRINTED" ||
        state.motor.step4?.generateQuotation?.data?.cnoteStatus === "PRINTED" ||
        state.motor.step4?.sendToJPJ?.cnoteStatus === "PRINTED" ||
        (state.motor.step4?.summary?.coverageInfo?.coverage?.coveragePremium?.cngeNote?.cnoteStatus === "PRINTED" && !!state.retrieveQuotation)
      );

      let isCnoteStatusSaved = state.draftDetails?.cnoteStatus === "SAVED";
      let agentAdditional = state.motor.step1?.agentAdditional ?? null;

      
      let cnoteType = state.motor.step1.issuance.issuance.cnoteType ?? null;
      let vehicleNo = state?.motor?.step1?.issuance?.issuance?.vehicleNo;

      if ((cnoteType && cnoteType == 'NWTR') && state.motor?.step3?.coverage) {
        vehicleNo = state.motor.step3?.coverage?.vehicleDetails?.vehicleNo ?? vehicleNo;
      }

      data = {
        cngeNote: {
          draftInd: isSaveDraft,
          geocodedInd: state.geoCodedInd,
          productType: "MT",
          currentStep: state.currentStep,
          progress: state.currentStep,
          bankType: agentAdditional?.bankType ?? null,
          bankBranch: agentAdditional?.bankBranch ?? null,
          bankChannel: agentAdditional?.bankChannel ?? null,
          bankSubChannel: agentAdditional?.bankSubChannel ?? null,
          bankStaffNo: agentAdditional?.bankStaffNo ?? null,
          bankStaffName: agentAdditional?.bankStaffName ?? null,
          referralSalesStaffId: agentAdditional?.referralSalesStaffId ?? null,
          referralSalesStaffName: agentAdditional?.referralSalesStaffName ?? null,
          salesCampaign: agentAdditional?.salesCampaign ?? null,
          bankClientType: agentAdditional?.bankClientType ?? null,
          ...state.currentStep,
          ...state.motor.step1.issuance.issuance,
          ...state.motor.step1.issuance.pdpa.pdpaCheck,
          ...state.motor.step1.issuance.pdpa.consentDetails,
          vehicleNo: vehicleNo,
          mkagAgent: {
            ...state.motor.step1.agentDetail,
          },
        },
        operator: payload.userId,
      };

      if (payload.skipErrorAndProceed) {
        data.cngeNote.skipErrorAndProceed = true;
      }

      if (isCnoteStatusPrinted || isCnoteStatusSaved) {
        let updateVersion = "";
        let createBy = "";
        let createDate = "";
        let proposalNo = "";
        let cnoteNo = "";
        let contractId = "";

        if (isCnoteStatusSaved) {
          updateVersion = state.draftDetails?.updateVersion;
          createBy = state.draftDetails?.createBy;
          createDate = state.draftDetails?.cnoteDate;
          contractId = state.draftDetails?.contractId;
          proposalNo = state.draftDetails?.proposalNo;
        } else if(isCnoteStatusPrinted){
          updateVersion = state.motor.step4?.generateQuotation?.updateVersion ??
            state.motor.step4?.generateQuotation?.data?.updateVersion ??
            state.motor.step4?.sendToJPJ?.updateVersion ??
            state.motor.step1?.activeQuotation?.cngeNote?.updateVersion;
          
          createBy = state.motor.step4?.generateQuotation?.createBy ??
            state.motor.step4?.generateQuotation?.data?.createBy ??
            state.motor.step4?.sendToJPJ?.createBy ??
            state.motor.step1?.activeQuotation?.cngeNote?.createBy;
          
          createDate = state.motor.step4?.generateQuotation?.cnoteDate ??
            state.motor.step4?.generateQuotation?.data?.cnoteDate ??
            state.motor.step4?.sendToJPJ?.cnoteDate ??
            state.motor.step1?.activeQuotation?.cngeNote?.cnoteDate;


          proposalNo = state.motor.step4?.generateQuotation?.proposalNo ??
            state.motor.step4?.generateQuotation?.data?.proposalNo ??
            state.motor.step4?.sendToJPJ?.proposalNo ?? 
            state.motor.step1?.activeQuotation?.cngeNote?.proposalNo;

          cnoteNo = state.motor.step4?.sendToJPJ?.cnoteNo;

          contractId = state.motor.step4?.generateQuotation?.contractId ??
            state.motor.step4?.generateQuotation?.data?.contractId ??
            state.motor.step4?.sendToJPJ?.contractId ?? 
            state.motor.step1?.activeQuotation?.cngeNote?.contractId;
        }

        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            updateVersion: (isCnoteStatusPrinted || isCnoteStatusSaved || updateVersion) ? updateVersion : null,
            createBy: (isCnoteStatusPrinted || isCnoteStatusSaved || createBy) ? createBy : null,
            createDate: (isCnoteStatusPrinted || isCnoteStatusSaved || createDate) ? createDate : null,
            //proposalNo: (isCnoteStatusPrinted || proposalNo) ? proposalNo : null,
            cnoteNo: (isCnoteStatusPrinted || cnoteNo) ? cnoteNo : null,
          },
        };

        if (contractId) {
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              contractId: contractId,
            },
          };
        }

        if (isCnoteStatusPrinted || proposalNo) {
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              proposalNo: (isCnoteStatusPrinted || proposalNo) ? proposalNo : null,
            },
          };
        }

      }

      if(state.data.cancellationRes) {
        let cancellationRes = state.data.cancellationRes;

        // data.cancelReplaceQuotationCase = cancellationRes.cancelReplaceInd;
        // data.cancelRepContractId = cancellationRes.cancelReplaceContractId;
        // data.cancelRemarks = state.data.cancelRemarks;

        if (cancellationRes.cancelReplaceInd && cancellationRes.cancelReplaceContractId) {
          data.cngeNote.cancelReplaceInd = cancellationRes.cancelReplaceInd;
          data.cngeNote.cancelReplaceContractId = cancellationRes.cancelReplaceContractId;
          data.cngeNote.cancelReplaceRemarks = state.data.cancelRemarks;
          data.cngeNote.cancelReasonCode = cancellationRes.cancelReasonCode;
          data.cngeNote.cancelReplaceQuotInd = cancellationRes.cancelReplaceQuotInd;
        }
      }

      //C-centric
      if(state.opportunityId){
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            oppurtunityId: state.opportunityId
          },
        };
      }


      if (state.motor.step2) {
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            clientPartner: state.motor.step2,
          },
        };
      }

      if (state.motor.step3 && state.motor.step3.coverage) {
        data = this.populateCoverageStep3Payload(state, data, isCnoteStatusPrinted, isCnoteStatusSaved, extraCoverPackage);
      }

      if (!isSaveDraft) {
        data = this.populateNonDraftPayload(state, data, payload, extraCoverPackage);

        if(!state.data?.cancellationRes) {
          data = this.updateContractId(state, data, payload);
        }
        
        data.cngeNote = {
          ...data.cngeNote,
          cnoteDate: new Date().toISOString().substring(0, 10)
        }
      }

      if(state.opportunityId) {
        data.action = "PAYMENT_UPDATE";
      }

      if(state.motor?.step4?.paymentList) {
        data.cngeNote.paymentList = state.motor.step4.paymentList;
      }

      if(state.motor?.step4?.roadTaxPayable) {
        data.cngeNote = {
          ...data.cngeNote,
          ...state.motor.step4.roadTaxPayable
        };
      }

      if(state.motor?.step4?.upsellProduct) {
        data.cngeNote = {
          ...data.cngeNote,
          ...state.motor.step4.upsellProduct
        };
      }

      if(state.motor?.step4?.paymentInfo) {
        data.cngeNote = {
          ...data.cngeNote,
          ...state.motor.step4.paymentInfo
        };
      }    
      
      if(data?.cngeNote?.prevPolicyNo) {
        data.cngeNote.previousPolicyNo = data?.cngeNote?.prevPolicyNo;
      }

      if(state.motor?.step4?.summary?.renewalAssistance) {
        data.cngeNote = {
          ...data.cngeNote,
          ...state.motor.step4.summary?.renewalAssistance
        };
      } 


    }

    return isRTP ? rtpData : data;
  }

  updateContractId(state: SatMotorModel, data: any, payload: any): any {
    let contractId, updateVersion, createBy, createDate, updateBy, updateDate, proposalNo;

    let isRetrieveActiveQuotation = !!state.retrieveQuotation;
    let hasQuotationGenerated = !!state.motor?.step4?.generateQuotation;
    let isDraftRecord = !!state?.draftDetails;
    let isCnoteNoGenerated = !!state.motor?.step4?.sendToJPJ;
    let isDataFromView = !!state.viewQuotation || !!state.updateQuotation;
    let requoteQuotation = !!state.requoteQuotation;
    let hasEmailResponse = Boolean(state.emailDocumentResponse?.success) && state.emailDocumentResponse?.data != null;

    if (hasQuotationGenerated || isRetrieveActiveQuotation || isDraftRecord || isCnoteNoGenerated || (isDataFromView && !requoteQuotation)) {
      if (hasQuotationGenerated) {
        let cngeNote = state.motor?.step4?.generateQuotation?.data ?? state.motor?.step4?.generateQuotation;
        contractId = cngeNote.contractId;
        updateVersion = cngeNote.updateVersion;
        createBy = cngeNote.createBy;
        createDate = cngeNote.createDate;
        proposalNo = cngeNote.proposalNo;

        if (hasEmailResponse) {
          cngeNote = state.emailDocumentResponse.data;
          updateVersion = cngeNote.updateVersion;
          updateBy = cngeNote.updateBy;
          updateDate = cngeNote.updateDate;
        }

      } else if (isRetrieveActiveQuotation) {
        let cngeNote = state.motor?.step1?.activeQuotation?.cngeNote;
        contractId = cngeNote.contractId;
        updateVersion = cngeNote.updateVersion;
        createBy = cngeNote.createBy;
        createDate = cngeNote.createDate;
        proposalNo = cngeNote.proposalNo;

      } else if (isDraftRecord) {
        let cngeNote = state.draftDetails;
        contractId = cngeNote.contractId;
        updateVersion = cngeNote.updateVersion;
        createBy = cngeNote.createBy;
        createDate = cngeNote.createDate;

      } else if (isCnoteNoGenerated) {
        let cngeNote = state.motor?.step4?.sendToJPJ?.data ?? state.motor?.step4?.sendToJPJ;
        contractId = cngeNote.contractId;
        updateVersion = cngeNote.updateVersion;
        createBy = cngeNote.createBy;
        createDate = cngeNote.createDate;
        proposalNo = cngeNote.proposalNo;
      
      } else if (isDataFromView) {
        let cngeNote = state.updateQuotation || state.viewQuotation;
        contractId = cngeNote.contractId;
        updateVersion = cngeNote.updateVersion;
        createBy = cngeNote.createBy;
        createDate = cngeNote.createDate;
        proposalNo = cngeNote.proposalNo;
      }

      data.cngeNote = {
        ...data.cngeNote,
        contractId,
        updateVersion,
        createBy,
        createDate,
        ...(hasEmailResponse && {
          updateVersion,
          updateBy,
          updateDate
        }),
        ...(proposalNo && {
          proposalNo
        }),
      };

    } else {
      let hasActiveQuotation = !!state.motor?.step1?.activeQuotation;

      if (hasActiveQuotation && !isRetrieveActiveQuotation) {
        data.cngeNote = this.setNullForTags(data.cngeNote);
        data.cngeNote.contractId = null;
      
      } else {
        if (data.cngeNote) {
          if ('contractId' in data.cngeNote) {
            data.cngeNote.contractId = null;
          }
          if ('updateVersion' in data.cngeNote) {
            delete data.cngeNote.updateVersion;
          }
        }
      }
    }

    return data;

  }

  setNullForTags(obj: any) {
    if (!obj || typeof obj !== 'object') return obj;

    const tagsToNullify = ['contractId', 'riskId', 'coverId', 'subcoverId', 'updateVersion'];
    const newObj = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (tagsToNullify.includes(key)) {
        newObj[key] = null;
      } else if (typeof obj[key] === 'object') {
        newObj[key] = this.setNullForTags(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }

    return newObj;
  }

  populateCoverageStep3Payload(state: SatMotorModel, data: any, isCnoteStatusPrinted, isCnoteStatusSaved, extraCoverPackage): any {
    let isDraftRecord = !!state?.draftDetails;
    let premiumInfo = state.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null;
    let activeSelectionPopup = state?.activeSelectionPopup;
    let activeQuotation = state?.motor?.step1?.activeQuotation;
    let proposalNo = state?.motor?.step4?.generateQuotation?.data?.proposalNo;
    
    if(!premiumInfo && isDraftRecord) {
      premiumInfo = state?.draftDetails.riskList[0].coverList[0];
    }

    const { coverDto, cngeCoverNcdInfoList, cngeSubCoverUpSellList, coverLoadingList, ...remainingData } = premiumInfo || {};

    if (state.motor.step3.coverage) {
      if (state.motor.step3.coverage.vehicleDetails) {
        let vehicleDetailsData = state.motor.step3.coverage.vehicleDetails;
        let ismRefNo =
          state.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.ismRefNo ||
          state.motor.step3?.coverage?.ncdInfo?.ismRefNo;
      
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            riskList: [
              {
                ...state.motor.step3.coverage.vehicleDetails,
                riskType: "VEH",
                ...(ismRefNo !== null && ismRefNo !== undefined ? { ismRefNo } : {}),
              },
            ],
            region: vehicleDetailsData.region,
          },
        };
      }

      if (state.motor.step3.coverage.coverageType) {
        let vehicleDetailsData = state.motor.step3.coverage.vehicleDetails;
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            riskList: [
              {
                ncdEffDate :state?.motor?.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.ncdEffDate,
                prevNcdVeh :state?.motor?.step3?.coverage?.coverageType?.prevNcdVeh,
                prevNcdInsurer : state.motor?.step3?.coverage?.coverageType?.prevNcdInsurer,
                ...data.cngeNote.riskList[0],
                avInd : state.motor?.step3?.coverage?.coverageType?.avInd,
                coverList: [
                  {
                    ...state.motor.step3.coverage.coverageType,
                    makeModelSiMin:
                    vehicleDetailsData.region === "W"
                        ? vehicleDetailsData.avWmSiMin
                        : vehicleDetailsData.avEmSiMin,
                    makeModelSiMax:
                    vehicleDetailsData.region === "W"
                        ? vehicleDetailsData.avWmSiMax
                        : vehicleDetailsData.avEmSiMax,
                    importStatus: vehicleDetailsData.importStatus,
                    cngeSubCoverUpSellList,
                    cngeCoverNcdInfoList
                  },
                ],
              },
            ],
          },
        };

        if(state?.motor?.step3?.coverage?.ncdInfo?.ismResCode){
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [{
                ...data.cngeNote.riskList[0],
                coverList: [{
                  ...data.cngeNote.riskList[0].coverList[0],
                  coverDto: {
                    ncdEnquiryResponseCode: state?.motor?.step3?.coverage?.ncdInfo?.ismResCode 
                  },
                }],
              }],
            },
          };
        }

        if (coverLoadingList) {
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [{
                ...data.cngeNote.riskList[0],
                coverList: [{
                  ...data.cngeNote.riskList[0].coverList[0],
                  ...state.motor.step3.coverage.coverageType,
                  coverLoadingList
                }],
              }],
            },
          };
        }
      }

      if (state.motor.step3.coverage.extracover) {
        let cngeRiskDrvrDtoListNamedDriver = [];
        let cngeRiskDrvrDtoListEhailing = [];
        let vehicleUnNamedDrvrInd = null;
        let vehicleAlldrvrInd = null;

        if (state.motor.step3.coverage.extracover.driverNameDetails?.rows) {
          const namedDriverList = state.motor.step3.coverage.extracover.driverNameDetails.rows;
          vehicleUnNamedDrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleUnNamedDrvrInd;
          vehicleAlldrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleAlldrvrInd;
          cngeRiskDrvrDtoListNamedDriver = namedDriverList.map((driver) => {
            let driverName;
            if (driver.driverName) {
              driverName = driver.driverName;
            } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
              driverName = driver.cngeRiskDrvrPkDto.driverName;
            }

            let driverSeqValue = driver?.driverSeq || state.motor.step3.coverage.vehicleDetails?.cngeRiskDrvrDtoList?.[0]?.driverSeq;
            // if (isDraftRecord) {
            //   driverSeqValue = driver?.driverSeq ?? state.motor.step3.coverage.vehicleDetails?.cngeRiskDrvrDtoList?.[0]?.driverSeq ?? null;
            // } else {
            //   driverSeqValue = (isCnoteStatusPrinted || isCnoteStatusSaved)
            //     ? state.motor.step3.coverage.vehicleDetails?.cngeRiskDrvrDtoList?.[0]?.driverSeq ?? driver?.driverSeq ?? 1
            //     : driver?.driverSeq ?? 1;
            // }

            let cngeRiskDrvrPkDto = {
              cngeRiskDrvrPkDto: {
                driverName: driverName ? driverName : "",
                driverType: "NORM",
              },
              driverId: driver.driverId,
              driverAge: driver.driverAge,
              driverSeq: driverSeqValue
            };

            return cngeRiskDrvrPkDto;
          });
        }

        if (state.motor.step3.coverage.extracover.ehailingDriverDetails?.rows) {
          const ehailingDriverList = state.motor.step3.coverage.extracover.ehailingDriverDetails.rows;
          cngeRiskDrvrDtoListEhailing = ehailingDriverList.map((driver) => {
            let driverName;
            if (driver.driverName) {
              driverName = driver.driverName;
            } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
              driverName = driver.cngeRiskDrvrPkDto.driverName;
            }

            let cngeRiskDrvrPkDto = {
              cngeRiskDrvrPkDto: {
                driverName: driverName ? driverName : "",
                driverType: "EHAIL",
              },
              driverId: driver.driverId,
              driverAge: 0, // Assuming `0` is the default driver age for ehailing drivers
              driverSeq: driver.driverSeq,
            };

            return cngeRiskDrvrPkDto;
          });
        }

        // Merge the lists and update `data`
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            riskList: [
              {
                ...data.cngeNote.riskList[0],
                cngeRiskDrvrDtoList: [
                  ...cngeRiskDrvrDtoListNamedDriver,
                  ...cngeRiskDrvrDtoListEhailing
                ],
                coverList: [{
                  ...data.cngeNote.riskList[0].coverList[0],
                  vehicleUnNamedDrvrInd: vehicleUnNamedDrvrInd,
                  vehicleAlldrvrInd: vehicleAlldrvrInd,
                  coverDto: {
                    ...data.cngeNote.riskList[0].coverList[0].coverDto,
                    tmpNcdConfirmationError: state.motor.step3?.coverage?.ncdInfo?.tmpNcdConfirmationError
                  }
                }],
              },
            ],
          },
        };

        if (state.motor.step3.coverage.extracover.extraCoverPackage) {
          for (let cv of state.motor.step3.coverage.extracover.extraCoverPackage) {
            extraCoverPackage.push({
              ...cv,
              subCoverCodeDescription: cv.description,
              selectedInd: cv.selected,
              enableInd: cv.enabled,
              subCoverSumInsured: cv.subCoverSumInsured,
              subCoverDays: cv.subCoverNumberOfDays,
              subCoverAmtday: cv.subCoverAmtPerDay > 0 ? cv.subCoverAmtPerDay : (cv.subCoverAmtday > 0 ? cv.subCoverAmtday : 0),
              // Only add subCoverBenefitList if subCoverCode is 'PAB3A'
              ...(cv.subCoverCode === 'PAB3' && { subCoverBenefitList: cv.cngeSubCoverBenefitDtoList || null })
            });
          }

          let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
          if(!cngeNotePremium && isDraftRecord) {
            cngeNotePremium = state?.draftDetails;
          }

          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              premiumDueRounded: cngeNotePremium.premiumDueRounded,
              stamp: cngeNotePremium.stamp,
              riskList: [
                {
                  ...data.cngeNote.riskList[0],
                  coverList: [{
                    ...data.cngeNote.riskList[0].coverList[0],
                    subCovPackage: cngeNotePremium.riskList[0].coverList[0].subCovPackage,
                    subCovPackageDesc: cngeNotePremium.riskList[0].coverList[0].subCovPackageDesc,
                    subCoverList: [
                      ...extraCoverPackage
                    ]
                  }],
                },
              ],
            },
          };
        }
      }

      //Active quotation > Select No > generate quotation > send to jpj
      if (activeQuotation && !activeSelectionPopup && proposalNo && data.cngeNote.riskList.length > 0) {
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            riskList: [
              { ...data.cngeNote.riskList[0], 
                riskId: null },
            ]
          }
        };
      }
      
    }
    return data;
  }

  populateNonDraftPayload(state: SatMotorModel, data: any, payload: any, extraCoverPackage: any[]): any {
    let premiumInfo = state.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null;
    let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
    let riskList = cngeNotePremium.riskList[0];
    const isSundriesAgent = state.motor?.step1?.agentDetail?.sundriesAgent ?? null;

    if (premiumInfo) {
      //added subCoverList because having duplicate subCoverList
      const { coverDto, cngeCoverNcdInfoList, avInd, subCoverList, ...remainingData  } = premiumInfo;

      if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0) {
        if (data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
          const { cngeSubCoverUpSellList, ...coverListWithoutUpSellList } = remainingData;
          data.cngeNote.riskList[0].coverList[0] = {
            ...data.cngeNote.riskList[0].coverList[0],
            ...coverListWithoutUpSellList,
            cngeCoverNcdInfoList,
            cngeSubCoverUpSellList,
            subCoverList: [
              ...extraCoverPackage
            ]
          };
        }
      }

      const { commissionAmount } = remainingData;
      data.cngeNote = {
        ...data.cngeNote,
        ...remainingData,
        cbcAmount: 0.00,
        cbcAdvanceAmt: 0.00,
        referRemarkList: payload.referRemarkList,
        referWaiveLoad: payload.waiveLoadInd,
        referWaiveExcess: payload.waiveExcessInd,
        referUnderWriting: payload.underwritingInd,
        agentReferId: payload.agentReferId,
        ...(payload.contractId && {
          proposalNo: payload.proposalNo,
          contractId: payload.contractId,
          cnoteDate: payload.cnoteDate,
          issueBy: payload.issueBy,
          issuedDate: payload.issuedDate
        }),
        premiumDueRounded: cngeNotePremium.premiumDueRounded,
        stamp: cngeNotePremium.stamp,
        commisionAmount: commissionAmount
      };

      //update contractId fields to have latest contractId
      if (payload.contractId && !state.data?.cancellationRes) {
        const updateContractId = (obj) => {
          if (Array.isArray(obj)) {
            return obj.map(item => updateContractId(item));
          } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
              if (key === 'contractId') {
                acc[key] = payload.contractId;
              } else {
                acc[key] = updateContractId(obj[key]);
              }
              return acc;
            }, {});
          } else {
            return obj;
          }
        };
      
        data.cngeNote = updateContractId(data.cngeNote);
      }

      //Check and tally the sumInsured values
      if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0 &&
        data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
        if (data.cngeNote.riskList[0].coverList[0].sumInsured !== data.cngeNote.sumInsured) {
          data.cngeNote.riskList[0].coverList[0].sumInsured = data.cngeNote.sumInsured;
        }
      }

      if (riskList.ismRefNo) {
        data.cngeNote.riskList[0].ismRefNo = riskList.ismRefNo;
      }

      if (isSundriesAgent) {
        data.cngeNote.rebateType = cngeNotePremium.rebateType;
        data.cngeNote.riskList[0].coverList[0].rebateType = cngeNotePremium.rebateType;
      }
    }
    return data;
  }

  prepareEndtPayload(payload: any): {} {
    let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    let endorsementData = {};

      if (cngeNote && cngeNote.riskList) {
        let risk = cngeNote.riskList[0];
        let cover = risk.coverList[0];

        endorsementData = {
          cngeNoteDto: {
            cnoteType: cngeNote.cnoteType,
            productCode: cngeNote.productCode,
            productType: cngeNote.productType,
            effectiveDate: cngeNote.effectiveDate,
            expiryDate: cngeNote.expiryDate,
            cnoteDate: cngeNote.cnoteDate,
            endtEffectiveDate: cngeNote.endtEffectiveDate,
            endtExpiryDate: cngeNote.endtExpiryDate,
            proposalDate: cngeNote.cnoteDate,
            sumInsured: cngeNote.sumInsured,
            grossPremium: cover?.grossPremium,
            subCoverPremium: cover?.subCoverPremium,
            annualPremium: cover?.annualPremium,
            premiumDue: cover?.premiumDue,
            rebateType: "NR",
            totalHeadCount: cngeNote.totalHeadCount,
            cnoteBranch: cngeNote.mkagAgent.branchCode,
            lineOfBusiness: cngeNote.productType,
            region: cngeNote.mkagAgent.region,
            referProductCode: "",
            takeOverInd: cngeNote.takeOverInd,
            riskList: cngeNote.riskList,
            mkagAgent: cngeNote.mkagAgent,
            clientPartner: cngeNote.clientPartner,
            trailerRiskType: "XPM",
            premiumDueRounded: cngeNote.premiumDueRounded,
            stamp: cngeNote.stamp
          },
          operator: payload.userId,
          productType: "MT"
        };
      }

      return endorsementData;
  }

  prepareRTPPayload(payload): {} {
    let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto);
    let rtpData = {}; 
     
    if (cngeNote && cngeNote.riskList) {
        let risk = cngeNote.riskList[0];
        let cover = risk.coverList[0];

        rtpData = {
          cngeNoteDto: {
            cnoteType: cngeNote.cnoteType,
            productCode: cngeNote.productCode,
            productType: cngeNote.productType,
            effectiveDate: cngeNote.effectiveDate,
            expiryDate: cngeNote.expiryDate,
            cnoteDate: cngeNote.cnoteDate,
            endtEffectiveDate: cngeNote.endtEffectiveDate,
            endtExpiryDate: cngeNote.endtExpiryDate,
            proposalDate: cngeNote.cnoteDate,
            sumInsured: cngeNote.sumInsured,
            grossPremium: cover?.grossPremium,
            subCoverPremium: cover?.subCoverPremium,
            annualPremium: cover?.annualPremium,
            premiumDue: cover?.premiumDue,
            rebateType: "NR",
            totalHeadCount: cngeNote.totalHeadCount,
            cnoteBranch: cngeNote.mkagAgent.branchCode,
            lineOfBusiness: cngeNote.productType,
            region: cngeNote.mkagAgent.region,
            referProductCode: "",
            takeOverInd: cngeNote.takeOverInd,
            riskList: cngeNote.riskList,
            mkagAgent: cngeNote.mkagAgent,
            clientPartner: cngeNote.clientPartner,
            trailerRiskType: "XPM",
            premiumDueRounded: cngeNote.premiumDueRounded,
            stamp: cngeNote.stamp
          },
          operator: payload.userId,
          productType: "MT"
        };
      }

      return rtpData;
  }

  @Action(SAVE_CUSTOMER_PARTNER)
  public saveCustomerPartner({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SAVE_CUSTOMER_PARTNER) {
    const state = getState();
    const geoIndValue = state.geoCodedInd;
    let data = this.prepareCoverNoteRequestPayload(state, payload, true, false, geoIndValue); console.log(data);
    return this.satMotorService.saveCustomerPartner(payload).pipe(
      map((res) => {
        if (res.data) {
          dispatch(new STORE_STEP2(res.data));
        }
      }),
    );
  }

  @Action(GET_UPSELL_PRODUCT_LOV)
  public getUpsellProductLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_UPSELL_PRODUCT_LOV) {
    const state = getState();

    return this.satMotorService.getUpsellProductLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            vehicleClassLov: res.data,
            upsellProductLov: res.data,
          });
        }
      }),
    );
  }

  @Action(STORE_UPSELL_PRODUCT)
  public storeUpsellProduct({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_UPSELL_PRODUCT) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step4: {
          ...state.motor.step4,
          upsellProduct: payload,
        },
      },
    });
  }

  @Action(GET_PAYMENT_MODE_LOV)
  public getPaymentModeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PAYMENT_MODE_LOV) {
    const state = getState();

    return this.satMotorService.getPaymentLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            paymentModeLov: res.data,
          });
        }
      }),
    );
  }

  @Action(GET_PAYMENT_TO_LOV)
  public getPaymentToLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PAYMENT_TO_LOV) {
    const state = getState();

    return this.satMotorService.getPaymentLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            paymentToLov: res.data,
          });
        }
      }),
    );
  }

  @Action(GET_POSTCODE_LOV)
  public getPostcodeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_PAYMENT_TO_LOV) {
    const state = getState();

    return this.satMotorService.getPostcodeLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            lov: {
              ...state.lov,
              clientPostcodeList: res.data.clientPostcodeList,
            },
          });
        }
      }),
    );
  }

  @Action(GET_POSTCODE_INFO)
  public getPostcodeInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_POSTCODE_INFO) {
    const state = getState();

    return this.satMotorService.getPostcodeInfo(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              postCodeInfo: res.data,
            },
          });
        }
      }),
    );
  }

  @Action(GET_CROSS_SELL_LIST)
  public getCrossSellList({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload, productCode }: GET_CROSS_SELL_LIST) {
    const state = getState();

    return this.satMotorService.getCrossSellList(payload).pipe(
      map((res) => {
        if (res) {
          let crossSellList = res.crossSellList.map((crossSell) => {
            // if (crossSell.productCode != productCode) {
              let responseBannerImg = {
                file: {
                  data: crossSell.bannerImg,
                },
              };
              let bannerImgBlob = this.satMotorService.generateBlob(responseBannerImg);
              console.log("Banner Img Blob:", bannerImgBlob);
              let bannerImgURL = URL.createObjectURL(bannerImgBlob);

              return {
                ...crossSell,
                bannerImg: bannerImgURL,
              };
            // }
          });

          if(crossSellList && crossSellList.length > 0) {
            crossSellList = crossSellList.filter(a => a.productCode != productCode);
          }

          patchState({
            data: {
              ...state.data,
              step5: {
                ...(state.data.step5 ? state.data.step5 : null),
                crossSellList: crossSellList,
                marketingConsent: res.marketingConsent,
              },
            },
          });
        }
      }),
    );
  }
  @Action(STORE_AGENT)
  public storeAgent(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: STORE_AGENT) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...state.motor.step1,
          agentDetail: payload,
        },
      },
    });
  }

  @Action(COPY_CNGE_INFO)
  public copyCngeInfo(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_CNGE_INFO) {
    const state = getState();

    let step1 = {
      step1: {
        agentDetail: payload.mkagAgent,
        issuance: {
          issuance: {
            cnoteType: payload.cnoteType,
            vehicleNo: payload.vehicleNo,
            prevPolicyNo: payload.previousPolicyNo,
            productCode: payload.productCode,
            effectiveDate: payload.effectiveDate,
            expiryDate: payload.expiryDate,
            cnoteDate: payload.cnoteDate
          },
          pdpa: {
            pdpaCheck: true,
            consentDetails: {
              edocConsentInd: payload.edocConsentInd,
              einvoiceConsentInd: payload.einvoiceConsentInd
            }
          }
        },
      }
    };

    let data2;
    let step2;

    data2 = {
      step2: {
        store: {
          ...payload.clientPartner
        }
      }
    };

    step2 = {
      step2: {
        showSearchComponent: false,
        ...payload.clientPartner
      }
    };

    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
    this.store.dispatch(new STORE_STEP2_CP_SCREEN({
      cpScreen: "FOUND",
      isCompOrCustInfoIncomplete: false,
      isContactInfoIncomplete: false,
      isCorrespondenceInfoIncomplete: false
    }));


    let data3;
    let step3;
    data3 = {
      step3: {
        coverage: {
          selectedMakeModel: payload.riskList[0]
        }
      }
    };

    step3 = {
      step3: {
        coverage: {
          vehicleDetails: {
            ...payload.riskList[0],
            region: payload.region
          },
          coverageType: {
            ...payload.riskList[0]?.coverList[0],
          },
          extracover: {
            driverNameDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "NORM")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                  driverAge: a.driverAge
                }
              }),
              vehicleUnNamedDrvrInd: payload.riskList[0].coverList[0].vehicleUnNamedDrvrInd,
              vehicleAlldrvrInd: payload.riskList[0].coverList[0].vehicleAlldrvrInd
            },
            ehailingDriverDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "EHAIL")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                }
              }),
            },
            extraCoverPackage: payload.riskList[0]?.coverList[0]?.subCoverList,
          },
          coveragePremium: {
            cngeNote: { ...payload }
          }
        },
      },
    }

    this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ({
      isIssuance: true
    }));

    patchState({
      // data: {
      //   ...data2,
      //   ...data3
      // },
      motor: {
        ...step1,
        ...step2,
        ...step3,
        step4: null,
        step5: null
      }
    });
  }

  @Action(COPY_CNGE_INFO_RENEWAL)
  public copyCngeInfoRenewal(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_CNGE_INFO_RENEWAL) {
    const state = getState();

    //agent
    this.store.dispatch(new STORE_AGENT(payload.mkagAgent));

    //declaration
    let declarationInfo = {
      issuance: {
        cnoteType: payload.cnoteType,
        vehicleNo: payload.vehicleNo,
        prevPolicyNo: payload.previousPolicyNo,
        productCode: payload.productCode,
        effectiveDate: payload.effectiveDate,
        expiryDate: payload.expiryDate
      },
      pdpa: {
        pdpaCheck: true,
        consentDetails: {
          edocConsentInd: payload.edocConsentInd,
          einvoiceConsentInd: payload.einvoiceConsentInd
        }
      }
    };

    //customer partner
    const step2Search: STEP2.SearchQuery = {
      idType: payload.clientPartner.idType1,
      idValue: payload.clientPartner.idValue1,
      agentCode: payload.mkagAgent.agentCode,
      operator: null,
      productType: "MT"
    };
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
    this.store.dispatch(new STORE_STEP2_SEARCH(step2Search));
    this.store.dispatch(new STORE_STEP2_RENEWAL(payload.clientPartner));

    //coverage
    if (payload.riskList[0]) {
      this.store.dispatch(new STORE_STEP3_RENEWAL(payload));
    }

    let renewalAssistance = {
      autoRenewalInd: payload.autoRenewalInd,
      creditCardType: payload.creditCardType,
      creditCardNo: payload.creditCardNo,
      creditCardExpMonth: payload.creditCardExpMonth? payload.creditCardExpMonth.toString() : null,
      creditCardExpYear: payload.creditCardExpYear? payload.creditCardExpYear.toString() : null,
      creditCardHolderName: payload.creditCardHolderName,
      autoRenewalStartDt: payload.autoRenewalStartDt,
      termsOfUse: payload.termsOfUse
    }

    this.store.dispatch(new STORE_RENEWAL_ASSISTANCE(renewalAssistance));

    // patchState({

    // });
  }

  @Action(COPY_CNGE_TO_ENDORSEMENT_STATE)
  public copyCngeToEndorsementState(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_CNGE_TO_ENDORSEMENT_STATE) {
    const state = getState();

    //Store Step1 - Declaration
    const step1 = state.endorsement?.step1 || {};
    const cngeNote = step1.endtVehicleData;

    let step1FormData = {
      issuance: {
        vehicleNo: cngeNote.riskList[0].vehicleNo,
        policyNo: cngeNote.policyNo,
        productCode: cngeNote.productCode,
        policyEffectiveDate: cngeNote.effectiveDate,
        policyExpiryDate: cngeNote.expiryDate,
        endorsementType: cngeNote.cnoteType,
        endtEffectiveDate: cngeNote.endtEffectiveDate,
        endtExpiryDate: cngeNote.endtExpiryDate,
        previousExtension: cngeNote.previousExtension
      },
      pdpa: {
        pdpaCheck: true,
        consentDetails: {
          edocConsentInd: "",
          einvoiceConsentInd: cngeNote.einvoiceConsentInd
        }
      },
      isRTP: false,
      isEndorsement: true
    }

    patchState({
      endorsement: {
        ...state.endorsement,
        step1: {
          ...step1,
          issuance: step1FormData
        }
      },
    });


    //Store Step3 - Summary
    let step3GenerateQuotationData = {
      data: {
        ...cngeNote
      },
      success: true
    };

    patchState({
      endorsement: {
        ...state.endorsement,
        step3: {
          ...(state.endorsement?.step3 || {}),
          generateQuotation: step3GenerateQuotationData,
        },
      },
    });

  }

  @Action(COPY_CNGE_INFO_VIEW_QUOTATION)
  public copyCngeInfoViewQuotation(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_CNGE_INFO_VIEW_QUOTATION) {
    const state = getState();

    //agent
    this.store.dispatch(new STORE_AGENT(payload.mkagAgent));

    //declaration
    let declarationInfo = {
      issuance: {
        cnoteType: payload.cnoteType,
        vehicleNo: payload.vehicleNo,
        prevPolicyNo: payload.previousPolicyNo,
        prevExpDate: payload?.prevExpDate,
        productCode: payload.productCode,
        effectiveDate: payload.effectiveDate,
        expiryDate: payload.expiryDate,
        equotationNo: payload.proposalNo,
        proposalNo: payload.proposalNo,
        equotationCreatedDate: payload.createDate,
        updateVersion: payload.updateVersion ? payload.updateVersion : 0,
        cnoteDate: payload.cnoteDate,
      },
      pdpa: {
        pdpaCheck: true,
        consentDetails: {
          edocConsentInd: payload.edocConsentInd,
          einvoiceConsentInd: payload.einvoiceConsentInd
        }
      }
    };

    this.store.dispatch(new STORE_DECLARATION(declarationInfo));

    //customer partner
    const step2Search: STEP2.SearchQuery = {
      idType: payload.clientPartner.idType1,
      idValue: payload.clientPartner.idValue1,
      agentCode: payload.mkagAgent.agentCode,
      operator: null,
      productType: "MT"
    };
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
    this.store.dispatch(new STORE_STEP2_SEARCH(step2Search));
    this.store.dispatch(new STORE_STEP2_RENEWAL(payload.clientPartner));
    this.store.dispatch(new STORE_STEP2_CP_SCREEN({
      cpScreen: "FOUND",
      isCompOrCustInfoIncomplete: false,
      isContactInfoIncomplete: false,
      isCorrespondenceInfoIncomplete: false
    }));

    //coverage
    if (payload.riskList[0]) {
      this.store.dispatch(new STORE_STEP3_RENEWAL(payload));
    }

    if(payload.cnoteNo) {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...(state.motor?.step4 || {}),
            sendToJPJ: payload,
          },
        },
      });
    }else {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...(state.motor?.step4 || {}),
            generateQuotation: payload,
          },
        },
      });
    }

    if(payload.paymentList) {
      this.store.dispatch(new UPDATE_PAYMENT_LIST({isEndorsement: false, paymentList:payload.paymentList}));
    }

    let roadTaxPayable = {
      roadTax: payload.roadTax,
      myegFees: payload.myegFees,
      courierFees: payload.courierFees,
      merchantCharges: payload.merchantCharges,
      gstRoadTaxAmt: payload.gstRoadTaxAmt
    }
    this.store.dispatch(new STORE_ROAD_TAX_PAYABLE({isEndorsement: false, roadTaxPayable: roadTaxPayable}));

    let upsellProduct = {
      upSellType: payload.upSellType,
      upSellAmt: payload.upSellAmt
    }
    this.store.dispatch(new STORE_UPSELL_PRODUCT(upsellProduct));

    let paymentInfo = {
      totalPayable: payload.totalPayable ? payload.totalPayable : payload.riskList[0].coverList[0].premiumDue,
      totalPayableRounded: payload.totalPayableRounded ? payload.totalPayableRounded : payload.premiumDueRounded,
      totalPaid: payload.totalPaid,
      balancePayable: payload.balancePayable
    };
    this.store.dispatch(new STORE_PAYMENT_INFO({isEndorsement: false, paymentInfo: paymentInfo}));

    let renewalAssistance = {
        autoRenewalInd : payload.autoRenewalInd,
        creditCardType : payload.creditCardType,
        creditCardNo : payload.creditCardNo,
        creditCardExpMonth : payload.creditCardExpMonth,
        creditCardExpYear : payload.creditCardExpYear,
        creditCardHolderName : payload.creditCardHolderName,
        autoRenewalStartDt : payload.autoRenewalStartDt,
        termsOfUse : payload.termsOfUse
    }

    this.store.dispatch(new STORE_RENEWAL_ASSISTANCE(renewalAssistance));

  }

  @Action(GET_ISS_PRODUCT_PDF)
  public getIssMarketingMaterialDocList(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: GET_ISS_PRODUCT_PDF) {
    const state = getState();

    return this.satMotorService.getProductPDF(payload).pipe(
      map((res) => {
        if (res) {
          const pdfArray = [];

          for (const folder of res.folders) {
            for (const folder2 of folder.folders) {
              for (const folder3 of folder2.folders) {
                for (const folder4 of folder3.folders) {
                  for (const folder5 of folder4.folders) {
                    if (!folder5.contents) continue
                    for (const content of folder5.contents) {
                      if (content.contentFields[0].contentFieldValue.document.fileExtension.toLowerCase() == 'pdf') {
                        pdfArray.push(content);
                      }
                    }
                  }
                }
              }
            }
          }

          console.log('pdfArr: ', pdfArray);

          patchState({
            data: {
              ...state.data,
              step5: {
                ...state.data.step5,
                folderContents: pdfArray
              }
            }
          });
        }
      }),
    );
  }

  @Action(SAVE_ISS_MESSAGE_SENT_LOG)
  saveMessageSentLog(
    { patchState }: StateContext<SatMotorModel>,
    { payload }: SAVE_ISS_MESSAGE_SENT_LOG
  ) {
    return this.satMotorService.saveMessageLog(payload).subscribe();
  }

  @Action(SAVE_ISS_EMAIL_SENT_LOG)
  public saveEmailSentLog(
    { patchState }: StateContext<SatMotorModel>,
    { payload }: SAVE_ISS_EMAIL_SENT_LOG
  ) {
    return this.satMotorService.saveEmailLog(payload).subscribe();
  }

  @Action(GET_CCENTRIC_OPPORTUNITY_INFO)
  public getCCentricOpportunityInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_CCENTRIC_OPPORTUNITY_INFO) {
    const state = getState();
    return this.satMotorService.getCCentricOpportunityInfo(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            cCentricInfo: res.data
          });

          if(res.data.mkagAgent){
            patchState({
              viewQuotation: res.data,
              viewQuotationData: this.readDataFromCngeNote(res.data, false, true)
            });
  
            patchState({
              requoteQuotation: res.data
            });
          }
          
        }
      }),
    );
  }

  @Action(GET_SWITCH_SITE)
  public getSwitchSite({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_SWITCH_SITE) {
    const state = getState();

    patchState({
      data: {
        ...state.data,
        switchSiteUrl: null
      }
    });

    return this.satMotorService.getSwitchSite(payload).pipe(
      map((res) => {
        if (res && res.redirectUrl) {
          patchState({
            data: {
              ...state.data,
              switchSiteUrl: res.redirectUrl
            }
          });
        } else {
          console.error('Redirect URL is missing or malformed:', res);
          return null; // Or return a fallback URL
        }
      }),
    );
  }

  @Action(GET_DRAFT_DETAILS)
  public getDraftDetails({ patchState, getState, dispatch }: StateContext<SatMotorModel>, {  payload, action }: GET_DRAFT_DETAILS) {
    const state = getState();
    return this.satMotorService.getQuotationDetailWithAction(payload, action).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            ...state,
            draftDetails: res.data
          });
        }
      }),
    );
  }

  @Action(GET_QUOTATION_DETAIL)
  public getQuotationDetail({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload, action }: GET_QUOTATION_DETAIL) {
    const state = getState();
    return this.satMotorService.getQuotationDetailWithAction(payload, action).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            viewQuotation: res.data,
            viewQuotationData: this.readDataFromCngeNote(res.data, false, true)
          });
        }
      }),
    );
  }

  @Action(GET_QUOTATION_DETAIL_REQUOTE)
  public getQuotationDetailToRequote({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload, action }: GET_QUOTATION_DETAIL_REQUOTE) {
    const state = getState();
    if (action) {
      return this.satMotorService.getQuotationDetailWithAction(payload, action).pipe(
        map((res) => {
          if (res && res.data) {
            if (action === 'SUMMARY-UPDATE') {
              patchState({
                updateQuotation: res.data
              });
            }else{
              patchState({
                requoteQuotation: res.data
              });
            }
          }
        }),
      );
    } else {
      return this.satMotorService.getQuotationDetailToRequoteNoAction(payload).pipe(
        map((res) => {
          if (res && res.data) {
            patchState({
              requoteQuotation: res.data
            });
          }
        }),
      );
    }
  }

  readDataFromCngeNote(data: any, isViewPolicy: boolean, isViewQuotation: boolean): any {
    const { mkagAgent, riskList = [{}], clientPartner: cpData, referRemarkList = [], ...cngeNote } = data;
    const [risk] = riskList;
    const [coverList] = riskList[0]?.coverList || [];
    const [remarks] = referRemarkList;

    // Agent Data
    const agentData = {
      agentCode: mkagAgent.agentCode,
      name: mkagAgent.name,
      issuedBy: cngeNote.issueBy,
      issuedDate: cngeNote.cnoteDate,
    };

    // Declaration issuance
    const issuanceInfo = {
      issuance: {
        productCode: cngeNote.productCode,
        vehicleNo: cngeNote.vehicleNo ?? risk.vehicleNo,
        cnoteType: cngeNote.cnoteType,
        prevPolicyNo: cngeNote.previousPolicyNo,
        effectiveDate: cngeNote.effectiveDate,
        expiryDate: cngeNote.expiryDate,
        equotationNo: cngeNote.proposalNo,
        equotationCreatedDate: cngeNote.createDate,
        eCoverNoteNo: cngeNote.cnoteNo,
        jpjStatus: risk.jpjStatus,
        jpjMessage: cngeNote.jpjMessage,
        policyNo: cngeNote.policyNo,
        endtEffectiveDate: cngeNote.endtEffectiveDate,
        endtExpiryDate: cngeNote.endtExpiryDate,
        endtReferenceNo: cngeNote.endtReferenceNo,
        endtNo: cngeNote.endtNo
      },
      pdpa: {
        consentDetails: {
          edocConsentInd: cngeNote.edocConsentInd,
          einvoiceConsentInd: cngeNote.einvoiceConsentInd,
        },
      },
    };

    // Coverage Info
    const coverageInfo = {
      vehicleDetails: isViewPolicy ?
        {
          ...risk,
          makeModel: risk.vehicleModelDescp ? risk.vehicleModelDescp : 'N/A',
          region: cngeNote.region
        } : {
          ...risk,
          region: cngeNote.region
        },
      coverageDetails: {
        ...coverList,
        makeModelSiMin: cngeNote.region === 'W' ? risk.avWmSiMin : cngeNote.region === 'E' ? risk.avEmSiMin : undefined,
        makeModelSiMax: cngeNote.region === 'W' ? risk.avWmSiMax : cngeNote.region === 'E' ? risk.avEmSiMax : undefined,
        noClaimDiscountEffectiveDate:risk.ncdEffDate?risk.ncdEffDate : 'N/A',
        prevNcdVeh :risk.prevNcdVeh?risk.prevNcdVeh : 'N/A',
        prevNcdInsurer :risk.prevNcdInsurer?risk.prevNcdInsurer : 'N/A',
        avInd: risk.avInd ? Boolean(risk.avInd) : Boolean(coverList.avInd),
        loadingPctVehAge: this.getLoadingPct(coverList, 'VEHAGE'),
        loadingPctDrvrAge: this.getLoadingPct(coverList, 'DRVRAGE'),
        loadingPctClmExp: this.getLoadingPct(coverList, 'CLMEXP')
      },
      // driverNameDetails: this.getDriverListData(risk.cngeRiskDrvrDtoList, true),
      driverNameDetails: isViewPolicy ? this.getDriverListDataPolicy(risk.riskDrvrList, true) :  this.getDriverListData(risk.cngeRiskDrvrDtoList, true),
      ehailingDriverDetails: this.getDriverListData(risk.cngeRiskDrvrDtoList, false),
      extraCoverPackageSummary: this.getExtraCoverage(coverList),
    };

    // Premium Info
    const premiumInfo = {
      sumInsured: cngeNote.sumInsured,
      annualPremium: cngeNote.annualPremium,
      basicPremium: cngeNote.basicPremium,
      loadPercentage:isViewPolicy?coverList.loadPercentage: coverList.coverLoadingList?.loadingPct,
      loadAmount:isViewPolicy?coverList.loadAmount : coverList.coverLoadingList?.loadingAmt,
      ncdPercentage:isViewPolicy?coverList.ncdPct: coverList.ncdPercentage,
      ncdAmount:isViewPolicy?coverList.ncdAmt: coverList.ncdAmount,
      subCoverPremium: coverList.subCoverPremium,
      subCovPackagePrem: coverList.subCovPackagePrem,
      grossPremium: cngeNote.grossPremium,
      rebatePct: coverList.rebatePct || cngeNote.rebatePercentage,
      rebateAmt: coverList.rebateAmt || cngeNote.rebateAmount,
      servicetaxPercentage: cngeNote.servicetaxPercentage,
      servicetaxAmount: cngeNote.servicetaxAmount,
      stamp: cngeNote.stamp,
      commissionPercentage: cngeNote.commissionPercentage,
      commissionAmount:cngeNote.commissionAmount?cngeNote.commissionAmount :cngeNote.commisionAmount,
      premiumDue: cngeNote.premiumDue,
      premiumDueRounded: cngeNote.premiumDueRounded,

    };
    

    // Remarks Info
    const remarksInfo = {
      referral: isViewQuotation ? referRemarkList : cngeNote.referRemarks,
      cancel: cngeNote.cancelRemarks,
      approval: cngeNote.displayReferAdminRemarks,
      updatedBy: `${cngeNote.updateBy} at ${this.getFormattedDate(cngeNote.updateDate)}`,
    };

    return {
      cngeNote,
      mkagAgent,
      riskList,
      coverList,
      agentData,
      issuanceInfo,
      cpData,
      coverageInfo,
      premiumInfo,
      remarksInfo,
    };
  }

  getLoadingPct(coverList: any, loadingCodeStr: string): string {
    if (!coverList || !loadingCodeStr) return '0.00';

    const matchedItem =
      coverList.coverLoadingList && Array.isArray(coverList.coverLoadingList)
        ? coverList.coverLoadingList.find(item => item.loadingCode === loadingCodeStr)
        : null;

    return matchedItem?.loadingPct && !isNaN(matchedItem.loadingPct)
      ? matchedItem.loadingPct.toFixed(2)
      : '0.00';
  }

  getDriverListData(cngeRiskDrvrDtoList, isNormalDriver) {
    return cngeRiskDrvrDtoList?.filter(({ cngeRiskDrvrPkDto: { driverType } }) =>
      isNormalDriver ? driverType === 'NORM' : driverType === 'EHAIL'
    ).map(({ cngeRiskDrvrPkDto: { driverName }, driverId, driverAge }) => ({
      driverName,
      driverId,
      driverAge,
    })) || null;
  }

  getDriverListDataPolicy(riskDrvrList, isNormalDriver) {
    return riskDrvrList?.filter(( { driverType } ) =>
      isNormalDriver ? driverType === 'NORM' : driverType === 'EHAIL'
    ).map(( { driverName , driverId, driverAge }) => ({
      driverName,
      driverId,
      driverAge,
    })) || null;
  }

  getFormattedDate(dateVal: string) {
    return dateVal ? moment(dateVal, 'YYYY-MM-DD').format('DD/MM/YYYY') : dateVal;
  }

  getExtraCoverage(coverList: any) {

    if (!coverList || !coverList.subCoverList) {
      return {
        subCovPackageDesc: null,
        rahmahPackageList: [],
        topExtraCoverList: [],
        selectedExtraCoverList: [],
      };
    }

    const extraCoverPackage = coverList.subCoverList.map(cv => ({
      ...cv,
      description: cv.subCoverCodeDescription,
      selected: cv.selectedInd,
      enabled: cv.enableInd ?? false,
    }));

    // !o1.subCoverPrem && o1.coverDays && !o1.subCovPackage is for CART
    // remove (!o1.subCoverPrem && o1.coverDays && !o1.subCovPackage) impact motorplus honda coverage
    let selectedExtraCoverList = extraCoverPackage.filter(o1 => o1.selected && o1.subCoverPrem > 0);
    
    //remove !o1.enableInd because issuance view quotation cannot display rahmahPackageList && topExtraCoverList
    //rahmahPackageList: extraCoverPackage.filter(o1 => o1.subCovPackage && !o1.enableInd && !o1.subCoverPrem),
    let rahmahPackageList = extraCoverPackage.filter(o1 => o1.subCovPackage && !o1.subCoverPrem);
  
    //remove : selectedInd because issuance view quotation cannot display rahmahPackageList && topExtraCoverList
    //remove : (!o1.coverDays && !o1.subCovPackage) impact motorplus honda coverage
    let topExtraCoverList = extraCoverPackage
      .filter(o1 => o1.selectedInd && !o1.subCoverPrem)
      .filter(o1 => !rahmahPackageList.some(rp => rp.subCoverCode === o1.subCoverCode)); 

    return {
      subCovPackageDesc: coverList.subCovPackageDesc ?? null,
      costInd: coverList.costInd,
      rahmahPackageList: rahmahPackageList,
      topExtraCoverList: topExtraCoverList,
      selectedExtraCoverList: selectedExtraCoverList
    };
  }

  @Action(GET_ISS_CONTENT_ID)
  getContentId(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: GET_ISS_CONTENT_ID
  ) {
    const state = getState();
    return this.satMotorService.getContentId(payload).pipe(
      map(res => {
        if (res) {
          patchState({
            data: {
              ...state.data,
              step5: {
                ...state.data.step5,
                contentId: res[0].code
              }
            }
          });
        }
      })
    );
  }

  @Action(GET_NCD_CONFIRMATION)
  getNcdConfirmation(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: GET_NCD_CONFIRMATION
  ) {
    const state = getState();
    return this.satMotorService.getNcdConfirmation(payload).pipe(
      map(res => {
        if (res) {
          patchState({
            ncdDetails: res
          });
        } else {
          patchState({
            ncdDetails: null
          });
        }
      })
    );
  }

  @Action(RESET_MOTOR_STATE)
  public resetMotorState({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...state.motor.step1,
          issuance: null,
        },
        step2: null,
        step3: null,
        step4: null,
        step5: null,
      },
      data: {
        ...state.data,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
        isRenewNow: null,
        hasShowedOneAllianzPopUp: null,
      },
      declarationInfo: null,
    });
  }

  @Action(RESET_PREVIOS_POLICY_DETAILS)
  public resetPreviousPolicyDetails({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      previousPolicyDetails: null,
      previousPolicyDetailsError: null
    });
  }

  @Action(PRODUCT_VALIDATION)
  public productValidation({ patchState, getState }: StateContext<SatMotorModel>) {
    const state = getState();

    patchState({
      previousPolicyDetails: null,
      previousPolicyDetailsError: null
    });
  }

  @Action(ENDORSEMENT_TYPE_VALIDATION)
  public endtTypeValidation({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: ENDORSEMENT_TYPE_VALIDATION) {

    return this.satMotorService.validateEndtType(payload).pipe(
      map((res) => {
        const state = getState();
        const step1 = state.endorsement?.step1 || {};

        patchState({
          endorsement: {
            ...state.endorsement,
            step1: {
              ...step1,
              endtTypeResponse: res,
            },
          },
        });
        return res;
      })
    );
  }

  @Action(COPY_DRAFT_INFO)
  public copyDraftInfo(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_DRAFT_INFO) {
    const state = getState();
    const hasAdditionalPage = payload?.hasAdditionalPage ?? false;

    let step1 = {
      step1: {
        agentDetail: { ...state.motor.step1.agentDetail },
        agentAdditional: {},
        issuance: {
          issuance: {
            cnoteType: payload.cnoteType,
            vehicleNo: payload.vehicleNo,
            prevPolicyNo: payload.previousPolicyNo,
            prevExpDate: payload?.prevExpDate,
            productCode: payload.productCode,
            effectiveDate: payload.effectiveDate,
            expiryDate: payload.expiryDate,
            cnoteDate: payload.cnoteDate,
            contractId: payload.contractId,
            proposalNo: payload.proposalNo,
            updateVersion: payload.updateVersion ? payload.updateVersion : 0,
          },
          pdpa: {
            pdpaCheck: true,
            consentDetails: {
              edocConsentInd: payload.edocConsentInd,
              einvoiceConsentInd: payload.einvoiceConsentInd
            }
          }
        },
      }
    };

    if (hasAdditionalPage) {
      step1.step1.agentAdditional = {
        bankType: payload?.bankType ?? null,
        bankBranch: payload?.bankBranch ?? null,
        bankChannel: payload?.bankChannel ?? null,
        bankSubChannel: payload?.bankSubChannel ?? null,
        bankStaffNo: payload?.bankStaffNo ?? null,
        bankStaffName: payload?.bankStaffName ?? null,
        referralSalesStaffId: payload?.referralSalesStaffId ?? null,
        referralSalesStaffName: payload?.referralSalesStaffName ?? null,
        salesCampaign: payload?.salesCampaign ?? null,
        bankClientType: payload?.bankClientType ?? null
      };
    } else {
      delete step1.step1.agentAdditional;
    }

    let data2;
    let step2;
    if (payload.progress > (hasAdditionalPage ? 2 : 1)) {
      data2 = {
        step2: {
          store: {
            ...payload.clientPartner
          }
        }
      };

      step2 = {
        step2: {
          showSearchComponent: false,
          ...payload.clientPartner
        }
      };
      const step2CPScreen = {
        cpScreen: "FOUND",
        isCompOrCustInfoIncomplete: false,
        isContactInfoIncomplete: false,
        isCorrespondenceInfoIncomplete: false
      };
      const step2Search: STEP2.SearchQuery = {
        idType: payload.clientPartner.idType1,
        idValue: payload.clientPartner.idValue1,
        agentCode: payload.mkagAgent.agentCode,
        operator: null,
        productType: "MT"
      };
      this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
      this.store.dispatch(new STORE_STEP2_SEARCH(step2Search));
      this.store.dispatch(new STORE_STEP2_CP_SCREEN(step2CPScreen));
    }

    let data3;
    let step3;
    if (payload.progress > (hasAdditionalPage ? 3 : 2)) {
      let selectedMakeModel = { ...payload.riskList[0] };
      delete selectedMakeModel["cngeRiskDrvrDtoList"];
      delete selectedMakeModel["coverList"];
      data3 = {
        step3: {
          coverage: {
            selectedMakeModel: selectedMakeModel
          }
        }
      };

      step3 = {
        step3: {
          coverage: {
            vehicleDetails: {
              ...payload.riskList[0],
              region: payload.region
            },
            coverageType: {
              ...payload.riskList[0]?.coverList[0],
            },
            extracover: {
              driverNameDetails: {
                rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "NORM")?.map(a => {
                  return {
                    driverName: a.cngeRiskDrvrPkDto.driverName,
                    driverId: a.driverId,
                    driverAge: a.driverAge
                  }
                }),
                vehicleUnNamedDrvrInd: payload.riskList[0].coverList[0].vehicleUnNamedDrvrInd,
                vehicleAlldrvrInd: payload.riskList[0].coverList[0].vehicleAlldrvrInd
              },
              ehailingDriverDetails: {
                rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "EHAIL")?.map(a => {
                  return {
                    driverName: a.cngeRiskDrvrPkDto.driverName,
                    driverId: a.driverId,
                  }
                }),
              },
              extraCoverPackage: payload.riskList[0]?.coverList[0]?.subCoverList,
            },
          },
        },
      }
    }


    patchState({
      currentStep: payload.progress,
      data: {
        ...state.data,
        step1: { ...state.data.step1 },
        ...data2,
        ...data3
      },
      motor: {
        ...step1,
        ...step2,
        ...step3,
        step4: {
          generateQuotation: payload,
        }
      }
    });

  }

  @Action(COPY_REQUOTE_INFO)
  public copyRequoteInfo(
    { patchState, getState, dispatch }: StateContext<SatMotorModel>,
    { payload }: COPY_REQUOTE_INFO) {
    const state = getState();

    let step1 = {
      step1: {
        agentDetail: payload.mkagAgent,
        issuance: {
          issuance: {
            cnoteType: payload.cnoteType,
            vehicleNo: payload.vehicleNo,
            prevPolicyNo: payload.previousPolicyNo,
            productCode: payload.productCode,
            effectiveDate: payload.effectiveDate,
            expiryDate: payload.expiryDate,
            cnoteDate: payload.cnoteDate,
            contractId: payload.contractId,
            proposalNo: payload.proposalNo,
            idType: payload.clientPartner.idType1,
            idNumberText: payload.clientPartner.idValue1
          },
          pdpa: {
            pdpaCheck: false,
            consentDetails: {
              edocConsentInd: undefined,
              einvoiceConsentInd: undefined
            }
          }
        },
      }
    };

    let data2;
    let step2;
    // if (payload.progress > 1) {
    data2 = {
      step2: {
        store: {
          ...payload.clientPartner
        }
      }
    };

    step2 = {
      step2: {
        showSearchComponent: false,
        ...payload.clientPartner
      }
    };
    const step2CPScreen = {
      cpScreen: "FOUND",
      isCompOrCustInfoIncomplete: false,
      isContactInfoIncomplete: false,
      isCorrespondenceInfoIncomplete: false
    };
    const step2Search: STEP2.SearchQuery = {
      idType: payload.clientPartner.idType1,
      idValue: payload.clientPartner.idValue1,
      agentCode: payload.mkagAgent.agentCode,
      operator: null,
      productType: "MT"
    };
    this.store.dispatch(new SHOW_SEARCH_COMPONENT_2(false));
    this.store.dispatch(new STORE_STEP2_SEARCH(step2Search));
    this.store.dispatch(new STORE_STEP2_CP_SCREEN(step2CPScreen));
    // }

    let data3;
    let step3;
    // if (payload.progress > 2) {
    let selectedMakeModel = { 
      ...payload.riskList[0], 
      capacity: payload.riskList[0].vehicleCapacity,  
      seat: payload.riskList[0].vehicleSeat,  
      uom: payload.riskList[0].vehicleUom
    };
    delete selectedMakeModel["cngeRiskDrvrDtoList"];
    delete selectedMakeModel["coverList"];
    data3 = {
      step3: {
        coverage: {
          selectedMakeModel: selectedMakeModel
        }
      }
    };

    step3 = {
      step3: {
        coverage: {
          vehicleDetails: {
            ...payload.riskList[0],
            region: payload.region
          },
          coverageType: {
            ...payload.riskList[0]?.coverList[0],
          },
          extracover: {
            driverNameDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "NORM")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                  driverAge: a.driverAge
                }
              }),
              vehicleUnNamedDrvrInd: payload.riskList[0].coverList[0].vehicleUnNamedDrvrInd,
              vehicleAlldrvrInd: payload.riskList[0].coverList[0].vehicleAlldrvrInd
            },
            ehailingDriverDetails: {
              rows: payload.riskList[0]?.cngeRiskDrvrDtoList?.filter(d => d.cngeRiskDrvrPkDto.driverType === "EHAIL")?.map(a => {
                return {
                  driverName: a.cngeRiskDrvrPkDto.driverName,
                  driverId: a.driverId,
                }
              }),
            },
            extraCoverPackage: payload.riskList[0]?.coverList[0]?.subCoverList,
          },
        },
      },
    }
    // }

    patchState({
      currentStep: payload.progress,
      data: {
        ...data2,
        ...data3
      },
      motor: {
        ...step1,
        ...step2,
        ...step3,
        step4: {
          generateQuotation: payload,
        }
      }
    });

  }


  @Action(STORE_STEP2_DRAFT_NO)
  public storeStep2DraftNo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP2_DRAFT_NO) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step2: {
          ...state.motor.step2,
          draftNo: payload.proposalNo
        }
      }
    });
  }

  @Action(STORE_STEP3_DRAFT_NO)
  public storeStep3DraftNo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_STEP3_DRAFT_NO) {
    const state = getState();

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          draftNo: payload.proposalNo,
          contractId: payload.contractId
        }
      }
    });
  }

  @Action(STORE_EXTRA_COVER_PACKAGE)
  public storeExtraCoveragePackage({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_EXTRA_COVER_PACKAGE) {
    const state = getState();
    let extraCoverPackage = [];
    for (let cv of payload.subCoverList) {
      extraCoverPackage.push({
        ...cv,
        description: cv.subCoverCodeDescription,
        selected: cv.selectedInd,
        enabled: cv.enableInd !== undefined ? cv.enableInd : false
      });
    }

    // let rahmahExtraCoverList = extraCoverPackage.filter(o1 => o1.subCovPackage && o1.enabled == false && o1.subCoverPrem == 0);
    // let topExtraCoverList = extraCoverPackage.filter(o1 => o1.selected === true && o1.enabled === false && o1.subCoverPrem == 0 && !o1.subCovPackage);
    // let extraCoverList = extraCoverPackage.filter(o1 => o1.selected === true && o1.subCoverPrem > 0);
    let rahmahExtraCoverList = extraCoverPackage.filter(o1 => o1.subCovPackage && o1.enableInd == false && o1.subCoverPrem == 0);
    let topExtraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.enableInd === false && o1.subCoverPrem == 0 && !o1.subCovPackage);
    let extraCoverList = extraCoverPackage.filter(o1 => o1.selectedInd === true && o1.subCoverPrem > 0);

    let extraCoverInSummary = {
      subCovPackageDesc: payload.subCovPackageDesc,
      rahmahPackageList: rahmahExtraCoverList,
      topExtraCoverList: topExtraCoverList,
      selectedExtraCoverList: extraCoverList
    }

    patchState({
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            extracover: {
              ...state.motor.step3.coverage.extraCover,
              extraCoverPackageSummary: extraCoverInSummary
            },
          },
        },
      },
    });
  }

  @Action(CANCEL_QUOTATION)
  public cancelQuotation(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: CANCEL_QUOTATION
  ) {
    const state = getState();

    return this.satMotorService.cancelQuotation(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              cancellationRes: res.data
            },
          });
        }
      }),
    );
  }

  @Action(CANCEL_COVER_NOTE)
  public cancelCoverNote(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: CANCEL_COVER_NOTE
  ) {
    const state = getState();

    return this.satMotorService.cancelCoverNote(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            data: {
              ...state.data,
              cancellationRes: res.data
            },
          });
        }
      }),
    );
  }

  @Action(STORE_COMPLETE_DATA)
  public initCompleteData(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: STORE_COMPLETE_DATA
  ) {
    const state = getState();

    if (payload.isIssuance) {
      patchState({
        motor: {
          ...state.motor,
          step5: {
            ...state.motor.step5,
            cngeNote: payload.cngeNote
          }
        }
      });
    } else if (payload.isRTP) {
      patchState({
        rtp: {
          ...state.rtp,
          step3: {
            ...state.rtp.step3,
            cngeNote: payload.cngeNote
          }
        }
      });

    } else if (payload.isEndorsement) {
      patchState({
        endorsement: {
          ...state.endorsement,
          step4: {
            ...state.endorsement.step4,
            cngeNote: payload.cngeNote
          }
        }
      });

    }
  }

  @Action(VALIDATE_POI_DATE)
  public validatePoiDate({ patchState, getState }: StateContext<SatMotorModel>,
    { payload }: VALIDATE_POI_DATE) {
    return this.satMotorService.validatePoiDate(payload).pipe(
      map((res) => {
        if (res && res.success !== undefined) {
          patchState({
            poiValidateResult: res
          });
        } else {
          patchState({
            poiValidateResult: null
          });
        }
      })
    );
  }

  prepareCoverNoteRequestPayloadRenewal(state: SatMotorModel, payload: any, isSaveDraft: boolean, isRTP: boolean, geoIndValue) {
    let rtpData = {};
    let data;
    let extraCoverPackage = [];

    data = {
      cngeNote: {
        draftInd: isSaveDraft,
        geoCodedInd: false,
        productType: "MT",
        currentStep: state.currentStep,
        progress: state.currentStep,
        ...state.currentStep,
        ...state.motor.step1.issuance.issuance,
        ...state.motor.step1.issuance.pdpa.pdpaCheck,
        ...state.motor.step1.issuance.pdpa.consentDetails,
        mkagAgent: {
          ...state.motor.step1.agentDetail,

        },
      },
      operator: payload.userId,
    };

    let cngeNote = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
    if(cngeNote){
      let isCnoteStatusPrinted = cngeNote.cnoteStatus === "PRINTED";
      let updateVersion = cngeNote.updateVersion;
      let createBy = cngeNote.createBy;
      let createDate = cngeNote.cnoteDate;
      let cnoteNo = cngeNote.cnoteNo;
  
      if (isCnoteStatusPrinted && !cnoteNo) {
        data = {
          ...data,
          cngeNote: {
            ...data.cngeNote,
            updateVersion: isCnoteStatusPrinted || updateVersion ? updateVersion : null,
            createBy: isCnoteStatusPrinted || createBy ? createBy : null,
            createDate: isCnoteStatusPrinted || createDate ? createDate : null,
            cnoteDate: new Date()
          },
        };
      }
    }
    

    if (state.motor.step2) {
      data = {
        ...data,
        cngeNote: {
          ...data.cngeNote,
          clientPartner: state.motor.step2,
          coverId: null,
          contractId: null,
          riskId: null,
        },
      };
    }

    if (state.motor.step3) {
      let premiumInfo = state.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null;
      const { coverDto, cngeCoverNcdInfoList, cngeSubCoverUpSellList, ...remainingData } = premiumInfo;

      if (state.motor.step3.coverage) {
        if (state.motor.step3.coverage.vehicleDetails) {
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [{ ...state.motor.step3.coverage.vehicleDetails, riskType: "VEH" }],
              region: state.motor.step3.coverage.vehicleDetails.region,
              grossPremium: state.motor.step3.coverage.coverageType.grossPremium,
              annualPremium: state.motor.step3.coverage.coverageType.annualPremium
            },
          };
        }
        if (state.motor.step3.coverage.coverageType) {
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [
                {
                  ...data.cngeNote.riskList[0],
                  coverList: [{ 
                    ...state.motor.step3.coverage.coverageType,
                    makeModelSiMin:
                    state.motor.step3.coverage?.vehicleDetails?.region === "W"
                        ? state.motor.step3.coverage?.vehicleDetails?.avWmSiMin
                        : state.motor.step3.coverage?.vehicleDetails?.avEmSiMin,
                    makeModelSiMax:
                    state.motor.step3.coverage?.vehicleDetails?.region === "W"
                        ? state.motor.step3.coverage?.vehicleDetails?.avWmSiMax
                        : state.motor.step3.coverage?.vehicleDetails?.avEmSiMax,
                    importStatus: state.motor.step3.coverage?.vehicleDetails?.importStatus }],
                  contractId: null,
                  riskId: null,
                  grossPremium: state.motor.step3.coverage.coverageType.grossPremium,
                  annualPremium: state.motor.step3.coverage.coverageType.annualPremium,
                  cngeSubCoverUpSellList,
                  cngeCoverNcdInfoList
                },
              ],
            },
          };
        }
        if (state.motor.step3.coverage.extracover) {
          let cngeRiskDrvrDtoListNamedDriver = [];
          let cngeRiskDrvrDtoListEhailing = [];
          let vehicleUnNamedDrvrInd = null;
          let vehicleAlldrvrInd = null;

          if (state.motor.step3.coverage.extracover.driverNameDetails?.rows) {
            const namedDriverList = state.motor.step3.coverage.extracover.driverNameDetails.rows;
            vehicleUnNamedDrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleUnNamedDrvrInd;
            vehicleAlldrvrInd = state.motor.step3.coverage.extracover.driverNameDetails.vehicleAlldrvrInd;
            cngeRiskDrvrDtoListNamedDriver = namedDriverList.map((driver) => {
              let driverName;
              if (driver.driverName) {
                driverName = driver.driverName;
              } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
                driverName = driver.cngeRiskDrvrPkDto.driverName;
              }

              let cngeRiskDrvrPkDto = {
                cngeRiskDrvrPkDto: {
                  driverName: driverName ? driverName : "",
                  driverType: "NORM",
                },
                driverId: driver.driverId,
                driverAge: driver.driverAge,
                driverSeq: driver.driverSeq,
              };

              return cngeRiskDrvrPkDto;
            });
          }

          if (state.motor.step3.coverage.extracover.ehailingDriverDetails?.rows) {
            const ehailingDriverList = state.motor.step3.coverage.extracover.ehailingDriverDetails.rows;
            cngeRiskDrvrDtoListEhailing = ehailingDriverList.map((driver) => {
              let driverName;
              if (driver.driverName) {
                driverName = driver.driverName;
              } else if (driver?.cngeRiskDrvrPkDto?.driverName) {
                driverName = driver.cngeRiskDrvrPkDto.driverName;
              }

              let cngeRiskDrvrPkDto = {
                cngeRiskDrvrPkDto: {
                  driverName: driverName ? driverName : "",
                  driverType: "EHAIL",
                },
                driverId: driver.driverId,
                driverAge: 0, // Assuming `0` is the default driver age for ehailing drivers
                driverSeq: driver.driverSeq,
              };

              return cngeRiskDrvrPkDto;
            });
          }

          // Merge the lists and update `data`
          data = {
            ...data,
            cngeNote: {
              ...data.cngeNote,
              riskList: [
                {
                  ...data.cngeNote.riskList[0],
                  cngeRiskDrvrDtoList: [
                    ...cngeRiskDrvrDtoListNamedDriver,
                    ...cngeRiskDrvrDtoListEhailing
                  ],
                  coverList: [{
                    ...data.cngeNote.riskList[0].coverList[0],
                    vehicleUnNamedDrvrInd: vehicleUnNamedDrvrInd,
                    vehicleAlldrvrInd: vehicleAlldrvrInd,
                    coverId: null,
                    contractId: null,
                    riskId: null,
                    coverDto: null
                  }],
                },
              ],
            },
          };

          if (state.motor.step3.coverage.extracover.extraCoverPackage) {
            for (let cv of state.motor.step3.coverage.extracover.extraCoverPackage) {
              extraCoverPackage.push({
                ...cv,
                subCoverCodeDescription: cv.description,
                selectedInd: cv.selected,
                enableInd: cv.enabled,
                subCoverSumInsured: cv.subCoverSumInsured,
                subCoverDays: cv.subCoverNumberOfDays,
                subCoverAmtday: cv.subCoverAmtPerDay > 0 ? cv.subCoverAmtPerDay : (cv.subCoverAmtday > 0 ? cv.subCoverAmtday : 0),
                contractId: "",
                riskId: "",
                subcoverId: "",
                coverId: "",

              });
            }
            let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
            data = {
              ...data,
              cngeNote: {
                ...data.cngeNote,
                premiumDueRounded: cngeNotePremium.premiumDueRounded,
                stamp: cngeNotePremium.stamp,
                grossPremium: state.motor.step3.coverage.coverageType.grossPremium,
                annualPremium: state.motor.step3.coverage.coverageType.annualPremium,
                riskList: [
                  {
                    ...data.cngeNote.riskList[0],
                    coverList: [{
                      ...data.cngeNote.riskList[0].coverList[0],
                      subCovPackage: cngeNotePremium.riskList[0].coverList[0].subCovPackage,
                      subCovPackageDesc: cngeNotePremium.riskList[0].coverList[0].subCovPackageDesc,
                      subCoverList: [
                        ...extraCoverPackage,
                      ]
                    }],
                  },
                ],
              },
            };
          }
        }
      }
    }

    if (!isSaveDraft) {
      let premiumInfo = state.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null;
      let cngeNotePremium = state.motor.step3?.coverage?.coveragePremium?.cngeNote;
      if (premiumInfo) {
        const { coverDto, cngeCoverNcdInfoList, ...remainingData } = premiumInfo;

        if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0) {
          if (data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
            if (
              !data.cngeNote.riskList[0].coverList[0].hasOwnProperty('grossPremium') ||
              data.cngeNote.riskList[0].coverList[0].grossPremium === undefined ||
              data.cngeNote.riskList[0].coverList[0].grossPremium === null ||
              data.cngeNote.riskList[0].coverList[0].grossPremium === ''
            ) {
              data.cngeNote.riskList[0].coverList[0].grossPremium = remainingData.grossPremium;
            }

            if (
              !data.cngeNote.riskList[0].coverList[0].hasOwnProperty('subCoverPremium') ||
              data.cngeNote.riskList[0].coverList[0].subCoverPremium === undefined ||
              data.cngeNote.riskList[0].coverList[0].subCoverPremium === null ||
              data.cngeNote.riskList[0].coverList[0].subCoverPremium === ''
            ) {
              data.cngeNote.riskList[0].coverList[0].subCoverPremium = remainingData.subCoverPremium;
            }

            const { cngeSubCoverUpSellList, ...coverListWithoutUpSellList } = remainingData;
            data.cngeNote.riskList[0].coverList[0] = {
              ...data.cngeNote.riskList[0].coverList[0],
              ...coverListWithoutUpSellList,
              cngeCoverNcdInfoList,
              cngeSubCoverUpSellList,
              subCoverList: [
                ...extraCoverPackage
              ]
            };
          }
        }

        if (data.cngeNote.clientPartner && data.cngeNote.clientPartner.clientPartnerAddress) {
          let clientPartner = JSON.parse(JSON.stringify(data.cngeNote.clientPartner));

          if (!clientPartner.clientPartnerAddress.clientAddress1 || clientPartner.clientPartnerAddress.clientAddress1.trim() === '') {
            clientPartner.clientPartnerAddress.clientAddress1 = 'LOT123';
          }

          if (!clientPartner.clientPartnerAddress.clientPostcode || clientPartner.clientPartnerAddress.clientPostcode.trim() === '') {
            clientPartner.clientPartnerAddress.clientPostcode = '43950';
          }

          data.cngeNote.clientPartner = clientPartner;
        } //TO BE DELETED

        data.cngeNote = {
          ...data.cngeNote,
          ...remainingData,
          previousPolicyNo: data?.cngeNote?.prevPolicyNo,
          cbcAmount: 0.00,
          cbcAdvanceAmt: 0.00,
          referRemarkList: payload.referRemarkList,
          referWaiveLoad: payload.waiveLoadInd,
          referWaiveExcess: payload.waiveExcessInd,
          referUnderWriting: payload.underwritingInd,
          agentReferId: payload.agentReferId,
          grossPremium: state.motor.step3.coverage.coveragePremium?.cngeNote?.riskList[0]?.coverList[0].grossPremium,
          annualPremium: state.motor.step3.coverage.coveragePremium?.cngeNote?.riskList[0]?.coverList[0].annualPremium,
          ...(payload.proposalNo && {
            proposalNo: payload.proposalNo,
            contractId: payload.contractId,
            cnoteDate: payload.cnoteDate,
            issueBy: payload.issueBy,
            issuedDate: payload.issuedDate
          }),
          premiumDueRounded: cngeNotePremium.premiumDueRounded,
          stamp: cngeNotePremium.stamp,
          coverId: null,
          contractId: null,
          riskId: null
        };
        
        delete data.cngeNote.prevPolicyNo;        

        //Check and tally the sumInsured values
        if (data.cngeNote.riskList && data.cngeNote.riskList.length > 0 &&
          data.cngeNote.riskList[0].coverList && data.cngeNote.riskList[0].coverList.length > 0) {
          if (data.cngeNote.riskList[0].coverList[0].sumInsured !== data.cngeNote.sumInsured) {
            data.cngeNote.riskList[0].coverList[0].sumInsured = data.cngeNote.sumInsured;
          }
        }
      }
    }

    return isRTP ? rtpData : data;
  }

  @Action(UPDATE_ISM_POLICY)
  public updateIsmPolicy({ patchState, getState }: StateContext<SatMotorModel>,
    { payload }: UPDATE_ISM_POLICY) {
    return this.satMotorService.updateIsmPolicy(payload).pipe(
      map((res) => {
        if (res && res.success !== undefined) {
          patchState({
            updateIsmPolicyMessage: res
          });
        } else {
          patchState({
            updateIsmPolicyMessage: res
          });
        }
      })
    );
  }

  @Action(GET_NCD_ENQUIRY)
  getNcdEnquiry(
    { patchState, getState }: StateContext<SatMotorModel>,
    { payload }: GET_NCD_ENQUIRY
  ) {
    const state = getState();
    return this.satMotorService.getNcdEnquiry(payload).pipe(
      map(res => {
        if (res) {
          patchState({
            ncdEnquiryDetails: res
          });
        } else {
          patchState({
            ncdEnquiryDetails: null
          });
        }
      })
    );
  }

  @Action(RESET_SUBSEQUENT_PAGE_DATA)
  public resetSubsequentPageData(
    { patchState, getState }: StateContext<SatMotorModel>
  ) {
    const state = getState();

    const currentStep = state.currentStep;
    const actionType = state.actionType;

    if (currentStep === 1) {
      if (actionType === "ISSUE") {
        patchState({
          motor: {
            ...state.motor,
            step2: defaultSatMotorState.motor.step2,
            step3: defaultSatMotorState.motor.step3,
            step4: defaultSatMotorState.motor.step4,
            step5: defaultSatMotorState.motor.step5,
          },
          data: {
            ...state.data,
            hasShowedOneAllianzPopUp: defaultSatMotorState.data.hasShowedOneAllianzPopUp,
            step2: defaultSatMotorState.data.step2,
            step3: defaultSatMotorState.data.step3,
            step4: defaultSatMotorState.data.step4,
            step5: defaultSatMotorState.data.step5,
          }
        });
      }
    }

    if (currentStep === 2) {
      if (actionType === "ISSUE") {
        patchState({
          motor: {
            ...state.motor,
            step3: defaultSatMotorState.motor.step3,
            step4: defaultSatMotorState.motor.step4,
            step5: defaultSatMotorState.motor.step5,
          },
          data: {
            ...state.data,
            hasShowedOneAllianzPopUp: defaultSatMotorState.data.hasShowedOneAllianzPopUp,
            step3: defaultSatMotorState.data.step3,
            step4: defaultSatMotorState.data.step4,
            step5: defaultSatMotorState.data.step5,
          }
        });
      }
    }
  }

  @Action(SEARCH_POLICY_ENQUIRY)
  public searchPolicyEnquiry({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SEARCH_POLICY_ENQUIRY) {
    const state = getState();
    return this.satMotorService.searchPolicyEnquiry(payload).pipe(
      map((res) => {
        let searchEnquiryList;
        if (res && res.data) {
          searchEnquiryList = res.data;
        } else {
          searchEnquiryList = [];
        }
        const state = getState();
        patchState({
          policyEnquiryDetails: searchEnquiryList
        });

      }),
    );
  }

  @Action(EMAIL_DOCUMENT)
  public emailDocument({ patchState, getState }: StateContext<SatMotorModel>, { payload }: EMAIL_DOCUMENT) {
    const state = getState();
    return this.satMotorService.emailDocument(payload).pipe(
      map((res) => {
        let emailDocumentResponse;
        if (res) {
          emailDocumentResponse = res;
        } else {
          emailDocumentResponse = [];
        }
        const state = getState();
        patchState({
          emailDocumentResponse: emailDocumentResponse
        });

      }),
    );
  }

  @Action(GET_EMAIL_LISTING)
  public getEmailListing({ patchState, getState }: StateContext<SatMotorModel>, { payload }: GET_EMAIL_LISTING) {
    const state = getState();
    return this.satMotorService.getEmailListing(payload).pipe(
      map((res) => {
        let emailListing;
        if (res) {
          emailListing = res;
        } else {
          emailListing = [];
        }
        const state = getState();
        patchState({
          emailListing: emailListing
        });

      }),
    );
  }

  @Action(STORE_PREMIUM_CALCULATION_PAYLOAD)
  public storePremiumCalculationPayload({ patchState, getState }: StateContext<SatMotorModel>, { payload }: STORE_PREMIUM_CALCULATION_PAYLOAD) {
    const state = getState();

    patchState({
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3.coverage,
            coveragePremiumPayload: payload,
          },
        },
      },
    });
  }

  @Action(GET_DOCUMENT_TYPE_LOV)
  public getDocumentTypeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { }: GET_DOCUMENT_TYPE_LOV) {
    const state = getState();

    return this.satMotorService.getDocumentTypeLov().pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            documentTypeLov: res.data
          });
        }
      }),
    );
  }

  @Action(GET_REASON_CODE_LOV)
  public getReasonCodeLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_REASON_CODE_LOV) {
    const state = getState();

    return this.satMotorService.getReasonCodeLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            reasonCodeLov: res.data
          });
        }
      }),
    );
  }

  @Action(GET_VEHICLE_CLASS_USE_LOV)
  public getVehicleClassUseLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_VEHICLE_CLASS_USE_LOV) {
    const state = getState();

    return this.satMotorService.getVehicleClassUseLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            vehicleClassUseLov: res.data
          });
        }
      }),
    );
  }

@Action(GET_CHECKLIST_LOV)
  public getChecklistLov({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_CHECKLIST_LOV) {
    const state = getState(); 
	return this.satMotorService.getChecklistLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            checklistLov: res.data
          });
        }
      }),
    );
  }

 @Action(GET_QUOTATION_DETAIL_PAYLOAD)
  public getQuotationDetailPayload({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: GET_QUOTATION_DETAIL_REQUOTE) {
    const state = getState();
       return this.satMotorService.getQuotationDetailPayload(payload).pipe(
        map((res) => {
          if (res && res.data) {
            patchState({
              quotationDetail: res.data
            });
          }
        }),
      ); 
  }

  @Action(SAVE_ENDT_CUSTOMER_PARTNER)
  public saveEndtCustomerPartner({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SAVE_ENDT_CUSTOMER_PARTNER) {
    const state = getState();
    const geoIndValue = state.geoCodedInd;
    // let data = this.prepareCoverNoteRequestPayload(state, payload, true, false, geoIndValue); console.log(data);
    return this.satMotorService.saveCustomerPartner(payload).pipe(
      map((res) => {
        if (res.data) {
          let payload = {
            eInvoiceCustomerPartner: res.data,
            isEInvoiceCustomerPartner: true
          }
          this.store.dispatch(new STORE_ENDORSEMENT(payload));
        }
      }),
    );
  }

  @Action(SET_ENDT_CUSTOMER_PARTNER_SEARCH_TYPE)
  public setEndtCustomerPartnerSearchType({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SET_ENDT_CUSTOMER_PARTNER_SEARCH_TYPE) {
    const state = getState();
    patchState({
      endorsement: {
        ...state.endorsement,
        step2: {
          ...state.endorsement.step2,
          cpType: payload,
        },
      },
    });
  }
  
  @Action(STORE_ROAD_TAX_PAYABLE)
  public storRoadTaxPayable({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_ROAD_TAX_PAYABLE) {
    const state = getState();
    if (!payload.isEndorsement) {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...state.motor.step4,
            roadTaxPayable: payload.roadTaxPayable,
          },
        },
      });
    }else {
      patchState({
        endorsement: {
          ...state.endorsement,
          step3: {
            ...state.endorsement.step3,
            roadTaxPayable: payload.roadTaxPayable,
          },
        },
      });
    }

  }

  @Action(SET_UPDATE_PAYMENT_IND)
  public setUpdatePaymentInd({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SET_UPDATE_PAYMENT_IND) {
    const state = getState();
    patchState({
      isUpdatePayment: payload
    });
  }

  @Action(SAVE_PAYMENT)
  public savePayment({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: SAVE_PAYMENT) {
    const state = getState();
    const geoIndValue = state.geoCodedInd;

    let data = this.prepareCoverNoteRequestPayload(state, payload, false, false, geoIndValue);

    return this.satMotorService.generateQuotation(data).pipe(
      map((res) => {
        if (res && res.data) {
          
          if (payload.isEndorsement) {
            
          } else {
            patchState({
              motor: {
                ...state.motor,
                step4: {
                  ...(state.motor?.step4 || {}),
                  updatePayment: res,
                },
              },
            });
          }

        }
      }),
    );
  }

  @Action(STORE_PAYMENT_INFO)
  public storePaymentInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_PAYMENT_INFO) {
    const state = getState();
    if (!payload.isEndorsement) {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...state.motor.step4,
            paymentInfo: payload.paymentInfo,
          },
        },
      });
    }else {
      patchState({
        endorsement: {
          ...state.endorsement,
          step3: {
            ...state.endorsement.step3,
            paymentInfo: payload.paymentInfo,
          },
        },
      });
    }

  }

  @Action(ENDT_CALCULATE_PREMIUM_SUMMARY)
  public endtCalculatePremiumSummary({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: ENDT_CALCULATE_PREMIUM_SUMMARY) {
    const state = getState();
    return this.satMotorService.calculateEndtPremium(payload).pipe(
      map((res) => {
        if (res) {
          patchState({ endtSummaryPremium: res });
        }
      }),
    );
  }

  @Action(IS_CANCEL_AT_COMPLETE)
  public isCancelAtComplete({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: IS_CANCEL_AT_COMPLETE) {
    const state = getState();
    patchState({ 
      isCancelAtComplete: payload
    });
  }

  @Action(STORE_NCD_INFO)
  public storeNcdInfo({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_NCD_INFO) {
    const state = getState();
    patchState({ 
      motor: {
        ...state.motor,
        step3: {
          ...state.motor.step3,
          coverage: {
            ...state.motor.step3.coverage,
            ncdInfo: {
              ismRefNo: payload.ismRefNo,
              ismResCode:  payload.ismResCode,
              tmpNcdConfirmationError: payload.errMsg ? true : false
            }
          }
        }
      }
    });
  } 

  @Action(UPDATE_COVERAGE_ALERT)
  public updateShowDialog({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: UPDATE_COVERAGE_ALERT) {
    const state = getState();
    patchState({ 
      data: {
        ...state.data,
        step3: {
          ...state.data.step3,
          coverage: {
            ...state.data.step3.coverage,
            coverageShowDialog: {
              ...state.data.step3.coverage.coverageShowDialog,
              ...payload
            }
          }
        }
      }
    });
  }

  @Action(RESET_CUSTOMER_PARTNER_DATA)
  public resetCustomerPartnerData({ patchState, getState, dispatch }: StateContext<SatMotorModel>) {
    const state = getState();
    patchState({ 
      data: {
        ...state.data,
        step2: defaultSatMotorState.data.step2
      },

      motor: {
        ...state.motor,
        step2: defaultSatMotorState.motor.step2
      }
    });
  }

  @Action(VALIDATE_BLACKLIST)
  public validateBlacklist({ patchState, getState }: StateContext<SatMotorModel>, { payload }: VALIDATE_BLACKLIST) {
    const state = getState();
    const geoIndValue = state.geoCodedInd;

    let data = this.prepareCoverNoteRequestPayload(state, payload, true, false, geoIndValue); console.log(data);

    return this.satMotorService.validateBlacklist(data).pipe(
      map((res) => {
        if (res && res.success !== undefined) {
          patchState({
            blacklistResult: res
          });
        } else {
          patchState({
            blacklistResult: null
          });
        }
      })
    );
  }

  @Action(SME_DUPLICATION_CHECKING)
  public smeDuplicationChecking({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SME_DUPLICATION_CHECKING) {
    const state = getState();

    return this.satMotorService.smeDuplicationChecking(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            smeDuplicate: res.smeRegNoDuplicate
          });
        } else {
          patchState({
            smeDuplicate: null
          });
        }
      })
    );
  }

  @Action(SET_EINVOICE_CONSENT_IND)
  public setEInvoiceConsentInd({ patchState, getState }: StateContext<SatMotorModel>, { payload }: SET_EINVOICE_CONSENT_IND) {
    const state = getState();
    
    patchState({
      motor: {
        ...state.motor,
        step1: {
          ...state.motor.step1,
          issuance: {
            ...state.motor.step1.issuance,
            pdpa: {
              ...state.motor.step1.issuance.pdpa,
              consentDetails: {
                ...state.motor.step1.issuance.pdpa.consentDetails,
                einvoiceConsentInd: payload
              }
            }
          }
        }
      }
    });
  }

  @Action(IS_CANCEL_REPLACE_COVER_NOTE)
  public isCancelReplaceCoverNote({ patchState, getState }: StateContext<SatMotorModel>, { payload }: IS_CANCEL_REPLACE_COVER_NOTE) {
    const state = getState();
    
    patchState({
      isCancelReplaceCoverNote: payload.cnoteNo ? true : false
    });
  }

  @Action(ACTIVE_SELECTION_POPUP)
  public setActiveSelectionPopup({ patchState, getState }: StateContext<SatMotorModel>, { payload }: ACTIVE_SELECTION_POPUP) {
    patchState({
      activeSelectionPopup: payload === 'Y' ? 'Y' : null
    });
  }

  @Action(STORE_RENEWAL_ASSISTANCE)
  public setRenewalAssistance({ patchState, getState, dispatch }: StateContext<SatMotorModel>, { payload }: STORE_ROAD_TAX_PAYABLE) {
    const state = getState();
    if (!payload.isEndorsement) {
      patchState({
        motor: {
          ...state.motor,
          step4: {
            ...(state.motor?.step4 || {}),
            summary: { 
              ...(state.motor?.step4?.summary || {}),
              renewalAssistance: payload 
            },
          },
        },
      });
    }

  }

}
