const PREFIX = 'SATMOTORAPPROVAL';

export class GET_APPROVAL_DETAILS {
  static readonly type = `[${PREFIX}] GET APPROVAL DETAILS`;

  constructor(public readonly payload) { }
}

export class SAVE_ADMIN_REMARKS {
  static readonly type = `[${PREFIX}] SAVE ADMIN REMARKS`;

  constructor(public readonly payload) { }
}

export class APPROVE_COVER_NOTE {
  static readonly type = `[${PREFIX}] APPROVE COVER NOTE`;

  constructor(public readonly payload) { }
}

export class REJECT_COVER_NOTE {
  static readonly type = `[${PREFIX}] REJECT COVER NOTE`;

  constructor(public readonly payload) { }
}

export class CALCULATE_PREMIUM {
  static readonly type = `[${PREFIX}] CALCULATE PREMIUM`;

  constructor(public readonly payload) { }
}

// export class CALCULATE_ENDT_PREMIUM {
//   static readonly type = `[${PREFIX}] CALCULATE ENDT PREMIUM`;

//   constructor(public readonly payload) { }
// }

export class STORE_APPROVAL_DETAILS_CNGE {
  static readonly type = `[${PREFIX}] STORE APPROVAL DETAILS CNGE`;

  constructor(public readonly payload) { }
}

export class DOWNLOAD_PDF {
  static readonly type = `[${PREFIX}] DOWNLOAD_PDF`;

  constructor(public readonly payload) { }
}

export class GET_VEHICLE_USE {
  static readonly type = `[${PREFIX}] GET VEHICLE USE`;

  constructor(public readonly payload) { }
}

export class RESET_APPROVAL_STATE {
  static readonly type = `[${PREFIX}] RESET APPROVAL STATE`;
}

export class GET_CROSS_SELL_LIST {
  static readonly type = `[${PREFIX}] GET CROSS SELL LIST`;

  constructor(public readonly payload) {}
}

export class GET_APPROVAL_PRODUCT_PDF {
  static readonly type = `[${PREFIX}] GET APPROVAL PRODUCT PDF`;

  constructor(public readonly payload) {}
}

export class SAVE_APPROVAL_MESSAGE_SENT_LOG {
  static readonly type = `[${PREFIX} SAVE APPROVAL MESSAGE SENT LOG]`
  constructor(public readonly payload) { }
}

export class SAVE_APPROVAL_EMAIL_SENT_LOG {
  static readonly type = `[${PREFIX} SAVE APPROVAL EMAIL SENT LOG]`
  constructor(public readonly payload) { }
}