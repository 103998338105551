import { SharedModule } from "../../shared/shared.module";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import {
  NxMultiStepperComponent,
  NxMultiStepperDirection,
  NxProgressStepperDirective,
} from "@aposin/ng-aquila/progress-stepper";

@Component({
  selector: "app-progress-bar",

  standalone: true,
  imports: [SharedModule],
  templateUrl: "./progress-bar.component.html",
  styleUrl: "./progress-bar.component.scss",
})
export class ProgressBarComponent {
  direction: NxMultiStepperDirection = "vertical";

  @Input() progressValue!: number;
  @Input() progressType!: string;

  unification = [
    {
      name: "Agent Additional Info",
      completed: false,
    },
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Customer Partner",
      completed: false,
    },
    {
      name: "Coverage",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  endorsement = [
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Endorsement Details",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  cn_quotation = [
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Customer Partner",
      completed: false,
    },
    {
      name: "Coverage",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  rtp = [
    {
      name: "Declaration & Issuance Info",
      completed: false,
    },
    {
      name: "Summary",
      completed: false,
    },
    {
      name: "Complete",
      completed: false,
    },
  ];

  activeMultiStep: any;

  constructor(private cdr: ChangeDetectorRef) {}
  ngAfterViewInit() {

    if (this.progressType == "cn") {
      this.activeMultiStep = this.cn_quotation;
    } else if (this.progressType == "endorsement") {
      this.activeMultiStep = this.endorsement;
    } else if (this.progressType == "rtp") {
      this.activeMultiStep = this.rtp;
    } else if (this.progressType == "unification") {
      this.activeMultiStep = this.unification;
    }

    for (let i = 0; i <= this.progressValue - 1; i++) {
      if (i >= 0) {
        this.activeMultiStep[i].completed = true;
      }
    }

    // this.completeStep(0);
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  // completeStep(i: number) {
  //   this.multiSteps[i].completed = !this.multiSteps[i].completed;
  // }
}
