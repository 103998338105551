import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
class NxBadgeComponent {
  /** Sets the class name for the badge element.  */
  set type(value) {
    if (value !== this._type) {
      this._type = value; // TODO properly coerce input value
      this._cdr.markForCheck();
    }
  }
  get type() {
    return this._type;
  }
  /** Change badge style to vibrant. */
  set vibrant(value) {
    const newValue = coerceBooleanProperty(value);
    if (value !== this._vibrant) {
      this._vibrant = newValue;
      this._cdr.markForCheck();
    }
  }
  get vibrant() {
    return this._vibrant;
  }
  constructor(_cdr) {
    this._cdr = _cdr;
    this._vibrant = false;
  }
  static {
    this.ɵfac = function NxBadgeComponent_Factory(t) {
      return new (t || NxBadgeComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxBadgeComponent,
      selectors: [["nx-badge"]],
      hostVars: 10,
      hostBindings: function NxBadgeComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("nx-badge--active", ctx.type === "active")("nx-badge--positive", ctx.type === "positive")("nx-badge--critical", ctx.type === "critical")("nx-badge--negative", ctx.type === "negative")("nx-badge--vibrant", ctx.vibrant);
        }
      },
      inputs: {
        type: "type",
        vibrant: "vibrant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function NxBadgeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{font-size:var(--badge-font-size);line-height:var(--badge-line-height);font-weight:var(--badge-font-weight);letter-spacing:var(--badge-letter-spacing);display:inline-block;padding:0 8px;white-space:nowrap;border-radius:12px;min-width:24px}@media screen and (forced-colors: active){[_nghost-%COMP%]{border:1px solid CanvasText}}[_nghost-%COMP%]{color:var(--badge-color);background-color:var(--badge-background-color)}.nx-badge--active[_nghost-%COMP%]{background-color:var(--badge-active-background-color);color:var(--badge-active-color)}.nx-badge--positive[_nghost-%COMP%]{background-color:var(--badge-positive-background-color);color:var(--badge-positive-color)}.nx-badge--critical[_nghost-%COMP%]{background-color:var(--badge-critical-background-color);color:var(--badge-critical-color)}.nx-badge--negative[_nghost-%COMP%]{background-color:var(--badge-negative-background-color);color:var(--badge-negative-color)}.nx-badge--vibrant[_nghost-%COMP%]{color:var(--badge-vibrant-color);background-color:var(--badge-vibrant-background-color)}.nx-badge--vibrant.nx-badge--active[_nghost-%COMP%]{background-color:var(--badge-vibrant-active-background-color);color:var(--badge-vibrant-active-color)}.nx-badge--vibrant.nx-badge--positive[_nghost-%COMP%]{background-color:var(--badge-vibrant-positive-background-color);color:var(--badge-vibrant-positive-color)}.nx-badge--vibrant.nx-badge--critical[_nghost-%COMP%]{background-color:var(--badge-vibrant-critical-background-color);color:var(--badge-vibrant-critical-color)}.nx-badge--vibrant.nx-badge--negative[_nghost-%COMP%]{background-color:var(--badge-vibrant-negative-background-color);color:var(--badge-vibrant-negative-color)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxBadgeComponent, [{
    type: Component,
    args: [{
      selector: 'nx-badge',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.nx-badge--active]': 'type === "active"',
        '[class.nx-badge--positive]': 'type === "positive"',
        '[class.nx-badge--critical]': 'type === "critical"',
        '[class.nx-badge--negative]': 'type === "negative"',
        '[class.nx-badge--vibrant]': 'vibrant'
      },
      styles: [":host{font-size:var(--badge-font-size);line-height:var(--badge-line-height);font-weight:var(--badge-font-weight);letter-spacing:var(--badge-letter-spacing);display:inline-block;padding:0 8px;white-space:nowrap;border-radius:12px;min-width:24px}@media screen and (forced-colors: active){:host{border:1px solid CanvasText}}:host{color:var(--badge-color);background-color:var(--badge-background-color)}:host.nx-badge--active{background-color:var(--badge-active-background-color);color:var(--badge-active-color)}:host.nx-badge--positive{background-color:var(--badge-positive-background-color);color:var(--badge-positive-color)}:host.nx-badge--critical{background-color:var(--badge-critical-background-color);color:var(--badge-critical-color)}:host.nx-badge--negative{background-color:var(--badge-negative-background-color);color:var(--badge-negative-color)}:host.nx-badge--vibrant{color:var(--badge-vibrant-color);background-color:var(--badge-vibrant-background-color)}:host.nx-badge--vibrant.nx-badge--active{background-color:var(--badge-vibrant-active-background-color);color:var(--badge-vibrant-active-color)}:host.nx-badge--vibrant.nx-badge--positive{background-color:var(--badge-vibrant-positive-background-color);color:var(--badge-vibrant-positive-color)}:host.nx-badge--vibrant.nx-badge--critical{background-color:var(--badge-vibrant-critical-background-color);color:var(--badge-vibrant-critical-color)}:host.nx-badge--vibrant.nx-badge--negative{background-color:var(--badge-vibrant-negative-background-color);color:var(--badge-vibrant-negative-color)}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    type: [{
      type: Input
    }],
    vibrant: [{
      type: Input
    }]
  });
})();
class NxBadgeModule {
  static {
    this.ɵfac = function NxBadgeModule_Factory(t) {
      return new (t || NxBadgeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxBadgeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxBadgeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [NxBadgeComponent],
      exports: [NxBadgeComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxBadgeComponent, NxBadgeModule };
