import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NX_DATE_FORMATS, NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { SummaryPopupComponent } from "../../../../popup/summary-popup/summary-popup.component";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import moment from "moment";
import { formatDate } from '@angular/common';

import {
  CALCULATE_PREMIUM_SUMMARY,
  DOWNLOAD_PDF,
  GENERATE_QUOTATION,
  GET_DOCUMENT_LOV,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  SAVE_PAYMENT,
  SEND_TO_JPJ,
  SET_CURRENT_STEP,
  SET_JPJ_STATUS_SENT,
  STORE_ISSUANCE_SUMMARY,
  STORE_PAYMENT_INFO,
  STORE_ROAD_TAX_PAYABLE,
  STORE_SHOWED_ONE_ALLIANZ_POP_UP,
} from "@store/sat-motor/sat-motor.action";
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { SharedModule } from '@shared/shared.module';
import { OneAllianzMessageComponent } from '@shared/components/modal/one-allianz-message/one-allianz-message.component';
import { PaymentDetailsComponent } from '@shared/components/payment-details/payment-details.component';
import { UpsellProductComponent } from '@shared/components/upsell-product/upsell-product.component';
import { UserResponse } from "@interfaces/user";
import { CoverageSummaryComponent } from '@shared/components/coverages/coverage-summary/coverage-summary.component';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { ScrollToError } from '@shared/classes/scroll-to-error';
import { EndorsementNarrationComponent } from "@shared/components/endorsement-narration/endorsement-narration.component";
import { PaymentComponent } from "@shared/components/payment/payment.component";
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { RoadTaxPayableComponent } from '@shared/components/road-tax-payable/road-tax-payable.component';
import { PaymentInformationComponent } from '@shared/components/payment-information/payment-information.component';
import { RemoveCommaPipe } from '@shared/pipes/remove-comma/remove-comma.pipe';
import { SimpleModalComponent } from '@shared/components/modal/simple-modal/simple-modal.component';
import { ConfirmationModalComponent } from '@shared/components/modal/confirmation-modal/confirmation-modal.component';
import { WarningPopupComponent } from '@shared/components/modal/warning-popup/warning-popup.component';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    ProgressBarComponent,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    //NxMomentDateModule,
    NxRadioModule,
    SharedModule,
    PaymentDetailsComponent,
    UpsellProductComponent,
    CoverageSummaryComponent,
    OkMessageComponent,
    EndorsementNarrationComponent,
    PaymentComponent,
    RoadTaxPayableComponent,
    PaymentInformationComponent
  ],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent implements OnInit {

  @Select(SatMotorState.motorActionType) actionType$;

  isIssuance: boolean = false;
  isEndorsement: boolean = false;
  isEnquiry: boolean = false;
  isRTP: boolean = false;

  progressType: any;
  userInfo: any;
  agentData: any;
  issuanceInfo: any = {};
  clientClaimHistory: any;
  vehicleClaimHistory: any;
  rtpPolicy: any;
  premiumInfo: any;
  coverageInfo: any;
  cpData: any;
  lov: any;
  isProgressTypeCN: boolean = false;
  isProgressTypeENDST: boolean = false;
  isProgressTypeRTP: boolean = false;

  showAgentInfo: boolean = false;
  showIssuanceInfo: boolean = false;
  showCustomerPartner: boolean = false;
  showReferralDetails: boolean = false;
  showCoverage: boolean = false;
  showHistoricalClaim: boolean = false;
  showPremiumInformation: boolean = false;
  showReferCase: boolean = false;
  showPayment: boolean = false;
  showUpsellProduct: boolean = false;
  showPaymentInformation: boolean = false;
  showPaymentDetails: boolean = false;
  showChecklist: boolean = false;
  showRenewalAssistant: boolean = false;
  showEndorsementNarration: boolean = false;
  showRoadTaxPayable: boolean = false;
  showEndorsementErrMessage: boolean = false;
  showEditButton: boolean = true;
  generateQuotationIsDone: boolean = false;
  isCCentric: boolean = false;

  referralForm: FormGroup;
  referCaseForm: FormGroup;
  previousPolicyDetails: any;
  requoteQuotation: any;
  renewalAssistanceInd: any;
  cnoteType: any;
  renewalAssistanceDetails: any;

  dialogRef?: NxModalRef<any>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  simpleModal?: NxModalRef<SimpleModalComponent>;
  confirmMessageModal?: NxModalRef<ConfirmationModalComponent>;
  warningPopupModal?: NxModalRef<WarningPopupComponent>;
  summaryForm: FormGroup;
  infoMessageModal?: NxModalRef<SimpleModalComponent>;

  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;

  alimCustomerEligible: boolean;
  hasShowedOneAllianzPopUp: boolean;
  oneAllianzModal?: NxModalRef<OneAllianzMessageComponent>;
  paymentList: any = [];
  payment: any;
  roadTaxPayable: any;
  paymentInformation: any;
  numberFmt: any;
  motorPC: any;
  lovproductConfig: any;
  productAccessListData: any;
  lovPC: string;
  lovGeo: any;
  emailCheck : any;
  edocConsentInd: boolean = false;
  eInvoiceConsentInd : boolean;
  isUpdatePayment: any;
  isSingleAgent: boolean = false;
  isCancelReplaceCoverNote: boolean = false;
  renewalAssistanceCheckbox: boolean = false;
  selection: any;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.numberFmt = new RemoveCommaPipe();
    this.referralForm = this.fb.group({
      referralId: ['',]
    });

    this.referCaseForm = this.fb.group({
      waiveLoadInd: [{ value: false, disabled: true }],
      waiveExcessInd: [{ value: false, disabled: true }],
      underwritingInd: [false],
      remarks: ['']
    });

    this.summaryForm = this.fb.group({});
  }

  renewalAssistanceForm = new FormGroup({
    autoRenewalInd: new FormControl(false),
    creditCardType: new FormControl(''),
    creditCardNo: new FormControl(''),
    creditCardExpMonth: new FormControl(''),
    creditCardExpYear: new FormControl(''),
    creditCardHolderName: new FormControl(''),
    termsOfUse: new FormControl({ value: false, disabled: true }),
    autoRenewalStartDt: new FormControl('')
  });

  monthList = [
    { code: '1', name: '01' },
    { code: '2', name: '02' },
    { code: '3', name: '03' },
    { code: '4', name: '04' },
    { code: '5', name: '05' },
    { code: '6', name: '06' },
    { code: '7', name: '07' },
    { code: '8', name: '08' },
    { code: '9', name: '09' },
    { code: '10', name: '10' },
    { code: '11', name: '11' },
    { code: '12', name: '12' },
  ];

  yearList = [
    { code: '2024', name: '2024' },
    { code: '2025', name: '2025' },
    { code: '2026', name: '2026' },
    { code: '2027', name: '2027' }
  ];

  paymentModeList = [
    { code: 'MASTER', name: 'MASTER' },
    { code: 'VISA', name: 'VISA' }
  ];

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });

    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.previousPolicyDetails = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.previousPolicyDetails);
    this.cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1?.issuance?.issuance?.cnoteType);
    this.edocConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1?.issuance?.pdpa?.consentDetails?.edocConsentInd);
    this.eInvoiceConsentInd = this.store.selectSnapshot<boolean>(state => state.SatMotorState.motor.step1?.issuance?.pdpa?.consentDetails?.einvoiceConsentInd);
    this.isUpdatePayment = this.store.selectSnapshot<any>(state => state.SatMotorState.isUpdatePayment);
    this.isCCentric = this.store.selectSnapshot<any>((state) => state.SatMotorState.opportunityId);
    this.agentData = this.store.selectSnapshot(SatMotorState.motorAgentDetail);
    this.isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
    this.requoteQuotation = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState.requoteQuotation);
    this.renewalAssistanceInd = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState?.motor?.step1?.agentDetail?.enableRenewalAssistance);
    this.renewalAssistanceDetails = this.store.selectSnapshot<UserResponse>((state) => state.SatMotorState?.motor?.step4?.summary?.renewalAssistance);

    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });
    
    this.setStep();
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);

    this.showComponents();
    this.setProgressType();

    if (this.isIssuance) {
      this.retrieveIssuanceData();
    }

    if (this.isRTP) {
      this.retrieveRTPData();
    }

    if (this.isEndorsement) {
      this.retrieveEndorsementData();
    }

    if(this.renewalAssistanceInd){
      if(!this.renewalAssistanceForm.controls.autoRenewalInd.value){
        this.populateRenewalAssistanceForm();
      }
      this.showRenewalAssistant = true;
      if(this.isUpdatePayment){
        this.disableRenewalAssistanceForm();
      }
    }else{
      this.showRenewalAssistant = false;
    }

    if (this.previousPolicyDetails || this.requoteQuotation) {
      
      if(this.previousPolicyDetails){
        this.premiumInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
        this.premiumInfo = {
          ...this.premiumInfo,
          stamp: this.previousPolicyDetails.stamp,
          premiumDueRounded: this.previousPolicyDetails.premiumDueRounded
        };
      }else if(this.requoteQuotation){
        this.premiumInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote);
        
      if(this.premiumInfo?.oppurtunityId && this.premiumInfo?.proposalNo){
        this.generateQuotationIsDone = true;
      }
      }
    }

    if(this.isCCentric && !this.generateQuotationIsDone){
      this.isCCentric = true;
    }else{
      this.isCCentric = false;
    }

    this.isCancelReplaceCoverNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.isCancelReplaceCoverNote);

    this.saveSummaryStep();

  }

  setStep() {
    let step;
    if (this.isRTP) {
      step = 2;
    } else if (this.cnoteType == "ENDT") {
      step = 3;
    } else {
      if ((this.userInfo.callCentreType != undefined || (!!this.agentData?.customerType) || this.agentData?.bancaAgent || this.agentData?.sundriesAgent) && !this?.isSingleAgent) {
        step = 5;
      } else {
        step = 4;
      }
    }
    this.store.dispatch(new SET_CURRENT_STEP(step));
  }
  
  retrieveIssuanceData() {
    let step4Summary = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step4?.summary);
    const cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step3?.coverage?.coveragePremium?.cngeNote);
    this.alimCustomerEligible = this.store.selectSnapshot<boolean>((state) => state.SatMotorState.motor?.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0].alimCustomerEligible);
    this.hasShowedOneAllianzPopUp = this.store.selectSnapshot<boolean>(state => state.SatMotorState.data?.hasShowedOneAllianzPopUp);
    const activeQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step1?.activeQuotation ?? null);
    const previousPolicyDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.previousPolicyDetails ?? null);
    const retrieveQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.retrieveQuotation ?? null);
    const vehicleDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step3.coverage.vehicleDetails);
    let generateQuotationData = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data);
    let oldData = null;

    if ((activeQuotation && retrieveQuotation) || previousPolicyDetails) {
      oldData = activeQuotation ? activeQuotation.cngeNote : previousPolicyDetails;
    }

    if (this.alimCustomerEligible && !this.hasShowedOneAllianzPopUp) {
      this.openPopupOneAllianz();
    }

    //Agent Information
    const agentDetail = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.agentDetail);
    this.agentData = {
      ...agentDetail,
      issuedDate: new Date().toISOString().substring(0, 10),
      issuedBy: this.userInfo.userId
    };

    //Issuance Information
    if (oldData) {
      this.issuanceInfo = this.getIssuanceData(oldData);
    } else {
      this.issuanceInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.issuance);
      this.issuanceInfo = {
        ...this.issuanceInfo,
        issuance: {
          ...this.issuanceInfo.issuance,
          vehicleNo: vehicleDetails.vehicleNo,
          ...(generateQuotationData && {
            equotationNo: generateQuotationData.proposalNo,
            equotationCreatedDate: generateQuotationData.createDate
          })
        }
      };
    }

    //Customer Partner
    this.cpData = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step2);

    //Coverage
    this.coverageInfo = this.store.selectSnapshot(SatMotorState.motorStep3Coverage);

    //Historical claims
    this.clientClaimHistory = cngeNote?.claimCnt ?? '0';
    this.vehicleClaimHistory = cngeNote?.vehClaimCount ?? '0';

    //Premium Information
    this.loadPremiumSummaryData();

    //ReferralId and Remarks populate
    this.populateReferralRemarks(step4Summary);

    //Refer Case
    this.updateRemarksValidator();
    this.subscribeToCheckboxChanges();

    this.store.dispatch(new GET_DOCUMENT_LOV({
      lob: "MT",
      userId: this.userInfo.userId,
      productCode: this.issuanceInfo.issuance.productCode,
      productType: "",
      type: "SATMTCNDOCTYPE"
    }));

    if (this.userInfo.callCenter) {
      this.getPaymentData(step4Summary);
    }
  }

  getIssuanceData(cngeNote: any): any {
    this.issuanceInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step1.issuance);
    let risk = cngeNote.riskList[0];

    const issuanceInfo = {
      issuance: {
        productCode: cngeNote.productCode,
        vehicleNo: cngeNote.vehicleNo ?? risk.vehicleNo,
        cnoteType: cngeNote.cnoteType,
        prevPolicyNo: cngeNote.previousPolicyNo,
        effectiveDate: cngeNote.effectiveDate,
        expiryDate: cngeNote.expiryDate,
        equotationNo: cngeNote.proposalNo,
        equotationCreatedDate: cngeNote.createDate,
        eCoverNoteNo: cngeNote.cnoteNo,
        jpjStatus: risk.jpjStatus,
        jpjMessage: cngeNote.jpjMessage,
      },
      pdpa: {
        consentDetails: {
          edocConsentInd: this.issuanceInfo?.pdpa?.consentDetails?.edocConsentInd,
          einvoiceConsentInd: this.issuanceInfo?.pdpa?.consentDetails?.einvoiceConsentInd,
        },
      },
    };

    return issuanceInfo;
  }

  populateReferralRemarks(data) {
    if (!data || !data.referCaseData) {
      const cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step3?.coverage?.coveragePremium?.cngeNote);

      if (cngeNote) {
        data = null;
        let remarks = (cngeNote.referRemarkList && cngeNote.referRemarkList.length > 0) ? cngeNote.referRemarkList[0].referRemark : null;
        data = {
          referralId: cngeNote.agentReferId,
          referCaseData: {
            waiveLoadInd: cngeNote.referWaiveLoad,
            waiveExcessInd: cngeNote.referWaiveExcess,
            underwritingInd: cngeNote.referUnderWriting,
            remarks: remarks
          }
        }
      }
    }

    if (data) {
      if (data.referralId !== null && data.referralId !== undefined && data.referralId !== '') {
        this.referralForm.patchValue({
          referralId: data.referralId
        });
      }

      if (data.referCaseData) {
        const referCaseData = data.referCaseData;

        const formValue: any = {
          waiveLoadInd: referCaseData.waiveLoadInd,
          waiveExcessInd: referCaseData.waiveExcessInd,
          underwritingInd: referCaseData.underwritingInd
        };

        if (referCaseData.remarks !== null && referCaseData.remarks !== undefined && referCaseData.remarks !== '') {
          formValue.remarks = referCaseData.remarks;
        }

        this.referCaseForm.patchValue(formValue);
      }
    }
  }

  retrieveEndorsementData() {
    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    const endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);

    let step3Summary = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails);
    // const cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState?.motor?.step3?.coverage?.coveragePremium?.cngeNote);
    // this.alimCustomerInd = this.store.selectSnapshot<string>((state) => state.SatMotorState?.motor?.step2?.alimCustomerIndicator) === "YES";
    this.hasShowedOneAllianzPopUp = this.store.selectSnapshot<boolean>(state => state.SatMotorState.data?.hasShowedOneAllianzPopUp);
    const activeQuotation = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step1?.activeQuotation ?? null);
    const previousPolicyDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.previousPolicyDetails ?? null);

    //Agent Information
    const agentDetail = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.agentDetail);
    console.log('agentDetail:' + agentDetail); //undefined
    this.agentData = {
      ...agentDetail,
      agentCode: endtVehiclePolicyData?.mkagAgent?.agentCode,
      name: endtVehiclePolicyData?.mkagAgent?.name,
      issuedDate: endtVehiclePolicyData?.cnoteDate,
      issuedBy: endtVehiclePolicyData?.issueBy
    };

    //Issuance Information
    this.issuanceInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step1.issuance);

    //Customer Partner

    let eInvoiceCustomerPartner = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step2.endorsementDetails.eInvoiceCustomerPartner);

    if (eInvoiceCustomerPartner) {
      this.cpData = eInvoiceCustomerPartner;

    } else if (!eInvoiceCustomerPartner && endtVehiclePolicyData) {
      let customerPartnerData = endtVehiclePolicyData?.clientPartner;
      this.cpData = {
        ...customerPartnerData,
        // fixLine: endtVehiclePolicyData?.clientPartner?.fixLine, //not found
        // faxNumber: endtVehiclePolicyData?.clientPartner?.faxNumber, //not found
        email: endtVehiclePolicyData?.clientPartner?.clientEmail,
      };
    }

    //Coverage
    let endorsementDetails = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement.step2.endorsementDetails); //need to check
    console.log('summary endorsementDetails:', endorsementDetails);

    if (!this.coverageInfo) {
      this.getCoverageFromPolicy(endtVehiclePolicyData);
    }

    let coverageInfoTemp = this.coverageInfo;

    if (endorsementDetails.vehicleDetails) {
      this.coverageInfo = {
        ...coverageInfoTemp,
        vehicleDetails: endorsementDetails.vehicleDetails,
      };
    } else if (endorsementDetails.extraCover) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        extraCover: endorsementDetails.extraCover,
      };
    } else if (endorsementDetails.vehicleSumInsured) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        vehicleSumInsured: endorsementDetails.vehicleSumInsured,
      };
    } else if (endorsementDetails.ncd) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        ncd: endorsementDetails.ncd,
      };
    } else if (endorsementDetails.namedDriver) { //need to fix to match with rtp and issuance for coverageInfo
      this.coverageInfo = {
        ...coverageInfoTemp,
        namedDriver: endorsementDetails.namedDriver,
      };
    }

    console.log('summary this.coverageInfo:', this.coverageInfo);

    //Historical claims
    this.clientClaimHistory = endtPreviousVehiclePolicyData?.claimCnt ?? '0';
    this.vehicleClaimHistory = endtPreviousVehiclePolicyData?.vehClaimCount ?? '0'; //no data for now

    //Premium Information
    // this.loadPremiumSummaryData();
    this.premiumInfo = endtVehiclePolicyData?.riskList[0]?.coverList[0];
    this.premiumInfo = {
      ...this.premiumInfo,
      stamp: endtVehiclePolicyData.stamp,
      premiumDueRounded: endtVehiclePolicyData.premiumDueRounded
    };

    //ReferralId and Remarks populate
    this.populateReferralRemarks(step3Summary);

    //Refer Case
    this.updateRemarksValidator();
    this.subscribeToCheckboxChanges();

    this.store.dispatch(new GET_DOCUMENT_LOV({
      lob: "MT",
      userId: this.userInfo.userId,
      productCode: this.issuanceInfo.issuance.productCode,
      productType: "",
      type: "SATMTCNDOCTYPE"
    }));
  }

  retrieveRTPData() {
    this.rtpPolicy = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto);
    let vehicleNo = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.issuance?.issuance?.vehicleNo);

    if (this.rtpPolicy) {
      this.agentData = { ...this.rtpPolicy.mkagAgent, issuedDate: this.rtpPolicy.cnoteDate, issuedBy: this.rtpPolicy.issueBy };

      //Declaration issuance
      let issuanceData = {
        productCode: this.rtpPolicy.productCode,
        vehicleNo: this.rtpPolicy.vehicleNo ?? vehicleNo,
        coverNoteType: 'ROAD TAX PURPOSE',
        policyNo: this.rtpPolicy.policyNo,
        effectiveDate: this.rtpPolicy.effectiveDate,
        expiryDate: this.rtpPolicy.expiryDate,
        eCoverNoteNo: this.rtpPolicy.cnoteNo,
      }

      this.issuanceInfo = {
        issuance: issuanceData
      }

      //Customer Partner
      this.cpData = this.rtpPolicy.clientPartner;


      //Coverage
      if (!this.coverageInfo) {
        this.getCoverageFromPolicy(this.rtpPolicy);
      }

      //Premium Calculation
      this.premiumInfo = this.rtpPolicy?.riskList[0]?.coverList[0];
      this.premiumInfo = {
        ...this.premiumInfo,
        stamp: this.rtpPolicy.stamp,
        premiumDueRounded: this.rtpPolicy.premiumDueRounded
      };
    }

    this.clientClaimHistory = this.rtpPolicy.claimCnt ?? '0';
    this.vehicleClaimHistory = this.rtpPolicy.vehClaimCount ?? '0';
  }

  loadPremiumSummaryData() {
    let cngeNote = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote);
    const premiumInfo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step3?.coverage?.coveragePremium?.cngeNote?.riskList[0]?.coverList[0] ?? null);

    if (premiumInfo && cngeNote) {
      this.premiumInfo = {
        ...premiumInfo,
        stamp: cngeNote.stamp,
        premiumDueRounded: cngeNote.premiumDueRounded
      };
      this.updateReferState();
    }
  }

  getCoverageFromPolicy(policy) {
    let risk = policy.riskList[0];
    let coverList = risk.coverList[0];

    const coverageInfo = {
      vehicleDetails: risk,
      coverageDetails: coverList,
      driverNameDetails: this.getDriverListForRTPAndEndt(risk.cngeRiskDrvrDtoList, true),
      ehailingDriverDetails: this.getDriverListForRTPAndEndt(risk.cngeRiskDrvrDtoList, false),
      extraCoverPackageSummary: this.getExtraCoverage(coverList),
    };

    this.coverageInfo = coverageInfo;
  }

  getExtraCoverage(coverList: any) {
    if (!coverList || !coverList.subCoverList) {
      return {
        subCovPackageDesc: null,
        rahmahPackageList: [],
        topExtraCoverList: [],
        selectedExtraCoverList: [],
      };
    }

    const extraCoverPackage = coverList.subCoverList.map(cv => ({
      ...cv,
      description: cv.subCoverCodeDescription,
      selected: cv.selectedInd,
      enabled: cv.enableInd ?? false,
    }));

    return {
      subCovPackageDesc: coverList.subCovPackageDesc ?? null,
      rahmahPackageList: extraCoverPackage.filter(o1 => o1.subCovPackage && !o1.enabled && !o1.subCoverPrem),
      topExtraCoverList: extraCoverPackage.filter(o1 => o1.selected && !o1.enabled && !o1.subCoverPrem && !o1.subCovPackage),
      selectedExtraCoverList: extraCoverPackage.filter(o1 => o1.selected && o1.subCoverPrem > 0),
    };
  }

  getDriverListForRTPAndEndt(cngeRiskDrvrDtoList, isNormalDriver) {
    return cngeRiskDrvrDtoList?.filter(({ cngeRiskDrvrPkDto: { driverType } }) =>
      isNormalDriver ? driverType === 'NORM' : driverType === 'EHAIL'
    ).map(({ cngeRiskDrvrPkDto: { driverName }, driverId, driverAge }) => ({
      driverName,
      driverId,
      driverAge,
    })) || null;
  }

  getDriverListData(cngeRiskDrvrDtoList: any) {
    if (cngeRiskDrvrDtoList) {
      return cngeRiskDrvrDtoList.map(item => {
        return {
          driverName: item.cngeRiskDrvrPkDto.driverName,
          driverId: item.driverId,
          driverAge: item.driverAge
        };
      });
    }
    return null;
  }

  setProgressType() {
    if (this.isIssuance) {
      this.progressType = 'cn';
    }

    if (this.isRTP) {
      this.progressType = 'rtp';
    }

    if (this.isEndorsement) {
      this.progressType = 'endorsement';
    }
  }

  saveSummaryStep() {

    let summaryData = {
      agentData: this.agentData,
      issuanceInfo: this.issuanceInfo,
      customerPartnerDetails: this.cpData,
      referralId: this.referralForm?.get('referralId')?.value ?? null,
      coverageInfo: this.coverageInfo,
      clientClaimHistory: this.clientClaimHistory,
      vehicleClaimHistory: this.vehicleClaimHistory,
      premiumInfo: this.premiumInfo,
      referCaseData: this.referCaseForm.getRawValue(),
      renewalAssistance:  this.renewalAssistanceForm.getRawValue()
    };

    console.log('Summary Data:', summaryData);
    this.store.dispatch(new STORE_ISSUANCE_SUMMARY(summaryData));

  }

  onBlurReferralId() {
    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
    }
    if (this.referralForm.valid) {
      this.saveSummaryStep();
    }
  }

  updateReferState() {
    this.toggleReferControlState('waiveLoadInd', this.premiumInfo.loadAmount > 0);
    this.toggleReferControlState('waiveExcessInd', this.premiumInfo.excessAmount > 0);
  }

  toggleReferControlState(controlName: string, condition: boolean) {
    const control = this.referCaseForm.get(controlName);
    condition ? control.enable() : control.disable();
  }

  updateRemarksValidator() {
    const waiveLoadInd = this.referCaseForm.get('waiveLoadInd').value;
    const waiveExcessInd = this.referCaseForm.get('waiveExcessInd').value;
    const underwritingInd = this.referCaseForm.get('underwritingInd').value;

    if (waiveLoadInd || waiveExcessInd || underwritingInd) {
      this.referCaseForm.get('remarks').setValidators([Validators.required]);
    } else {
      this.referCaseForm.get('remarks').clearValidators();
    }
    this.referCaseForm.get('remarks').updateValueAndValidity();
  }

  subscribeToCheckboxChanges() {
    this.referCaseForm.get('waiveLoadInd').valueChanges.subscribe(() => this.updateRemarksValidator());
    this.referCaseForm.get('waiveExcessInd').valueChanges.subscribe(() => this.updateRemarksValidator());
    this.referCaseForm.get('underwritingInd').valueChanges.subscribe(() => this.updateRemarksValidator());
  }

  showComponents() {
    if (this.isIssuance) {
      this.showAgentInfo = this.showIssuanceInfo = this.showCustomerPartner =
        this.showReferralDetails = this.showCoverage = this.showHistoricalClaim =
        this.showPremiumInformation = this.showReferCase = true;

        if (this.userInfo.callCenter) {
          this.showPaymentDetails = true;
          this.showPaymentInformation = true;
          this.showPayment = true;
          this.showRoadTaxPayable = true;
          this.showUpsellProduct = true;
        }
    }

    if (this.isRTP) {
      this.showEditButton = false;
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      // this.showIssuanceInfo = this.showCustomerPartner =
      //   this.showReferralDetails = this.showCoverage = this.showHistoricalClaim =
      //   this.showPremiumInformation = this.showReferCase = true;
    }

    if (this.isEndorsement) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.showChecklist = true; //endorsement only
      this.showEndorsementNarration = true; //endorsement only
      this.showPayment = true; //endorsement only
    }
  }

  componentDialogRef?: NxModalRef<SummaryPopupComponent>;

  openPopup(popup_type: any) {

    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopupOCC(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      height: '500px',
      data: { popup_type: popup_type },
    });
  }

  warningPopup(popup_type: any, error: any) {
    this.warningPopupModal = this.dialogService.open(WarningPopupComponent, {
      ariaLabel: "A simple dialog",
      width: '400px',
      showCloseIcon: false,
      data: {
        popup_type: popup_type,
        popup_error: error
      },
    });
  }

  goToIssueDeclaration() {
    this.saveSummaryStep();

    this.router.navigate(['issuance/declaration-issuance-info'], {
      queryParamsHandling: 'preserve',
    });

  }

  goToCPScreen() {
    this.saveSummaryStep();

    this.router.navigate(['issuance/customer-partner'], {
      queryParamsHandling: 'preserve',
    });
  }

  goToCoverageScreen() {
    this.saveSummaryStep();

    this.router.navigate(['issuance/coverage'], {
      queryParamsHandling: 'preserve',
    });
  }

  openPopupOneAllianz() {
    this.oneAllianzModal = this.dialogService.open(OneAllianzMessageComponent, {
      showCloseIcon: false,
      width: "400px",
      maxWidth: "400px",
    });
    this.oneAllianzModal.afterClosed().subscribe((result) => {
      this.store.dispatch(new STORE_SHOWED_ONE_ALLIANZ_POP_UP(true));
    });
  }

  validateReferral() {
    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }

    return this.referralForm.valid;
  }

  validateReferCase() {
    const underwritingIndControl = this.referCaseForm.get('underwritingInd');
    const remarksControl = this.referCaseForm.get('remarks');

    if (underwritingIndControl?.value) {
      remarksControl?.setValidators([Validators.required]);
    } else {
      remarksControl?.clearValidators();
    }
    remarksControl?.updateValueAndValidity();

    if (this.referCaseForm.invalid) {
      console.log("referCaseForm invalid!");
      this.referCaseForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }

    return this.referCaseForm.valid;
  }

  validatePayment() {
    if(!this.paymentList || this.paymentList.length === 0) {
      this.simpleModal = this.dialogService.open(SimpleModalComponent, {
        width: '400px',
        data: {
          type: "Warning",
          message: "Please at least add one payment."
        }
      });
      return false;
    }else {
      return true;
    }
  }

  scrollToFirstInvalidControl(form: FormGroup) {
    const firstInvalidControl: HTMLElement = document.querySelector('form .ng-invalid');
    if (firstInvalidControl) {
      firstInvalidControl.focus();
      firstInvalidControl.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }

  validateRenewalAssistance() {
    if (this.renewalAssistanceForm.invalid) {
      console.log("renewalAssistanceForm invalid!");
      this.renewalAssistanceForm.markAllAsTouched();
    } else {
      console.log("renewalAssistanceForm valid...");
    }

    return this.renewalAssistanceForm.valid;
  }

  generate() {

    let cnoteType = "";
    let updateQuotation = false;
    
    if (this.isEndorsement) {
      cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.generateQuotation?.cnoteStatus);
    } else {
      cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.cnoteStatus);

      let isQuotationGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data?.proposalNo);
      let isCoverNoteGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ?.data?.cnoteNo);
      let isUpdateQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState.updateQuotation);
      updateQuotation = isQuotationGenerated || isCoverNoteGenerated || isUpdateQuotation ? true : false;
    }

    let isFormValid = this.validateFormFields();
    const isCnoteStatusPrinted = cnoteType === "PRINTED";

    if (!isFormValid) {
      console.log("Form Validation Failed.");
      return;
    }

    let payload = this.getPayloadData();

    let resetPayload = {};

    if (this.isRTP) {
      resetPayload = {
        isRTP: this.isRTP
      };
    } else if (this.isEndorsement) {
      resetPayload = {
        isEndorsement: this.isEndorsement
      };
    }

    if (!isCnoteStatusPrinted && !updateQuotation) {
      this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    }
    this.store.dispatch(new GENERATE_QUOTATION(payload, this.cnoteType)).subscribe(() => { //need to check for endorsement
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation ?? null);
      console.log(response);

      if (response && response.data && response.data.contractId && response.success) {
        let contractId = response?.data?.contractId;

        if (response?.data?.cnoteStatus === 'PRINTED') {
          this.downloadPDF(contractId, 'quotation');
        }

        this.router.navigate(['issuance/complete'], {
          queryParamsHandling: 'preserve',
        });

      } else {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: response.message
          }
        });
      }
    });
  }

  getPayloadData() {
    let payload = {
      userId: this.userInfo.userId,
      waiveLoadInd: this.referCaseForm.get('waiveLoadInd').value,
      waiveExcessInd: this.referCaseForm.get('waiveExcessInd').value,
      underwritingInd: this.referCaseForm.get('underwritingInd').value,
      agentReferId: this.referralForm.get('referralId').value,
      referRemarkList: null,
      isEndorsement: this.isEndorsement,
    };

    const referRemarkValue = this.referCaseForm.get('remarks').value;
    if (referRemarkValue) {
      payload = {
        ...payload,
        referRemarkList: [
          {
            referRemark: referRemarkValue,
            createdBy: this.userInfo.userId
          }
        ]
      };
    } else {
      delete payload.referRemarkList;
    }

    this.saveSummaryStep();
    return payload;
  }

  validateFormFields() {
    let isValidReferral = this.validateReferral();
    let isValidRemark = this.validateReferCase();
    let isValidRenewalAssistance = this.validateRenewalAssistance();

    return this.isRTP ? true : (isValidReferral && isValidRemark && isValidRenewalAssistance);
  }

  validateCCentricFormFields() {
    let isValidPayment = true;

    if(this.isCCentric && this.userInfo.callCenter && !this.userInfo.issueOnQuotation) {
      isValidPayment = this.validatePayment();
    }
    return isValidPayment;
  }

  sendToJPJ() {
    this.motorPC = this.store.selectSnapshot<any>((state)=>state.SatMotorState.motor.step1?.issuance?.issuance?.productCode);
    this.lovproductConfig = this.lov.productAccessConfigList;
    let address1 = this.cpData.clientPartnerAddress.clientAddress1Tmp;
    let emailValue = this.cpData.clientEmail;
    let cnoteStatus = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data?.cnoteStatus);
    let proposalNo = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data?.proposalNo);
    let custAge = this.cpData?.clientAge;
    let cpType = this.cpData?.idType1;
    let updateQuotation = false;
    
    for (let i = 0; i < this.lovproductConfig.length; i++) {
    this.productAccessListData = this.lovproductConfig[i];
    this.lovPC = Object.keys( this.productAccessListData)[0]; 
  
      if (this.lovPC === this.motorPC) {
       this.emailCheck = this.productAccessListData[this.lovPC].emailCheckEnableSat;
      }
    }


    if(( (address1 =="") && (emailValue =="") ) && ( (this.edocConsentInd) || (this.emailCheck =="true") || (this.eInvoiceConsentInd) ) && (!this.isRTP) ){
      this.openPopup("addrEmailSum");
    }
    else if(((address1!="") && (emailValue =="")) && ( (this.edocConsentInd) || (this.emailCheck =="true") || (this.eInvoiceConsentInd) ) &&((!this.isRTP)) ){
      this.openPopup("emailSum");
    }
    else if ( (!address1) && ((!this.isRTP)) ) {
      this.openPopup("address");
    }else if((custAge < 16 || custAge > 99) && (!this.isRTP) &&(cpType !=="NEW_BR_NO" && cpType !=="BR_NO")){
      this.openPopup("custAge");
    }
    else {

      let isFormValid = false;

      if(this.isCCentric) {
        isFormValid = this.validateCCentricFormFields();
      }else {
        isFormValid = this.validateFormFields();
      }

      if (!isFormValid) {
        console.log("Form Validation Failed.");
        return;
      }

      let resetPayload = {};

      if(cnoteStatus === 'PRINTED' && proposalNo){
        //update quotation purpose
        resetPayload = {
          updateQuotation: true
        };
      }

      if (this.isRTP) {
        resetPayload = {
          isRTP: this.isRTP
        };
      } else if (this.isEndorsement) {
        resetPayload = {
          isEndorsement: this.isEndorsement
        };
      } else {
        let isQuotationGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.data?.proposalNo);
        let isCoverNoteGenerated = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ?.data?.cnoteNo);
        let isUpdateQuotation = this.store.selectSnapshot<any>((state) => state.SatMotorState.updateQuotation);
        updateQuotation = isQuotationGenerated || isCoverNoteGenerated || isUpdateQuotation ? true : false;
      }

      let payload = this.getPayloadData() as any;
      payload = {
        ...payload,
        isRTP: this.isRTP,
        isEndorsement: this.isEndorsement,
        cnoteType: this.cnoteType,
        updateQuotation
      };

      if (!updateQuotation) {
        this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
      }
      this.store.dispatch(new SEND_TO_JPJ(payload)).subscribe(() => {
        let response;
        if (this.isRTP) {
          response = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step2?.sendToJPJ ?? null);
          if (response && response.data && response.data.cngeNoteDto.contractId) {
            let contractId = response?.data?.cngeNoteDto?.contractId;

            this.router.navigate(['issuance/complete'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.showResponseError(response, payload);
          }
        } else if (this.isEndorsement) {
          response = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.sendToJPJ ?? null);
          console.log('SEND_TO_JPJ response:' + response); //null

          if (response && response.data && response.data.cngeNoteDto) {
            let contractId = response?.data?.cngeNoteDto?.contractId; //not found in response

            this.router.navigate(['endorsement/complete'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.showResponseError(response, payload);
          }
        } else {
          response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);
          if (response && response.data && response.success) {
            let contractId = response?.data?.contractId;
            if (contractId && response?.data?.cnoteStatus === 'PRINTED') {
              this.downloadPDF(contractId, 'covernote');
            }

            this.store.dispatch(new SET_JPJ_STATUS_SENT(true));

            this.router.navigate(['issuance/complete'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.showResponseError(response, payload);
          }
        }
      });
    }
  }

  showResponseError(response: any, payload) {

    console.log('showResponseError response:' + response);

    let message = (response.message && response.message != '') ? response.message : (response.errorCode && response.errorCode != '') ? response.errorCode : null;

    console.log('showResponseError message:' + message);

    if (message) {
      this.okMessageModal = this.dialogService.open(OkMessageComponent, {
        width: '400px',
        data: {
          message: message
        }
      });
    } else if (response.messageConfirm && response.messageHeader) {
      this.showConfirmationMessage(response, payload);
    }
  }

  showConfirmationMessage(response, payload) {
    this.confirmMessageModal = this.dialogService.open(ConfirmationModalComponent, {
      showCloseIcon: false, width: "550px", maxWidth: "550px",
      data: { message: response.messageConfirm, type: response.messageHeader },
    });
  
    this.confirmMessageModal.afterClosed().subscribe(result => {
      if (result) {
        let resendPayload = {
          ...payload,
          skipErrorAndProceed: true
        }
        if (!payload.updateQuotation) {
          this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ({}));
        }
        
        this.store.dispatch(new SEND_TO_JPJ(resendPayload)).subscribe(() => {
          let response;
          response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);
          if (response && response.data && response.success) {
            let contractId = response?.data?.contractId;
            if (contractId && response?.data?.cnoteStatus === 'PRINTED') {
              this.downloadPDF(contractId, 'covernote');
            }

            this.store.dispatch(new SET_JPJ_STATUS_SENT(true));

            this.router.navigate(['issuance/complete'], {
              queryParamsHandling: 'preserve',
            });
          } else {
            this.showResponseError(response, payload);
          }
          
        });
      }
    });
  }

  renewalAssistanceWarningMessage(popup_type: any, error: any) {
    this.warningPopupModal = this.dialogService.open(WarningPopupComponent, {
      showCloseIcon: false, width: "550px", maxWidth: "550px",
      data: {
        popup_type: popup_type,
        popup_error: error
      },
    });
  
    this.warningPopupModal.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'Y') {
          this.renewalAssistanceForm.controls.creditCardType.setValue("");
          this.renewalAssistanceForm.controls.creditCardNo.setValue("");
          this.renewalAssistanceForm.controls.creditCardExpMonth.setValue("");
          this.renewalAssistanceForm.controls.creditCardExpYear.setValue("");
          this.renewalAssistanceForm.controls.creditCardHolderName.setValue("");
          this.renewalAssistanceForm.controls.autoRenewalStartDt.setValue("");
          this.renewalAssistanceForm.controls.termsOfUse.setValue(null);
          this.renewalAssistanceCheckbox = false;
        }else{
          this.renewalAssistanceForm.controls.autoRenewalInd.setValue(true);
          this.renewalAssistanceForm.controls.termsOfUse.setValue(true);
          this.renewalAssistanceCheckbox = true;
        }
        this.saveSummaryStep();
      }
    });
  }

  downloadPDF(contractId, type) {
    let payload = {
      operator: this.userInfo.userId,
      contractId: contractId,
      type: type
    };

    this.store.dispatch(new DOWNLOAD_PDF(payload)).subscribe(() => {
      this.router.navigate(['issuance/complete'], {
        queryParamsHandling: 'preserve',
      });
    });

  }

  onChangeRenewalAssistanceForm(fieldName: any, value: any) {
    console.log('onChangeValue..' + value.checked);
    
    if(value){
      this.renewalAssistanceCheckbox = true;
      this.renewalAssistanceForm.controls.autoRenewalInd.setValue(true);
      this.renewalAssistanceForm.controls.termsOfUse.setValue(true);
      this.saveSummaryStep();
    }else{
      this.renewalAssistanceWarningMessage("warningPopup","This will remove Auto Renewal details. Click OK to continue.");
    }


    if (fieldName == 'autoRenewalInd') {
      //this.renewalAssistanceForm.controls.creditCardType.reset();
      this.renewalAssistanceForm.controls.creditCardType.clearValidators();
      this.renewalAssistanceForm.controls.creditCardNo.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpMonth.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpYear.clearValidators();
      this.renewalAssistanceForm.controls.creditCardHolderName.clearValidators();
      this.renewalAssistanceForm.controls.autoRenewalStartDt.clearValidators();
      this.renewalAssistanceForm.controls.termsOfUse.clearValidators();
      if (value) {
        this.renewalAssistanceForm.controls.creditCardType.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.setValidators([Validators.required, Validators.pattern(/^\d{16}$/)]);
        this.renewalAssistanceForm.controls.creditCardExpMonth.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpYear.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardHolderName.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.autoRenewalStartDt.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termsOfUse.setValidators([Validators.required]);
      } else {
        this.renewalAssistanceForm.controls.creditCardType.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpMonth.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpYear.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardHolderName.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.autoRenewalStartDt.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termsOfUse.removeValidators([Validators.required]);
      }
      this.updateRenewalAssistanceValueAndValidity();
    }
  }

  onDateChange(event: any) {
    this.saveSummaryStep();
  }

  populateRenewalAssistanceForm(){
    if(this.renewalAssistanceDetails){

      this.renewalAssistanceForm.patchValue({ 
        ...this.renewalAssistanceDetails,
      });

      this.renewalAssistanceForm.updateValueAndValidity();
  
      if (this.renewalAssistanceDetails?.autoRenewalInd) {
        this.renewalAssistanceCheckbox = true;
      }
    }
    
  }

  updateRenewalAssistanceValueAndValidity(){
    this.renewalAssistanceForm.controls.creditCardType.updateValueAndValidity();
    this.renewalAssistanceForm.controls.creditCardNo.updateValueAndValidity();
    this.renewalAssistanceForm.controls.creditCardExpMonth.updateValueAndValidity();
    this.renewalAssistanceForm.controls.creditCardExpYear.updateValueAndValidity();
    this.renewalAssistanceForm.controls.creditCardHolderName.updateValueAndValidity();
    this.renewalAssistanceForm.controls.autoRenewalStartDt.updateValueAndValidity();
    this.renewalAssistanceForm.controls.termsOfUse.updateValueAndValidity();
  }

  uploadDocument() {

    const productCode = this.store.selectSnapshot<any>((state)=>state.SatMotorState.motor.step1?.issuance?.issuance?.productCode);

    let contractId = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step1?.issuance?.issuance?.contractId);
    if (!contractId)
      contractId = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step3?.contractId);
    if (!contractId)
      contractId = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step4?.generateQuotation?.contractId);
    if (!contractId)
      contractId = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor?.step5?.cngeNote?.contractId);

    let dialogConfig = {
      showCloseIcon: false,
      width: "80%",
      maxWidth: "80vw",
      maxHeight: "95vh",
      data: {
        productCode: productCode,
        contractId: contractId
      }
    };

    this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {  }
    });
    
  }

  goBack() {
    if (this.isRTP) {
      this.router.navigate(['issuance/declaration-issuance-info']);
    }

    if (this.isIssuance) {
      this.router.navigate(['issuance/coverage']);
    }

    if (this.isEndorsement) {
      this.router.navigate(['endorsement/endorsement-details']);
    }
  }

  updatePaymentList(paymentList) {
    this.paymentList = paymentList;
    this.onChangePaymentSection();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  getPaymentData(data) {
    this.payment = {
      ...data?.payment,
      accountCode: this.agentData.agentCode,
      insuredName: this.cpData.clientName,
      totalPremiumDue: this.premiumInfo.premiumDue
    };

    this.paymentList = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.paymentList) ? this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.paymentList) : [];

    this.roadTaxPayable = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.roadTaxPayable) ? this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.roadTaxPayable) : {};

    this.paymentInformation = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.paymentInfo) ? this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.paymentInfo) : {};
  }

  onChangePaymentSection() {
    let summaryPremium = this.store.selectSnapshot<any>(state => state.SatMotorState.summaryPremium);
    let coveragePremium = this.store.selectSnapshot<any>(state => state.SatMotorState.motor.step3.coverage.coveragePremium.cngeNote);
    let cngeNote = summaryPremium ? summaryPremium : coveragePremium;
    if(this.summaryForm.get('roadTaxPayableForm')) {
      let roadTaxPayableForm = this.summaryForm.get('roadTaxPayableForm');
      cngeNote = {
        ...cngeNote,
        ...roadTaxPayableForm.getRawValue(),
        roadTax: this.numberFmt.transform(roadTaxPayableForm.get('roadTax').value),
        myegFees: this.numberFmt.transform(roadTaxPayableForm.get('myegFees').value),
        merchantCharges:this.numberFmt.transform(roadTaxPayableForm.get('merchantCharges').value),
        courierFees: roadTaxPayableForm.get('courierFees').value ? 6.00 : 0.00,
      };
    }

    cngeNote.paymentList = this.paymentList ? this.paymentList : [];

    let payload = {
      cngeNote: cngeNote,
      operator: this.userInfo.userId
    };

    this.store.dispatch(new CALCULATE_PREMIUM_SUMMARY(payload)).subscribe(state => {
      let premiumSummaryResponse = state.SatMotorState.summaryPremium;
      let roadTaxPayableResponse = {
        roadTax: premiumSummaryResponse.roadTax,
        myegFees: premiumSummaryResponse.myegFees,
        courierFees: premiumSummaryResponse.courierFees,
        merchantCharges: premiumSummaryResponse.merchantCharges,
        gstRoadTaxAmt: premiumSummaryResponse.gstRoadTaxAmt
      };
      this.roadTaxPayable = roadTaxPayableResponse;
      this.store.dispatch(new STORE_ROAD_TAX_PAYABLE({isEndorsement: this.isEndorsement, roadTaxPayable: roadTaxPayableResponse}));

      this.paymentInformation = {
        totalPayable: premiumSummaryResponse.totalPayable,
        totalPayableRounded: premiumSummaryResponse.totalPayableRounded,
        totalPaid: premiumSummaryResponse.totalPaid,
        balancePayable: premiumSummaryResponse.balancePayable
      };
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      this.store.dispatch(new STORE_PAYMENT_INFO({isEndorsement: this.isEndorsement, paymentInfo: this.paymentInformation}));
    });
    
  }

  savePayment() {
    let cnoteType = "";
    
    if (this.isEndorsement) {
      cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.endorsement?.step3?.generateQuotation?.cnoteStatus);
    } else {
      cnoteType = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.generateQuotation?.cnoteStatus);
    }

    let isFormValid = this.validateFormFields();
    const isCnoteStatusPrinted = cnoteType === "PRINTED";

    if (!isFormValid) {
      console.log("Form Validation Failed.");
      return;
    }

    let payload = this.getPayloadData();

    let resetPayload = {};

    // if (!isCnoteStatusPrinted) {
    //   this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    // }

    this.store.dispatch(new SAVE_PAYMENT(payload)).subscribe(() => { //need to check for endorsement
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.updatePayment ?? null);
      console.log(response);

      if (response && response.data && response.data.contractId && response.success) {
        let contractId = response?.data?.contractId;

        if (response?.data?.cnoteStatus === 'PRINTED') {
          this.downloadPDF(contractId, 'quotation');
        }

        this.router.navigate(['issuance/complete'], {
          queryParamsHandling: 'preserve',
        });

      } else {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: response.message
          }
        });
      }
    });
  }

  disableRenewalAssistanceForm(){
    this.renewalAssistanceForm.controls.creditCardType.disable();
    this.renewalAssistanceForm.controls.creditCardNo.disable();
    this.renewalAssistanceForm.controls.creditCardExpMonth.disable();
    this.renewalAssistanceForm.controls.creditCardExpYear.disable();
    this.renewalAssistanceForm.controls.creditCardHolderName.disable();
    this.renewalAssistanceForm.controls.autoRenewalStartDt.disable();
    this.renewalAssistanceForm.controls.termsOfUse.disable();
  }

  // openAgeNotMetPopup() {
  //   this.infoMessageModal = this.dialogService.open(SimpleModalComponent, {
  //     showCloseIcon: false,
  //     width: '400px',
  //     data: { 
  //       type: "Warning", 
  //       message: `The age of the Policyholder must be within the age range as below:
  //       (16 - 99 years old).`,
  //     },
  //   });
  // }
}


