<div nxRow class="nx-margin-top-m">
  <div nxCol="12">
    <nx-card>
      <div nxLayout="grid nopadding" class="summary">
        <div nxRow class="nx-margin-bottom-3xs">
          <div nxCol="6,7,5" class="title">{{ 'PREMIUM_SUMMARY.TITLE.PREMIUM_SUMMARY' | translate }}</div>
          <div nxCol="1,1,2" class="std-ta-center"></div>
          <div nxCol="5,4,5" class="std-ta-right title">{{ 'PREMIUM_SUMMARY.TITLE.SUBTOTAL' | translate }}</div>
        </div>
        <div nxRow class="nx-margin-bottom-3xs">
          <div nxCol="6,7,5">{{ 'PREMIUM_SUMMARY.FIELD.BASIC_PREMIUM' | translate }}</div>
          <div nxCol="1,1,2" class="std-ta-center">:</div>
          <div nxCol="5,4,5" class="std-ta-right">{{ typeOfCoverage?.basicPremium | number: '1.2-2' }}</div>
        </div>
        @if (isThirdParty) {
            <div nxRow class="nx-margin-bottom-3xs">
                <div nxCol="6,7,5">Loading ({{ typeOfCoverage?.loadPercentage | number: '1.2-2' }}%)</div>
                <div nxCol="1,1,2" class="std-ta-center">:</div>
                <div nxCol="5,4,5" class="std-ta-right">{{ typeOfCoverage?.loadAmount | number: '1.2-2' }}</div>
            </div>
        }
        <div nxRow class="nx-margin-bottom-3xs">
          <div nxCol="6,7,5">{{'PREMIUM_SUMMARY.FIELD.NCD' | translate: { percent: typeOfCoverage?.ncdPercentage | number: '1.2-2' } }}</div>
          <div nxCol="1,1,2" class="std-ta-center">:</div>
          <div nxCol="5,4,5" class="std-ta-right">{{ typeOfCoverage?.ncdAmount | number: '1.2-2' }}</div>
        </div>
        <div nxRow class="nx-margin-bottom-3xs">
          <div nxCol="6,7,5">{{ 'PREMIUM_SUMMARY.FIELD.TOTAL_EXTRA_COVER_PREMIUM' | translate }}</div>
          <div nxCol="1,1,2" class="std-ta-center">:</div>
          <div nxCol="5,4,5" class="std-ta-right">{{ typeOfCoverage?.subCoverPremium | number: '1.2-2' }}</div>
        </div>
        <div nxRow class="nx-margin-bottom-3xs">
          <div nxCol="6,7,4">{{ 'PREMIUM_SUMMARY.FIELD.GROSS_PREMIUM' | translate }}</div>
          <div nxCol="1,1,4" class="std-ta-center">:</div>
          <div nxCol="5,4,4" class="std-ta-right">{{ typeOfCoverage?.grossPremium | number: '1.2-2' }}</div>
        </div>
      </div>
    </nx-card>
  </div>
</div>
