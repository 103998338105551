const PREFIX = 'ERROR';

export class SET_ERROR_MESSAGE {
    static readonly type = `[${PREFIX}] SET ERROR MESSAGE`;
    constructor(public readonly payload ) {}    
}

export class PROMPT_ERROR {
    static readonly type = `[${PREFIX}] PROMPT MESSAGE`;
    constructor(public readonly payload ) {}
}

export class SHOW_MESSAGE {
    static readonly type = `[${PREFIX}] SHOW MESSAGE`;
}

export class RESET_ERROR_MESSAGE {
    static readonly type = `[${PREFIX}] RESET ERROR MESSAGE`;
}

export class COMMON_SAVE_AS_DRAFT_DEBUG {
    static readonly type = `[${PREFIX}] COMMON SAVE AS DRAFT DEBUG`;
    constructor(public readonly payload ) {}  
}





