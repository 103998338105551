import { Component, Input } from '@angular/core';
import { REGION } from '@shared/enums/index.enum';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-view-vehicle-details',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './view-vehicle-details.component.html',
  styleUrl: './view-vehicle-details.component.scss'
})
export class ViewVehicleDetailsComponent {
  @Input() lov;
  @Input() data;
  @Input() isApproval: boolean;
  @Input() vehicleUselov: any;

  formatData(value: string): string {
    if (!value || value === '' || value === 'NA') {
        return 'N/A';
    }
    return value;
  }

  formatDate(dateVal: any): any {
    if (!dateVal) {
      return null;
    }

    const date = new Date(dateVal);

    if (isNaN(date.getTime())) {
      return null;
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  get region() {
    return REGION[this.data.region];
  }

  get vehicleUse() {
    let vehicleUse = this.vehicleUselov.find(a => a.code === this.data.riskList[0].vehicleUse);
    if(vehicleUse) {
      return vehicleUse.description;
    }
  }

  get loanType() {
    let loanType = this.lov.vehicleLoanTypeList.find(a => a.code === this.data.riskList[0].vehicleLoanType);
    if(loanType) {
      return loanType.descp;
    }
  }

  get engineType() {
    let engineType = this.lov.engineTypeList.find(a => a.code === this.data.riskList[0].engineType);
    if(engineType) {
      return engineType.descp;
    }
  }

  get capacity() {
    let capacity = this.data.riskList[0].vehicleCapacity;
    let uom = this.data.riskList[0].vehicleUom;
    if (capacity !== null && Number.isInteger(capacity)) {
      return capacity.toLocaleString() + ' / ' + uom;
    }
    return this.formatData(capacity) + ' / ' + this.formatData(uom);
  }

  get makeModelPrintDesc() {
      return this.data.riskList[0].vehModelNvicDesc + ' ' + this.data.riskList[0].makeModelDesc;
  }
}
