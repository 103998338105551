import { Component, Input } from '@angular/core';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';
import { EndtNarrationComponent } from '../modal/endt-narration/endt-narration.component';

@Component({
  selector: 'app-endt-history-table',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './endt-history-table.component.html',
  styleUrl: './endt-history-table.component.scss'
})
export class EndtHistoryTableComponent {
  @Input() endtHistoryList: any;
  @Input() lov: any;
  statusMap = {
    PRINTED: "Printed",
    APPROVED: "Approved",
    PENDING: "Pending \n Approval",
    CANCELLED: "Cancelled",
    SUBMITTED: "Submitted",
    ACTIVE: "Active",
    EXPIRED: "Expired"
  };
  endtNarrationModal?: NxModalRef<EndtNarrationComponent>;

  constructor(
    private readonly dialogService: NxDialogService,
  ) {}

  getStatus(status) {
    return this.statusMap[status] || "";
  }

  getEndtType(endtType) {
    return this.lov.cnoteTypeList.find(e => e.code === endtType)?.descp;
  }

  openEndtNarrationPopUp(item) {
    //'<p><b><u>CANCELLATION OF POLICY - BY INSURED</u></b></p><p><br/>IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE <b>28/12/2023</b> THE POLICY IS CANCELLED.<br/></p><p><br/>IN CONSIDERATION OF THE ABOVE, A REFUND PREMIUM OF <b>RM6,086.10</b> IS HEREBY ALLOWED TO THE INSURED.<br/> </p><p><br/>NCD <b>0.00 %</b> on policy</p><br/>'
    this.endtNarrationModal = this.dialogService.open(EndtNarrationComponent, {
      showCloseIcon: false,
      data: {
        endtNarration: item.endtNarration
      }
    });
  }
}
