import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-save-draft-modal',
  standalone: true,
  imports: [ SharedModule ],
  templateUrl: './save-draft-modal.component.html',
  styleUrl: './save-draft-modal.component.scss',
})
export class SaveDraftModalComponent {
  
  draftNo: any;
  
  constructor(
    @Inject(NX_MODAL_DATA) data: any,
    private dialogRef: NxModalRef<any>,
  ) {
    this.draftNo = data.draftNo;
  }
  
  onclickOK() {
    this.dialogRef.close();
  }
  
}
