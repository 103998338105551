import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EnquiryComponent } from '../enquiry.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { GET_ENQUIRY_DETAILS } from '@store/enquiry/enquiry.action';
import { EnquiryPopupComponent } from "../../../popup/enquiry-popup/enquiry-popup.component";
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { GET_DOCUMENT_TYPE_LOV, GET_PRODUCT_CONFIG_LOV, GET_REASON_CODE_LOV, GET_UPSELL_PRODUCT_LOV, GET_VEHICLE_CLASS_USE_LOV, GET_VEHICLE_USE, SEARCH_MAKE_MODEL, UPDATE_ISM_POLICY } from "@store/sat-motor/sat-motor.action";
import {
  DOWNLOAD_PDF
}
  from '@store/sat-motor/sat-motor.action';

import moment from 'moment';
import { disableDebugTools } from '@angular/platform-browser';
import { UserResponse } from '@interfaces/user';
import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { ScrollToError } from '@shared/classes/scroll-to-error';
import { MakeModelComponent } from '@shared/components/modal/make-model/make-model.component';
import { SearchPolicyEnquiryPopupComponent } from '@shared/components/modal/search-policy-enquiry-popop/search-policy-enquiry-popop.component';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-endorsement-data-upload',
  standalone: true,
  imports: [
    EnquiryComponent,
    SharedModule,
    CrossSellBannerComponent,
    NxMomentDateModule
  ],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }],
  templateUrl: './endorsement-data-upload.html',
  styleUrl: './endorsement-data-upload.scss'
})
export class EndorsementDataUploadComponent implements OnInit {
  componentDialogRef1?: NxModalRef<EnquiryPopupComponent>;
  dialogRef?: NxModalRef<any>;
  jpjIsmForm: FormGroup;
  enquiryDetail: any;
  searchModelForm: FormGroup;
  ismPolicyMessage: any;
  success: any;
  @Select(SatMotorState.commonLov) commonLov$;

  @ViewChild("templateOne") templateRef!: TemplateRef<any>;
  @ViewChild("templateEnHistory") templateRef5!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  lov: any;
  productList: any;
  filteredReasonCodeList: any;
  vehicleUselov: any;
  documentTypeList: any;
  vehicleClassList: any;
  coverageTypeList: any;

  showRef(template: TemplateRef<any>, type: any) {
    this.templateRef! = template;
    this.title = type;
  }

  title = "Private Cars Excluding Goods";

  listDisableForm = [
    'policyNo', 'vehicleNo', 'effectiveDate', 'expiryDate', 'idNo1', 'idNo2',
    'vehicleEngine', 'vehicleChassis', 'ncdLvl', 'ncdEffectiveDate', 'ncdChgDate', 'subcls', 'vehCls',
    'grossPremium', 'insCode', 'insuredName'

  ];
  updateEndorsementDataUploadForm: FormGroup<any>;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {

    this.updateEndorsementDataUploadForm = this.fb.group({
      insCode: [{ value: "216", disabled: true }],
      docType: ["", Validators.required],
      reasonCode: [{ value: "", disabled: true }],
      requestType: "",
      policyNo: [{ value: "", disabled: true }],
      vehicleNo: [{ value: "", disabled: true }],
      effectiveDate: [{ value: "", disabled: true }],
      expiryDate: [{ value: "", disabled: true }],
      insuredName: [{ value: "", disabled: true }],
      //idNo1: ["", Validators.required],
      //idNo2: ["", Validators.required],
      idNo1: [{ value: "", disabled: true }],
      idNo2: [{ value: "", disabled: true }],
      vehicleEngine: [{ value: "", disabled: true }],
      vehicleChassis: [{ value: "", disabled: true }],
      ncdLvl: [{ value: "", disabled: true }],
      ncdEffectiveDate: [{ value: "", disabled: true }],
      ncdChgDate: [{ value: "", disabled: true }],
      subcls: [{ value: "", disabled: true }],
      vehCls: [{ value: "", disabled: true }],
      vehicleUse: [{ value: "", disabled: true }],
      grossPremium: [{ value: "", disabled: true }],
    });


  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.commonLov$.subscribe((state) => {
      if (state) {
        this.lov = state;
        this.productList = state.productList;
      }
    });

    this.store.dispatch(new GET_DOCUMENT_TYPE_LOV()).subscribe((state) => {
      this.documentTypeList = state.SatMotorState.documentTypeLov;
    });

    //Vehicle Class LOV
    this.store.dispatch(new GET_UPSELL_PRODUCT_LOV({
      listTypes: ["vehicleClassList"],
      agentCode: "KL18431",
      productType: "MT",
      productCode: "080100",
    })).subscribe(state => {
      this.vehicleClassList = state.SatMotorState.vehicleClassLov.vehicleClassList;
    });

    let productConfigParam = {
      lob: "MT",
      productCode: "080100",
      agentCode: "KL18431",
    }

    this.store.dispatch(new GET_PRODUCT_CONFIG_LOV(productConfigParam)).subscribe((state) => {
      this.coverageTypeList = state.SatMotorState.productConfigLov['080100'].coverList;
    });
  }

  onUpdateClicked() {
    if (this.updateEndorsementDataUploadForm.valid) {
      let value = {
        "insCode": this.updateEndorsementDataUploadForm.getRawValue()["insCode"],
        "docType": this.updateEndorsementDataUploadForm.getRawValue()["docType"],
        "reasonCode": this.updateEndorsementDataUploadForm.getRawValue()["reasonCode"],
        "policyNo": this.updateEndorsementDataUploadForm.getRawValue()["policyNo"],
        "vehicleNo": this.updateEndorsementDataUploadForm.getRawValue()["vehicleNo"],
        "effectiveDate": this.updateEndorsementDataUploadForm.getRawValue()["effectiveDate"],
        "expiryDate": this.updateEndorsementDataUploadForm.getRawValue()["expiryDate"],
        "insuredName": this.updateEndorsementDataUploadForm.getRawValue()["insuredName"],
        "idNo1": this.updateEndorsementDataUploadForm.getRawValue()["idNo1"],
        "idNo2": this.updateEndorsementDataUploadForm.getRawValue()["idNo2"],
        "vehicleEngine": this.updateEndorsementDataUploadForm.getRawValue()["vehicleEngine"],
        "vehicleChassis": this.updateEndorsementDataUploadForm.getRawValue()["vehicleChassis"],
        "ncdLvl": this.updateEndorsementDataUploadForm.getRawValue()["ncdLvl"],
        "ncdEffectiveDate": this.updateEndorsementDataUploadForm.getRawValue()["ncdEffectiveDate"],
        "ncdChgDate": this.updateEndorsementDataUploadForm.getRawValue()["ncdChgDate"],
        "subcls": this.updateEndorsementDataUploadForm.getRawValue()["subcls"],
        "vehCls": this.updateEndorsementDataUploadForm.getRawValue()["vehCls"],
        "vehicleUse": this.updateEndorsementDataUploadForm.getRawValue()["vehicleUse"],
        "grossPremium": this.updateEndorsementDataUploadForm.getRawValue()["grossPremium"],
        "createBy": this.store.selectSnapshot<UserResponse>(state => state?.UserState?.user?.userId),
        "createDt": this.getCurrentDate(),
        "updateBy": this.store.selectSnapshot<UserResponse>(state => state?.UserState?.user?.userId),
        "updateDt": this.getCurrentDate()
      };

      this.store.dispatch(new UPDATE_ISM_POLICY(value)).subscribe((res) => {
        this.ismPolicyMessage = res.SatMotorState.updateIsmPolicyMessage?.data?.ncdMessageList[0];
        this.success = res.SatMotorState.updateIsmPolicyMessage.success;

        window.scroll({ top: 0, left: 0, behavior: 'instant' });
      });
    } else {
      this.updateEndorsementDataUploadForm.markAllAsTouched();
      ScrollToError.scrollToError('.ng-invalid[formControlName]');
    }
  }

  onChangeFormValue(formName: any, value: any) {
    if (value != '') {
      if (formName == 'docType') {
        this.resetValue();
        this.enableFormAndSetValidator('reasonCode');
        this.onDocTypeChange(value);
      }

      if (formName == 'reasonCode') {
        Object.keys(this.updateEndorsementDataUploadForm.controls).forEach(key => {
          if (!this.listDisableForm.includes(key) || key === 'insCode') return;
          this.enableFormAndSetValidator(key);
        });

        this.updateEndorsementDataUploadForm.controls['insCode'].setValue('216');

      }

      if (formName == 'vehCls') {
        this.enableFormAndSetValidator('vehicleUse');
        this.store.dispatch(new GET_VEHICLE_CLASS_USE_LOV(value)).subscribe((state) => {
          this.vehicleUselov = state.SatMotorState.vehicleClassUseLov;
        });

      }

      if (formName == 'ncdLvl') {
        if (this.updateEndorsementDataUploadForm.controls['ncdLvl'].value > 100) {
          this.updateEndorsementDataUploadForm.controls['ncdLvl'].setErrors({ 'invalidValue': true });
        }
      }
    }
  }

  resetForm() {
    this.updateEndorsementDataUploadForm.reset();
    this.updateEndorsementDataUploadForm.controls['insCode'].setValue('216');
    this.ismPolicyMessage = "";
    this.success = "";
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
  }

  resetValue() {
    this.updateEndorsementDataUploadForm.controls['reasonCode'].setValue('');
  }

  enableFormAndSetValidator(formName: any) {
    this.updateEndorsementDataUploadForm.get(formName).setValue('');
    this.updateEndorsementDataUploadForm.get(formName).setValidators([Validators.required]);
    this.updateEndorsementDataUploadForm.get(formName).updateValueAndValidity();
    this.updateEndorsementDataUploadForm.get(formName).enable();
  }

  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if month is single digit
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if day is single digit
    return `${year}-${month}-${day}`;
  }

  onDocTypeChange(selectedDocType: string): void {

    this.store.dispatch(new GET_REASON_CODE_LOV(selectedDocType)).subscribe((state) => {
      this.filteredReasonCodeList = state.SatMotorState.reasonCodeLov;
    });

  }
  openPopupPolicyEnquiry() {

    this.dialogRef = this.dialogService.open(SearchPolicyEnquiryPopupComponent, {
      showCloseIcon: false,
      width: "920px",
      maxWidth: "920px",
      data: {
        vehicleClassList: this.vehicleClassList,
        coverageTypeList: this.coverageTypeList
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      let data = result?.item;
      if (data) {
        if (data.riskList[0].vehicleClass) {
          this.store.dispatch(new GET_VEHICLE_CLASS_USE_LOV(data.riskList[0].vehicleClass)).subscribe((state) => {
            this.vehicleUselov = state.SatMotorState.vehicleClassUseLov;

            this.updateEndorsementDataUploadForm.patchValue({
              policyNo: data.policyNo,
              vehicleNo: data.riskList[0].vehicleNo,
              insuredName: data.clientPartner.clientName,
              effectiveDate: data.effectiveDate,
              expiryDate: data.expiryDate,
              idNo1: data.clientPartner.idValue1,
              vehicleEngine: data.riskList[0].vehicleEngine,
              vehicleChassis: data.riskList[0].vehicleChassis,
              ncdEffectiveDate: data.riskList[0].ncdEffDate,
              ncdChgDate: data.riskList[0].nextNcdEffDate,
              subcls: data.riskList[0].coverList[0].coverCode,
              vehCls: data.riskList[0].vehicleClass,
              vehicleUse: data.riskList[0].vehicleUse,
              grossPremium: data.riskList[0].grossPremium
            });
            this.updateEndorsementDataUploadForm.get('vehicleUse').enable();
          });
        }

      }
    });
  }
}
