import { Injectable } from '@angular/core';
import { Branch } from './branch';
import { signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private branches = signal<Branch[]>([
    { id: 1, name: 'Google', lat: 46.948, lng: 7.4474, postCode: '3000', canton: 'BE', location: 'Bern' },
    // Add more branches here
  ]);

  getBranches() {
    return this.branches();
  }
}
