import { Component, Inject } from "@angular/core";
import { NX_MODAL_DATA, NxModalRef } from "@aposin/ng-aquila/modal";
import { SharedModule } from "@shared/shared.module";

@Component({
  selector: "app-simple-modal",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./simple-modal.component.html",
  styleUrl: "./simple-modal.component.scss",
})
export class SimpleModalComponent {
  
  type: any;
  message: any;
  btnOkLabel: any;
  
  constructor(
    @Inject(NX_MODAL_DATA) data: any,
    private dialogRef: NxModalRef<any>,
  ) {
    this.type = data.type;
    this.message = data.message;
    this.btnOkLabel = data.btnOkLabel ?? "OK";
  }
  
  onclickOK() {
    this.dialogRef.close();
  }
  
}
