import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { SatMotorApprovalService } from "./sat-motor-approval.service";
import {
  APPROVE_COVER_NOTE,
  CALCULATE_PREMIUM,
  // CALCULATE_ENDT_PREMIUM,
  DOWNLOAD_PDF,
  GET_APPROVAL_DETAILS,
  GET_VEHICLE_USE,
  REJECT_COVER_NOTE,
  SAVE_ADMIN_REMARKS,
  STORE_APPROVAL_DETAILS_CNGE,
  RESET_APPROVAL_STATE,
  GET_CROSS_SELL_LIST,
  GET_APPROVAL_PRODUCT_PDF,
  SAVE_APPROVAL_MESSAGE_SENT_LOG,
  SAVE_APPROVAL_EMAIL_SENT_LOG
} from "./sat-motor-approval.action";
import { map } from "rxjs";

interface SatMotorApprovalModel {
  approvalDetails: any;
  premiumRes: any;
  histories: any;
  lov: any;
  crossSellList: any;
  marketingConsent: any;
  folderContents: any;
}

@Injectable()
@State<SatMotorApprovalModel>({
  name: 'SatMotorApprovalState',
})
export class SatMotorApprovalState {
  constructor(
    private satMotorApprovalService: SatMotorApprovalService,
    private store: Store,
  ) { }

  @Action(RESET_APPROVAL_STATE)
  resetState(state: StateContext<SatMotorApprovalModel>) {
    state.patchState({
      approvalDetails: null,
      premiumRes: null,
      histories: null,
      lov: null,
      crossSellList: null,
      marketingConsent: null,
      folderContents: null
    });
  }

  @Action(GET_APPROVAL_DETAILS)
  public getApprovalDetails(
    { patchState, getState }: StateContext<SatMotorApprovalModel>,
    { payload }: GET_APPROVAL_DETAILS
  ) {
    const state = getState();
    return this.satMotorApprovalService.getApprovalDetails(payload).pipe(
      map((res) => {
        console.log(res);
        patchState({
          approvalDetails: res.APPROVAL_MAP,
          histories: res.histories
        });
      })
    );
  }


  @Action(SAVE_ADMIN_REMARKS)
  public saveAdminRemarks(
    { patchState, getState }: StateContext<SatMotorApprovalModel>,
    { payload }: SAVE_ADMIN_REMARKS
  ) {
    const state = getState();
    return this.satMotorApprovalService.saveAdminRemarks(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            approvalDetails: {
              ...state.approvalDetails,
              cngeNote: res.data.cngeNote
            }
          });
        }

      })
    );
  }

  @Action(APPROVE_COVER_NOTE)
  public approveCoverNote(
    { patchState, getState }: StateContext<SatMotorApprovalModel>,
    { payload }: APPROVE_COVER_NOTE
  ) {
    const state = getState();
    return this.satMotorApprovalService.approveCoverNote(payload).pipe(map(res => {
      if (res) {
        patchState({
          approvalDetails: {
            ...state.approvalDetails,
            cngeNote: res.cngeNoteDto
          }
        });
      }
    }));
  }

  @Action(REJECT_COVER_NOTE)
  public declineCoverNote(
    { patchState, getState }: StateContext<SatMotorApprovalModel>,
    { payload }: REJECT_COVER_NOTE
  ) {
    const state = getState();
    return this.satMotorApprovalService.rejectCoverNote(payload).pipe(map(res => {
      if (res) {
        patchState({
          approvalDetails: {
            ...state.approvalDetails,
            referAdminRemarks: res.message ? res.message : (res.error ? res.error : null),
          }
        });
      }
    }));
  }

  @Action(CALCULATE_PREMIUM)
  public calculatePremium(
    { patchState, getState, dispatch }: StateContext<SatMotorApprovalModel>,
    { payload }: CALCULATE_PREMIUM) {
    const state = getState();
    return this.satMotorApprovalService.calculatePremium(payload).pipe(
      map((res) => {
        if (res) {
          patchState({
            premiumRes: res
          });
        }
      }),
    );
  }

  // @Action(CALCULATE_ENDT_PREMIUM)
  // public calculateEndtPremium(
  //   { patchState, getState, dispatch }: StateContext<SatMotorApprovalModel>,
  //   { payload }: CALCULATE_ENDT_PREMIUM) {
  //   const state = getState();
  //   return this.satMotorApprovalService.calculateEndtPremium(payload).pipe(
  //     map((res) => {
  //       if (res) {
  //         patchState({
  //           premiumRes: res
  //         });
  //       }
  //     }),
  //   );
  // }

  @Action(STORE_APPROVAL_DETAILS_CNGE)
  public storeApprovalDetailsCnge(
    { patchState, getState, dispatch }: StateContext<SatMotorApprovalModel>,
    { payload }: STORE_APPROVAL_DETAILS_CNGE) {
    const state = getState();

    patchState({
      approvalDetails: {
        ...state.approvalDetails,
        cngeNote: payload
      }
    });
  }

  @Action(DOWNLOAD_PDF)
  public downloadPDF(
    { patchState, getState, dispatch }: StateContext<SatMotorApprovalModel>,
    { payload }: DOWNLOAD_PDF) {
    return this.satMotorApprovalService.downloadPDF(payload);
    // return this.satMotorApprovalService.downloadPDF(payload).pipe(
    //   map((res) => {
    //     const blob = this.satMotorApprovalService.generateBlob(res);
    //     console.log("Blob:", blob);

    //     if (blob) {
    //       this.satMotorApprovalService.downloadPDFUsingBlob(blob);
    //     }
    //   }),
    // );
  }

  @Action(GET_VEHICLE_USE)
  public getVehicleUseLov({ patchState, getState }: StateContext<SatMotorApprovalModel>, { payload }: GET_VEHICLE_USE) {
    const state = getState();
    return this.satMotorApprovalService.getVehicleUseLov(payload).pipe(
      map((res) => {
        if (res && res.data) {
          patchState({
            lov: {
              ...state.lov,
              vehicleUse: res.data
            },
          });
        }
      }),
    );
  }

  @Action(GET_CROSS_SELL_LIST)
  public getCrossSellList({ patchState, getState, dispatch }: StateContext<SatMotorApprovalModel>, { payload, productCode }: GET_CROSS_SELL_LIST) {
    const state = getState();

    return this.satMotorApprovalService.getCrossSellList(payload).pipe(
      map((res) => {
        if (res) {
          let crossSellList = res.crossSellList.map((crossSell) => {
            // if (crossSell.productCode != productCode) {
              let responseBannerImg = {
                file: {
                  data: crossSell.bannerImg,
                },
              };
              let bannerImgBlob = this.satMotorApprovalService.generateBlob(responseBannerImg);
              console.log("Banner Img Blob:", bannerImgBlob);
              let bannerImgURL = URL.createObjectURL(bannerImgBlob);

              return {
                ...crossSell,
                bannerImg: bannerImgURL,
              };
            // }
          });

          if(crossSellList && crossSellList.length > 0) {
            crossSellList = crossSellList.filter(a => a.productCode != productCode);
          }

          patchState({
            crossSellList: crossSellList,
            marketingConsent: res.marketingConsent,
          });
        }
      }),
    );
  }

  @Action(GET_APPROVAL_PRODUCT_PDF)
  public getIssMarketingMaterialDocList(
    { patchState, getState, dispatch }: StateContext<SatMotorApprovalModel>,
    { payload }: GET_APPROVAL_PRODUCT_PDF) {
    const state = getState();

    return this.satMotorApprovalService.getProductPDF(payload).pipe(
      map((res) => {
        if (res) {
          const pdfArray = [];

          for (const folder of res.folders) {
            for (const folder2 of folder.folders) {
              for (const folder3 of folder2.folders) {
                for (const folder4 of folder3.folders) {
                  for (const folder5 of folder4.folders) {
                    if (!folder5.contents) continue
                    for (const content of folder5.contents) {
                      if (content.contentFields[0].contentFieldValue.document.fileExtension.toLowerCase() == 'pdf') {
                        pdfArray.push(content);
                      }
                    }
                  }
                }
              }
            }
          }

          console.log('pdfArr: ', pdfArray);

          patchState({
            folderContents: pdfArray
          });
        }
      }),
    );
  }

  @Action(SAVE_APPROVAL_MESSAGE_SENT_LOG)
  saveMessageSentLog(
    { patchState }: StateContext<SatMotorApprovalModel>,
    { payload }: SAVE_APPROVAL_MESSAGE_SENT_LOG
  ) {
    return this.satMotorApprovalService.saveMessageLog(payload).subscribe();
  }

  @Action(SAVE_APPROVAL_EMAIL_SENT_LOG)
  public saveEmailSentLog(
    { patchState }: StateContext<SatMotorApprovalModel>,
    { payload }: SAVE_APPROVAL_EMAIL_SENT_LOG
  ) {
    return this.satMotorApprovalService.saveEmailLog(payload).subscribe();
  }
}