import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxLength]',
  standalone: true
})
export class MaxLengthDirective {
  @Input() appMaxLength: number | undefined;
  
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const inputValue = this.el.nativeElement.value;
    const maxLength = this.appMaxLength;

    if (maxLength && inputValue.length > maxLength) {
      this.el.nativeElement.value = inputValue.substring(0, maxLength);
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any): void {
    const inputValue = this.el.nativeElement.value;
    const maxLength = this.appMaxLength;

    if (maxLength && inputValue.length >= maxLength) {
      let subStringValue = inputValue.substring(0, maxLength);
      this.el.nativeElement.value = subStringValue;
      this.ngControl.control?.setValue(subStringValue, { emitEvent: false });

    }
  }

}
