<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>No Claim Discount Enquiry</h1>
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #templateThree> -->
<form [formGroup]="searchNcdEnquiryForm">
  <div nxLayout="grid maxwidth" class="nx-margin-bottom-3xl">
    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Product Type">
          <nx-dropdown formControlName="productType" (selectionChange)="onChangeValue($event)">
            <ng-container *ngFor="let item of productTypeList">
              <nx-dropdown-item [value]="item.code">{{ item.descp }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please select Product Type.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-formfield label="Use of Vehicle">
          <nx-dropdown formControlName="vehicleUse">
            <ng-container *ngFor="let item of useOfVehicleList">
              <nx-dropdown-item [value]="item.code">{{ item.description }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please select Use of Vehicle.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,12,6">
        <nx-formfield label="Vehicle No.">
          <input nxInput formControlName="vehicleNo" appToUpperCase style="text-transform: uppercase;"/>
          <nx-error nxFormfieldError *ngIf="searchNcdEnquiryForm.get('vehicleNo').hasError('required')">
            <strong>Please enter Vehicle No.</strong>
          </nx-error>
          <nx-error nxFormfieldError *ngIf="searchNcdEnquiryForm.get('vehicleNo').hasError('pattern')">
            <strong>Invalid Vehicle No.</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="12,12,6">
        <nx-formfield label="Chassis No. (Optional)">
          <input nxInput formControlName="chassisNo" appToUpperCase style="text-transform: uppercase;"/>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="6">
        <nx-formfield label="ID Type">
          <nx-dropdown formControlName="idType" [panelGrow]="true" [panelMaxWidth]="423">
            <ng-container *ngFor="let type of idTypeList">
              <nx-dropdown-item [value]="type.code">{{ type.descp }}</nx-dropdown-item>
            </ng-container>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please select ID Type.</strong>
          </nx-error>
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield label="ID No.">
          <input nxInput formControlName="idNo1" appToUpperCase style="text-transform: uppercase;"/>
          <nx-error nxFormfieldError>
            <strong>Please enter ID No.</strong>
          </nx-error>
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12" class="nx-margin-top-s std-ta-right">
        <button (click)="resetForm()" nxButton="secondary" type="button" class="nx-margin-right-s">
          RESET
        </button>
        <button (click)="onSearchClicked()" nxButton="primary" type="button" class="m_margin_top_20">
          SEARCH
        </button>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12" class="nx-margin-y-2m">
        <hr />
      </div>
    </div>

    <div nxRow>
      <div nxCol="12">
        <table nxTable cellpadding="0" class="ncd_enquiry">
          <!-- @if(searchModelResult){ -->
          <ng-container *ngIf="searchModelResult">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">ISM Reference No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{searchModelResult.ismRefNo? searchModelResult.ismRefNo: 'N/A'}}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">ISM Response Code</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{searchModelResult.ismResCode? searchModelResult.ismResCode : 'N/A' }}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Previous Vehicle No.</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{searchModelResult.preVehNo? searchModelResult.preVehNo : 'N/A'}}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Current No Claim Discount (%)</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{ searchModelResult.currNcdPct ? (searchModelResult.currNcdPct | number:'1.2-2') : '0' }}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Current No Claim Discount Effective Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{ searchModelResult.currPctEffDate ? (searchModelResult.currPctEffDate | date:'dd/MM/yyyy') : 'N/A' }}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Current No Claim Discount Expiry Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{ searchModelResult.currPctExpDate ? (searchModelResult.currPctExpDate | date:'dd/MM/yyyy') : 'N/A' }}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Next No Claim Discount (%)</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{ searchModelResult.nextNcdPct ? (searchModelResult.nextNcdPct | number:'1.2-2') : '0' }}</span>
              </td>
            </tr>
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">Next No Claim Discount Effective Date</span>
              </td>
              <td nxTableCell class="column_cp_content">
                <span nxCopytext="normal">{{ searchModelResult.nextNcdPctEffDate ? (searchModelResult.nextNcdPctEffDate | date:'dd/MM/yyyy') : 'N/A' }}</span>
              </td>
            </tr>
          </ng-container>
          <!-- } -->
          <!-- @if(!searchModelResult){ -->
          <ng-container *ngIf="!searchModelResult">
            <tr nxTableRow>
              <td nxTableCell>
                <span nxCopytext="normal">{{noResultFoundMessage}}</span>
              </td>
            </tr>
          </ng-container>
          <!-- } -->
        </table>
      </div>
    </div>

  </div>
</form>
<!-- </ng-template> -->