<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>{{ title }}</h1>
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12" class="grid_width_page">
      <ng-container>
        <nx-tab-group
          mobileAccordion="false"
          (selectedTabChange)="tabChange($event)"
        >
          <!-- Policy Enquiry tab -->
          <nx-tab label="Policy Enquiry"> </nx-tab>

          <!-- Claim Enquiry tab -->
          <nx-tab label="Claim Enquiry"> </nx-tab>

          <!-- e-Invoice Enquiry tab -->
          <nx-tab label="e-Invoice Enquiry"> </nx-tab>
        </nx-tab-group>
      </ng-container>
    </div>
  </div>
</div>

<!-- policy enquiry -->
<div nxLayout="grid maxwidth" *ngIf="currentIndex == 0">
  <div nxRow>
    <div nxCol="12" class="grid_width_page">
      <div class="tab__content">
        <form>
          <div nxLayout="grid nopadding">
            <div nxRow class="nx-margin-y-2m">
              <div nxCol="12">
                <div
                  class="container_flex_center align_right"
                  (click)="togglePolicyFilter()"
                >
                  <nx-icon aria-hidden="true" name="filter" size="s"></nx-icon>
                  &nbsp; &nbsp;
                  <span nxCopytext="normal">Filter</span>
                </div>
              </div>
            </div>
            <ng-container *ngIf="show_policy_filter">
              <div nxRow>
                <div nxCol="12">
                  <nx-formfield label="Select Product">
                    <nx-dropdown [valueFormatter]="toText">
                      <nx-dropdown-item
                        *ngFor="let option of product_list"
                        [value]="option"
                      ></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12,12,4,4">
                  <nx-formfield label="Search By">
                    <nx-dropdown
                      [panelGrow]="true"
                      [panelMaxWidth]="2000"
                      [valueFormatter]="toText"
                    >
                      <nx-dropdown-item
                        *ngFor="let option of search_by_list"
                        [value]="option"
                      ></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,8,8">
                  <nx-formfield label="Enter Value">
                    <input nxInput type="text" />
                  </nx-formfield>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12">
                  <div class="button_dashboard">
                    <button nxButton="secondary" type="button">RESET</button>
                    <button nxButton="primary" type="button" class="mx-0">
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </form>

        <div class="nx-margin-y-2m">
          <app-external-scrollbar
            targetSyncScroll="1"
            [targetBaseWidth]="tableHolder1"
          ></app-external-scrollbar>
          <div
            class="table-holder table_content_scroll only-this-horizon-scrollbar"
            #tableHolder1
            syncScroll="1"
          >
            <table
              nxTable
              nxSort
              zebra
              class="enquiry clickable_table"
              (sortChange)="sortTable($event)"
            >
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="agent">
                    Agent Code /<br />Agent Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy_no">
                    Policy No. /e-Schedule <br />
                    No. /e-Cover Note No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="product_name">
                    Product <br />
                    Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="customer_name">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="Vehicle_trailer_no">
                    Vehicle / <br />
                    Trailer No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="effective_date">
                    Effective <br />
                    Date
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="expiry_date">
                    Expiry Date
                  </th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="premium_due"
                    class="cell_align_right"
                  >
                    Premium <br />
                    Due (RM)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="additional_info">
                    Additional Info
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  nxTableRow
                  *ngFor="let item of tableElements"
                  (click)="clickTable('Policy Enquiry')"
                >
                  <td nxTableCell class="std-va-top">
                    {{ item.agent }}
                  </td>
                  <td nxTableCell class="std-va-top">{{ item.policy_no }}</td>
                  <td nxTableCell class="std-va-top">{{ item.product_name }}</td>
                  <td nxTableCell class="std-va-top">
                    {{ item.customer_name }}
                  </td>
                  <td nxTableCell class="std-va-top">
                    {{ item.Vehicle_trailer_no }}
                  </td>
                  <td nxTableCell class="std-va-top">
                    {{ item.effective_date | date : "dd/MM/yyyy" }}
                  </td>
                  <td nxTableCell class="std-va-top">
                    {{ item.expiry_date | date : "dd/MM/yyyy" }}
                  </td> 
                  <td nxTableCell class="std-ta-right std-va-top">
                    {{ item.premium_due | number: '1.2-2' }}
                  </td>
                  <td nxTableCell class="std-va-top">
                    {{ item.additional_info }}
                  </td>
                  <td nxTableCell class="std-va-top">
                    <nx-badge [type]="item.status">{{
                      item.status.charAt(0).toUpperCase() + item.status.slice(1)
                    }}</nx-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="scroll-container nx-margin-top-2m">
          <nx-swipebar label="Swipe to view more">
            <table
              nxTable
              nxSort
              zebra
              class="enquiry clickable_table table_vertical_top"
              (sortChange)="sortTable($event)"
            >
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="agent">
                    Agent Code /<br />Agent Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy_no">
                    Policy No. /e-Schedule <br />
                    No. /e-Cover Note No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="product_name">
                    Product <br />
                    Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="customer_name">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="Vehicle_trailer_no">
                    Vehicle / <br />
                    Trailer No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="effective_date">
                    Effective <br />
                    Date
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="expiry_date">
                    Expiry Date
                  </th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="premium_due"
                    class="cell_align_right"
                  >
                    Premium <br />
                    Due (RM)
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="additional_info">
                    Additional Info
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="status">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  nxTableRow
                  *ngFor="let item of tableElements"
                  (click)="clickTable('Policy Enquiry')"
                >
                  <td nxTableCell>{{ item.agent }}</td>
                  <td nxTableCell>{{ item.policy_no }}</td>
                  <td nxTableCell>{{ item.product_name }}</td>
                  <td nxTableCell>
                    {{ item.customer_name }}
                  </td>
                  <td nxTableCell>
                    {{ item.Vehicle_trailer_no }}
                  </td>
                  <td nxTableCell>
                    {{ item.effective_date | date : "dd/MM/yyyy" }}
                  </td>
                  <td nxTableCell>
                    {{ item.expiry_date | date : "dd/MM/yyyy" }}
                  </td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.premium_due | number : "1.0" : "en-US" }}
                  </td>
                  <td nxTableCell>
                    {{ item.additional_info }}
                  </td>
                  <td nxTableCell>
                    <nx-badge [type]="item.status">{{
                      item.status.charAt(0).toUpperCase() +
                        item.status.slice(1)
                    }}</nx-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </nx-swipebar>
        </div> -->
      </div>
      <div class="nx-margin-bottom-3xl"></div>
    </div>
  </div>
</div>

<!-- claim enquiry -->
<div nxLayout="grid maxwidth" *ngIf="currentIndex == 1">
  <div nxRow>
    <div nxCol="12" class="grid_width_page">
      <div class="tab__content">
        <form>
          <div nxLayout="grid nopadding">
            <div nxRow class="nx-margin-y-2m">
              <div nxCol="12">
                <div
                  class="container_flex_center align_right"
                  (click)="toggleClaimFilter()"
                >
                  <nx-icon aria-hidden="true" name="filter" size="s"></nx-icon>
                  &nbsp; &nbsp;
                  <span nxCopytext="normal">Filter</span>
                </div>
              </div>
            </div>
            <ng-container *ngIf="show_claim_filter">
              <div nxRow>
                <div nxCol="12">
                  <nx-formfield label="Product Name">
                    <nx-dropdown
                      [valueFormatter]="toText"
                      value="motor"
                      [disabled]="true"
                    >
                      <nx-dropdown-item
                        *ngFor="let option of product_list"
                        [value]="option"
                      ></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12,12,4,4">
                  <nx-formfield label="Search By">
                    <nx-dropdown
                      [valueFormatter]="toText"
                      value="Customer Name"
                    >
                      <nx-dropdown-item
                        *ngFor="let option of search_by_list"
                        [value]="option"
                      ></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,8,8">
                  <nx-formfield label="Enter Value">
                    <input nxInput type="text" value="WONG KAM WAI" />
                  </nx-formfield>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12">
                  <div class="button_dashboard">
                    <button nxButton="secondary" type="button">RESET</button>
                    <button nxButton="primary" type="button" class="mx-0">
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </form>

        <div class="nx-margin-y-2m">
          <app-external-scrollbar
            targetSyncScroll="2"
            [targetBaseWidth]="tableHolder2"
          ></app-external-scrollbar>
          <div
            class="table-holder table_content_scroll only-this-horizon-scrollbar"
            #tableHolder2
            syncScroll="22"
          >
            <table nxTable nxSort zebra (sortChange)="sortTable($event)">
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="claim_no">Claim No.</th>
                  <th
                    nxHeaderCell
                    nxSortHeaderCell="policy_no"
                    class="text_no_wrap"
                  >
                    Policy No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="Vehicle_trailer_no">
                    Vehicle / Trailer No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="customer_name">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="as_at">As At</th>
                  <th nxHeaderCell class="std-ta-right text_no_wrap">
                    <span class="text_no_wrap">
                      No Claim Discount (%)
                    </span>
                    
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  nxTableRow
                  *ngFor="let item of tableClaims"
                  (click)="clickTable('Claim Enquiry')"
                >
                  <td nxTableCell>{{ item.claim_no }}</td>
                  <td nxTableCell class="text_no_wrap">
                    {{ item.policy_no }}
                  </td>
                  <td nxTableCell>{{ item.Vehicle_trailer_no }}</td>
                  <td nxTableCell>
                    {{ item.customer_name }}
                  </td>
                  <td nxTableCell>
                    {{ item.as_at | date : "dd/MM/yyyy" }}
                  </td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.ncd_percent }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="scroll-container nx-margin-y-2m">
          <nx-swipebar label="Swipe to view more">
            <table nxTable nxSort zebra (sortChange)="sortTable($event)">
              <thead>
                <tr nxTableRow>
                  <th nxHeaderCell nxSortHeaderCell="claim_no">
                    Claim No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="policy_no">
                    Policy No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="Vehicle_trailer_no">
                    Vehicle / Trailer No.
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="customer_name">
                    Customer Name
                  </th>
                  <th nxHeaderCell nxSortHeaderCell="as_at">As At</th>
                  <th
                    nxHeaderCell
                  
                    class="std-ta-right cell_align_right"
                  >
                    No Claim Discount (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr nxTableRow *ngFor="let item of tableClaims" (click)="clickTable('Claim Enquiry')">
                  <td nxTableCell>{{ item.claim_no }}</td>
                  <td nxTableCell>{{ item.policy_no }}</td>
                  <td nxTableCell>{{ item.Vehicle_trailer_no }}</td>
                  <td nxTableCell>
                    {{ item.customer_name }}
                  </td>
                  <td nxTableCell>
                    {{ item.as_at | date : "dd/MM/yyyy" }}
                  </td>
                  <td nxTableCell class="std-ta-right">
                    {{ item.ncd_percent }}
                  </td>
                </tr>
              </tbody>
            </table>
          </nx-swipebar>
        </div> -->
        <!-- <p nxCopytext="small">No Result Found</p> -->
      </div>
      <div class="nx-margin-bottom-3xl"></div>
    </div>
  </div>
</div>

<!-- e-Invoice Enquiry -->
<div nxLayout="grid maxwidth" *ngIf="currentIndex == 2">
  <div nxRow>
    <div nxCol="12" class="grid_width_page">
      <div class="tab__content">Third tab content</div>
    </div>
  </div>
</div>
