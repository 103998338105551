import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { clamp } from '@aposin/ng-aquila/utils';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
let progressbarId = 0;
class NxProgressbarComponent {
  constructor() {
    /** @docs-private */
    this.progressbarId = `nx-progress-bar-${progressbarId++}`;
    this._value = 0;
  }
  /** Sets the value of the progress bar. Defaults to zero. Mirrored to aria-value now. */
  set value(value) {
    this._value = clamp(value || 0); // TODO properly coerce input value
  }
  get value() {
    return this._value;
  }
  _primaryTransform() {
    const scale = this.value;
    return {
      transform: `scaleX(${scale})`
    };
  }
  static {
    this.ɵfac = function NxProgressbarComponent_Factory(t) {
      return new (t || NxProgressbarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NxProgressbarComponent,
      selectors: [["nx-progressbar"]],
      hostVars: 1,
      hostBindings: function NxProgressbarComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("aria-valuenow", ctx.value);
        }
      },
      inputs: {
        value: "value"
      },
      decls: 2,
      vars: 1,
      consts: [[1, "nx-progress__track"], [1, "nx-progress__indicator", 3, "ngStyle"]],
      template: function NxProgressbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "div", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", ctx._primaryTransform());
        }
      },
      dependencies: [i1.NgStyle],
      styles: ["[_nghost-%COMP%]{display:block;height:4px;overflow:hidden;position:relative;width:100%}.nx-progress__track[_ngcontent-%COMP%]{background-color:var(--progressbar-background-color);height:100%}.nx-progress__indicator[_ngcontent-%COMP%]{transform-origin:top left;height:100%;position:absolute;width:100%}[dir=rtl][_nghost-%COMP%]   .nx-progress__indicator[_ngcontent-%COMP%], [dir=rtl]   [_nghost-%COMP%]   .nx-progress__indicator[_ngcontent-%COMP%]{transform-origin:top right}.nx-progress__indicator[_ngcontent-%COMP%]:after{height:100%;position:absolute;width:100%;animation:none;content:\"\";display:inline-block;left:0;background-color:var(--progressbar-indicator-color)}[dir=rtl][_nghost-%COMP%]   .nx-progress__indicator[_ngcontent-%COMP%]:after, [dir=rtl]   [_nghost-%COMP%]   .nx-progress__indicator[_ngcontent-%COMP%]:after{right:0;left:auto}@media screen and (forced-colors: active){[_nghost-%COMP%]{height:8px}.nx-progress__track[_ngcontent-%COMP%]{border:1px solid windowFrame;border-radius:2px}.nx-progress__indicator[_ngcontent-%COMP%]{border-right:1px solid windowFrame}[dir=rtl][_ngcontent-%COMP%]   .nx-progress__indicator[_ngcontent-%COMP%]{border-right:none;border-left:1px solid windowFrame}.nx-progress__indicator[_ngcontent-%COMP%]:after{background:highlight}}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxProgressbarComponent, [{
    type: Component,
    args: [{
      selector: 'nx-progressbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[attr.aria-valuenow]': 'value'
      },
      template: "<div class=\"nx-progress__track\">\n    <div class=\"nx-progress__indicator\" [ngStyle]=\"_primaryTransform()\"></div>\n</div>\n",
      styles: [":host{display:block;height:4px;overflow:hidden;position:relative;width:100%}.nx-progress__track{background-color:var(--progressbar-background-color);height:100%}.nx-progress__indicator{transform-origin:top left;height:100%;position:absolute;width:100%}:host-context([dir=rtl]) .nx-progress__indicator{transform-origin:top right}.nx-progress__indicator:after{height:100%;position:absolute;width:100%;animation:none;content:\"\";display:inline-block;left:0;background-color:var(--progressbar-indicator-color)}:host-context([dir=rtl]) .nx-progress__indicator:after{right:0;left:auto}@media screen and (forced-colors: active){:host{height:8px}.nx-progress__track{border:1px solid windowFrame;border-radius:2px}.nx-progress__indicator{border-right:1px solid windowFrame}[dir=rtl] .nx-progress__indicator{border-right:none;border-left:1px solid windowFrame}.nx-progress__indicator:after{background:highlight}}\n"]
    }]
  }], null, {
    value: [{
      type: Input
    }]
  });
})();
class NxProgressbarModule {
  static {
    this.ɵfac = function NxProgressbarModule_Factory(t) {
      return new (t || NxProgressbarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NxProgressbarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NxProgressbarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [NxProgressbarComponent],
      declarations: [NxProgressbarComponent],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NxProgressbarComponent, NxProgressbarModule };
