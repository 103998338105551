<div class="title-header">
  <div nxLayout="grid maxwidth">
    <h1>ISM Policy / Endorsement Data Upload</h1>
  </div>
</div>

<div nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12">
      <nx-message context="error" class="nx-margin-bottom-m">
        <span class="nx-font-weight-bold"
          >ISM Status: NOT OK. Failed to update ISM. IST054 - Duplicate
          data.</span
        >
      </nx-message>

      <nx-message context="success" class="nx-margin-bottom-m">
        <span class="nx-font-weight-bold"
          >ISM Status: OK. ISM has been updated successfully.</span
        >
      </nx-message>
    </div>
  </div>
  <div nxRow>
    <div nxCol="12">
      <div nxLayout="grid nopadding" class="grid_remove_padding">
        <div nxRow>
          <div nxCol="12,12,12,6">
            <nx-formfield label="Insurance Code">
              <input nxInput disabled value="216" />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Document Type">
              <nx-dropdown>
                <nx-dropdown-item
                  value="NEW / RENEWAL POLICY DATA CREATION"
                ></nx-dropdown-item>
                <nx-dropdown-item
                  value="ENDORSEMENTS TO UPDATE EXISTING DATA (EFFECTIVE DATE, COVER ETC)"
                ></nx-dropdown-item>
                <nx-dropdown-item
                  value="POLICY CANCELLATION"
                ></nx-dropdown-item>
                <nx-dropdown-item
                  value="CANCEL NO CLAIM DISCOUNT WITHDRAWN"
                ></nx-dropdown-item>
                <nx-dropdown-item
                  value="NO CLAIM DISCOUNT WITHDRAWN"
                ></nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldNote>
                <strong>Document Type required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="Reason Code">
              <nx-dropdown>
                <nx-dropdown-item value="NEW BUSINESS"></nx-dropdown-item>
                <nx-dropdown-item value="RENEWAL BUSINESS"></nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldNote>
                <strong>Reason Code required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Policy No.">
              <input nxInput />
              <span nxFormfieldSuffix>
                <nx-icon aria-hidden="true" name="search"></nx-icon>
              </span>

              <nx-error nxFormfieldNote>
                <strong>Policy No. required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="Vehicle No.">
              <input nxInput />

              <nx-error nxFormfieldNote>
                <strong>Vehicle No. required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Policy Effective Date">
              <input nxDatefield nxInput [datepicker]="myDatepicker1" />
              <span nxFormfieldHint>MM/DD/YYYY</span>

              <nx-datepicker-toggle
                [for]="myDatepicker1"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker1></nx-datepicker>

              <nx-error nxFormfieldNote>
                <strong>Policy Effective Date required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="Policy Expiry Date">
              <input nxDatefield nxInput [datepicker]="myDatepicker2" />
              <span nxFormfieldHint>MM/DD/YYYY</span>

              <nx-datepicker-toggle
                [for]="myDatepicker2"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker2></nx-datepicker>

              <nx-error nxFormfieldNote>
                <strong>Policy Expiry Date required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Customer Name">
              <input nxInput />

              <nx-error nxFormfieldNote>
                <strong>Customer Name required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="ID No. 1">
              <input nxInput />
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="ID No. 2">
              <input nxInput />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Engine / Motor No.">
              <input nxInput />

              <nx-error nxFormfieldNote>
                <strong>Engine / Motor No. required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="Chassis No.">
              <input nxInput />

              <nx-error nxFormfieldNote>
                <strong>Chassis No. required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Claim Free Years (CFY)">
              <input nxInput value="0" />

              <nx-error nxFormfieldNote>
                <strong>Claim Free Years (CFY) required.</strong>
              </nx-error>
            </nx-formfield>

            <button
              nxButton="primary small"
              type="button"
              (click)="openPopup(25)"
            >
              CFY not within range 0 - 100
            </button>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="No Claim Discount Effective Date">
              <input nxDatefield nxInput [datepicker]="myDatepicker3" />
              <span nxFormfieldHint>MM/DD/YYYY</span>

              <nx-datepicker-toggle
                [for]="myDatepicker3"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker3></nx-datepicker>

              <nx-error nxFormfieldNote>
                <strong>No Claim Discount Effective Date required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="No Claim Discount Change Date">
              <input nxDatefield nxInput [datepicker]="myDatepicker4" />
              <span nxFormfieldHint>MM/DD/YYYY</span>

              <nx-datepicker-toggle
                [for]="myDatepicker4"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #myDatepicker4></nx-datepicker>

              <nx-error nxFormfieldNote>
                <strong>No Claim Discount Change Date required.</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Coverage Type">
              <nx-dropdown>
                <nx-dropdown-item value="COMPREHENSIVE"></nx-dropdown-item>
                <nx-dropdown-item value="THIRD PARTY"></nx-dropdown-item>
                <nx-dropdown-item
                  value="THIRD PARTY, FIRE AND THEFT"
                ></nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldNote>
                <strong>Coverage Type required</strong>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,12,6">
            <nx-formfield label="Vehicle Class">
              <nx-dropdown>
                <nx-dropdown-item value="PRIVATE CAR"></nx-dropdown-item>
                <nx-dropdown-item value="MOTORCYCLE"></nx-dropdown-item>
              </nx-dropdown>

              <nx-error nxFormfieldNote>
                <strong>Vehicle Class required</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <nx-formfield label="Use of Vehicle">
              <nx-dropdown
                value="PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY USE)"
              >
                <nx-dropdown-item
                  value="PRIVATE CAR - PRIVATE USE (DRIVE TO WORK/DAILY USE)"
                ></nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldNote>
                <strong>Use of Vehicle required</strong>
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,12,6">
            <nx-formfield label="Gross Premium (RM)">
              <input nxInput value="2,190.35" />
            </nx-formfield>

            <nx-error nxFormfieldNote>
              <strong>Gross Premium (RM) required</strong>
            </nx-error>
          </div>
        </div>
        <div nxRow class="nx-margin-top-2m nx-margin-bottom-3m">
          <div nxCol="12" class="button_ism">
            <button nxButton="secondary" type="button">RESET</button>
            <button nxButton="primary" type="button" class="nx-margin-right-0">
              UPDATE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PopUp for Policy Search (to be move to new component) -->
<ng-template #policysearch>
  <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s">
    Policy Movement
</h3> 
  <div nxModalContent class="ism_popup">   

    <div nxLayout="grid nopadding" class="grid_remove_padding">
      <div nxRow>
        <div nxCol="12,12,6">
          <nx-formfield label="Search By">
            <nx-dropdown [panelGrow]="true"
            [panelMaxWidth]="2000">
              <nx-dropdown-item value="VEHICLE NO."></nx-dropdown-item>
              <nx-dropdown-item value="E-POLICY NO."></nx-dropdown-item>
              <nx-dropdown-item value="CUSTOMER NAME"></nx-dropdown-item>
              <nx-dropdown-item value="NRIC"></nx-dropdown-item>
              <nx-dropdown-item
                value="OLD IC / POLICE ID / ARMY ID / PASSPORT NO."
              ></nx-dropdown-item>
              <nx-dropdown-item
                value="BUSINESS REGISTRATION NO."
              ></nx-dropdown-item>
              <nx-dropdown-item
                value="NEW BUSINESS REGISTRATION NO."
              ></nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="12,12,6">
          <nx-formfield label="Enter Value">
            <input nxInput value="2289" />
          </nx-formfield>
        </div>
      </div>
      <div nxRow>
        <div nxCol="12" class="button_dashboard">
          <button nxButton="secondary" type="button">RESET</button>
          <button nxButton="primary" type="button" class="nx-margin-right-0">
            SEARCH
          </button>
        </div>
      </div>
    </div>

    <div>
      <app-external-scrollbar
        targetSyncScroll="1"
        [targetBaseWidth]="tableHolder"
      ></app-external-scrollbar>
      <div
        class="table-holder table_content_scroll only-this-horizon-scrollbar"
        #tableHolder
        syncScroll="1"
      >
      <table nxTable zebra cellpadding="0">
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>
              <span nxCopytext="medium" class="nx-font-weight-semibold"
                >Vehicle No.</span
              >
            </th>
            <th nxHeaderCell>
              <span nxCopytext="medium" class="nx-font-weight-semibold"
                >e-Policy No.</span
              >
            </th>
            <th nxHeaderCell>
              <span nxCopytext="medium" class="nx-font-weight-semibold"
                >Customer Name</span
              >
            </th>
            <th nxHeaderCell>
              <span nxCopytext="medium" class="nx-font-weight-semibold"
                >Vehicle Class</span
              >
            </th>
            <th nxHeaderCell>
              <span nxCopytext="medium" class="nx-font-weight-semibold"
                >Coverage Type</span
              >
            </th>
            <th nxHeaderCell>
              <span nxCopytext="medium" class="nx-font-weight-semibold"
                >Effective Date</span
              >
            </th>
            <th nxHeaderCell>
              <span nxCopytext="medium" class="nx-font-weight-semibold"
                >Expiry Date</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow>
            <td nxTableCell><span nxCopytext="medium">SHN2289</span></td>
            <td nxTableCell class="text_no_wrap"><span nxCopytext="medium">3VKP0000008-00</span></td>
            <td nxTableCell><span nxCopytext="medium">WONG KAM WAI</span></td>
            <td nxTableCell><span nxCopytext="medium">PRIVATE CAR</span></td>
            <td nxTableCell><span nxCopytext="medium">COMPREHENSIVE</span></td>
            <td nxTableCell><span nxCopytext="medium">19/06/2023</span></td>
            <td nxTableCell><span nxCopytext="medium">18/06/2024</span></td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell><span nxCopytext="medium">SHN2289</span></td>
            <td nxTableCell class="text_no_wrap"><span nxCopytext="medium">3VKP0000009-00</span></td>
            <td nxTableCell><span nxCopytext="medium">JOHN DOE</span></td>
            <td nxTableCell><span nxCopytext="medium">PRIVATE CAR</span></td>
            <td nxTableCell><span nxCopytext="medium">COMPREHENSIVE</span></td>
            <td nxTableCell><span nxCopytext="medium">19/06/2023</span></td>
            <td nxTableCell><span nxCopytext="medium">18/06/2024</span></td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell><span nxCopytext="medium">SHN2289</span></td>
            <td nxTableCell class="text_no_wrap"><span nxCopytext="medium">3VKP0000010-00</span></td>
            <td nxTableCell><span nxCopytext="medium">LIM SEE PENG</span></td>
            <td nxTableCell><span nxCopytext="medium">PRIVATE CAR</span></td>
            <td nxTableCell><span nxCopytext="medium">COMPREHENSIVE</span></td>
            <td nxTableCell><span nxCopytext="medium">19/06/2023</span></td>
            <td nxTableCell><span nxCopytext="medium">18/06/2024</span></td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell><span nxCopytext="medium">SHN2289</span></td>
            <td nxTableCell class="text_no_wrap"><span nxCopytext="medium">3VKP0000011-00</span></td>
            <td nxTableCell><span nxCopytext="medium">ALEX LEE</span></td>
            <td nxTableCell><span nxCopytext="medium">PRIVATE CAR</span></td>
            <td nxTableCell><span nxCopytext="medium">COMPREHENSIVE</span></td>
            <td nxTableCell><span nxCopytext="medium">19/06/2023</span></td>
            <td nxTableCell><span nxCopytext="medium">18/06/2024</span></td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell><span nxCopytext="medium">SHN2289</span></td>
            <td nxTableCell class="text_no_wrap"><span nxCopytext="medium">3VKP0000012-00</span></td>
            <td nxTableCell><span nxCopytext="medium">FONG ENG LI</span></td>
            <td nxTableCell><span nxCopytext="medium">PRIVATE CAR</span></td>
            <td nxTableCell><span nxCopytext="medium">COMPREHENSIVE</span></td>
            <td nxTableCell><span nxCopytext="medium">19/06/2023</span></td>
            <td nxTableCell><span nxCopytext="medium">18/06/2024</span></td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div
      nxLayout="grid nopadding"
      class="nx-margin-top-2xs grid_remove_padding pagination_form"
    >
      <div nxRow>
        <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
          <nx-natural-language-form size="small" class="pagination_form">
            <span nxCopytext="normal">Show</span>
            <nx-word>
              <nx-dropdown value="20">
                <nx-dropdown-item value="5">5</nx-dropdown-item>
                <nx-dropdown-item value="10">10</nx-dropdown-item>
                <nx-dropdown-item value="20">20</nx-dropdown-item>
                <nx-dropdown-item value="30">30</nx-dropdown-item>
                <nx-dropdown-item value="40">40</nx-dropdown-item>
                <nx-dropdown-item value="50">50</nx-dropdown-item>
              </nx-dropdown>
            </nx-word>
            <span nxCopytext="normal">results per page</span>
          </nx-natural-language-form>
        </div>

        <div
          class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
        ></div>
        <div nxCol="12,12,6">
          <nx-pagination
            [count]="count"
            [page]="page"
            [perPage]="perPage"
            type="advanced"
            (goPrev)="prevPage()"
            (goNext)="nextPage()"
            (goPage)="goToPage($event)"
            ariaLabel="Advanced example pagination"
            class="pagination_position"
          >
          </nx-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="nx-margin-top-2m">
    <button nxModalClose nxButton="secondary" type="button">Cancel</button>
  </div>
</ng-template>
