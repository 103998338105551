import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { Moment } from 'moment';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { SummaryPopupComponent } from "../../../popup/summary-popup/summary-popup.component";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from '@store/sat-motor/sat-motor.state';
import { Observable } from 'rxjs';
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { ViewRemarksComponent } from '@shared/components/view-remarks/view-remarks.component';
import { VehicleViewAmendmentComponent } from '@shared/components/vehicle-view-amendment/vehicle-view-amendment.component';
import {
  CALCULATE_PREMIUM,
  SELECTED_MAKE_MODEL,
  CALCULATE_PREMIUM_SUMMARY,
  GET_CUSTOMER_PARTNER_MOCK,
  GENERATE_QUOTATION,
  SEND_TO_JPJ,
  DOWNLOAD_PDF,
  SET_JPJ_STATUS_SENT,
  CALCULATE_PREMIUM_PAYLOAD,
  GET_DOCUMENT_LOV,
  STORE_ISSUANCE_SUMMARY,
  GET_QUOTATION_DETAIL,
  CANCEL_REPLACE,
  REQUEST_CANCELLATION,
  STORE_AGENT_FROM_RESPONSE,
  STORE_ISSUANCE_FROM_RESPONSE,
  COPY_CNGE_INFO_RENEWAL,
  COPY_CNGE_INFO_VIEW_QUOTATION,
  GET_PRODUCT_LIST,
  STORE_EXTRA_COVER_PACKAGE,
  GET_SWITCH_SITE,
  GET_CROSS_SELL_LIST,
  GET_QUOTATION_DETAIL_REQUOTE,
  COPY_DRAFT_INFO,
  COPY_REQUOTE_INFO,
  RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ,
  COPY_CNGE_INFO,
  CANCEL_QUOTATION,
  CANCEL_COVER_NOTE,
  SET_ACTION,
  GET_AGENT_LIST_AGENT
}
  from "@store/sat-motor/sat-motor.action";
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { SharedModule } from '@shared/shared.module';
import { PaymentModeComponent } from '@shared/components/modal/payment-mode/payment-mode.component';
import { OneAllianzMessageComponent } from '@shared/components/modal/one-allianz-message/one-allianz-message.component';
import { PaymentDetailsComponent } from '@shared/components/payment-details/payment-details.component';
import { UpsellProductComponent } from '@shared/components/upsell-product/upsell-product.component';
import { MakeModelComponent } from "@shared/components/modal/make-model/make-model.component";
import { NumberRangeValidator } from "@shared/classes/validators/number-range.validator";
import { UploadDocumentComponent } from "@shared/components/upload-document/upload-document.component";
import { UserResponse } from "@interfaces/user";
import { UploadDocument } from "@interfaces/fileupload";
import { CoverageSummaryComponent } from '@shared/components/coverages/coverage-summary/coverage-summary.component';
import { OkMessageComponent } from '@shared/components/modal/ok-message/ok-message.component';
import { EnquiryPopupComponent } from 'src/app/popup/enquiry-popup/enquiry-popup.component';
import { RequestCancellationComponent } from '@shared/components/modal/request-cancellation/request-cancellation.component';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { ACTIONS } from '@shared/enums/index.enum';

@Component({
  selector: 'app-view-quotation',
  standalone: true,
  imports: [
    NxAccordionModule,
    NxGridModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxButtonModule,
    NxIconModule,
    NxDropdownModule,
    NxInputModule,
    NxFormfieldModule,
    NxHeadlineModule,
    NxTableModule,
    NxListModule,
    NxSwipebarModule,
    ProgressBarComponent,
    NxCardModule,
    NxCheckboxModule,
    TextFieldModule,
    NxModalModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxRadioModule,
    SharedModule,
    PaymentDetailsComponent,
    UpsellProductComponent,
    CoverageSummaryComponent,
    OkMessageComponent,
    CrossSellBannerComponent,
    ViewRemarksComponent,
    VehicleViewAmendmentComponent
  ],
  templateUrl: './view-quotation.component.html',
  styleUrl: './view-quotation.component.scss'
})
export class ViewQuotationComponent implements OnInit {
  @ViewChild(VehicleViewAmendmentComponent) vehicleAmendData!: VehicleViewAmendmentComponent;
  @Select(SatMotorState.motorActionType) actionType$;
  @Select(SatMotorState.commonLov) commonLov$;

  isIssuance: boolean = false;
  isEndorsement: boolean = false;
  isEnquiry: boolean = false;
  isRTP: boolean = false;

  requestCancellationModal?: NxModalRef<RequestCancellationComponent>;
  cnoteStatus: any;
  quotationDetail: any;
  progressType: any;
  userInfo: any;
  agentData: any;
  issuanceInfo: any = {};
  clientClaimHistory: any;
  vehicleClaimHistory: any;
  premiumInfo: any;
  coverageInfo: any;
  cpData: any;
  lov: any;
  isProgressTypeCN: boolean = false;
  isProgressTypeENDST: boolean = false;
  isProgressTypeRTP: boolean = false;

  showAgentInfo: boolean = false;
  showIssuanceInfo: boolean = false;
  showCustomerPartner: boolean = false;
  showReferralDetails: boolean = false;
  showCoverage: boolean = false;
  showHistoricalClaim: boolean = false;
  showPremiumInformation: boolean = false;
  showReferCase: boolean = false;
  showPayment: boolean = false;
  showUpsellProduct: boolean = false;
  showPaymentInformation: boolean = false;
  showPaymentDetails: boolean = false;
  showChecklist: boolean = false;
  showRenewalAssistant: boolean = false;
  showEndorsementNarration: boolean = false;
  showRoadTaxPayable: boolean = false;
  showEndorsementErrMessage: boolean = false;
  sundriesAgent: boolean = false;
  showRemarks: boolean = false;
  contractId: any;
  mock: any;
  token: any;
  referralForm: FormGroup;
  referCaseForm: FormGroup;
  callIssuanceInformation: boolean = false;
  viewQuotation: boolean = true;
  popupMessage: any;
  referralReasonList: any;
  status: any;
  classType: any;
  type: any;
  proposalNo: any;
  productName: any;
  dialogRef?: NxModalRef<any>;
  okMessageModal?: NxModalRef<OkMessageComponent>;
  productList: any;
  remarksInfo: any;
  agentReferId: string;
  responseData: any;
  cngeNote: any;
  isDataLoaded: boolean = false;
  queryParams: any = {};
  crossSellList: any = [];
  marketingConsent: any;
  isUpdateJPJScreen: boolean = false;
  isAmendDateScren: boolean = false;

  isShowBackBtn: boolean = false;
  isShowRequestCancellationBtn: boolean = false;
  isShowUpdateQuotationBtn: boolean = false;
  isShowSendToJPJBtn: boolean = false;
  isShowUploadDocumentBtn: boolean = false;
  isShowAddRemarksBtn: boolean = false;
  isUpdatePaymentBtn: boolean = false;
  isShowDownloadQuotationBtn: boolean = false;
  isShowDownloadCoverNoteBtn: boolean = false;
  isShowDownloadInvoiceBtn: boolean = false;
  isShowUpdateJPJBtn: boolean = false;
  isShowAmendCoverNoteBtn: boolean = false;
  isShowEmailDocumentBtn: boolean = false;
  isShowRequoteBtn: boolean = false;
  isShowMarketingBtn: boolean = false;
  isShowIssueNewBtn: boolean = false;

  monthList = [
    { code: '01', name: '01' },
    { code: '02', name: '02' },
    { code: '03', name: '03' },
    { code: '04', name: '04' },
    { code: '05', name: '05' },
    { code: '06', name: '06' },
    { code: '07', name: '07' },
    { code: '08', name: '08' },
    { code: '09', name: '09' },
    { code: '10', name: '10' },
    { code: '11', name: '11' },
    { code: '12', name: '12' },
  ];

  yearList = [
    { code: '2024', name: '2024' },
    { code: '2025', name: '2025' },
    { code: '2026', name: '2026' },
    { code: '2027', name: '2027' }
  ];

  paymentModeList = [
    { code: 'MASTER', name: 'MASTER' },
    { code: 'VISA', name: 'VISA' }
  ];

  statusMap = {
    PRINTED: "Printed",
    APPROVED: "Approved",
    PENDING: "Pending Approval",
    CANCELLED: "Cancelled",
    SUBMITTED: "Submitted",
    ACTIVE: "Active",
    EXPIRED: "Expired"
  };

  classTypeMap = {
    PRINTED: "printed_badge",
    APPROVED: "approved_badge",
    PENDING: "pending_badge",
    CANCELLED: "cancel_badge",
    SUBMITTED: "submitted_badge",
    ACTIVE: "active_badge",
    EXPIRED: "expired_badge"
  };


  @Select(SatMotorState.premiumCalculationSummary) premiumCalculationSummary$: Observable<SatMotorState>;

  alimCustomerInd: boolean;
  hasShowedOneAllianzPopUp: boolean;
  oneAllianzModal?: NxModalRef<OneAllianzMessageComponent>;

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.referralForm = this.fb.group({
      referralId: ['', Validators.required]
    });

    this.referCaseForm = this.fb.group({
      waiveLoadInd: [{ value: false, disabled: true }],
      waiveExcessInd: [{ value: false, disabled: true }],
      underwritingInd: [true],
      remarks: ['', Validators.required]
    });
  }

  renewalAssistanceForm = new FormGroup({
    renewalAssistant: new FormControl(false),
    paymentMode: new FormControl(''),
    creditCardNo: new FormControl(''),
    creditCardExpiryDateMonth: new FormControl(''),
    creditCardExpiryDateYear: new FormControl(''),
    nameOnCard: new FormControl(''),
    termofUse: new FormControl(false),
    renewalAssistanceStartDate: new FormControl('')
  });

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    this.queryParams = this.store.selectSnapshot<any>((state) => state.UserState.queryParams);
    this.userInfo = this.store.selectSnapshot<UserResponse>(state => state.UserState.user);
    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

    this.commonLov$.subscribe((state) => {
      if (state) {
        this.lov = state;
      }
    });

    if (this.isRTP) {
      this.store.dispatch(new GET_QUOTATION_DETAIL(this.queryParams.contractId)).subscribe((state) => {
        const rtpPolicy = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step1?.rtpPolicy?.data?.cngeNoteDto);
        const sendJPJResponse = this.store.selectSnapshot<any>(state => state.SatMotorState.rtp?.step2?.sendToJPJ?.data?.cngeNoteDto);
        
        this.populateCommonData(state, sendJPJResponse);
        
        if (!this.issuanceInfo.issuance.eCoverNoteNo) {
          this.issuanceInfo = {
            ...this.issuanceInfo,
            issuance: {
              ...this.issuanceInfo.issuance,
              eCoverNoteNo: sendJPJResponse.cnoteNo
            }
          };
        }
        
        this.isProgressTypeRTP = true;
        this.progressType = 'rtp';
        this.showComponents();
        this.isDataLoaded = true;
      });
    } else {
      this.type = 'view-quotation-enquiry';
      this.popupMessage = 'Your e-Cover Note has been submitted as pending approval.';

      this.searchCoverNoteByContractId();
      
    }
  }

  searchCoverNoteByContractId() {
    this.store.dispatch(new GET_QUOTATION_DETAIL(this.queryParams.contractId)).subscribe((state) => {
      this.populateCommonData(state, null);

      this.showRemarks = !!this.remarksInfo;
      this.showReferralDetails = !!this.agentReferId;

      const getCrossSellListPayload = {
        "agentCode": this.agentData.agentCode,
        "partnerId": this.cpData.partnerId,
        "idType": this.cpData.idType1,
        "idValue": this.cpData.idValue1
      };
      this.store.dispatch(new GET_CROSS_SELL_LIST(getCrossSellListPayload)).subscribe(state => {
        this.crossSellList = state.SatMotorState.data.step5.crossSellList;
        this.marketingConsent = state.SatMotorState.data.step5.marketingConsent;
        console.log("cross sell list: ", this.crossSellList);
      });
      this.isProgressTypeCN = true;
      this.progressType = 'cn';
      this.showComponents();
      this.updateButtons(this.cngeNote, this.issuanceInfo.issuance);
      this.isIssuance = true;
      this.isDataLoaded = true;
      window.scroll({ top: 0, left: 0, behavior: 'instant' });
    });
  }

  populateCommonData(state: any, sendJPJResponse: any) {
    this.responseData = state.SatMotorState.viewQuotationData;
    this.cngeNote = this.responseData.cngeNote;
    this.status = this.statusMap[this.cngeNote.cnoteStatus] || "";
    this.classType = this.classTypeMap[this.cngeNote.cnoteStatus] || "";
    this.proposalNo = sendJPJResponse ? sendJPJResponse.cnoteNo : this.cngeNote.proposalNo || this.cngeNote.cnoteNo;
  
    this.agentData = this.responseData.agentData;
    this.issuanceInfo = this.responseData.issuanceInfo;
    this.cpData = this.responseData.cpData;
    this.coverageInfo = this.responseData.coverageInfo;
    this.premiumInfo = this.responseData.premiumInfo;
    this.remarksInfo = this.responseData.remarksInfo;
  
    this.clientClaimHistory = this.cngeNote.claimCnt ?? '0';
    this.vehicleClaimHistory = this.cngeNote.vehClaimCount ?? '0';
  
    this.updateProductList(this.responseData.mkagAgent.agentCode, this.cngeNote.productCode);
    this.lov = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov);
  }

  updateButtons(cngeNote: any, issuance: any) {
    const status = cngeNote.cnoteStatus;
    const isPendingApproval = status === 'PENDING';
    const isPrintQuotation = status === 'PRINTED' && cngeNote.proposalNo && !cngeNote.cnoteNo;
    const isPrintCNote = status === 'PRINTED' && cngeNote.cnoteNo;
    const isPrintRTP = cngeNote.cnoteType === 'RP';
    const isCancelled = status === 'CANCELLED';
    const isExpired = status === 'EXPIRED';
    const isSubmitted = status === 'SUBMITTED';

    cngeNote = { ...cngeNote, isPendingApproval, isPrintQuotation, isPrintCNote };
    this.cngeNote = { ...cngeNote };

    this.isShowBackBtn = true;

    if (isPendingApproval) {
      Object.assign(this, {
        isShowRequestCancellationBtn: true,
        isShowAddRemarksBtn: true,
        isUpdatePaymentBtn: true,
        isShowUploadDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true
      });
    } else if (isPrintQuotation) {
      Object.assign(this, {
        isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote),
        isShowUpdateQuotationBtn: true,
        isShowDownloadQuotationBtn: true,
        isShowSendToJPJBtn: !!this.userInfo.agent && this.userInfo.agent === true,
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true
      });
    } else if (isPrintCNote) {
      Object.assign(this, {
        isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote),
        isUpdatePaymentBtn: this.showUpdatePayment(cngeNote),
        isShowDownloadCoverNoteBtn: true,
        isShowDownloadInvoiceBtn: true,
        isShowUpdateJPJBtn: this.showUpdateUpdateJPJ(cngeNote),
        isShowAmendCoverNoteBtn: this.showAmendCoverNote(cngeNote),
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true
      });
    } else if (isPrintRTP) {
      this.isShowBackBtn = true;
    } else if (isCancelled || isExpired) {
      if(isCancelled) {
        Object.assign(this, {
          isShowRequestCancellationBtn: this.showRequestCancellation(cngeNote),
          isUpdatePaymentBtn: this.showUpdatePayment(cngeNote),
          isShowDownloadCoverNoteBtn: false,
          isShowDownloadInvoiceBtn: false,
          isShowUpdateJPJBtn: this.showUpdateUpdateJPJ(cngeNote),
          isShowAmendCoverNoteBtn: this.showAmendCoverNote(cngeNote),
          isShowUploadDocumentBtn: false,
          isShowEmailDocumentBtn: false,
          isShowMarketingBtn: false,
          isShowIssueNewBtn: false,
          isShowRequoteBtn: !!this.userInfo.agent && this.userInfo.agent === true,
          isShowUpdateQuotationBtn: false,
          isShowDownloadQuotationBtn: false,
          isShowSendToJPJBtn: false,
        });
      }else {
        this.isShowRequoteBtn = !!this.userInfo.agent && this.userInfo.agent === true;
      }
    } else if (isSubmitted) {
      Object.assign(this, {
        isUpdatePaymentBtn: true,
        isShowDownloadCoverNoteBtn: true,
        isShowDownloadInvoiceBtn: true,
        isShowAmendCoverNoteBtn: true,
        isShowUploadDocumentBtn: true,
        isShowEmailDocumentBtn: true,
        isShowMarketingBtn: true,
        isShowIssueNewBtn: true
      });
    }
  }

  showRequestCancellation(cngeNote: any): any {
    const {
      cnoteStatus, jpjStatus, cnoteType, endtCode,
      policyNo, endtNo, issueCngeNote,
      issueEndorsement, createBy, updateBy
    } = cngeNote;
    const userId = this.userInfo.userId;

    let hideButton = false;

    if (cnoteStatus !== "CANCELLED") {
      if (cngeNote.isPrintCNote) {
        hideButton =
          (cnoteStatus === 'CANCELLED') ||
          (cnoteStatus === 'PRINTED' && jpjStatus === 'SENT' && cnoteType !== 'OTTEMP') ||
          (cnoteStatus === 'PRINTED' && jpjStatus === 'SENT' && !endtCode && policyNo) ||
          (cnoteStatus === 'PRINTED' && jpjStatus === 'SENT' && endtCode && cnoteType === 'ENDT' && endtNo) ||
          ((issueCngeNote === true || issueEndorsement === true) && (createBy !== userId && updateBy !== userId));
      }

      if (cngeNote.isPrintQuotation) {
        hideButton = hideButton || ((issueCngeNote === true || issueEndorsement === true) && (createBy !== userId && updateBy !== userId))
          || cnoteStatus === 'CANCELLED';
      }
    }else {
      hideButton = true;
    }

    return !hideButton;
  }  

  showUpdatePayment(cngeNote: any): any {
    const isCallCentreOrBancaAgent = this.userInfo.callCentreOrBancaAgent;
    const userId = this.userInfo.userId;
    const {
      cnoteStatus, issueCngeNote,
      issueEndorsement, createBy
    } = cngeNote;

    let hideButton = false;

    if (!isCallCentreOrBancaAgent ||
      cnoteStatus === "CANCELLED" ||
      (cnoteStatus === "PENDING" && (issueCngeNote === true || issueEndorsement === true) && createBy !== userId)) {
      hideButton = true;
    }

    return !hideButton;
  }

  showUpdateUpdateJPJ(cngeNote: any): any {
    const {
      cnoteStatus, jpjStatus, issueCngeNote,
      issueEndorsement, createBy, updateBy
    } = cngeNote;
    const userId = this.userInfo.userId;

    let hideButton = false;

    if (
      cnoteStatus !== "PRINTED" || jpjStatus !== "NOT OK" ||
      issueCngeNote === true || issueEndorsement === true ||
      createBy !== userId || updateBy !== userId
    ) {
      hideButton = true;
    }

    return !hideButton;
  }

  showAmendCoverNote(cngeNote: any): any {
    const {
      cnoteStatus, jpjStatus, issueCngeNote,
      issueEndorsement, createBy, updateBy
    } = cngeNote;
    const userId = this.userInfo.userId;

    let hideButton = false;

    if (
      !((cnoteStatus === "PRINTED" || cnoteStatus === "USED") && jpjStatus === "OK") ||
      issueCngeNote === true ||
      issueEndorsement === true ||
      createBy !== userId ||
      updateBy !== userId
    ) {
      hideButton = true;
    }

    return !hideButton;
  }

  async updateProductList(agentCode, productCode) {
    this.productList = this.lov?.productList;
    if (!this.productList) {
      let productListPayload = {
        lob: 'MT',
        agentCode: agentCode
      };

      await firstValueFrom(this.store.dispatch(new GET_PRODUCT_LIST(productListPayload)));
      this.productList = this.lov?.productList;
    }

    let product = this.productList.find(e => e.code === productCode);
    if (product) {
      let descp = product.descp
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
      console.log(this.productList);
      this.productName = {
        descp: descp
      }
    }

    this.getCoverDescription(productCode);
  }

  getCoverDescription(productCode) {
    const productAccessConfigList = this.lov?.productAccessConfigList;
    if (!productAccessConfigList) return;

    const coverListLov = productAccessConfigList.reduce((list, product) => {
      return list.concat(product[productCode]?.coverList || []);
    }, []);

    const coverCodeVal = this.coverageInfo?.coverageDetails?.coverCode;

    if (coverCodeVal) {
      const matchingCover = coverListLov.find(cover => cover.coverCode === coverCodeVal);
      if (matchingCover) {
        const updatedCoverageDetails = {
          ...this.coverageInfo.coverageDetails,
          coverDescription: matchingCover.coverDescription
        };

        this.coverageInfo = {
          ...this.coverageInfo,
          coverageDetails: updatedCoverageDetails
        };
      }
    }
  }

  getCoverageFromPolicy(policy) {
    let riskList = policy.riskList[0];
    let coverageData: any;

    coverageData = {
      coverage: {
        coveragePremium: {
          cngeNote: policy,
        },
        coverageType: {
          ...riskList.coverList[0],
        },
        extracover: {
          driverNameDetails: {
            rows: riskList.cngeRiskDrvrDtoList,
          },
          ehailingDriverDetails: {},
          extraCoverPackage: {},
        },
        vehicleDetails: {
          ...riskList,
        }
      }
    };

    if (coverageData) {
      this.coverageInfo = coverageData;
    }
  }

  showComponents() {
    if (this.isProgressTypeCN) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.showReferCase = false;
    }

    if (this.isRTP) {
      this.showAgentInfo = true;
      this.showIssuanceInfo = true;
      this.showCustomerPartner = true;
      this.showCoverage = true;
      this.showHistoricalClaim = true;
      this.showPremiumInformation = true;
      this.viewQuotation = true;
      this.progressType = 'rtp';
    }
  }

  componentDialogRef?: NxModalRef<SummaryPopupComponent>;

  openPopup(popup_type: any) {

    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: '400px',
      data: { popup_type: popup_type },
    });
  }


  openPopupOCC(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(SummaryPopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      height: '500px',
      data: { popup_type: popup_type },
    });
  }

  goToIssueDeclaration() {

    this.router.navigate(['issuance/declaration-issuance-info'], {
      queryParamsHandling: 'preserve',
    });

  }

  validateReferral() {

    if (this.referralForm.invalid) {
      this.referralForm.markAllAsTouched();
    }

  }

  validateReferCase() {
    if (this.referCaseForm.invalid) {
      console.log("referCaseForm invalid!");
      this.referCaseForm.markAllAsTouched();
    } else {
      console.log("referCaseForm valid...");
    }
  }

  validateRenewalAssistance() {
    if (this.renewalAssistanceForm.invalid) {
      console.log("renewalAssistanceForm invalid!");
      this.renewalAssistanceForm.markAllAsTouched();
    } else {
      console.log("renewalAssistanceForm valid...");
    }
  }

  redirectBack() {
    if (this.queryParams?.action === 'COMPLETE') {
      this.router.navigate(['issuance/complete'], {
        queryParamsHandling: 'preserve',
      });
    } else {
      this.redirectToSummary();
    }
  }

  redirectToSummary() {
    this.store.dispatch(new GET_SWITCH_SITE("SUMMARY_SYS_URL")).subscribe(state => {
      window.location.href = state.SatMotorState.data.switchSiteUrl;
    });
  }

  sendToJPJ() {
    let payload = {
      userId: this.userInfo.userId,
      proposalNo: this.cngeNote.proposalNo,
      contractId: this.cngeNote.contractId,
      cnoteDate: this.cngeNote.cnoteDate,
      issueBy: this.cngeNote.issueBy,
      issuedDate: this.cngeNote.cnoteDate
    };

    let resetPayload = {
      isRTP: false
    };

    this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    this.store.dispatch(new SEND_TO_JPJ(payload)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);

      if (response && response.data && response.success && response.data?.contractId) {
        let updatedContractId = response?.data?.contractId;
        
        if(updatedContractId && response?.data?.cnoteStatus === 'PRINTED') {
          this.cngeNote = {
            ...this.cngeNote,
            contractId: updatedContractId
          }
          this.downloadPDF('covernote');
        }
      }

      let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
      if (message) {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: message
          }
        });
      }

      this.store.dispatch(new SET_JPJ_STATUS_SENT(true));

      this.router.navigate(['issuance/complete'], {
        queryParamsHandling: 'preserve',
      });

    });
  }

  downloadPDF(type) {
    let payload = {
      operator: this.userInfo.userId,
      contractId: this.cngeNote.contractId,
      type: type
    };

    this.store.dispatch(new DOWNLOAD_PDF(payload)).subscribe(() => {
    });
  }

  onChangeRenewalAssistanceForm(fieldName: any, value: any) {
    console.log('onChangeValue..' + value.checked);
    if (fieldName == 'renewalAssistant') {
      //this.renewalAssistanceForm.controls.paymentMode.reset();
      this.renewalAssistanceForm.controls.paymentMode.clearValidators();
      this.renewalAssistanceForm.controls.creditCardNo.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.clearValidators();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.clearValidators();
      this.renewalAssistanceForm.controls.nameOnCard.clearValidators();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.clearValidators();
      this.renewalAssistanceForm.controls.termofUse.clearValidators();
      if (value == true) {
        this.renewalAssistanceForm.controls.paymentMode.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.setValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.setValidators([Validators.required]);
      } else {
        this.renewalAssistanceForm.controls.paymentMode.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardNo.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.creditCardExpiryDateYear.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.nameOnCard.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.renewalAssistanceStartDate.removeValidators([Validators.required]);
        this.renewalAssistanceForm.controls.termofUse.removeValidators([Validators.required]);
      }
      this.renewalAssistanceForm.controls.paymentMode.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardNo.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateMonth.updateValueAndValidity();
      this.renewalAssistanceForm.controls.creditCardExpiryDateYear.updateValueAndValidity();
      this.renewalAssistanceForm.controls.nameOnCard.updateValueAndValidity();
      this.renewalAssistanceForm.controls.renewalAssistanceStartDate.updateValueAndValidity();
      this.renewalAssistanceForm.controls.termofUse.updateValueAndValidity();
    }
  }

  uploadDocument() {

    let dialogConfig = {
      disableClose: true,
      maxWidth: '100%',
      maxHeight: '100vh',
      height: '96%',
      width: '90%',
      panelClass: 'uploadDocumentDialogCss',
      data: {
        products: [],
        arePreviousDocumentsCopied: false
      }
    };

    let products: UploadDocument.Data = {
      products: []
    };

    [{ cnoteStatus: 'SAVED', code: '080100', descp: 'MOTOR' }].forEach(prod => {
      if (prod.cnoteStatus !== 'CANCELLED' && prod.cnoteStatus !== 'P.CANCEL' && prod.cnoteStatus !== 'SUBMITTED') {
        const data: UploadDocument.ProductData = {
          productCode: prod.code,
          productName: prod.descp,
          contractId: prod["contractId"]
        }
        products.products.push(data);
      }
    });
    dialogConfig.data.products = products.products;

    //this.dialogRef = this.dialogService.open(UploadDocumentComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  openRequestCancellationPopUp() {
    let data = {
      summaryData: this.cngeNote
    }

    this.requestCancellationModal = this.dialogService.open(RequestCancellationComponent, {
      showCloseIcon: false,
      data: data
    });

    this.requestCancellationModal.afterClosed().subscribe(result => {
      if(result) {
        let payload = {
          cancelRemarks: result.data.cancelRemarks,
          contractId: this.cngeNote.contractId,
          operator: this.userInfo.userId
        };

        if (result.isCancelAndReplace) {
          this.store.dispatch(new CANCEL_REPLACE(payload)).subscribe((state) => {
            let cancellationRes = state.SatMotorState.data.cancellationRes;
            this.store.dispatch(new COPY_CNGE_INFO(cancellationRes)).subscribe(state => {
                this.router.navigate(['issuance/summary']);
            });
          });
          
        } else {
          if(this.cngeNote.isPrintQuotation) {
            this.store.dispatch(new CANCEL_QUOTATION(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.searchCoverNoteByContractId();
              }
            });
          } else {
            this.store.dispatch(new CANCEL_COVER_NOTE(payload)).subscribe(state => {
              let cancellationRes = state.SatMotorState.data.cancellationRes;
              if(cancellationRes) {
                this.searchCoverNoteByContractId();
              }
            });
          }
        }
      }
    });
  }

  updateQuotation() {
    this.store.dispatch(new GET_QUOTATION_DETAIL_REQUOTE(this.queryParams.contractId, "")).subscribe((state) => {
      let requoteQuotationDetails = state.SatMotorState.requoteQuotation;
      if (requoteQuotationDetails) {
        if (requoteQuotationDetails.cnoteType === 'ENDT') {
          this.store.dispatch(new SET_ACTION(ACTIONS.ENDT));
        } else if (requoteQuotationDetails.cnoteType === 'RP') {
          this.store.dispatch(new SET_ACTION(ACTIONS.RTP));
        } else {
          this.store.dispatch(new SET_ACTION(ACTIONS.ISSUE));
        }
        this.store.dispatch(new GET_PRODUCT_LIST({ lob: requoteQuotationDetails.productType, agentCode: requoteQuotationDetails.mkagAgent.agentCode })).subscribe((state) => {
          this.store.dispatch(new COPY_CNGE_INFO_VIEW_QUOTATION(requoteQuotationDetails)).subscribe(state => {
            if (requoteQuotationDetails.progress) {
              this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: requoteQuotationDetails }));
            }
            this.router.navigate(["/issuance/summary"]);

          });
        });
      }
    });
  }

  addRemarks() {

  }

  updatePayment() {

  }

  updateJPJDetails() {
    this.isAmendDateScren = false;
    this.isUpdateJPJScreen = true;
  }

  amendCoverNote() {
    this.isUpdateJPJScreen = false;
    this.isAmendDateScren = true;
  }

  emailDocument() {

  }

  requote() {
    this.store.dispatch(new GET_QUOTATION_DETAIL_REQUOTE(this.queryParams.contractId, "REQUOTE")).subscribe((state) => {
      let requoteQuotationDetails = state.SatMotorState.requoteQuotation;
      if (requoteQuotationDetails) {

        this.store.dispatch(new GET_PRODUCT_LIST({ lob: requoteQuotationDetails.productType, agentCode: requoteQuotationDetails.mkagAgent.agentCode })).subscribe((state) => {
          this.store.dispatch(new COPY_REQUOTE_INFO(requoteQuotationDetails)).subscribe(state => {
            if (requoteQuotationDetails.progress) {
              this.store.dispatch(new CALCULATE_PREMIUM({ cngeNote: requoteQuotationDetails }));
            }
            this.store.dispatch(new SET_ACTION(ACTIONS.ISSUE));
            const isSingleAgent = this.store.selectSnapshot<any>((state) => state.SatMotorState.isSingleAgent);
            // to do: to further implement redirect logics based on user type
            if (!isSingleAgent) {
              this.store.dispatch(new GET_AGENT_LIST_AGENT({ userId: this.userInfo.userId })).subscribe(() => {
                this.router.navigate(["/agent-selection"], {});
              });
            } else {
              this.router.navigate(["/issuance/declaration-issuance-info"], {}); // for single agent
            }
          });
        });
      }
    });
  }

  openMarketing() {

  }

  issueNew() {

  }

  resendJPJAction() {
    if (this.vehicleAmendData && this.vehicleAmendData.vehicleDetailForm) {
      const form = this.vehicleAmendData.vehicleDetailForm;
  
      if (form.valid) {
        this.resendJPJ(true, false, form.getRawValue());

      } else {
        form.markAllAsTouched();
      }
    }
  }

  resendJPJ(isResendJPJ, isAmendOpenDate, formValue) {
    let payload = {
      userId: this.userInfo.userId,
      proposalNo: this.cngeNote.proposalNo,
      contractId: this.cngeNote.contractId,
      cnoteDate: this.cngeNote.cnoteDate,
      issueBy: this.cngeNote.issueBy,
      issuedDate: this.cngeNote.cnoteDate,
      isResendJPJ: isResendJPJ,
      isAmendOpenDate: isAmendOpenDate,
      updatedFormValues: formValue
    };

    let resetPayload = {
      isRTP: false
    };

    this.store.dispatch(new RESET_GENERATE_QUOTATION_AND_SEND_TO_JPJ(resetPayload));
    this.store.dispatch(new SEND_TO_JPJ(payload)).subscribe(() => {
      let response = this.store.selectSnapshot<any>(state => state.SatMotorState.motor?.step4?.sendToJPJ ?? null);

      let message = (response?.message && response?.message != '') ? response?.message : (response?.errorCode && response?.errorCode != '') ? response?.errorCode : null;
      if (message) {
        this.okMessageModal = this.dialogService.open(OkMessageComponent, {
          width: '400px',
          data: {
            message: message
          }
        });
      }

      this.store.dispatch(new SET_JPJ_STATUS_SENT(true));
      this.searchCoverNoteByContractId();
      this.exitUpdateScreen();
    });
  }

  saveCNDate() {

  }

  exitUpdateScreen() {
    this.isUpdateJPJScreen = false;
    this.isAmendDateScren = false;
  }

}