<div [formGroup]="consentDetails">
    <div *ngFor="let question of questions" class="nx-margin-top-2m nx-margin-left-2m">
        <div nxRow>
            <div nxCol="12,12,12,8,9,9,9">
                <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold">
                    {{ question.label }}
                </h4>
            </div>
            <div nxCol="12,12,12,4,3,3,3">
                <nx-radio-group [formControlName]="question.controlName" class="float-right">
                    <div class="horizontal-buttons radio-group3">
                        <nx-radio [value]="true" >Yes</nx-radio>
                        <nx-radio [value]="false" class="nx-margin-left-m">No</nx-radio>
                    </div>
                </nx-radio-group>
            </div>
        </div>
        <hr class="nx-margin-top-2m"/>
    </div>
</div>
