<form [formGroup]="remarksForm">
<div nxModalContent>
    <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
        {{ title }}
    </h3>
    <nx-formfield label="Enter Remarks">
        <textarea nxInput type="text" maxlength="200" cdkTextareaAutosize #remarks formControlName="remarks"></textarea>
        @if (remarks.value?.length === 0) {
            <span nxFormfieldHint>Max. 200 Characters</span>
        }

        @if (remarks.value.length > 0) {
            <span nxFormfieldHint>{{ 200 - (remarks.value.length || 0) }} {{ 'COMMON.CHAR_REMAINING' | translate }}</span>
        }
        
    </nx-formfield>

    <button class="button_popup_2btn" nxButton="secondary" type="button" (click)="closeRemarksModal()">
        CANCEL
    </button>
    <button nxButton="primary" type="button" [disabled]="!remarksForm.get('remarks').value" (click)="onRemarks()">
        {{ primaryButtonLabel }}
    </button>
</div>
</form>