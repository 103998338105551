import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError, of } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {

  private URL:string;
  private notificationURL: string;

  constructor (private http: HttpClient) {
    this.URL = '';
    this.notificationURL = '';
  }

  setEnv(url: string) {
    this.URL = url;
  }

  setNotifEnv(url: string) {
    this.notificationURL = url;
  }

  private formatErrors(error: any) {
    return  throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.URL}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${this.URL}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${this.URL}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  delete(path:string): Observable<any> {
    return this.http.delete(
      `${this.URL}${path}`
    ).pipe(catchError(this.formatErrors));
  }

  getURL(path:string) {
    return of(`${this.URL}${path}`);
  }

  getURLString(path:string): string {
    return `${this.URL}${path}`;
  }

  generateReport(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${this.URL}${path}`,
      JSON.stringify(body),
      { observe: 'response', responseType: 'blob' as 'json' }
    ).pipe(catchError(this.formatErrors));
  }

  postXmlRequest(path: string) {
    return `${this.URL}${path}`
  }

  downloadFile(path: string, body: Object = {}): Observable<any> {
    return this.http.get(
      `${this.URL}${path}`,
      { observe: 'response', responseType: 'blob' as 'json' }
    ).pipe(catchError(this.formatErrors));
  }

  getDirectUrl(path: string): Observable<any> {
    return this.http.get(path)
      .pipe(catchError(this.formatErrors));
  }

}
