import { Component, Inject } from '@angular/core';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-ok-message',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './ok-message.component.html',
  styleUrl: './ok-message.component.scss'
})
export class OkMessageComponent {
  message: any;

  constructor(
    @Inject(NX_MODAL_DATA) readonly parentData: any,
    private dialogRef: NxModalRef<any>,
  ) {
    this.message = parentData.message
  }
}
