import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeComma',
  standalone: true
})
export class RemoveCommaPipe implements PipeTransform {

  transform(val: any): string {
    if (val)
      return val.toString().includes(',') ? val.toString().replace(/\,/g, "") : val;
    return val;
  }

}
