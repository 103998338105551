import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VehicleNoValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) return null;
    let containsAlphabert = /[a-zA-Z]/.test(control.value);
    let containsNumber = /[0-9]/.test(control.value);
    let containsSpaces = /\s/.test(control.value);
    if (control.value === "NA") {
      return null;
    }
    if (!(containsAlphabert && containsNumber && !containsSpaces)) {
      return { invalid: true };
    }
    return null;
  };
}
