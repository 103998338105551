import { Component, HostListener, VERSION } from "@angular/core";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxFooterModule } from "@aposin/ng-aquila/footer";
import { DOCUMENT } from "@angular/common";
import { NxCopytextModule } from "@aposin/ng-aquila/copytext";
import { Inject } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [NxGridModule, NxFooterModule, NxCopytextModule, SharedModule],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent {
  name = "Angular " + VERSION.major;
  constructor(@Inject(DOCUMENT) private document: Document) {}
  windowScrolled = false;
  scrollToTop(): void {
    // scroll to the top of the body

    // return this.document.body.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    //   inline: 'start'
    // });

    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // showScrollButton = false;
  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   // Check if the user has scrolled to the end of the page
  //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //     this.showScrollButton = true;
  //   } else {
  //     this.showScrollButton = false;
  //   }
  // }

  showScrollButton = false;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    // Calculate the scroll position
    const scrollPosition = window.innerHeight + window.scrollY;
    const documentHeight = document.body.offsetHeight;
    const scrollPercentage = (scrollPosition / documentHeight) * 100;

    // Check if the user has scrolled 90% to the end of the page
    if (scrollPercentage >= 90) {
      this.showScrollButton = true;
    } else {
      this.showScrollButton = false;
    }
  }
}
