import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  TemplateRef,
} from "@angular/core";
import { Route, Router, RouterModule } from "@angular/router";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { SharedModule } from "../../shared/shared.module";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";

@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [RouterModule, NxButtonModule, SharedModule],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent {
  currentIndex = 0;
  // Filter //
  toDisplay = false;

  toggleData() {
    this.toDisplay = !this.toDisplay;
  }

  changeTab(event: any) {
    // console.log(event);

    // 0 = summary
    // 1 = draft
    // 2 = expiring
    // 3 = pending
    // 4 = motor
    this.currentIndex = event.index;
  }

  // Table Summary //
  tableElements = [
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "FREEN SAROCHA",
      issuance: "Renewal / New Business - Old Vehicle, Old Registration",
      vehicle: "WA9011L",
      jpjstatus: "NOT OK",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "FREEN SAROCHA",
      issuance: "New Business - New Vehicle, New Registration",
      vehicle: "NA",
      jpjstatus: "OK",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "FREEN SAROCHA",
      issuance: "New Business - Transfer Of Ownership",
      vehicle: "WA9011L",
      jpjstatus: "N/A",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "FREEN SAROCHA",
      issuance: "Renewal",
      vehicle: "WA9011L",
      jpjstatus: "OK",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "FREEN SAROCHA",
      issuance: "Revision Of Sum Insured",
      vehicle: "WA9011L",
      jpjstatus: "N/A",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      additional: "DE55G",
      status: "active",
      statusText: "Printed",
    },
  ];

  // Table Draft //
  tableDraftElements = [
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "",
      premium: "1,700.00",
      additional: "",
      status: "",
      class: "",
      progressText: "Step 3/5",
      progress: "0.3",
      statusText: "Saved",
    },
  ];

  // Table Expiry //
  tableExpiryElements = [
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "",
      class: "",
      statusText: "Saved",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "DNCKL24001037",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      premium: "1,700.00",
      quotationexpirydate: "25/02/2024",
      additional: "",
      status: "active",
      class: "",
      statusText: "Printed",
    },
  ];

  // Table Pending Approval //
  tablependingapprovalElements = [
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "26/12/2023",
      agentcode: "KL18431 / TST CENTRE - MOD",
      policy: "QKL23120000138",
      product: "SMART HOME COVER",
      customer: "FREEN SAROCHA",
      issuance: "New",
      vehicle: "DE55G",
      premium: "1,700.00",
      additional: "",
      referred: "RG003 - Sum Insured exceeds authorized limit",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
  ];

  // Table Pending Motor //
  tablependingmotorElements = [
    {
      date: "16/12/2023",
      agentcode: "KL18172 / CIMB BANK BHD (AFC)",
      policy: "PGE00069327",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "FREEN SAROCHA",
      issuance: "New Business - New Vehicle, New Registration",
      vehicle: "NA",
      suminsured: "65.00",
      premium: "1,700.00",
      additional: "",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "16/12/2023",
      agentcode: "AS00626 / ONG SENG SWEE ONG AH BAH",
      policy: "PGE00069315",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "BECKY",
      issuance: "New Business - New Vehicle, New Registration",
      vehicle: "NA",
      suminsured: "65.00",
      premium: "1,700.00",
      additional: "",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "16/12/2023",
      agentcode: "KL11713 / TR ENTERPRISE",
      policy: "AEKL0034036",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "RICHARD LEE",
      issuance: "New Business - New Vehicle, New Registration",
      vehicle: "NA",
      suminsured: "80.00",
      premium: "890.00",
      additional: "",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
    {
      date: "16/12/2023",
      agentcode: "KL04303 / C S TEE INSURANCE AGENCY",
      policy: "AEKL0034037",
      product: "PRIVATE CARS EXCLUDING GOODS",
      customer: "ALBERT TAN MING",
      issuance: "New Business - New Vehicle, New Registration",
      vehicle: "NA",
      suminsured: "220.00",
      premium: "1,700.80",
      additional: "",
      status: "active",
      class: "pending_badge",
      statusText: "Pending Approval",
    },
  ];

  // Pagination //
  count = 210;
  page = 1;
  perPage = 10;

  prevPage() {
    this.page--;
  }

  nextPage() {
    this.page++;
  }

  goToPage(n: number) {
    this.page = n;
  }

  // Pop Up //
  @ViewChild("templateMarketerManager") templateRef4!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<DashboardComponent>;

  constructor(
    private readonly dialogService: NxDialogService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

  marketerManager(): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef4, {
      // height: "500px",
      width: "800px",
      maxWidth: "800px",
    });
  }

  closeTemplateDialog() {
    this.templateDialogRef?.close();
  }

  //   @ViewChild('receiveemail') receiveemailRef!: TemplateRef<any>;

  //     openFromTemplate3(): void {
  //         this.dialogService.open(this.receiveemailRef, {
  //             ariaLabel: 'A simple modal',
  //             disableClose: true,
  //         });
  //     }

  navEnquiry(type: any) {
    this.router.navigate(["/enquiry/" + type]);
  }
}
