<h4 class="nx-margin-top-10 nx-margin-bottom-30">Vehicle Details</h4>
<table nxTable cellpadding="0" class="summary_table">
    <tr nxTableRow *ngFor="let item of getFilteredVehicleDetailsLabels()">
        <td nxTableCell>
            <span nxCopytext="normal">{{ item.label }}</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal" style="white-space: pre-wrap;">
                {{ getValue(item.key, 'vehicleDetails') }}
            </span>
        </td>
    </tr>
</table>

<hr class="nx-margin-y-2m" />
<h4 class="nx-margin-top-30 nx-margin-bottom-30">Coverage Details</h4>
<table nxTable cellpadding="0" class="summary_table">
    <tr nxTableRow *ngFor="let item of coverageDetailsLabels">
        <td nxTableCell>
            <span nxCopytext="normal">{{ item.label }}</span>
        </td>
        <td nxTableCell class="column_cp_content">
            <span nxCopytext="normal">
                <ng-container *ngIf="(viewQuotation || viewPolicyDetails) && item.key === 'sumInsured'">
                    {{ getValue(item.key, 'coverageDetails') }}
                    @if(viewPolicyDetails){
                        <span *ngIf="isShowMaxMin" nxCopytext="small">
                            <i>
                                @if (isSameAvSumInsured()) {
                                    (Recommended Sum Insured : RM {{formatSumInsured(minSumInsured)}})
                                }
                                @else {
                                    (Recommended Sum Insured Range : RM {{formatSumInsured(minSumInsured)}} - {{formatSumInsured(maxSumInsured)}})
                                }
                            </i>
                        </span>
                    }@else{
                        <span *ngIf="isShowMaxMin" nxCopytext="small">
                            <i>
                                @if (isSameAvSumInsured()) {
                                    (Recommended Sum Insured : RM {{formatSumInsured(minSumInsured)}})
                                }
                                @else {
                                    (Recommended Sum Insured Range : RM {{formatSumInsured(minSumInsured)}} - {{formatSumInsured(maxSumInsured)}})
                                }
                            </i>
                        </span>
                    }
                </ng-container>
                <ng-container *ngIf="!((viewQuotation || viewPolicyDetails) && item.key === 'sumInsured')">
                    {{ getValue(item.key, 'coverageDetails') }}
                </ng-container>
            </span>
        </td>
    </tr>
</table>

@if(extraCoverPackageSummary && showExtraCoverage){
<hr class="nx-margin-y-2m" />
<h4 class="nx-margin-y-2m">Extra Coverage</h4>
@if(extraCoverPackageSummary.rahmahPackageList?.length > 0 || extraCoverPackageSummary.topExtraCoverList?.length > 0){
<span nxCopytext="normal" class="nx-font-weight-semibold">These coverage(s) / service(s) are provided at no extra
    cost.</span>
}

<ul nxList="small" class="completed_list">
    @if(!extraCoverPackageSummary.costInd){
    <li>
        <span nxCopytext="normal" class="nx-font-weight-semibold">{{ extraCoverPackageSummary.subCovPackageList?.packageDescription || extraCoverPackageSummary.subCovPackageDesc
            }}</span>
        <ul class="nx-margin-y-0" *ngFor="let item of extraCoverPackageSummary.rahmahPackageList; let i = index">
            <div nxLayout="grid nopadding" class="grid_remove_padding">
                <div nxRow>
                    <div nxCol="12,12,5,5">
                        <span nxCopytext="medium">- {{ item.description || item.subCoverCodeDescription }}</span>
                    </div>
                    <div *ngIf="item.displayLabel && item.subCoverCode == 'RPPA1'" nxCol="12,12,4,4"
                        class="extra_coverage_text">
                        <span nxCopytext="medium">{{ item.displayLabel}}
                        </span>
                    </div>
                    <div nxCol="12,12,5,4" class="extra_coverage_text">
                        <span nxCopytext="medium">
                            <span [innerHTML]="removeNewLinesFromOpus(item.subcoverNarrDesc, item.subCoverCode)"></span>
                        </span>
                    </div>
                </div>
            </div>
        </ul>
    </li> 
    }
    @if(extraCoverPackageSummary.topExtraCoverList?.length > 0){
    <li *ngFor="let item of extraCoverPackageSummary.topExtraCoverList; let i = index">
        <div nxLayout="grid nopadding" class="grid_remove_padding">
            <div nxRow>
                <div nxCol="12,12,6,5">
                    <span nxCopytext="normal" class="nx-font-weight-semibold">{{ item.description ||
                        item.subCoverCodeDescription}}</span>
                </div>
                <div nxCol="12,12,4,4" class="extra_coverage_text">
                    <span nxCopytext="medium">
                        <span [innerHTML]="removeNewLinesFromOpus(item.subcoverNarrDesc, item.subCoverCode)"></span>
                    </span>
                </div>
            </div>
        </div>
    </li>
    }
    @if(extraCoverPackageSummary.rahmahPackageList?.length > 0 &&
    (extraCoverPackageSummary.subCovPackageList?.packageDescription || extraCoverPackageSummary.subCovPackageDesc) &&
    extraCoverPackageSummary.costInd){
    <div nxRow class="nx-margin-top-m">
        <div nxCol="10" class="nx-font-weight-semibold cell-padding-bottom">Extra Coverage Package</div>
    </div>
    <li>
        <span nxCopytext="normal" class="nx-font-weight-semibold">{{ extraCoverPackageSummary.subCovPackageList?.packageDescription || extraCoverPackageSummary.subCovPackageDesc}}</span>
        <ul class="nx-margin-y-0" *ngFor="let item of extraCoverPackageSummary.rahmahPackageList; let i = index">
            <div nxLayout="grid nopadding" class="grid_remove_padding">
                <!-- summary -->
                @if(!viewPolicyDetails && !viewQuotation){
                <div nxRow>
                    <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- {{ item.description || item.subCoverCodeDescription }}</span>
                    </div>
                    @if(item.subcoverNarrDesc){
                    <div nxCol="12,12,4,4" class="cr14-si-description"><span nxCopytext="medium"
                            [innerHTML]="removeNewLinesFromOpus(item.subcoverNarrDesc, item.subCoverCode)"></span></div> <br>
                    }
                    <div nxCol="2" class="extra_coverage_text">
                    </div>
                </div>
            }

            @if((viewQuotation) || (viewPolicyDetails)){
                <div nxRow>
                    <div nxCol="12,12,6,5">
                        <span nxCopytext="medium">- {{ item.description || item.subCoverCodeDescription }}</span>
                    </div>
                    @if(item.subcoverNarrDesc){
                    <div nxCol="12,12,4,4" class="cr14-si-description"><span nxCopytext="medium"
                            [innerHTML]="removeNewLinesFromOpus(item.subcoverNarrDesc, item.subCoverCode)"></span></div> <br>
                    }
                    <div nxCol="2" class="extra_coverage_text">
                    </div>
                </div>
            }
            </div>
        </ul>
    </li> 
    }
</ul>
@if((!viewQuotation)&& (!viewPolicyDetails)){
<ng-container *ngIf="extraCoverPackageSummary.selectedExtraCoverList.length > 0">
    <div class="nx-margin-top-2m">
        <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder1"></app-external-scrollbar>
        <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder1 syncScroll="1">

            <table nxTable zebra cellpadding="0">
                <thead>
                    <tr nxTableRow class="table_row_flex">
                        <th nxHeaderCell class="table_extra_coverage_sum">
                            <span nxCopytext="medium" class="nx-font-weight-semibold">Extra Cover</span>
                        </th>
                        <th nxHeaderCell class="table_extra_coverage_sum"></th>
                        <th nxHeaderCell class="table_extra_coverage_sum">
                            <span nxCopytext="medium" class="nx-font-weight-semibold no-wrap">Premium (RM)</span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr nxTableRow class="table_row_flex"
                        *ngFor="let item of extraCoverPackageSummary.selectedExtraCoverList">
                        <td nxTableCell class="table_extra_coverage_sum">
                            <!-- p2p -->
                            @if(item.subCoverCode === 'A207'){
                                <span nxCopytext="medium">{{ item.subCoverDescp }}</span>
                            }

                            @if(item.subCoverCode !== 'A207'){
                                <span nxCopytext="medium">{{ item.description }}</span>
                            }
                            <!-- summary -->
                            <ng-container *ngIf="item.cngeSubCoverBenefitDtoList?.length;">
                                <div class="sub_cover extra-coverage-padding-top">
                                    <ul nxList>
                                        <li style="margin-bottom: 0px !important;" nxListIcon="" size="s" *ngFor="let subItem of item.cngeSubCoverBenefitDtoList">
                                            <div nxCopytext="medium">
                                                <div nxRow col="12">
                                                    <div>- </div>
                                                    <div class="extra-coverage-description">{{ subItem.subCoverBenefitDescp }}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            
                             <!-- generate quotation > update Quotation > summary-->
                             <ng-container *ngIf="item.subCoverBenefitList?.length;">
                                <div class="sub_cover extra-coverage-padding-top">
                                    <ul nxList>
                                        <li style="margin-bottom: 0px !important;" nxListIcon="" size="s" *ngFor="let subItem of item.subCoverBenefitList">
                                            <div nxCopytext="medium">
                                                <div nxRow col="12">
                                                    <div>- </div>
                                                    <div class="extra-coverage-description">{{ subItem.subCoverBenefitDescp }}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                        </td>
                        <td nxTableCell class="table_extra_coverage_sum">
                            <span [innerHTML]="removeNewLinesFromOpus(item.subcoverNarrDesc, item.subCoverCode)"></span>
                        </td>
                        <td nxTableCell class="table_extra_coverage_sum">
                            <ng-container *ngIf="item.subCoverPrem != 0">
                                <span nxCopytext="medium">{{ item.subCoverPrem| formatDecimal }}</span>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>
}
@if((viewQuotation) || (viewPolicyDetails)){
    <ng-container *ngIf="extraCoverPackageSummary.selectedExtraCoverList.length > 0">
        <div class="nx-margin-top-2m">
            <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder1"></app-external-scrollbar>
            <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder1 syncScroll="1">
    
                <table nxTable zebra cellpadding="0">
                    <thead>
                        <tr nxTableRow class="table_row_flex">
                            <th nxHeaderCell class="table_extra_coverage_quo">
                                <span nxCopytext="medium" class="nx-font-weight-semibold">Extra Cover</span>
                            </th>
                            <th nxHeaderCell class="table_extra_coverage_quo"></th>
                            <th nxHeaderCell class="table_extra_coverage_quo">
                                <span nxCopytext="medium" class="nx-font-weight-semibold">Premium (RM)</span>
                            </th>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr nxTableRow class="table_row_flex"
                            *ngFor="let item of extraCoverPackageSummary.selectedExtraCoverList">
                            <td nxTableCell class="table_extra_coverage_quo">
                                <!-- p2p -->
                                @if(item.subCoverCode === 'A207'){
                                    <span nxCopytext="medium">{{ item.subCoverDescp }}</span>
                                }
    
                                @if(item.subCoverCode !== 'A207'){
                                    <span nxCopytext="medium">{{ item.description }}</span>
                                }
                                
                                <!-- view Quotation -->
                                <ng-container *ngIf="item.subCoverBenefitList?.length;">
                                    <div class="sub_cover extra-coverage-padding-top-quo">
                                        <ul nxList>
                                            <li style="margin-bottom: 0px !important;" nxListIcon="" size="s" *ngFor="let subItem of item.subCoverBenefitList">
                                                <div nxCopytext="medium">
                                                    <div nxRow col="12">
                                                        <div>- </div>
                                                        <div class="extra-coverage-description-quo">{{ subItem.subCoverBenefitDescp }}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-container>
                            </td>
                            <td nxTableCell class="table_extra_coverage_quo">
                                <span [innerHTML]="removeNewLinesFromOpus(item.subcoverNarrDesc, item.subCoverCode)"></span> 
                            </td>
                            <td nxTableCell class="table_extra_coverage_quo">
                                <ng-container *ngIf="item.subCoverPrem != 0">
                                    <span nxCopytext="medium">{{ item.subCoverPrem| formatDecimal }}</span>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
}
}

@if(showNamedDriver) {
<hr class="nx-margin-y-2m" />
<h4 class="nx-marginm-2m">Named Drivers</h4>
<div class="nx-margin-top-2m-botto">
    <app-external-scrollbar targetSyncScroll="2" [targetBaseWidth]="tableHolder2"></app-external-scrollbar>
    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder2 syncScroll="2">
        <table nxTable zebra cellpadding="0">
            <thead>
                <tr nxTableRow>
                    <th nxHeaderCell *ngFor="let header of driverHeaderLabels"
                    [ngClass]="header.key === 'driverAge' ? 'age_column' : ''">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">{{ header.label }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr nxTableRow *ngFor="let driver of namedDriverList">
                    <td nxTableCell *ngFor="let header of driverHeaderLabels">
                        @if(header.key === 'driverId' || header.key === 'driverAge') {
                        <span nxCopytext="medium">{{ formatDriverAgeAndId(driver[header.key]) }}</span>
                        }@else {
                        <span nxCopytext="medium" class="text_no_wrap">{{ driver[header.key] ?? 'N/A' }}</span>
                        }
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
}

@if(showEhailing) {
<h4 class="nx-margin-bottom-2m">e-Hailing Drivers Name</h4>
<div class="nx-margin-top-2m">
    <app-external-scrollbar targetSyncScroll="3" [targetBaseWidth]="tableHolder3"></app-external-scrollbar>
    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder3 syncScroll="3">
        <table nxTable zebra cellpadding="0">
            <thead>
                <tr nxTableRow>
                    <th nxHeaderCell *ngFor="let header of ehailingDriverHeaderLabels">
                        <span nxCopytext="medium" class="nx-font-weight-semibold">{{ header.label }}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr nxTableRow *ngFor="let driver of ehailingDriverList">
                    <td nxTableCell *ngFor="let header of ehailingDriverHeaderLabels">
                        <span nxCopytext="medium">{{ driver[header.key] }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
}