<div class="title-header">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12">
        <h1>Private Cars Excluding Goods</h1>
        <!-- Customer Partner -EInvoice -->
      </div>
    </div>
  </div>
</div>

<div nxLayout="grid maxwidth" class="nx-margin-top-2m">
  <div nxRow rowJustify="center">
    <div nxCol="12,12,12,3" class="grid_width_page">
      <!-- 
      Note:
      Progree type:
      1. cn = cover note and quotation progress bar, 5 step
      2. endorsement = endorsement progress bar, 4 step
      3. unification = unification progress bar, 6 step
      4. rtp = road tax purpose progress bar, 3 step

      progressvalue = step index
      -->
      <app-progress-bar [progressValue]="1" [progressType]="'cn'" />
    </div>
    <div nxCol="12,12,12,9" class="grid_width_page">
      <div nxLayout="grid nopadding">
        <div nxRow>
          <div nxCol="12">
            <h2 class="nx-margin-0">Customer Partner</h2>
            <span nxCopytext="medium">
              <i>All fields are mandatory.</i>
            </span>
          </div>
        </div>

        <div *ngIf="cp_search">
          <div nxRow class="nx-margin-top-2m">
            <div nxCol="12">
              <div class="cp_toggle">
                <nx-radio-toggle>
                  <nx-radio-toggle-button
                    value="individual"
                    [selected]="true"
                    (click)="individual = true; company = false"
                  >
                    INDIVIDUAL
                  </nx-radio-toggle-button>
                  <nx-radio-toggle-button
                    value="company"
                    (click)="individual = false; company = true"
                  >
                    COMPANY
                  </nx-radio-toggle-button>
                </nx-radio-toggle>
              </div>
            </div>
          </div>

          <div nxRow class="nx-margin-top-3m">
            <div nxCol="12">
              <div class="radio-group">
                <nx-radio
                  name="group1"
                  [checked]="true"
                  class="nx-margin-right-s"
                  >Malaysian</nx-radio
                >
                <nx-radio name="group1">Non-Malaysian</nx-radio>
              </div>
            </div>
          </div>
          <div nxRow class="nx-margin-top-2m">
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="ID Type">
                <nx-dropdown value="NRIC">
                  <nx-dropdown-item value="NRIC"></nx-dropdown-item>
                  <nx-dropdown-item value="OLD IC NO."></nx-dropdown-item>
                  <nx-dropdown-item
                    value="POLICE ID / ARMY ID"
                  ></nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,12,6,6,6,6,6">
              <nx-formfield label="ID No.">
                <input nxInput type="text" />

                <nx-error nxFormfieldNote>
                  <strong> Please enter ID No. </strong>
                </nx-error>
                <nx-error nxFormfieldNote>
                  <strong> Invalid Business Registration No. </strong>
                </nx-error>
              </nx-formfield>
            </div>
          </div>

          <div nxRow class="nx-margin-top-m nx-margin-bottom-3xl">
            <div nxCol="12" class="button-container">
              <button nxButton="secondary" type="button">BACK</button>

              <button nxButton="primary" type="button" (click)="search()">
                SEARCH
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="cp_notfoundMissing || cp_found">
          <div nxRow class="nx-margin-top-m">
            <div nxCol="12">
              <button
                nxPlainButton
                type="button"
                class="p-0"
                (click)="search()"
              >
                <nx-icon
                  name="arrow-right"
                  nxIconPositionStart
                  aria-hidden="true"
                ></nx-icon
                >SEARCH AGAIN
              </button>
            </div>
          </div>
          <div nxRow class="nx-margin-top-m">
            <div nxCol="12">
              <nx-error *ngIf="cp_notfoundMissing" nxFormfieldError>
                <strong>Customer Not Found</strong>
              </nx-error>

              <nx-error *ngIf="cp_notfoundMissing" nxFormfieldError>
                <strong
                  >Customer Information is not complete. Fill out the details
                  below.</strong
                >
              </nx-error>

              <nx-message *ngIf="cp_found" context="success">
                <span class="nx-font-weight-bold">Customer Found</span>
              </nx-message>
            </div>
          </div>

          <div *ngIf="cp_notfoundMissing">
            <!-- <div nxRow class="nx-margin-top-m">
              <div nxCol="12">
                <div>
                  <div nxRow>
                    <div nxCol="2"><p nxCopytext="normal">Nationality</p></div>
                    <div nxCol="10"><p nxCopytext="normal">: MALAYSIA</p></div>
                  </div>
                  <div nxRow>
                    <div nxCol="2"><p nxCopytext="normal">NRIC</p></div>
                    <div nxCol="10">
                      <p nxCopytext="normal">: 880914145277</p>
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="2"><p nxCopytext="normal">Old IC No.</p></div>
                    <div nxCol="10"><p nxCopytext="normal">: A307</p></div>
                  </div>
                  <div nxRow>
                    <div nxCol="2">
                      <p nxCopytext="normal">Date of Birth</p>
                    </div>
                    <div nxCol="10">
                      <p nxCopytext="normal">: 14/09/1988</p>
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="2"><p nxCopytext="normal">Age</p></div>
                    <div nxCol="10"><p nxCopytext="normal">: 35</p></div>
                  </div>
                </div>
              </div>
            </div> -->

            <div
              nxLayout="grid nopadding"
              class="nx-margin-top-m grid_remove_padding summary_column_space"
            >
              <table nxTable cellpadding="0" class="summary_table">
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Nationality</span>
                  </td>
                  <td nxTableCell class="column_cp_content">
                    <span nxCopytext="normal">MALAYSIA</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
                  <td nxTableCell class="column_cp_content">
                    <span nxCopytext="normal">880914145277</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Old IC No.</span>
                  </td>
                  <td nxTableCell class="column_cp_content">
                    <span nxCopytext="normal">A307</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell>
                    <span nxCopytext="normal">Date of Birth</span>
                  </td>
                  <td nxTableCell class="column_cp_content">
                    <span nxCopytext="normal">14/09/1988</span>
                  </td>
                </tr>
                <tr nxTableRow>
                  <td nxTableCell><span nxCopytext="normal">Age</span></td>
                  <td nxTableCell class="column_cp_content">
                    <span nxCopytext="normal">35</span>
                  </td>
                </tr>
              </table>
            </div>

            <div nxRow class="nx-margin-top-m">
              <div nxCol="12">
                <hr class="hr" class="nx-margin-bottom-m" />
                <h4 class="nx-margin-0">
                  Fill out the details below to create a new customer partner
                  profile.
                </h4>
              </div>
            </div>
            <!--===== Large and medium view =====-->
            <div class="nx-hidden-xs nx-hidden-s">
              <div nxRow>
                <div nxCol="12">
                  <h4 *ngIf="individual">Personal Information</h4>
                  <h4 *ngIf="company">Company Information</h4>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12">
                  <nx-formfield label="Name">
                    <input nxInput #inputToCount maxlength="200" />
                    <span nxFormfieldHint>
                      <span *ngIf="inputToCount.value?.length === 0">
                        max. 200 characters
                      </span>
                      <span *ngIf="inputToCount.value.length > 0">
                        {{ 200 - (inputToCount.value.length || 0) }}
                        characters remaining
                      </span>
                    </span>

                    <nx-error nxFormfieldNote>
                      <strong> Please enter the Name</strong>
                    </nx-error>
                  </nx-formfield>
                </div>
              </div>
              <div nxRow *ngIf="company">
                <div nxCol="12">
                  <nx-formfield label="Country of Incorporation">
                    <nx-dropdown value="Malaysia" [valueFormatter]="toText">
                      <nx-dropdown-item value="Malaysia"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>

              <div nxRow *ngIf="company">
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="ID Type 2">
                    <nx-dropdown [valueFormatter]="toText">
                      <nx-dropdown-item [value]="'NRIC'"> </nx-dropdown-item>
                      <nx-dropdown-item
                        [value]="'Old IC / Police ID / Army ID / Passport No.'"
                      >
                      </nx-dropdown-item>
                      <nx-dropdown-item [value]="'Business Registration No.'">
                      </nx-dropdown-item>
                      <nx-dropdown-item
                        [value]="'New Business Registration No.'"
                      ></nx-dropdown-item>
                    </nx-dropdown>

                    <nx-error nxFormfieldNote>
                      <strong> Please enter ID Type 2</strong>
                    </nx-error>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="ID No. 2">
                    <input nxInput />

                    <nx-error nxFormfieldNote>
                      <strong>Please enter ID No. 2</strong>
                    </nx-error>
                    <nx-error nxFormfieldNote>
                      <strong>Wrong Format</strong>
                    </nx-error>
                  </nx-formfield>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Tax Identification No. (TIN)">
                    <input nxInput />
                  </nx-formfield>
                </div>
              </div>

              <div *ngIf="company">
                <div nxRow>
                  <div nxCol="12">
                    <span nxCopytext="normal">SST Registered?</span><br />
                    <div class="radio-group nx-margin-top-xs">
                      <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                      <nx-radio name="group1" class="nx-margin-left-2m"
                        >No</nx-radio
                      >
                    </div>
                  </div>
                </div>
                <div *ngIf="sst" class="nx-margin-top-2m">
                  <div nxRow>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SST No.">
                        <input nxInput />

                        <nx-error nxFormfieldNote>
                          <strong> Please enter SST No.</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SST Effective Date">
                        <input
                          nxDatefield
                          nxInput
                          [datepicker]="myDatepicker1"
                        />
                        <span nxFormfieldHint>MM/DD/YYYY</span>
                        <nx-datepicker-toggle
                          [for]="myDatepicker1"
                          nxFormfieldSuffix
                        ></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker1></nx-datepicker>

                        <nx-error nxFormfieldNote>
                          <strong> Please enter SST Effective Date</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SST De-Registration Date">
                        <input
                          nxDatefield
                          nxInput
                          [datepicker]="myDatepicker2"
                          [disabled]="true"
                        />
                        <span nxFormfieldHint>MM/DD/YYYY</span>
                        <nx-datepicker-toggle
                          [for]="myDatepicker2"
                          nxFormfieldSuffix
                        ></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker2></nx-datepicker>
                      </nx-formfield>
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SST Resident Status">
                        <nx-dropdown value="Resident" [valueFormatter]="toText">
                          <nx-dropdown-item value="Resident"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SST Country">
                        <nx-dropdown value="Malaysia" [valueFormatter]="toText">
                          <nx-dropdown-item value="Malaysia"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="company">
                <div nxRow>
                  <div nxCol="12">
                    <span nxCopytext="normal">SME Registered?</span><br />
                    <div class="radio-group nx-margin-top-xs">
                      <nx-radio name="group1" [checked]="true">Yes</nx-radio>
                      <nx-radio name="group1" class="nx-margin-left-2m"
                        >No</nx-radio
                      >
                    </div>
                  </div>
                </div>
                <div *ngIf="sme">
                  <div nxRow class="nx-margin-top-2m">
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SME Reg. No.">
                        <input nxInput />

                        <nx-error nxFormfieldNote>
                          <strong> Please enter SME Reg No. </strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SME Category">
                        <nx-dropdown [valueFormatter]="toText">
                          <nx-dropdown-item value="MICRO"></nx-dropdown-item>
                          <nx-dropdown-item value="SMALL"></nx-dropdown-item>
                          <nx-dropdown-item value="MEDIUM"></nx-dropdown-item>
                        </nx-dropdown>

                        <nx-error nxFormfieldNote>
                          <strong>Plese select SME Category</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                  </div>
                  <div nxRow>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SME Cert. Start Date">
                        <input
                          nxDatefield
                          nxInput
                          [datepicker]="myDatepicker3"
                        />
                        <span nxFormfieldHint>MM/DD/YYYY</span>
                        <nx-datepicker-toggle
                          [for]="myDatepicker3"
                          nxFormfieldSuffix
                        ></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker3></nx-datepicker>

                        <nx-error nxFormfieldNote>
                          <strong>Please enter SME Start Date</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                    <div nxCol="12,12,6,6,6,6,6">
                      <nx-formfield label="SME Cert. End Date">
                        <input
                          nxDatefield
                          nxInput
                          [datepicker]="myDatepicker4"
                        />
                        <span nxFormfieldHint>MM/DD/YYYY</span>
                        <nx-datepicker-toggle
                          [for]="myDatepicker4"
                          nxFormfieldSuffix
                        ></nx-datepicker-toggle>
                        <nx-datepicker #myDatepicker4></nx-datepicker>

                        <nx-error nxFormfieldNote>
                          <strong>Please enter SME End Date</strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                  </div>
                </div>
              </div>
              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <span nxCopytext="normal">Gender</span><br />
                  <div class="radio-group nx-margin-top-xs">
                    <nx-radio name="group1" [checked]="true">Male</nx-radio>
                    <nx-radio name="group1" class="nx-margin-left-2m"
                      >Female</nx-radio
                    >
                  </div>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <div class="customField_marital">
                    <nx-formfield label="Marital Status">
                      <nx-dropdown [valueFormatter]="toText">
                        <nx-dropdown-item value="SINGLE"></nx-dropdown-item>
                        <nx-dropdown-item value="MARIED"></nx-dropdown-item>
                      </nx-dropdown>
                      <nx-error nxFormfieldNote>
                        <strong>Please enter the Marital Status</strong>
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Date of Birth">
                    <input nxDatefield nxInput [datepicker]="myDatepicker5" />
                    <span nxFormfieldHint>MM/DD/YYYY</span>
                    <nx-datepicker-toggle
                      [for]="myDatepicker5"
                      nxFormfieldSuffix
                    ></nx-datepicker-toggle>
                    <nx-datepicker #myDatepicker5></nx-datepicker>

                    <nx-error nxFormfieldNote>
                      <strong>Please enter a valid DOB</strong>
                    </nx-error>
                  </nx-formfield>

                  <button
                    nxPlainButton
                    class="nx-margin-x-2xs"
                    type="button"
                    (click)="openPopup(1, 'one-button')"
                  >
                    (Current Year - Birth Year) = 0
                  </button>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Age">
                    <input nxInput [disabled]="true" value="35" />
                  </nx-formfield>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12">
                  <hr class="hr" />

                  <h4>Contact Details</h4>
                  <!-- <p
                    nxCopytext="large"
                    class="nx-font-weight-semibold nx-margin-y-m"
                  >
                    Contact Details
                  </p> -->
                </div>
              </div>

              <div nxRow>
                <div nxCol="12,12,6,6,6,6,6">
                  <div nxLayout="grid nopadding">
                    <div class="cp__customer-detail-phone">
                      <nx-formfield label="Code" class="code">
                        <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                          <nx-dropdown-item value="6010"></nx-dropdown-item>
                          <nx-dropdown-item value="6011"></nx-dropdown-item>
                          <nx-dropdown-item value="6012"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                      <nx-formfield
                        label="Mobile No."
                        class="info nx-margin-left-s"
                      >
                        <input
                          nxInput
                          inputmode="numeric"
                          required
                          numberOnly
                          minlength="7"
                          maxlength="8"
                          autocomplete="off"
                        />
                        <nx-error nxFormfieldNote>
                          <strong>Please enter the Mobile No.</strong>
                        </nx-error>
                        <nx-error nxFormfieldNote>
                          <strong
                            >Please enter the number between 7 and 8 characters
                            long.</strong
                          >
                        </nx-error>
                      </nx-formfield>
                    </div>
                  </div>

                  <button
                    nxPlainButton
                    class="nx-margin-x-2xs"
                    type="button"
                    (click)="openPopup(2, 'two-button')"
                  >
                    Mobile No. same as Agent
                  </button>
                </div>

                <div nxCol="12,12,6,6,6,6,6">
                  <div nxLayout="grid nopadding">
                    <div class="cp__customer-detail-phone">
                      <nx-formfield label="Code" class="code">
                        <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                          <nx-dropdown-item value="6010"></nx-dropdown-item>
                          <nx-dropdown-item value="6011"></nx-dropdown-item>
                          <nx-dropdown-item value="6012"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                      <nx-formfield
                        label="Fixed Line No."
                        class="info nx-margin-left-s"
                      >
                        <input
                          nxInput
                          inputmode="numeric"
                          required
                          numberOnly
                          minlength="7"
                          maxlength="8"
                          autocomplete="off"
                        />
                        <nx-error nxFormfieldNote>
                          <strong>
                            Please enter the number between 7 and 8 characters
                            long.
                          </strong>
                        </nx-error>
                      </nx-formfield>
                    </div>
                  </div>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <div nxLayout="grid nopadding">
                    <div class="cp__customer-detail-phone">
                      <nx-formfield label="Code" class="code">
                        <nx-dropdown [panelGrow]="true" [panelMaxWidth]="150">
                          <nx-dropdown-item value="6010"></nx-dropdown-item>
                          <nx-dropdown-item value="6011"></nx-dropdown-item>
                          <nx-dropdown-item value="6012"></nx-dropdown-item>
                        </nx-dropdown>
                      </nx-formfield>
                      <nx-formfield
                        label="Fax No. (Optional)"
                        class="info nx-margin-left-s"
                      >
                        <input
                          nxInput
                          inputmode="numeric"
                          required
                          numberOnly
                          minlength="7"
                          maxlength="8"
                          autocomplete="off"
                        />
                      </nx-formfield>
                    </div>
                  </div>
                </div>

                <div nxCol="12,12,6,6,6,6,6">
                  <div nxLayout="grid nopadding">
                    <!-- if the e-invoice content is no, Email (Optional) else Email -->
                    <nx-formfield>
                      <nx-formfield-label
                        >Email
                        <span *ngIf="!eInvoice_consent"
                          >(Optional)</span
                        ></nx-formfield-label
                      >
                      <input nxInput type="text" />

                      <nx-error nxFormfieldNote>
                        <strong> Please enter the Email </strong>
                      </nx-error>
                      <nx-error nxFormfieldNote>
                        <strong> Please enter a valid email address. </strong>
                      </nx-error>
                    </nx-formfield>
                  </div>

                  <button
                    nxPlainButton
                    class="nx-margin-x-2xs"
                    type="button"
                    (click)="openPopup(3, 'two-button')"
                  >
                    Email same as Agent
                  </button>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12">
                  <hr class="hr" />

                  <h4>Correspondence Details</h4>
                  <!-- <p
                    nxCopytext="large"
                    class="nx-font-weight-semibold nx-margin-top-m"
                  >
                    Correspondence Details
                  </p> -->
                </div>
              </div>

              <div nxRow class="nx-margin-top-m">
                <div nxCol="12">
                  <nx-switcher [checked]="true">Show Map</nx-switcher>
                </div>
                <div nxCol="12" class="search-map nx-margin-top-m">
                  <div class="pac-card" id="pac-card">
                    <div>
                      <div id="title">Autocomplete search</div>
                      <div id="type-selector" class="pac-controls">
                        <div class="radio-group">
                          <span nxCopytext="medium">Search Mode: &nbsp;</span>
                          <div class="radio-map">
                            <nx-radio
                              name="group1"
                              labelSize="small"
                              [checked]="true"
                              value="address"
                              (valueChange)="changeSearchMode($event)"
                              ><span nxCopytext="medium">By Address</span>
                            </nx-radio>
                            <nx-radio
                              name="group1"
                              labelSize="small"
                              value="lat/long"
                              (valueChange)="changeSearchMode($event)"
                              ><span nxCopytext="medium">By Lat/Long</span>
                            </nx-radio>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                    <div id="pac-container">
                      <input
                        *ngIf="search_mode_address"
                        id="pac-input"
                        type="text"
                        placeholder="Enter a location"
                      />

                      <div *ngIf="!search_mode_address">
                        <div class="lat_long">
                          <input
                            id="pac-input"
                            type="text"
                            placeholder="LATITUDE"
                          />
                          <input
                            id="pac-input"
                            type="text"
                            placeholder="LONGITUDE"
                          />
                        </div>
                        <div
                          class="container_flex_center align_right nx-margin-top-s"
                        >
                          <button nxButton="primary small" type="button">
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.857620894033!2d101.6841407748091!3d3.132302996843152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b50e075c1d%3A0xc37d80fcda677529!2sMenara%20Allianz%20Sentral!5e0!3m2!1sen!2smy!4v1716797684318!5m2!1sen!2smy"
                    class="map"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>

                <div nxCol="12" class="nx-margin-top-m">
                  <nx-formfield label="Unit No">
                    <input nxInput [disabled]="true" />
                  </nx-formfield>
                </div>

                <div nxCol="12">
                  <nx-formfield label="Address 1">
                    <input nxInput />

                    <nx-error nxFormfieldNote>
                      <strong> Please enter Address 1 </strong>
                    </nx-error>
                  </nx-formfield>
                </div>

                <div nxCol="12">
                  <nx-formfield label="Address 2">
                    <input nxInput />
                  </nx-formfield>
                </div>

                <div nxCol="12">
                  <nx-formfield label="Address 3">
                    <input nxInput />
                  </nx-formfield>
                </div>

                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Postcode">
                    <input nxInput />

                    <nx-error nxFormfieldNote>
                      <strong> Please enter a valid Postcode </strong>
                    </nx-error>
                  </nx-formfield>
                </div>

                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="City">
                    <nx-dropdown [disabled]="true" [valueFormatter]="toText">
                      <nx-dropdown-item value="City"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>

                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="State">
                    <nx-dropdown [disabled]="true" [valueFormatter]="toText">
                      <nx-dropdown-item value="State"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
                <div nxCol="12,12,6,6,6,6,6">
                  <nx-formfield label="Country">
                    <nx-dropdown [disabled]="true" [valueFormatter]="toText">
                      <nx-dropdown-item value="Country"></nx-dropdown-item>
                    </nx-dropdown>
                  </nx-formfield>
                </div>
              </div>

              <div nxRow>
                <div nxCol="12">
                  <!-- <p nxCopytext="large" class="nx-font-weight-bold">
                    Please select Address Type
                  </p> -->
                  <h4 class="nx-margin-0">Please select Address Type</h4>
                </div>

                <div nxCol="12">
                  <div class="radio-group2 nx-margin-top-m">
                    <nx-radio name="group3" [checked]="true"
                      >Residential</nx-radio
                    >
                    <nx-radio name="group3">Non-residential</nx-radio>
                  </div>
                </div>
              </div>
            </div>

            <!-- ===== Mobile View ===== -->
            <div
              class="nx-margin-top-2m nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
            >
              <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title>
                      <span
                        *ngIf="individual"
                        nxCopytext="large"
                        class="nx-font-weight-bold"
                      >
                        Personal Information
                      </span>

                      <span
                        *ngIf="company"
                        nxCopytext="large"
                        class="nx-font-weight-bold"
                      >
                        Company Information
                      </span>
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <div nxLayout="grid nopadding">
                    <div nxRow>
                      <div nxCol="12">
                        <nx-formfield label="Name">
                          <input nxInput #inputToCount maxlength="200" />
                          <span nxFormfieldHint>
                            <span *ngIf="inputToCount.value?.length === 0">
                              max. 200 characters
                            </span>
                            <span *ngIf="inputToCount.value.length > 0">
                              {{ 200 - (inputToCount.value.length || 0) }}
                              characters remaining
                            </span>
                          </span>
                          <nx-error nxFormfieldNote>
                            <strong> Please enter the Name</strong>
                          </nx-error>
                        </nx-formfield>
                      </div>
                    </div>
                    <div nxRow *ngIf="company">
                      <div nxCol="12">
                        <nx-formfield label="Country of Incorporation">
                          <nx-dropdown
                            value="Malaysia"
                            [valueFormatter]="toText"
                          >
                            <nx-dropdown-item
                              value="Malaysia"
                            ></nx-dropdown-item>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>
                    </div>
                    <div nxRow *ngIf="company">
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="ID Type 2">
                          <nx-dropdown [valueFormatter]="toText">
                            <nx-dropdown-item [value]="'NRIC'">
                            </nx-dropdown-item>
                            <nx-dropdown-item
                              [value]="
                                'Old IC / Police ID / Army ID / Passport No.'
                              "
                            >
                            </nx-dropdown-item>
                            <nx-dropdown-item
                              [value]="'Business Registration No.'"
                            >
                            </nx-dropdown-item>
                            <nx-dropdown-item
                              [value]="'New Business Registration No.'"
                            ></nx-dropdown-item>
                          </nx-dropdown>

                          <nx-error nxFormfieldNote>
                            <strong> Please enter ID Type 2</strong>
                          </nx-error>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="ID No. 2">
                          <input nxInput />

                          <nx-error nxFormfieldNote>
                            <strong>Please enter ID No. 2</strong>
                          </nx-error>
                          <nx-error nxFormfieldNote>
                            <strong>Wrong Format</strong>
                          </nx-error>
                        </nx-formfield>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Tax Identification No. (TIN)">
                          <input nxInput />
                        </nx-formfield>
                      </div>
                    </div>
                    <div *ngIf="company">
                      <div nxRow>
                        <div nxCol="12">
                          <span nxCopytext="normal">SST Registered?</span><br />
                          <div class="radio-group nx-margin-top-xs">
                            <nx-radio name="group1" [checked]="true"
                              >Yes</nx-radio
                            >
                            <nx-radio name="group1" class="nx-margin-left-2m"
                              >No</nx-radio
                            >
                          </div>
                        </div>
                      </div>
                      <div *ngIf="sst" class="nx-margin-top-m">
                        <div nxRow>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SST No.">
                              <input nxInput />

                              <nx-error nxFormfieldNote>
                                <strong> Please enter SST No.</strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                        </div>
                        <div nxRow>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SST Effective Date">
                              <input
                                nxDatefield
                                nxInput
                                [datepicker]="myDatepicker10"
                              />
                              <span nxFormfieldHint>MM/DD/YYYY</span>
                              <nx-datepicker-toggle
                                [for]="myDatepicker10"
                                nxFormfieldSuffix
                              ></nx-datepicker-toggle>
                              <nx-datepicker #myDatepicker10></nx-datepicker>

                              <nx-error nxFormfieldNote>
                                <strong>
                                  Please enter SST Effective Date</strong
                                >
                              </nx-error>
                            </nx-formfield>
                          </div>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SST De-Registration Date">
                              <input
                                nxDatefield
                                nxInput
                                [datepicker]="myDatepicker11"
                                [disabled]="true"
                              />
                              <span nxFormfieldHint>MM/DD/YYYY</span>
                              <nx-datepicker-toggle
                                [for]="myDatepicker11"
                                nxFormfieldSuffix
                              ></nx-datepicker-toggle>
                              <nx-datepicker #myDatepicker11></nx-datepicker>
                            </nx-formfield>
                          </div>
                        </div>
                        <div nxRow>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SST Resident Status">
                              <nx-dropdown
                                value="Resident"
                                [valueFormatter]="toText"
                              >
                                <nx-dropdown-item
                                  value="Resident"
                                ></nx-dropdown-item>
                              </nx-dropdown>
                            </nx-formfield>
                          </div>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SST Country">
                              <nx-dropdown
                                value="Malaysia"
                                [valueFormatter]="toText"
                              >
                                <nx-dropdown-item
                                  value="Malaysia"
                                ></nx-dropdown-item>
                              </nx-dropdown>
                            </nx-formfield>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="company">
                      <div nxRow>
                        <div nxCol="12">
                          <span nxCopytext="normal">SME Registered?</span><br />
                          <div class="radio-group nx-margin-top-xs">
                            <nx-radio name="group1" [checked]="true"
                              >Yes</nx-radio
                            >
                            <nx-radio name="group1" class="nx-margin-left-2m"
                              >No</nx-radio
                            >
                          </div>
                        </div>
                      </div>
                      <div *ngIf="sme">
                        <div nxRow class="nx-margin-top-2m">
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SME Reg. No.">
                              <input nxInput />

                              <nx-error nxFormfieldNote>
                                <strong> Please enter SME Reg No. </strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SME Category">
                              <nx-dropdown [valueFormatter]="toText">
                                <nx-dropdown-item
                                  value="MICRO"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="SMALL"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="MEDIUM"
                                ></nx-dropdown-item>
                              </nx-dropdown>

                              <nx-error nxFormfieldNote>
                                <strong>Plese select SME Category</strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                        </div>
                        <div nxRow>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SME Cert. Start Date">
                              <input
                                nxDatefield
                                nxInput
                                [datepicker]="myDatepicker12"
                              />
                              <span nxFormfieldHint>MM/DD/YYYY</span>
                              <nx-datepicker-toggle
                                [for]="myDatepicker12"
                                nxFormfieldSuffix
                              ></nx-datepicker-toggle>
                              <nx-datepicker #myDatepicker12></nx-datepicker>

                              <nx-error nxFormfieldNote>
                                <strong>Please enter SME Start Date</strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                          <div nxCol="12,12,6,6,6,6,6">
                            <nx-formfield label="SME Cert. End Date">
                              <input
                                nxDatefield
                                nxInput
                                [datepicker]="myDatepicker13"
                              />
                              <span nxFormfieldHint>MM/DD/YYYY</span>
                              <nx-datepicker-toggle
                                [for]="myDatepicker13"
                                nxFormfieldSuffix
                              ></nx-datepicker-toggle>
                              <nx-datepicker #myDatepicker13></nx-datepicker>

                              <nx-error nxFormfieldNote>
                                <strong>Please enter SME End Date</strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12,12,6,6,6,6,6">
                        <span nxCopytext="normal">Gender</span><br />
                        <div class="radio-group nx-margin-top-xs">
                          <nx-radio name="group1" [checked]="true"
                            >Male</nx-radio
                          >
                          <nx-radio name="group1" class="nx-margin-left-2m"
                            >Female</nx-radio
                          >
                        </div>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <div class="customField_marital">
                          <nx-formfield label="Marital Status">
                            <nx-dropdown [valueFormatter]="toText">
                              <nx-dropdown-item
                                value="SINGLE"
                              ></nx-dropdown-item>
                              <nx-dropdown-item
                                value="MARIED"
                              ></nx-dropdown-item>
                            </nx-dropdown>
                            <nx-error nxFormfieldNote>
                              <strong>Please enter the Marital Status</strong>
                            </nx-error>
                          </nx-formfield>
                        </div>
                      </div>
                    </div>

                    <div nxRow>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Date of Birth">
                          <input
                            nxDatefield
                            nxInput
                            [datepicker]="myDatepicker14"
                          />
                          <span nxFormfieldHint>MM/DD/YYYY</span>
                          <nx-datepicker-toggle
                            [for]="myDatepicker14"
                            nxFormfieldSuffix
                          ></nx-datepicker-toggle>
                          <nx-datepicker #myDatepicker14></nx-datepicker>

                          <nx-error nxFormfieldNote>
                            <strong>Please enter a valid DOB</strong>
                          </nx-error>
                        </nx-formfield>

                        <button
                          nxPlainButton
                          class="nx-margin-x-2xs"
                          type="button"
                          (click)="openPopup(1, 'one-button')"
                        >
                          (Current Year - Birth Year) = 0
                        </button>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Age">
                          <input nxInput [disabled]="true" value="35" />
                        </nx-formfield>
                      </div>
                    </div>
                  </div>
                </nx-expansion-panel>
              </nx-accordion>

              <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title>
                      <span nxCopytext="large" class="nx-font-weight-bold">
                        Contact Details
                      </span>
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <div nxLayout="grid nopadding">
                    <div nxRow>
                      <div nxCol="12,12,6,6,6,6,6">
                        <div nxLayout="grid nopadding">
                          <div class="cp__customer-detail-phone">
                            <nx-formfield label="Code" class="code">
                              <nx-dropdown
                                [panelGrow]="true"
                                [panelMaxWidth]="150"
                              >
                                <nx-dropdown-item
                                  value="6010"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="6011"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="6012"
                                ></nx-dropdown-item>
                              </nx-dropdown>
                            </nx-formfield>
                            <nx-formfield
                              label="Mobile No."
                              class="info nx-margin-left-s"
                            >
                              <input
                                nxInput
                                inputmode="numeric"
                                required
                                numberOnly
                                minlength="7"
                                maxlength="8"
                                autocomplete="off"
                              />
                              <nx-error nxFormfieldNote>
                                <strong> Please enter the Mobile No. </strong>
                              </nx-error>
                              <nx-error nxFormfieldNote>
                                <strong>
                                  Please enter the number between 7 and 8
                                  characters long.
                                </strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                        </div>
                        <button
                          nxPlainButton
                          class="nx-margin-x-2xs"
                          type="button"
                          (click)="openPopup(2, 'two-button')"
                        >
                          Mobile No. same as Agent
                        </button>
                      </div>

                      <div nxCol="12,12,6,6,6,6,6">
                        <div nxLayout="grid nopadding">
                          <div class="cp__customer-detail-phone">
                            <nx-formfield label="Code" class="code">
                              <nx-dropdown
                                [panelGrow]="true"
                                [panelMaxWidth]="150"
                              >
                                <nx-dropdown-item
                                  value="6010"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="6011"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="6012"
                                ></nx-dropdown-item>
                              </nx-dropdown>
                            </nx-formfield>
                            <nx-formfield
                              label="Fixed Line No."
                              class="info nx-margin-left-s"
                            >
                              <input
                                nxInput
                                inputmode="numeric"
                                required
                                numberOnly
                                minlength="7"
                                maxlength="8"
                                autocomplete="off"
                              />
                              <nx-error nxFormfieldNote>
                                <strong>
                                  Please enter the number between 7 and 8
                                  characters long.
                                </strong>
                              </nx-error>
                            </nx-formfield>
                          </div>
                        </div>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <div nxLayout="grid nopadding">
                          <div class="cp__customer-detail-phone">
                            <nx-formfield label="Code" class="code">
                              <nx-dropdown
                                [panelGrow]="true"
                                [panelMaxWidth]="150"
                              >
                                <nx-dropdown-item
                                  value="6010"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="6011"
                                ></nx-dropdown-item>
                                <nx-dropdown-item
                                  value="6012"
                                ></nx-dropdown-item>
                              </nx-dropdown>
                            </nx-formfield>
                            <nx-formfield
                              label="Fax No. (Optional)"
                              class="info nx-margin-left-s"
                            >
                              <input
                                nxInput
                                inputmode="numeric"
                                required
                                numberOnly
                                minlength="7"
                                maxlength="8"
                                autocomplete="off"
                              />
                            </nx-formfield>
                          </div>
                        </div>
                      </div>

                      <div nxCol="12,12,6,6,6,6,6">
                        <div nxLayout="grid nopadding">
                          <!-- if the e-invoice content is no, Email (Optional) else Email -->
                          <nx-formfield>
                            <nx-formfield-label
                              >Email
                              <span *ngIf="!eInvoice_consent"
                                >(Optional)</span
                              ></nx-formfield-label
                            >
                            <input nxInput type="text" />

                            <nx-error nxFormfieldNote>
                              <strong> Please enter the Email </strong>
                            </nx-error>
                            <nx-error nxFormfieldNote>
                              <strong>
                                Please enter a valid email address.
                              </strong>
                            </nx-error>
                          </nx-formfield>
                        </div>
                        <button
                          nxPlainButton
                          class="nx-margin-x-2xs"
                          type="button"
                          (click)="openPopup(3, 'two-button')"
                        >
                          Email same as Agent
                        </button>
                      </div>
                    </div>
                  </div>
                </nx-expansion-panel>
              </nx-accordion>
              <nx-accordion variant="light">
                <nx-expansion-panel class="accordion_remove_btm_space">
                  <nx-expansion-panel-header>
                    <nx-expansion-panel-title>
                      <span nxCopytext="large" class="nx-font-weight-bold">
                        Correspondence Details
                      </span>
                    </nx-expansion-panel-title>
                  </nx-expansion-panel-header>
                  <div nxLayout="grid nopadding">
                    <div nxRow class="nx-margin-top-m">
                      <div nxCol="12">
                        <nx-switcher [checked]="true">Show Map</nx-switcher>
                      </div>
                      <div nxCol="12" class="search-map nx-margin-top-m">
                        <div class="pac-card" id="pac-card">
                          <div>
                            <div id="title">Autocomplete search</div>
                            <div id="type-selector" class="pac-controls">
                              <div class="radio-group">
                                <span nxCopytext="medium"
                                  >Search Mode: &nbsp;</span
                                >
                                <div class="radio-map">
                                  <nx-radio
                                    name="group1"
                                    labelSize="small"
                                    [checked]="true"
                                    value="address"
                                    (valueChange)="changeSearchMode($event)"
                                    ><span nxCopytext="medium">By Address</span>
                                  </nx-radio>
                                  <nx-radio
                                    name="group1"
                                    labelSize="small"
                                    value="lat/long"
                                    (valueChange)="changeSearchMode($event)"
                                    ><span nxCopytext="medium"
                                      >By Lat/Long</span
                                    >
                                  </nx-radio>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                          <div id="pac-container">
                            <input
                              *ngIf="search_mode_address"
                              id="pac-input"
                              type="text"
                              placeholder="Enter a location"
                            />

                            <div *ngIf="!search_mode_address">
                              <div class="lat_long">
                                <input
                                  id="pac-input"
                                  type="text"
                                  placeholder="Enter a location"
                                />
                                <input
                                  id="pac-input"
                                  type="text"
                                  placeholder="Enter a location"
                                />
                              </div>
                              <div
                                class="container_flex_center align_right nx-margin-top-s"
                              >
                                <button nxButton="primary small" type="button">
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.857620894033!2d101.6841407748091!3d3.132302996843152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b50e075c1d%3A0xc37d80fcda677529!2sMenara%20Allianz%20Sentral!5e0!3m2!1sen!2smy!4v1716797684318!5m2!1sen!2smy"
                          class="map"
                          style="border: 0"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>

                      <div nxCol="12" class="nx-margin-top-m">
                        <nx-formfield label="Unit No">
                          <input nxInput [disabled]="true" />
                        </nx-formfield>
                      </div>

                      <div nxCol="12">
                        <nx-formfield label="Address 1">
                          <input nxInput />

                          <nx-error nxFormfieldNote>
                            <strong> Please enter Address 1 </strong>
                          </nx-error>
                        </nx-formfield>
                      </div>

                      <div nxCol="12">
                        <nx-formfield label="Address 2">
                          <input nxInput />
                        </nx-formfield>
                      </div>

                      <div nxCol="12">
                        <nx-formfield label="Address 3">
                          <input nxInput />
                        </nx-formfield>
                      </div>

                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Postcode">
                          <input nxInput />

                          <nx-error nxFormfieldNote>
                            <strong> Please enter a valid Postcode </strong>
                          </nx-error>
                        </nx-formfield>
                      </div>

                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="City">
                          <nx-dropdown
                            [disabled]="true"
                            [valueFormatter]="toText"
                          >
                            <nx-dropdown-item value="City"></nx-dropdown-item>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>

                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="State">
                          <nx-dropdown
                            [disabled]="true"
                            [valueFormatter]="toText"
                          >
                            <nx-dropdown-item value="State"></nx-dropdown-item>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>
                      <div nxCol="12,12,6,6,6,6,6">
                        <nx-formfield label="Country">
                          <nx-dropdown
                            [disabled]="true"
                            [valueFormatter]="toText"
                          >
                            <nx-dropdown-item
                              value="Country"
                            ></nx-dropdown-item>
                          </nx-dropdown>
                        </nx-formfield>
                      </div>
                    </div>
                    <div nxRow>
                      <div nxCol="12">
                        <p nxCopytext="normal" class="nx-font-weight-semibold">
                          Please select Address Type
                        </p>
                      </div>

                      <div nxCol="12">
                        <div class="radio-group2 nx-margin-top-xs">
                          <nx-radio name="group3" [checked]="true"
                            >Residential</nx-radio
                          >
                          <nx-radio name="group3">Non-residential</nx-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                </nx-expansion-panel>
              </nx-accordion>
            </div>
          </div>

          <div nxRow *ngIf="cp_found" class="nx-margin-top-m">
            <div nxLayout="grid nogutters">
              <div nxRow>
                <div nxCol="12">
                  <table nxTable cellpadding="0" class="summary_table">
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Customer Type</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <span nxCopytext="normal" *ngIf="individual"
                              >INDIVIDUAL</span
                            >
                            <span nxCopytext="normal" *ngIf="company"
                              >COMPANY</span
                            >
                            <button
                              nxPlainButton
                              type="button"
                              (click)="openEdit('editCP')"
                            >
                              EDIT
                            </button>
                          </div>
                        </span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell><span nxCopytext="normal">Name</span></td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">WONG KAM WAI</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal"
                          >Country of Incorporation</span
                        >
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">MALAYSIA</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal"
                          >New Business Registration No.</span
                        >
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">201333333322</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal"
                          >Business Registration No.</span
                        >
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">555666-X</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Nationality</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">MALAYSIA</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Old IC No.</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">A3078</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell><span nxCopytext="normal">NRIC</span></td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">880914145277</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Passport No.</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">A37</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal"
                          >Tax Identification No. (TIN)</span
                        >
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">OG12345678101</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SST No. </span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">W88888888888888</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SST Effective Date</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">26/07/2022</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal"
                          >SST De-Registration Date</span
                        >
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">25/07/2024</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SST Resident Status</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">RESIDENT</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SST Country</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">MALAYSIA</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SME Reg. No.</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">SME908766</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SME Category</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">MICRO</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SME Cert. Start Date</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">27/12/2021</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">SME Cert. End Date</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">26/12/2023</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Gender</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">MALE</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Marital Status</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">SINGLE</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Date of Birth</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">14/09/1988</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell><span nxCopytext="normal">Age</span></td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">35</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">ALIM Customer</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">NO</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Mobile No.</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">6012-2222222</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Fixed Line No.</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">602-33333333</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Fax No.</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">602-44444444</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Email</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <span nxCopytext="normal">KAMWAIGMAIL.COM</span>
                      </td>
                    </tr>
                    <tr nxTableRow>
                      <td nxTableCell>
                        <span nxCopytext="normal">Address</span>
                      </td>
                      <td nxTableCell class="column_cp_content">
                        <button
                          nxPlainButton
                          type="button"
                          aria-label="add item"
                        >
                          <nx-icon name="location" aria-hidden="true"></nx-icon>
                        </button>
                        <span nxCopytext="normal"
                          >28-3, ALLIANZ MALAYSIA BERHAD, LEVEL 28, MENARA
                          ALLIANZ SENTRAL, 203, JALAN TUN SAMBANTHAN, KUALA
                          LUMPUR SENTRAL, 50470 KUALA LUMPUR, WILAYAH
                          PERSEKUTUAN, MALAYSIA</span
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div nxRow>
            <div nxCol="12" class="nx-margin-top-m">
              <p nxCopytext="medium">
                <i>
                  By clicking NEXT, you confirm that the address submitted is
                  complete and accurate to the best of your knowledge.
                </i>
              </p>
            </div>
          </div>

          <div nxRow class="nx-margin-top-50 nx-margin-bottom-3xl">
            <div nxCol="12" class="button-container">
              <a routerLink="../pdpa"
                ><button nxButton="secondary" type="button">Back</button></a
              >
              <button nxButton="secondary" type="button">SAVE AS DRAFT</button>
              <a routerLink="../coverage"
                ><button nxButton="primary" type="button">NEXT</button></a
              >
            </div>
            <div nxCol="12"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
