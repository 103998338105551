@if(!viewPolicyDetails){
<div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Agent Code</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(agentData?.agentCode) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Agent Name</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(agentData?.name) }}</p>
      </nx-data-display>
    </div>
    @if(!isClaimEnquiry) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >Issued By</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(agentData?.issuedBy) }}</p>
        </nx-data-display>
      </div>
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >Issued Date</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(agentData?.issuedDate) }}</p>
        </nx-data-display>
      </div>
      @if(isApproval && !isEndorsement) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey">Agent Score</nx-data-display-label>
          <p nxCopytext="large">{{ agentData?.agentAps === 0 || agentData?.agentAps === null || agentData?.agentAps === '' ?
            '0.00' : agentData?.agentAps }}</p>
        </nx-data-display>
      </div>
      }
    }

  </div>
}
  @if(viewPolicyDetails){
    <div nxRow>
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >Agent Code</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(agentData?.agentCode) }}</p>
        </nx-data-display>
      </div>
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >Agent Name</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(agentData?.name) }}</p>
        </nx-data-display>
      </div>
    </div>
  }