import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sme-cert-form',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './sme-cert-form.component.html',
  styleUrl: './sme-cert-form.component.scss'
})
export class SmeCertFormComponent implements OnInit {
  @Input() customerInfoForm: FormGroup;
  @Input() cpDetails: any;
  @Input() smeCategoryList: any;

  unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.initForm();
    this.onChangeSmeRegisteredInd();
    this.onChangeSmeStartDate();
    this.onChangeSmeEndDate();
  }
  
  initForm() {
    this.customerInfoForm.addControl('smeRegisteredInd', new FormControl(this.cpDetails?.smeRegisteredInd ? 'Y' : 'N')); //pending integrate to OPUS for duplicate checking
    this.customerInfoForm.addControl('smeRegNo', new FormControl(this.cpDetails?.smeRegisteredNumber ? this.cpDetails?.smeRegisteredNumber : ''));
    this.customerInfoForm.addControl('smeCategory', new FormControl(this.cpDetails?.smeCategory ? this.cpDetails?.smeCategory : ''));
    this.customerInfoForm.addControl('smeStartDate', new FormControl(this.cpDetails?.smeStartDate ? this.cpDetails?.smeStartDate : ''));
    this.customerInfoForm.addControl('smeEndDate', new FormControl(this.cpDetails?.smeEndDate ? this.cpDetails?.smeEndDate : ''));

    if (this.customerInfoForm.controls['smeRegisteredInd'].value === 'Y') {
      this.addValidators();
    }else {
      this.clearValidators();
    }
  }
  
  addValidators(): void {
    this.customerInfoForm.controls['smeRegNo'].setValidators([Validators.required, Validators.pattern('^SEM\\w+$')]);
    this.customerInfoForm.controls['smeCategory'].setValidators(Validators.required);
    this.customerInfoForm.controls['smeStartDate'].setValidators(Validators.required);
    this.customerInfoForm.controls['smeEndDate'].setValidators(Validators.required);

    this.customerInfoForm.updateValueAndValidity();
  }

  addDefaultValue() {
    // this.customerInfoForm.controls['sstResidentStatus'].setValue('Resident');
    // this.customerInfoForm.controls['sstCountry'].setValue('Malaysia');
  }

  clearValidators() {
    this.customerInfoForm.controls['smeRegNo'].clearValidators();
    this.customerInfoForm.controls['smeCategory'].clearValidators();
    this.customerInfoForm.controls['smeStartDate'].clearValidators();
    this.customerInfoForm.controls['smeEndDate'].clearValidators();
    this.customerInfoForm.updateValueAndValidity();

  }

  onChangeSmeRegisteredInd() {
    this.customerInfoForm.controls['smeRegisteredInd'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
         if (this.customerInfoForm.controls['smeRegisteredInd'].value === 'Y') {
          this.addValidators();
          this.addDefaultValue();
        }else {
          this.clearValidators();
          this.resetForm();
        }
    });
  }

  onChangeSmeStartDate() {
    this.customerInfoForm.controls['smeStartDate'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let smeRegisteredInd = this.customerInfoForm.controls['smeRegisteredInd'].value;
      if (smeRegisteredInd === 'Y') {
        let startDate = value ? new Date(value) : null;
        let startDateAfter2Year = startDate ? new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate() - 1) : null;
        const currentDate = new Date();

        currentDate.setHours(0, 0, 0, 0);

        if (startDate > currentDate) {
          this.customerInfoForm.controls['smeStartDate'].setErrors({ 'invalidSMEStartDate': true });
        } else {
          this.customerInfoForm.controls['smeEndDate'].setValue(startDateAfter2Year);
        }
      }
    });
  }

  onChangeSmeEndDate() {
    this.customerInfoForm.controls['smeEndDate'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      let smeRegisteredInd = this.customerInfoForm.controls['smeRegisteredInd'].value;
      if(smeRegisteredInd === 'Y') {
        let startDate = this.customerInfoForm.controls['smeStartDate'].value ? new Date(this.customerInfoForm.controls['smeStartDate'].value): null;
        let startDateAfter2Year = startDate ? new Date(startDate.getFullYear()+2, startDate.getMonth()-1, startDate.getDate()-1) : null;
        let endDate = new Date(value);
  
        if (startDate && endDate) {
          if (endDate < startDate) {
            this.customerInfoForm.controls['smeEndDate'].setErrors({ 'smeSDLaterThanSmeED': true });
          }
  
          if (endDate > startDateAfter2Year) {
            this.customerInfoForm.controls['smeEndDate'].setErrors({ 'smeEDMoreThanTwoYear': true });
          }
        }else {
          if (!startDate && endDate) {
            this.customerInfoForm.controls['smeEndDate'].setErrors({ 'smeStartDateEmpty': true });
          }
        }
      }      
      
    });
  }

  resetForm() {
    this.customerInfoForm.get('smeRegNo').reset();
    this.customerInfoForm.get('smeCategory').reset();
    this.customerInfoForm.get('smeStartDate').reset();
    this.customerInfoForm.get('smeEndDate').reset();
  }

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }
}
