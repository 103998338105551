import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { GET_PREVIOUS_POLICY_NO, SEARCH_VIX, SELECTED_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { UserResponse } from "@interfaces/user";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { SharedModule } from "@shared/shared.module";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NumberRangeValidator } from "@shared/classes/validators/number-range.validator";
import { MakeModelComponent } from "@shared/components/modal/make-model/make-model.component";
import { OkMessageComponent } from "../modal/ok-message/ok-message.component";

import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";
import { DatePipe } from "@angular/common";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import moment from "moment";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-vehicle-view-amendment',
  standalone: true,
  imports: [SharedModule, NxMomentDateModule],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }, DatePipe],
  templateUrl: './vehicle-view-amendment.component.html',
  styleUrl: './vehicle-view-amendment.component.scss'
})
export class VehicleViewAmendmentComponent implements OnInit {

  @Input() isUpdateJPJScreen: boolean = false;
  @Input() isAmendDateScren: boolean = false;

  @Input() cngeNote: any;
  @Input() coverageInfo: any;

  okMessageModal?: NxModalRef<OkMessageComponent>;
  vehicleDetailForm: FormGroup;
  decimalFmt: any;
  numberFmt: any;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });

    let data = this.coverageInfo.vehicleDetails;
    if (this.isUpdateJPJScreen) {
      this.vehicleDetailForm = this.fb.group({
        vehicleNo: ["", [Validators.required, VehicleNoValidator()]],
        vehicleEngine: ["", [Validators.required]],
        vehicleChassis: ["", [Validators.required]],
        jpjEffectiveDate: [{ value: "", disabled: true }],
        jpjExpiryDate: [{ value: "", disabled: true }],
      });

      if (data) {
        this.vehicleDetailForm.patchValue({
          vehicleNo: data.vehicleNo,
          vehicleEngine: data.vehicleEngine,
          vehicleChassis: data.vehicleChassis,
          jpjEffectiveDate: data.jpjEffectiveDate || this.cngeNote.jpjEffectiveDate,
          jpjExpiryDate: data.jpjExpiryDate || this.cngeNote.jpjExpiryDate
        });
      }
      
    }

    if (this.isAmendDateScren) {
      this.vehicleDetailForm = new FormGroup({
        vehicleNo: new FormControl('', [
          Validators.required, VehicleNoValidator()
        ]),
        vehicleMakeYear: new FormControl({ value: '', disabled: true }),
        vehModelNvicDesc: new FormControl({ value: '', disabled: true }),
        vehicleEngine: new FormControl({ value: '', disabled: true }),
        vehicleChassis: new FormControl({ value: '', disabled: true }),
        jpjEffectiveDate: new FormControl('', [Validators.required]),
        jpjExpiryDate: new FormControl({ value: '', disabled: true }),
        jpjStatus: new FormControl({ value: '', disabled: true })
      });


      if (data) {
        this.vehicleDetailForm.patchValue({
          vehicleNo: data.vehicleNo,
          vehicleEngine: data.vehicleEngine,
          vehicleChassis: data.vehicleChassis,
          jpjEffectiveDate: data.jpjEffectiveDate || this.cngeNote.jpjEffectiveDate,
          jpjExpiryDate: data.jpjExpiryDate || this.cngeNote.jpjExpiryDate,
          jpjStatus: data.jpjStatus,
          vehicleMakeYear: data.vehicleMakeYear,
          vehModelNvicDesc: [data.piamMakeModelDesc, data.variantFullDesc].join(" ").trim()
        });
      }
    }

  }

  onDateChange(event: any) {
    let issueDate = moment(this.cngeNote.cnoteDate, "YYYY-MM-DD");

    if (event.value) {
      const effectiveDate = moment(event.value, "YYYY-MM-DDTHH:mm:ss.SSSZ");
      if (!effectiveDate.isValid()) {
        this.showOkMessage('Invalid date format. Please enter a valid date.');
        return;
      }

      if (effectiveDate.toDate() < issueDate.toDate()) {
        this.showOkMessage('Inception date cannot be earlier than issued date.');
      } else {
        const expiryDate = effectiveDate.clone().add(1, 'year').subtract(1, 'day');
        this.vehicleDetailForm.controls['jpjExpiryDate'].setValue(expiryDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"));

        this.vehicleDetailForm.patchValue({
          jpjEffectiveDate: effectiveDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        });
        const jpjEffectiveDateControl = this.vehicleDetailForm.get('jpjEffectiveDate');
        if (jpjEffectiveDateControl) {
          jpjEffectiveDateControl.setErrors(null);
          jpjEffectiveDateControl.markAsPristine();
          jpjEffectiveDateControl.markAsUntouched();
          jpjEffectiveDateControl.updateValueAndValidity();
        }
      }

    }
  }

  checkVehicleNo() {
    const vehicleNoControl = this.vehicleDetailForm.get('vehicleNo');
    if (vehicleNoControl && vehicleNoControl.hasError('invalid')) {
      this.showOkMessage('Vehicle No. is Invalid.');
    }
  }

  showOkMessage(messageData: any) {
    this.okMessageModal = this.dialogService.open(OkMessageComponent, {
      width: '400px',
      data: {
        message: messageData
      }
    });

    this.okMessageModal.afterClosed().subscribe(() => {
      if (messageData.includes('Vehicle No')) {
        this.clearVehicleNo();
      } else if (messageData.includes('Inception date')) {
        this.clearJPJDate();
      }
    });
  }

  clearJPJDate() {
    const jpjEffectiveDateControl = this.vehicleDetailForm.get('jpjEffectiveDate');
    const jpjExpiryDateControl = this.vehicleDetailForm.get('jpjExpiryDate');

    if(jpjEffectiveDateControl) {
      jpjEffectiveDateControl.setValue('');
    }

    if(jpjExpiryDateControl) {
      jpjExpiryDateControl.setValue('');
    }
  }

  clearVehicleNo() {
    const vehicleNoControl = this.vehicleDetailForm.get('vehicleNo');
    if (vehicleNoControl) {
      if (this.isUpdateJPJScreen) {
        vehicleNoControl.setValue('');
      } else {
        vehicleNoControl.setValue('NA');
      }
    }
  }

}
