
  <header nx-header>


     <nx-header-row class="header_container" >

      <nx-header-brand>
        <nx-link class="nx-margin-right-m">
          <a href="#">
            <figure nxFigure>
              <img src="../../../assets/img/logo.svg" />
            </figure>
          </a>
        </nx-link>
        <div class="top_header_menu nx-hidden-xs nx-hidden-s nx-hidden-m">
          <nx-formfield>
            
            <nx-dropdown
              [panelGrow]="true"
              value="SAT Portal"
              
            >
            <div class="arrow_icon"></div>
              <nx-dropdown-item
                value="Allianz Malaysia"
                class="menu_list"
                
              ></nx-dropdown-item>
              <nx-dropdown-item
                value="Alpha Portal"
                class="menu_list"
              
              ></nx-dropdown-item>
              <nx-dropdown-item
                value="SAT Portal"
                class="menu_list"
              ></nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
  
          <!-- <button nxPlainButton
              
                class="nx-margin-right-2xs"
                [nxPopoverTriggerFor]="popoverBottomAnchor"
                nxPopoverDirection="bottom"
                nxPopoverTrigger="click"
              
            >
                SAT Portal
            </button>
            <nx-popover #popoverBottomAnchor>
                            <nx-formfield>
                <nx-dropdown [panelGrow]="true" value="SAT Portal">
                    <nx-dropdown-item value="Allianz Malaysia" class="menu_list"></nx-dropdown-item>
                    <nx-dropdown-item value="Alpha Portal" class="menu_list"></nx-dropdown-item>
                    <nx-dropdown-item value="SAT Portal" class="menu_list"></nx-dropdown-item>                   
                </nx-dropdown>
              </nx-formfield>
            </nx-popover> -->
        </div>
      </nx-header-brand>
      <nx-header-actions
        class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
      >
        <nx-icon name="bars" aria-hidden="true" (click)="toggleMenu()"></nx-icon>
      </nx-header-actions>
      <nx-header-actions class="nx-hidden-xs nx-hidden-s nx-hidden-m">
        <div>
          <span nxCopytext="medium">Welcome, lengzai</span>
          <span class="font-grey nx-margin-left-3xs" nxCopytext="small">
            Last login: 02/02/2024 15:20PM
          </span>
        </div>
        <div class="border-line nx-margin-x-m"></div>
        <button nxPlainButton class="button_black">
          <nx-icon
            name="export-and-share"
            class="rotate_logout_icon ndbx-icon nx-icon--export-and-share"
            nxIconPositionStart
            aria-hidden="true"
          ></nx-icon
          ><span nxCopytext="medium">Log out</span>
        </button>
      </nx-header-actions>
    </nx-header-row>
    <nx-header-row class="header_border">
    </nx-header-row>
    <nx-header-row class="bottom_header_menu header_container">
      <nx-header-brand
        class="nx-margin-0 nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
      >
        <div nxCopytext="medium" class="nx-margin-right-2xs">
          Welcome, lengzai
        </div>
        <div class="font-grey nx-margin-top-3xs" nxCopytext="small">
          Last login: 02/02/2024 15:20PM
        </div>
      </nx-header-brand>
      <nx-header-navigation *ngIf="showmenu">
        <nx-header-navigation-item
          class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
        >
          <a nxHeaderLink>Alpha Portal</a>
          <hr />
        </nx-header-navigation-item>
        <nx-header-navigation-item>
          <a nxHeaderLink>Dashboard</a>
          <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
        </nx-header-navigation-item>
        <nx-header-navigation-item>
          <ng-container *ngFor="let item of menuData">
            <div
              class="nx-header__link policytab"
              nxMenuButton
              (click)="onClick(item)"
              menuButtonType="root"
              [expandable]="!!item.children"
              nxMenuItem
              [expanded]="item.expanded"
            >
              <a class="policy" nxHeaderLink>Policy Management</a>
            </div>
            <div
              nxMenuItem
              *ngIf="item.children && item.expanded"
              class="sub_menu"
            >
              <div nxLayout="grid maxwidth">
                <div nxRow>
                  <div nxCol="12,12,12,2,2,2" class="nx-margin-bottom-m">
                    <a (click)="nav('../summary', item)">Summary</a> <br />
                    <a (click)="nav('../report', item)">Reports</a> <br />
                    <a (click)="nav('../enquiry', item)">Enquiry</a> <br />
                    <a (click)="nav('', item)">CI Upload</a> <br />
                  </div>
                  <div nxCol="12,12,12,4,4,4" class="nx-margin-bottom-m">
                    <span class="nx-font-weight-semibold">Motor</span><br />
                    <a (click)="nav('../agents', item)">Cover Note</a> <br />
                    <a (click)="nav('../endorsement-pdpa', item)">Endorsement</a>
                    <br />
                    <a (click)="nav('', item)">Road Tax Renewal</a> <br />
                    <a [routerLink]="''">Road Tax Purpose</a> <br />
                    <a (click)="nav('../ism-policy', item)"
                      >ISM Policy / Endorsement Data Upload</a
                    >
                    <br />
                    <a (click)="nav('../enquiry/NCD Confirmation Enquiry', item)"
                      >No Claim Discount Confirmation Enquiry</a
                    >
                    <br />
                    <a (click)="nav('../enquiry/No Claim Discount Enquiry', item)"
                      >No Claim Discount Enquiry</a
                    >
                    <br />
                    <ng-container *ngFor="let submenu of menuData1">
                      <a
                        class="padding_0 header_nested_sub_menu"
                        nxMenuButton
                        (click)="onClick(submenu)"
                        menuButtonType="root"
                        [expandable]="!!submenu.children"
                        nxMenuItem
                        [expanded]="submenu.expanded"
                      >
                        <nx-icon
                          nxMenuButtonIcon
                          name="chevron-right"
                          size="s"
                          aria-hidden="true"
                        ></nx-icon>
                        {{ submenu.label }}
                      </a>
                      <div
                        nxMenuItem
                        *ngIf="submenu.children && submenu.expanded"
                      >
                        <a
                          class="nested_sub_menu"
                          *ngFor="let child of submenu.children"
                          nxMenuLink
                          (click)="nav2(child.path, submenu, item)"
                          >{{ child.label }}</a
                        >
                      </div>
                    </ng-container>
                  </div>
                  <div nxCol="12,12,12,3,3,3" class="nx-margin-bottom-m">
                    <span class="nx-font-weight-semibold">Non-Motor</span><br />
                    Issuance<br />
                    Cover Note
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
        </nx-header-navigation-item>
        <nx-header-navigation-item>
          <a nxHeaderLink>e-Library</a>
          <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
        </nx-header-navigation-item>
        <nx-header-navigation-item>
          <a nxHeaderLink>Sales Management</a>
          <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
        </nx-header-navigation-item>
        <nx-header-navigation-item>
          <a nxHeaderLink>Customer Management</a>
          <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
        </nx-header-navigation-item>
        <nx-header-navigation-item
          class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
        >
          <a class="color_dark_blue" nxHeaderLink>Allianz Malaysia</a>
          <hr />
        </nx-header-navigation-item>
        <nx-header-navigation-item
          class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
        >
          <a nxHeaderLink>
            <button nxPlainButton class="button_black">
              <nx-icon
                name="export-and-share"
                class="rotate_logout_icon ndbx-icon nx-icon--export-and-share"
                nxIconPositionStart
                aria-hidden="true"
              ></nx-icon
              >Log out
            </button>
          </a>
          <hr />
        </nx-header-navigation-item>
      </nx-header-navigation>
  
      <nx-header-actions>
        <nx-icon name="user-o" size="s" class="nx-margin-s"></nx-icon>
        <nx-icon name="bell-o" size="s" class=""></nx-icon>
      </nx-header-actions>
    </nx-header-row>
    

   
  </header>

