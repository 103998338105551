<div>
    <div nxModalContent>
      <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">Warning</h3>
      <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-light">
        {{ message }}
      </p>
    </div>

    <div class="button_area2">
      <button nxModalClose nxButton="secondary" type="button" (click)="no()">No</button>
      <button nxModalClose nxButton="primary" type="button" (click)="yes()">Yes</button>
    </div>
  </div>