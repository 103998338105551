<form [formGroup]="requestCancellationForm">
    <div nxModalContent>
        <h3 size="s" class="nx-margin-bottom-2m nx-margin-top-0">
            Reason for cancellation of {{ isEquotation ? 'e-Quotation' : 'e-Cover Note' }}
        </h3>

        @if (isCoverNote) {
            <nx-formfield label="Select Reason">
                <nx-dropdown formControlName="cancellationReason">
                    @for(reason of reasonLov; track reason) {
                        <nx-dropdown-item [value]="reason.code">{{ reason.descp | uppercase }}</nx-dropdown-item>
                    }
                </nx-dropdown>
                <nx-error nxFormfieldError>
                    <strong>Reason is mandatory.</strong>
                </nx-error>
            </nx-formfield>
        }
        

        <nx-formfield label="Enter Remarks">
            <textarea nxInput type="text" #remarks maxlength="200" cdkTextareaAutosize formControlName="cancelRemarks"></textarea>

            @if (remarks.value?.length === 0) {
            <span nxFormfieldHint>Max. 200 Characters</span>
            }

            @if (remarks.value.length > 0) {
            <span nxFormfieldHint>{{ 200 - (remarks.value.length || 0) }} {{ 'COMMON.CHAR_REMAINING' | translate }}</span>
            }

            <nx-error nxFormfieldError>
                <strong>Remarks is mandatory.</strong>
            </nx-error>
        </nx-formfield>

        @if(!activateCancelAndReplaceBtn) {
        <!-- 2 buttons -->
        <div>
            <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button" (click)="close(null)">
                CANCEL
            </button>
            <button nxButton="primary" type="button" (click)="cancelReplace()">
                SUBMIT
            </button>
        </div>
        } @else {
        <!-- 3 buttons -->
        <div>
            <button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button" (click)="close(null)">
                CLOSE
            </button>
            <button class="button_popup_2btn" nxButton="primary" type="button" (click)="submitCancellation()">
                CANCEL
            </button>
            <button nxButton="primary" type="button" (click)="cancelReplace()">
                CANCEL AND REPLACE
            </button>
        </div>
        }
        
    </div>
</form>