<!-- Popup Content -->
<!-- <div *ngIf="selectedPolicyMovement" nxModal> -->
<div *ngIf="popup_type === 'policyTable'" nxModal>
    <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s">Policy Movement</h3>
    <div nxModalContent>
        <div class="left-content">
            <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-m popup_blue_title nx-font-weight-semibold">
                Endorsement Details
            </h4>
            <div nxLayout="grid nopadding">
                <div nxRow>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Endorsement No.</nx-data-display-label>
                            <p nxCopytext="large">{{ selectedPolicyMovement.transactionNo }}</p>
                        </nx-data-display>
                    </div>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Endorsement Type</nx-data-display-label>
                            <p nxCopytext="large">{{ selectedPolicyMovement.endorsementDesc }}</p>
                        </nx-data-display>
                    </div>
                </div>
                <div nxRow>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Issue Date</nx-data-display-label>
                            <!-- to check if data exists -->
                            <p nxCopytext="large">{{ selectedPolicyMovement.cnoteDate }}</p>
                        </nx-data-display>
                    </div>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small">
                            <nx-data-display-label class="font-grey">Issue By</nx-data-display-label>
                            <p nxCopytext="large">{{ selectedPolicyMovement.issueBy }}</p>
                        </nx-data-display>
                    </div>
                </div>
                <div nxRow>
                    <div nxCol="12,12,6" class="nx-margin-bottom-m">
                        <nx-data-display size="small" class="nx-margin-bottom-m">
                            <nx-data-display-label class="font-grey">Gross Premium (RM)</nx-data-display-label>
                            <p nxCopytext="large">{{ selectedPolicyMovement.grossPremium }}</p>
                        </nx-data-display>
                    </div>
                </div>
                <div nxRow>
                    <hr />
                </div>
            </div>
            <hr class="nx-margin-bottom-m" />
            <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-m popup_blue_title nx-font-weight-semibold">
                Endorsement Narration
            </h4>

            <div [innerHTML]="safeNarration" class="narration-content"></div>
             
            <!-- <u nxCopytext="normal" class="nx-font-weight-semibold">{{ selectedPolicyMovement.narration }}</u> -->
            <!-- <p nxCopytext="normal" class="nx-margin-y-2m">
                IT IS HEREBY DECLARED AND AGREED THAT EFFECTIVE
                <span class="nx-font-weight-bold">{{ selectedPolicyMovement.effectiveDate }}</span> THE ENGINE NUMBER AND/OR CUBIC
                CAPACITY IS/ARE AMENDED
                TO READ AS FOLLOWS AND NOT AS OTHERWISE STATED.
            </p>
            <p nxCopytext="normal" class="nx-margin-bottom-m nx-font-weight-bold">
                ENGINE / MOTOR NO. : {{ selectedPolicyMovement.engineNo }}<br />
                CUBIC CAPACITY : {{ selectedPolicyMovement.cubicCapacity }} CC
            </p>

            <p nxCopytext="normal" class="nx-margin-y-m">
                IN CONSIDERATION OF THE ABOVE, AN ADDITIONAL PREMIUM OF
                <span class="nx-font-weight-bold">RM{{ selectedPolicyMovement.additionalPremium }}</span> CHARGED TO THE INSURED.
            </p>

            <p nxCopytext="normal">ALL OTHER TERMS AND CONDITIONS REMAIN UNCHANGED.</p> -->

            <div nxRow class="nx-margin-top-50">
                <!-- <button nxButton="secondary" nxModalClose (click)="closePopup()">Close</button> -->
                <button nxButton="secondary" nxModalClose>Close</button>
            </div>
        </div>
    </div>

    <!-- <button nxModalClose nxButton="primary" type="button" class="nx-margin-top-m" (click)="closePopup()">OK</button> -->
    <!-- <button nxModalClose nxButton="primary" type="button" class="nx-margin-top-m">OK</button> -->
</div>