<div nxRow>
  <div nxCol="12">
    <h2 class="nx-margin-0 nx-margin-bottom-3xs">Coverage</h2>
    <p nxCopytext="small"><i>{{ 'GLOBAL.INFORM.REQUIRED_FIELDS' | translate }}</i></p>
  </div>
</div>

<form [formGroup]="coverageFmGroup">

  <div nxRow class="nx-margin-top-2m">
    <div nxCol="12">
      <app-vehicle-details *ngIf="vehicleUselov" (calculatePremium)="calculatePremium($event)" [coverageFmGroup]="coverageFmGroup" [vehicleUselov]="vehicleUselov" />
    </div>
  </div>

  <app-type-of-coverage *ngIf="coverList" (calculatePremium)="calculatePremium($event)" [coverageFmGroup]="coverageFmGroup" [coverListLov]="coverList" />

  <div nxRow class="nx-margin-top-50 nx-margin-bottom-3xl">
    <div nxCol="12" class="button-container">
      <button type="button" nxButton="secondary" (click)="goBack()">
        {{ 'GLOBAL.ACTION.BACK' | translate }}
      </button>
      <button type="button" nxButton="secondary" (click)="saveAsDraft()">
        {{ 'GLOBAL.ACTION.SAVE_DRAFT' | translate }}
      </button>
      @if (this.isSaved && this.isSaved.cnoteStatus && this.isSaved.cnoteStatus=== 'SAVED') {
        <button type="button" nxButton="secondary" (click)="cancelDraft()">
          {{ 'GLOBAL.ACTION.CANCEL_DRAFT' | translate }}
        </button>
      }
      <button type="button" nxButton="primary" (click)="next()" [disabled]="!isNext(coveragePremium$ | async)">
        {{ 'GLOBAL.ACTION.NEXT' | translate }}
      </button>
    </div>
  </div>

</form>
