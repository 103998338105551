<!-- WORKING: Endorsement Type Validation -->

<!-- <div nxLayout="grid maxwidth">

    <div nxRow rowJustify="center">
        <div nxCol="12,12,12,3" class="grid_width_page">
            <app-progress-bar [progressValue]="0" [progressType]="'cn'" />
        </div>
        <div nxCol="12,12,12,9" class="grid_width_page">
            <div> -->
<!-- /////////////////////////////////////////////////////////////////////////////// -->
<div nxRow *ngIf="endorsement">
    <div nxCol="12">
        <nx-formfield label="Endorsement Type">
            <!-- <nx-dropdown [valueFormatter]="toText">
                                <nx-dropdown-item *ngFor="let type of endorsementTypes" [value]="type">
                                  {{ type }}
                                </nx-dropdown-item>
                            </nx-dropdown> -->
            <nx-dropdown [(ngModel)]="selectedEndorsementType" (ngModelChange)="validateEndorsementType($event)"
                [valueFormatter]="toText">
                <nx-dropdown-item *ngFor="let type of endorsementTypes" [value]="type">
                    {{ type }}
                </nx-dropdown-item>
            </nx-dropdown>
            <!-- //////////////////////////////////////////////////// -->

            <!-- <nx-error nxFormfieldNote>
                                <strong>Please select Endorsement Type</strong>
                            </nx-error> -->
            <nx-error nxFormfieldNote *ngIf="endorsementErrorMessage">
                <strong>{{ endorsementErrorMessage }}</strong>
            </nx-error>
            <!-- //////////////////////////////////////////////////// -->

        </nx-formfield>

        <!-- <div>
                            <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(6)">
                                agent not Banca Agent
                            </button>
                            |
                            <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(7)">
                                policy NCD is zero
                            </button>
                            |
                            <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(8)">
                                policy NCD is not zero</button>|
                            <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(9)">
                                Amendment of e-Invoice Details
                            </button>
                            |
                            <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(10)">
                                Change of e-Invoice consent</button>|
                            <button nxPlainButton class="nx-margin-x-2xs" type="button" (click)="openPopup(11)">
                                Change of e-Invoice consent
                            </button>
                        </div> -->

        <div *ngFor="let popup of popupTypes">
            <button *ngIf="[6, 7, 8, 9, 10, 11].includes(popup)" nxPlainButton class="nx-margin-x-2xs" type="button"
                (click)="openPopup(popup)">
                {{ popupMessages[popup] }}
            </button>
            <span *ngIf="popup !== 11"></span>
        </div>
        <!-- //////////////////////////////////////////////// -->

    </div>
</div>

<div style="height: 10px;"></div>

<div *ngIf="endorsement" class="nx-margin-bottom-s">
    <div nxRow>
        <div nxCol="12">
            <span nxCopytext="large">Are you cancelling a previous "Extension of Policy"
                endorsement?</span>
            <div class="radio-group nx-margin-top-s">
                <nx-radio name="group1">Yes</nx-radio>
                <nx-radio name="group1" class="nx-margin-left-2m">No</nx-radio>
            </div>
        </div>
    </div>

    <div nxRow class="nx-margin-top-s">
        <div nxCol="12,12,6,6,6,6,6">
            <nx-error nxFormfieldNote>
                <strong>Please select option</strong>
            </nx-error>
        </div>
    </div>
</div>

<div *ngIf="endorsement">
    <div nxRow>
        <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Endorsement Effective Date">
                <input nxDatefield nxInput [datepicker]="myDatepicker5" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle [for]="myDatepicker5" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker5></nx-datepicker>

                <nx-error nxFormfieldNote>
                    <strong>Please enter Endorsement Effective Date</strong>
                </nx-error>
            </nx-formfield>

        </div>
        <div nxCol="12,12,6,6,6,6,6">
            <nx-formfield label="Endorsement Expiry Date">
                <input nxDatefield nxInput [datepicker]="myDatepicker6" />
                <span nxFormfieldHint>MM/DD/YYYY</span>

                <nx-datepicker-toggle [for]="myDatepicker6" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker6></nx-datepicker>

                <nx-error nxFormfieldNote>
                    <strong>Please enter a valid Endorsement Expiry Date</strong>
                </nx-error>
            </nx-formfield>

        </div>
    </div>
    <div nxRow>
        <div nxCol="12,12,8,8,8,8,8">
            <nx-message context="info">
                <span class="nx-font-weight-bold">
                    Endorsement Effective date cannot be earlier than issued
                    date.
                </span>
            </nx-message>
            <nx-message context="info">
                <span class="nx-font-weight-bold">
                    Endorsement Effective date cannot be after expiry date.
                </span>
            </nx-message>
            <nx-message context="info">
                <span class="nx-font-weight-bold">
                    Endorsement Effective date cannot be before the effective
                    date of the policy (dd/mm/yyyy).
                </span>
            </nx-message>
            <nx-message context="info">
                <span class="nx-font-weight-bold">
                    Expiry date cannot be more than 6 months after effective
                    date.
                </span>
            </nx-message>
            <nx-message context="info">
                <span class="nx-font-weight-bold">
                    Expiry date cannot be earlier than effective date.
                </span>
            </nx-message>
        </div>
    </div>
</div>

<!-- <div nxRow class="nx-margin-top-50 nx-margin-bottom-3xl">
                    <div nxCol="12" class="button_area">
                        <button nxButton="secondary" type="button">back</button>
                        <button nxButton="secondary" type="button">CANCEL DRAFT</button>
                        <button nxButton="primary" type="button" [routerLink]="'../customer-partner'">
                            NEXT
                        </button>

                        //////////////////////////////////////////////////////////////
                        <button nxButton="primary" type="button" [disabled]="isNextButtonDisabled">Next</button>
                        //////////////////////////////////////////////////////////////////
                        
                    </div>
                </div> -->
<!-- /////////////////////////////////////////////////////////////////////////////// -->
<!-- </div>
        </div>
    </div>
</div> -->