<h3 size="s" class="nx-margin-bottom-2m nx-margin-top-0">
    {{ parentData.editMode ? 'Edit Payment Mode' :  'Add Payment Mode' }}
</h3>

<form [formGroup]="addPaymentForm">
<nx-formfield label="Payment Paid by Customer (RM) ">
    <input nxInput formControlName="amount" appAllowDecimalNumber (blur)="currencyFormat($event)" (focus)="removeSpecialChar()"/>
</nx-formfield>

<nx-formfield label="Payment Made To">
    <nx-dropdown formControlName="paymentMadeTo">
        @for(paymentTo of paymentToList; track paymentTo) {
            <nx-dropdown-item [value]="paymentTo.code">{{ paymentTo.descp }}</nx-dropdown-item>
        }
    </nx-dropdown>
    <nx-error nxFormfieldError> This field is required. </nx-error>
</nx-formfield>


<nx-formfield label="Payment Mode">
    <nx-dropdown [showFilter]="true" filterPlaceholder="Type to search payment mode" formControlName="paymentMode" (selectionChange)="onChangePaymentMode()">
        @for(paymentMode of paymentModeList; track paymentMode) {
            <nx-dropdown-item [value]="paymentMode.code">{{ paymentMode.descp }}</nx-dropdown-item>
        }
        <!-- <nx-dropdown-item value="CS">CASH (OVER THE COUNTER)</nx-dropdown-item>
        <nx-dropdown-item value="CDM">CASH DEPOSIT MACHINE</nx-dropdown-item>
        <nx-dropdown-item value="CDMD">CHEQUE</nx-dropdown-item>
        <nx-dropdown-item value="ATM">ATM TRANSFER</nx-dropdown-item>
        <nx-dropdown-item value="CC">CREDIT CARD</nx-dropdown-item>
        <nx-dropdown-item value="IFUND">I-FUND TRANSFER / CIMB CLICKS</nx-dropdown-item>
        <nx-dropdown-item value="IBG">INTER BANK (GIRO)</nx-dropdown-item> -->
    </nx-dropdown>
    <nx-error nxFormfieldError> This field is required. </nx-error>
</nx-formfield>

@if(addPaymentForm.controls['paymentMode'].value === 'CDMD' || addPaymentForm.controls['paymentMode'].value === 'IBG'){
<nx-formfield label="Issuing Bank Name">
    <nx-dropdown [showFilter]="true" filterPlaceholder="Type to search bank" formControlName="issueBank">
        @for(issueBank of issueBankList; track issueBank) {
            <nx-dropdown-item [value]="issueBank.code">{{ issueBank.descp }}</nx-dropdown-item>
        }
    </nx-dropdown>
    <nx-error nxFormfieldError>This field is required.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'CDMD') {
<nx-formfield label="Issuing Bank Branch">
    <input nxInput formControlName="issueBankBranch"/>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'CDMD') {
<nx-formfield label="Cheque No.">
    <input nxInput formControlName="chequeNo"/>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if (addPaymentForm.controls['paymentMode'].value && addPaymentForm.controls['paymentMode'].value !== 'CC') {
<nx-formfield label="Pay in Slip / Ref No.">
    <input nxInput formControlName="payInSlip"/>
    <span nxFormfieldHint class="std-ta-left">eg: 123456789123456</span>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'CDM') {
<nx-formfield label="CDM ID/BRCD">
    <input nxInput formControlName="cdmId" (change)="combinedPaySlip()"/>
    <span nxFormfieldHint class="std-ta-left">eg: S145/9815</span>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'CDM') {
<nx-formfield label="SEQ No.">
    <input nxInput formControlName="seqNo" (change)="combinedPaySlip()"/>
    <span nxFormfieldHint class="std-ta-left">eg: 5431</span>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'ATM') {
<nx-formfield label="ATM ID">
    <input nxInput formControlName="atmId" (change)="combinedPaySlip()"/>
    <span nxFormfieldHint class="std-ta-left">eg: FCXB1408</span>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'ATM') {
<nx-formfield label="Trace No.">
    <input nxInput formControlName="traceNo" (change)="combinedPaySlip()"/>
    <span nxFormfieldHint class="std-ta-left">eg: 5431</span>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'CC') {
<nx-formfield label="Approval Code">
    <input nxInput formControlName="approvalCode" />
    <span nxFormfieldHint class="std-ta-left">eg: T0415000</span>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'CC') {
<nx-formfield label="Credit Card No.">
    <input nxInput formControlName="creditCardNo" />
    <span nxFormfieldHint class="std-ta-left">eg: 5424441002206152</span>
    <nx-error nxFormfieldError>This is not a valid value.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'IFUND' || addPaymentForm.controls['paymentMode'].value == 'IBG') {
<nx-formfield label="Acc Holder's Name">
    <input nxInput formControlName="accountHolder"/>
    <nx-error nxFormfieldError>This field is required.</nx-error>
</nx-formfield>
}

@if(addPaymentForm.controls['paymentMode'].value == 'IFUND' || addPaymentForm.controls['paymentMode'].value == 'IBG') {
<nx-formfield label="Transaction Date">
    <input nxDatefield nxInput [datepicker]="myDatepicker2" formControlName="transactionDate"/>
    <span nxFormfieldHint>DD/MM/YYYY</span>

    <nx-datepicker-toggle [for]="myDatepicker2" nxFormfieldSuffix></nx-datepicker-toggle>
    <nx-datepicker #myDatepicker2></nx-datepicker>
    <nx-error nxFormfieldError>This field is required.</nx-error>
</nx-formfield>
}


<button nxModalClose class="button_popup_2btn" nxButton="secondary" type="button">
    Cancel
</button>
<button nxModalClose nxButton="primary" type="button" [disabled]="!addPaymentForm.valid || !addPaymentForm.status" (click)="addOrUpdatePayment(addPaymentForm.getRawValue())">Save</button>

</form>