import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { STORE_SHOWED_ONE_ALLIANZ_POP_UP } from '@store/sat-motor/sat-motor.action';

@Component({
  selector: 'app-one-allianz-message',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './one-allianz-message.component.html',
  styleUrl: './one-allianz-message.component.scss'
})
export class OneAllianzMessageComponent {

  constructor(
    private store: Store
  ) {}

  onClickOK() {
    this.store.dispatch(new STORE_SHOWED_ONE_ALLIANZ_POP_UP(true));
  }

}
