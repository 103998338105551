import { Directive, ElementRef, HostListener, Injector, Self } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
  standalone: true,
  selector: '[appCusCurrencyFormat]'
})
export class CusCurrencyFormatDirective {
  constructor(private el: ElementRef, private injector: Injector,
  @Self() private model: NgControl = injector.get(NgControl),) { }

  @HostListener('blur') onBlur() {
    let newVal = this.el.nativeElement.value.toString().replace(/[a-zA-Z:,]+/g, "");
    if (newVal)
      this.model.control.setValue(Number(newVal).toLocaleString(undefined, { minimumFractionDigits: 2 }));
  }
}
