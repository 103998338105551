import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";

import { NgxsModule } from "@ngxs/store";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";

import { routes } from "./app.routes";
import { MotorRoutes } from "./views/motor/motor.routes";

import { environment } from "../environments/environment.development";
import { UserState } from "@store/user/user.state";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { EnquiryState } from "@store/enquiry/enquiry.state";
import { NotificationState } from "@store/notification/notification.state";

import { LoginResolverService } from "src/app/shared/services/login-resolver/login-resolver.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RequestInterceptorService } from "@services/request-interceptor/request-interceptor.service";
import { LoadingInterceptorService } from "@services/loading-interceptor/loading-interceptor.service";
import { SatMotorApprovalState } from "@store/sat-motor-approval/sat-motor-approval.state";
import { GeolocationState } from "@shared/components/geolocation/store/geolocation.state";
import { GeolocationService } from "@shared/components/geolocation/store/geolocation.service";
import { ErrorState } from "@store/exception/error.state";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?4");
}

export const appConfig: ApplicationConfig = {
  
  providers: [
    GeolocationService,
    provideRouter(routes),
    provideRouter(MotorRoutes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true,
    },
    importProvidersFrom([BrowserAnimationsModule]),
    importProvidersFrom([LoginResolverService]),
    importProvidersFrom(NgxsStoragePluginModule.forRoot()),
    importProvidersFrom(
      NgxsLoggerPluginModule.forRoot({
        logger: console,
        collapsed: false,
        disabled: environment.production,
      }),
    ),
    importProvidersFrom(
      NgxsModule.forRoot([UserState, SatMotorState, NotificationState, EnquiryState, SatMotorApprovalState, GeolocationState], {
        developmentMode: !environment.production,
      }),
      NgxsModule.forFeature([GeolocationState]),
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
  ],
};
