import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { STEP2 } from '../../../../interfaces/motor';
import { CLIENTTYPE, IDTYPE, COUNTRIES } from '../../../enums/index.enum'
import { Store } from '@ngxs/store';
import { SET_EINVOICE_CONSENT_IND, VALIDATE_CP } from '@store/sat-motor/sat-motor.action';
import { SharedModule } from '@shared/shared.module';
import { NricService } from '@shared/services/nric/nric.service';
import { BrnoService } from '@shared/services/brno/brno.service';

@Component({
  selector: 'app-customer-partner-search',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './customer-partner-search.component.html',
  styleUrl: './customer-partner-search.component.scss'
})
export class CustomerPartnerSearchComponent implements OnInit {
  @Output() search = new EventEmitter();
  @Output() back = new EventEmitter();
  searchForm: FormGroup;
  individual:boolean = false;
  company:boolean = false;
  isMalaysia: boolean = false;
  product: any;
  idTypeOption: any;
  idValueLength: number;
  onlyNumber: boolean = false;


  unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private nricService: NricService,
    private brnoService: BrnoService,
  ) {
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      clientType: [''],
      clientAddressType: [''],
      idType: ['', Validators.required],
      idValue: ['', Validators.required],
    });

    this.product = this.store.selectSnapshot<string>(state => state.SatMotorState.motor.step1.issuance.issuance.productCode);

    this.defaultClientType();

    if(!this.company) {
      this.defaultClientAddressType();
    }

    this.updateIdTypeOption();

  //  this.idTypeOnChange();
   this.clientAddressTypeOnChange();
   this.clientTypeOnChange();
  }

  defaultClientType() {
    if(this.product === '080100' || this.product === '082100' || this.product === '082101') {
      this.searchForm.controls['clientType'].setValue(CLIENTTYPE.INDIVIDUAL);
      this.company = false;
    }else {
      this.searchForm.controls['clientType'].setValue(CLIENTTYPE.COMPANY);
      this.company = true;
      this.store.dispatch(new SET_EINVOICE_CONSENT_IND(true));
    }
  }

  defaultClientAddressType() {
    this.searchForm.controls['clientAddressType'].setValue(COUNTRIES.MALAYSIAN);
  }

  onSubmit() {
    if (this.searchForm.valid) {
      this.search.emit(this.getSearchFormData());
    }else {
      this.throwFieldErr();
    }
  }

  getSearchFormData(): STEP2.SearchDataStore {
    const { clientType, clientAddressType, idType, idValue } = this.searchForm.getRawValue();

    const obj: STEP2.SearchDataStore = {      
          idType,
          idValue,
          clientType,
          clientAddressType,
      }
      
    return obj;
  }

  throwFieldErr() {
    console.warn(this.searchForm.controls);
    if(this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
    }
    
  }

  idTypeOnChange() {
    let value = this.searchForm.controls['idType'].value;
    this.searchForm.controls['idValue'].reset();
    if (value === IDTYPE.NRIC || value === IDTYPE.NEW_BR_NO) {
      this.idValueLength = 12;
    } else {
      this.idValueLength = 20;
    }

    if(value === IDTYPE.NRIC) {
      this.onlyNumber = true;
    }else {
      this.onlyNumber = false;
    }
  }

  clientAddressTypeOnChange() {
    this.searchForm.controls['clientAddressType'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      this.searchForm.controls['idType'].reset();
      this.searchForm.controls['idValue'].reset();
      this.updateIdTypeOption();
      if(value === 'NM') {
        this.searchForm.controls['idType'].disable();
      }else {
        this.searchForm.controls['idType'].enable();
      }
    });
  }

  clientTypeOnChange() {
    this.searchForm.controls['clientType'].valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      this.searchForm.controls['clientAddressType'].reset();
      this.searchForm.controls['idType'].reset();
      this.searchForm.controls['idValue'].reset();

      if(value === CLIENTTYPE.COMPANY) {
        this.company = true;
        this.store.dispatch(new SET_EINVOICE_CONSENT_IND(true));
      }else {
        this.defaultClientAddressType();
        this.company = false;
      }
      this.updateIdTypeOption();
      
    });
  }

  updateIdTypeOption() {
    let clientType = this.searchForm.controls['clientType'].value;
    let clientAddressType = this.searchForm.controls['clientAddressType'].value;

    this.searchForm.controls['idType'].setValue('');

    if(clientType === CLIENTTYPE.INDIVIDUAL) {
      if (clientAddressType === COUNTRIES.MALAYSIAN) {
        this.idTypeOption = [
          { code: IDTYPE.NRIC, descp: IDTYPE.NRIC_DESCP },
          { code: IDTYPE.OLD_IC, descp: IDTYPE.OLD_IC_DESCP },
          { code: IDTYPE.POL, descp: IDTYPE.POL_DESCP }
        ];
        this.searchForm.controls['idType'].setValue(IDTYPE.NRIC);
      }else {
        this.idTypeOption = [
          {code:IDTYPE.PASS, descp:IDTYPE.PASS_DESCP}
        ];
        this.searchForm.controls['idType'].setValue(IDTYPE.PASS);
      } 
    }else {
      this.idTypeOption = [
        {code:IDTYPE.BR_NO, descp:IDTYPE.BR_NO_DESCP},
        {code:IDTYPE.NEW_BR_NO, descp:IDTYPE.NEW_BR_NO_DESCP}
      ];
      this.searchForm.controls['idType'].setValue(IDTYPE.NEW_BR_NO);
    }
    this.idTypeOnChange();
    this.cdr.detectChanges();
  }

  goBack() {
    this.back.emit();
  }

  onBlurIdValue() {
    let idValueControl = this.searchForm.controls['idValue'];
    let idTypeControl = this.searchForm.controls['idType'];
    let idValueVal = idValueControl.value;
    let idTypeVal = idTypeControl.value;

    if (idValueVal) {
      switch (idTypeVal) {
        case IDTYPE.NRIC:
          if (idValueVal.length === 12) {
            const isValidNric = this.nricService.isNRICValid(idValueVal);
            if (!isValidNric) {
              idValueControl.setErrors({ 'wrongNricFormat': true });
            }
          }else {
            idValueControl.setErrors({ 'wrongNricFormat': true });
          }
          break;
        
        case IDTYPE.NEW_BR_NO:
          if(idValueVal.length < 12) {
            idValueControl.setErrors({ 'wrongNewBrnoFormat': true });
          }else {
            try {
              const isValidNewBrNo = this.brnoService.isBRNOValid(idValueVal);
              if (!isValidNewBrNo) {
                idValueControl.setErrors({ 'wrongNewBrnoFormat': true });
              }
            }catch (err) {
              idValueControl.setErrors({ 'wrongNewBrnoFormat': true });
            }
            
          }
          break;
      }
      idTypeControl.setValidators([Validators.required]);
    }else {
      idTypeControl.removeValidators([Validators.required]);
    }
    idTypeControl.updateValueAndValidity();
  }

  get isOldIC() {
    return this.searchForm.controls['idType'].value === IDTYPE.OLD_IC;
  }

  get isPassport() {
    return this.searchForm.controls['idType'].value === IDTYPE.PASS;
  }
}
