import { ValidationErrors, AbstractControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/enums/index.enum';
import * as moment from 'moment';

export function splitNRIC(value) {
  const regex = /^(\d{2})(\d{2})(\d{2})-?(\d{2})-?(\d{3})(\d{1})$/;
  const parts = value.match(regex);

  if (!parts) {
    throw new Error('Invalid value number format');
  }

  return parts;
}


export function combineToDate(year, month, day) {
  const today = new Date();
  const combinedDate = new Date(year, month - 1, day);

  const age = today.getFullYear() - combinedDate.getFullYear();

  // Works for now. Update this in year 2099.
  if (age > 100 || (age == 100 && dateIsBefore(combinedDate, today))) {
    combinedDate.setFullYear(combinedDate.getFullYear() + 100);
  }

  return combinedDate;
}

function dateIsBefore(before, max) {
  const bNorm = new Date(0, before.getMonth(), before.getDate());
  const mNorm = new Date(0, max.getMonth(), max.getDate());

  return bNorm < mNorm;
}

export function inBetween(value, min, max) {
  return value >= min && value <= max;
}

export const RegexTinNo = '^[a-zA-Z0-9]{1,13}$';
export const RegexEmail = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';

export function splitBRNO(value) {
  const regex = /^(\d{4})(\d{2})(\d{6})$/;
  const parts = value.match(regex);

  if (!parts) {
    throw new Error('Invalid value number format');
  }

  return parts;
}

export function maxYearValue(value, max) {
  return value <= max;
}