import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  NX_MODAL_DATA,
  NxDialogService,
  NxModalRef,
} from "@aposin/ng-aquila/modal";
import { SharedModule } from "../../shared/shared.module";

@Component({
  selector: 'app-summary-popup',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './summary-popup.component.html',
  styleUrl: './summary-popup.component.scss'
})
export class SummaryPopupComponent {
  templateDialogRef?: NxModalRef<any>;
  popup_type: any;
  constructor(@Inject(NX_MODAL_DATA) data: any,
  private router: Router
) {
    console.log("data", data.popup_type);
    this.popup_type = data.popup_type;    
  }

  returnCP(){
    this.router.navigate(['issuance/customer-partner']);
  }

}
