<div>
  <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-s">Policy Search</h3>
  <div nxModalContent>
    <div class="left-content">
      <div class="nx-margin-top-2m">
        <div nxLayout="grid nopadding">
          <div [formGroup]="searchModelForm">
            <div nxRow>
              <div nxCol="12,12,6">
                <nx-formfield label="Search By">
                  <nx-dropdown formControlName="policySearchType" [panelGrow]="true"
                    (selectionChange)="onChangeFormValue($event, 'searchType')">
                    <ng-container *ngFor="let type of policySearchTypeList">
                      <nx-dropdown-item [value]="type.code">{{ type.descp }}</nx-dropdown-item>
                    </ng-container>
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <strong>This field is required</strong>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6">
                <nx-formfield label="Enter Value">
                  <input nxInput formControlName="policySearchTypeValue"
                    (change)="onChangeFormValue($event, 'searchValue')" appToUpperCase />
                  <nx-error nxFormfieldError>
                    <strong>This field is required</strong>
                  </nx-error>
                </nx-formfield>
              </div>
            </div>
            <div nxRow>
              <div nxCol="12" class="std-ta-right button_dashboard">
                <button nxButton="secondary" nxModalClose>{{ 'GLOBAL.ACTION.RESET' | translate }}</button>
                <button nxButton (click)="onSearchClicked()">{{ 'GLOBAL.ACTION.SEARCH' | translate }}</button>
              </div>
            </div>
          </div>
          @if (searchEnquiryList.length) {
          <div nxRow>
            <div nxCol="12" class="nx-margin-top-2m">
              <div class="scroll-container table_popup">
                <div>
                  <app-external-scrollbar targetSyncScroll="1" [targetBaseWidth]="tableHolder"></app-external-scrollbar>
                  <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder
                    syncScroll="1">
                    <!-- table here -->
                    <table nxTable zebra class="nx-table--scrollable">
                      <thead #tableHeader>
                        <tr nxTableRow>
                          <th nxHeaderCell>Vehicle No.</th>
                          <th nxHeaderCell>e-Policy No.</th>
                          <th nxHeaderCell>Customer Name</th>
                          <th nxHeaderCell>Vehicle Class</th>
                          <th nxHeaderCell>Coverage Type</th>
                          <th nxHeaderCell>Effective Date</th>
                          <th nxHeaderCell>Expiry Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr nxTableRow *ngFor="let item of paginatedResults; let i = index" (select)="select(item)"
                          [selectable]="true">
                          <td nxTableCell>{{ item.riskList[0].vehicleNo }}</td>
                          <td nxTableCell>{{ item.riskList[0].policyNo }}</td>
                          <td nxTableCell>{{ item.clientPartner.clientName }}</td>
                          <td nxTableCell>{{ getVehicleClassDescription(item.riskList[0].vehicleClass)}}</td>
                          <td nxTableCell>{{ getCoverageTypeDescription(item.riskList[0].coverList[0].coverCode) }}</td>
                          <td nxTableCell>{{ item.effectiveDate ? (item.effectiveDate | date:'dd/MM/yyyy') : 'N/A' }}</td>
                          <td nxTableCell>{{ item.expiryDate ? (item.expiryDate | date:'dd/MM/yyyy') : 'N/A' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      <form [formGroup]="pageFilterForm" class="navigation">
        <div nxLayout="grid nopadding" class="nx-margin-top-2xs grid_remove_padding pagination_form">
          @if (showPagination && getResultLength() > 5) {
          <div nxRow>
            <div nxCol="12,12,6" class="nx-hidden-xs nx-hidden-s">
              <nx-natural-language-form size="small" class="pagination_form">
                <span nxCopytext="normal">Show</span>
                <nx-word>
                  <nx-dropdown formControlName="perPage" [panelGrow]="true" [panelMaxWidth]="500" [value]="perPage">
                    <nx-dropdown-item *ngFor="let item of itemsPerPageSelections" [value]="item"></nx-dropdown-item>
                  </nx-dropdown>
                </nx-word>
                <span nxCopytext="normal">results per page</span>
              </nx-natural-language-form>
            </div>

            <div class="nx-margin-xs margin-box nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"></div>
            @if (getResultLength() > 5) {
            <div nxCol="12,12,6">
              <nx-pagination [count]="getResultLength()" [page]="curpage" [perPage]="perPage" type="advanced"
                class="pagination_position" (goPrev)="prevPage()" (goNext)="nextPage()"
                (goPage)="goToPage($event)"></nx-pagination>
            </div>
            }
          </div>
          }
        </div>
      </form>
    </div>
  </div>
  <div nxModalActions class="makemodel_button nx-margin-top-m">
    <button nxButton="secondary" nxModalClose>Cancel</button>
  </div>
</div>