import {
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from "@angular/core";
import { CoverageComponent } from "../../../pages/coverage/coverage.component";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EndorsementPopupComponent } from "../../../popup/endorsement-popup/endorsement-popup.component";
import { STORE_ENDORSEMENT } from '@store/sat-motor/sat-motor.action';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { SharedModule } from "../../../shared/shared.module";
import { ProgressBarComponent } from "../../../components/progress-bar/progress-bar.component";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { CoveragePopupComponent } from "../../../popup/coverage-popup/coverage-popup.component";
import { NgIf, formatNumber } from "@angular/common";

import { PremiumSummaryComponent } from "@shared/components/premium-summary/premium-summary.component";
import { ExtraCoverageComponent } from "@shared/components/coverages/extra-coverage/extra-coverage.component";
import { Observable, pairwise, startWith } from "rxjs";
import { CALCULATE_PREMIUM_PAYLOAD, DELETE_COVERAGE, GET_NCD } from "@store/sat-motor/sat-motor.action";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NcdMessageComponent } from "@shared/components/modal/ncd-message/ncd-message.component";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-vehicle-sum-insured',
  standalone: true,
  imports: [
    SharedModule,
    NxSmallStageModule,
    TextFieldModule
  ],
  templateUrl: './vehicle-sum-insured.component.html',
  styleUrl: './vehicle-sum-insured.component.scss'
})
export class VehicleSumInsuredComponent {

  @Select(SatMotorState.endtStep2EndorsementDetails) endtEndorsementDetailsData$;

  @Input() endorsementType: any;

  underInsurenceApplies: boolean;
  sumInsuredRange: boolean;
  avIndChecked: boolean;

  isPrivateCar: boolean;
  isMotorcycle: boolean;
  isComprehensive: boolean;
  isThirdPartyFireTheft: boolean;
  isThirdParty: boolean;

  issuanceInfo: any;
  vehicleNo: any;
  decimalFmt: any;
  vehicleSumInsured: any;
  previousSumInsured: any;
  coverCode: any;
  coverDescp: any;
  vehicleClass: any;

  makeModelSIMin: any;
  makeModelSIMax: any;

  selectedMakeModel: any;
  agentDetail: any;
  avSi: any; //average sum insured
  oldValue = {};

  endtVehiclePolicyData: any;
  endtPreviousVehiclePolicyData: any;

  vehicleSumInsuredForm: FormGroup;

  onCoverFormChanged: any; //need to change to fix later


  templateDialogRef?: NxModalRef<any>;
  componentDialogRef?: NxModalRef<CoverageComponent>;

  @ViewChild("template") templateRef!: TemplateRef<any>;
  @ViewChild("template2") templateRef2!: TemplateRef<any>;
  @ViewChild("templatePolicyTable") templateRef3!: TemplateRef<any>;
  @ViewChild("autosize") autosize!: CdkTextareaAutosize;

  // tableHolder: any;
  // toText(value: string): string | null {
  //   return value ? value.toUpperCase() : null;
  // }

  // openDialog(template: TemplateRef<any>, config: { height?: string; width?: string } = {}): void {
  //   this.templateDialogRef = this.dialogService.open(template, {
  //     height: config.height || '500px',
  //     width: config.width || '780px',
  //   });
  // }

  closeDialog(): void {
    this.templateDialogRef?.close();
  }

  warningMessageModal?: NxModalRef<SimpleModalComponent>;

  constructor(
    private readonly dialogService: NxDialogService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store
  ) { }

  ngOnInit() {

    this.agentDetail = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.agentDetail);
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement.step1.issuance.issuance);
    this.vehicleNo = this.issuanceInfo.vehicleNo;

    this.loadVehicleSumInsured();
    // this.loadEndorsementDetailsData();
    /////////////////////////////////////////////////////////////////
    this.vehicleSumInsured = this.store.selectSnapshot<any>((state) => state.SatMotorState.endorsement?.step2?.endorsementDetails?.vehicleSumInsured);
    console.log("vehicleSumInsured:", this.vehicleSumInsured);

    const endtVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtVehicleData);
    const endtPreviousVehiclePolicyData = this.store.selectSnapshot<any>(state => state.SatMotorState?.endorsement?.step1?.endtPreviousVehicleData);

    this.endtVehiclePolicyData = endtVehiclePolicyData
    this.endtPreviousVehiclePolicyData = endtPreviousVehiclePolicyData

    // console.log("endtVehiclePolicyData:", this.endtVehiclePolicyData);
    // console.log("endtPreviousVehiclePolicyData:", this.endtPreviousVehiclePolicyData);

    // let customerData = endtVehiclePolicyData?.clientPartner;
    let agentData = endtVehiclePolicyData?.mkagAgent;
    let coverageData = endtVehiclePolicyData?.riskList;
    let coverList = endtVehiclePolicyData?.riskList[0]?.coverList;

    console.log("agentData:", agentData);
    console.log("coverageData:", coverageData);
    console.log("coverList:", coverList);

    let data = coverList[0];

    console.log("coverageData[0]:", coverageData[0]);

    this.vehicleClass = coverageData[0].vehicleClass; //type of vehicle class, ex: "01" for motorcycle, "02" for private car 
    this.coverCode = data.coverCode; //type of coverage code, ex: "01"
    this.coverDescp = data.coverDescp; //type of coverage description, ex: "COMPREHENSIVE"
    this.previousSumInsured = formatNumber(data.previousSumInsured, 'en-US');

    if (this.vehicleClass = "01") {
      this.allVehicleClassFalse();
      this.isMotorcycle = true;
    } else if (this.vehicleClass = "02") {
      this.allVehicleClassFalse();
      this.isPrivateCar = true;
    }

    switch (this.coverCode) {
      case '01':
        this.allCoverageTypeFalse();
        this.isComprehensive = true;
        break;
      case '20':
        this.allCoverageTypeFalse();
        this.isThirdParty = true;
        break;
      case '30':
        this.allCoverageTypeFalse();
        this.isThirdPartyFireTheft = true;
        break;
    }

    console.log("vehicleClass:", this.vehicleClass);
    console.log("coverCode:", this.coverCode);
    console.log("coverDescp:", this.coverDescp);
    console.log("previousSumInsured:", this.previousSumInsured);

    if (endtVehiclePolicyData && !this.vehicleSumInsured) {
      if (coverList) {
        this.vehicleSumInsuredForm.patchValue({
          sumInsured: data.sumInsured,
          avInd: data.avInd,
        });
      } else {
        console.error("coverList cannot populate vehicleSumInsuredForm");
      }
    } else if (endtVehiclePolicyData && this.vehicleSumInsured) {
      this.vehicleSumInsuredForm.patchValue(this.vehicleSumInsured);
    } else {
      console.error("endtVehiclePolicyData is undefined");
    }

    if (endtPreviousVehiclePolicyData) {
      let previousCoverageData = endtPreviousVehiclePolicyData?.riskList;
      let previousCoverList = endtPreviousVehiclePolicyData?.riskList[0]?.coverList;

      // console.log("previousCoverageData:", previousCoverageData);
      // console.log("previousCoverList:", previousCoverList);

      if (previousCoverList) {
        let data = previousCoverList[0];
        this.makeModelSIMin = data.makeModelSiMin;
        this.makeModelSIMax = data.makeModelSiMax;

        console.log("makeModelSIMin:", this.makeModelSIMin);
        console.log("makeModelSIMax:", this.makeModelSIMax);
      } else {
        console.error("previousCoverList is undefined");
      }
    } else {
      console.error("endtPreviousVehiclePolicyData is undefined");
    }
    ////////////////////////////////////////////////////////
  }

  allVehicleClassFalse() {
    this.isPrivateCar = false;
    this.isMotorcycle = false;
    }

  allCoverageTypeFalse() {
  this.isComprehensive = false;
  this.isThirdPartyFireTheft = false;
  this.isThirdParty = false;
  }

  onChangeForm($event: any) {
    
    this.avIndChecked = false;

    const data = {
      fieldName: $event.target.getAttribute("formControlName"),
      value: $event.target.value,
      oldValue: this.oldValue[$event.target.getAttribute("formControlName")],
    };

    const doChangeForm = {
      // sumInsured: () => {
      //   this.onCoverFormChanged.emit(data);
      // },
      // prevNcdVeh: () => {
      //   this.checkDisabledGetNCD();
      // },
    };

    if (data.oldValue !== data.value) {
      doChangeForm[data.fieldName]();
    }
  }

  loadVehicleSumInsured() {
    this.vehicleSumInsuredForm = this.fb.group({
      sumInsured: ["", Validators.required],
      avInd: false,
    });
  }

  loadEndorsementDetailsData() {
    this.endtEndorsementDetailsData$.subscribe((state) => {
      if (state) {
        if (state.vehicleSumInsured) {
          this.vehicleSumInsuredForm.patchValue(state.vehicleSumInsured);
        }
      }
    });
  }

  // ngAfterViewInit() {}

  setAgreedValue() { //to create agreed sum insured

    this.avIndChecked = this.avSi[this.agentDetail.region] && this.selectedMakeModel.inactiveSiInd === "N";

  }

  setRecommendedSumInsuredRange() { //to create recommended sum insured range
    this.selectedMakeModel = this.store.selectSnapshot<any>((state) => state.SatMotorState.data.step2?.endorsementDetails?.selectedMakeModel);
    if (this.selectedMakeModel) {
      this.avSi = {
        E: {
          avSiMin: this.selectedMakeModel.avEmSiMin, avSiMinDesc: formatNumber(Number(this.selectedMakeModel.avEmSiMin), 'en-US'),
          avSiMax: this.selectedMakeModel.avEmSiMax, avSiMaxDesc: formatNumber(Number(this.selectedMakeModel.avEmSiMax), 'en-US'),
        },
        W: {
          avSiMin: this.selectedMakeModel.avWmSiMin, avSiMinDesc: formatNumber(Number(this.selectedMakeModel.avWmSiMin), 'en-US'),
          avSiMax: this.selectedMakeModel.avWmSiMax, avSiMaxDesc: formatNumber(Number(this.selectedMakeModel.avWmSiMax), 'en-US'),
        },
      };
    }
  }

  // isPrivateCars() {
  //   return ["080100", "080200", "080300"].includes(this.issuanceInfo.productCode);
  // }

  // isMotorcycle() {
  //   return ["082100", "082101"].includes(this.issuanceInfo.productCode);
  // }

  // setSIWarning(sumInsured: any) {
  //   let value: any;
  //   if (this.isPrivateCars() && sumInsured < 10000) {
  //     value = "10000";
  //   }
  //   if (this.isMotorcycle() && sumInsured < 1000) {
  //     value = "1000";
  //   }

  //   if (value) {
  //     this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
  //       showCloseIcon: false, width: "400px", maxWidth: "400px",
  //       data: { type: "WARNING", message: "Sum insured must be at least RM" + formatNumber(Number(value), 'en-US') + "." },
  //     });

  //     this.warningMessageModal.afterClosed().subscribe((result) => {
  //       this.sumInsured.setValue(this.decimalFmt.transform(value));
  //       this.onCoverFormChanged.emit();
  //     });
  //   }
  // }

  setSumInsuredRange(sumInsured) { //to set sum insured range
    this.sumInsuredRange = sumInsured >= this.avSi[this.agentDetail.region].avSiMin && sumInsured <= this.avSi[this.agentDetail.region].avSiMax;
  }

  setUnderInsurenceApplies(sumInsured) { //to set under insurance applies
    this.underInsurenceApplies = sumInsured < ((90 / 100) * this.avSi[this.agentDetail.region].avSiMin);
  }

  /////////////////////////////////////////////////////
  get sumInsured() {
    return this.vehicleSumInsuredForm.controls["sumInsured"];
  }

  get avInd() {
    return this.vehicleSumInsuredForm.controls["avInd"];
  }
  ///////////////////////////////////////////////////

  storeEndorsementDetailsData() {
    if (this.vehicleSumInsuredForm.valid) {
      let vehicleSumInsured = this.vehicleSumInsuredForm.value;
      console.log('Saving Vehicle Sum Insured Data:', vehicleSumInsured);
      let payload = {
        vehicleSumInsured: vehicleSumInsured,
        isVehicleSumInsured: true
      }

      this.store.dispatch(new STORE_ENDORSEMENT(payload));
    } else {
      console.error('Vehicle Sum Insured form is invalid!');
      // this.logFormErrors(this.vehicleSumInsuredForm);
      this.vehicleSumInsuredForm.markAllAsTouched();
    }
  }

}
