<div class="std-ta-center">
  <h3 nxHeadline="subsection-large" class="nx-margin-bottom-m">
  	{{ type }}
  </h3>
  <p nxCopytext="normal" class="nx-font-weight-light nx-margin-bottom-m">
  	{{ message }}
  </p>
  <button nxModalClose class="nx-margin-bottom-m" nxButton="primary" type="button" (click)="onclickOK()">
  	{{ btnOkLabel }}
  </button>
</div>
