import { Component, Inject, OnInit } from '@angular/core';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';

@Component({
  selector: 'app-cp-same-agent-info',
  standalone: true,
  imports: [],
  templateUrl: './cp-same-agent-info.component.html',
  styleUrl: './cp-same-agent-info.component.scss'
})
export class CpSameAgentInfoComponent implements OnInit{
  message: any;
  type: any;

  constructor(
    @Inject(NX_MODAL_DATA) data: any,
    private dialogRef: NxModalRef<any>,
  ){
    this.type = data.type;
  }

  ngOnInit(): void {
    if(this.type === "MOBILE") {
      this.message = "The mobile number you have keyed in is an agent's mobile number. If you proceed with this number, it is deemed that you have obtained the customer's consent to use this number as the contact number for this policy. Please confirm if this is correct or otherwise key-in the mobile number provided by the customer.";
    }else {
      this.message = "The email you have keyed in is an agent's email. If you proceed with this email, it is deemed that you have obtained the customer's consent to use this email as the contact email for this policy. Please confirm if this is correct or otherwise key-in the email provided by the customer.";
    }
  }

  yes() {
    this.dialogRef.close("Y");
  }

  no() {
    this.dialogRef.close("N");
  }

}
