import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgIf, formatNumber } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { ExtraCoverageComponent } from "@shared/components/coverages/extra-coverage/extra-coverage.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { Observable, pairwise, startWith } from "rxjs";
import { CALCULATE_PREMIUM_PAYLOAD, DELETE_COVERAGE, GET_NCD } from "@store/sat-motor/sat-motor.action";
import { VehicleNoValidator } from "@shared/classes/validators/vehicle-no.validator";
import { NamedDriverComponent } from "@shared/components/named-driver/named-driver.component";
import { NcdMessageComponent } from "@shared/components/modal/ncd-message/ncd-message.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PremiumSummaryComponent } from "@shared/components/premium-summary/premium-summary.component";
import { FormatDecimalPipe } from "@shared/pipes/format-decimal/format-decimal.pipe";
import { RemoveCommaPipe } from "@shared/pipes/remove-comma/remove-comma.pipe";
import { SimpleModalComponent } from "@shared/components/modal/simple-modal/simple-modal.component";

@Component({
  selector: "app-coverage-third-party",
  standalone: true,
  imports: [NgIf, SharedModule, PremiumSummaryComponent, ExtraCoverageComponent, NamedDriverComponent],
  templateUrl: "./coverage-third-party.component.html",
  styleUrl: "./coverage-third-party.component.scss",
})
export class CoverageThirdPartyComponent implements OnInit {
  @Input() coverageFmGroup!: FormGroup;
  @Input() isThirdParty!: boolean;
  @Input() lov!: any;
  @Input() defaultDriver: any;
  @Input() issuanceInfo: any;

  @Output() onCoverDeleted = new EventEmitter<unknown>();
  @Output() onCoverFormChanged = new EventEmitter<unknown>();
  @Output() alimCustomerEligible = new EventEmitter<unknown>();

  @Select(SatMotorState.currentTypeOfCoverage) currentTypeOfCoverage$: Observable<SatMotorState>;
  @Select(SatMotorState.coveragePremium) coveragePremium$: Observable<SatMotorState>;

  thirdPartyForm: FormGroup<any>;
  vehicleAgeLoadingList: any;
  driverAgeLoadingList: any;
  claimsExpLoadingList: any;
  vehicleNo: any;
  typeOfCoverage: any;
  isShowExtraCoverage: boolean = false;
  oldValue = {};

  decimalFmt: any;
  numberFmt: any;

  isDisabledGetNCD: boolean;
  ncdMessageModal?: NxModalRef<NcdMessageComponent>;
  warningMessageModal?: NxModalRef<SimpleModalComponent>;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private readonly dialogService: NxDialogService,
  ) {
    this.decimalFmt = new FormatDecimalPipe();
    this.numberFmt = new RemoveCommaPipe();
    this.thirdPartyForm = this.fb.group({
      sumInsured: [{ value: 0, disabled: true }],
      annualPremium: [{ value: "", disabled: true }],
      grossPremium: [{ value: "", disabled: true }],
      basicPremium: [{ value: "", disabled: true }],
      vehicleAgeLoading: "",
      driverAgeLoading: "",
      claimExperienceLoading: "",
      loadingAmount: [{ value: "", disabled: true }],
      ncdPercentage: [{ value: "", disabled: true }],
      ncdAmount: [{ value: "", disabled: true }],
      prevNcdInsurer: "",
      prevNcdVeh: ["", VehicleNoValidator()],
    });
  }

  ngOnInit() {
    this.issuanceInfo = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step1.issuance.issuance);
    this.vehicleNo = this.issuanceInfo.vehicleNo;

    this.typeOfCoverage = this.store.selectSnapshot<any>((state) => state.SatMotorState.motor.step3?.coverage?.coverageType);
    if (this.typeOfCoverage  && this.isThirdParty) {
      this.thirdPartyForm.patchValue({
        sumInsured: this.decimalFmt.transform(this.typeOfCoverage["sumInsured"]),
        excessAmount: this.decimalFmt.transform(this.typeOfCoverage["excessAmount"]),
        annualPremium:this.decimalFmt.transform( this.typeOfCoverage["annualPremium"]),
        basicPremium: this.decimalFmt.transform(this.typeOfCoverage["basicPremium"]),
        grossPremium: this.decimalFmt.transform(this.typeOfCoverage["grossPremium"]),
        ncdPercentage: this.decimalFmt.transform(this.typeOfCoverage["ncdPercentage"]),
        ncdAmount: this.decimalFmt.transform(this.typeOfCoverage["ncdAmount"]),
        prevNcdVeh: this.decimalFmt.transform(this.typeOfCoverage["prevNcdVeh"]),
        prevNcdInsurer: this.typeOfCoverage["prevNcdInsurer"],
      });
    }

    this.thirdPartyForm.valueChanges.pipe(startWith(this.thirdPartyForm.value), pairwise()).subscribe(([old, value]) => {
      this.oldValue = old;
    });

    this.currentTypeOfCoverage$.subscribe((state) => {
      if (state) {
        if (state["coverCode"] === "20") {
          this.coverageFmGroup.controls["typeOfCoverage"] = this.thirdPartyForm;
          this.typeOfCoverage = state;
          if (this.typeOfCoverage && this.isThirdParty) {
            let coverListLov = this.store.selectSnapshot<any>((state) => state.SatMotorState.data?.step3?.coverage?.coverListLov);
            for (let coverList of coverListLov) {
              if (coverList["coverCode"] === "20") {
                this.typeOfCoverage = { ...this.typeOfCoverage, coverDescription: coverList["coverDescription"] };
              }
            } 
          }
        }
      }
    });

    this.coveragePremium$.subscribe((state) => {
      if (state) {
        const cngeNote = state["cngeNote"];
        if (cngeNote) {
          const riskList = cngeNote["riskList"];
          if (riskList?.length) {
            for (let riskListItem of riskList) {
              this.prevNcdVeh.setValue(riskListItem.vehicleNo);
              const coverList = riskListItem["coverList"];
              if (coverList?.length) {
                for (let coverListItem of coverList) {
                  if (this.isThirdParty && coverListItem["coverDto"].subCovList?.length > 0) {
                    this.isShowExtraCoverage = true;
                  }
                  
                  if (coverListItem["coverCode"] !== "20") break;
                  this.typeOfCoverage = {
                    ...this.typeOfCoverage,
                    ...{ grossPremium: coverListItem["grossPremium"] },
                    ...{ basicPremium: coverListItem["basicPremium"] },
                    ...{ ncdPercentage: coverListItem["ncdPercentage"] },
                    ...{ ncdAmount: coverListItem["ncdAmount"] },
                    ...{ subCoverPremium: coverListItem["subCoverPremium"] },
                    ...{ loadPercentage: coverListItem["loadPercentage"] },
                    ...{ loadAmount: coverListItem["loadAmount"] },
                  };
                  const grossPremiumWarning = this.setGrossPremiumWarning(coverListItem["grossPremium"]);
                  if (!grossPremiumWarning) {
                    if (coverListItem.alimCustomerEligible) {
                      this.alimCustomerEligible.emit();
                    }
                  }
                  const coverDto = coverListItem["coverDto"];
                  if (coverDto) {
                    this.vehicleAgeLoadingList = coverDto["vehicleAgeLoadingList"];
                    this.driverAgeLoadingList = coverDto["driverAgeLoadingList"];
                    this.claimsExpLoadingList = coverDto["claimsExpLoadingList"];
                  }
                }
              }
            }
          }
        }
      }
    });

    this.checkDisabledGetNCD();
  }

  onChangeForm($event: any) {
    const data = {
      fieldName: $event.target.getAttribute("formControlName"),
      value: $event.target.value,
      oldValue: this.oldValue[$event.target.getAttribute("formControlName")],
    };

    const doChangeForm = {
      sumInsured: () => {
        this.onCoverFormChanged.emit(data);
      },
      prevNcdVeh: () => {
        this.checkDisabledGetNCD();
      },
    };

    if (data.oldValue !== data.value) {
       doChangeForm[data.fieldName]();
    }
  }

  deleteCover(cover: any) {
    this.store.dispatch(new DELETE_COVERAGE(cover)).subscribe((state) => {
      this.thirdPartyForm.reset();
      this.onCoverDeleted.emit();
    });
    this.isShowExtraCoverage = false;
  }

  checkDisabledGetNCD() {
    let vehicleNo = this.thirdPartyForm.get("prevNcdVeh");
    if (this.issuanceInfo.coverNoteType === "NWTR" && vehicleNo.valid && vehicleNo.value) {
      this.isDisabledGetNCD = false;
    } else {
      this.isDisabledGetNCD = true;
    }
  }

  onClickGetNCD() {
    this.store.dispatch(new CALCULATE_PREMIUM_PAYLOAD({})).subscribe((state) => {
      this.store.dispatch(new GET_NCD(state.SatMotorState.data.step3.coverage.coveragePremiumPayload)).subscribe((state) => {
        let ncdInfo = state.SatMotorState.data.step3.coverage.coverageNCD.cngeNote;
        if (ncdInfo.ncdMessageList) {
          this.ncdMessageModal = this.dialogService.open(NcdMessageComponent, {
            data: {
              message: ncdInfo.ncdMessageList[0],
            },
          });
        } else {
          this.thirdPartyForm.patchValue({
            ncdPercentage: ncdInfo.ncdPercentage,
          });
        }
      });
    });
  }

  openPopup(popup_type: any) {}

  openMessage(popup_type: any) {}

  openPopupComp(popup_type: any) {}

  openPopupMakeModel(popup_type: any) {}

  isPrivateCars() {
    return ["080100", "080200", "080300"].includes(this.issuanceInfo.productCode);
  }

  isMotorcycle() {
    return ["082100", "082101"].includes(this.issuanceInfo.productCode);
  }

  setGrossPremiumWarning(grossPremium : any) {
    let value : any;
    if (this.isPrivateCars() && grossPremium < 50) {
      value = "50";
    }
    if (this.isMotorcycle() && grossPremium < 20) {
      value = "20";
    }
    
    if (value) {
      this.warningMessageModal = this.dialogService.open(SimpleModalComponent, {
        showCloseIcon: false, width: "400px", maxWidth: "400px",
        data: { type: "Warning", message: "Minimum Gross Premium Applied." },
      });
	  
      this.warningMessageModal.afterClosed().subscribe((result) => {
        this.typeOfCoverage = { ...this.typeOfCoverage, ...{ grossPremium: this.decimalFmt.transform(value) } };
        this.onCoverFormChanged.emit(this.typeOfCoverage);
      });

      return true;
    }

    return false;
  }

  get prevNcdVeh() {
    return this.thirdPartyForm.controls["prevNcdVeh"];
  }
}
