<div nxRow class="nx-margin-top-30">
  <div nxCol="12">
    <button nxPlainButton type="button" class="p-0" (click)="restartSearch()">
      <nx-icon name="arrow-right" nxIconPositionStart aria-hidden="true"></nx-icon>SEARCH AGAIN
    </button>
  </div>
</div>

<div nxRow class="nx-margin-top-m">
  <div nxCol="12">
    <nx-message context="success">
      <span class="nx-font-weight-bold">{{ isCompany ? 'Company' : 'Customer' }} Found</span>
    </nx-message>
  </div>
</div>

@if(isUpdatedCP) {
  <div nxRow class="nx-margin-top-m">
    <div nxCol="12">
      <nx-message context="success">
        <span class="nx-font-weight-bold">Customer Partner Information is successfully updated.</span>
      </nx-message>
    </div>
  </div>
}

<!-- <div nxLayout="grid nogutters nopadding" class="nx-margin-top-m"> -->
  <!-- <div nxRow>
    <div nxCol="12"> -->
    <div class="nx-margin-top-m">
      <app-customer-partner-details [cpType]="'FOUND'" [cpDetails]="cpDetails" [lov]="lov" (openEdit)="openEdit($event)">
      </app-customer-partner-details>
    </div>
    <!-- </div> -->
  <!-- </div> -->

  <div nxRow>
    <div nxCol="12" class="nx-margin-top-m">
      <p nxCopytext="medium">
        <i>
          By clicking NEXT, you confirm that the address submitted is
          complete and accurate to the best of your knowledge.
        </i>
      </p>
    </div>
  </div>

<div nxRow class="nx-margin-top-50 nx-margin-bottom-3xl">
  <div nxCol="12" class="button-container">
    <button nxButton="secondary" type="button" (click)="goBack()">Back</button>
    @if (isSaved && isSaved.cnoteStatus && isSaved.cnoteStatus=== 'SAVED') {
      <button type="button" nxButton="secondary" (click)="cancelDraft()">
        {{ 'GLOBAL.ACTION.CANCEL_DRAFT' | translate }}
      </button>
    }
    <button *ngIf="!hideSaveDraftBtn" nxButton="secondary" type="button" (click)="saveAsDraft()">SAVE AS DRAFT</button>
    <button nxButton="primary" type="button" (click)="nextPage()">NEXT</button>
  </div>
  <div nxCol="12"></div>
</div>
