
import {
  ChangeDetectorRef,
  Component,
  importProvidersFrom,
  ViewChild,
} from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SharedModule } from "../../../../shared/shared.module";
import { ProgressBarComponent } from "../../../../components/progress-bar/progress-bar.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PdpaPopupComponent } from "../../../../popup/pdpa-popup/pdpa-popup.component";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, Select } from '@ngxs/store';
import { STORE_DECLARATION, SEARCH_ACTIVE_QUOTATION, GET_PREVIOUS_POLICY_NO, GET_LOV, GET_PRODUCT_LIST, SEARCH_VIX, SET_CURRENT_STEP} from '@store/sat-motor/sat-motor.action';
import { IssueEndorsementValidationComponent } from "../../endorsement/issue-endorsement-validation/issue-endorsement-validation.component";
import { EnquiryPopupComponent } from "../../../../popup/enquiry-popup/enquiry-popup.component";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { PdpaComponent } from "@shared/components/declaration-issuance/pdpa/pdpa.component";
import { IssuanceComponent } from "@shared/components/declaration-issuance/issuance/issuance.component";

@Component({
  selector: 'app-declaration-issuance-info',
  standalone: true,
  imports: [
    SharedModule, 
    ProgressBarComponent, 
    IssueEndorsementValidationComponent,
    PdpaComponent,
    IssuanceComponent
  ],
  templateUrl: './declaration-issuance-info.component.html',
  styleUrl: './declaration-issuance-info.component.scss'
})
export class DeclarationIssuanceInfoComponent {

  @Select(SatMotorState.motorActionType) actionType$;

  isIssuance: boolean = false; 
  isEndorsement: boolean = false; 
  isEnquiry: boolean = false;
  isRTP: boolean = false;

  constructor(
    private dialogService: NxDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public store: Store,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
    
    this.store.dispatch(new SET_CURRENT_STEP(1));
    this.actionType$.subscribe((state) => {
      if (state) {
        this.isIssuance = state.isIssuance;
        this.isEndorsement = state.isEndorsement;
        this.isEnquiry = state.isEnquiry;
        this.isRTP = state.isRTP;
      }
    });

  }
}