import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-view-coverage-details',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './view-coverage-details.component.html',
  styleUrl: './view-coverage-details.component.scss'
})
export class ViewCoverageDetailsComponent implements OnInit {
  @Input() data;
  @Input() isApproval;
  @Input() progressType;
  @Input() product;
  @Input() cnoteType;
  @Input() lov;
  isProgressTypeCN: boolean = false;
  isProgressTypeENDST: boolean = false;

  ngOnInit() {
    if(this.progressType) {
      this.isProgressTypeCN = this.progressType === 'cn';
      this.isProgressTypeENDST = this.progressType === 'endorsement';
    }
  }

  showRSI() {
    if (this.makeModelSiMin > 0 || this.makeModelSiMax > 0) {
      if (this.data.vehicleClass === "02" && (this.data.riskList[0].coverList[0].coverCode === "01" || this.data.riskList[0].coverList[0].coverCode === "30") ||
        this.data.vehicleClass === "01" && (this.data.riskList[0].coverList[0].coverCode === "01" || this.data.riskList[0].coverList[0].coverCode === "30")) {
        return true;
      } else {
        return false;
      }
    }else {
      return false;
    }
  }

  showAVOrRV() {
    if(this.data.vehicleClass === "02" && (this.data.riskList[0].coverList[0].coverCode === "01" || this.data.riskList[0].coverList[0].coverCode === "30") ||
    this.data.vehicleClass === "01" && (this.data.riskList[0].coverList[0].coverCode === "01" || this.data.riskList[0].coverList[0].coverCode === "30")) {
      return true;
    }else {
      return false;
    }
  }

  formatData(value: string) {
    if (!value || value === '' || value === 'NA') {
        return 'N/A';
    }
    return value;
  }

  getDateFormat(value: string): string {
    if(!value || value === '') {
      return null;
    }
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formatNumericData(value: string) {
    if (!value) {
      return 0;
    }
    return value;
  }

  formatSumInsured(data) {
    if(data && data != '' && data != undefined) {
      return data.toLocaleString();
    }
    return data;
  }

  get recommendSumInsured() {
    let value = this.makeModelSiMin ? this.makeModelSiMin : this.makeModelSiMax;
    if(value) {
      return "RM" + value;
    }else {
      return null;
    }
  }

  get recommendSumInsuredRange() {
    let min = this.makeModelSiMin;
    let max = this.makeModelSiMax;
    if(min && max) {
      return "RM" + min + "-" + "RM" + max;
    }else {
      return null;
    }
  }

  get noClaimDiscountFrom() {
    let noClaimDiscountFrom = this.lov.insurerList.find(a => a.code == this.data.riskList[0].prevNcdInsurer);

    return (noClaimDiscountFrom && noClaimDiscountFrom.descp) ? noClaimDiscountFrom.descp : null;
  }


  get isPrivateCar() {
    if(this.product === '080100' || this.product === '080200' || this.product === '080300') {
      return true;
    }else {
      return false;
    }
  }

  get coverageType() {
    let coverageType;
    switch(this.data.riskList[0].coverList[0].coverCode) {
      case '01':
        coverageType = "COMPREHENSIVE";
        break;
      case '20':
        coverageType = "THIRD PARTY";
        break;
      case '30':
        coverageType = "THIRD PARTY, FIRE AND THEFT";
        break;
      default:
        coverageType = null;
        break;
    }

    return coverageType;
  }

  get makeModelSiMin () {
    return this.data.region === 'W' ? this.data.riskList[0].avWmSiMin : this.data.region === 'E' ? this.data.riskList[0].avEmSiMin : undefined;
  }

  get makeModelSiMax () {
    return this.data.region === 'W' ? this.data.riskList[0].avWmSiMax : this.data.region === 'E' ? this.data.riskList[0].avEmSiMax : undefined;
  }

}
