import { Component, Inject, OnInit } from "@angular/core";
import { NX_MODAL_DATA, NxModalRef } from "@aposin/ng-aquila/modal";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { SharedModule } from "@shared/shared.module";
import { SatMotorState } from "@store/sat-motor/sat-motor.state";
import { DocumentArrayModel, DocumentDetails, ProductFiles, UploadDocument, UploadedFileDetails } from "@interfaces/fileupload";
import {
  COMMIT_UPLOAD_DOCUMENT,
  DELETE_UPLOAD_DOCUMENT,
  DOWNLOAD_DOCUMENT, GET_DOCUMENTS,
  INITIATE_UPLOAD_DOCUMENT,
  REQUOTE_UPLOAD_COPIED_DOCUMENTS,
  RESET_UPLOAD_DOCUMENTS,
  STORE_CART_NAVIGATION,
  UPLOAD_DOCUMENT
} from "@store/sat-motor/sat-motor.action";
import { forkJoin } from "rxjs";
import { UserResponse } from "@interfaces/user";

@Component({
  selector: "app-upload-document",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./upload-document.component.html",
  styleUrl: "./upload-document.component.scss",
})
export class UploadDocumentComponent implements OnInit {
  @Select(SatMotorState.mandatoryDocumentList) mandatoryDocumentList$: any;
  @Select(SatMotorState.optionalDocumentList) optionalDocumentList$: any;
  @Select(SatMotorState.getUploadDocumentInfo) uploadDocumentInfo$: any;

  userInfo: UserResponse;

  optionalDocForm: FormGroup;
  copyIndForm: FormGroup;

  copyCheckboxClicked: boolean = false;
  arePreviousDocumentsCopied: boolean = false;
  equoteNo: any;
  deleteFail: boolean = false;
  deleteFailDocName: any = "";
  acceptFileType: any = "";

  file: File;
  fileArray: Array<ProductFiles> = [];
  maximumFileSize: number = 3145728;

  mandatoryDocumentList: any;
  mandatoryDocArray: Array<DocumentDetails> = [];
  optionalDocumentList: Array<any> = [];
  optionalDocArray: Array<DocumentDetails> = [];

  currentProductCode: any;
  cartNavigation: any;

  constructor(
    @Inject(NX_MODAL_DATA) public data: UploadDocument.Data,
    private dialogRef: NxModalRef<any>,
    private fb: FormBuilder,
    private store: Store,
  ) {
    this.optionalDocForm = this.fb.group({
      document: [""],
    });
    this.copyIndForm = this.fb.group({
      copyInd: [""],
    });
    this.store.dispatch(new RESET_UPLOAD_DOCUMENTS());
  }

  ngOnInit(): void {
    this.userInfo = this.store.selectSnapshot<UserResponse>((state) => state.UserState.user);
    this.mandatoryDocumentList = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov.documentList.mandatoryDocumentList);

    this.data.products.forEach(val => {
      let mandDocs = [];
      const mandData: DocumentDetails = {
        productCode: val.productCode,
        document: mandDocs
      };
      this.mandatoryDocArray.push(mandData);

      const data = {
        productCode: val.productCode,
        optionalDocumentList: this.store.selectSnapshot<any>(state => state.SatMotorState.lov.documentList.optionalDocumentList)[val.productCode]
      };
      this.optionalDocumentList.push(data);

      const docData: DocumentDetails = {
        productCode: val.productCode,
        document: []
      }
      this.optionalDocArray.push(docData);

      this.fileArray.push({
        productCode: val.productCode,
        files: []
      });
    });

    const payload = {
      key: 'equoteNo',
      value: this.equoteNo
    };
    this.getUploadedDocuments(payload);

  }

  getUploadedDocuments(payload) {
    this.store.dispatch(new GET_DOCUMENTS(payload)).subscribe(state => {
      this.data.products.forEach(val => {

      });
    });
  }

  getMandatoryDoc(productCode) {
    const response = this.mandatoryDocArray.find((opt) => opt.productCode === productCode);
    return response && response.document ? response.document : [];
  }

  getOptionalDoc(productCode) {
    const response = this.optionalDocArray.find((opt) => opt.productCode === productCode);
    return response && response.document ? response.document : [];
  }

  getDocData(doc) {
    return doc.data ? doc.data : [];
  }

  getFileName(doc, productCode) {
    const response = this.fileArray.find((val) => val.productCode === productCode);
    return response && response.files.length > 0 && response.files.find((file) => file.docCode === doc.code && file.tempDocumentId === doc.id) ? response.files.find((file) => file.docCode === doc.code && file.tempDocumentId === doc.id).fileName : "";
  }

  fileChange(file, productCode, doc) {
    this.file = file.target.files[0];
    if (this.file.size > this.maximumFileSize) {
      //this.notificationService.openNotificationDialog("File size exceeds size limit, it must be less than 3MB.");
    } else {
      const data: UploadedFileDetails = {
        tempDocumentId: doc.id,
        docIdFromDb: doc.docId ? doc.docId : "",
        docCode: doc.code,
        fileName: this.file.name,
        file: this.file,
        retrievedFromDb: false,
        productCode: productCode,
      };
      this.addDataIntoFileArray(data, productCode, doc);
      if (this.copyCheckboxClicked) this.copyFileToSameDocuments(productCode, doc, this.file);
    }
  }

  addDataIntoFileArray(data, productCode, docModelData) {
    let doc = docModelData;
    if (Array.isArray(docModelData)) {
      doc = docModelData.find((d) => d.docId === data.docIdFromDb);
    }
    const indexToSplice = this.fileArray.find((file) => file.productCode === productCode).files.findIndex((val) => val.docCode === doc.code && val.tempDocumentId === doc.id);
    if (doc.docId) {
      if (indexToSplice > -1) {
        this.fileArray.find((file) => file.productCode === productCode).files[indexToSplice] = data;
      } else {
        this.fileArray.find((file) => file.productCode === productCode).files.push(data);
      }
    } else {
      if (indexToSplice > -1) {
        this.fileArray.find((file) => file.productCode === productCode).files.splice(indexToSplice, 1);
      }
      this.fileArray.find((file) => file.productCode === productCode).files.push(data);
    }
  }

  copyFileToSameDocuments(productCode, docData, file) {
    let filteredDocList = [];
    if (docData.mandatoryInd) {
      filteredDocList = this.mandatoryDocArray.filter((doc) => doc.productCode !== productCode);
    } else {
      filteredDocList = this.optionalDocArray.filter((doc) => doc.productCode !== productCode);
    }
    filteredDocList.forEach((doc) => {
      if (doc.document.find((val: { docCode: any }) => val.docCode === docData.code) && !this.checkCopyFile(doc.productCode, docData.code)) {
        const data: UploadedFileDetails = {
          tempDocumentId: Math.max(...doc.document.find((val: { docCode: any }) => val.docCode === docData.code).data.map((o) => o.id), 1),
          docIdFromDb: docData.docId,
          docCode: docData.code,
          fileName: file.name,
          file: file,
          retrievedFromDb: false,
          productCode: docData.productCode,
        };
        this.addDataIntoFileArray(
          data,
          doc.productCode,
          doc.document.find((val: { docCode: any }) => val.docCode === docData.code),
        );
      }
    });
  }

  checkCopyFile(productCode, docCode) {
    const fileExist = this.fileArray.find((file) => file.productCode === productCode).files.find((val) => val.docCode === docCode);
    return !!fileExist;
  }

  addMoreFile(docData, productCode, docType) {
    this.currentProductCode = productCode;
    switch (docType) {
      case "MANDATORY":
        this.addMandatoryExtraDoc(docData, docType);
        break;

      case "OPTIONAL":
        this.addOptionalExtraDoc(docData, docType);
        break;

      default:
        break;
    }
  }

  addMandatoryExtraDoc(selectedDoc, docType) {
    const existedData = this.mandatoryDocArray.find((opt) => opt.productCode === this.currentProductCode).document.find((doc) => doc.docCode === selectedDoc.code);
    if (existedData) {
      const newId = Math.max(...existedData.data.map((o) => o.id), 1) + 1;
      const data = {
        ...selectedDoc,
        docId: "",
        id: newId,
        productCode: docType,
      };
      this.mandatoryDocArray
        .find((opt) => opt.productCode === this.currentProductCode)
        .document.find((doc) => doc.docCode === selectedDoc.code)
        .data.push(data);
    } else {
      const docData: DocumentArrayModel = {
        docCode: selectedDoc.code,
        docName: selectedDoc.descp,
        data: [
          {
            ...selectedDoc,
            docId: "",
            id: 1,
            productCode: docType,
          },
        ],
      };
      this.mandatoryDocArray.find((opt) => opt.productCode === this.currentProductCode).document.push(docData);
    }
  }

  addOptionalExtraDoc(selectedDoc, docType) {
    console.warn(this.optionalDocArray);
    const existedData = this.optionalDocArray.find((opt) => opt.productCode === this.currentProductCode).document.find((doc) => doc.docCode === selectedDoc.code);
    if (existedData) {
      const newId = Math.max(...existedData.data.map((o) => o.id), 1) + 1;
      const data = {
        ...selectedDoc,
        docId: "",
        id: newId,
        productCode: docType,
      };
      this.optionalDocArray
        .find((opt) => opt.productCode === this.currentProductCode)
        .document.find((doc) => doc.docCode === selectedDoc.code)
        .data.push(data);
    } else {
      const docData: DocumentArrayModel = {
        docCode: selectedDoc.code,
        docName: selectedDoc.descp,
        data: [
          {
            ...selectedDoc,
            docId: "",
            id: 1,
            productCode: docType,
          },
        ],
      };
      this.optionalDocArray.find((opt) => opt.productCode === this.currentProductCode).document.push(docData);
    }
    this.updateOptionalDocList(this.currentProductCode);
  }

  updateOptionalDocList(productCode) {
    let optionDocList = JSON.parse(JSON.stringify(this.store.selectSnapshot<any>((state) => state.SatMotorState.lov.documentList.optionalDocumentList)))[productCode];
    let codesToRemove = [];
    const selectedOptionalDocs = this.optionalDocArray.find((data) => data.productCode === productCode).document;
    selectedOptionalDocs.forEach((doc) => {
      if (doc.data.length > 0) {
        codesToRemove.push(doc.docCode);
      }
    });
    console.warn(optionDocList);
    this.optionalDocumentList.find((doc) => doc.productCode === productCode).optionalDocumentList = optionDocList.filter((item) => codesToRemove.indexOf(item.code) === -1);
  }

  deleteMandatoryRow(doc, productCode) {
    if (!doc.docId && doc.id > 1) {
      let indexToSplice = this.mandatoryDocArray
        .find((mand) => mand.productCode === productCode)
        .document.find((val) => val.docCode === doc.code)
        .data.findIndex((item) => item.id === doc.id);
      this.mandatoryDocArray
        .find((opt) => opt.productCode === productCode)
        .document.find((val) => val.docCode === doc.code)
        .data.splice(indexToSplice, 1);
    }
    this.removeFileData(productCode, doc, "Mandatory");
  }

  removeFileData(productCode, doc, docType) {
    switch (docType) {
      case "Mandatory":
        const manFileIndex = this.fileArray.find((data) => data.productCode === productCode).files.findIndex((item) => item.docCode === doc.code && item.tempDocumentId === doc.id);
        if (manFileIndex > -1) {
          if (doc.docId) {
            this.store.dispatch(new DELETE_UPLOAD_DOCUMENT(doc, this.equoteNo)).subscribe((state) => {
              console.warn(state.NEQTState.uploadDocument.result.success);
              if (state.NEQTState.uploadDocument.result.success) {
                this.fileArray.find((data) => data.productCode === productCode).files[manFileIndex].file = null;
                this.fileArray.find((data) => data.productCode === productCode).files[manFileIndex].fileName = null;
              } else {
                this.deleteFail = true;
                this.deleteFailDocName = doc.name;
                console.warn(doc);
              }
            });
          } else {
            console.warn(this.fileArray);
            this.fileArray.find((data) => data.productCode === productCode).files.splice(manFileIndex, 1);
          }
        }
        break;

      case "Optional":
        const optFileIndex = this.fileArray.find((data) => data.productCode === productCode).files.findIndex((item) => item.docCode === doc.code && item.tempDocumentId === doc.id);
        if (optFileIndex > -1) {
          if (doc.docId) {
            this.store.dispatch(new DELETE_UPLOAD_DOCUMENT(doc, this.equoteNo)).subscribe((state) => {
              console.warn(state.NEQTState.uploadDocument.result.success);
              if (state.NEQTState.uploadDocument.result.success) {
                this.fileArray.find((data) => data.productCode === productCode).files[optFileIndex].file = null;
                this.fileArray.find((data) => data.productCode === productCode).files[optFileIndex].fileName = null;
              } else {
                this.deleteFail = true;
                this.deleteFailDocName = doc.name;
                console.warn(doc);
              }
            });
          } else {
            console.warn(this.fileArray);
            this.fileArray.find((data) => data.productCode === productCode).files.splice(optFileIndex, 1);
          }
        }
        break;

      default:
        break;
    }
  }

  viewDoc(doc, productCode) {
    const retrievedFromDb = this.fileArray.find((file) => file.productCode === productCode).files.find((val) => val.docCode == doc.code && val.tempDocumentId === doc.id).retrievedFromDb;
    if (retrievedFromDb) {
      this.store.dispatch(
        new DOWNLOAD_DOCUMENT({
          docId: doc.docId,
          fileName: doc.fileName,
        }),
      );
    } else {
      //this.notificationService.openNotificationDialog("This file has not been uploaded yet.");
    }
  }

  getProductMandatoryDocLength(docList, productCode) {
    let length = 0;
    const mandatoryDocLength = docList[productCode] ? docList[productCode].length : 0;
    return length + mandatoryDocLength;
  }

  deleteOptionalRow(doc, productCode) {
    if (!doc.docId) {
      let indexToSplice = this.optionalDocArray
        .find((opt) => opt.productCode === productCode)
        .document.find((val) => val.docCode === doc.code)
        .data.findIndex((item) => item.id === doc.id);
      this.optionalDocArray
        .find((opt) => opt.productCode === productCode)
        .document.find((val) => val.docCode === doc.code)
        .data.splice(indexToSplice, 1);

      const latestLength = this.optionalDocArray.find((opt) => opt.productCode === productCode).document.find((val) => val.docCode === doc.code).data.length;
      if (latestLength === 0) {
        const index = this.optionalDocArray.find((opt) => opt.productCode === productCode).document.findIndex((val) => val.docCode === doc.code);
        this.optionalDocArray.find((opt) => opt.productCode === productCode).document.splice(index, 1);
      }
    }
    this.removeFileData(productCode, doc, "Optional");
    this.updateOptionalDocList(productCode);
  }

  optionalDocByProd(documentList, productCode) {
    return documentList[productCode] ? documentList[productCode] : [];
  }

  getOptionalDocumentList(productCode) {
    const optionalList = this.optionalDocumentList;console.log(this.optionalDocumentList.length > 0 ? this.optionalDocumentList.find((doc) => doc.productCode === productCode).optionalDocumentList : []);
    return this.optionalDocumentList.length > 0 ? this.optionalDocumentList.find((doc) => doc.productCode === productCode).optionalDocumentList : [];
  }

  getFailedDoc() {
    const optionalDocList = this.store.selectSnapshot<any>((state) => state.SatMotorState.lov.documentList.optionalDocumentList);
    const uploadDocState = this.store.selectSnapshot<any>((state) => state.SatMotorState.uploadDocument);
    let combinedDocLovList = [];

    const productList = Object.keys(this.mandatoryDocumentList);
    productList.forEach((productCode) => {
      combinedDocLovList = combinedDocLovList.concat(this.mandatoryDocumentList[productCode]);
      combinedDocLovList = combinedDocLovList.concat(optionalDocList[productCode]);
    });

    let failedDocDescp = [];
    uploadDocState.failedDocCode.forEach((code) => {
      failedDocDescp.push(combinedDocLovList.find((doc) => doc.code === code).descp);
    });

    return failedDocDescp.length > 0 ? failedDocDescp.toString() : null;
  }

  uploadDocument() {
    this.deleteFail = false;
    this.deleteFailDocName = "";
    //TODO: this.loadingScreenService.startLoading();
    let payloadArray = this.prepareUploadData();

    if (this.cartNavigation.requote) {
      // update (uploadDocumentInfo$ | async)?.isRequotedDocCopied state, to hide success copied from prev msg
      const payload = { isRequotedDocCopied: false };
      this.store.dispatch(new REQUOTE_UPLOAD_COPIED_DOCUMENTS(payload));
    }

    if (payloadArray.length === 0) {
      //TODO: this.notificationService.openNotificationDialog("No file changes detected.");
      //TODO: this.loadingScreenService.stopLoading();
      return;
    }
    console.warn(this.equoteNo);
    let list = [];
    const initiatePayload = { equoteNo: this.equoteNo };
    this.store.dispatch(new INITIATE_UPLOAD_DOCUMENT(initiatePayload)).subscribe((val) => {
      for (let index = 0; index < payloadArray.length; index++) {
        payloadArray[index].request.transactionId = val.NEQTState.uploadDocument.trxnId;
        if (!payloadArray[index].request.documents[0].requoteInd) {
          list.push(this.store.dispatch(new UPLOAD_DOCUMENT(payloadArray[index])));
        }
      }

      // observe all the observable in list then trigger commit upload document.
      forkJoin(list).subscribe((states) => {
        const uploadDocState = states[0].NEQTState.uploadDocument;
        const commitPayload = {
          trxnId: uploadDocState.trxnId,
        };
        this.store.dispatch(new COMMIT_UPLOAD_DOCUMENT(commitPayload)).subscribe((states) => {
          //TODO: this.loadingScreenService.stopLoading();
        });
        if (!uploadDocState.isUploadFailed) {
          this.copyDocFromPrevious(this.copyIndForm.get("copyInd").value);
          this.resetArrays();
          this.ngOnInit();
        }
      });
    });
  }

  prepareUploadData() {
    let payloadArray = [];
    this.fileArray.forEach((fileArr) => {
      let contractId;
      let docIdNo = 0;
      if (fileArr.files.length > 0) {
        contractId = fileArr.files.find((val) => val.productCode === fileArr.productCode)["contractId"];
      }

      if (!contractId) contractId = this.data.products.find((val) => val.productCode === fileArr.productCode).contractId;
      fileArr.files.forEach((file) => {
        //const contractId = this.data.products.find(val => val.productCode === fileArr.productCode).contractId;
        if (!file.retrievedFromDb || (this.cartNavigation.requote && file.retrievedFromDb)) {
          const request = {
            operator: this.userInfo.userId,
            transactionId: null,
            documents: [
              {
                docId: file.docIdFromDb ? file.docIdFromDb : "",
                contractId: contractId,
                code: file.docCode,
                updatePreviousRecordInd: file.docIdFromDb && !this.cartNavigation.requote ? true : false,
                requoteInd: this.cartNavigation.requote && file.docIdFromDb ? true : false,
                productCode: file.productCode,
              },
            ],
          };
          const payload = {
            file: file.file,
            request: request,
          };
          // for requote, if doc id empty, count docIdNo++, if docIdNO = 0, prompt No file changes detected.
          if (!file.docIdFromDb) {
            docIdNo++;
          }
          payloadArray.push(payload);
        }
      });
      if (this.cartNavigation.requote && docIdNo === 0) {
        //TODO: this.notificationService.openNotificationDialog("No file changes detected.");
        //TODO: this.loadingScreenService.stopLoading();
      }
    });
    return payloadArray;
  }

  copyDocFromPrevious(ind) {
    let payload = {
      action: "requoteCopyDoc",
      copyInd: null,
    };

    if (ind === "1") {
      payload.copyInd = true;
      this.store.dispatch(new STORE_CART_NAVIGATION(payload));
    } else {
      payload.copyInd = false;
      this.store.dispatch(new STORE_CART_NAVIGATION(payload));
    }
  }

  resetArrays() {
    this.mandatoryDocArray = [];
    this.optionalDocArray = [];
    this.fileArray = [];
  }

  checkEnableUploadButton() {
    let enableInd = false;
    let conditionMetCounter = 0;
    let noOfProductsRequireFile = 0;

    this.data.products.forEach((prod, index) => {
      let noOfFilesToBrowse = 0;
      let noOfFilesHaveBrowsed = 0;
      if (this.fileArray.length > 0 && (this.mandatoryDocArray.length > 0 || this.optionalDocArray.length > 0)) {
        const mandDoc = this.mandatoryDocArray.find((mand) => mand.productCode === prod.productCode);
        const optDoc = this.optionalDocArray.find((opt) => opt.productCode === prod.productCode);
        if (mandDoc && mandDoc.document) {
          mandDoc.document.forEach((doc1) => {
            noOfFilesToBrowse += doc1.data.length;
          });
        }
        if (optDoc && optDoc.document) {
          optDoc.document.forEach((doc2) => {
            noOfFilesToBrowse += doc2.data.length;
          });
        }
        this.fileArray
          .find((fileArr) => fileArr.productCode === prod.productCode)
          .files.forEach((file) => {
          if (file.fileName) {
            noOfFilesHaveBrowsed++;
          }
        });
        if (noOfFilesToBrowse > 0) {
          noOfProductsRequireFile++;
          if (noOfFilesHaveBrowsed === noOfFilesToBrowse) {
            conditionMetCounter++;
          }
        }
      }
      if (index === this.data.products.length - 1 && noOfProductsRequireFile > 0 && conditionMetCounter > 0 && noOfProductsRequireFile === conditionMetCounter) {
        enableInd = true;
      }
    });
    return enableInd;
  }

  select(item: any) {
    this.dialogRef.close({ item });
  }
}
