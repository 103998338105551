import {
  ChangeDetectorRef,
  Component,
  importProvidersFrom,
  ViewChild,
} from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { NxInputDirective } from "@aposin/ng-aquila/input";
import { NxDropdownComponent } from "@aposin/ng-aquila/dropdown";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-agents",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./agents.component.html",
  styleUrl: "./agents.component.scss",
})
export class AgentsComponent {

  agentForm = new FormGroup({
    agent: new FormControl('',Validators.required,),
    customer_type: new FormControl('FRANCHISE & OTHERS'),
    branch: new FormControl('Alor Setar'),
  });

  // agentFormControl = new FormControl("", [
  //   Validators.required,
  // ]);
  constructor(private cdr: ChangeDetectorRef) {}

  toText(value: string): string | null {
    return value ? value.toUpperCase() : null;
  }

}
