import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EnquiryComponent } from '../enquiry.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { GET_ENQUIRY_DETAILS } from '@store/enquiry/enquiry.action';
import { EnquiryPopupComponent } from "../../../popup/enquiry-popup/enquiry-popup.component";
import { CrossSellBannerComponent } from '@shared/components/cross-sell-banner/cross-sell-banner.component';
import { GET_NCD_CONFIRMATION, SEARCH_MAKE_MODEL } from "@store/sat-motor/sat-motor.action";
import { SortEvent, SortDirection } from '@aposin/ng-aquila/table/public-api';
import { NX_DATE_FORMATS } from "@aposin/ng-aquila/datefield";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";

import {
  DOWNLOAD_PDF
}
  from '@store/sat-motor/sat-motor.action';

import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-ncd-confirmation',
  standalone: true,
  imports: [
    EnquiryComponent,
    SharedModule,
    CrossSellBannerComponent,
    NxMomentDateModule
  ],
  providers: [{ provide: NX_DATE_FORMATS, useValue: MY_FORMATS }],
  templateUrl: './ncd-confirmation.component.html',
  styleUrl: './ncd-confirmation.component.scss'
})
export class NcdConfirmationComponent implements OnInit {
  componentDialogRef1?: NxModalRef<EnquiryPopupComponent>;
  jpjIsmForm: FormGroup;
  enquiryDetail: any;
  searchNcdConfirmationForm: FormGroup;
  searchModelResult: any;
  formattedCurrentDate: any;
  firstDateOfMonth: any;

  @ViewChild("templateEnHistory") templateRef5!: TemplateRef<any>;

  templateDialogRef?: NxModalRef<any>;
  @ViewChild("templateOne") templateRef!: TemplateRef<any>;
  showRef(template: TemplateRef<any>, type: any) {
    this.templateRef! = template;
    this.title = type;
  }

  title = "Private Cars Excluding Goods";
  requestTypeList = [
    { code: '- All -', name: 'ALL' },
    { code: 'CCN', name: 'CANCELLATION OF CONFIRMED NO CLAIM DISCOUNT' },
    { code: 'CFN', name: 'NO CLAIM DISCOUNT CONFIRMATION (NEWLY REGISTERED CAR)' },
    { code: 'CFO', name: 'NO CLAIM DISCOUNT CONFIRMATION (REGISTERED CAR)' },
    { code: 'ENQ', name: 'NO CLAIM DISCOUNT ENQUIRY' }
  ];

  constructor(
    private dialogService: NxDialogService,
    public store: Store,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    this.setDefaultDate();
    this.searchNcdConfirmationForm = this.fb.group({
      cnoteNo: [''],
      requestType: ['- All -', Validators.required],
      previousVehicleNo: [''],
      currentVehicleNo: [''],
      startDate: [this.firstDateOfMonth, Validators.required],
      endDate: [this.formattedCurrentDate, Validators.required]
    });
  }

  ngAfterViewInit() {

  }

  onSearchClicked() {

    // Check the values

    let value = {
      "cnoteNo": this.searchNcdConfirmationForm.getRawValue()["cnoteNo"],
      "requestType": this.searchNcdConfirmationForm.getRawValue()["requestType"],
      "previousVehicleNo": this.searchNcdConfirmationForm.getRawValue()["previousVehicleNo"],
      "currentVehicleNo": this.searchNcdConfirmationForm.getRawValue()["currentVehicleNo"],
      "startDate": this.searchNcdConfirmationForm.controls["startDate"].value,
      "endDate": this.searchNcdConfirmationForm.controls["endDate"].value
    };

    console.log('Form values:', this.searchNcdConfirmationForm.getRawValue());
    if (this.searchNcdConfirmationForm.valid) {
      this.store.dispatch(new GET_NCD_CONFIRMATION({ value })).subscribe((res) => {
        this.searchModelResult = res.SatMotorState.ncdDetails;
      });
    } else {
      this.searchNcdConfirmationForm.markAllAsTouched();
    }

  }

  resetForm() {
    this.setDefaultDate();
    this.searchNcdConfirmationForm = this.fb.group({
      cnoteNo: [''],
      requestType: ['- All -', Validators.required],
      previousVehicleNo: [''],
      currentVehicleNo: [''],
      startDate: [this.firstDateOfMonth, Validators.required],
      endDate: [this.formattedCurrentDate, Validators.required]
    });
    this.searchModelResult = "";
  }

  setDefaultDate() {
    const currentDate = new Date();
    this.formattedCurrentDate = currentDate.toISOString().split('T')[0]; // 'yyyy-MM-dd'  
    currentDate.setDate(1);
    this.firstDateOfMonth = currentDate.toISOString().split('T')[0];
  }


  getRequestTypeName(code: any) {
    return this.requestTypeList.find(rt => rt.code === (code || ''))?.name || '-';
  }

  sortTable(sort: SortEvent) {
    this.searchModelResult = this.searchModelResult.slice().sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        if (sort.active != 'updatedDate') {
          return this.compare(a[sort.active], b[sort.active], sort.direction);
        }
        else {
          return this.compareMoment(a[sort.active], b[sort.active], sort.direction);
        }
      }
      return 0;
    });
  }

  compareMoment(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {
    return (moment(a).isBefore(b) ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {
    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }

}
