<div nxModalContent class="modal_email_marketing">

  <div class="title_name nx-margin-top-0">
            Marketing Material
  </div>


 
 
        <form [formGroup]="searchForm">
        <nx-page-search [hideSearchButton]="true" buttonLabel="Search" aria-label="Search hidden example"
        class="search_box">

          <div nxLayout="grid nogutters" class="grid_remove_padding">
            <div nxRow>
                <div nxCol="12,12,6">
                    <div class="search_flex">
                        <nx-icon name="search" size="s" class="search_icon"></nx-icon>
                        <nx-formfield class="formfield_no_padding">
                          <input nxInput type="search" formControlName="searchBy" placeholder="To search, start typing..."/>
                          @if (searchForm.get('searchBy').value) {
                            <span
                                nxFormfieldSuffix
                                aria-hidden="true"
                                (click)="clearSearchBar()"
                            >
                                <nx-icon name="close" size="s"></nx-icon>
                            </span>
                            }
                        </nx-formfield>
                    </div>
                </div>
            </div>
        </div>

        </nx-page-search>
        </form>
     
  
  <p class="note_message">Note: Maximum 5 links to be attached.</p>
  <hr>

  <div class="search_table_wrapper">
  <table nxTable nxSort (sortChange)="sortTable($event)" zebra cellpadding=0 class="search_table">
    <thead>
      <tr nxTableRow>
        <th nxHeaderCell>
          @if(eDocument.length > 0) {
          <nx-checkbox (checkedChange)="toggleAll($event)" aria-label="Toggle selection of all rows"
            [checked]="checkboxCondition()" [indeterminate]="checkIndeterminate()">
          </nx-checkbox>
          }
        </th>
        <th nxHeaderCell nxSortHeaderCell="docTitle"><span nxCopytext="regular" class="nx-font-weight-semibold">e-Document Name</span></th>
        <th nxHeaderCell><span nxCopytext="regular" class="nx-font-weight-semibold">Action</span></th>
      </tr>
    </thead>
    <tbody>
        @for(item of eDocument.slice(start, end); track item) {
        <tr nxTableRow [selected]="selection.isSelected(item)" (select)="selection.toggle(item)">
          <td nxTableCell>
            <nx-checkbox (checkedChange)="selection.toggle(item)" aria-label="Toggle row selection" 
            [checked]="selection.isSelected(item)">
            </nx-checkbox>
          </td>
          <td nxTableCell>
            <div class="horizontal-checkboxes">
              <img class="pdf_img" src="../../../assets/img/Icon - PDF - Colour.svg" />
              <span nxCopytext="regular" class="nx-margin-left-xs">{{ item.docTitle }}</span>
            </div>
          </td>
          <td nxTableCell>
            <button nxPlainButton type="button" (click)="viewDocument(item.docUrl)">VIEW</button>
          </td>
        </tr>
        }
      
    </tbody>
  </table>
  </div>

  @if(eDocument.length > 10) {
  <form [formGroup]="pageFilterForm">
    <div nxLayout="grid nogutters" class="grid_remove_padding dropdown_pagination">
      <div nxRow rowAlignItems="center">
        <div nxCol="4" class="hide_pagedropdown">
          <div class="page_dropdwon">
            <span nxCopytext="regular">Show</span>
            <nx-formfield class="dropdown_numberpage formfield_no_padding">
              <nx-dropdown [(value)]="perPage" formControlName="perPage" [panelGrow]="true" [panelMaxWidth]="500"
                (selectionChange)="onChangePerPage()" class="dropdown_value_font">
                @for(item of itemsPerPageSelections; track item) {
                <nx-dropdown-item [value]="item"></nx-dropdown-item>
                }
              </nx-dropdown>
            </nx-formfield>
            <span nxCopytext="regular">results per page</span>
        </div>
        </div>
  

        <div nxCol="12,12,8">
          <nx-pagination [count]="eDocument.length" [page]="curPage" [perPage]="perPage" (goPrev)="prevPage()"
            (goNext)="nextPage()" (goPage)="goToPage($event)" type="advanced" ariaLabel="Advanced example pagination"
            class="custom_pagination">
          </nx-pagination>
      </div>
      </div>
    </div>
  </form>
  }

  <div class="button_send_flex">
    <button nxModalClose class="button-width" nxButton="primary" type="button"
      [disabled]="selection.selected.length <= 0 || selection.selected.length > 5" (click)="sendEmail()">
      SEND EMAIL
    </button>
    <button class="button-width" nxButton="primary" type="button"
      [disabled]="selection.selected.length <= 0 || selection.selected.length > 5 || !contactNo" (click)="sendWhatsapp()">
      SEND WHATSAPP
    </button>
  </div>

  @if(selection.selected.length > 5) {
  <div class="notification_warning">
    The maximum selection is 5.
  </div>
  }

  
</div>

<!-- <div nxModalActions class="">      
      <button nxButton="primary" nxModalClose>SEND EMAIL</button>
      <button nxButton="primary" nxModalClose>SEND WHATSAPP</button>     
  </div> -->