<!-- <div class="nx-hidden-xs nx-hidden-s nx-hidden-m">
  <div class="progress_bar">
    <aside class="nx-margin-left-m"> -->

<div class="nx-hidden-xs nx-hidden-s nx-hidden-m">
  <div class="progress_bar">
    <aside>
      <!-- <nx-multi-stepper currentStepLabel="Step" [direction]="direction" linear>
          <nx-step label="Declaration & Issuance Info"></nx-step>
          <nx-step label="Customer Partner"></nx-step>
          <nx-step label="Coverage"></nx-step>
          <nx-step label="Summary"></nx-step>
          <nx-step label="Complete"></nx-step>
      </nx-multi-stepper> -->

      <nx-multi-stepper currentStepLabel="Step" [direction]="direction"  linear>
        <ng-container *ngFor="let item of activeMultiStep; let i = index" >
          <nx-step [label]="item.name" [completed]="item.completed"></nx-step>
        </ng-container>
      </nx-multi-stepper>
    </aside>
  </div>
</div>


