import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from "@angular/core";
import { SyncScrollService } from "../sync-scroll/sync-scroll.service";
import { SharedModule } from "../../shared.module";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-external-scrollbar",
  standalone: false,
  // imports: [CommonModule, SharedModule],
  templateUrl: "./external-scrollbar.component.html",
  styleUrl: "./external-scrollbar.component.scss",
})
export class ExternalScrollbarComponent {
  @Input("targetSyncScroll") targetSyncScroll!: string;
  @Input("targetBaseWidth") targetBaseWidth!: HTMLElement;
  @ViewChild("scrollbar", { static: false }) scrollbar!: ElementRef;

  syncScrollIndex: number = -1;
  scrollWidth: number = 0;

  @HostListener("window:resize") onResize() {
    this.scrollWidth = this.targetBaseWidth.scrollWidth;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private syncScrollService: SyncScrollService
  ) {}

  ngAfterViewInit() {
    this.syncScrollIndex = this.syncScrollService.add(
      this.targetSyncScroll,
      this.scrollbar
    );

    this.onResize();
    this.changeDetectorRef.detectChanges();
    
  }

  get canScroll(): boolean {
    this.scrollWidth = this.targetBaseWidth.scrollWidth;
    return this.targetBaseWidth.scrollWidth > this.targetBaseWidth.clientWidth;
  }

  syncScroll() {

    this.syncScrollService.sync(this.targetSyncScroll, this.syncScrollIndex);
  }

  ngOnDestroy() {
    this.syncScrollService.remove(this.targetSyncScroll, this.syncScrollIndex);
  }
}
