<div nxLayout="grid nogutters">
  <div nxLayout="grid nogutters">
    <div nxRow>
      <div nxCol="11" class="nx-margin-bottom-2m">
        <h2 nxHeadline="subsection-medium" class="nx-margin-bottom-xs ">{{ 'UPLOAD_DOCUMENTS.TITLE' | translate }}</h2>
        <p><i>{{ 'UPLOAD_DOCUMENTS.SUB_TITLE' | translate }}</i></p>
      </div>
      <div nxCol="1" class="nx-margin-bottom-2m">
        <button nxPlainButton type="button"  class="float-right"><!--[mat-dialog-close]="arePreviousDocumentsCopied"-->
        <nx-icon name="close" size="s" class="color_close_btn"></nx-icon>
        </button>
      </div>
    </div>

    <app-external-scrollbar targetSyncScroll="uploadDocumentId"
                            [targetBaseWidth]="tableHolder"></app-external-scrollbar>
    <div class="table-holder table_content_scroll only-this-horizon-scrollbar" #tableHolder
         syncScroll="uploadDocumentId">
      <table nxTable zebra #table class="upload_table">
        <thead>
        <tr nxTableRow>
          <th nxHeaderCell>{{ 'UPLOAD_DOCUMENTS.DOC_NEEDED' | translate }}</th>
          <th nxHeaderCell>
            <p>{{ 'UPLOAD_DOCUMENTS.MAND' | translate }}/</p>
            <p>{{ 'UPLOAD_DOCUMENTS.OPT' | translate }}</p>
          </th>
          <th nxHeaderCell>{{ 'UPLOAD_DOCUMENTS.FILE_NAME' | translate }}</th>
          <th nxHeaderCell>{{ 'UPLOAD_DOCUMENTS.ACTION' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of data.products">

          <!-- Mandatory Default List -->
          <ng-container *ngFor="let doc of getMandatoryDoc(item.productCode); let i = index">
            <tr nxTableRow *ngFor="let val of getDocData(doc); let h = index">
              <ng-container *ngIf="h === 0">
                <td nxTableCell class="uppercase">{{ i + 1 }}. &nbsp; {{ doc.docName }}</td>
                <td nxTableCell>{{ 'UPLOAD_DOCUMENTS.MANDATORY' | translate }}</td>
              </ng-container>
              <ng-container *ngIf="h > 0">
                <td nxTableCell></td>
                <td nxTableCell></td>
              </ng-container>
              <td nxTableCell class="break-word">
                <p>{{ getFileName(val, item.productCode) }}</p>
              </td>
              <td nxTableCell class="table-no-wrap">
                <div class="horizontal-buttons">
                  <input type="file" style="display: none" #file
                         (change)="fileChange($event, item.productCode, val)" />

                  <button nxButton="secondary small" type="button" (click)="file.click()" class="nx-margin-right-s">
                    {{ 'UPLOAD_DOCUMENTS.CHOOSE_FILE' | translate }}
                  </button>

                  <button type="button" nxButton="secondary small"
                          [ngStyle]="{'visibility': h === 0 ? 'visible' : 'hidden' }"
                          (click)="addMoreFile(val, item.productCode, 'MANDATORY')" class="nx-margin-right-s">
                    {{ 'UPLOAD_DOCUMENTS.ADD_MORE' | translate }}
                  </button>

                  <button nxPlainButton type="button" (click)="deleteMandatoryRow(val, item.productCode)"
                          class="nx-margin-right-s">
                    <nx-icon name="trash-o"></nx-icon>
                  </button>

                  <button nxPlainButton type="button" class="nx-margin-right-s grey"
                          [ngClass]="val.docId ? 'actionHyperlink' : 'actionHyperlinkDisabled'"
                          (click)="val.docId ? viewDoc(val, item.productCode) : ''">
                    {{ 'UPLOAD_DOCUMENTS.VIEW' | translate }}
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>

          <!-- Optional Default List -->
          <ng-container *ngFor="let doc of getOptionalDoc(item.productCode); let j = index">
            <tr nxTableRow *ngFor="let val of getDocData(doc); let k = index">
              <ng-container *ngIf="k === 0">
                <td nxTableCell class="uppercase">
                  {{ getProductMandatoryDocLength(mandatoryDocumentList$ | async, item.productCode) + j + 1 }}.
                  &nbsp; {{ val.descp }}
                </td>
                <td nxTableCell>{{ 'UPLOAD_DOCUMENTS.OPTIONAL' | translate }}</td>
              </ng-container>
              <ng-container *ngIf="k > 0">
                <td nxTableCell></td>
                <td nxTableCell></td>
              </ng-container>
              <td nxTableCell class="break-word">
                <p>{{ getFileName(val, item.productCode) }}</p>
              </td>
              <td nxTableCell class="table-no-wrap">
                <div class="horizontal-buttons">
                  <input type="file" style="display: none;" #file onclick="value = null" [accept]="acceptFileType"
                         (change)="fileChange($event, item.productCode, val)" />

                  <button nxButton="secondary small" type="button" (click)="file.click()" class="nx-margin-right-s">
                    {{ 'UPLOAD_DOCUMENTS.CHOOSE_FILE' | translate }}
                  </button>

                  <button type="button" nxButton="secondary small"
                          [ngStyle]="{'visibility': k === 0 ? 'visible' : 'hidden' }"
                          (click)="addMoreFile(val, item.productCode, 'OPTIONAL')" class="nx-margin-right-s">
                    {{ 'UPLOAD_DOCUMENTS.ADD_MORE' | translate }}
                  </button>

                  <button nxPlainButton type="button" (click)="deleteOptionalRow(val, item.productCode)"
                          class="nx-margin-right-s">
                    <nx-icon name="trash-o"></nx-icon>
                  </button>

                  <button nxPlainButton type="button" class="nx-margin-right-s blue-primary"
                          [ngClass]="val.docId ? 'actionHyperlink' : 'actionHyperlinkDisabled'"
                          (click)="val.docId ? viewDoc(val, item.productCode) : ''">
                    {{ 'UPLOAD_DOCUMENTS.VIEW' | translate }}
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="optionalDocByProd((optionalDocumentList$ | async),item.productCode)?.length > 0">
            <!-- <ng-container> -->
            <tr nxTableRow>
              <td nxTableCell>
                <div class="upload_dropdown_width nx-margin-top-m">
                  <form [formGroup]="optionalDocForm">
                    <nx-formfield label="{{ 'UPLOAD_DOCUMENTS.OPTIONAL_DOCUMENT_PLACEHOLDER' | translate }}">
                      <nx-dropdown [showFilter]="true" formControlName="document">
                        <nx-dropdown-item *ngFor="let doc of getOptionalDocumentList(item.productCode) | sortAlphabet"
                                          value="doc" (click)="currentProductCode = item.productCode"
                                          class="uppercase">
                          {{ doc.descp }}
                        </nx-dropdown-item>
                      </nx-dropdown>
                    </nx-formfield>
                  </form>
                </div>
              </td>
              <td nxTableCell>{{ 'UPLOAD_DOCUMENTS.OPTIONAL' | translate }}</td>
              <td nxTableCell></td>
              <td nxTableCell></td>
            </tr>
          </ng-container>
        </ng-container>
        </tbody>
      </table>
    </div>
    <div class="nx-margin-top-3m"></div>

    <ng-container *ngIf="(uploadDocumentInfo$ | async)['isUploadFailed'] !== null">
      <nx-message nxFormfieldNote *ngIf="uploadDocumentInfo$['isUploadFailed']" context="error" showIcon="true">
        <span>Failed to upload file(s) for document(s) {{ getFailedDoc() }}</span>
      </nx-message>

      <nx-message nxFormfieldNote *ngIf="!uploadDocumentInfo$['isUploadFailed']" context="success" showIcon="true">
        <span>Your files has been successfully uploaded.</span>
      </nx-message>
      <div class="nx-margin-top-3m"></div>
    </ng-container>

    <ng-container *ngIf="(uploadDocumentInfo$ | async)['isRequotedDocCopied'] !== null">
      <nx-message nxFormfieldNote *ngIf="(uploadDocumentInfo$ | async)['isRequotedDocCopied']" context="success" showIcon="true">
        <span>Your files has been successfully copied and uploaded from previous quotation.</span>
      </nx-message>
      <div class="nx-margin-top-3m"></div>
    </ng-container>

    <ng-container *ngIf="deleteFail">
      <nx-message *ngIf="deleteFail"  context="error" showIcon="true" nxFormfieldNote>
        <span>Failed to delete for document {{ deleteFailDocName }}</span>
      </nx-message>
      <div class="nx-margin-top-3m"></div>
    </ng-container>

    <button nxButton="secondary" class="nx-margin-right-m nx-margin-bottom-m"><!--[mat-dialog-close]="arePreviousDocumentsCopied"-->
    {{ 'GLOBAL.ACTION.CLOSE' | translate }}
    </button>
    <button nxButton="primary" class="nx-margin-bottom-m" (click)="uploadDocument()"
            [disabled]="!checkEnableUploadButton()">
      {{ 'UPLOAD_DOCUMENTS.UPLOAD' | translate }}
    </button>

  </div>
</div>
