import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: "[appOnlyNumber]",
})
export class OnlyNumberDirective {
  @Input("appOnlyNumber") isOnlyNumber: boolean;

  private regex: RegExp = new RegExp(/^[0-9]+$/g);

  private specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight"];

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex) && this.isOnlyNumber) {
      event.preventDefault();
    }
  }
}
