import { Component, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from "../../shared/shared.module";
import { ProgressBarComponent } from "../../components/progress-bar/progress-bar.component";
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';
import { CoveragePopupComponent } from "../../popup/coverage-popup/coverage-popup.component";

@Component({
  selector: 'app-coverage',
  standalone: true,
  imports: [
    SharedModule,
    ProgressBarComponent,
    NxSmallStageModule,
    TextFieldModule
  ],
  templateUrl: './coverage.component.html',
  styleUrl: './coverage.component.scss'
})


export class CoverageComponent {

  templateDialogRef?: NxModalRef<any>;

  constructor(private readonly dialogService: NxDialogService) {}
  componentDialogRef?: NxModalRef<CoveragePopupComponent>;

  openPopup(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(CoveragePopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "800px",
      maxWidth: "800px",
      data: { popup_type: popup_type },
    });
  }

  openMessage(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(CoveragePopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      width: "400px",
      maxWidth: "400px",
      data: { popup_type: popup_type },
    });
  }

  openPopupComp(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(CoveragePopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,
      height: "500px",
      width: "800px",
      maxWidth: "800px",
      data: { popup_type: popup_type },
    });
  }

  openPopupMakeModel(popup_type: any) {
    this.componentDialogRef = this.dialogService.open(CoveragePopupComponent, {
      ariaLabel: "A simple dialog",
      showCloseIcon: false,

      width: "920px",
      maxWidth: "920px",
      data: { popup_type: popup_type },
    });
  }



  toDisplayComp = false;
  toDisplayTP = false;
  toDisplayTPFT = false;

  toggleDataComp() {
    this.toDisplayComp = !this.toDisplayComp;
    this.toDisplayTP = false;
    this.toDisplayTPFT = false;
  }

  toggleDataTP() {
    this.toDisplayComp = false;
    this.toDisplayTP = !this.toDisplayTP;
    this.toDisplayTPFT = false;
  }

  toggleDataTPFT() {
    this.toDisplayComp = false;
    this.toDisplayTP = false;
    this.toDisplayTPFT = !this.toDisplayTPFT;
  }



  @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;
  makeModel = "PERODUA MYVI 1.3";

  // temporary implementation. future to implement form
  characterCount: number = 0;
  maxlength = 100;
  onKeyUp(event: any) {
    this.characterCount = event.target.value.length + this.makeModel.length;
  }


}
