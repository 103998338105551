import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAlphabet'
})
export class SortAlphabetPipe implements PipeTransform {

  transform(array: Array<any>): Array<any>{
    let newArray = array;
    return newArray.slice().sort((a,b) => {
        if(a.descp < b.descp)
            return -1;
        if(a.descp > b.descp)
            return 1;
        return 0;
    });
  }

}
