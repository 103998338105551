import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MarketingMaterialComponent } from '../modal/marketing-material/marketing-material.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { GET_REDIRECT_PAGE, GET_SWITCH_SITE, ISSUE_NEW } from '@store/sat-motor/sat-motor.action';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cross-sell-banner',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './cross-sell-banner.component.html',
  styleUrl: './cross-sell-banner.component.scss'
})
export class CrossSellBannerComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
  @Input() crossSellList: any;
  @Input() userInfo: any;
  @Input() marketingConsent: any;
  @Input() clientPartnerInfo: any;
  @Input() agentInfo: any;
  @Input() isApproval: boolean;
  marketingMaterialModal?: NxModalRef<MarketingMaterialComponent>;

  currentIndex = 0;
  showPagination = false;
  imagesPerView = 2; // Default for large screens
  totalPages = 0;

  touchStartX = 0;
  touchEndX = 0;

  constructor(
    private readonly dialogService: NxDialogService,
    private store: Store,
    private router: Router
  ) {}

  @HostListener('window:resize') onWindowResize() {
    this.checkScreenSize();
  }

  @HostListener('wheel', ['$event'])
  onMouseScroll(event: WheelEvent) {
    if (this.isCursorOnBanner(event)) {
      event.preventDefault();
      if (event.deltaY > 0) {
        this.nextImage();
      } else {
        this.prevImage();
      }
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].clientX;
    this.handleSwipe();
  }

  ngOnInit() {
    this.checkScreenSize();
  }

  ngAfterViewInit() {
    this.checkScreenSize();
  }

  openPopUpMarketingMaterial(crossSell) {
    this.marketingMaterialModal = this.dialogService.open(MarketingMaterialComponent, {
      minWidth: '70vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      showCloseIcon: true,
      data: {
        crossSell: crossSell,
        customerPartnerInfo: this.clientPartnerInfo,
        userInfo: this.userInfo,
        agentInfo: this.agentInfo,
        isApproval: this.isApproval
      }
    });
  }

  getCSRedirectURL(crossSell) {
        const payload = {
          agentCode: this.agentInfo.agentCode,
          documentNo: '',
          operator: this.userInfo.userId,
          userId: this.userInfo.userId,
          productType: crossSell?.productType,
          action: 'ISSUE',
          contractId: '',
          productCode: crossSell.productCode,
          cnoteType: 'NW',
          idType: this.clientPartnerInfo.idType1,
          idValue: this.clientPartnerInfo.idValue1,
          clientType: this.clientPartnerInfo.clientType,
          // renewNow: false,
          // equote: false
        };

        this.store.dispatch(new GET_REDIRECT_PAGE(payload)).subscribe((state) => {
          // let switchSiteUrl = state.SatMotorState.data.switchSiteUrl;

          // switchSiteUrl += payload.agentCode ? `&agentCode=${payload.agentCode}` : '';
          // switchSiteUrl += payload.productType ? `&productType=${payload.productType}` : '';
          // switchSiteUrl += payload.productCode ? `&productCode=${payload.productCode}` : '';
          // switchSiteUrl += payload.action ? `&action=${payload.action}` : '';
          // switchSiteUrl += payload.documentNo ? `&documentNo=${payload.documentNo}` : '';
          // switchSiteUrl += payload.contractId ? `&contractId=${payload.contractId}` : '';
          // switchSiteUrl += payload.cnoteType ? `&cnoteType=${payload.cnoteType}` : '';
          // switchSiteUrl += payload.idType ? `&idType=${payload.idType}` : '';
          // switchSiteUrl += payload.idValue ? `&idValue=${payload.idValue}` : '';
          // switchSiteUrl += payload.clientType ? `&clientType=${payload.clientType}` : '';
          // switchSiteUrl += `&renewNow=${payload.renewNow}`;
          // switchSiteUrl += `&equote=${payload.equote}`;
          
          // console.log("SwitchSiteUrl Url : " + switchSiteUrl);
          // window.location.href = switchSiteUrl;
        });
            
  }

  disableMartketingAndIssueNew() {
    if(this.marketingConsent) {
      return this.userInfo.adminType === "HQ" || this.userInfo.adminType === "BDM"; 
    }else {
      return true;
    }
  }

  prevImage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.totalPages - 1; // Loop to last
    }
    this.scrollToCurrentImage();
  }

  nextImage() {
    if (this.currentIndex < this.totalPages - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0; // Loop back to first
    }
    this.scrollToCurrentImage();
  }

  scrollToCurrentImage() {
    if (this.scrollContainer) {
      const scrollAmount = this.currentIndex * this.scrollContainer.nativeElement.clientWidth / this.imagesPerView;
      this.scrollContainer.nativeElement.scrollTo({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  isCursorOnBanner(event: WheelEvent): boolean {
    if (!this.scrollContainer) return false;

    const banner = this.scrollContainer.nativeElement;
    const rect = banner.getBoundingClientRect();


    return event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom;
  }

  handleSwipe() {
    const swipeThreshold = 50; // Minimum swipe distance in pixels
    const swipeDistance = this.touchStartX - this.touchEndX;

    if (swipeDistance > swipeThreshold) {
      this.nextImage(); // Swipe left → next image
    } else if (swipeDistance < -swipeThreshold) {
      this.prevImage(); // Swipe right → previous image
    }
  }

  setCurrentIndex(index: number) {
    this.currentIndex = index;
    this.scrollToCurrentImage();
  }

  checkScreenSize() {
    const screenWidth = window.innerWidth;
    this.imagesPerView = screenWidth <= 993 ? 1 : 2; // Mobile: 1 image, Large: 2 images
    this.totalPages = Math.max(1, Math.ceil(this.crossSellList.length / this.imagesPerView)); // Calculate pages
    this.showPagination = this.crossSellList.length > this.imagesPerView; // Show only if needed
  }

}
