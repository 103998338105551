import { Component, Input } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-claim-details-table',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './claim-details-table.component.html',
  styleUrl: './claim-details-table.component.scss'
})
export class ClaimDetailsTableComponent {
  @Input() data;
}
