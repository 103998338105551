<!-- RoadTax Issuance Information (to be move to new component) -->
@if (isProgressTypeRTP) {
  <div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Product Name</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.productName) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Vehicle No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.vehicleNo) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Cover Note Type</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.coverNoteType) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Policy No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.policyNo) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Effective Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.effectiveDate) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Expiry Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.expiryDate) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Cover Note No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.eCoverNoteNo) }}</p>
      </nx-data-display>
    </div>
    @if(viewQuotation) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >JPJ Status</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(issuanceData?.jpjStatus) }}</p>
        </nx-data-display>
      </div>
      }
      @if(viewQuotation) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >JPJ Message</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(issuanceData?.jpjMessage) }}</p>
        </nx-data-display>
      </div>
      }
  </div>
  
}


<!-- Cover Note Issuance Information (to be move to new component) -->
@if (isProgressTypeCN && !isUpdateJPJScreen && !viewPolicyDetails) {
  <div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Product Name</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.productName) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Vehicle No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.vehicleNo) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Cover Note Type</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.coverNoteType) }}</p>
      </nx-data-display>
    </div>
  
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Previous Policy No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.prevPolicyNo) }}</p>
      </nx-data-display>
    </div>
  

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Effective Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.effectiveDate) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Expiry Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.expiryDate) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Quotation No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.equotationNo) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Quotation Creation Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.equotationCreatedDate) }}</p>
      </nx-data-display>
    </div>
  
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Cover Note No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.eCoverNoteNo) }}</p>
      </nx-data-display>
    </div>
    @if(!isApproval) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Document Consent</nx-data-display-label
        >
        <p nxCopytext="large">{{ pdpaData?.edocConsent }}</p>
      </nx-data-display>
    </div>
    }
    @if(!isApproval) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Invoice Consent</nx-data-display-label
        >
        <p nxCopytext="large">{{ pdpaData?.einvoiceConsentInd }}</p>
      </nx-data-display>
    </div>
    }
    @if(!isTypeIssuance) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >JPJ Status</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.jpjStatus) }}</p>
      </nx-data-display>
    </div>
    }
    @if(!isTypeIssuance) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >JPJ Message</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.jpjMessage) }}</p>
      </nx-data-display>
    </div>
    }
    @if(isApproval) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >Previous Policy Expiry Date</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(issuanceData?.prevPolExpiryDate) }}</p>
        </nx-data-display>
      </div>
    }
  </div>
}

@if (isProgressTypeCN && isUpdateJPJScreen) {

  <div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Product Name</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.productName) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Vehicle No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.vehicleNo) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Cover Note Type</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.coverNoteType) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Cover Note No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.eCoverNoteNo) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Effective Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.effectiveDate) }}</p>
      </nx-data-display>
    </div>
    
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Expiry Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.expiryDate) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >JPJ Status</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.jpjStatus) }}</p>
      </nx-data-display>
    </div>

  </div>
}


<!-- Endorsement Issuance Information (to be move to new component) -->
@if (isProgressTypeENDST) {
  <div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Product Name</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.productName) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Vehicle No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.vehicleNo) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Endorsement Type</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(endorsementTypeDesc) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Policy No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.policyNo) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Policy Effective Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.effectiveDate) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Policy Expiry Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.expiryDate) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Endorsement Effective Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(getFormattedDate(issuanceData?.endtEffectiveDate)) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Endorsement Expiry Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(getFormattedDate(issuanceData?.endtExpiryDate)) }}</p>
      </nx-data-display>
    </div>
    @if(!isApproval) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Endorsement Ref. No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.endtReferenceNo) }}</p>
      </nx-data-display>
    </div>
    }
    @if(!isApproval) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Endorsement No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.endtNo) }}</p>
      </nx-data-display>
    </div>
    }
    <!-- @if(!isApproval) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">JPJ Status</nx-data-display-label>
        <p nxCopytext="large">{{ formatData(issuanceData?.jpjStatus) }}</p>
      </nx-data-display>
    </div>
    }
    @if(!isApproval) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey">JPJ Message</nx-data-display-label>
        <p nxCopytext="large">{{ formatData(issuanceData?.jpjMessage) }}</p>
      </nx-data-display>
    </div>
    } -->
    @if(!isApproval) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Invoice Consent</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(pdpaData?.einvoiceConsentInd) }}</p>
      </nx-data-display>
    </div>
    }
    @if(!isApproval && issuanceData?.equotationNo) {
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Quotation No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.equotationNo) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Quotation Creation Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.equotationCreatedDate) }}</p>
      </nx-data-display>
    </div>
    }
    @if(isApproval) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >Previous Policy Expiry Date</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(getFormattedDate(issuanceData?.prevPolicyDate)) }}</p>
        </nx-data-display>
      </div>
    }
    @if(isApproval) {
      <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
        <nx-data-display size="small">
          <nx-data-display-label class="font-grey"
            >Endorsement Method</nx-data-display-label
          >
          <p nxCopytext="large">{{ formatData(issuanceData?.endtMethodDesc) }}</p>
        </nx-data-display>
      </div>
    }
  </div>
}

@if (viewPolicyDetails) {

  <div nxRow>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Product Name</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(cngeNote?.productName) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Vehicle No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.vehicleNo) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Effective Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.effectiveDate) }}</p>
      </nx-data-display>
    </div>
    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >Expiry Date</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.expiryDate) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >E-Policy No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(cngeNote?.policyNo) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Cover Note No.</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(issuanceData?.eCoverNoteNo) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Document Consent</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(pdpaData?.edocConsent) }}</p>
      </nx-data-display>
    </div>

    <div nxCol="12,12,4,3" class="nx-margin-bottom-2m">
      <nx-data-display size="small">
        <nx-data-display-label class="font-grey"
          >e-Invoice Consent</nx-data-display-label
        >
        <p nxCopytext="large">{{ formatData(pdpaData?.einvoiceConsentInd) }}</p>
      </nx-data-display>
    </div>

  </div>
}
