<header nx-header>
   <!-- header Start-->
  <nx-header-row class="header_container">
    <nx-header-brand>
      <nx-link class="nx-margin-right-l">
        <a href="#">
          <figure nxFigure>
            <img src="../../../assets/img/logo.svg" />
          </figure>
        </a>
      </nx-link>
      <div class="container_flex_center nx-hidden-xs nx-hidden-s nx-hidden-m">
        <nx-link (click)="toAllianzMalaysia()">Allianz Malaysia</nx-link>
        <nx-link class="nx-margin-left-2m" (click)="backToAlphaSystem()">Alpha Portal</nx-link>
        <!--nx-formfield>
          <nx-dropdown [panelGrow]="true" value="SAT Portal">
            <div class="arrow_icon"></div>
            <nx-dropdown-item value="Allianz Malaysia" class="menu_list" (click)="toAllianzMalaysia()"></nx-dropdown-item>
            <nx-dropdown-item value="Alpha Portal" class="menu_list" (click)="backToAlphaSystem()"></nx-dropdown-item>
            <nx-dropdown-item value="SAT Portal" class="menu_list" (click)="backToSATSystem()"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield-->
      </div>
    </nx-header-brand>
    <nx-header-actions class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
      <nx-icon name="bars" aria-hidden="true" (click)="toggleMenu()"></nx-icon>
    </nx-header-actions>
    <nx-header-actions class="nx-hidden-xs nx-hidden-s nx-hidden-m">
      <div>
        <span nxCopytext="medium">{{ userName$ | async }}</span>
        <span class="font-grey nx-margin-left-3xs" nxCopytext="small"> {{ lastLogin$ | async }} </span>
      </div>
      <div class="border-line nx-margin-x-m"></div>
      <button nxPlainButton class="button_black" (click)="signOut()">
        <nx-icon name="export-and-share" class="rotate_logout_icon ndbx-icon nx-icon--export-and-share"
                 nxIconPositionStart aria-hidden="true"></nx-icon>
        <span nxCopytext="medium">{{ 'HEADER.SIGN_OUT' | translate }}</span>
      </button>
    </nx-header-actions>
  </nx-header-row>
  <nx-header-row class="header_border">
  </nx-header-row>
  <!-- header end-->
  <nx-header-row class="bottom_header_menu header_container">
    <nx-header-brand class="nx-margin-0 nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
      <div nxCopytext="medium" class="nx-margin-right-2xs">{{ userName$ | async }}</div>
      <div class="font-grey nx-margin-top-3xs" nxCopytext="small"> {{ lastLogin$ | async }}</div>
    </nx-header-brand>
    <nx-header-navigation *ngIf="showmenu && isShowMenu">
      <nx-header-navigation-item class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        <a nxHeaderLink>Alpha Portal</a>
        <hr />
      </nx-header-navigation-item>

      <ng-container *ngFor="let item of menuList">
        <nx-header-navigation-item>
          @if (item.menuId === 'SA_MNPOLICIES') {
            <div class="nx-header__link policytab" nxMenuButton (click)="onClick(item)" menuButtonType="root"
                 [expandable]="!!item.subMenu" nxMenuItem [expanded]="item.hidden">
              <a class="policy" nxHeaderLink>{{ item.description }}</a>
            </div>
            <div nxMenuItem *ngIf="item.subMenu && item.hidden" class="sub_menu">
              <div nxLayout="grid maxwidth nogutter">
                <div nxRow>

                  <div nxCol="12,12,12,2,2,2" class="nx-margin-bottom-m">
                    @for (itemPolMg of menuPolicyMgmt; track itemPolMg) {
                      @if (["SA_POLISSUANCE", "SA_POLSUMMARY", "SA_POLREPORTS", "SA_POLENQUIRY"].includes(itemPolMg.menuId)) {
                        <a (click)="nav(itemPolMg.link, itemPolMg, item)">{{ itemPolMg.description }}</a> <br />
                      }
                    }
                  </div>
                  <div nxCol="12,12,12,4,4,4" class="nx-margin-bottom-m">
                    @for (itemPolMg of menuPolicyMgmt; track itemPolMg) {
                      @if ("SAT_MT_MOTOR" === itemPolMg.menuId) {
                        <span class="nx-font-weight-semibold">{{ itemPolMg.description }}</span><br />
                        @for (itemPolMgSubMenu of itemPolMg.subMenu; track itemPolMgSubMenu) {
                          @if (["SAT_MT_COMM", "SAT_MT_MTCYLE", "SAT_MT_PRIVATECAR"].includes(itemPolMgSubMenu.menuId)) {
                            <a class="padding_0 header_nested_sub_menu" nxMenuButton (click)="onClick(itemPolMgSubMenu)" menuButtonType="root" [expandable]="!!itemPolMgSubMenu.subMenu" nxMenuItem [expanded]="itemPolMgSubMenu.hidden">
                              <nx-icon nxMenuButtonIcon name="chevron-right" size="s" aria-hidden="true"></nx-icon>
                              {{ itemPolMgSubMenu.description }}
                            </a>
                            <div nxMenuItem *ngIf="itemPolMgSubMenu.subMenu && itemPolMgSubMenu.hidden">
                              <a class="nested_sub_menu" *ngFor="let submenu of itemPolMgSubMenu.subMenu" nxMenuLink (click)="open(submenu.link, submenu, item)">
                                {{ submenu.description }}
                              </a>
                            </div>
                          }
                          @else {
                            <a (click)="nav(itemPolMgSubMenu.link, itemPolMgSubMenu, item)">{{ itemPolMgSubMenu.description }}</a>
                            <br />
                          }
                        }
                      }
                    }
                  </div>
                  <div nxCol="12,12,12,3,3,3" class="nx-margin-bottom-m">
                    @for (itemPolMg of menuPolicyMgmt; track itemPolMg) {
                      @if ("SA_NONMOTOR" === itemPolMg.menuId) {
                        <span class="nx-font-weight-semibold">{{ itemPolMg.description }}</span><br />
                        @for (itemPolMgSubMenu of itemPolMg.subMenu; track itemPolMgSubMenu) {
                          <a (click)="nav(itemPolMgSubMenu.link, itemPolMgSubMenu, item)">{{ itemPolMgSubMenu.description }}</a><br />
                        }
                      }
                    }
                  </div>
                </div>
              </div>
            </div>
          } @else {
            <a nxHeaderLink (click)="nav(item.link, item, null)">{{ item.description }}</a>
          }
          <hr class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl" />
        </nx-header-navigation-item>
      </ng-container>

      <nx-header-navigation-item class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        <a class="color_dark_blue" nxHeaderLink>Allianz Malaysia</a>
        <hr />
      </nx-header-navigation-item>
      <nx-header-navigation-item class="nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl">
        <a nxHeaderLink>
          <button nxPlainButton class="button_black">
            <nx-icon name="export-and-share" class="rotate_logout_icon ndbx-icon nx-icon--export-and-share"
                     nxIconPositionStart aria-hidden="true"></nx-icon>
            Log out
          </button>
        </a>
        <hr />
      </nx-header-navigation-item>
    </nx-header-navigation>

    <nx-header-actions>
      <nx-icon name="user-o" size="s" class="nx-margin-s"></nx-icon>
      <nx-icon name="bell-o" size="s" class=""></nx-icon>
    </nx-header-actions>
  </nx-header-row>
</header>
